import { Pipe, Injectable, PipeTransform, ElementRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
@Pipe({
  name: 'slxPbjRecNumber',
  pure: true
})
@Injectable()
export class SlxPbjRecNumberPipe implements PipeTransform {
  // TODO: add explicit constructor
  constructor(private decimalPipe: DecimalPipe) {}

  public transform(val: number): string {
    const value = +val;
    let result: string = '';

    if (!isNaN(value)) {
      result = this.decimalPipe.transform(value, '1.2-2');
    }
    
    return result;
  }
}

import { Component, OnInit, OnChanges, SimpleChanges, Input, Provider, ChangeDetectionStrategy } from '@angular/core';

import 'moment-range';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, GroupDescriptor, GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { OrgLevel } from '../../../../state-model/models/index';
import { DialogOptions } from '../../../../common/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { VacationPlannerApiService } from '../../../services/index';
import {
  VacationPlanEmployeeRecordDetails
} from '../../../models/index';
import { VacationEmployeeDialogComponent } from '../index';
import { ORG_LEVEL_ID_TOKEN } from '../../../../core/models/index';
import { Lookup, LookupType, ILookupRequest } from '../../../../organization/models/index';
import { ScheduleAbsence } from '../../../../organization/models/lookup/index';

export class VacationDetailsRecord {
  public record: VacationPlanEmployeeRecordDetails;
  public lookup: Lookup;

  constructor(record: VacationPlanEmployeeRecordDetails, lookup: Lookup) {
    this.record = record;
    this.lookup = lookup;
  }
}

@Component({
  moduleId: module.id,
  selector: 'slx-weekly-absences-grid',
  templateUrl: 'weekly-absences-grid.component.html',
  styleUrls: ['weekly-absences-grid.component.scss']
})
export class WeeklyAbsencesGridComponent implements OnInit, OnChanges {
  @Input()
  public orgLevelId: number;
  @Input()
  public scheduleRecords: VacationPlanEmployeeRecordDetails[];
  @Input()
  public absencesLookup: Lookup;

  public appConfig: IApplicationConfig;
  public gridView: GridDataResult;
  public gridState: State;
  public gridData: VacationPlanEmployeeRecordDetails[];
  public vacationDetailsRecords: VacationDetailsRecord[];

  constructor() {
    this.gridState = {
      skip: undefined,
      take: undefined,
      filter: undefined,
      sort: undefined,
      group: undefined
    };
    this.vacationDetailsRecords = [];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (_.isArray(this.scheduleRecords) && _.isObject(this.absencesLookup)) {
      this.fillInWithLookups();
      this.refreshGrid();
    }
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.refreshGrid();
  }

  private fillInWithLookups(): void {
    this.vacationDetailsRecords = _.map(this.scheduleRecords, (record: VacationPlanEmployeeRecordDetails) => {
      const vacDetails: VacationDetailsRecord = this.makeVacationDetailsRecord(record);
      if (record.scheduleAbsence) {
        const index: number = _.findIndex<ScheduleAbsence>(this.absencesLookup.items, { code: record.scheduleAbsence.code });
        if (index === -1) {
          vacDetails.lookup.items.push(_.assign(new ScheduleAbsence(), record.scheduleAbsence));
        }
      }

      return vacDetails;
    });
  }

  private makeVacationDetailsRecord(record: VacationPlanEmployeeRecordDetails): VacationDetailsRecord {
    const lookup: Lookup = _.cloneDeep(this.absencesLookup);

    return new VacationDetailsRecord(record, lookup);
  }

  private refreshGrid(): void {
    if (!this.vacationDetailsRecords) {
      this.gridView = null;
      return;
    }
    this.gridView = process(this.vacationDetailsRecords, this.gridState);
  }
}

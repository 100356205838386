import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import { NgForm, NgModel } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { IColumnSettings } from '../../../../core/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { timeAndAttendanceConfig } from '../../../time-and-attendance.config';
import { KendoGridStateHelper, saveEvent, KendoGridCustomSelectionHelper } from '../../../../common/models/index';
import { Supervisor, SupervisedEmployee } from '../../../models/index';
import { unsubscribe } from '../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-supervisor-grid',
  templateUrl: 'supervisor-grid.component.html',
  styleUrls: ['supervisor-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupervisorGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public supervisors: Supervisor[];
  @Output()
  public selectionChanged: EventEmitter<Supervisor[]>;
  @Output()
  public saveSuprvisor: EventEmitter<Supervisor>;

  public appConfig: IApplicationConfig;
  public selectedRecords: Supervisor[];
  public gridState: KendoGridStateHelper<Supervisor>;
  public selectionHelper: KendoGridCustomSelectionHelper<Supervisor>;
  
  private selectedRowIndex: number;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;

  constructor(private changeDetector: ChangeDetectorRef) {
    this.gridState = new KendoGridStateHelper<Supervisor>();
    this.selectionHelper = new KendoGridCustomSelectionHelper<Supervisor>(this.gridState.view, false);
    this.selectionChanged = new EventEmitter<Supervisor[]>();
    this.saveSuprvisor = new EventEmitter<Supervisor>();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    this.gridEditSubscription = this.gridState.onEdit$.subscribe(() => {
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<Supervisor>) => {
      this.saveSuprvisor.emit(event.dataItem);
    });
  }

  public onCheckedPrimary(supervisor: Supervisor, ngModel: NgModel): void {
    const primarySupervisor = _.find(this.supervisors, (s: Supervisor) => supervisor.id !== s.id && s.isPrimary);
    if (_.isObjectLike(primarySupervisor) && primarySupervisor.id !== supervisor.id && supervisor.isPrimary) {
      ngModel.control.setErrors({ noMore: true });
    } else {
      const errors = ngModel.control.errors || {};
      delete errors.noMore;
      ngModel.control.setErrors(_.size(errors) > 0 ? errors : null);
    }
  }  

  public ngOnChanges(changes: SimpleChanges): void {
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public allSelectionChange(): void {
    this.selectionHelper.onToggleAllSelected();
    this.selectedRecords = this.selectionHelper.selection.slice(0);
    this.selectionChanged.emit(this.selectedRecords);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public selectionChange(supervisor: Supervisor): void {
    this.selectionHelper.onItemSelectionChange(supervisor);
    this.selectedRecords = this.selectionHelper.selection.slice(0);
    this.selectionChanged.emit(this.selectedRecords);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private refreshGrid(): void {
    if (!this.supervisors) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.supervisors, this.gridState.state);
    this.selectionHelper.view = this.gridState.view;
  }
}


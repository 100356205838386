import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ShiftRequestService } from '../../../services/schedule/shift-request.service';
import { AttributesModel, AttributeValue, DetailsAgencyModel, DetailsAgencyPosition, DetailsPartner } from '../../../models';
import * as _ from 'lodash';


@Component({
  selector: 'slx-shift-request-details',
  templateUrl: './shift-request-details.component.html',
  styleUrls: ['./shift-request-details.component.scss']
})

export class ShiftRequestDetailsComponent implements OnInit, OnChanges {

  @Input()
  public isShiftSelected: boolean;

  @Input()
  public selectedShiftDetails: any[];

  @Input()
  public agencyData: DetailsAgencyModel[];

  @Input()
  public scrollContentSize: number;

  @Output()
  public agencyStaffRequest = new EventEmitter<any[]>();


  public agencyList: DetailsAgencyModel[] = [];
  public selectedAgencyList: DetailsAgencyModel[] = [];
  public selectedAgencyCount: number = 0;
  public isAgencyExpanded: boolean = false;
  public expandedAgencyDetails: DetailsAgencyModel[] = [];

  public scrollViewitems: any[] = [];
  private scrollViewPreviousItem = 0;
  public editMode: boolean;
  public scrollCounter: number = 1;
  public disable: boolean = true;
  public totalScroll: number = 1;
  public remainderscoll: number;
  public dropDownOptions: AttributeValue[] = [];
  public premuimPay: string;
  public incentivePay: string;
  public isIncentiveDetails = false;
  public infectious: string;
  public instantAccept: boolean = true;
  public shiftAttributes: AttributeValue[] = [];
  public partnerAttributes: any[] = [];
  public isValidIncentivePayDetails: boolean = false;
  public incentivePaydropdown: string;
  public incentivePayText: string;
  public agencyListData: DetailsPartner[] = [];
  public controlCounter: number = 0;
  public controlConterText: number = -1;
  public attributes: AttributesModel[] = [];
  public isAgencyDetails: boolean = true;
  public incentivepayAmount: any;
  public isValidPosition: any[] = [];
  public isInstantAccept: any[] =[];

  constructor(private shiftService: ShiftRequestService) {
    this.scrollViewitems = new Array<number>(this.scrollContentSize);
  }

  ngOnInit() {

    this.isShiftSelected = false;
    this.shiftService.editAgencyDetails().subscribe((details) => {
      this.selectedAgencyList = details['selectedAgency'];
      this.editMode = true;
      this.isShiftSelected = true;
      this.selectedShiftDetails.push(details);
      this.getAgencyDetails();
    });
    this.shiftService.updateShiftRequests().subscribe((addShift) => {
      this.editMode = false;
    });

  }
  public ngOnChanges(changes: SimpleChanges) {
    this.selectedAgencyList = [];
    this.isIncentiveDetails = false;
    this.premuimPay = 'Standard';
    this.incentivePay = 'No';
    this.infectious = 'None';
    this.instantAccept = true;
    this.incentivePaydropdown = '';
    this.incentivePayText = '';
    this.selectedAgencyCount = 0;
    this.agencyList = this.agencyData;
    this.getAgencyDetails();
    if (this.selectedShiftDetails.length > 0) {
      this.isShiftSelected = true;
    }
  }
  public getAgencyDetails(): void {

    if (this.editMode) {
      this.selectedAgencyCount = this.selectedAgencyList.length;
      this.agencyList.forEach(element => {
        this.expandedAgencyDetails[element.partnerId] = false;
      });
      this.selectedAgencyList.forEach(element => {
        this.expandedAgencyDetails[element.partnerId] = true;
        element.attributes.sort();
        this.updateAttributes();
        element.attributes.forEach(el => {
          if (el.name == "infectious") { this.infectious = el.attrValues[0].name }
          if (el.name == "incentivePay") { this.incentivePay = el.attrValues[0].name }
          if (el.name == "premiumPay") { this.premuimPay = el.attrValues[0].name }
          if (el.name == "instantAccept") {
            if (el.attrValues[0].name == 'No') {
              this.instantAccept = false;
            }
            else if(el.attrValues[0].name == 'Yes') {
              this.instantAccept = true;
            }
          }
          if (this.incentivePay == 'Yes') {
            this.isIncentiveDetails = true;
          }
        });
      });
      this.setDefaultValues();
    }
    else {
      this.agencyList.forEach(element => {
        this.expandedAgencyDetails[element.partnerId] = false;
        this.isValidPosition[element.partnerId] = true;
        element.attributes.sort();
      });

      this.setDefaultValues();

    }
    this.agencyList.forEach((element:DetailsAgencyModel) => {
      
      let tempIA = {partnerId:element.partnerId, value:true}
      this.isInstantAccept.push(tempIA)
    });
    // intialize  scroll view 
    this.totalScroll = Math.trunc(this.agencyList.length / 3);
    this.remainderscoll = (this.agencyList.length) % 3
    if (this.remainderscoll > 0) {
      this.totalScroll += 1;
    }
    this.scrollCounter = 0;
    let index = 0;
    this.scrollViewitems = new Array<number>(this.scrollContentSize);
    for (index = 0; index < this.scrollContentSize; index++) {

      this.scrollViewitems[index] = this.agencyList[this.scrollCounter * this.scrollContentSize + index];
    }
    this.dropDownOptions = this.shiftService.getInentivePayDropDownValues(this.agencyList);
  }
  public updateAttributes(): void {
    this.selectedAgencyList.forEach(agency => {
      this.agencyList.forEach(element => {

        if (element.partnerId == agency.partnerId) {
          let incentiveDetails;
          if (element.attributes.length <= 4) {
            element.partner_attributes.subscribe(e => {
              e.forEach(el => {

                if (el.key == "incentivepayAmount") {
                  incentiveDetails = _.cloneDeep(el);
                }
                if (el.key == "incentivepayPercent") {
                  incentiveDetails = _.cloneDeep(el);
                }
              });
            });

            let attr: AttributesModel = new AttributesModel();
            if (incentiveDetails != undefined) {

              attr.attrValues = incentiveDetails.options;
              attr.name = incentiveDetails.key;
              attr.value = incentiveDetails.value;
            }
            element.attributes = _.cloneDeep(agency.attributes);
            element.attributes = element.attributes.concat(attr);
          }


        }
      });
    });
  }
  public setDefaultValues() {
    if (this.editMode) {
     this.EditModeDefaultValues();
    }
    else {
      this.attributeDefaultValues();
    } 

  }
  public attributeDefaultValues():void {
    this.agencyList.forEach(element => {
      if (element.attributes.length > 4) {
        let attr = _.cloneDeep(element.attributes);
        this.attributes = attr;
        this.attributes = this.attributes.filter(e => e.name != "incentivepayAmount");
        this.attributes = this.attributes.filter(e => e.name != "incentivepayPercent");
       
      }
    });
    this.attributes.forEach(element => {
      if (element.name != 'instantAcceptGlobal') {
          if (element.name != 'instantAcceptManual') {
            element.attrValues.pop();
            if(element.name == "instantAccept") {
              this.updateInstantAccept(element)
          }
        }
      }
    });
  }
  public EditModeDefaultValues():void {
         this.selectedAgencyList.forEach(element => {
        if (element.attributes.length > 4) {
          let attr = _.cloneDeep(element.attributes);
          this.attributes = attr;
          this.attributes = this.attributes.filter(e => e.name != "incentivepayAmount");
          this.attributes = this.attributes.filter(e => e.name != "incentivepayPercent");

        }
      });   
  }
  public updateInstantAccept(attr) :void {
    let attrValues: AttributeValue = new AttributeValue();
    if (this.instantAccept) {
      attrValues.name = 'Yes'
    }
    else {
      attrValues.name = 'No'
    }                    
    attr.attrValues = [attrValues];
            
  }
  public unselectedAgency(value: boolean, partnerId: string): void {
    //Agency unselected position mapping error should go 

    if (!value) {
      this.isValidPosition[partnerId] = true;
    }
  }
  public verifyInstantAcceptValue(partnerId:string) {
    let partnerDetails = this.isInstantAccept.filter(element => element.partnerId ==partnerId)
      return partnerDetails[partnerDetails.length-1].value;

  }
  public selectAgency(selectedAgency: DetailsAgencyModel): void {
    this.verifyInstanctAccept(selectedAgency);   
    let partnerDetails = this.isInstantAccept.filter(element => element.partnerId ==selectedAgency.partnerId)
    if(partnerDetails[partnerDetails.length-1].value) {
      this.isValidShiftPosition(selectedAgency);
      if (this.isValidPosition[selectedAgency.partnerId]) {
       this.updateSelectedAgency(selectedAgency);
        this.selectedAgencyCount = this.selectedAgencyList.length;

      }
    }

  }
  public updateSelectedAgency(selectedAgency: DetailsAgencyModel): void {
    if (this.selectedAgencyList.length > 0) {
      let isAgencySelected = this.selectedAgencyList.filter(e => e.partnerId == selectedAgency.partnerId)
      if (isAgencySelected.length > 0) {
        this.selectedAgencyList = this.selectedAgencyList.filter(e => e.partnerId != selectedAgency.partnerId);

      }
      else {
        this.selectedAgencyList.push(selectedAgency);
      }
    }
    else {
      this.selectedAgencyList.push(selectedAgency);
    }
  }
  public verifyInstanctAccept(agency: DetailsAgencyModel): void {
    let isInstantAccept: boolean = false;
    let isManualAccept: boolean = false;
    let tempInstantAccept;
    
      isInstantAccept =false;
      isManualAccept = false;
      agency.attributes.forEach(el => {
        if (el.name == "instantAcceptGlobal") {
          isInstantAccept = el.value == 'true' ? true : false;
        }
        if (el.name == "instantAcceptManual") {
          isManualAccept = el.value == 'true' ? true : false;
       
        }
      });
      this.validateInstanctAccept(agency,isInstantAccept,isManualAccept)
     
  }
  public validateInstanctAccept(agency,isInstantAccept,isManualAccept) :void {
    let tempInstantAccept = false; 
    if ((isInstantAccept == false && isManualAccept == false)) {
      tempInstantAccept= false;
     }
     else if ((isInstantAccept == false) && this.instantAccept) {
      tempInstantAccept = false;

     }
     else if ((isInstantAccept) && this.instantAccept == false &&(isManualAccept == false) ) {
      tempInstantAccept= false;
     }

   else {
     tempInstantAccept = true;
   }
   this.agencyList.forEach((element:DetailsAgencyModel) => {
     if (element.partnerId == agency.partnerId) {
       let tempIA = {partnerId:element.partnerId, value:tempInstantAccept}
       this.isInstantAccept.push(tempIA)
     }
   });
  }
  public getInstanctAcceptError(): string {
    return 'Please connect with the SLX Support Team as Agency Profile is not configured';
  }
  public isValidShiftPosition(selectedAgency: DetailsAgencyModel) {
    let partnerPositionMapData = this.shiftService.getPartnerPositionMapData();

    this.selectedShiftDetails.forEach(element => {
      let currentPositionsData: DetailsAgencyPosition[] = partnerPositionMapData.get(selectedAgency.partnerId).positions;

      let isValid = currentPositionsData.some(
        (el) => el.position_name === element.JobDescription

      );
      if (!this.isValidPosition[selectedAgency.partnerId]) {
        this.isValidPosition[selectedAgency.partnerId] = false;
      }
      else {
        this.isValidPosition[selectedAgency.partnerId] = isValid;
      }
    });
  }
  public prevAgencyList(): void {
    this.disable = true;
    this.scrollCounter -= 1;
    let index = 0;

    if (this.scrollCounter < 1) {
      this.scrollCounter = 0;
    }
    this.scrollViewitems = new Array<number>(this.scrollContentSize);

    for (index = 0; index < this.scrollContentSize; index++) {
      this.scrollViewitems[index] = this.agencyList[this.scrollCounter * this.scrollContentSize + index];
    }
    this.disable = false;
  }
  public nextAgencyList(): void {
    this.disable = true;
    if (this.scrollCounter > this.scrollViewitems[this.scrollViewitems.length - 1]) {
      this.scrollCounter = this.scrollViewitems.length;
    }
    this.scrollCounter += 1;

    if (this.scrollCounter == this.totalScroll) {
      this.scrollCounter = this.totalScroll

    }

    else {

      let index = 0;
      this.scrollViewitems = new Array<number>(this.scrollContentSize);
      if (this.remainderscoll > 0 && this.totalScroll - 1 == this.scrollCounter) {
        this.scrollViewitems = new Array<number>(this.remainderscoll);
        for (index = 0; index < this.remainderscoll; index++) {
          this.scrollViewitems[index] = this.agencyList[this.scrollCounter * this.scrollContentSize + index];
        }
      } else {
        for (index = 0; index < this.scrollContentSize; index++) {
          if (this.agencyList[this.scrollCounter * this.scrollContentSize + index]) {
            this.scrollViewitems[index] = this.agencyList[this.scrollCounter * this.scrollContentSize + index];
          }
        }
      }

    }
    this.disable = false;
  }
  public disablePreview(): boolean {
    if (this.scrollCounter > 0) {
      return true
    }
    return false;
  }
  public disableNext(): boolean {
    if (this.scrollCounter < this.totalScroll - 1) {
      return true
    }
    return false;
  }
  public getDisplayName(agency: DetailsAgencyModel): string {
    if (agency.name == undefined) { return ''; }
    return agency.name.length > 50 ? (agency.name.substr(0,50)) + '..' : (agency.name)
  }
  public getAttributeControlType(partnerAttributes: any, attributes): string {
    
    this.partnerAttributes = partnerAttributes;
    this.controlCounter = -1;
    let controlType: string;
    let control = this.partnerAttributes.filter(e => e.key == "incentivepayPercent")
    this.partnerAttributes.filter(e => {
      if (e.key == "incentivepayPercent") {
        if (control.length > 0) {
          controlType = control[0].controlType;
          this.controlCounter = attributes.findIndex(item => item.name === 'incentivepayPercent');
          this.dropDownOptions = control[0].options;

        }

      }
    });
    if (!controlType) {
      let control = this.partnerAttributes.filter(e => e.key == "incentivepayAmount")

      this.partnerAttributes.filter(e => {
        if (e.key == "incentivepayAmount") {
          if (control.length > 0) {
            controlType = control[0].type;
            this.controlConterText = attributes.findIndex(item => item.name === 'incentivepayAmount');
          }
        }
      });
      if (this.editMode) {

        this.controlCounter = attributes.length - 1;

      }
    }
    return controlType;

  }
  public onDisplaySelectChanged(name, value): void {

    if (this.incentivePay == 'Yes') {
      this.isIncentiveDetails = true;
    }
    else {
      this.isIncentiveDetails = false;
    }
    let attrValues: AttributeValue = new AttributeValue();
    attrValues.name = value;
    let attr: AttributesModel[] = this.attributes.filter(e => e.name == name)
    attr[0].attrValues = [];
    attr[0].attrValues.push(attrValues);

  }
  public instanctAcceptChange(): void {
    let attrValues: AttributeValue = new AttributeValue();
    if (this.instantAccept) {
      attrValues.name = 'Yes'
    }
    else {
      attrValues.name = 'No'
    }

    let selectedAttribute: AttributesModel = new AttributesModel();
    selectedAttribute.attrValues = [attrValues];
    let attr: AttributesModel[] = this.attributes.filter(e => e.name == "instantAccept")
    attr[0].attrValues = [];
    attr[0].attrValues.push(attrValues);
    this.selectedAgencyList = [];
    this.selectedAgencyCount = 0 ;
    this.agencyList.forEach((element:DetailsAgencyModel) => {
      this.expandedAgencyDetails[element.partnerId] = false;      
      let tempIA = {partnerId:element.partnerId, value:true}
     this.isInstantAccept.push(tempIA)
    });
  }
  public updateAgencyAttributes() {
    this.selectedAgencyList.forEach(agency => {
      this.agencyList.forEach(element => {
        if (element.partnerId == agency.partnerId) {
          agency.attributes = _.cloneDeep(element.attributes);
        }
      });
    });
  }
  public addShiftRequest(): void {
    if (this.editMode) {
      this.updateAgencyAttributes();
    }
    this.selectedAgencyCount = 0;
    this.selectedAgencyList.forEach(element => {
      let attr = element.attributes
      let tempAttr: AttributesModel[] = [];
      if (this.isIncentiveDetails) {
        attr.forEach(el => {
          if (el.name == 'incentivepayAmount' || el.name == "incentivepayPercent") {
            tempAttr.push(el);
          }
        });
        if (tempAttr.length > 0) {
          let tempValue = tempAttr[0].attrValues;
          let tempAttrValue: AttributeValue[] = [];
          if (tempValue.length > 0) {
            tempValue.forEach(e => {
              tempAttrValue = [{ id: e.id, 'name': e.name, value: false }]
            });
          }
          tempAttr[0].attrValues = tempAttrValue;
          element.attributes = this.attributes.concat(tempAttr);
        }
        else {
          element.attributes = this.attributes;
        }
      }
      else {
        element.attributes = this.attributes;
      }
    });
    let tempAgencyList = _.cloneDeep(this.selectedAgencyList);
    this.selectedAgencyList = [];
    this.agencyStaffRequest.emit(tempAgencyList);

    this.selectedAgencyList = [];
    if (this.editMode) {
      this.shiftService.updateRequestAgency();
    }
    this.editMode = false;
    this.isShiftSelected = false;
    this.isValidIncentivePayDetails = true;
    this.selectedShiftDetails = [];

  }

  public cancelShiftRequest(): void {
    this.selectedAgencyList = [];
    this.isShiftSelected = false;
    this.shiftService.updateShifts(false);
  }

}

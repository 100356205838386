
  <div *ngIf="isQuickConfirmStarted " class="message-line">
    <span class="message1">Quick Confirm Active!</span>
    <span class="message2">Click a cell in the grid to Confirm.</span>
  </div>
  
  <div class="shift-editor-holder">
  </div>
  <div class="buttons-holder">
    <button *ngIf="!isQuickConfirmStarted"  (click)="startQuickConfirm()" class="start-button">Start</button>
    <button *ngIf="isQuickConfirmStarted" (click)="saveQuickConfirm()" class="stop-button">Save</button>
    <button *ngIf="!showChanges" [disabled]="!hasChanges" (click)="showChangesClick()" class="show-button">Show Changes</button>
    <button *ngIf="showChanges" (click)="hideChangesClick()" class="hide-button">Hide Changes</button>
  
  </div>
  <div *ngIf="showChanges" class="changes-holder">
    <div class="changes-holder-title">
      <span>Last Changes</span>
      <hr>
    </div>
    <ng-container   *ngFor="let item of items; let index = index">  
      <div #elem [ngClass]="{ hideChildren : expandedItem?.employeeMasterId !== item.employeeMasterId }">
      <div  class="change-item">
        <span class="change-emp-name" (click)="setExpandedItem(item)" >
          <em class="fa fa-angle-down" [ngClass]="{ 'hideArrow': expandedItem?.employeeMasterId !== item.employeeMasterId, 'showArrow': expandedItem?.employeeMasterId === item.employeeMasterId }"></em>
          <em class="fa fa-angle-right"  [ngClass]="{ 'hideArrow' : expandedItem?.employeeMasterId == item.employeeMasterId, 'showArrow': expandedItem?.employeeMasterId !== item.employeeMasterId }" ></em>
          {{item.employeeName}}
        </span>      
        <button (click)="undoEdit(item)" class="undo-button">
          <i class="fas fa-undo-alt" aria-hidden="true"></i>
        </button>
      </div>
        <ng-container *ngFor="let monthItem of item.months; let index = index">       
          <div class="change-child-item">
            <span class="change-month-name">{{monthItem.monthName}}</span>
            <span class="change-value">{{monthItem.selectedCode}}  ${{monthItem.selectedCost}}  {{monthItem.selectedHarbor }}</span>       
            <button (click)="undoEdit(item,monthItem)" class="undo-button">
              <i class="fas fa-undo-alt" aria-hidden="true"></i>
            </button>
          </div>
        </ng-container>   
      </div>
    
    </ng-container>
  </div>
  
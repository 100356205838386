import { Subscription } from 'rxjs';
import { OpenShiftManagementManagementService } from './../../../services/open-shift-management/open-shift-management-management.service';
import { PostScheduleSettings } from './../../../models/open-shift-management/post-schedule-settings';
import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import * as moment from 'moment';
import { ScheduleCycle } from './../../../../../app/organization';
@Component({
  moduleId: module.id,
  selector: 'slx-post-schedule-settings-form',
  templateUrl: 'post-schedule-settings-form.component.html',
  styleUrls: ['post-schedule-settings-form.component.scss']
})
export class PostScheduleSettingsFormComponent implements OnInit, OnDestroy {
  public settings: PostScheduleSettings;
  public checkNotify: any;
  @Output()
  public onCancel: EventEmitter<any>;
  @Output()
  public onApply: EventEmitter<any>;
  @unsubscribe()
  private settingsSubscription: Subscription;

  @unsubscribe()
  private selectedScheduleCycleDataSubscription: Subscription;

  public isApplyButtonDisabled: boolean = false;

  constructor(private openShiftManagementManagementService: OpenShiftManagementManagementService) {
    this.onCancel = new EventEmitter();
    this.onApply = new EventEmitter();
    this.settings = new PostScheduleSettings();
  }

  public ngOnInit(): void {
    this.settingsSubscription = this.openShiftManagementManagementService.onPostSettingsLoaded$.subscribe((value: PostScheduleSettings) => {
      this.settings = value;
    });
    this.openShiftManagementManagementService.dataEmitter.subscribe((value) => {
      this.checkNotify = value;
    })

    this.selectedScheduleCycleDataSubscription = this.openShiftManagementManagementService.selectedScheduleCycleData$.subscribe((scheduleCycle: ScheduleCycle) => {
      this.isApplyButtonDisabled = (moment(scheduleCycle.endDate).toDate() < moment().startOf('D').toDate()) ? true : false;
      this.onCancel.emit();
    });

  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public applySettings(): void {
    this.openShiftManagementManagementService.postSettings = this.settings.clone();
    this.onApply.emit();
  }

  public restoreSettings(): void {
    this.settings = this.openShiftManagementManagementService.postSettings.clone();
  }

  public onCancelHandler(): void {
    this.restoreSettings();
    this.onCancel.emit();
  }

  get isDisabled(): boolean {
    if (this.openShiftManagementManagementService.isIncreaseFrequencyofShiftsPostedNotifications)
      return ((this.settings.notifyDaysAndWeekly == 0 && this.settings.reminderCount == null && this.settings.notifyWeekly) || (this.settings.reminderCount == null && this.settings.notifyDaysAndWeekly != 0) || (this.settings.reminderCount == 0 && this.settings.notifyDaysAndWeekly != 0));

    //Disable if Schedule Cycle End Date is less than today - #304114
    return this.isApplyButtonDisabled;
  }

}

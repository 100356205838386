<slx-spinner [show]="isLoading">
  <div class="slx-high-box workers-compensation-configuration">

    <div class="slx-high-box__body">
      <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
        slxKendoGridState="BENEFITS_OPTION_DETAIL_OPTIONS" 
        [slxKendoGridColumnsGroup]="columnGroup"
        [data]="gridState.view" 
        [skip]="gridState.state.skip" 
        [sortable]="true" 
        [sort]="gridState.state.sort"
        [filterable]="'menu'" 
        [filter]="gridState.state.filter" 
        (dataStateChange)="gridState.dataStateChange($event)"
        (edit)="gridState.editHandler($event)">

        <kendo-grid-column title="Type" width="200" field="type" filter="text" [sortable]="true">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Type</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ dataItem.type }}
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Code" width="180" field="code" filter="text" [sortable]="true">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Code</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ dataItem.code }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Spouse" width="180" field="spouse" filter="text" [sortable]="true">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Spouse</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ dataItem.spouse }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Children" width="120" field="children" filter="text" [sortable]="true">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Children</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ dataItem.children }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Cost" width="160" field="cost" filter="text" [sortable]="true">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Cost</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ dataItem.cost | number: ".2-2"| slxMoney}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Employee Contribution" width="160" field="empContribution" filter="text"
          [sortable]="true">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Employee Cont.</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ dataItem.empContribution | number: ".2-2"| slxMoney}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Employer Contribution" width="160" field="erContribution" filter="text"
          [sortable]="true">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Employer Cont.</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ dataItem.erContribution | number: ".2-2"| slxMoney }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-messages filterMenuTitle="Filter">
        </kendo-grid-messages>  
      </kendo-grid>
    </div>
  </div>
</slx-spinner>
import { Subject ,  Subscription ,  Observable } from 'rxjs';

export class SignalrHubEvent<T> {
  public event: string;
  public groupSubjects: StringMap<SignalrHubGroup<T>>;
}

export class SignalrHubGroup<T> {
  public event: string;
  public group: string;
  public subject: Subject<T>;
  public toObservable(): SignalrHubGroupObservable<T> {
    let obs: SignalrHubGroupObservable<T> = new SignalrHubGroupObservable<T>();
    obs.event = this.event;
    obs.group = this.group;
    obs.observable = this.subject.asObservable();
    return obs;
  }
}

export class SignalrHubGroupObservable<T> {
  public event: string;
  public group: string;
  public subscription: Subscription;
  public observable: Observable<T>;
}

import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable ,  Subscription } from 'rxjs';

import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../../app/app.config';
import { subscribeToOrgLevel } from '../../../../../../organization/selectors/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../../../common/services/index';

import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../../common/services/index';
import { OrgLevel } from '../../../../../../state-model/models';
import { BenefitLineInfo,BenefitEnrolledEmployee, BenefitDetailsOption } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/index';

@Component({
  selector: 'benefit-option-details-options-grid',
  templateUrl: 'benefit-option-details-options-grid.component.html',
  providers: [StateManagementService, ColumnManagementService ]
})
export class BenefitOptionDetailsOptionsGridComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<BenefitLineInfo>;
  public columnGroup = 'BenefitOptionDetailsOptions';
  public appConfig: IApplicationConfig;
  public isLoading: boolean = true;
  public isShowingExpired: boolean = false;

  @Input()
  public benefitOptionData: BenefitDetailsOption[];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  constructor(private manService: BenefitEmployeeManagementService,
    private stateManagement: StateManagementService,
    private columnManagementService: ColumnManagementService
  ) {
    this.gridState = new KendoGridStateHelper<BenefitLineInfo>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;

    this.appConfig = appConfig;
  }

  public ngOnInit() {
    this.stateManagement.init('BenefitsOptionGridComponent');
    this.columnManagementService.init('BenefitsOptionGridComponent');
    this.columnManagementService.initGroup(this.columnGroup, 5);
    this.refreshGrid();
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

  }

  public ngOnDestroy(): void {

  }

  private refreshGrid(): void {
    this.isLoading = false;
    if (!this.benefitOptionData) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.benefitOptionData, this.gridState.state);
  }

}

import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { TimecardSummaryModel, TimecardSummaryRequest } from '../../../models/index';
import { Department, Position, EmpType, PayPolicy, ShiftDiffPolicy } from '../../../../organization/models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-timecard-summary-filters',
    templateUrl: 'summary-filters.component.html',
    styleUrls: ['summary-filters.component.scss']
})

export class SummaryFiltersComponent implements OnInit, OnDestroy {


    public get department(): Department[] {
      if(this.management.orgLevelDepartment) {
        return this.management.orgLevelRelatedDepartments;
      } else {
        return this.selectedDepartments;
      }
    }
    public set department(value: Department[]) {
      this.selectedDepartments = value;
    }
    public position: number[];
    public empType: string[];
    public payPolicy: string[];
    public shiftDiffPolicy: string[];

    @unsubscribe()
    public loadSubscription: Subscription;

    private summary: TimecardSummaryModel;
    private selectedDepartments: Department[];

    constructor(public management: TimecardsSummaryManagementService) { }

    public ngOnInit(): void {
        this.loadSubscription = this.management.onLoaded$.subscribe((summary: TimecardSummaryModel) => {
            if (
              summary && summary.organization &&
              this.summary && this.summary.organization &&
              this.summary.organization.id !== summary.organization.id
            ) {
              this.resetFilters();
            }

            this.summary = summary;
        });
    }

    public ngOnDestroy(): void {
      // See #issueWithAOTCompiler
    }

    public onFilterChanged(): void {
        if (this.summary) {
            this.summary = null;
            let request: TimecardSummaryRequest = this.createSummaryRequest();
            this.management.loadTimecardsSummary(request);
        }
    }

    private resetFilters(): void {
        this.department = [];
        this.position = [];
        this.empType = [];
        this.payPolicy = [];
        this.shiftDiffPolicy = [];
    }

    private createSummaryRequest(): TimecardSummaryRequest {

        let request: TimecardSummaryRequest = new TimecardSummaryRequest();
        request.organizationId = this.management.organizationId;
        request.startDate = this.management.currentPayCycle.startDate;
        request.endDate = this.management.currentPayCycle.endDate;

        if (this.department) request.departmentIds = _.map(this.department, 'id');
        if (this.position) request.positionIds = _.map(this.position, 'id');
        if (this.empType) request.employeeTypeNames = _.map(this.empType, 'name');
        if (this.payPolicy) request.payPolicyNames = _.map(this.payPolicy, 'name');
        if (this.shiftDiffPolicy) request.shiftDifferentialPolicyNames = _.map(this.shiftDiffPolicy, 'name');

        return request;
    }
}

import { mutableSelect } from './../../../../core/decorators/redux-decorators';
import { Subscription ,  Observable } from 'rxjs';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
import { SpecificMessageComponent } from './specific-message/specific-message.component';
import { TimeclockMessageComponent } from './timeclock-message/timeclock-message.component';
import { OrgLevel } from '../../../../state-model/models/index';
import { Component, Output, Inject, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TimeclockCommunicationSendTypes } from '../../models/index';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-timeclock-communication',
  templateUrl: 'timeclock-communication.component.html',
  styleUrls: ['timeclock-communication.component.scss']
})
export class TimeclockCommunicationComponent implements OnInit, OnDestroy {

    @Output()
    public onCancel: EventEmitter<any>;
    public orgLevel: OrgLevel;
    @mutableSelect('orgLevel')
    public orgLevel$: Observable<OrgLevel>;

    public state: {
        isLoading: boolean;
        isActiveOnly: boolean;
    };

    public sendType: TimeclockCommunicationSendTypes = TimeclockCommunicationSendTypes.specific;

    @unsubscribe()
    private orgLevelSubscription: Subscription;

    constructor() {
        //this.orgLevel = orgLevel;
        this.onCancel = new EventEmitter<any>();
        this.state = {
            isLoading: false,
            isActiveOnly: false
        };
    }

    public ngOnInit(): void {
        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            this.orgLevel = orgLevel;
          });
    }

    public ngOnDestroy(): void {
      // #issueWithAOTCompiler
    }

    public cancel(): void {
        this.onCancel.emit();
    }
}



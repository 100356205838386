export * from './orglevel-path.pipe';
export * from './timezone-display-name.pipe';

import { OrgLevelPathPipe } from './orglevel-path.pipe';
import { TimezoneDisplayNamePipe } from './timezone-display-name.pipe';

export const configurationPipes: any [] = [
    OrgLevelPathPipe,
    TimezoneDisplayNamePipe
];

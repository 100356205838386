import * as _ from 'lodash';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { IDialog, ModalService, DialogOptions, ISelectableItemContainer } from '../../../../../../common/index';
import { Assert } from '../../../../../../framework/index';
import { AccrualPolicy, DynamicAccrualPolicy } from '../../../../models';
import * as moment from 'moment';
import { EmployeeSectionsAccrualsApiService } from '../../../../services/index';
import { AbstractControl, NgForm } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-dynamic-policy-dialog.component',
  templateUrl: 'dynamic-policy-dialog.component.html',
  styleUrls: ['dynamic-policy-dialog.component.scss']
})
export class DynamicPolicyDialogComponent implements IDialog, OnInit {

  public get initialized(): boolean {
    return this.m_initialized;
  }
  public employeeId: number;
  public accrualPolicyLookup: AccrualPolicy[] = [];
  public dynamicAccrualPolicy: DynamicAccrualPolicy[] = [];
  public options: DialogOptions;
  public dialogResult: boolean;
  public isLoading: boolean;
  private modalService: ModalService;
  private m_initialized: boolean;
  public now: Date = moment().startOf('day').toDate();
  public isPolicyDisabled: boolean = false;
  public addCounter: number = 0;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }
  @ViewChild('form')
  public ngForm: NgForm;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private employeeSectionsAccrualsApiService: EmployeeSectionsAccrualsApiService,
    options: DialogOptions, modalService: ModalService) {
    Assert.isNotNull(options, 'options');
    this.options = options;
    Assert.isNotNull(modalService, 'modalService');
    this.modalService = modalService;
    this.dialogResult = false;
    this.isLoading = false;
  }

  public ngOnInit(): void {
    this.m_initialized = true;

    if (this.dynamicAccrualPolicy.length == 0) {
      this.isPolicyDisabled = true;
    }
  }

  public get SelectedPolicyIds(): string {
    let str: string = "";
    this.dynamicAccrualPolicy.forEach((i: DynamicAccrualPolicy) => {
      if (i.policyId != '0') {
        if (str == "") {
          str = str.concat(i.policyId.toString());
        }
        else {
          str = str.concat("-", i.policyId.toString());
        }
      }

    });
    return str;
  }

  public get SelectedPolicyNames(): string {
    let str: string = "";
    this.dynamicAccrualPolicy.forEach((i: DynamicAccrualPolicy) => {
      if (i.policyId != '0') {
        if (str == "") {
          str = str.concat(i.policyName);
        }
        else {
          str = str.concat("-", i.policyName)
        }
      }

    });
    return str;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public loadSubsection(): void {

    if (this.employeeId) {
      this.isLoading = true;
      this.employeeSectionsAccrualsApiService.getAccrualsPolicyList(this.employeeId, this.now.toDateString())
        .then((accrualPolicy: AccrualPolicy[]) => {
          this.isLoading = false;
          this.accrualPolicyLookup = accrualPolicy;
          if (this.dynamicAccrualPolicy.length == 0) {
            this.onAddPolicy();
          }
          this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
        }).catch(() => {
          this.isLoading = false;
        });
    }
  }

  public onAddPolicy(): void {

    this.addCounter++;
    let accrualPolicy: DynamicAccrualPolicy = new DynamicAccrualPolicy();
    accrualPolicy.id = this.addCounter;
    accrualPolicy.policyName = "";
    accrualPolicy.policyId = "0";
    accrualPolicy.isError = false;
    accrualPolicy.accrualType = "";
    this.dynamicAccrualPolicy.push(accrualPolicy);
    this.isPolicyDisabled = true;
  }

  private addPolicyDisabled() {
    if (this.dynamicAccrualPolicy.length == 0) {
      this.isPolicyDisabled = true;
    }
    else {
      let anySelected: boolean = false;
      let anyError: boolean = false;
      let count: number = 0;
      _.forEach(this.dynamicAccrualPolicy, (x: DynamicAccrualPolicy) => {
        if (x.policyId != "0") {
          anySelected = true;
        }
        count = _.filter(this.dynamicAccrualPolicy, ['accrualType', x.accrualType]).length;
        if (count == 1) {
          x.isError = false;
        }

        if (x.isError) {
          anyError = true;
        }

      });
      if (!anySelected || anyError) {
        this.isPolicyDisabled = true;
      }
      else {
        this.isPolicyDisabled = false;
      }
    }
  }
  
  public onDeletePolicy(pid: number): void {
    _.remove(this.dynamicAccrualPolicy, { id: pid });
    this.addPolicyDisabled();
  }
  
  public policyChanged(item: DynamicAccrualPolicy, value): void {
    let dpolicy = _.without(this.dynamicAccrualPolicy, item);
    _.forEach(this.accrualPolicyLookup, function (value) {
      if (value.id == parseInt(item.policyId)) {
        item.policyName = value.policyName;
        item.accrualType = _.first(value.rules).accrualTypeName;
        return false;
      }
    });
    if ((_.findIndex(dpolicy, ['accrualType', item.accrualType])) != -1) {
      item.isError = true;
      this.isPolicyDisabled = true;
    }
    else {
      item.isError = false;
      this.isPolicyDisabled = false;
    }
    this.addPolicyDisabled();
  }


}

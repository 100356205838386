import * as _ from 'lodash';
import { EmployeeSectionBridgeService } from './../../../services/employee-section-bridge.service';
import { EmployeeSectionsComponent } from './../../employee-sections/employee-sections.component';
import { Subscription } from 'rxjs';
import { unsubscribe } from './../../../../../core/decorators/unsubscribe-decorator';
import { KendoGridStateHelper } from './../../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { ConfirmDialogComponent } from './../../../../../common/components/confirm-dialog/confirm-dialog.component';
import { Component, OnInit, OnDestroy, Input, Host, NgZone, Provider, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Assert } from '../../../../../framework/index';
import { MetaMapService } from '../../../../../core/services/index';

import { ModalService } from '../../../../../common/services/modal/modal.service';
import {
  EmployeeSectionsAuditTrail,
  EmployeeSectionsAuditTrailNoteRecord,
  EmployeeSectionsAuditTrailRecord,
  EmployeeSectionsBase,
} from '../../../models/index';
import { EmployeeSectionsAuditApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';


import { GridDataResult } from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  process, State
} from '@progress/kendo-data-query';
import { EmployeeSectionsNoteEditComponent } from '../employee-sections-note-edit/employee-sections-note-edit.component';
import { DialogOptions } from '../../../../../common/models/dialog-options';
import { AppSettingsManageService } from '../../../../../app-settings/services/index';
import { AppServerConfig } from '../../../../../app-settings/model/app-server-config';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-audit-trail',
  templateUrl: 'employee-sections-audit-trail.component.html',
  styleUrls: ['employee-sections-audit-trail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsAuditTrailComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input('employeeSectionsAuditTrail')
  public set auditTrail(employeeSectionsAuditTrail: EmployeeSectionsAuditTrail) {
    this.employeeSectionsAuditTrail = employeeSectionsAuditTrail;
    this.refreshGrid();
  }
  @Input()
  public employeeId: number;

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }
  
  public gridState: KendoGridStateHelper<EmployeeSectionsAuditTrailRecord>;
  public pageSize: number = 10;

  public get form(): AbstractControl {
    return null;
  }
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public employeeSectionsAuditTrail: EmployeeSectionsAuditTrail;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  private employeeSectionsAuditApiService: EmployeeSectionsAuditApiService;
  private modalService: ModalService;
  private metaMapService: MetaMapService;
  private changeDetector: ChangeDetectorRef;
  private appSettings: AppServerConfig;

  constructor(
    employeeSectionsAuditApiService: EmployeeSectionsAuditApiService,
    private appSettingsManageService: AppSettingsManageService,
    metaMapService: MetaMapService,
    modalService: ModalService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    changeDetector: ChangeDetectorRef,
    private bridgeService: EmployeeSectionBridgeService
  ) {
    super(decorator, ngZone);
    this.employeeSectionsAuditApiService = employeeSectionsAuditApiService;
    this.metaMapService = metaMapService;
    this.modalService = modalService;
    this.changeDetector = changeDetector;

    this.gridState = new KendoGridStateHelper<EmployeeSectionsAuditTrailRecord>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'changedOn', dir: 'desc' }];
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.loadSubsection();
    this.getAppSettings();
  }

  private async getAppSettings(): Promise<void> {
    try {
      this.appSettings = await this.appSettingsManageService.getAppServerConfig();
      console.log( this.appSettings.isNotesEnabled);
    } catch (e) { }
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsAuditTrail;
  }

  public checkCanDelete(record: EmployeeSectionsAuditTrailRecord): boolean {
    if (!this.employeeSectionsAuditTrail) { return false; }
    if (!record) { return false; }
    if (!this.employeeSectionsAuditTrail.actions.canDelete) { return false; }
    return (record instanceof EmployeeSectionsAuditTrailNoteRecord);
  }

  public checkCanEdit(record: EmployeeSectionsAuditTrailRecord): boolean {
    if (!this.employeeSectionsAuditTrail) { return false; }
    if (!record) { return false; }
    if (!this.employeeSectionsAuditTrail.actions.canEdit) { return false; }
    return (record instanceof EmployeeSectionsAuditTrailNoteRecord);
  }

  public onStartAdd(): void {
    let note: EmployeeSectionsAuditTrailNoteRecord = new EmployeeSectionsAuditTrailNoteRecord();
    note.subject = this.metaMapService.emptyFieldMeta<string>(null, 'subject');
    note.detail = this.metaMapService.emptyFieldMeta<string>(null, 'detail');
    note.isPublic = false;
    let options: DialogOptions = new DialogOptions();
    options.height = 350;
    options.width = 600;
    let resolvedProvidersConf: Provider[] = [
      { provide: DialogOptions, useValue: options },
      { provide: EmployeeSectionsAuditTrailNoteRecord, useValue: note },
    ];
    this.bridgeService.sectionsAreVisible = false;
    this.modalService.globalAnchor.openDialog(EmployeeSectionsNoteEditComponent, 'New note', options, resolvedProvidersConf, (result: boolean) => {
      if (result) {
        this.doAdd(note);
      }
    });
  }

  public onStartModify(note: EmployeeSectionsAuditTrailNoteRecord): void {
    Assert.isNotNull(note, 'EmployeeSectionsAuditTrailNoteRecord');
    let options: DialogOptions = new DialogOptions();
    options.height = 350;
    options.width = 600;
    let resolvedProvidersConf: Provider[] = [
      { provide: DialogOptions, useValue: options },
      { provide: EmployeeSectionsAuditTrailNoteRecord, useValue: note },
    ];
    this.modalService.globalAnchor.openDialog(EmployeeSectionsNoteEditComponent, 'Edit note', options, resolvedProvidersConf, (result: boolean) => {
      if (result) {
        this.doModify(note);
      }
    }
    );
  }

  public onStartRemove(note: EmployeeSectionsAuditTrailNoteRecord): void {
    Assert.isNotNull(note, 'EmployeeSectionsAuditTrailNoteRecord');
    ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure?', this.modalService, (result: boolean) => {
      if (result) {
        this.doRemove(note);
      }
    });
  }

  protected doAdd(note: EmployeeSectionsAuditTrailNoteRecord): void {
    Assert.isNotNull(note, 'EmployeeSectionsAuditTrailNoteRecord');
    this.startProgress();
    this.employeeSectionsAuditApiService.addAuditTrailNote(this.employeeId, note)
      .then((status: any) => {
        this.loadSubsection(() => (this.bridgeService.sectionsAreVisible = true));
      }).catch((error: any) => {
        this.loadSubsection();
      }) ;
  }

  protected doModify(note: EmployeeSectionsAuditTrailNoteRecord): void {
    Assert.isNotNull(note, 'EmployeeSectionsAuditTrailNoteRecord');
    this.startProgress();
    this.employeeSectionsAuditApiService.editAuditTrailNote(this.employeeId, note)
      .then((status: any) => {
        this.loadSubsection();
      })
      .catch((error: any) => {
        this.loadSubsection();
      });
  }

  protected doRemove(note: EmployeeSectionsAuditTrailNoteRecord): void {
    Assert.isNotNull(note, 'EmployeeSectionsAuditTrailNoteRecord');
    this.startProgress();
    this.employeeSectionsAuditApiService.deleteAuditTrailNote(this.employeeId, note.id)
      .then((status: any) => {
        this.loadSubsection();
      }).catch((error: any) => {
        this.loadSubsection();
      });
  }

  protected loadSubsection(callback?: () => void): void {
    if(!this.employeeSectionsAuditTrail) {
      return;
    }
    this.startProgress();
    this.employeeSectionsAuditApiService.getAuditTrail(this.employeeId)
      .then((employeeSectionsAuditTrail: EmployeeSectionsAuditTrail) => {
        this.employeeSectionsAuditTrail = employeeSectionsAuditTrail;
        this.refreshGrid();
        this.stopProgress();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        if (_.isFunction(callback)) callback();
      })
      .catch(() => {
        this.stopProgress();
      });
  }

  private refreshGrid(): void {
    if (!this.employeeSectionsAuditTrail) {
      this.gridView = null;
      return;
    }
    this.gridState.view = process(this.employeeSectionsAuditTrail.records, this.gridState.state);
  }
}


import {filter} from 'rxjs/operators';
import { LookupType } from './../../../../organization/models/lookup/lookup-definition';
import { OrgLevelWatchService } from './../../../../organization/services/org-level/org-level-watch.service';
import { AgencyScheduleNavigationService } from './../../../../common/services/navigation/agency-schedule-navigation.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Observable ,  Subscription } from 'rxjs';
import { AgencyDefinition, Lookup, ILookupRequest, EmployeeShortInfo, Organization, Department, Position } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { AgencyEmployeeApiService, AgencyEmployeeValidatorAdapter } from '../../../services/index';
import { AgencyEmployee, AddAgencyEmployeeReq, ValidateAgencyEmployeeReq } from '../../../models/index';
import { IServerValidationResult } from '../../../../common/validators/common-validators-models';
import { LookupService } from '../../../../organization/services/lookup/lookup.service';
import { AgenciesApiService } from '../../../services/index';
import { DetailScreenService } from '../../../../scheduler/services';
import { ISession } from '../../../../authentication/store/index';


@Component({
  moduleId: module.id,
  selector: 'slx-add-agency-employee',
  templateUrl: 'add-agency-employee.component.html',
  styleUrls: ['add-agency-employee.component.scss']
})
export class AddAgencyEmployeeComponent implements OnInit, OnDestroy {
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  public state: {
    isLoading: boolean;
    isAddingMode: boolean;
    isError: boolean;
    message?: string;
  };

  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  @unsubscribe()
  private userSubscription: Subscription;

  public selectedAgency: AgencyDefinition;
  public preSelectedEmployee: AgencyEmployee;
  public selectedEmployee: AgencyEmployee;
  public selectedEmployeePositions: Position[];
  public employeeLookup1: Lookup;
  public employeeLookup2: Lookup;
  public agencyDefinitionLookup: Lookup;
  public request: AddAgencyEmployeeReq;
  public validationResult: IServerValidationResult;

  public currentOrgLevel: OrgLevel;
  public organizationSelectorOrglevelId: number;
  public selectedOrgLevelId: number;
  public selectedDepartmentId: number;
  public departmentLookupRequest: ILookupRequest;
  public minWorkDate: Date;
  public agencyList: AgencyDefinition[];

  public showOrgStructureSelectors: boolean;
 
  public get getAgencyDefinitionLookup(): Lookup {
    return this.agencyDefinitionLookup;
  }

  public get isScheduled(): boolean {
    return ((this.request.shift && this.request.shift.id) && this.request.agencyEmployee && !!this.request.agencyEmployee.workDate);
  }

  private agencyName: string;
  private employeeLastName: string;
  private employeeFirstName: string;
  private firstNameField: string = 'employeeFirstName';
  private lastNameField: string = 'employeeLastName';
  private agencyNavService: AgencyScheduleNavigationService;
  private detailScreenService: DetailScreenService;
  private alias: string;
  private selectedPositionStartDate: Date;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  constructor(private apiService: AgencyEmployeeApiService, private validationAdapter: AgencyEmployeeValidatorAdapter,
    private activatedRoute: ActivatedRoute, private router: Router, private orglevelWatch: OrgLevelWatchService, private lookupService: LookupService,
    private agenciesApiService: AgenciesApiService, detailScreenService: DetailScreenService) {
    this.agencyNavService = new AgencyScheduleNavigationService(this.router, this.activatedRoute);
    this.detailScreenService = detailScreenService;
    this.startNewEmployee();
    this.state = {
      isLoading: false,
      isAddingMode: true,
      isError: false
    };
  }

  public async ngOnInit(): Promise<any> {
    this.userSubscription = this.user$.subscribe((session: ISession) => {
      if (session) {
        this.alias = session.alias;
      }
    });
    this.orgLevelSubscription = this.orgLevel$.pipe(
      filter((o: OrgLevel) => o && o.id && (!this.currentOrgLevel || this.currentOrgLevel.id !== o.id)))
      .subscribe((o: OrgLevel) => {
        if (o.type === OrgLevelType.department) {
          if (_.isNil(o.parentId)|| o.parentId == 0) {
            let userDepartmentsPromise: Promise<Lookup> = this.lookupService.getLookup({ lookupType: LookupType.userAccessibleDepartments, orgLevelId: o.id, updateCacheForced: true});
            let organizationsPromise: Promise<Lookup> = this.lookupService.getLookup({ lookupType: LookupType.organization });

            Promise.all([userDepartmentsPromise, organizationsPromise])
            .then((values: any[]) => {
              let userDepartments: Lookup<Department> = values[0];
              let organizations: Lookup<Organization> = values[1];

              const department = _.find(userDepartments.items, d => d.orgLevelId == o.id);
              const organization = _.find(organizations.items, o => o.id == department.parentOrganizationId);

              this.organizationSelectorOrglevelId = organization.orgLevelId;
              this.selectedDepartmentId = department.orgLevelId;
              this.showOrgStructureSelectors = true;
            });
          } else {
            this.organizationSelectorOrglevelId = o.parentId;
            this.selectedDepartmentId = o.id;
            this.showOrgStructureSelectors = true;
          }
        } else {
          this.organizationSelectorOrglevelId = o.id;
          this.selectedDepartmentId = undefined;
          this.showOrgStructureSelectors = true;
        }
        
      });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public agencySelected(agency: AgencyDefinition): void {
    this.setAgency(agency);
    if (!agency || !agency.id) {
      this.employeeLookup1 = this.employeeLookup2 = this.createEmpLookup([], '');
      this.preSelectedEmployee = null;
      this.selectedEmployee = null;
      this.setEmployee(new AgencyEmployee());
      return;
    }
    this.state.isLoading = true;
    this.apiService.getAgencyEmployees(agency.id)
      .then((emps: AgencyEmployee[]) => {
        this.state.isLoading = false;
        this.employeeLookup1 = this.createEmpLookup(emps, this.lastNameField);
      });
  }

  public changedAgencyName(agencyName: string): void {
    this.agencyName = agencyName;
  }

  public changedEmployeeLastName(name: string): void {
    if (!this.employeeLookup1) {
      return;
    }
    this.employeeLastName = name;
    const filtered: AgencyEmployee[] = _.filter(this.employeeLookup1.items, (emp: AgencyEmployee) => _.startsWith(emp.employeeLastName, this.employeeLastName));
    const fEmp = _.first(filtered);
    this.employeeLookup2 = this.createEmpLookup(filtered, this.firstNameField);
    if (fEmp && fEmp.employeeLastName === this.employeeLastName) {
      this.selectedEmployee = fEmp;
      this.employeeFirstName = fEmp.employeeFirstName;
      this.setEmployee(this.selectedEmployee);
    } else {
      this.selectedEmployee = null;
    }
  }

  public changedEmployeeFirstName(name: string): void {
    if (!this.employeeLookup2) {
      return;
    }
    this.employeeFirstName = name;
    const fEmp: AgencyEmployee = _.find(this.employeeLookup2.items, (emp: AgencyEmployee) => _.startsWith(emp.employeeFirstName, this.employeeFirstName));
    if (fEmp && fEmp.employeeFirstName === this.employeeFirstName) {
      this.selectedEmployee = fEmp;
      this.setEmployee(this.selectedEmployee);
    } else {
      this.createAndSetSelectedEmployee();
    }
    this.validateReq();
  }

  public async onOrganizationChanged(org: Organization): Promise<any> {
    this.request.department = null;
    this.request.position = null;
    this.request.shift = null;
    this.request.unit = null;
    this.selectedOrgLevelId = org.orgLevelId;
    this.departmentLookupRequest = { lookupType: 'userAccessibleDepartments', orgLevelId: this.selectedOrgLevelId, updateCacheForced: true};
    this.agencyList = await this.prepareAgencyList(1);
  }

  public departmentLookupApplied(lookup: Lookup): void {
    if (this.selectedDepartmentId) {
      this.request.department = _.find(lookup.items, (d: Department) => d.orgLevelId === this.selectedDepartmentId);
      if (this.request.department === undefined && lookup.items.length > 0) {
        this.request.department = lookup.items[0];
      }
    }
  }

  public onDepartmentChanged(dep: Department): void {
    this.request.position = null;
    this.request.shift = null;
    this.request.unit = null;
  }

  public onWorkdataChanged(): void {
    this.validateReq();
  }

  public onShiftChanged(): void {
    this.validateReq();
  }

  public onPositionChanged(pos: Position): void {
    if (this.request.position && !_.isEmpty(this.selectedEmployeePositions)) {
      const empPos: Position = _.find(this.selectedEmployeePositions, pos => pos.id === this.request.position.id);
      if (empPos) {
        this.selectedPositionStartDate = empPos.startDate;
      }
    }
    this.calcMinWorkDate();
  }

  public onStartDateChanged(): void {
    this.calcMinWorkDate();
  }

  private calcMinWorkDate(): void {
    if (this.selectedPositionStartDate && this.selectedPositionStartDate > this.request.agencyEmployee.startDate) {
      this.minWorkDate =  this.selectedPositionStartDate;
    } else {
      this.minWorkDate =  this.request.agencyEmployee.startDate || new Date();
    }
    if (!this.request.agencyEmployee.workDate || (this.request.agencyEmployee.workDate < this.minWorkDate)) {
      this.request.agencyEmployee.workDate = this.minWorkDate;
    }
  }

  public onAddClick(): void {
    this.state.isLoading = true;
    this.request.createAgency = false;
    this.request.createEmployee = false;
    this.state.message = undefined;
    if (!this.selectedAgency || !this.selectedAgency.id) {
      this.request.agencyEmployee.agency = { id: 0, name: this.agencyName, orgLevelId: 0 };
      this.request.createAgency = true;
    }
    if (!this.selectedEmployee || !this.selectedEmployee.employeeId) {
      this.request.agencyEmployee.employeeId = 0;
      this.request.agencyEmployee.employeeFirstName = this.employeeFirstName;
      this.request.agencyEmployee.employeeLastName = this.employeeLastName;
      this.request.createEmployee = true;
    }
    this.apiService.addAgencyEmployee(this.request)
      .then((emp: AgencyEmployee) => {
        this.request.agencyEmployee.badgeId = emp.badgeId;
        this.request.agencyEmployee.prefix = emp.prefix;
        this.request.agencyEmployee.employeeId = emp.employeeId;
        this.state.isLoading = false;
        this.state.isAddingMode = false;
        this.state.isError = false;
        this.state.message = emp.message;
        if (this.request.createAgency) {
          this.orglevelWatch.reloadOrgTree();
        }
      })
      .catch((res: any) => {
        this.state.isLoading = false;
        this.state.isAddingMode = false;
        this.state.isError = true;
      });
  }

  public onAddNewClick(): void {
    this.state.isAddingMode = true;
    this.state.isError = true;
    this.state.message = undefined;
    this.startNewEmployee();
  }

  public get selectedEmployeeExists(): boolean {
    return this.selectedEmployee && this.selectedEmployee.employeeId > 0;
  }

  private validateReq(): void {
    if (!this.request.agencyEmployee.workDate || !this.request.shift || !this.request.department) {
      return;
    }
    let req: ValidateAgencyEmployeeReq = new ValidateAgencyEmployeeReq();
    req.date = this.request.agencyEmployee.workDate;
    req.employeeId = this.request.agencyEmployee.employeeId;
    req.shiftId = this.request.shift.id;
    req.orgLevelId = this.request.department.orgLevelId;
    this.validationAdapter.validate(AgencyEmployeeValidatorAdapter.agencyEmployeeAddValidation, req)
      .then((value: IServerValidationResult) => {
        this.validationResult = value;
        this.validationResult.isReadyForValidation = true;
      }).catch((res: any) => {
        this.validationResult = { isValid: false, isReadyForValidation: true, errorMessage: 'During validation the error has occurred! Contact your system administrator.' };
      });
  }

  private createAndSetSelectedEmployee(): void {
    this.selectedEmployee = new AgencyEmployee();
    this.selectedEmployee.employeeFirstName = this.employeeFirstName;
    this.selectedEmployee.employeeLastName = this.employeeLastName;
    this.setEmployee(this.selectedEmployee);
  }


  private setAgency(agency: AgencyDefinition): void {
    this.request.agencyEmployee.agency.id = agency ? agency.id : undefined;
    this.request.agencyEmployee.agency.name = agency ? agency.name : null;
  }

  private setEmployee(emp: AgencyEmployee): void {
    this.selectedEmployeePositions = [];
    this.request.agencyEmployee.employeeId = emp.employeeId;
    this.request.agencyEmployee.employeeFirstName = emp.employeeFirstName;
    this.request.agencyEmployee.employeeLastName = emp.employeeLastName;
    this.request.agencyEmployee.prefix = emp.prefix;
    this.request.agencyEmployee.badgeId = emp.badgeId;
    this.request.agencyEmployee.email = emp.email;
    this.request.agencyEmployee.phone = emp.phone;
    this.request.agencyEmployee.startDate = emp.startDate;
    this.validateReq();

    if (emp.employeeId > 0) {
      this.state.isLoading = true;
      this.lookupService.getLookup({ lookupType: LookupType.position, employeeId: emp.employeeId, isActive: false, updateCacheForced: true}).then((value: Lookup<Position>) => {
        this.state.isLoading = false;
        this.selectedEmployeePositions = value.items;
      });
    }
  }

  private createEmpLookup(items: AgencyEmployee[], field: string): Lookup {
    let lookup: Lookup = new Lookup();
    lookup.titleField = field;
    lookup.valueField = 'employeeId';
    lookup.items = items;
    return lookup;
  }

  private startNewEmployee(): void {

    const prevRequest: AddAgencyEmployeeReq = this.request;

    this.request = new AddAgencyEmployeeReq();
    this.request.agencyEmployee = new AgencyEmployee();
    this.request.agencyEmployee.agency = new AgencyDefinition();
    this.request.agencyEmployee.workDate = new Date();
    this.request.agencyEmployee.startDate = new Date();
    this.minWorkDate = new Date();
    this.selectedAgency = null;
    this.preSelectedEmployee = null;
    this.selectedEmployee = null;
    this.employeeFirstName = null;
    this.employeeLastName = null;
    this.validationResult = null;

    if (prevRequest) {
      this.request.organization = prevRequest.organization;
      this.request.department =  prevRequest.department;
    }
  }

  private async prepareAgencyList(orgLevelId: number): Promise<AgencyDefinition[]> {
    if (this.request && this.request.organization) {
      var agencies = await this.agenciesApiService.getAgencies(orgLevelId);
      var filteredResults = agencies.filter(a => (a.organizations.findIndex(o => o.id == this.request.organization.id) > -1) );
      return _.map(filteredResults, a => {
        var agencyDef = new AgencyDefinition();
          agencyDef.id = a.agency.id;
          agencyDef.name = a.agency.name;
        return agencyDef;
      });
    }
  }

 
}

<form novalidate #form="ngForm">
  <div class="col-xs-12 col-sm-9">
    <div class="emp-title-box">
      <span class="theme-header-text">{{employeeFullName}}</span>
    </div>
  </div>
  <div class="col-sm-3">
  </div>
  <div class="col-xs-12">
    <div class="emp-text-box">
      <span class="theme-input-text">{{employee.organization}}/{{employee.department}}/{{employee.position}} </span>
    </div>
  </div>
  <div class="col-xs-12">
    <hr class="separator">
  </div>
  <div class="col-xs-12">
    <div class="emp-text-box">
      <span class="theme-input-text">Transfer To:</span>
    </div>
  </div>
  <div class="col-xs-12">
    <slx-input-decorator>
      <slx-dropdown-input slx-input [required]="true" [lookup]="{lookupType: 'userAccesibleOrganizations'}"
        name="organization" placeholder="Select a Center" [(ngModel)]="requestToTransfer.organization" (ngModelChange)="onOrganizationChanged($event)" emptyOption>
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
  </div>
  <div class="col-xs-12">
    <slx-input-decorator>
      <slx-dropdown-input slx-input [required]="true" [lookup]="departmentLookup" [readonly]="!departmentEditable" name="department"
        placeholder="Select a Department" [(ngModel)]="requestToTransfer.department" (ngModelChange)="onDepartmentChanged($event)" emptyOption>
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
  </div>
  <div class="col-xs-12">
    <slx-input-decorator>
      <slx-dropdown-input slx-input [prohibitedValues]="{values: positionProhibitedValues, valuePropertyName:'id'}" [required]="true"
        [lookup]="positionLookup" [readonly]="!positionEditable" name="position" placeholder="Position" [(ngModel)]="requestToTransfer.position" emptyOption>
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
      <span errorMessage for="prohibitedValues">You can not transfer employee to the same position</span>
    </slx-input-decorator>
  </div>
  <div class="col-xs-6 col-sm-6">
    <slx-input-decorator>
      <slx-dropdown-input slx-input [required]="true" [lookup]="shiftLookup" [readonly]="!shiftEditable" name="shift" placeholder="Preferred Shift" [(ngModel)]="requestToTransfer.shift" emptyOption>
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
  </div>
  <div class="col-xs-6 col-sm-6">
    <slx-input-decorator>
      <slx-dropdown-input slx-input [required]="true" [lookup]="unitLookup" [readonly]="!unitEditable" name="unit" placeholder="Preferred Unit"
        [(ngModel)]="requestToTransfer.unit" emptyOption>
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
  </div>
  <div class="col-xs-12" *ngIf="bswiftEnabled">
    <slx-input-decorator>
      <slx-dropdown-input slx-input [required]="true" [lookup]="benefitClassLookup" name="benefitclass" [readonly]="!benefitClassEditable"
        placeholder="Benefit Class" [(ngModel)]="requestToTransfer.benefitClass" emptyOption>
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
  </div>
  <div class="col-xs-12" *ngIf="bswiftEnabled">
    <slx-input-decorator>
      <slx-date-picker-ngx slx-input
        [(ngModel)]="requestToTransfer.benefitClassEffectiveDate"
        [minDate]="employee.hireDate"
        [required]="true"
        [readonly]="!benefitClassEditable"
        placeholder="Benefit Class Effective Date"
        name="date"
      ></slx-date-picker-ngx>
      <span errorMessage for="required"></span>
      <span errorMessage for="minDate">Benefit Class Effective Date cannot be before Hire Date</span>
    </slx-input-decorator>
  </div>
  <div class="col-xs-6 col-sm-6" *ngIf="!isNgpUser">
    <slx-input-decorator>
      <input slx-input type="text" 
       [(ngModel)]="requestToTransfer.payrollNumber"
       [server]="{ validationName: payrollNumberValidation, 
                   parameters: [requestToTransfer.organization?.orgLevelId, this.requestToTransfer.employeeId], 
                   validationAdapter: employeeActivitiesValidatorAdapter, 
                   componentName: 'payrollNumber', 
                   form: form }"
       [readonly]="PayrollReadOnly"
       placeholder="New Payroll No"
       name="payrollNumber">
      <span errorMessage for="required"></span>
      <span errorMessage for="min"></span>
      <span errorMessage for="max"></span>
      <span errorMessage [for]="payrollNumberValidation">msg from server</span>
    </slx-input-decorator>
  </div>
</form>

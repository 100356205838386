
import {combineLatest} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ReplaySubject ,  Subject ,  Observable ,  Subscription } from 'rxjs';


import { DateRange } from '../../../../core/models/index';
import { Assert } from '../../../../framework/index';

import { appConfig } from '../../../../app.config';
import { pbjConfig } from '../../pbj.config';

import { ManagementBaseService } from '../../../../core/services/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { OrgLevel, OrgLevelTypeDefinition } from '../../../../state-model/models/index';

import { OrgLevelWatchService } from '../../../../organization/services/index';
import { PbjNavigationService } from '../../../../common/services/index';
import { PbjReconciliationApiService } from './pbj-reconciliation-api.service';
import { EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { PbjReconciliationEmployee, PbjReconciliationOrglevel } from '../../models/index';
import { EmployeeShortInfo } from '../../../../organization/models/index';

import { PBJRecOrgEntry, PBJRecDepEntry, PBJRecEmpEntry, PBJRecDailyEntry } from '../../models/index';
import { ComponentStateStorageService, StateManagementService } from '../../../../common/services/index';
import { StateResetTypes } from '../../../../core/models/index';

@Injectable()
export class PbjReconciliationManagementService extends ManagementBaseService<any, any> {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  private expandedDetailsChanged$: ReplaySubject<boolean>;
  private orgLevelChanged$: ReplaySubject<OrgLevel>;
  private reconEmployeeChanged$: ReplaySubject<PbjReconciliationEmployee>;
  private reconOrglevelChanged$: ReplaySubject<PbjReconciliationOrglevel>;
  private resetBy: StateResetTypes = StateResetTypes.SessionEnd;
  private loadedOrgEntries$: Subject<PBJRecOrgEntry[]>;
  private loadedDepEntries$: Subject<{ orgLevelId: number, entries: PBJRecDepEntry[] }>;
  private loadedEmpEntries$: Subject<PBJRecEmpEntry[]>;
  private loadedDailyEntries$: Subject<PBJRecDailyEntry[]>;
  private exportToPdf$: Subject<null>;
  private exportToExcel$: Subject<null>;
  private pbjNavService: PbjNavigationService;
  private startDate: Date;
  private endDate: Date;
  private maxDaysRange: number;
  private dayInSec: number = 60 * 60 * 24;
  private componentId: string = 'PbjReconciliationComponent';
  private reportFrom: string = 'StartDate';
  private reportTo: string = 'EndDate';
  public quarter = Math.floor((new Date().getMonth() / 3));
  public passStartDate$: Subject<Date>;
  public passEndDate$: Subject<Date>;

  constructor(
    private apiService: PbjReconciliationApiService,
    private employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    private orgLevelWatchService: OrgLevelWatchService,
    private storageService: ComponentStateStorageService,
    private stateManagement: StateManagementService
  ) {
    super();
    this.maxDaysRange = pbjConfig.settings.reconciliation.maxDaysRange;
  }

  public destroy(): void {
    super.destroy();
    this.expandedDetailsChanged$.complete();
    this.orgLevelChanged$.complete();
    this.loadedOrgEntries$.complete();
    this.loadedDepEntries$.complete();
    this.loadedEmpEntries$.complete();
    this.loadedDailyEntries$.complete();
    this.reconEmployeeChanged$.complete();
    this.reconOrglevelChanged$.complete();
    this.exportToPdf$.complete();
    this.exportToExcel$.complete();
    this.passStartDate$.complete();
    this.passEndDate$.complete();
  }

  public init(router: Router, route: ActivatedRoute, expanded: boolean): void {
    this.pbjNavService = new PbjNavigationService(router, route);

    this.createEmitters();
    this.changeExpandedDetails(expanded);

    this.initSubscriptionToOrgLevel();
    var firstDate = new Date(new Date().getFullYear(), this.quarter * 3 - 3, 1);
    var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
    this.restoreDates(firstDate, endDate);
    this.initSubscriptionToParams(route);
  }

  public createEmitters(): void {
    this.expandedDetailsChanged$ = new ReplaySubject<boolean>(1);
    this.orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
    this.reconEmployeeChanged$ = new ReplaySubject<PbjReconciliationEmployee>(1);
    this.reconOrglevelChanged$ = new ReplaySubject<PbjReconciliationOrglevel>(1);

    this.loadedOrgEntries$ = new Subject<PBJRecOrgEntry[]>();
    this.loadedDepEntries$ = new Subject<{ orgLevelId: number, entries: PBJRecDepEntry[] }>();
    this.loadedEmpEntries$ = new Subject<PBJRecEmpEntry[]>();
    this.loadedDailyEntries$ = new Subject<PBJRecDailyEntry[]>();
    this.exportToPdf$ = new Subject<null>();
    this.exportToExcel$ = new Subject<null>();
    this.passStartDate$ = new Subject<Date>();
    this.passEndDate$ = new Subject<Date>();

    this.subscriptions = {};
    this.startDate = null;
    this.endDate = null;
  }

  public changeOrgLevel(orgLevelId: number, orgLevelType: OrgLevelTypeDefinition): void {
    if (_.isNumber(orgLevelId)) {
      const orgLevel: OrgLevel = this.orgLevelWatchService.getOrgLevelByRelatedItemId(orgLevelId, orgLevelType);
      if (orgLevel) {
        this.pbjNavService.NavigateToReconciliation(orgLevel.id, null, null);
      }
    }
  }

  public navigateToReconciliation(orgLevelId: number, sDate: Date, eDate: Date): void {
    this.pbjNavService.NavigateToReconciliation(orgLevelId, sDate, eDate);
  }

  public navigateToReconciliationEmployee(empId: number, sDate?: Date, eDate?: Date): void {
    this.pbjNavService.NavigateToReconciliationEmployee(empId, sDate, eDate);
  }

  public storeDates(sDate?: Date, eDate?: Date) {
    this.storageService.setControlState(this.componentId,
      this.reportFrom,
      { value: sDate },
      this.resetBy
    );
    
    this.storageService.setControlState(this.componentId,
      this.reportTo,
      { value: eDate },
      this.resetBy
    );
  }

  public changeExpandedDetails(isOn: boolean): void {
    this.expandedDetailsChanged$.next(isOn);
  }

  public exportToPdf(): void {
    this.exportToPdf$.next(null);
  }

  public subscribeToExportToPdf(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.exportToPdf$.subscribe(callback);
  }

  public exportToExcel(): void {
    this.exportToExcel$.next(null);
  }

  public subscribeToExportToExcel(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.exportToExcel$.subscribe(callback);
  }

  public subscribeToExpandedDetails(callback: (isOn: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.expandedDetailsChanged$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (orgLevel: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToLoadedOrgEntries(callback: (entries: PBJRecOrgEntry[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadedOrgEntries$.subscribe(callback);
  }

  public subscribeToLoadedDepEntries(callback: (data: { orgLevelId: number, entries: PBJRecDepEntry[] }) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadedDepEntries$.subscribe(callback);
  }

  public subscribeToLoadedEmpEntries(callback: (entries: PBJRecEmpEntry[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadedEmpEntries$.subscribe(callback);
  }

  public subscribeToLoadedDailyEntries(callback: (entries: PBJRecDailyEntry[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadedDailyEntries$.subscribe(callback);
  }

  public subscribeToReconEmployee(callback: (data: PbjReconciliationEmployee) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.reconEmployeeChanged$.subscribe(callback);
  }

  public subscribeToReconOrglevel(callback: (data: PbjReconciliationOrglevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.reconOrglevelChanged$.subscribe(callback);
  }

  public loadOrgEntries(orgLevelId: number, startDate: Date, endDate: Date): void {
    this.onLoadStatusChanged(true);
    this.apiService.getOrgEntries(orgLevelId, startDate, endDate)
      .then((entries: PBJRecOrgEntry[]) => {
        this.loadedOrgEntries$.next(entries);
        this.onLoadStatusChanged(false);
      }).catch((reason: any) => {
        this.onError(reason);
      });
  }

  public loadDepEntries(orgLevelId: number, startDate: Date, endDate: Date, isChangeLoadingState: boolean = true): void {
    if (isChangeLoadingState) this.onLoadStatusChanged(true);
    this.apiService.getDepEntries(orgLevelId, startDate, endDate)
      .then((entries: PBJRecDepEntry[]) => {
        this.loadedDepEntries$.next({ orgLevelId, entries });
        if (isChangeLoadingState) this.onLoadStatusChanged(false);
      }).catch((reason: any) => {
        this.onError(reason);
      });
  }

  public loadEmpEntries(orgLevelId: number, startDate: Date, endDate: Date): void {
    this.onLoadStatusChanged(true);
    this.apiService.getEmpEntries(orgLevelId, startDate, endDate)
      .then((entries: PBJRecEmpEntry[]) => {
        this.loadedEmpEntries$.next(entries);
        this.onLoadStatusChanged(false);
      }).catch((reason: any) => {
        this.onError(reason);
      });
  }

  public loadDailyEntries(orgLevelId: number,empId: number, startDate: Date, endDate: Date): void {
    this.onLoadStatusChanged(true);
    this.apiService.getDailyEntries(orgLevelId ,empId, startDate, endDate)
      .then((entries: PBJRecDailyEntry[]) => {
        this.loadedDailyEntries$.next(entries);
        this.onLoadStatusChanged(false);
      }).catch((reason: any) => {
        this.onError(reason);
      });
  }

  public async loadEmployeeInfo(employeeId: number): Promise<EmployeeShortInfo> {
    return await this.employeeDefinitionsApiService.getEmployeeShortInfo(employeeId);
  }

  private initSubscriptionToOrgLevel(): void {
    this.subscriptions.orgLevel = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if ( _.isNumber(_.get(orgLevel, 'id')) ) {
        this.orgLevelChanged$.next(orgLevel);
      }
    });
  }

  private initSubscriptionToParams(route: ActivatedRoute): void {
    this.subscriptions.params = route.params.pipe(
      combineLatest(route.queryParams))
      .subscribe(async ([params, queryParams]: [Params, Params]) => {
        const empId: number = +params.employeeId;
        const startDate = moment(route.snapshot.queryParams.startDate, appConfig.linkDateFormat).toDate();
        const endDate = moment(route.snapshot.queryParams.endDate, appConfig.linkDateFormat).toDate();
        if (_.isFinite(empId)) {
          const empInfo: EmployeeShortInfo = await this.loadEmployeeInfo(empId);
          this.reconEmployeeChanged$.next(new PbjReconciliationEmployee(empInfo.id, empInfo.name, startDate, endDate, this.maxDaysRange * this.dayInSec));
        } else {
          if (!this.startDate || !this.endDate || this.startDate.getTime() !== startDate.getTime() || this.endDate.getTime() !== endDate.getTime()) {
            this.startDate = startDate;
            this.endDate = endDate;
            this.reconOrglevelChanged$.next(new PbjReconciliationOrglevel(startDate, endDate, this.maxDaysRange * this.dayInSec));
          }
        }
      });
  }

  public restoreDates(startDate: Date, endDate: Date) {
    this.stateManagement.init(this.componentId, true);

    this.subscriptions.dates = this.stateManagement.onInit$
    .subscribe(() => {
      const state = this.storageService.getControlState(this.componentId, this.reportFrom);
      const value = state && state.value && _.isDate(new Date(state.value)) && moment(new Date(state.value), appConfig.linkDateFormat).toDate() > moment('1/1/1970', appConfig.linkDateFormat).toDate() ? new Date(state.value) : startDate;
      this.passStartDate$.next(value);

      const state1 = this.storageService.getControlState(this.componentId, this.reportTo);
      const value1 = state1 && state1.value && _.isDate(new Date(state1.value)) && moment(new Date(state1.value), appConfig.linkDateFormat).toDate() > moment('1/1/1970', appConfig.linkDateFormat).toDate() ? new Date(state1.value) : endDate;
      this.passEndDate$.next(value1);
    });
  }

  public subscribeToPassStartDate(callback: (data: Date) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.passStartDate$.subscribe(callback);
  }

  public subscribeToPassEndDate(callback: (data: Date) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.passEndDate$.subscribe(callback);
  }

}

export * from './dateTimeUtils';
export * from './screenUtils';
export * from './color-util';
export * from './domUtils';

import { forwardRef, TypeProvider } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor } from '@angular/forms';

export function createValuAccessor(componentType: TypeProvider): any {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => componentType),
    multi: true
  };
}

export function createValidators(componentType: TypeProvider): any {
  return {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => componentType),
    multi: true
  };
}

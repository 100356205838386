import * as _ from 'lodash';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ReplaySubject } from 'rxjs';
import { IBaseSelectableModel } from './base-selectable-model';

export class KendoGridCustomSelectionHelper<T extends IBaseSelectableModel> {

  public onSelectionChanged: ReplaySubject<T[]>;

  public get isAllSelected(): boolean {
    return this.m_isAllSelected;
  }

  public get selectionLength(): number {
    return this.selectedRecords.length;
  }

  public set view(value: GridDataResult) {
    this.m_view = value;
    this.clearSelection();
  }

  public get view(): GridDataResult {
    return this.m_view;
  }

  public get selection(): T[] {
    return this.selectedRecords;
  }

  public get singleItemMode(): boolean {
    return this.m_singleItemMode;
  }

  private m_isAllSelected: boolean = false;
  private m_view: GridDataResult;
  private m_singleItemMode: boolean;
  private selectedRecords: T[] = [];

  constructor(view: GridDataResult, singleItemMode: boolean) {
    this.onSelectionChanged = new ReplaySubject<T[]>();
    this.m_singleItemMode = singleItemMode;
    this.view = view;
  }

  public onToggleAllSelected(): void {
    this.m_isAllSelected = !this.m_isAllSelected;
    if (this.isAllSelected) {
      this.selectedRecords = _.filter(this.view.data, (item: T) => item.selectable);
    } else {
      this.selectedRecords = [];
    }
    this.updateAllItems(this.m_isAllSelected);
    this.onSelectionChanged.next(this.selectedRecords);
  }

  public onItemSelectionChange(item: T): void {
    let index: number = this.selectedRecords.indexOf(item);
    if (!this.m_singleItemMode) {
      if (index === -1) {
        item.isSelected = true;
        this.selectedRecords.push(item);
      } else {
        item.isSelected = false;
        this.selectedRecords.splice(index, 1);
      }
    } else {
      if (index === -1) {
        item.isSelected = true;
        this.selectedRecords = [item];
      } else {
        item.isSelected = false;
        this.selectedRecords = [];
      }
      this.deselectOthers(item);
    }

    this.m_isAllSelected = this.selectedRecords.length === this.view.data.length;
    this.onSelectionChanged.next(this.selectedRecords);
  }

  public clearSelection(): void {
    this.selectedRecords = [];
    this.m_isAllSelected = false;
    this.updateAllItems(false);
    this.onSelectionChanged.next(this.selectedRecords);
  }

  public getSelectedItemAt(index: number): T {
    if (this.selectionLength > 0 && index < this.selectionLength) {
      return this.selectedRecords[index];
    }
    return null;
  }

  public restoreSelection(items: T[]): void {
    this.selectedRecords = items;
    this.onSelectionChanged.next(this.selectedRecords);
  }

  private updateAllItems(selected: boolean): void {
    if (this.view && this.view.data) {
      _.each(this.view.data, (item: T) => {
        item.isSelected = item.selectable ? selected : false;
      });
    }
  }

  private deselectOthers(selectedItem: T): void {
    if (this.view && this.view.data) {
      _.each(this.view.data, (item: any) => {
        if (item !== selectedItem) {
          item.isSelected = false;
        }
      });
    }
  }
}

<form [formGroup]="formGroup">
  <div class="editor-container">

    <div class="field-container wrapped">

      <div class="col">

        <slx-input-decorator>
          <input slx-input type="text" formControlName="position" [required]="true" [readonly]="!isSystemLogin"
            placeholder="Position">
        </slx-input-decorator>


        <div class="control-group  dropdown-group ">
          <div class="col">
            <slx-input-decorator>
              <slx-dropdown-input class="form-control" slx-input placeholder="Position Group" name="positionGroup"
                formControlName="positionGroup" [options]="positionsGroupFiltered" titleField="name" valueField="id"
                [required]="false" emptyOption></slx-dropdown-input>
            </slx-input-decorator>
          </div>
          <button class="remove-button" (click)="removeValue ($event, 'positionGroup')">
            <div>
              <i aria-hidden="true" class="fa fa-times" slxTooltip="Clear value" tipPosition="left"></i>
            </div>
          </button>
        </div>
      </div>

      <div class="col">

        <div class="control-group dropdown-group">

          <div class="col">
            <slx-input-decorator>
              <input slx-input type="text" formControlName="clientDepartment" [required]="false"
                placeholder="Client Dept">
            </slx-input-decorator>
          </div>

          <div class="col">
            <slx-input-decorator>
              <input slx-input type="text" formControlName="jobClass" [required]="false"
                placeholder="Job Class">
            </slx-input-decorator>
          </div>

        </div>


        <div class="control-group dropdown-group">
          <div class="col">
            <slx-input-decorator>
              <slx-dropdown-input class="form-control" slx-input placeholder="Group With" name="groupedWith"
                formControlName="groupedWith" [options]="positionsFiltered" titleField="name" valueField="id"
                [required]="false" emptyOption></slx-dropdown-input>
            </slx-input-decorator>
          </div>
          <button class="remove-button" (click)="removeValue ($event, 'groupedWith')">
            <div>
              <i aria-hidden="true" class="fa fa-times" slxTooltip="Clear value" tipPosition="left"></i>
            </div>
          </button>
        </div>

      </div>

      <div class="col">

        <div class="control-group dropdown-group">
          <div class="col">
            <slx-input-decorator>
              <slx-dropdown-input class="form-control" slx-input placeholder="Budgeted Position" name="budgetedPosition"
                formControlName="budgetedPosition" lookup="budgetedPosition" [orgLevelId]="currentOrgLevel.id"
                [employeeId]="-1" [required]="false" titleField="description" emptyOption>
              </slx-dropdown-input>
            </slx-input-decorator>
          </div>
          <button class="remove-button" (click)="removeValue ($event, 'budgetedPosition')">
            <div>
              <i aria-hidden="true" class="fa fa-times" slxTooltip="Clear value" tipPosition="left"></i>
            </div>
          </button>
        </div>

        <div class="control-group dropdown-group">
          <div class="col">
            <slx-input-decorator>
              <slx-dropdown-input class="form-control" slx-input placeholder="CMS - PBJ Job Category"
                name="pbjCmsPosition" formControlName="pbjCmsPosition" [required]="false" lookup="pbjPositions"
                emptyOption></slx-dropdown-input>
            </slx-input-decorator>
          </div>
          <button class="remove-button" (click)="removeValue ($event, 'pbjCmsPosition')">
            <div>
              <i aria-hidden="true" class="fa fa-times" slxTooltip="Clear value" tipPosition="left"></i>
            </div>
          </button>
        </div>

      </div>
    </div>
    <div *ngIf="isSupervisorEnabled">
      <div class="additional-info-header">
        <div class="header-content">
          <span>Supervisor Assignment</span>
        </div>
      </div>
      <div class="field-container wrapped">
        <div class="col">
          <div class="control-group  dropdown-group ">
            <div class="col">
              <slx-input-decorator class="slx-input-long-text-dots">
                <slx-dropdown-input class="form-control" slx-input
                  [lookup]="{lookupType: 'userAccesibleOrganizationsCrossCorpActionCheck'}"
                  formControlName="supervisorOrganization" name="organization" placeholder="Facility" emptyOption
                  (ngModelChange)="loadDepartments( $event)">
                </slx-dropdown-input>
              </slx-input-decorator>
            </div>
            <button class="remove-button" (click)="removedropdownValue ('supervisorOrganization')">
              <div>
                <i aria-hidden="true" class="fa fa-times" slxTooltip="Clear value" tipPosition="left"></i>
              </div>
            </button>
          </div>
        </div>
        <div class="col">
          <slx-spinner [show]="isDepartmentLookupProgress">
            <div class="control-group  dropdown-group ">
              <div class="col">
                <slx-input-decorator class="slx-input-long-text-dots">
                  <slx-dropdown-input slx-input [lookup]="supervisorDepartmentLookup" class="form-control"
                    formControlName="supervisorDepartment" name="supervisorDepartment" placeholder="Department"
                    (ngModelChange)="onSupervisorDepartmentChange( $event)" emptyOption>
                  </slx-dropdown-input>
                </slx-input-decorator>
              </div>
              <button class="remove-button" (click)="removedropdownValue ('supervisorDepartment')">
                <div>
                  <i aria-hidden="true" class="fa fa-times" slxTooltip="Clear value" tipPosition="left"></i>
                </div>
              </button>
            </div>
          </slx-spinner>
        </div>
        <div class="col">
          <slx-spinner [show]="isPositionLookupProgress">
            <div class="control-group dropdown-group">
              <div class="col">
                <slx-input-decorator class="slx-input-long-text-dots">
                  <slx-dropdown-input slx-input [lookup]="supervisorPositionLookup" class="form-control"
                    formControlName="supervisorPosition" name="supervisorPosition" emptyOption placeholder="Position">
                  </slx-dropdown-input>
                </slx-input-decorator>
              </div>
              <button class="remove-button" (click)="removedropdownValue ('supervisorPosition')">
                <div>
                  <i aria-hidden="true" class="fa fa-times" slxTooltip="Clear value" tipPosition="left"></i>
                </div>
              </button>
            </div>
          </slx-spinner>
        </div>
      </div>
    </div>
    <div class="field-container wrapped">
      <div class="col">

        <div class="field-container wrapped" *ngIf="isCanReplaceEnabled">
          <div class="slx-label canReplace">
            <span>Can replace</span>
          </div>
        </div>

        <div class="field-container wrapped canReplaceOptions">
          <div class="col">
            <div class="control-group numeric-group left-aligner"
              [ngStyle]="{'display': canReplaceOptions.length ? 'inline' : 'none' }">
              <ng-container>
                <ng-container>
                  <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                    <slx-checkbox-input slx-input caption="All" (change)="selectedAllCanReplace()" fieldName="selectAll"
                      formControlName="selectAll">
                    </slx-checkbox-input>
                  </slx-input-decorator>
                </ng-container>
              </ng-container>
            </div>

            <div class="control-group grouping-box numeric-group" formGroupName="replacesPositionsIds"
              *ngIf="isCanReplaceEnabled">
              <ng-container *ngFor="let option of canReplaceOptions; let i = index">
                <ng-container>
                  <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                    <slx-checkbox-input slx-input [caption]="option.name" fieldName="position{{i}}"
                      formControlName="{{i}}" (change)="selectionChanged($event.target.checked, option.id)">
                    </slx-checkbox-input>
                  </slx-input-decorator>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="field-container wrapped">
      <div class="col">
        <div class="control-group numeric-group">
          <div class="col">
            <slx-input-decorator className="slx-form-group slx-no-border small-font">
              <slx-checkbox-input slx-input formControlName="isDirectCare" placeholder="Direct Care"
                fieldName="isDirectCare" className="reset-display">
              </slx-checkbox-input>
            </slx-input-decorator>
          </div>

          <div class="col">
            <slx-input-decorator>
              <input class="form-control" slx-input type="text" formControlName="objectAccount" [required]="false"
                placeholder="Object Account">
            </slx-input-decorator>
          </div>
          <div class="col">
            <slx-input-decorator>
              <input class="form-control" slx-input type="text" formControlName="businessUnit" [required]="false"
                placeholder="Business Unit">
            </slx-input-decorator>
          </div>
        </div>
      </div>

      <div class="col"></div>
      <div class="col"></div>

    </div>


    <div class="additional-info-header">
      <div class="header-content">
        <span>Additional Info</span>
      </div>
    </div>

    <div class="field-container wrapped additional-info">
      <div class="col">
        <slx-input-decorator>
          <input slx-input type="text" formControlName="eeocClass" placeholder="EEOC Class">
        </slx-input-decorator>
        <slx-input-decorator>
          <input slx-input type="text" formControlName="eeocGroup" placeholder="EEOC Group">
        </slx-input-decorator>
        <slx-input-decorator>
          <input slx-input type="text" formControlName="payType" placeholder="Pay Type">
        </slx-input-decorator>
        <slx-input-decorator>
          <input slx-input type="text" formControlName="payGrade" placeholder="Pay Grade">
        </slx-input-decorator>
      </div>
      <div class="col">
        <slx-input-decorator>
          <slx-kendo-number slx-input class="form-control" formControlName="minimumRate" placeholder="Minimum Rate"
            [decimals]="2" [min]="minRateLimit" [slxMin]="minRateLimit" [max]="maxRate" [slxMax]="maxRate"
            [autoCorrect]="true"></slx-kendo-number>
        </slx-input-decorator>
        <slx-input-decorator>
          <slx-kendo-number slx-input class="form-control" formControlName="maximumRate" placeholder="Maximum Rate"
            [decimals]="2" [min]="minRate" [slxMin]="minRate" [max]="maxRateLimit" [slxMax]="maxRateLimit"
            [autoCorrect]="true"></slx-kendo-number>
        </slx-input-decorator>
        <slx-input-decorator>
          <slx-kendo-number slx-input class="form-control" formControlName="perDiemRate" placeholder="Per Diem Rate"
            [decimals]="2" [min]="0" [slxMin]="0" [autoCorrect]="true"></slx-kendo-number>
        </slx-input-decorator>

        <div class="control-group dropdown-group" *ngIf="essTemplatesEnabled">
          <div class="col">
            <slx-input-decorator>
              <slx-dropdown-input class="form-control" slx-input placeholder="Go User Template" name="essTemplate"
                formControlName="essTemplate" [options]="essTemplates" [required]="false" titleField="name"
                valueField="id" emptyOption></slx-dropdown-input>
            </slx-input-decorator>
          </div>
        </div>

      </div>
      <div class="col">
        <slx-input-decorator>
          <input slx-input type="text" formControlName="occupationCode"
            placeholder="Occupation Code">
        </slx-input-decorator>
        <slx-input-decorator>
          <input slx-input type="text" formControlName="workersCompCode"
            placeholder="Workers' Comp Code" tabindex="15">
        </slx-input-decorator>
        <slx-input-decorator>
          <slx-kendo-number slx-input class="form-control" formControlName="fte" placeholder="FTE" 
            [decimals]="2" [min]="0" [slxMin]="0" [autoCorrect]="true"></slx-kendo-number>
        </slx-input-decorator>
      </div>
    </div>

    <div class="additional-info-header">
      <div class="header-content">
        <span>License Type</span>
      </div>
    </div>

    <div style="display: flex; padding: 5px;">
      <slx-radio-button formControlName="selectAllLicenseType" caption="All" name="radioLicenseTypeAll"
        [option]="'AND'" [(ngModel)]="operator"></slx-radio-button>
      <slx-radio-button formControlName="selectAnyLicenseType" caption="Any" name="radioLicenseTypeAny"
        [option]="'OR'" [(ngModel)]="operator"></slx-radio-button>
    </div>

    <div class="field-container wrapped additional-info">
      <div class="control-group dropdown-group">
        <div class="col">
          <div class="licenseType-container">
            <div class="action-bar">
              <div class="search-field">
                <div class="input-append">
                  <input formControlName="searchField" class="form-control users-search-input" type="text"
                    [(ngModel)]="searchText" placeholder="Filter by License...">
                </div>
              </div>
            </div>
            <div class="control-group grouping-box numeric-group" formGroupName="positionLicenseTypeSelection">
              <div *ngIf="isPositionLicenseTypeSelection">
                <ng-container *ngFor="let licenseType of positionLicenseTypesSelection | filter: searchText; let i = index;">
                  <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                    <slx-checkbox-input slx-input [caption]="licenseType.item.licenseTypeName"
                      formControlName="licenserest{{licenseType.item.licenseTypeID}}" fieldName="licenserest{{i}}"
                      (change)="selectionLicenseTypeChanged($event.target.checked, licenseType.item.licenseTypeID)"
                      [(ngModel)]="licenseType.selected">
                    </slx-checkbox-input>
                  </slx-input-decorator>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="actions-bar">
      <div class="col"></div>
      <button class="action-button save-btn" (click)="onSaveClick ($event)" [disabled]="formGroup.invalid">Save</button>
      <button class="action-button cancel-btn" (click)="onCancelClick ($event)">Cancel</button>
    </div>

  </div>
</form>

<div class="provider-info flex justify-content__space-between">
  <section class="slx-benefit-box provider flex__grow">
    <h5 class="slx-benefit-box__hd flex align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-info"></i>
      <span class="slx-benefit-box__text">Provider Info</span>
    </h5>
    <div class="slx-benefit-box__body">
      <form class="form-horizontal" #benefitsInfoForm="ngForm">
        <div class="slx-benefit-box__control flex align-items__center">
          <p class="slx-benefit-box__label provider__label">Provider Name</p>
          <p class="slx-benefit-box__value flex__grow">
            <input #provName="ngModel" type="text"
              class="slx-benefit-box__text-field"
              [(ngModel)]="providerName"
              [disabled]="!isEditMode"
              [server]="{ validationName: providerNameValidation, 
                          parameters: params, 
                          validationAdapter: benefitsValidatorAdapter, 
                          componentName: 'providerName', 
                          form: benefitsInfoForm }"
              name="providerName"
              maxlength="25"
              [slxMaxLength]="25"
              autocomplete="off"
              required
            />
            <span *ngIf="isEditMode && provName?.errors" class="slx-error-block error-box">
              <span *ngIf="provName?.errors?.required" errorMessage for="required"></span>
              <span *ngIf="provName?.errors?.slxMaxLength" errorMessage>Max length is 25 characters</span>
              <span *ngIf="provName?.errors?.providerNameValidation" errorMessage>Same benefit name exists. Please choose a different name</span>
            </span>
          </p>
        </div>
      </form>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label provider__label">Dependent Child Age Limit</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-number class="slx-wide"
            #ageField="ngModel"
            [slxMin]="min"
            [slxMax]="max"
            [step]="step"
            name="childAgeLimit"
            [autoCorrect]='false'
            [readonly]="!isEditMode"
            [(ngModel)]="childAgeLimit"
            (change)="onChangeChildAgeLimit()"
          ></slx-number>
          <span *ngIf="ageField.errors" class="slx-error-block error-box">
            <span *ngIf="ageField.errors.min" errorMessage for="min">should be greater than or equal {{min}}</span>
            <span *ngIf="ageField.errors.max" errorMessage for="max">should not exceed {{max}}</span>
          </span>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label provider__label">Include in Report</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-checkbox-button class="slx-no-indents"
            [(ngModel)]="isIncludeInReport"
            (ngModelChange)="onChangeIncludeInReport()"
            [readonly]="!isEditMode"
            name="isIncludeInReport"
          ></slx-checkbox-button>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center" *ngIf="showMappedDeduction">
        <p class="slx-benefit-box__label provider__label">Mapped Payroll Deduction</p>
        <p class="slx-benefit-box__value flex__grow">{{mappedDeductionName}}</p>
      </div>
    </div>
  </section>
  <section class="slx-benefit-box cost flex__grow">
    <h5 class="slx-benefit-box__hd flex align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-dollar-sign"></i>
      <span class="slx-benefit-box__text">Cost Settings</span>
    </h5>
    <div class="slx-benefit-box__body">
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label cost__label">Cost Frequency</p>
        <p class="slx-benefit-box__value flex__grow field__label">
          <slx-dropdown-list #cFreq="ngModel" class="slx-wide"
            valueField="id"
            titleField="name"
            [options]="config.freqCodes"
            [readonly]="!isEditMode"
            [(ngModel)]="costFreq"
            (ngModelChange)="onChangeCostFreq()"
            name="costFreq"
            required
          ></slx-dropdown-list>
          <span *ngIf="cFreq.errors && isEditMode" class="slx-error-block error-box">
            <span *ngIf="cFreq.errors.required" errorMessage for="required"></span>
          </span>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label cost__label">Employee Contribution Frequency</p>
        <p class="slx-benefit-box__value flex__grow field__label">
          <slx-dropdown-list #eFreq="ngModel" class="slx-wide"
            valueField="id"
            titleField="name"
            [options]="config.freqCodes"
            [readonly]="!isEditMode"
            [(ngModel)]="empFreq"
            (ngModelChange)="onChangeEmpFreq()"
            name="empFreq"
            required
          ></slx-dropdown-list>
          <span *ngIf="eFreq.errors && isEditMode" class="slx-error-block error-box">
            <span *ngIf="eFreq.errors.required" errorMessage for="required"></span>
          </span>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label cost__label">Calculation Method</p>
        <p class="slx-benefit-box__value flex__grow field__label align_left" [ngClass]="{ 'hide-tip': canHideCalcMethodToolTip}">
          <slx-dropdown-list #cMethod="ngModel" class="slx-wide"
                             valueField="id"
                             titleField="name"
                             [options]="group.calcMethods"
                             [readonly]="isCalcMethodReadOnly"
                             [(ngModel)]="calcMethod"
                             (ngModelChange)="onChangeCalcMethod()"
                             name="calcMethod"
                             required>
          </slx-dropdown-list>
          <span *ngIf="cMethod.errors && isEditMode" class="slx-error-block error-box">
            <span *ngIf="cMethod.errors.required" errorMessage for="required"></span>
          </span>
          <span [slxTooltip]="enrolledEmpTooltipText" ></span>
        </p>
      </div>
    </div>
  </section>
  <section class="slx-benefit-box coverage flex__grow">
    <h5 class="slx-benefit-box__hd flex align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-calendar-alt"></i>
      <span class="slx-benefit-box__text">Coverage Date</span>
    </h5>
    <div class="slx-benefit-box__body">
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label coverage__label">Plan Effective Start Date</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-datepicker #effStartDate="ngModel" class="slx-wide"
            [maxDate]="isEditMode? (effectiveEndDate || maxValidDate) : effectiveStartDate"
            [readonly]="!isDraftProvider"
            [(ngModel)]="effectiveStartDate"
            (ngModelChange)="onChangeStartDate()"
            [acceptNullDate]="false"
            [emitValueAnyway]="true"
            [required]="true"
            name="effectiveStartDate"
          ></slx-datepicker>
          <span *ngIf="effStartDate.errors" class="slx-error-block error-box">
            <span *ngIf="effStartDate.errors.required" errorMessage for="required"></span>
            <span *ngIf="effStartDate.errors.date" errorMessage>Date is out of valid range</span>
            <span *ngIf="effStartDate.errors.maxDate" errorMessage>Should be less than End Date</span>
          </span>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label coverage__label">Plan Effective End Date</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-datepicker #effEndDate="ngModel" class="slx-wide"
            [minDate]="effectiveStartDate"
            [maxDate]="maxValidDate"
            [readonly]="!isDraftProvider"
            [(ngModel)]="effectiveEndDate"
            (ngModelChange)="onChangeEndDate()"
            [acceptNullDate]="false"
            [emitValueAnyway]="true"
            [required]="true"
            name="effectiveEndDate"
          ></slx-datepicker>
          <span *ngIf="effEndDate.errors" class="slx-error-block error-box">
            <span *ngIf="effEndDate.errors.required" errorMessage for="required"></span>
            <span *ngIf="effEndDate.errors.date" errorMessage>Date is out of valid range</span>
            <span *ngIf="effEndDate.errors.minDate" errorMessage>Should be greater than Start Date</span>
          </span>
        </p>
      </div>
      <div class="slx-benefit-box__control flex align-items__center" *ngIf="!isBenefitDeductionEndabled">
        <p class="slx-benefit-box__label coverage__label">Payroll Deduction Date</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-datepicker #dedDate="ngModel" class="slx-wide"
            [maxDate]="maxValidDate"
            [readonly]="!isEditMode"
            [(ngModel)]="deductionDate"
            (ngModelChange)="onChangeDeductionDate()"
            [acceptNullDate]="true"
            [emitValueAnyway]="true"
            name="deductionDate"
          ></slx-datepicker>
          <span *ngIf="dedDate.errors" class="slx-error-block error-box">
            <span *ngIf="dedDate.errors.date" errorMessage>Date is out of valid range</span>
          </span>
        </p>
      </div>
    </div>
  </section>
</div>

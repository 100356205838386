import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();

  setData(data: any, isImport: boolean) {
    const combinedData = { data, isImport };
    this.dataSubject.next(combinedData);
  }

  setOrgLevelId(orgLevelId: number) {
    this.dataSubject.next({ orgLevelId });
  }
}
import { Provider } from '@angular/core';

export * from './wc-report-map.service';
export * from './wc-report-api.service';
export * from './wc-report-management.service';

import { WCReportMapService } from './wc-report-map.service';
import { WCReportApiService } from './wc-report-api.service';
import { WCReportManagementService } from './wc-report-management.service';

export const crudServices: Provider[] = [
    WCReportMapService,
    WCReportApiService,
    WCReportManagementService
];

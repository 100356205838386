import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataStateChangeEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { DailyOpenShiftDetailsData, DetailsAgencyModel, DetailsAgencyPosition, DetailsPartner } from '../../models';
import { ShiftRequestService } from '../../services/schedule/shift-request.service';
import { StateManagementService } from './../../../common/services/index';
import { DetailsListAgencyPosition } from '../../models/detail-screen/details-list-agency-position';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { AppSettingsManageService } from '../../../app-settings/services';
import { dateTimeUtils } from '../../../common/utils';
import { OrgLevel } from '../../../../app/state-model/models';
import { Observable, Subscription } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../app/core/decorators';
import { Element } from '@angular/compiler';


@Component({
  selector: 'slx-shift-request-grid',
  templateUrl: './shift-request-grid.component.html',
  styleUrls: ['./shift-request-grid.component.scss'],
  providers: [StateManagementService],
})
export class ShiftRequestGridComponent implements OnInit {
  public settings: DailyOpenShiftDetailsData;
  @Input()
  requestedDate: Date = new Date;


  @Input()
  currentOffset: number;

  @unsubscribe()
  public orgLevelSubscripion: Subscription;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  public orgLevel: OrgLevel;

  public storeSelectedDataArr: any = [];
  public selectedDisplayId: number[] = [];
  gridData: any = null;
  gridView: any = null;
  public state: State = {};
  public optionItemsSelected: boolean = false;

  public checkboxOnly = false;
  public mode = 'multiple';
  public drag = false;
  public previousGroups: any = [];
  public storeSelectedData: any = [];
  public OptionData: any;
  public partnerPositionData: DetailsAgencyPosition[];
  public partnerPositionMapData: Map<string, DetailsListAgencyPosition>;
  public Loadstate: {
    isLoading: boolean;
  };
  public selectableSettings: SelectableSettings = {
    checkboxOnly: this.checkboxOnly,
  };
  @ViewChild("expand") expandView: ElementRef;
  public records: any[] = [];
  public agencyDetailsData: DetailsPartner[];
  public displayId: number = 0;
  public isEditMode: boolean = false;

  public selectedValue: DetailsPartner;

  @Output() gridSelectionChangeHandler = new EventEmitter<any>();
  public serverUtcOffsetSec: number;

  constructor(private shiftRequestService: ShiftRequestService, private appSettingsManageService: AppSettingsManageService) {

    this.previousGroups = _.cloneDeep(this.previousGroups);
    this.Loadstate = {
      isLoading: false,
    };
    this.appSettingsManageService.getAppServerConfig()
      .then((appConfigData) => {
        this.serverUtcOffsetSec = appConfigData.serverUtcOffsetSec;
      });
  }

  public selectedItem: any;


  ngOnInit() {
    this.orgLevelSubscripion = this.orgLevel$.subscribe((selectedOrgLevel: OrgLevel) => {
      this.orgLevel = selectedOrgLevel;
    });
    this.agencyDetailsData = this.shiftRequestService.getAgencies();
    this.gridData = this.shiftRequestService.filterOpenShiftsData(this.shiftRequestService.getGridData());


    this.partnerPositionMapData = this.shiftRequestService.getPartnerPositionMapData();
    this.applyTableState(this.state);
    this.shiftRequestService.getDisplayId().subscribe((id) => {
      this.isEditMode = true;
    });
    this.shiftRequestService.getshiftChanges().subscribe((el) => {
      this.isEditMode = false;
    });

    this.shiftRequestService.updateShiftRequests().subscribe((addShift) => {
      if (addShift) {
        this.filterSelectedShift(addShift)
      }
      else {
        this.isEditMode = false;
        if (this.shiftRequestService.isrequestAgencyStaffCancelled) {
          this.records =  this.shiftRequestService.currentShiftRecords;
          let shift = this.shiftRequestService.shiftRecords.filter(e => e.displayId == this.shiftRequestService.deletedDisplayId);
          this.records.push(shift[0]);
          this.records.sort((a, b) => a.displayId < b.displayId ? -1 : 1);
          this.shiftRequestService.isrequestAgencyStaffCancelled = false;
          this.displayId = this.shiftRequestService.deletedDisplayId;
        }
        this.selectedDisplayId = [];
      }
      this.isEditMode = false;
      this.storeSelectedData = [];
    });
  }

  private applyTableState(state: State): void {
    this.Loadstate.isLoading = true;

    setTimeout(() => {
      this.Loadstate.isLoading = false;
      let shiftDetails: any[] = [];
      shiftDetails = this.shiftRequestService.filterGridData(this.gridData, this.agencyDetailsData)
      this.records = this.filterPassedShifts(shiftDetails)

      let displayId = 1;
      this.records.forEach((item) => {
        item.displayId = displayId++;
        item.Hours = parseFloat(item.Hours)
      });
      this.shiftRequestService.shiftRecords = this.records;
      this.refreshGrid();
    }, 300);
  }
  private refreshGrid(): void {
    
    this.gridView = process(this.records, this.state);
  }
  public filterPassedShifts(shiftDetails: any[]): any[] {
   // Shift grace period is 2 
    this.currentOffset = +_.get(this.orgLevel, 'location.timeZoneOffset') || 0;
    var utctime = moment.utc();
    let uniquecode = new Date().getTime();
    let appDateTime = utctime.utcOffset(this.currentOffset);
    let currentDateTime = moment(appDateTime).add(2, 'hours').format(appConfig.dateTimeFormat);
    let records: any[] = [];
    shiftDetails.forEach(element => {
      let shiftStartDate = moment(element.ShiftStart).toDate();
      if (moment(currentDateTime).isBefore(shiftStartDate)) {
        records.push(element);
      }
    });
    return records;

  }
  public filterSelectedShift(addShift: boolean) {
    if (this.selectedDisplayId.length > 0) {
      if (addShift) {
        const shifts = this.records.filter(ar =>
          !this.selectedDisplayId.find(rm => (rm === ar.displayId)))
        this.records = shifts;
      }
    }
    this.shiftRequestService.currentShiftRecords =  this.records;
    this.selectedDisplayId = [];
    this.refreshGrid();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refreshGrid();
  }

  public gridSelectedShift(selection) {
    if (selection.selectedRows.length !== 0) {
      selection.selectedRows.forEach((row) => {       
        this.storeSelectedData.push(row.dataItem);
      });
    }
    if (selection.deselectedRows.length !== 0) {
      selection.deselectedRows.forEach((row) => {
        this.storeSelectedData = this.storeSelectedData.filter((e) => e.displayId !== row.dataItem.displayId);
      });
    }
    this.gridSelectionChangeHandler.emit(this.storeSelectedData);
  }

  public isDisabledShift(dataItem): boolean {
    if (this.isEditMode) {
      return true
    }
    else {
      return false;
    }
  }
}
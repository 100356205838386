import { Directive, HostListener, Input, Provider } from '@angular/core';

import { ModalRef } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/index';

import {
  EmployeeSectionsContext
} from '../../models/index';
import { EmployeeCallLogComponent } from '../../components/index';
import { Subscription } from 'rxjs';

@Directive({
  /* tslint:disable */
  selector: '[employeeCallLogButton]',
  /* tslint:enable */
})
export class EmployeeCallLogButtonDirective {
  @Input()
  public employeeCallLogButton: number;

  private modalService: ModalService;
  constructor(modalService: ModalService) {
    this.modalService = modalService;
  }
  @HostListener('click')
  public onClick(): void {

    let empContext: EmployeeSectionsContext = new EmployeeSectionsContext(this.employeeCallLogButton);
    let resolvedProvidersConf: Provider[] = [
      { provide: EmployeeSectionsContext, useValue: empContext }
    ];
    let ref: ModalRef<EmployeeCallLogComponent> = this.modalService.globalAnchor.open(
      EmployeeCallLogComponent,
      'Call Log',
      resolvedProvidersConf
    );

    let win: EmployeeCallLogComponent = ref.reference;
    let closeSubscription: Subscription = win.onClose.subscribe(() => {
      this.modalService.closeWindow(ref.uniqueId);
      closeSubscription.unsubscribe();
    });
  }
}

import { InfoPreloaderDialogComponent } from './../../common/components/info-preloader-dialog/info-preloader-dialog.component';
import { InfoPreloaderParams } from './../../common/models/info-preloader-model/info-preloader-params';
import { Component, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { unsubscribe, destroyService } from '../../core/decorators/index';
import { ReportsManagementService } from '../services/index';
import { ReportGroup, ReportDefinition } from '../models/index';
import { Observable ,  Subscription } from 'rxjs';
import { FileBlobResponse } from '../../core/models/api/file-blob-response';
import { NotificationsService } from '../../core/components/index';
import { FileService, ModalService } from '../../common/services/index';
import { Assert } from '../../framework/index';

@Component({
  moduleId: module.id,
  selector: 'slx-reports',
  templateUrl: 'reports.component.html',
  styleUrls: ['reports.component.scss'],
  providers: [ReportsManagementService],
})
export class ReportsComponent implements OnDestroy {
  public state: {
    isLoading: boolean;
  };

  public get reportGroups(): ReportGroup[] {
    return this.m_reportGroups;
  }

  // private reportData: FileBlobResponse;
  private m_reportGroups: ReportGroup[] = [];
  @unsubscribe()
  private loadStartedSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private reportGeneratedSubscription: Subscription;
  @unsubscribe()
  private reportGenerationStartedSubscription: Subscription;
  @unsubscribe()
  private errorOccuredSubscription: Subscription;
  @unsubscribe()
  private saveConfigSettingSubscription: Subscription;
  @destroyService()
  private reportsManagementService: ReportsManagementService;

  private generateReportPreloader: InfoPreloaderDialogComponent;
  private ignoreNextReportGenerated: boolean;

  constructor(
    reportsManagementService: ReportsManagementService,
    private fileService: FileService,
    private modalService: ModalService,
    private notificationService: NotificationsService
  ) {
    this.reportsManagementService = reportsManagementService;
    this.state = {
      isLoading: false,
    };

    this.loadStartedSubscription = this.reportsManagementService.onReportsLoadStarted$.subscribe(() => {
      this.state.isLoading = true;
    });

    this.saveConfigSettingSubscription = this.reportsManagementService.onReportConfigSettingSave$.subscribe(
      (isLoading: boolean) => {
        this.state.isLoading = isLoading;
      }
    );

    this.reportGenerationStartedSubscription = this.reportsManagementService.onReportGenerationStarted$.subscribe(
      (report: ReportDefinition) => {
        //this.state.isLoading = true;
        Assert.isNotNull(report, 'report');
        let selectedReport: ReportDefinition = this.reportsManagementService.selectReport(report);

        let params: InfoPreloaderParams = new InfoPreloaderParams();
        params.buttonLabel = 'Cancel Report';
        params.title = 'Running Report';
        params.firstMessage = 'Please be patient as the: ';
        params.styleMessage = selectedReport.displayName;
        params.lastMessage = ' is being run. When the report is complete - it will open in a new window';

        this.generateReportPreloader = InfoPreloaderDialogComponent.openDialog(
          params,
          this.modalService,
          (result: boolean): void => {
            if (!result) {
              // report canceled
              this.ignoreNextReportGenerated = true;
            }
          }
        );
      }
    );

    this.loadedSubscription = this.reportsManagementService.onReportsLoaded$.subscribe((groups: ReportGroup[]) => {
      this.m_reportGroups = groups;
      this.state.isLoading = false;
    });

    this.errorOccuredSubscription = this.reportsManagementService.onErrorOccured$.subscribe((error: any): void => {
      this.state.isLoading = false;
      this.ignoreNextReportGenerated = false;

      if (this.generateReportPreloader) this.generateReportPreloader.onCancel();

      let errorMessage: string = null;
      if (error.error && error.error.status === 0) {
        errorMessage = 'Report execution timeout has occured.';
      } else {
        errorMessage = !!error ? error.message : 'Internal error has occured.';
      }
      this.notificationService.error(errorMessage);
      throw new Error(errorMessage);
    });

    this.reportGeneratedSubscription = this.reportsManagementService.onReportGenerated$.subscribe(
      (data: FileBlobResponse): void => {
        if (this.ignoreNextReportGenerated) {
          this.ignoreNextReportGenerated = false;
          return;
        }
        if (data) {
          this.fileService.saveToFileSystem(data.blob, data.file);
        }
        this.generateReportPreloader.onOk();
        this.state.isLoading = false;
      }
    );
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}

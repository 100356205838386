import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AttributesModel, DetailsAgencyModel, DetailsPartner, DetailsShiftRequest } from '../../../../scheduler/models';
import { ShiftRequestService } from '../../../services/schedule/shift-request.service';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { ISession } from '../../../../authentication/store/index';
import { Observable } from 'rxjs';
import {
  DetailScreenService,
} from '../../../services/index';
import * as _ from 'lodash';

@Component({
  selector: 'slx-partner-agency-details',
  templateUrl: './partner-agency-details.component.html',
  styleUrls: ['./partner-agency-details.component.scss']
})
export class PartnerAgencyDetailsComponent implements OnInit {
  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  @unsubscribe()
  private userSubscription: Subscription;
  @Input()
  public requestId: number;

  @Input()
  public agencyMap: any;

  private detailScreenService: DetailScreenService;
  public alias: string;
  public agencyDetailsData: DetailsPartner[];
  public agencyDetailsList: DetailsAgencyModel[];
  public agencyAttributesList: DetailsAgencyModel[];
  public loader: boolean = false;
  public attributes: AttributesModel[] = [];
  public incentivePay: boolean = true;
  public validIncentivePay: any[] = [];
  serval: boolean=false;
  constructor(private shiftRequestService: ShiftRequestService, detailScreenService: DetailScreenService) {
    this.detailScreenService = detailScreenService;
  }


  ngOnInit() {
    this.userSubscription = this.user$.subscribe((session: ISession) => {
      if (session) {
        this.alias = session.alias;
      }
    });
    this.agencyDetailsData = this.shiftRequestService.getAgencies();
    this.agencyDetailsList = this.shiftRequestService.setPartnerModelData(this.agencyDetailsData);
    if (this.agencyDetailsList.length > 0) {
      this.getRequestDetails();
    }
  }

  getRequestDetails() {
    this.loader = true;
    this.detailScreenService.getShiftRequestDetails(this.requestId, this.alias).then((response: DetailsShiftRequest[]) => {
      if (response.length > 0 && this.agencyDetailsList.length > 0) {
        this.agencyAttributesList = [];
        this.agencyDetailsList.forEach(ele => {
          response.forEach(attrItem => {
            if (ele.partnerId == attrItem.partnerId) {
              ele.name = this.agencyMap[ele.agencyId];
              ele.shiftStatus = attrItem.shiftStatus;
              ele.attributes.forEach(item => {
                attrItem.attributes.forEach(attr => {

                  if (item.name == attr.name) {
                    item.attrValues = attr.attrValues;
                    item.value = attr.value;
                    if(item.name=="instantAcceptManual"||item.name=="instantAcceptManual")
                    this.serval=true;
                  }
                });
              });
              this.agencyAttributesList.push(ele);
            }
          });
        });
      }
      this.updateCommonAttributes();
      this.loader = false;
    });
    
  }
  public updateCommonAttributes() {
    this.agencyAttributesList.forEach(partner => {
      let attr = _.cloneDeep(partner.attributes);
      attr.forEach(element => {
        if (element.name == 'incentivePay')
          if (element.attrValues[0].name == 'No') {
            this.validIncentivePay[partner.partnerId] = false;
          }
          else {
            this.validIncentivePay[partner.partnerId] = true;
          }
      });

    });
  
    this.agencyAttributesList.forEach(partner => {
      let attr = _.cloneDeep(partner.attributes);
      this.attributes = Object.assign(attr);
      this.attributes = partner.attributes.filter(e => e.name != "incentivepay_details_input")
      this.attributes = partner.attributes.filter(e => e.name != "incentivepay_details_percent")
    });
    
  }

  public verifyCommonAttributes(name: string) {

    let attr = this.attributes.filter(e => e.name == name);



    if (attr.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }
  public isInstanctAccept(name: string) {
    if (name == "instant_accept") {
      return true;
    }
    else {
      return false;
    }
  }
  public onPanelChange(partner: DetailsAgencyModel) {
    let expand = partner.partnerExpanded;
    partner.partnerExpanded = !expand;
  }
  public getDisplayName(name: string) {
    if (name == 'incentivePay') {
      return 'Incentive Pay';
    }
    else if (name == 'instantAccept') {
      return 'Instant Accept';
    }
    else if (name == "infectious") {
      return 'Infectious';
    }
    else if (name == 'premiumPay') {
      return 'Premuim Pay ';
    }
    else if(name =="incentivepayAmount" || name == "incentivepayPercent" ){
      return 'Incentive Pay Details';
    }
  }
  public isIncentiveDetails(partnerId) {
    return this.validIncentivePay[partnerId]
  }
}

import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { mutableSelect } from '../../../../../../core/decorators/index';
import { Observable } from 'rxjs';
import { AddEmployeeWizardActions } from '../../../../store/index';
import { AddEmployeeModel, AddEmployeeRestrictions } from '../../../../models/index';
import { AddEmployeeBasicComponent } from '../add-employee/add-employee-basic.component';
import { EmployeeValidatorAdapter } from '../../../../../../organization/services/index';
import { Department, INamesValidation, Organization, Position } from '../../../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../../../state-model/models/index';
import { IServerValidationResult } from '../../../../../../common/validators/common-validators-models';
import * as _ from 'lodash';
import { LookupApiService } from '../../../../../../organization/services/lookup/lookup-api.service';
import { NgForm } from '@angular/forms';


@Component({
  moduleId: module.id,
  selector: 'slx-add-employee-payroll',
  templateUrl: 'add-employee-payroll.component.html',
  styleUrls: ['add-employee-payroll.component.scss']
})
export class AddEmployeePayrollComponent extends AddEmployeeBasicComponent implements OnInit, OnDestroy, AfterViewInit {
  @mutableSelect(['addEmployeeWizard', 'model'])
  public addEmployeeWizard$: Observable<AddEmployeeModel>;
  @Input() public currentOrgLevel: OrgLevel;
  @Input() public addEmployeeRestrictions: AddEmployeeRestrictions;
  @Output() public onSelectOrganization: EventEmitter<AddEmployeeModel>;
  @ViewChild('payrollForm', { static: true }) mainForm: NgForm;

  @Input('organizationLookup')
  public set orgLookup(organizationLookup: Organization[]) {
    this.organizationLookup = organizationLookup;
    this.setPredefinedLookups();
  }

  public organizationLookup: Organization[];
  public departmentLookup: Department[];
  public positionLookup: Position[];

  public get canSelectPosition(): boolean {
    if (!this.addEmployeeModel) return false;
    if (!this.addEmployeeModel.organization) return false;
    if (!this.addEmployeeModel.department) return false;
    return true;
  }

  public state: {
    isDepartmentsLoading: boolean;
    isPositionsLoading: boolean;
  };

  public get isLoading(): boolean {
    return this.state.isDepartmentsLoading || this.state.isPositionsLoading;
  }
  public namesValidation: IServerValidationResult;
  private employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter;
  private lastOrganization: Organization;
  private lookupApiService: LookupApiService;
  private storedOrg: Organization;
  private storedDep: Department;

  constructor(addEmployeeWizardActions: AddEmployeeWizardActions, lookupApiService: LookupApiService, employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter) {
    super(addEmployeeWizardActions);
    this.lookupApiService = lookupApiService;
    this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
    this.onSelectOrganization = new EventEmitter<AddEmployeeModel>();
    this.state = {
      isDepartmentsLoading: false,
      isPositionsLoading: false,
    };
    this.namesValidation = { isValid: true, isReadyForValidation: false, errorMessage: null };
  }

  public ngOnInit(): void {
    this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe((model: AddEmployeeModel) => {
      this.onWizard(model);
      this.setPredefinedLookups();
      this.validateNames();
    });
  }

  public ngAfterViewInit(): void {
    this.addChangesSubscriptionToForm(this.mainForm);
  }

  public setPredefinedLookups(): void {
    if (!this.addEmployeeModel || !this.organizationLookup || !this.currentOrgLevel) return;
    this.setPredefinedOrganizationOrgLevel();
  }

  public setPredefinedOrganizationOrgLevel(): void {
    if (!this.addEmployeeModel.organization) {
      if (this.currentOrgLevel.type === OrgLevelType.organization) {
        this.addEmployeeModel.organization = _.find(this.organizationLookup, (organization: Organization) => {
          return organization.orgLevelId === this.currentOrgLevel.id;
        });
      } else if (this.currentOrgLevel.type === OrgLevelType.department) {
        this.addEmployeeModel.organization = _.find(this.organizationLookup, (organization: Organization) => {
          return organization.orgLevelId === this.currentOrgLevel.parentId;
        });
      }
      if (!this.addEmployeeModel.organization) {
        this.addEmployeeModel.organization = _.head(this.organizationLookup);
      }
    }
    this.storedOrg = this.addEmployeeModel.organization;
    if (this.addEmployeeModel.organization) {
      this.refreshPolicy();
    }
    this.loadDepartments()
      .then(() => this.loadPositions());
  }

  public setPredefinedDepartmentOrgLevel(): void {
    if (!this.addEmployeeModel.department) {
      if (this.currentOrgLevel.type === OrgLevelType.department) {
        this.addEmployeeModel.department = _.find(this.departmentLookup, (department: Department) => {
          return department.orgLevelId === this.currentOrgLevel.id;
        });
      }
      if (!this.addEmployeeModel.department) {
        this.addEmployeeModel.department = _.first(this.departmentLookup);
      }
    }
    this.storedDep = this.addEmployeeModel.department;
  }

  public setPredefinedPositionOrgLevel(): void {
    if (!this.addEmployeeModel.position) {
      this.addEmployeeModel.position = _.first(this.positionLookup);
    }
  }

  public onOrganizationChanged(org: Organization): void {
    const orgId: number = _.get(this.storedOrg, 'id') || -1;
    if (orgId !== org.id) {
      this.storedOrg = org;
      this.addEmployeeModel.department = null;
      this.addEmployeeModel.position = null;
      this.addEmployeeModel.shiftDiffPolicy = null;
      this.addEmployeeModel.payPolicy = null;
      this.loadDepartments()
        .then(() => this.loadPositions());
      this.refreshPolicy();
    }
  }

  public onDepartmentChanged(dep: Department): void {
    const depId: number = _.get(this.storedDep, 'id') || -1;
    if (depId !== dep.id) {
      this.storedDep = dep;
      this.addEmployeeModel.position = null;
      this.addEmployeeModel.shiftDiffPolicy = null;
      this.addEmployeeModel.payPolicy = null;
      this.loadPositions();
      this.validateNames();
    }
  }

  public validateNames(): void {
    let namesData: INamesValidation = {
      firstName: this.addEmployeeModel.firstName,
      lastName: this.addEmployeeModel.lastName,
      middleName: this.addEmployeeModel.middleName,
      suffix: this.addEmployeeModel.suffix,
      departmentOrgLevelId: this.addEmployeeModel.department ? this.addEmployeeModel.department.orgLevelId : null
    };
    this.employeeActivitiesValidatorAdapter.validate(EmployeeValidatorAdapter.namesValidation, null, namesData).
      then((result: IServerValidationResult) => {
        this.namesValidation = result;
      })
      .catch(() => {
        this.namesValidation = { isValid: false, isReadyForValidation: true, errorMessage: 'Server validation error!' };
      });

  }

  private loadDepartments(): Promise<any> {
    this.state.isDepartmentsLoading = true;
    return this.lookupApiService.getDepartments(undefined, this.addEmployeeModel.organization.orgLevelId)
      .then((departmentLookup: Department[]) => {
        this.departmentLookup = departmentLookup;
        this.setPredefinedDepartmentOrgLevel();
        this.state.isDepartmentsLoading = false;
      })
      .catch((res: any) => {
        this.state.isDepartmentsLoading = false;
      });
  }

  private loadPositions(): void {
    this.state.isPositionsLoading = true;
    this.lookupApiService.getPositions(undefined, this.addEmployeeModel.department.orgLevelId)
      .then((positionLookup: Position[]) => {
        this.positionLookup = positionLookup;
        this.setPredefinedPositionOrgLevel();
        this.state.isPositionsLoading = false;
      })
      .catch((res: any) => {
        this.state.isPositionsLoading = false;
      });
  }

  private refreshPolicy(): void {
    this.onSelectOrganization.emit(this.addEmployeeModel);
  }

}

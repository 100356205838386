
import {timer as observableTimer,  Subject ,  Subscription ,  Observable } from 'rxjs';

import {debounceTime} from 'rxjs/operators';
import { Component, Input, Inject, ElementRef, ContentChildren, QueryList, OnDestroy, AfterContentInit, HostListener, Optional, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { appConfig } from '../../../app.config';


import { select } from '@angular-redux/store';

import { unsubscribe } from '../../../core/decorators/index';
import { ToolbarBaseService, TOOLBAR_SERVICE } from '../../../core/services/index';
import { ToolbarSectionTemplateDirective } from '../../directives/toolbar/index';
import { ToolbarSectionTypes } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-toolbar',
  templateUrl: 'toolbar.component.html',
  styleUrls: ['toolbar.component.scss']
})
export class ToolbarComponent implements AfterContentInit, OnDestroy {

  @Input()
  public alignExpandButtonRight: boolean;
  @Input()
  public isDuaComponent: boolean;
  public visibleSections: ToolbarSectionTemplateDirective[];
  public collapsedSections: ToolbarSectionTemplateDirective[];
  public rightSections: ToolbarSectionTemplateDirective[];
  public isExpanded: boolean;
  public counts: StringMap<number>;

  @Input()
  public customPopperContentClass: string;

  @select(['sidebar', 'isLeftSidebarOpen'])
  public leftSidebarState$: Observable<boolean>;

  @ContentChildren(ToolbarSectionTemplateDirective,{descendants: true})
  private contentChildren: QueryList<ToolbarSectionTemplateDirective>;

  private nativeElement: HTMLElement;
  private recalc$: Subject<any>;

  @unsubscribe()
  private debouncedSubscription: Subscription;

  @unsubscribe()
  private leftSidebarSubscripion: Subscription;
  @unsubscribe()
  private externalRecalcSubscripion: Subscription;

  constructor(private elementRef: ElementRef, @Inject(TOOLBAR_SERVICE) @Optional() private toolbarService: ToolbarBaseService<any>,
  private changeDetector: ChangeDetectorRef) {
    let subscription: Subscription = null;
    const period: number = 300;
    const animationDuration: number = 1000;

    this.nativeElement = elementRef.nativeElement;
    this.recalc$ = new Subject();
    this.debouncedSubscription = this.recalc$.pipe(debounceTime(period)).subscribe(() => {
      this.debouncedRecalc();
    });
    this.leftSidebarSubscripion = this.leftSidebarState$.subscribe((value: boolean) => {
      if (subscription) subscription.unsubscribe();
      subscription = observableTimer(period, period)
        .subscribe((val) => {
          this.recalc();
          if (val === +(animationDuration / period).toFixed(0)) {
            subscription.unsubscribe();
            subscription = null;
          }
        });
    });
    if (this.toolbarService) {
    this.externalRecalcSubscripion = this.toolbarService.onRecalcToolbar$.subscribe(() => {
      this.recalc();
    });
    }

    this.counts = this.createCounts();
    this.visibleSections = [];
    this.collapsedSections = [];
    this.rightSections = [];
  }

  public isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }

  public isCollapsedSection(sectionType: ToolbarSectionTypes): boolean {
    return sectionType === ToolbarSectionTypes.COLLAPSED_BY_DEFAULT || sectionType === ToolbarSectionTypes.COLLAPSED_BY_RESIZE;
  }

  public ngAfterContentInit(): void {
    this.recalc();
  }

  public showCollapsed(): void {
    this.isExpanded = true;
  }

  public hideCollapsed(): void {
    this.isExpanded = false;
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent): void {
    this.recalc();
  }

  private recalc(): void {
    this.recalc$.next();
  }

  private countAsRight(item: ToolbarSectionTemplateDirective): boolean {
    return item.alignMode === 'right'
      || (!this.isMobile() && item.alignMode === 'rightOnlyDesktop');
  }
  private expandButtonWidth(willBeCollapsed?: boolean): number {
    if (this.collapsedSections.length > 0 || willBeCollapsed) {
      return 100;
    }
    return 0;
  }

  private debouncedRecalc(): void {
    if(!this.visibleSections || !this.collapsedSections || !this.rightSections) {
      return;
    }
    const counts: StringMap<number> = this.createCounts();
    this.visibleSections.length = this.collapsedSections.length = this.rightSections.length = 0;
    let width = this.contentChildren.reduce((accumulator: number, item: ToolbarSectionTemplateDirective) => {
      if (this.countAsRight(item)) accumulator += +item.width || 0;
      return accumulator;
    }, 0);
    let projectedWidth = this.contentChildren.reduce((accumulator: number, item: ToolbarSectionTemplateDirective) => {
      accumulator += +item.width || 0;
      return accumulator;
    }, 0);
    const remainsWidth = this.nativeElement.offsetWidth - projectedWidth - this.expandButtonWidth();
    const directives = this.contentChildren.toArray();
    const orderedDirectives = _.orderBy(directives, (item: ToolbarSectionTemplateDirective) => {
      if (this.countAsRight(item)) {
        return 0;
      }
      if (item.alignMode === 'left') {
        return 1;
      }
      if (item.alignMode === 'leftWithPriorityIfNotEnoughPlace') {
        return remainsWidth > 0 ? 1 : 0.5;
      }
      if (item.alignMode === 'rightIfEnoughPlace') {
        return 2;
      }
      if (item.alignMode === 'rightIfNothingCollapsed') {
        return 3;
      }
      return 1;
    });
    let rightIfNothingCollapsedItems: ToolbarSectionTemplateDirective[] = [];
    let rightIfNothingCollapsedItemsWidth: number = 0;
    _.forEach(orderedDirectives, (item: ToolbarSectionTemplateDirective) => {
      if (this.countAsRight(item)) {
        item.sectionType = ToolbarSectionTypes.RIGHT;
        counts[item.sectionType]++;
        this.rightSections.push(item);
      } else if (!item.collapsed && item.alignMode === 'rightIfNothingCollapsed') {
        rightIfNothingCollapsedItemsWidth += item.width;
        rightIfNothingCollapsedItems.push(item);
      } else {
        if (item.collapsed) {
          item.sectionType = ToolbarSectionTypes.COLLAPSED_BY_DEFAULT;
          counts[item.sectionType]++;
          this.collapsedSections.push(item);
        } else {
          width += item.width;
          if (width < (this.nativeElement.offsetWidth - this.expandButtonWidth(true))) {
            item.sectionType = ToolbarSectionTypes.VISIBLE;
            counts[item.sectionType]++;
            if (item.alignMode === 'leftWithPriorityIfNotEnoughPlace') {
              this.visibleSections.push(item);
            }
            if (item.alignMode === 'left') {
            this.visibleSections.push(item);
            }
            if (item.alignMode === 'rightIfEnoughPlace') {
              this.rightSections.push(item);
            }
          } else {
            item.sectionType = ToolbarSectionTypes.COLLAPSED_BY_RESIZE;
            counts[item.sectionType]++;
            this.collapsedSections.push(item);
          }
        }
      }
    });

    if (this.collapsedSections.length === 0 && rightIfNothingCollapsedItemsWidth < this.nativeElement.offsetWidth - width - this.expandButtonWidth()) {
      _.forEach(rightIfNothingCollapsedItems, (item: ToolbarSectionTemplateDirective) => {
        item.sectionType = ToolbarSectionTypes.RIGHT;
        counts[item.sectionType]++;
        this.rightSections.push(item);
      });
    } else {
      _.forEach(rightIfNothingCollapsedItems, (item: ToolbarSectionTemplateDirective) => {
        width += item.width;
        if (width < (this.nativeElement.offsetWidth - this.expandButtonWidth(true))) {
          item.sectionType = ToolbarSectionTypes.VISIBLE;
          counts[item.sectionType]++;
          this.visibleSections.push(item);
        } else {
          item.sectionType = ToolbarSectionTypes.COLLAPSED_BY_RESIZE;
          counts[item.sectionType]++;
          this.collapsedSections.push(item);
        }
      });
    }
    this.counts = counts;
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private createCounts(): StringMap<number> {
    return {
      [ToolbarSectionTypes.VISIBLE]: 0,
      [ToolbarSectionTypes.COLLAPSED_BY_DEFAULT]: 0,
      [ToolbarSectionTypes.COLLAPSED_BY_RESIZE]: 0,
      [ToolbarSectionTypes.RIGHT]: 0
    };
  }

  get setLeftWidth(){    
    return this.alignExpandButtonRight && (this.collapsedSections && this.collapsedSections.length>0) && this.isDuaComponent ? 'dua-responsive-left-width'  : 'left-full-width'
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MomentModule } from 'angular2-moment';

import { components, entryComponents } from './components/index';
import { services } from './services/index';
import { routes } from './reports.routes';
import { CommonModule as CommonAppModule } from '../common/index';
import { rootEpics } from '../state-model/state-model.module';
import { EmployeeModule } from '../employee/employee/index';
import { EmployeeSectionsModule } from '../employee/employee-sections/index';
import { OrganizationModule } from '../organization/index';
import { ComponentsLibraryModule } from '../components-library/index';
import { directives } from './directives/index';
import { NgxPopperModule } from 'ngx-popper';
import { DecimalReportParameterComponent } from './components/report-parameters/decimal-report-parameter/decimal-report-parameter.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { RnHrsMinWarningDIalogComponent } from './components/report-parameters/rn-hrs-min-warning-dialog/rn-hrs-min-warning-dialog.component';
import { MidnightToMidnightReportDialogComponent } from './components/midnight-to-midnight-report-dialog/midnight-to-midnight-report-dialog.component';
import { MidnightToMidnightReportComponent } from './components/midnight-to-midnight-report-dialog/midnight-to-midnight-report/midnight-to-midnight-report.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        OrganizationModule,
        CommonAppModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        DropDownsModule,
        EmployeeModule,
        MomentModule,
        ComponentsLibraryModule,
        NgxPopperModule,
        InputsModule
    ],
    declarations: [
        ...components, ...directives, DecimalReportParameterComponent, RnHrsMinWarningDIalogComponent, MidnightToMidnightReportDialogComponent, MidnightToMidnightReportComponent
    ],
    providers: [
        ...services
    ],
    exports: [
        components
    ]
})
export class ReportsModule {
}

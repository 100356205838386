import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Subject ,  Subscription } from 'rxjs';

import { Assert } from '../../../../framework/index';
import { unsubscribeAll } from '../../../../core/decorators/index';

@Injectable()
export class EmployeeSectionsBenefitsCommonService {
  private updateDependents$ = new Subject<void>();
  private spinner$ = new Subject<boolean>();

  public subscribeToUpdateDependents(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.updateDependents$.subscribe(callback);
  }

  public subscribeToDependentsSpinner(callback: (isShown: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.spinner$.subscribe(callback);
  }

  public updateDependents(): void {
    this.updateDependents$.next();
  }

  public toggleDependentsSpinner(isShown: boolean): void {
    this.spinner$.next(isShown);
  }
}

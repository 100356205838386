import { Provider } from '@angular/core';

export * from './local-storage/local-storage.service';
export * from './jwt/jwt.service';
export * from './api/api.service';
export * from './session/session.service';
export * from './auth-api/auth-api.service';
export * from './logger/logger.service';
export * from './breadcrumb/breadcrumb.service';
export * from './breadcrumb/breadcrumb-state.service';
export * from './calendar-data/calendar-data.service';
export * from './meta/meta-map.service';
export * from './management/management-base.service';
export * from './error-handling/error-handling-api.service';
export * from './error-handling/error-handling.service';
export * from './api-watch/api-watch.service';
export * from './server-filter/server-filter.service';
export * from './polling-period/polling-period.service';
export * from './user/user.service';
export * from './user-settings/user-settings.service';
export * from './toolbar/toolbar-base.service';
export * from './throttling/throttling.service';
export * from './token-validity/token-validity.service';
export * from './previous-route/previous-route-service';

import { LocalStorageService } from './local-storage/local-storage.service';
import { JwtService } from './jwt/jwt.service';
import { ApiService } from './api/api.service';
import { SessionService } from './session/session.service';
import { AuthApiService } from './auth-api/auth-api.service';
import { LoggerService } from './logger/logger.service';
import { BreadcrumbStateService } from './breadcrumb/breadcrumb-state.service';
import { CalendarDataService } from './calendar-data/calendar-data.service';
import { MetaMapService } from './meta/meta-map.service';
import { ErrorHandlingApiService } from './error-handling/error-handling-api.service';
import { ErrorHandlingService } from './error-handling/error-handling.service';
import { ApiWatchService } from './api-watch/api-watch.service';
import { UserActivityService } from './user-activity/user-activity.service';
import { UserService } from './user/user.service';
import { UserSettingsService } from './user-settings/user-settings.service';
import { ThrottlingService } from './throttling/throttling.service';
import { TokenValidityService } from './token-validity/token-validity.service';
import { PreviousRouteService } from './previous-route/previous-route-service';

export const coreServices: Provider[] = [
  LocalStorageService,
  JwtService,
  ApiService,
  SessionService,
  AuthApiService,
  LoggerService,
  BreadcrumbStateService,
  CalendarDataService,
  MetaMapService,
  ErrorHandlingApiService,
  ErrorHandlingService,
  ApiWatchService,
  ThrottlingService,
  UserActivityService,
  UserService,
  UserSettingsService,
  TokenValidityService,
  PreviousRouteService
];

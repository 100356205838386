import { OrgLevel } from './../../../state-model/models/org-level/org-level';
import { Injectable, Inject } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Observable ,  Subscription ,  ReplaySubject ,  Subject } from 'rxjs';

import { ModalService } from '../../../common/services/modal/modal.service';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import { ChangeManagementService } from '../../../common/services/change-management/change-management.service';
import { ChangesDialogOptions } from '../../../common/models/index';
import { IndividualScheduleApiService } from '../individual-schedule/individual-schedule-api.service';
import { ScheduleMapService } from './schedule-map.service';
import { MasterScheduleManagementService } from './master-schedule-management.service';
import { MasterScheduleQuickEditPromptDialogComponent } from '../../components/master-shedule/master-schedule-quick-edit-prompt-dialog/master-schedule-quick-edit-prompt-dialog.component';
import { EmployeesStateSelector } from '../../store/selectors/index';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../app-settings/services/index';
import { OrgLevelWatchService, LookupApiService } from '../../../organization/services/index';
import { NightShiftSetting } from '../../../app-settings/model/night-shift-setting';

import {
  ScheduleEntryDefinition, EmployeeDefinition, ScheduledShiftDefinition,
  Position, LocationUnit, ScheduleAbsence, ConstraintDefinition, ScheduleCycleMessages,
  ShiftDefinition, ShiftGroupOrder, Organization
} from '../../../organization/models/index';
import {
  MasterScheduleEntryRow, MasterScheduleEntryCell, MasterScheduleShiftInfo, ScheduleEntryEditItem, ScheduleQuickEditConfiguration,
  QuickEditOvelapDecisionRequest, QuickEditOvelapDecision, QuickEditOvelapStatus, QuickEditOvelapState, QuickEditOvelap, QuickEditOvelapDecisionState,
  ScheduleEntryEditShift
} from '../../models/index';
import { Exception } from '@microsoft/applicationinsights-web';

export interface IQuickEditStatusEvent {
  isActive: boolean;
  isStarted: boolean;
  isConfigurationError: boolean;
}
@Injectable()
export class MasterScheduleQuickEditService {
  public quickEditUndo$: Subject<ScheduleEntryEditItem[]>;
  public quickEditListChanged$: Subject<ScheduleEntryEditItem[]>;
  public quickEditStatusChanged$: Subject<IQuickEditStatusEvent>;
  public quickSaveResult$: Subject<ScheduleCycleMessages>;
  public appSettings: AppServerConfig;

  private items: ScheduleEntryEditItem[] = [];
  public last10Changes: ScheduleEntryEditItem[] = [];
  private configuration: ScheduleQuickEditConfiguration;
  private readonly changeGroupId = 'quickEdit';
  private readonly maxQuickEditCount = 100;
  private currentStatus: IQuickEditStatusEvent;
  private organizations: Organization[];
  private departmentOrglevelId: number;
  private isNightShiftFirstShiftForOrganization: boolean;


  constructor(
    private individualScheduleApiService: IndividualScheduleApiService,
    private scheduleMapService: ScheduleMapService,
    private masterScheduleManagementService: MasterScheduleManagementService,
    private changeManagementService: ChangeManagementService,
    private modalService: ModalService,
    private appSettingsService: AppSettingsManageService,
    private orgLevelWatchService: OrgLevelWatchService,
    private lookupApiService: LookupApiService) {
    this.quickEditListChanged$ = new Subject();
    this.quickEditStatusChanged$ = new Subject();
    this.quickSaveResult$ = new Subject();
    this.quickEditUndo$ = new Subject();
    this.currentStatus = { isActive: false, isStarted: false, isConfigurationError: false };
    this.getAppSettings();
  }

  public async setEditConfiguration(configuration: ScheduleQuickEditConfiguration, hasErrors: boolean): Promise<any> {
    this.configuration = configuration;
    this.currentStatus.isConfigurationError = hasErrors;

    await this.obtainNightShiftFirstShiftForOrg(configuration);

    this.quickEditStatusChanged(this.currentStatus);
  }

  public getOverlapDecision(row: MasterScheduleEntryRow, cell: MasterScheduleEntryCell, shiftInfo: MasterScheduleShiftInfo): Promise<QuickEditOvelapDecisionState> {
    const res = new QuickEditOvelapDecisionState();
    res.overlap = this.getOvelapState(row, cell, shiftInfo);
    if (res.overlap.canReplace && (this.configuration.replaceAlways)) {
      res.decision = QuickEditOvelapDecision.Replace;
      return Promise.resolve(res);
    }

    if(this.CheckOverlapShiftsNotExists(res.overlap.currentDayState) && this.CheckOverlapShiftsNotExists(res.overlap.nextDayState) && this.CheckOverlapShiftsNotExists(res.overlap.prevDayState)){
      res.decision = QuickEditOvelapDecision.Append;
      return Promise.resolve(res);
    }

    const req = new QuickEditOvelapDecisionRequest();
    req.overlap = res.overlap;
    req.canCancel = true;
    let promise: Promise<QuickEditOvelapDecisionState> = new Promise((resolve, reject) => {
      MasterScheduleQuickEditPromptDialogComponent.openDialog(req, this.modalService, (result: QuickEditOvelapDecision) => {
        res.decision = result;
        resolve(res);
      });
    });
    return promise;
  }

  private CheckOverlapShiftsNotExists(dayShifts : QuickEditOvelapState){
    return dayShifts.leftOverlapShifts.length === 0 && dayShifts.rightOverlapShifts.length === 0 && dayShifts.status === 0;
  }

  public quickEditCommand(modalService: ModalService, row: MasterScheduleEntryRow, existCell: MasterScheduleEntryCell): Promise<void> {
    _.remove(this.items, (item: ScheduleEntryEditItem) => _.includes(this.last10Changes, item));
    this.last10Changes = [];
    if (this.items.length > this.maxQuickEditCount) {
      return new Promise((resolve, reject) => {
        this.modalService.globalAnchor.openInfoDialog('Information', `You can not edit more then ${this.maxQuickEditCount} items!`,
          (result: boolean) => {
            resolve();
          });
      });
    }
    if (!existCell.shiftsInfo || existCell.shiftsInfo.length === 0) {
      existCell.shiftsInfo = [];
    }

    if (existCell.isInQuickEdit) {
      let item: ScheduleEntryEditItem = _.find(this.items, (i: ScheduleEntryEditItem) => {
        return moment(i.date).isSame(existCell.dateOn) && i.employeeId === row.id;
      });
      if (item) {
        //remove prev quick edit changes
        let dateOn: string = EmployeesStateSelector.getDateKey(item.date);
        existCell = row.cell[dateOn] = _.cloneDeep(item.cellForUndo);
      }
    }
    const shiftInfo: MasterScheduleShiftInfo = this.createShiftInfo(row, existCell);
    return this.getOverlapDecision(row, existCell, shiftInfo)
      .then((decision: QuickEditOvelapDecisionState) => {
        if (decision.decision === QuickEditOvelapDecision.Cancel) {
          this.quickEditListChanged$.next(this.items);
          return;
        }
        this.applyShiftChanges(decision, row, existCell, shiftInfo);
        this.changeManagementService.changeNotify(this.changeGroupId);
        this.masterScheduleManagementService.calculateDataRow(row);
        if (shiftInfo.absenceCode) {
          existCell.hasAbsence = true;
        }
        existCell.clearNames();
        this.quickEditListChanged$.next(this.items);
      });
  }

  public applyShiftChanges(
    decision: QuickEditOvelapDecisionState,
    row: MasterScheduleEntryRow,
    cell: MasterScheduleEntryCell,
    shiftInfo: MasterScheduleShiftInfo): void {

    let item: ScheduleEntryEditItem = _.find(this.items, (i: ScheduleEntryEditItem) => {
      return moment(i.date).isSame(cell.dateOn) && i.employeeId === row.id;
    });
    if (!item) {
      item = new ScheduleEntryEditItem();
      this.items.unshift(item);
    }

    item.date = cell.dateOn;
    item.employeeId = row.id;
    item.employeeName = row.name;
    item.shift = shiftInfo;
    item.shiftsToModify = [];

    if (!cell.isInQuickEdit) {
      item.cellForUndo = _.cloneDeep(cell);
      cell.isInQuickEdit = true;
    }

    switch (decision.decision) {
      case QuickEditOvelapDecision.Replace:
        item.shiftsToRemove = _.map(cell.shiftsInfo);
        cell.shiftsInfo = [shiftInfo];
        break;
      case QuickEditOvelapDecision.Append:
        this.makeAppend(row, cell, shiftInfo, decision.overlap);
        break;
      case QuickEditOvelapDecision.Override:
        item.shiftsToModify = this.makeOverride(row, cell, shiftInfo, decision.overlap);
        break;
      case QuickEditOvelapDecision.Cancel:
        break;
      default:
        throw new Error('unknown overlap decision');
    }
  }

  public makeAppend(
    row: MasterScheduleEntryRow,
    cell: MasterScheduleEntryCell,
    shiftInfo: MasterScheduleShiftInfo, overlap: QuickEditOvelap): void {
    if (overlap.prevDayState.status !== QuickEditOvelapStatus.NoOverlap) {
      const lastPrevSchedule = _.maxBy(overlap.prevDayState.rightOverlapShifts, (s: MasterScheduleShiftInfo) => moment(s.shiftEnd).unix());
      shiftInfo.shiftStart = moment(lastPrevSchedule.shiftEnd).add(1, 's').toDate();
    }
    if (overlap.nextDayState.status !== QuickEditOvelapStatus.NoOverlap) {
      const firstNextSchedule = _.minBy(overlap.nextDayState.leftOverlapShifts, (s: MasterScheduleShiftInfo) => moment(s.shiftStart).unix());
      shiftInfo.shiftEnd = moment(firstNextSchedule.shiftStart).subtract(1, 's').toDate();
    }
    if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapLeft) {
      const lastLeftSchedule = _.maxBy(overlap.currentDayState.leftOverlapShifts, (s: MasterScheduleShiftInfo) => moment(s.shiftEnd).unix());
      shiftInfo.shiftStart = moment(lastLeftSchedule.shiftEnd).add(1, 's').toDate();
    }
    if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapRight) {
      const firstRightSchedule = _.minBy(overlap.currentDayState.rightOverlapShifts, (s: MasterScheduleShiftInfo) => moment(s.shiftStart).unix());
      shiftInfo.shiftEnd = moment(firstRightSchedule.shiftStart).subtract(1, 's').toDate();
    }
    shiftInfo.duration = moment(shiftInfo.shiftEnd).diff(shiftInfo.shiftStart) - shiftInfo.lunchDuration;

    cell.shiftsInfo = [...cell.shiftsInfo, shiftInfo];
  }

  public makeOverride(
    row: MasterScheduleEntryRow,
    cell: MasterScheduleEntryCell,
    shiftInfo: MasterScheduleShiftInfo, overlap: QuickEditOvelap): ScheduleEntryEditShift[] {
    const changedShifts: ScheduleEntryEditShift[] = [];

    if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapLeft) {
      _.forEach(overlap.currentDayState.leftOverlapShifts, (s: MasterScheduleShiftInfo) => {
        const ch = new ScheduleEntryEditShift();
        ch.scheduledShift = _.cloneDeep(s);
        ch.endDate = s.shiftEnd = moment(shiftInfo.shiftStart).subtract(1, 's').toDate();
        ch.startDate = s.shiftStart;
        ch.duration = moment(ch.endDate).diff(ch.startDate);
        changedShifts.push(ch);
      });
    }
    if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapRight) {
      _.forEach(overlap.currentDayState.leftOverlapShifts, (s: MasterScheduleShiftInfo) => {
        const ch = new ScheduleEntryEditShift();
        ch.scheduledShift = _.cloneDeep(s);
        ch.endDate = s.shiftEnd;
        ch.startDate = s.shiftStart = moment(shiftInfo.shiftEnd).subtract(1, 's').toDate();
        ch.duration = moment(ch.endDate).diff(ch.startDate);
        changedShifts.push(ch);

      });
    }
    cell.shiftsInfo = [...cell.shiftsInfo, shiftInfo];
    return changedShifts;
  }

  public undoEditCommand(items: ScheduleEntryEditItem[]): void {
    this.quickEditUndo$.next(items);
    _.remove(this.items, (item: ScheduleEntryEditItem) => _.includes(items, item));
    if (this.items.length === 0) {
      this.changeManagementService.clearChanges(this.changeGroupId);
    }
    this.quickEditListChanged$.next(this.items);
  }

  public undoEditCommandFromId(itemId: number, dateOn: Date): void {
    let item: ScheduleEntryEditItem = _.find(this.items, (i: ScheduleEntryEditItem) => {
      return moment(i.date).isSame(dateOn) && i.employeeId === itemId;
    });
    if (!item) {
      return;
    }
    this.undoEditCommand([item]);
  }

  public saveChanges(): void {
    this.masterScheduleManagementService.onLoadStatusChanged(true);
    const req = this.scheduleMapService.mapQuickEditToRequest(this.items);
    this.individualScheduleApiService.saveScheduleDefinitions(req)
      .then((res: ScheduleCycleMessages) => {
        this.masterScheduleManagementService.onLoadStatusChanged(false);
        this.quickSaveResult$.next(res);
        this.changeManagementService.clearChanges(this.changeGroupId);
        this.deactivateQuickEdit();
      }).catch((err) => {
        this.masterScheduleManagementService.onLoadStatusChanged(false);
        this.changeManagementService.clearChanges(this.changeGroupId);
        this.deactivateQuickEdit();
        throw err;
      });
  }

  public quickEditStatusChanged(status: IQuickEditStatusEvent): void {
    this.quickEditStatusChanged$.next(status);
  }

  public activateQuickEdit(): void {
    this.items = [];
    this.currentStatus.isActive = true;
    this.quickEditStatusChanged(this.currentStatus);
  }

  public deactivateQuickEdit(): void {
    this.canClose().then((res: boolean) => {
      if (res) {
        this.undoEditCommand(this.items);
        this.items = [];
        this.currentStatus.isActive = false;
        this.currentStatus.isStarted = false;
        this.changeManagementService.clearChanges(this.changeGroupId);
        this.quickEditStatusChanged(this.currentStatus);
      }
    });
  }
  public startQuickEdit(): void {
    this.currentStatus.isActive = true;
    this.currentStatus.isStarted = true;
    this.quickEditStatusChanged(this.currentStatus);
  }
  public stopQuickEdit(): void {
    this.saveChanges();
    this.currentStatus.isActive = true;
    this.currentStatus.isStarted = false;
    this.quickEditStatusChanged(this.currentStatus);
  }

  private async obtainNightShiftFirstShiftForOrg(configuration: ScheduleQuickEditConfiguration): Promise<any> {
    let organizationId: number;
    if (this.departmentOrglevelId !== configuration.department.orgLevelId) {
      this.departmentOrglevelId = configuration.department.orgLevelId;
      let orglevel: OrgLevel = await this.orgLevelWatchService.getOrgLevelByIdSafeAsync(this.departmentOrglevelId);
      if (orglevel) {
        orglevel = await this.orgLevelWatchService.getOrgLevelByIdSafeAsync(orglevel.parentId);
        if (orglevel && orglevel.relatedItemId) {
          organizationId = orglevel.relatedItemId;
        } else {
          if(!this.organizations) {
            this.organizations = await this.lookupApiService.getOrganizationsAsync();
          }
          let organization = this.getOrganizationByOrgLevel(orglevel.parentId);
          if (organization) {
            organizationId = organization.id;
          }
        }

        if(organizationId) {
          let shiftSetting: NightShiftSetting = this.getNightShiftSettingForOrganization(organizationId);
          if (!_.isNil(shiftSetting)) {
            this.isNightShiftFirstShiftForOrganization = shiftSetting.enabled;
          }
        } else {
          throw new Error('Could not obtain night shift first shift setting for organization.');
        }
      }
    }
  }

  private createShiftInfo(row: MasterScheduleEntryRow, cell: MasterScheduleEntryCell): MasterScheduleShiftInfo {
    const shiftInfo: MasterScheduleShiftInfo = new MasterScheduleShiftInfo();
    shiftInfo.empoyeeId = row.id;
    shiftInfo.position = { id: row.position.id, name: row.position.name, orgLevelId: row.position.orgLevelId };
    shiftInfo.shiftName = this.configuration.shift.name;
    shiftInfo.shiftId = this.configuration.shift.id;
    shiftInfo.shiftDefStart = shiftInfo.shiftStart = dateTimeUtils.getDateTimeFromTime(cell.dateOn, this.configuration.shift.start);
    shiftInfo.shiftDefEnd = shiftInfo.shiftEnd = dateTimeUtils.getDateTimeFromTime(cell.dateOn, this.configuration.shift.end);
    shiftInfo.duration = this.configuration.shift.durationHours * 3600000;
    shiftInfo.lunchDuration = dateTimeUtils.convertFromDtoDurationStringToNumber(this.configuration.shift.lunchDuration, 'ms');
    if (this.configuration.unit) {
      shiftInfo.unitId = this.configuration.unit.id;
      shiftInfo.unitName = this.configuration.unit.name;
    }
    if (this.configuration.absence) {
      shiftInfo.absenceCode = this.configuration.absence.code;
      shiftInfo.absenceName = this.configuration.absence.description;
    }
    if (this.configuration.constraint) {
      shiftInfo.constraintId = this.configuration.constraint.id;
      shiftInfo.constraintCode = this.configuration.constraint.code;
    }
    shiftInfo.partialAbsenceCode = null;
    shiftInfo.partialAbsenceName = null;
    shiftInfo.partialAbsenceStart = null;
    shiftInfo.partialAbsenceEnd = null;
    shiftInfo.partialAbsenceDuration = null;
    shiftInfo.departmentId = this.configuration.department.id;
    shiftInfo.departmentName = this.configuration.department.name;
    shiftInfo.departmentOrgLevelId = this.configuration.department.orgLevelId;

    this.updateTimeByNightShiftSetting(shiftInfo, this.configuration.shift.group, this.configuration.department.orgLevelId);

    return shiftInfo;
  }


  private updateTimeByNightShiftSetting(shift: MasterScheduleShiftInfo, shiftGroup: any, orglevelId: number): void {
    if (shift.shiftDefStart > shift.shiftDefEnd) {
      if (!this.isNightShiftFirstShiftForOrganization || shiftGroup.groupOrder !== ShiftGroupOrder.Night) {
        shift.shiftDefEnd = shift.shiftEnd = moment(shift.shiftDefEnd).add(1, 'days').toDate();
      } else {
        shift.shiftDefStart = shift.shiftStart = moment(shift.shiftDefStart).subtract(1, 'days').toDate();
      }
    }
  }

  private getOrganizationByOrgLevel(orgLevelId: number): Organization {
    return _.find(this.organizations, (o: Organization) => o.orgLevelId === orgLevelId);
  }

  private getNightShiftSettingForOrganization(organizationId: number): NightShiftSetting {
    let setting: NightShiftSetting = _.find(this.appSettings.nightShiftSettings, (s: NightShiftSetting) => s.organizationId === organizationId);
    if (_.isNil(setting)) {
      setting = _.find(this.appSettings.nightShiftSettings, (s: NightShiftSetting) => s.organizationId === 0);
    }
    return setting;
  }

  private async getAppSettings(): Promise<void> {
    if (this.appSettings) {
      return;
    }

    this.appSettings = await this.appSettingsService.getAppServerConfig();
  }

  private getOvelapState(row: MasterScheduleEntryRow, cell: MasterScheduleEntryCell, shiftInfo: MasterScheduleShiftInfo): QuickEditOvelap {
    const overlap = new QuickEditOvelap();
    overlap.canAppend = true;
    overlap.canOverride = true;
    overlap.canReplace = true;
    overlap.messages = [];
    const prevDay = moment(cell.dateOn).subtract(1, 'day').toDate();
    const nextDay = moment(cell.dateOn).add(1, 'day').toDate();
    let prevDayKey: string = EmployeesStateSelector.getDateKey(prevDay);
    let nextDayKey: string = EmployeesStateSelector.getDateKey(nextDay);
    const prevCell: MasterScheduleEntryCell = row.cell[prevDayKey];
    const nextCell: MasterScheduleEntryCell = row.cell[nextDayKey];

    overlap.prevDayState = this.checkCellOvelap(prevCell, shiftInfo, true);
    overlap.nextDayState = this.checkCellOvelap(nextCell, shiftInfo, false);
    overlap.currentDayState = this.checkCellOvelap(cell, shiftInfo, false);
    if (overlap.prevDayState.status !== QuickEditOvelapStatus.NoOverlap) {
      overlap.canOverride = false;
      overlap.canReplace = false;
      overlap.messages.push('Has overlapping with previous day schedule');
      if (this.hasOvelap(overlap.prevDayState.rightOverlapShifts, overlap.currentDayState.leftOverlapShifts) ||
          this.hasOvelap(overlap.prevDayState.rightOverlapShifts, overlap.currentDayState.rightOverlapShifts)) {
        overlap.canAppend = false;
        overlap.messages.push('No free space between previous day schedule and current day schedule');
      }
      if (overlap.prevDayState.status & QuickEditOvelapStatus.OverlapMiddleInside) {
        overlap.canAppend = false;
        overlap.messages.push('New shift inside previous day shift schedule');
      }
      if (overlap.prevDayState.status & QuickEditOvelapStatus.OverlapMiddleCover) {
        overlap.canAppend = false;
        overlap.messages.push('New shift cover previous day shift schedule');
      }
    }
    if (overlap.nextDayState.status !== QuickEditOvelapStatus.NoOverlap) {
      overlap.canOverride = false;
      overlap.canReplace = false;
      overlap.messages.push('Has overlapping with next day schedule');
      if (this.hasOvelap(overlap.nextDayState.leftOverlapShifts, overlap.currentDayState.rightOverlapShifts) ||
          this.hasOvelap(overlap.nextDayState.leftOverlapShifts, overlap.currentDayState.leftOverlapShifts)) {
        overlap.canAppend = false;
        overlap.messages.push('No free space between next day schedule and current day schedule');
      }
      if (overlap.nextDayState.status & QuickEditOvelapStatus.OverlapMiddleInside) {
        overlap.canAppend = false;
        overlap.messages.push('New shift inside next day shift schedule');
      }
      if (overlap.nextDayState.status & QuickEditOvelapStatus.OverlapMiddleCover) {
        overlap.canAppend = false;
        overlap.messages.push('New shift cover next day shift schedule');
      }
    }
    if (overlap.currentDayState.status === QuickEditOvelapStatus.NoOverlap) {
      overlap.canOverride = false;
    }
    if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapMiddleInside) {
      overlap.canAppend = false;
      overlap.canOverride = false;
      overlap.messages.push('New shift inside current day shift schedule');
    }
    if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapMiddleCover) {
      overlap.canAppend = false;
      overlap.canOverride = false;
      overlap.messages.push('New shift cover current day shift schedule');
    }
    return overlap;
  }

  private checkCellOvelap(cell: MasterScheduleEntryCell, shiftInfo: MasterScheduleShiftInfo, prevDay: boolean): QuickEditOvelapState {
    const state = new QuickEditOvelapState();
    state.status = QuickEditOvelapStatus.NoOverlap;
    if (!cell) {
      return state;
    }
    _.forEach(cell.shiftsInfo, (s: MasterScheduleShiftInfo) => {
      if (moment(s.shiftStart).isSameOrBefore(shiftInfo.shiftStart) && moment(s.shiftEnd).isBefore(shiftInfo.shiftEnd) && moment(s.shiftEnd).isAfter(shiftInfo.shiftStart)) {
        if (prevDay) {
          state.status = state.status | QuickEditOvelapStatus.OverlapRight;
          state.rightOverlapShifts.push(s);
        } else {
          state.status = state.status | QuickEditOvelapStatus.OverlapLeft;
          state.leftOverlapShifts.push(s);
        }
      }
      if (moment(s.shiftStart).isAfter(shiftInfo.shiftStart) && moment(s.shiftEnd).isSameOrAfter(shiftInfo.shiftEnd) && moment(s.shiftStart).isBefore(shiftInfo.shiftEnd)) {
        if (prevDay) {
          state.status = state.status | QuickEditOvelapStatus.OverlapLeft;
          state.leftOverlapShifts.push(s);
        } else {
          state.status = state.status | QuickEditOvelapStatus.OverlapRight;
          state.rightOverlapShifts.push(s);
        }
      }
      if (moment(s.shiftStart).isSameOrBefore(shiftInfo.shiftStart) && moment(s.shiftEnd).isSameOrAfter(shiftInfo.shiftEnd)) {
        state.status = state.status | QuickEditOvelapStatus.OverlapMiddleInside;
      }
      if (moment(s.shiftStart).isSameOrAfter(shiftInfo.shiftStart) && moment(s.shiftEnd).isSameOrBefore(shiftInfo.shiftEnd)) {
        state.status = state.status | QuickEditOvelapStatus.OverlapMiddleCover;
      }
    });
    return state;
  }

  private hasOvelap(list1: MasterScheduleShiftInfo[], list2: MasterScheduleShiftInfo[]): boolean {
    let hasOverlap = false;
    _.forEach(list1, (s1: MasterScheduleShiftInfo) => {
      _.forEach(list2, (s2: MasterScheduleShiftInfo) => {
        if (moment(s1.shiftStart).isSameOrBefore(s2.shiftEnd) && moment(s1.shiftEnd).isSameOrAfter(s2.shiftStart)) {
          hasOverlap = true;
        }
        return !hasOverlap;
      });
      return !hasOverlap;
    });
    return hasOverlap;
  }

  private canClose(): Promise<boolean> {
    return this.changeManagementService.checkPristine(undefined, ChangesDialogOptions.DOWORK, this.changeGroupId);
  }

  public getLastTenScheduleRecords(orglevelId: number){
    let startDate: Date, endDate: Date;
    startDate = this.masterScheduleManagementService.dateFrom;
    endDate = this.masterScheduleManagementService.endDate;
    this.individualScheduleApiService.getLastTenScheduleRecords(orglevelId, startDate, endDate).then(response => {
      this.items = response;
      this.last10Changes = response;
      this.quickEditListChanged$.next(response);
    });
  }
}


import {combineLatest} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { PbjExportApiService } from '../../../services/index';
import { PopperContent, PopperController } from 'ngx-popper';
import { PBJExportLogHeader, PBJExportContainer } from '../../../models/index';
import { FileBlobResponse } from '../../../../../core/models/api/file-blob-response';
import { FileService } from '../../../../../common/services/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { PbjNavigationService } from '../../../../../common/services/index';
import { PbjDetailsEmpDataGridComponent } from '../pbj-details-emp-data-grid/pbj-details-emp-data-grid.component';
import { PbjDetailsCensusDataGridComponent } from '../pbj-details-census-data-grid/pbj-details-census-data-grid.component';
import { PbjDetailsErrorsDataGridComponent } from '../pbj-details-errors-data-grid/pbj-details-errors-data-grid.component';
import { PbjDetailsStaffingHoursGridComponent } from '../pbj-details-staffing-hours-grid/pbj-details-staffing-hours-grid.component';
import { AppSettingsManageService } from '../../../../../app-settings/services';
import { AppServerConfig } from '../../../../../app-settings/model/app-server-config';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-export-details',
  templateUrl: 'pbj-export-details.component.html',
  styleUrls: ['pbj-export-details.component.scss'],
})
export class PbjLogExportDetailsComponent implements OnDestroy, OnInit {
  public state: {
    isLoading: boolean;
    isChildLoading: boolean;
    empData: boolean;
    staffingHours: boolean;
    census: boolean;
    errors: boolean;
  };

  public appConfig: IApplicationConfig;
  public exportId: number;
  public pbjHeader: PBJExportLogHeader;

  @ViewChild('popperContent', {static: true})
  public popperContent: PopperContent;

  @ViewChild('empDataGrid')
  public empDataGrid: PbjDetailsEmpDataGridComponent;

  @ViewChild('censusDataGrid')
  public censusDataGrid: PbjDetailsCensusDataGridComponent;

  @ViewChild('errorsDataGrid')
  public errorsDataGrid: PbjDetailsErrorsDataGridComponent;

  @ViewChild('staffingHoursGrid')
  public staffingHoursGrid: PbjDetailsStaffingHoursGridComponent;

  @unsubscribe()
  private routeSubscripion: Subscription;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  private orgLevelId: number = null;
  public isPBJExportCorpLevelEnabled: boolean;
  public pdfName: string;
  public excelName: string;
  public xmlName: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: PbjExportApiService,
    private fileService: FileService, public appSettingsManageService : AppSettingsManageService
  ) {
    this.state = {
      isLoading: false,
      isChildLoading: false,
      empData: false,
      staffingHours: false,
      census: false,
      errors: false
    };
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.appSettingsManageService.getAppServerConfig().then((config: AppServerConfig) => {
      this.isPBJExportCorpLevelEnabled = config.IsPBJExportCorpLevelEnabled;
      this.pdfName = this.isPBJExportCorpLevelEnabled ? 'Download PDF' : 'Export to  PDF';
      this.excelName = this.isPBJExportCorpLevelEnabled ? 'Download Excel' : 'Export to  Excel';
      this.xmlName = this.isPBJExportCorpLevelEnabled ? 'Download XML' : 'Export XML File';
    })
    this.routeSubscripion = this.route.params.pipe(
      combineLatest(this.route.queryParams))
      .subscribe(([params, queryParams]) => {
        const orgLevelId = +queryParams['orgLevelId'];
        if (_.isFinite(this.orgLevelId) && _.isFinite(orgLevelId) && this.orgLevelId !== orgLevelId) {
          this.navigateToPbjExport();
          return;
        }
        if (!_.isFinite(this.orgLevelId) && _.isFinite(orgLevelId)) {
          this.orgLevelId = orgLevelId;
        }

        this.exportId = +params['exportId'];
        this.state.empData = true;
        this.loadHeaders(this.exportId);
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onChildLoadStatusChange(isLoading: boolean): void {
    this.state.isChildLoading = isLoading;
  }

  public onEmployeeData(): void {
    this.state.empData = true;
    this.state.staffingHours = false;
    this.state.census = false;
    this.state.errors = false;
  }

  public onStaffingHours(): void {
    this.state.empData = false;
    this.state.staffingHours = true;
    this.state.census = false;
    this.state.errors = false;
  }

  public onCensus(): void {
    this.state.empData = false;
    this.state.staffingHours = false;
    this.state.census = true;
    this.state.errors = false;
  }

  public onErrors(): void {
    this.state.empData = false;
    this.state.staffingHours = false;
    this.state.census = false;
    this.state.errors = true;
  }

  public onXMLFile(): void {
    this.apiService.getPBJExportData(this.exportId)
      .then((val: FileBlobResponse) => {
        this.state.isLoading = false;
        this.fileService.saveToFileSystem(val.blob, val.file);
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  public onExportToPdf(): void {
    if (this.state.empData) {
      this.empDataGrid.exportToPdf();
    } else if (this.state.census) {
      this.censusDataGrid.exportToPdf();
    } else if (this.state.errors) {
      this.errorsDataGrid.exportToPdf();
    } else if (this.state.staffingHours) {
      this.staffingHoursGrid.exportToPdf();
    }
  }

  public onExportToExcel(): void {
    if (this.state.empData) {
      this.empDataGrid.exportToXls();
    } else if (this.state.census) {
      this.censusDataGrid.exportToXls();
    } else if (this.state.errors) {
      this.errorsDataGrid.exportToXls();
    } else if (this.state.staffingHours) {
      this.staffingHoursGrid.exportToXls();
    }
  }

  public get hasErrors(): boolean {
    return this.pbjHeader && this.pbjHeader.errorsCount > 0;
  }

  public get title(): string {
    return this.hasErrors ? 'Export is not available for items that contain errors' : '';
  }

  private loadHeaders(exportId: number): void {
    this.state.isLoading = true;
    this.apiService.getPBJExportLogHeader(exportId)
      .then((val: PBJExportLogHeader) => {
        this.state.isLoading = false;
        this.pbjHeader = val;
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  private navigateToPbjExport(): void {
    const pbjNavService = new PbjNavigationService(this.router, this.route);
    pbjNavService.NavigateToPbjExport();
  }
}

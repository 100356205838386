
import {combineLatest} from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { GenericListManagementService } from '../../../../organization/services/index';
import { GenericListDefinition, GenericList, GenericListRequest, GenericFieldType, GenericFieldAlignment } from '../../../../organization/models/generic-list/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { genericListConfig } from '../../../../organization/generic-list.config';

import { Observable ,  Subscription } from 'rxjs';


import { UserApplication } from '../../../../state-model/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { DateRange, IDateRange, IColumnSettings } from '../../../../core/models/index';
import { StateManagementService } from './../../../../common/services/state-management/state-management.service';
import { ColumnManagementService } from '../../../../common/services';
import { DirectCareHoursManagementService } from '../../services';
import { GenericGridComponent } from '../../../../organization';
import { IGenericGridConfig, genericGridConfig } from '../../../../organization/components/generic-list/generic-grid/generic-grid.config';

@Component({
  moduleId: module.id,
  selector: 'slx-direct-care-hours',
  templateUrl: 'direct-care-hours.component.html',
  styleUrls: ['direct-care-hours.component.scss'],
  providers: [
    GenericListManagementService, StateManagementService,
    ColumnManagementService, DirectCareHoursManagementService]
})
export class DirectCareHoursComponent implements OnInit, OnDestroy {

  @ViewChild(GenericGridComponent, { static: true })
  public grid: GenericGridComponent;

  @mutableSelect('application')
  public application: Observable<UserApplication>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public appConfig: IApplicationConfig;
  public startDate: Date;
  public endDate: Date;

  public state: {
    isLoading: boolean;
    definitionsLoaded: boolean;
  };

  public genericGridConfig: IGenericGridConfig;

  @unsubscribe()
  private definitionsLoadedSubscription: Subscription;
  @unsubscribe()
  private definitionsPrepareSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private valuesLoadedSubscription: Subscription;
  @unsubscribe()
  private datesChanged: Subscription;

  private currentOrgLevel: OrgLevel;
  private currentApplication: UserApplication;
  @unsubscribe()
  private loadSubscription: Subscription;

  constructor (
    private managementService: DirectCareHoursManagementService,
    private genericListManagementService: GenericListManagementService,
    private stateManagement: StateManagementService
    ) {
    this.state = {
      isLoading: false,
      definitionsLoaded: false
    };

  }

  public ngOnInit(): void {

    this.genericGridConfig = _.cloneDeep(genericGridConfig);
    this.genericGridConfig.pdfExport.autoscale = true;

    this.appConfig = appConfig;

    this.stateManagement.init('DirectCareHoursComponent', false);

    this.orgLevelSubscription = this.orgLevel$.pipe(
      combineLatest(this.application))
      .subscribe((value: [OrgLevel, UserApplication]) => {
        let [orgLevel, application]: [OrgLevel, UserApplication] = value;
        if (!orgLevel || !orgLevel.id || !application.id) {
          return;
        }
        this.currentOrgLevel = orgLevel;
        this.currentApplication = application;
        this.loadDefinitions();
      });

    this.loadSubscription = this.stateManagement.loadData$.subscribe(() => {
      this.loadValues();
    });

    this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe((defs: GenericListDefinition) => {
      this.state.definitionsLoaded = true;
      this.stateManagement.onComponentActiveStateChanged({});
    });

    this.definitionsPrepareSubscription = this.genericListManagementService.onDefinitionsPrepare$.subscribe((defs: GenericListDefinition) => {
      if (defs) {
        _.each(defs.fields, x => {
          if (x.fieldType === 'number' || x.fieldType === 'int') {
            x.align = 'right' as GenericFieldAlignment;
          }
        });
      }
    });

    this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe((list: GenericList) => {
      this.state.isLoading = false;
      this.stateManagement.loadedData({});
    });

    this.datesChanged = this.managementService.subscribeToDateRange((range: IDateRange) => {
      this.startDate = range.startDate;
      this.endDate = range.endDate;
      this.loadValues();
    });

    this.managementService.subscribeToExport((isPdf: boolean) => {
      
      let start: string = moment(this.startDate).format(appConfig.dateFormat);
      let end: string = moment(this.endDate).format(appConfig.dateFormat);
      this.genericGridConfig.pdfExport.fileName = `DirectCareHours_${start}-${end}.pdf`;
      this.genericGridConfig.excelExport.fileName = `DirectCareHours_${start}-${end}.xlsx`;

      if (isPdf) {
        this.grid.exportToPdf();
      } else {
        this.grid.exportToExcel()
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private loadDefinitions(): void {
    this.state.isLoading = true;
    this.state.definitionsLoaded = false;
    this.genericListManagementService.loadListDefinition(this.currentApplication.id, this.currentOrgLevel.id, genericListConfig.directCareHours, false);
  }

  private loadValues(): void {
    if (this.state.definitionsLoaded) {
      this.state.isLoading = true;
      let req: GenericListRequest = new GenericListRequest();
      req.startDate = this.startDate;
      req.endDate = this.endDate;
      this.genericListManagementService.loadListValues(this.currentOrgLevel.id, req);
    }
  }
}

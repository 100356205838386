import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { IUser } from '../../../authentication/store/index';
import { scheduleMicrotask } from '../../../core/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-user-info',
  templateUrl: 'user-info.component.html',
  styleUrls: ['user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnDestroy {
  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;
  @mutableSelect(['session', 'user'])
  public user$: Observable<IUser>;
  public userName: string;

  @unsubscribe()
  private userSubscription: Subscription;

  public ngOnInit(): void {
    this.userSubscription = this.user$.subscribe((user: IUser) => {
      if (user) {
        this.userName = user.name;
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}


import {merge as observableMerge,  Subscription ,  Observable } from 'rxjs';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';


import { unsubscribe } from '../../../../core/decorators/index';
import { Organization } from '../../../../organization/models/index';
import { AGENCY_ORGANIZATION_ID } from '../../../configuration.config';
import { AgencyMapping, AgencyModel } from '../../../models/index';
import { AgencyDetailsManagementService } from '../../../services/index';

@Component({
    moduleId: module.id,
    selector: 'slx-agency-details',
    templateUrl: 'agency-details.component.html',
    styleUrls: ['agency-details.component.scss']
})
export class AgencyDetailsComponent implements OnInit, OnDestroy {

    @Input()
    public orglevelId: number;

    @Input()
    public set agencyMapping(value: AgencyMapping) {
        this.currentAgencyMapping = value;
        if (this.currentAgencyMapping) {
            this.tempAgencyMapping = this.managementService.cloneAgencyMapping(this.currentAgencyMapping);
        } else {
            this.tempAgencyMapping = null;
        }

        this.editMode = false;
        if (this.fullOrganizationsList) {
            this.filterOrganizations();
        }
    }

    @Input()
    public set organizations(value: Organization[]) {
        this.fullOrganizationsList = value;
        if (this.currentAgencyMapping) {
            this.filterOrganizations();
        }
    }

    @Input()
    public editMode: boolean;
    @Input()
    public standaloneMode: boolean;
    @Input()
    public rowIndex: number = 0;

    @unsubscribe()
    public mappingSubscription: Subscription;

    public filteredOrganizations: Organization[];
    public organizationToAdd: Organization;
    public currentAgencyMapping: AgencyMapping;
    public tempAgencyMapping: AgencyMapping;

    public get hasFreeOrganizationsForMapping(): boolean {
      return this.filteredOrganizations && this.filteredOrganizations.length>0;
    }

    private fullOrganizationsList: Organization[];
    private managementService: AgencyDetailsManagementService;

    constructor(managementService: AgencyDetailsManagementService) {
        this.managementService = managementService;
    }

    public ngOnInit(): void {
        this.mappingSubscription = observableMerge(this.managementService.onAdded$, this.managementService.onRemoved$).subscribe((result: any) => {
            this.organizationToAdd = null;
            this.filterOrganizations();
        });
    }

    public ngOnDestroy(): void {
      // See #issueWithAOTCompiler
    }

    public toggleEditMode(): void {
        this.editMode = !this.editMode;
    }

    public addNewOrganization(): void {
        this.managementService.addOrganization(this.currentAgencyMapping, this.organizationToAdd);
    }

    public removeHandler(event: { sender: any, rowIndex: number, dataItem: any, isNew: boolean }): void {
        if (!event.dataItem) {
            return;
        }
        this.managementService.removeOrganization(this.currentAgencyMapping, event.dataItem);
    }

    public saveAgencyInfo(): void {
        this.currentAgencyMapping = this.managementService.cloneAgencyMapping(this.tempAgencyMapping);
        this.managementService.saveAgencyInfo(this.orglevelId, this.tempAgencyMapping);
    }

    public discardAgencyInfo(): void {
        this.tempAgencyMapping = this.managementService.cloneAgencyMapping(this.currentAgencyMapping);
    }

    public get isAnyOrganizationWithoutAgencies(): boolean  {
        return this.filteredOrganizations && this.filteredOrganizations.length > 0;
    }

    private filterOrganizations(): void {
        if (this.fullOrganizationsList && this.currentAgencyMapping) {
            this.filteredOrganizations = _.differenceBy(this.fullOrganizationsList, this.currentAgencyMapping.organizations, 'id');
            if (this.filteredOrganizations && this.filteredOrganizations.length > 0) {
                this.filteredOrganizations = _.without(this.filteredOrganizations, _.find(this.filteredOrganizations, (organization: Organization) => {
                    return organization.id === AGENCY_ORGANIZATION_ID;
                }));
            }
        }
    }

}

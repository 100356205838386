import { Provider } from '@angular/core';

export * from './pay-units-mass-assignment-map.service';
export * from './pay-units-mass-assignment-api.service';

import { PayUnitsMassAssignmentMapService } from './pay-units-mass-assignment-map.service';
import { PayUnitsMassAssignmentApiService } from './pay-units-mass-assignment-api.service';

export const payUnitsMassAssignmentServices: Provider[] = [
  PayUnitsMassAssignmentMapService,
  PayUnitsMassAssignmentApiService
];

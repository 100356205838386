export * from './tree.component';
export * from './tree-node/tree-node.component';
export * from './tree-node-children/tree-node-children.component';
export * from './tree-node-expander/tree-node-expander.component';
export * from './tree-node-content/tree-node-content.component';
export * from './tree-root/tree-root.component';

import { TreeComponent } from './tree.component';
import { TreeNodeComponent } from './tree-node/tree-node.component';
import { TreeNodeChildrenComponent } from './tree-node-children/tree-node-children.component';
import { TreeNodeExpanderComponent } from './tree-node-expander/tree-node-expander.component';
import { TreeNodeContentComponent } from './tree-node-content/tree-node-content.component';
import { TreeRootComponent } from './tree-root/tree-root.component';

export const treeComponents: any = [
    TreeComponent, TreeNodeComponent, TreeNodeChildrenComponent,
    TreeNodeExpanderComponent, TreeNodeContentComponent, TreeRootComponent
];

import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Provider } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import {
  ModalService,
  KendoGridStateHelper,
  KendoGridCustomSelectionHelper,
  DialogOptions
} from '../../../common/index';
import {
  destroyService,
  unsubscribe
} from '../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { appMessages, IApplicationMessages } from '../../../app.messages';
import { AccessManagementService, UnitsConfigurationManagementService } from '../../services/index';
import { Unit, EditableListActionKind } from '../../models/index';
import { UnitRemoveDialogComponent } from './location-unit-remove-dialog/location-unit-remove-dialog.component';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { NgForm } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-location-unit-component',
  templateUrl: 'location-units.component.html',
  styleUrls: ['location-units.component.scss'],
  providers: [UnitsConfigurationManagementService, AccessManagementService]
})
export class UnitsComponent implements OnInit, OnDestroy {

  public onAddItem: () => void;
  public onDeletedItems: () => void;

  public crudHelper: ConfigurationComponentHelper<Unit>;
  public gridState: KendoGridStateHelper<Unit>;
  public selectionHelper: KendoGridCustomSelectionHelper<Unit>;

  public state: {
    isLoading: boolean;
    configureMode: boolean;
    copyMode: boolean;
  };

  public appConfig: IApplicationConfig;
  public appMessages: IApplicationMessages;

  public prohibitedNameValues: string[] = [];
  public prohibitedDescriptionValues: string[] = [];

  @ViewChild('kendoGrid', {static: true})
  private set grid(value: GridComponent) {
    if (this.crudHelper) this.crudHelper.grid = value;
    this.m_grid = value;
  }

  private get grid(): GridComponent {
    return this.m_grid;
  }

  private m_grid: GridComponent;

  @ViewChild('templateForm', { static: true })
  private mainForm: NgForm;

  @destroyService()
  private management: UnitsConfigurationManagementService;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private editSubscription: Subscription;
  @unsubscribe()
  private removeSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private mainFormSubscription: Subscription;

  constructor(management: UnitsConfigurationManagementService,
    public access: AccessManagementService,
    private modalService: ModalService) {

    this.management = management;

    this.gridState = new KendoGridStateHelper<Unit>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

    this.crudHelper = new ConfigurationComponentHelper<Unit>();
    this.crudHelper.gridState = this.gridState;
    this.crudHelper.selectionHelper = this.selectionHelper;
    this.crudHelper.management = management;

    // directive intercepted methods (scope bug)
    this.onAddItem = () => {
      let item: Unit = new Unit();
      this.crudHelper.addItem(item);
      this.updateProhibitedNameValues(item);
    };

    this.onDeletedItems = () => {
      this.crudHelper.deleteSelected();
    };
  }

  public ngOnInit(): void {

    this.appMessages = appMessages;
    this.appConfig = appConfig;

    this.state = {
      isLoading: false,
      configureMode: true,
      copyMode: false
    };

    this.stateSubscription = this.management.onStateChanged$.subscribe((state: { isLoading: boolean, configureMode: boolean, copyMode: boolean }) => {
      if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
      if (_.has(state, 'configureMode')) this.state.configureMode = state.configureMode;
      if (_.has(state, 'copyMode')) this.state.copyMode = state.copyMode;
    });

    this.editSubscription = this.management.editItemCmd$.subscribe((item: Unit) => {
      if (item) {
        this.updateProhibitedNameValues(item);
        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
          if (this.mainForm.dirty) {
            this.management.markAsDirty();
          }
        });
      } else {
        if (this.mainFormSubscription) {
          this.mainFormSubscription.unsubscribe();
        }
      }
    });

    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: Unit[]): void => {
      this.crudHelper.selectionChange(_.first(records), 0);
    });

    this.removeSubscription = this.management.removeItemsCmd$.subscribe((item: Unit) => {
      if (item.employeesCount > 0) {
        let dialogOptions: DialogOptions = new DialogOptions();
        dialogOptions.height = 190;
        dialogOptions.width = 350;
        let resolvedProviders: Provider[] = [
          {
            provide: DialogOptions,
            useValue: dialogOptions
          },
          {
            provide: ModalService,
            useValue: this.modalService
          }
        ];
        let dialog: UnitRemoveDialogComponent = this.modalService.globalAnchor.openDialog(
          UnitRemoveDialogComponent, 'Remove Unit', dialogOptions, resolvedProviders,
          (result: boolean, uniqueId?: string) => {
            if (result) {
              this.management.doRemoveItem(item, dialog.unit);
            }

          });

        dialog.units = this.management.container.records.filter((obj: Unit, index: number, array: Unit[]) => {
          return !(obj === item || obj.id === 0);
        });
      } else {
        this.management.doRemoveItem(item, null);
      }
    });

    this.crudHelper.grid = this.grid;
    this.crudHelper.init();
    this.management.init();

  }

  // Must be, see #issueWithAOTCompiler
  public ngOnDestroy(): void {
    if (this.crudHelper) {
      this.crudHelper.destroy();
    }
  }

  public updateProhibitedNameValues(item: Unit): void {
    if (item) {
      let values: string[] = ['FL'];
      let descValues: string[] = [];
      _.each(this.management.container.records, (s: Unit) => {
        if (item.id !== s.id) {
          values.push(s.name);
          descValues.push(s.description);
        }
      });
      this.prohibitedDescriptionValues = descValues;
      this.prohibitedNameValues = values;
    }
  }

  public onMobileRendererEvent(action: EditableListActionKind, item: Unit, index: number): void {
    if (action === EditableListActionKind.SELECTION_CHANGE) {
      this.crudHelper.selectionChange(item, index);
    } else if (action === EditableListActionKind.START_EDIT) {
      this.crudHelper.startEdit(item, index);
    }
  }

  public onMobileEditorEvent(action: EditableListActionKind, item: Unit, index: number): void {
    if (action === EditableListActionKind.COMPLETE_EDIT) {
      this.crudHelper.completeEdit(item, index);
    } else if (action === EditableListActionKind.CANCEL_EDIT) {
      this.crudHelper.cancelEdit(index);
    }
  }
}



import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable ,  ReplaySubject ,  Subject ,  Subscription } from 'rxjs';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { IAcaC1095AuditState, initialAcaC1095AuditState, AcaC1095AuditFilterState } from '../../models/aca-c1095-audit/aca-c1095-audit-state';
import { AcaC1095Audit, AcaC1095AuditRecord, EmployeeIdList } from '../../models/aca-c1095-audit/aca-c1095-audit-record';
import { AcaC1095AuditApiService } from './aca-c1095-audit-api.service';
import { StateManagementService } from '../../../common';
import { StateResetTypes } from '../../../core/models';
import { EmployeeFilterRecord, EmployeeFilter } from '../../models/index';

@Injectable()
export class AcaC1095AuditManagementService {

    private originalContainer: AcaC1095Audit;
    private orgLevel: OrgLevel;
    private selectedYear: number;
    public expandedEmployeeList: EmployeeIdList[] = [];
    public acaFilterState: AcaC1095AuditFilterState = new AcaC1095AuditFilterState();
    private loading$ = new Subject<boolean>();
    private recordsLoaded$ = new Subject<AcaC1095Audit>();
    private exportTo$ = new Subject<boolean>();
    private expandAll$ = new Subject<boolean>();
    private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
    private employeeFilter$ = new Subject<EmployeeFilter>();
    private employeeFilterRecords$ = new ReplaySubject<EmployeeFilterRecord[]>(1);

    public filter: EmployeeFilterRecord[] = [];

    private readonly stateKey: string = 'AcaC1095AuditState';
    public state: IAcaC1095AuditState;
    private isExpandAll: boolean;

    @unsubscribeAll('destroy')
    public subscriptions: StringMap<Subscription> = {};

    @mutableSelect('orgLevel')
    public orgLevel$: Observable<OrgLevel>;

    constructor(
        private apiService: AcaC1095AuditApiService,
        private stateManagement: StateManagementService
    ) {

    }

    public init(): void {
        this.subscribeToOrgLevelChanges();
    }

    public destroy(): void {
        this.orgLevel = null;
        this.loading$.complete();
        this.recordsLoaded$.complete();
        this.exportTo$.complete();
        this.expandAll$.complete();
        this.orgLevelChanged$.complete();
        this.employeeFilterRecords$.complete();
    }

    public exportTo(isPDF: boolean): void {
        this.exportTo$.next(isPDF);
    }
    public expandAll(isExpand: boolean): void {
        this.expandAll$.next(isExpand);
    }
    public subscribeToExport(callback: (b: boolean) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    }
    public subscribeToExpandAll(callback: (b: boolean) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.expandAll$.subscribe(callback);
    }
    public subscribeToLoadedRecords(callback: (b: AcaC1095Audit) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    }
    public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    }

    public subscribeToLoading(callback: (v: boolean) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    }

    public subscribeToEmployeeFilter(callback: (f: EmployeeFilter) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.employeeFilter$.subscribe(callback);
    }

    public subscribeToEmpFilterRecords(callback: (b: EmployeeFilterRecord[]) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.employeeFilterRecords$.subscribe(callback);
    }

    public loadAcaC1095AuditRecords(year: number, acaFilterState: AcaC1095AuditFilterState): void {
        if (!_.isFinite(_.get(this.orgLevel, 'id'))) {
            return;
        }

        this.loading$.next(true);
        this.apiService.getC1095AuditRecords(this.orgLevel.id, year)
            .then((container: AcaC1095Audit) => {
                if (acaFilterState && acaFilterState.employeeIdList) {
                    let filteredRecords: AcaC1095AuditRecord[] = _.forEach(container.records, (r: AcaC1095AuditRecord) => {
                        _.forEach(acaFilterState.employeeIdList, (k) => {
                            if (k.employeeId === r.employeeId) {
                                r.expand = k.expand;
                                r.expandMonth = k.expandMonth;
                                return false;
                            }
                        });
                    });
                    container.records = filteredRecords;
                }
                if (acaFilterState && (acaFilterState.employeeIdList == null || acaFilterState.employeeIdList.length == 0)) {
                    let filteredRecords: AcaC1095AuditRecord[] = _.forEach(container.records, (r: AcaC1095AuditRecord) => {
                        r.expand = acaFilterState.isExpandAll;
                        r.expandMonth = acaFilterState.isExpandAll;
                    });
                    container.records = filteredRecords;
                }
                this.originalContainer = container;
                this.recordsLoaded$.next(container);

                let employeeFilter: EmployeeFilter = new EmployeeFilter();
                employeeFilter.employeeFilterRecord = [];
                employeeFilter.filter = [];
                if (container && container.records) {
                    _.forEach(container.records, function (item) {
                        let empItem: EmployeeFilterRecord = new EmployeeFilterRecord();
                        empItem.id = item.employeeId;
                        empItem.name = item.employeeName;
                        employeeFilter.employeeFilterRecord.push(empItem);
                    });

                    if (acaFilterState && acaFilterState.employeeListFilter) {
                        employeeFilter.filter = acaFilterState.employeeListFilter;
                    }

                    this.employeeFilter$.next(employeeFilter);
                }

                this.loading$.next(false);
            })
            .catch(() => {
                this.loading$.next(false);
            });
    }

    private subscribeToOrgLevelChanges(): void {
        this.subscriptions.orgLevel = this.orgLevel$.pipe(
            filter((o: OrgLevel) => o && _.isFinite(o.id)))
            .subscribe((orgLevel: OrgLevel) => {
                if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
                this.orgLevel = orgLevel;
                this.orgLevelChanged$.next(this.orgLevel);
                if (this.selectedYear) {
                    this.restoreState();
                }
            });
    }

    public setEmployeeIdList(item: EmployeeIdList): void {
        if (this.expandedEmployeeList.indexOf(item) === -1) {
            if (item.expand || item.expandMonth) {
                this.expandedEmployeeList.push(item);
            }
            else {
                let list = _.find(this.expandedEmployeeList, { 'employeeId': item.employeeId });
                this.expandedEmployeeList = _.without(this.expandedEmployeeList, list);
            }
            this.setC1095AuditState();
        }

    }

    public setEmployeeGridExpandCollapse(isExpand: boolean): void {
        this.isExpandAll = isExpand;
        this.expandedEmployeeList = [];
        this.setC1095AuditState();
    }

    public setSelectedYear(selYear: number) {
        this.selectedYear = selYear;
        this.restoreState();
    }

    public setC1095AuditState(): void {
        if (!this.selectedYear) {
            return;
        }
        let state: IAcaC1095AuditState = _.clone(initialAcaC1095AuditState);
        const controlState = this.stateManagement.getControlState(this.stateKey);

        if (controlState && controlState.value) {
            state = controlState.value;
        }
        let acaState: AcaC1095AuditFilterState = new AcaC1095AuditFilterState();
        acaState.year = this.selectedYear;
        acaState.orgLevelId = this.orgLevel.id;
        if (this.expandedEmployeeList && this.expandedEmployeeList.length > 0) {
            acaState.employeeIdList = this.expandedEmployeeList;
        }
        if (this.expandedEmployeeList && this.expandedEmployeeList.length == 0) {
            acaState.isExpandAll = this.isExpandAll;
        }
        if (state && state.acaC1095AuditStateList && state.acaC1095AuditStateList.length > 0) {
            let index = _.findIndex(state.acaC1095AuditStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
            if (index >= 0) {
                let item = _.find(state.acaC1095AuditStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
                state.acaC1095AuditStateList = _.without(state.acaC1095AuditStateList, item);
                state.acaC1095AuditStateList.push(acaState);
            }
            else {
                state.acaC1095AuditStateList.push(acaState);
            }
        }
        else {
            state.acaC1095AuditStateList = [];
            state.acaC1095AuditStateList = [acaState];
        }

        this.saveState(state);

    }
    public saveState(state: IAcaC1095AuditState): void {
        this.stateManagement.setControlState(this.stateKey, {
            value: state
        }, StateResetTypes.None);
    }
    public restoreState(): void {
        if (!this.selectedYear) {
            return;
        }

        let state: IAcaC1095AuditState = _.clone(initialAcaC1095AuditState);
        const controlState = this.stateManagement.getControlState(this.stateKey);

        if (controlState && controlState.value) {
            state = controlState.value;
        }

        if (state && state.acaC1095AuditStateList) {
            if (_.findIndex(state.acaC1095AuditStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id }) >= 0) {
                this.acaFilterState = _.find(state.acaC1095AuditStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
            }
            else {
                this.acaFilterState = null;
            }
        }
        else {
            this.acaFilterState = null;
        }
        this.loadAcaC1095AuditRecords(this.selectedYear, this.acaFilterState);
    }

    public setEmployeeFilterRecords(empFilter: EmployeeFilter): void {
        this.employeeFilter$.next(empFilter);
    }

    public changeEmployeeFilter(filters: EmployeeFilterRecord[]): void {
        this.employeeFilterRecords$.next(filters);
    }

    public setEmployeeFilter(filters: EmployeeFilterRecord[]) {
        this.filter = filters;
        this.setC1095AuditState();
        this.changeEmployeeFilter(filters);
    }
}
<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells"
  [slxKendoGridColumnsGroup]="columnsGroupName"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  [reorderable]="isReordable"
  (dataStateChange)="gridState.dataStateChange($event)"

  [slxKendoGridFiltersState]="gridState"
>

 <kendo-grid-column *slxHiddenOn="['mobile']" width="50" [locked]="isLockedColumn">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="pm-emp" (click)="onEmpIconClick(dataItem.employee?.id)">
        <i class="pm-emp__icon fas fa-file-search" aria-hidden="true"></i>
      </span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column
    title="Employee Name"
    field="employee.name"
    width="180"
    filter="text"
    [locked]="isLockedColumn"
    [reorderable]="false"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="pm-emp" [title]="dataItem.employee?.name" (click)="onEmpNameClick(dataItem.employee?.id)">
        <img class="pm-emp__img" [employeeThumbinalSrc]="dataItem.employee?.id" alt="Employee Photo" />
        <span class="pm-emp__text pm-link">{{ dataItem.employee?.name }}</span>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Position"
    field="position.name"
    width="150"
    filter="text"
    [locked]="isLockedColumn"
    [reorderable]="false"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Facility"
    field="organization.name"
    width="150"
    filter="text"
    [locked]="isLockedColumn"
    [reorderable]="false"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Facility</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Hire Date"
    field="hideDate"
    width="110"
    filter="date"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Hire Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.hideDate | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Termination Date"
    field="terminationDate"
    width="180"
    filter="date"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Termination Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.terminationDate | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Employee Status"
    field="employeeStatus"
    width="150"
    filter="text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Status</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.employeeStatus }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Review Date"
    field="reviewDate"
    width="170"
    filter="date"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Review Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.reviewDate" class="pm-link" (click)="onOpenReport(dataItem)">
        {{ dataItem.reviewDate | amDateFormat: appConfig.dateFormat }}
      </span>
      <span *ngIf="!dataItem.reviewDate && dataItem.id" class="pm-link" (click)="onOpenReport(dataItem)">No Date</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Review Type"
    width="170"
    filter="text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState
    field="reviewType.name"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Review Type</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.reviewType?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Rating"
    width="170"
    filter="text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState
    field="reviewRating"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Rating</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.reviewRating }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Start Date"
    field="startDate"
    width="110"
    filter="date"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Start Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDate | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Completion Date"
    field="completionDate"
    width="170"
    filter="date"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Completion Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.completionDate | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Status"
    field="status"
    width="170"
    filter="text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Status</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.status }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Reviewed By"
    field="reviewedBy"
    width="170"
    filter="text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Reviewed By</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.reviewedBy }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['desktop']"
    title=""
    class="overflow-visible-cell action-column"
    [width]="50"
    [filterable]="false"
    [sortable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="m-roster">
        <span class="m-roster__btn"
          [popper]="pmInfo"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-end'"
          [popperDisableStyle]="'true'"
        >
          <i class="fas fa-list-ul" aria-hidden="true"></i>
        </span>
        <popper-content #pmInfo class="slx-popper">
          <section class="m-roster__popper">
            <div class="flex flex-direction-row m-roster__group" *slxHiddenOn="['tablet']">
              <p class="m-roster__field">
                <span class="m-roster__title">Position</span>
                <span class="m-roster__value">{{ dataItem.position?.name }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Facility</span>
                <span class="m-roster__value">{{ dataItem.organization?.name }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group" *slxHiddenOn="['tablet']">
              <p class="m-roster__field">
                <span class="m-roster__title">Hire Date</span>
                <span class="m-roster__value">{{ dataItem.hideDate | amDateFormat: appConfig.dateFormat }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Termination Date</span>
                <span class="m-roster__value">{{ dataItem.terminationDate | amDateFormat: appConfig.dateFormat }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Employee Status</span>
                <span class="m-roster__value">{{ dataItem.employeeStatus }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Review Date</span>
                <span class="m-roster__value">
                  <span class="pm-link" (click)="onOpenReport(dataItem)">{{ dataItem.reviewDate | amDateFormat: appConfig.dateFormat }}</span>
                </span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Review Type</span>
                <span class="m-roster__value">{{ dataItem.reviewType?.name }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Start Date</span>
                <span class="m-roster__value">{{ dataItem.startDate | amDateFormat: appConfig.dateFormat }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Completion Date</span>
                <span class="m-roster__value">{{ dataItem.completionDate | amDateFormat: appConfig.dateFormat }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Status</span>
                <span class="m-roster__value">{{ dataItem.status }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Created by</span>
                <span class="m-roster__value">{{ dataItem.addedBy }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Reviewed By</span>
                <span class="m-roster__value">{{ dataItem.reviewedBy }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field m-roster__icon">
              <span class="pm-link" (click)="onEmpIconClick(dataItem.employee?.id)">
                <i class="pm-emp__icon fas fa-file-search" aria-hidden="true">&nbsp;</i>All Reviews
              </span>
            </p>
            </div>
          </section>
        </popper-content>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel [fileName]="xlsxName" [fetchData]="retriveAllPages()"></kendo-grid-excel>
  <kendo-grid-pdf [fileName]="pdfName" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

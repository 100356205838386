import { Component, EventEmitter, Input, Output, forwardRef, Optional } from '@angular/core';
import { Subscription } from 'rxjs';

import { MessagesService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-single-message',
  templateUrl: 'single-message.component.html',
})
export class SingleMessageComponent {
  @Input()
  public set isActive(value: boolean) {
    if (!this.m_isActive && value) {
      this.m_isClosed = false;
    }
    this.m_isActive = value;
  }
  public get isActive(): boolean {
    return this.m_isActive && !this.m_isClosed;
  }
  @Input()
  public level: 'warning' | 'high' = 'warning';
  @Input()
  public message: string;
  @Input()
  public description: string;
  @Input()
  public canClose: boolean = true;

  private m_isActive: boolean;
  private m_isClosed: boolean;

  @unsubscribe()
  private resetClosedSubscription: Subscription;

  constructor(@Optional() private messagesService: MessagesService) {
    if (messagesService) {
      this.resetClosedSubscription = messagesService.resetClosed$.subscribe(() => {
        this.m_isClosed = false;
      });
    }
  }
  public onClose(): void {
    this.m_isClosed = true;
  }
}

import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { KendoGridStateHelper, removeEvent, saveEvent } from '../../../../common/models/index';
import { IpRestriction } from '../../../models/ip-management/ip-restriction.model';
import { IpDownloadFile } from '../../../models/ip-management/ip-download-file.model';
import { IpManagementService } from '../../../services/ip-management/ip-management.service';
import { process, State } from '@progress/kendo-data-query';
import { NgForm, AbstractControl } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ConfirmOptions,ConfirmDialogComponent,ModalService } from '../../../../common/index';
import { IpSettings } from '../../../models/ip-management/ip-settings.model';

@Component({
  selector: 'slx-ip-whitelisting',
  templateUrl: 'ip-whitelisting.component.html',
  styleUrls: ['ip-whitelisting.component.scss']
})

export class IpWhitelistingComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<IpRestriction>;
  private subscriptions: StringMap<Subscription> = {}; 

  public ipWhitelist: IpRestriction[]=[];
  public isLoading: boolean ;

  public isCanEdit: boolean = false;
  public isEnableSave: boolean = false;
  public canDelete: boolean = true;
  public selectedRowIndex: number;
  public ipAddress: number;
  public disabled: boolean;
  public invalidIP: boolean = true;
  public isDuplicate = { show : false, inTab : null};

  public isAddMode : boolean =false;
  public isEditMode : boolean =false;
  private editedRowIndex: number;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @unsubscribe()
  private gridSaveSubscription: Subscription;

  @unsubscribe()
  private gridRemoveSubscription: Subscription;


  constructor(
    private ipManService: IpManagementService,
    private modalService: ModalService) {
      this.gridState = new KendoGridStateHelper<IpRestriction>();
      this.gridState.view = null;
      this.gridState.state.sort = [
        { field: 'ipAddress', dir: 'desc' }
      ];
  }

  public ngOnInit(): void {
    this.isLoading=true; 
   
    this.subscriptions.onLoad = this.ipManService
      .subscribeToLoading((value: boolean) => {
        this.isLoading = value;
      });

      this.subscriptions.ipManagementSettings = this.ipManService
      .subscribeToIpSettings((v:IpSettings) => {
       this.isCanEdit = v.canEdit;
      });

    this.subscriptions.ipWhitelist = this.ipManService
    .subscribeToIpWhitelist((v:IpRestriction[]) => {
     this.ipWhitelist = v;
     this.refreshGrid();
    this.isLoading=false; 
    });

    this.subscriptions.addNewIp = this.ipManService
    .subscribeToAddNewIp((v: boolean) => {
      if(v)
      {
      this.isEnableSave = false;
      this.isAddMode=true;
      this.isEditMode=false;
      this.onStartAdd();
      }
    });

    this.subscriptions.downloadWhitelistFile = this.ipManService
    .subscribeToDownloadWhitelistConfig((v:IpDownloadFile) => {
      this.downloadFile(v);
    });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });
    
    this.subscriptions.gridEditSubscription = this.gridState.onEdit$
    .subscribe((option: IpRestriction) => {     
      this.isAddMode=false;
      this.isEditMode=true;
      this.editedRowIndex = this.gridState.editedRowIndex;
      this.disabled=true;    
      this.isEnableSave = false;
    });

    this.subscriptions.gridCancelSubscription = this.gridState.onCancel$
    .subscribe(() => {
      this.disabled=true;
      this.invalidIP = false;
      this.isDuplicate = { show : false, inTab : null};

      this.isAddMode=false;
      this.isEditMode=false;

    });

    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe((item: removeEvent<IpRestriction>): void => {
      let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Confirmation',
          'Are you sure that you want to remove this IP address?',
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.doRemove(item.dataItem);
            }
          }, options);
    });

    this.gridSaveSubscription = this.gridState.onSave$.subscribe((item: saveEvent<IpRestriction>): void => {
      if (item.isNew) {
        this.doAdd(item.dataItem);       
        this.gridState.closeEditor(this.grid);           
      } else {
        this.doUpdate(item.dataItem);
        this.gridState.closeEditor(this.grid);   
      }
    });
  }

  public refreshGrid(): void {
    if (!this.ipWhitelist) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.ipWhitelist, this.gridState.state);
    this.disabled =true;
  }
  
  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.ipWhitelist, this.gridState.state).data
    }) as ExcelExportData;
  }

  private downloadFile(downloadFileConfig: IpDownloadFile): void {
    if (downloadFileConfig.tabType === "Allowlist" && downloadFileConfig.fileType === "PDF") {
      this.grid.saveAsPDF();
    } 
    if (downloadFileConfig.tabType === "Allowlist" && downloadFileConfig.fileType === "Excel") {
      this.grid.saveAsExcel();
    } 
  }

  public doAdd(ipWhitelist: IpRestriction): void {
    this.ipManService.startProgress();
    this.ipManService.addRestriction(ipWhitelist)
    .then((status: any) => {
      let data = status.data
      this.ipWhitelist.push(status.data);
      this.ipManService.updateIPList(data, true)
      this.refreshGrid();
      this.ipManService.stopProgress()
      }).catch((error: any) => {
        this.refreshGrid();
      this.ipManService.stopProgress()
      }) ;
  }

  public doUpdate(ipWhitelist: IpRestriction) {
    this.ipManService.startProgress();
    this.ipManService.updateRestriction(ipWhitelist)
    .then((status: any) => {
      let data = status.data
      this.ipManService.updateIPList(data, true)
      this.refreshGrid();
      this.ipManService.stopProgress()
      })
      .catch((error: any) => {
        this.refreshGrid();
        this.ipManService.stopProgress()
      });
  }
  
  public doRemove(ipWhitelist: IpRestriction): void {
    this.ipManService.startProgress();
    this.ipManService.deleteRestriction(ipWhitelist.id)
    .then((status: any) => {
      _.remove(this.ipWhitelist, function (ip) {
        return ip.ipAddress == ipWhitelist.ipAddress;
      });
      this.ipManService.updateIPAddress(ipWhitelist.id);
      this.refreshGrid();
      this.ipManService.stopProgress()
      }).catch((error: any) => {
        this.refreshGrid();
        this.ipManService.stopProgress()
      });
  }

  public onStartAdd(): void { 
    this.gridState.closeEditor(this.grid);
    const ipWhiteList: IpRestriction = new IpRestriction();
    ipWhiteList.isEnabled=false;
    ipWhiteList.useInGo=false;
    ipWhiteList.useInV6=false;
    ipWhiteList.isAllowRestriction=true;
    this.grid.addRow(ipWhiteList);
    this.ipManService.addNewIp(false);
  }

  public onChangeIPStatus(e: any,item: IpRestriction){ 
    item.isEnabled=e; 
    this.onChangeWhiteListIPAddress(item.ipAddress, item);
  }

  public onChangeWhiteListIPAddress(e: any,item: IpRestriction){ 
    let validIp;
    let isDuplicate;
    
    if (this.isEditMode){
       validIp = this.ipManService.checkIpValidation(e);
       isDuplicate = this.ipManService.checkDuplicateToEdit(e,item.id);
    }
    else if(this.isAddMode){
      validIp = this.ipManService.checkIpValidation(e);
      isDuplicate = this.ipManService.checkDuplicateToEdit(e,null);
    }

    if(validIp){
      this.invalidIP = false;
      if(!isDuplicate){
        this.isDuplicate = {show : false, inTab : null};
      }
      else{
        this.isDuplicate = isDuplicate;
      }
      this.invalidIP = false;
    }
    else{
      this.invalidIP = true;
      this.isDuplicate = {show : false, inTab : null};
    }
    this.enableSave();
  }

  public onChangeWhiteListDescription(e: IpRestriction){
    this.onChangeWhiteListIPAddress(e.ipAddress, e);
  }

  public onChangeWhiteListCheckBox(e: IpRestriction){
    this.onChangeWhiteListIPAddress(e.ipAddress, e);
  }

  public enableSave(){
    if(!this.invalidIP && !this.isDuplicate.show){
      this.isEnableSave = true;
    }
    else{
      this.isEnableSave = false;
    }
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

}


import {timer as observableTimer,  Observable ,  Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { PopperContent, PopperController } from 'ngx-popper';
import { IApplicationConfig, appConfig } from '../../../app.config';
import { unsubscribe } from '../../../core/decorators/index';
import { mutableSelect } from '../../../core/decorators/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { ApplicationStateBusService, TimeclockDataService } from '../../../organization/services/index';
import { TimeclockDailySummaryContainer, ITimeclockDailySummary } from '../../../organization/models/index';
import { AppSettingsManageService } from '../../../app-settings/services';

@Component({
  moduleId: module.id,
  selector: 'slx-header-nav-time-element',
  templateUrl: 'header-nav-time-element.component.html',
  styleUrls: ['header-nav-time-element.component.scss']
})

export class HeaderNavTimeElementComponent implements OnInit {

  @Input()
  public popperPosition: string = 'bottom-end';

  @Input()
  public showTimeclocksCount: number = 5;

  public loadError: boolean;

  public currentTime: moment.Moment;
  public appConfig: IApplicationConfig;

  public totalRecordsCount: number;

  public timeclockDailySummaryContainer: TimeclockDailySummaryContainer;
  public displayedRecords: ITimeclockDailySummary[];

  @ViewChild('popperContent', {static: true})
  public popperContent: PopperContent;

  public popupShown: boolean;

  public showExclamation: boolean;
  public showTimeclocks: boolean = true;

  public popperModifiers: any = {
    arrow: {
      order: 500,
      enabled: true,
      element: '[x-arrow]'
    }
  };

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @unsubscribe()
  private timeSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private errorSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private stateBusSubscription: Subscription;

  private orgLevel: OrgLevel;

  private escCode: number = 27;

  private isLoading: boolean;

  private isLocalTimeShown: boolean = true;
  private currentOffset: number;
  
  public useTZnotOffset: boolean = false;
  private currentTimezone:string;
  public timezoneCode:string;

  constructor(
    private timeclockDataService: TimeclockDataService,
    private stateBus: ApplicationStateBusService,
    private appSettingsManageService: AppSettingsManageService
  ) {}

  public ngOnInit(): void {
    this.appSettingsManageService.getAppServerConfig()
    .then((appConfig) => {
      this.useTZnotOffset = appConfig.useTZnotOffset;
    });
    
    this.timeclockDataService.init();

    this.appConfig = appConfig;
    this.currentTime = moment();

    this.stateBusSubscription = this.stateBus.orgLevelsLoaded$.subscribe((orgLevels: OrgLevel[]) => {

      this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
        if (orgLevel && _.isNumber(orgLevel.id)) {
          this.orgLevel = orgLevel;
          this.getTimeStatus(orgLevel);
          this.getTimezone(orgLevel);
          this.updateTime();
        }
      });

    });

    this.timeSubscription = observableTimer(1000, 1000).subscribe(() => {
      this.updateTime();
    });

    this.errorSubscription = this.timeclockDataService.onError$.subscribe(() => {
      this.loadError = true;
      this.showExclamation = true;
    });

    this.loadedSubscription = this.timeclockDataService.onLoaded$
      .subscribe((timeclocksContainer: TimeclockDailySummaryContainer) => {
        this.loadError = false;
        this.timeclockDailySummaryContainer = timeclocksContainer;
        this.getDisplayedRecords();
        this.showExclamation = false;
        _.each(this.displayedRecords, (r: ITimeclockDailySummary) => {
          if (r.communicationStatus === 0) {
            this.showExclamation = true;
          }
        });
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.timeclockDataService.dispose();
  }

  public get iconClass(): string {
    return this.isLocalTimeShown ? 'fal fa-user ' : 'fal fa-building ';
  }

  public get iconTitle(): string {
    let msg: string;
    if (this.loadError) {
      msg = 'There may be a delay communicating with timeclocks. Timeclock data may not be up to date.';
    } else {
      msg = this.isLocalTimeShown ? 'User\'s Local Time' : 'Local Time at Facility';
    }
    return msg;
  }

  public onKeyup(event: KeyboardEvent): void {
    if (event.keyCode === this.escCode) {
      this.popperContent.hide();
    }
  }

  public onCloseFromList(): void {
    if (this.popperContent) this.popperContent.hide();
  }

  public onShown(popper: PopperController): void {
    this.popupShown = true;
    //this.popperOnShow.emit(popper);
  }

  public onHidden(popper: PopperController): void {
    this.popupShown = false;
    //this.popperOnHide.emit(popper);
  }

  private getDisplayedRecords(): void {
    this.timeclockDailySummaryContainer.records = _.orderBy(this.timeclockDailySummaryContainer.records, ['isVirtual', 'communicationStatus', 'sortName']);
    if (this.timeclockDailySummaryContainer.records && this.timeclockDailySummaryContainer.records.length > 0) {
      this.totalRecordsCount = this.timeclockDailySummaryContainer.records.length;
      if (this.showTimeclocksCount > 0) {
        this.displayedRecords = this.timeclockDailySummaryContainer.records.slice(0, Math.min(this.showTimeclocksCount, this.timeclockDailySummaryContainer.records.length));
      }
    } else {
      this.displayedRecords = [];
      this.totalRecordsCount = 0;
    }
  }

  private getTimeStatus(orgLevel: OrgLevel): void {
    if (!orgLevel) return;

    this.isLocalTimeShown = !(orgLevel.type === OrgLevelType.organization || orgLevel.type === OrgLevelType.department);
    if (!this.isLocalTimeShown) {
      this.currentOffset = +_.get(orgLevel, 'location.timeZoneOffset') || 0;
    }
  }

  private getTimezone(orgLevel: OrgLevel):void{
    let localTimezone = moment.tz.guess();
    if (!orgLevel) return;
    if(orgLevel.type === OrgLevelType.organization || orgLevel.type === OrgLevelType.department){
      this.currentTimezone = orgLevel.location ? _.get(orgLevel, 'location.timeZone') : localTimezone;
      this.timezoneCode = orgLevel.location ? orgLevel.location.tzShortName : moment.tz(localTimezone).format('zz') ;
    }else{
      this.currentTimezone = localTimezone;
      this.timezoneCode = moment.tz(localTimezone).format('zz');
    }
  }

  private updateTime(): void {
    if (this.orgLevel) {
      let locaTime: moment.Moment = moment();
      if (this.isLocalTimeShown) {
        this.currentTime = locaTime;
      } else {
        if (this.currentOffset !== 0 && !this.useTZnotOffset) {
          locaTime = locaTime.utcOffset(this.currentOffset);
        }
        this.currentTime = this.useTZnotOffset ? moment.tz(this.currentTimezone) : locaTime;
      }
    }
  }

  onClick(event: MouseEvent): void {
    if(this.useTZnotOffset){
      const button = event.target as HTMLElement;
      const rect = button.getBoundingClientRect();
      const buttonMid = rect.left + (rect.width / 2);
  
      if (event.clientX < buttonMid) {
        this.showTimeclocks = true;
      } else {
        this.showTimeclocks = false;
      }
    }else{
      this.showTimeclocks = true;
    }
    
  }
}

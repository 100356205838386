<label class="slx-drodown-list" [ngClass]="{ 'disabled': readonly }">
  <span *ngIf="isPlaceholder()" class="slx-drodown-list__label">{{ placeholder }}</span>

  <div [class.active]="isInvalidType" class="slx-drodown-list__container">
    <kendo-dropdownlist class="slx-drodown-list__list"
      #dropdownlist
      name="dropdownField"
      [defaultItem]="defaultItem"
      [data]="items"
      [textField]="titleField"
      [valueField]="valueField"
      [valuePrimitive]="valuePrimitive"
      [(ngModel)]="selectedItem"
      [disabled]="readonly"
      [listHeight]="listHeight"
      (open)="onOpen()"
      (close)="onClose()"
      (blur)="onBlur()"
      [virtual]="virtual"
    >
    <ng-template *ngIf="hasValueIcon" kendoDropDownListValueTemplate let-dataItem>
      <i aria-hidden="true" [class]="valueIconClass">&nbsp;</i>
       {{dataItem? dataItem.name ? dataItem[titleField] : null : null}} 
      </ng-template>

    <ng-template kendoDropDownListItemTemplate let-dataItem >
        <span class="slx-drodown-list-item">{{ valuePrimitive ? dataItem : dataItem[titleField] }}</span>
      </ng-template>
    </kendo-dropdownlist>
 
    <button *ngIf="icon" class="slx-drodown-list__icon iconButton" (blur)="handleIconButtonBlur()" (click)="toggleDropdown()">
      <i [class]="icon" aria-hidden="true"></i>
    </button>

  </div>

</label>
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { moment } from '../../../../common';
import { DateTimeService } from '../../../../common/services/date-time/date-time.service';
import { dateTimeUtils } from '../../../../common/utils/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { ScheduledShiftDefinition, Shift, TaAbsenceCode } from '../../../../organization/models/index';
import { DailyAbsence, TimeCardDisplayOptions, TimeCardModel } from '../../../models/index';
import { DailyTimecardManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-timecard-absences-editor',
  templateUrl: 'timecard-absences-editor.component.html',
  styleUrls: ['timecard-absences-editor.component.scss'],
})
export class TimeCardAbsencesEditorComponent implements OnInit, OnDestroy {

  @Input()
  public dataItem: DailyAbsence;

  public minDateLimit: Date;
  public maxDateLimit: Date;
  public appConfig: IApplicationConfig;
  public absenceCodesLookup: TaAbsenceCode[];
  public shiftsLookup: Shift[];
  public displayOptions: TimeCardDisplayOptions;
  public schedules: ScheduledShiftDefinition[];
  public isOverlapped: boolean = false;
  public isOverlappedAbsencestartdate: boolean = false;
  public isOverlappedAbsenceenddate: boolean = false;
  public isOverlappedAbsenceshift: boolean = false;
  public isOverlappedAbsencesDB: boolean = false;
  public dateon: Date;
  public PreventOverlappingAbsences: boolean;
  @ViewChild('absencesForm', { static: true })
  public absencesForm: UntypedFormGroup;

  private m_model: TimeCardModel;
  public uneditedItem: DailyAbsence;
  public uneditedAbsences: DailyAbsence[];
  @unsubscribe()
  private modelSubscription: Subscription;
  @unsubscribe()
  private absenceSubscription: Subscription;
  @unsubscribe()
  private optionsChangedSubscription: Subscription;

  constructor(
    private managementService: DailyTimecardManagementService,
    private dateTimeService: DateTimeService,
    private appSettingsManageService: AppSettingsManageService,
  ) {
    this.shiftsLookup = managementService.shiftsLookup;
    this.absenceCodesLookup = managementService.absenceCodesLookup;
    this.appConfig = appConfig;
    this.schedules = managementService.dataModel.schedule;
    this.dateon = managementService.dateOn;
  }

  public ngOnInit(): void {
    this.modelSubscription = this.managementService.onLoaded$
      .subscribe((model: TimeCardModel) => {
        this.m_model = model;
        this.maxDateLimit = moment(model.date).add(1, 'd').toDate();
        this.minDateLimit = moment(model.date).subtract(1, 'd').toDate();
        if (!this.uneditedAbsences) {
          this.uneditedAbsences = this.m_model.absences;
        }
      });
    //GET PreventOverlappingAbsences FROM SETTINGS API
    this.appSettingsManageService.getAppServerConfig()
      .then((config) => {
        this.PreventOverlappingAbsences = config.preventoverlappingabsences;
      });
    this.optionsChangedSubscription = this.managementService.displayOptionSelected$
      .subscribe((options: TimeCardDisplayOptions) => {
        this.displayOptions = options;
      });

    let shift = this.GetDefaultShift();
    if (shift !== undefined && this.dataItem.shift === undefined) {
      this.dataItem.shift = shift;
      if (!this.dataItem.absence) {
        this.onShiftChanged(this.dataItem);
      }
    }
    if (!this.uneditedItem) {
      this.uneditedItem = _.cloneDeep(this.dataItem);
    }
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onStartDateChanged(dataItem: DailyAbsence): void {
    dataItem.calculateInterval();
    dataItem.copyIntervalToRaw();
    this.checkIntervalError(dataItem);
    if (this.PreventOverlappingAbsences) {
      this.CheckOverlappedAbsenceTime(dataItem, 'startdate');
    }
  }

  public onEndDateChanged(dataItem: DailyAbsence): void {
    dataItem.calculateInterval();
    dataItem.copyIntervalToRaw();
    this.checkIntervalError(dataItem);
    if (this.PreventOverlappingAbsences) {
      this.CheckOverlappedAbsenceTime(dataItem, 'enddate');
    }
  }

  public onIntervalChanged(dataItem: DailyAbsence, hours: number): void {
    //if (hours > 24) hours = 24;
    let ms: number = hours * 60 * 60 * 1000;
    dataItem.interval = ms;
    this.calculateEndDate(dataItem);
    dataItem.copyIntervalToRaw();
    this.checkIntervalError(dataItem);
    if (this.PreventOverlappingAbsences) {
      this.CheckOverlappedAbsenceTime(dataItem, 'enddate');
    }
  }

  checkIntervalError(dataItem: DailyAbsence): void {
    setTimeout(() => {
      const intervalControl = this.absencesForm.controls['interval'];
      if (!intervalControl) {
        return;
      }
      if (dataItem.updateSchedule && dataItem.interval < 0 && dataItem.unpaidInterval > 0) {
        intervalControl.setErrors({ ['paidPeriod']: true });
      } else {
        intervalControl.setErrors(null);
      }
    }, 100);
  }

  public onIntervalHMChanged(dataItem: DailyAbsence, value: Date): void {
    let interval = dateTimeUtils.getIntervalFromIntervalTime(value, 'h');
    this.onIntervalChanged(dataItem, interval);
  }

  public onTypeChanged(dataItem: DailyAbsence): void {
    dataItem.id = dataItem.absence.id;
    dataItem.name = dataItem.absence.description;
    dataItem.checkHasLinkedSa();
    if (!dataItem.hasLinkedSa) {
      dataItem.updateSchedule = false;
    } else {
      dataItem.updateSchedule = this.managementService.lastUpdateScheduleSelection;
    }
    this.checkIntervalError(dataItem);
    if (this.PreventOverlappingAbsences) {
      this.CheckOverlappedAbsenceTime(dataItem, 'scheduleshift');
    }
  }

  public onUpdateScheduleChanged(dataItem: DailyAbsence): void {
    this.managementService.saveLastUpdateScheduleOption(dataItem.updateSchedule);
    this.checkIntervalError(dataItem);
  }

  public onShiftChanged(dataItem: DailyAbsence): void {
    this.isOverlapped = false;
    if (!dataItem.shift) {
      return;
    }
    this.isOverlapped = this.CheckOverlappedShifts(dataItem.shift);
    let dateRange: { start: Date, end: Date } = dateTimeUtils.getDateRangeFromTimeRange(dataItem.start, dataItem.shift.start, dataItem.shift.end);
    dataItem.start = dateRange.start;
    dataItem.end = dateRange.end;
    dataItem.calculateInterval();
    dataItem.copyIntervalToRaw();
    this.checkIntervalError(dataItem);
    if (this.PreventOverlappingAbsences) {
      this.CheckOverlappedAbsenceTime(dataItem, 'scheduleshift');
    }
    dataItem.checkHasLinkedSa();
    if (!dataItem.hasLinkedSa) {
      dataItem.updateSchedule = false;
    } else {
      dataItem.updateSchedule = this.managementService.lastUpdateScheduleSelection;
    }
  }
  public CheckOverlappedAbsenceTime(dataItem: DailyAbsence, dateString: String) {
    let i: number = 0;
    let date: Date = dataItem.end;
    this.isOverlappedAbsencestartdate = false;
    this.isOverlappedAbsenceenddate = false;
    this.isOverlappedAbsenceshift = false;
    this.isOverlappedAbsencesDB = false;
    if (dateString === 'startdate') {
      date = dataItem.start;
    } else if (dateString === 'enddate') {
      date = dataItem.end;
    }
    if (date != null) {
      const allAbsence = this.uneditedAbsences.filter(x => !(x.id === this.uneditedItem.id && x.start.toString() === this.uneditedItem.start.toString() && x.end.toString() === this.uneditedItem.end.toString()));
      _.times(allAbsence.length, () => {
        if (this.isTimeOverlapped(dataItem, allAbsence[i])) {
          if (dateString === 'startdate') {
            this.isOverlappedAbsencestartdate = true;
          }
          if (dateString === 'enddate') {
            this.isOverlappedAbsenceenddate = true;
          }
          if (dateString === 'scheduleshift') {
            this.isOverlappedAbsenceshift = true;
          }
        }
        i++;
      });
    }
  }

  private isTimeOverlapped(dataItem: DailyAbsence, allAbsence: DailyAbsence) {
    let date1: moment.Moment = moment(dataItem.start);
    let date2: moment.Moment = moment(dataItem.end);
    let date3: moment.Moment = moment(allAbsence.start);
    let date4: moment.Moment = moment(allAbsence.end);
    if (((this.dateTimeService.isDateRangeOverlapped(moment(date1), moment(date2), moment(date3), moment(date4), true)) || (moment.range(date3, date4).contains(date1) || moment.range(date3, date4).contains(date2)))) {
      return true;
    } else return false;
  }
  private calculateEndDate(item: DailyAbsence): void {
    item.end = new Date(item.interval + item.unpaidInterval + item.start.getTime());
    item.calculateInterval();
  }
  private CheckOverlappedShifts(shift: Shift): boolean {
    let isOverlapped = false;
    this.schedules.forEach((schedule: ScheduledShiftDefinition) => {
      let shiftDateRange: { start: Date, end: Date } = dateTimeUtils.getDateRangeFromTimeRange(this.dateon, shift.start, shift.end);
      let schedulerange = moment.range(schedule.start, schedule.end);
      let shiftRange = moment.range(shiftDateRange.start, shiftDateRange.end);
      if (!shiftRange.isSame(schedulerange) && shiftRange.overlaps(schedulerange)) {
        isOverlapped = true;
      }
    });
    return isOverlapped;
  }

  private GetDefaultShift(): Shift {
    let defaultshift: Shift;
    if (this.schedules[0] !== undefined && this.dataItem.id === undefined) {
      let schedulerange = moment.range(this.schedules[0].start, this.schedules[0].end);
      this.shiftsLookup.forEach((shift: Shift) => {
        let shiftDateRange: { start: Date, end: Date } = dateTimeUtils.getDateRangeFromTimeRange(this.dateon, shift.start, shift.end);
        let shiftRange = moment.range(shiftDateRange.start, shiftDateRange.end);
        if (shiftRange.isSame(schedulerange)) {
          defaultshift = shift;
          return defaultshift;
        }
      });
    } else if (this.shiftsLookup !== undefined) {
      let daterange = moment.range(this.dataItem.start, this.dataItem.end);
      let closeRange: number;
      let startTimeDiff: number;
      for (let shift of this.shiftsLookup) {
        let shiftDateRange: { start: Date, end: Date } = dateTimeUtils.getDateRangeFromTimeRange(this.dateon, shift.start, shift.end);
        let shiftRange = moment.range(shiftDateRange.start, shiftDateRange.end);
        if (shiftRange.isSame(daterange)) {
          defaultshift = shift;
          break;
        } else {
          const timeDiff = Math.abs(daterange.diff('seconds') - shiftRange.diff('seconds'));
          const startRange = moment.range(this.dataItem.start, shiftDateRange.start);
          const startDiff = Math.abs(startRange.diff('seconds'));
          if (startTimeDiff === undefined || startDiff <= startTimeDiff) {
            startTimeDiff = startDiff;
            if (closeRange === undefined || timeDiff <= closeRange) {
              closeRange = timeDiff;
              defaultshift = shift;
            }
          }
        }
      }
    }
    return defaultshift;
  }
}

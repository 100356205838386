export * from './holidays.component';
export * from './holidays-editor/holidays-editor.component';
export * from './holidays-copy/holidays-copy.component';

import { HolidaysComponent } from './holidays.component';
import { HolidaysEditorComponent } from './holidays-editor/holidays-editor.component';
import { HolidaysCopyComponent } from './holidays-copy/holidays-copy.component';

export const holidaysComponents: any = [
  HolidaysComponent,
  HolidaysEditorComponent,
  HolidaysCopyComponent
];

<kendo-grid #kendoGridElem class="slx-blue-grid slx-grid-slim-rows slx-full-height"
  [ngClass]="{ 'slx-grid-indents': applyStyle }"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [pageable]="true"
  [pageSize]="pageSize"
  (stateRestored)="gridState.dataStateChange($event)"
  [skip]="gridState.state.skip"
  [groupable]="true"
  [group]="gridState.state.group"
  selectable="true"
  (selectionChange)="gridState.selectionChange($event)"
  [slxKendoGridState]="gridState.gridDefinition"
  [slxKendoGridStatePageSize]="pageSize"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  (dataStateChange)="gridState.dataStateChange($event)"
  (groupChange)="groupStateChanged ($event)"
  [slxKendoGridColumnsGroup]="'genericGridGroup'"
>
  <ng-template kendoGridToolbarTemplate position="top">
    <ng-content></ng-content>
  </ng-template>
  <ng-template ngFor let-fieldDef [ngForOf]="(listDefinitions?.fields) | values">
    <kendo-grid-column *ngIf="!(fieldDef.value.hidden)" slxKendoGridColumnHiddenState
    [sortable]="fieldDef.value.fieldType!=='check'" title="{{fieldDef.value.displayName}}"
      filter="{{getFilter(fieldDef.value.fieldType)}}" field="fields['{{fieldDef.key}}'].value.value" width="150"
      [filterable]="fieldDef.value.fieldType!=='check'" [class]="columnCustomCssClass">
      <ng-template kendoGridHeaderTemplate>
        <input *ngIf="fieldDef.value.fieldType==='check'" name="selectAll" type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onToggleAllSelected(isAllSelected)">
        <span class="header-title" *ngIf="fieldDef.value.fieldType!=='check'">{{fieldDef.value.displayName}}</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <slx-generic-cell
          [genRow]="dataItem"
          [fieldDef]="dataItem.fields | property: fieldDef.key | property: 'field'"
          [disableEmployeeLink]="disableEmployeeLink"
          (employeeLinkClick)="onEmployeeLinkClick($event)"
          (selectionChanged)="selectionChange($event)"
        ></slx-generic-cell>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter *ngIf="fieldDef.value.fieldType !== 'date' && fieldDef.value.fieldType !== 'datetime' && fieldDef.value.fieldType !== 'number'" [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
        <slx-kendo-grid-date-filter *ngIf="fieldDef.value.fieldType === 'date' || fieldDef.value.fieldType === 'datetime'" [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-date-filter>
        <slx-kendo-grid-number-filter *ngIf="fieldDef.value.fieldType === 'number'" [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>
    </kendo-grid-column>
  </ng-template>
  <kendo-grid-excel *ngIf="isExcelExportConfigured" [fileName]="genericGridConfig.excelExport.fileName" [fetchData]="exportExcelData()"></kendo-grid-excel>
  <kendo-grid-pdf *ngIf="isPdfExportConfigured" slxKendoGridPdfSuppressIndicator [fileName]="genericGridConfig.pdfExport.fileName" [allPages]="genericGridConfig.pdfExport.allPages"
    [paperSize]="genericGridConfig.pdfExport.paperSize" [landscape]="genericGridConfig.pdfExport.landscape" [scale]="genericGridConfig.pdfExport.scale"
    [repeatHeaders]="genericGridConfig.pdfExport.repeatHeaders">
    <kendo-grid-pdf-margin [top]="genericGridConfig.pdfExport.top" [left]="genericGridConfig.pdfExport.left" [right]="genericGridConfig.pdfExport.right"
      [bottom]="genericGridConfig.pdfExport.bottom"></kendo-grid-pdf-margin>
    <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
      <div class="page-template">
        <div class="footer">
          Page {{ pageNum }} of {{ totalPages }}
        </div>
      </div>
    </ng-template>
  </kendo-grid-pdf>
  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import { Component, Input, OnInit, Provider } from '@angular/core';
import * as _ from 'lodash';

import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/index';

import { DialogOptions, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { ShiftReplacementManagementService } from '../../../services/index';
import { ShiftReplacementRequest, ShiftReplacementSettings, ShiftEligibleEmployee, ShiftOpenCmd } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-shift-replacement-open',
  templateUrl: 'shift-replacement-open.component.html',
  styleUrls: ['shift-replacement-open.component.scss']
})
export class ShiftReplacementOpenComponent implements IDialog, OnInit {
  public options: DialogOptions;
  public dialogResult: boolean;
  public settings: ShiftReplacementSettings;
  public selectedEmployees: ShiftEligibleEmployee[];


  @unsubscribe()
  private selectedEmployeesSubscription: Subscription;
  private shiftManagementService: ShiftReplacementManagementService;
  private modalService: ModalService;
  private appConfig: IApplicationConfig;

  public static openDialog(request: ShiftReplacementRequest, unitId: number, modalService: ModalService, callback: (result: boolean, cmd: ShiftOpenCmd) => void): ShiftReplacementOpenComponent {

    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 600;
    dialogOptions.width = 1200;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ShiftReplacementRequest,
        useValue: request
      }
    ];
    let dialog: ShiftReplacementOpenComponent = modalService.globalAnchor
      .openDialog(ShiftReplacementOpenComponent, 'Open Shift', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        let cmd: ShiftOpenCmd;
        if (result) {
          cmd = new ShiftOpenCmd();
          cmd.shiftId = request.shiftId;
          cmd.positionId = request.positionId;
          cmd.unitId = unitId;
          cmd.dateOn = request.date;
          cmd.employeeIds = _.map(dialog.selectedEmployees, (emp: ShiftEligibleEmployee) => {
            return emp.employee.id;
          });
        }
        callback(result, cmd);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    request: ShiftReplacementRequest,
    shiftManagementService: ShiftReplacementManagementService
  ) {
    this.modalService = modalService;
    this.options = options;
    this.shiftManagementService = shiftManagementService;
    this.settings = new ShiftReplacementSettings();
    this.settings.createColumns();
    this.settings.request = request;
    this.settings.isMultipleSelectionMode = true;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.selectedEmployeesSubscription = this.shiftManagementService.subscribeToSelectedEmployees((selected: ShiftEligibleEmployee[]) => {
      this.selectedEmployees = selected;
    });
  }
  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}

import { Component, OnDestroy, Input } from '@angular/core';
import { ICollapssiblePanel } from '../../../../common/components/collapsible-panel/collapsible-panel.component';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/index';
import { EmployeeDefinition } from '../../../../organization/models/index';

import { DailyTimecardManagementService } from '../../../services/index';
import { TimeCardModel } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-timecard-employee-info',
  templateUrl: 'daily-timecard-employee-info.component.html',
  styleUrls: ['daily-timecard-employee-info.component.scss']
})
export class DailyTimeCardEmployeeInfoComponent implements OnDestroy {

  public employee: EmployeeDefinition;
  public model: TimeCardModel;
  public appConfig: IApplicationConfig;
  @unsubscribe()
  private subscription: Subscription;

  constructor(private managementService: DailyTimecardManagementService) {
    this.appConfig = appConfig;
    this.subscription = this.managementService.onLoaded$.subscribe((model: TimeCardModel) => {
      this.model = model;
      this.employee = model.employee;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public navigateToUnapprovedPaycodes(): void {
    this.managementService.navigateToUnapprovedPaycodes();
  }

  public navigateToUnapprovedOvertimes(): void {
    this.managementService.navigateToUnapprovedOvertimes(this.model.primaryPosition.orgLevelId);
  }
}


import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, forwardRef } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../app.config';

import { AbsenceModel, EditableListActionKind } from '../../../models/index';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-absence-renderer',
  templateUrl: 'configure-absence-renderer.component.html',
  styleUrls: ['configure-absence-renderer.component.scss'],
  providers: [
    {
      provide: CustomListActorBase,
      useExisting: forwardRef(() => ConfigureAbsenceRendererComponent)
    }
  ]
})
export class ConfigureAbsenceRendererComponent extends CustomListActorBase implements OnInit {

  @Input()
  public set context(value: any) {
    this.item = value.item;
    this.rowIndex = value.rowIndex;
  }

  @Input()
  public item: AbsenceModel;

  @Input()
  public rowIndex: number;

  @Input()
  public editable: boolean = true;

  @Input()
  public selectable: boolean = true;


  public get appConfig(): IApplicationConfig {
    return this.m_appConfig;
  }
  private m_appConfig: IApplicationConfig;

  public ngOnInit(): void {
    this.m_appConfig = appConfig;
  }

  public onEditClick(): void {
    this.actionEmitter.emit(EditableListActionKind.START_EDIT);
  }

  public onItemSelect(): void {
    this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
  }

}

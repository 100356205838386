import { Component, OnInit, Input } from '@angular/core';
import { IApplicationConfig, appConfig } from '../../../../app.config';
import { TimeclockDailySummary } from '../../../../organization/models/index';
import { ITimeClock, TimeClock } from './../../../../../app/time-and-attendance/models';
import { ModalService } from './../../../../../app/common';
import { TimeclockRebootDialogComponent } from './timeclock-reboot-dialog/timeclock-reboot-dialog.component';
import {
    IRebootStatus,
    TimeclockDataService,
  } from '../../../../organization/index';
  import { unsubscribe } from '../../../../core/decorators/index';
  import { Subscription } from 'rxjs';
import { TaSignalrService } from '../../../../../app/time-and-attendance/services/signalR/ta-signalr-service';
import { signalrResp } from '../../../../../app/time-and-attendance/models/timeclock/signalrResp';
import { SessionService } from '../../../../../app/core/services';

@Component({
    moduleId: module.id,
    selector: 'slx-timeclock-item',
    templateUrl: 'timeclock-item.component.html',
    styleUrls: [
        'timeclock-item.component.scss'
    ]
})
export class TimeclockItemComponent implements OnInit {

    @unsubscribe()
    private isClockRebooted: Subscription;

    @Input()
    public timeclockDailySummary: TimeclockDailySummary;

    @Input()
    public expandable: boolean = true;

    public modalService: ModalService;
    private _timeclockDataService: TimeclockDataService;
    public rebootApiStatus:boolean ;
    public get expanded(): boolean {
        return !this.expandable || this.m_expanded;
    }
    public appConfig: IApplicationConfig;
    private m_expanded: boolean;

    constructor(modalService: ModalService,timeclockDataService: TimeclockDataService,
        private taSignalrService : TaSignalrService,
        private sessionService: SessionService) {
        this.modalService = modalService;
        this._timeclockDataService=timeclockDataService;
        this.rebootApiStatus=false;
    }

    public ngOnInit(): void {
        this.appConfig = appConfig;        
    }

    public toggleExpand(): void {
        this.m_expanded = !this.m_expanded;
    }

    public clockRebootDialog( clockId: number,clockName:string) {
        let timeClock: TimeClock = new TimeClock();
        timeClock.clockId = clockId;
        timeClock.clockName = clockName;
        timeClock.isClockRebootStarted = this.timeclockDailySummary.isTimeClockRebootStarted;
        TimeclockRebootDialogComponent.openDialog(timeClock, this.modalService,(result: boolean, request: ITimeClock) => {
            if(result)
            {
               this.rebootApiStatus = true;
               this.timeclockDailySummary.isTimeClockRebootStarted = true;
               var responce= this.rebootClockService(this._timeclockDataService.orgLevel.id,clockId);
               this._timeclockDataService.rebootApiStatus.subscribe(message => {
                if(this.timeclockDailySummary.id === clockId )
                this.timeclockDailySummary.isTimeClockRebootStarted = message;
            });
            }
        });
    }

   

    private rebootClockService(orgLevelId: number,clockId:number) : IRebootStatus{
        var response = this._timeclockDataService.rebootClock(orgLevelId,clockId); 
        if(response){
            return response
        }       
      }
}

<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <div class="slx-high-box__body flex flex__grow">
      <kendo-grid #optionsGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
                  [data]="gridState?.view"
                  [filterable]="'menu'"
                  [sortable]="true"
                  [skip]="gridState.state.skip"
                  [sort]="gridState.state.sort"
                  [filter]="gridState.state.filter"
                  (dataStateChange)="gridState.dataStateChange($event)">

        <kendo-grid-column title="Description" width="200" field="type">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Description</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ dataItem.type }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Code" width="180" field="code">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Code</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ dataItem.code }}
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
                  [data]="formulaGridState.view"
                  [skip]="formulaGridState.state.skip"
                  (dataStateChange)="formulaGridState.dataStateChange($event)">

        <kendo-grid-column title="Employee Contribution" width="200" field="employeeContribution"
                           filter="text" [sortable]="true">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Employee Contribution</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ employeeContribution | number: ".2-2"| slxMoney }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Employer Contribution" width="200" field="employerContribution">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Employer Contribution</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ employerContribution | number: ".2-2"| slxMoney }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Coverage" width="200" field="coverage">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Coverage</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ coverage | number: ".2-2"| slxMoney }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filterMenuTitle="Filter">
        </kendo-grid-messages>  
      </kendo-grid>
    </div>
  </div>
</slx-spinner>

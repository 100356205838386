import * as _ from 'lodash';
import * as moment from 'moment';
import { TreeModel } from '../../tree/models/tree.model';
import { EmployeeSearchMapService } from '../../../services/index';
import { TreeNodeModel } from '../../tree/models/index';
import { LogicalExpressionModel } from './logical-expression.model';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { SearchCategory } from '../../../../../organization/models/lookup/index';
import { ComparisionOperator } from './logical-expression.model';
import { SearchOperator } from '../../../../employee-search/components';

export class QueryModel extends TreeModel {

    public categories: any[] = [];
    public allowNested: boolean = false;

    private rootForm: UntypedFormGroup;
    private formChildren: UntypedFormArray;

    private mapService: EmployeeSearchMapService;

    //travesal of tree  with brute force can be O(n^2 + n) or O(n^2) atleast..
    // so tracking flattened list of nodes for some operations;
    private flatNodesList: TreeNodeModel[] = [];

    public static getDefaultOperator(operators: string[]): string {
        let defaultOperator: string;
        _.each(operators, (operator: string) => {
            if (operator === ComparisionOperator[ComparisionOperator.Contains]) defaultOperator = operator;
        });
        if (defaultOperator === undefined) defaultOperator = operators[0];
        return defaultOperator;
    }

    constructor(mapService: EmployeeSearchMapService) {

        super();

        this.mapService = mapService;

        this.showRoot = false;
        let rootExpression: LogicalExpressionModel = new LogicalExpressionModel();

        let firstChildExpression: LogicalExpressionModel = new LogicalExpressionModel();
        rootExpression.children.push(firstChildExpression);

        let firstChildFormGroup: UntypedFormGroup = this.createNodeFormGroup();

        let firstChildNodeData: NodeData = new NodeData(firstChildExpression, firstChildFormGroup, this.mapService.createLogicExpressionDTO());

        let fisrtChildNode: TreeNodeModel = new TreeNodeModel(firstChildNodeData, this.root, this);
        this.root.children.push(fisrtChildNode);

        this.formChildren = new UntypedFormArray([firstChildFormGroup]);
        let rootForm: UntypedFormGroup = this.createNodeFormGroup(true);

        rootForm.addControl('children', this.formChildren);

        let rootDTO: any = this.mapService.createLogicExpressionDTO();
        rootDTO.ChildExpressions = [firstChildNodeData.dto];
        this.root.data = new NodeData(rootExpression, rootForm, rootDTO);

        this.rootForm = rootForm;

        this.flatNodesList.push(this.root);
        this.flatNodesList.push(fisrtChildNode);
    }

    public get valid(): boolean {
        return this.rootForm.valid;
    }

    public processMouseAction(actionName: string, $event: any, node: TreeNodeModel, data: any = null): void {
        super.processMouseAction(actionName, $event, node, data);

        switch (actionName) {
            case 'addSibling':
                this.addSibling(node);
                break;
            case 'removeNode':
                this.removeNode(node);
                break;
            case 'addChild':
                this.addChild(node);
                break;
        }
    }

    public addChild(node: TreeNodeModel): void {

        let expression: LogicalExpressionModel = new LogicalExpressionModel();
        let parentData: NodeData = node.data;

        let newFormGroup: UntypedFormGroup = this.createNodeFormGroup();
        let newDTO: any = this.mapService.createLogicExpressionDTO();
        let newNodeData: NodeData = new NodeData(expression, newFormGroup, newDTO);

        let newNode: TreeNodeModel = new TreeNodeModel(newNodeData, node, this);

        parentData.expression.children.push(expression);
        node.children.push(newNode);

        parentData.dto.ChildExpressions = parentData.dto.ChildExpressions || [];
        parentData.dto.ChildExpressions.push(newDTO);

        this.addFormGroup(newFormGroup);

        newNode.depth = this.getDepth(newNode);

        this.flatNodesList.push(newNode);
    }

    public removeNode(node: TreeNodeModel): void {

        let parent: TreeNodeModel = node.parent;
        let nodeData: NodeData = node.data;
        let parentData: NodeData = parent.data;

        parentData.expression.children = _.without(parentData.expression.children, nodeData.expression);
        parent.children = _.without(parent.children, node);

        parentData.dto.ChildExpressions = _.without(parentData.dto.ChildExpressions, nodeData.dto);

        this.removeFormGroup(nodeData.formGroup);
        this.flatNodesList.splice(this.flatNodesList.indexOf(node), 1);
    }

    public addSibling(node: TreeNodeModel): void {

        let parent: TreeNodeModel = node.parent;
        let expression: LogicalExpressionModel = new LogicalExpressionModel();
        let newFormGroup: UntypedFormGroup = this.createNodeFormGroup();
        let newDTO: any = this.mapService.createLogicExpressionDTO();
        let newNodeData: NodeData = new NodeData(expression, newFormGroup, newDTO);
        let newNode: TreeNodeModel = new TreeNodeModel(newNodeData, parent, this);

        let parentExpression: LogicalExpressionModel = parent.data.expression;
        parentExpression.children.splice(parentExpression.children.indexOf(node.data) + 1, 0, expression);

        let parentExps: any[] = parent.data.dto.ChildExpressions;
        parentExps.splice(parentExps.indexOf(node.data.dto) + 1, 0, newDTO);

        parent.children.splice(parent.children.indexOf(node) + 1, 0, newNode);
        newNode.depth = this.getDepth(newNode);

        this.addFormGroup(newFormGroup);
        this.flatNodesList.push(newNode);
    }

    public prepareDTO(): any {

        _.each(this.flatNodesList, (node: TreeNodeModel) => {
            this.prepareNode(node);
        });

        return this.root.data.dto;
    }



    private prepareNode(node: TreeNodeModel): void {

        let nodeData: NodeData = node.data;
        let expression: LogicalExpressionModel = nodeData.expression;
        let formGroup: UntypedFormGroup = nodeData.formGroup;

        // save changes from form model to data model

        let formCategory: SearchCategory = formGroup.get('selectedCategory').value;
        let selectedCategory: SearchCategory = _.find(this.categories, (category: SearchCategory) => {
            return category.categoryFieldName === formCategory.categoryFieldName;
        });

        if (selectedCategory) {
            expression.searchPredicate.searchCategoryName = selectedCategory.categoryFieldName;
            let formOperator: SearchOperator = formGroup.get('selectedOperator').value;
            expression.searchPredicate.searchOperator = formOperator.displayName;

            let firstValue: any = formGroup.get('firstValue').value;
            if (firstValue instanceof Date) {
                firstValue = moment (firstValue).startOf ('day').toDate();
            }
            expression.searchPredicate.values = [firstValue];
            let secondValue: any = formGroup.get('secondValue').value;
            if (secondValue) {
                if (secondValue instanceof Date) {
                    secondValue = moment (secondValue).startOf ('day').toDate();
                }
                expression.searchPredicate.values.push(secondValue);
            }
        }

        // map data model to server dto's
        this.mapService.mapLogicatlExpressionToDTO(expression, nodeData.dto);

    }

    private createNodeFormGroup(noValidation?: boolean): UntypedFormGroup {

        if (!noValidation) {
            return new UntypedFormGroup({
                selectedCategory: new UntypedFormControl('', Validators.required),
                selectedOperator: new UntypedFormControl('', Validators.required),
                firstValue: new UntypedFormControl('', Validators.required),
                secondValue: new UntypedFormControl('')
            });
        }

        return new UntypedFormGroup({
            selectedCategory: new UntypedFormControl(''),
            selectedOperator: new UntypedFormControl(''),
            firstValue: new UntypedFormControl(''),
            secondValue: new UntypedFormControl('')
        });
    }

    private addFormGroup(group: UntypedFormGroup): void {
        this.formChildren.push(group);
    }

    private removeFormGroup(group: UntypedFormGroup): void {
        let index: number = this.formChildren.controls.indexOf(group);
        this.formChildren.removeAt(index);
    }

}

export class NodeData {

    public expression: LogicalExpressionModel;
    public formGroup: UntypedFormGroup;
    public dto: any;

    constructor(expression: LogicalExpressionModel, formGroup: UntypedFormGroup, dto: any) {
        this.expression = expression;
        this.formGroup = formGroup;
        this.dto = dto;
    }
}

import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeDefinitionsApiService } from '../../../../../../organization/services/index';
import { EmployeeShortInfo, EmployeeBenefitClass, BenefitClassDefinition } from '../../../../../../organization/models/index';
import { LookupService, EmployeeValidatorAdapter } from '../../../../../../organization/services/index';
import { Lookup, LookupType, Organization, Department } from '../../../../../../organization/models/index';
import { EmployeeToTransfer, EmployeeTransfer } from '../../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';
import { AppSettingsManageService } from '../../../../../../app-settings/services/index';
import { AppServerConfig } from '../../../../../../app-settings/model/app-server-config';
import { truncate } from 'fs';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-transfer',
  templateUrl: 'employee-transfer.component.html',
  styleUrls: ['employee-transfer.component.scss']
})
export class EmployeeTransferComponent implements OnInit {
  @Input('employee')
  public set emp(emp: EmployeeToTransfer) {
    this.employee = emp;
       this.setEmployeeId();
  }

  public employee: EmployeeToTransfer;
  public appConfig: IApplicationConfig;
  public requestToTransfer: EmployeeTransfer;
  public departmentLookup: Lookup;
  public positionLookup: Lookup;
  public shiftLookup: Lookup;
  public unitLookup: Lookup;
  public benefitClassLookup: Lookup;
  public employeeShortInfo: EmployeeShortInfo;
  public employeeBenefitClass: EmployeeBenefitClass;
  public employeeFullName: String;
  public bswiftEnabled: boolean = false;
  public PreventPayroll :boolean =false;
  public PayrollReadOnly :boolean =false;
  public isNgpUser: boolean = false;
  public config: AppServerConfig;

  public get departmentEditable(): boolean {
    return !!this.requestToTransfer.organization;
  }

  public get positionEditable(): boolean {
    return !!this.requestToTransfer.department;
  }

  public get shiftEditable(): boolean {
    return !!this.requestToTransfer.department;
  }

  public get unitEditable(): boolean {
    return !!this.requestToTransfer.department;
  }

  public get benefitClassEditable(): boolean {
    return !!this.requestToTransfer.department;
  }

  public get positionProhibitedValues(): any[] {
    if (!this.employee || !this.requestToTransfer) {
      return null;
    }
    if (!this.requestToTransfer.organization || !this.requestToTransfer.department) {
      return null;
    }

    if (this.employee.organizationId === this.requestToTransfer.organization.id && this.employee.departmentId === this.requestToTransfer.department.id) {
      return [{ id: this.employee.positionId }];
    }
    return null;
  }

  public employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter;

  public get payrollNumberValidation(): string {
    return EmployeeValidatorAdapter.payrollNumberValidation;
  }

  @ViewChild('form')
  public ngForm: NgForm;
  private lookupService: LookupService;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;

  constructor(
    lookupService: LookupService,
    employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter,
    private appSettingsManageService: AppSettingsManageService
  ) {
    this.lookupService = lookupService;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.requestToTransfer = new EmployeeTransfer();
    this.requestToTransfer.date = null;
    this.setEmployeeId();
    this.getSettings();
    this.getNextPayrollNumber();
    if (!this.requestToTransfer.organization) {
      this.getPayrollNumberInfo(this.employee.organizationId,0);
      }
      else{
        this.getPayrollNumberInfo(this.employee.organizationId, this.requestToTransfer.organization.id);
      }
    this.requestToTransfer.employeeType = this.employee.employeeType;
    this.requestToTransfer.payrollNumber = this.employee.payrollNumber;
  }

  public setEmployeeId(): void {
    if (this.employee && this.requestToTransfer) {
      this.requestToTransfer.employeeId = this.employee.employeeId;
      this.loadEmployeeInfo();
    }
  }

  public onOrganizationChanged(org: Organization): void {
    this.clearOrgDependences();
    if (!this.requestToTransfer.organization) {
      this.getPayrollNumberInfo(this.employee.organizationId,0);
    }
    this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: this.requestToTransfer.organization.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
      .then((departmentLookup: Lookup) => {
        this.filterOutDepartments(departmentLookup);
        this.getNextPayrollNumber();
        if (!this.requestToTransfer.organization) {
        this.getPayrollNumberInfo(this.employee.organizationId,0);
        }
        else{
          this.getPayrollNumberInfo(this.employee.organizationId,this.requestToTransfer.organization.id);
        }
      });
  }

  public onDepartmentChanged(dep: Department): void {
    this.clearDepDependences();
    if (!this.requestToTransfer.department) {
      return;
    }
    this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: this.requestToTransfer.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
      .then((positionLookup: Lookup) => {
        this.positionLookup = positionLookup;
      });
      this.lookupService.getLookup({ lookupType: LookupType.shiftDefinition, orgLevelId: this.requestToTransfer.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
      .then((shiftLookup: Lookup) => {
        this.shiftLookup = shiftLookup;
      });
      this.lookupService.getLookup({ lookupType: LookupType.locationUnit, orgLevelId: this.requestToTransfer.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
      .then((unitLookup: Lookup) => {
        this.unitLookup = unitLookup;
      });

    if (this.bswiftEnabled) {
      this.lookupService.getLookup({ lookupType: LookupType.benefitClasses, orgLevelId: this.requestToTransfer.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
        .then((benefitClassLookup: Lookup) => {
          this.benefitClassLookup = benefitClassLookup;
          this.updateBenefitClass();
        });
    }
  }

  public clearOrgDependences(): void {
    this.requestToTransfer.department = null;
    this.clearDepDependences();
  }

  public clearDepDependences(): void {
    this.requestToTransfer.position = null;
    this.requestToTransfer.shift = null;
    this.requestToTransfer.unit = null;
    this.requestToTransfer.benefitClass = null;
  }

  private async loadEmployeeInfo(): Promise<void> {
    const [employeeShortInfo, employeeBenefitClass] = await Promise.all([
      this.employeeDefinitionsApiService.getEmployeeShortInfo(this.employee.employeeId),
      this.employeeDefinitionsApiService.getEmployeeBenefitClass(this.employee.employeeId)
    ]);

    this.employeeShortInfo = employeeShortInfo;
    this.employeeFullName = this.employeeShortInfo.fullName;
    if (this.requestToTransfer.date === null && this.employeeShortInfo.effectiveEndDate) {
      this.requestToTransfer.date = moment(this.employeeShortInfo.effectiveEndDate).toDate();
    }

    this.employeeBenefitClass = employeeBenefitClass;
  }

  private updateBenefitClass(): void {
    if (!this.employeeBenefitClass
      || !this.benefitClassLookup
      || _.isEmpty(this.benefitClassLookup.items.length)
    ) {
      const benefitClassDefinition: BenefitClassDefinition =
        _.find(this.benefitClassLookup.items, benefitClass => benefitClass.id === this.employeeBenefitClass.benefitClass.id);

      if (benefitClassDefinition) {
        this.requestToTransfer.benefitClass = benefitClassDefinition;
        this.requestToTransfer.benefitClassEffectiveDate = this.employeeBenefitClass.startDate;
      }
    }
  }

  private filterOutDepartments(departmentLookup: Lookup): void {
    const empDepartmentId: number = _.get(this.employee, 'departmentId');
    const departments: Department[] = _.filter(departmentLookup.items, (dep: Department) => dep.id !== empDepartmentId);
    const lookup: Lookup = new Lookup();
    lookup.titleField = departmentLookup.titleField;
    lookup.valueField = departmentLookup.valueField;
    lookup.type = departmentLookup.type;
    lookup.items = departments;

    this.departmentLookup = lookup;
  }

  private async getSettings(): Promise<void> {
    this.config= await this.appSettingsManageService.getAppServerConfig();
    this.bswiftEnabled = this.config.bswiftIntegrationEnabled;
    this.checkForNGPFlag();
  }

  private async getNextPayrollNumber() {
    if(!this.requestToTransfer.organization) {
      return;
    }
    if(!this.isNgpUser) {
      const nextPayrollNumber = await this.employeeDefinitionsApiService.getNextPayrollNumber(
        this.requestToTransfer.organization.orgLevelId,
        this.requestToTransfer.employeeId
      );
      this.requestToTransfer.payrollNumber = nextPayrollNumber;
    }
  }

  public async getPayrollNumberInfo(emporgId :number ,orgId : number) {
    this.requestToTransfer.oldOrganizationId = emporgId;
    this.PreventPayroll= await this.employeeDefinitionsApiService.getPayrollNumberInfo(emporgId,orgId);
    if(this.requestToTransfer.organization )
    {
      if(this.PreventPayroll)
      {
      this.PayrollReadOnly=true;
      }
      else{
        this.PayrollReadOnly=false;
      }
    }
    else
    {
      this.PayrollReadOnly=true;
    }
  }
  public checkForNGPFlag(){
    this.isNgpUser = this.config.IsNextgenPayrollEnabled || false;    
  }
}

import { AppSettingsManageService } from './../../../app-settings/services/app-settings-manage.service';
import { AppServerConfig } from './../../../app-settings/model/app-server-config';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, Input, Provider } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogOptions, IDialog } from '../../../common/models/index';
import { unsubscribe } from '../../../core/decorators/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';

@Component({
  moduleId: module.id,
  selector: 'slx-inactivity-notification',
  templateUrl: 'inactivity-notification.component.html',
  styleUrls: ['inactivity-notification.component.scss']
})
export class IncativityNotificationComponent implements OnDestroy, IDialog {


  public get remainingTime(): string {
    let duration: moment.Duration = moment.duration(this.remainingTimeSeconds, 'seconds');
    let seconds: number = duration.seconds();
    let minutes: number = duration.minutes();
    return `${duration.minutes()}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  public dialogResult: boolean = false;

  @unsubscribe()
  private timeoutCountdownSubscription: Subscription;
  private remainingTimeSeconds: number;
  private appServerConfig: AppServerConfig;

  public static openDialog(modalService: ModalService, callback: (result: boolean) => void): IncativityNotificationComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 165;
    dialogOptions.width = 300;
    dialogOptions.hideTitleBar = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];
    let dialog: IncativityNotificationComponent = modalService.globalAnchor
      .openDialog(IncativityNotificationComponent, 'Inactivity Warning', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result);
      });
    return dialog;
  }

  constructor(private options: DialogOptions,
    private modalService: ModalService,
    private userActivityService: UserActivityService,
    private appSettingsManageService: AppSettingsManageService) {
    this.timeoutCountdownSubscription = this.userActivityService.onTimeoutCountdown.subscribe((countdown: number) => {
      this.remainingTimeSeconds = countdown;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public get inactivityThresholdMinutes(): string {
    let duration: moment.Duration = moment.duration(this.userActivityService.thresholdSeconds, 'seconds');
    return `${duration.minutes()}`;
  }

  public get timeoutAfterMinutes(): string {
    let duration: moment.Duration = moment.duration(this.userActivityService.timeoutSeconds, 'seconds');
    return `${duration.minutes()}`;
  }

  public close(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}

import { Component, OnInit } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';
import { SessionActions } from '../../../authentication/actions/session.actions';
import { NotificationsApiService } from '../../services/notifications/notifications-api.service';
import { mutableSelect } from '../../../core/decorators/redux-decorators';
import { unsubscribe } from '../../../core/decorators/unsubscribe-decorator';
import { IUser, ISession } from '../../../authentication/store/session/session.types';
import { ReminderModel } from '../../models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-header-reminders-list',
    templateUrl: 'header-reminders-list.component.html',
    styleUrls: [
        'header-reminders-list.component.scss'
    ]
})
export class HeaderRemindersListComponent implements OnInit {


    @mutableSelect(['session'])
    public session$: Observable<ISession>;

    public reminders: ReminderModel[];
    public isLoading: boolean;

    @unsubscribe()
    private userSubscription: Subscription;

    constructor(private api: NotificationsApiService, private sessionActions: SessionActions) { }

    public ngOnInit(): void {

        this.userSubscription = this.session$.subscribe((session: ISession) => {
            session.user.remidersUnread = 0;
            this.sessionActions.saveSession(session);
        });

        this.isLoading = true;

        this.api.getReminders().then((result: any[]) => {
            this.reminders = result;
            this.isLoading = false;
            this.api.markRemindersAsRead(this.reminders);
        });
    }
}

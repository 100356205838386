import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable, Injector } from '@angular/core';
import { Subscription ,  Observable ,  ReplaySubject ,  Subject } from 'rxjs';
import { ManagementBaseService } from '../../../core/services/index';
import { unsubscribeInService } from '../../../core/decorators/index';
import { mutableSelect } from '../../../core/decorators/index';
import { ArrivalsDeparturesApiService } from './arrivals-departures-api.service';
import { IDateRange } from '../../../core/models/index';
import { LocationUnit, EmployeeDefinition } from '../../../organization/models/index';
import { StateManagementService } from '../../../common/index';
import { IControlState } from '../../../core/models/index';

import { OrgLevel } from '../../../state-model/models/index';
import {
  LinePunch, ArrivalsDeparturesDetails, ArrivalsDeparturesDetailsRecord, ArrivalDeparturesFilter,
  ArrivalDeparturesTimelineFilter, ArrivalDeparturesVariances, ArrivalDeparturesContainer, ArrivalsDeparturesTimelineViewModeDefinition
} from '../../models/index';

@Injectable()
export class ArrivalsDeparturesTimelineDetailsManagementService extends ManagementBaseService<ArrivalsDeparturesDetails, any> {

  public onFilterChanged$: ReplaySubject<ArrivalDeparturesTimelineFilter>;
  public onToogleTimelineView$: Subject<ArrivalsDeparturesTimelineViewModeDefinition>;
  public onToogleShowOverragesView$: Subject<boolean>;
  public onArrivalDeparturesLoaded$: Subject<ArrivalDeparturesContainer>;

  public workDate: Date;
  public filter: ArrivalDeparturesTimelineFilter;

  private data: ArrivalsDeparturesDetails;
  constructor(
    private arrivalsDeparturesApiService: ArrivalsDeparturesApiService, private stateManagement: StateManagementService) {
    super();
    this.onFilterChanged$ = new ReplaySubject(1);
    this.onToogleTimelineView$ = new Subject();
    this.onToogleShowOverragesView$ = new Subject();
    this.onArrivalDeparturesLoaded$ = new Subject();
    const initSubscription = this.stateManagement.onInit$.subscribe(() => {
      this.restoreFilter();
      initSubscription.unsubscribe();
    });
  }

  public ArrivalDeparturesLoaded(container: ArrivalDeparturesContainer): void {
    this.onArrivalDeparturesLoaded$.next(container);
  }

  public loadData(orgLevelId: number, workDate: Date, timeOn: Date): void {
    this.workDate = workDate;
    this.onLoadStatusChanged(true);
    this.arrivalsDeparturesApiService.getArrivalsDeparturesDetails(orgLevelId, workDate, timeOn)
      .then((value: ArrivalsDeparturesDetails) => {
        this.data = value;
        this.filterRecords(this.filter, value);
        this.onLoadStatusChanged(false);
      });
      /*.
      catch((reason: any) => {
        this.onError(reason);
      });*/
  }

  public onArrivalDeparturesFilterChangedSelected(filter: ArrivalDeparturesFilter): void {
    this.filter.arrivalsRange = filter.arrivalsRange;
    this.filter.departuresRange = filter.departuresRange;
    this.onFilterChanged(this.filter);
  }

  public toggleTimeline(mode: ArrivalsDeparturesTimelineViewModeDefinition): void {
    this.onToogleTimelineView$.next(mode);
  }
  public toggleShowOverrages(isShow: boolean): void {
    this.onToogleShowOverragesView$.next(isShow);
  }

  public onFilterChanged(filter: ArrivalDeparturesTimelineFilter): void {
    this.filter = filter;
    this.filterRecords(filter, this.data);
    this.onFilterChanged$.next(filter);
  }

  private filterRecords(filter: ArrivalDeparturesTimelineFilter, value: ArrivalsDeparturesDetails): void {
    if (!value) {
      return;
    }
    value.filteredRecords = _.filter(value.records, (record: ArrivalsDeparturesDetailsRecord) => {
      let res = true;
      if (filter.arrivalsRange) {
        res = res && (
          (!!record.firstShiftStart && record.firstShiftStart.isSameOrBefore(filter.arrivalsRange.endDate)) ||
          (!!record.inTime && record.inTime.isSameOrBefore(filter.arrivalsRange.endDate))
        );
      }
      if (filter.departuresRange) {
        res = res && (
          (!!record.lastShiftEnd && record.lastShiftEnd.isSameOrAfter(filter.departuresRange.startDate)) ||
          (!!record.outTime && record.outTime.isSameOrAfter(filter.departuresRange.startDate))
        );
      }
      if (filter.units && filter.units.length>0) {
        let uRes = false;
        _.forEach(filter.units, (unit: LocationUnit) => {
          if (_.includes(record.unitsIds, unit.id)) {
            uRes = true;
            return false;
          }
          return true;
        });
        res = res && uRes;
      }

      if (filter.variances && filter.variances.length) {
        if (_.includes(filter.variances, ArrivalDeparturesVariances.late)) {
          res = res && record.isLate;
        }
        if (_.includes(filter.variances, ArrivalDeparturesVariances.arrivals)) {
          res = res && record.isArrival;
        }
        if (_.includes(filter.variances, ArrivalDeparturesVariances.departures)) {
          res = res && record.isDeparture;
        }
        if (_.includes(filter.variances, ArrivalDeparturesVariances.approachingOT)) {
          res = res && record.isApproachingOT;
        }
      }

      if (filter.empFilter && filter.empFilter.length>0) {
        let uRes = false;
        _.forEach(filter.empFilter, (emp: EmployeeDefinition) => {
          if (record.employee.id === emp.id) {
            uRes = true;
            return false;
          }
          return true;
        });
        res = res && uRes;
      }
      return res;
    });
    this.onLoaded(value);
  }

  private restoreFilter(): void {
    const filter = new ArrivalDeparturesTimelineFilter();
    let state: IControlState = null;
    state = this.stateManagement.getControlState('units');
    filter.units = state.value;
    state = this.stateManagement.getControlState('variances');
    filter.variances = _.map(state.value, (v: any) => v.value);
    state = this.stateManagement.getControlState('isShowOverages');
    this.toggleShowOverrages(!!state.value);
    state = this.stateManagement.getControlState('empFilter');
    filter.empFilter = state.value;
    this.onFilterChanged(filter);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import { UserRolesManagementService } from '../../../services/index';
import { RolesWithUsersContainerDecorator, UsersRoles } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-user-roles-assignement',
  templateUrl: 'user-roles-assignement.component.html',
  styleUrls: ['user-roles-assignement.component.scss'],
  providers: [UserRolesManagementService]
})

export class UserRolesAssignementComponent implements OnInit, OnDestroy {

  public state: {
    isLoading: boolean;
  };

  public roles: UsersRoles;
  public rolesDecorator: RolesWithUsersContainerDecorator;

  @unsubscribe()
  private onUsersWithRolesLoadedSubscription: Subscription;
  @unsubscribe()
  private onLoadStatusSubscription: Subscription;

  constructor(private management: UserRolesManagementService) { }

  public ngOnInit(): void {

    this.state = {
      isLoading: false
    };

    this.onUsersWithRolesLoadedSubscription = this.management.onUsersWithRolesLoaded$.subscribe((roles: UsersRoles) => {
      this.roles = roles;
      this.rolesDecorator = new RolesWithUsersContainerDecorator(roles);
      this.state.isLoading = false;
    });

    this.onLoadStatusSubscription = this.management.onLoadStatus$.subscribe((lock: boolean) => {
      this.state.isLoading = lock;
    });

    this.state.isLoading = true;
    
    this.management.init();
  }

  public ngOnDestroy(): void {
    this.management.destroy();
    // See #issueWithAOTCompiler
  }
  
}

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ApiStatus, ApiEvent } from '../../models/index';

@Injectable()
export class ApiWatchService {
  public apiRequestStart$: ReplaySubject<ApiEvent>;
  public apiRequestEnd$: ReplaySubject<ApiEvent>;
  constructor() {
    this.apiRequestStart$ = new ReplaySubject(1);
    this.apiRequestEnd$ = new ReplaySubject(1);
  }

  public onRequestStart(event: ApiEvent): void {
    this.apiRequestStart$.next(event);
  }
  public onRequestEnd(event: ApiEvent): void {
    this.apiRequestEnd$.next(event);
  }

}

import { Observable ,  Subscription } from 'rxjs';


export function destroyService(): PropertyDecorator {
  return function decorate(target: any, key: string | symbol): void {
    let ngOnDestroyFunction: Function = target['ngOnDestroy'];
    let ngOnDestroy: Function = function (): void {
      if (this) {
        let service: any = this[key];
        if (service.destroy) {
          service.destroy();
        }
        if (ngOnDestroyFunction) {
          ngOnDestroyFunction.call(this);
        }
      }
    };
    target['ngOnDestroy'] = ngOnDestroy;
  };
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../../authentication/services/authentication/authentication.service";
import { NgRedux } from "@angular-redux/store";
import { IAppState } from "../../../store/index";
import { INavigationMenuEvent, NavigationMenuItem } from "../../../organization/models/index";
import { ApplicationStateBusService } from "../../../organization/services/index";
import { tap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private ngRedux: NgRedux<IAppState>;
  private selectedMenuItem: NavigationMenuItem;

  constructor(
    private auth: AuthenticationService,
    ngRedux: NgRedux<IAppState>,
    private applicationStateBusService: ApplicationStateBusService
  ) {
    this.ngRedux = ngRedux;
    this.applicationStateBusService.subscribeToSelectMenu((menu:INavigationMenuEvent) => {
      this.selectedMenuItem = menu.menuItem;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let state: any = this.ngRedux.getState();
    let orgLevelId: string = '';
    if (state != undefined && state.orgLevel != undefined && state.orgLevel.get('id') != undefined) {
      orgLevelId = state.orgLevel.get('id');
    }

    let selectedMenuId = '';
    if(!!this.selectedMenuItem && !isNaN(this.selectedMenuItem.id))
    {
      selectedMenuId = this.selectedMenuItem.id.toString();
    }

    const requestWithOrgLevelId = request.clone({
      setHeaders: {['X-slx-orglevelid']: `${orgLevelId}`}
    });  

    const requestWithHeaders = requestWithOrgLevelId.clone({
      setHeaders: {['X-slx-menuid']: `${selectedMenuId}`}
    })

    return next.handle(requestWithHeaders).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 && err.error.meta.error === 'Log off requested by System Administrator. Login again or contact your System Administrator.') {
            this.auth.logout();
        }
      }
    }));
  }
}
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

import { Subscription ,  Observable } from 'rxjs';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import { unsubscribe } from '../../../../core/decorators/index';

import * as moment from 'moment';
import { OrgLevelFlat } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { OrgLevelRecordWrapper, UserRoleRelationModel } from '../../../models/index';
import { UserProfileMapService } from '../../../services/index';
import { UserProfileManagementService } from '../../../services/index';

@Component({
    moduleId: module.id,
    selector: 'slx-org-level-selector',
    templateUrl: 'org-level-selector.component.html',
    styleUrls: ['org-level-selector.component.scss'],
})
export class OrgLevelSelectorComponent implements OnInit, OnDestroy {

    @Input()
    public set filterByOrglevelType(value: { id: string, name: string }) {
        this.m_filterByOrglevelType = value;
        this.refreshGrid();
        this.clearSelection();
        this.updateSelection();
    }

    @Input()
    public set selectedRoleRelation(value: UserRoleRelationModel) {
        this.m_selectedRoleRelation = value;
        this.clearSelection();
        this.updateSelection();
    }

    @Output()
    public selectionCountChanged: EventEmitter<number>;

    public get filterByOrglevelType(): { id: string, name: string } {
        return this.m_filterByOrglevelType;
    }

    public selectedOrgLevel: OrgLevelRecordWrapper;

    public state: {
        isLoading: boolean;
    };

    public gridState: KendoGridStateHelper<OrgLevelRecordWrapper>;

    public isAllSelected: boolean;
    public selectedRecords: OrgLevelRecordWrapper[];

    public records: OrgLevelRecordWrapper[];

    private m_filterByOrglevelType: { id: string, name: string };

    @unsubscribe()
    private gridRefreshSubscription: Subscription;

    private m_selectedRoleRelation: UserRoleRelationModel;

    constructor(private orgLevelWatchService: OrgLevelWatchService,
        private mappingService: UserProfileMapService,
        private management: UserProfileManagementService) {

        this.selectionCountChanged = new EventEmitter<number>();
        this.state = {
            isLoading: false,
        };
        this.gridState = new KendoGridStateHelper<OrgLevelRecordWrapper>();
    }

    public ngOnInit(): void {

        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
            this.refreshGrid();
        });
        this.management.onAddRoleModeSwitch$.subscribe(() => {
            this.clearSelection();
        });
        this.loadOrgLevels();
    }

    public ngOnDestroy(): void {
        // See #issueWithAOTCompiler
    }

    public onToggleAllSelected(): void {
        _.forEach(this.gridState.view.data, (record: OrgLevelRecordWrapper) => {
            record.isSelected = this.isAllSelected && Boolean(record.selectable);
        });
        this.selectedRecords = this.isAllSelected ? this.gridState.view.data.slice(0) : [];
        this.selectionCountChanged.next(this.selectedRecords.length);
    }

    public selectionChange(dataItem?: OrgLevelRecordWrapper): void {
        this.selectedRecords = [];
        if (this.gridState.view && this.gridState.view.data) {
            this.selectedRecords = _.filter(this.gridState.view.data, (record: OrgLevelRecordWrapper) => {
                return Boolean(record.isSelected) === true;
            });

            if (dataItem && !dataItem.isSelected) {
              this.recursiveDeselectOrgLevel(dataItem.orgLevel.orgLevel);
            }

            this.selectionCountChanged.next(this.selectedRecords.length);
        }
        this.selectionCountChanged.next(this.selectedRecords.length);
    }

    private recursiveDeselectOrgLevel(orgLevel: OrgLevel): void {
      _.find(this.records, (record: OrgLevelRecordWrapper) => {
        if (record.orgLevel.orgLevel.id === orgLevel.id) {
          record.isSelected = false;

          if (orgLevel.hasChilds) {
            _.each(orgLevel.childs, (child: OrgLevel) => {
              this.recursiveDeselectOrgLevel(child);
            });
          }
        }
      });
    }

    private loadOrgLevels(): void {
        this.records = this.mappingService.wrapOrgLevelFlatList(this.orgLevelWatchService.getFlatList());
        this.refreshGrid();
        this.clearSelection();
        this.updateSelection();
    }

    private updateSelection(): void {
        if (this.m_selectedRoleRelation) {
            if (this.m_selectedRoleRelation.orgLevels && this.m_selectedRoleRelation.orgLevels.length > 0) {
                _.each(this.records, (record: OrgLevelRecordWrapper) => {

                    let recordId: number = record.orgLevel.orgLevel.id;
                    let path: OrgLevel[] = this.orgLevelWatchService.getOrgLevelPath(recordId);

                    let existingLeveL: OrgLevel = _.find(this.m_selectedRoleRelation.orgLevels, (orgLevel: OrgLevel) => {
                        return (orgLevel.id === recordId);
                    });

                    if (existingLeveL) {
                        record.isSelected = true;
                    } else {
                        _.each(path, (parentLvl: OrgLevel) => {
                            let existingParent: OrgLevel = _.find(this.m_selectedRoleRelation.orgLevels, (orgLevel: OrgLevel) => {
                                return parentLvl.id === orgLevel.id;
                            });
                            if (existingParent) {
                                record.isSelected = true;
                            }
                        });
                    }
                });
            }
        }
        this.selectionChange();
    }

    private clearSelection(): void {
        if (this.records) {
            _.forEach(this.records, (record: OrgLevelRecordWrapper) => {
                record.isSelected = false;
            });
        }
        this.isAllSelected = false;
        this.selectedRecords = [];
        this.selectionCountChanged.next(this.selectedRecords.length);
    }

    private refreshGrid(): void {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }

        let filteredrecords: OrgLevelRecordWrapper[] = this.records;
        if (this.m_filterByOrglevelType) {
            filteredrecords = _.filter(this.records, (w: OrgLevelRecordWrapper) => {
                return w.orgLevel.orgLevel.type === this.m_filterByOrglevelType.id;
            });
        }
        this.gridState.view = process(filteredrecords, this.gridState.state);
    }
}

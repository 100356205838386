import { Directive, ElementRef, Input, ContentChild, OnInit, OnChanges, Optional, Host, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription ,  Subject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { IControlState, ControlStateKey, StateResetTypes } from '../../../core/models/index';
import { ComponentStateStorageService, StateManagementService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';
import { scheduleMicrotask } from '../../../core/utils/index';

@Directive({
  selector: '[slxControlInitState][formControlName],[slxControlInitState][formControl],[slxControlInitState][ngModel]'
})
export class ControlInitStateDirective implements OnInit {

  @Input('slxControlInitState')
  public set resetType(type: StateResetTypes) {
    if (!type) {
      return;
    }
    this.m_resetType = type;
  }
  @Input()
  public restoreManually: boolean;

  public get controlId(): string {
    return this.m_controlId.toString();
  }

  @unsubscribe()
  private initSubscription: Subscription;
  @unsubscribe()
  private valueChangesSubscription: Subscription;

  private m_resetType: StateResetTypes;
  private m_controlId: string | number;
  private state: IControlState;
  private accessor: ControlValueAccessor;

  constructor(@Host() private ngControl: NgControl, private storageService: ComponentStateStorageService, private stateManagement: StateManagementService) {
    this.accessor = ngControl.valueAccessor;
    this.m_resetType = StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
  }

  public ngOnInit(): void {
    this.m_controlId = this.ngControl.name;
    if (!this.m_controlId) {
      throw Error('State directive can be used only with control which has a name');
    }
    if (this.restoreManually) {
      this.state = {};
      this.valueChangesSubscription = this.ngControl.valueChanges.subscribe((x: any): any => {
        this.valueChanged(x);
      });
    }
    this.initSubscription = this.stateManagement.onInit$.subscribe(() => {
      scheduleMicrotask(() => { this.loadState(); });
    });

  }

  public valueChanged(val: any): void {
    this.storageService.mapValueToState(val, this.state);
    this.storageService.setControlState(this.stateManagement.componentKey, this.controlId, this.state, this.m_resetType);
  }

  private loadState(): void {
    if (this.restoreManually) {
      return;
    }
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
    this.state = this.storageService.getControlState(this.stateManagement.componentKey, this.controlId);
    if (this.state.value !== undefined) {
      let value: any = this.storageService.mapValueFromState(this.state);
      this.accessor.writeValue(value);
      this.ngControl.viewToModelUpdate(value);
    }
    this.valueChangesSubscription = this.ngControl.valueChanges.subscribe((x: any): any => {
      this.valueChanged(x);
    });
  }
}

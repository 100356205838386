import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { ReportParameter } from '../../../models/index';
import { Observable ,  Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'slx-integer-report-parameter',
  templateUrl: 'integer-report-parameter.component.html',
  styleUrls: ['integer-report-parameter.component.scss']
})
export class ReportIntegerParameterComponent {
  public includeThousandsSeparator: boolean = false;

  @Input()
  public set parameter(value: ReportParameter) {
    this.processParameter(value);
    this.m_parameter = value;
  }

  public get parameter(): ReportParameter {
    return this.m_parameter;
  }

  private m_parameter: ReportParameter;

  private processParameter(parameter: ReportParameter): void {
    if (!(_.isNull(parameter.defaultValue) || _.isUndefined(parameter.defaultValue))) {
      parameter.value = parameter.defaultValue;
    } else {
      parameter.value = 0;
    }
  }
}

import { Provider } from '@angular/core';

export * from './accruals-api.service';
export * from './accruals-management.service';
export * from './accruals-map.service';
export * from './accruals-balances-management.service';
export * from './accruals-balances-resolver.service';
export * from './accruals-transactions-management.service';
export * from './accruals-policy/accruals-policy-api.service';
export * from './accruals-policy/accruals-policy-map.service';

import { AccrualsApiService } from './accruals-api.service';
import { AccrualsManagementService } from './accruals-management.service';
import { AccrualsMapService } from './accruals-map.service';
import { AccrualsBalancesManagementService } from './accruals-balances-management.service';
import { AccrualsTransactionsManagementService } from './accruals-transactions-management.service';
import { AccrualsBalancesResolver } from './accruals-balances-resolver.service';
import { AccrualsPolicyApiService } from './accruals-policy/accruals-policy-api.service';
import { AccrualsPolicyMapService } from './accruals-policy/accruals-policy-map.service';
import { AccrualPolicyManagementService } from './accruals-policy/accrual-policy-management.service';

export const services: Provider[] = [
  AccrualsBalancesManagementService,
  AccrualsTransactionsManagementService,
  AccrualsApiService,
  AccrualsManagementService,
  AccrualsMapService,
  AccrualsBalancesResolver,
  AccrualsPolicyApiService,
  AccrualsPolicyMapService,
  AccrualPolicyManagementService
];

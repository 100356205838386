export * from './pbj-export/pbj-export-api.service';
export * from './pbj-export/pbj-export-map.service';
export * from './pbj-export/pbj-export-log-management.service';
export * from './pbj-manual-entry/pbj-manual-entry-api.service';
export * from './pbj-manual-entry/pbj-manual-entry-map.service';
export * from './pbj-reconciliation/pbj-reconciliation-map.service';
export * from './pbj-reconciliation/pbj-reconciliation-api.service';
export * from './pbj-reconciliation/pbj-reconciliation-management.service';
export * from './pbj-reconciliation/pbj-reconciliation-resolver.service';
export * from './meal-deduction/meal-deduction-map.service';
export * from './meal-deduction/meal-deduction-api.service';
export * from './meal-deduction/meal-deduction-management.service';

import { Provider } from '@angular/core';
import { PbjExportApiService } from './pbj-export/pbj-export-api.service';
import { PbjExportMapService } from './pbj-export/pbj-export-map.service';
import { PbjManualEntryApiService } from './pbj-manual-entry/pbj-manual-entry-api.service';
import { PbjManualEntryMapService } from './pbj-manual-entry/pbj-manual-entry-map.service';
import { PbjReconciliationMapService } from './pbj-reconciliation/pbj-reconciliation-map.service';
import { PbjReconciliationApiService } from './pbj-reconciliation/pbj-reconciliation-api.service';
import { PbjReconciliationManagementService } from './pbj-reconciliation/pbj-reconciliation-management.service';
import { PbjReconciliationResolver } from './pbj-reconciliation/pbj-reconciliation-resolver.service';
import { MealDeductionApiService } from './meal-deduction/meal-deduction-api.service';
import { MealDeductionMapService } from './meal-deduction/meal-deduction-map.service';
import { MealDeductionManagementService } from './meal-deduction/meal-deduction-management.service';
import { SignalrHub2Service } from '../../../channel/services/signalr/signalr-hub2.service';


export const services: Provider[] = [
  PbjExportApiService,
  PbjExportMapService,
  PbjManualEntryApiService,
  PbjManualEntryMapService,
  PbjReconciliationMapService,
  PbjReconciliationApiService,
  PbjReconciliationManagementService,
  PbjReconciliationResolver,
  MealDeductionApiService,
  MealDeductionMapService,
  MealDeductionManagementService,
  SignalrHub2Service
];

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { IPayloadAction } from '../../state-model/models/index';
import { ApplicationActions } from '../actions/index';

@Injectable()
export class ApplicationEpics {
  private router: Router;

  constructor(router: Router) {
    this.router = router;
  }

  public selectApplication = action$ => action$.pipe(
    filter(({ type }: { type: string }) => type === ApplicationActions.SELECT_APPLICATION),
    mergeMap((action: IPayloadAction) => {
      return of(
        {
          type: ApplicationActions.APPLICATION_SELECTED,
          payload: action.payload
        });
    }));
  
/*   public selectApplication = (action$: Observable<IPayloadAction>): Observable<any> => {
    return action$.pipe(
      filter(({ type }: { type: string }) => type === ApplicationActions.SELECT_APPLICATION),
      mergeMap((action: IPayloadAction) => {
        return of(
          {
            type: ApplicationActions.APPLICATION_SELECTED,
            payload: action.payload
          });
      }));
  } */
}

import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { UntypedFormControl, AbstractControl, NgControl } from '@angular/forms';
import { Assert, StringUtils } from '../../framework/index';
import { Valid } from './valid';

@Pipe({
  name: 'invalid',
  pure: false
})
@Injectable()
export class Invalid extends Valid implements PipeTransform {

  public transform(form: UntypedFormControl | NgControl, controlPath: string): boolean {
    Assert.isNotNull(form, 'form');

    if (form instanceof NgControl || StringUtils.isNullOrEmpty(controlPath)) {
      return form.invalid;
    }

    let control: AbstractControl = form.get(controlPath);
    if (!control || control.pending) {
      return false;
    }

    let isValid: boolean = this.checkValid(control);
    return !isValid;
  }

}

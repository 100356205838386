<div *ngIf="isQuickEditStarted && isAllFieldsPopulated" class="message-line">
  <span class="message1">Quick Edit Active!</span>
  <span class="message2">Click a cell in the schedule to edit shift.</span>
</div>
<div *ngIf="isQuickEditStarted && !isAllFieldsPopulated" class="message-line error-message">
  <span class="message1">Quick Edit Error!</span>
  <span class="message2">Not all required fields populated.</span>
</div>
<div class="shift-editor-holder">
  <slx-orglevel-shift-editor [orgLevelId]="orgLevelId" (onShiftTemplateChanged)="configurationChanged($event)"></slx-orglevel-shift-editor>
</div>
<div class="buttons-holder" *ngIf="disablepopup">
  <button *ngIf="!isQuickEditStarted" [disabled]="!isAllFieldsPopulated" (click)="startQuickEdit()" class="start-button">Start</button>
  <button *ngIf="isQuickEditStarted" (click)="stopQuickEdit()" class="stop-button">Complete</button>
  <button *ngIf="!showChanges" [disabled]="!hasChanges" (click)="showChangesClick()" class="show-button">Show Changes</button>
  <button *ngIf="showChanges" (click)="hideChangesClick()" class="hide-button">Hide Changes</button>
  <slx-check-box caption="Replace always" [(ngModel)]="configuration.replaceAlways" (ngModelChange)="onReplaceAlwaysChanged()">
  </slx-check-box>
</div>
<div *ngIf="showChanges" class="changes-holder">
  <div class="changes-holder-title">
    <span>Last 10 Changes</span>
    <hr>
  </div>
  <ng-container *ngFor="let item of items; let index = index">
    <div class="change-item" *ngIf="index<10">
      <span class="change-emp-name">{{item.employeeName}}</span>
      <span class="change-date">{{item.date | amDateFormat: appConfig.dateFormatShortYear}}</span>
      <span class="change-shift">{{item.shift.shiftName}}</span>
      <span class="change-unit">{{item.shift.unitName}} {{item.shift.absenceCode}}</span>
      <button *ngIf="!hideUndoCommand" (click)="undoEdit(item)" class="undo-button">
        <i class="fas fa-undo-alt" aria-hidden="true"></i>
      </button>
    </div>
  </ng-container>
</div>

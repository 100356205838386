<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <div *ngIf="!state.isLoading && getCurrentOrgLevel.id == 1" class="slx-el-actions-bar"
      [ngClass]="{'inactive': stateManagement.lockActions}">
      <button class="slx-el-action-button" (click)="openVirtualClockModal()">Create Virtual Clock</button>
    </div>

    <!-- Table header -->
    <div class="slx-main-content-indents slx-high-box__body page-overflow">
      <div class="slx-el-table-header slx-el-row border-top">
        <div class="slx-el-col hxs"></div>
        <div *ngIf="stateManagement.canEdit" class="slx-el-col ohxs"></div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Organization</span>
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Department</span>
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Clock Name</span>
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Clock Model</span>
        </div>
        <div class="slx-el-col ohxs">
          <span class="slx-el-header-title">Clock ID</span>
        </div>
        <div class="slx-el-col ohxs">
          <span class="slx-el-header-title">Physical ID</span>
        </div>
        <div class="slx-el-col sm">
          <span class="slx-el-header-title">IP Address</span>
        </div>
      </div>

      <!-- Timeclocks list -->
      <slx-editable-list [data]="container?.records" class="slx-el-table-body" (editStart)="onItemEditStart($event)"
        (editCancel)="onItemEditCancel($event)" (selectionChange)="onSelectionChange($event)"
        (itemSave)="onItemSave($event)" [editable]="false" [selectable]="false">
        <ng-template slx-editable-list-renderer let-context>
          <slx-timeclock-renderer slx-list-highlight slx-list-actions [context]="context"></slx-timeclock-renderer>
        </ng-template>
      </slx-editable-list>
    </div>
  </div>

  <!-- Modal for creating virtual timeclocks -->
  <div class="modal-overlay" *ngIf="showVirtualClockModal && getCurrentOrgLevel.id == 1">
    <div class="modal-content">
      <h3>Create Virtual Timeclocks</h3>

      <!-- Spinner with padding when loading -->
      <div [ngClass]="{'padded-spinner': state.isLoading}">
        <slx-spinner [show]="state.isLoading"></slx-spinner>
      </div>

      <!-- Modal body shows only if not loading -->
      <div *ngIf="!state.isLoading" class="modal-body">
        <div class="columns">
          <!-- Left Column: Timeclocks Grouped by Organization -->
          <div class="column">
            <h3>Timeclocks ({{allTimeclocks.length}})</h3>
            <ul class="selection-tree">
              <li *ngFor="let orgGroup of filteredTreeStructuredTimeclocks" class="org-group">
                <!-- Organization Checkbox for Selecting/Deselecting All Timeclocks -->
                <label class="org-label">
                  <input type="checkbox" [checked]="areAllTimeclocksSelected(orgGroup.timeclocks)"
                    (change)="toggleAllTimeclocksForOrg(orgGroup.timeclocks)">
                  {{ orgGroup.organization.name }}
                </label>

                <!-- Nested Timeclocks under Organization -->
                <ul class="timeclock-list">
                  <li *ngFor="let timeclock of orgGroup.timeclocks" class="timeclock-item">
                    <label>
                      <input type="checkbox" [checked]="selectedTimeclockIds.includes(timeclock.id)"
                        (change)="toggleTimeclockSelection(timeclock.id)">
                      {{ timeclock.name || 'Unnamed Timeclock' }}
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <!-- Right Column: Organizations for Copying Timeclocks -->
          <div class="column">
            <h3>Organizations ({{allOrganizations.length}})</h3>
            <ul class="selection-list">
              <li *ngFor="let org of allOrganizations">
                <label>
                  <input type="checkbox" [checked]="selectedOrgIds.includes(org.id)"
                    (change)="toggleCopyOrgSelection(org.id)">
                  {{ org.name || 'Unnamed Organization' }}
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Modal footer with filter, dropdown, and buttons in separate rows -->
      <div class="modal-footer sticky-footer">
        <!-- First Row: Filter and Dropdown -->
        <div class="filter-container">
          <div class="filter-item">
            <!-- Filter Input -->
            <input type="text" [(ngModel)]="filterText" (input)="applyFilter()" placeholder="Filter Timeclocks..." />
          </div>
          <div class="filter-item">
            <!-- Dropdown for Naming Format -->
            <select id="naming-format-select" [(ngModel)]="selectedNamingFormat" class="dropdown-select">
              <option *ngFor="let format of namingFormats" [value]="format.value">{{ format.label }}</option>
            </select>
          </div>
        </div>

        <!-- Second Row: Buttons -->
        <div class="button-container">
          <button class="flair-button green" (click)="createVirtualClocks()"
            [disabled]="!canCreateVirtualClocks() || state.isLoading"
            [ngClass]="{'disabled': !canCreateVirtualClocks() || state.isLoading}">
            Create
          </button>
          <button class="flair-button gray" (click)="closeVirtualClockModal()">Close</button>
        </div>
      </div>
    </div>
  </div>
</slx-spinner>

import { Component, Input, Output, OnInit, OnDestroy, OnChanges, SimpleChanges, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

import { unsubscribe } from '../../../../core/decorators/index';
import { DailyPunchesManagementService, DailyTimecardManagementService, DailyTimecardToolbarService, TimecardsCheckRightsService } from '../../../services/index';
import { LinePunch, EmployeeDailyPunches, PunchesDisplaySettings, PunchesEventFilter, DailyLinePunchActions, IApplyPunchesEvent } from '../../../models/index';
import { DailyLinePunchGridComponent } from '../daily-line-punch-grid/daily-line-punch-grid.component';
import { PunchesManagementService } from '../../../services/punches/punches-management.service';
import { TOOLBAR_SERVICE } from '../../../../core/services/index';
import { PayCycleHelperService } from '../../../../organization/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-punches-management',
  templateUrl: 'daily-punches-management.component.html',
  styleUrls: ['daily-punches-management.component.scss'],
  providers: [DailyPunchesManagementService, DailyTimecardManagementService,
    { provide: TOOLBAR_SERVICE, useClass: DailyTimecardToolbarService }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DailyPunchesManagementComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public employeeId: number;
  @Input()
  public employeeName: string;
  @Input()
  public empPunches: EmployeeDailyPunches;
  @Input()
  public punches: LinePunch[];
  @Input()
  public minPunchDate: Date;
  @Input()
  public maxPunchDate: Date;
  @Input()
  public defaultPunchTime: Date;
  @Input('userActions')
  public set defineUserActions(a: DailyLinePunchActions) {
    this.userActions = new DailyLinePunchActions(a.canEditTimecards, true);
  }

  @Output()
  public applyChanges: EventEmitter<IApplyPunchesEvent>;
  @Output()
  public changingPunches: EventEmitter<LinePunch[]>;
  @Output()
  public discardChanges: EventEmitter<any>;

  public state: {
    isLoading: boolean;
  };
  public isOutOfPayCycle = false;
  public userActions: DailyLinePunchActions;
  public canEditOwnTimecard = false;

  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadStatusHeadersSubscription: Subscription;

  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private loadedPunchesSubscription: Subscription;
  @unsubscribe()
  private applyChangesSubscription: Subscription;
  @unsubscribe()
  private changingPunchesSubscription: Subscription;
  @unsubscribe()
  private discardChangesSubscription: Subscription;
  @unsubscribe()
  private filterSetSubscription: Subscription;

  private discardPending: boolean;

  constructor(private managementService: DailyPunchesManagementService, private punchesManagementService: PunchesManagementService,
    @Inject(TOOLBAR_SERVICE) private dailyTimecardToolbarService: DailyTimecardToolbarService, private payCycleHelperService: PayCycleHelperService,
    private changeDetector: ChangeDetectorRef,
    private timecardsCheckService: TimecardsCheckRightsService) {
    this.state = {
      isLoading: false
    };
    this.applyChanges = new EventEmitter<IApplyPunchesEvent>();
    this.changingPunches = new EventEmitter<LinePunch[]>();
    this.discardChanges = new EventEmitter<any>();
  }

  public ngOnInit(): void {
    this.canEditOwnTimecard = this.timecardsCheckService.userCanEditOwnTimecard(this.employeeId);
    this.punchesManagementService.isPaidRestBreakEnabled$.subscribe((res:any)=>{
      this.managementService.isPaidRestBreakEnabled$.next(res);
    })
    this.filterSetSubscription = this.punchesManagementService.onFilterSet$.subscribe((filter: PunchesDisplaySettings) => {
      if (!filter) {
        return;
      }
      this.managementService.applyFilter(this.punches, filter.eventFilter);
    });

    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.loadStatusHeadersSubscription = this.punchesManagementService.onLoadHeaderPunchesStatus$
      .subscribe((event: { value: boolean, employeeId: number, date: Date }) => {
        if (event.employeeId !== this.employeeId || !moment(event.date).isSame(this.defaultPunchTime)) {
          return;
        }
        this.state.isLoading = event.value;
        //this.changeDetector.markForCheck();
        //this.changeDetector.detectChanges();
      });

    this.loadedPunchesSubscription = this.punchesManagementService.onLoadedHeaderPunches$
      .subscribe((empPunches: EmployeeDailyPunches) => {
        if (empPunches.header.employee.id !== this.employeeId || !moment(empPunches.header.date).isSame(this.defaultPunchTime)) {
          return;
        }
        this.updateCanEdit(empPunches);
        this.managementService.setPanches(empPunches.punches);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.applyChangesSubscription = this.managementService.onApplyChanges$
      .subscribe((changes: IApplyPunchesEvent) => {
        this.applyChanges.emit(changes);
      });

    this.changingPunchesSubscription = this.managementService.onChangingPunches$
      .subscribe((changes: LinePunch[]) => {
        this.changingPunches.emit(changes);
      });

    this.discardChangesSubscription = this.managementService.onDiscardChanges$
      .subscribe(() => {
        this.discardChanges.emit(null);
        this.discardPending = true;
      });


    this.payCycleHelperService.getPayCycleByDate(this.defaultPunchTime, undefined, this.employeeId, true)
    .then((payCycle) => {
      this.isOutOfPayCycle = !payCycle;
      this.updateCanEdit(this.empPunches);
    });
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['empPunches'] && this.empPunches) {
      if(this.empPunches.isPayrollCycleSubmitted) {
        this.managementService.canEditChanged(false);
      }
      if (!this.empPunches.punches) {
        this.punchesManagementService.loadPunches(this.empPunches);
      } else {
        this.managementService.setPanches(this.punches);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }
    }

    if (changes['punches']) {
      if ((this.empPunches && this.punches !== this.empPunches.punches) || this.discardPending) {
        this.discardPending = false;
        this.managementService.setPanches(this.punches);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }
    }

    this.dailyTimecardToolbarService.onRecalcToolbar();
  }

  private updateCanEdit(empPunches: EmployeeDailyPunches){
    let editable = !(empPunches.isPayrollCycleSubmitted || this.isOutOfPayCycle || empPunches.isLocked || !this.canEditOwnTimecard);
    this.managementService.canEditChanged(editable);
  }
  public ngOnDestroy(): void {
    // Must be, see #issueWithAOTCompiler
  }
}

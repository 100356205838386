<slx-spinner [show]="state.isInProgress">
  <div>
    <ul #contentContainer class="messages">
      <span class="theme-exsm-text" *ngIf="details?.messages?.length === 0">There are no active conversations.</span>
      <li *ngFor="let message of details?.messagesFromThreads" [ngClass]="{'left': message?.isInbound, 'right': message?.isOutbound}"
        class="message appeared">
        <div [attr.id]="message.messageId" class="message-content">
          <div class="message-party">
            <div class="user-image">
              <img class="rounded-image" [employeeThumbinalSrc]="message?.senderId" alt="Employee Photo" />
            </div>
            <div class="name theme-exsm-text">{{message?.senderName}}</div>
          </div>
          <div class="text_wrapper">
            <div class="message-host">
              <div class="text theme-exsm-text">{{message?.text}}</div>
              <div class="message-controls">
                <div *ngIf="message?.isInbound" class="dropdown icon-action-menu">
                  <button aria-expanded="false" aria-haspopup="true" class="btn btn-circle dropdown-toggle btn-elipsis-accent" data-toggle="dropdown"
                    type="button">
                  <i class="fa fa-ellipsis-h fa-lg" aria-hidden="true"></i>
                </button>
                  <ul aria-labelledby="group-activities" class="dropdown-menu">
                    <!-- <li (click)="onReadClicked(message)" [class.disabled]="!message?.canRead"><a><i class="fa fa-eye"></i> Read</a></li> -->
                    <li (click)="onApproveClicked(message, details)" [class.disabled]="!(message?.isNew || message?.isRequested || message?.isReaded)"><a><i class="fa fa-check" aria-hidden="true"></i> Approve</a></li>
                    <li><a><i class="fas fa-calendar-alt" aria-hidden="true"></i> Preview Schedule</a></li>
                    <li *ngIf="message?.isInbound" (click)="onReplyViaSmsClicked(message, details)"><a><i class="far fa-envelope" aria-hidden="true"></i> Reply via SMS</a></li>
                    <li (click)="onDenyClicked(message, details)" [class.disabled]="!(message?.isNew || message?.isRequested || message?.isReaded)"><a><i class="fa fa-ban" aria-hidden="true"></i> Deny</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <span class="message-type">
                <i [ngClass]="{'fa fa-laptop': message?.isSystem, 'fas fa-comment-alt': message?.isSms}" aria-hidden="true" class="fa-lg"></i>
            </span>
            <div class="timestamp theme-sm-text">{{message?.timestamp | amDateFormat: appConfig.dateTimeFormat}}</div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</slx-spinner>

import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ScheduleDailyDetailsApiService } from '../../../services/index';
import { ScheduleDailyDetails, ScheduleDailyDetailsRequest } from '../../../models/index';
import { Observable ,  Subscription } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';


@Component({
  moduleId: module.id,
  selector: 'slx-schedule-daily-details',
  templateUrl: 'schedule-daily-details.component.html',
  styleUrls: ['schedule-daily-details.component.scss']
})
export class ScheduleDailyDetailsComponent implements OnChanges {
  @Input()
  public req: ScheduleDailyDetailsRequest;

  public appConfig: IApplicationConfig;
  public state: {
    isLoading: boolean;
  };
  public records: ScheduleDailyDetails[];
  private apiService: ScheduleDailyDetailsApiService;

  constructor(apiService: ScheduleDailyDetailsApiService) {
    this.apiService = apiService;
    this.state = { isLoading: false };
    this.appConfig = appConfig;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['req']) {
      this.loadData();
    }
  }

  public loadData(): void {
    if (!this.req) {
      return;
    }
    this.state.isLoading = true;

    this.apiService.getScheduleDailyDetails(this.req)
      .then((val: ScheduleDailyDetails[]) => {
        this.state.isLoading = false;
        this.records = val;
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }
}

import * as _ from 'lodash';
import { Component, OnInit, Host, NgZone, OnDestroy, Input } from '@angular/core';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { EmployeeSubSectionsDecoratorComponent } from '../../../employee-subsection-decorator/employee-subsection-decorator.component';
import { BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { EmployeeSectionsBasicComponent } from '../../../employee-sections/employee-sections-basic.component';
import { AbstractControl } from '@angular/forms';
import { EmployeeSectionsBase, EmployeeSubsectionEnrollment } from '../../../../models';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'slx-benefit-enrollment-section',
  templateUrl: './benefit-enrollment-section.component.html',
  styleUrls: ['./benefit-enrollment-section.component.scss'],
})
export class BenefitEnrollmentSectionComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input()
  public set employeeId(value: number) {
    this.m_employeeId = value;
  }
  @Input()
  public canMapPayroll: boolean;
  
  @Input()
  public employeeStatus: string;

  public get employeeId(): number {
    return this.m_employeeId;
  }

  @Input()
  public set benefitEnrollment(c: EmployeeSubsectionEnrollment) {
    this.m_benefitEnrollment = c;
    if (this.m_benefitEnrollment) {
      this.managementService.setSectionInfo(this.m_benefitEnrollment);
      this.managementService.getAttachmentsData(this.m_employeeId);
    }
  }

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  public get benefitEnrollment(): EmployeeSubsectionEnrollment {
    return this.m_benefitEnrollment;
  }

  public get form(): AbstractControl {
    return null;
  }

  public isLoading: boolean;
  public effectiveDate: Date;

  private m_employeeId: number;
  private m_benefitEnrollment: EmployeeSubsectionEnrollment;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private managementService: BenefitsEnrollmentSectionManagementService
  ) {
    super(decorator, ngZone);
  }

  public ngOnInit() {
    this.subscriptions.loading = this.managementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
    
    this.subscriptions.effectiveDate = this.managementService.subscribeToEffectiveDate((effectiveDate: Date) => {
      this.effectiveDate = effectiveDate;
    });
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.m_benefitEnrollment;
  }

  protected loadSubsection(): void {
    this.managementService.getBenefitEnrollmentData(this.employeeId, this.effectiveDate);
  }

}

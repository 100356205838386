<!--
    At this momment this component doesn't used at all
 -->
<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid"
            [data]="gridState.view"
            (dataStateChange)="gridState.dataStateChange($event)"
            [sortable]="{ mode: 'multiple' }"
            [sort]="gridState.state.sort"
            [filterable]="'menu'"
            [filter]="gridState.state.filter"
            [selectable]="{ mode: 'single' }"
            [rowSelected]="isRowSelected()"
            (cellClick)="onCellClick($event)"
            [scrollElementSelector]="'.k-grid-content.k-virtual-content'"
            slxKendoGridState="TIMECARD_DISPLAY">

  <kendo-grid-column media="xs" title="Items" width="500">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="flex-horizontal">
        <i *ngIf="dataItem.isError" class="fa fa-exclamation-circle alert-error" aria-hidden="true"></i>
        <i *ngIf="dataItem.isWarning" class="fa fa-exclamation-triangle alert-warning" aria-hidden="true"></i>
      </div>
      <h4>
        {{ dataItem.employeePosition.employee.name }}
      </h4>
      <dl>
        <dt>Department</dt>
        <dd>{{ dataItem.employeePosition.positionDepartment.name }}</dd>

        <dt>Position</dt>
        <dd>{{ dataItem.employeePosition.position.name }}</dd>

        <dt>Standard Pay Rate</dt>
        <dd>{{ dataItem.standardPayRate| number: ".2-2" | slxMoney }}</dd>
      </dl>
      <h5>
        Totals
      </h5>
      <dl>
        <dt>Worked Hours</dt>
        <dd>{{ dataItem.workedHours| number: ".2-2" }}</dd>
        <dt>Total Hours</dt>
        <dd>{{ dataItem.totalHours| number: ".2-2" }}</dd>
        <dt>Regular Pay</dt>
        <dd>{{ dataItem.regularPay| number: ".2-2" | slxMoney }}</dd>

        <dt> Overtime and Extra pay</dt>
        <dd>{{ dataItem.overtimeAndExtra| number: ".2-2" | slxMoney }}</dd>

        <dt> Total Pay</dt>
        <dd>{{ dataItem.totalPay| number: ".2-2" | slxMoney }}</dd>
      </dl>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="" [sortable]="false" field="isSelected" media="sm" width="40" [filterable]="false" headerClass="overflow-visible-cell">
    <ng-template kendoGridHeaderTemplate>
      <div>
        <input slxTooltip="Select/Deselect All" tipPosition="bottom" type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onToggleAllSelected()">
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange()">
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Name" media="sm" [width]="250" field="employeePosition.employee.name" class="overflow-visible-cell" headerClass="overflow-visible-cell">
    <ng-template kendoGridHeaderTemplate>
      Name
      <span class="alerts">
        <i class="fa fa-exclamation-circle" slxTooltip="Has missing punches" tipPosition="bottom" aria-hidden="true"></i>
      </span>
      <span class="alerts">
        <i class="fa fa-exclamation-triangle" slxTooltip="Has invalid punches" tipPosition="bottom" aria-hidden="true"></i>
      </span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="flex">
        <span class="link">
          <a [individualTimecardsLink]="dataItem?.employeePosition.employee.id"
             [startDate]="container?.payCycle.startDate"
             [endDate]="container?.payCycle.endDate"
             (click)="onHighlightEmployee(dataItem?.employeePosition.employee.id)">{{dataItem?.employeePosition.employee.name}}</a>
        </span>
        <span class="alerts">
          <i *ngIf="dataItem.isError" class="fa fa-exclamation-circle alert-error" slxTooltip="Has missing punches" tipPosition="bottom" aria-hidden="true"></i>
        </span>
        <span class="alerts">
          <i *ngIf="dataItem.isWarning" class="fa fa-exclamation-triangle alert-warning" slxTooltip="Has invalid punches" tipPosition="bottom" aria-hidden="true"></i>
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('department')" title="Department" media="lg" [width]="getWidth('department')"
                     field="employeePosition.positionDepartment.name">
    <ng-template kendoGridHeaderTemplate>
      Department
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.employeePosition.positionDepartment.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('position')" title="Position" media="sm" [width]="getWidth('position')"
                     field="employeePosition.position.name">
    <ng-template kendoGridHeaderTemplate>
      Position
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.employeePosition.position.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('payroll')" title="Payroll #" media="lg" [width]="getWidth('payroll')"
                     field="employeePosition.employee.payrollNumber">
    <ng-template kendoGridHeaderTemplate>
      Payroll #
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.employeePosition.employee.payrollNumber}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('empType')" title="Employee Type" media="lg" [width]="getWidth('empType')"
                     field="employeePosition.employee.employeeType.name">
    <ng-template kendoGridHeaderTemplate>
      Employee Type
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.employeePosition.employee.employeeType.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('approved')" [hidden]="!isVisible('approved')" title="Approved" media="sm" field="approved" filter="boolean">
    <ng-template kendoGridHeaderTemplate>
      Approved
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.approved | boolyn}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('hireDate')" title="Hired" media="lg" [width]="getWidth('hireDate')" field="employeePosition.employee.dateHired" filter="date">
    <ng-template kendoGridHeaderTemplate>
      Hired
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.employeePosition.employee.dateHired | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('shiftDiffPolicy')" field="shiftDiffPolicy.description" [hidden]="!isVisible('shiftDiffPolicy')"
                     title="Shift Diff Policy" media="lg" class="detail-cell">
    <ng-template kendoGridHeaderTemplate>
      Shift Diff Policy
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.shiftDiffPolicy.description}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('payPolicy')" field="payPolicy.description" [hidden]="!isVisible('payPolicy')" title="Pay Policy"
                     media="lg" class="detail-cell">
    <ng-template kendoGridHeaderTemplate>
      Pay Policy
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.payPolicy.description}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('payRate') || !state.isShowPayRates" [width]="getWidth('payRate')" title="Standard Pay Rate"
                     media="sm" field="standardPayRate" class="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      Standard Pay Rate
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.standardPayRate | number: ".2-2" | slxMoney}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('homeShift')" title="Home Shift" media="sm" [width]="getWidth('homeShift')"
                     field="homeShift">
    <ng-template kendoGridHeaderTemplate>
      Home Shift
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.homeShift?.name }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('workedHours')" title="Worked Hours" media="sm" [width]="getWidth('workedHours')"
                     field="workedHours" class="numeric-cell" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      Worked Hours
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.workedHours| number: ".2-2"}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['workedHours']?.sum | number: ".2-2"}}</strong>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [hidden]="!isVisible('totalHours')" title="Total Hours" media="sm" [width]="getWidth('totalHours')"
                     field="totalHours" class="numeric-cell" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      Total Hours
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.totalHours| number: ".2-2"}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['totalHours']?.sum | number: ".2-2"}}</strong>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [hidden]="!isVisible('regularPay')" title="Regular Pay" media="sm" [width]="getWidth('regularPay')" field="regularPay"
                     class="numeric-cell" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      Regular Pay
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.regularPay| number: ".2-2" | slxMoney}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['regularPay']?.sum  | number: ".2-2" | slxMoney}}</strong>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('overtimeAndOther')" title="Overtime and Extra pay" media="sm" [width]="getWidth('overtimeAndOther')"
                     field="overtimeAndExtra" class="numeric-cell" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      Overtime and Extra pay
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.overtimeAndExtra| number: ".2-2" | slxMoney}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['overtimeAndExtra']?.sum | number: ".2-2" | slxMoney}}</strong>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('totalPay')" title="Total" media="sm" [width]="getWidth('totalPay')" field="totalPay"
                     class="numeric-cell" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      Total Pay
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.totalPay| number: ".2-2" | slxMoney}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['totalPay']?.sum | number: ".2-2" | slxMoney}}</strong>
    </ng-template>
  </kendo-grid-column>

  <div *kendoGridDetailTemplate="let dataItem">
    <slx-timecards-display-detail-grid [state]="state" [employee]="dataItem"></slx-timecards-display-detail-grid>
  </div>
  <kendo-grid-excel fileName="Timecards.xlsx">
  </kendo-grid-excel>
  <kendo-grid-pdf fileName="Timecards.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { IFilteredItems, PagingData } from '../../../../../core/models/index';
import { AuditTrailEmployeeAction } from '../../../models/index';
import { orderBy, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { AuditTrailManagementService } from '../../../services/audit-trail/audit-trail-management.service';
import { AuditTrailEvent, AuditTrailEventCmd } from '../../../models/audit-trail/audit-trail-event';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DatePipe } from '@angular/common';

@Component({
  moduleId: module.id,
  selector: 'slx-audit-trail-grid',
  templateUrl: 'audit-trail-grid.component.html',
  styleUrls: ['audit-trail-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditTrailGridComponent implements OnInit, OnDestroy {
  @Input()
  public set gridView(value: IFilteredItems<AuditTrailEmployeeAction>) {
    this.records = value;
    this.refreshGrid();
  }
  @Input()
  public set skip(value: number) {
    if (value === undefined) {
      return;
    }
    this.gridState.state.skip = value;
  }

  @Output()
  public gridPageChanged: EventEmitter<PagingData> = new EventEmitter();

  @Output()
  public gridSortChanged: EventEmitter<SortDescriptor[]> = new EventEmitter();

  public originalActions: AuditTrailEmployeeAction[];
  public pageSize: number = 50;
  public gridState: KendoGridStateHelper<AuditTrailEmployeeAction>;
  public appConfig: IApplicationConfig;
  private exportData: IFilteredItems<AuditTrailEmployeeAction>;

  public useTZnotOffset: boolean = false;
  public currentTimezoneCode:string;
  public useTZnotOffsetSubscription:Subscription;
  public currentTimezoneCodeSubscription:Subscription;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private actionSubscription: Subscription;
  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;
  private records: IFilteredItems<AuditTrailEmployeeAction>;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private managementService: AuditTrailManagementService,
    private datePipe : DatePipe  ) {
    this.gridState = new KendoGridStateHelper<AuditTrailEmployeeAction>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort =  [{ field: 'auditDateTime', dir: 'desc' }];
    this.gridState.state.group = [];
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.actionSubscription = this.managementService.subscribeToActions((event: AuditTrailEvent): void => {
      if (event.cmd === AuditTrailEventCmd.excelExport) {
         event.payload.items.forEach((item =>{
          item.auditDateTime = this.datePipe.transform(item.auditDateTime, 'MM/dd/yyyy hh:mm a');
        }))
        this.exportData = event.payload;
        this.grid.saveAsExcel();
      }
      if (event.cmd === AuditTrailEventCmd.pdfExport) {
        this.grid.saveAsPDF();
      }
    });
    this.useTZnotOffsetSubscription = this.managementService.useTZnotOffset$.subscribe((value:boolean)=> {
      this.useTZnotOffset = value;
    });
    this.currentTimezoneCodeSubscription = this.managementService.currentTimezoneCode$.subscribe((value:string)=>{
      this.currentTimezoneCode = value;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.useTZnotOffsetSubscription.unsubscribe();
    this.currentTimezoneCodeSubscription.unsubscribe();
  }

  public pageChanged(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.gridPageChanged.emit({ take: event.take, skip: event.skip });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.state.sort = sort;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
    this.gridSortChanged.emit(this.gridState.state.sort);
  }

  public filterChange(filter: any): void {
    this.gridState.state.filter = filter;
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public getGridData(): () => ExcelExportData {

    return (): ExcelExportData => {
      return {
        data: this.exportData.items,
        group: this.gridState.state.group
      };
    };
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = { data: this.records.items, total: this.records.count };
  }

  public excelExport($event): void {
    var sheet = $event.workbook.sheets[0];
    sheet.freezePane = { rowSplit: 1, colSplit: 0 };
    for (var rowIndex = 0; rowIndex < sheet.rows.length; rowIndex++) {
      var row = sheet.rows[rowIndex];  
      for (var colIndex = 0; colIndex < row.cells.length; colIndex++) {
        row.cells[colIndex].wrap = true;
      }
    }
  }
}

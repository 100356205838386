import { Directive, TemplateRef } from '@angular/core';

@Directive ({
    selector: '[slx-editable-list-editor]'
})
export class ListEditorTemplateDirective {
    public editorTemplateRef: TemplateRef<any>;

    constructor (templateRef: TemplateRef<any>) {
        this.editorTemplateRef = templateRef;
    }
}

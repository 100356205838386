import { Injectable } from "@angular/core";
import { ApiUtilService } from "../../../common";
import { PayPoliciesMapService } from "./pay-policies-map.service";
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { PayPolicyDto } from "../../models/pay-policies/dtos/pay-policy.dto";
import { PayPolicyRuleDto } from "../../models/pay-policies/dtos/pay-policy-rule.dto";
import { PayPolicy } from "../../models/pay-policies/models/pay-policy.model";
import { PayPolicyRule } from "../../models/pay-policies/models/pay-policy-rule.model";

@Injectable()
export class PayPoliciesAPIService {

    private readonly taApiRoot: string = this.getTAServicesApiRoot(); // Use the TA Services API root directly

    constructor(
        private apiUtilService: ApiUtilService,
        private mapService: PayPoliciesMapService
    ) { }

    private getTAServicesApiRoot(): string {
        return `${this.apiUtilService.getTAServicesApiRoot()}/taappend`;
    }

    // Get all pay policies for a specific organization
    public async getAllPayPolicies(organizationId: number): Promise<PayPolicy[]> {
        const url: string = `${this.taApiRoot}/organizations/${organizationId}/paypolicies`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        try {
            const response = await this.apiUtilService.request<any, any>(request);
            if (response && response.data) {
                return response.data.map(this.mapService.mapToModel);
            }
            return []; // Return empty list if no data found
        } catch (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                return []; // Return empty list for 404 errors
            }
            this.handleError(error);
            throw error;
        }
    }

    // Get a specific pay policy by ID for an organization
    public async getPayPolicyById(organizationId: number, policyId: string): Promise<PayPolicy> {
        const url: string = `${this.taApiRoot}/organizations/${organizationId}/paypolicies/${policyId}`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        try {
            const response = await this.apiUtilService.request<any, any>(request);
            if (response && response.data) {
                return this.mapService.mapToModel(response.data);
            }
            throw new Error(`No pay policy found with ID: ${policyId}`);
        } catch (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                return null;
            }
            this.handleError(error);
            throw error;
        }
    }

    // Create a new pay policy for an organization
    public async createPayPolicy(organizationId: number, dto: PayPolicyDto): Promise<void> {
        const url: string = `${this.taApiRoot}/organizations/${organizationId}/paypolicies`;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const request: HttpRequest<any> = new HttpRequest('POST', url, dto, { headers });

        try {
            const response = await this.apiUtilService.request<any, any>(request);
            console.log('Pay policy created successfully.');
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    // Delete a pay policy by ID for an organization
    public async deletePayPolicyById(organizationId: number, policyId: string): Promise<void> {
        const url: string = `${this.taApiRoot}/organizations/${organizationId}/paypolicies/${policyId}`;
        let request: HttpRequest<any> = new HttpRequest('DELETE', url);

        try {
            const response = await this.apiUtilService.request<any, any>(request);
            console.log('Pay policy deleted successfully.');
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    // Get all pay policy rules for an organization
    public async getAllPayPolicyRules(organizationId: number): Promise<PayPolicyRule[]> {
        const url: string = `${this.taApiRoot}/organizations/${organizationId}/paypolicies/rules`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);

        try {
            const response = await this.apiUtilService.request<any, any>(request);
            if (response && response.data) {
                return response.data.map(this.mapService.mapRuleToModel);
            }
            return []; // Return empty list if no data found
        } catch (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                return []; // Return empty list for 404 errors
            }
            this.handleError(error);
            throw error;
        }
    }

    // Get pay policy rules by policy ID for an organization
    public async getPayPolicyRulesByPolicyId(organizationId: number, policyId: string): Promise<PayPolicyRule[]> {
        const url: string = `${this.taApiRoot}/organizations/${organizationId}/paypolicies/${policyId}/rules`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);

        try {
            const response = await this.apiUtilService.request<any, any>(request, true);
            if (response && response.data) {
                return response.data.map(this.mapService.mapRuleToModel);
            }
            return []; // Return empty list if no data found
        } catch (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                return []; // Return empty list for 404 errors
            }
            this.handleError(error);
            throw error;
        }
    }

    // Create a pay policy rule for an organization
    public async createPayPolicyRule(organizationId: number, policyId: string, dto: PayPolicyRuleDto): Promise<void> {
        const url: string = `${this.taApiRoot}/organizations/${organizationId}/paypolicies/${policyId}/rules`;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const request: HttpRequest<any> = new HttpRequest('POST', url, dto, { headers });

        try {
            const response = await this.apiUtilService.request<any, any>(request);
            console.log('Pay policy rule created successfully.');
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    // Delete a pay policy rule by ID for an organization
    public async deletePayPolicyRule(organizationId: number, policyId: string, ruleId: number): Promise<void> {
        const url: string = `${this.taApiRoot}/organizations/${organizationId}/paypolicies/${policyId}/rules/${ruleId}`;
        let request: HttpRequest<any> = new HttpRequest('DELETE', url);

        try {
            const response = await this.apiUtilService.request<any, any>(request);
            console.log('Pay policy rule deleted successfully.');
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    public async isPolicyUsed(organizationId: number, policyId: string): Promise<boolean> {
        const url: string = `${this.taApiRoot}/organizations/${organizationId}/paypolicies/${policyId}/isused`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);

        try {
            const response = await this.apiUtilService.request<any, any>(request);
            if (response && response.data && typeof response.data.isUsed === 'boolean') {
                return response.data.isUsed;
            }
            return false; // Default to false if no data is found
        } catch (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                return false; // Return false for 404 errors, assuming policy is not used
            }
            this.handleError(error);
            throw error;
        }
    }

    private handleError(error: any): void {
        if (error.status === 404 || (error.error && error.error.status === 404))
            console.warn('Resource not found:', error);
        if (error.status === 500 || (error.error && error.error.status === 500)) {
            console.error('Internal server error:', error);
        } else {
            console.error('An error occurred:', error);
        }
    }
}
import * as moment from 'moment';
import { Input, Output, Directive } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { OrgLevel } from '../../../../state-model/models/index';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { ITImecardSummarySectionContainer } from '../../../models/index';
import { PayCycle } from '../../../../organization/models/index';
import { Subscription } from 'rxjs';

@Directive()
export abstract class SummarySectionBase {
    @Input()
    public set container(value: ITImecardSummarySectionContainer) {
        this.m_container = value;
        this.refreshGrid();
    }

    @Input()
    public payrollCycle: PayCycle;

    public get container(): ITImecardSummarySectionContainer {
        return this.m_container;
    }

    public gridState: KendoGridStateHelper<ITImecardSummarySectionContainer>;

    public management: TimecardsSummaryManagementService;
    public abstract grid: GridComponent;
    protected orgLevelName: string;
    protected todayDateTime: string;

    @unsubscribeAll('destroy')
    private subscriptions: StringMap<Subscription> = {};
    private m_container: ITImecardSummarySectionContainer;

    constructor(management: TimecardsSummaryManagementService) {
        this.management = management;
        this.gridState = new KendoGridStateHelper<ITImecardSummarySectionContainer>();
    }

    public exportToExel(): void {
      this.todayDateTime = moment().format(appConfig.dateTimeFormatUS);
      this.grid.saveAsExcel();
    }

    public exportToPdf(): void {
      this.todayDateTime = moment().format(appConfig.dateTimeFormatUS);
      this.grid.saveAsPDF();
    }

    protected setupManagementService(): void {
        this.subscriptions.onLoaded = this.management.onLoaded$.subscribe(() => {
            this.onDataLoaded();
        });

        this.subscriptions.orgLevel = this.management.orgLevel$.subscribe((o: OrgLevel) => {
            this.orgLevelName = o.name;
        });
    }

    protected destroy(): void {}

    protected refreshGrid(): void {

        if (!this.m_container || !this.m_container.records) {
            this.gridState.view = null;
            return;
        }

        this.gridState.view = process(this.m_container.records, this.gridState.state);
    }

    protected abstract onDataLoaded(): void;

}

import * as _ from 'lodash';
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { unsubscribeAll } from '../../../../../core/decorators/index';

import { PMDialogOptions } from '../../../models/index';

import { Employee } from '../../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';

import { PmReviewEntry } from '../../../models/index';
import { PmCreationManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-pm-create-review-container',
  templateUrl: 'pm-create-review-container.component.html',
  styleUrls: ['pm-create-review-container.component.scss'],
  providers: [PmCreationManagementService]
})
export class PmCreateReviewContainerComponent implements OnInit, OnDestroy {
  @Input()
  public options: PMDialogOptions;

  @Output()
  public action = new EventEmitter<boolean>();

  @Output()
  public employeeSelected = new EventEmitter<number>();


  public get hasEmployee(): boolean {
    return this.prevReview ? this.review.hasEmployee : this.isReviewMode;
  }

  public get isNew(): boolean {
    return this.review.isNew;
  }

  public get hasError(): boolean {
    return _.size(this.errorMessage) > 0;
  }

  public get canEdit(): boolean {
    return this.review ? this.review.canEdit : false;
  }

  public get isEditMode(): boolean {
    return this.review.isEditMode || this.review.isNew;
  }

  public get isComplete(): boolean {
    return this.review.status === 'Completed';
  }

  public orgLevel: OrgLevel;

  public isReviewMode: boolean;
  public hasChanges: boolean;
  public formValid: boolean = true;

  public loadingReview = true;
  public loadingEmployees = true;

  private review = new PmReviewEntry();
  private prevReview;
  private errorMessage = '';

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor (private manService: PmCreationManagementService, private changeDetector: ChangeDetectorRef) { }

  public ngOnInit(): void {

    if (_.isObjectLike(_.get(this.options, 'employee'))) {
      this.isReviewMode = true;
      this.manService.setEmployeeWithoutProcessing(this.options.employee);
      this.employeeSelected.emit(this.options.employee.id);
    } else {
      this.isReviewMode = _.isObjectLike(_.get(this.options, 'review.employee'));
      if (this.isReviewMode) {
        this.manService.setEmployeeWithoutProcessing(_.get(this.options, 'review.employee'));
      }
    }

    this.manService.init(this.options.review);

    this.subscriptions.loading = this.manService.subscribeToLoading((isLoading: boolean) => {
      this.loadingReview = isLoading;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.subscriptions.review = this.manService.subscribeToReviewChanged((review: PmReviewEntry) => {

      this.loadingEmployees = false;
      this.review = review;

      if (!this.prevReview) {
        this.prevReview = _.cloneDeep(review);
      }
      // ignore changes in this prop
      this.prevReview.isEditMode = review.isEditMode;
      this.prevReview.employee = review.employee;
      this.prevReview.templates = review.templates;
      this.prevReview.id = review.id;
      this.hasChanges = JSON.stringify(this.prevReview) !== JSON.stringify(review);
      this.isReviewMode = _.isObjectLike(review.employee);
    });

    this.subscriptions.orgLevel = this.manService.subscribeToOrgLevel((o: OrgLevel) => {
      this.orgLevel = o;
    });

    this.subscriptions.valid = this.manService.onValidityChanged((valid: boolean) => {
      this.formValid = valid;
    });

    this.subscriptions.close = this.manService.subscribeToClosePopup(() => {
      this.onClose();
    });

  }

  public ngOnDestroy(): void {
    this.manService.destroy();
  }

  public onSearchLoadStatusChange(isLoading: boolean): void {
    this.loadingEmployees = isLoading;
  }

  public onEmployeeSelected(emp: Employee): void {
    if (_.isObjectLike(emp) && _.isFinite(emp.id)) {
      this.isReviewMode = true;
      this.manService.setEmployee(emp);
      this.employeeSelected.emit(emp.id);
    }
  }

  public onEdit(): void {
    this.manService.toggleEditMode(true);
  }

  public async onSave(): Promise<void> {
    this.prevReview = null;
    this.manService.saveReview();
  }

  public onCancel(): void {
    this.manService.cancelEditing();
  }

  public onClose(): void {
    this.action.emit(false);
  }
}


import {combineLatest} from 'rxjs/operators';
import * as moment from 'moment';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators';
import { GridComponent } from '@progress/kendo-angular-grid';
import { UserApplication, OrgLevel } from '../../../state-model/models';
import { Observable, Subscription } from 'rxjs';
import { IApplicationConfig, appConfig } from '../../../app.config';
import { IRangeDates } from '../../../common/models/range-dates';
import { AttendancePointsTotals, AttendancePointsTotalsData } from '../../models/attendance-points/attendance-points-totals';
import { KendoGridStateHelper, DeviceDetectorService, EmployeeSectionNavigationService } from '../../../common';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { AttendancePointsApiService } from '../../services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'slx-attendancce-points-totals',
  templateUrl: 'attendancce-points-totals.component.html',
  styleUrls: ['attendancce-points-totals.component.scss']
})
export class AttendanccePointsTotalsComponent implements OnInit, OnDestroy {

  public get isLockedColumn(): boolean {
    return this.devDetector.isDesktop;
  }

  @mutableSelect('application')
  public application: Observable<UserApplication>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  public state: {
    isLoading: boolean;
  };

  public appConfig: IApplicationConfig;
  public startDate: Date;
  public endDate: Date;

  private currentOrgLevel: OrgLevel;
  public isActiveOnly: boolean = true;
  private currentApplication: UserApplication;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription>;

  public xlsxName = 'Attendance_Points_Totals_';
  public pdfName = 'Attendance_Points_Totals_';
  public columnsGroupName = 'AttendancePointsTotals';
  public pageSize: number = 50;

  public gridState: KendoGridStateHelper<AttendancePointsTotals>;
  public records: AttendancePointsTotals[];
  public buyBackEndabled: boolean;

  constructor(
    private devDetector: DeviceDetectorService,
    private api: AttendancePointsApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.subscriptions = {};
    this.state = {
      isLoading: false
    };
    this.startDate = moment().subtract(1, 'year').toDate();
    this.endDate = moment().toDate();

    this.gridState = new KendoGridStateHelper<AttendancePointsTotals>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [
      { field: 'organization.name', dir: 'asc' }
      , { field: 'department.name', dir: 'asc' }
      , { field: 'employee.name', dir: 'asc' }
    ];
  }

  public ngOnDestroy(): void {
    this.appConfig = null;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    let date = this.getCurrentDate();
    let dateStr = moment(date).format(appConfig.dateFormat);
    this.xlsxName += `${dateStr}.xlsx`;
    this.pdfName += `${dateStr}.pdf`;

    this.subscriptions.orgLevelSubscription = this.orgLevel$.pipe(
      combineLatest(this.application))
      .subscribe((value: [OrgLevel, UserApplication]) => {
        let [orgLevel, application]: [OrgLevel, UserApplication] = value;
        if (!orgLevel || !orgLevel.id || !application.id) {
          return;
        }
        this.currentOrgLevel = orgLevel;
        this.currentApplication = application;
        this.loadTotals();
      });

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());

  }

  public loadTotals(): void {
    this.state.isLoading = true;
    this.gridState.state.skip = 0;
    this.api.loadAttendancePointsTotals(this.currentOrgLevel.id, this.isActiveOnly)
      .then((data: AttendancePointsTotalsData) => {
        this.records = data.records;
        this.buyBackEndabled = data.buyBackEnabled;
        this.refreshGrid();
      }).finally(() => {
        this.state.isLoading = false;
      });
  }

  public onDisplaySettingsChanged(): void {
    this.loadTotals();
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.loadTotals();
  }

  public onEmpNameClick(empId: number): void {
    let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.activatedRoute);
    const urlTree = navService.getUrlTreeFromRoot(empId, true);
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigateByUrl(urlTree, extras);
  }

  public onExportToExcel(): void {
    this.grid.saveAsExcel();
  }

  public onExportToPdf(): void {
    this.grid.saveAsPDF();
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => {
      return {
        data: process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
      };
    };
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  private getCurrentDate(): Date {
    return new Date();
  }



}

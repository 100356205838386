import { Injectable, InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import * as moment from 'moment';
import * as _ from 'lodash';
import { ToolbarBaseService, TOOLBAR_SERVICE } from '../../../core/services/index';
import { MasterScheduleTotalsSettings, MasterScheduleSettings,
  MasterScheduleFilters, IMasterScheduleGroupingType } from '../../models/index';
import {  } from '../../models/';

@Injectable()
export class MasterScheduleToolbarService extends ToolbarBaseService<MasterScheduleFilters> {

  public onSettingsChanged$: ReplaySubject<MasterScheduleSettings>;

  public onGroupingChanged$: ReplaySubject<IMasterScheduleGroupingType>;

  constructor() {
    super();
    this.onSettingsChanged$ = new ReplaySubject(1);
    this.onGroupingChanged$ = new ReplaySubject(1);
  }

  public settingsChanged(columnsSettings: MasterScheduleSettings): void {
    this.onSettingsChanged$.next(columnsSettings);
  }

  public groupingChanged(columnsSettings: IMasterScheduleGroupingType): void {
    this.onGroupingChanged$.next(columnsSettings);
  }
}

import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { QueryBuilderComponent } from '../query-builder/index';
import { TreeModel, TreeNodeModel } from '../tree/index';
import { QueryModel, LogicalExpressionModel, NodeData, ILogicExpression } from '../query-builder/index';
import { EmployeeSearchApiService, EmployeeSearchMapService } from '../../services/index';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LookupApiService } from '../../../../organization/services/index';
import { SearchCategory } from '../../../../organization/models/lookup/index';
import { Subscription ,  Observable } from 'rxjs';
import { OrgLevel } from '../../../../state-model/models/index';
import { mutableSelect } from '../../../../core/decorators/redux-decorators';
import * as _ from 'lodash';
import { EmployeeSearchResult } from '../../models/index';
import { ComparisionOperator } from '../query-builder/models/logical-expression.model';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-search',
  encapsulation: ViewEncapsulation.Emulated,
  templateUrl: 'employee-search.component.html',
  styleUrls: ['employee-search.component.scss']
})
export class EmployeeSearchComponent implements OnInit, OnDestroy {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public state: {
    isLoading: boolean;
    displayResults: boolean;
    queryBuilder: boolean;
  };
  public queryModel: QueryModel;
  public searchResults: EmployeeSearchResult[];

  private currentOrgLevel: OrgLevel;
  private orgLevelSubscription: Subscription;

  private mapService: EmployeeSearchMapService;

  private api: EmployeeSearchApiService;
  private lookup: LookupApiService;

  constructor(service: EmployeeSearchApiService, lookup: LookupApiService, mapService: EmployeeSearchMapService) {
    this.api = service;
    this.lookup = lookup;
    this.mapService = mapService;
    this.queryModel = new QueryModel(this.mapService);
  }

  public ngOnInit(): void {

    this.state = {
      isLoading: false,
      displayResults: false,
      queryBuilder: false
    };

    this.state.isLoading = true;

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.currentOrgLevel = orgLevel;
    });

    let root: TreeNodeModel = this.queryModel.root;
    let children: TreeNodeModel[] = root.children;
    let firstChildExpression: LogicalExpressionModel = root.children[0].data.expression;

    this.lookup.getSearchCategories()
      .then((categories: SearchCategory[]) => {

        this.queryModel.categories = categories;

        let employeeCategory: SearchCategory = _.find(categories, (cat: SearchCategory) => {
          return cat.categoryFieldName === 'EmpName';
        });

        if (employeeCategory) {
          firstChildExpression.searchPredicate.searchCategoryName = employeeCategory.categoryFieldName;
          firstChildExpression.searchPredicate.searchOperator = QueryModel.getDefaultOperator(employeeCategory.operators);
        }

        this.queryModel.setExpanded(root);

        this.state.isLoading = false;
        this.state.queryBuilder = true;
        this.state.displayResults = false;

      })
      .catch(() => {
        this.state.isLoading = false;
        this.state.queryBuilder = true;
        this.state.displayResults = false;
      });
  }

  public ngOnDestroy(): void {
    this.orgLevelSubscription.unsubscribe();
  }

  public onBackToQuery(): void {
    this.state.displayResults = false;
    this.state.queryBuilder = true;
  }

  public onSubmit(event: Event): void {

    event.preventDefault();
    if (this.queryModel.valid) {

      let dto: ILogicExpression = this.queryModel.prepareDTO();
      this.state.isLoading = true;
      this.api.search(dto, this.currentOrgLevel)
        .then((results: EmployeeSearchResult[]) => {
          this.searchResults = results;
          this.state.isLoading = false;
          this.state.queryBuilder = false;
          this.state.displayResults = true;
        })
        .catch(() => {
          this.state.isLoading = false;
          this.state.queryBuilder = true;
          this.state.displayResults = false;
        });

    }
  }
}

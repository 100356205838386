
import {combineLatest} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { mutableSelect } from '../../../../../../core/decorators/index';
import { Observable } from 'rxjs';
import { AddEmployeeWizardActions, AddEmployeeWizardStatus, IAddEmployeeWizardState } from '../../../../store/index';
import { AddEmployeeModel } from '../../../../models/index';
import { AddEmployeeBasicComponent } from '../add-employee/add-employee-basic.component';
import { EmployeeSectionNavigationService } from '../../../../../../common/services/index';
import { appConfig } from '../../../../../../app.config';
import { SsoNavigationService } from '../../../../../../organization';
import { AppSettingsManageService } from './../../../../../../app-settings/services/app-settings-manage.service';
import { AppServerConfig } from './../../../../../../app-settings/model/app-server-config';

@Component({
  moduleId: module.id,
  selector: 'slx-add-employee-finish',
  templateUrl: 'add-employee-finish.component.html',
  styleUrls: ['add-employee-finish.component.scss']
})
export class AddEmployeeFinishComponent extends AddEmployeeBasicComponent implements OnInit, OnDestroy {
  @mutableSelect('addEmployeeWizard')
  public addEmployeeWizard$: Observable<IAddEmployeeWizardState>;

  public get wizardError(): boolean {
    return (this.employeeAddStatus === AddEmployeeWizardStatus.error);
  }
  public get wizardSuccess(): boolean {
    return (this.employeeAddStatus === AddEmployeeWizardStatus.success);
  }
  private employeeListActions: AddEmployeeWizardActions;
  private router: Router;
  private route: ActivatedRoute;
  private employeeAddStatus: AddEmployeeWizardStatus;
  private newEmpId: number;
  private appSettingManageService: AppSettingsManageService;
  public isNgpUser: boolean = false;
  public orgLevelId: number;
  public payrollNumber: string = null;
  constructor(addEmployeeWizardActions: AddEmployeeWizardActions,
    employeeListActions: AddEmployeeWizardActions,
    router: Router, route: ActivatedRoute, 
    appSettingManageService: AppSettingsManageService, 
    public ssoService: SsoNavigationService
    ) {
    super(addEmployeeWizardActions);
    this.employeeListActions = employeeListActions;
    this.router = router;
    this.route = route;        
    this.appSettingManageService = appSettingManageService;
  }

  public ngOnInit(): void {
    this.route.params.pipe(
    combineLatest(this.route.queryParams))
    .subscribe(([params, queryParams]) => {
      this.orgLevelId = +queryParams['orgLevelId'] || undefined;
    })
    this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe((value: IAddEmployeeWizardState) => {
      this.onWizard(value.model);
      this.employeeAddStatus = value.status;
      this.newEmpId = value.newEmpId;
      this.payrollNumber = value.payrollNumber;
    });    
    this.getNGPEnabledFlag();
  }
  public addAnotherEmployee(): void {
    this.addEmployeeWizardActions.startWizard(new AddEmployeeModel());
  }

  public goThisEmployee(): void {
    let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
    navService.NavigateToEmployeeSections(this.newEmpId, false);
  }
  public activateNGPSSO(){
    const menuItem = 'nextgenpayroll';
    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${appConfig.api.sso}/${menuItem}?orglevel=${this.orgLevelId}`;
    this.ssoService.navigationToV5(url);
  }

  public goEmployeeList(): void {
    if (this.addEmployeeModel.organization && this.addEmployeeModel.department) {
      this.employeeListActions.selectEmployee(this.addEmployeeModel.organization.orgLevelId, this.addEmployeeModel.department.orgLevelId, this.newEmpId);
    }
    this.router.navigate(['../apps/scheduler/employee_list']);
  }

  public async getNGPEnabledFlag() {
    let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();    
    const isNGPUser = {'IsNextgenPayrollEnabled' : appServerConfig.IsNextgenPayrollEnabled}    
    let isValidEmployeeType = true;
    if((this.addEmployeeModel['employeeType'] && this.addEmployeeModel.employeeType['name'] && (this.addEmployeeModel.employeeType.name === 'AFT' || this.addEmployeeModel.employeeType.name === 'APT')) 
    || (this.addEmployeeModel.organization && this.addEmployeeModel.organization.id == 1000) )
    {
      isValidEmployeeType = false;
    }
    if(isNGPUser && isValidEmployeeType){
      this.isNgpUser = isNGPUser['IsNextgenPayrollEnabled'];
    }
  }
}

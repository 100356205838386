import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';

import { Observable ,  Subject ,  Subscription } from 'rxjs';

import { SсheduleHeaderMode, SсheduleHeaderModeDefinition } from '../../models/index';
import { CollapsibleSectionService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-collapsible-section',
  templateUrl: 'collapsible-section.component.html',
})
export class CollapsibleSectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public uniqId: string;
  @Input()
  public title: string;
  @Input()
  public expanded: boolean;
  @Input()
  public customIcon: boolean;
  @Input()
  public emptyHeader: boolean = false;
  @Input()
  public counter: number;
  @Input()
  public mode: SсheduleHeaderModeDefinition;
  @Input()
  public headerOpenClose: boolean = false;

  @Output()
  public expandChanged: EventEmitter<CollapsibleSectionComponent>;

  public get hasCustomIcon(): boolean {
    return _.isString(this.customIcon) && _.size(this.customIcon) > 0;
  }

  public get hasCounter(): boolean {
    return _.isFinite(this.counter);
  }

  @unsubscribe()
  public sectionSubscription: Subscription;
  private sectionService: CollapsibleSectionService;

  constructor(sectionService: CollapsibleSectionService) {
    this.sectionService = sectionService;
    this.expandChanged = new EventEmitter<CollapsibleSectionComponent>();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const self: any = this;
    _.forEach(changes, (change, prop) => {
      if (changes.previousValue !== change.currentValue) {
        self[prop] = change.currentValue;
      }
    });
  }

  public ngOnInit(): void {
    this.sectionSubscription = this.sectionService.subscribeToToggleSections((isExpand: boolean) => {
      this.expanded = isExpand;
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onToggleSection(): void {
    this.expanded = !this.expanded;
    this.expandChanged.emit(this);
  }

  public toggleIconClass(): string {
    return this.expanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down';
  }

  public toggleIconTitle(): string {
    return this.expanded ? 'Hide section' : 'Show section';
  }

  public isShown(): boolean {
    return this.expanded;
  }

  public getModeClass(): string {
    let cssClass: string = '';
    switch(this.mode) {
      case SсheduleHeaderMode.blue:
        cssClass = 'blue';
        break;
      case SсheduleHeaderMode.gray:
        cssClass = 'gray';
        break;
      case SсheduleHeaderMode.white:
        cssClass = 'white';
        break;
      default:
    }

    return cssClass;
  }
}

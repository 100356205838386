import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import { process, State } from '@progress/kendo-data-query';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Department, Organization } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { LookupApiService } from '../../../../organization/services/index';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';

import { Shift, ConfOrganization } from '../../../models/index';
import { ShiftsApiService } from '../../../services/index';
import { KendoGridCustomSelectionHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-custom-selection-helper';
import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { Actions } from '../../../../core/models/index';
import { NotificationsService } from '../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';

@Component({
  moduleId: module.id,
  selector: 'slx-copy-shifts',
  templateUrl: 'copy-shifts.component.html',
  styleUrls: ['copy-shifts.component.scss']
})
export class CopyShiftsComponent implements OnInit, OnDestroy {

  @Input()
  public set shifts(value: Shift[]) {
    this.m_shifts = value;
    this.refreshGrid();
  }

  public appConfig: IApplicationConfig = appConfig;
  public gridState: KendoGridStateHelper<Shift>;
  public selectionHelper: KendoGridCustomSelectionHelper<Shift>;

  @Output('onDiscard')
  public discardEmitter: EventEmitter<any> = new EventEmitter<any>();

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public state: {
    isLoading: boolean;
    allowEdit: boolean;
    allowCopy: boolean;
    departmentLevel: boolean;
  };

  public copyToCenter: ConfOrganization;
  public copyToDepartment: Department;
  public copyFromDepartment: string;
  public copyFromCenter: string;

  public get organizations(): ConfOrganization[] {
    return this.m_organizations;
  }
  private m_organizations: ConfOrganization[];

  private currentOrgLevel: OrgLevel;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private formSubscription: Subscription;

  private m_shifts: Shift[];
  private m_targetShifts: Shift[];

  @ViewChild('copyActionsForm', {static: true}) private form: UntypedFormGroup;

  @Input()
  public isDefaultShiftEnabled: boolean;

  constructor(
    private api: ShiftsApiService,
    private lookup: LookupApiService,
    private notificationsService: NotificationsService) {
    this.gridState = new KendoGridStateHelper<Shift>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
  }

  public ngOnInit(): void {
    this.state = {
      isLoading: false,
      allowEdit: true,
      allowCopy: false,
      departmentLevel: false
    };


    this.gridState.onRefreshGrid.subscribe((state: State) => {
      this.refreshGrid();
    });

    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.checkActionsConditions();
    });

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.state.isLoading = true;
      this.currentOrgLevel = orgLevel;
      this.copyFromDepartment = this.currentOrgLevel.name;
      this.selectionHelper.clearSelection();

      this.lookup.getOrganizations(undefined, this.currentOrgLevel.parentId).then((orgs: Organization[]) => {
        if (orgs && orgs.length > 0) {
          this.copyFromCenter = orgs[0].name;
        } else {
          this.copyFromCenter = this.currentOrgLevel.parentId.toString();
        }
      });

      this.checkActionsConditions();
      this.api.getCopyShiftConfiguration(this.currentOrgLevel.id).then((org: ConfOrganization[]) => {
        this.m_organizations = org;

        this.copyToCenter = _.first(org);
        if (this.copyToCenter) {
          this.copyToDepartment = _.first(this.copyToCenter.departments);
          if (this.copyToDepartment) this.onTargetDepartmentChanged();
        }
        this.state.isLoading = false;
      });

    });

    this.selectionHelper.onSelectionChanged.subscribe(() => {

      if (this.selectionHelper.selectionLength > 0) {
        this.state.allowCopy = true;
      } else {
        this.state.allowCopy = false;
      }
      this.checkActionsConditions();
    });

  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onDoCopy(): void {

    this.state.isLoading = true;
    let selectedForCopyItems: Shift[] = this.selectionHelper.selection;
    let ids: number[] = _.map(selectedForCopyItems, (shift: Shift) => { return shift.id; });

    this.api.copyShifts(this.currentOrgLevel.relatedItemId, this.copyToDepartment.id, ids)
      .then((respone?: any) => {
        this.state.isLoading = false;
        this.selectionHelper.clearSelection();
        this.fetchShiftsList();
        this.notificationsService.success('Copy Shifts', 'Shift(s) has been successfully copied.');
      }).catch((reason: any) => {
        this.state.isLoading = false;
        this.selectionHelper.clearSelection();
        this.updateSelectableState();
      });
  }

  public onDiscardCopy(): void {
    this.selectionHelper.clearSelection();
    this.discardEmitter.emit();
  }

  public onTargetDepartmentChanged(): void {
    if (!this.copyToDepartment || !this.copyToDepartment.orgLevelId) {
      return;
    }
    this.selectionHelper.clearSelection();
    this.fetchShiftsList();
  }

  
  public onTargetOrganizationChanged(): void {
    this.copyToDepartment = _.first(this.copyToCenter.departments);
    this.onTargetDepartmentChanged();
  }

  protected fetchShiftsList(): void {

    this.api.getShiftsList(this.copyToDepartment.orgLevelId).
      then((result: { actions: Actions, records: Shift[] }) => {
        this.m_targetShifts = result.records;
        this.updateSelectableState();
      });
  }

  protected updateSelectableState(): void {
    _.each(this.m_shifts, (item: Shift) => {
      item.selectable = !_.some(this.m_targetShifts,
        (target: Shift) =>
          target.start.getTime() === item.start.getTime()
          && target.end.getTime() === item.end.getTime()
          && target.duration === item.duration);
    });
  }

  protected refreshGrid(): void {

    if (!this.m_shifts) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.m_shifts, this.gridState.state);
    this.selectionHelper.view = this.gridState.view;
  }

  private checkActionsConditions(): void {

    let allowCopy: boolean = true;

    if (this.currentOrgLevel.type === OrgLevelType.department) {
      this.state.departmentLevel = true;
    } else {
      this.state.departmentLevel = false;
      allowCopy = false;
    }

    if (!this.form.valid) {
      allowCopy = false;
    }

    if (this.selectionHelper.selectionLength === 0) {
      allowCopy = false;
    }

    this.state.allowCopy = allowCopy;
  }
}

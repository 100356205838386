import { Provider } from '@angular/core';

export * from './schedule-console/schedule-console-api.service';
export * from './schedule-console/schedule-console-map.service';
export * from './schedule-console/schedule-console-toolbar.service';
export * from './schedule-console/schedule-console-chart.service';
export * from './schedule-console/schedule-console-filters.service';

import { ScheduleConsoleApiService } from './schedule-console/schedule-console-api.service';
import { ScheduleConsoleMapService } from './schedule-console/schedule-console-map.service';
import { ScheduleConsoleChartService } from './schedule-console/schedule-console-chart.service';
import { ScheduleConsoleFiltersService } from './schedule-console/schedule-console-filters.service';

export const scheduleConsoleServices: Provider[] = [
  ScheduleConsoleApiService,
  ScheduleConsoleMapService,
  ScheduleConsoleChartService,
  ScheduleConsoleFiltersService
];

<kendo-grid [data]="gridView"
  scrollable="'scrollable'"
  [sortable]="{ mode: 'multiple' }"
  [sort]="sort"
  (sortChange)="onSortChange($event)"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="skip"
  (pageChange)="onPageChange($event)"
>
  <ng-template kendoGridToolbarTemplate position="top">
    
    <form novalidate class="f-container f-container-fix">
      
      <slx-date-range-ngx 
        name="startEndDate"
        [startDate]="startDate"
        [endDate]="endDate"
        (rangeDateChanged)="onFilterDateChanged($event)"
      ></slx-date-range-ngx>


        <span class="f-spacer"></span>

      <span class="curr-blns-lbl"> Current Balance: {{attendancePoints?.currentBalance}}</span>

      <div class="f-buttons f-buttons-fix">
        <button class="add-ap-button theme-iconed-accent-button"[disabled]="!hasAccessToAdd || !isEditable" (click)="onAddPointsClicked()">
            <span class="icon-button-content">
              <span>Add</span>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </span></button>
        <button class="remove-ap-button theme-iconed-button" [disabled]="!isAnyItemSelected || !hasAccessToDelete || !isEditable" (click)="onRemovePointsClicked()">
            <span class="icon-button-content">
                <span>Remove</span>
                <i class="fa fa-minus" aria-hidden="true"></i>
            </span>
            </button>
      </div>
    </form>

  </ng-template>
  <kendo-grid-column width="30">
    <ng-template kendoGridHeaderTemplate>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [disabled]="dataItem.item?.categoryName?.fieldValue == 'Buy Back calc'" [(ngModel)]="dataItem.selected" (ngModelChange)="onItemSelectionChanged()" [(checked)]="dataItem.selected"
      />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="item.dateOn.fieldValue" filter="date" width="100">
    <ng-template kendoGridHeaderTemplate>
      Date
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.item?.dateOn?.fieldValue | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="item.categoryName.fieldValue" width="100">
    <ng-template kendoGridHeaderTemplate>
      Category
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.item?.categoryName?.fieldValue }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="item.definition.fieldValue" width="100">
    <ng-template kendoGridHeaderTemplate>
      Definition
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.item?.definition?.fieldValue }}
    </ng-template>
    <ng-template kendoGridFooterTemplate>
      <div class="total-text">Total for selected range</div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="item.value.fieldValue" filter="numeric" width="100">
    <ng-template kendoGridHeaderTemplate>
      Original Points
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.item?.value?.fieldValue | number: ".2-2" }}
    </ng-template>
    <!--
    <ng-template kendoGridFooterTemplate>
      {{ totalPoints | number: ".2-2" }}
    </ng-template>
    -->
  </kendo-grid-column>

  <kendo-grid-column field="item.value2.fieldValue" filter="numeric" width="100">
    <ng-template kendoGridHeaderTemplate>
      Current Points
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.item?.value2?.fieldValue | number: ".2-2" }}
    </ng-template>
    <ng-template kendoGridFooterTemplate>
      {{ totalCurrentPoints | number: ".2-2" }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="item.expiration.fieldValue" filter="date" width="100">
    <ng-template kendoGridHeaderTemplate>
      Expiration
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.item?.expiration?.fieldValue | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Subscription ,  Subject } from 'rxjs';

import { Assert } from '../../../framework/index';

@Injectable()
export class AccrualsManagementService {
  private loadAccrualsBalances$ = new Subject<void>();
  private loadAccrualsTransactions$ = new Subject<void>();

  public loadAccrualsBalances(): void {
    this.loadAccrualsBalances$.next();
  }

  public loadAccrualsTransactions(): void {
    this.loadAccrualsTransactions$.next();
  }

  public subscribeToLoadAccrualsBalances(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loadAccrualsBalances$.subscribe(callback);
  }

  public subscribeToLoadAccrualsTransactions(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loadAccrualsTransactions$.subscribe(callback);
  }
}

import { PayCycle } from './../../../../organization/models/lookup/pay-cycle';
import { ActivatedRoute, Router } from '@angular/router';
import { PayCycleHelperService } from './../../../../organization/services/index';
import { Component, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { unsubscribe, unsubscribeAll } from '../../../../core/decorators/index';

import { DailyTimecardManagementService, editAction } from '../../../services/index';
import { TimeCardModel, TimeCardDisplayOptions, TimecardAddCommentsReq } from '../../../models/daily-timecard/index';
import { AuditTrailLimitedDialogComponent } from '../../../../app-modules/audit-trail/components/index';
import { ModalService, ChangeManagementService, INotifyChanged, TimecardsNavigationService } from '../../../../common/services/index';
import { ChangesDialogOptions } from '../../../../common/models/index';
import { AuditTrailLimitedReq } from '../../../../app-modules/audit-trail/models/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../../common/components/index';
import { LookupService } from '../../../../organization/services/index';
import { LookupType, Lookup } from '../../../../organization/models/index';
import { TimecardPredefinedComment } from '../../../../organization/models/index';
import { TimecardAddCommentsDialogComponent } from '../../punches/add-comments-dialog/add-comments-dialog.component';
import { AppSettingsManageService } from '../../../../app-settings/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-timecard-actions-bar',
  templateUrl: 'daily-timecard-actions-bar.component.html',
  styleUrls: ['daily-timecard-actions-bar.component.scss']
})
export class DailyTimeCardActionsBarComponent implements OnDestroy {

  public model: TimeCardModel;
  public logicalDate: Date;
  public lockSave: boolean;
  public showIntervalsInTime = TimeCardDisplayOptions.showIntervalsInTime;
  public showIntervalsInDecimal = TimeCardDisplayOptions.showIntervalsInDecimal;
  public displayOptions: TimeCardDisplayOptions;
  public hasChanges: boolean;
  public currentPaycycle: PayCycle;
  public hasOverlap: boolean = false;
  // public hasOverlapNum: boolean;
  @unsubscribe()
  public loadSubscription: Subscription;
  @unsubscribe()
  public editSubscription: Subscription;
  @unsubscribeAll()
  public overlapSubscription: Subscription;
  @unsubscribe()
  private optionsChangedSubscription: Subscription;
  @unsubscribe()
  private notifyChangedSubscription: Subscription;
  @unsubscribe()
  private overlapDescSubscription: Subscription;

  private readonly approveGroup = 'approved';
  private initialApproved: boolean;
  private tcNaviService: TimecardsNavigationService;
  private timecardPredefinedComments: Lookup<TimecardPredefinedComment>;
  public preventOverlappingPunches: boolean;

  constructor(private managementService: DailyTimecardManagementService, private modalService: ModalService,
    private changeManagementService: ChangeManagementService, private lookupService: LookupService,
    private appSettingsManageService: AppSettingsManageService,
    private payCycleHelper: PayCycleHelperService, private router: Router, private route: ActivatedRoute) {
    this.loadSubscription = this.managementService.onLoaded$.subscribe((model: TimeCardModel) => {
      this.model = model;
      this.logicalDate = this.model.date;
      this.hasChanges = false;
      this.initialApproved = this.model.isLoadedApproved;
      this.findPaycycle();
    });

    this.editSubscription = this.managementService.sectionEditAction$.subscribe((action: editAction) => {
      this.lockSave = action.lockOthers;
    });

    this.overlapSubscription = this.managementService.hasOverlap$.subscribe((action: boolean) => {
      this.hasOverlap = action;
    })

    // this.overlapDescSubscription = this.managementService.overlapPunchesDesc$.subscribe((data: boolean) => {
    //   this.hasOverlapNum = data;
    // })

    this.optionsChangedSubscription = this.managementService.displayOptionSelected$
      .subscribe((options: TimeCardDisplayOptions) => {
        this.displayOptions = options;
      });

    this.changeManagementService.changesEvent.subscribe((event: INotifyChanged) => {
      this.hasChanges = !!event.changes;
    });

    this.tcNaviService = new TimecardsNavigationService(router, route);
    this.lookupService.getLookup({ lookupType: LookupType.timecardPredefinedComment })
      .then((res) => {
        this.timecardPredefinedComments = res;
    });

    this.overlapDescSubscription = this.managementService.overlapPunchesDesc$.subscribe((data: boolean) => {
      if (!data) {
        if (!this.model.hasMissingPunches) {
          this.changeManagementService.clearChanges();
          this.managementService.onSaveTimecard();
          return;
        }
        this.modalService.globalAnchor.openConfirmDialog(`Warning`, 'Missing punches appear on this timecard, do you wish to proceed?', (result: boolean) => {
          if (result) {
            this.changeManagementService.clearChanges();
            this.managementService.onSaveTimecard();
          }
        });
      }
    });
  }

  public ngOnInit(): void {
    this.appSettingsManageService.getAppServerConfig()
      .then((config) => {
        this.preventOverlappingPunches = config.preventOverlappingPunches;
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onDateChange(): void {
    /*this.changeManagementService.canMoveForward()
      .then((canMove: boolean) => {
        if (canMove) {
          this.changeManagementService.clearChanges();
        }
      });*/
    this.findPaycycle();
    this.managementService.changeDate(this.logicalDate);
    this.hasOverlap = false;
    this.managementService.setHasOverlapToTC(false);
  }

  public onRecalculate(): void {
    this.changeManagementService.canMoveForward()
      .then((canMove: boolean) => {
        if (canMove) {
          this.changeManagementService.clearChanges();
        }
      });
    this.managementService.recalculateTimecard();
  }

  public async saveTimecard(): Promise<any> {
    if (!this.model.comment && this.timecardPredefinedComments && this.timecardPredefinedComments.items.length > 0) {
      const req = new TimecardAddCommentsReq();
      req.employeeId = this.model.employee.id;
      req.employeeName = this.model.employee.name;
      req.date = this.model.date;
      req.timecardPredefinedComments = this.timecardPredefinedComments;
      const res = await TimecardAddCommentsDialogComponent.openDialog('Add Comments', req, this.modalService, 'multiple');
      if (!res.isApply) {
        return;
      }
      this.model.comment = res.comment.replace(/\n\n/g, "|");
    }

    if (this.model.unApprovedPayCodesCount > 0 && this.model.isApproved) {
      this.modalService.globalAnchor.openConfirmDialog(`Warning`, 'This timecard can`t be saved. Pay codes must be approved first. Do you want to navigate to the Pay Code Approvals screen?', (result: boolean) => {
        if (result) {
          this.navigateToUnapprovedPaycodes();
        }
      });
      return;
    }

    if (this.model.unApprovedOTCount > 0 && this.model.isApproved) {
      this.modalService.globalAnchor.openConfirmDialog(`Warning`, 'This timecard can`t be saved. Overtime must be approved first. Do you want to navigate to the Overtime Approvals Screen?', (result: boolean) => {
        if (result) {
          this.navigateToUnapprovedOvertimes(this.model.primaryPosition.orgLevelId);
        }
      });
      return;
    }
    this.saveTimecardInternal();
  }

  public approveTimecard(): void {
    if(this.model.locked || !this.model.canModifyOwnTimecard || !this.model.canApprove || this.lockSave || !this.model.canEditTimecard || this.model.isPayrollCycleSubmitted || this.model.hasMissingPunchesAlert || this.hasOverlap || this.model.hasMissingPunches)
    {
      this.modalService.globalAnchor.openInfoDialog(`Warning`, 'This timecard can`t be approved.');
      return;
    }

    if (this.model.unApprovedPayCodesCount > 0) {
      this.modalService.globalAnchor.openConfirmDialog(`Warning`, 'This timecard can`t be approved. Pay codes must be approved first. Do you want to navigate to the Pay Code Approvals screen?', (result: boolean) => {
        if (result) {
          this.navigateToUnapprovedPaycodes();
        }
      });
      return;
    }

    if (this.model.unApprovedOTCount > 0) {
      this.modalService.globalAnchor.openConfirmDialog(`Warning`, 'This timecard can`t be approved. Overtime must be approved first. Do you want to navigate to the Overtime Approvals Screen?', (result: boolean) => {
        if (result) {
          this.navigateToUnapprovedOvertimes(this.model.primaryPosition.orgLevelId);
        }
      });
      return;
    }

    this.managementService.approveTimecard();
    if (this.initialApproved === this.model.isApproved) {
      this.changeManagementService.clearChanges(this.approveGroup);
    } else {
      this.changeManagementService.changeNotify(this.approveGroup);
    }
    this.saveTimecard();
  }

  public unapproveTimecard(): void {
    if(this.model.locked || !this.model.canModifyOwnTimecard || !this.model.canUnapprove || this.model.beforeHire || this.lockSave  || this.model.isPayrollCycleSubmitted || this.hasOverlap)
    {
      this.modalService.globalAnchor.openInfoDialog(`Warning`, 'This timecard can`t be un-approved.');
      return;
    }
    this.managementService.unapproveTimecard();
    if (this.initialApproved === this.model.isApproved) {
      this.changeManagementService.clearChanges(this.approveGroup);
    } else {
      this.changeManagementService.changeNotify(this.approveGroup);
    }
    this.saveTimecard();
  }

  public exportDebugTimecard(): void {
    this.managementService.onExportTimecard();
  }

  public navigateToUnapprovedPaycodes(): void {
    this.managementService.navigateToUnapprovedPaycodes();
  }

  public navigateToUnapprovedOvertimes(homeOrgLevelId: number): void {
    this.managementService.navigateToUnapprovedOvertimes(homeOrgLevelId);
  }

  public onRecreatePunches(): void {
    if(this.disableEditTimeCard){
      this.modalService.globalAnchor.openInfoDialog(`Warning`, 'This timecard can`t be Edited.');
      return;
    }
    ConfirmDialogComponent.openDialog(
      'Warning',
      'This action will remove all existing punches and create new punches based on schedule start and end times. Do you wish to proceed?',
      this.modalService,
      (result: boolean) => {
        if (!result) {
          return;
        }
        this.changeManagementService.canMoveForward(undefined, ChangesDialogOptions.DOWORK)
          .then((canMove: boolean) => {
            if (canMove) {
              this.managementService.discardPunches();
            }
          });
      });
  }
  public onRemoveCodes(): void {
    if(this.disableEditTimeCard){
      this.modalService.globalAnchor.openInfoDialog(`Warning`, 'This timecard can`t be Edited.');
      return;
    }
    this.changeManagementService.canMoveForward(undefined, ChangesDialogOptions.DOWORK)
      .then((canMove: boolean) => {
        if (canMove) {
          this.managementService.discardPayRules();
        }
      });
  }
  public onOptionSelected(option: TimeCardDisplayOptions): void {
    this.managementService.selectDisplayOption(option);
  }
  public openAudit(): void {
    let req: AuditTrailLimitedReq = new AuditTrailLimitedReq();
    req.employeeId = +this.model.employee.id;
    req.employeeName = this.model.employee.name;
    req.date = this.logicalDate;
    req.area = 'Timecard';
    req.header = 'Timecard Audit';
    req.orgLevelId = this.model.primaryPosition.orgLevelId;
    AuditTrailLimitedDialogComponent.openDialog(req, this.modalService, (result: boolean, cmd: any) => {
      //todo
    });
  }

  public navigateToIndividualTimecards(): void {
    this.tcNaviService.NavigateToIndividualTimecards(+this.model.employee.id, this.currentPaycycle.startDate, this.currentPaycycle.endDate);
  }

  public lockTimecard(): void {
    let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      options.buttonOKtext = "Confirm";
      options.notes = "Locking the timecard will prevent any further changes or additions to the recorded time.";
      ConfirmDialogComponent.openDialog(
        'Lock Timecard', 'Are you sure you want to lock this timecard?',
         this.modalService,
        (result: boolean) => {
          if (result) {
            this.managementService.lockTimecard(this.model);
          }
        },
        options
      ); 
      
  }

  public unlockTimecard(): void {
    this.managementService.unlockTimecard(this.model);
  }

  private findPaycycle(): void {
    this.currentPaycycle = null;
    this.payCycleHelper.getPayCycleByDate(this.logicalDate, this.model.primaryPosition.orgLevelId, +this.model.employee.id)
      .then((cycle: PayCycle) => {
        this.currentPaycycle = cycle;
      });
  }

  private saveTimecardInternal(): void {
    if (this.preventOverlappingPunches) {
      this.managementService.overlapingPunchesAction(true);
    } else {
      if (!this.model.hasMissingPunches) {
        this.changeManagementService.clearChanges();
        this.managementService.onSaveTimecard();
        return;
      }
      this.modalService.globalAnchor.openConfirmDialog(`Warning`, 'Missing punches appear on this timecard, do you wish to proceed?', (result: boolean) => {
        if (result) {
          this.changeManagementService.clearChanges();
          this.managementService.onSaveTimecard();
        }
      });
    }
  }

  get disableEditTimeCard(){
    return this.model ? (!this.model.canEditTimecard || !this.model.canEditOwnTimecard) : true;
  }
}

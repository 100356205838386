import { Injectable } from '@angular/core';
import { filter, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IPayloadAction, UserApplication } from '../../state-model/models/index';
import { ApplicationActions, SidebarActions } from '../../portal/actions/index';
import { TimecardsActions } from '../store/index';
import { Observable } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../store/store';
import { mutableSelect } from '../../core/decorators/index';

@Injectable()
export class TimecardsEpics {
  @mutableSelect('application')
  public application: Observable<UserApplication>;

  private ngRedux: NgRedux<IAppState>;
  private timecardsActions: TimecardsActions;

  constructor(timecardsActions: TimecardsActions, ngRedux: NgRedux<IAppState>) {
    this.ngRedux = ngRedux;
  }

  public resetPayCycle = action$ => action$.pipe(
    filter(({ type }: { type: string }) => type === ApplicationActions.SELECT_APPLICATION ),
    mergeMap((action: IPayloadAction) => {
      return of({
          type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE,
          payload: null
        });
    }));

/*   public resetPayCycle = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    return action$.pipe(
      filter(({ type }: { type: string }) => type === ApplicationActions.SELECT_APPLICATION ),
      mergeMap((action: IPayloadAction) => {
        return of({
            type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE,
            payload: null
          });
      }));
  } */

  public resetSelectionSettings = action$ => action$.pipe(
    filter(({ type }: { type: string }) => type === TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE),
    mergeMap((action: IPayloadAction) => {
      return of({
          type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_SELECTION_SETTINGS,
          payload: null
        });
    }));

/*   public resetSelectionSettings = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    return action$.pipe(
      filter(({ type }: { type: string }) => type === TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE),
      mergeMap((action: IPayloadAction) => {
        return of({
            type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_SELECTION_SETTINGS,
            payload: null
          });
      }));
  } */
}

import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Subscription } from 'rxjs';

import { unsubscribeAll } from '../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { DeviceDetectorService, EmployeeSectionNavigationService } from '../../../../common/services/index';
import { OrgLevel } from '../../../../state-model/models/index';

import { AccrualsTransactionRecord } from '../../models/accruals-transaction-record';
import { AccrualsTransactionsManagementService } from '../../services/accruals-transactions-management.service';
import { AppServerConfig } from './../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../app-settings/services/index';

@Component({
  selector: 'slx-accruals-transactions-grid',
  templateUrl: './accruals-transactions-grid.component.html',
  styleUrls: ['./accruals-transactions-grid.component.scss']
})
export class AccrualsTransactionsGridComponent implements OnInit, OnDestroy {
  public records: AccrualsTransactionRecord[];
  public gridState: KendoGridStateHelper<AccrualsTransactionRecord[]>;
  public appConfig: IApplicationConfig;
  public orgLevel: OrgLevel;
  public pageSize: number = 50;
  public canEdit: boolean;
  public xlsxName = 'Accruals_Transaction_';
  public pdfName = 'Accruals_Transaction_';
  public columnsGroupName: string = 'AccrualsTransaction';
  public EnableRate4DecimalPlaces: boolean;
  public cells: any;

  public get isLockedColumn(): boolean {
    return this.devDetector.isDesktop;
  }

  public get isReordable(): boolean {
    return this.devDetector.isDesktop;
  }

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private accrualManagementService: AccrualsTransactionsManagementService, private devDetector: DeviceDetectorService,private appSettingsManageService: AppSettingsManageService, private router: Router, private route: ActivatedRoute) {
    this.gridState = new KendoGridStateHelper<AccrualsTransactionRecord[]>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [
      { field: 'transaction.date', dir: 'desc' },
      { field: 'employee.fullName', dir: 'asc' }
    ];
    this.appConfig = appConfig;
  }

  public ngOnInit() {
    const date = new Date().toLocaleDateString();
    this.xlsxName += `${date}.xlsx`;
    this.pdfName += `${date}.pdf`;
    this.getSettings();

    this.subscriptions.onLoaded = this.accrualManagementService
      .subscribeToLoadedRecords((r: AccrualsTransactionRecord[]) => this.assignRecords(r));

    this.subscriptions.export = this.accrualManagementService
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());
  }

  public ngOnDestroy(): void { }

  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.EnableRate4DecimalPlaces =  config.EnableRate4DecimalPlaces;
    if(this.EnableRate4DecimalPlaces){
      this.cells = {
        format: '0.0000'
      }
    }
    else{
      this.cells = {
        format: '0.00'
      }
    }
  }

  public get isShowHighPrecision(): boolean {
    return this.EnableRate4DecimalPlaces;
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }

  public onEmpNameClick(empId: number): void {
    let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
    const urlTree = navService.getUrlTreeFromRoot(empId, true);
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigateByUrl(urlTree, extras);
  }

  private assignRecords(records: AccrualsTransactionRecord[]): void {
    this.records = records;
    this.refreshGrid();
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}

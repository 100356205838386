import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { CustomDomEvents } from '../../models/index';
@Directive({
  selector: 'input,select',
})
export class FocusForwarderDirective {
  private elRef: ElementRef;
  private renderer: Renderer2;
  constructor(elRef: ElementRef, renderer: Renderer2) {
    this.elRef = elRef;
    this.renderer = renderer;
  }
  @HostListener('focus', ['$event'])
  public onFocus(event: Event): void {
    this.elRef.nativeElement.dispatchEvent(new CustomEvent(CustomDomEvents.focus, { bubbles: true }));
  }
}

import { Component, OnInit, Provider } from '@angular/core';
import { ModalService } from '../../../../common/index';
import { LookupApiService } from '../../../../organization/services/index';
import { DialogOptions } from '../../../../common/models/index';
import * as _ from 'lodash';
import { Position } from '../../../../organization/models/index';

import { RemovePositionRequest } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-positions',
  templateUrl: 'configure-positions-reassign-popup.component.html',
  styleUrls: ['configure-positions-reassign-popup.component.scss']
})
export class ConfigurePositionsReassignPopupComponent implements OnInit {

  public options: DialogOptions;
  public dialogResult: boolean;
  public effectiveDate: Date = new Date();
  public position: Position;
  public currentDate: Date = new Date();
  public reassignRequest: RemovePositionRequest;

  public get positionsFiltered(): Position[] {
    return this.m_positionsFiltered;
  }
  private m_positionsFiltered: Position[] = [];

  public static openDialog(
    reassignRequest: RemovePositionRequest,
    modalService: ModalService,
    callback: (result: boolean, req: RemovePositionRequest) => void): ConfigurePositionsReassignPopupComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 230;
    dialogOptions.width = 370;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: RemovePositionRequest,
        useValue: reassignRequest
      }
    ];
    let dialog: ConfigurePositionsReassignPopupComponent = modalService.globalAnchor
      .openDialog(ConfigurePositionsReassignPopupComponent, 'Choose position to reassign employees', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.reassignRequest);
      });
    return dialog;
  }

  constructor(options: DialogOptions, private readonly modalService: ModalService, private readonly lookupApiService: LookupApiService, reassignRequest: RemovePositionRequest) {
    this.options = options;
    this.reassignRequest = reassignRequest;
  }

  public ngOnInit(): void {
    this.lookupApiService.getPositions(null, this.reassignRequest.orgLevelId, true).then((positionLookup: Position[]) => {
      this.m_positionsFiltered = _.filter(positionLookup, (p: Position) => {
        return p.id !== this.reassignRequest.itemIdToDelete;
      });
    });
  }

  public onOk(): void {
    if (this.position) this.reassignRequest.ressignedPositionId = this.position.id;
    if (this.effectiveDate) this.reassignRequest.effectiveDate = this.effectiveDate;
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}

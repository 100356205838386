
import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import { Directive, Input, Output, EventEmitter, OnInit, OnDestroy, Host } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { StateResetTypes } from '../../../../core/models/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { StateManagementService } from '../../../../common/services/index';
import { KendoGridStateHelper } from '../../../models/index';

@Directive({
  selector: '[slxKendoGridFiltersState]',
  providers: [StateManagementService]
})
export class KendoGridFiltersStateDirective implements OnInit, OnDestroy {
  @Input('slxKendoGridFiltersState')
  public set gridStateHelper(v: KendoGridStateHelper<any>) {
    this.gridState = v;
  }

  @Output()
  public kendoGridStateChanged = new EventEmitter<State>();

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  private gridState: KendoGridStateHelper<any>;
  private orgLevel: OrgLevel;
  private initialFiltersState: State;

  constructor(
    @Host() public kendoGrid: GridComponent,
    private stateManagement: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.validateMandatoryFields();

    this.initialFiltersState = _.cloneDeep(this.gridState.state);

    this.stateManagement.init(this.gridState.gridComponentKey, true);

    this.subscriptions.orgLevel = this.orgLevel$.pipe(
      filter((o: OrgLevel) => _.isObjectLike(o) && _.isFinite(o.id) && (!_.isObjectLike(this.orgLevel) || this.orgLevel.id !== o.id)))
      .subscribe((o: OrgLevel) => this.resetGridState(o));

    this.subscriptions.stateReady = this.stateManagement.onInit$
      .subscribe(() => this.restoreGridState());

    this.subscriptions.gridState = this.kendoGrid.dataStateChange
      .asObservable()
      .subscribe(() => this.saveGridState());
  }

  public ngOnDestroy(): void {
  }

  private restoreGridState(): void {
    const state = this.getStoredState();
    this.assignRestoredState(state);
    this.kendoGridStateChanged.emit(state);
  }

  private getStoredState(): State {
    const state = this.stateManagement.getControlState(this.gridState.gridControlStateKey);
    const gState: State = _.isObjectLike(state.value) ? state.value : null;
    if (_.isObjectLike(gState) && _.isObjectLike(gState.filter)) {
      gState.filter = this.stateManagement.mapStateIntoFilters(gState.filter);
    }
    return gState;
  }

  private assignRestoredState(value: State): void {
    if (_.isObjectLike(value) && this.gridState.assignRestoredState) {
      this.gridState.state = value;
      if (this.gridState.refreshGridAfterRestoreState) {
        this.gridState.refreshGrid();
      }
    }
  }

  private saveGridState(): void {
    const gState = _.cloneDeep(this.gridState.state);
    if (_.isObjectLike(gState) && _.isObjectLike(gState.filter)) {
      gState.filter = this.stateManagement.mapFiltersIntoState(gState.filter);
    }
    this.stateManagement.setControlState(
      this.gridState.gridControlStateKey,
      { value: gState },
      this.gridState.gridComponentStateResetType
    );
  }

  private resetGridState(o: OrgLevel): void {
    const manuallyChangedOrgLevel = _.isObjectLike(this.orgLevel) && _.isFinite(this.orgLevel.id);
    this.orgLevel = o;
    if (manuallyChangedOrgLevel && this.gridState.gridComponentStateResetType & StateResetTypes.OrgLevelChange) {
      const state = _.cloneDeep(this.initialFiltersState);
      this.assignRestoredState(state);
      this.kendoGridStateChanged.emit(state);
    }
  }

  private validateMandatoryFields(): void {
    if (!(_.isObjectLike(this.gridState))) {
      throw new TypeError('The "slxKendoGridFiltersState" is not an object');
    }
    if (!(this.gridState instanceof KendoGridStateHelper)) {
      throw new TypeError('The input "slxKendoGridFiltersState" can be only instance of KendoGridStateHelper class');
    }
    if (!_.isString(this.gridState.gridComponentKey) || _.size(this.gridState.gridComponentKey) === 0) {
      throw new TypeError('The property "gridStateKey" cannot be null or empty');
    }
    if (!_.isString(this.gridState.gridControlStateKey) || _.size(this.gridState.gridControlStateKey) === 0) {
      throw new TypeError('The property "gridFilterStateKey" cannot be null or empty');
    }
    if (!_.isFinite(this.gridState.gridComponentStateResetType)) {
      throw new TypeError('The property "gridFiltersStateResetType" cannot be null or undefined');
    }
    if (this.gridState.gridComponentKey === this.gridState.gridControlStateKey) {
      throw new TypeError('The property "gridStateKey" cannot be equal to "gridFilterStateKey" ');
    }
  }
}

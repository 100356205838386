import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Subscription ,  Observable } from 'rxjs';

import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { EssTemplateManagementService } from '../../services/index';
import { EssTemplate } from '../../models/index';
import { OrgLevel } from '../../../../state-model/models';
import { PunchAttestationGroup } from '../../../punch-attestation/models';
import { PunchAttestationManagementService } from '../../../punch-attestation/services';

@Component({
  moduleId: module.id,
  selector: 'slx-ess-templates',
  templateUrl: 'ess-templates.component.html',
  styleUrls: ['ess-templates.component.scss'],
  providers: [EssTemplateManagementService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EssTemplatesComponent implements OnInit, OnDestroy {

  public isLoading: boolean;
  public data: EssTemplate[];
  public orgLevel: OrgLevel;
  public punchAttestationGroups: PunchAttestationGroup[];

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private statusSubscription: Subscription;

  constructor(private managementService: EssTemplateManagementService, private changeDetector: ChangeDetectorRef,
    private punchAttestationManagementService: PunchAttestationManagementService) {

  }

  public ngOnInit(): void {
    this.loadedSubscription = this.managementService.onLoaded$.subscribe((data: EssTemplate[]) => {
      this.data = data;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
    this.statusSubscription = this.managementService.onLoadStatus$.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
    this.punchAttestationManagementService.onLoaded$.subscribe((groups: PunchAttestationGroup[]) => {
      this.punchAttestationGroups = groups;
    });

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevel = orgLevel;
        this.loadGoGroups();
      }
    });
  }

  loadGoGroups() {
    if (!this.orgLevel.id) {
      return;
    }

    this.punchAttestationManagementService.loadGoGroups(this.orgLevel);
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}

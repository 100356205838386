import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, ElementRef, AfterViewInit, Input } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { ScrollElementSelectorDirective } from '../../../../common/directives/index';
import {
  GridComponent
} from '@progress/kendo-angular-grid';
import { Router, ActivatedRoute } from '@angular/router';
import { IColumnSettings } from '../../../../core/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { ExceptionConsoleContainer, ExceptionConsoleRecord, ExceptionConsoleState, IExceptionConsoleColumnPayload } from '../../../models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { ExceptionConsoleManagementService } from '../../../services/exception-console/exception-console-management.service';
import { ExceptionConsoleNavigationService } from '../../../../common/services/index';
@Component({
  moduleId: module.id,
  selector: 'slx-exception-console-grid',
  templateUrl: 'exception-console-grid.component.html',
  styleUrls: ['exception-console-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExceptionConsoleGridComponent implements OnInit, OnDestroy {

  @Input()
  public usePayCycle: boolean;

  public appConfig: IApplicationConfig;
  public records: ExceptionConsoleRecord[];
  public filteredRecords: ExceptionConsoleRecord[];
  public isAllSelected: boolean;
  public container: ExceptionConsoleContainer;
  public state: ExceptionConsoleState;
  public gridState: KendoGridStateHelper<ExceptionConsoleRecord>;
  public total: any = {};

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;
  @ViewChild('form', {static: true})
  private form: NgForm;
  private managementService: ExceptionConsoleManagementService;

  private aggregates: any = [
    { field: 'totalExceptionsCount', aggregate: 'sum' },
    { field: 'pendingMissingPunchesCount', aggregate: 'sum' },
    { field: 'approvedMissingPunchesCount', aggregate: 'sum' }
  ];


  @unsubscribe()
  private loadStateSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private recordsSelectedSubscription: Subscription;

  private changeDetector: ChangeDetectorRef;
  private gridHeight: number;
  private elementRef: ElementRef;

  @ViewChild(ScrollElementSelectorDirective, {static: true})
  private scrollElement: ScrollElementSelectorDirective;

  private navService: ExceptionConsoleNavigationService;

  constructor(managementService: ExceptionConsoleManagementService, changeDetector: ChangeDetectorRef, elementRef: ElementRef, router: Router, route: ActivatedRoute) {
    this.managementService = managementService;
    this.changeDetector = changeDetector;
    this.gridState = new KendoGridStateHelper<ExceptionConsoleRecord>();
    this.elementRef = elementRef;
    this.navService = new ExceptionConsoleNavigationService(router, route);
  }


  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(
      (container: ExceptionConsoleContainer) => {
        this.container = container;
        this.records = container.records;
        this.isAllSelected = false;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.loadStateSubscription = this.managementService.onStateChanged$
      .subscribe((state: ExceptionConsoleState) => {
        this.state = state;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onToggleAllSelected(): void {
    _.forEach(this.records, (record: ExceptionConsoleRecord) => {
      record.isSelected = this.isAllSelected && record.isSelectable && !record.isLocked;
    });
    this.selectionChange();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public selectionChange(): void {
    let selectedRecords: ExceptionConsoleRecord[] = [];
    selectedRecords = _.filter(this.records, (record: ExceptionConsoleRecord) => {
      return record.isSelected === true;
    });
    this.isAllSelected = this.records && selectedRecords ? this.records.length === selectedRecords.length : false;
    this.managementService.onRecordsSelected(selectedRecords);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public onPaycycleClicked(item: ExceptionConsoleRecord): void {
    // rollup grid component always used only in date ranges mode
    let orgLevelId: number = this.managementService.currentOrgLevel.id;
    this.navService.navigateToExceptionConsoleDates(orgLevelId, item.startDate, item.endDate, true);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    if (this.state.exceptionOnly) {
      this.filteredRecords = _.filter(this.records, (record: ExceptionConsoleRecord) => {
        let isException: boolean = record.totalExceptionsCount > 0;
        if (isException) {
          return true;
        }
        _.forEach(this.state.columns, (col: IColumnSettings) => {
          if (col.payload) {
            let payload: IExceptionConsoleColumnPayload = col.payload;
            if (payload.countAsException) {
              let rec: any = record;
              let count: number = rec[col.name];
              if (count > 0) {
                isException = true;
                return;
              }
            }
          }
        });
        return isException;
      });
    } else {
      this.filteredRecords = this.records;
    }

    this.gridState.view = process(this.filteredRecords, this.gridState.state);
    this.total = aggregateBy(this.gridState.view.data, this.aggregates);

    if (this.state.dynamicColumns && this.state.dynamicColumns.length > 0) {
      _.each(this.state.dynamicColumns, (c: IColumnSettings) => {
        this.total[c.name] = 0;
        _.each(this.gridState.view.data, (r: ExceptionConsoleRecord) => {
          this.total[c.name] += r.exceptionsMap[c.name].exceptionCount;
        });
      });
    }

  }

}


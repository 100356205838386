import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, HostListener, ViewChildren, QueryList, ElementRef, ChangeDetectionStrategy } from '@angular/core';

import { Observable ,  Subscription } from 'rxjs';
import * as _ from 'lodash';
import { GroupResult, orderBy, groupBy, process, State, filterBy } from '@progress/kendo-data-query';
import {
  GridComponent
} from '@progress/kendo-angular-grid';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { schedulerConfig } from '../../../scheduler.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { IndSchOrgLevelEmployee } from '../../../models/index';
import { IndividualScheduleManagementService, restoreEmpArg } from '../../../services/individual-schedule/individual-schedule-management.service';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { unsubscribe, mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'slx-individual-schedule-employee-grid',
  templateUrl: 'individual-schedule-employee-grid.component.html',
  styleUrls: ['individual-schedule-employee-grid.component.scss']
})
export class IndividualScheduleEmployeeGridComponent implements OnInit, OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @ViewChildren('employeeLink')
  private employeeLinks: QueryList<ElementRef>;

  @Input('orgLevelEmployees')
  public set records(orgLevelEmployees: IndSchOrgLevelEmployee[]) {
    this.orgLevelEmployees = orgLevelEmployees;
    this.refreshGrid();
  }
  @Input()
  public set filter(filter: string) {
    if (this.filterString === filter) {
      return;
    }
    this.filterString = filter;
    this.refreshGrid();
  }

  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<IndSchOrgLevelEmployee>;

  private individualScheduleManagementService: IndividualScheduleManagementService;
  private orgLevelEmployees: IndSchOrgLevelEmployee[];
  private filterString: string;
  private modalService: ModalService;
  private selectedEmployee: IndSchOrgLevelEmployee;
  private selectedEmployeeId: number;

  private selectedRouteEmpId: number;

  @unsubscribe()
  private gridEventSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private employeeSubscription: Subscription;
  @unsubscribe()
  private restoreEmpSelectionSubscription: Subscription;
  @unsubscribe()
  private onSearchEmpSelected: Subscription;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  constructor(private router: ActivatedRoute, individualScheduleManagementService: IndividualScheduleManagementService, modalService: ModalService) {
    this.individualScheduleManagementService = individualScheduleManagementService;

    this.modalService = modalService;
    this.gridState = new KendoGridStateHelper<IndSchOrgLevelEmployee>();
    this.gridState.state.sort = [{ field: 'employee.name', dir: 'asc' }];
    this.gridState.itemKey = 'id';

    this.gridEventSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((selected: IndSchOrgLevelEmployee[]): void => {
        this.selectionChange(_.first(selected));
    });

    this.employeeSubscription = this.individualScheduleManagementService.onSelectionChanged$.subscribe((emp: IndSchOrgLevelEmployee) => {
      this.selectedEmployee = emp;
      //if (!this.selectedEmployee) {
      //  this.grid.selectable = false;
      //  setTimeout(() => { this.grid.selectable = true; }, 10);
      // }
    });

    this.restoreEmpSelectionSubscription = this.individualScheduleManagementService.onRestoreEmpSelection$.subscribe((args: restoreEmpArg) => {
      this.selectedEmployeeId = args.employeeId;
    });

    this.onSearchEmpSelected = this.individualScheduleManagementService.subscribeToOnSearchEmpSelected((id: number) => {
      let emp: IndSchOrgLevelEmployee = _.find(this.gridState.view.data, x => x.employee.id === id);
      if (_.isObject(emp)) {
        this.selectionChange(emp);
        this.gridState.selectRowByKey(emp.id, true);
        this.scrollToEmp(emp.id);
      }
    });


    this.subscriptions.orgLevel = this.orgLevel$.subscribe((o: OrgLevel) => {
      this.selectedEmployee = null;
    });
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.router.queryParams.subscribe(params => {
      if (params.empID) {
        this.selectedRouteEmpId = JSON.parse(params.empID);
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  protected selectionChange(selection: IndSchOrgLevelEmployee): void {
    if (this.individualScheduleManagementService.isDirty) {
      this.modalService.globalAnchor.openConfirmDialog('Confirmation', 'You have unsaved changes. Are you sure to change employee?', (result: boolean) => {
        if (result) {
          this.individualScheduleManagementService.onEmployeeSelected(selection);
        } else {
          if (this.selectedEmployee) {
            this.gridState.selectRowByKey(this.selectedEmployee.id, true);
            this.scrollToEmp(this.selectedEmployee.id);
          }
        }
      });
    } else if (selection) {
      this.individualScheduleManagementService.onEmployeeSelected(selection);
    }
  }

  private restoreEmployee(): void {
    if (_.size(this.gridState.view.data) <= 0) {
      this.individualScheduleManagementService.onEmployeeSelected(null);
    }
    if (!this.selectedEmployee) {
      this.gridState.selectFirstRow();
    } else {
      let emp: IndSchOrgLevelEmployee = _.find(this.gridState.view.data, x => x.employee.id === this.selectedEmployee.id);
      if (_.isObject(emp)) {
        this.gridState.selectRowByKey(emp.id);
        this.scrollToEmp(emp.id);
      } else {
        this.gridState.selectFirstRow();
      }
    }
  }

  private scrollToEmp(empId): void {
    const link = _.find(this.employeeLinks.toArray(), (e) => e.nativeElement.id === `Employee_Id_${empId}`);
    if (_.isObjectLike(link)) {
      link.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private refreshGrid(): void {
    if (!this.orgLevelEmployees) {
      this.gridState.view = null;
      return;
    }
    let filteredRecords: IndSchOrgLevelEmployee[];
    if (!this.filterString || this.filterString.length === 0) {
      filteredRecords = this.orgLevelEmployees;
    } else {
      filteredRecords = _.filter(this.orgLevelEmployees, (record: IndSchOrgLevelEmployee) => {
        return _.includes(record.employee.name.toLowerCase(), this.filterString.toLowerCase());
      });
    }
    let sortedRecords: IndSchOrgLevelEmployee[] = orderBy(filteredRecords, this.gridState.state.sort);

    let filtered: IndSchOrgLevelEmployee[] = filterBy(sortedRecords, this.gridState.state.filter);
    let groupedRecords: IndSchOrgLevelEmployee[] | GroupResult[] = groupBy(filtered, this.gridState.state.group);

    this.gridState.view = {
      data: groupedRecords,
      total: groupedRecords.length
    };
    if (this.selectedRouteEmpId) {
      this.selectedEmployee = this.gridState.view.data.find(employee => employee.employee.id === this.selectedRouteEmpId);
    }
    setTimeout(() => { this.restoreEmployee(); }, 10);
  }
}

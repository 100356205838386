import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { MasterScheduleComponent } from './components/index';

export const routes: Routes = [
  {
    path: 'master_schedule',
    component: MasterScheduleComponent
  }
];

/* export const routesModule: ModuleWithProviders = RouterModule.forChild(routes);
 */
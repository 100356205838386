
import {combineLatest} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription ,  Subject ,  Observable } from 'rxjs';


import { appConfig } from '../../../../app.config';
import { IControlState, IColumnSettings, StateResetTypes } from '../../../../core/models/index';
import { ExceptionConsoleContainer, ExceptionConsoleRecord, ExceptionConsoleState } from '../../../models/index';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';
import { StateManagementService, ComponentStateStorageService, ExceptionConsoleNavigationService } from '../../../../common/services/index';
import { RangeDates, IRangeDates } from '../../../../common/models/range-dates';
import { PayCycle } from '../../../../organization/models/index';
import { ExceptionConsoleManagementService } from '../../../services/exception-console/exception-console-management.service';
import { PayCycleHelperService } from '../../../../organization/services/pay-cycle-helper/pay-cycle-helper.service';
import { ExceptionConsoleRouteData } from '../../../models/exception-console/exception-console-route-data';

@Component({
  moduleId: module.id,
  selector: 'slx-exception-console',
  templateUrl: 'exception-console.component.html',
  styleUrls: ['exception-console.component.scss'],
  providers: [ExceptionConsoleManagementService, StateManagementService]
})
export class ExceptionConsoleComponent implements OnInit, OnDestroy {

  public selectedRecords: ExceptionConsoleRecord[];
  public globalState: ExceptionConsoleState;

  public set usePayCycle(value) {
    this.managementService.exactPayCycle = value;
  }

  public get usePayCycle() {
    return this.managementService.exactPayCycle;
  }

  public currentRange: RangeDates;
  public selectedPayCycle: PayCycle;
  public setDefaultCycle: boolean;
  public isLoading: boolean;

  @destroyService()
  private managementService: ExceptionConsoleManagementService;

  @unsubscribe()
  private loadStateSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private recordsSelectedSubscription: Subscription;
  @unsubscribe()
  private routeSubscription: Subscription;

  private container: ExceptionConsoleContainer;
  private resetBy: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;

  private readonly componentId: string = 'ExceptionConsoleComponent';
  private readonly payCycleControlName: string = 'payCycle';
  private readonly dateRangeControlName: string = 'dateRange';


  constructor(
    private stateService: StateManagementService,
    private storageService: ComponentStateStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private payCycleHelper: PayCycleHelperService,
    managementService: ExceptionConsoleManagementService
  ) {
    this.isLoading = true;
    this.managementService = managementService;
  }

  public ngOnInit(): void {

    this.usePayCycle = false;
    this.setDefaultCycle = false;

    this.routeSubscription = this.activatedRoute.data.pipe(
      combineLatest(this.stateService.onInit$))
      .subscribe(([data, init]: [any, any]) => {

        let routeData: ExceptionConsoleRouteData = data.exceptionConsoleData;

        this.usePayCycle = routeData.usePayCycle;
        let payCycle: PayCycle = routeData.payCycle;
        let range: RangeDates = routeData.range;

        if (this.usePayCycle) {
          if (payCycle) {
            this.setDefaultCycle = false;
            this.selectedPayCycle = payCycle;
            range = new RangeDates();
            range.startDate = payCycle.startDate;
            range.endDate = payCycle.endDate;
          } else {
            this.setDefaultCycle = true;
          }
        }
        this.currentRange = range;
        if (this.currentRange) this.managementService.setDateRange(this.currentRange);

      });

    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.isLoading = value;
      });

    this.loadStateSubscription = this.managementService.onStateChanged$
      .subscribe((state: ExceptionConsoleState) => {
        this.globalState = state;
      });

    this.loadedSubscription = this.managementService.onLoaded$
      .subscribe((container: ExceptionConsoleContainer) => {
        this.container = container;
      });

    this.recordsSelectedSubscription = this.managementService.onRecordsSelected$
      .subscribe((records: ExceptionConsoleRecord[]) => {
        this.selectedRecords = records;
      });

    this.stateService.init(this.componentId);
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onApproveTimecards(): void {
    this.managementService.approveTimecards(this.selectedRecords);
  }

  public selectAll(event: MouseEvent): void {
    _.forEach(this.globalState.allColumns, (column: IColumnSettings) => {
      if (!column.readonly) {
        column.displayed = true;
      }
    });
    this.managementService.onStateChanged(this.globalState);
  }
  public clearAll(event: MouseEvent): void {
    _.forEach(this.globalState.allColumns, (column: IColumnSettings) => {
      if (!column.readonly) {
        column.displayed = false;
      }
    });
    this.managementService.onStateChanged(this.globalState);
  }

  public onRefreshByCheck(): void {
    this.managementService.onStateChanged(this.globalState);
  }

  public onChangeColumn(event: MouseEvent, column: IColumnSettings): any {
    this.managementService.onStateChanged(this.globalState);
  }

  public onSwitchRangeMode(mode: boolean): void {

    this.usePayCycle = mode;
    const navigation: ExceptionConsoleNavigationService = new ExceptionConsoleNavigationService(this.router, this.activatedRoute);

    if (!this.currentRange) {
      navigation.navigateToExceptionConsole(this.managementService.currentOrgLevel.id, this.usePayCycle);
    } else {
      navigation.navigateToExceptionConsoleDates(this.managementService.currentOrgLevel.id, this.currentRange.startDate, this.currentRange.endDate, this.usePayCycle);
    }

  }

  public onPayCycleSelected(payCycle: PayCycle): void {
    let range: RangeDates = new RangeDates();
    range.startDate = payCycle.startDate;
    range.endDate = payCycle.endDate;
    this.currentRange = range;
    this.managementService.navigateToDateRange(range, true);
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    let range: RangeDates = new RangeDates();
    range.startDate = startDate;
    range.endDate = endDate;
    this.currentRange = range;
    this.managementService.navigateToDateRange(range, false);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { components } from './components/index';
import { exportDataServices } from './services';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { OrganizationModule } from '../../organization/index';
import { MomentModule } from 'angular2-moment';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { CommonModule as CommonAppModule } from '../../common/index';
import { ComponentsLibraryModule } from '../../components-library/index';
import { NgxPopperModule } from 'ngx-popper';
import { AcaCorrectionsDialogComponent } from './components/aca-export/aca-corrections-dialog/aca-corrections-dialog.component';
import { AcaExportCorrected1095CComponent } from './components/aca-export/aca-export-corrected-1095c/aca-export-corrected-1095c.component';
import { AcaCorrectedSelectEmployeeComponent } from './components/aca-export/aca-corrected-select-employee/aca-corrected-select-employee.component';
import { AcaExportSupplementalComponent } from './components/aca-export/aca-export-supplemental-1095c/aca-export-supplemental-1095c.component';
import { AcaExportCorrected1094cParametersComponent } from './components/aca-export/aca-export-corrected-1094c-parameters/aca-export-corrected-1094c-parameters.component';
import { AcaExportCorrected1094cCountsComponent } from './components/aca-export/aca-export-corrected-1094c-counts/aca-export-corrected-1094c-counts.component';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        HttpClientModule,
        CommonModule,
        CommonAppModule,
        OrganizationModule,
        MomentModule,
        GridModule,
        LayoutModule,
        NgxPopperModule,
        ComponentsLibraryModule,
        TextMaskModule
    ],
    declarations: [
        ...components,
    ],
    providers: [
        ...exportDataServices
    ],
    exports: [
        ...components
    ]
})

export class ExportDataModule { }

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';

import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { LocationUnit, Shift } from '../../../../../organization/index';
import { ScheduleConsoleFiltersService } from '../../../services/index';
import { OperationalConsoleStateService } from '../../../../services/operational-console/operational-console-state.service';
import { Lookup, LookupType } from '../../../../../organization/index';
import { LookupService } from '../../../../../organization/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-overview-toolbar',
  templateUrl: 'schedule-console-overview-toolbar.component.html',
  styleUrls: ['schedule-console-overview-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleConsoleOverviewToolbarComponent {
  @Input()
  public showDetailedControl: boolean = false;

  @Output()
  public onShowDetailedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showDetailed: boolean;
  public isUnitDropdownAvailable: boolean;
  public isShiftDropdownAvailable: boolean;

  @mutableSelect()
  public orgLevel$: Observable<OrgLevel>;

  public unitFilter: LocationUnit[];
  public shiftFilter: Shift[];

  public locationUnitLookup: Lookup;
  public shiftLookup: Lookup;

  public get currentOrgLevel(): OrgLevel {
    return this.m_currentOrgLevel;
  }
  private m_currentOrgLevel: OrgLevel;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  constructor(
    private filterService: ScheduleConsoleFiltersService,
    private stateService: OperationalConsoleStateService,
    private lookupService: LookupService
  ) {

  }

  public ngOnInit(): void {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.m_currentOrgLevel = orgLevel;
      this.updateLookups();
    });
    this.updateLookups();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public updateLookups(): void {
    if(!this.currentOrgLevel) {
      return;
    }

    this.lookupService.getLookup({ lookupType: LookupType.locationUnit, orgLevelId: this.currentOrgLevel.id })
      .then((locationUnitLookup: Lookup) => {
        this.locationUnitLookup = locationUnitLookup;
        this.isUnitDropdownAvailable = locationUnitLookup && locationUnitLookup.items.length > 0;
      });
    this.lookupService.getLookup({ lookupType: LookupType.shift, orgLevelId: this.currentOrgLevel.id })
      .then((shiftLookup: Lookup) => {
        this.shiftLookup = shiftLookup;
        this.isShiftDropdownAvailable = shiftLookup && shiftLookup.items.length > 0;
      });
  }

  public onUnitChanged(): void {
    this.stateService.overviewFiltersChanged(this.unitFilter, this.shiftFilter);
    this.filterService.applyFilters();
  }

  public onShiftChanged(): void {
    this.stateService.overviewFiltersChanged(this.unitFilter, this.shiftFilter);
    this.filterService.applyFilters();
  }

  public onShowDetailedChanged(value: boolean):void {
    this.onShowDetailedChange.emit(value);
  }
}

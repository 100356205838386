import { Provider } from '@angular/core';

export * from './employee-activities/employee-terminate-map.service';
export * from './employee-activities/employee-terminate-api.service';
export * from './employee-activities/employee-transfer-map.service';
export * from './employee-activities/employee-transfer-api.service';
export * from './employee-activities/employee-add-api.service';
export * from './employee-activities/employee-add-map.service';
export * from './employee-call-log/employee-call-log-api.service';
export * from './employee-call-log/employee-call-log-management.service';
export * from './employee-messages/employee-messages-api.service';
export * from './timeclock-communication/timeclock-communication-api.service';
export * from './timeclock-communication/timeclock-communication-map.service';
export * from './employee-messages/employee-messages-map.service';
export * from './employee-activities/employee-rehire-map.service';
export * from './employee-activities/employee-rehire-api.service';
export * from './employee-activities/employee-selfservice-api.service';
export * from './employee-search-base/employee-search-base';
export * from './lookup-employee-provider/lookup-employee-provider';
export * from '../../../time-and-attendance/services/daily-timecard/daily-timecard-api.service';

import { EmployeeTerminateApiService } from './employee-activities/employee-terminate-api.service';
import { EmployeeTerminateMapService } from './employee-activities/employee-terminate-map.service';
import { EmployeeTransferApiService } from './employee-activities/employee-transfer-api.service';
import { EmployeeTransferMapService } from './employee-activities/employee-transfer-map.service';
import { EmployeeActivitiesApiService } from './employee-activities/employee-add-api.service';
import { EmployeeActivitiesMapService } from './employee-activities/employee-add-map.service';
import { EmployeeRehireApiService } from './employee-activities/employee-rehire-api.service';
import { EmployeeRehireMapService } from './employee-activities/employee-rehire-map.service';
import { EmployeeSelfServiceApiService } from './employee-activities/employee-selfservice-api.service';

import { EmployeeCallLogApiService } from './employee-call-log/employee-call-log-api.service';
import { EmployeeCallLogMapService } from './employee-call-log/employee-call-log-map.service';
import { EmployeeCallLogManagementService } from './employee-call-log/employee-call-log-management.service';

import { EmployeeMessagesApiService } from './employee-messages/employee-messages-api.service';
import { EmployeeMessagesMapService } from './employee-messages/employee-messages-map.service';
import { TimeclockCommunicationApiService } from './timeclock-communication/timeclock-communication-api.service';
import { TimeclockCommunicationMapService } from './timeclock-communication/timeclock-communication-map.service';
import { EmployeeSearchBase } from './employee-search-base/employee-search-base';
import { LookupEmployeeProvider } from './lookup-employee-provider/lookup-employee-provider';
import { DailyTimecardApiService } from './employee-ta-log/employee-ta-log-api.service';

export const services: Provider[] = [
  EmployeeTerminateApiService,
  EmployeeTerminateMapService,
  EmployeeTransferApiService,
  EmployeeTransferMapService,
  EmployeeActivitiesApiService,
  EmployeeActivitiesMapService,
  EmployeeCallLogApiService,
  EmployeeCallLogMapService,
  EmployeeCallLogManagementService,
  EmployeeMessagesApiService,
  EmployeeMessagesMapService,
  TimeclockCommunicationApiService,
  TimeclockCommunicationMapService,
  EmployeeRehireApiService,
  EmployeeRehireMapService,
  EmployeeSelfServiceApiService,
  { provide: EmployeeSearchBase, useClass: LookupEmployeeProvider },
  DailyTimecardApiService
];


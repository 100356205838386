import {
  Component, ChangeDetectionStrategy, ChangeDetectorRef, Input,
  OnChanges, SimpleChanges, OnInit, Inject, ViewChild, ViewChildren, ElementRef,
  QueryList, Renderer2
} from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

import { dateTimeUtils } from '../../../../common/utils/index';
import { ArrivalsDeparturesTimelineDetailsManagementService, ArrivalDeparturesManagementService, TaConsoleToolbarService } from '../../../services/index';
import { Subscription } from 'rxjs';


import * as moment from 'moment';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import {
  ArrivalsDeparturesDetails,
  ArrivalsDeparturesDetailsRecord, ArrivalsDeparturesTimelineItem,
  ArrivalsDeparturesTimelineItemType, LinePunch, ArrivalDeparturesTimelineFilter, ArrivalDeparturesContainer, Arrival, Departure,
  ArrivalsDeparturesTimelineViewModeDefinition
} from '../../../models/index';
import { ScheduledShiftDefinition } from '../../../../organization/models/index';
import { IDateRange } from '../../../../core/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimelineService, TimelineComponentConfigiration } from '../../../../common/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-arrivals-departures-timeline',
  templateUrl: 'arrivals-departures-timeline.component.html',
  styleUrls: ['arrivals-departures-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('contentState', [
      state('active', style({
        position: 'relative', 'z-index': 2, opacity: 1,
      })),
      transition('right => active', [
        style({
          transform: 'translateX(100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 0, transform: 'translateX(100%)', offset: 0 }),
          style({ opacity: 1, transform: 'translateX(0%)', offset: 1.0 })
        ]))
      ]),
      transition('active => right', [
        style({
          transform: 'translateX(-100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 1, transform: 'translateX(0%)', offset: 0 }),
          style({ opacity: 0, transform: 'translateX(100%)', offset: 1.0 })
        ]))
      ]),
      transition('active => left', [
        style({
          transform: 'translateX(-100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 1, transform: 'translateX(0%)', offset: 0 }),
          style({ opacity: 0, transform: 'translateX(-100%)', offset: 1.0 })
        ]))
      ]),
      transition('left => active', [
        style({
          transform: 'translateX(100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 0, transform: 'translateX(-100%)', offset: 0 }),
          style({ opacity: 1, transform: 'translateX(0%)', offset: 1.0 })
        ]))
      ]),
    ])
  ]
})
export class ArrivalsDeparturesTimelineComponent implements OnInit {

  public workDate: Date;
  public details: ArrivalsDeparturesDetails;
  public container: ArrivalDeparturesContainer;
  public items: ArrivalsDeparturesTimelineItem[];
  public scheduleStart = ArrivalsDeparturesTimelineItemType.scheduleStart;
  public scheduleEnd = ArrivalsDeparturesTimelineItemType.scheduleEnd;
  public punchIn = ArrivalsDeparturesTimelineItemType.punchIn;
  public punchOut = ArrivalsDeparturesTimelineItemType.punchOut;
  public late = ArrivalsDeparturesTimelineItemType.late;
  public ot = ArrivalsDeparturesTimelineItemType.ot;
  public appConfig: IApplicationConfig;
  public config: TimelineComponentConfigiration;
  public viewMode: ArrivalsDeparturesTimelineViewModeDefinition = 'GroupByShift';
  public isShowOverages: boolean;
  protected startDate: Date;
  protected endDate: Date;
  protected unfilteredItems: ArrivalsDeparturesTimelineItem[];

  public get eventsWrapperWidth(): number {
    return this.timelineService.eventsWrapperWidth;
  }

  public get loaded(): boolean {
    return this.timelineService.loaded;
  }

  public get prevLinkInactive(): boolean {
    return this.timelineService.prevLinkInactive;
  }

  public get nextLinkInactive(): boolean {
    return this.timelineService.nextLinkInactive;
  }

  @ViewChild('eventsWrapper', {static: true}) eventsWrapper: ElementRef;
  @ViewChild('fillingLineStart', {static: true}) fillingLineStart: ElementRef;
  @ViewChild('fillingLineEnd', {static: true}) fillingLineEnd: ElementRef;
  @ViewChildren('timelineEvents') timelineEvents: QueryList<ElementRef>;


  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription2: Subscription;
  @unsubscribe()
  private changeSubscription: Subscription;
  @unsubscribe()
  private onToogleTimelineViewSubscription: Subscription;
  @unsubscribe()
  private onStatusSubscription: Subscription;
  @unsubscribe()
  private toggleOverrageSubscription: Subscription;

  constructor(private changeDetector: ChangeDetectorRef, private managementService: ArrivalsDeparturesTimelineDetailsManagementService,
    private timelineService: TimelineService, private renderer: Renderer2) {
    this.changeSubscription = this.timelineService.changeDetection.subscribe(() => {
      this.changeDetection();
    });
    this.appConfig = appConfig;
  }

  public changeDetection(): void {
    this.changeDetector.detectChanges();
  }
  public ngOnInit(): void {
    this.onStatusSubscription = this.managementService.onLoadStatus$.subscribe((isLock: boolean) => {
      if (isLock) {
        this.unfilteredItems = null;
        this.details = null;
        this.container  = null;
      }
    });

    this.toggleOverrageSubscription = this.managementService.onToogleShowOverragesView$.subscribe((value: boolean) => {
      this.isShowOverages = value;
      this.changeDetection();
    });

    this.onToogleTimelineViewSubscription = this.managementService.onToogleTimelineView$.subscribe((mode: ArrivalsDeparturesTimelineViewModeDefinition) => {
      if (!this.config) {
        return;
      }
      if (this.viewMode !== mode) {
        this.viewMode = mode;
        if (!this.items || !this.unfilteredItems) {
          return;
        }
        this.setViewMode();
      }
    });

    this.loadedSubscription = this.managementService.onLoaded$
    .subscribe((details: ArrivalsDeparturesDetails) => {
      this.details = details;
      this.process();
    });

    this.loadedSubscription2 = this.managementService.onArrivalDeparturesLoaded$
    .subscribe((container: ArrivalDeparturesContainer) => {
      this.container = container;
      this.process();
    });
  }

  private process(): void {
    if(!this.details || !this.container) {
      return;
    }
    this.workDate = this.managementService.workDate;
    if (!this.unfilteredItems) {
      this.unfilteredItems = this.createItems(this.details.records);
      if (this.unfilteredItems.length > 0) {
        this.startDate = _.first(this.unfilteredItems).date;
        this.endDate = _.last(this.unfilteredItems).date;
      }
    }
    this.setViewMode();
  }
  public setViewMode(): void {
    if (this.viewMode === 'Simple') {
      this.items = this.createItems(this.details.filteredRecords);
      if (this.container) {
        this.mergeArrivalsDetails(this.container);
      }
      this.timelineService.initItems(this.items, { startDate: this.startDate, endDate: this.endDate, fitToWidth: false });
    }
    if (this.viewMode === 'GroupByShift') {
      this.items = this.createShiftGroupingItems(this.details.filteredRecords);
      if (this.container) {
        this.mergeArrivalsDetails(this.container);
      }
      this.timelineService.initItems(this.items, { startDate: this.startDate, endDate: this.endDate, fitToWidth: false, eventsMinDistance: 40 });
    }
    if (this.viewMode === 'FitToWidth') {
      this.items = this.createItems(this.details.filteredRecords);
      if (this.container) {
        this.mergeArrivalsDetails(this.container);
      }
      this.timelineService.initItems(this.items, { startDate: this.startDate, endDate: this.endDate, fitToWidth: true });
    }

    if (this.managementService.filter.arrivalsRange && this.managementService.filter.arrivalsRange.endDate) {
      this.timelineService.updateFillingStartByDate(this.managementService.filter.arrivalsRange.endDate);
    }
    if (this.managementService.filter.departuresRange && this.managementService.filter.departuresRange.startDate) {
      this.timelineService.updateFillingEndByDate(this.managementService.filter.departuresRange.startDate);
    }
  }
  public createItems(records: ArrivalsDeparturesDetailsRecord[]): ArrivalsDeparturesTimelineItem[] {
    const items: ArrivalsDeparturesTimelineItem[] = [];
    const shiftStarts: NumberMap<ArrivalsDeparturesTimelineItem> = {};
    const shiftEnds: NumberMap<ArrivalsDeparturesTimelineItem> = {};

    const punchInMap: NumberMap<ArrivalsDeparturesTimelineItem> = {};
    const punchOutMap: NumberMap<ArrivalsDeparturesTimelineItem> = {};

    _.forEach(records, (record: ArrivalsDeparturesDetailsRecord) => {
      if (record.entry) {
        _.forEach(record.entry.shifts, (s: ScheduledShiftDefinition) => {
          if (!dateTimeUtils.isInValidPeriod(s.start) || !dateTimeUtils.isInValidPeriod(s.end)) {
            return;
          }
          const startIndex = moment(s.start).unix();
          if (!shiftStarts[startIndex]) {
            const shiftStart = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.scheduleStart);
            shiftStart.records = [];
            shiftStart.date = s.start;
            shiftStart.minDistanceRelated = true;
            items.push(shiftStart);
            shiftStarts[startIndex] = shiftStart;
          }
          shiftStarts[startIndex].records.push(record);

          const endIndex = moment(s.end).unix();
          if (!shiftEnds[endIndex]) {
            const shiftEnd = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.scheduleEnd);
            shiftEnd.records = [];
            shiftEnd.date = s.end;
            shiftEnd.minDistanceRelated = true;
            items.push(shiftEnd);
            shiftEnds[endIndex] = shiftEnd;
          }
          shiftEnds[endIndex].records.push(record);
        });
      }

      _.forEach(record.punches, (p: LinePunch) => {
        if (p.type.isBreak || p.type.isLunch) {
          return;
        }

        if (p.type.isIn) {
          const punchInMapIndex = moment(p.time).unix();
          if (!punchInMap[punchInMapIndex]) {
            const punchIn = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchIn);
            punchIn.records = [];
            punchIn.date = p.time;
            punchIn.minDistanceRelated = true;
            items.push(punchIn);
            punchInMap[punchInMapIndex] = punchIn;
          }
          punchInMap[punchInMapIndex].records.push(record);
        }
        if (p.type.isOut) {
          const punchOutMapIndex = moment(p.time).unix();
          if (!punchOutMap[punchOutMapIndex]) {
            const punchOut = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchOut);
            punchOut.records = [];
            punchOut.date = p.time;
            punchOut.minDistanceRelated = true;
            items.push(punchOut);
            punchOutMap[punchOutMapIndex] = punchOut;
          }
          punchOutMap[punchOutMapIndex].records.push(record);
        }
      });
    });
    return _.orderBy(items, (item: ArrivalsDeparturesTimelineItem) => item.date);
  }

  public mergeArrivalsDetails(container: ArrivalDeparturesContainer): void {
    _.forEach(container.arrivals, (record: Arrival) => {
      if (record.late > 0 && record.arrivalTime) {
        const late = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.late);
        late.date = moment(record.arrivalTime).add('m', 5).toDate();
        late.arrival = record;
        const index = _.sortedIndexBy(this.items, late, (item: ArrivalsDeparturesTimelineItem) => item.date);
        this.items.splice(index, 0, late);
        this.suppresNeighborsTitles(late, index);

      }
    });
    _.forEach(container.departures, (record: Departure) => {
      if (record.overtime > 0 && record.departureTime) {
        const ot = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.ot);
        ot.date = moment(record.departureTime).add('m', 5).toDate();
        ot.departure = record;
        const index = _.sortedIndexBy(this.items, ot, (item: ArrivalsDeparturesTimelineItem) => item.date);
        this.items.splice(index, 0, ot);
        this.suppresNeighborsTitles(ot, index);
      }
    });
  }

  public suppresNeighborsTitles(target: ArrivalsDeparturesTimelineItem, index: number): void {
    const neighbors = _.slice(this.items, index - 1, index + 2);
    const neighborsToSuppress = _.filter(neighbors, (item: ArrivalsDeparturesTimelineItem) => item !== target && Math.abs(item.date.getTime() - target.date.getTime()) < 1200000);
    _.forEach(neighborsToSuppress, (item: ArrivalsDeparturesTimelineItem) => {
      if(target.type===ArrivalsDeparturesTimelineItemType.late) {
        item.lateBageNeighbor = true;
      }
      if(target.type===ArrivalsDeparturesTimelineItemType.ot) {
        item.otBageNeighbor = true;
      }
    });
  }

  public createShiftGroupingItems(records: ArrivalsDeparturesDetailsRecord[]): ArrivalsDeparturesTimelineItem[] {
    const items: ArrivalsDeparturesTimelineItem[] = [];
    const shifts: NumberMap<ArrivalsDeparturesTimelineItem> = {};

    const punchInMap: NumberMap<ArrivalsDeparturesTimelineItem> = {};
    const punchOutMap: NumberMap<ArrivalsDeparturesTimelineItem> = {};
    const offsetInGroup = 600000;

    _.forEach(records, (record: ArrivalsDeparturesDetailsRecord) => {
      if (record.entry) {
        _.forEach(record.entry.shifts, (s: ScheduledShiftDefinition) => {
          if (!dateTimeUtils.isInValidPeriod(s.start) || !dateTimeUtils.isInValidPeriod(s.end)) {
            return;
          }
          const startIndex = Math.floor(moment(s.start).unix() / 600);
          if (!shifts[startIndex]) {
            const shiftStart = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.scheduleStart);
            shiftStart.records = [];
            shiftStart.date = s.start;
            shiftStart.minDistanceRelated = true;
            items.push(shiftStart);
            shifts[startIndex] = shiftStart;
          }
          shifts[startIndex].records.push(record);

          const endIndex = Math.floor(moment(s.end).unix() / 600);
          if (!shifts[endIndex]) {
            const shiftEnd = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.scheduleEnd);
            shiftEnd.records = [];
            shiftEnd.date = s.end;
            shiftEnd.minDistanceRelated = true;
            items.push(shiftEnd);
            shifts[endIndex] = shiftEnd;
          }
          shifts[endIndex].records.push(record);
          const inPunches = _.find(record.punches, (p: LinePunch) => !p.type.isBreak && !p.type.isLunch && p.type.isIn);
          const outPunches = _.find(record.punches, (p: LinePunch) => !p.type.isBreak && !p.type.isLunch && p.type.isOut);
          if (inPunches) {
            const punchInMapIndex = startIndex + offsetInGroup;
            if (!punchInMap[punchInMapIndex]) {
              const punchIn = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchIn);
              punchIn.records = [];
              punchIn.date = moment(s.start).add('ms', -offsetInGroup).toDate();
              //punchIn.minDistanceRelated = true;
              items.push(punchIn);
              punchInMap[punchInMapIndex] = punchIn;
            }
            punchInMap[punchInMapIndex].records.push(record);
          }
          if (outPunches) {
            const punchOutMapIndex = startIndex + offsetInGroup;
            if (!punchOutMap[punchOutMapIndex]) {
              const punchOut = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchOut);
              punchOut.records = [];
              punchOut.date = moment(s.end).add('ms', offsetInGroup).toDate();
              //punchOut.minDistanceRelated = true;
              items.push(punchOut);
              punchOutMap[punchOutMapIndex] = punchOut;
            }
            punchOutMap[punchOutMapIndex].records.push(record);
          }
        });
      } else {
        _.forEach(record.punches, (p: LinePunch) => {
          if (p.type.isBreak || p.type.isLunch) {
            return;
          }
          if (p.type.isIn) {
            const punchInMapIndex = moment(p.time).unix();
            if (!punchInMap[punchInMapIndex]) {
              const punchIn = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchIn);
              punchIn.records = [];
              punchIn.date = p.time;
              items.push(punchIn);
              punchInMap[punchInMapIndex] = punchIn;
            }
            punchInMap[punchInMapIndex].records.push(record);
          }
          if (p.type.isOut) {
            const punchOutMapIndex = moment(p.time).unix();
            if (!punchOutMap[punchOutMapIndex]) {
              const punchOut = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchOut);
              punchOut.records = [];
              punchOut.date = p.time;
              items.push(punchOut);
              punchOutMap[punchOutMapIndex] = punchOut;
            }
            punchOutMap[punchOutMapIndex].records.push(record);
          }

        });
      }
    });
    return _.orderBy(items, (item: ArrivalsDeparturesTimelineItem) => item.date);
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngAfterViewInit(): void {
    this.changeDetector.detach();
    this.config = {
      eventsWrapper: this.eventsWrapper,
      fillingLineStart: this.fillingLineStart,
      fillingLineEnd: this.fillingLineEnd,
      timelineEvents: this.timelineEvents,
      renderer: this.renderer,
      eventsMinDistance: 1
    };
    this.timelineService.configireAfterViewInit(this.config);
    this.changeDetection();
  }

  public onScrollClick(event: Event, forward: boolean): void {
    event.preventDefault();
    this.timelineService.onScrollClick(event, forward);
  }


  public onEventClick(event: Event, selectedItem: ArrivalsDeparturesTimelineItem): void {
    event.preventDefault();
    //this.timelineService.onEventClick(event, selectedItem);
  }
}

import * as _ from 'lodash';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

import { unsubscribe } from '../../../../core/decorators/index';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper } from '../../../../common/models/index';
import { ConfigurationComponentHelper } from '../../../utils/configuration-component-helper';
import { CostCentersConfigurationContainer, CostCenterModel } from '../../../models/index';
import { CostCentersConfigurationManagementService } from '../../../services/index';
import { IApplicationConfig, appConfig } from '../../../../app.config';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { Department, LocationUnit, Position } from '../../../../organization/models/index';
import { ICostGridState } from '../../../models/cost-centers/cost-grid-state.interface';
import { NgForm } from '@angular/forms';

@Component({
    moduleId: module.id,
    selector: 'slx-cost-centers-grid',
    templateUrl: 'cost-centers-grid.component.html',
    styleUrls: ['cost-centers-grid.component.scss']
})
export class CostCentersGridComponent implements OnInit {

    public get container(): CostCentersConfigurationContainer {
        return this.m_container;
    }

    public state: {
        isLoading: boolean;
        bulkEditMode: boolean;
        canBulkEdit: boolean;
        canEdit: boolean;
        canAdd: boolean;
        canDelete: boolean;
    };

    public appConfig: IApplicationConfig;

    public crudHelper: ConfigurationComponentHelper<CostCenterModel>;
    public gridState: KendoGridStateHelper<CostCenterModel>;
    public selectionHelper: KendoGridCustomSelectionHelper<CostCenterModel>;

    public currentOrgLevel: OrgLevel;
    public notDepartment: boolean;

    public departments: Department[];
    public positions: Position[];
    public units: LocationUnit[];

    private m_container: CostCentersConfigurationContainer;

    @ViewChild('kendoGrid', {static: true})
    private set grid(value: GridComponent) {
        if (this.crudHelper) this.crudHelper.grid = value;
        this.m_grid = value;
    }
    private get grid(): GridComponent {
        return this.m_grid;
    }
    private m_grid: GridComponent;

    @ViewChild('templateForm', { static: true })
    private mainForm: NgForm;

    @unsubscribe()
    private stateSubscription: Subscription;
    @unsubscribe()
    private removeSubscription: Subscription;
    @unsubscribe()
    private gridSelectSubscription: Subscription;
    @unsubscribe()
    private savedSubscription: Subscription;
    @unsubscribe()
    private removeStartSubscription: Subscription;
    @unsubscribe()
    private defaultsSubscription: Subscription;
    @unsubscribe()
    private mainFormSubscription: Subscription;
    @unsubscribe()
    private editSubscription: Subscription;

    constructor(private management: CostCentersConfigurationManagementService) {

        this.gridState = new KendoGridStateHelper<CostCenterModel>();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

        this.crudHelper = new ConfigurationComponentHelper<CostCenterModel>();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
    }

    public ngOnInit(): void {

        this.appConfig = appConfig;

        this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: true,
            canEdit: true,
            canAdd: true,
            canDelete: true
        };

        this.editSubscription = this.management.editItemCmd$.subscribe((item: CostCenterModel) => {
            if (item) {
                this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
                    if (this.mainForm.dirty) {
                        this.management.markAsDirty();
                    }
                });
            } else {
                if (this.mainFormSubscription) {
                    this.mainFormSubscription.unsubscribe();
                }
            }
        });


        this.stateSubscription = this.management.onStateChanged$.subscribe((state: ICostGridState) => {

            this.state = state;
            if (state.bulkEditMode) {
                if (this.grid) this.gridState.closeEditor(this.grid);
            }
            if (state.orgLevelChanged) {
                if (this.grid) this.gridState.closeEditor(this.grid);
                this.currentOrgLevel = this.management.currentOrgLevel;
                this.notDepartment = this.currentOrgLevel.type !== OrgLevelType.department;
            }
        });

        this.defaultsSubscription = this.management.defaultsInitialized$.subscribe(() => {
            this.departments = this.management.departments;
        });

        this.savedSubscription = this.management.onItemSaved$.subscribe((item: CostCenterModel) => {
            this.gridState.editedRecord = null;
            this.gridState.savedEditedRecord = null;
        });

        this.removeStartSubscription = this.management.removeItemsStartCmd$.subscribe(() => {
            if (this.selectionHelper.selectionLength === 0) {
                this.management.noSelectedItem();
            } else {
                this.crudHelper.deleteSelected();
            }
        });


        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: CostCenterModel[]): void => {
            this.crudHelper.selectionChange(_.first(records), 0);
        });

        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
    }

    public rowCallback(context: RowClassArgs): any {
        if (context.dataItem.isDirty) {
            return { 'dirty-item': true };
        }
        return {};
    }
}

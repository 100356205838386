
import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { ReplaySubject ,  Observable ,  Subscription ,  Subject } from 'rxjs';

import { ManagementBaseService, MetaMapService, SessionService } from '../../../../core/services/index';
import { ModalService, ChangeManagementService, EmployeeSectionNavigationService, LmNavigationService, FileService } from '../../../../common/services/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { WCReportApiService } from './wc-report-api.service';
import { WcEmployeeInfo, WcReport, WcInjuryReport, WcCodeDefinition, WcLookupsContainer, AttachmentDefinition, WcOSHAReport, WCCompensationReport, ReportCommonFields } from '../../models';
import { Assert } from '../../../../framework';
import { LookupApiService, LookupService, ApplicationStateBusService } from '../../../../organization/services';
import { LookupType, Employee, Lookup, UserProfileDefinition, ReadFile, State } from '../../../../organization/models';
import { WCReportMapService } from './wc-report-map.service';
import { IFieldData } from '../../../../core/models';
import { UrlStateManagementService } from '../../../../portal/services';
import { LoaRequestDropDownItem, LoaActionsDropDownItem } from '../../../../common/models';
import { Router, ActivatedRoute, NavigationEnd, Route, NavigationExtras } from '@angular/router';
import { WindowRef } from '../../../../core/services/window/window-ref.model';
import { NotificationsService } from '../../../../core/components';

@Injectable()
export class WCReportManagementService extends ManagementBaseService<any, any> {

    public orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
    public employeeInfoLoaded$ = new ReplaySubject<WcEmployeeInfo>(1);
    public wcLookupsLoaded$ = new ReplaySubject<WcLookupsContainer>(1);
    public onFileChanged$ = new ReplaySubject<WcReport>(1);
    private closePopup$ = new Subject<boolean>();
    private onError$ = new Subject<string>();
    private onValidityChanged$ = new Subject<boolean>();
    private incidentDate$ = new ReplaySubject<Date>(1);

    @mutableSelect(['orgLevel'])
    private orgLevel$: Observable<OrgLevel>;

    private orgLevel: OrgLevel;
    private employeeInfo: WcEmployeeInfo;
    private report: WcReport;

    private wcLookups: WcLookupsContainer;

    private changeGroupName: string = 'wc-incident-report-form';
    private changeManagementComponentId: string = 'wc-incident-report';

    private wcValid: boolean = true;
    private oshaValid: boolean = true;
    private injuryValid: boolean = true;
    private mainControlsValid: boolean = true;
    private ignoreChanges: boolean = false;

    public incidentDate: Date = null;

    constructor(
        private api: WCReportApiService,
        private mapService: WCReportMapService,
        private lookupService: LookupService,
        private modalService: ModalService,
        private changeService: ChangeManagementService,
        private sessionService: SessionService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationsService,
        private fileService: FileService
    ) {
        super();
        this.init();
    }

    public destroy(): void {
        this.incidentDate$.complete();
    }

    public init(): void {

        this.changeService.setCurrentComponentId(this.changeManagementComponentId);

        this.report = this.createWcReportModel();

        this.subscriptions.orgLevel = this.orgLevel$.pipe(
            filter((o: OrgLevel) => o && _.isFinite(o.id)))
            .subscribe(async (orgLevel: OrgLevel) => {
                if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
                this.orgLevel = orgLevel;
                this.orgLevelChanged$.next(orgLevel);
                this.loadLookups();
            });
    }

    public subscribeToOrgLevelChange(callback: (orgLevel: OrgLevel) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    }

    public subscribeToReportLoad(callback: (report: WcReport) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.onLoaded$.subscribe(callback);
    }

    public subscribeToFileChanged(callback: (report: WcReport) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.onFileChanged$.subscribe(callback);
    }

    public subscribeToClosePopup(callback: (flag: boolean) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.closePopup$.subscribe(callback);
    }

    public subscribeToError(callback: (message: string) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.onError$.subscribe(callback);
    }

    public subscribeToLookupsLoad(callback: (container: WcLookupsContainer) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.wcLookupsLoaded$.subscribe(callback);
    }

    public subscribeToSetIncidentDate(callback: (incidentDateTime: Date) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.incidentDate$.subscribe(callback);
    }

    public onValidityChanged(callback: (valid: boolean) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.onValidityChanged$.subscribe(callback);
    }

    public onFileChangeNotify(sendChangeAlso: boolean): void {
        this.onFileChanged$.next();
        if (sendChangeAlso) {
            this.onChangeNotify();
        }
    }

    public closePopup(afterActionSaved: boolean = true): void {
        this.ignoreChanges = true;
        this.changeService.clearChanges(this.changeGroupName);
        this.changeService.clearCurrentComponentId();
        this.closePopup$.next(afterActionSaved);
    }

    public changeLoading(isLoading: boolean) {
        this.onLoadStatusChanged(isLoading);
    }

    public setMainFormValidity(valid: boolean) {
        this.mainControlsValid = valid;
        this.updateValidity();
    }

    public setWcFormValidity(valid: boolean) {
        this.wcValid = valid;
        this.updateValidity();
    }

    public setInjuryFormValidity(valid: boolean) {
        this.injuryValid = valid;
        this.updateValidity();
    }

    public setOshaFormValidity(valid: boolean) {
        this.oshaValid = valid;
        this.updateValidity();
    }

    public onChangeNotify(): void {
        if (!this.ignoreChanges) {
            this.changeService.changeNotify(this.changeGroupName);
            this.changeNotify$.next(this.changeGroupName);
        }
    }

    public toggleEditMode(): void {
        if (this.report) {
            this.report.editMode = !this.report.editMode;
        }
    }

    public disableOshaReport(): void {
        if (this.report) {
            this.report.injuryReport.isOSHARecordable = false;
        }
        this.oshaValid = true;
        this.updateValidity();
        this.onChangeNotify();
    }

    public disableWCReport(): void {
        if (this.report) {
            this.report.injuryReport.isWorkersCompCase = false;
        }
        this.wcValid = true;
        this.updateValidity();
        this.onChangeNotify();
    }

    public enableOshaReport(): void {
        if (this.report) {
            if (!this.report.oshaReport) {
                let osha = new WcOSHAReport();
                this.report.oshaReport = osha;
            }
            this.report.injuryReport.isOSHARecordable = true;
        }
        this.onChangeNotify();
    }

    public enableWCReport(): void {
        if (this.report) {
            if (!this.report.compReport) {
                let wc = new WCCompensationReport();
                this.report.compReport = wc;
            }
            this.report.injuryReport.isWorkersCompCase = true;
        }
        this.onChangeNotify();
    }

    public setReportId(reportId: number): void {
        this.loadReport(reportId);
    }

    public createNewReport(empId: number): void {
        this.changeLoading(true);
        this.getEmployeeInfo(empId)
            .then((e: WcEmployeeInfo) => {
                this.employeeInfo = e;
                this.employeeInfoLoaded$.next(this.employeeInfo);
                const report = this.createWcReportModel();
                report.employee = this.employeeInfo;
                this.report = report;
                this.onLoaded$.next(this.report);
                this.changeLoading(false);
                this.changeService.clearChanges(this.changeGroupName);
            });
    }

    public async reloadReport(): Promise<void> {
        if (_.isFinite(this.report.id)) {
            this.loadReport(this.report.id);
        }
    }

    public async loadReport(reportId: number): Promise<void> {

        this.changeLoading(true);

        try {
            let report = await this.api.getReport(reportId);
            this.report = report;
            this.employeeInfo = report.employee;

            if (this.report.injuryReport.isWitnessEmployee && _.isFinite(this.report.injuryReport.witnessEmployee)) {
                let info: WcEmployeeInfo = await this.getEmployeeInfo(this.report.injuryReport.witnessEmployee);
                this.report.injuryReport.witnessEmployeeObj = this.getLookupEmployeeFromWcEmpInfo(info);
            }
            this.employeeInfoLoaded$.next(this.employeeInfo);
            this.onLoaded$.next(this.report);
            this.changeLoading(false);
            this.changeService.clearChanges();
        } catch (err) {
            let errorMessage = err && err.message || 'An error has occurred. Try again later';
            this.onError$.next(errorMessage);
            this.changeLoading(false);
            throw err;
        }
    }

    public async saveReport(): Promise<void> {
        this.changeLoading(true);
        let x = await this.api.createReport(this.report);
        x.employee = this.employeeInfo;
        let reportWithDocs = await this.attachSavedFiles(x.id, this.report.filesToSave);
        this.report = reportWithDocs || x;
        this.changeService.clearChanges();
        this.employeeInfoLoaded$.next(this.employeeInfo);
        this.onLoaded$.next(this.report);
        this.changeLoading(false);
        this.notificationService.success('Report created', 'Incident report was successfully created');
        this.closePopup();
    }

    public async updateReport(): Promise<void> {

        this.changeLoading(true);

        let x = await this.api.updateReport(this.report);
        x.employee = this.employeeInfo;
        let reportWithDocs = await this.attachSavedFiles(this.report.id, this.report.filesToSave);
        this.report = reportWithDocs || x;
        this.changeService.clearChanges();
        this.employeeInfoLoaded$.next(this.employeeInfo);
        this.onLoaded$.next(this.report);
        this.onLoadStatusChanged(false);
        this.notificationService.success('Report updated', 'Incident report was successfully updated');
        this.closePopup();
    }

    public async deleteReport() {
        try {
            this.changeLoading(true);
            await this.api.deleteReport(this.report.id);
            this.report = null;
            this.employeeInfo = null;
            this.changeService.clearChanges();
            this.changeLoading(false);
            this.closePopup();
            this.notificationService.success('Report deleted', 'Incident report was successfully deleted');
        } catch (err) {
            let errorMessage = err && err.message || 'An error has occurred. Try again later';
            this.onError$.next(errorMessage);
            this.changeLoading(false);
            this.notificationService.error('Error', 'Incident report was not deleted. Try again later');
            throw err;
        }
    }

    public onCancel(): void {
        this.changeService.canMoveForward(this.changeManagementComponentId).then(canMove => {
            if (!canMove) {
                return;
            }
            this.closePopup(false);
        });
    }

    public async getEmployeeInfo(id: number): Promise<WcEmployeeInfo> {
        const info = await this.api.getWcEmployeeInfo(id);
        return info;
    }

    public async setWitnessEmployee(empId: number): Promise<void> {

        this.changeLoading(true);
        this.report.injuryReport.witnessEmployee = empId;

        let info: WcEmployeeInfo = await this.getEmployeeInfo(empId);

        let fieldInfo: IFieldData = _.find(info.metaMap, (f: IFieldData) => f.fieldName === 'empPhone');
        if (fieldInfo.access === 'full') {
            this.report.injuryReport.witnessPhone = info.employee.phoneNumber;
        } else {
            fieldInfo = _.find(info.metaMap, (f: IFieldData) => f.fieldName === 'cellPhoneNo');
            if (fieldInfo.access === 'full') {
                this.report.injuryReport.witnessPhone = info.employee.mobilePhoneNumber;
            }
        }
        this.changeLoading(false);
    }

    public getLookupEmployeeFromWcEmpInfo(info: WcEmployeeInfo): Employee {
        let employee: Employee = this.mapService.mapWcEmployeeInfoToEmployeeLookup(info);
        return employee;
    }

    public async loadEmployees(): Promise<Lookup> {
        return this.lookupService.getLookup({ lookupType: LookupType.employeeList, orgLevelId: this.orgLevel.id, isActive: true });
    }

    public async loadStates(): Promise<Lookup> {
        return this.lookupService.getLookup({ lookupType: LookupType.state });
    }

    public async readAddedFiles(files: File[]): Promise<ReadFile[]> {
        const promises = _.map(files, (f: File) => this.readFileData(f));
        let readedFiles: ReadFile[] = [];
        try {
            const binaryData = await Promise.all(promises);
            readedFiles = this.mapDateToReadFiles(files, binaryData);
        } catch (err) {
            console.error(err);
        } finally {
            return readedFiles;
        }
    }

    public async deleteAttachment(attachmentId: number): Promise<void> {
        await this.api.deleteDocument(this.report.id, [attachmentId]);
        this.report.attachments = _.filter(this.report.attachments, (a) => a.id !== attachmentId);
    }



    public setAttachments(attachments: AttachmentDefinition[]): void {
        if (_.isArray(attachments)) {
            this.report.attachments = _.slice(attachments, 0);
        }
    }

    public getAttachments(): AttachmentDefinition[] {
        if (_.size(this.report.attachments) > 0) {
            return _.slice(this.report.attachments, 0);
        }
        return [];
    }

    public addFilesToSave(files: ReadFile[]): void {
        if (_.isArray(files)) {
            this.report.filesToSave = _.concat(files, this.report.filesToSave || []);
        }
    }

    public deleteFileToSave(file: ReadFile): void {
        if (_.isObjectLike(file)) {
            this.report.filesToSave = _.filter(this.report.filesToSave, (f) => f.fileName !== file.fileName);
        }
    }

    public getFilesToSave(): ReadFile[] {
        if (_.isArray(this.report.filesToSave)) {
            return _.slice(this.report.filesToSave, 0);
        }
        return [];
    }

    public navigateToUserProfile(id: number) {
        this.changeService.canMoveForward(this.changeManagementComponentId).then(canMove => {
            if (!canMove) {
                return;
            }
            let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
            const urlTree = navService.getUrlTreeFromRoot(id, true);
            const extras: NavigationExtras = {
                skipLocationChange: false,
                replaceUrl: false,
            };
            this.router.navigateByUrl(urlTree, extras);
            this.closePopup(false);
        });
    }

    public async navigateToLeaveManagementWithAction(action: LoaActionsDropDownItem, empId: number): Promise<void> {
        this.changeService.canMoveForward(this.changeManagementComponentId).then(canMove => {
            if (!canMove) {
                return;
            }

            let navService: LmNavigationService = new LmNavigationService(this.router, this.route);
            navService.navigateToLmRosterWithAction(this.orgLevel.id, action.id, empId);
            this.closePopup(false);
        });
    }

    public getUserName(): string {
        var user = this.sessionService.getUser();
        return user.username;
    }

    public downloadAttachment(attachmentId: number): Promise<void> {
        this.changeLoading(true);
        try {
            let promise: Promise<void> = this.api.downloadAttachment(attachmentId)
                .then((file) => {
                    return this.fileService.saveToFileSystem(file.blob, file.file);
                });
            return promise;
        } catch (e) {
            console.error(e);
        } finally {
            this.changeLoading(false);
        }
    }

    private async attachSavedFiles(reportId: number, filesToSave: ReadFile[]): Promise<WcReport> {
        if (_.size(filesToSave) > 0) {
            let report = await this.api.createDocument(reportId, filesToSave);
            return report;
        }
        return null;
    }

    private createWcReportModel() {

        let report = new WcReport();
        report.editMode = true;

        report.incidentDateTime = null;
        report.reportDateTime = null;

        report.injuryReport = new WcInjuryReport();
        report.injuryReport.isOSHARecordable = false;
        report.injuryReport.isWorkersCompCase = false;

        let profile = new UserProfileDefinition();
        let user = this.sessionService.getUser();
        profile.id = user.id;
        profile.name = user.username;
        profile.login = user.name;
        profile.email = user.email;
        report.injuryReport.user = profile;

        report.common = new ReportCommonFields();

        return report;
    }

    private async loadLookups() {

        let statuses = this.api.getReportStatuses();
        let treatments = this.api.getTreatments();
        let compCases = this.api.getWcCompCases();

        let injuryTypes = this.api.getWcCodes('injuryType');
        let oshaInjuryTypes = this.api.getWcCodes('oSHAInjuryType');
        let equipmentTypes = this.api.getWcCodes('equipment');
        let locations = this.api.getWcCodes('location');
        let bodyParts = this.api.getWcCodes('bodyPart2');
        let bodySide = this.api.getWcCodes('body');
        let causes = this.api.getWcCodes('cause');
        let occ = this.api.getWcCodes('occurrence');
        let illnesses = this.api.getWcCodes('illness');

        const results = await Promise.all([
            injuryTypes,
            oshaInjuryTypes,
            locations,
            equipmentTypes,
            bodyParts,
            bodySide,
            causes,
            occ,
            illnesses,
        ]);

        const results2 = await Promise.all([
            statuses,
            treatments,
            compCases
        ]);

        let wcLookups = new WcLookupsContainer();
        wcLookups.reportStatuses = results2[0];
        wcLookups.medicalTreatments = results2[1];
        wcLookups.compCases = results2[2];

        wcLookups.injuryTypes = results[0];
        wcLookups.oshaInjuryTypes = results[1];
        wcLookups.incidentLocations = results[2];
        wcLookups.equipmentTypes = results[3];
        wcLookups.bodyParts = results[4];
        wcLookups.bodySides = results[5];
        wcLookups.causes = results[6];
        wcLookups.occurences = results[7];
        wcLookups.illnesses = results[8];

        this.wcLookups = wcLookups;

        this.wcLookupsLoaded$.next(this.wcLookups.deepClone());
    }

    private mapDateToReadFiles(files: File[], binaryData: (string | ArrayBuffer)[]): ReadFile[] {
        return _.map(files, (file: File, i: number) => {
            const index = file.name.lastIndexOf('.');
            const name = file.name.slice(0, index);
            const ext = file.name.slice(index + 1);
            const data = new Blob([binaryData[i]]);
            return new ReadFile(name, file.size, file.type, ext, data);
        });
    }

    private readFileData(file: File): Promise<ArrayBuffer> {
        return new Promise((resolve: (v: ArrayBuffer) => void, reject: (r: any) => void) => {
            const fr: FileReader = new FileReader();
            fr.onloadend = (): void => {
                const buffer = fr.result as ArrayBuffer;
                resolve(buffer);
            };
            fr.onerror = (): void => {
                reject(fr.error);
            };
            fr.readAsArrayBuffer(file);
        });
    }

    private updateValidity(): void {
        this.onValidityChanged$.next(this.wcValid && this.injuryValid && this.oshaValid && this.mainControlsValid);
    }

    public setIncidentDateTime(iDate: Date) {
        this.incidentDate = iDate;
        this.incidentDate$.next(iDate);
    }
}

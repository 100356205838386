import { TimeclockAssignmentRestriction } from '../../../employee/employee-sections/models/index';
import { Injectable } from '@angular/core';
import { ReplaySubject ,  Observable ,  Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';

import {
  TimeclockAssignmentContainer, TimeclockAssignmentEmployee, TimeclockAssignment,
  TimeclockRestrictionTotal, TimeclockAssignmentState
} from '../../models/index';
import { TimeclockRestrictionDefinition, TimeclockDefinition, EmployeeDefinition } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { LookupApiService } from '../../../organization/services/index';
import { TimeclockAssignmentApiService } from './timeclock-assignment-api.service';
import { IDestroyService } from '../../../core/models/index';

@Injectable()
export class TimeclockAssignmentManagementService implements IDestroyService {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  public onLoadStatus$: ReplaySubject<boolean>;
  public onStateChanged$: ReplaySubject<TimeclockAssignmentState>;
  public onSelectionChanged$: ReplaySubject<TimeclockAssignmentEmployee[]>;
  public onOrgLevelChanged$: ReplaySubject<number>;

  public selectedRecords: TimeclockAssignmentEmployee[];
  public currentOrgLevel: OrgLevel;
  public container: TimeclockAssignmentContainer;
  public state: TimeclockAssignmentState;
  public restrictions: TimeclockRestrictionDefinition[];
  public firstLoad: boolean;
  public orgLevelHasChanged: boolean;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  private timeclockAssignmentApiService: TimeclockAssignmentApiService;
  private lookupApiService: LookupApiService;

  constructor(timeclockAssignmentApiService: TimeclockAssignmentApiService, lookupApiService: LookupApiService) {
    this.timeclockAssignmentApiService = timeclockAssignmentApiService;
    this.lookupApiService = lookupApiService;
    this.state = new TimeclockAssignmentState();
    this.state.showRestrictions = [];
    this.state.resetGrid = false;
    this.firstLoad = true;
    this.orgLevelHasChanged = false;
    this.onSelectionChanged$ = new ReplaySubject(1);
    this.onLoadStatus$ = new ReplaySubject(1);
    this.onStateChanged$ = new ReplaySubject(1);
    this.onOrgLevelChanged$ = new ReplaySubject(1);
    this.orgLevelSubscription = this.orgLevel$
      .pipe(filter((o: OrgLevel) => !this.currentOrgLevel || o && this.currentOrgLevel.id !== o.id))
      .subscribe((o: OrgLevel) => {
        if (!o || !o.id) {
          return;
        }
        this.onOrgLevelChanged(o);
      });
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public loadTimeclockAssignments(orgLevelId: number): void {
    this.onLoadStatusChanged(true);
    this.timeclockAssignmentApiService.getTimeclockAssignments(orgLevelId)
      .then((container: TimeclockAssignmentContainer) => {
        this.container = container;
        this.calculateTotals(this.container, this.restrictions, this.state);
        if (this.firstLoad || this.orgLevelHasChanged) {
          if (this.state.unassignedEmployees > 0) {
            this.state.showUnassigned = true;
            this.state.showAssigned = false;
          } else {
            this.state.showUnassigned = false;
            this.state.showAssigned = true;
          }
        }
        this.onStateChanged(this.orgLevelHasChanged);
        this.onLoadStatusChanged(false);
        this.firstLoad = false;
        this.orgLevelHasChanged = false;
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public onAssign(assignment: TimeclockAssignment[]): void {
    this.onLoadStatusChanged(true);
    this.timeclockAssignmentApiService.assignEmployees(this.currentOrgLevel.id, assignment)
      .then((result: any) => {
        this.loadTimeclockAssignments(this.currentOrgLevel.id);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public onUnAssign(assignment: TimeclockAssignment[]): void {
    this.onLoadStatusChanged(true);
    this.timeclockAssignmentApiService.unassignEmployees(this.currentOrgLevel.id, assignment)
      .then((result: any) => {
        this.loadTimeclockAssignments(this.currentOrgLevel.id);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public onReAssign(assignment: TimeclockAssignment[]): void {
    this.onLoadStatusChanged(true);
    this.timeclockAssignmentApiService.assignEmployees(this.currentOrgLevel.id, assignment)
      .then((result: any) => {
        this.loadTimeclockAssignments(this.currentOrgLevel.id);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public onStateChanged(isResetGrid: boolean): void {
    this.state.resetGrid = isResetGrid;
    this.applyFilters(this.container, this.state);
    this.onStateChanged$.next(this.state);
  }

  public onRecordsSelected(selectedRecords: TimeclockAssignmentEmployee[]): void {
    this.selectedRecords = selectedRecords;
    this.onSelectionChanged$.next(selectedRecords);
  }

  public onLoadStatusChanged(isLoading: boolean): void {
    this.onLoadStatus$.next(isLoading);
  }

  public calculateTotals(container: TimeclockAssignmentContainer, restrictions: TimeclockRestrictionDefinition[], state: TimeclockAssignmentState): void {
    let assignedDefaults: boolean = false;
    state.unassignedEmployees = _.filter(container.employees, (employee: TimeclockAssignmentEmployee) => {
      return employee.assignments.length === 0;
    }).length;
    state.assignedEmployees = 0;
    state.totals = _.map(restrictions, (restriction: TimeclockRestrictionDefinition) => {
      let restTotal: TimeclockRestrictionTotal = new TimeclockRestrictionTotal();
      restTotal.restriction = restriction;
      restTotal.employeeCount = _.filter(container.employees, (employee: TimeclockAssignmentEmployee) => {
        return !!_.find(employee.assignments, (assignment: TimeclockAssignmentRestriction) => {
          return assignment.restriction.id === restriction.id;
        });
      }).length;
      state.assignedEmployees += restTotal.employeeCount;

      if ((this.firstLoad || this.orgLevelHasChanged) && !assignedDefaults && restTotal.employeeCount > 0) {
        state.showRestrictions.push(restriction);
        restTotal.isChecked = true;
        assignedDefaults = true;
      } else if (!this.firstLoad) {
        const index: number = _.indexOf(state.showRestrictions, restriction);
        if (index !== -1) {
          restTotal.isChecked = true;
        }
      }

      return restTotal;
    });

    if (state.showRestrictions.length === 0) {
      const firstItem: TimeclockRestrictionTotal = _.head(state.totals);
      firstItem.isChecked = true;
      state.showRestrictions.push(firstItem.restriction);
    }
  }

  public applyFilters(container: TimeclockAssignmentContainer, state: TimeclockAssignmentState): void {
    if (state.showUnassigned) {
      state.records = _.filter(container.employees, (employee: TimeclockAssignmentEmployee) => {
        return employee.assignments.length === 0;
      });
      return;
    }
    if (state.showAssigned) {
      state.records = _.filter(container.employees, (employee: TimeclockAssignmentEmployee) => {
        let result: boolean = false;
        _.forEach(state.showRestrictions, (rest: TimeclockRestrictionDefinition) => {
          let founded: TimeclockAssignmentRestriction = _.find(employee.assignments, (assignment: TimeclockAssignmentRestriction) => {
            return assignment.restriction.id === rest.id;
          });
          if (founded) {
            result = true;
            return;
          }
        });
        return result;
      });
      return;
    }
    state.records = [];
  }

  private onOrgLevelChanged(o: OrgLevel): void {
    this.currentOrgLevel = o;
    this.orgLevelHasChanged = true;
    this.onOrgLevelChanged$.next(this.currentOrgLevel ? this.currentOrgLevel.id : undefined);
    if (this.currentOrgLevel) {
      this.loadTimeclockRestrictions(this.currentOrgLevel.id);
      this.loadTimeclocks(this.currentOrgLevel.id);
    }
  }

  private loadTimeclockRestrictions(orgLevelId: number): void {
    this.onLoadStatusChanged(true);
    this.lookupApiService.getTimeclockRestrictionDefinitions(orgLevelId)
      .then((restrictions: TimeclockRestrictionDefinition[]) => {
        this.restrictions = restrictions;
        this.loadTimeclockAssignments(this.currentOrgLevel.id);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  private loadTimeclocks(orgLevelId: number): void {
    this.onLoadStatusChanged(true);
    this.lookupApiService.getTimeclockDefinitions(orgLevelId)
      .then((restrictions: TimeclockDefinition[]) => {
        this.state.timeclocks = restrictions;
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }
}

<slx-spinner [show]="isLoading">
    <div class="slx-high-box">
        <!-- ---------------------Tool Bar--------------------------------- -->
        <div class="slx-content-toolbar-indents cp-header">
            <div class="flex align-items__center justify-content__flex-end ">
                <div class="aca-manage-action-cls">
                    <slx-action-list *ngIf="!isEditMode" class="slx-wide">
                        <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent"
                            [popperPosition]="'bottom-end'">Actions<span></span></slx-action-button>
                        <popper-content #popperContent>
                            <slx-actions-list-item *ngIf="canExportToExcel" (onClick)="onClickExport(false)">Export to
                                Excel</slx-actions-list-item>
                            <slx-actions-list-item *ngIf="canExportToPdf" (onClick)="onClickExport(true)">Export to PDF
                            </slx-actions-list-item>
                        </popper-content>
                    </slx-action-list>
                </div>
            </div>
        </div>

        <form form #codeForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
            <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
                slxKendoGridState="MANAGAE_ACA_PERIODS" [data]="gridState?.view" [filterable]="'menu'"
                [filter]="gridState.state.filter" [skip]="gridState.state.skip" [pageable]="true" [pageSize]="pageSize"
                [sortable]="{ mode: 'multiple' }" [sort]="gridState.state.sort" [resizable]="true"
                (edit)="gridState.editHandler($event)" (save)="gridState.saveHandler($event)"
                (remove)="gridState.removeHandler($event)" (cancel)="gridState.cancelHandler($event)"
                (pageChange)="gridState.pageChange($event)" (dataStateChange)="gridState.dataStateChange($event)">

                <kendo-grid-command-column *ngIf="canEdit" title="Edit" width="90" [minResizableWidth]="80"
                    [locked]="isLockedColumn">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div class="inline-buttons">
                            <button kendoGridEditCommand type="button" [disabled]="isEditMode"
                                class="slx-inline-edit-button2">
                                <i class="fas fa-edit slx-inline-edit-button__icon" aria-hidden="true"></i>
                            </button>
                            <button kendoGridSaveCommand type="button" [disabled]="!codeForm.valid || isValidate"
                                class="slx-icon-button slx-inline-apply-button2">
                                <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
                            </button>
                            <button kendoGridCancelCommand type="button"
                                class="slx-icon-button slx-inline-cancel-button2">
                                <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </ng-template>
                </kendo-grid-command-column>
                <kendo-grid-column-group title="New Hire Config" [locked]="false" width="120">

                    <kendo-grid-column title="First of the Month" field="newHireFirstOfTheMonth" filter="boolean"
                        width="155" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span class="pendo-aca-config-firstmonth">First of the Month</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="cell-content">
                                <i *ngIf="dataItem.newHireFirstOfTheMonth" aria-hidden="true"
                                    class="fas fa-check align-checkBox"></i>
                            </div>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <div class="cell-content">
                                <slx-checkbox-button class="align-checkBox-edit"
                                    [(ngModel)]="dataItem.newHireFirstOfTheMonth"
                                    fieldName="newHireFirstOfTheMonth{{rowIndex}}"
                                    name="newHireFirstOfTheMonth{{rowIndex}}" (ngModelChange)="onChangeValue()">
                                </slx-checkbox-button>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Allow Overlap" field="newHireAllowOverlap" filter="boolean" width="140"
                        [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span class="pendo-aca-config-allowoverlap">Allow Overlap</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="cell-content">
                                <i *ngIf="dataItem.newHireAllowOverlap" aria-hidden="true"
                                    class="fas fa-check align-checkBox-overlap"></i>
                            </div>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <slx-checkbox-button class="align-checkBox-overlap-edit"
                                [(ngModel)]="dataItem.newHireAllowOverlap" fieldName="newHireAllowOverlap{{rowIndex}}"
                                name="newHireAllowOverlap{{rowIndex}}" (ngModelChange)="onChangeValue()">
                            </slx-checkbox-button>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Measurement Period" field="newHireMeasurementPeriod" filter="numeric"
                        width="175" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span class="pendo-aca-config-newhire-measurementperiod">Measurement Period</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="align-record"
                                [title]="dataItem.newHireMeasurementPeriod">{{ dataItem.newHireMeasurementPeriod }}
                            </span>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <slx-input-decorator>
                                <slx-kendo-number #newHireMeasurementPeriod="ngModel" slx-input
                                    [(ngModel)]="dataItem.newHireMeasurementPeriod" [min]="3" [max]="12"
                                    [required]="true" (ngModelChange)="onChangeNewHireMeasPer(dataItem)"
                                    placeholder="(in Months)" name="newHireMeasurPeriod{{rowIndex}}"></slx-kendo-number>
                                <span errorMessage for="required"></span>
                                <span errorMessage for="min">Min is 3 Months</span>
                                <span errorMessage for="max">Max is 12 Months</span>
                            </slx-input-decorator>
                            <div class="slx-error-block align-item"
                                *ngIf="newHireMeasMax && !newHireMeasurementPeriod.errors">Initial Measurement + Admin
                                period must be less than 14 full months.</div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Administrative Period" field="newHireAdministrativePeriod"
                        filter="numeric" width="180" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span class="pendo-aca-config-newhire-adminperiod">Administrative Period</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="align-record"
                                [title]="dataItem.newHireAdministrativePeriod">{{ dataItem.newHireAdministrativePeriod }}</span>
                        </ng-template>

                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <slx-input-decorator>
                                <slx-kendo-number #newHireAdministrativePeriod="ngModel" slx-input
                                    [(ngModel)]="dataItem.newHireAdministrativePeriod" [min]="0" [max]="90"
                                    [required]="true" (ngModelChange)="onChangeNewHireAdminPer(dataItem)"
                                    placeholder="(in Days (0-90))" name="newHireAdminPeriod{{rowIndex}}">
                                </slx-kendo-number>
                                <span errorMessage for="required"></span>
                                <span errorMessage for="min">Min 0 Days</span>
                                <span errorMessage for="max">Max 90 Days</span>
                            </slx-input-decorator>
                            <div class="slx-error-block align-item"
                                *ngIf="newHireAdminMax && !newHireAdministrativePeriod.errors">Initial Measurement +
                                Admin period must be less than 14 full months.</div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Stability Period" field="newHireStabilityPeriod" filter="numeric"
                        width="140" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span class="pendo-aca-config-newhire-stabilityperiod">Stability Period</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="align-record"
                                [title]="dataItem.newHireStabilityPeriod">{{ dataItem.newHireStabilityPeriod }}</span>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <slx-input-decorator>
                                <slx-kendo-number #newHireStabilityPeriod="ngModel" slx-input
                                    [(ngModel)]="dataItem.newHireStabilityPeriod" [min]="6" [max]="12" [required]="true"
                                    (ngModelChange)="onChangeNewHireStabPer(dataItem)" placeholder="(in Months)"
                                    name="newHireStabilityPer{{rowIndex}}"></slx-kendo-number>
                                <span errorMessage for="required"></span>
                                <span errorMessage for="min">Min is 6 Months</span>
                                <span errorMessage for="max">Max is 12 Months</span>
                            </slx-input-decorator>
                            <div class="slx-error-block align-item-stab"
                                *ngIf="newMaxHireStabPer && !newHireStabilityPeriod.errors">Min is
                                {{dataItem.newHireMeasurementPeriod}} months</div>
                        </ng-template>
                    </kendo-grid-column>

                </kendo-grid-column-group>

                <kendo-grid-column-group title="Standard Config" [locked]="false" width="120">

                    <kendo-grid-column title="First Day of Measurement" field="standardConfigFirstDayOfMeasurement"
                        filter="date" width="200" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>First Day of Measurement</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span
                                class="align-record">{{ dataItem.standardConfigFirstDayOfMeasurement | amDateFormat: appConfig.dateFormat}}</span>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <slx-input-decorator class="row-item">
                                <slx-date-picker-ngx slx-input
                                    [(ngModel)]="dataItem.standardConfigFirstDayOfMeasurement" [acceptNullDate]="false"
                                    [minDate]="minDateLimit()" [required]="true" placeholder="Date"
                                    (ngModelChange)="onChangeValue()" name="stdFirstMeasurement{{rowIndex}}">
                                </slx-date-picker-ngx>
                                <div errorMessage for="required"></div>
                                <div errorMessage for="date"></div>
                                <span errorMessage for="minDate">Date is invalid</span>
                            </slx-input-decorator>
                        </ng-template>

                    </kendo-grid-column>
                    <kendo-grid-column title="Measurement Period" field="standardConfigMeasurementPeriod"
                        filter="numeric" width="175" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span class="pendo-aca-config-stdconf-measurementperiod">Measurement Period</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="align-record"
                                [title]="dataItem.standardConfigMeasurementPeriod">{{ dataItem.standardConfigMeasurementPeriod }}</span>
                        </ng-template>

                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <slx-input-decorator>
                                <slx-kendo-number slx-input #standardConfigMeasurementPeriod="ngModel"
                                    [(ngModel)]="dataItem.standardConfigMeasurementPeriod" [min]="3" [max]="12"
                                    [required]="true" (ngModelChange)="onChangeStdConfigMeasPer(dataItem)"
                                    placeholder="(in Months)" name="stdMeasurementPer{{rowIndex}}"></slx-kendo-number>
                                <span errorMessage for="required"></span>
                                <span errorMessage for="min">Min is 3 Months</span>
                                <span errorMessage for="max">Max is 12 Months</span>
                            </slx-input-decorator>
                            <div class="slx-error-block align-item"
                                *ngIf="stdConfigMeasMax && !standardConfigMeasurementPeriod.errors">Initial Measurement
                                + Admin period must be less than 14 full months.</div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Administrative Period" field="standardConfigAdministrativePeriod"
                        filter="numeric" width="180" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span class="pendo-aca-config-stdconf-adminperiod">Administrative Period</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="align-record"
                                [title]="dataItem.stdAdministrativePeriod">{{ dataItem.standardConfigAdministrativePeriod }}</span>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <slx-input-decorator>
                                <slx-kendo-number #newHireAdministrativePeriod="ngModel" slx-input
                                    [(ngModel)]="dataItem.standardConfigAdministrativePeriod" [min]="0" [max]="90"
                                    [required]="true" (ngModelChange)="onChangeStdConfigAdminPer(dataItem)"
                                    placeholder="(in Days (0-90))" name="stdAdministrativePer{{rowIndex}}">
                                </slx-kendo-number>
                                <span errorMessage for="required"></span>
                                <span errorMessage for="min">Min 0 Days</span>
                                <span errorMessage for="max">Max 90 Days</span>
                            </slx-input-decorator>
                            <div class="slx-error-block align-item"
                                *ngIf="stdConfigAdminMax && !newHireAdministrativePeriod.errors">Initial Measurement +
                                Admin period must be less than 14 full months.</div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Stability Period" field="standardConfigStabilityPeriod" filter="numeric"
                        width="140" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span class="pendo-aca-config-stdconf-stabilityperiod">Stability Period</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="align-record"
                                [title]="dataItem.standardConfigStabilityPeriod">{{ dataItem.standardConfigStabilityPeriod }}</span>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

                            <slx-input-decorator>
                                <slx-kendo-number #standardConfigStabilityPeriod="ngModel" slx-input
                                    [(ngModel)]="dataItem.standardConfigStabilityPeriod" [min]="6" [max]="12"
                                    [required]="true" (ngModelChange)="onChangeStdConfigStabPer(dataItem)"
                                    placeholder="(in Months)" name="stdStabilityPer{{rowIndex}}"></slx-kendo-number>
                                <span errorMessage for="required"></span>
                                <span errorMessage for="min">Min is 6 Months</span>
                                <span errorMessage for="max">Max is 12 Months</span>
                            </slx-input-decorator>
                            <div class="slx-error-block align-item-stab"
                                *ngIf="stdConfigStabPer && !standardConfigStabilityPeriod.errors">Min is
                                {{dataItem.standardConfigMeasurementPeriod}} Months</div>

                        </ng-template>

                    </kendo-grid-column>

                </kendo-grid-column-group>


                <kendo-grid-excel fileName="ManageACAPeriods.xlsx" [fetchData]="getGridData()"></kendo-grid-excel>
                <kendo-grid-pdf fileName="ManageACAPeriods.pdf" [allPages]="true">
                    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
                    <kendo-grid-column field="employer.name" title="Employer"></kendo-grid-column>
                    <kendo-grid-column-group title="New Hire Config">
                        <kendo-grid-column field="newHireFirstOfTheMonth" title="First of the Month">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="cell-content">
                                    <i *ngIf="dataItem.newHireFirstOfTheMonth" aria-hidden="true"
                                        class="fas fa-check align-checkBox"></i>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="newHireAllowOverlap" title="Allow Overlap">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="cell-content">
                                    <i *ngIf="dataItem.newHireAllowOverlap" aria-hidden="true"
                                        class="fas fa-check align-checkBox-overlap"></i>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="newHireMeasurementPeriod" title="Measurement Period">
                        </kendo-grid-column>
                        <kendo-grid-column field="newHireAdministrativePeriod" title="Administrative Period">
                        </kendo-grid-column>
                        <kendo-grid-column field="newHireStabilityPeriod" title="Stability Period"></kendo-grid-column>
                    </kendo-grid-column-group>
                    <kendo-grid-column-group title="Standard Config">
                        <kendo-grid-column field="standardConfigFirstDayOfMeasurement" title="First Day of Measurement">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                {{ dataItem.standardConfigFirstDayOfMeasurement | amDateFormat: appConfig.dateFormat}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="standardConfigMeasurementPeriod" title="Measurement Period">
                        </kendo-grid-column>
                        <kendo-grid-column field="standardConfigAdministrativePeriod" title="Administrative Period">
                        </kendo-grid-column>
                        <kendo-grid-column field="standardConfigStabilityPeriod" title="Stability Period">
                        </kendo-grid-column>
                    </kendo-grid-column-group>
                </kendo-grid-pdf>

                <kendo-grid-messages filterMenuTitle="Filter">
                </kendo-grid-messages>  
            </kendo-grid>

        </form>
    </div>
</slx-spinner>
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SeriesClickEvent } from '@progress/kendo-angular-charts';
import { Subscription } from 'rxjs';

import { unsubscribe } from '../../../core/decorators/index';
import { ValuePairChartModel } from '../../models/value-pair-chart/value-pair-chart-model';
import { ValuePairChartDataService } from '../../services/value-pair-chart-data/value-pair-chart-data.service';

@Component({
  moduleId: module.id,
  selector: 'slx-donut-chart-value-pair',
  templateUrl: 'donut-chart-value-pair.component.html',
  styleUrls: ['donut-chart-value-pair.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartValuePairComponent implements OnInit, OnDestroy {

  @Output()
  public onClick: EventEmitter<any>;

  public model: ValuePairChartModel;

  @unsubscribe()
  private modelLoaded: Subscription;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dataService: ValuePairChartDataService
  ) {
    this.onClick = new EventEmitter<any>();
  }

  public ngOnInit(): void {
    this.modelLoaded = this.dataService.onModelLoaded.subscribe((model) => {
      this.model = model;

      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public handleClick(event: SeriesClickEvent): void {
    if (event.originalEvent && event.originalEvent.type === 'contextmenu') {
      return;
    }

    this.onClick.emit(event);
  }
}

import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, forwardRef } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import * as _ from 'lodash';
import { UntypedFormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { EditableListActionKind } from '../../../models/index';
import { CustomListActorBase } from '../custom-list-actor.base';

@Component({
  moduleId: module.id,
  selector: 'slx-editable-list-editor',
  templateUrl: 'editable-list-editor.component.html',
  styleUrls: ['editable-list-editor.component.scss'],
   providers: [
    {
      provide: CustomListActorBase,
      useExisting: forwardRef( () => EditableListEditorComponent)
    }
  ]
})
export class EditableListEditorComponent extends CustomListActorBase implements OnInit, OnDestroy {

  public formGroup: UntypedFormGroup;

  public ngOnInit(): void {
    this.formGroup = this.createFormGroup();
    if (this._item) this.updateFormGroup();
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onSaveClick (event: MouseEvent): void {
    event.preventDefault ();
    this.updateItem ();
    this.actionEmitter.emit (EditableListActionKind.COMPLETE_EDIT);
  }

  public onCancelClick (event: MouseEvent): void {
    event.preventDefault ();
    this.actionEmitter.emit (EditableListActionKind.CANCEL_EDIT);
  }

  protected updateItem(): void {
    //TODO: complete this functionality for default list editor
    console.debug ('update item');
  }

  protected createFormGroup (): UntypedFormGroup {
    return new UntypedFormGroup ({});
  }

  protected updateFormGroup (): void {
    //TODO: complete this functionality for default list editor
    console.debug ('update from group');
  }

}

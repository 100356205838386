
import {combineLatest} from 'rxjs/operators';

import { AppSettingsManageService } from './../../../../app-settings/services/app-settings-manage.service';
import { AppServerConfig } from './../../../../app-settings/model/app-server-config';
import { FileService } from './../../../../common/services/file/file.service';
import { FileBlobResponse } from './../../../../core/models/api/file-blob-response';
import { ConfirmOptions, ConfirmDialogComponent } from './../../../../common/components/confirm-dialog/confirm-dialog.component';
import { PayrollExportTestStateStatusLabels } from './../../models/payroll-export-test/payroll-export-test-state-statuses';
import { ActivatedRoute, Params } from '@angular/router';
import { OrgLevelTypeDefinition, OrgLevelType } from './../../../../state-model/models/org-level/org-level-type';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { PayrollExportResultDialogComponent } from './../../../../time-and-attendance/components/timecards-display/payroll-export-result-dialog/payroll-export-result-dialog.component';
import { PayrollExportSubmitResults } from './../../../../time-and-attendance/models/timecards/payroll-export-submit-result';
import { TimecardsApiService } from './../../../../time-and-attendance/services/timecards/timecards-api.service';
import { mutableSelect, unsubscribeInService } from './../../../../core/decorators';
import { PayCycle } from './../../../../organization/models/lookup/pay-cycle';
import { OrgLevel } from './../../../../state-model/models/org-level/org-level';
import { Observable ,  Subscription ,  Subject ,  ReplaySubject } from 'rxjs';
import { PayrollExportTestsContainer } from './../../models/payroll-export-test/payroll-export-test-container';
import { Injectable } from '@angular/core';
import { PayrollExportTestApiService } from './payroll-export-test-api.service';
import { OrganizationPayroll } from '../../models/payroll-export-test/organization-payroll';
import * as _ from 'lodash';
import * as moment from 'moment';
import { PayrollExportConfigContainer } from '../../models/payroll-export-test/payroll-export-config-container';
import { OrganizationPayrollContainer } from '../../models/payroll-export-test/organization-payroll-container';
import { OrganizationPayrollStates } from '../../models/payroll-export-test/organization-payroll-states';
import { appConfig, IApplicationConfig } from './../../../../app.config';
import { PayrollExportTestState } from '../../models/payroll-export-test/payroll-export-test-state';
import { AccrualsApiService } from '../../../accruals/services/accruals-api.service';
import { AccrualBalances, AccrualBalanceRecord, IAccrualsBalancesState } from '../../../accruals/models/index';
import { Assert } from '../../../../framework/index';
import { OrgPayrollExportStatus } from '../../models/payroll-export-test/org-payroll-export-status';
import { OrgPayrollExportState } from '../../models/payroll-export-test/org-payroll-export-state';
import { ExportPayrollTimeInterval } from '../../models/payroll-export-test/export-payroll-time-interval';
@Injectable()
export class PayrollExportTestManagementService {
    public globalExportSettingsEnabled: boolean;
    public settingsAvailable: boolean;
    public canExportToPayroll: boolean;
    public canLockTimecards: boolean;
    public canChangeConfig: boolean;
    public canUnlockTimecards: boolean;
    public isRequestExport: boolean;
    public selectedPayCycle: PayCycle;
    public payCycles: PayCycle[];
    public orgLevelId: number;
    public orgLevel: OrgLevel;
    public orgList: OrganizationPayroll[];
    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;
    public loadingChanged: Subject<boolean> = new Subject();
    public paycycleChanged: Subject<PayCycle> = new Subject();
    public urlParamsChanged: Subject<PayCycle> = new Subject();
    public paycyclesChanged: Subject<PayCycle[]> = new Subject();
    public isAccrualModuleEnabled$: ReplaySubject<boolean> = new ReplaySubject(1);
    @unsubscribeInService()
    private orgLevelSubscription: Subscription;
   
    private appConfig: IApplicationConfig;
    private startDate: Date;
    private endDate: Date;

    public isNextgenPayrollEnabled: boolean = false;
    public exportToPayrollFrequency: number = 0;
    public exportToPayrollDuration: number  = 0;
    public intervalId: any;
    public updateexportPayrollStatus: Subject<OrganizationPayroll> = new Subject();
    public isNextgenTimeExportEnabled: boolean = false;
    public nextgenPhasedRollout: boolean = false;

    constructor(private api: PayrollExportTestApiService, private taApi: TimecardsApiService,
                public modalService: ModalService, private activatedRoute: ActivatedRoute,
      private fileService: FileService, private appSettingsManageService: AppSettingsManageService
     
   ) {
        this.appConfig = appConfig;
        this.orgLevelSubscription = this.orgLevel$.pipe(
        combineLatest(this.activatedRoute.queryParams))
        .subscribe(((value: [OrgLevel, Params]) => {
            this.startDate = null;
            this.endDate = null;
            this.setSelectedPaycycle(null);
            this.setPaycycles(null);
            let [orgLevel, queryParams]: [OrgLevel, Params] = value;
            let queryOrgLevelId: number = Number(queryParams['orgLevelId']);
            if (queryOrgLevelId > 0 && queryOrgLevelId !== orgLevel.id) {
                return; 
            }
            this.orgLevel = orgLevel;
            let startDate = queryParams['startDate'];
            let endDate = queryParams['endDate'];

            if (startDate && endDate) {
                this.startDate = moment(startDate, appConfig.linkDateFormat).toDate();
                this.endDate = moment(endDate, appConfig.linkDateFormat).toDate();
            }

            if (orgLevel && this.orgLevelId !== orgLevel.id) {
                this.orgLevel = orgLevel;
                this.orgLevelId = orgLevel.id;
                this.updatePayCycleList();
            }
        }));      
     
        this.appSettingsManageService.getAppServerConfig()
            .then((conf: AppServerConfig) => {
                this.isRequestExport = conf.payrollExpManagementEnabled;
                this.isNextgenPayrollEnabled = conf.IsNextgenPayrollEnabled;
                this.exportToPayrollFrequency = conf.ExportToPayrollFrequency;
                this.exportToPayrollDuration = conf.ExportToPayrollDuration;
                this.isNextgenTimeExportEnabled = conf.IsNextgenTimeExportEnabled;                
                this.nextgenPhasedRollout = conf.NextgenPhasedRollout;
            });
    }

    public updatePayCycleList(): void {
        if (this.orgLevelId > 0) {
            this.changeLoading(true);
            this.api.getPayCycles(this.orgLevelId)
            .then((value: PayCycle[]) => {
                this.setPaycycles(value);
                this.autoSelectPaycycle();
                //this.updateList();
            });
        }
    }

    public updateList(): void {
        if (this.orgLevelId > 0 && this.selectedPayCycle) {

            this.changeLoading(true);
            this.api.getOrganizationPayrollList(this.orgLevelId, this.selectedPayCycle.startDate, this.selectedPayCycle.endDate)
            .then((value: OrganizationPayrollContainer) => {
                this.orgList = value.organizationPayrollList;
                this.settingsAvailable = value.settingsAvailable;
                this.globalExportSettingsEnabled = value.globalExportSettingsEnabled;
                this.canExportToPayroll = value.canExportToPayroll;
                this.canChangeConfig = value.canChangeConfig;
                this.canUnlockTimecards = value.canUnlockTimecards;
                this.canLockTimecards = this.selectedPayCycle.endDate < new Date();
                this.setSelectedPaycycle(this.selectedPayCycle);
                this.changeLoading(false);
                this.updateStatusOnload();
            });
        } else {
            this.changeLoading(false);
        }
    }

    public getTests(orgPayrollId: number): Promise<PayrollExportTestsContainer> {
        this.changeLoading(true);
        return this.api.getTests(orgPayrollId).then((value: PayrollExportTestsContainer) => {
            const org: OrganizationPayroll = _.find(this.orgList, org => org.organizationPayrollId === orgPayrollId);
            org.state = value.organizationPayroll.state;
            org.alertCount = value.organizationPayroll.alertCount;
            org.failCount = value.organizationPayroll.failCount;
            org.canExportToPayroll = value.organizationPayroll.canExportToPayroll;
            org.isLocked = value.organizationPayroll.isLocked;
            this.changeLoading(false);
            return value;
        });
    }

    public runTests(organizationId: number): Promise<boolean> {
        this.changeLoading(true);
        return this.api.runTests(organizationId, this.selectedPayCycle.startDate, this.selectedPayCycle.endDate)
        .then((value: boolean) => {
            this.changeLoading(false);
            return value;
        });
    }

    public requestExport(organizationId: number): Promise<PayrollExportSubmitResults> {
        this.changeLoading(true);
        return this.taApi.submitPayroll(organizationId, this.selectedPayCycle.startDate, this.selectedPayCycle.endDate)
            .then((value: PayrollExportSubmitResults) => {
                if (value) {
                    PayrollExportResultDialogComponent.openDialog(value, this.modalService, () => {});
                }
                this.changeLoading(false);
                return value;
            });
    }

    public getConfig(): Promise<PayrollExportConfigContainer> {
        this.changeLoading(true);
        return this.api.getConfig(this.orgLevelId)
            .then((value: PayrollExportConfigContainer) => {
                this.changeLoading(false);
                return value;
            });
    }

    public saveConfig(config: PayrollExportConfigContainer): Promise<PayrollExportConfigContainer> {
        this.changeLoading(true);
        return this.api.saveConfig(config)
            .then((value: PayrollExportConfigContainer) => {
                this.changeLoading(false);
                return value;
            });
    }

    public changeConfigIsGlobal(): Promise<boolean> {
        this.changeLoading(true);
        let newValue: boolean = !this.globalExportSettingsEnabled;
        return this.api.saveGlobalFlag(newValue)
            .then((value: boolean) => {
                if (value) {
                    this.globalExportSettingsEnabled = newValue;
                }
                this.changeLoading(false);
                return value;
            });
    }

    public lockOrganizationPayroll(orgPayroll: OrganizationPayroll, lock: boolean): Promise<boolean> {
        this.changeLoading(true);
        return this.api.lockOrganizationPayroll(orgPayroll.organizationPayrollId, lock)
        .then((success: boolean) => {
            if (success) {
                orgPayroll.isLocked = lock;
                if (!lock) {
                    orgPayroll.state = OrganizationPayrollStates.REOPENED;
                    orgPayroll.status = OrgPayrollExportState.Reopened;
                }
            }
            this.changeLoading(false);
            return success;
        });
    }

    public exportToPayroll(organizationId: number): Promise<boolean> {
        this.changeLoading(true);
        return this.taApi.getExportedTimecards(organizationId, this.selectedPayCycle.startDate, this.selectedPayCycle.endDate)
          .then((file: FileBlobResponse) => {
            this.fileService.saveToFileSystem(file.blob, file.file);
            this.changeLoading(false);
            return true;
          })
          .catch((reason: any) => {
            this.changeLoading(false);
            return false;
          });
    }


    private changeLoading(isLoading: boolean): void {
        this.loadingChanged.next(isLoading);
    }

    private autoSelectPaycycle(): void {
        this.selectedPayCycle = null;
        let paycycle: PayCycle;
        if (this.startDate && this.endDate) {
            const sd: number = this.startDate.getTime();
            const ed: number = this.endDate.getTime();
            paycycle = _.find(this.payCycles, (pc: PayCycle) => {
                return pc.startDate.getTime() === sd && pc.endDate.getTime() === ed;
            });
        }
        if (!paycycle) {
            let current: Date = new Date();
            paycycle = _.find(this.payCycles, (pc: PayCycle) => {
                return pc.startDate <= current && pc.endDate >= current;
            });

            if (!paycycle) {
                paycycle = _.maxBy(this.payCycles, (pc: PayCycle) => pc.endDate);
            }
        }

        this.setSelectedPaycycle(paycycle);
    }

    private setSelectedPaycycle(paycycle: PayCycle): void {
        this.selectedPayCycle = paycycle;

        if (!this.selectedPayCycle) {
            this.paycycleChanged.next(paycycle);
            return;
        }

        if (this.startDate && this.endDate) {
            let startSame = moment(this.startDate).isSame(paycycle.startDate);
            let endSame = moment(this.endDate).isSame(paycycle.endDate);
            if (startSame && endSame) {
                this.paycycleChanged.next(paycycle);
                return;
            }
        }

        this.orgLevelId = null;
        this.orgLevel = null;
        this.urlParamsChanged.next(paycycle);
        return;
    }

    private setPaycycles(paycycles: PayCycle[]): void {
        this.payCycles = paycycles;
        this.paycyclesChanged.next(paycycles);
    }

    
  
  public subscribeToIsAccrualModuleEnabled(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.isAccrualModuleEnabled$.subscribe(callback);
  }
  
  public isAccrualModuleEnabled(): void {

    this.api.isAccrualModuleEnabled()
      .then((data: boolean) => {
        this.isAccrualModuleEnabled$.next(data);
      })      
  }

  public updateStatus(exportedIds: string): Promise<OrgPayrollExportStatus[]> {
    return this.api.getOrganizationPayrollExportStatus(exportedIds)
        .then((value: OrgPayrollExportStatus[]) => {
        return value;
    });
  }

  public updateStatusAndEnableBtn(orgPayrollId: number, state: number, isEnabled: boolean): OrganizationPayroll {
     const org: OrganizationPayroll = _.find(this.orgList, org => org.organizationPayrollId === orgPayrollId);
     org.status = state;
     org.isCheckingExportStatus = isEnabled;
     return org;
  }

  public getInprogressExportStatus(): Promise<ExportPayrollTimeInterval[]> {
    let ids = Object.keys(this.orgList).map(key => this.orgList[key].organizationPayrollId).join(",");
    return this.api.getInprogressExportStatus(ids)
        .then((value: ExportPayrollTimeInterval[]) => {
        return value;
    });
  }

  public updateStatusOnload(): void {
    const liveWithTAExport = 'LiveWithTAExport';
    let isLiveWithTAExport = this.orgList.some((x) => x.payrollAppStatus === liveWithTAExport);
    if((!this.nextgenPhasedRollout && this.isNextgenPayrollEnabled && this.isNextgenTimeExportEnabled)
    || (this.nextgenPhasedRollout && this.isNextgenPayrollEnabled && isLiveWithTAExport)) {
        let exportIds = JSON.parse(sessionStorage.getItem('PayrollExportTimeInterval'));

        if(exportIds === null || exportIds === undefined || exportIds.length === 0) {
            this.getInprogressExportStatus()
            .then((value: ExportPayrollTimeInterval[]) => {
                sessionStorage.setItem('PayrollExportTimeInterval', JSON.stringify(value));
            });
        }
    }
  }
}

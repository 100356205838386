<slx-spinner [show]="isLoading">
  <div class="slx-high-box workers-compensation-configuration">
    
    <div class="slx-high-box__body">
        <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
        slxKendoGridState="BENEFITS_OPTION_DETAIL_FORMULA" 
        [slxKendoGridColumnsGroup]="columnGroup"
        [data]="gridState.view"
        [skip]="gridState.state.skip" 
        [sortable]="true"
        [sort]="gridState.state.sort"
        [filterable]="'menu'"
        [filter]="gridState.state.filter" 
        (dataStateChange)="gridState.dataStateChange($event)"
        (edit)="gridState.editHandler($event)"
        >

          <kendo-grid-column title="Employee Contribution" width="200" field="employeeContribution" 
             filter="text" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Employee Contribution</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ employeeContribution | number: ".2-2"| slxMoney }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Employer Contribution" width="200" field="employerContribution" 
             filter="text" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Employer Contribution</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ employerContribution | number: ".2-2"| slxMoney }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Coverage" width="200" field="coverage" 
             filter="text" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Coverage</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ coverage | number: ".2-2"| slxMoney }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-messages filterMenuTitle="Filter">
          </kendo-grid-messages>  
          </kendo-grid>


    </div>
  </div>
</slx-spinner>

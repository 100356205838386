export * from './accruals-balances/accruals-balances.component';
export * from './accruals-balances-grid/accruals-balances-grid.component';
export * from './accruals-balances-toolbar/accruals-balances-toolbar.component';
export * from './accruals-transactions/accruals-transactions.component';
export * from './accruals-transactions-grid/accruals-transactions-grid.component';
export * from './accruals-transactions-toolbar/accruals-transactions-toolbar.component';
export * from './recalculate-dialog/recalculate-dialog.component'
export * from './accruals-policy/accruals-policy.component';

import { AccrualsBalancesComponent } from './accruals-balances/accruals-balances.component';
import { AccrualsBalancesGridComponent } from './accruals-balances-grid/accruals-balances-grid.component';
import { AccrualsBalancesToolbarComponent } from './accruals-balances-toolbar/accruals-balances-toolbar.component';
import { AccrualsTransactionsComponent } from './accruals-transactions/accruals-transactions.component';
import { AccrualsTransactionsGridComponent } from './accruals-transactions-grid/accruals-transactions-grid.component';
import { AccrualsTransactionsToolbarComponent } from './accruals-transactions-toolbar/accruals-transactions-toolbar.component';
import { RecalculateDialogComponent } from './recalculate-dialog/recalculate-dialog.component'
import { AccrualsPolicyComponent } from './accruals-policy/accruals-policy.component';

export const accrualComponents: any[] = [
  AccrualsBalancesComponent,
  AccrualsBalancesGridComponent,
  AccrualsBalancesToolbarComponent,
  AccrualsTransactionsComponent,
  AccrualsTransactionsGridComponent,
  AccrualsTransactionsToolbarComponent,
  RecalculateDialogComponent,
  AccrualsPolicyComponent
];

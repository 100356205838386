
import {combineLatest as observableCombineLatest,  Subscription ,  Observable } from 'rxjs';

import { Component, OnInit, OnDestroy, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import * as _ from 'lodash';

import { ScheduleApiService } from '../../services/schedule/schedule-api.service';
import { mutableSelect } from '../../../core/decorators/redux-decorators';
import { ScheduleCycle } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { unsubscribe } from '../../../core/decorators/index';
import { PostScheduleNavigationService } from '../../../common/services/index';

import { appConfig, IApplicationConfig } from '../../../app.config';
import { IMasterScheduleFilters } from '../../store/master-schedule/master-schedule.types';

import { PostScheduleData, IPostScheduleData, SummaryGridRecord } from '../../models/post-schedule/post-schedule-data';
import { ResponseBody } from '../../../core/models/api/response-body';
import { Meta } from '../../../core/models/api/meta';
import { State, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { PageChangeEvent, GridDataResult, SelectionEvent, RowArgs } from '@progress/kendo-angular-grid';
import { LookupApiService } from '../../../organization/services/index';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'slx-post-schedule',
  templateUrl: 'post-schedule.component.html',
  styleUrls: ['post-schedule.component.scss', 'toggle-switch.scss']
})
export class PostScheduleComponent implements OnInit, OnDestroy {

  @mutableSelect(['masterSchedule', 'filters'])
  public filters$: Observable<IMasterScheduleFilters>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  public appConfig: IApplicationConfig;
  public scheduleData: PostScheduleData;
  public scheduleCycles: ScheduleCycle[];
  public selectedScheduleCycle: ScheduleCycle;
  public allowOT: boolean = true;
  public allowSameDay: boolean = true;
  public filters: IMasterScheduleFilters;
  public state: {
    isLoading: boolean;
    isLoaded: boolean;
    editMode: boolean;
  };
  public lookupApiService: LookupApiService;
  public sort: SortDescriptor[];
  public gridView: GridDataResult;
  public pageSize: number = 15;
  public skip: number = 0;

  @unsubscribe()
  protected orgLevelSubscription: Subscription;

  private currentOrgLevel: OrgLevel;
  private api: ScheduleApiService;
  private route: ActivatedRoute;
  private router: Router;

  constructor(api: ScheduleApiService, lookupApiService: LookupApiService, route: ActivatedRoute, router: Router) {

    this.route = route;
    this.router = router;

    this.api = api;
    this.scheduleData = new PostScheduleData();
    this.sort = [{ field: 'startDate', dir: 'desc' }];

    this.lookupApiService = lookupApiService;
  }

  public ngOnInit(): void {

    this.state = {
      isLoading: false,
      isLoaded: false,
      editMode: false
    };

    this.appConfig = appConfig;

    this.orgLevelSubscription = observableCombineLatest(this.orgLevel$, this.filters$, this.route.params).subscribe((value: [OrgLevel, IMasterScheduleFilters, Params]) => {

      let orgLevel: OrgLevel = value[0];
      let filters: IMasterScheduleFilters = value[1];
      let params: Params = value[2];

      this.currentOrgLevel = orgLevel;

      if (filters) {

        let linkMoment: moment.Moment = moment(params['date'], appConfig.linkDateFormat).startOf('day');
        let filterMoment: moment.Moment = moment(filters.dateFrom).startOf('day');

        this.scheduleData.startDate = filterMoment.isSame(linkMoment) ? filterMoment.toDate() : linkMoment.toDate();
        this.scheduleData.endDate = moment(this.scheduleData.startDate).add(filters.weekNumber, 'weeks').toDate();

        this.filters = filters;
      }

      this.lookupApiService.getScheduleCycles(orgLevel.id)
        .then((cycles: ScheduleCycle[]) => {
          this.scheduleCycles = cycles;
          if (!this.scheduleCycles) return;

          let startDate: moment.Moment = moment(this.scheduleData.startDate);

          let selectedCycle: ScheduleCycle = _.find(this.scheduleCycles, (cycle: ScheduleCycle) => {
            return cycle.startDate.isSame(startDate, 'day');
          });
          if (selectedCycle) {
            this.selectedScheduleCycle = selectedCycle;
            this.refreshData();
          } else {
            selectedCycle = _.find(this.scheduleCycles, (cycle: ScheduleCycle) => {
              return cycle.startDate.isBefore(startDate, 'day') && cycle.endDate.isAfter(startDate, 'day');
            });
            if (selectedCycle) this.onScheduleCycleSelected(selectedCycle);
          }
        });

    });

  }

  public ngOnDestroy(): void {
    this.appConfig = null;
  }


  public onScheduleCycleSelected(cycle: ScheduleCycle): void {

    if (cycle) {
      this.scheduleData.startDate = cycle.startDate.toDate();
      let service: PostScheduleNavigationService = new PostScheduleNavigationService(this.router, this.route);
      service.updateRoute(this.scheduleData.startDate);
      this.refreshData();
    }
  }

  public onPostClick(): void {

    this.startProgress();

    this.api.postSummarySchedule(this.currentOrgLevel, this.allowOT, this.allowSameDay, this.scheduleData)
      .then((response: ResponseBody<PostScheduleData, Meta>) => {
        this.stopProgress();
      }).catch((ex: Error) => {
        this.stopProgress();
      });
  }

  public switchState(item: SummaryGridRecord): void {
    item.editing = !item.editing;
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.refreshGrid();
  }

  public gridSelectionChange(selection: SelectionEvent): void {
    //Andrey Skorik  Please check this logic
    _.forEach(selection.selectedRows, (args: RowArgs) => { args.dataItem.editing = true; });
    _.forEach(selection.deselectedRows, (args: RowArgs) => {
      let item: any = args.dataItem ? args.dataItem : args;
      item.editing = false;
    });

    /* Prior version
    _.forEach(this.scheduleData.entities, (record: SummaryGridRecord, index: number) => {
          if (selection.index !== index) {
            record.editing = false;
          } else {
            record.editing = selection.selected;
          }
     });
    */
  }


  protected startProgress(): void {
    this.state.isLoading = true;
  }

  protected stopProgress(): void {
    this.state.isLoading = false;
  }

  private refreshGrid(): void {
    if (!this.scheduleData.entities) {
      this.gridView = null;
      return;
    }
    let sortedRecords: SummaryGridRecord[] = orderBy(this.scheduleData.entities, this.sort);
    let pagedRecords: SummaryGridRecord[] = sortedRecords.slice(this.skip, this.skip + this.pageSize);

    this.gridView = {
      data: pagedRecords,
      total: sortedRecords.length
    };
  }

  private refreshData(): void {
    if (this.currentOrgLevel) {

      this.startProgress();
      this.api.getScheduleSummary(this.currentOrgLevel, this.scheduleData.startDate)
        .then((response: ResponseBody<PostScheduleData, Meta>) => {

          this.scheduleData = response.data;
          if (!this.scheduleData) {
            this.scheduleData = new PostScheduleData();
            this.scheduleData.startDate = this.selectedScheduleCycle.startDate.toDate();
          }
          this.refreshGrid();
          this.stopProgress();

        }).catch((ex: Error) => {
          this.scheduleData = new PostScheduleData();
          this.scheduleData.startDate = this.selectedScheduleCycle.startDate.toDate();
          this.refreshGrid();
          this.stopProgress();
          console.debug('Error fetching records', ex);
        });
    }
  }

}

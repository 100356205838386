
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Input, ChangeDetectorRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';

import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper, saveEvent } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../../app/app.config';
import { ModalService, ModalAnchorDirective } from '../../../../../../common/index';

import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitEnrolledEmployee, BenefitDetailsTier } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/benefit-employees/index';
import { InfoDialogComponent, ConfirmOptions, ConfirmDialog2Component } from './../../../../../../common/components/index';

import { OrgLevel } from '../../../../../../state-model/models/index';
import { BenefitEmployeesApiService, BenefitEnrollmentCommonService } from '../../../../services/index';
import { subscribeToOrgLevel } from '../../../../../../organization/selectors/index';
import { BenefitEnrolledEmployeePreview, IBenefitEnrolledEmployeePreview } from '../../../../models/benefit-employees/benefit-enrolled-employee-preview';
import { NgForm, NgModel } from '@angular/forms';


@Component({
  selector: 'benefit-option-formula-grid',
  templateUrl: 'benefit-option-formula-grid.component.html',
  styleUrls: ['./benefit-option-formula-grid.component.scss']
})
export class BenefitOptionFormulaGridComponent implements OnInit, OnDestroy, AfterViewInit {
  public gridState: KendoGridStateHelper<BenefitEnrolledEmployee>;
  public columnGroup = 'BenefitOptionFormula';
  public appConfig: IApplicationConfig;
  public data: BenefitEnrolledEmployee[] = [];
  public coverageMultipllierOptions: { name: string, value: number }[];
  public isLoading: boolean = true;
  public isCoverageEditable: boolean = true;
  public editedRowsCount: number = 0;
  public employeeContribution: any = 0;
  public employerContribution: any = 0;
  public selectableSettings: SelectableSettings;
  public coverage: any = null;
  public stepcurrency = 0.01;
  public employeeContributionDefined: boolean = true;
  public employerContributionDefined: boolean = true;
  public coverageDefined: boolean = true;
  public warningMessage: string;
  public editeditems: BenefitEnrolledEmployee[] = [];

  @Input()
  public selectedEmployees: BenefitEnrolledEmployee[];

  @Input()
  public tierDetails: BenefitDetailsTier;

  @Input()
  public date: Date;

  @Output()
  public onEnrollmentCancel: EventEmitter<true>;

  @Output()
  public showCalculate = new EventEmitter<boolean>();

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private orgLevel: OrgLevel;
  public readonly pageSize = 50;

  @ViewChild(ModalAnchorDirective, { static: true })
  private modalAnchor: ModalAnchorDirective;
  private hasViewChanged: boolean;

  @ViewChild('form', {static: true}) 
  public ngForm: NgForm;
  private isFormValid: boolean = true;

  constructor(
    private manService: BenefitEmployeeManagementService,
    private commonValidationService: BenefitEnrollmentCommonService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private apiService: BenefitEmployeesApiService,
  ) {
    this.gridState = new KendoGridStateHelper<BenefitEnrolledEmployee>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;

    this.selectableSettings = {
      checkboxOnly: true
    };
    this.appConfig = appConfig;
    this.onEnrollmentCancel = new EventEmitter();
  }

  public ngOnInit() {
    this.hasViewChanged = this.manService.viewModeIsChanged;
    this.subscriptions.orgLevel = subscribeToOrgLevel(
      this.orgLevel$,
      () => this.orgLevel,
      (orgLevel: OrgLevel) => {
        this.orgLevel = orgLevel;
        if (!this.hasViewChanged) {
          this.reload();
        } else {
          this.hasViewChanged = false;
          this.renderRecords();
        }
      }
    );
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    this.subscriptions.calculateCoverage = this.manService.subscribeTocalculateCoverage(() => {
      _.forEach(this.editeditems, (e) => {
        let value = e.coverage;
        this.calculateFormula(value, e);
      });
      this.editeditems = [];
    });

    this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(() => {
      this.isFormValid = this.ngForm.valid;
      this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid);
    });
  }

  private populateValues() {
    this.getCoverageOptions();
    this.getEmployeeContribution();
    this.getEmployerContribution();
    this.getCoverage();
    this.getCoverageValue();
    this.validatePrerequisites();
    if ((this.tierDetails.erFormula.expression || this.tierDetails.empFormula.expression || this.tierDetails.coverageFormula.expression) && (this.employeeContributionDefined && this.employerContributionDefined && this.coverageDefined)) {
      this.formulaSelectionStart(true);
    }
  }

  private emitChanges(): void {
    this.showCalculate.emit(true);
  }

  public ngOnDestroy(): void {
  }

  public ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public validatePrerequisites(): void {
    if (!this.employeeContributionDefined || !this.employerContributionDefined || !this.coverageDefined) {
      let message = '';
      message = 'Employees cannot be enrolled as ';
      if (!this.employeeContributionDefined && !this.employerContributionDefined && !this.coverageDefined) {
        message += 'the Employee and Employer Contribution and Coverage amounts have';
      } else if (!this.employeeContributionDefined && !this.employerContributionDefined) {
        message += 'the Employee and Employer Contribution amounts have';
      } else if (!this.employeeContributionDefined && !this.coverageDefined) {
        message += 'the Employee Contribution and Coverage amounts have';
      } else if (!this.employerContributionDefined && !this.coverageDefined) {
        message += 'the Employer Contribution and Coverage amounts have';
      } else if (!this.employeeContributionDefined) {
        message += 'the Employee Contribution amount has';
      } else if (!this.employerContributionDefined) {
        message += 'the Employer Contribution amount has';
      } else if (!this.coverageDefined) {
        message += 'the Coverage amount has';
      }
      message += ' not been created for this benefit plan. Please add contribution and Coverage amounts for this plan to enroll employees.';
      this.coverageSelectionStart(message, true);
    }
  }

  public getCoverageValue() {
    if (this.tierDetails.coverageFormula.isFixedCalcType) {
      this.isCoverageEditable = false;
      this.manService.updateEmployeesUpdatedInfo(this.data);
      this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid);
      return this.tierDetails.coverageFormula.fixedAmount;
    } else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
      this.isCoverageEditable = false;
      this.manService.updateEmployeesUpdatedInfo(this.data);
      this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid);
      return this.tierDetails.coverageFormula.expression;
    } else if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
      this.isCoverageEditable = true;
      return null;
    } else {
      return null;
    }
  }

  public getCoverageOptions(): void {
    if (this.tierDetails.coverageFormula.isMultiplierCalcType) {

      let multiplier = this.tierDetails.coverageFormula.multipler;
      let maxCap = this.tierDetails.coverageFormula.maxCap;
      multiplier = _.isNumber(multiplier) ? multiplier : 500;
      maxCap = _.isNumber(maxCap) ? maxCap : 10000;
      const range = _.range(multiplier, maxCap + multiplier, multiplier);
      const opts = _.map(range, value => ({ name: _.toString(value), value }));

      this.coverageMultipllierOptions = opts;
    }
  }

  public onChangeCoverageWithMultiplier(value: { name: string, value: number }, dataItem: BenefitEnrolledEmployee, hasError: boolean): void {
    if (!hasError) {
      let newValue = _.isObjectLike(value) && _.isFinite(value.value) ? value.value : null;
      let hasValueChanged = dataItem.coverage !== newValue;
      if (hasValueChanged) {
        dataItem.coverage = newValue;
        this.calculateFormula(dataItem.coverage, dataItem);
      }
    } else {
      dataItem.coverage = null;
      this.validateInputs();
    }
  }

  public calculateFormula(coverage: number, dataItem: BenefitEnrolledEmployee) {
    if (coverage === dataItem.originalCoverage) {
      return;
    }
    dataItem.coverage = coverage;
    if (this.tierDetails.coverageFormula.isFormulaCalcType ||
      this.tierDetails.erFormula.isFormulaCalcType ||
      this.tierDetails.empFormula.isFormulaCalcType) {
      this.isLoading = true;
      this.manService.benefitsOptionTabDetailsChanged(true);
      this.apiService.getBenefitEnrolledPreviewEmployees(this.orgLevel.id, this.tierDetails.id, this.date, dataItem.coverage, [dataItem])
        .then((records: BenefitEnrolledEmployeePreview[]) => {
          const employeeMap = new Map(records.map(employee => [employee.employeeId, employee]));
          this.data = _.map(this.selectedEmployees, employee => {
            const record = employeeMap.get(employee.employeeId);
            if (record) {
              dataItem.employeeContributionAmount = record.employeeContributionAmount;
              dataItem.employerContributionAmount = record.employerContributionAmount;
              employee.calculatedCoverage = record.coverage;
              dataItem.originalCoverage = record.coverage;
            }
            return employee;
          });
        }).finally(() => {
          this.isLoading = false;
          this.updateRowsData();
        });
    } else {
      this.updateRowsData();
    }
  }

  public updateEditedItems(dataItem: BenefitEnrolledEmployee): void {
    if (_.filter(this.editeditems, (e) => e.employeeId == dataItem.employeeId).length == 0) {
      this.editeditems.push(dataItem);
    } else {
      _.remove(this.editeditems, x => x.employeeId === dataItem.employeeId);
      this.editeditems.push(dataItem);
    }
    this.emitChanges();
  }

  public onCoverageValueChange(value: number, dataItem: BenefitEnrolledEmployee, hasError: boolean): void {
    if (!hasError) {
      dataItem.coverage = value;
      this.updateEditedItems(dataItem);
    } else {
      dataItem.coverage = null;
    }
    this.validateInputs();
  }

  public getEmployeeContribution() {
    if (this.tierDetails && this.tierDetails.empFormula) {
      if (this.tierDetails.empFormula.isFixedCalcType && _.gte(this.tierDetails.empFormula.fixedAmount, 0)) {
        this.employeeContributionDefined = true;
        this.employeeContribution = this.tierDetails.empFormula.fixedAmount;
      } else if (this.tierDetails.empFormula.isFormulaCalcType) {
        this.employeeContributionDefined = !_.isNil(this.tierDetails.empFormula.expression) && _.size(this.tierDetails.empFormula.expression) > 0;
      } else {
        this.employeeContributionDefined = false;
      }
    }
  }

  public getEmployerContribution() {
    if (this.tierDetails && this.tierDetails.erFormula) {
      if (this.tierDetails.erFormula.isFixedCalcType && _.gte(this.tierDetails.erFormula.fixedAmount, 0)) {
        this.employerContributionDefined = true;
        this.employerContribution = this.tierDetails.erFormula.fixedAmount;
      } else if (this.tierDetails.erFormula.isFormulaCalcType) {
        this.employerContributionDefined = !_.isNil(this.tierDetails.erFormula.expression) && _.size(this.tierDetails.erFormula.expression) > 0;
      } else {
        this.employerContributionDefined = false;
      }
    }
  }

  public getCoverage() {
    if (this.tierDetails && this.tierDetails.coverageFormula) {
      if (this.tierDetails.coverageFormula.isFixedCalcType && _.gte(this.tierDetails.coverageFormula.fixedAmount, 0)) {
        this.coverageDefined = true;
        this.coverage = this.tierDetails.coverageFormula.fixedAmount;
        this.manService.updateEmployeesUpdatedInfo(this.data);
        this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid);
      } else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
        this.coverageDefined = !_.isNil(this.tierDetails.coverageFormula.expression) && _.size(this.tierDetails.coverageFormula.expression) > 0;
      } else if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
        this.coverageDefined = true;
        this.coverage = -1;
      } else if (this.tierDetails.coverageFormula.isAnyValueMaxCapCalcType && _.gt(this.tierDetails.coverageFormula.maxCap, 0)) {
        this.coverageDefined = true;
        this.coverage = -1;
      } else {
        this.coverageDefined = false;
      }
    }
  }

  public updateRowsData() {
    this.manService.updateEmployeesUpdatedInfo(this.data);
    this.validateInputs();
  }

  public validateInputs() {
    if (_.size(this.data) > 0) {
      const allDataValid = _.every(this.data, e => {
        let coverage = _.isNil(e.calculatedCoverage) ? e.coverage : e.calculatedCoverage;
        if (_.isNil(coverage)) {
          coverage = this.coverage;
        }
        let dataValid = (this.isValid(coverage)) &&
        (this.isValid(this.employeeContribution) || this.isValid(e.employeeContributionAmount)) &&
        (this.isValid(this.employerContribution) || this.isValid(e.employerContributionAmount));
        return dataValid;
      });
      if (allDataValid) {
        this.warningMessage = '';
      } else if (!this.warningMessage || this.warningMessage === '') {
        this.warningMessage = 'All required fields should be entered';
      }
      this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid || !allDataValid);
    }
  }

  private isValid(input: number) {
    return (_.isFinite(input) && input >= 0);
  }

  public coverageSelectionStart(message: string, noValues?: boolean): void {
    if ((this.coverageMultipllierOptions && this.coverageMultipllierOptions.length === 0) || noValues) {
      let options: ConfirmOptions = new ConfirmOptions();
      options.height = 225;
      options.showCancel = false;
      options.showOK = true;
      ConfirmDialog2Component.openDialog(
        'Warning',
        message,
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.onEnrollmentCancel.emit();
          }
        }, options);
      this.manService.benefitsOptionTabDetailsChanged(true);
    }
  }

  public async reload(): Promise<any> {
    if (this.tierDetails.coverageFormula.isFormulaCalcType ||
      this.tierDetails.erFormula.isFormulaCalcType ||
      this.tierDetails.empFormula.isFormulaCalcType) {
      this.isLoading = true;
      return this.apiService.getBenefitEnrolledPreviewEmployees(this.orgLevel.id, this.tierDetails.id, this.date, null, this.selectedEmployees)
        .then((records: BenefitEnrolledEmployeePreview[]) => {
          const employeeMap = new Map(records.map(employee => [employee.employeeId, employee]));
          this.data = _.map(this.selectedEmployees, employee => {
            const record = employeeMap.get(employee.employeeId);
            if (record) {
              employee.employeeContributionAmount = record.employeeContributionAmount ? record.employeeContributionAmount : 0;
              employee.employerContributionAmount = record.employerContributionAmount ? record.employerContributionAmount : 0;
              employee.coverage = record.coverage ? record.coverage : 0;
              employee.calculatedCoverage = record.coverage ? record.coverage : 0;
              employee.originalCoverage = record.coverage ? record.coverage : 0;
            }
            return employee;
          });
          this.refreshGrid();
          this.populateValues();
          this.validateInputs();
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.renderRecords();
    }
  }

  private renderRecords(): void {
    this.data = this.selectedEmployees;
    this.refreshGrid();
    this.populateValues();
    this.validateInputs();
    this.isLoading = false;
  }

  private refreshGrid(): void {
    if (!this.data) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.data, this.gridState.state);
  }

  public formulaSelectionStart(wrongValue?: boolean): void {
    _.forEach(this.selectedEmployees, (employee) => {
      if (employee.employeeContributionAmount === null) {
        employee.employeeContributionAmount = -1;
        this.employeeContribution = -1;
      } else {
        this.employeeContribution = employee.employeeContributionAmount;
      }

      if (employee.employerContributionAmount === null) {
        employee.employerContributionAmount = -1;
        this.employerContribution = -1;
      } else {
        this.employerContribution = employee.employerContributionAmount;
      }

      if (employee.coverage === null && this.tierDetails.coverageFormula.isFormulaCalcType) {
        employee.coverage = -1;
        this.coverage = -1;
      } else {
        this.coverage = employee.coverage;
      }
    });

    let message = this.commonValidationService.getFormulaError(
      this.tierDetails.empFormula.isFormulaCalcType,
      this.tierDetails.erFormula.isFormulaCalcType,
      this.tierDetails.coverageFormula.isFormulaCalcType,
      this.employeeContribution,
      this.employerContribution,
      this.coverage
    );

    if ((this.coverageMultipllierOptions && this.coverageMultipllierOptions.length === 0) || wrongValue) {
      if (message && message != '') {
        this.warningMessage = message;
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        ConfirmDialog2Component.openDialog(
          'Warning',
          message,
          this.modalService,
          (result: boolean) => {
            this.validateInputs();
            this.onEnrollmentCancel.emit();
          }, options);
      }
    }
  }
}

export interface IEmployeeListConfig {
  api: {
    employeeLists: string;
    employees: {
      root: string;
      list: string;
      actions: {
        root: string;
        list: string;
        group: {
          root: string;
          assignPolicy: string,
          sms: string;
          assignSlateProfile: string;
        }
        generatePassword: string;
        generateBadgeId: string;
        assignPoliciesToEmployees: string;
        assignPaycycleToEmployees: string;
        massterminate: string;
        payrate :{
          root: string;
          getPayRateComponents:string;
          massAdjustment:string;
        }
      };
    };
    applications: {
      root: string;
      orgLevel: {
        root: string;
        listDefinitions: string;
        lists: string;
      }
    },
    
  };
  defaultGridPageSize: number;
  employeeIdentifierName: string;
  shiftRequestDate:string;
  pendingShiftPartnerStatus:string;
	preassignPendingStatus:string;
}

export const employeeListConfig: IEmployeeListConfig = {
  api: {
    employeeLists: 'employeeListDefinitions',
    employees: {
      root: 'employees',
      list: 'list',
      actions: {
        root: 'actions',
        list: 'list',
        group: {
          root: 'group',
          assignPolicy: 'assignPolicy',
          sms: 'sms',
          assignSlateProfile: 'assignSlateProfile'
        },
        generatePassword: 'generatePassword',
        generateBadgeId: 'generateBadgeId',
        assignPoliciesToEmployees: 'assignPoliciesToEmployees',
        assignPaycycleToEmployees: 'assignPaycycleToEmployees',
        massterminate: 'massterminate',
        payrate :{
          root: 'payrate', 
          getPayRateComponents:'getPayRateComponents',
          massAdjustment:'massAdjustment'
        }
      }
    },
    applications: {
      root: 'applications',
      orgLevel: {
        root: 'orgLevel',
        listDefinitions: 'listDefinitions',
        lists: 'lists'
      }
    },
   
  },
  defaultGridPageSize: 20,
  employeeIdentifierName: 'EmpId',
  shiftRequestDate:"ShiftRequestDate",
  pendingShiftPartnerStatus: "Pending Agency Employee Open Shift Request",
  preassignPendingStatus: "Response Required Agency Employee Requested Open Shift"
};

import { Component, OnInit } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';

import { ClientMessageManagementService } from '../../services/client-message/client-message-management.service';
import { ClientMessage } from '../../models/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-client-message',
  templateUrl: 'client-message.component.html',
  styleUrls: ['client-message.component.scss']
})
export class ClientMessageComponent implements OnInit {

  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;
  public messages: ClientMessage[];

  @unsubscribe()
  private loadedSubscription: Subscription;

  constructor(private managementService: ClientMessageManagementService) {
  }

  public ngOnInit(): void {
   this.loadedSubscription = this.managementService.onLoaded$.subscribe((messages: ClientMessage[]) => {
      this.messages = messages;
    });
  }

  public closeMessage(msg: ClientMessage): void {
    this.managementService.readMessage(msg);
  }
}

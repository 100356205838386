import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, Renderer2 } from '@angular/core';
import * as _ from 'lodash';


import { EmployeePhoto } from '../../models/index';
import { EmployeeImagesService } from '../../services/index';


@Directive({
  selector: 'img[employeeThumbinalSrc]'
})
export class EmployeeThumbinalSrcDirective implements OnChanges {

  @Input()
  public employeeThumbinalSrc: number;
  @Input()
  public getEmpInitials = false;
  @Input()
  public defaultSize: number = 20;
  private imgPhoto: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private employeeImagesService: EmployeeImagesService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['employeeThumbinalSrc']) {
      __ngRendererSetElementAttributeHelper(this.renderer, this.elementRef.nativeElement, 'src', 'assets/img/user-image.png');
      this.employeeImagesService.getEmployeeThumbnail(this.employeeThumbinalSrc)
        .then((photo: EmployeePhoto) => {
          this.applyPhoto(photo);
        });

    }
  }

  public applyPhoto(employeePhoto: EmployeePhoto): void {
    if (employeePhoto.photo) {
      this.imgPhoto = employeePhoto.photo;
    } else if (this.getEmpInitials && _.size(employeePhoto.empInitial) > 0) {
      this.imgPhoto = this.getInitials(employeePhoto.empInitial);
    } else {
      this.imgPhoto = 'assets/img/user-image.png';
    }
    __ngRendererSetElementAttributeHelper(this.renderer, this.elementRef.nativeElement, 'src', this.imgPhoto);
  }

  public getInitials(initials:string):string {
    const width: number = this.elementRef.nativeElement.offsetWidth | this.defaultSize;
    const height: number = this.elementRef.nativeElement.offsetHeight | this.defaultSize;

    let canvas = document.createElement('canvas');
    canvas.style.display = 'none';
    canvas.width = width;
    canvas.height = height;
    document.body.appendChild(canvas);
    let context = canvas.getContext('2d');
    context.fillStyle = '#FDFEFE';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = '9px Lato';
    context.fillStyle = '#0092cb';
    context.imageSmoothingEnabled = false;
    context.fillText(initials.toUpperCase(), (canvas.width / 10) *2,(canvas.width / 10) * 7);
    const data = canvas.toDataURL();
    document.body.removeChild(canvas);
    return data;
  }
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ":") {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ["", name];
}

function __ngRendererSetElementAttributeHelper(renderer: AnyDuringRendererMigration, element: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    if (value != null) {
        renderer.setAttribute(element, name, value, namespace);
    }
    else {
        renderer.removeAttribute(element, name, namespace);
    }
}

<form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
    
    <kendo-grid #kendoGrid class="slx-blue-grid slx-full-height"
      [data]="gridState?.view"
      [selectable]="{ mode: 'single' }"
      [filterable]="'menu'"
      [filter]="gridState.state.filter"
      [sort]="gridState.state.sort"
      [sortable]="{ mode: 'multiple' }"
      (selectionChange)="gridState.selectionChange($event)"
      (dataStateChange)="gridState.dataStateChange($event)"
      (cancel)="gridState.cancelHandler($event)"
      (edit)="gridState.editHandler($event)"
      (save)="gridState.saveHandler($event)"
      [rowClass]="rowCallback"
    >

        <kendo-grid-command-column width="120" *ngIf="!state.bulkEditMode && state.canEdit">
                <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
                <div class="command-container">
                    <button kendoGridEditCommand type="button" class="slx-button slx-with-icon command-button">
                    <i class="fas fa-pencil-alt slx-button__icon" aria-hidden="true"></i>
                    </button>
                    <button kendoGridSaveCommand type="button" [disabled]="!templateForm.valid" class="slx-button slx-with-icon command-button">
                    <i class="far fa-check-circle fa-2x slx-button__icon" aria-hidden="true"></i>
                    </button>
                    <button kendoGridCancelCommand type="button" class="slx-button slx-with-icon command-button">
                    <i class="fa fa-ban fa-2x slx-button__icon" aria-hidden="true"></i>
                    </button>
                </div>
                </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-column title="Code" field="code" [filterable]="true">

            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="left-align">{{ dataItem.code }}</span>
            </ng-template>

            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <slx-input-decorator  className="slx-no-label slx-small-font">
                    <slx-kendo-number slx-input class="form-control" slx-input type="text" 
                    [decimals]="0" [step]="1" [(ngModel)]="dataItem.code" [required]="true" 
                    placeholder="Code"
                    name="codeInput{{rowIndex}}">
                    </slx-kendo-number>
                <span errorMessage for="required"></span>
                </slx-input-decorator>
            </ng-template>

            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-numeric-filter-menu
                            [column]="column"
                            [filter]="filter"
                            [filterService]="filterService"
                            >
                </kendo-grid-numeric-filter-menu>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Description" field="description" [filterable]="true">

            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="left-align">{{ dataItem.description }}</span>
            </ng-template>

            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <slx-input-decorator className="slx-no-label slx-small-font">
                <input class="form-control" slx-input type="text" maxlength="50" [(ngModel)]="dataItem.description" [required]="true" placeholder="Description"
                    name="descriptionInput{{rowIndex}}">
                <span errorMessage for="required"></span>
                </slx-input-decorator>
            </ng-template>

            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                            [column]="column"
                            [filter]="filter"
                            [filterService]="filterService"
                            >
                </kendo-grid-string-filter-menu>
            </ng-template>

        </kendo-grid-column>
        
        <kendo-grid-column title="Department" field="department.name" [filterable]="true" *ngIf="notDepartment">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span *ngIf="dataItem.department" class="left-align">{{ dataItem.department.name }}</span>
            </ng-template>

            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <slx-input-decorator className="slx-no-label slx-small-font">
                    <slx-dropdown-input slx-input [lookup]="{lookupType: 'department', orgLevelId: currentOrgLevel.id}" 
                        [required]="true" [(ngModel)]="dataItem.department"
                        placeholder="Department" name="department{{rowIndex}}">
                    </slx-dropdown-input>
                    <span errorMessage for="required"></span>
                </slx-input-decorator>
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                >
                </kendo-grid-string-filter-menu>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Position" field="position.name" [filterable]="true">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span *ngIf="dataItem.position" class="left-align">{{ dataItem.position.name }}</span>
                    <span *ngIf="!dataItem.position" class="left-align">All</span>
                </ng-template>
    
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <slx-input-decorator className="slx-no-label slx-small-font">
                        <slx-dropdown-input slx-input [lookup]="{lookupType: 'position', orgLevelId: dataItem.department?.orgLevelId}"
                            [emptyOption]="{ id: 0, name: 'All' }"
                            autoSelectFirst="true" [required]="false" [(ngModel)]="dataItem.position"
                            placeholder="Position" name="position{{rowIndex}}">
                        </slx-dropdown-input>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>
                </ng-template>
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                    >
                    </kendo-grid-string-filter-menu>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Unit" field="unit.name" [filterable]="true">

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span *ngIf="dataItem.unit" class="left-align">{{ dataItem.unit.name }}</span>
                    <span *ngIf="!dataItem.unit" class="left-align">All</span>
                </ng-template>
    
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <slx-input-decorator className="slx-no-label slx-small-font">
                        <slx-dropdown-input slx-input [lookup]="{lookupType: 'locationUnit', orgLevelId: dataItem.department?.orgLevelId}"
                            [emptyOption]="{ id: 0, name: 'All' }" 
                            autoSelectFirst="true" [required]="false" [(ngModel)]="dataItem.unit"
                            placeholder="Unit" name="unit{{rowIndex}}">
                        </slx-dropdown-input>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>
                </ng-template>
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                    >
                    </kendo-grid-string-filter-menu>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Updated On" field="updatedOn" [filterable]="true" editable="false">

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span *ngIf="dataItem.updatedOn" class="left-align">{{ dataItem.updatedOn | amDateFormat: appConfig.dateTimeFormatUS }}</span>
                </ng-template>
    
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-date-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                       >
                    </kendo-grid-date-filter-menu>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Updated By" field="updatedBy" [filterable]="true" editable="false">

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span *ngIf="dataItem.updatedBy" class="left-align">{{ dataItem.updatedBy }}</span>
                </ng-template>
    
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                       >
                    </kendo-grid-string-filter-menu>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-messages filterMenuTitle="Filter">
        </kendo-grid-messages>  
    </kendo-grid>

  </form>
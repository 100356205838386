
import {first} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import * as _ from 'lodash';

import { UserApplication, User } from '../../../state-model/models/index';
import { StyledUserApplication, IApplicationStyleMap, NavigationMenuItem, NavigationMenuType, ApplicationDashboardItem } from '../../../organization/models/index';
import { SessionService } from '../../../core/services/index';
import { Assert, StringUtils } from '../../../framework/index';
import { organizationConfig } from '../../../organization/organization.config';
import { ApplicationStateBusService } from '../../../organization/services/index';
import { appConfig } from '../../../../app/app.config';

@Injectable()
export class ApplicationService {

  public applicationsUpdated$: ReplaySubject<StyledUserApplication[]>;
  private styledApplications: StyledUserApplication[];

  constructor(
    private appStateBusService: ApplicationStateBusService,
    private sessionService: SessionService
  ) {
    this.applicationsUpdated$ = new ReplaySubject(1);

    appStateBusService.menuLoaded$.subscribe((menu: NavigationMenuItem[]) => {
      let applications = menu.filter(
        (item: NavigationMenuItem) => item.type === NavigationMenuType.application
      ).map(
        (item: NavigationMenuItem) => this.mapToStyledApplications(item)
      );

      let benAdminItem: NavigationMenuItem = _.find(menu, m => m.link === appConfig.extraApplicationsInfo.benAdmin.name);
      if (benAdminItem) {
        benAdminItem.applicationId = benAdminItem.id;
        applications.push(this.mapToStyledApplications(benAdminItem));
      }

      const hasV5AppMenuItem = menu.some((menuItem: NavigationMenuItem) => {
        return menuItem.name === appConfig.moreV5ItemsApplication.db_menu_name;
      });

      if (hasV5AppMenuItem) {
        const moreV5App = this.createMoreV5Application();
        applications.push(moreV5App);
      }

      const fakeApp = this.createFakeCommonStyledApplication();
      if (!applications.some((app: UserApplication) => app.id === fakeApp.id)) {
        applications.push(fakeApp);
      }
      this.styledApplications = applications;
      this.applicationsUpdated$.next(this.styledApplications);
    });
  }

  public applicationSelected(app: UserApplication): void {
    this.appStateBusService.selectApp(app);
  }

  public deselectApplication(): void {
    this.appStateBusService.deselectApp();
  }

  public getStyledApplications(): Promise<StyledUserApplication[]> {
    return new Promise<StyledUserApplication[]>((resolve) => {
      this.applicationsUpdated$.pipe(first()).subscribe((applications: StyledUserApplication[]) => {
        resolve(applications);
      });
    });
  }

  public getApplicationByName(name: string): Promise<UserApplication> {
    Assert.isNotNull(name, 'name');

    return this.getApplicationsFromSession().then((applications: UserApplication[]) => {
      let application: UserApplication = _.find(applications,
        (app: UserApplication) => StringUtils.equalsIgnoreCase(app.name, name)
      );

      return application;
    });
  }

  private getApplicationsFromSession(): Promise<UserApplication[]> {
    let applications: UserApplication[] = [];

    if (!this.sessionService.isExpired()) {
      let user: User = this.sessionService.getUser();
      applications = user.applications;

      const fakeApp = this.createFakeCommonApplication();
      if (!applications.some((app: UserApplication) => app.id === fakeApp.id)) {
        applications.push(fakeApp);
      }
    }

    return Promise.resolve(applications);
  }

  private mapToStyledApplications(item: NavigationMenuItem): StyledUserApplication {
    let styledApplication = new StyledUserApplication();

    let styleMap: IApplicationStyleMap = _.find(organizationConfig.applications,
      (styleMapApp: IApplicationStyleMap) => styleMapApp.id === item.applicationId
    );

    if (!styleMap) {
      styleMap = organizationConfig.unknownApplication;
    }

    styledApplication.icon = styleMap.icon;
    styledApplication.id = item.applicationId;
    styledApplication.link = item.link;
    styledApplication.name = item.name;
    styledApplication.title = item.displayName;
    styledApplication.loadCounters = styleMap.loadCounters;
    styledApplication.isAvailableOnMobile = item.isAvailableOnMobile;
    styledApplication.dashboards = [];
    if (item.childs) {
      const dashboardsLinks = [
        appConfig.extraApplicationsInfo.hrms.dashboards.leaveManagement.link,
      ];
      const childs = _.reduce(item.childs, (accum, m) => {
        if (_.includes(dashboardsLinks, m.link)) {
          const item = new ApplicationDashboardItem();
          item.id = m.id;
          item.link = m.link;
          item.title = m.displayName;
          item.icon = m.menuItemMapping.icon;
          accum.push(item);
        }

        return accum;
      }, []);
      styledApplication.dashboards.push(...childs);
    }
    return styledApplication;
  }

  private createFakeCommonApplication(): UserApplication {
    let app = new UserApplication();

    app.id = appConfig.globalMenuItemsApplication.id;
    app.name = appConfig.globalMenuItemsApplication.name;
    app.title = appConfig.globalMenuItemsApplication.title;
    app.link = appConfig.globalMenuItemsApplication.link;

    return app;
  }

  private createMoreV5Application(): StyledUserApplication {
    let styledApp = new StyledUserApplication();
    styledApp.id = appConfig.moreV5ItemsApplication.id;
    styledApp.name = appConfig.moreV5ItemsApplication.name;
    styledApp.title = appConfig.moreV5ItemsApplication.title;
    styledApp.link = appConfig.moreV5ItemsApplication.link;
    styledApp.isAvailableOnMobile = false;
    styledApp.loadCounters = organizationConfig.moreApplication.loadCounters;
    styledApp.icon = organizationConfig.moreApplication.icon;
    return styledApp;
  }

  private createFakeCommonStyledApplication(): StyledUserApplication {
    const app = this.createFakeCommonApplication();
    const styledApp = new StyledUserApplication();

    styledApp.id = app.id;
    styledApp.link = app.link;
    styledApp.name = app.name;
    styledApp.title = app.title;
    styledApp.isAvailableOnMobile = false;
    styledApp.loadCounters = organizationConfig.unknownApplication.loadCounters;
    styledApp.icon = organizationConfig.unknownApplication.icon;

    return styledApp;
  }

}


import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgModel } from '@angular/forms';

import { Observable ,  Subscription } from 'rxjs';

import { mutableSelect, unsubscribeAll } from '../../../../../core/decorators/index';
import { ManageGroupsManagementService } from '../../../../services/index';

import { Position, Lookup } from '../../../../../organization/models/index';

import { PositionGroupView } from '../../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-manage-position-groups',
  templateUrl: 'manage-position-groups.component.html',
  styleUrls: ['manage-position-groups.component.scss'],
  providers: [ManageGroupsManagementService],
  encapsulation: ViewEncapsulation.None
})
export class ManagePositionGroupsComponent implements OnInit, OnDestroy {
  public groups: PositionGroupView[];
  public storedGroups: PositionGroupView[];
  public positionsLookup: Lookup<Position>;
  public get isEditing(): boolean {
    return _.isObjectLike(this.editingGroup);
  }
  public searchText: string = '';

  @Output()
  public onLoading = new EventEmitter<boolean>();

  @Output()
  public onHasChanges = new EventEmitter<boolean>();

  private movedFrom: PositionGroupView;
  private movedPosition: Position;
  private groupNameIsValid: boolean = false;
  private editingGroup: PositionGroupView;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private indexToInsertNewGroup = 1;

  constructor (private manService: ManageGroupsManagementService) {}

  public ngOnInit(): void {
    this.manService.init();
    this.subscriptions.loading = this.manService
      .subscribeToLoading((isLoading: boolean) => {
        this.onLoading.emit(isLoading);
      });

    this.subscriptions.groups = this.manService
      .subscribeToLoadGroups((groups) => {
        this.groups = groups;
        this.storedGroups = _.cloneDeep(groups);
      });

    this.subscriptions.positions = this.manService
      .subscribeToLoadPositions((lookup) => {
        this.positionsLookup = lookup;
      });
  }

  public ngOnDestroy(): void {
    this.manService.destroy();
  }

  public isDefaultGroup(group: PositionGroupView): boolean {
    return group.id === this.manService.defaultGroupId;
  }

  public canSaveGroup(group: PositionGroupView): boolean {
    return this.isEditing && group.isEditing && _.size(group.name) > 0 && this.groupNameIsValid;
  }

  public canEditGroup(group: PositionGroupView): boolean {
    return !this.isEditing && !group.isEditing;
  }

  public canRemoveGroup(group: PositionGroupView): boolean {
    return !this.isEditing && group.positions.length === 0;
  }

  public canDragNDropGroups(): boolean {
    return !this.isEditing;
  }

  public onInputGroupName(model: NgModel, group: PositionGroupView): void {
    const groupName = _.trim(_.toLower(group.name));
    const groupViews = _.filter(this.groups, (g) => _.trim(_.toLower(g.name)) === groupName);
    if (_.size(groupViews) > 1) {
      model.control.setErrors({ 'uniqueName': true });
      this.groupNameIsValid = false;
    } else {
      const errors = model.control.errors || {};
      delete errors['uniqueName'];
      model.control.setErrors(errors);
      this.groupNameIsValid = _.size(errors) === 0;
    }
  }

  public onPressEnter(event: KeyboardEvent, model: NgModel, group: PositionGroupView): void {
    if (event.key === 'Enter' && _.size(model.control.errors) === 0) {
      this.onSave(group);
    }
  }

  public onAdd(): void {
    const group = this.manService.createGroupView();
    this.groups.splice(this.indexToInsertNewGroup, 0, group);
    this.toggleEdit(group, true);
  }

  public onCancel(group: PositionGroupView): void {
    if (!_.isFinite(group.id)) {
      this.deleteGroup(this.groups, group);
    }
    this.toggleEdit(group, false, true);
  }

  public onEdit(group: PositionGroupView): void {
    if (this.canEditGroup(group)) {
      this.toggleEdit(group, true);
    }
  }

  public async onSave(group: PositionGroupView): Promise<void> {
    if (this.canSaveGroup(group)) {
      if (!_.isFinite(group.id)) {
        await this.createGroup(group);
      } else {
        await this.updateGroup(group);
      }
      this.emitChanges();
    }
  }

  public async onDelete(group: PositionGroupView): Promise<void> {
    if (this.canRemoveGroup(group) && _.isFinite(group.id)) {
      await this.manService.deleteGroup(group);
      this.deleteGroup(this.groups, group);
      this.deleteGroup(this.storedGroups, group);
      this.emitChanges();
    }
  }

  public async onDragAdd(event: { dataItem: Position }): Promise<void> {
    this.movedPosition = event.dataItem;
  }

  public onDragStart(group: PositionGroupView): void {
    this.movedFrom = group;
  }

  public async onDragEnd(movedTo: PositionGroupView): Promise<void> {
    if (this.canDragNDropGroups() && _.isObjectLike(this.movedPosition)) {
      await this.manService.assignGroupsToPositions(movedTo, this.movedPosition);
      this.applyChangesForStoredGroups(this.movedFrom, movedTo, this.movedPosition);
      this.emitChanges();
    }
  }

  public onFilterChanged(): void {
    this.groups = this.manService.filteringGroups(this.storedGroups, this.searchText);
  }

  public onResetSearch(): void {
    if (this.searchText.length > 0) {
      this.searchText = '';
      this.onFilterChanged();
    }
  }

  private async createGroup(g: PositionGroupView): Promise<void> {
    const createdGroup = await this.manService.createGroup(g);
    if (_.isObjectLike(createdGroup)) {
      const index = this.deleteGroup(this.groups, g);
      if (index !== -1) {
        this.groups.splice(index, 0, createdGroup);
        this.storedGroups.splice(index, 0, _.cloneDeep(createdGroup));
      }
      this.toggleEdit(createdGroup, false);
    }
  }

  private async updateGroup(group: PositionGroupView): Promise<void> {
    const updatedGroup = await this.manService.updateGroup(group);
    if (_.isObjectLike(updatedGroup)) {
      group.name = updatedGroup.name;
      const storedIndex = this.findIndex(this.storedGroups, group);
      if (storedIndex !== -1) {
        this.storedGroups[storedIndex].name = updatedGroup.name;
      }
      this.toggleEdit(group, false);
    }
  }

  private toggleEdit(group: PositionGroupView, isEditing: boolean, isRestore: boolean = false): void {
    if (isEditing) {
      this.editingGroup = _.cloneDeep(group);
      group.isEditing = true;
      return;
    }

    if (isRestore) {
      group.name = this.editingGroup.name;
    }
    group.isEditing = false;
    this.editingGroup = null;
  }

  private applyChangesForStoredGroups(movedFrom: PositionGroupView, movedTo: PositionGroupView, pos: Position): void {
    const position = _.cloneDeep(pos);
    const movedFromIndex = this.findIndex(this.storedGroups, movedFrom);
    if (movedFromIndex !== -1) {
      _.remove(this.storedGroups[movedFromIndex].positions, (p) => p.id === position.id);
    }

    const movedToIndex = this.findIndex(this.storedGroups, movedTo);
    if (movedToIndex !== -1) {
      const positions = this.storedGroups[movedToIndex].positions;
      const posIndex = _.findIndex(positions, { id: position.id });
      if (posIndex === -1) {
        positions.push(position);
      } else {
        positions.splice(posIndex, 1, position);
      }

    }
  }

  private emitChanges(): void {
    this.onHasChanges.emit(true);
  }

  private deleteGroup(groups: PositionGroupView[], group: PositionGroupView): number {
    const index = this.findIndex(groups, group);
    if (index !== -1) {
      groups.splice(index, 1);
    }
    return index;
  }

  private findIndex(groups: PositionGroupView[], group: PositionGroupView): number {
    return _.findIndex(groups, (g) => g.id === group.id || g.name === group.name);
  }
}

import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { Assert } from '../../../../framework/index';
import { UrlParamsService, ApiUtilService, OdataApiService } from '../../../../common/services/index';
import { Meta, ResponseBody, IFilteredItems, ServerQuery } from '../../../../core/models/index';
import { PbjExportMapService } from './pbj-export-map.service';
import { pbjConfig } from '../../pbj.config';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import {
  IPBJExportLogRecord, PBJExportLogRecord, IPBJExportLogCensusData, PBJExportLogCensusData,
  IPBJExportLogEmployeeData, PBJExportLogEmployeeData,
  IPBJExportLogError, PBJExportLogError,
  IPBJExportLogStaffingHours, PBJExportLogStaffingHours,
  IPBJExportLogHeader, PBJExportLogHeader,
  IPBJExportResult, PBJExportResult,
  IPBJExportRequest, PBJExportRequest,
  IPBJExportContainer, PBJExportContainer, PBJBatchExport
} from '../../models/index';
import { IPBJExportParentGrid, PBJExportParentGrid } from '../../models/pbj-export/pbj-export-parent-grid';
import { IPBJExportResultFlagEnabled, PBJExportResultFlagEnabled } from '../../models/pbj-export/pbj-export-result-flag-enabled';
import { IPBJExportChildResult, PBJExportChildResult } from '../../models/pbj-export/pbj-export-child-result';
import { PBJExportChildGrid } from '../../models/pbj-export/pbj-export-child-grid';
import { PBJChildDownload, PBJDownloadBulkExport, PBJDownloadExcelPdf, PBJDownloadSummary } from '../../models/pbj-export/pbjChildGridDownload';
import { IPBJDuplicateWorkers, PBJDuplicateWorkers } from '../../models/pbj-duplicate-workers/pbj-duplicate-workers';

@Injectable()
export class PbjExportApiService {

  constructor(private apiUtilService: ApiUtilService, private odataApiService: OdataApiService, private urlParamsService: UrlParamsService, private mapService: PbjExportMapService) {
  }

  public getPBJExportLogRecords(query: ServerQuery, orgLevelId: number): Promise<IFilteredItems<PBJExportLogRecord>> {
    let promise: Promise<IFilteredItems<PBJExportLogRecord>> = this.odataApiService.getData<PBJExportLogRecord>('PBJExportLogRecord', query,
      (dto: IPBJExportLogRecord) => { return this.mapService.mapPBJExportLogRecord(dto); }, { orgLevelId: orgLevelId });
    return promise;
  }

  public getPBJExportLogCensusData(query: ServerQuery, exportId: number): Promise<IFilteredItems<PBJExportLogCensusData>> {
    let promise: Promise<IFilteredItems<PBJExportLogCensusData>> = this.odataApiService.getData<PBJExportLogCensusData>('PBJExportLogCensusData', query,
      (dto: IPBJExportLogCensusData) => { return this.mapService.mapPBJExportLogCensusData(dto); }, { exportId: exportId });
    return promise;
  }

  public getPBJExportLogEmployeeData(query: ServerQuery, exportId: number): Promise<IFilteredItems<PBJExportLogEmployeeData>> {
    let promise: Promise<IFilteredItems<PBJExportLogEmployeeData>> = this.odataApiService.getData<PBJExportLogEmployeeData>('PBJExportLogEmployee', query,
      (dto: IPBJExportLogEmployeeData) => { return this.mapService.mapPBJExportLogEmployeeData(dto); }, { exportId: exportId });
    return promise;
  }

  public getPBJExportLogError(query: ServerQuery, exportId: number): Promise<IFilteredItems<PBJExportLogError>> {
    let promise: Promise<IFilteredItems<PBJExportLogError>> = this.odataApiService.getData<PBJExportLogError>('PBJExportLogError', query,
      (dto: IPBJExportLogError) => { return this.mapService.mapPBJExportLogError(dto); }, { exportId: exportId });
    return promise;
  }

  public getPBJExportLogStaffingHours(query: ServerQuery, exportId: number): Promise<IFilteredItems<PBJExportLogStaffingHours>> {
    let promise: Promise<IFilteredItems<PBJExportLogStaffingHours>> = this.odataApiService.getData<PBJExportLogStaffingHours>('PBJExportLogStaffingHours', query,
      (dto: IPBJExportLogStaffingHours) => { return this.mapService.mapPBJExportLogStaffingHours(dto); }, { exportId: exportId });
    return promise;
  }


  public getPBJExportLogHeader(exportId: number): Promise<PBJExportLogHeader> {
    const url: string = `${this.getExportApiRoot()}/${exportId}/${pbjConfig.api.pbj.export.header}`;

    let promise: Promise<PBJExportLogHeader> = this.apiUtilService.request<IPBJExportLogHeader, Meta>(this.urlParamsService.createGetRequest(url))
      .then((response: ResponseBody<IPBJExportLogHeader, Meta>) =>
        this.mapService.mapPBJExportLogHeader(response.data));
    return promise;
  }

  public performExport(req: PBJExportRequest): Promise<PBJExportResult> {
    Assert.isNotNull(req, 'req');
    const url: string = `${this.getExportApiRoot()}/${pbjConfig.api.pbj.export.performExport}`;

    let promise: Promise<PBJExportResult> = this.apiUtilService.request<PBJExportResult, Meta>(this.urlParamsService.createPostRequest(url, this.mapService.mapPBJExportRequestDto(req)))
      .then((response: ResponseBody<IPBJExportResult, Meta>) =>
        this.mapService.mapPBJExportResult(response.data));
    return promise;
  }

  public performBatchExport(req: PBJExportRequest): Promise<PBJExportResultFlagEnabled[]> {
    Assert.isNotNull(req, 'req');
    const url: string = `${this.getExportApiRoot()}/${pbjConfig.api.pbj.export.performBatchIDExport}`;
    let promise: Promise<PBJExportResultFlagEnabled[]> = this.apiUtilService.request<PBJExportResultFlagEnabled[], Meta>(this.urlParamsService.createPostRequest(url, this.mapService.mapPBJExportRequestDto(req)))
      .then((response: ResponseBody<IPBJExportResultFlagEnabled[], Meta>) =>
        this.mapService.mapPBJExportResultFlagEnabledRecords(response.data));
    return promise;
  }

  public performDuplicateExport(orgLevelId :number): Promise<PBJDuplicateWorkers[]> {
    Assert.isNotNull(orgLevelId, 'req');
    const url: string = `${this.apiUtilService.getApiRoot()}/${pbjConfig.api.pbj.root}/${pbjConfig.api.pbj.duplicateWorkers.root}/${pbjConfig.api.pbj.duplicateWorkers.GetEmployeeDupes}?orgLevelId=${orgLevelId}`;
    let promise: Promise<PBJDuplicateWorkers[]> = this.apiUtilService.request<IPBJDuplicateWorkers[], Meta>(this.urlParamsService.createGetRequest(url))
      .then((response: ResponseBody<IPBJDuplicateWorkers[], Meta>) =>
        this.mapService.mapPBJEmployeeDuplicates(response.data));
    return promise;
  }

  public getPBJEmployeeDuplicateRecordsRefresh(orgLevelId :number): Promise<PBJDuplicateWorkers[]> {
    Assert.isNotNull(orgLevelId, 'req');
    const url: string = `${this.apiUtilService.getApiRoot()}/${pbjConfig.api.pbj.root}/${pbjConfig.api.pbj.duplicateWorkers.root}/${pbjConfig.api.pbj.duplicateWorkers.GetEmployeeDupesRefresh}?orgLevelId=${orgLevelId}`;
    let promise: Promise<PBJDuplicateWorkers[]> = this.apiUtilService.request<IPBJDuplicateWorkers[], Meta>(this.urlParamsService.createGetRequest(url))
      .then((response: ResponseBody<IPBJDuplicateWorkers[], Meta>) =>
        this.mapService.mapPBJEmployeeDuplicates(response.data));
    return promise;
  }

  public changeDisposition(req: PBJDuplicateWorkers): Promise<IPBJDuplicateWorkers[]> {
    Assert.isNotNull(req, 'req');
    const url: string = `${this.apiUtilService.getApiRoot()}/${pbjConfig.api.pbj.root}/${pbjConfig.api.pbj.duplicateWorkers.root}/${req.matching_org_id}/${pbjConfig.api.pbj.duplicateWorkers.changeDisposition}`
    let promise: Promise<PBJDuplicateWorkers[]> = this.apiUtilService.request<IPBJDuplicateWorkers[], Meta>(this.urlParamsService.createPutRequest(url, req))
      .then((response: ResponseBody<IPBJDuplicateWorkers[], Meta>) =>
        this.mapService.mapPBJEmployeeDuplicates(response.data));
    return promise;
  }

  public getExportMatchesDefaultDate(orgLevelId :number): Promise<any> {
    Assert.isNotNull(orgLevelId, 'req');
    const url: string = `${this.apiUtilService.getApiRoot()}/${pbjConfig.api.pbj.root}/${pbjConfig.api.pbj.duplicateWorkers.root}/${pbjConfig.api.pbj.duplicateWorkers.getExportMatchesDefaultDate}?orgLevelId=${orgLevelId}`;
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(this.urlParamsService.createGetRequest(url));
    return promise;
  }

  public updateExportMatchesDefaultDate(req: PBJDuplicateWorkers[], orgLevelId :number): Promise<any> {
    Assert.isNotNull(req, 'req');
    const url: string = `${this.apiUtilService.getApiRoot()}/${pbjConfig.api.pbj.root}/${pbjConfig.api.pbj.duplicateWorkers.root}/${orgLevelId}/${pbjConfig.api.pbj.duplicateWorkers.updateMatchesExportDate}`
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(this.urlParamsService.createPutRequest(url, req));
    return promise;
  }


  public getBatchIDExportData(req: PBJBatchExport): Promise<PBJExportParentGrid[]> {
    Assert.isNotNull(req, 'req');
    const url: string = `${this.getExportApiRoot()}/${pbjConfig.api.pbj.export.getBatchIDExportData}`;
    let promise: Promise<PBJExportParentGrid[]> = this.apiUtilService.request<PBJExportParentGrid[], Meta>(this.urlParamsService.createPostRequest(url, this.mapService.mapPBJBatchExportDto(req)))
      .then((response: ResponseBody<IPBJExportParentGrid[], Meta>) =>
        this.mapService.mapPBJParentGridExportRecords(response.data));
    return promise;
  }

  public getChildExportData(req: PBJExportChildGrid): Promise<PBJExportChildResult[]> {
    Assert.isNotNull(req, 'req');
    const url: string = `${this.getExportApiRoot()}/${pbjConfig.api.pbj.export.getChildExportData}`;
    let promise: Promise<PBJExportChildResult[]> = this.apiUtilService.request<PBJExportChildResult[], Meta>(this.urlParamsService.createPostRequest(url, this.mapService.mapPBJChildExportDto(req)))
      .then((response: ResponseBody<IPBJExportChildResult[], Meta>) =>
        this.mapService.mapPBJChildGridExportRecords(response.data));
    return promise;
  }

  public getPBJExportData(exportId: number): Promise<FileBlobResponse> {
    const url: string = `${this.getExportApiRoot()}/${exportId}/${pbjConfig.api.pbj.export.data}`;

    let promise: Promise<FileBlobResponse> = this.apiUtilService.requestForFile(this.urlParamsService.createGetRequest(url))
      .then((response: FileBlobResponse) => response);

    /*
        let promise: Promise<PBJExportContainer> = this.apiUtilService.request<IPBJExportContainer, Meta>(this.urlParamsService.createGetRequest(url))
          .then((response: ResponseBody<IPBJExportContainer, Meta>) =>
            this.mapService.mapPBJExportContainer(response.data));*/
    return promise;
  }

  private getExportApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${pbjConfig.api.pbj.root}/${pbjConfig.api.pbj.export.root}`;
  }

  public async downloadAllChildFiles(exportId: PBJChildDownload): Promise<FileBlobResponse> {
    const url: string = `${this.getExportApiRoot()}/${pbjConfig.api.pbj.export.downloadBlobZipFile}`;
    const request = this.urlParamsService.createPostRequest(url,exportId);
    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public async downloadExportSummaryExcel(exportId: PBJDownloadSummary): Promise<FileBlobResponse> {
    const url: string = `${this.getExportApiRoot()}/${pbjConfig.api.pbj.export.DownloadExportSummary}`;
    const request = this.urlParamsService.createPostRequest(url,exportId);
    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public async downloadBulkExportSummary(batchIdObj: PBJDownloadBulkExport): Promise<FileBlobResponse> {
    const url: string = `${this.getExportApiRoot()}/${pbjConfig.api.pbj.export.DownloadBulkExport}`;
    const request = this.urlParamsService.createPostRequest(url,batchIdObj);
    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public async downloadExcelFile(exportObj:PBJDownloadExcelPdf): Promise<FileBlobResponse>{
    const url: string = `${this.getExportApiRoot()}/${pbjConfig.api.pbj.export.downloadExcelFile}`;
    const request = this.urlParamsService.createPostRequest(url,exportObj);
    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public async downloadPDFFile(exportObj:PBJDownloadExcelPdf): Promise<FileBlobResponse>{
    const url: string = `${this.getExportApiRoot()}/${pbjConfig.api.pbj.export.downloadPDFFile}`;
    const request = this.urlParamsService.createPostRequest(url,exportObj);
    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkLocationSyncComponent } from '../work-location-sync/work-location-sync.component';
import { WfmResponseNavigationService } from '../../../../configuration/services/wfm-response-navigation.service';
import { ChangeManagementService } from '../../../../common/services/change-management/change-management.service';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { WFMFlagsSyncStatus, WFMStopSyncDetails } from '../../../../configuration/models/wfm-sync';

@Component({
  selector: 'slx-wfm-ngp-sync',
  templateUrl: './wfm-ngp-sync.component.html',
  styleUrls: ['./wfm-ngp-sync.component.scss']
})
export class WfmNgpSyncComponent implements OnInit {
  public isLoading: boolean = true;
  public BenefitPlansList: string[] = [];
  public selectedBenefitPlan: string;
  public tabSwitch: boolean;
  public isShowingExpired: boolean = null;
  public benefitPlansLoading: boolean = true;
  public currentExpandedGroups: number[];
  public canMapPayroll: boolean = false;
  @Input() type: any;
  public pause: boolean = false;
  public onGoing: boolean = false;
  public migration: boolean = false;
  public wfmFlagsSyncStatus: WFMFlagsSyncStatus;
  public stop: boolean = false;
  public wfmStopSyncDetails: WFMStopSyncDetails;
  public customerId: string;
  
  constructor(private wfmsyncService: WfmSyncService) {
  }

  public ngOnInit() {
    this.onPageload();
    this.getStopSyncData();
  }

  public onPageload() {
    this.wfmsyncService.getSyncStatus().then((value2: any) => {
      this.wfmFlagsSyncStatus = value2.data;
      this.onGoing = this.wfmFlagsSyncStatus.ongoingSync;
      this.pause = this.wfmFlagsSyncStatus.pauseSync;
    });
  }

  public syncPause() {
    let request: WFMFlagsSyncStatus = new WFMFlagsSyncStatus();
    if (this.onGoing == true ) {
      request.ongoingSync=true;
      request.migrationSync=false;
      
    } else {
      request.ongoingSync=true;
      request.migrationSync=false;
    }
    request.pauseSync = this.pause
    this.wfmsyncService.postSyncStatus(request).then((value2: any) => {
      this.onPageload();
    }).catch((reason: any) => {
      this.onPageload();
    });
  }

  public getStopSyncData() {
    this.wfmsyncService.getStopSyncStatus().then((value2: any) => {
      this.wfmStopSyncDetails = value2.data;
     this.customerId = this.wfmStopSyncDetails.customerId;
     this.stop = this.wfmStopSyncDetails.stopSync;
    });
  }

  public syncStop() {
    let request: WFMStopSyncDetails=new WFMStopSyncDetails();
    request.customerId = this.customerId;
    request.stopSync = this.stop;
    this.wfmsyncService.stopSyncStatus(request).then((value2: any) => {
      this.getStopSyncData()
    }).catch((reason: any) => {
      this.getStopSyncData();
    });
  }
}

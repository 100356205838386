<kendo-grid #kendoGrid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" [sortable]="{ mode: 'multiple' }"
            [sort]="gridState.state.sort" [filter]="gridState.state.filter" [groupable]="{ showFooter: true }" [group]="gridState.state.group"
            [filterable]="false" [filter]="gridState.state.filter"
            [selectable]="{ mode: 'single' }" [rowSelected]="isRowSelected()" (selectionChange)="onSelectionChanged($event)">

  <kendo-grid-column media="xs" title="Items" width="500">
    <ng-template kendoGridCellTemplate let-dataItem>
      <!-- template for mobile -->
      <div class="flex-horizontal">
        <i *ngIf="dataItem.isError" class="fa fa-exclamation-circle alert-error" aria-hidden="true"></i>
        <i *ngIf="dataItem.isInvalidPunch|| dataItem.isNoShow" class="fa fa-exclamation-triangle alert-warning" aria-hidden="true"></i>
      </div>
      <h4>
        {{ dataItem.weekDay }}
      </h4>
      <dl>
        <dt>Department</dt>
        <dd>{{ dataItem.departmentName }}</dd>

        <dt>Position</dt>
        <dd>{{ dataItem.positionName }}</dd>

        <dt>Standard Pay Rate</dt>
        <dd>{{ dataItem.standardPayRate| number: ".2-2"| slxMoney }}</dd>
      </dl>
      <h5>
        Totals
      </h5>
      <dl>
        <dt>Total Hours</dt>
        <dd>{{ dataItem.totalHours| number: ".2-2" }}</dd>

        <dt>Regular Pay</dt>
        <dd>{{ dataItem.regularPay| number: ".2-2"| slxMoney }}</dd>

        <dt> Overtime and Extra pay</dt>
        <dd>{{ dataItem.overtimeAndExtra| number: ".2-2"| slxMoney }}</dd>


        <dt> Total Pay</dt>
        <dd>{{ dataItem.totalPay| number: ".2-2"| slxMoney }}</dd>
      </dl>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Week" [hidden]="true" media="sm" width="80" field="week" headerClass="overflow-visible-cell height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      <div>
        <span slxTooltip="Week of cycle" tipPosition="bottom">Week</span>
      </div>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.week}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="120" title="Day" media="sm" field="weekDay" [filterable]="false" headerClass="overflow-visible-cell height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      Day
      <span class="day-alert">
        <i class="fa fa-exclamation-circle" slxTooltip="Has missing punches" tipPosition="bottom" aria-hidden="true"></i>
      </span>
      <span class="day-alert">
        <i class="fa fa-exclamation-triangle" slxTooltip="Has invalid punches" tipPosition="bottom" aria-hidden="true"></i>
      </span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="flex">
        <span class="day-week">{{ dataItem?.weekDay }}</span>
        <span class="day-alert">
          <i *ngIf="dataItem.isError" class="fa fa-exclamation-circle alert-error" aria-hidden="true"></i>
        </span>
        <span class="day-alert">
          <i *ngIf="dataItem.isInvalidPunch || dataItem.isInvalidLogin || dataItem.isNoShow" class="fa fa-exclamation-triangle alert-warning" aria-hidden="true"></i>
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="150" title="Date" media="sm" field="date" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      Date
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [dailyTimecardsLink]="container?.employee?.id" [timecardDate]="dataItem?.date">
        {{dataItem?.date | amDateFormat: appConfig.dateFormat }}
      </a>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('approved')" [width]="getWidth('approved')" [hidden]="!isVisible('approved')" title="Approved"
                     media="sm" field="approved" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      Approved
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.approved | boolyn}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-boolean-filter [column]="column" [filter]="filter">
      </slx-kendo-grid-boolean-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('schedule')" [width]="getWidth('schedule')" [hidden]="!isVisible('schedule')" title="Schedule"
                     media="sm" field="shiftName" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      Schedule
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a scheduleEntryLink [employeeId]="container?.employee?.id" [dateOn]="dataItem?.date">
        {{dataItem?.shiftName }}
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('in')" [hidden]="!isVisible('in')" title="IN" media="sm" field="punchInSeconds" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      IN
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.punchInTime | amDateFormat: appConfig.timeFormat }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-time-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-time-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('out')" [hidden]="!isVisible('out')" title="OUT" media="sm" field="punchOutSeconds" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      OUT
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.punchOutTime | amDateFormat: appConfig.timeFormat }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-time-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-time-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('department')" [width]="getWidth('department')" [hidden]="!isVisible('department')"
                     title="Department" media="lg" field="departmentName" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Department</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.departmentName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('position')" [width]="getWidth('position')" [hidden]="!isVisible('position')" title="Position"
                     media="sm" field="positionName" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Position</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.positionName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('payRate')" [hidden]="!isVisible('payRate') || !state.isShowPayRates" title="Pay Rate"
                     media="sm" field="standardPayRate" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Standard Pay Rate</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.standardPayRate | number: ".2-2"| slxMoney}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('shiftCode')" [width]="getWidth('shiftCode')" [hidden]="!isVisible('shiftCode')" title="Shift Code"
                     media="sm" field="shiftCode" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Shift Code</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.shiftCode }}
    </ng-template>
  </kendo-grid-column>
  <!--
  <kendo-grid-column-group title="Totals">
    -->
  <kendo-grid-column [width]="getWidth('workedHours')" [hidden]="!isVisible('workedHours')" title="Worked Hours" media="sm"
                     field="workedHours" class="numeric-cell" footerClass="numeric-cell" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Worked Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.workedHours| number: ".2-2"}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['workedHours']?.sum | number: ".2-2"}}</strong>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{aggregates['workedHours'].sum| number: ".2-2"}}</div>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column [width]="getWidth('totalHours')" [hidden]="!isVisible('totalHours')" title="Total Hours" media="sm"
                     field="totalHours" class="numeric-cell" footerClass="numeric-cell" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Total Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.totalHours| number: ".2-2"}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['totalHours']?.sum | number: ".2-2"}}</strong>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{aggregates['totalHours'].sum| number: ".2-2"}}</div>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column [width]="getWidth('regularPay')" [hidden]="!isVisible('regularPay')" title="Regular Pay" media="sm" field="regularPay"
                     class="numeric-cell" footerClass="numeric-cell" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Regular Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.regularPay| number: ".2-2"| slxMoney}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['regularPay']?.sum | number: ".2-2"| slxMoney}}</strong>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{aggregates['regularPay'].sum| number: ".2-2"| slxMoney}}</div>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('overtimeAndExtra')" [hidden]="!isVisible('overtimeAndExtra')" title="Overtime and Extra pay"
                     media="sm" field="overtimeAndExtra" class="numeric-cell" footerClass="numeric-cell" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Overtime and Extra pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.overtimeAndExtra| number: ".2-2"| slxMoney}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['overtimeAndExtra']?.sum | number: ".2-2"| slxMoney}}</strong>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{aggregates['overtimeAndExtra'].sum | number: ".2-2"| slxMoney}}</div>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('totalPay')" [hidden]="!isVisible('totalPay')" title="Total" media="sm" field="totalPay"
                     class="numeric-cell" footerClass="numeric-cell" headerClass="height-cell-60">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Total Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.totalPay| number: ".2-2"| slxMoney}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['totalPay']?.sum | number: ".2-2"| slxMoney}}</strong>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['totalPay'].sum | number: ".2-2"| slxMoney }}</div>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>

  </kendo-grid-column>
  <!--
  </kendo-grid-column-group>
  -->

  <div *kendoGridDetailTemplate="let dataItem">
    <slx-individual-timecards-detail-grid [state]="state" [day]="dataItem"></slx-individual-timecards-detail-grid>
  </div>
  <kendo-grid-excel fileName="Timecards.xlsx">
  </kendo-grid-excel>
  <kendo-grid-pdf fileName="Timecards.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>
</kendo-grid>

import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, ViewEncapsulation, OnInit, OnDestroy} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridOptions, CellEvent, Column, GridApi } from 'ag-grid-community';
import { Aca1095cGridHelper } from './aca-1095-c-grid-helper';
import { Aca1095c,acaQuickPopupState, Aca1095cRecord, EmployeeFilterRecord,aca1095CPopupAction,Aca1095CPopupInitData, AcaMonthRecord, EmployeeIdList, AcaInsightFilter, EmployeeFilter, Aca1095cActions} from '../../../models';
import { Aca1095cManagementService } from '../../../services';
import { Subscription } from 'rxjs';
import { unsubscribe, unsubscribeAll } from '../../../../core/decorators';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { EmployeeSectionNavigationService } from '../../../../common';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { Aca1095cSettings } from '../../../models/aca-1095-c/aca-1095-c-settings';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { ACA1095cConfig } from '../../../aca-1095.config';

@Component({
  selector: 'slx-aca-1095-c-grid',
  templateUrl: './aca-1095-c-grid.component.html',
  styleUrls: ['./aca-1095-c-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class Aca1095cGridComponent implements OnInit, OnDestroy, ICellRendererAngularComp{
  public gridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public getRowHeight;
  public records: Aca1095cRecord[];
  public storedRecords: Aca1095cRecord[];
  public params: any;
  public appConfig: IApplicationConfig;
  public rowData: any[];
  public rowCount: string;
  public footerData: any[];
  public columnToExport: string[];
  public gridScrollElement: HTMLElement;
  public gridHelper: Aca1095cGridHelper;
  public insightsFilter: AcaInsightFilter;
  public selectedEmployees: EmployeeFilterRecord[];
  public columnList: string[] = [];
  public actions: Aca1095cActions;
  public selectedCellData:AcaMonthRecord;
  public quickEditSelectedData:AcaMonthRecord;
 
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  public quickPopupState:string;
  public popupState:string;

  constructor(private managementService: Aca1095cManagementService, private router: Router, private route: ActivatedRoute) {
    this.gridHelper = new Aca1095cGridHelper();
    this.gridHelper.state = {
      year: 2020,
      numMonths: 12,
      organizationColShown: false,
      departmentColShown: false,
      positionColShown: false,
      dateHiredColShown: false,
      dateTerminatedColShown: false,
     
      benefitDeclinedColShown: false,
      purchaseMarketplaceColShown: false
   
    };
    this.gridOptions = <GridOptions>{};
    this.gridOptions.context = {
      thisComponent: this
    };
    this.getRowHeight = function (params) {
      if (params.data.expand) {
        return 100;
      }
      else {
        return 28;
      }
    };
  }
  refresh(): boolean {
    return false;
  }
  agInit(params: any): void {
    this.appConfig = appConfig;
    this.params = params;
  }

  public ngOnInit() {
    this.gridHelper.monthLinkArray();
    this.gridHelper.createColumnDefs();

    this.subscriptions.onLoaded = this.managementService
      .subscribeToLoadedRecords((container: Aca1095c) => {
        this.records = container.records;
        this.gridHelper.monthLinkArray();
        if (!this.actions) {
          this.actions=new  Aca1095cActions();
          this.actions = container.actions;                        
        }
        this.storedRecords = _.clone(container.records);      
        this.refreshGrid();
      });
      
    this.subscriptions.employeeFilter = this.managementService
      .subscribeToEmpFilterRecords((employeeFilter: EmployeeFilterRecord[]) => this.applyFilter(employeeFilter));
    
    this.subscriptions.quickEditSelectedData = this.managementService
      .subscribeToQuickEditSelectedData((selectedData: AcaMonthRecord) => {
        this.quickEditSelectedData=selectedData;
      });
    
    this.subscriptions.undoQuickRecords = this.managementService
      .subscribeToUndoQuickRecord((item: Aca1095cRecord) => {
        this.undoQuickRecords(item)
      });

    this.subscriptions.onLoaded = this.managementService
      .subscribeToEmployeeFilter((records: EmployeeFilter) => {
        this.applyFilter(records.filter)       
      });
    
    this.subscriptions.export = this.managementService
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.subscriptions.expand = this.managementService
      .subscribeToExpandAll((isExpand: boolean) => this.expandAll(isExpand));

    this.subscriptions.quickPopupState = this.managementService
      .subscribeToQuickPopupState((quickPopupState: string) =>{
        this.quickPopupState=quickPopupState;
        switch(quickPopupState) { 
          case acaQuickPopupState.quickEditSave: 
          case acaQuickPopupState.quickConfirmSave: 
          case acaQuickPopupState.quickEditResetSave: 
          case acaQuickPopupState.quickConfirmResetSave: 
          { 
            let records:Aca1095cRecord[]=this.getQuickRecordForSave(quickPopupState);
             this.managementService.submitQuick1095cRecords(records,quickPopupState);
             break; 
          } 
          case acaQuickPopupState.quickEditStart:
          case acaQuickPopupState.quickEditResetStart: 
          case acaQuickPopupState.quickConfirmStart:
          case acaQuickPopupState.quickConfirmResetStart:
          {              
             break; 
          }            
          case acaQuickPopupState.quickEditClear:
          case acaQuickPopupState.quickEditClearAndClose:
          case acaQuickPopupState.quickEditResetClear:
          case acaQuickPopupState.quickEditResetClearAndClose:
          case acaQuickPopupState.quickConfirmClear:
          case acaQuickPopupState.quickConfirmClearAndClose:
          case acaQuickPopupState.quickConfirmResetClear:
          case acaQuickPopupState.quickConfirmResetClearAndClose:          
          {         
            this.clearQuick1095cRecords(quickPopupState);
            break;
          }
          case acaQuickPopupState.quickConfirmClose:
          case acaQuickPopupState.quickEditClose:
          {
            this.updateMonthLinkState("All",false);
            break;
          }
       }

    });
    
    this.subscriptions.quickMonthAction = this.managementService
      .subscribeToQuickMonthAction((monthAction: any) => {
      if(this.getIsQuickPopupActive())
      {
        let selectedMonth: AcaMonthRecord = null;
        let confirmRecordCount:number = 0;
        let notConfirmRecordCount:number = 0;
        let recordHaveNoCoverage:number = 0;
        let recordNotEdited:number=0;
        const monthName=monthAction.name;
        const isLink=monthAction.value;
        
        if(this.gridApi)
        {
        this.gridApi.forEachNode(node => {
          let monthsRecords: AcaMonthRecord[] = node.data.months;
     
            _.forEach(monthsRecords,(selectedMonth:AcaMonthRecord)=> 
            {  
              if((selectedMonth.monthName==monthName)||monthName=='All')
              {
              confirmRecordCount +=(selectedMonth.confirmedInd)?1:0;              
              recordHaveNoCoverage +=(!selectedMonth.selectedCode && !selectedMonth.suggestedCode)?1:0;            
              notConfirmRecordCount +=(!selectedMonth.confirmedInd)?1:0;
              recordNotEdited +=(!selectedMonth.confirmedInd && (!selectedMonth.selectedCode && !selectedMonth.selectedHarbor && !selectedMonth.selectedCost))?1:0;            
              }
            });

          });
        }
        if(isLink)
        {
          if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditStart)&&confirmRecordCount>0)
          {
            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit__record_already_confirmed"]);
            return;
          }
          else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditResetStart)&&confirmRecordCount>0)
          {
            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__record_already_confirmed"]);
            return;
          }
          else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditResetStart)&&recordNotEdited>0)
          {
            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__not_edited"]);
            return;
          }
          else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmStart)&&recordHaveNoCoverage>0)
          {
            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__line14_blank"]);
            return;
          }
          else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmStart)&&confirmRecordCount>0)
          {
            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__record_already_confirmed"]);
            return;
          }
          else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmResetStart)&& notConfirmRecordCount>0)
          {
            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm_reset__record_not_confirmed"]);
            return;
          }
        }
        this.updateMonthLinkState(monthName,isLink);
        if(this.gridApi)
        {          
          this.gridApi.forEachNode(node => {
            let nodeData:Aca1095cRecord=_.clone(node.data);
            let monthsRecords: AcaMonthRecord[] = nodeData.months;
            if(monthName=='All')
            {
              node.data.link=isLink;
            }
            this.updateMonthRecords(isLink,monthName,monthsRecords);              
          });
          this.gridHelper.linkMonths[monthName]=isLink;
          this.gridApi.expandAll();

        }
      }
      });     
  }
  public getIsQuickPopupActive()
  {
    let quickPopupState:string=this.quickPopupState;
    let popupState:boolean=false;
    if(_.isEqual(quickPopupState,acaQuickPopupState.quickEditStart))
    {
      popupState=true;
    }
    else if(_.isEqual(quickPopupState,acaQuickPopupState.quickEditResetStart))
    {
      popupState=true;
    }
    else if(_.isEqual(quickPopupState,acaQuickPopupState.quickConfirmStart))
    {
      popupState=true;
    }
    else if(_.isEqual(quickPopupState,acaQuickPopupState.quickConfirmResetStart))
    {
      popupState=true;
    }
    else
    {
      popupState=false;
    }
    return popupState;
  }
  private undoQuickRecords(item:Aca1095cRecord):void
  {
    if(this.gridApi)
    {
      this.gridApi.forEachNode(node => {
        if(_.isEqual(node.data.companyId,item.companyId)&&_.isEqual(node.data.employeeMasterId,item.employeeMasterId))
        {
          if(item.months.length==12)
          {
            node.data.link=false;
          }
          let monthsRecords: AcaMonthRecord[] = node.data.months as AcaMonthRecord[];
          _.forEach(monthsRecords,(month:AcaMonthRecord)=> 
            {  
            if(_.filter(item.months,(undoMonth:AcaMonthRecord)=> (_.isEqual(undoMonth.monthName,month.monthName))).length>0)
            {
              month.quickConfirm=false;
              month.quickConfirmReset=false;              
              month.quickEditReset=false;              
              this.undoQuickEdit(month);              
            }
            });
        }
      });
      if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditStart))
      {
        this.setQuickEditRecord();
      }
      else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditResetStart))
      {
        this.setQuickEditResetRecord();
      }
      else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmStart))
      {
        this.setQuickConfirmRecord();
      }
      else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmResetStart))
      {
        this.setQuickConfirmResetRecord();
      }
      this.gridApi.expandAll();  
    }

  }
  private updateMonthRecords(isLink:boolean,monthName:string,monthsRecords:AcaMonthRecord[]):void{
  
    
    _.forEach(monthsRecords,(selectedMonth:AcaMonthRecord)=> 
    {  
      if((selectedMonth.monthName==monthName)||monthName=='All')
      {
        if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditStart))
        {
          
          if(!isLink)
          {                
            this.undoQuickEdit(selectedMonth);
          }
          else
          {                
            this.applyQuickEdit(selectedMonth);                
          }
                  
          this.setQuickEditRecord();        
        }           
        else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditResetStart))
        {
          if( !isLink)
          {
            selectedMonth.quickEditReset=false;
          }
          else
          {
            selectedMonth.quickEditReset=true;
          }      
            
          this.setQuickEditResetRecord();
        }
        else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmStart))
        {
          if( !isLink)
          {
            selectedMonth.quickConfirm=false;
          }
          else
          {
            selectedMonth.quickConfirm=true;
          }  
          
          this.setQuickConfirmRecord();
        }
        else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmResetStart))
        {
          if( !isLink)
          {
            selectedMonth.quickConfirmReset=false;
          }
          else
          {
            selectedMonth.quickConfirmReset=true;
          } 
          
          this.setQuickConfirmResetRecord();
        }
      }
    });  
  }
  private updateMonthLinkState(monthName:string,isLink:boolean):void
  {
    if(monthName=='All')
    {
      for (let month: number = 0; month < 12; month++) {
        let strMonth = moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM');
        this.gridHelper.linkMonths[strMonth]=isLink;  
        this.managementService.setQuickMonthColumnHeaderAction({name:strMonth,value:isLink}); 
      }  
    }
    this.managementService.setQuickMonthColumnHeaderAction({name:monthName,value:isLink}); 
  }
  public clearGridChangedRecords(quickPopuState:string):void
  {
    if (this.gridApi) {
    this.gridApi.forEachNode(node => {

      let monthsRecords: AcaMonthRecord[] = node.data.months as AcaMonthRecord[];
           if(node.data.link)
           {
            node.data.link=false;
           }
        _.forEach(monthsRecords,(month:AcaMonthRecord)=> 
          {      
                  
            let selectedMonth: AcaMonthRecord = month;    
            switch(quickPopuState) {      
              case acaQuickPopupState.quickEditClearAndClose:
              case acaQuickPopupState.quickEditClear:
              { 
                if(selectedMonth.quickEdit)
                {              
                  this.undoQuickEdit(selectedMonth);                  
                }   
                break;  
              } 
              case acaQuickPopupState.quickEditResetClearAndClose:
              case acaQuickPopupState.quickEditResetClear:
              {
                if(selectedMonth.quickEditReset)
                {   
                  selectedMonth.quickEditReset=false;
                }    
                break;  
              }
              case acaQuickPopupState.quickConfirmClearAndClose:
              case acaQuickPopupState.quickConfirmClear:
              {
                if(selectedMonth.quickConfirm)
                {   
                  selectedMonth.quickConfirm=false;
                }   
                break;   
              }
              case acaQuickPopupState.quickConfirmResetClearAndClose:
              case acaQuickPopupState.quickConfirmResetClear:
              {
                if(selectedMonth.quickConfirmReset)
                {   
                  selectedMonth.quickConfirmReset=false;
                }    
                break;  
              }
              default: { 
                 //statements; 
                 break; 
              }       
            }
          });
    });
    this.gridApi.expandAll();
  }
  }
  private  clearQuick1095cRecords(quickPopupState: string):void
  {
    switch(quickPopupState) {      
      case acaQuickPopupState.quickEditClearAndClose: { 
        this.clearGridChangedRecords(quickPopupState);
        this.setQuickEditRecord();
        this.managementService.setQuickPopupState(acaQuickPopupState.quickEditClose);
        break; 
      }       
      case acaQuickPopupState.quickEditResetClearAndClose:{
        this.clearGridChangedRecords(quickPopupState);  
        this.setQuickEditResetRecord();
        this.managementService.setQuickPopupState(acaQuickPopupState.quickEditClose);      
        break; 
      }
      case acaQuickPopupState.quickEditClear:{
        this.clearGridChangedRecords(quickPopupState);  
        this.setQuickEditRecord();
        this.managementService.setQuickPopupState(acaQuickPopupState.quickEditResetActive);      
        break; 
      }
      case acaQuickPopupState.quickEditResetClear:{
        this.clearGridChangedRecords(quickPopupState);  
        this.setQuickEditResetRecord();
        this.managementService.setQuickPopupState(acaQuickPopupState.quickEditActive);      
        break; 
      }
      case acaQuickPopupState.quickConfirmClearAndClose: { 
        this.clearGridChangedRecords(quickPopupState);  
        this.setQuickConfirmRecord();
        this.managementService.setQuickPopupState(acaQuickPopupState.quickConfirmClose);
        break; 
      } 
      case acaQuickPopupState.quickConfirmResetClearAndClose: { 
        this.clearGridChangedRecords(quickPopupState);  
        this.setQuickConfirmResetRecord();
        this.managementService.setQuickPopupState(acaQuickPopupState.quickConfirmClose);
        break; 
      } 
      case acaQuickPopupState.quickConfirmClear: { 
        this.clearGridChangedRecords(quickPopupState);  
        this.setQuickConfirmRecord();
        this.managementService.setQuickPopupState(acaQuickPopupState.quickConfirmResetActive);
        break; 
      }       
      case acaQuickPopupState.quickConfirmResetClear: { 
        this.clearGridChangedRecords(quickPopupState);  
        this.setQuickConfirmResetRecord();
        this.managementService.setQuickPopupState(acaQuickPopupState.quickConfirmActive);
        break; 
      } 
      default: { 
         //statements; 
         break; 
      } 
   } 
   
    
  }
  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }
  private applyFilter(employeeFilter: EmployeeFilterRecord[]): void {
    this.selectedEmployees = employeeFilter;
    this.refreshGrid();
  }
 
  private refreshGrid(): void {
    let records: any = _.clone(this.storedRecords);
    
    if (this.selectedEmployees && this.selectedEmployees.length > 0) {
      this.records = _.filter(records, (r: Aca1095cRecord) => {
        let res = false;
        _.forEach(this.selectedEmployees, (employee: EmployeeFilterRecord) => {
          res = res || _.isEqual(r.employeeId, employee.id);
        });
        return res;
      });
    }
    else {
      this.records = records;
    }
    
    if (this.records) {
      this.rowData = this.records;
    }
    
  }

  public ngAfterViewInit(): void {
    this.subscriptions.settings = this.managementService.onSettingsChanged$.subscribe((settings: Aca1095cSettings) => {
      this.gridHelper.state.organizationColShown = settings.columns.columnsMap['organization'] ? settings.columns.columnsMap['organization'].displayed : false;
      this.gridHelper.state.departmentColShown = settings.columns.columnsMap['department']?settings.columns.columnsMap['department'].displayed : false;
      this.gridHelper.state.positionColShown = settings.columns.columnsMap['position']?settings.columns.columnsMap['position'].displayed : false;
      this.gridHelper.state.dateHiredColShown = settings.columns.columnsMap['hireDate']?settings.columns.columnsMap['hireDate'].displayed : false;
      this.gridHelper.state.dateTerminatedColShown = settings.columns.columnsMap['terminationDate']?settings.columns.columnsMap['terminationDate'].displayed : false;
      this.gridHelper.state.benefitDeclinedColShown = settings.columns.columnsMap['benefitDeclined']? settings.columns.columnsMap['benefitDeclined'].displayed : false;
      this.gridHelper.state.purchaseMarketplaceColShown = settings.columns.columnsMap['purchasedMarketplace']?settings.columns.columnsMap['purchasedMarketplace'].displayed : false;
      this.setColumnsState('organization', this.gridHelper.state.organizationColShown);
      this.setColumnsState('department', this.gridHelper.state.departmentColShown);
      this.setColumnsState('position', this.gridHelper.state.positionColShown);
      this.setColumnsState('hireDate', this.gridHelper.state.dateHiredColShown);
      this.setColumnsState('terminationDate', this.gridHelper.state.dateTerminatedColShown);
      this.setColumnsState('benefitDeclined', this.gridHelper.state.benefitDeclinedColShown);
      this.setColumnsState('purchasedMarketplace', this.gridHelper.state.purchaseMarketplaceColShown);

      this.columnList = [];
      this.columnList.push('employeeName');
      if (this.gridHelper.state.dateHiredColShown) {
        this.columnList.push('hireDate');
      }
      if (this.gridHelper.state.organizationColShown) {
        this.columnList.push('organization');
      }
      if (this.gridHelper.state.departmentColShown) {
        this.columnList.push('department');
      }
      if (this.gridHelper.state.positionColShown) {
        this.columnList.push('position');
      }
      if (this.gridHelper.state.dateTerminatedColShown) {
        this.columnList.push('terminationDate');
      }
     
      if (this.gridHelper.state.benefitDeclinedColShown) {
        this.columnList.push('benefitDeclined');
      }
      if (this.gridHelper.state.purchaseMarketplaceColShown) {
        this.columnList.push('purchasedMarketplace');
      }      

    });

  }
  private setColumnsState(fieldName: string, isColShown): void {
    if (this.gridOptions.columnApi) {      
      this.gridOptions.columnApi.setColumnVisible(fieldName, isColShown);
    }
  }
   
  public exportTo(isPdf: boolean): void {
    let monthColumnIds: any[] = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    let columnkey=_.unionBy(this.columnList, monthColumnIds);

    let params: any = {
      skipHeader: false,
      columnGroups: false,
      skipFooters: false,
      skipGroups: false,
      skipFloatingTop: false,
      skipFloatingBottom: false,
      allColumns: false,
      onlySelected: false,
      suppressQuotes: false,
      columnKeys: columnkey,
      fileName: 'ACA_1095-C_Reporting_Export.xlsx',
      columnSeparator: ',',
      processHeaderCallback: (params: any) => {
 
          if (params.column && params.column.colDef) {
            let val: string = params.column.colDef.headerName;
            if (val && val.indexOf('main-date-header-text') !== -1) {
              return val.replace(/<[^>]*>/g, '');
            }

            return val;
          }

      },
      processCellCallback: (params: any) => {
        let colId = params.column.getColDef().field;
        let zipValue:string="";

        if (colId === 'hireDate' || colId === 'terminationDate') {
          return moment(params.value).format(appConfig.dateFormat);
        } else if (monthColumnIds.indexOf(colId) > -1) {
          let monthsRecords: AcaMonthRecord[] = params.node.data.months as AcaMonthRecord[];
          let selectedMonth: AcaMonthRecord = null;
          let monthName = colId;
          let selectedMonthRecords: any[] = _.filter(monthsRecords, (monthsRecord) => {
            if (monthsRecord.monthName == monthName) {
              return monthsRecord;
            }
          });
          if (selectedMonthRecords.length > 0) {
            selectedMonth = selectedMonthRecords[0];
          }
          if (selectedMonth)
          {
            if(_.includes(ACA1095cConfig.line14ValueForLine17,selectedMonth.displayCode))
            {
            zipValue='Zip: '+selectedMonth.selZip;
            }
            return selectedMonth.displayCode + ' ' + selectedMonth.displayCost + ' ' + selectedMonth.displayHarbor + '\n' + selectedMonth.displayOption + '\n' + selectedMonth.displayTier+ '\n' + zipValue;
          }
          else
            return;
        }
        else {
          return params.value;
        }
        
      }
    };

    this.gridOptions.api.exportDataAsExcel(params);
  }
  public expandAll(isExpand: boolean): void {
    _.forEach(this.records, function (item) {
      if (isExpand) {      
        item.expand = true;
      }
      else
      {     
        item.expand = false;
      }
    });
    this.managementService.setEmployeeGridExpandCollapse(isExpand);
    if (this.gridApi) {
      this.gridApi.expandAll();
      this.gridApi.resetRowHeights();
    }
  }
 
  private setExpandedList(state: boolean,empId: number): void {
    let employee: EmployeeIdList = new EmployeeIdList();
    employee.employeeId = empId;
    employee.expand = state;
    this.managementService.setEmployeeIdList(employee);
  }
  
  public getQuickRecordForSave(popupState:string):Aca1095cRecord[]
  {
    let record:Aca1095cRecord[]=[];
    this.gridApi.forEachNode(node => {
      let nodeData:Aca1095cRecord=_.clone(node.data);
      let monthsRecords: AcaMonthRecord[] = nodeData.months;
      let selectedMonthRecords:AcaMonthRecord[] =[];
        switch(popupState) { 
          case acaQuickPopupState.quickEditSave: 
          {
            selectedMonthRecords=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.quickEdit));
            break;
          }
          case acaQuickPopupState.quickConfirmSave: 
          {
            selectedMonthRecords=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.quickConfirm));
            break;
          }
          case acaQuickPopupState.quickEditResetSave: 
          {
            selectedMonthRecords=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.quickEditReset));
            break;
          }
          case acaQuickPopupState.quickConfirmResetSave: 
          { 
            selectedMonthRecords=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.quickConfirmReset));
            break;
          }       
        } 
      
      if(selectedMonthRecords.length>0)
      {
        nodeData.months=selectedMonthRecords;
        selectedMonthRecords=[];
        record.push(nodeData);
      }          
    });
    return record; 
  }
  public setQuickEditRecord():void
  {
    let record:Aca1095cRecord[]=[];
    this.gridApi.forEachNode(node => {
      let nodeData:Aca1095cRecord=_.clone(node.data);
      let monthsRecords: AcaMonthRecord[] = nodeData.months;
      let EditMonthRecords:AcaMonthRecord[] =[];
      EditMonthRecords=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.quickEdit));
        if(EditMonthRecords.length>0)
        {
          nodeData.months=EditMonthRecords;
          EditMonthRecords=[];
          record.push(nodeData);
        }          
    });
    this.managementService.setQuickEditRecords(record);    
  }
  public setQuickEditResetRecord():void
  {
    let record:Aca1095cRecord[]=[];
    this.gridApi.forEachNode(node => {
      let nodeData:Aca1095cRecord=_.clone(node.data);
      let monthsRecords: AcaMonthRecord[] = nodeData.months;
      let resetMonthRecords:AcaMonthRecord[] =[];
      resetMonthRecords=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.quickEditReset));
        if(resetMonthRecords.length>0)
        {
          nodeData.months=resetMonthRecords;
          resetMonthRecords=[];
          record.push(nodeData);
        }
          
    });
    this.managementService.setQuickEditResetRecords(record);    
  }
  public setQuickConfirmRecord():void
  {
    let record:Aca1095cRecord[]=[];
    this.gridApi.forEachNode(node => {
      let nodeData:Aca1095cRecord=_.clone(node.data);
      let monthsRecords: AcaMonthRecord[] = nodeData.months;
      let confirmMonthRecords:AcaMonthRecord[] =[];
      confirmMonthRecords=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.quickConfirm));
        if(confirmMonthRecords.length>0)
        {
          nodeData.months=confirmMonthRecords;
          confirmMonthRecords=[];
          record.push(nodeData);
        }
          
    });
    this.managementService.setQuickConfirmRecords(record);    
  }
  public setQuickConfirmResetRecord():void
  {
    let record:Aca1095cRecord[]=[];
    this.gridApi.forEachNode(node => {
      let nodeData:Aca1095cRecord=_.clone(node.data);
      let monthsRecords: AcaMonthRecord[] = nodeData.months;
      let confirmResetMonthRecords:AcaMonthRecord[] =[];
      confirmResetMonthRecords=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.quickConfirmReset));
        if(confirmResetMonthRecords.length>0)
        {
          nodeData.months=confirmResetMonthRecords;
          confirmResetMonthRecords=[];
          record.push(nodeData);
        }
          
    });
    this.managementService.setQuickConfirmResetRecords(record);    
  }
  public onCellDoubleClick($event: CellEvent): void {
    let selectedYear=this.managementService.getSelectedYear();
    const isEditableYear = ACA1095cConfig.isEditableYear(selectedYear);
    if(isEditableYear)
    {
      let monthArray:string[]=[];
      for (let month: number = 0; month < 12; month++) {
        monthArray.push(moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM'));
      }
      if( _.filter(monthArray,(month:string)=>(_.isEqual(month, $event.column.getColDef().field))).length>0) 
      {
        let monthsRecords: AcaMonthRecord[] = $event.node.data.months as AcaMonthRecord[];
        let selectedMonth: AcaMonthRecord = null;
        let monthName = $event.column.getColDef().field;
        let selectedMonthRecords: any[] = _.filter(monthsRecords, (monthsRecord) => {
          if (monthsRecord.monthName == monthName) {
            return monthsRecord;
          }
        });
        if (selectedMonthRecords.length > 0) {
          selectedMonth = selectedMonthRecords[0];
        }
        if(!selectedMonth.confirmedInd)
        {
        this.selectedCellData=selectedMonth;     
        let acaPopupInitData:Aca1095CPopupInitData=new Aca1095CPopupInitData();
        acaPopupInitData=new Aca1095CPopupInitData();
        acaPopupInitData.popupAction=aca1095CPopupAction.edit1095Record;
        acaPopupInitData.params=this.selectedCellData;
        acaPopupInitData.companyId=$event.node.data.companyId;
        acaPopupInitData.employeeId=$event.node.data.employeeId
        acaPopupInitData.employeeMasterId=$event.node.data.employeeMasterId
        this.managementService.init1095Popup(acaPopupInitData);
        }
      }
    }
  }
  public applyQuickEdit(selectedMonth: AcaMonthRecord):void
  {
    if(!selectedMonth.quickEdit)
    {
      selectedMonth.edited_selectedHarbor=selectedMonth.selectedHarbor;
      selectedMonth.edited_selectedCode=selectedMonth.selectedCode;
      selectedMonth.edited_selectedCost=selectedMonth.selectedCost;
      selectedMonth.edited_enrolledInd=selectedMonth.enrolledInd;
      selectedMonth.edited_reason=selectedMonth.reason;

      selectedMonth.selectedHarbor=this.quickEditSelectedData.selectedHarbor;
      selectedMonth.selectedCode=this.quickEditSelectedData.selectedCode;
      selectedMonth.selectedCost=this.quickEditSelectedData.selectedCost;
      selectedMonth.enrolledInd=this.quickEditSelectedData.enrolledInd;
      selectedMonth.reason=this.quickEditSelectedData.reason;
      selectedMonth.quickEdit=true;
    }
  }
  public undoQuickEdit(selectedMonth: AcaMonthRecord):void
  {
    if(selectedMonth.quickEdit)
    {
      selectedMonth.selectedHarbor=selectedMonth.edited_selectedHarbor;
      selectedMonth.selectedCode=selectedMonth.edited_selectedCode;
      selectedMonth.selectedCost=selectedMonth.edited_selectedCost;
      selectedMonth.enrolledInd=selectedMonth.edited_enrolledInd;
      selectedMonth.reason=selectedMonth.edited_reason;

      selectedMonth.edited_selectedCode="";
      selectedMonth.edited_selectedCost="";
      selectedMonth.edited_selectedHarbor="";
      selectedMonth.edited_reason="";
      selectedMonth.edited_enrolledInd=false;
      selectedMonth.quickEdit=false;
    }
  }
  public onCellClicked($event: CellEvent): void {
    let pageNumber: number = this.gridApi.paginationGetCurrentPage();
    if ($event.column.getColDef().field === 'employeeName') {
      let empId: string = $event.node.data.employeeId;
      if (empId !== null && empId !== undefined)
        if (parseInt(empId) === 0) {
          return;
        }
      const parsedEmpId = parseInt(empId, 10);
      let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);

      const urlTree = navService.getUrlTreeFromRoot(parsedEmpId, true);
      const extras: NavigationExtras = {
        skipLocationChange: false,
        replaceUrl: false,
      };
      this.router.navigateByUrl(urlTree, extras);
    }
    else if ($event.column.getColDef().field === 'expand') {
      if ($event.node.data.expand == false) {
        $event.node.data.expand = true;
        this.setExpandedList(true, $event.node.data.employeeId);
      }
      else
      {
        $event.node.data.expand = false;
        this.setExpandedList(false, $event.node.data.employeeId);
      }
      if (this.gridApi) {
        this.gridApi.expandAll();
        this.gridApi.resetRowHeights();
      }
    }
    else if($event.column.getColDef().field === 'link')
    { 
      let monthsRecords: AcaMonthRecord[] = $event.node.data.months as AcaMonthRecord[];     
      if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditStart))
      {
        if($event.node.data.link)
        {          
          _.forEach(monthsRecords,(month:AcaMonthRecord)=> 
            {                       
              let selectedMonth: AcaMonthRecord = month;          
              if(selectedMonth.quickEdit)
              {                
                this.undoQuickEdit(selectedMonth);                
              }            
            });
          $event.node.data.link=false;
          this.setQuickEditRecord();          
        }
        else
        {
          const acaMonthRecords:AcaMonthRecord[]=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.confirmedInd));
          if(acaMonthRecords.length==0)
          {
            _.forEach(monthsRecords,(month:AcaMonthRecord)=> 
            {                        
              let selectedMonth: AcaMonthRecord = month;     
              if(!selectedMonth.quickEdit)
              {
                this.applyQuickEdit(selectedMonth);            
              }
            });
            $event.node.data.link=true;
            this.setQuickEditRecord();
          }
          else
          {
            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit__record_already_confirmed"]);
          }         
        }       
      }
      if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditResetStart))
      {
        if($event.node.data.link)
        {          
          _.forEach(monthsRecords,(month:AcaMonthRecord)=> 
            {     
                    
              let selectedMonth: AcaMonthRecord = month;          
              if(selectedMonth.quickEditReset)
              {
                selectedMonth.quickEditReset=false;                
              }            
            });
            $event.node.data.link=false;
            this.setQuickEditResetRecord();
          
        }
        else
        {
          const acaMonthRecords:AcaMonthRecord[]=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.confirmedInd));
          const acaMonthNotEditedRecords:AcaMonthRecord[]=_.filter(monthsRecords,(month:AcaMonthRecord)=> (!month.confirmedInd && (!month.selectedCode && !month.selectedHarbor && !month.selectedCost)));
          
          if(acaMonthRecords.length==0 && acaMonthNotEditedRecords.length==0)
          {
            _.forEach(monthsRecords,(month:AcaMonthRecord)=> 
            {      
                    
              let selectedMonth: AcaMonthRecord = month;     
              if(!selectedMonth.quickEditReset)
              {             
                selectedMonth.quickEditReset=true;            
              }
            });
            $event.node.data.link=true;
            this.setQuickEditResetRecord();
          }
          else
          {
            if(acaMonthRecords.length>0)
            {
              this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__record_already_confirmed"]);
            }
            else if(acaMonthNotEditedRecords.length>0)
            {
              this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__not_edited"]);
            }
            
          }
        }   
        
      }
      else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmStart))
      {
        if($event.node.data.link)
        {
          
          _.forEach(monthsRecords,(month:AcaMonthRecord)=> 
            {      
                    
              let selectedMonth: AcaMonthRecord = month;          
              if(selectedMonth.quickConfirm)
              {
                selectedMonth.quickConfirm=false;                
              }            
            });
            $event.node.data.link=false;
            this.setQuickConfirmRecord();
          
        }
        else
        {
          const acaMonthRecords:AcaMonthRecord[]=_.filter(monthsRecords,(month:AcaMonthRecord)=> (!month.selectedCode && !month.suggestedCode));
          const acaMonthConfirmRecords:AcaMonthRecord[]=_.filter(monthsRecords,(month:AcaMonthRecord)=> (month.confirmedInd));
          
          if(acaMonthRecords.length==0 && acaMonthConfirmRecords.length==0)
          {
            _.forEach(monthsRecords,(month:AcaMonthRecord)=> 
            {      
                    
              let selectedMonth: AcaMonthRecord = month;     
              if(!selectedMonth.quickConfirm)
              {             
                selectedMonth.quickConfirm=true;            
              }
            });
            $event.node.data.link=true;
            this.setQuickConfirmRecord();
          }        
          else{            
            if(acaMonthRecords.length>0)
            {
              this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__line14_blank"]);
            }
            else if(acaMonthConfirmRecords.length>0)
            {
              this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__record_already_confirmed"]);
            }            
          }
        }   
        
      }
      else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmResetStart))
      {
        if($event.node.data.link)
        {
          
          _.forEach(monthsRecords,(month:AcaMonthRecord)=> 
            {      
                    
              let selectedMonth: AcaMonthRecord = month;          
              if(selectedMonth.quickConfirmReset)
              {
                selectedMonth.quickConfirmReset=false;                
              }            
            });
            $event.node.data.link=false;
            this.setQuickConfirmResetRecord();
          
        }
        else
        {
          const acaMonthRecords:AcaMonthRecord[]=_.filter(monthsRecords,(month:AcaMonthRecord)=> (!month.confirmedInd));
          if(acaMonthRecords.length==0)
          {
            _.forEach(monthsRecords,(month:AcaMonthRecord)=> 
            {      
                    
              let selectedMonth: AcaMonthRecord = month;     
              if(!selectedMonth.quickConfirmReset)
              {             
                selectedMonth.quickConfirmReset=true;            
              }
            });
            $event.node.data.link=true;    
            this.setQuickConfirmResetRecord();  
          }
          else{
            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm_reset__record_not_confirmed"]);
          }

        }   
       
      }
        if (this.gridApi) {
          this.gridApi.expandAll();          
        }   
    }
    else
    {
      
      let monthArray:string[]=[];
      for (let month: number = 0; month < 12; month++) {
        monthArray.push(moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM'));
      }
      if( _.filter(monthArray,(month:string)=>(_.isEqual(month, $event.column.getColDef().field))).length>0) 
      {      
        let monthsRecords: AcaMonthRecord[] = $event.node.data.months as AcaMonthRecord[];
        let selectedMonth: AcaMonthRecord = null;
        let monthName = $event.column.getColDef().field;
        let selectedMonthRecords: any[] = _.filter(monthsRecords, (monthsRecord) => {
          if (monthsRecord.monthName == monthName) {
            return monthsRecord;
          }
        });
        if (selectedMonthRecords.length > 0) {
          selectedMonth = selectedMonthRecords[0];
        }
        if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditStart))
        {
           
          if(selectedMonth.quickEdit)
          {            
            this.undoQuickEdit(selectedMonth);
            this.gridApi.expandAll();          
            this.setQuickEditRecord(); 
          }
          else
          {
            if(!selectedMonth.confirmedInd)
            {
              this.applyQuickEdit(selectedMonth);
              this.gridApi.expandAll();          
              this.setQuickEditRecord(); 
            }
            else
            {
              this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit__record_already_confirmed"]);
            }
            
          }
                 
        }           
        else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickEditResetStart))
        {
          if(selectedMonth.quickEditReset)
          {
            selectedMonth.quickEditReset=false;
            this.gridApi.expandAll();     
            this.setQuickEditResetRecord();
          }
          else
          {
            const acaMonthNotEditedRecords:AcaMonthRecord[]=_.filter(monthsRecords,(month:AcaMonthRecord)=> (!month.confirmedInd && (!month.selectedCode && !month.selectedHarbor && !month.selectedCost)));
         
            if(!selectedMonth.confirmedInd && (selectedMonth.selectedCode || selectedMonth.selectedHarbor || selectedMonth.selectedCost))
            {
            selectedMonth.quickEditReset=true;
            this.gridApi.expandAll();     
            this.setQuickEditResetRecord();
            }
            else
            {
              if(selectedMonth.confirmedInd )
              {
                this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__record_already_confirmed"]);
              }
              else if(!selectedMonth.confirmedInd &&(!selectedMonth.selectedCode && !selectedMonth.selectedCost && !selectedMonth.selectedHarbor))
              {
                this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__not_edited"]);
              }
              
            }
          }      
          
        }
        else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmStart))
        {
          if((selectedMonth.suggestedCode || selectedMonth.selectedCode) && !selectedMonth.confirmedInd)
          {
            if(selectedMonth.quickConfirm)
            {
              selectedMonth.quickConfirm=false;
            }
            else
            {
              selectedMonth.quickConfirm=true;
            }  
            this.gridApi.expandAll();   
            this.setQuickConfirmRecord();
          }
          else
          {
            if(!selectedMonth.suggestedCode && !selectedMonth.selectedCode)
            {
              this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__line14_blank"]);
            }
            else if(selectedMonth.confirmedInd)
            {
              this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__record_already_confirmed"]);
            }            
          }
        }
        else if(_.isEqual(this.quickPopupState,acaQuickPopupState.quickConfirmResetStart))
        {
          if(selectedMonth.confirmedInd)
          {
            if(selectedMonth.quickConfirmReset)
            {
              selectedMonth.quickConfirmReset=false;
            }
            else
            {
              selectedMonth.quickConfirmReset=true;
            } 
            this.gridApi.expandAll();  
            this.setQuickConfirmResetRecord();
          }
          else
          {
            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm_reset__record_not_confirmed"]);
          }
        }
        else
        {
          this.selectedCellData=selectedMonth;         
          this.managementService.setQuickEditInitData(this.selectedCellData);
        }
      }
    }
    
  }  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }  

  public getContextMenuItems(params) {
    var result:any[] =[];
    let selectedYear=params.context.thisComponent.managementService.getSelectedYear();
    const isEditableYear = ACA1095cConfig.isEditableYear(selectedYear);
    if (params.context.thisComponent.actions.addEmployee && isEditableYear) {    
      result.push(
        {         
          name: 'Add Employee',
          action: function () {
          params.context.thisComponent.managementService.isAddRemoveEmployee();          
          }
        }

      );
    }
    if(params.context.thisComponent.actions.deleteEmployee && isEditableYear)
    {
      result.push(
        {
          name: 'Remove Employee',
          action: function () {
          params.context.thisComponent.managementService.isAddRemoveEmployee(params.node.data);
          
          }
        }

      );
    }
    if(params.context.thisComponent.actions.edit1095c && isEditableYear)
    {
      result.push(
        {
          name: 'Quick Edit',
          action: function () {
            let acaPopupInitData:Aca1095CPopupInitData=new Aca1095CPopupInitData();
            acaPopupInitData=new Aca1095CPopupInitData();
            acaPopupInitData.popupAction=aca1095CPopupAction.quickEditRecord;
           
            let monthsRecords: AcaMonthRecord[] = params.node.data.months as AcaMonthRecord[];
            let selectedMonth: AcaMonthRecord = null;
            let monthName = params.column.getColDef().field;
            let selectedMonthRecords: any[] = _.filter(monthsRecords, (monthsRecord) => {
              if (monthsRecord.monthName == monthName) {
                return monthsRecord;
              }
            });
            if (selectedMonthRecords.length > 0) {
              selectedMonth = selectedMonthRecords[0];
            }
            
            acaPopupInitData.params=selectedMonth;
            params.context.thisComponent.managementService.init1095Popup(acaPopupInitData);            
          }
        }
      );
    }  
    return result;
  }
 
}

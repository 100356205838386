<form novalidate #gridForm="ngForm">
  <kendo-grid #kendoGrid scrollable="'scrollable'" [data]="gridState.view" (excelExport)="onExcelExportPayCycle($event)" [sortable]="{ mode: 'multiple' }"
    [sort]="gridState.state.sort" [pageable]="true" [pageSize]="pageSize" [skip]="gridState.state.skip"
    [groupable]="false" [slxKendoGridState]="gridState.gridDefinition" [filterable]="false"
    (dataStateChange)="dataStateChange($event)" [filter]="gridState.state.filter" (stateRestored)="dataStateChange($event)">
    <ng-template kendoGridToolbarTemplate position="top">
      <div class="col-xs-12 p-0">
        <form novalidate class="col-xs-6 p-0">
          <slx-date-range-ngx class="col-xs-12 p-0" name="startEndDate" [startDate]="startDate" [endDate]="endDate"
            (rangeDateChanged)="onFilterDateChanged($event)"></slx-date-range-ngx>
        </form>
        <button type="button" class="assign-button pull-right" *ngIf="canEdit && isEditable" [disabled]="!hasActiveState" (click)="onStartModify()">
          <span class="text-button-content">Assign Payroll Cycle</span>
        </button>
        <span [class.active]="employeeActionToolBar" *ngIf="true"
          class="paycycles-toolbar__control as-flex-start mt-16 pr-1 pull-right">
          <slx-action-list>
            <slx-action-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">
              Export</slx-action-button>
            <popper-content #popperContent>
              <slx-action-list-item (onClick)="onClickExportPayCycle(false)">Export to Excel</slx-action-list-item>
              <slx-action-list-item (onClick)="onClickExportPayCycle(true)">Export to PDF</slx-action-list-item>
            </popper-content>
          </slx-action-list>
        </span>
    </div>
    </ng-template>
    <kendo-grid-column title="" [sortable]="false" field="isSelected" media="sm" width="40"
      headerClass="overflow-visible-cell">
      <ng-template kendoGridHeaderTemplate>
        <div>
          <i class="fas fa-calendar-alt" slxTooltip="Individual Timecard Link" tipPosition="bottom"
            aria-hidden="true"></i>
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [individualTimecardsLink]="employeeId" [startDate]="dataItem.startDate" [endDate]="dataItem.endDate">
          <i class="fas fa-calendar-alt" aria-hidden="true"></i>
        </a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="startDate" width="200">
      <ng-template kendoGridHeaderTemplate>
        Start Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [individualTimecardsLink]="employeeId" [startDate]="dataItem.startDate" [endDate]="dataItem.endDate">
          {{dataItem.startDate | amDateFormat: appConfig.dateFormat}}
        </a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="endDate" width="200">
      <ng-template kendoGridHeaderTemplate>
        End Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [individualTimecardsLink]="employeeId" [startDate]="dataItem.startDate" [endDate]="dataItem.endDate">
          {{dataItem.endDate | amDateFormat: appConfig.dateFormat}}
        </a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="approved" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Approved
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.approved | boolyn }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="locked" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Locked
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.locked | boolyn }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="regular" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Regular
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.regular | number: ".2-2" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="overtime" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Overtime
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.overtime | number: ".2-2" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="other" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Other
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.other | number: ".2-2" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="absences" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Absences
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.absences | number: ".2-2" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-excel [fileName]="getPayCycleFileName(false)" [fetchData]="retriveAllPages()">
      <kendo-excelexport-column-group [title]="getPayCycleTitle()" [headerCellOptions]="{ textAlign:'center' }">
        <kendo-excelexport-column-group [title]="getPayCycleEmployeeInfo()" [headerCellOptions]="{ textAlign: 'center' }">
          <kendo-excelexport-column title="Start Date" field="startDateFormatForExcel" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
            <ng-template kendoExcelExportFooterTemplate>Created on</ng-template>
          </kendo-excelexport-column>
          <kendo-excelexport-column title="End Date" field="endDateFormatForExcel" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
            <ng-template kendoExcelExportFooterTemplate>{{createdDateTime}}</ng-template>
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Approved" field="approved" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }"></kendo-excelexport-column>
          <kendo-excelexport-column title="Locked" field="locked" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }"></kendo-excelexport-column>
          <kendo-excelexport-column title="Regular" field="regular" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ format: '0.00', textAlign: 'left' }"></kendo-excelexport-column>
          <kendo-excelexport-column title="Overtime" field="overtime" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ format: '0.00', textAlign: 'left' }"></kendo-excelexport-column>
          <kendo-excelexport-column title="Other" field="other" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ format: '0.00', textAlign: 'left' }"></kendo-excelexport-column>
          <kendo-excelexport-column title="Absences" field="absences" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ format: '0.00', textAlign: 'left' }"></kendo-excelexport-column>
        </kendo-excelexport-column-group>
      </kendo-excelexport-column-group>
    </kendo-grid-excel>
    <kendo-grid-pdf [fileName]="getPayCycleFileName(true)" 
          [allPages]="pdfTemplate.allPages" 
          [landscape]="pdfTemplate.landscape" 
          [paperSize]="pdfTemplate.paperSize"
          [margin]="pdfTemplate.margin" 
          [scale]="pdfTemplate.scale" 
          [repeatHeaders]="pdfTemplate.repeatHeaders">
      <kendo-grid-pdf-margin 
          [top]="pdfTemplate.marginTop" 
          [bottom]="pdfTemplate.marginBottom" 
          [right]="pdfTemplate.marginRight" 
          [left]="pdfTemplate.marginLeft">
      </kendo-grid-pdf-margin>
      <ng-template kendoGridPDFTemplate>
        <div class="pdf-page-template">
          <div class="pdf-header">
            <div class="pdf-title">{{getPayCycleTitle()}}</div>
            <div class="pdf-title1">
              <span class="pdf-text-container">{{employeeName}}</span>
              <span class="pdf-text-container">{{employeePosition}}</span>
              <span class="pdf-text-container">{{employeeType}}</span>
              <span class="pdf-text-container">{{employeePayRollNumber}}</span>
            </div>
          </div>
          <div class="pdf-footer">
            <div class="pdf-footer-title">Created on : {{createdDateTime}}</div>
          </div>
        </div>
      </ng-template>
      <kendo-grid-column field="startDate" width="200">
        <ng-template kendoGridHeaderTemplate>
          Start Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <a [individualTimecardsLink]="employeeId" [startDate]="dataItem.startDate" [endDate]="dataItem.endDate">
            {{dataItem.startDate | amDateFormat: appConfig.dateFormat}}
          </a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="endDate" width="200">
        <ng-template kendoGridHeaderTemplate>
          End Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <a [individualTimecardsLink]="employeeId" [startDate]="dataItem.startDate" [endDate]="dataItem.endDate">
            {{dataItem.endDate | amDateFormat: appConfig.dateFormat}}
          </a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="approved" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Approved
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.approved | boolyn }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="locked" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Locked
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.locked | boolyn }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="regular" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Regular
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.regular | number: ".2-2" }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="overtime" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Overtime
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.overtime | number: ".2-2" }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="other" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Other
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.other | number: ".2-2" }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="absences" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Absences
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.absences | number: ".2-2" }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-pdf>
  </kendo-grid>
</form>
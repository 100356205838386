import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ReplaySubject, Subject ,  Subscription } from 'rxjs';
import { Assert } from '../../../framework';
import { NotificationsApiService } from './notifications-api.service';
import { IconAccess, MessageNotificationModel, IMessageNotificationModel } from '../../models/index';
import { UnreadMessageCount, IUnreadMessageCount, IMessageList } from '../../../app-modules/message-center/models';

@Injectable()
export class MessageNotificationService {
  private iconAccess$ = new ReplaySubject(1);
  private unreadSmsCount$ = new Subject<UnreadMessageCount>();
  private unreadArchiveCount$ = new Subject<UnreadMessageCount>();
  private messageStatusUpdate$ = new Subject<any>();

  constructor(private apiService: NotificationsApiService) { }

  public subscribeToIconAccess(callback: (v) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.iconAccess$.subscribe(callback);
  }

  public async checkIconAcess(orgLevelId: number): Promise<any> {
    this.apiService.getIconAccessCheck(orgLevelId).then((access: IconAccess) => {
      this.iconAccess$.next(access);
    });
  }

  public async getMessages(): Promise<any> {
    this.apiService.getMessages();
    // .then((messages : MessageNotification ) => {
    //   this.setMessages(messages);
    //   this.setUnreadMessagesCount(messages);
    // });
  }

  public subscribeToUnreadSmsCount(callback: (v: IUnreadMessageCount) => void): Subscription {
    Assert.isNotNull(callback, "callback");
    return this.unreadSmsCount$.subscribe(callback);
  }

  public getUnreadSmsCount(orgLevelId: number, isMyMessage: boolean): void {
    this.apiService.getUnreadCount(orgLevelId, isMyMessage).then((res: IUnreadMessageCount) => {
      this.unreadSmsCount$.next(res);
    });
  }

  public subscribeToUnreadArchiveCount(callback: (v: IUnreadMessageCount) => void): Subscription {
    Assert.isNotNull(callback, "callback");
    return this.unreadArchiveCount$.subscribe(callback);
  }

  public getUnreadArchiveCount(orgLevelId: number, isMyMessage: boolean): void {
    this.apiService.getArchiveCount(orgLevelId, isMyMessage).then((res: IUnreadMessageCount) => {
      this.unreadArchiveCount$.next(res);
    });
  }

  public subscribeToMessageStatusUpdate(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, "callback");
    return this.messageStatusUpdate$.subscribe(callback);
  }

  public getMessageStatusUpdate(rowData: IMessageList) {
    this.apiService.getMessageStatusUpdate(rowData.employeeId).then((res: MessageNotificationModel) => {
      this.messageStatusUpdate$.next(rowData);
    });
  }

}

import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { Subject ,  Subscription ,  ReplaySubject } from 'rxjs';
import { ManagementBaseService } from '../../../../core/services/index';
import { EmpType, Organization, Department, UserAction } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';

import { PbjManualEntryApiService } from './pbj-manual-entry-api.service';
import { PBJManualTimeEntity, PBJManualTimeEntry } from '../../models/index';
import { RangeDates, IRangeDates } from '../../../../common/models/range-dates';
import { AccessibleApiService, OrgLevelWatchService } from '../../../../organization/services/index';
import { Assert } from '../../../../framework/index';

@Injectable()
export class PbjManualEntryManagementService extends ManagementBaseService<PBJManualTimeEntity, any> {
  public onSavedEntry$: ReplaySubject<PBJManualTimeEntry>;
  public onAddedEntry$: ReplaySubject<PBJManualTimeEntry>;
  public onRemovedEntries$: ReplaySubject<number[]>;
  public onEntriesSelected$: ReplaySubject<PBJManualTimeEntry[]>;
  public onEditState$: ReplaySubject<boolean>;
  public onAddEntry$: ReplaySubject<PBJManualTimeEntry>;
  public onOrgLevel$: ReplaySubject<OrgLevel>;
  public onFilterDates$: ReplaySubject<RangeDates>;
  public exportToPdf$: Subject<void> = new Subject<void>();
  public exportToExcel$: Subject<void> = new Subject<void>();
  private apiService: PbjManualEntryApiService;
  private organizations: Organization[];
  private orgLevel: OrgLevel;
  private startDate: Date;
  private endDate: Date;
  private userActonsChanged$ = new ReplaySubject<UserAction[]>(1);

  constructor(apiService: PbjManualEntryApiService,
    private accessibleApiService: AccessibleApiService) {
    super();
    this.apiService = apiService;
    this.onSavedEntry$ = new ReplaySubject<PBJManualTimeEntry>(1);
    this.onAddedEntry$ = new ReplaySubject<PBJManualTimeEntry>(1);
    this.onRemovedEntries$ = new ReplaySubject<number[]>(1);
    this.onEntriesSelected$ = new ReplaySubject<PBJManualTimeEntry[]>(1);
    this.onEditState$ = new ReplaySubject<boolean>(1);
    this.onAddEntry$ = new ReplaySubject<PBJManualTimeEntry>(1);
    this.onOrgLevel$ = new ReplaySubject<OrgLevel>(1);
    this.onFilterDates$ = new ReplaySubject<RangeDates>(1);
  }

  public addEntry(entry: PBJManualTimeEntry): void {
    this.onLoadStatusChanged(true);
    this.apiService.addPBJManualEntry(this.orgLevel.id, entry)
      .then((entry: PBJManualTimeEntry) => {
        this.onLoadStatusChanged(false);
        this.onAddedEntry$.next(entry);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public saveEntry(entry: PBJManualTimeEntry): void {
    this.onLoadStatusChanged(true);
    this.apiService.savePBJManualEntry(this.orgLevel.id, entry)
      .then((entry: PBJManualTimeEntry) => {
        this.onLoadStatusChanged(false);
        this.onSavedEntry$.next(entry);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public removeEntries(entriesIds: number[]): void {
    this.onLoadStatusChanged(true);
    this.apiService.removePBJManualEntries(entriesIds, this.orgLevel.id)
      .then(() => {
        this.onLoadStatusChanged(false);
        this.onRemovedEntries$.next(entriesIds);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public addEntryToRow(entry: PBJManualTimeEntry): void {
    this.onAddEntry$.next(entry);
  }

  public editStateChanged(isCanEdit: boolean): void {
    this.onEditState$.next(isCanEdit);
  }

  public orgLevelChanged(orgLevel: OrgLevel): void {
    this.orgLevel = orgLevel;
    this.onOrgLevel$.next(orgLevel);
    this.checkRequiredData();
    this.loadUserActions(orgLevel.id);
  }

  public subscribeToUserActionsChanged(callback: (v: UserAction[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.userActonsChanged$.subscribe(callback);
  }
  

  public loadUserActions(orgLevelId: number): void {
    this.accessibleApiService.getUserActions(orgLevelId, ['Export to Excel'])
      .then((data: UserAction[]) => {
        this.userActonsChanged$.next(data);
      });
  }

  public dateFilterChanged(startDate: Date, endDate: Date): void {
    if (_.isDate(startDate) && _.isDate(endDate)) {
      const rangeDates = new RangeDates();
      rangeDates.startDate = this.startDate = startDate;
      rangeDates.endDate = this.endDate = endDate;
      this.onFilterDates$.next(rangeDates);

      this.checkRequiredData();
    }
  }

  public loadEntries(): void {
    this.onLoadStatusChanged(true);
    this.apiService.getPBJManualEntries(this.orgLevel.id, this.startDate, this.endDate)
      .then((val: PBJManualTimeEntity) => {
        this.onLoadStatusChanged(false);
        this.onLoaded(val);
      }).catch((reason: any) => {
        this.onError(reason);
      });
  }

  public cloneEntry(entry: PBJManualTimeEntry): void {
    const newEntry = this.createPBJManualEntry();
    newEntry.employee = entry.employee;
    newEntry.center = entry.center;
    newEntry.department = entry.department;
    newEntry.position = entry.position;
    newEntry.hours = entry.hours;
    this.addEntryToRow(newEntry);
  }

  public createPBJManualEntry(): PBJManualTimeEntry {
    let entry: PBJManualTimeEntry = new PBJManualTimeEntry();
    entry.addedByUsername = '';
    entry.addedDate = null;
    entry.center = null;
    entry.date = null;
    entry.department = null;
    entry.employee = null;
    entry.entryType = '';
    entry.hours = null;
    entry.position = null;

    return entry;
  }

  private checkRequiredData(): void {
    if (this.orgLevel && _.isDate(this.startDate) && _.isDate(this.endDate)) {
      this.loadEntries();
    }
  }

  public exportToPdf(): void {
    this.exportToPdf$.next();
  }

  public exportToExcel(): void {
    this.exportToExcel$.next();
  }

}

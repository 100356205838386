<slx-spinner [show]="isLoading">
    <div class="slx-high-box workers-compensation-configuration">

      <div class="slx-high-box__body">
          <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
          [data]="gridState.view"
          [skip]="gridState.state.skip"
          [sortable]="true"
          [sort]="gridState.state.sort"
          [filterable]="'menu'"
          [filter]="gridState.state.filter"
          [pageable]="isPageable"
          [pageSize]="pageSize"
          [skip]="gridState.state.skip"
          (dataStateChange)="gridState.dataStateChange($event)"

          [slxKendoGridFiltersState]="gridState"
          >

            <kendo-grid-column title="Plan" width="200" field="name"
               filter="text" [sortable]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Plan</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a (click)="onClickBenefit(dataItem)"><strong>{{ dataItem.name }}</strong></a>
              </ng-template>
            </kendo-grid-column>


            <kendo-grid-column title="Group" width="100"
               field="benefitGroupName" filter="text" [sortable]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Group</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.benefitGroupName }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Updated On" width="150" field="filterModifiedAt" filter="date"
              [sortable]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Updated On</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.modifiedAt | amDateFormat: appConfig.dateTimeFormat }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Updated by" width="100" field="modifiedBy"
               filter="text" [sortable]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Updated by</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.modifiedBy }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-messages filterMenuTitle="Filter">
            </kendo-grid-messages>  
          </kendo-grid>

      </div>
    </div>
  </slx-spinner>

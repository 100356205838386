<div>
  <form novalidate #gridForm="ngForm">
    <kendo-grid #kendoGrid scrollable="'scrollable'" [data]="gridState.view"
      (dataStateChange)="gridState.dataStateChange($event)" [sortable]="false" [sort]="gridState.state.sort"
      [pageable]="false" [groupable]="false" [selectable]="false" (selectionChange)="gridState.selectionChange($event)"
      [slxKendoGridState]="gridState.gridDefinition" (stateRestored)="gridState.dataStateChange($event)"
      [filterable]="false" [filter]="gridState.state.filter" (edit)="gridState.editHandler($event)"
      (save)="gridState.saveHandler($event)" (cancel)="gridState.cancelHandler($event)"
      (remove)="gridState.removeHandler($event)">
      <ng-template kendoGridToolbarTemplate position="top">
        <div class="col-xs-12" *ngIf="employeeSectionsBenefitClasses?.actions.canEdit && isEditable">
          <div class="row">
            <button class="theme-iconed-accent-button pull-right" (click)="onStartAdd()">
              <span class="icon-button-content">
                <span>Add</span>
                <i class="fa fa-plus" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>
      </ng-template>

      <kendo-grid-column title="" [sortable]="false" [filterable]="false" width="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex">
            <button kendoGridRemoveCommand type="button" class="slx-button slx-only-icon slx-toolbar"
              *ngIf="employeeSectionsBenefitClasses?.actions.canEdit && isEditable"
              [disabled]="addMode"
              >
              <i class="fas fa-times" aria-hidden="true"></i>
            </button>
            <button kendoGridEditCommand type="button" class="slx-button slx-only-icon slx-toolbar"
              *ngIf="employeeSectionsBenefitClasses?.actions.canEdit && isEditable"
              [disabled]="addMode"
              >
              <i class="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
            <button kendoGridSaveCommand type="button" class="slx-button slx-only-icon slx-toolbar slx-margin-r"
              [disabled]="!gridForm.valid || !dataItem.benefitClassId">
              <i class="far fa-check-circle" aria-hidden="true"></i>
            </button>
            <button kendoGridCancelCommand type="button" class="slx-button slx-only-icon slx-toolbar">
              <i class="fa fa-ban" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="benefitClass.name" title="BenefitClass" width="150">
        <ng-template kendoGridHeaderTemplate>
          Benefit Class
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.benefitClass?.name }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator>
            <slx-dropdown-input slx-input [options]="benefitClassDefinitionsLookup" valueField="id" titleField="name"
              [ngModel]="dataItem.benefitClass"
              name="benefitClassInput{{rowIndex}}" [required]="true"
              (ngModelChange)="benefitClassChanged(dataItem, $event)"
              placeholder="Benefit Class">
            </slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="benefitClass.description" title="Benefit Class Description" width="200"
        editable="false">
        <ng-template kendoGridHeaderTemplate>
          Benefit Class Description
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.benefitClass?.description }}
        </ng-template>

      </kendo-grid-column>

      <kendo-grid-column field="startDate" title="Start Date" width="130" editable="false">
        <ng-template kendoGridHeaderTemplate>
          Start Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.startDate | amDateFormat: appConfig.dateFormat }}
        </ng-template>

        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-datepicker #startDate="ngModel"
            class="slx-wide"
            [(ngModel)]="dataItem.startDate"
            [minDate]="minDate"
            [maxDate]="dataItem.endDate"
            [slxMinDate]="minDate"
            [slxMaxDate]="dataItem.endDate"
            placeholder="Start Date"
            name="startDate{{rowIndex}}"
            [acceptNullDate]="false"
            required
          ></slx-datepicker>
          <div *ngIf="startDate.errors" class="slx-error-block">
            <span *ngIf="startDate.errors.required" errorMessage for="required"></span>
            <span *ngIf="startDate.errors.date" errorMessage for="date"></span>
            <span *ngIf="startDate.errors.minDate" errorMessage for="minDate">The value should be lower than {{ minDate | amDateFormat: appConfig.dateFormat }}</span>
            <span *ngIf="startDate.errors.maxDate" errorMessage for="maxDate">The value should be greater than {{ dataItem.endDate | amDateFormat: appConfig.dateFormat }}</span>
          </div>
        </ng-template>

      </kendo-grid-column>

      <kendo-grid-column field="endDate" title="End Date" width="130" editable="false">
        <ng-template kendoGridHeaderTemplate>
          End Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span> {{ dataItem.endDate | amDateFormat: appConfig.dateFormat | slxHideMaxDate }} </span>
        </ng-template>

        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              <slx-datepicker #endDate="ngModel"
              class="slx-wide"
              [(ngModel)]="dataItem.endDate"
              [minDate]="dataItem.startDate"
              [maxDate]="maxDate"
              placeholder="End Date"
              name="endDate{{rowIndex}}"
              [acceptNullDate]="true"
              [constantValidation]="true"
              >
              </slx-datepicker>
              <div *ngIf="endDate.errors" class="slx-error-block">
                <span *ngIf="endDate.errors.date" errorMessage for="date"></span>
                <span *ngIf="endDate.errors.minDate" errorMessage for="minDate">The value should be lower than {{ dataItem.startDate | amDateFormat: appConfig.dateFormat }}</span>
                <span *ngIf="endDate.errors.maxDate" errorMessage for="maxDate">The value should be greater than {{ maxDate | amDateFormat: appConfig.dateFormat }}</span>
              </div>
      </ng-template>

      </kendo-grid-column>

      <kendo-grid-column field="updatedOn" title="Modified Date" width="200">
        <ng-template kendoGridHeaderTemplate>
          Modified Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.updatedOn | amDateFormat: appConfig.dateFormat }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="updatedBy" title="Modified By" width="200">
        <ng-template kendoGridHeaderTemplate>
          Modified By
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.updatedBy }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
</div>

import * as _ from 'lodash';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Params, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';

export abstract class BaseResolver<T> implements Resolve<T>  {

    constructor(protected router: Router) { }

    public abstract resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T> | Promise<T> | T;

    public createQueryParams(route: ActivatedRouteSnapshot, data?: any): Params {
        const params: Params = {};
        _.forIn(route.queryParams, (value: any, key: string) => {
            params[key] = value;
        });
        return params;
    }

    public navigateToPath(route: ActivatedRouteSnapshot, params: Params): void {
        const path = _.reduce(route.pathFromRoot, (result: string[], s: ActivatedRouteSnapshot) => {
            return result.concat(_.filter(_.map(s.url, (seg: UrlSegment) => seg.path), (str: string) => !!str));
        }, []);
        this.router.navigate(path, { queryParams: params });
    }
}

<kendo-grid *ngIf="dataLoaded"
  class="slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [group]="gridState.state.group"
  [filterable]="true"
  [filter]="gridState.state.filter"
  [groupable]="{ showFooter: true, enabled: true }"
  (dataStateChange)="gridState.dataStateChange($event)"
>
<!--
  <kendo-grid-column title="Work Date" field="workDate">
    <ng-template kendoGridHeaderTemplate>
      Work Date
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.workDate | amDateFormat: appConfig.dateFormat}}
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>
-->
  <kendo-grid-column title="Position Name" field="positionName" width="200" [locked]="true">
      <ng-template kendoGridHeaderTemplate>
        <span class="column-title-break">Position Name / Group</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.positionName}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Unit Name" field="unitName" width="150">
        <ng-template kendoGridHeaderTemplate>
          <span class="column-title-break">Unit Name</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.unitName}}
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
   </kendo-grid-column>

  <kendo-grid-column title="Shift Group Name" field="shiftGroupName" width="150">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Shift Group Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.shiftGroupName}}
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Shift Name" field="shiftName" width="150">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Shift Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.shiftName}}
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Required Count" class="numeric-cell" footerClass="numeric-cell" field="requiredCount" width="150">
      <ng-template kendoGridHeaderTemplate>
        <span class="column-title-break">Required Count</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.requiredCount| number: ".2-2"}}
      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
        <div class="numeric-group-cell">{{ aggregates['requiredCount']?.sum | number: ".2-2" }}</div>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-column>
        <strong>{{ total['requiredCount']?.sum | number: ".2-2" }}</strong>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Scheduled Count" class="numeric-cell" footerClass="numeric-cell" field="scheduledCount" width="150">
      <ng-template kendoGridHeaderTemplate>
        <span class="column-title-break">Scheduled Count</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.scheduledCount| number: ".2-2"}}
      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
        <div class="numeric-group-cell">{{ aggregates['scheduledCount']?.sum | number: ".2-2" }}</div>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-column>
        <strong>{{ total['scheduledCount']?.sum | number: ".2-2" }}</strong>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Differences Count" class="numeric-cell" footerClass="numeric-cell" field="differencesCount" width="150">
        <ng-template kendoGridHeaderTemplate>
          <span class="column-title-break">Open Shifts</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="slx-parameter-partial-label" *ngIf="displayPartial(dataItem)"><div><span>Partial</span></div></div>          
          {{dataItem.differencesCount| number: ".2-2"}}
        </ng-template>
        <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
          <div class="numeric-group-cell">{{ aggregates['differencesCount']?.sum | number: ".2-2" }}</div>
        </ng-template>
        <ng-template kendoGridFooterTemplate let-column>
          <strong>{{ total['differencesCount']?.sum | number: ".2-2" }}</strong>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-number-filter>
        </ng-template>
      </kendo-grid-column>

  <kendo-grid-column title="Required Hours" class="numeric-cell" footerClass="numeric-cell" field="requiredHours" width="150">
        <ng-template kendoGridHeaderTemplate>
            <span class="column-title-break">Required Hours</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.requiredHours| number: ".2-2"}}
        </ng-template>
        <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
          <div class="numeric-group-cell">{{ aggregates['requiredHours']?.sum | number: ".2-2" }}</div>
        </ng-template>
        <ng-template kendoGridFooterTemplate let-column>
          <strong>{{ total['requiredHours']?.sum | number: ".2-2" }}</strong>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-number-filter>
        </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Scheduled Hours" class="numeric-cell" footerClass="numeric-cell" field="scheduledHours" width="150">
      <ng-template kendoGridHeaderTemplate>
        <span class="column-title-break">Scheduled Hours</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [ngClass]="{'bold': displayPartial(dataItem)}">
          {{dataItem.scheduledHours | number: ".2-2"}}
        </span>
      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
        <div class="numeric-group-cell">{{ aggregates['scheduledHours']?.sum | number: ".2-2" }}</div>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-column>
        <strong>{{ total['scheduledHours']?.sum | number: ".2-2" }}</strong>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>
  </kendo-grid-column>



  <kendo-grid-column title="Difference Hours" class="numeric-cell" footerClass="numeric-cell" field="differencesHours" width="200">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Difference Hours</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.differencesHours| number: ".2-2"}}
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['differencesHours']?.sum | number: ".2-2" }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['differencesHours']?.sum | number: ".2-2" }}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

import { LicenseTypesApiService } from './license-types/license-types-api.service';
import { LicenseTypesMapService } from './license-types/license-types-map.service';
export * from './editableList/list-actions.service';

export * from './accessManagement/access-management.service';

export * from './units/units-api.service';
export * from './units/units-map.service';
export * from './units/units-configuration-management.service';

export * from './absences/absence-configuration-api.service';
export * from './absences/absence-configuration-map.service';

export * from './positions/positions-configuration-api.service';
export * from './positions/positions-configuration-map.service';
export * from './positions/positions-configuration-management.service';

export * from './employers/employers-configuration-api.service';
export * from './employers/employers-configuration-map.service';
export * from './employers/employers-configuration-management.service';

export * from './constraints/constraints-configuration-management.service';
export * from './constraints/constraints-configuration-api.service';
export * from './constraints/constraints-configuration-map.service';

export * from './shifts/shifts-api.service';
export * from './shifts/shifts-map.service';
export * from './shifts/shifts-configuration-management.service';

export * from './ideal-schedule/ideal-schedule.state.service';
export * from './ideal-schedule/ideal-schedule.helper.service';
export * from './ideal-schedule/ideal-schedule-toolbar.service';
export * from './ideal-schedule/ideal-schedule-map.service';
export * from './ideal-schedule/ideal-schedule-api.service';

export * from './timeclocks/timeclocks-api.service';
export * from './timeclocks/timeclocks-map.service';
export * from './timeclocks/temp-sensor-reading-api.service';
export * from './timeclocks/temp-sensor-reading-management.service';
export * from './timeclocks/temp-sensor-reading-map.service';

export * from './holidays/holidays-api.service';
export * from './holidays/holidays-map.service';
export * from './roles/roles-api.service';
export * from './roles/roles-map.service';
export * from './roles/roles-management.service';
export * from './roles/roles-definition-management.service';

export * from './agencies/agencies-api.service';
export * from './agencies/agencies-map.service';
export * from './agencies/agency-details-management.service';

export * from './users/user-roles-api.service';
export * from './users/user-roles-map.service';
export * from './users/user-roles-management.service';

export * from './users/user-profile-api.service';
export * from './users/user-profile-map.service';
export * from './users/user-profile-management.service';

export * from './agency-employee/agency-employee-api.service';
export * from './agency-employee/agency-employee-map.service';
export * from './agency-employee/agency-employee-validator-adapter';
export * from './attendance-points-config/attendance-points-config-api.service';
export * from './attendance-points-config/attendance-points-config-map.service';

export * from './users/user-validator-adapter';

export * from './pbj-organizations/pbj-organizations-map.service';
export * from './pbj-organizations/pbj-organizations-api.service';
export * from './pbj-organizations/ccn-number-validator-adapter';
export * from './pbj-organizations/pbj-organizations-configuration-management.service';

export * from './pay-codes/pay-codes-map.service';
export * from './pay-codes/pay-codes-api.service';
export * from './pay-codes/pay-codes-configuration-management.service';
export * from './pay-codes/pay-code-accrual-balance-navigation.service';

export * from './user-password-settings/user-password-settings-api.service';
export * from './user-password-settings/user-password-settings-map.service';

export * from './cost-centers/cost-centers-map.service';
export * from './cost-centers/cost-centers-api.service';
export * from './cost-centers/cost-centers-configuration-management.service';
export * from './geolocation/geolocation-management.service';
export * from './geolocation/geolocation-api.service';
export * from './geolocation/geolocation-map.service';
export * from './manage-groups/manage-groups-management.service';
export * from './manage-groups/manage-groups-api.service';
export * from './manage-groups/manage-groups-map.service';
export * from './aca-config/aca-manage-management.service';
export * from './aca-config/aca-map.service';
export * from './aca-config/aca-api.service';

export * from './favorites/favorites-api.service';
export * from './favorites/favorites-map.service';
export * from './favorites/favorites-management.service';

export * from './aca-measurement/aca-measurement-management.service';
export * from './aca-measurement/aca-measurement-api.service';
export * from './aca-measurement/aca-measurement-map.service';

export * from './aca-1095-c/aca-1095-c-management.service';
export * from './aca-1095-c/aca-1095-c-api.service';
export * from './aca-1095-c/aca-1095-c-map.service';
export * from './aca-c1095-audit/aca-c1095-audit-management.service';
export * from './aca-c1095-audit/aca-c1095-audit-api.service';
export * from './aca-c1095-audit/aca-c1095-audit-map.service';

export * from './integrations/partner-config-api.service';
export * from './integrations/partner-config-map.service';
export * from './integrations/partner-config-management.service';
export * from './notifications/notifications-management.service';
export * from './notifications/notifications-api.service';
export * from './notifications/notifications-map.service';


export * from './user-login-management/user-login-management-api.service';
export * from './user-login-management/user-login-management-map.service';

import { Provider } from '@angular/core';
import { UnitsApiService } from './units/units-api.service';
import { UnitsMapService } from './units/units-map.service';

import { AbsenceConfigurationMapService } from './absences/absence-configuration-map.service';
import { AbsenceConfigurationApiService } from './absences/absence-configuration-api.service';

import { PositionsConfigurationMapService } from './positions/positions-configuration-map.service';
import { PositionsConfigurationApiService } from './positions/positions-configuration-api.service';

import { EmployersConfigurationApiService } from './employers/employers-configuration-api.service';
import { EmployersConfigurationMapService } from './employers/employers-configuration-map.service';

import { ConstraintsConfigurationMapService } from './constraints/constraints-configuration-map.service';
import { ConstraintsConfigurationApiService } from './constraints/constraints-configuration-api.service';

import { ShiftsMapService } from './shifts/shifts-map.service';
import { ShiftsApiService } from './shifts/shifts-api.service';
import { TimeclocksMapService } from './timeclocks/timeclocks-map.service';
import { TimeclocksApiService } from './timeclocks/timeclocks-api.service';
import { RolesMapService } from './roles/roles-map.service';
import { RolesApiService } from './roles/roles-api.service';

import { IdealScheduleStateService } from './ideal-schedule/ideal-schedule.state.service';
import { IdealScheduleHelperService } from './ideal-schedule/ideal-schedule.helper.service';
import { IdealScheduleApiService } from './ideal-schedule/ideal-schedule-api.service';
import { IdealScheduleMapService } from './ideal-schedule/ideal-schedule-map.service';
import { IdealScheduleToolbarService } from './ideal-schedule/ideal-schedule-toolbar.service';
import { AgenciesApiService } from './agencies/agencies-api.service';
import { AgenciesMapService } from './agencies/agencies-map.service';

import { AgencyEmployeeApiService } from './agency-employee/agency-employee-api.service';
import { AgencyEmployeeMapService } from './agency-employee/agency-employee-map.service';
import { AgencyEmployeeValidatorAdapter } from './agency-employee/agency-employee-validator-adapter';

import { HolidaysApiService } from './holidays/holidays-api.service';
import { HolidaysMapService } from './holidays/holidays-map.service';

import { UserRolesMapService } from './users/user-roles-map.service';
import { UserRolesApiService } from './users/user-roles-api.service';

import { UserProfileApiService } from './users/user-profile-api.service';
import { UserProfileMapService } from './users/user-profile-map.service';
import { AttendancePointsConfigApiService } from './attendance-points-config/attendance-points-config-api.service';
import { AttendancePointsConfigMapService } from './attendance-points-config/attendance-points-config-map.service';

import { UserValidatorAdapter } from './users/user-validator-adapter';

import { PbjOrganizationsMapService } from './pbj-organizations/pbj-organizations-map.service';
import { PbjOrganizationsApiService } from './pbj-organizations/pbj-organizations-api.service';
import { CCNNumberValidatorAdapter } from './pbj-organizations/ccn-number-validator-adapter';

import { PayCodesMapService } from './pay-codes/pay-codes-map.service';
import { PayCodesApiService } from './pay-codes/pay-codes-api.service';

import { UserPasswordSettingsApiService } from './user-password-settings/user-password-settings-api.service';
import { UserPasswordSettingsMapService } from './user-password-settings/user-password-settings-map.service';
import { TelepunchLocationsApiService } from './telepunch-locations/telepunch-locations-api.service';
import { TelepunchLocationsMapService } from './telepunch-locations/telepunch-locations-map.service';

import { CostCentersMapService } from './cost-centers/cost-centers-map.service';
import { CostCentersApiService } from './cost-centers/cost-centers-api.service';
import { CostCentersConfigurationManagementService } from './cost-centers/cost-centers-configuration-management.service';

import { GeolocationManagementService } from './geolocation/geolocation-management.service';
import { GeolocationApiService } from './geolocation/geolocation-api.service';
import { GeolocationMapService } from './geolocation/geolocation-map.service';

import { ManageGroupsManagementService } from './manage-groups/manage-groups-management.service';
import { ManageGroupsApiService } from './manage-groups/manage-groups-api.service';
import { ManageGroupsMapService } from './manage-groups/manage-groups-map.service';

import { FavoriteApiService } from './favorites/favorites-api.service';
import { FavoritesMapService } from './favorites/favorites-map.service';
import { FavoriteManagementService } from './favorites/favorites-management.service';

import { AcaMeasurementManagementService } from './aca-measurement/aca-measurement-management.service';
import { AcaMeasurementApiService } from './aca-measurement/aca-measurement-api.service';
import { AcaMeasurementMapService } from './aca-measurement/aca-measurement-map.service';

import { ACAConfigurationManagementService } from './aca-config/aca-manage-management.service';
import { ACAConfigurationApiService } from './aca-config/aca-api.service';
import { ACAConfigurationMapService } from './aca-config/aca-map.service'
import { IpManagementService } from './ip-management/ip-management.service';
import { IpManagementApiService } from './ip-management/ip-management-api.service';
import { IpManagementMapService } from './ip-management/ip-management-map.service';
import { TempSensorReadingManagementService } from './timeclocks/temp-sensor-reading-management.service';
import { TempSensorReadingMapService } from './timeclocks/temp-sensor-reading-map.service';
import { TempSensorReadingApiService } from './timeclocks/temp-sensor-reading-api.service';

import { Aca1095cManagementService } from './aca-1095-c/aca-1095-c-management.service';
import { Aca1095cApiService } from './aca-1095-c/aca-1095-c-api.service';
import { Aca1095cMapService } from './aca-1095-c/aca-1095-c-map.service';
import { AcaC1095AuditManagementService } from './aca-c1095-audit/aca-c1095-audit-management.service';
import { AcaC1095AuditApiService } from './aca-c1095-audit/aca-c1095-audit-api.service';
import { AcaC1095AuditMapService } from './aca-c1095-audit/aca-c1095-audit-map.service';

import { EmployeeSmsManagementService } from './employee-sms-management/employee-sms-management.service';
import { EmployeeSmsManagementApiService } from './employee-sms-management/employee-sms-management-api.service';
import { EmployeeSmsManagementMapService } from './employee-sms-management/employee-sms-management-map.service';
import { NotificationsManagementService } from './notifications/notifications-management.service';
import { NotificationsApiService } from './notifications/notifications-api.service';
import { NotificationsMapService } from './notifications/notifications-map.service';

import { PartnerConfigMapService } from './integrations/partner-config-map.service';
import { PartnerConfigApiService } from './integrations/partner-config-api.service';
import { PartnerConfigManagementService } from './integrations/partner-config-management.service';

import { UserLoginManagementApiService } from './user-login-management/user-login-management-api.service';
import { UserLoginManagementMapService } from './user-login-management/user-login-management-map.service';
import { WfmSyncService } from './wfm/wfm-sync.service';
import { WFMSyncMapService } from './wfm/wfm-sync-map.service';

import { ShiftGroupApiService } from './shift-group/shift-group-api.service';
import { ShiftGroupMapService } from './shift-group/shift-group-map.service';
import { ShiftGroupManagementService } from './shift-group/shift-group-management.service';
import { FileUploadsApiService } from './file-uploads/file-uploads-api.service';
import { FileUploadsMapService } from './file-uploads/file-uploads-map.service';
import { FileUploadsManagementService } from './file-uploads/file-uploads-management.service';
import { DownloadDataService } from './file-uploads/download-data.service';
import { PayrollPayCodesApiService } from './payroll/payroll-pay-codes/payroll-pay-codes-api.service';
import { PayrollPayCodesManagementService } from './payroll/payroll-pay-codes/payroll-pay-codes-management.service';
import { PayrollPayCodesMapService } from './payroll/payroll-pay-codes/payroll-pay-codes-map.service';
import { PayCodeDetailsApiService } from './pay-code-details/pay-code-details-api.service';
import { PaycodeExceptionMapService } from './pay-code-details/paycode-exception-map.service';
import { RuleMapService } from './pay-code-details/rule-map.service';
import { AdditionalRequirementMapService } from './pay-code-details/additional-requirements-map.service';
import { RuleFormulaMapService } from './pay-code-details/rule-formula-map.service';
import { CopyPayCodesAPIService } from './copy-paycodes/copy-paycodes-api.service';
import { PayPoliciesAPIService } from './pay-policies/pay-policies-api.service';
import { PayPoliciesMapService } from './pay-policies/pay-policies-map.service';

export const services: Provider[] = [
  UnitsApiService, UnitsMapService,
  PositionsConfigurationMapService, PositionsConfigurationApiService,
  EmployersConfigurationMapService, EmployersConfigurationApiService,
  ConstraintsConfigurationMapService, ConstraintsConfigurationApiService,
  AbsenceConfigurationMapService, AbsenceConfigurationApiService,
  ShiftsMapService, ShiftsApiService,
  TimeclocksMapService,
  TimeclocksApiService,
  TempSensorReadingApiService,
  TempSensorReadingManagementService,
  TempSensorReadingMapService,
  RolesMapService,
  RolesApiService,
  ConstraintsConfigurationMapService,
  ShiftsMapService,
  IdealScheduleStateService, IdealScheduleHelperService, IdealScheduleApiService, IdealScheduleMapService, IdealScheduleToolbarService,
  HolidaysApiService, HolidaysMapService,
  AgenciesApiService, AgenciesMapService, AgencyEmployeeApiService, AgencyEmployeeMapService, AgencyEmployeeValidatorAdapter,
  UserRolesMapService, UserRolesApiService,
  UserProfileMapService, UserProfileApiService,
  AttendancePointsConfigApiService,
  AttendancePointsConfigMapService,
  UserValidatorAdapter,
  PbjOrganizationsMapService, PbjOrganizationsApiService, CCNNumberValidatorAdapter,
  PayCodesMapService, PayCodesApiService,
  PayCodeDetailsApiService, CopyPayCodesAPIService, PayPoliciesAPIService, PayPoliciesMapService, PaycodeExceptionMapService, RuleMapService, RuleFormulaMapService, AdditionalRequirementMapService,
  UserPasswordSettingsApiService, UserPasswordSettingsMapService,
  TelepunchLocationsApiService, TelepunchLocationsMapService,
  CostCentersMapService, CostCentersApiService, CostCentersConfigurationManagementService,
  GeolocationManagementService,
  GeolocationApiService,
  GeolocationMapService,
  ManageGroupsApiService,
  ManageGroupsMapService,
  FavoriteManagementService,
  FavoriteApiService,
  FavoritesMapService,
  AcaMeasurementManagementService,
  AcaMeasurementApiService,
  AcaMeasurementMapService,
  ACAConfigurationManagementService,
  ACAConfigurationApiService,
  ACAConfigurationMapService,
  IpManagementService,
  IpManagementApiService,
  IpManagementMapService,
  Aca1095cManagementService,
  Aca1095cApiService,
  Aca1095cMapService,
  AcaC1095AuditManagementService,
  AcaC1095AuditApiService,
  AcaC1095AuditMapService,
  EmployeeSmsManagementService,
  EmployeeSmsManagementApiService,
  EmployeeSmsManagementMapService,
  PartnerConfigMapService,
  PartnerConfigApiService,
  PartnerConfigManagementService,
  NotificationsMapService,
  NotificationsApiService,
  NotificationsManagementService,
  LicenseTypesMapService,
  LicenseTypesApiService,
  UserLoginManagementMapService,
  UserLoginManagementApiService,
  WfmSyncService,
  WFMSyncMapService,
  ShiftGroupApiService,
  ShiftGroupMapService,
  ShiftGroupManagementService,
  FileUploadsApiService,
  FileUploadsMapService,
  FileUploadsManagementService,
  DownloadDataService,
  PayrollPayCodesApiService,
  PayrollPayCodesManagementService,
  PayrollPayCodesMapService
];


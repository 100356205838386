import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MomentModule } from 'angular2-moment';
import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { NgxPopperModule } from 'ngx-popper';
import { ComponentsLibraryModule } from '../../components-library/index';
import {
  pbjReconciliationComponents, pbjExportComponents, pbjEntryComponents, pbjComponents,
  mealDeductionEntryComponents, mealDeductionComponents
} from './components/index';

import { pbjPipes } from './pipes/index';

import { services } from './services/index';
import { PbjExportGridComponent } from './components/pbj-export/pbj-export-grid/pbj-export-grid.component';
import { PbjExportGridParentComponent } from './components/pbj-export/pbj-export-grid-parent/pbj-export-grid-parent.component';
import { PbjExportGridChildComponent } from './components/pbj-export/pbj-export-grid-child/pbj-export-grid-child.component';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TreeviewModule } from 'ngx-treeview';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { PbjDuplicateWorkersComponent } from './components/pbj-duplicate-workers/pbj-duplicate-workers.component';
import { PbjDuplicateMatchesDialogComponent } from './components/pbj-duplicate-workers/pbj-duplicate-matches-dialog/pbj-duplicate-matches-dialog.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        MomentModule,
        GridModule,
        PDFModule, ExcelModule,
        DropDownsModule,
        ProgressBarModule,
        OrganizationModule,
        CommonAppModule,
        NgxPopperModule,
        ComponentsLibraryModule,
        TreeviewModule,
        ButtonsModule,
        TooltipModule
    ],
    declarations: [
        ...pbjComponents,
        ...pbjReconciliationComponents,
        ...mealDeductionComponents,
        ...pbjPipes,
        PbjExportGridComponent,
        PbjExportGridParentComponent,
        PbjExportGridChildComponent,
        PbjDuplicateWorkersComponent,
        PbjDuplicateMatchesDialogComponent
    ],
    providers: [
        ...services,
        DecimalPipe
    ],
    exports: [
        ...pbjExportComponents
    ]
})
export class PbjModule { }

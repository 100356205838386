<slx-spinner [show]="isLoading">
  <div class="slx-high-box leave-types-configuration">
    <div class="slx-content-toolbar-indents">
       <div class="leave-types-toolbar__left">
        <button *ngIf="canEdit" type="button" class="slx-button slx-blue slx-with-icon leave-type_add" (click)="addType()">
          <i aria-hidden="true" class="slx-button__icon fal fa-plus"></i>Add
        </button>
       </div>
       <slx-blue-columns-config-button [colGroupKey]="columnGroup" title="Leave Types Columns" ></slx-blue-columns-config-button>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
        <form #typesForm="ngForm" novalidate class="slx-full-height">
          <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
            slxKendoGridState="LM_TYPE_CONFIGURATION"
            [slxKendoGridColumnsGroup]="columnGroup"
            [data]="gridState?.view"
            [filterable]="'menu'"
            [filter]="gridState.state.filter"
            [sortable]="{ mode: 'multiple' }"
            [sort]="gridState.state.sort"
            [skip]="gridState.state.skip"
            [pageable]="true"
            [pageSize]="pageSize"
            (cancel)="gridState.cancelHandler($event)"
            (edit)="gridState.editHandler($event)"
            (remove)="gridState.removeHandler($event)"
            (save)="gridState.saveHandler($event)"
            (dataStateChange)="gridState.dataStateChange($event)">

            <kendo-grid-column class="slx-no-padding" [media]="screenUtils.getKendoMediaForMobile()">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="slx-mobile-expandable slx-blue">
                 <div><span class="slx-mobile-expandable__header">{{ dataItem.loaType?.description }}</span> <span class="slx-mobile-expandable__header-additional">({{dataItem.loaType?.name }})</span></div>
                 <slx-collapse-button className="slx-white-icon" [slxCollapseByCss]="leaveTypeCellContent"></slx-collapse-button>
                </div>
                <div #leaveTypeCellContent class="slx-mobile-expandable-content">
                  <div class="slx-mobile-expandable-content__buttons">
                    <button kendoGridEditCommand type="button" *ngIf="canEdit" class="slx-inline-edit-button2">
                      <i class="fas fa-edit slx-inline-edit-button__icon" aria-hidden="true"></i>
                    </button>
                    <button kendoGridRemoveCommand *ngIf="canEdit" class="slx-icon-button slx-inline-remove-button2">
                      <i class="fas fa-trash slx-inline-edit-button__icon" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div>
                    <div class="type-value">
                      <span class="type-value-header">Type:</span> {{ dataItem.loaType?.name }}
                    </div>
                    <div class="type-value">
                      <span class="type-value-header">Description:</span> {{ dataItem.loaType?.description }}
                    </div>
                    <div class="type-value">
                      <span class="type-value-header">Modified by:</span> {{ dataItem.modifiedBy }}
                    </div>
                    <div class="type-value">
                      <span class="type-value-header">Modified at:</span> {{ dataItem.modifiedAt | amDateFormat: appConfig.dateFormat  }}
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <div class="slx-mobile-expandable">
                    <div><span class="slx-mobile-expandable__header">{{ dataItem.loaType?.description }}</span> <span class="slx-mobile-expandable__header-additional">({{ dataItem.loaType?.name }})</span></div>
                    <slx-collapse-button className="slx-white-icon" [slxCollapseByCss]="paycodeEditCellContent"></slx-collapse-button>
                  </div>
                  <div #paycodeEditCellContent class="slx-mobile-expandable-content">
                    <div class="slx-mobile-expandable-content__buttons">
                      <button kendoGridSaveCommand type="button" [disabled]="!typesForm.valid" class="slx-icon-button slx-inline-apply-button2">
                        <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
                      </button>
                      <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button2">
                        <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div>
                      <slx-input-decorator>
                        <input slx-input required="true" name="name" placeholder="Name" [(ngModel)]="dataItem.loaType.name" slxMaxLength="50" slxMinLength="4"
                        [prohibitedValues]="{values: prohibitedNameValues, excludeSelf: true}" [selfValue]="dataItem.loaType.name">
                        <span errorMessage for="required"></span>
                        <span errorMessage for="slxMaxLength">Name should be a maximum length of '50'</span>
                        <span errorMessage for="slxMinLength">Name should be a minimun length of '4'</span>
                        <span errorMessage for="prohibitedValues">Name should be unique</span>
                      </slx-input-decorator>
                      <slx-input-decorator>
                        <input slx-input required="true" name="description" placeholder="Description" [(ngModel)]="dataItem.loaType.description" slxMaxLength="100">
                        <span errorMessage for="required"></span>
                        <span errorMessage for="slxMaxLength">Description should be a maximum length of '100'</span>
                      </slx-input-decorator>
                      <div class="type-value">
                          <span class="type-value-header">Modified by:</span> {{ dataItem.modifiedBy }}
                        </div>
                        <div class="type-value">
                          <span class="type-value-header">Modified at:</span> {{ dataItem.modifiedAt | amDateFormat: appConfig.dateFormat  }}
                        </div>
                    </div>
                  </div>
                </ng-template>
            </kendo-grid-column>


            <kendo-grid-command-column width="100" [hidden]="!canEdit" [media]="screenUtils.getKendoMediaForDesktop()">
              <ng-template kendoGridCellTemplate>
                <div class="inline-buttons">
                  <button kendoGridEditCommand type="button" *ngIf="canEdit" class="slx-inline-edit-button2">
                    <i class="fas fa-edit slx-inline-edit-button__icon" aria-hidden="true"></i>
                  </button>
                  <button kendoGridRemoveCommand *ngIf="canEdit" class="slx-icon-button slx-inline-remove-button2">
                    <i class="fas fa-trash slx-inline-edit-button__icon" aria-hidden="true"></i>
                  </button>
                  <button kendoGridSaveCommand type="button" [disabled]="!typesForm.valid" class="slx-icon-button slx-inline-apply-button2">
                    <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
                  </button>
                  <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button2">
                    <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
                  </button>
                </div>
              </ng-template>
            </kendo-grid-command-column>

            <kendo-grid-column title="Leave Type" width="150" field="loaType.name" slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Leave Type</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.loaType.name }}
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <slx-input-decorator>
                      <input slx-input required="true" name="name" placeholder="Name" [(ngModel)]="dataItem.loaType.name" slxMaxLength="50" slxMinLength="4"
                      [prohibitedValues]="{values: prohibitedNameValues, excludeSelf: true, caseSensitive: false}" [selfValue]="dataItem.loaType.name">
                      <span errorMessage for="required"></span>
                      <span errorMessage for="slxMaxLength">Name should be a maximum length of '50'</span>
                      <span errorMessage for="slxMinLength">Name should be a minimun length of '4'</span>
                      <span errorMessage for="prohibitedValues">Name should be unique</span>
                    </slx-input-decorator>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Leave Description" width="200" field="loaType.description" slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Leave Description</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.loaType.description }}
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <slx-input-decorator>
                        <input slx-input required="true" name="description" placeholder="Description" [(ngModel)]="dataItem.loaType.description" slxMaxLength="100">
                        <span errorMessage for="required"></span>
                        <span errorMessage for="slxMaxLength">Description should be a maximum length of '100'</span>
                      </slx-input-decorator>
                  </ng-template>
              </kendo-grid-column>

              <!--
              <kendo-grid-column title="Color" field="loaType.color" [filterable]="false" width="80" [media]="screenUtils.getKendoMediaForDesktop()">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="col" [style.background-color]="dataItem.loaType.color | DecHex"></div>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <div class="color-picker">
                      <slx-input-decorator>
                        <input class="hidden-input" type="hidden" slx-input [required]="true" placeholder="">
                        <input id="colorField{{rowIndex}}" type="text" class="color-border color-picker-input" [colorPicker]="dataItem.loaType.color"
                          [cpPosition]="'bottom'" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" [style.background]="dataItem.loaType.color"
                          (colorPickerChange)="onColorPickerChange(dataItem, $event)" readonly [cpDialogDisplay]="'popup'" [cpUseRootViewContainer]="true"
                          [placeholder]="''">
                        <span errorMessage for="required"></span>
                      </slx-input-decorator>
                    </div>
                  </ng-template>
                </kendo-grid-column>
              -->
              <kendo-grid-column title="Modified by" width="200" field="modifiedBy" slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()" filter="date">
                  <ng-template kendoGridHeaderTemplate>
                    <span slxKendoGridHeaderTooltip>Modified by</span>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    {{ dataItem.modifiedBy }}
                 </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Modified Date" width="200" field="modifiedAt" slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()">
                  <ng-template kendoGridHeaderTemplate>
                    <span slxKendoGridHeaderTooltip>Modified Date</span>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    {{ dataItem.modifiedAt | amDateFormat: appConfig.dateFormat  }}
                 </ng-template>
              </kendo-grid-column>

              <kendo-grid-messages filterMenuTitle="Filter">
              </kendo-grid-messages>  
          </kendo-grid>
        </form>
    </div>
</div>
</slx-spinner>

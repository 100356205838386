import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';

import { Subscription } from 'rxjs';
import { process } from '@progress/kendo-data-query';

import { KendoGridStateHelper } from '../../../../../common/models/index';
import { LoaRequest } from '../../../models/index';
import { appConfig, IApplicationConfig } from './../../../../../app.config';
import { LMManagementService } from '../../../services/index';
import { IScreenUtils, screenUtils } from '../../../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-console-table',
  templateUrl: 'lm-console-table.component.html',
  styleUrls: ['lm-console-table.component.scss'],
})
export class LMConsoleTableComponent implements OnInit, OnDestroy {
  @Input()
  public mode: 'onLeave' | 'returning' | 'upcoming' | 'pastDue';
  @Input()
  public set records(value: LoaRequest[]) {
    this.m_records = value;
    this.refreshGrid();
  }

  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<LoaRequest>;
  public screenUtils: IScreenUtils;
  
  private m_records: LoaRequest[];
  private subscriptions: StringMap<Subscription> = {};
  constructor(private managementService: LMManagementService) {
    this.screenUtils = screenUtils;
    this.gridState = new KendoGridStateHelper<LoaRequest>();
    this.gridState.view = null;
    this.appConfig = appConfig;
  }

  public ngOnInit(): void { }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public getStartDate(dateItem: LoaRequest): Date {
    if(!dateItem.actStartDate) {
      return dateItem.estStartDate;
    }
    return dateItem.actStartDate;
  }
  public onClickEmployee(req: LoaRequest): void {
    this.managementService.openCreationDialog(null, req);
  }

  public getIcon(request: LoaRequest): string {
    return this.managementService.getIcon(request.requestClass);
  }

  private refreshGrid(): void {
    if (!this.m_records) {
      this.gridState.view = null;
      return;
    }
    if (this.mode === 'onLeave' || this.mode === 'upcoming') {
      this.gridState.state.sort = [{ field: 'actStartDate', dir: 'asc' }];
    } else {
      this.gridState.state.sort = [{ field: 'estEndDate', dir: 'asc' }];
    }
    this.gridState.view = process(this.m_records, this.gridState.state);
  }

  public getDaysOnLeave(dataItem)  { 
    return dataItem.isIntermittent ? '' : dataItem.daysOnLeave;
  }

  public getDaysRemaining(dataItem) {
    return dataItem.isIntermittent ? '' : dataItem.daysRemaining;
  }
}

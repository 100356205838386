import * as _ from 'lodash';

import { UserPasswordSettingsDialogComponent } from './../../user-password-settings-dialog/user-password-settings-dialog.component';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { RoleDefinition } from '../../../../organization/models/index';
import { RoleColumnsState, RoleColumn } from '../../../models/index';
import { RolesManagementService, RolesDefinitionManagementService } from '../../../services/index';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';
import { RolesLegendDialogComponent } from '../roles-legend-dialog/roles-legend-dialog.component';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-roles-management',
  templateUrl: 'roles-management.component.html',
  styleUrls: ['roles-management.component.scss'],
  providers: [RolesManagementService, RolesDefinitionManagementService, StateManagementService, ComponentStateStorageService]
})
export class RolesManagementComponent implements OnInit, OnDestroy {

  public state: {
    isLoading: boolean;
    isAccessTableLoading: boolean;
    isManageRoleMode: boolean;
  };
  public selectedRole: RoleDefinition;
  public hasChanges: boolean;
  public manageModeHasChanges: boolean;
  public roleColumnsState: RoleColumnsState;
  public columnsList: RoleColumn[];
  public shownColumns: RoleColumn[];

  public canSave: boolean = true;

  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription2: Subscription;
  @unsubscribe()
  private selectDefinitionSubscription: Subscription;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private changesNotifySubscription: Subscription;
  @unsubscribe()
  private changesClearSubscription: Subscription;
  @unsubscribe()
  private managementModeChangesSubscription: Subscription;
  @unsubscribe()
  private managementModeChangesClearSubscription: Subscription;

  @destroyService()
  private managementService: RolesManagementService;

  @destroyService()
  private definitionManagementService: RolesDefinitionManagementService;

  private maxVisibleColumnsToSave: number = 5;

  constructor(managementService: RolesManagementService, definitionManagementService: RolesDefinitionManagementService, private modalService: ModalService) {
    this.managementService = managementService;
    this.definitionManagementService = definitionManagementService;
    this.state = {
      isLoading: false,
      isAccessTableLoading: true,
      isManageRoleMode: false
    };
    this.columnsList = [];
    this.shownColumns = [];
  }

  public ngOnInit(): void {
    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isAccessTableLoading = value;
      });
    this.stateSubscription = this.managementService.rolesColumnsStateChanged$
      .subscribe((state: RoleColumnsState) => {
        this.roleColumnsState = state;
        this.columnsList = _.values(state.columns);
        const filteredColumns: RoleColumn[] = state.getVisibleColumns();
        this.shownColumns = filteredColumns;
        this.canSave = _.size(this.shownColumns) <= this.maxVisibleColumnsToSave;
      });
    this.loadStatusSubscription2 = this.definitionManagementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });
    this.selectDefinitionSubscription = this.definitionManagementService.selectRecord$
      .subscribe((role: RoleDefinition) => {
        this.selectedRole = role;
      });

    this.managementModeChangesSubscription = this.definitionManagementService.changeNotify$.subscribe((source: string) => {
      this.manageModeHasChanges = true;
    });

    this.managementModeChangesClearSubscription = this.definitionManagementService.clearChanges$.subscribe(() => {
      this.manageModeHasChanges = false;
    });

    this.changesNotifySubscription = this.managementService.changeNotify$.subscribe((source: string) => {
      this.hasChanges = true;
    });

    this.changesClearSubscription = this.managementService.clearChanges$.subscribe(() => {
      this.hasChanges = false;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onManagingRoles(): void {
    this.state.isManageRoleMode = true;
  }

  public onShowLegend(): void {
    RolesLegendDialogComponent.openDialog(this.modalService, (result: boolean, cmd: any) => {
      //todo
    });
  }

  public onManagingAccessTable(): void {
    this.managementService.reLoadAccessTable();
    this.state.isManageRoleMode = false;
  }
  public onSaveChanges(): void {
    this.managementService.saveChanges();
  }

  public onDicardChanges(): void {
    this.managementService.dicardChanges();
  }

  public onAddDefinition(): void {
    this.definitionManagementService.addCmd();
  }
  public onCloneDefinition(): void {
    this.definitionManagementService.cloneCmd(this.selectedRole);
  }

  public onPasswordSettings(): void {
    UserPasswordSettingsDialogComponent.openDialog(this.modalService, (result: boolean, cmd: any) => {
      //todo
    });
  }

  public onFiltersChanged(): void {
    this.roleColumnsState.applyFilter(this.shownColumns.concat());
    this.managementService.changeRolesColumnsState(this.roleColumnsState);
  }
}

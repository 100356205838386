import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { Subscription } from 'rxjs';
import { process, orderBy, State, SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { unsubscribe } from '../../../../../app/core/decorators/index';
import { ParLevels, IdealScheduleConfig } from '../../../models/ideal-schedule/index';
import { DayOfWeek } from '../../../../common/models/days/day-of-week';
import { IdealScheduleHelperService } from '../../../services/ideal-schedule/ideal-schedule.helper.service';
import { IdealScheduleStateService } from '../../../services/ideal-schedule/ideal-schedule.state.service';
import { IdealSchedulePosition } from '../../../models/index';
import { CalendarDataService } from '../../../../core/services/index';
import { IdealSchedulePositionRange } from '../../../models/ideal-schedule/ideal-schedule-position-range';
import { screenUtils } from '../../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-ideal-schedule-grid-control',
  templateUrl: 'ideal-schedule-grid-control.component.html',
  styleUrls: ['ideal-schedule-grid-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdealScheduleGridControlComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public range: IdealSchedulePositionRange;

  @Input()
  public set showShiftColumn(show: boolean) {
    this.state.showShiftColumn = show;
  }

  @Input()
  public set showUnitColumn(show: boolean) {
    this.state.showUnitColumn = show;
  }

  @Input()
  public set isShiftGroup(isShiftGroup: boolean) {
    this.state.isShiftGroup = isShiftGroup;
    this.updateHourlyMaxLimit(isShiftGroup);
  }

  @Input()
  public set filterGroup(group: string) {
    this.state.filterGroup = group;
  }

  public get position(): IdealSchedulePosition {
    return this.stateService.selectedPosition;
  }

  public get originalPosition(): IdealSchedulePosition {
    return this.stateService.originalPosition;
  }

  public state: {
    showShiftColumn: boolean,
    showUnitColumn: boolean,
    isShiftGroup: boolean,
    maxHourLimit: number,
    filterGroup: string
  };

  public gridState: KendoGridStateHelper<ParLevels>;

  public get weekDays(): DayOfWeek[] {
    return this.calendarDataService.getWeekDaysList;
  }

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  @unsubscribe()
  private stateServiceChangedSubscription: Subscription;

  @unsubscribe()
  private stateServiceTotalHoursSubscription: Subscription;

  constructor(
    private calendarDataService: CalendarDataService,
    public scheduleHelper: IdealScheduleHelperService,
    public stateService: IdealScheduleStateService,
    private changeDetector: ChangeDetectorRef) {
    this.state = {
      showShiftColumn: false,
      showUnitColumn: false,
      isShiftGroup: false,
      maxHourLimit: IdealScheduleConfig.ShiftGroupInputMaxHours,
      filterGroup: null
    };
    this.gridState = new KendoGridStateHelper<ParLevels>();
    this.gridState.state.sort = [];
  }

  public ngOnInit(): void {
    this.gridState.state.sort = this.createSortState();

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
    this.stateServiceChangedSubscription = this.stateService.changed$.subscribe( () => {
      this.refreshGrid();
      this.detectChanges();
    });
    this.stateServiceTotalHoursSubscription = this.stateService.totalHoursChanged$.subscribe( () => {
      this.detectChanges();
    });

    this.refreshGrid();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const rangeChange = changes['range'];

    if (rangeChange && !rangeChange.firstChange) {
      this.refreshGrid();
    }
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public isMobile(): boolean {
    return screenUtils.isMobile;
  }

  public detectChanges() : void {
    setTimeout(() => {
      this.changeDetector.markForCheck();
    }, 0);
  }

  private createSortState() : Array<SortDescriptor> {
    let result: Array<SortDescriptor> = [];
    if(this.state.showShiftColumn) {
      result.push({ field: 'shift.startDate', dir: 'asc' });
      result.push({ field: 'shift.endDate', dir: 'asc' });
    }
    // if(this.state.showUnitColumn) {
    //   result.push({ field: 'unit.name', dir: 'asc' });
    // }
    return result;
  }

  private refreshGrid(): void {
    this.gridState.state.sort = this.createSortState();
    let result: ParLevels[] = this.getParLevels();
    this.gridState.view = process(orderBy(result, this.gridState.state.sort), {});
    this.detectChanges();
  }

  private getParLevels(): ParLevels[] {
    let result: ParLevels[] = _.values(this.range.parLevels);

    if (this.stateService.hideUnused) {
      result = _.filter(result, (item: ParLevels) => !item.isUnused());
    }

    if (this.state.filterGroup) {
      result = _.filter(result, (item: ParLevels) => item.shiftGroup.name === this.state.filterGroup);
    }

    return result;
  }

  private updateHourlyMaxLimit(isShiftGroup: boolean): void {
    this.state.maxHourLimit = isShiftGroup ? IdealScheduleConfig.ShiftGroupInputMaxHours : IdealScheduleConfig.NonShiftGroupInputMaxHours;
  }
}

import {
  Component, OnInit, Input, ViewEncapsulation,
  ElementRef, AfterViewChecked, AfterViewInit, OnChanges, SimpleChanges, DoCheck, ChangeDetectorRef
} from '@angular/core';
import { UntypedFormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';

import { TreeNodeComponent, TreeNodeModel } from '../../../tree/index';
import { LogicalExpressionModel, ComparisionOperator, DataType } from '../../models/logical-expression.model';
import { QueryModel, NodeData } from '../../models/query-model';
import { SearchOperator } from '../../models/search-operator.model';
import { SearchInputTypes } from './search-input-types';

import { SearchCategory } from '../../../../../../organization/models/lookup/index';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'slx-expression-selector-component',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['expression-selector.component.scss'],
  templateUrl: 'expression-selector.component.html'
})
export class ExpressionSelectorComponent extends TreeNodeComponent implements OnInit, DoCheck, OnChanges {

  public formGroup: UntypedFormGroup;

  public currentCategories: SearchCategory[];
  public selectedCategory: SearchCategory;
  public selectedOperator: SearchOperator;
  public operators: SearchOperator[];
  public firstValue: any;
  public secondValue: any;

  public firstDate: boolean;
  public secondDate: boolean;
  public hasSecondValue: boolean;

  constructor(private changeDetector: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {

    let nodeData: NodeData = this.node.data;

    let queryModel: QueryModel = this.node.tree as QueryModel;
    this.currentCategories = queryModel.categories;

    this.formGroup = nodeData.formGroup;
    let expression: LogicalExpressionModel = nodeData.expression;

    let formGroupCategory: SearchCategory = this.formGroup.get('selectedCategory').value;
    if (!formGroupCategory) {
      this.initFromExpression(expression);
    } else {
      this.setSelectedCategory(formGroupCategory.categoryFieldName);
      this.updateExpression();
      this.initFromExpression(expression);
    }
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    //
  }

  public ngDoCheck(): void {

    let queryModel: QueryModel = this.node.tree as QueryModel;

    if (queryModel.categories !== this.currentCategories) {

      this.currentCategories = queryModel.categories;
      let nodeData: NodeData = this.node.data;
      let expression: LogicalExpressionModel = nodeData.expression;

      let formGroupCategory: SearchCategory = this.formGroup.get('selectedCategory').value;
      if (!formGroupCategory) {
        this.initFromExpression(expression);
      } else {
        this.setSelectedCategory(formGroupCategory.categoryFieldName);
        this.updateExpression();
        this.initFromExpression(expression);
      }

      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }

  public onCategoryChanged(value: SearchCategory): void {

    let nodeData: NodeData = this.node.data;
    let expression: LogicalExpressionModel = nodeData.expression;
    let queryModel: QueryModel = this.node.tree as QueryModel;
    this.selectedCategory = value;

    if (this.selectedCategory) {

      this.setOperatorsFromCategory();
      this.setSelectedOperator(QueryModel.getDefaultOperator(this.selectedCategory.operators));
      this.formGroup.get('selectedOperator').setValue(this.selectedOperator);
      this.setDefaultValues();
      this.updateFormGroupValues();
    }

    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();

  }

  public onOperatorChanged(value: SearchOperator): void {
    let nodeData: NodeData = this.node.data;
    let expression: LogicalExpressionModel = nodeData.expression;
    this.selectedOperator = value;
    this.setDefaultValues();
    this.updateFormGroupValues();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public onValueChange(): void {
    this.updateExpression();
  }

  private initFromExpression(expression: LogicalExpressionModel): void {

    if (expression && expression.searchPredicate && expression.searchPredicate.searchCategoryName) {
      this.setSelectedCategory(expression.searchPredicate.searchCategoryName);
    } else if (this.currentCategories) {
      this.selectedCategory = this.currentCategories[0];
    }

    if (this.selectedCategory) {
      this.setOperatorsFromCategory();

      this.setSelectedOperator(expression.searchPredicate.searchOperator || QueryModel.getDefaultOperator(this.selectedCategory.operators));

      this.firstValue = null;
      this.secondValue = null;

      if (expression.searchPredicate.values) {
        if (expression.searchPredicate.values.length > 0) this.firstValue = expression.searchPredicate.values[0];
        if (expression.searchPredicate.values.length > 1) this.secondValue = expression.searchPredicate.values[1];
      }

      this.firstDate = false;
      this.secondDate = false;

      if (!this.firstValue) {
        if (this.checkDataType(this.selectedCategory.dataType, DataType.Date)) {
          this.firstValue = moment().startOf('day').toDate();
          this.firstDate = true;
        }
      } else {
        this.firstDate = this.checkDataType(this.selectedCategory.dataType, DataType.Date);
      }

      if (!this.secondValue) {
        if (this.checkOperator(this.selectedOperator, ComparisionOperator.Between) && this.checkDataType(this.selectedCategory.dataType, DataType.Date)) {
          this.secondValue = moment().startOf('day').toDate();
          this.secondDate = true;
        }
      } else {
        this.secondDate = this.checkDataType(this.selectedCategory.dataType, DataType.Date);
      }

      this.hasSecondValue = this.secondValue !== null;

      this.updateFormGroupCategory();
      this.updateFormGroupValues();
    }
  }

  private setSelectedCategory(searchCategoryName: string): void {
    this.selectedCategory = _.find(this.currentCategories, (category: SearchCategory) => {
      return category.categoryFieldName === searchCategoryName;
    });

    if (!this.selectedCategory && this.currentCategories) {
      this.selectedCategory = this.currentCategories[0];
    }
  }

  private setOperatorsFromCategory(): void {
    let operators: SearchOperator[] = [];
    _.each(this.selectedCategory.operators, (operator: string) => {
      let o: SearchOperator = new SearchOperator();
      o.displayName = operator;
      let operatorEnum: { [idx: string]: ComparisionOperator; } = <any>ComparisionOperator;
      o.operatorId = operatorEnum[operator];
      operators.push(o);
    });
    this.operators = operators;
  }

  private setSelectedOperator(searchOperator: string): void {
    this.selectedOperator = _.find(this.operators, (operator: SearchOperator) => {
      return operator.displayName === searchOperator;
    });

  }

  private checkOperator(value: SearchOperator, operator: ComparisionOperator): boolean {
    return value.operatorId === operator;
  }

  private checkDataType(value: any, dataType: any): boolean {
    return value === DataType[dataType];
  }

  private updateFormGroupCategory(): void {
    this.formGroup.get('selectedCategory').setValue(this.selectedCategory);
    this.formGroup.get('selectedOperator').setValue(this.selectedOperator);
  }

  private updateFormGroupValues(): void {

    if (this.selectedCategory) {
      this.formGroup.get('firstValue').setValue(this.firstValue);
      if (this.hasSecondValue) {
        this.formGroup.get('secondValue').setValue(this.secondValue);
      } else {
        this.formGroup.get('secondValue').setValue(null);
      }
    } else {
      this.formGroup.get('firstValue').setValue(null);
      this.formGroup.get('secondValue').setValue(null);
    }
  }

  private updateExpression(): void {
    let nodeData: NodeData = this.node.data;
    let expression: LogicalExpressionModel = nodeData.expression;
    let firstValue: any;
    let secondValue: any;
    if (this.selectedCategory) {
      expression.searchPredicate.searchCategoryName = this.selectedCategory.categoryFieldName;
      let selectedOperator: SearchOperator = this.formGroup.get('selectedOperator').value;
      expression.searchPredicate.searchOperator = selectedOperator.displayName;
      firstValue = this.formGroup.get('firstValue').value;
      if (this.firstDate) {
        firstValue = moment(firstValue).startOf('day').toDate();
      }
      expression.searchPredicate.values = [firstValue];
      secondValue = this.formGroup.get('secondValue').value;

      if (this.hasSecondValue) {
        if (this.secondDate) {
          secondValue = moment(secondValue).startOf('day').toDate();
        }
        expression.searchPredicate.values.push(secondValue);
      }
    }
  }

  private setDefaultValues(): void {

    if (this.checkDataType(this.selectedCategory.dataType, DataType.Date)) {
      this.firstValue = moment().startOf('day').toDate();
      this.firstDate = true;
    } else {
      this.firstValue = null;
      this.firstDate = false;
    }

    if (this.checkOperator(this.selectedOperator, ComparisionOperator.Between)) {
      this.hasSecondValue = true;
    } else {
      this.hasSecondValue = false;
    }

    if (this.hasSecondValue) {
      if (this.checkDataType(this.selectedCategory.dataType, DataType.Date)) {
        this.secondValue = moment().startOf('day').toDate();
        this.secondDate = true;
      } else {
        this.secondValue = null;
        this.secondDate = false;
      }
    } else {
      this.secondValue = null;
      this.secondDate = false;
    }
  }

}

<kendo-grid class="slx-full-height slx-blue-grid aca-export-data-grid"
            [data]="gridState.view"
            [sortable]="{ mode: 'multiple' }"
            [sort]="gridState.state.sort"
            [filterable]="'menu'"
            [filter]="gridState.state.filter"
            [pageSize]="pageSize"
            [pageable]="true"
            [scrollable]="'scrollable'"
            (sortChange)="sortChange($event)"
            (filterChange)="filterChange($event)"
            (pageChange)="pageChange($event)"
            [skip]="gridState.state.skip"
            (dataStateChange)="gridState.dataStateChange($event)">

  <kendo-grid-column title="Status" locked="true" field="status" [filterable]="false" width="110">
    <ng-template kendoGridHeaderTemplate>
      Status
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngIf="dataItem.status === exportDataStatus.Waiting" class="aca-export-data-status aca-export-data-waiting">
        <em class="fas fa-clock aca-export-data-waiting icon" aria-hidden="true"></em>
        <span>Waiting</span>
      </div>
      <div *ngIf="dataItem.status === exportDataStatus.Completed" class="aca-export-data-status aca-export-data-completed">
        <em class="fas fa-check-circle aca-export-data-completed icon" aria-hidden="true"></em>
        <span>Completed</span>
      </div>
      <div *ngIf="dataItem.status === exportDataStatus.Expired" class="aca-export-data-status aca-export-data-expired">
        <em class="fas fa-times-circle aca-export-data-expired icon" aria-hidden="true"></em>
        <span>Expired</span>
      </div>
      <div *ngIf="dataItem.status === exportDataStatus.InProgress" class="aca-export-data-status aca-export-data-in-progress">
        <em class="fas fa-spinner fa-pulse aca-export-data-in-progress icon" aria-hidden="true"></em>
        <span>In progress</span>
      </div>
      <div *ngIf="dataItem.status == exportDataStatus.Failed && dataItem.reason" class="aca-export-data-status aca-export-data-fail"
           [popper]="failedReason" [popperTrigger]="'hover'" [popperPlacement]="'bottom-left'" [popperDisableStyle]="'true'"
           (click)="showReasonInfoPopup(dataItem)">
        <em class="fas fa-times-circle aca-export-data-fail icon" aria-hidden="true"></em>
        <span>Failed</span>
        <popper-content #failedReason class="slx-popper">
          <div class="export-data-popper">
            <span>{{dataItem.reason}}</span>
          </div>
        </popper-content>
      </div>
      <div *ngIf="dataItem.status == exportDataStatus.Failed && !dataItem.reason" class="aca-export-data-status aca-export-data-fail">
        <em class="fas fa-times-circle aca-export-data-fail icon" aria-hidden="true"></em>
        <span>Failed</span>
      </div>
      <div *ngIf="dataItem.status == exportDataStatus.Cancelled" class="aca-export-data-status aca-export-data-cancel">
        <em class="fas fa-times-circle aca-export-data-cancel icon" aria-hidden="true"></em>
        <span>Cancelled</span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Type" field="exportTypeName" width="120">
    <ng-template kendoGridHeaderTemplate>
      Type
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.exportTypeName">{{dataItem.exportTypeName}}</div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Executed By" field="executedBy" width="120">
    <ng-template kendoGridHeaderTemplate>
      Executed By
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.executedBy">{{dataItem.executedBy}}</div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Started On" field="startedOn" width="130" [filter]="'date'" format="{0:MM/dd/yyyy hh:mm a}">
    <ng-template kendoGridHeaderTemplate>
      Started On
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.startedOn | amDateFormat: appConfig.dateTimeFormatUS">{{dataItem.startedOn | amDateFormat: appConfig.dateTimeFormatUS}}</div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Completed On" field="completedOn" width="130" [filter]="'date'" format="{0:MM/dd/yyyy hh:mm a}">
    <ng-template kendoGridHeaderTemplate>
      Completed On
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.completedOn | amDateFormat: appConfig.dateTimeFormatUS">{{dataItem.completedOn | amDateFormat: appConfig.dateTimeFormatUS}}</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Year" field="year" width="80">
    <ng-template kendoGridHeaderTemplate>
      Year
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.year">{{dataItem.year}}</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Employer" field="employerName" width="110">
    <ng-template kendoGridHeaderTemplate>
      Employer
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.employerName">{{dataItem.employerName}}</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Other Parameter" field="otherParameters" width="130" *ngIf="!configuration?.exportParams.hidden">
    <ng-template kendoGridHeaderTemplate>
      Other Parameter
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="text-params" [title]="dataItem.otherParameters">{{dataItem.otherParameters}}</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Receipt ID" field="receiptId" width="110" *ngIf="configuration?.exportType == 4">
    <ng-template kendoGridHeaderTemplate>
      Receipt ID
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="text-params" [title]="dataItem.receiptId">{{dataItem.receiptId}}</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Actions" locked="true" [sortable]="false" [filterable]="false" width="70" *ngIf="configuration?.isActionEnabled">
    <ng-template kendoGridHeaderTemplate>
      Actions
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="aca-export-download" title="Generate Corrections" *ngIf="dataItem.status === exportDataStatus.Completed">
        <em class="fas fa-edit aca-export-download-button" (click)="showAcaExportCorrectionsPopup(dataItem)"></em>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Download" locked="true" [sortable]="false" [filterable]="false" width="70">
    <ng-template kendoGridHeaderTemplate>
      Download
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="aca-export-download" *ngIf="dataItem.status === exportDataStatus.Completed && !isDownloadAvailable(dataItem)"
           [popper]="downloadWarning" [popperTrigger]="'hover'" [popperPlacement]="'bottom-left'" [popperDisableStyle]="'true'">
        <em class="fas fa-download aca-export-download-button dowload-disabled" (click)="showDownloadInfoPopup(dataItem)"></em>
        <popper-content #downloadWarning class="slx-popper">
          <div class="aca-export-data-popper">
            <span>{{messages.downloadNotAvailable}}</span>
          </div>
        </popper-content>
      </div>
      <div class="aca-export-download" *ngIf="dataItem.status === exportDataStatus.Completed && isDownloadAvailable(dataItem)">
        <em class="fas fa-download aca-export-download-button" (click)="downloadFile(dataItem)"></em>
      </div>   
      
      <div class="aca-export-download" *ngIf="dataItem.status === exportDataStatus.Failed" [popper]="downloadWarning" [popperTrigger]="'hover'" [popperPlacement]="'bottom-left'" [popperDisableStyle]="'true'">
        <em class="fas fa-download aca-export-download-button" (click)="downloadFile(dataItem)"></em>
        <popper-content #downloadWarning class="slx-popper">
         <div class="aca-export-data-popper">
          <span>This file should not be filed against the IRS and should only be used for debugging</span>
         </div>
       </popper-content>
       </div>  
        
    </ng-template>
  </kendo-grid-column>
  
  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { process, State } from '@progress/kendo-data-query';
import { Subscription ,  Observable } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { mutableSelect, unsubscribeAll } from '../../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { BenefitEligibleEmployee } from '../../../models/index';
import { BenefitEmployeesApiService } from '../../../services/index';
import { subscribeToOrgLevel } from '../../../../../organization/selectors/index';
import { IScreenUtils, screenUtils } from '../../../../../common/utils/index';
import { BenefitDetailsTier } from './../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-benefit-employees-eligible',
  templateUrl: 'benefit-employees-eligible.component.html',
  styleUrls: ['benefit-employees-eligible.component.scss'],
})
export class BenefitEmployeesEligibleComponent implements OnInit, OnDestroy {
  @Input()
  public tierId: number;

  @Input()
  public lineId: number;

  @Input()
  public date: Date;

  @Input()
  public canEnroll: boolean;

  @Input()
  public isUserCanEnroll: boolean;

  @Input()
  public planName: string;

  @Input()
  public method: string;

  @Input()
  public tierDetails: BenefitDetailsTier;

  @Input()
  public lowLevelOrgId: number;

  @Output()
  public selectEmployees: EventEmitter<BenefitEligibleEmployee[]>;

  public screenUtils: IScreenUtils;
  public isLoading: boolean;
  public appConfig: IApplicationConfig;
  public data: BenefitEligibleEmployee[] = [];
  public filteredRecords: BenefitEligibleEmployee[] = [];
  public selectedEmployees = new Map<number, BenefitEligibleEmployee>();
  public pageSize: number = 50;
  public gridState: KendoGridStateHelper<BenefitEligibleEmployee>;
  public title: string;
  public isCheckedAll = false;
  @Input()
  public set skip(value: number) {
    if (value === undefined) {
      return;
    }
    this.gridState.state.skip = value;
  }

  @ViewChild('form', { static: true })
  public form: UntypedFormGroup;

  @ViewChild('kendoGrid', { static: true })
  public kendoGrid: GridComponent;


  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private orgLevel: OrgLevel;

  constructor(
    private apiService: BenefitEmployeesApiService
  ) {
    this.gridState = new KendoGridStateHelper<BenefitEligibleEmployee>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'employeeName', dir: 'asc' }];
    this.appConfig = appConfig;
    this.screenUtils = screenUtils;
    this.selectEmployees = new EventEmitter();
  }

  public ngOnInit(): void {
    this.subscriptions.orgLevel = subscribeToOrgLevel(this.orgLevel$, () => this.orgLevel, (orgLevel: OrgLevel) => this.reload(orgLevel));

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    if (this.canEnroll) {
      this.title = 'You must save or cancel changes on Plan Details page before enrolling employees.';
    }
  }

  public isCheckedEmp(emp: BenefitEligibleEmployee): boolean {
    return this.selectedEmployees.has(emp.employeeId);
  }

  public async reload(orgLevel: OrgLevel): Promise<any> {
    this.isLoading = true;
    this.orgLevel = orgLevel;
    let orgLevelId = this.lowLevelOrgId ? this.lowLevelOrgId : this.orgLevel.id;
    return this.apiService.getBenefitEligibleEmployees(orgLevelId, this.tierId, this.date)
      .then((records: BenefitEligibleEmployee[]) => {
        this.data = records;
        this.refreshGrid();
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public onSelectAll(): void {
    if (this.isCheckedAll) {
      this.selectedEmployees.clear();
      this.isCheckedAll = false;
    } else {
      _.forEach(this.filteredRecords, e => {
        this.selectedEmployees.set(e.employeeId, e);
      });
      this.isCheckedAll = true;
    }
  }

  public onSelectSingle(emp: BenefitEligibleEmployee): void {
    if (this.selectedEmployees.has(emp.employeeId)) {
      this.selectedEmployees.delete(emp.employeeId);
      this.isCheckedAll = false;
    } else {
      this.selectedEmployees.set(emp.employeeId, emp);
      if (_.size(this.filteredRecords) === this.selectedEmployees.size) {
        this.isCheckedAll = true;
      }
    }
  }

  public enrollEmployees(): void {
    this.selectEmployees.emit(Array.from(this.selectedEmployees.values()));
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private refreshGrid(): void {
    if (!this.data) {
      this.gridState.view = null;
      return;
    }

    this.filteredRecords = process(this.data, { filter: this.gridState.state.filter, sort: this.gridState.state.sort }).data;
    this.gridState.view = process(this.data, this.gridState.state);
  }
}

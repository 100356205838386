import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { schedulerRoutes } from './portal.scheduler.routes';
import { timeRoutes } from './portal.time.routes';
import { reportsRoutes } from './portal.reports.routes';
import { pbjRoutes } from './portal.pbj.routes';
import { logiRoutes } from './portal.logi.routes';
import { hrRoutes } from './portal.hr.routes';
import { globalRoutes } from './portal.global.routes';
import { appConfig } from '../../app/app.config';
import { accrualsRoutes } from './portal.accruals.routes';

import {
  PortalComponent,
  PortalEmployeeComponent,
  ApplicationsDashboardComponent,
  ApplicationContainerComponent,
  ApplicationComponent,
  HeaderComponent,
  FooterComponent,
  AuthorizessoComponent,
  LeftSidebarComponent,
} from './components/index';
import { AuthenticationGuard } from '../authentication/services/index';
import { CanLeaveGuard, ComponentStateGuard } from '../common/services/index';
import { AccessibleGuard } from '../organization/services/index';
import { SsoGuard } from '../authentication/services/index';
import { intgRoutes } from './portal.intg.routes';

export const menuRoutes: Routes = [];
export const routes: Routes = [
  {
    path: 'apps',
    canActivate: [SsoGuard, AuthenticationGuard, AccessibleGuard],
    canActivateChild: [AuthenticationGuard, AccessibleGuard, ComponentStateGuard],
    canDeactivate: [CanLeaveGuard],
    component: PortalComponent,
    children: [
      {
        path: 'scheduler',
        component: ApplicationComponent,
        children: [...schedulerRoutes]
      },
      {
        path: 'time',
        component: ApplicationComponent,
        children: [...timeRoutes]
      },
      {
        path: 'payroll',
        component: ApplicationComponent,
        children: [...schedulerRoutes]
      },
      {
        path: 'inservice',
        component: ApplicationComponent,
        children: [...schedulerRoutes]
      },
      {
        path: 'cpcs',
        component: ApplicationComponent,
        children: [...schedulerRoutes]
      },
      {
        path: 'directory',
        component: ApplicationComponent,
        children: [...schedulerRoutes]
      },
      {
        path: 'reports',
        component: ApplicationComponent,
        children: [...reportsRoutes]
      },
      {
        path: 'accruals',
        component: ApplicationComponent,
        children: [...accrualsRoutes]
      },
      {
        path: 'aca',
        component: ApplicationComponent,
        children: [...schedulerRoutes]
      },
      {
        path: 'pbj',
        component: ApplicationComponent,
        children: [...pbjRoutes]
      },
      {
        path: 'bi',
        component: ApplicationComponent,
        children: [...logiRoutes]
      },
      {
        path: 'ngp',
        component: ApplicationComponent,
        children: [...schedulerRoutes]
      },
      {
        path: 'hr',
        component: ApplicationComponent,
        children: [...hrRoutes]
      },
      {
        path: 'intg',
        component: ApplicationComponent,
        children: [...intgRoutes]
      },
      {
        path: appConfig.globalMenuItemsApplication.link,
        component: ApplicationComponent,
        children: [...globalRoutes]
      },
      {
        path: '',
        component: ApplicationsDashboardComponent,
        data: { showSecondLine: true }
      },
      {
        path: '',
        outlet: 'leftSidebar',
        component: LeftSidebarComponent
      },
      {
        path: '',
        outlet: 'header',
        component: HeaderComponent
      }
      /*,
      {
        path: '',
        outlet: 'footer',
        component: FooterComponent
      }*/
    ]
  }
];

/* export const routesModule: ModuleWithProviders = RouterModule.forChild(routes); */

import * as _ from 'lodash';
import { Observable ,  Subscription } from 'rxjs';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { AttachmentDefinition, FileModel } from '../../../models';
import { ReadFile } from '../../../../../organization';
import { wcConfig } from '../../../workers-compensation.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { PopoverContentComponent } from '../../../../../common/index';

@Component({
    moduleId: module.id,
    selector: 'slx-wc-report-attachments',
    templateUrl: 'wc-report-attacments.component.html',
    styleUrls: ['wc-report-attacments.component.scss']
})
export class WcReportAttachmentsComponent implements OnInit, OnDestroy {

    @Input()
    public editMode: boolean;

    @unsubscribeAll()
    public subscriptions: StringMap<Subscription> = {};

    public get hasFiles(): boolean {
        return _.size(this.files) > 0;
    }

    public errors: { maxFiles?: boolean, maxFileSize?: boolean, fileType?: boolean } = {};
    public acceptedFileTypesInputFormat: string;
    public acceptedFileTypes: string[];
    public maxFileSize: string;
    public maxFiles: number;
    public maxFileSizeBytes: number;
    public acceptedFileTypesReadable: string;
    public attachments: AttachmentDefinition[];
    public files: FileModel[] = [];

    constructor (private management: WCReportManagementService) { }

    public ngOnInit(): void {

        this.maxFiles = wcConfig.files.maxFiles;
        this.maxFileSizeBytes = wcConfig.files.maxFileSizeBytes;
        this.acceptedFileTypes = wcConfig.files.acceptedTypes;
        this.acceptedFileTypesInputFormat = wcConfig.files.acceptedTypes.join(',');
        this.acceptedFileTypesReadable = wcConfig.files.acceptedTypes.join(', ');
        this.maxFileSize = this.getReadableBytes(wcConfig.files.maxFileSizeBytes);

        this.subscriptions.onLoad = this.management.onLoaded$.subscribe(() => {
            this.files = this.getAllFiles();
            this.management.onFileChangeNotify(false);
        });
    }

    public ngOnDestroy(): void {}

    public async onFileChange(event: MouseEvent): Promise<void> {
        if (!this.editMode) {
            return;
        }
        const fileList: FileList = _.get(event, 'target.files');
        const files = this.validateFiles(fileList, event);

        if (files.length > 0) {
            const readFiles = await this.management.readAddedFiles(files);
            this.management.addFilesToSave(readFiles);
            this.files = [...this.files, ...this.mapToFiles(readFiles)];
        }
        this.management.onFileChangeNotify(true);
    }

    public onResetErrors(event: any): void {
        this.resetInput(event.target);

        this.errors.maxFiles = false;
        this.errors.maxFileSize = false;
        this.errors.fileType = false;
    }

    public async onClickDelete(isDelete: boolean, acceptPopover: PopoverContentComponent, file: FileModel): Promise<void> {
        if (!this.editMode) {
            return;
        }

        acceptPopover.hide();

        if (isDelete) {
            this.management.changeLoading(true);
            if (file.isAttachment) {
                await this.removeAttachment(file);
            } else {
                await this.removeFileToSave(file);
            }
            this.management.changeLoading(false);
            this.management.onFileChangeNotify(true);
        }
    }

    public async onDownloadFile(file: FileModel): Promise<void> {
        if (!this.isDownloadable(file)) {
            return;
        }

        this.management.downloadAttachment(file.id);
    }

    public isDownloadable(file: FileModel): boolean {
        return file.isAttachment;
    }

    private getAllFiles(): FileModel[] {
        const attachments = this.getAttachments();
        const filestToSave = this.getFilesToSave();

        return [...attachments, ...filestToSave];
    }

    private getAttachments(): FileModel[] {
        const attachments = this.management.getAttachments();
        return this.mapToFiles(attachments);
    }

    private getFilesToSave(): FileModel[] {
        const addedFiles = this.management.getFilesToSave();
        return this.mapToFiles(addedFiles);
    }

    private mapToFiles(files: AttachmentDefinition[] | ReadFile[]): FileModel[] {
        var userName = this.management.getUserName();
        return _.map(files, (f: AttachmentDefinition | ReadFile) => new FileModel(f, userName));
    }

    private async removeAttachment(file: FileModel): Promise<void> {
        await this.management.deleteAttachment(file.id);
        this.files = _.filter(this.files, (f: FileModel) => f.id !== file.id);
    }

    private async removeFileToSave(file: FileModel): Promise<boolean> {
        this.management.deleteFileToSave(file.sourceItem as ReadFile);
        this.files = _.filter(this.files, (f: FileModel) => f.name !== file.name);

        return of(true).pipe(delay(500)).toPromise();
    }

    private validateFiles(fileList: FileList, event: MouseEvent): File[] {
        let isError = false;
        const files: File[] = [];
        if (fileList.length > this.maxFiles) {
            isError = true;
            this.setError('maxFiles');
        }

        for (let i = 0, length = fileList.length; i < length; i++) {
            const file = fileList.item(i);
            if (file.size > this.maxFileSizeBytes) {
                isError = true;
                this.setError('maxFileSize');
                break;
            }
            let fileExt = `.${_.last(file.name.split('.'))}`;
            fileExt = fileExt.toLowerCase();
            if (!_.includes(this.acceptedFileTypes, fileExt)) {
                isError = true;
                this.setError('fileType');
                break;
            }
            files.push(file);
        }
        this.resetInput(event.target);

        return isError ? [] : files;
    }

    private setError(errorType: string): void {
        this.errors.maxFiles = false;
        switch (errorType) {
            case 'maxFiles':
                this.errors.maxFiles = true;
                break;
            case 'maxFileSize':
                this.errors.maxFileSize = true;
                break;
            case 'fileType':
                this.errors.fileType = true;
                break;
        }
    }

    private getReadableBytes(bytes: number): string {
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const res = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));

        return `${res * 1} ${sizes[i]}`;
    }

    private resetInput(elem: any): void {
        elem.value = '';
    }
}

import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { process } from '@progress/kendo-data-query';

import { Subscription } from 'rxjs';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { IDateRange } from '../../../../../core/models/index';
import { OrgLevel } from '../../../../../state-model/models/index';

import { LMRosterManagementService, LMManagementService } from '../../../services/index';

import { KendoGridStateHelper, LoaCategory } from '../../../../../common/models/index';
import { LoaRequest, LoaMappedRequest, LoaRequestContainer } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-calendar-view',
  templateUrl: 'lm-calendar-view.component.html',
  styleUrls: ['lm-calendar-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LMCalendarViewComponent implements OnInit, OnDestroy {
  public records: LoaMappedRequest[];
  public storedRecords: LoaMappedRequest[];
  public isLoading: boolean = true;
  public gridState: KendoGridStateHelper<LoaMappedRequest>;
  public pageSize: number = 30;
  public dateRange: IDateRange;
  public daysLength: number;
  public isDayView: boolean = true;
  public appConfig: IApplicationConfig = appConfig;

  private selectedCategories: LoaCategory[];
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private manRosterService: LMRosterManagementService,
    private manService: LMManagementService,
    private cdf: ChangeDetectorRef
  ) {
    this.gridState = new KendoGridStateHelper<LoaMappedRequest>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'loaRequest.estStartDate', dir: 'desc' }];
  }

  public ngOnInit(): void {
    this.subscriptions.loaded = this.manRosterService
      .subscribeToRequestsLoaded((cont: LoaRequestContainer) => {
        this.assignRecords(cont.requests);
      });

    this.subscriptions.newLoaRequest = this.manService
      .subscribeToLoadRequests(() => this.manRosterService.loadLoaRequests());

    this.subscriptions.categoryFilter = this.manRosterService
      .subscribeToCategoryFilter((categories: LoaCategory[]) => this.applyFilter(categories));

    this.subscriptions.dateRange = this.manRosterService
      .subscribeToDateRange((r: IDateRange) => this.assignDateRanges(r));

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.updateRecords());


    this.subscriptions.modeView = this.manRosterService
      .subscribeToCalenderMode((isDay: boolean) => {
        this.isDayView = isDay;
        this.daysLength = isDay ? 1 : 7;
        this.updateRecords();
      });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onOpenLoaRequestDialog(req: LoaMappedRequest): void {
    this.manService.openCreationDialog(null, req.loaRequest);
  }

  public getStartDate(req: LoaMappedRequest): Date {
    const sDate = req.loaRequest.actStartDate;
    return _.isDate(sDate) ? sDate : req.loaRequest.estStartDate;
  }

  public getEndDate(req: LoaMappedRequest): Date {
    const sDate = req.loaRequest.actEndDate;
    const lastRange = _.last(_.last(_.values(req.absenceDays)));
    const lastDay = _.get(lastRange, 'endDate') || null;
    return _.isDate(sDate) ? sDate : lastDay;
  }

  private assignRecords(records: LoaRequest[]): void {
    this.records = this.manRosterService.mapRequests(records);
    this.storedRecords = _.clone(this.records);
    this.updateRecords();
  }

  private applyFilter(categories: LoaCategory[]): void {
    this.selectedCategories = categories;
    this.updateRecords();
  }

  private assignDateRanges(range: IDateRange): void {
    this.dateRange = range;
    this.updateRecords();
  }

  private updateRecords(): void {
    this.refreshGrid();
    this.cdf.markForCheck();
  }

  private refreshGrid(): void {
    if (_.size(this.selectedCategories) > 0) {
      this.records = _.filter(this.storedRecords, (r: LoaMappedRequest) => {
        let res = false;
        _.forEach(this.selectedCategories, (category: LoaCategory) => {
          res = res || _.includes(r.loaRequest.loaCategories, category);
        });
        return res;
      });
    } else {
      this.records = _.clone(this.storedRecords);
    }

    if (_.size(this.records) > 0) {
      this.gridState.view = process(this.records, this.gridState.state);
      return;
    }

    this.gridState.view = null;
  }
}

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { LookupService } from '../../../../../organization/services/index';
import { Lookup, LookupType } from '../../../../../organization/models/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { IFilteredItems, ServerCompositeFilterDescriptor, PagingData } from '../../../../../core/models/index';
import { AuditTrailEmployeeAction } from '../../../models/index';
import { orderBy, filterBy, State, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { ServerFilterService } from '../../../../../core/services/index';
import { KendoGridStateHelper, ScrollWatchEvent } from '../../../../../common/models/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { AuditTrailManagementService } from '../../../services/audit-trail/audit-trail-management.service';


@Component({
  moduleId: module.id,
  selector: 'slx-audit-trail-limited-grid',
  templateUrl: 'audit-trail-limited-grid.component.html',
  styleUrls: ['audit-trail-limited-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditTraiLimitedGridComponent implements OnInit, OnDestroy {
  @Input()
  public set gridView(value: IFilteredItems<AuditTrailEmployeeAction>) {
    this.records = value;
    this.refreshGrid();
  }
  
  @Output()
  public gridPageChanged: EventEmitter<PagingData> = new EventEmitter();

  public originalActions: AuditTrailEmployeeAction[];
  public pageSize: number = 50;
  public gridState: KendoGridStateHelper<AuditTrailEmployeeAction>;
  public appConfig: IApplicationConfig;
  public useTZnotOffset: boolean = false;
  public currentTimezoneCode:string;
  public useTZnotOffsetSubscription:Subscription;
  public currentTimezoneCodeSubscription:Subscription;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  private records: IFilteredItems<AuditTrailEmployeeAction>;

  constructor(private changeDetector: ChangeDetectorRef, private auditTrialManagementService: AuditTrailManagementService) {
    this.gridState = new KendoGridStateHelper<AuditTrailEmployeeAction>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.group = [];
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.useTZnotOffsetSubscription = this.auditTrialManagementService.useTZnotOffset$.subscribe((value:boolean)=> {
      this.useTZnotOffset = value;
    });
    this.currentTimezoneCodeSubscription = this.auditTrialManagementService.currentTimezoneCode$.subscribe((value:string)=>{
      this.currentTimezoneCode = value;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.useTZnotOffsetSubscription.unsubscribe();
    this.currentTimezoneCodeSubscription.unsubscribe();
  }

  public pageChanged(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.gridPageChanged.emit({ take: event.take, skip: event.skip });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.state.sort = sort;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public filterChange(filter: any): void {
    this.gridState.state.filter = filter;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = { data: null, total: null };
    let filtered: AuditTrailEmployeeAction[] = filterBy(this.records.items, this.gridState.state.filter);
    this.gridState.view = { data: orderBy(filtered, this.gridState.state.sort), total: this.records.count };
  }
}

import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../../services/index';
import { ActivatedRoute } from '@angular/router';

@Component({
    moduleId: module.id,
    selector: 'sso-error',
    templateUrl: 'sso-error.component.html',
    styleUrls: ['sso-error.component.scss']
})
export class SsoErrorComponent implements OnInit {
  errorCode:any;
  errorMessage:any;
  ssoErrorMessages:any[]= [
    {
      "errorCode": 200,
      "errorMsg": "Generic error with no error detail available."
    },
    {
      "errorCode": 201,
      "errorMsg": "The IDP name is invalid – or – configuration for the IDP is invalid – or – the IDP’s discovery document cannot be retrieved."
    },
    {
      "errorCode": 202,
      "errorMsg": "The discovery document indicates missing support for required features for SSO implementation to work."
    },
    {
      "errorCode": 203,
      "errorMsg": "The SSO callback request has no content."
    },
    {
      "errorCode": 204,
      "errorMsg": "There is no id_token field in the SSO callback request content."
    },
    {
      "errorCode": 205,
      "errorMsg": "The token failed validation (signature, expiration, issuer, etc.)"
    },
    {
      "errorCode": 206,
      "errorMsg": "Failed to retrieve the IDP’s JSON Web Keys."
    },
    {
      "errorCode": 207,
      "errorMsg": "Failed to create a session for the user."
    },
    {
      "errorCode": 208,
      "errorMsg": "The token contains no claims."
    },
    {
      "errorCode": 209,
      "errorMsg": "The subject claim is missing from the token."
    },
    {
      "errorCode": 210,
      "errorMsg": "The customer claim is missing from the token."
    },
    {
      "errorCode": 211,
      "errorMsg": "The given_name claim is missing from the token."
    },
    {
      "errorCode": 212,
      "errorMsg": "The family_name claim is missing from the token."
    },
    {
      "errorCode": 213,
      "errorMsg": "The roles claim is missing from the token."
    },
    {
      "errorCode": 214,
      "errorMsg": "The email claim contains an invalid address."
    },
    {
      "errorCode": 215,
      "errorMsg": "The roles claim is empty, or it cannot be deserialized."
    },
    {
      "errorCode": 216,
      "errorMsg": "A company claim cannot be mapped to a client ID."
    },
    {
      "errorCode": 217,
      "errorMsg": "The internal mapping API call failed."
    },
    {
      "errorCode": 218,
      "errorMsg": "A pay group or payroll cannot be found."
    },
    {
      "errorCode": 219,
      "errorMsg": "The internal pay group / payroll lookup API failed."
    },
    {
      "errorCode": 220,
      "errorMsg": "A role specified in a name claim cannot be found."
    },
    {
      "errorCode": 221,
      "errorMsg": "The internal call to create a user account failed."
    },
    {
      "errorCode": 222,
      "errorMsg": "The internal call to update a user account failed."
    },
    {
      "errorCode": 223,
      "errorMsg": "User account information is missing or corrupted."
    },
    {
      "errorCode": 224,
      "errorMsg": "Both accessiblePaygroups and accessiblePayrolls are empty in a roles claim."
    },
    {
      "errorCode": 225,
      "errorMsg": "User account information cannot be loaded."
    },
    {
      "errorCode": 226,
      "errorMsg": "One or more values in accessiblePaygroups / accessiblePayrolls do not belong to the client specified in a roles claim."
    },
    {
      "errorCode": 227,
      "errorMsg": "Invalid / missing configuration for the IDP."
    }
  ]
  constructor(private authenticationService: AuthenticationService, private activatedRoute: ActivatedRoute) {}

  public navigateToLogin(): void {
    this.authenticationService.navigateToLogin();
  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params:any)=>{
      if(+params.e>=200 && +params.e<=226){
        this.errorCode = +params.e;
        let error = this.ssoErrorMessages.find(x=>x.errorCode === this.errorCode);
        this.errorMessage = error.errorMsg;
      }else{
        this.errorCode = params.e;
        this.errorMessage = "Something went wrong, Please try again Later."
      }
    })
  }
}

import { Directive, ElementRef, Input, ContentChild, AfterContentInit, SimpleChanges, OnChanges } from '@angular/core';
import { Validators, NgModel } from '@angular/forms';
import { IFieldPolicy } from '../../../core/models/index';
import { CommonValidators } from '../../validators/common-validators';
import * as _ from 'lodash';

@Directive({
  selector: '[inputPolicy][formControlName],[inputPolicy][formControl],[inputPolicy][ngModel]',
})
export class InputPolicyDirective implements AfterContentInit, OnChanges {
  @Input()
  public inputPolicy: IFieldPolicy;

  private elementRef: ElementRef;
  private staticValidators: any[];

  private model: NgModel;

  constructor(elementRef: ElementRef, model: NgModel) {
    this.elementRef = elementRef;
    this.model = model;
    this.staticValidators = [];
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputPolicy'].currentValue) {
      this.setNativePolicies(this.inputPolicy);
    }
  }
  public ngAfterContentInit(): void {
    let m: any = this.model;
    this.staticValidators = [];
    _.forEach(m._rawValidators, (v: any) => {
      this.staticValidators.push(v);
    });
  }
  private setNativePolicies(policy: IFieldPolicy): void {
    if (!this.elementRef || !this.elementRef.nativeElement || !this.model) {
      return;
    }

    let valueAccessor: any = this.model.valueAccessor;
    let validators: any[] = [];

    if (policy.isEditable) {
      _.forEach(this.staticValidators, (v: any) => {
        validators.push(v);
      });

      if (policy.isRequired) {
        this.elementRef.nativeElement.setAttribute('required', true);
        validators.push(Validators.required);
        valueAccessor.required = true;
      }
      if (policy.minValue) {
        validators.push(CommonValidators.min(policy.minValue));
      }
      if (policy.maxValue) {
        validators.push(CommonValidators.max(policy.maxValue));
      }
    } else {
      this.elementRef.nativeElement.setAttribute('readonly', true);
      valueAccessor.readonly = true;
    }

    this.model.control.setValidators(null);
    if (validators.length > 0) {
      this.model.control.setValidators(validators);
    }
  }
}

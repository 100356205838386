import { Component, Provider, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeEditPhoto } from '../../../models/employee-edit-photo';
import { PhotoCropperComponent } from '../../../../../common/components/photo-cropper/photo-cropper.component';
import { employeeConfig } from '../../../employee.config';

import { CroppieOptions } from 'croppie';

import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-edit-photo-dialog',
  templateUrl: 'employee-edit-photo-dialog.component.html',
  styleUrls: ['employee-edit-photo-dialog.component.scss']
})
export class EmployeeEditPhotoDialogComponent implements IDialog, OnInit {
  public dialogResult: boolean;
  public fileType: string[];
  public canCropping: boolean;
  public canMakePhoto: boolean;
  public errors: { uploadFormat: boolean, imageFormat: boolean, photoError: boolean };
  public errorMessage: string;

  @ViewChild(PhotoCropperComponent)
  public photoCropper: PhotoCropperComponent;
  public cropperOptions: CroppieOptions;

  @ViewChild('croppieContainer', {static: true})
  public croppieContainer: ElementRef;
  private employee: EmployeeEditPhoto;
  private options: DialogOptions;
  private modalService: ModalService;
  private photoUploaded: boolean;

  public static openDialog(req: EmployeeEditPhoto, modalService: ModalService, callback: (result: boolean, req: Promise<string>) => void): EmployeeEditPhotoDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 550;
    dialogOptions.width = 850;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: EmployeeEditPhoto,
        useValue: req
      }
    ];
    let dialog: EmployeeEditPhotoDialogComponent = modalService.globalAnchor.openDialog(
      EmployeeEditPhotoDialogComponent,
      'Edit employee photo',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result, dialog.getCroppedImage());
    });

    return dialog;
  }

  constructor(
    employee: EmployeeEditPhoto,
    options: DialogOptions,
    modalService: ModalService
  ) {
    this.employee = employee;
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
    this.fileType = employeeConfig.photo.type;
    this.cropperOptions = employeeConfig.cropper;
    this.errors = { uploadFormat: false, imageFormat: false, photoError: false };
    this.photoUploaded = false;
    this.canMakePhoto = false;
    this.errorMessage = '';
    this.setupPhotoEditor();
  }

  public ngOnInit(): void {
    const maxWidth: number = this.getWidth(this.croppieContainer.nativeElement);
    const maxHeight: number = this.getHeight(this.croppieContainer.nativeElement);

    if (this.cropperOptions.boundary.width > maxWidth) {
      this.cropperOptions.boundary.width = maxWidth;
    }
    if (this.cropperOptions.boundary.height > maxHeight) {
      this.cropperOptions.boundary.height = maxHeight;
    }
  }

  public get fileTypesFieldFormat(): string {
    return this.fileType.join(',');
  }

  public onFileClick(event: any): void {
    const elem: HTMLInputElement = event.target;
    elem.value = '';
    this.resetError();
  }

  public onFileChange(event: Event): void {
    const files: Blob[] = _.get(event, 'target.files');
    const file: Blob = files[0];

    if (!file || !_.includes(this.fileType, file.type)) {
      this.setError('uploadFormat');

      return;
    }

    this.readImage(file);
  }

  public onApply(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onMakePhoto(): void {
    this.canMakePhoto = true;
    this.canCropping = false;
  }

  public onMadePhoto(dataUrl: string): void {
    this.canMakePhoto = false;
    this.employee.photo.src = dataUrl;
    this.photoUploaded = this.canCropping = true;
    this.resetError();
  }

  public onErrorPhoto(errorText: string): void {
    this.canMakePhoto = false;
    this.errorMessage = errorText;
    this.setError('photoError');
  }

  public getCroppedImage(): Promise<string> {
    if (this.photoCropper) {
      return this.photoCropper.getCroppedImage();
    }

    return Promise.resolve('');
  }

  private setError(errorType: string): void {
    this.errors.uploadFormat = false;
    this.errors.imageFormat = false;
    this.errors.photoError = false;
    switch(errorType) {
      case 'uploadFormat':
        this.errors.uploadFormat = true;
        break;
      case 'imageFormat':
        this.errors.imageFormat = true;
        break;
      case 'photoError':
        this.errors.photoError = true;
        break;
    }
  }

   private resetError(): void {
    this.errors.uploadFormat = false;
    this.errors.imageFormat = false;
    this.errors.photoError = false;
  }

  private isValidPhoto(width: number, height: number): boolean {
    return width > employeeConfig.photo.minWidth && height > employeeConfig.photo.minHeight;
  }

  private setupPhotoEditor(): void {
    this.canCropping = this.isValidPhoto(this.employee.photo.width, this.employee.photo.height);
    if (!this.canCropping) {
      this.setError('imageFormat');
    }
  }

  private readImage(file: Blob): void {
    const fileReader: FileReader = new FileReader();
    fileReader.onloadend = (): void => {
      this.getImageSize(<any>fileReader.result)
      .then((size) => {
        if (this.isValidPhoto(size.width, size.height)) {
          this.employee.photo.src = <any>fileReader.result;
          this.photoUploaded = this.canCropping = true;
          this.resetError();

          return;
        }

        this.setError('uploadFormat');
      });
    };
    fileReader.readAsDataURL(file);
  }

  private getImageSize(dataUri: string): Promise<any> {
    let resolver: Function;
    const promise: Promise<any> = new Promise((res) => (resolver = res));
    const image: HTMLImageElement = new Image();
    image.onload = (): void => {
      resolver({ width: image.width, height: image.height });
    };
     image.onerror = (): void => {
      resolver({ width: 0, height: 0 });
    };
    image.src = dataUri;

    return promise;
  }

  private getWidth(elem: HTMLElement): number {
    const style: CSSStyleDeclaration = getComputedStyle(elem);
    const offsets: number = this.toNumber(style.paddingLeft) + this.toNumber(style.paddingRight) +
    this.toNumber(style.borderLeftWidth) + this.toNumber(style.borderRightWidth);

    return elem.offsetWidth - offsets;
  }

  private getHeight(elem: HTMLElement): number {
    const style: CSSStyleDeclaration = getComputedStyle(elem);
    const offsets: number = this.toNumber(style.paddingTop) + this.toNumber(style.paddingBottom) +
    this.toNumber(style.borderTopWidth) + this.toNumber(style.borderBottomWidth);

    return elem.offsetHeight - offsets;
  }

  private toNumber(item: any): number {
    const num: number = parseInt(item);

    return _.isNaN(num) ? 0 : num;
  }
}

export * from './employee-search-api.service';
export * from './employee-search-map.service';

import { Provider } from '@angular/core';
import { EmployeeSearchApiService } from './employee-search-api.service';
import { EmployeeSearchMapService } from './employee-search-map.service';

export const services: Provider[] = [
    EmployeeSearchApiService, EmployeeSearchMapService
];

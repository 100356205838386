import * as _ from "lodash";
import { IColumnSettings } from "../../../../core/models/index";

export class DuplicateWorkercolumnState {
    public name : string;
    public isDisplayed : boolean;
    public columnsMap: StringMap<IColumnSettings>;
    public columns: IColumnSettings[];
    public createColumns(): void {
    this.columns = [
      {
        name: 'disposition',
        description: 'Disposition',
        displayed: true,
        reOrderedIndex:0,
        filter: 'text',
        width :150
      },
      {
        name: 'disposition_Date',
        description: 'Disposition Date',
        displayed: true,
        reOrderedIndex:1,
        filter: 'date',
        width :150
      },
      {
        name: 'matching_score',
        description: 'Match Score',
        displayed: true,
        reOrderedIndex:2,
        filter: 'text',
        width :200
      },
      {
        name: 'location',
        description: 'Location',
        displayed: true,
        reOrderedIndex:3,
        filter: 'text',
        width:120
      },
      {
        name: 'pbJ_ID_1',
        description: 'PBJ ID 1',
        displayed: true,
        reOrderedIndex:4,
        filter: 'text',
        width :200
      },
      {
        name: 'last_name_1',
        description: 'Last Name 1',
        displayed: true,
        reOrderedIndex:5,
        filter: 'text',
        width :200
      },
      {
        name: 'first_Name_1',
        description: 'First Name 1',
        displayed: true,
        reOrderedIndex:6,
        filter: 'text',
        width :200
      },
      {
        name: 'email_1',
        description: 'Email 1',
        displayed: true,
        reOrderedIndex:7,
        filter: 'text',
        width :200
      },
      {
        name: 'phone_1',
        description: 'Mobile Phone 1',
        displayed: true,
        reOrderedIndex:8,
        filter: 'text',
        width :200
      },
      {
        name: 'birthday_1',
        description: 'Birthdate 1',
        displayed: true,
        reOrderedIndex:9,
        filter: 'date',
        width :200
      },
      {
        name: 'ssN_1',
        description: 'SSN 1',
        displayed: true,
        reOrderedIndex:10,
        filter: 'text',
        width :200
      },
      {
        name: 'primary_Location_1',
        description: 'Primary Location 1',
        displayed: true,
        reOrderedIndex:11,
        filter: 'text',
        width :200
      },
      {
        name: 'pbJ_ID_2',
        description: 'PBJ ID 2',
        displayed: true,
        reOrderedIndex:12,
        filter: 'text',
        width :200
      },
      {
        name: 'last_name_2',
        description: 'Last Name 2',
        displayed: true,
        reOrderedIndex:13,
        filter: 'text',
        width :200
      },
      {
        name: 'first_Name_2',
        description: 'First Name 2',
        displayed: true,
        reOrderedIndex:14,
        filter: 'text',
        width :200
      },
      {
        name: 'email_2',
        description: 'Email 2',
        displayed: true,
        reOrderedIndex:15,
        filter: 'text',
        width :200
      },
      {
        name: 'phone_2',
        description: 'Mobile Phone 2',
        displayed: true,
        reOrderedIndex:16,
        filter: 'text',
        width :200
      },
      {
        name: 'birthday_2',
        description: 'Birthdate 2',
        displayed: true,
        reOrderedIndex:17,
        filter: 'date',
        width :200
      },
      {
        name: 'ssN_2',
        description: 'SSN 2',
        displayed: true,
        reOrderedIndex:18,
        filter: 'text',
        width :200
      },
      {
        name: 'primary_Location_2',
        description: 'Primary Location 2',
        displayed: true,
        reOrderedIndex:19,
        filter: 'text',
        width :200
      },
    ];
    this.mapColumns();
  }
  public mapColumns(): void {
    this.columnsMap = _.keyBy(this.columns, (column: IColumnSettings) => {
      return column.name;
    });
  }
}
export * from './punch-profile/punch-profile.component';
import { PunchProfileAttestationQuestionsComponent } from './punch-profile-attestation-questions/punch-profile-attestation-questions.component';
import { PunchProfileAttestationComponent } from './punch-profile-attestation/punch-profile-attestation.component';
import { PunchProfileComponent } from './punch-profile/punch-profile.component';


export const components: any[] = [
  PunchProfileComponent,
  PunchProfileAttestationComponent,
  PunchProfileAttestationQuestionsComponent
];

export const entryComponents: any[] = [
  PunchProfileAttestationQuestionsComponent
];

import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { ReplaySubject ,  Subject ,  Observable ,  Subscription } from 'rxjs';
import { process } from '@progress/kendo-data-query';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';

import {
  IndividualTimecardsContainer,
  TimecardsAction,
  IndividualTimecardsState,
  TimecardFlatRecord
} from '../../models/index';
import { PayCycle, PayRuleDefinition } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupApiService, ApplicationStateBusService } from '../../../organization/services/index';
import { EmployeeSectionsAccrualsApiService } from '../../../employee/employee-sections/services/index';
import { EmployeeSectionAccrualsBalances } from '../../../employee/employee-sections/models/index';
import { TimecardsApiService } from './timecards-api.service';
import { IIndividualTimecardsState, ITimecardsDisplayState, TimecardsActions } from '../../store/index';
import { IDestroyService } from '../../../core/models/index';
import { ConstraintsApiService } from '../../../organization/services/index';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class IndividualTimecardsManagementService implements IDestroyService {
  @mutableSelect(['timecards', 'individualTimecards'])
  public timecards$: Observable<IIndividualTimecardsState>;

  @mutableSelect(['timecards', 'timecardsDisplay'])
  public timecardsDisplay$: Observable<ITimecardsDisplayState>;


  public onActionCmd$: ReplaySubject<TimecardsAction>;
  public onLoadStatus$: ReplaySubject<boolean>;
  public onLoaded$: ReplaySubject<IndividualTimecardsContainer>;
  public onEmployeeLoaded$: ReplaySubject<TimecardFlatRecord[]>;
  public onStateChanged$: ReplaySubject<IndividualTimecardsState>;
  public accrualsLoaded$: Subject<EmployeeSectionAccrualsBalances>;
  public activeEmployee$: ReplaySubject<TimecardFlatRecord>;
  public currentEmployeeId: number;
  public currentEmployeeIndex: number;
  public currentPayCycle: PayCycle;
  public container: IndividualTimecardsContainer;
  public state: IndividualTimecardsState;
  public orgLevel: OrgLevel;
  public employees: TimecardFlatRecord[] = [];

  @unsubscribeInService()
  private timecardsSubscription: Subscription;
  @unsubscribeInService()
  private employeeSubscription: Subscription;
  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  private apiService: TimecardsApiService;
  private lookupApiService: LookupApiService;
  private empSectionsApi: EmployeeSectionsAccrualsApiService;
  private timecardActions: TimecardsActions;
  private busService: ApplicationStateBusService;
  private settings: IIndividualTimecardsState;

  constructor(
    apiService: TimecardsApiService,
    private constraintService: ConstraintsApiService,
    lookupApiService: LookupApiService,
    timecardActions: TimecardsActions,
    busService: ApplicationStateBusService,
    empSectionsApi: EmployeeSectionsAccrualsApiService,
    private route: ActivatedRoute
  ) {
    this.apiService = apiService;
    this.lookupApiService = lookupApiService;
    this.busService = busService;
    this.empSectionsApi = empSectionsApi;
    this.orgLevelSubscription = this.busService.subscribeToSelectOrgLevel((orgLevel: OrgLevel) => {
      this.orgLevel = orgLevel;
    });
    this.timecardActions = timecardActions;
    this.onActionCmd$ = new ReplaySubject(1);
    this.onLoadStatus$ = new ReplaySubject(1);
    this.onLoaded$ = new ReplaySubject(1);
    this.onEmployeeLoaded$ = new ReplaySubject(1);
    this.onStateChanged$ = new ReplaySubject(1);
    this.activeEmployee$ = new ReplaySubject(1);
    this.accrualsLoaded$ = new Subject<EmployeeSectionAccrualsBalances>();
    this.currentEmployeeId = parseInt(this.route.snapshot.params['employeeId']);

    this.timecardsSubscription = this.timecards$
      .subscribe((settings: IIndividualTimecardsState) => {
        this.settings = settings;
        let state: IndividualTimecardsState = new IndividualTimecardsState();
        if (!settings.empColumnStates || settings.empColumnStates['firstInit']) {
          state.createEmpColumns();
          state.empColumns.mapColumns();
          this.timecardActions.changeIndividualTimecardsSettings(state);
        } else {
          state.isShowPayRates = settings.isShowPayRates;
          state.isShowHighPrecision = settings.isShowHighPrecision;
          state.createEmpColumns();
          state.empColumns.setState(settings.empColumnStates, false);
          state.empColumns.mapColumns();
        }
        if (this.container) {
          this.recreatePayCodeColumns(state, this.container.usedRulesDefinitions);
        }
        this.onStateChanged(state);
      });

    this.employeeSubscription = this.timecardsDisplay$
      .subscribe((displaySettings: ITimecardsDisplayState) => {
        if (_.get(displaySettings, 'gridState.records')) {
          const tempState = _.cloneDeep(displaySettings.gridState);

          // Reset skip and take so we always get the entire list instead of paged list
          tempState.state.skip = 0;
          tempState.state.take = tempState.records.length;

          if (_.get(tempState, 'state.group')) {
            if (!tempState.state.sort) {
              tempState.state.sort = [];
            }

            tempState.state.group.forEach(group => {
              tempState.state.sort.unshift({
                field: group.field,
                dir: group.dir || 'asc'
              });
            });

            tempState.state.group = undefined;
          }

          this.employees = process(tempState.records, tempState.state).data;
          this.onEmployeeLoaded$.next(this.employees);
          this.currentEmployeeIndex = this.employees.map(e => e.emp.employeePosition.employee.id || -1).indexOf(this.currentEmployeeId);

          if (this.employees[this.currentEmployeeIndex]) {
            this.activeEmployee$.next(this.employees[this.currentEmployeeIndex]);
          }
        }
      });
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public hasPrevious(): boolean {
    if (this.employees.length > 0) {
      // 145759 : Skipping if the prev time row card employee is same as current one
      return this.getEmployeeIdByIndex(false) !== -1 ? true : false;;
    }
    return false;
  }

  public hasNext(): boolean {
    if (this.employees.length > 0) {
      // 145759 : Skipping if the next time row card employee is same as current one
      return this.getEmployeeIdByIndex(true) !== -1 ? true : false;
    }
    return false;
  }

  public getEmployees(): TimecardFlatRecord[] {
    return this.employees;
  }

  public resetCurrentEmployee(newEmployeeId: number) {
    this.currentEmployeeId = newEmployeeId;
    this.currentEmployeeIndex = this.employees.map(e => e.emp.employeePosition.employee.id).indexOf(this.currentEmployeeId);

    if (this.employees[this.currentEmployeeIndex]) {
      this.timecardActions.changeSelectedEmployeeId(this.currentEmployeeId);
      this.activeEmployee$.next(this.employees[this.currentEmployeeIndex]);
    }
  }

  public getEmployeeIdByIndex(isNext: boolean): number {
    const step: number = isNext ? 1 : -1;

    if (this.currentEmployeeIndex >= 0 && this.currentEmployeeIndex + step >= 0) {
      let newEmployeeIndex = this.currentEmployeeIndex + step;
      let newEmployeeId = _.get(this.employees[newEmployeeIndex], 'emp.employeePosition.employee.id', -1);

      // In the case an employee has multiple rows, we skip as all that employees timecards will be pulled onto the same page
      while (this.currentEmployeeId === newEmployeeId && newEmployeeIndex > 0 && newEmployeeIndex < this.employees.length - 1) {
        newEmployeeIndex = newEmployeeIndex + step;
        newEmployeeId = this.employees[newEmployeeIndex].emp.employeePosition.employee.id || -1;
      }

      if (this.currentEmployeeId !== newEmployeeId  && newEmployeeIndex >= 0 && newEmployeeIndex < this.employees.length) {
        return this.employees[newEmployeeIndex].emp.employeePosition.employee.id || -1;
      }
    }

    return -1;
  }

  public loadIndividualTimecards(employeeId: number, payCycle: PayCycle): void {

    this.resetCurrentEmployee(employeeId);

    this.apiService.getIndividualTimecards(employeeId, payCycle)
      .then((container: IndividualTimecardsContainer) => {
        this.container = container;
        this.constraintService.isPayrollSubmittedForEmployee(employeeId, payCycle.startDate, payCycle.endDate)
          .then((res: boolean) => {
            this.container.isPayCycleSubmitted = res;
            this.recreatePayCodeColumns(this.state, container.usedRulesDefinitions);
            this.onLoaded(container);
            this.onLoadStatusChanged(false);
          })
          .catch((reason: any) => {
            this.onLoadStatusChanged(false);
          });
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public approveTimecards(): void {
    this.onLoadStatusChanged(true);
    this.apiService.approveTimecard(this.container.employee.id, this.currentPayCycle)
      .then((result: any) => {
        this.loadIndividualTimecards(this.container.employee.id, this.currentPayCycle);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public unapproveTimecards(): void {
    this.onLoadStatusChanged(true);
    this.apiService.unapproveTimecard(this.container.employee.id, this.currentPayCycle)
      .then((result: any) => {
        this.loadIndividualTimecards(this.container.employee.id, this.currentPayCycle);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public recalculateTimecards(): void {
    this.onLoadStatusChanged(true);
    let ids: number[] = [this.container.employee.id];
    this.apiService.recalculateTimecards(ids, this.currentPayCycle)
      .then((result: any) => {
        this.loadIndividualTimecards(this.container.employee.id, this.currentPayCycle);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public loadAccruals(empId: number): void {
    this.onLoadStatusChanged(true);
    this.empSectionsApi.getEmployeeBalances(empId)
      .then((accruals: EmployeeSectionAccrualsBalances) => {
        this.onLoadStatusChanged(false);
        this.accrualsLoaded$.next(accruals);
      }).catch(() => {
        this.onLoadStatusChanged(false);
      });
  }

  public onStateChanged(state: IndividualTimecardsState): void {
    this.state = state;
    if(!this.state) {
      this.state = new IndividualTimecardsState();
      this.state.createEmpColumns();
    }
    this.onStateChanged$.next(state);
  }

  public onActionCmd(cmd: TimecardsAction): void {
    this.onActionCmd$.next(cmd);
  }

  public onLoadStatusChanged(isLoading: boolean): void {
    this.onLoadStatus$.next(isLoading);
  }

  public onLoaded(container: IndividualTimecardsContainer): void {
    this.onLoaded$.next(container);
  }

  public onPayCycleChanged(payCycle: PayCycle): void {
    this.currentPayCycle = payCycle;
    if (this.currentEmployeeId !== 0 && this.currentPayCycle) {
      this.loadIndividualTimecards(this.currentEmployeeId, this.currentPayCycle);
    }
  }

  public onEmployeeChange(employeeId: number): void {
    this.currentEmployeeId = employeeId;
    if (this.currentEmployeeId !== 0 && this.currentPayCycle) {
      this.loadIndividualTimecards(this.currentEmployeeId, this.currentPayCycle);
    }
  }

  public onQueryChange(employeeId: number, payCycle: PayCycle): void {
    this.currentEmployeeId = employeeId;
    this.currentPayCycle = payCycle;
    if (this.currentEmployeeId !== 0 && this.currentPayCycle) {
      this.loadIndividualTimecards(this.currentEmployeeId, this.currentPayCycle);
    }
  }

  private recreatePayCodeColumns(state: IndividualTimecardsState, usedPayRules: PayRuleDefinition[]): void {
    if (!this.settings.payCodeColumnStates || this.settings.payCodeColumnStates['firstInit']) {
      state.createPayCodeColumns(usedPayRules);
      state.payCodeColumns.mapColumns();
      this.timecardActions.changeIndividualTimecardsSettings(state);
    } else {
      state.createPayCodeColumns(usedPayRules);
      state.payCodeColumns.setState(this.settings.payCodeColumnStates, true);
      state.payCodeColumns.mapColumns();
    }
  }
}

import { fileProxyConfig, IFileProxyConfig } from './../../file-proxy.config';
import { ApiUtilService } from '../api/api-util.service';
import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';

declare global{
  interface Navigator {
    msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean
  }
}

@Injectable()
export class FileService {
  constructor(private apiUtilService: ApiUtilService) {}

  public saveToFileSystem(b: Blob, filename?: string): void {
    const blob = this.getCorrectBlob(b);
    let reader = new FileReader();

    // To save files that are bigger than 1MB, use a proxy.
    // Otherwise, you might get an Unknown network error error message.
    // https://www.telerik.com/kendo-angular-ui/components/filesaver/
    if (blob.size >= fileProxyConfig.fileSizeForProxy) {
      reader.onload = this.saveFileUsingProxy.bind(this, reader, filename);
    } else {
      reader.onload = this.saveFile.bind(this, reader, filename);
    }

    reader.readAsDataURL(blob);
  }

  private getCorrectBlob(blob: Blob): Blob {
    if (blob.type === '' || blob.size === 0) {
      let type: string = blob.type || 'text/plain';
      let content: string | Blob = blob.size > 0 ? blob : 'No data';

      return new Blob([content], { type });
    }

    return blob;
  }

  private saveFile(reader: FileReader, filename?: string): void {
      saveAs(this.convert(reader.result), filename);
  }


  private saveFileUsingProxy(reader: FileReader, filename?: string): void {
    const url: string = `${this.apiUtilService.getApiRoot()}/${fileProxyConfig.api.root}`;
    saveAs(this.convert(reader.result), filename, {
      proxyURL: url,
      forceProxy: true
    });
  }

  private convert(val: ArrayBuffer|string): Blob|string {
    if (typeof val === 'string') {
      return val;
    } else {
      return new Blob([val]);
    }
  }

  public openOrSavePdf(fileBlobResponse: FileBlobResponse):void{
    var file = new Blob([fileBlobResponse.filename, fileBlobResponse.blob], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(fileBlobResponse.blob, fileBlobResponse.filename + '.' + fileBlobResponse.fileExtension);
    } else {
      window.open(fileURL);
    }
  }
}

import { ReportsComponent } from './reports.component';
import { parametersComponents } from './report-parameters/index';
import { ReportGroupComponent } from './report-group/report-group.component';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportParameterComponent } from './report-parameters/report-parameter/report-parameter.component';
import { GenerateReportDialogComponent } from './generate-report-dialog/generate-report-dialog.component';
import { ParametersListComponent } from './report-parameters/parameters-list/parameters-list.component';
import { ReportDailyAttendanceConfigDialogComponent } from './report-configs-dialog/report-daily-attendance-config-dialog/report-daily-attendance-config-dialog.component';
import { ReportDailyTodayStaffConfigDialogComponent } from './report-configs-dialog/report-daily-today-staff-config-dialog/report-daily-today-staff-config-dialog.component';
import { ReportDailyTodayStaffConfigComponent } from './report-daily-today-staff-config/report-daily-today-staff-config.component';
import { ReportAttendanceConfigComponent } from './report-attendance-config/report-attendance-config.component';
import { W2sListComponent } from './w2s/w2s-list/w2s-list.component';
import { W2sGridComponent } from './w2s/w2s-grid/w2s-grid.component';
import { W2sToolbarComponent } from './w2s/w2s-toolbar/w2s-toolbar.component';
import { RnHrsMinWarningDIalogComponent } from './report-parameters/rn-hrs-min-warning-dialog/rn-hrs-min-warning-dialog.component';
import { BipaReportDialogComponent } from './bipa-report-dialog/bipa-report-dialog.component';
import { BipaReportComponent } from './bipa-report-dialog/bipa-report/bipa-report.component';
import { MidnightToMidnightReportDialogComponent } from './midnight-to-midnight-report-dialog/midnight-to-midnight-report-dialog.component';
import { MidnightToMidnightReportComponent } from './midnight-to-midnight-report-dialog/midnight-to-midnight-report/midnight-to-midnight-report.component';

export { ReportsComponent } from './reports.component';

export const entryComponents: any[] = [
  GenerateReportDialogComponent,
  ReportDailyAttendanceConfigDialogComponent,
  ReportDailyTodayStaffConfigDialogComponent,
  ReportDailyTodayStaffConfigComponent,
  ReportAttendanceConfigComponent,
  RnHrsMinWarningDIalogComponent,
  BipaReportDialogComponent,
  BipaReportComponent,
  MidnightToMidnightReportDialogComponent,
  MidnightToMidnightReportComponent
];

export const components: any[] = [
  ReportsComponent,
  ReportGroupComponent,
  ReportListComponent,
  ReportParameterComponent,
  ParametersListComponent,
  parametersComponents,
  entryComponents,
  W2sListComponent,
  W2sGridComponent,
  W2sToolbarComponent,
];

import { Provider } from '@angular/core';

export * from './schedule/census-entry-api.service';
export * from './schedule/census-entry-map.service';
export * from './detail-screen/detail-screen-api.service';
export * from './detail-screen/detail-screen-map.service';
export * from './schedule/schedule-api.service';
export * from './schedule/schedule-map.service';
export * from './schedule/master-schedule-quick-edit.service';
export * from './schedule-entry/schedule-entry-api.service';
export * from './schedule-entry/schedule-entry-map.service';
export * from './schedule-entry/schedule-entry-management.service';

export * from './vacation-planner/vacation-planner-api.service';
export * from './vacation-planner/vacation-planner-map.service';
export * from './shift-replacement/shift-replacement-api.service';
export * from './shift-replacement/shift-replacement-map.service';
export * from './shift-replacement/shift-replacement-management.service';
export * from './individual-schedule/individual-schedule-api.service';
export * from './individual-schedule/individual-schedule-map.service';
export * from './individual-schedule/individual-schedule-management.service';
export * from './individual-schedule/individual-schedule-emp-management.service';
export * from './open-shift-management/open-shift-management-api.service';
export * from './open-shift-management/open-shift-management-map.service';
export * from './open-shift-management/open-shift-management-management.service';
export * from './schedule/schedule-notification.service';
export * from './leave-request/leave-request-api.service';
export * from './leave-request/leave-request-map.service';
export * from './schedule/master-schedule-management.service';
export * from './schedule/master-schedule-toolbar.service';
export * from './schedule/schedule-daily-details-map.service';
export * from './schedule/schedule-daily-details-api.service';
export * from './schedule/auto-scheduling.service';
export * from './operational-console/operational-console-api.service';
export * from './operational-console/operational-console-map.service';
export * from './operational-console/operational-console-state.service';
export * from './shift-swap/shift-swap-api.service';
export * from './shift-swap/shift-swap-map.service';
export * from './shift-swap/shift-swap-manage.service';
export * from './individual-schedule/paycycle-employee-provider.service';
export * from './open-position-control/open-position-control-management.service';
export * from './open-position-control/open-position-control-api.service';
export * from './open-position-control/open-position-control-map.service';

import { CensusEntryApiService } from './schedule/census-entry-api.service';
import { CensusEntryMapService } from './schedule/census-entry-map.service';
import { DetailScreenService } from './detail-screen/detail-screen-api.service';
import { DetailScreenMapService } from './detail-screen/detail-screen-map.service';
import { ScheduleApiService } from './schedule/schedule-api.service';
import { ScheduleMapService } from './schedule/schedule-map.service';
import { ScheduleEntryApiService } from './schedule-entry/schedule-entry-api.service';
import { ScheduleEntryMapService } from './schedule-entry/schedule-entry-map.service';

import { ScheduleEntryManagementService } from './schedule-entry/schedule-entry-management.service';
import { VacationPlannerApiService } from './vacation-planner/vacation-planner-api.service';
import { VacationPlannerMapService } from './vacation-planner/vacation-planner-map.service';
import { ShiftReplacementApiService } from './shift-replacement/shift-replacement-api.service';
import { ShiftReplacementMapService } from './shift-replacement/shift-replacement-map.service';
import { ShiftReplacementManagementService } from './shift-replacement/shift-replacement-management.service';
import { IndividualScheduleApiService } from './individual-schedule/individual-schedule-api.service';
import { IndividualScheduleMapService } from './individual-schedule/individual-schedule-map.service';
import { OpenShiftManagementApiService } from './open-shift-management/open-shift-management-api.service';
import { OpenShiftManagementMapService } from './open-shift-management/open-shift-management-map.service';
import { OpenShiftManagementManagementService } from './open-shift-management/open-shift-management-management.service';
import { SchedulerNotificationService } from './schedule/schedule-notification.service';
import { LeaveRequestApiService } from './leave-request/leave-request-api.service';
import { LeaveRequestMapService } from './leave-request/leave-request-map.service';
import { ScheduleDailyDetailsMapService } from './schedule/schedule-daily-details-map.service';
import { ScheduleDailyDetailsApiService } from './schedule/schedule-daily-details-api.service';
import { MasterScheduleAutoSchedulingService } from './schedule/auto-scheduling.service';
import { OperationalConsoleApiService } from './operational-console/operational-console-api.service';
import { OperationalConsoleMapService } from './operational-console/operational-console-map.service';
import { OperationalConsoleStateService } from './operational-console/operational-console-state.service';
import { ShiftSwapApiService } from './shift-swap/shift-swap-api.service';
import { ShiftSwapMapService } from './shift-swap/shift-swap-map.service';
import { ShiftSwapManageService } from './shift-swap/shift-swap-manage.service';
import { IndividualScheduleNightShiftService } from './individual-schedule/individual-schedule-night-shift.service';
import { ScheduleRotationsManagementService } from './scheduled-rotation-template/schedule-rotations-management.service';
import { MasterScheduleManagementService } from './schedule/master-schedule-management.service';
import { ScheduleRotationApiService } from './scheduled-rotation-template/scheduled-rotation-api.service';
import { ScheduledRotationMapService } from './scheduled-rotation-template/scheduled-rotation-map.service';
import { OpenPositionControlManagementService } from './open-position-control/open-position-control-management.service';
import { OpenPositionsControlApiService } from './open-position-control/open-position-control-api.service';
import { OpenPositionControlMapService } from './open-position-control/open-position-control-map.service';
import { ScheduledRotationEmployeeGridHelper } from '../components/scheduled-rotations/scheduled-rotations-employee-grid/scheduled-rotations-employee-grid-helper';

export const services: Provider[] = [
  CensusEntryApiService,
  CensusEntryMapService,
  DetailScreenService,
  DetailScreenMapService,
  ScheduleApiService,
  ScheduleMapService,
  ScheduleEntryApiService,
  ScheduleEntryMapService,
  ScheduleEntryManagementService,
  VacationPlannerApiService,
  VacationPlannerMapService,
  ShiftReplacementApiService,
  ShiftReplacementMapService,
  ShiftReplacementManagementService,
  IndividualScheduleApiService,
  IndividualScheduleMapService,
  IndividualScheduleNightShiftService,
  OpenShiftManagementApiService,
  OpenShiftManagementMapService,
  OpenShiftManagementManagementService,
  LeaveRequestApiService,
  LeaveRequestMapService,
  SchedulerNotificationService,
  ScheduleDailyDetailsMapService,
  ScheduleDailyDetailsApiService,
  MasterScheduleAutoSchedulingService,
  OperationalConsoleApiService,
  OperationalConsoleMapService,
  OperationalConsoleStateService,
  ShiftSwapApiService,
  ShiftSwapMapService,
  ShiftSwapManageService,
  ScheduleRotationsManagementService,
  ScheduledRotationEmployeeGridHelper,
  ScheduleRotationApiService,
  ScheduledRotationMapService,
  MasterScheduleManagementService,
  OpenPositionControlManagementService,
  OpenPositionsControlApiService,
  OpenPositionControlMapService
];

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { GroupDescriptor } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { DetailGroup } from '../../../models/index';
import * as _ from 'lodash';

@Component({
  selector: 'slx-export-excel-grid',
  templateUrl: './export-excel-grid.component.html',
  styleUrls: ['./export-excel-grid.component.scss'],
})
export class ExportExcelGridComponent implements OnInit {

  @ViewChild('grid', { static: true })
  public grid: GridComponent;
  
  public enableExport = false;
  @Input()
  public ExportGroupData: DetailGroup[];

  @Input()
  public PartnerIsEnabled: boolean = false;

  @Input()
  public dateOn: Date;

  public excelExportFileName:string;

  ngOnInit() {
    if ( this.ExportGroupData != null ) {
      this.enableExport = true;
    }
    let formatedDate: string = moment(this.dateOn).format(appConfig.serverDateFormat);
    this.excelExportFileName = 'Daily_Unit_Assignment_'+formatedDate+'.xlsx';
  }
  public group: GroupDescriptor[] = [{ field: 'ShiftGroup' }];

  public exportData(): void {
    let element: HTMLElement = document.getElementById('exportExcelDatacls') as HTMLElement;
    element.click();
  }

  public exportToExcelData(grid: GridComponent): void {
    _.each(grid.data['data'], (item) => {
      _.map(item.items, (ele) => {
        let scheduledHours = parseFloat(ele.SchedHours);
        let idealHours = parseFloat(ele.Hours);
        ele.SchedHours = scheduledHours.toFixed(2);
        ele.Hours = idealHours.toFixed(2);
      });
    });
    grid.saveAsExcel();
  }
}

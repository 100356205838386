import { Component, Input, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';

import { ReportParameter } from '../../../models/index';
import { Observable ,  Subscription } from 'rxjs';
import { ReportsManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-boolean-report-parameter',
  templateUrl: 'boolean-report-parameter.component.html',
  styleUrls: ['boolean-report-parameter.component.scss']
})
export class ReportBooleanParameterComponent implements AfterViewInit {
  public includeThousandsSeparator: boolean = null;

  private m_parameter: ReportParameter;
  isChecked: any;

  @Input()
  public set parameter(value: ReportParameter) {
    this.m_parameter = value;
  }

  public get parameter(): ReportParameter {
    return this.m_parameter;
  }

  constructor(private reportManServce: ReportsManagementService ){

  }
  public ngOnInit(): void {
    if(this.parameter.name=="Show_dates" || this.parameter.name== "Zero_hrs") {
       this.parameter.defaultValue = false;
       this.parameter.value =0;
      }
  }
  public ngAfterViewInit(): void {
    Promise.resolve(null).then(() => this.processParameter(this.m_parameter));
  }

  private processParameter(parameter: ReportParameter): void {
    if (!(_.isNull(parameter.defaultValue) || _.isUndefined(parameter.defaultValue))) {
      parameter.value = parameter.defaultValue;
    } else {
      if (_.isNull(parameter.value) || _.isUndefined(parameter.value)) {
        parameter.value = false;
      }
    }
  }

  getChecked(event){
   if(this.parameter.name== 'Show_dates'){
    this.parameter.defaultValue = event;
    this.parameter.value = event ==true ? 1 : 0;
   }
   if(this.parameter.name== 'Zero_hrs') {
     this.parameter.defaultValue = event;  
    this.parameter.value = event==true ? 1 : 0;
   }
  }

  
}

import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { ReportParameter } from '../../../models/index';
import { Observable ,  Subscription } from 'rxjs';
import { ILookupEntity, LookupDefinition, ILookupRequest } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-list-report-parameter',
  templateUrl: 'employee-list-parameter.component.html',
  styleUrls: ['employee-list-parameter.component.scss']
})
export class EmployeeListParameterComponent implements OnInit {
  public includeThousandsSeparator: boolean = false;

  @Input()
  public orgLevel: OrgLevel;
  @Input()
  public set parameter(value: ReportParameter) {
    this.m_parameter = value;
    this.processParameter(this.m_parameter);
  }

  public lookupRequest: ILookupRequest;

  public isActive = true;
  public get parameter(): ReportParameter {
    return this.m_parameter;
  }

  public get hasDefaultValue(): boolean {
    return !_.isNull(this.parameter.defaultValue) && !_.isUndefined(this.parameter.defaultValue) && this.parameter.defaultValue !== '';
  }

  public get lookupValue(): any {
    return this.m_lookupValue;
  }

  public set lookupValue(value: any) {
    if (this.m_parameter) {
      this.m_parameter.value = value;
    }
    this.m_lookupValue = value;
  }

  public get lookupName(): LookupDefinition {
    if (this.parameter && this.parameter.dataType)
      return this.parameter.dataType.lookupName;

    return undefined;
  }

  private m_parameter: ReportParameter;
  private m_lookupValue: ILookupEntity;

  public ngOnInit(): void {

  }
  private processParameter(parameter: ReportParameter): void {
    parameter.value = parameter.defaultValue;
    this.createRequest();
  }
  public createRequest(): void {
    if(!this.lookupName) {
      return;
    }
    this.lookupRequest = {
      lookupType: this.lookupName,
      orgLevelId: this.orgLevel.id,
      isActive: this.isActive
    };
  }
}

import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Observable ,  ReplaySubject ,  Subject ,  Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { DateRange, IDateRange } from '../../../core/models/index';

import { AccrualsTransactionRecord, AccrualsTransactionRecords, AccrualRecalculate } from '../models/index';
import { AccrualsManagementService } from './accruals-management.service';
import { AccrualsApiService } from './accruals-api.service';

@Injectable()
export class AccrualsTransactionsManagementService {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;
  private dateRange: DateRange;
  private loading$ = new Subject<boolean>();
  private recordsLoaded$ = new Subject<AccrualsTransactionRecord[]>();
  private dateRange$ = new Subject<DateRange>();
  private exportTo$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private calculateAccrualsAction$ = new Subject<boolean>();

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private manService: AccrualsManagementService,
    private apiService: AccrualsApiService
  ) {}

  public init(): void {
    this.subscribeToOrgLevelChanges();
    this.subscribeToLoadAccrualsTransactions();
  }

  public destroy(): void {
    this.orgLevel = null;
    this.loading$.complete();
    this.recordsLoaded$.complete();
    this.dateRange$.complete();
    this.exportTo$.complete();
    this.orgLevelChanged$.complete();
    this.calculateAccrualsAction$.complete();
  }

  public getDefaultDateRange(): IDateRange {
    const d = new Date();
    return new DateRange(new Date(d.getFullYear() - 1, d.getMonth(), d.getDate()), d);
  }

  public changeDateRange(r: IDateRange): void {
    this.dateRange = r;
    this.dateRange$.next(r);
    this.loadAccrualsTransactions();
  }

  public subscribeToDateRange(callback: (r: DateRange) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.dateRange$.subscribe(callback);
  }

  public exportTo(isPDF: boolean): void {
    this.exportTo$.next(isPDF);
  }

  public subscribeToExport(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportTo$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }

  public subscribeToLoadedRecords(callback: (b: AccrualsTransactionRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.recordsLoaded$.subscribe(callback);
  }

  public subscribeTocalculateAccrualsAction(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.calculateAccrualsAction$.subscribe(callback);
  }

  public loadAccrualsTransactions(): void {
    if (
      !_.isFinite(_.get(this.orgLevel, 'id'))
      || !_.isDate(_.get(this.dateRange, 'startDate'))
      || !_.isDate(_.get(this.dateRange, 'endDate'))
    ) return;
    this.loading$.next(true);
    this.apiService.getAccrualsTransactionRecords(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate)
      .then((accrualTransactions: any) => {
        this.recordsLoaded$.next(accrualTransactions.records);
        this.calculateAccrualsAction$.next(accrualTransactions.calculateAccruals);
        this.loading$.next(false);
      })
      .catch (() => {
        this.loading$.next(false);
      });
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .pipe(filter((o: OrgLevel) => o && _.isFinite(o.id)))
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
        this.loadAccrualsTransactions();
      });
  }

  private subscribeToLoadAccrualsTransactions(): void {
    this.subscriptions.loadAccrualsTransaction = this.manService.subscribeToLoadAccrualsTransactions(() => this.loadAccrualsTransactions());
  }
}

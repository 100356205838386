import { Directive, Input, ElementRef, Renderer2, HostListener, OnInit, Host, AfterViewInit, DoCheck } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';

@Directive({
  selector: '[kendoGridFixFreezeHeader]',
})
export class KendoGridFixFreezeHeaderDirective implements AfterViewInit {

  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen$: Observable<boolean>;

  @unsubscribe()
  private isLeftSidebarOpenSubscription: Subscription;

  private elRef: ElementRef;
  private renderer: Renderer2;
  private headerElement: Element;
  private lockedHeaderElement: Element;

  constructor(elRef: ElementRef, renderer: Renderer2) {
    this.elRef = elRef;
    this.renderer = renderer;
    this.isLeftSidebarOpenSubscription = this.isLeftSidebarOpen$.subscribe((isOpen: boolean) => {
      if (this.headerElement && this.lockedHeaderElement) {
        //fix kendo bug
        let el: HTMLElement = <HTMLElement>this.lockedHeaderElement;
        this.renderer.setStyle(this.headerElement, 'width', `calc(100% - ${el.scrollWidth}px)`);
      }
    });
  }

  public ngAfterViewInit(): void {
    let el: HTMLElement = this.elRef.nativeElement;
    this.headerElement = el.querySelector('.k-grid-header>.k-grid-header-wrap');
    this.lockedHeaderElement = el.querySelector('.k-grid-header>.k-grid-header-locked');

  }
}

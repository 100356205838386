import * as _ from 'lodash';
import { Component, Input, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { FiveStarPredictorOrganizationInfo, StaffingOrganizationInfoRequest } from '../../models/index';
import { StaffingPredictorApiService, StaffingPredictorManagementService } from '../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { GoalSettingRequest, GoalSettingResponse } from '../../models/goal-setting';
import { SaveGoalRequest } from '../../models/save-goal';
import { NotificationsService } from '../../../../core/components';
import { ChangeManagementService } from '../../../../common';
import { NgForm } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-fivestar-predictor-rating-predictor',
  templateUrl: './fivestar-predictor-rating-predictor.component.html',
  styleUrls: ['./fivestar-predictor-rating-predictor.component.scss']
})
export class FivestarPredictorRatingPredictorComponent {
  @ViewChild('form', {static: true}) 
  ngForm: NgForm;
  public state:{
    isNegativeCensus: boolean;
    isNegCensusWeekend: boolean;
    isNurseHrsNeg: boolean;
    isNurseWeekendNeg:boolean;
    isNurseTurnOverNeg:boolean;
    isRnTurnoverNeg:boolean;
    isAdminDepNeg:boolean;
    isNurseHrdDisabled: boolean;
    isAvgCensusDisabled: boolean; 
    isCensusWeekendDisabled:boolean;
    isRnDisabled:boolean;
    isNurseWeekendDisabled:boolean;
    isNurseTurnoverDisabled:boolean;
    isRnTurnoverDisabled:boolean;
    isAdminDepDisabled:boolean;
  }
  
  @Output() starRating = new EventEmitter<any>();
  five_star_goal_section_enabled: boolean;
  goalsSectionDetails: GoalSettingResponse = new GoalSettingResponse();
  goalReq: GoalSettingRequest;
  isSaveDisabled: boolean=true;
  isValChanged: boolean;

  @Input()
  public sectionIndex: number;

  @unsubscribe()
  private hideSubscription: Subscription;

  public hideToPrint: boolean;

  public selectedRating: number;

  public m_model: FiveStarPredictorOrganizationInfo;

  constructor(private changeDetector: ChangeDetectorRef, private notificationService: NotificationsService,
    private management: StaffingPredictorManagementService, private appSettingsManageService: AppSettingsManageService, private api: StaffingPredictorApiService, private changeMangement: ChangeManagementService) { 
      this.management.goalReqObj.subscribe((goalReq)=>{
        this.goalReq = goalReq;
      })
      this.management.getAllGoalInfo$.subscribe(val=>{
        this.goalsSectionDetails = val;
      })
      this.management.isGoalValueChanged$.subscribe(val=>{
        this.isValChanged = val;
        if(this.isValChanged) this.changeMangement.changeNotify();
        else this.changeMangement.clearChanges();
      })
    }

  public ngOnInit(): void {
    this.hideSubscription = this.management.hideOnPrint$.subscribe((hide: boolean) => {
      this.hideToPrint = hide;
      this.goalsSectionDetails = new GoalSettingResponse();
    });
    this.state ={
      isNegativeCensus: false,
    isNegCensusWeekend: false,
    isNurseHrsNeg: false,
    isNurseWeekendNeg:false,
    isNurseTurnOverNeg:false,
    isRnTurnoverNeg:false,
    isAdminDepNeg:false,
    isNurseHrdDisabled: false,
    isAvgCensusDisabled: false, 
    isCensusWeekendDisabled:false,
    isRnDisabled:false,
    isNurseWeekendDisabled:false,
    isNurseTurnoverDisabled:false,
    isRnTurnoverDisabled:false,
    isAdminDepDisabled:false
    }
    this.appSettingsManageService.getAppServerConfig().then((config) => {
      this.five_star_goal_section_enabled = config.five_star_goal_section_enabled
      if(this.five_star_goal_section_enabled) this.retrieveGoal();
    })
    
  }

  public onPredictedRatingChange(value: number): void {
    this.selectedRating = value;
    this.starRating.emit(value);
    this.management.loadGoalsSectionInfo(this.five_star_goal_section_enabled,value);
    this.isSaveDisabled=true;
    this.management.isGoalValueChanged$.next(!this.isSaveDisabled)
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
    this.state.isNurseHrdDisabled = this.selectedRating == 0 ? true : false;
    this.state.isRnDisabled = this.selectedRating == 0 ? true : false;
    this.state.isAvgCensusDisabled = this.selectedRating == 0 ? true : false;
    this.state.isCensusWeekendDisabled = this.selectedRating == 0 ? true : false;
    this.state.isNurseTurnoverDisabled = this.selectedRating == 0 ? true : false;
    this.state.isNurseWeekendDisabled = this.selectedRating == 0 ? true : false;
    this.state.isRnTurnoverDisabled = this.selectedRating == 0 ? true : false;
    this.state.isAdminDepDisabled = this.selectedRating == 0 ? true : false;
  }

  public onShowDetails(): void {
    this.management.showDetailsFiveStar();
  }

  public clearAllFields(){
  this.isSaveDisabled=false;
  this.disablingInputValues();
  this.management.isGoalValueChanged$.next(!this.isSaveDisabled)
    this.selectedRating=0;
    this.goalsSectionDetails = new GoalSettingResponse();
    this.ngForm.resetForm();
  }

  public handleGoalRatingInputs(value, type, sectionIndex) {
    this.isSaveDisabled = false;
    this.management.isGoalValueChanged$.next(!this.isSaveDisabled);
    this.management.disableCollapsableContainer$.next([this.ngForm.dirty, this.sectionIndex]);
    switch(type){
      case 'avgCensus':
        this.goalsSectionDetails.avgDailyCensus = value;

        this.goalsSectionDetails.reportedTotalNursingHRD = this.reportedHrdValues(_.toNumber(this.goalsSectionDetails.totalNursingAvgDailyHours),this.goalsSectionDetails.avgDailyCensus);
        this.goalsSectionDetails.reportedRnHRD = this.reportedHrdValues(this.goalsSectionDetails.rnAvgDailyHours, this.goalsSectionDetails.avgDailyCensus);
        this.goalsSectionDetails.adjustedTotalNurseHRD = this.adjustedHrdValues(this.goalsSectionDetails.reportedTotalNursingHRD,this.goalsSectionDetails.caseMixTotalNurse,this.goalsSectionDetails.nationalCaseMixTotalNurse);
        this.goalsSectionDetails.adjustedRnHRD = this.adjustedHrdValues(this.goalsSectionDetails.reportedRnHRD,this.goalsSectionDetails.caseMixRN,this.goalsSectionDetails.nationalCaseMixRN);
        this.getHrdPoints('avgCensus', 'adjustedTotalNurseHRD', this.goalsSectionDetails.adjustedTotalNurseHRD);
        this.getHrdPoints('avgCensus', 'adjustedRnHRD', this.goalsSectionDetails.adjustedRnHRD);        
          break;

      case 'avgCensusWeekend':
        this.goalsSectionDetails.avgDailyCensusWeekEnd = value;
        this.goalsSectionDetails.reportedTotalNursingHRDWeekEnd =  this.reportedHrdValues(this.goalsSectionDetails.totalNursingAvgDailyHoursWeekEnd, this.goalsSectionDetails.avgDailyCensusWeekEnd);
        this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRD = this.adjustedHrdValues(this.goalsSectionDetails.reportedTotalNursingHRDWeekEnd,this.goalsSectionDetails.caseMixTotalNurseWeekend,this.goalsSectionDetails.nationalCaseMixTotalNurseWeekend);
        this.getHrdPoints('avgCensusWeekend', 'adjustedTotalNurseWeekendHRD', this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRD);
        break;

      case 'nursingHrd':
      this.goalsSectionDetails.totalNursingAvgDailyHours = (value==null) ? 0 : value;
      this.goalsSectionDetails.reportedTotalNursingHRD = this.reportedHrdValues(this.goalsSectionDetails.totalNursingAvgDailyHours,this.goalsSectionDetails.avgDailyCensus);
      this.goalsSectionDetails.adjustedTotalNurseHRD = this.adjustedHrdValues(this.goalsSectionDetails.reportedTotalNursingHRD,this.goalsSectionDetails.caseMixTotalNurse,this.goalsSectionDetails.nationalCaseMixTotalNurse);
        this.getHrdPoints('avgCensus','adjustedTotalNurseHRD',this.goalsSectionDetails.adjustedTotalNurseHRD);
        break;

      case 'rnHrd':
        this.goalsSectionDetails.rnAvgDailyHours = (value==null) ? 0 :value;
        this.goalsSectionDetails.reportedRnHRD = this.reportedHrdValues(this.goalsSectionDetails.rnAvgDailyHours, this.goalsSectionDetails.avgDailyCensus);
        this.goalsSectionDetails.adjustedRnHRD = this.adjustedHrdValues(this.goalsSectionDetails.reportedRnHRD, this.goalsSectionDetails.caseMixRN, this.goalsSectionDetails.nationalCaseMixRN);
        this.getHrdPoints('avgCensus','adjustedRnHRD',this.goalsSectionDetails.adjustedRnHRD);
        break;

      case 'nursingWeekend':
        this.goalsSectionDetails.totalNursingAvgDailyHoursWeekEnd =  (value==null) ? 0 : value;
        this.goalsSectionDetails.reportedTotalNursingHRDWeekEnd = this.reportedHrdValues(this.goalsSectionDetails.totalNursingAvgDailyHoursWeekEnd, this.goalsSectionDetails.avgDailyCensusWeekEnd);
        this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRD = this.adjustedHrdValues(this.goalsSectionDetails.reportedTotalNursingHRDWeekEnd, this.goalsSectionDetails.caseMixTotalNurseWeekend, this.goalsSectionDetails.nationalCaseMixTotalNurseWeekend);
        this.getHrdPoints('avgCensusWeekend','adjustedTotalNurseWeekendHRD',this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRD);
        break;

      case 'nurseTurnover':
        this.goalsSectionDetails.totalNursingTurnOver = (value==null) ? 0 : value;
        this.getTurnoverPoints('totalNursingTurnover',this.goalsSectionDetails.totalNursingTurnOver)
        break;

      case 'rnTurnover':
        this.goalsSectionDetails.rnTurnOver  = (value==null) ? 0 : value;
        this.getTurnoverPoints('rnTurnOver',this.goalsSectionDetails.rnTurnOver)
        break;

      case 'adminDep':
        this.goalsSectionDetails.administratorDepartures = (value == null) ? 0 : value;
        this.getTurnoverPoints('adminDeparture',this.goalsSectionDetails.administratorDepartures)
      break
   }
 }

 setValue(type,target){
  if(target!=undefined){
  switch(type){
   case 'nursingHrd':
    this.goalsSectionDetails.totalNursingAvgDailyHours = _.isEmpty(target.value) ? 0 : target.value;
    break;
   case 'rnHrd':
    this.goalsSectionDetails.rnAvgDailyHours = _.isEmpty(target.value) ? 0 :target.value;
    break;
  case 'nursingWeekend':
    this.goalsSectionDetails.totalNursingAvgDailyHoursWeekEnd= _.isEmpty(target.value) ? 0 :target.value;
    break;
  case 'nurseTurnover':
    this.goalsSectionDetails.totalNursingTurnOver = _.isEmpty(target.value) ? 0 :target.value;
    break;
  case 'rnTurnover':
    this.goalsSectionDetails.rnTurnOver = _.isEmpty(target.value) ? 0 :target.value;
    break;
  case 'adminDep':
    this.goalsSectionDetails.administratorDepartures = _.isEmpty(target.value) ? 0 :target.value;
    break;
}
  }
 }

 saveGoal(){
  this.isSaveDisabled=true;
  this.management.isGoalValueChanged$.next(!this.isSaveDisabled);
  this.management.disableCollapsableContainer$.next([false, this.sectionIndex]);
  for (let key in this.goalsSectionDetails) {
    this.goalsSectionDetails[key] = Math.round(this.goalsSectionDetails[key] * 100) / 100;
  }
  let saveGoalReqObj: SaveGoalRequest= Object.assign({}, this.goalsSectionDetails, this.goalReq);
  this.api.saveGoal(saveGoalReqObj).then((response)=>{
    if (response && response[0].statusMessage == 'Inserted Succesfully') this.notificationService.success('Goal is saved successfully');
    else this.notificationService.success('Goal is updated successfully');
  })
  .catch((error)=>{
    this.management.onLoadStatusChanged(false);
  })
 }

 get isGoalsSectionDetails(){
   return (this.goalsSectionDetails.adjustedTotalNurseHRDPoints) ? true : false;

 }
 public reportedHrdValues( hours, censusVal){
  return (hours/censusVal);     
 }

 public adjustedHrdValues(reportedHrd, caseMix, nationalCaseMix){
 return (reportedHrd/caseMix) * nationalCaseMix;
       
 }

 public retrieveGoal(){
  let retrieveGoalObj= new StaffingOrganizationInfoRequest();
      retrieveGoalObj.startDate = this.management.setStartDate;
      retrieveGoalObj.endDate = this.management.setEndDate;
      retrieveGoalObj.organizationId =this.management.organizationId;
   this.api.retrieveGoalDetails(retrieveGoalObj).then((result : GoalSettingResponse) => {
    this.goalsSectionDetails = result;
    this.setReportedHRDValues();
    this.selectedRating = this.goalsSectionDetails.staffingRating;
    if(!this.selectedRating) {
      this.disablingInputValues();
    }
   })
   .catch((error)=>{
    this.management.onLoadStatusChanged(false);
  })
       
 }

 setReportedHRDValues(){
  this.goalsSectionDetails.reportedTotalNursingHRD = this.reportedHrdValues(_.toNumber(this.goalsSectionDetails.totalNursingAvgDailyHours),this.goalsSectionDetails.avgDailyCensus);
  this.goalsSectionDetails.reportedRnHRD = this.reportedHrdValues(this.goalsSectionDetails.rnAvgDailyHours, this.goalsSectionDetails.avgDailyCensus);
  this.goalsSectionDetails.reportedTotalNursingHRDWeekEnd = this.reportedHrdValues(this.goalsSectionDetails.totalNursingAvgDailyHoursWeekEnd, this.goalsSectionDetails.avgDailyCensusWeekEnd); 
}

 public checkForNegativeVal(event, type){
  if(type == 'avgCensus'){
    this.goalsSectionDetails.avgDailyCensus = event.target.value;
  }
  else if (type == 'avgCensusWeekend'){
    this.goalsSectionDetails.avgDailyCensusWeekEnd = event.target.value;
  }
  else if(type == 'nursingHrd'){
    this.goalsSectionDetails.totalNursingAvgDailyHours = event.target.value;
  }
  else if(type == 'nursingWeekend'){
    this.goalsSectionDetails.totalNursingAvgDailyHoursWeekEnd = event.target.value;
  }
  else if(type == 'nurseTurnover'){
    this.goalsSectionDetails.totalNursingTurnOver= event.target.value;
  }
  else if(type == 'rnTurnover'){
    this.goalsSectionDetails.rnTurnOver= event.target.value;
  }
  else if(type == 'adminDep'){
    this.goalsSectionDetails.administratorDepartures= event.target.value;
  }
   this.isSaveDisabled = this.isFormValid();
 }

 public preventNegative(event, type){
  if(type == 'avgCensus'){
     this.state.isNegativeCensus = this.setBooleanVal(event); 
  }
  else if (type == 'avgCensusWeekend'){
        this.state.isNegCensusWeekend = this.setBooleanVal(event);
  }
  else if(type == 'nursingHrd'){
        this.state.isNurseHrsNeg = this.setBooleanVal(event);
  }
  else if(type == 'nursingWeekend'){
        this.state.isNurseWeekendNeg = this.setBooleanVal(event);
  }
  else if(type == 'nurseTurnover'){
        this.state.isNurseTurnOverNeg = this.setBooleanVal(event);
  }
  else if(type == 'rnTurnover'){
        this.state.isRnTurnoverNeg = this.setBooleanVal(event);
  }
  else if(type == 'adminDep'){
        this.state.isAdminDepNeg = this.setBooleanVal(event);
  }
 }

 public setBooleanVal(event){
  if(event.key == '-') {
    event.preventDefault();
    return true
    }
   return false;
 }

 public isFormValid(){
  if(this.goalsSectionDetails.avgDailyCensus < 0  ||
    this.goalsSectionDetails.avgDailyCensusWeekEnd < 0 ||
    this.goalsSectionDetails.totalNursingAvgDailyHours< 0 ||
    this.goalsSectionDetails.rnAvgDailyHours< 0 ||
    this.goalsSectionDetails.totalNursingAvgDailyHoursWeekEnd < 0||
    this.goalsSectionDetails.totalNursingTurnOver < 0 ||
    this.goalsSectionDetails.rnTurnOver < 0 ||
    this.goalsSectionDetails.administratorDepartures < 0 || this.ngForm.status.toLowerCase() == 'invalid') return true
    return false;
  }

  public getHrdPoints(changedProp, adjustedProp, adjValue) {
    switch (changedProp) {
      case 'avgCensus':
        switch (adjustedProp) {
          case 'adjustedTotalNurseHRD':
            if (adjValue > 0 && adjValue <= 2.746) this.goalsSectionDetails.adjustedTotalNurseHRDPoints = 10;

            else if (adjValue > 2.746 && adjValue <= 3.029) this.goalsSectionDetails.adjustedTotalNurseHRDPoints = 20;

            else if (adjValue > 3.029 && adjValue <= 3.247) this.goalsSectionDetails.adjustedTotalNurseHRDPoints = 30;

            else if (adjValue > 3.247 && adjValue <= 3.444) this.goalsSectionDetails.adjustedTotalNurseHRDPoints = 40;

            else if (adjValue > 3.444 && adjValue <= 3.652) this.goalsSectionDetails.adjustedTotalNurseHRDPoints = 50;

            else if (adjValue > 3.653 && adjValue <= 3.868) this.goalsSectionDetails.adjustedTotalNurseHRDPoints = 60;

            else if (adjValue > 3.868 && adjValue <= 4.104) this.goalsSectionDetails.adjustedTotalNurseHRDPoints = 70;

            else if (adjValue > 4.104 && adjValue <= 4.428) this.goalsSectionDetails.adjustedTotalNurseHRDPoints = 80;

            else if (adjValue > 4.428 && adjValue <= 4.953) this.goalsSectionDetails.adjustedTotalNurseHRDPoints = 90;

            else if (adjValue >= 4.954) this.goalsSectionDetails.adjustedTotalNurseHRDPoints = 100;

            break;

          case 'adjustedRnHRD':
            if (adjValue > 0 && adjValue < 0.26) this.goalsSectionDetails.adjustedRnHRDPoints = 10;

            else if (adjValue > 0.26 && adjValue <= 0.351) this.goalsSectionDetails.adjustedRnHRDPoints = 20;

            else if (adjValue > 0.351 && adjValue <= 0.425) this.goalsSectionDetails.adjustedRnHRDPoints = 30;

            else if (adjValue > 0.425 && adjValue <= 0.504) this.goalsSectionDetails.adjustedRnHRDPoints = 40;

            else if (adjValue > 0.504 && adjValue <= 0.590) this.goalsSectionDetails.adjustedRnHRDPoints = 50;

            else if (adjValue > 0.590 && adjValue <= 0.691) this.goalsSectionDetails.adjustedRnHRDPoints = 60;

            else if (adjValue > 0.691 && adjValue <= 0.818) this.goalsSectionDetails.adjustedRnHRDPoints = 70;

            else if (adjValue > 0.818 && adjValue <= 0.991) this.goalsSectionDetails.adjustedRnHRDPoints = 80;

            else if (adjValue > 0.991 && adjValue <= 1.297) this.goalsSectionDetails.adjustedRnHRDPoints = 90;

            else if (adjValue > 1.297) this.goalsSectionDetails.adjustedRnHRDPoints = 100;
            break;
        }
        break;
      case 'avgCensusWeekend':
        if (adjustedProp == 'adjustedTotalNurseWeekendHRD') {
          if (adjValue > 0 && adjValue <= 2.349) this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints = 5;

          else if (adjValue > 2.349 && adjValue <= 2.612) this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints = 10;

          else if (adjValue > 2.612 && adjValue <= 2.809) this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints = 15;

          else if (adjValue > 2.809 && adjValue <= 2.984) this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints = 20;

          else if (adjValue > 2.984 && adjValue <= 3.173) this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints = 25;

          else if (adjValue > 3.174 && adjValue <= 3.381) this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints = 30;

          else if (adjValue > 3.381 && adjValue <= 3.622) this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints = 35;

          else if (adjValue > 3.622 && adjValue <= 3.895) this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints = 40;

          else if (adjValue > 3.895 && adjValue <= 4.327) this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints = 45;

          else if (adjValue >= 4.328) this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints = 50;
        }
        break;
    }
    this.getGoalSectionFiveStarRating();
  }

  public getTurnoverPoints(noCalcValue,adjustedValue){
    switch(noCalcValue){
    case 'totalNursingTurnover':   
      if (adjustedValue > 72.679 && adjustedValue <= 100) this.goalsSectionDetails.totalNursingTurnOverPoints = 5;

      else if (adjustedValue > 65.742 && adjustedValue <= 72.678) this.goalsSectionDetails.totalNursingTurnOverPoints = 10;

      else if (adjustedValue > 60.7 && adjustedValue <= 65.741) this.goalsSectionDetails.totalNursingTurnOverPoints = 15;

      else if (adjustedValue > 56.392 && adjustedValue <= 60.699) this.goalsSectionDetails.totalNursingTurnOverPoints = 20;

      else if (adjustedValue > 52.354 && adjustedValue <= 56.391) this.goalsSectionDetails.totalNursingTurnOverPoints = 25;

      else if (adjustedValue > 48.697 && adjustedValue <= 52.353) this.goalsSectionDetails.totalNursingTurnOverPoints = 30;

      else if (adjustedValue > 44.849 && adjustedValue <= 48.696) this.goalsSectionDetails.totalNursingTurnOverPoints = 35;

      else if (adjustedValue > 40.595 && adjustedValue <= 44.848) this.goalsSectionDetails.totalNursingTurnOverPoints = 40;

      else if (adjustedValue > 34.417 && adjustedValue <= 40.594) this.goalsSectionDetails.totalNursingTurnOverPoints = 45;

      else if (adjustedValue <= 34.416) this.goalsSectionDetails.totalNursingTurnOverPoints = 50;
      break;
    
    case 'rnTurnOver':
      if (adjustedValue > 81.082 && adjustedValue <= 100) this.goalsSectionDetails.rnTurnOverPoints = 5;

      else if (adjustedValue > 71.054 && adjustedValue <= 81.081) this.goalsSectionDetails.rnTurnOverPoints = 10;

      else if (adjustedValue > 62.964 && adjustedValue <= 71.053) this.goalsSectionDetails.rnTurnOverPoints = 15;

      else if (adjustedValue > 56.978 && adjustedValue <= 62.963) this.goalsSectionDetails.rnTurnOverPoints = 20;

      else if (adjustedValue > 49.124 && adjustedValue <= 56.977) this.goalsSectionDetails.rnTurnOverPoints = 25;

      else if (adjustedValue > 45.162 && adjustedValue <= 49.123) this.goalsSectionDetails.rnTurnOverPoints = 30;

      else if (adjustedValue > 39.624 && adjustedValue <= 45.161) this.goalsSectionDetails.rnTurnOverPoints = 35;

      else if (adjustedValue > 33.109 && adjustedValue <= 39.623) this.goalsSectionDetails.rnTurnOverPoints = 40;

      else if (adjustedValue > 24.529 && adjustedValue <= 33.108) this.goalsSectionDetails.rnTurnOverPoints = 45;

      else if (adjustedValue <= 24.528) this.goalsSectionDetails.rnTurnOverPoints = 50;
            break;

    case 'adminDeparture':
        if (adjustedValue == 0) this.goalsSectionDetails.administratorDeparturesPoints = 30;
        
        else if (adjustedValue == 1) this.goalsSectionDetails.administratorDeparturesPoints = 25;
  
        else if (adjustedValue == 2) this.goalsSectionDetails.administratorDeparturesPoints = 10;
        break;
  
    }
    this.getGoalSectionFiveStarRating();
  }

  public getGoalSectionFiveStarRating(){
    let starRatingPoints = this.goalsSectionDetails.adjustedTotalNurseHRDPoints + this.goalsSectionDetails.adjustedRnHRDPoints + this.goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints + this.goalsSectionDetails.totalNursingTurnOverPoints + this.goalsSectionDetails.rnTurnOverPoints + this.goalsSectionDetails.administratorDeparturesPoints;
    if(starRatingPoints > 0 && starRatingPoints <= 154) this.selectedRating = 1;
    else if(starRatingPoints > 154 && starRatingPoints <= 204) this.selectedRating = 2;
    else if(starRatingPoints > 204 && starRatingPoints <= 254) this.selectedRating = 3;
    else if(starRatingPoints > 254 && starRatingPoints <= 319) this.selectedRating = 4;
    else if(starRatingPoints > 319 && starRatingPoints <= 380) this.selectedRating = 5;
  }

  public disablingInputValues(){
    this.state.isAvgCensusDisabled = true;
    this.state.isCensusWeekendDisabled = true;
    this.state.isNurseHrdDisabled = true;
    this.state.isRnDisabled = true;
    this.state.isNurseWeekendDisabled = true;
    this.state.isNurseTurnoverDisabled = true;
    this.state.isRnTurnoverDisabled = true;
    this.state.isAdminDepDisabled = true;
  }
}

<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells slx-cell-padding"
            [slxKendoGridColumnsGroup]="columnsGroupName"
            [data]="gridState.view"
            [sortable]="{ mode: 'multiple' }"
            [sort]="gridState.state.sort"
            [filterable]="'menu'"
            [filter]="gridState.state.filter"
            [skip]="gridState.state.skip"
            [pageable]="true"
            [pageSize]="pageSize"
            [reorderable]="isReordable"
            (dataStateChange)="gridState.dataStateChange($event)">

  <kendo-grid-column title="Employee Name"
                     field="employee.fullName"
                     width="220"
                     filter="text"
                     [locked]="isLockedColumn">
    <ng-template kendoGridHeaderTemplate>
      <span>Employee Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>

      <span class="accruals-emp" [title]="dataItem.employee?.fullName" (click)="onEmpNameClick(dataItem.employee?.id)">
        <img class="accruals-emp__img" [employeeThumbinalSrc]="dataItem.employee?.id" alt="Employee Photo" />
        <span class="accruals-emp__text accruals-link">{{ dataItem.employee?.fullName }}</span>
      </span>

    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Date"
                     field="transaction.date"
                     width="100"
                     filter="date"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.transaction?.date | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Type"
                     field="transaction.type"
                     width="150"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Type</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.transaction?.type }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Action"
                     field="transaction.action"
                     width="180"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Action</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.transaction?.action }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Original"
                     field="transaction.original"
                     width="100"
                     filter="numeric"
                     format="{0:n2}"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Original</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.transaction?.original | slxDecimal24Switch : isShowHighPrecision }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Change"
                     field="transaction.change"
                     width="100"
                     filter="numeric"
                     format="{0:n2}"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Change</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.transaction?.change | slxDecimal24Switch : isShowHighPrecision }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="New Value"
                     field="transaction.newValue"
                     width="120"
                     filter="numeric"
                     format="{0:n2}"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>New Value</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.transaction?.newValue | slxDecimal24Switch : isShowHighPrecision }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Employee Organization"
                     field="organization.name"
                     width="250"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Organization</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Employee Department"
                     field="department.name"
                     width="200"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Department</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.department?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Employee Position"
                     field="position.name"
                     width="270"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Accrual Policy"
                     field="accrualPolicy.policyName"
                     width="270"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Accrual Policy</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.accrualPolicy?.policyName }}</ng-template>
  </kendo-grid-column>



  <kendo-grid-column title="Notes"
                     field="transaction.notes"
                     width="150"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Notes</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span  class="transaction-notes" title="{{ dataItem.transaction?.notes }}">{{ dataItem.transaction?.notes }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel [fileName]="xlsxName" [fetchData]="retriveAllPages()">
    <kendo-excelexport-column title="Employee Name" field="employee.fullName" width="220"></kendo-excelexport-column>
    <kendo-excelexport-column title="Date" field="transaction.date" width="100"></kendo-excelexport-column>
    <kendo-excelexport-column title="Type" field="transaction.type" width="150"></kendo-excelexport-column>
    <kendo-excelexport-column title="Action" field="transaction.action" width="180"></kendo-excelexport-column>
    <kendo-excelexport-column title="Original" field="transaction.original" width="100" [cellOptions]="cells"></kendo-excelexport-column>
    <kendo-excelexport-column title="Change" field="transaction.change" width="100" [cellOptions]="cells"></kendo-excelexport-column>
    <kendo-excelexport-column title="New Value" field="transaction.newValue" width="120" [cellOptions]="cells"></kendo-excelexport-column>
    <kendo-excelexport-column title="Employee Organization" field="organization.name" width="250"></kendo-excelexport-column>
    <kendo-excelexport-column title="Employee Department" field="department.name" width="200"></kendo-excelexport-column>
    <kendo-excelexport-column title="Employee Position" field="position.name" width="270"></kendo-excelexport-column>
    <kendo-excelexport-column title="Accrual Policy" field="accrualPolicy.policyName" width="270"></kendo-excelexport-column>
    <kendo-excelexport-column title="Notes" field="transaction.notes" width="150"></kendo-excelexport-column>
  </kendo-grid-excel>
  <kendo-grid-pdf [fileName]="pdfName" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>

  <kendo-grid-messages filterMenuTitle="Filter"  >
  </kendo-grid-messages>

</kendo-grid>

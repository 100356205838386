import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable ,  ReplaySubject ,  Subscription ,  Subject } from 'rxjs';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { ManageACAPeriodsContainer } from '../../models/aca-config/manage-aca-container';
import { ACAConfigurationApiService } from './aca-api.service';
import { Assert } from '../../../framework/index';
import { ManageACAPeriods } from '../../models';

@Injectable()
export class ACAConfigurationManagementService {

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    @unsubscribeInService()
    private orgLevelSubscription: Subscription;

    constructor(
        private api: ACAConfigurationApiService) {
        this.onLoaded$ = new ReplaySubject(1);
        this.onStateChanged$ = new ReplaySubject(1);
    }

    private onLoaded$: ReplaySubject<ManageACAPeriodsContainer>;
    private onStateChanged$: ReplaySubject<any>;
    private exportTo$ = new Subject<boolean>();
    private m_container: ManageACAPeriodsContainer;
    private currentOrgLevel: OrgLevel;
    public orgLevelId: number;

    public init(): void {
        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            if (_.isNumber(orgLevel.id)) {
                this.currentOrgLevel = orgLevel;
                this.orgLevelId = orgLevel.id;
                this.onStateChanged$.next({ orgLevelChanged: true });
                this.fetchRecords();
            }
        });
    }

    public fetchRecords(): void {
        this.api.getManageACAPeriodsRecords(this.orgLevelId).
            then((result: ManageACAPeriodsContainer) => {
                this.m_container = result;
                this.onStateChanged$.next({ isLoading: false });
                this.onLoaded$.next(this.m_container);
            }).catch(() => {
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    public subscribeToOnLoaded(callback: (o: ManageACAPeriodsContainer) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.onLoaded$.subscribe(callback);
    }

    public subscribeToOnStateChanged(callback: (o: any) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.onStateChanged$.subscribe(callback);
    }

    public exportTo(isPDF: boolean): void {
        this.exportTo$.next(isPDF);
    }

    public subscribeToExport(callback: (isPDF: boolean) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    }

    public postManageACARecord(item: ManageACAPeriods): void {
        this.api.postManageACARecords(item, this.orgLevelId).
            then((result: any) => {
                this.fetchRecords();
                this.onStateChanged$.next({ isLoading: false });
            }).catch(() => {
                this.onStateChanged$.next({ isLoading: false });
            });
    }
}
import { Component, Input, HostBinding, ViewChild, OnChanges, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NgModel } from '@angular/forms';


import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { createValuAccessor } from '../../utils/index';
import { isDestroyed } from '../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-dropdown-input',
  templateUrl: 'dropdown-input.component.html',
  styleUrls: ['dropdown-input.component.scss'],
  providers: [createValuAccessor(DropdownInputComponent)]
})
export class DropdownInputComponent implements ControlValueAccessor {
  @Input()
  public set options(items: any[]) {
    this.items = items;
  }

  public set emptyOption(value: any) {
    this.m_emptyOption = value;
    if (this.autoSelectFirst && !this.selectedValue) {
      this.selectFirstValue();
    }
  }

  public get emptyOption(): any {
    return this.m_emptyOption;
  }

  @Input()
  public set autoSelectFirst(value: boolean) {
    this.m_autoSelectFirst = value;
    if (value && !this.selectedValue) {
      this.selectFirstValue();
    }
  }

  public get autoSelectFirst(): boolean {
    return this.m_autoSelectFirst;
  }

  @Input()
  public set disabledOption(value: any) {
    if (_.has(value, this.valueField)) {
      this.m_disabledOption = value[this.valueField];
      return;
    }
    this.m_disabledOption = null;
  }

  public get disabledOption(): any {
    return this.m_disabledOption;
  }

  @Input()
  public valueField: string;

  @Input()
  public titleField: string;

  @Input()
  public readonly: boolean;

  @Input()
  public disabled: boolean = false;

  @Input()
  public placeholder: string;

  @ViewChild('selectElement')
  public select: ElementRef;

  public get items(): any[] {
    return this.m_items;
  }

  public set items(value: any[]) {
    if (this.isDestroyed) {
      return;
    }
    this.m_items = value;

    if (this.selectedValue && this.selectedValue !== this.emptyOption) {
      this.silenceRefresh(this.selectedValue[this.valueField]);
      return;
    }
    if (this.autoSelectFirst && !this.selectedValue) {
      this.selectFirstValue();
    }

    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public get selectedValue(): any {
    return this.m_selectedValue;
  }

  public set selectedValue(value: any) {
    this.m_selectedValue = value;
  }

  public get showEmptyOption(): boolean {
    return !_.isUndefined(this.emptyOption) && !_.isNull(this.emptyOption);
  }

  public get internalValue(): any {
    return this.m_internalValue;
  }
  public set internalValue(value: any) {
    this.m_internalValue = value;
  }

  @isDestroyed()
  private isDestroyed: boolean;
  private m_selectedValue: any;
  private m_internalValue: any;

  private m_autoSelectFirst: boolean;
  private m_emptyOption: any;
  private m_items: any[] = [];
  private m_disabledOption: any;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;


  constructor(private changeDetector: ChangeDetectorRef) {
    this.valueField = 'id';
    this.titleField = 'name';
  }

  public writeValue(value: any): void {
    if (this.isDestroyed) {
      return;
    }
    if (!value && this.emptyOption) {
      value = this.emptyOption;
    }

    this.internalValue = value ? value === this.emptyOption ? this.emptyOption : value[this.valueField] : null;
    this.selectedValue = value;
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = (value: any) => { Promise.resolve(null).then(() => fn(value)); };
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public silenceRefresh(selectChange: any): void {
    let foundValue = _.find(this.items, { [this.valueField]: selectChange });
    if (foundValue) {
      this.writeValue(foundValue);
    }
  }

  public change(selectChange: any): void {
    if (selectChange && selectChange === this.emptyOption) {
      this.writeValue(this.emptyOption);
      this.onChangeCallback(undefined);
      return;
    }

    let foundValue = _.find(this.items, { [this.valueField]: selectChange });
    if (foundValue) {
      this.writeValue(foundValue);
      this.onChangeCallback(foundValue);
    }
  }

  public focus(): void {
    this.select.nativeElement.focus();
  }

  private selectFirstValue(): void {
    if (this.emptyOption) {
      this.change(this.emptyOption);
    } else if (this.items && this.items.length > 0) {
      let firstValue: any = _.first(this.items);
      this.change(firstValue[this.valueField]);
    }
  }
}

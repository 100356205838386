export * from './employee-list/employee-list.service';
export * from './employee-list/employee-list-map.service';
export * from './group-activities/group-activities-api.service';
export * from './direct-care-hours/direct-care-hours-management.service';
export * from './projected-hours/projected-hours-management.service';
export * from './slate-profile/slate-profile.service';
export * from './mass-rate-adjustments/mass-rate-adjustments-map.service';

import { Provider } from '@angular/core';
import { EmployeeListService } from './employee-list/employee-list.service';
import { EmployeeListMapService } from './employee-list/employee-list-map.service';
import { GroupActivitiesApiService } from './group-activities/group-activities-api.service';
import { DirectCareHoursManagementService } from './direct-care-hours/direct-care-hours-management.service'
import { ProjectedHoursManagementService } from './projected-hours/projected-hours-management.service';
import { EmployeeListCommonService } from './employee-list/employee-list-common.service';
import { SlateProfileService } from './slate-profile/slate-profile.service';
import { MassPayRateAdjustmentMapService } from './mass-rate-adjustments/mass-rate-adjustments-map.service';

export const services: Provider[] = [
  EmployeeListService,
  EmployeeListMapService,
  GroupActivitiesApiService,
  DirectCareHoursManagementService,
  ProjectedHoursManagementService,
  EmployeeListCommonService,
  SlateProfileService,
  MassPayRateAdjustmentMapService
];

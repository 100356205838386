
import {combineLatest} from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { GenericListManagementService } from '../../../organization/services/index';
import { GenericListDefinition, GenericList, GenericRow, GenericListRequest } from '../../../organization/models/generic-list/index';
import { GenericGridComponent } from '../../../organization/components/generic-list/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { genericGridConfig, IGenericGridConfig } from '../../../organization/components/generic-list/generic-grid/generic-grid.config';
import { genericListConfig } from '../../../organization/generic-list.config';

import { Observable ,  Subscription } from 'rxjs';


import { UserApplication } from '../../../state-model/models/index';
import { OrgLevel } from '../../../state-model/models/index';

import { RangeDates, IRangeDates } from '../../../common/models/range-dates';
import { ColumnManagementService } from '../../../common';

@Component({
  moduleId: module.id,
  selector: 'slx-attendance-points',
  templateUrl: 'attendance-points.component.html',
  styleUrls: ['attendance-points.component.scss'],
  providers: [GenericListManagementService, ColumnManagementService]
})
export class AttendancePointsComponent implements OnInit, OnDestroy {

  @mutableSelect('application')
  public application: Observable<UserApplication>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @ViewChild(GenericGridComponent, { static: true})
  public grid: GenericGridComponent;

  public appConfig: IApplicationConfig;
  public genericGridConfig: IGenericGridConfig;
  public startDate: Date;
  public endDate: Date;

  public state: {
    isLoading: boolean;
  };

  @unsubscribe()
  private definitionsLoadedSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private valuesLoadedSubscription: Subscription;

  private genericListManagementService: GenericListManagementService;
  private currentOrgLevel: OrgLevel;
  private currentApplication: UserApplication;
  private listDefinition: GenericListDefinition;
  private listValues: GenericList;

  constructor(genericListManagementService: GenericListManagementService) {
    this.genericListManagementService = genericListManagementService;
    this.state = {
      isLoading: false
    };
    this.startDate = moment().subtract(1, 'year').toDate();
    this.endDate = moment().toDate();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.genericGridConfig = _.assign({}, genericGridConfig);
    this.genericGridConfig.pdfExport.fileName = 'AttendancePoints.pdf';
    this.genericGridConfig.excelExport.fileName = 'AttendancePoints.xlsx';
    this.genericGridConfig.pdfExport.autoscale = true;

    this.orgLevelSubscription = this.orgLevel$.pipe(
      combineLatest(this.application))
      .subscribe((value: [OrgLevel, UserApplication]) => {
        let [orgLevel, application]: [OrgLevel, UserApplication] = value;
        if (!orgLevel || !orgLevel.id || !application.id) {
          return;
        }
        this.currentOrgLevel = orgLevel;
        this.currentApplication = application;
        this.loadDefinitions();
      });

    this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe((defs: GenericListDefinition) => {
      this.loadValues();
    });
    this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe((list: GenericList) => {
      this.state.isLoading = false;
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.loadValues();
  }

  public onExportToExcel(): void {
    this.grid.exportToExcel();
  }

  public onExportToPdf(): void {
    this.grid.exportToPdf();
  }

  private loadDefinitions(): void {
    this.state.isLoading = true;
    this.genericListManagementService.loadListDefinition(this.currentApplication.id, this.currentOrgLevel.id, genericListConfig.attendancePoints);
  }

  private loadValues(): void {
    this.state.isLoading = true;
    let req: GenericListRequest = new GenericListRequest();
    req.startDate = this.startDate;
    req.endDate = this.endDate;
    this.genericListManagementService.loadListValues(this.currentOrgLevel.id, req);
  }
}

import { Component, Input, Output, OnInit, HostBinding, ViewChild, OnChanges, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { createValuAccessor } from '../../utils/index';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  moduleId: module.id,
  selector: 'slx-autocomplete-input',
  templateUrl: 'autocomplete-input.component.html',
  styleUrls: ['autocomplete-input.component.scss'],
  providers: [createValuAccessor(AutocompleteInputComponent)]
})
export class AutocompleteInputComponent implements ControlValueAccessor {

  @Input() public tabindex: number = 0;

  @Input()
  public set options(items: any[]) {
    this.items = items;
  }

  @Input()
  public titleField: string;

  @Input()
  public readonly: boolean;

  @Input()
  public placeholder: string = 'Start input...';

  @Input()
  public className: string;

  @Input()
  public createIfNotFound: boolean = false;

  @Input()
  public caseSensitive: boolean = true;

  @Input()
  public popupSettings: PopupSettings = {
    popupClass: 'slx-autocomplete'
  };

  @Output()
  public valueChanged: EventEmitter<string>;

  public get items(): any[] {
    return this.m_items;
  }
  public set items(vals: any[]) {
    this.m_items = vals;
    this.dataItems = _.map(this.m_items, (item: any) => item[this.titleField]);
    this.internalValue = this.selectedValue ? this.selectedValue[this.titleField] : null;
    if (this.internalValue) {
      this.selectedValue = this.findValue(this.internalValue);
    }
  }
  public dataItems: string[];
  public internalValue: string;
  private selectedValue: any;
  private m_items: any[];

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  constructor() {
    this.titleField = 'name';
    this.valueChanged = new EventEmitter<string>();
  }

  public writeValue(value: any): void {
    if (value) {
      if (typeof value !== 'string') {
        let title: string = value[this.titleField];
        this.internalValue = title ? title : null;
      } else {
        this.internalValue = value;
      }
    } else {
      this.internalValue = null;
    }

    if (this.m_items) {
      this.selectedValue = this.findValue(this.internalValue);
    } else {
      this.selectedValue = value;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = (value: any) => {
      Promise.resolve(null).then(
        () => {
          return fn(value);
        });
    };
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public change(selectChange: string): void {
    this.internalValue = selectChange ? selectChange.trim() : null;
    let value: any = this.findValue(selectChange);
    if (!value) {
      if (this.createIfNotFound && this.internalValue) {
        let obj: any = {};
        obj[this.titleField] = selectChange;
        value = obj;
      } else {
        this.internalValue = value = null;
      }
    }
    this.onChangeCallback(value);
    this.valueChanged.emit(this.internalValue);
  }

  private findValue(strVal: string): any {
    let value: any;
    if(_.isNil(strVal) || _.isNaN(strVal)) return null;
    if (this.caseSensitive) {
      value = _.find(this.items, (v: any) => v[this.titleField] === strVal);
    } else {
      strVal = strVal.toLowerCase();
      value = _.find(this.items, (v: any) => {
        let str: string = v[this.titleField];
        if (str) {
          str = str.toLowerCase();
        }
        return str === strVal;
      });
    }
    return value;
  }
}

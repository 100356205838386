import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnDestroy, Input, NgZone, Host, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { GridDataResult, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

import { EmployeeSectionsBase, EmployeeSubsectionBenefitClasses } from '../../../models/index';
import { EmployeeSectionsBenefitsManagementApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { KendoGridStateHelper, ConfirmDialog2Component, ModalService, saveEvent, removeEvent, ConfirmOptions, cancelEvent } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { LookupService, Lookup, LookupType, BenefitClassDefinition, EmployeeBenefitClass } from '../../../../../organization/index';

import { ConfirmBenefitChangesDialogComponent } from './confirm-benefit-changes-dialog/confirm-benefit-changes-dialog.component';
import { appConfig } from '../../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-benefit-class',
  templateUrl: 'employee-sections-benefit-class.component.html',
  styleUrls: ['employee-sections-benefit-class.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsBenefitClassComponent extends EmployeeSectionsBasicComponent implements OnDestroy {

  @Input('employeeSectionsBenefitClasses')
  public set benefitClasses(empBenefitClasses: EmployeeSubsectionBenefitClasses) {
    this.employeeSectionsBenefitClasses = empBenefitClasses;
    this.originalEmployeeBenefitClasses = empBenefitClasses ? _.slice(empBenefitClasses.empBenefitClasses, 0) : null;
    this.refreshGrid();
    this.updateLookups();
  }

  @Input()
  public set employeeId(value: number) {
    this.m_employeeId = value;
    this.updateLookups();
  }

  @Input() public employeeStatus: string;
  @Input() public employeeRehireDate: Date;
  public get employeeId(): number {
    return this.m_employeeId;
  }

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }

  public addMode: boolean;
  public gridState: KendoGridStateHelper<EmployeeBenefitClass>;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public employeeSectionsBenefitClasses: EmployeeSubsectionBenefitClasses;
  public originalEmployeeBenefitClasses: EmployeeBenefitClass[];
  public benefitClassDefinitionsLookup: BenefitClassDefinition[] = [];

  public get minDate(): Date{
    return (this.employeeStatus.toLowerCase() === "future rehire") ? this.employeeRehireDate: new Date(1900, 1, 1);
  }
  public now: Date = moment().startOf('day').toDate();
  public maxDate: Date = moment(appConfig.maxCorrectDate).toDate();

  public editingItem: EmployeeBenefitClass;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild('gridForm', { static: true })
  private ngFormChild: NgForm;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  @unsubscribe()
  private gridSaveSubscription: Subscription;

  @unsubscribe()
  private gridRemoveSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;
  @unsubscribe()
  private formValueChangeSubscription: Subscription;

  private m_employeeId: number;

  private tempTimeline: EmployeeBenefitClass[];
  private deletedItems: EmployeeBenefitClass[];
  private addedItems: EmployeeBenefitClass[];
  private changedItems: EmployeeBenefitClass[];

  constructor(
    private modalService: ModalService,
    private lookupService: LookupService,
    private changeDetector: ChangeDetectorRef,
    private apiService: EmployeeSectionsBenefitsManagementApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone
  ) {
    super(decorator, ngZone);
    decorator.isEditableByConfiguration = false;

    this.gridState = new KendoGridStateHelper<EmployeeBenefitClass>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'startDate', dir: 'asc' }];

    this.createSubscriptions();

    this.updateLookups();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.formValueChangeSubscription = this.ngFormChild.statusChanges.subscribe((x) => {
      console.log(x);
      console.log(this.ngFormChild.valid);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public createSubscriptions(): void {

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.gridEditSubscription = this.gridState.onEdit$.subscribe((item: EmployeeBenefitClass): void => {
      this.editingItem = item;
      this.addMode = false;
    });

    this.gridCancelSubscription = this.gridState.onCancel$.subscribe((item: cancelEvent<EmployeeBenefitClass>): void => {
      this.editingItem = null;
      this.addMode = false;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.gridSaveSubscription = this.gridState.onSave$.subscribe((item: saveEvent<EmployeeBenefitClass>): void => {
      if (item.isNew) {
        let source = this.employeeSectionsBenefitClasses.empBenefitClasses;
        this.employeeSectionsBenefitClasses.empBenefitClasses = _.concat(source, [item.dataItem]);
        this.addMode = false;
      }
      this.doUpdate(item.dataItem);
    });

    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe((item: removeEvent<EmployeeBenefitClass>): void => {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      ConfirmDialog2Component.openDialog(
        'Confirmation',
        'Are you sure that you want to remove this class?',
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.doRemove(item.dataItem);
          }
        }, options);
    });

  }

  public benefitClassChanged(item: EmployeeBenefitClass, value: BenefitClassDefinition): void {
    item.benefitClassId = value.id;
    item.benefitClass = value;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsBenefitClasses;
  }

  public onStartAdd(): void {
    const newEmpBenClass: EmployeeBenefitClass = new EmployeeBenefitClass();
    newEmpBenClass.empId = this.employeeId;
    newEmpBenClass.startDate = (this.employeeStatus.toLowerCase() === "future rehire") ? this.employeeRehireDate: moment().startOf('day').toDate();
    newEmpBenClass.endDate = null;
    this.addMode = true;
    this.grid.addRow(newEmpBenClass);
  }

  protected doUpdate(item: EmployeeBenefitClass): void {

    this.updateTimeline(item, false);
    this.confirmSaveChanges();
  }

  protected doRemove(item: EmployeeBenefitClass): void {
    this.employeeSectionsBenefitClasses.empBenefitClasses = _.without(this.employeeSectionsBenefitClasses.empBenefitClasses, item);
    this.updateTimeline(item, true);
    this.confirmSaveChanges();
  }

  protected confirmSaveChanges() {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;

    let hasDeleted = this.deletedItems && _.size(this.deletedItems) > 0;
    let hasAdded = this.addedItems && _.size(this.addedItems) > 0;
    let hasChanged = this.changedItems && _.size(this.changedItems) > 0;

    if (_.size(this.originalEmployeeBenefitClasses) == 0) {
      hasAdded = hasDeleted = hasChanged = false;
    }

    if (hasDeleted || hasAdded) {

      ConfirmBenefitChangesDialogComponent
        .openDialog(
          this.changedItems,
          this.addedItems,
          this.deletedItems,
          'Confirmation',
          'By updating the following benefit classes will be',
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.originalEmployeeBenefitClasses = this.employeeSectionsBenefitClasses.empBenefitClasses = this.tempTimeline;
              this.refreshGrid();
              this.startProgress();
              this.saveSubsection();
            } else {
              this.tempTimeline = null;
              this.employeeSectionsBenefitClasses.empBenefitClasses = this.originalEmployeeBenefitClasses;
              this.refreshGrid();
              this.changeDetector.markForCheck();
              this.changeDetector.detectChanges();
            }
          });
    } else {


      if (hasChanged) {

        ConfirmDialog2Component.openDialog(
          'Confirmation',
          'Are you sure you want apply changes?',
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.originalEmployeeBenefitClasses = this.employeeSectionsBenefitClasses.empBenefitClasses = this.tempTimeline;
              this.refreshGrid();
              this.startProgress();
              this.saveSubsection();
            } else {
              this.tempTimeline = null;
              this.employeeSectionsBenefitClasses.empBenefitClasses = this.originalEmployeeBenefitClasses;
              this.refreshGrid();
              this.changeDetector.markForCheck();
              this.changeDetector.detectChanges();
            }
          }, options);
      } else {
        this.tempTimeline = null;
        this.originalEmployeeBenefitClasses = this.employeeSectionsBenefitClasses.empBenefitClasses;
        this.refreshGrid();
        this.startProgress();
        this.saveSubsection();
      }

    }
  }

  protected saveSubsection(): void {
    this.apiService.saveBenefitClassesSection(this.employeeSectionsBenefitClasses, this.employeeId)
      .then((status: any) => {
        this.loadSubsection();
      })
      .catch((error: any) => {
        this.loadSubsection();
      });

  }


  protected loadSubsection(): void {
    this.startProgress();
    this.apiService.getEmployeeBenefitClasses(this.employeeId)
      .then((subsectionData: EmployeeSubsectionBenefitClasses) => {
        this.employeeSectionsBenefitClasses = subsectionData;
        this.refreshGrid();
        this.stopProgress();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }


  private refreshGrid(): void {
    if (!this.employeeSectionsBenefitClasses) {
      this.gridView = null;
      return;
    }

    this.gridState.view = process(this.employeeSectionsBenefitClasses.empBenefitClasses, this.gridState.state);
  }


  private updateLookups(): void {
    if (this.m_employeeId) {
      this.lookupService.getLookup({
        lookupType: LookupType.benefitClasses, employeeId: this.m_employeeId
      }).then((lookup: Lookup) => {
        let classes: BenefitClassDefinition[] = [];

        _.forEach(<BenefitClassDefinition[]>lookup.items, (item: BenefitClassDefinition) => {
          classes.push(item);
        });
        this.benefitClassDefinitionsLookup = _.sortBy(classes, x=> x.name);
      });
    }
  }

  private updateTimeline(editedItem: EmployeeBenefitClass, isRemove: boolean): void {

    const maxTime: number = 864 * Math.pow(10, 13);
    this.tempTimeline = null;
    this.deletedItems = [];
    this.addedItems = [];
    this.changedItems = [];

    let copy = _.slice(this.employeeSectionsBenefitClasses.empBenefitClasses, 0);

    if (isRemove) {
      if (_.size(copy) == 0) {
        this.deletedItems = [editedItem];
      }
      /*
      if (_.size(copy) > 0) {
        if (moment(editedItem.startDate).isBefore(this.now)) {

          // close benefit with current date;
          let closedItem = this.copyBenefitItem(editedItem);
          closedItem.endDate = moment(this.now).startOf('day').toDate();

          this.deletedItems = [editedItem];
          this.addedItems = [closedItem];
        }
      } else {
        this.deletedItems = [editedItem];
      }
      */
    } else {

      if (_.isNil(editedItem.id)) {
        this.addedItems = [editedItem];


      } else {
        this.changedItems = [editedItem];

      }

    }

    if (_.size(copy) <= 1) {
      this.tempTimeline = _.slice(copy, 0);
      return;
    }

    // -- process timeline resonving overlaps and filling gaps

    let ranges: { benClass: EmployeeBenefitClass, protectedItem: boolean, skip: boolean, x: number, y: number, parent: any }[] = _.map(copy, (x) => {
      return {
        benClass: x,
        protectedItem: _.isNil(x.id) || editedItem === x,
        x: moment(x.startDate).startOf('day').toDate().getTime(),
        y: x.endDate ? moment(x.endDate).startOf('day').toDate().getTime() : maxTime,
        parent: null,
        skip: false
      };
    });

    let protectedItemIsSubset: boolean = false;
    let protectedItem = _.find(ranges, x => x.protectedItem);
    ranges = _.without(ranges, protectedItem);

    let sets: { benClass: EmployeeBenefitClass, protectedItem: boolean, skip: boolean, x: number, y: number, parent: any }[] = [];
    _.each(ranges, (range) => {
      _.each(ranges, (set) => {
        if (range.x >= set.x && range.y <= set.y && range !== set && !set.skip) {
          range.skip = true;
        }
      });
    });

    _.each(ranges, (range) => {
      if (!range.skip) {
        sets.push(range);
      }
    });

    if (protectedItem) {

      _.each(sets, (range) => {

        if (range.x < protectedItem.x && range.y > protectedItem.y) {
          protectedItemIsSubset = true;
          protectedItem.parent = range;
        } else if (range.x == protectedItem.x && range.y == protectedItem.y) {
          range.skip = true;
        } else if (range.x > protectedItem.x && range.y < protectedItem.y) {
          range.skip = true;
        } else if (range.x == protectedItem.x && range.y < protectedItem.y) {
          range.skip = true;
        } else if (range.x > protectedItem.x && range.y == protectedItem.y) {
          range.skip = true;
        }
      });

      let newArr = [];
      _.each(sets, (range) => {
        if (!range.skip) {
          newArr.push(range);
        }
      });

      sets = newArr;

      if (protectedItemIsSubset) {
        let parent = protectedItem.parent;
        if (protectedItem.benClass.benefitClassId !== parent.benClass.benefitClassId) {
          let leftItem = {
            benClass: parent.benClass,
            protectedItem: false,
            x: parent.x,
            y: protectedItem.x,
            parent: null,
            skip: false
          };

          let rightItem = {
            benClass: parent.benClass,
            protectedItem: false,
            x: protectedItem.y,
            y: parent.y,
            parent: null,
            skip: false
          };

          sets = _.without(sets, parent);
          sets = _.concat(sets, [leftItem, protectedItem, rightItem]);
        } else {
          parent.protectedItem = true;
          parent.skip = false;
        }
      } else {
        sets = _.concat(sets, protectedItem);
      }
    }

    sets = _.sortBy(sets, 'x');

    // resolve overlaps (respect newly added item)
    let distinctSets: { benClass: EmployeeBenefitClass, protectedItem: boolean, skip: boolean, x: number, y: number, parent: any }[] = [];

    const setsSize = _.size(sets);
    const dayInMillisecods = 1000 * 60 * 60 * 24;
    for (let i = 0; i < setsSize; i++) {
      let currentSet = sets[i];
      let priorSet = null;
      if (i > 0) {
        priorSet = sets[i - 1];
      }

      if (!currentSet.protectedItem) {
        if (priorSet) {
          if (currentSet.benClass.benefitClassId === priorSet.benClass.benefitClassId) {
            currentSet.skip = true;
            priorSet.y = currentSet.y;
          } else {
            if (currentSet.x < priorSet.y + dayInMillisecods) {
              currentSet.x = priorSet.y + dayInMillisecods;
            }
          }
        }
      } else {
        if (priorSet) {
          if (currentSet.benClass.benefitClassId === priorSet.benClass.benefitClassId) {
            currentSet.skip = true;
            priorSet.y = currentSet.y;
          } else {
            if (priorSet.y > currentSet.x - dayInMillisecods) {
              priorSet.y = currentSet.x - dayInMillisecods;
            }
          }
        }
      }
    }

    for (let i = 0; i < setsSize; i++) {
      let currentSet = sets[i];
      if (!currentSet.skip) {
        distinctSets.push(currentSet);
      }
    }

    // produce new benefits array
    const dSetsSize = _.size(distinctSets);
    let newBenefitClasses: EmployeeBenefitClass[] = [];
    for (let i = 0; i < dSetsSize; i++) {
      let set = distinctSets[i];
      let benefitClass = new EmployeeBenefitClass();
      benefitClass.id = set.benClass.id;
      benefitClass.benefitClassId = set.benClass.benefitClassId;
      benefitClass.empId = this.employeeId;
      benefitClass.startDate = moment(new Date(set.x)).startOf('day').toDate();
      benefitClass.endDate = set.y != maxTime ? moment(new Date(set.y)).startOf('day').toDate() : null;
      benefitClass.benefitClass = set.benClass.benefitClass;
      benefitClass.updatedOn = set.benClass.updatedOn;
      benefitClass.updatedBy = set.benClass.updatedBy;
      newBenefitClasses.push(benefitClass);
    }

    this.tempTimeline = newBenefitClasses;

    _.each(this.employeeSectionsBenefitClasses.empBenefitClasses, (original: EmployeeBenefitClass) => {
      if (!_.isNil(original.id)) {
        var matchingItem = _.find(this.tempTimeline, temp => {
          return temp.id === original.id;
        });

        if (_.isNil(matchingItem)) {
          this.deletedItems.push(original);
        } else {
          if (!moment(matchingItem.startDate).isSame(original.startDate) || !moment(matchingItem.endDate).isSame(original.endDate)) {
            this.changedItems.push(matchingItem);
          }
        }
      }
    })
  }

  protected copyBenefitItem(editedItem: EmployeeBenefitClass): EmployeeBenefitClass {
    let copy = new EmployeeBenefitClass();
    copy.benefitClassId = editedItem.benefitClassId;
    copy.benefitClass = editedItem.benefitClass;
    copy.empId = editedItem.empId;
    copy.startDate = editedItem.startDate;
    copy.endDate = editedItem.endDate;
    copy.id = editedItem.id;
    copy.updatedBy = editedItem.updatedBy;
    copy.updatedOn = editedItem.updatedOn;

    return copy;
  }


}

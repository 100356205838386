import { AgmCoreModule } from '@agm/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise';
import { MomentModule } from 'angular2-moment';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxPopperModule } from 'ngx-popper';
import { environment } from '../../environments/environment';
import { EssTemplatesModule } from '../app-modules/ess-templates/ess-templates.module';
import { CommonModule as CommonAppModule } from '../common/index';
import { ComponentsLibraryModule } from '../components-library/index';
import { OrganizationModule } from '../organization/index';
import { FileUploadsComponent } from './components/file-uploads/file-uploads.component';
import { UploadedFileStatusComponent } from './components/file-uploads/uploaded-file-status/uploaded-file-status.component';
import { IdealScheduleImportTemplateDialogComponent } from './components/ideal-schedule/ideal-schedule-import-template-dialog/ideal-schedule-import-template-dialog.component';
import { configurationComponents, entryConfigurationComponents, exportConfigurationComponents, idealScheduleComponents } from './components/index';
import { PartnerPositionMappingComponent } from './components/partner-position-mapping/partner-position-mapping.component';
import { OrgStructureCodeComponent } from './components/payroll/code-mapping/org-structure-code/org-structure-code.component';
import { OrgStructureCodesTabComponent } from './components/payroll/code-mapping/org-structure-codes-tab/org-structure-codes-tab.component';
import { ShiftGroupComponent } from './components/shift-group/shift-group.component';
import { BaseFilterSyncComponent } from './components/wfm-sync/base-filter-sync.component';
import { BaseOrgDeptPosSyncComponent } from './components/wfm-sync/base-org-dept-pos-sync.component';
import { BenefitdeductionMigrationComponent } from './components/wfm-sync/benefitdeduction-migration/benefitdeduction-migration.component';
import { BenefitdeductionSyncComponent } from './components/wfm-sync/benefitdeduction-sync/benefitdeduction-sync.component';
import { BenefitSyncComponent } from './components/wfm-sync/dash/benefit-sync/benefit-sync.component';
import { CostcenterSyncComponent } from './components/wfm-sync/dash/costcenter-sync/costcenter-sync.component';
import { DashboardComponent } from './components/wfm-sync/dash/dashboard/dashboard.component';
import { DepartmentSyncComponent } from './components/wfm-sync/dash/department-sync/department-sync.component';
import { EmployeeGraphSyncComponent } from './components/wfm-sync/dash/employee-graph-sync/employee-graph-sync.component';
import { EmployeePayrollappstatusSyncComponent } from './components/wfm-sync/dash/employee-payroll-app-status-sync/employee-payroll-app-status-sync.component';
import { OrgPayrollappstatusSyncComponent } from './components/wfm-sync/dash/org-payrollappstatus-sync/org-payrollappstatus-sync.component';
import { PositionSyncComponent } from './components/wfm-sync/dash/position-sync/position-sync.component';
import { SummaryDashboardComponent } from './components/wfm-sync/dash/summary-dashboard/summary-dashboard.component';
import { DepartmentComponent } from './components/wfm-sync/department/department.component';
import { EmployeeMigrationComponent } from './components/wfm-sync/employee-migration/employee-migration.component';
import { EmployeeSyncComponent } from './components/wfm-sync/employee-sync/employee-sync.component';
import { EventErrorReportComponent } from './components/wfm-sync/event-error-report/event-error-report.component';
import { EventReportComponent } from './components/wfm-sync/event-report/event-report.component';
import { LaborcodeMigrationComponent } from './components/wfm-sync/laborcode-migration/laborcode-migration.component';
import { LaborcodeSyncComponent } from './components/wfm-sync/laborcode-sync/laborcode-sync.component';
import { MultiCheckFilterComponent } from './components/wfm-sync/multicheck-filter/multicheck-filter.component';
import { OrgSyncComponent } from './components/wfm-sync/org-sync/org-sync.component';
import { OrganizationSyncComponent } from './components/wfm-sync/organization-sync/organization-sync.component';
import { OrganizationsComponent } from './components/wfm-sync/organizations/organizations.component';
import { PositionComponent } from './components/wfm-sync/position/position.component';
import { PostEventSyncComponent } from './components/wfm-sync/post-event-sync/post-event-sync.component';
import { BenefitDashboardMigrationComponent } from './components/wfm-sync/sync-dashboard-migration/benefit-dashboard-migration/benefit-dashboard-migration.component';
import { CostcenterDashboardMigrationComponent } from './components/wfm-sync/sync-dashboard-migration/costcenter-dashboard-migration/costcenter-dashboard-migration.component';
import { DepartmentDashboardMigrationComponent } from './components/wfm-sync/sync-dashboard-migration/department-dashboard-migration/department-dashboard-migration.component';
import { EmployeeDashboardMigrationComponent } from './components/wfm-sync/sync-dashboard-migration/employee-dashboard-migration/employee-dashboard-migration.component';
import { EmployeePayrollAppStatusMigrationComponent } from './components/wfm-sync/sync-dashboard-migration/employee-payroll-app-status-sync-migration/employee-payroll-app-status-sync-migration.component';
import { OrgMigrationComponent } from './components/wfm-sync/sync-dashboard-migration/org-migration/org-migration.component';
import { OrgPayrollappstatusMigrationComponent } from './components/wfm-sync/sync-dashboard-migration/org-payrollappstatus-migration/org-payrollappstatus-migration.component';
import { PositionDashboardMigrationComponent } from './components/wfm-sync/sync-dashboard-migration/position-dashboard-migration/position-dashboard-migration.component';
import { SummaryDashboardMigrationComponent } from './components/wfm-sync/sync-dashboard-migration/summary-dashboard-migration/summary-dashboard-migration.component';
import { SyncDashboardMigrationComponent } from './components/wfm-sync/sync-dashboard-migration/sync-dashboard-migration.component';
import { SyncDashboardComponent } from './components/wfm-sync/sync-dashboard/sync-dashboard.component';
import { TAEventReportComponent } from './components/wfm-sync/ta-event-report/ta-event-report.component';
import { WFMMigrationSyncComponent } from './components/wfm-sync/wfm-migration-sync/wfm-migration-sync.component';
import { WfmNgpBenefitdeductionSyncComponent } from './components/wfm-sync/wfm-ngp-benefitdeduction-sync/wfm-ngp-benefitdeduction-sync.component';
import { WfmNgpEmployeeSyncComponent } from './components/wfm-sync/wfm-ngp-employee-sync/wfm-ngp-employee-sync.component';
import { WfmNgpLaborcodeSyncComponent } from './components/wfm-sync/wfm-ngp-laborcode-sync/wfm-ngp-laborcode-sync.component';
import { WfmNgpOrgDeptPosSyncComponent } from './components/wfm-sync/wfm-ngp-org-dept-pos-sync/wfm-ngp-org-dept-pos-sync.component';
import { WfmNgpSecondaryPositionsSyncComponent } from './components/wfm-sync/wfm-ngp-secondary-positions-sync/wfm-ngp-secondary-positions-sync.component';
import { WfmNgpSyncComponent } from './components/wfm-sync/wfm-ngp-sync/wfm-ngp-sync.component';
import { WFMOngoingSyncComponent } from './components/wfm-sync/wfm-ongoing-sync/wfm-ongoing-sync.component';
import { PayrollPayCodesComponent } from './components/payroll/payroll-pay-codes/payroll-pay-codes.component';
import { BaseSyncComponent } from './components/wfm-sync/wfm-sync-base.component';
import { WorkLocationMigrationComponent } from './components/wfm-sync/work-location-migration/work-location-migration.component';
import { WorkLocationSyncComponent } from './components/wfm-sync/work-location-sync/work-location-sync.component';
import { editableListDirectives, stateManagementDirectives } from './directives/index';
import { configurationPipes } from './pipes/index';
import { services } from './services/index';

LicenseManager.setLicenseKey(environment.AG_GRID_LICENCE);

@NgModule({
    imports: [
        RouterModule,
        FormsModule, ReactiveFormsModule,
        HttpClientModule,
        CommonAppModule,
        CommonModule,
        ComponentsLibraryModule,
        OrganizationModule,
        EssTemplatesModule,
        MomentModule,
        DialogsModule,
        ColorPickerModule,
        GridModule,
        ExcelModule,
        PDFModule,
        DropDownsModule,
        SortableModule,
        NgxPopperModule,
        Ng2SearchPipeModule,
        AgmCoreModule,
        TreeViewModule,
        AgmCoreModule,
        ChartsModule,
        LayoutModule,
        AgGridModule,
        ProgressBarModule,
        InputsModule,
        ButtonsModule,
    ],
    declarations: [
        ...configurationComponents,
        ...editableListDirectives,
        ...stateManagementDirectives,
        ...idealScheduleComponents,
        ...configurationPipes,
        BaseSyncComponent,
        BaseFilterSyncComponent,
        BaseOrgDeptPosSyncComponent,
        PartnerPositionMappingComponent,
        EventReportComponent,
        WorkLocationMigrationComponent,
        WorkLocationSyncComponent,
        OrgSyncComponent,
        EmployeeSyncComponent,
        EventErrorReportComponent,
        OrganizationSyncComponent,
        DepartmentComponent,
        PositionComponent,
        OrganizationsComponent,
        BenefitdeductionSyncComponent,
        LaborcodeSyncComponent,
        PostEventSyncComponent,
        BenefitdeductionMigrationComponent,
        EmployeeMigrationComponent,
        LaborcodeMigrationComponent,
        BenefitSyncComponent,
        CostcenterSyncComponent,
        DashboardComponent,
        DepartmentSyncComponent,
        EmployeeGraphSyncComponent,
        PositionSyncComponent,
        SyncDashboardComponent,
        BenefitDashboardMigrationComponent,
        CostcenterDashboardMigrationComponent,
        DepartmentDashboardMigrationComponent,
        EmployeeDashboardMigrationComponent,
        OrgMigrationComponent,
        PositionDashboardMigrationComponent,
        SyncDashboardMigrationComponent,
        SummaryDashboardComponent,
        SummaryDashboardMigrationComponent,
        ShiftGroupComponent,
        IdealScheduleImportTemplateDialogComponent,
        OrgStructureCodeComponent,
        OrgStructureCodesTabComponent,
        PayrollPayCodesComponent,
        OrgPayrollappstatusSyncComponent,
        OrgPayrollappstatusMigrationComponent,
        OrgPayrollappstatusSyncComponent,
        EmployeePayrollappstatusSyncComponent,
        EmployeePayrollAppStatusMigrationComponent,
        FileUploadsComponent,
        UploadedFileStatusComponent,
        EmployeePayrollAppStatusMigrationComponent,
        TAEventReportComponent,
        UploadedFileStatusComponent,
        WfmNgpSyncComponent,
        WfmNgpEmployeeSyncComponent,
        WfmNgpLaborcodeSyncComponent,
        WfmNgpBenefitdeductionSyncComponent,
        WfmNgpOrgDeptPosSyncComponent,
        UploadedFileStatusComponent,
        WFMOngoingSyncComponent,
        WFMMigrationSyncComponent,
        MultiCheckFilterComponent,
        WfmNgpSecondaryPositionsSyncComponent
    ],
    entryComponents: [
        entryConfigurationComponents
      ],    
    providers: [
        ...services,
        ...configurationPipes,
        DatePipe
    ],
    exports: [
        exportConfigurationComponents,
        ...idealScheduleComponents,
        ...editableListDirectives,
        ...stateManagementDirectives,
        ...configurationPipes
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ConfigurationModule { }


import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Subscription ,  of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { unsubscribeAll } from '../../../../../core/decorators/index';

import { DeviceDetectorService } from '../../../../../common/services/index';
import { BenefitDetailsProviderLineStandartEntity, BenefitDetailsUserActions, BenefitDetailsEditModes, BenefitDetailsProviderEntity } from '../../../models/index';
import { BenefitDetailsManagementService, BenefitDetailsStandartManagementService, BenefitDetailsPermissionService } from '../../../services/index';

@Component({
  selector: 'slx-benefit-details-providers-menu',
  templateUrl: './benefit-details-providers-menu.component.html',
  styleUrls: ['./benefit-details-providers-menu.component.scss'],
})
export class BenefitDetailsProvidersMenuComponent implements OnInit, OnDestroy {
  public providers: BenefitDetailsProviderEntity[] = [];
  public isDisabledLeft = true;
  public isDisabledRight = true;
  public canCreate = true;
  public isEditMode = false;
  public get isMobile(): boolean {
    return this.devDetector.isMobile;
  }
  public get isHiddenLeftRigth(): boolean {
    return this.isDisabledLeft && this.isDisabledRight;
  }
  public selectedProvider: BenefitDetailsProviderEntity;

  @ViewChild('list')
  public ulElem: ElementRef<HTMLUListElement>;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private currentShiftPx = 0;
  private tabsViewWidth = 0;
  private totalTabsWidth = 0;
  private step = 200;

  constructor(
    private commonManService: BenefitDetailsManagementService,
    private permissionService: BenefitDetailsPermissionService,
    private manService: BenefitDetailsStandartManagementService,
    private devDetector: DeviceDetectorService
  ) {
  }

  public ngOnInit() {
    this.subscriptions.providers = this.commonManService
      .subscribeToLoadProviders((p: BenefitDetailsProviderEntity[]) => {
        if (_.isArray(p)) {
          this.providers = p;
          if (_.size(p) > 0) {
            this.runDesktopFunctionality(null);
          } else {
            this.selectedProvider = null;
            this.isDisabledLeft = true;
            this.isDisabledRight = true;
          }
        }
      });

    this.subscriptions.provider = this.commonManService
      .subscribeToSelectProvider((p: BenefitDetailsProviderEntity) => {
        if (_.isObjectLike(p)) {
          this.selectedProvider = p;
          this.runDesktopFunctionality(p);
        }
      });

    this.subscriptions.actions = this.permissionService
      .subscribeToUserActions((v: BenefitDetailsUserActions) => (this.canCreate = v.canAdd));

    this.subscriptions.editmode = this.permissionService
      .subscribeToEditMode((v: BenefitDetailsEditModes) => (this.isEditMode = v.providerInfo));
  }

  public ngOnDestroy(): void {
    this.selectedProvider = null;
  }

  public get translateX(): string {
    return `translateX(-${this.currentShiftPx}px)`;
  }

  public isActiveMenu(p: BenefitDetailsProviderEntity): boolean {
    return this.commonManService.isEqualProviders(this.selectedProvider, p);
  }

  public onClickTab(p: BenefitDetailsProviderEntity): void {
    if (this.isActiveMenu(p) || this.isEditMode) return;
    this.selectedProvider = p;
    this.commonManService.selectProvider(p, true);
  }

  public onClickShift(isLeft: boolean): void {
    if (isLeft && !this.isDisabledLeft || !isLeft && !this.isDisabledRight) {
      const shift = isLeft ? this.currentShiftPx - this.step : this.currentShiftPx + this.step;
      this.moveMenuTo(shift);
    }
  }

  public onChangeProviderOnMobile(p: BenefitDetailsProviderEntity): void {
    if (this.isEditMode) return;
    this.selectedProvider = p;
    this.commonManService.selectProvider(p, true);
  }

  public onCreateNew(): void {
    this.manService.createDraftProvider();
  }

  private runDesktopFunctionality(p: BenefitDetailsProviderEntity): void {
    if (!this.isMobile) {
      this.calcMenusDimensions(150)
        .then(() => {
          if (_.isObjectLike(p)) {
            this.selectMenu(p);
          }
        });
    }
  }

  private selectMenu(p: BenefitDetailsProviderEntity): void {
    const index = _.findIndex(this.providers, pr => this.commonManService.isEqualProviders(pr, p));
    const shift = _.reduce(this.ulElem.nativeElement.children, (accum, elem: HTMLLIElement, i) => {
      if (index > i) {
        accum += elem.offsetWidth;
      }
      return accum;
    }, 0);
    this.moveMenuTo(shift);
  }

  private moveMenuTo(shift: number): void {
    const minAvailableShift = 0;
    const maxAvailableShift = Math.max(this.totalTabsWidth - this.tabsViewWidth, 0);
    if (this.totalTabsWidth > this.tabsViewWidth) {
      if (shift >= maxAvailableShift) {
        this.currentShiftPx = maxAvailableShift;
      } else if (shift <= minAvailableShift) {
        this.currentShiftPx = minAvailableShift;
      } else {
        this.currentShiftPx = shift;
      }
    } else {
      this.currentShiftPx = 0;
    }
    this.isDisabledLeft = this.currentShiftPx === minAvailableShift;
    this.isDisabledRight = this.currentShiftPx === maxAvailableShift;
  }

  private calcMenusDimensions(delayMs: number): Promise<void> {
    return of(true)
      .pipe(delay(delayMs))
      .toPromise()
      .then(() => {
        const { nativeElement: ul } = this.ulElem;
        const width = _.reduce(ul.children, (accum, elem: HTMLLIElement) => (accum + elem.offsetWidth), 0);
        if (width > 0) {
          this.totalTabsWidth = width;
          this.tabsViewWidth = ul.offsetWidth;
          this.isDisabledRight = this.totalTabsWidth <= this.tabsViewWidth;
        }
      });
  }
}

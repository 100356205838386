import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';

export const requireNumberMultiValidationKey = 'requireNumberMulti';

function checkSplitValue(input: string): boolean {
  const nums: string[] = input.split(",");
  for (let i = 0; i < nums.length; i++) {
      if (!(validation(nums[i].trim()))) {
          return false;
      }
  }
  return true;
}

function validation(input: string): boolean {
  if (input.length == 0) {
      return false;
  }
  if (input.length > 1 && input.startsWith("0", 0)) {
      return false;
  }
  for (let i = 0; i < input.length; i++) {
      const character: string = input.charAt(i);
      const characterVal: number = parseInt(character);
      if (!(Number.isInteger(characterVal))) {
          return false;
      }
  }
  return true;
}

export function requireNumberMultiValidator(): ValidatorFn {
  
  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value ? _.trim(control.value) : control.value;
    const isValid = checkSplitValue(value);
    return !isValid ? { [requireNumberMultiValidationKey]: true } : null;
  };

  return validator;
}

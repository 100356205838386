import { Directive, Input, ElementRef, HostListener, OnInit, Host, AfterViewInit, DoCheck, Optional, Renderer2 } from '@angular/core';
import { GridComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';

import { IColumnSettings } from '../../../../core/models/index';
import { ColumnSettingsStorageService, ColumnManagementService, GroupEvent, ColumnsChangedEvent } from '../../../services/index';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/index';

@Directive({
  selector: '[slxKendoGridColumnsGroup]',
})
export class KendoGridColumnsGroupDirective {

  @Input('slxKendoGridColumnsGroup')
  public columnGroup: string = ColumnSettingsStorageService.defaultColumnGroupKey;

}

import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Subject ,  ReplaySubject } from 'rxjs';
import { LookupService, OrgLevelWatchService } from '../../../../organization/services/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { MealDeductionApiService } from './meal-deduction-api.service';
import { Organization, LookupType, Lookup } from '../../../../organization/models/index';
import { MealDeductionSettings, ShiftModes } from '../../models/index';
import { ManagementBaseService } from '../../../../core/services/index';

@Injectable()
export class MealDeductionManagementService extends ManagementBaseService<MealDeductionSettings, any> {

    public onSaved$: Subject<boolean>;

    public selIds$: ReplaySubject<Number[]>;

    private settings: MealDeductionSettings;
    private orgiginalSerialized: string;

    private currentOrgLevel: OrgLevel;
    private currentOrganization: Organization;
    public organizationsLookup: Lookup;

    constructor(private api: MealDeductionApiService,
      private lookupService: LookupService,
      private orgLevelWatchService: OrgLevelWatchService)
    {
      super();
      this.onSaved$ = new Subject();
      this.selIds$ = new ReplaySubject();
    }

    public saveConfiguration(settings: MealDeductionSettings): void {

        this.onLoadStatusChanged(true);
        if (_.isNil(settings.recordId) || settings.recordId === 0) {
            this.api.addOrganizationMealDeductionSettings(settings).then(() => {
                this.onSaved$.next(true);
                this.onLoadStatusChanged(false);
            }).catch(() => {
                this.onSaved$.next(false);
                this.onLoadStatusChanged(false);
            });
        } else {

            let savingSerialized: string = JSON.stringify(settings);

            if (savingSerialized === this.orgiginalSerialized) {
                this.onSaved$.next(false);
                return;
            }

            this.api.saveOrganizationMealDeductionSettings(settings).then(() => {
                this.onSaved$.next(true);
                this.onLoadStatusChanged(false);
            }).catch(() => {
                this.onSaved$.next(false);
                this.onLoadStatusChanged(false);
            });
        }
    }

    public reload(): void {
        if (!_.isNil(this.currentOrganization)) {
            this.api.getOrganizationMealDeductionSettings(this.currentOrganization.id)
                .then((s: MealDeductionSettings) => {
                    if (!_.isNil(s.recordId) && s.recordId !== 0) {
                        this.settings = s;
                        this.orgiginalSerialized = JSON.stringify(this.settings);
                    } else {
                        let settings: MealDeductionSettings = this.createDefaultSettings(this.currentOrganization);
                        settings.canConfigure = s.canConfigure;
                        settings.generatePBJFromAgency = s.generatePBJFromAgency;
                        settings.shift_Type = s.shift_Type;
                        settings.midShiftOne = s.midShiftOne;
                        settings.midShiftTwo = s.midShiftTwo;
                        this.settings = settings;
                        this.orgiginalSerialized = null;
                    }
                    this.onLoaded(this.settings);
                    this.onLoadStatusChanged(false);
                }).catch(() => {
                    this.settings = null;
                    this.orgiginalSerialized = null;
                    this.onLoaded(this.settings);
                    this.onLoadStatusChanged(false);
                });
        }
        else
        {
            let settings: MealDeductionSettings = this.createDefaultSettings(this.currentOrganization);
            // settings.canConfigure = true;
            // settings.generatePBJFromAgency = false;
            // settings.shift_Type = "";
            // settings.midShiftOne = 0;
            // settings.midShiftTwo = 0;
            // this.settings = settings;
            // this.orgiginalSerialized = null;

            this.onLoaded(this.settings);
            this.onLoadStatusChanged(false);
        }
    }

    public onOrglevelChanged(orgLevel: OrgLevel): void {
        this.currentOrgLevel = orgLevel;
        this.onLoadStatusChanged(true);
        this.loadOrganizations().then(() => {
          this.getCurrentOrganization();
        });
    }

    private createDefaultSettings(organization: Organization): MealDeductionSettings {
        let s: MealDeductionSettings = new MealDeductionSettings();
        s.organizationId = organization.id;
        s.enableForAgency = undefined;
        s.enableForExemptS = undefined;
        s.enableForExemptT = undefined;
        s.enableForExemptX = undefined;
        s.enableForExemptY = undefined;
        s.enableForNonExempt = undefined;
        s.maxPunchTreshold = undefined;
        s.lastUpdateOn = new Date();
        s.generatePBJFromAgency = false;
        s.shift_Type = _.head(ShiftModes);
        s.midShiftOne = 4;
        s.midShiftTwo = 12;
        return s;
    }

    private getCurrentOrganization(): void {
        let organization: Organization;
        let searchOrgLevelId: number;
        let corpOrgLevel: OrgLevel = this.orgLevelWatchService.getCurrentOrgLevel();
        let lookupdata: any = this.organizationsLookup.items.filter((x)=>x.id != 1000);
        if (corpOrgLevel.type === OrgLevelType.organization) {
          searchOrgLevelId = this.currentOrgLevel.id;
          this.currentOrganization = _.find(lookupdata, (o: Organization) => o.orgLevelId === searchOrgLevelId);
          this.reload();
        }
        else if (corpOrgLevel.type == OrgLevelType.department) {
          searchOrgLevelId = this.currentOrgLevel.parentId;
          this.currentOrganization = _.find(lookupdata, (o: Organization) => o.orgLevelId === searchOrgLevelId);
          this.reload();
        }
        else {
          this.api.getAllOrganizationIds(corpOrgLevel.id).then(res => {
            let orgLevelIds: any = res.data;
            if (!_.isNil(orgLevelIds)) {
              for (let x = 0; x < orgLevelIds.length; x++) {
                for (let y = 0; y < lookupdata.length; y++) {
                  if (orgLevelIds[x] == lookupdata[y].orgLevelId ) {
                    organization = lookupdata[y];
                    break;
                  }
                }
                if (organization) {
                  this.currentOrganization = organization;
                  this.reload();
                  break;
                }
              }
            }
          });
        }
      }
    private loadOrganizations(): Promise<Lookup> {
        return this.lookupService
            .getLookup({ lookupType: LookupType.organization })
            .then((organizationLookup: Lookup) => {
                this.organizationsLookup = organizationLookup;
                return this.organizationsLookup;
            });
    }

    setSelectedIds(selIds:Number[]){
      this.selIds$.next(selIds)
    }

    getSelectedIds(){
      let selectedIds:Number[];
      this.selIds$.subscribe((data:Number[])=>{
        selectedIds = data;
      })
      return selectedIds;
    }
}

<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  [resizable]="true"
  (dataStateChange)="gridState.dataStateChange($event)"

  [slxKendoGridFiltersState]="gridState"
>
  <kendo-grid-column title="Name" field="employee.name" width="180" [class]="{'slx-padding-6': true}" [media]="getMediaForDesktop()">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="flex align-items__center roster-emp" [title]="dataItem.employee?.name" (click)="onClickEmployee(dataItem)">
        <img class="rounded-image roster-emp__img" [employeeThumbinalSrc]="dataItem.employee?.id" alt="Employee Photo" />
        <span class="roster-emp__text">{{ dataItem.employee?.name }}</span>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Position"
    field="position.name"
    [hidden]="!isVisible('position')"
    [width]="getWidth('position')"
    [filter]="getFilter('position')"
    [media]="getMediaForDesktop()"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Facility"
    field="organization.name"
    [hidden]="!isVisible('organization')"
    [width]="getWidth('organization')"
    [filter]="getFilter('organization')"
    [media]="getMediaForDesktop()"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Facility</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Department"
    field="department.name"
    [hidden]="!isVisible('department')"
    [width]="getWidth('department')"
    [filter]="getFilter('department')"
    [media]="getMediaForDesktop()"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Department</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.department?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Type"
    field="type.name"
    [hidden]="!isVisible('type')"
    [width]="getWidth('type')"
    [filter]="getFilter('type')"
    [media]="getMediaForDesktop()"
    [class]="{'slx-padding-0': true}"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Type</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="flex justify-content__space-between align-items__center roster-type">
        <em class="roster-type__color" [style.backgroundColor]="dataItem.typeColor"></em>
        <i class="fas roster-type__icon"
          [style.color]="dataItem.typeColor"
          [ngClass]="{ 'fa-repeat': dataItem.isContinuous, 'fa-random': dataItem.isIntermittent }"
          aria-hidden="true"
        ></i>
        <span class="roster-type__text flex__grow">{{ dataItem.typeName }}</span>
      </span>
    </ng-template>

    <ng-template kendoGridFilterMenuTemplate
       let-column="column"
       let-filter="filter"
       let-filterService="filterService"
       >
       <slx-multiselect class="loa-type__filter"
        [options]="loaTypes"
        [ngModel]="categoryFilters(filter)"
        (ngModelChange)="onChangeLoaTypeFilter($event, filterService)"
        titleField="name"
        valueField="name"
        placeholder="Type filter"
        name="loaFilter"
       ></slx-multiselect>
   </ng-template>

  </kendo-grid-column>

  <kendo-grid-column-group title="Estimated" [locked]="false">
    <kendo-grid-column
      title="Start Date"
      field="estStartDate"
      [hidden]="!isVisible('estStartDate')"
      [width]="getWidth('estStartDate')"
      [filter]="getFilter('estStartDate')"
      [media]="getMediaForDesktop()"
    >
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Start Date</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.estStartDate | amDateFormat: appConfig.dateFormat }}</ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="End Date"
      field="estEndDate"
      [hidden]="!isVisible('estEndDate')"
      [width]="getWidth('estEndDate')"
      [filter]="getFilter('estEndDate')"
      [media]="getMediaForDesktop()"
    >
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>End Date</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.estEndDate | amDateFormat: appConfig.dateFormat }}</ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Actual" [locked]="false">
    <kendo-grid-column
      title="Start Date"
      field="actStartDate"
      [hidden]="!isVisible('actStartDate')"
      [width]="getWidth('actStartDate')"
      [filter]="getFilter('actStartDate')"
      [media]="getMediaForDesktop()"
    >
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Start Date</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.actStartDate | amDateFormat: appConfig.dateFormat }}</ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="End Date"
      field="actEndDate"
      [hidden]="!isVisible('actEndDate')"
      [width]="getWidth('actEndDate')"
      [filter]="getFilter('actEndDate')"
      [media]="getMediaForDesktop()"
    >
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>End Date</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.actEndDate | amDateFormat: appConfig.dateFormat }}</ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Days" [locked]="false">
    <kendo-grid-column
      title="On Leave"
      field="daysOnLeaveText"
      [hidden]="!isVisible('daysOnLeave')"
      [width]="getWidth('daysOnLeave')"
      [filter]="getFilter('daysOnLeave')"
      [media]="getMediaForDesktop()"
    >
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>On Leave</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="!dataItem.isNegativeDaysBalance">{{dataItem.daysOnLeaveText}}</ng-container>
        <strong class="roster-warning" *ngIf="dataItem.isNegativeDaysBalance">{{dataItem.daysOnLeaveText}}</strong>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="Remaining"
      field="daysRemainingText"
      [hidden]="!isVisible('daysRemaining')"
      [width]="getWidth('daysRemaining')"
      [filter]="getFilter('daysRemaining')"
      [media]="getMediaForDesktop()"
    >
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Remaining</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [ngClass]="{ 'roster-warning': dataItem.isNegativeDaysBalance }">{{dataItem.daysRemainingText}}</span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column
    title="Reason"
    field="reason"
    [hidden]="!isVisible('reason')"
    [width]="getWidth('reason')"
    [filter]="getFilter('reason')"
    [media]="getMediaForDesktop()"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Reason</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.reason }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Notes"
    field="notes"
    [hidden]="!isVisible('notes')"
    [width]="getWidth('notes')"
    [filter]="getFilter('notes')"
    [media]="getMediaForDesktop()"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Notes</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.notes }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Attachments"
    [sortable]="false"
    [filterable]="false"
    field="attachmentsLength"
    [hidden]="!isVisible('attachments')"
    [width]="getWidth('attachments')"
    [media]="getMediaForDesktop()"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Attachments</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="roster-attachments" (click)="onClickAttachaments(dataItem)">{{ dataItem.attachmentsLength }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Created By"
    field="addedBy"
    [hidden]="!isVisible('addedBy')"
    [width]="getWidth('addedBy')"
    [filter]="getFilter('addedBy')"
    [media]="getMediaForDesktop()"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Created By</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.addedBy }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Created Date"
    field="addedDate"
    [hidden]="!isVisible('addedDate')"
    [width]="getWidth('addedDate')"
    [filter]="getFilter('addedDate')"
    [media]="getMediaForDesktop()"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Created Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.addedDate | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Last Updated By"
    field="updatedBy"
    [hidden]="!isVisible('updatedBy')"
    [width]="getWidth('updatedBy')"
    [filter]="getFilter('updatedBy')"
    [media]="getMediaForDesktop()"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Last Updated By</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.updatedBy }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Last Updated Date"
    field="updatedDate"
    [hidden]="!isVisible('updatedDate')"
    [width]="getWidth('updatedDate')"
    [filter]="getFilter('updatedDate')"
    [media]="getMediaForDesktop()"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Last Updated Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.updatedDate | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Roster Employee List"
    [filterable]="false"
    [sortable]="false"
    [media]="getMediaForMobile()"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <section class="m-roster">
        <header class="flex justify-content__space-between align-content__flex-start m-roster__header">
          <div class="flex">
            <img class="rounded-image m-roster__emp-i" [employeeThumbinalSrc]="dataItem.employee?.id" alt="Employee Photo" />
            <span class="m-roster__emp">
              <span class="m-roster__emp-name">{{ dataItem.employee?.name }}</span>
              <span class="roster-type__emp-type">
                <i aria-hidden="true" class="fas fa-repeat m-roster__emp-type-i" *ngIf="dataItem.isContinuous"></i>
                <i aria-hidden="true" class="fas fa-random m-roster__emp-type-i" *ngIf="dataItem.isIntermittent"></i>
                {{ dataItem.type.name }}
              </span>
            </span>
          </div>
          <div>
            <i aria-hidden="true" class="fas fa-edit m-roster__edit-i"></i>
          </div>
        </header>
        <footer class="m-roster__footer">
          <kendo-tabstrip class="slx-tabs">
            <kendo-tabstrip-tab title="Dates" [selected]="true">
              <ng-template kendoTabContent>
                <section class="m-roster__tab">
                  <div class="flex flex-direction-row m-roster__f-group">
                    <p class="m-roster__field">
                      <span class="m-roster__title">Actual Dates</span>
                      <span class="m-roster__value">
                        <ng-container *ngIf="dataItem.actStartDate && dataItem.actEndDate">
                          {{ dataItem.actStartDate | amDateFormat: appConfig.dateFormat }} to
                          {{ dataItem.actEndDate | amDateFormat: appConfig.dateFormat }}
                        </ng-container>
                        <ng-container *ngIf="!dataItem.actStartDate && !dataItem.actEndDate">-</ng-container>
                      </span>
                    </p>
                    <p class="m-roster__field">
                      <span class="m-roster__title">Estimated Dates</span>
                      <span class="m-roster__value">
                        <ng-container *ngIf="dataItem.estStartDate && dataItem.estEndDate">
                          {{ dataItem.estStartDate | amDateFormat: appConfig.dateFormat }} to
                          {{ dataItem.estEndDate | amDateFormat: appConfig.dateFormat }}
                        </ng-container>
                        <ng-container *ngIf="!dataItem.estStartDate && !dataItem.estEndDate">-</ng-container>
                      </span>
                    </p>
                  </div>
                  <div class="flex flex-direction-row m-roster__f-group">
                    <p class="m-roster__field">
                      <span class="m-roster__title">Days on Leave</span>
                      <span class="m-roster__value">
                        <span [ngClass]="{ 'roster-warning': dataItem.isNegativeDaysBalance }">{{ dataItem.daysOnLeave }}</span>
                      </span>
                    </p>
                    <p class="m-roster__field">
                      <span class="m-roster__title">Remaining Days</span>
                      <span class="m-roster__value">
                        <span [ngClass]="{ 'roster-warning': dataItem.isNegativeDaysBalance }">{{ dataItem.daysRemaining }}</span>
                      </span>
                    </p>
                  </div>
                </section>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="Details">
              <ng-template kendoTabContent>
                <section class="m-roster__tab">
                  <div class="flex flex-direction-row m-roster__f-group">
                    <p class="m-roster__field">
                      <span class="m-roster__title">Position</span>
                      <span class="m-roster__value">{{ dataItem.position?.name }}</span>
                    </p>
                    <p class="m-roster__field">
                      <span class="m-roster__title">Facility</span>
                      <span class="m-roster__value">{{ dataItem.organization?.name }}</span>
                    </p>
                  </div>
                  <div class="flex flex-direction-row m-roster__f-group">
                    <p class="m-roster__field">
                      <span class="m-roster__title">Department</span>
                      <span class="m-roster__value">{{ dataItem.department?.name }}</span>
                    </p>
                  </div>
                </section>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="Notes">
              <ng-template kendoTabContent>
                <section class="m-roster__tab">
                  <label for="notes" class="m-roster__notes-label">Notes</label>
                  <textarea id="notes" class="m-roster__notes-text" placeholder="Notes"></textarea>
                </section>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="Exceptions">
              <ng-template kendoTabContent>
                <section class="m-roster__tab">
                  <div class="m-roster__exceptions">
                    <span class="m-roster__excep-title">Exception Dates</span>
                    <ng-container *ngIf="dataItem.exceptionDates.length > 0">
                      <p *ngFor="let e of dataItem.exceptionDates" class="m-roster__excep-dates">
                        {{ e.startDate | amDateFormat: appConfig.dateTimeFormatUS }} to
                        {{ e.endDate | amDateFormat: appConfig.dateTimeFormatUS }}
                      </p>
                    </ng-container>
                    <ng-container *ngIf="dataItem.exceptionDates.length === 0">
                      <p class="m-roster__excep-dates">No exception dates specified</p>
                      </ng-container>
                  </div>
                </section>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab>
              <ng-template kendoTabTitle>
                <i aria-hidden="true" class="fas fa-paperclip"></i>
              </ng-template>
              <ng-template kendoTabContent>
                <section class="m-roster__tab">
                  <ng-container *ngIf="dataItem.attachments.length > 0">
                    <p *ngFor="let a of dataItem.attachments" class="m-roster__attach-file">
                      <i aria-hidden="true" class="fal fa-file m-roster__attach-i"></i>
                      <span class="m-roster__attach-name">{{ a.fileName }}</span>
                    </p>
                  </ng-container>
                  <ng-container *ngIf="dataItem.attachments.length === 0">
                    <p class="m-roster__attach-no">No attachments</p>
                  </ng-container>
                </section>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </footer>
      </section>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel fileName="roster List.xlsx" [fetchData]="retriveAllPages()"></kendo-grid-excel>
  <kendo-grid-pdf fileName="roster List.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Event, Params, UrlSegment } from '@angular/router';

import { Subscription ,  Observable } from 'rxjs';

import { UserApplication } from '../../../state-model/models/index';


import { ApplicationService, ScreenAvailabilityService } from '../../services/index';
import { ScreenAvailabilityState } from '../../models/index';

import { mutableSelect } from '../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-application-container',
  templateUrl: 'application-container.component.html',
  styleUrls: ['application-container.component.scss']
})
export class ApplicationContainerComponent implements OnInit, OnDestroy {
  @mutableSelect('application')
  public application: Observable<UserApplication>;

  @mutableSelect(['sidebar', 'isRightSidebarOpen'])
  public isRightSidebarOpen: Observable<boolean>;

  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  @mutableSelect(['sidebar', 'isLeftSidebarHidden'])
  public isLeftSidebarHidden: Observable<boolean>;

  public availability: ScreenAvailabilityState;

  private router: Router;
  private route: ActivatedRoute;
  private applicationService: ApplicationService;
  private screenAvailabilityService: ScreenAvailabilityService;

  private applicationSubscription: Subscription;
  private restoreOrgLevelSubcription: Subscription;
  private availabilitySubscription: Subscription;
  private applicationSelected: boolean;

  constructor(
    router: Router,
    route: ActivatedRoute,
    applicationService: ApplicationService,
    screenAvailabilityService: ScreenAvailabilityService
  ) {
    this.router = router;
    this.route = route;
    this.applicationService = applicationService;
    this.screenAvailabilityService = screenAvailabilityService;
    this.availability = new ScreenAvailabilityState();

    this.applicationSelected = false;
  }

  public ngOnInit(): void {
   this.availabilitySubscription = this.screenAvailabilityService.subscribeToAvailability((availability: ScreenAvailabilityState) => {
    this.availability = availability;
   });
  }

  public ngOnDestroy(): void {
    if (this.availabilitySubscription) {
      this.availabilitySubscription.unsubscribe();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { AuthenticationService } from '../../../authentication/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { ApplicationStateBusService } from '../../../organization/services/index';


@Component({
  moduleId: module.id,
  selector: 'slx-bottom-menu',
  templateUrl: 'bottom-menu.component.html',
  styleUrls: ['bottom-menu.component.scss']
})
export class BottomMenuComponent implements OnInit {

  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen$: Observable<boolean>;

  public isLeftSidebarOpen: boolean;
  public isExpanded: boolean;

  @unsubscribe()
  private isLeftSidebarOpenSubscripion: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private changeManagementService: ChangeManagementService,
    private applicationStateBusService: ApplicationStateBusService
  ) {
  }

  public ngOnInit(): void {
    scheduleMicrotask(() => {
      this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen$.subscribe((value: boolean) => {
        this.isLeftSidebarOpen = value;
        if (this.isLeftSidebarOpen) {
          this.isExpanded = false;
        }
      });
    });
  }

  public toggleFullScreen(): void {
    const doc: any = window.document;
    const elem: any = doc.documentElement;
    if (!doc.fullscreenElement && !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    } else {
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      }
    }
  }

  public toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }

  public logOut(): void {
    this.changeManagementService.canMoveForward()
      .then((can: boolean) => {
        if (can) {
          //TODO we should move authenticationService to oganization module or move applicationStateBusService to common or core
          this.authenticationService.logout();
          this.applicationStateBusService.logout();
        } else {
          //todo
        }
      });
  }
}

import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { process, aggregateBy, AggregateDescriptor } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';

import { IColumnSettings } from '../../../../../../core/models/settings/index';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';
import { unsubscribeAll } from '../../../../../../core/decorators/index';

import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { OrgLevel } from '../../../../../../state-model/models/index';

import { WcComparisonsEntry, WCComparisonsState, WcComparisonsContainer } from '../../../../models/index';
import { WcComparisonsManagementService } from '../../../../services/index';
import { WcRosterToolbarService } from '../../../../services/index'

@Component({
  selector: 'slx-wc-comparisons-grid',
  templateUrl: './wc-comparisons-grid.component.html',
  styleUrls: ['./wc-comparisons-grid.component.scss']
})

export class WcComparisonsGridComponent implements OnInit, OnDestroy {
  public records: WcComparisonsEntry[];
  public storedRecords: WcComparisonsEntry[];
  public gridState: KendoGridStateHelper<WcComparisonsEntry>;
  public appConfig: IApplicationConfig;
  public orgLevel: OrgLevel;
  public state: WCComparisonsState;
  public pageSize: number = 50;
  public canEdit: boolean;
  public totals: StringMap<any>;
  public testrecords: WcComparisonsEntry[];
  public gridDataContainer: any;
  public yearArray: Array<number>;
  public aggregates: AggregateDescriptor[];
  public columns: Array<IColumnSettings>;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: WcComparisonsManagementService) {
    this.gridState = new KendoGridStateHelper<WcComparisonsEntry>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [
      { field: 'organization', dir: 'asc' }
    ];
    this.appConfig = appConfig;
    this.totals = {};
    this.aggregates = [{ field: 'total', aggregate: 'sum' }];
  }

  public ngOnInit(): void {
    this.subscriptions.onLoaded = this.manService
      .subscribeToLoadedRecords((r: WcComparisonsContainer) => {
        this.canEdit = r.canEdit;
        this.assignRecords(r.records);
      });

    this.subscriptions.state = this.manService
      .subscribeToChangeState((s: WCComparisonsState) => this.applyState(s));

    this.subscriptions.export = this.manService
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());

    this.manService.subscritpionToSenseYearChange((s: number[]) => {
      this.yearArray = s;
    });

  }

  private assignRecords(records: WcComparisonsEntry[]): void {
    this.records = records;
    this.storedRecords = _.clone(records);
    this.refreshGrid();
  }

  private applyState(s: WCComparisonsState): void {
    this.state = s;
    this.columns = s.monthlyColumns;
    this.refreshGrid();
  }

  public isVisible(field: string): boolean {
    return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].displayed : false;
  }

  public aggregateFunc(columns) {
    this.aggregates = [{ field: 'total', aggregate: 'sum' }];
    for (let i in columns) {
      this.aggregates.push({ field: this.columns[i].name, aggregate: 'sum' })
    }
  }

  public ngOnDestroy(): void {
    this.manService.destroy();
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  public getMonthwiseCount(dateItem, column): number {
    return dateItem[column.name];
  }

  public getMonthwiseTotalCount(totals, column): number {
    if (totals[column.name]) {
      return totals[column.name].sum;
    }
  }

  public onExcelExport(e: any): void {
    const rows = e.workbook.sheets[0].rows;
    e.workbook.sheets[0].rows.unshift(
      {
        cells: [{
          value: "Workers' Comp Comparison",
          background: "#fff",
          colSpan: 2,
          color: "#000",
          rowSpan: 1
        }]
      },
      {
        cells: [{
          value: "Selected Years : " + this.yearArray,
          background: "#fff",
          colSpan: 2,
          color: "#000",
          rowSpan: 1
        }]
      })

      for (var ri = 0; ri < rows.length; ri++) {
        var row = rows[ri];
        if ( row.type == "footer") {
          for (var ci = 0; ci < row.cells.length; ci++) {
            var cell = row.cells[ci];
            if(Number(cell.value) >= 0) {
              cell.value = Number(cell.value);
            }
          }
        }
      }
  }

  public columnCustomization() {
    var monthDetailsContainer = [];
    for (var i in this.records) {
      for (var j in this.columns) {
        for (var k in this.records[i].months) {
          var actualMonth = moment(this.records[i].months[k].startOfMonth).format('MMM') + moment(this.records[i].months[k].startOfMonth).format('YY');
            if (actualMonth == this.columns[j].name) {
              monthDetailsContainer.push({ count: this.records[i].months[k].count, startOfMonth: actualMonth });
              break;
            } else {
              if (Number(k) == (this.records[i].months.length - 1)) {
                monthDetailsContainer.push({ count: 0, startOfMonth: this.columns[j].name });
              }
            }
        }
      }
      this.records[i].months = monthDetailsContainer;
      monthDetailsContainer = [];
    }

    var result = {};
    this.gridDataContainer = [];
    for (let k in this.records) {
      result['organization'] = this.records[k].organization.name;
      result['total'] = this.records[k].total;
        for (let i in this.records[k].months) {
          result[this.records[k].months[i].startOfMonth] = this.records[k].months[i].count;
        }
        this.gridDataContainer.push(result);
        result = {};
    }

    return this.gridDataContainer;
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    
    this.aggregateFunc(this.columns);

    this.gridDataContainer = this.columnCustomization();

    this.gridState.view = process(this.gridDataContainer, this.gridState.state);

    this.totals = aggregateBy(this.gridDataContainer, this.aggregates);
  }
}

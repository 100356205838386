import { Injectable } from '@angular/core';

import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { UserApplication } from '../../../../state-model/models/index';
import { Assert } from '../../../../framework/index';
import { EmployeeListMapService } from './employee-list-map.service';
import { EmployeeListItem, Employee, EmployeeActionDefinition, IEmployeeActionDefinitions, EmployeeActionDefinitions } from '../../models';
import { HttpRequest, HttpParams } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { ResponseBody } from '../../../../core/models/index';
import { PaginationMeta } from '../../../../core/models/api/pagination-meta';
import { Meta } from '../../../../core/models/api/meta';
import { organizationConfig } from '../../../../organization/organization.config';
import { employeeListConfig } from '../../employee-list.config';

import * as _ from 'lodash';

@Injectable()
export class EmployeeListService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private employeeListMapService: EmployeeListMapService) {
  }

  public getEmployeeListItems(application: UserApplication, orgLevelId: number): Promise<EmployeeListItem[]> {
    Assert.isNotNull(application);
    Assert.isNotNull(orgLevelId);

    const url: string = `${this.getApiRoot()}/${organizationConfig.apiroot}/${application.id}/${employeeListConfig.api.employeeLists}`;
    let promise: Promise<EmployeeListItem[]> = this.apiUtilService.request<EmployeeListItem[], Meta>(this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId }))
      .then((response: ResponseBody<EmployeeListItem[], Meta>) => _.map(response.data, (item: any) => this.employeeListMapService.mapToEmplyeeListItem(item)));
    return promise;
  }

  public getEmployees(employeeListItemId: number, orgLevelId: number, activeOnly: boolean, itemDef: EmployeeListItem, startDate: Date, endDate: Date): Promise<Employee[]> {
    Assert.isNotNull(employeeListItemId);
    Assert.isNotNull(orgLevelId);

    const url: string = `${this.getApiRoot()}/${employeeListConfig.api.employees.root}/${employeeListConfig.api.employees.list}/${employeeListItemId}`;

    let promise: Promise<Employee[]> = this.apiUtilService.request<Employee[], PaginationMeta>(this.urlParamsService.createGetRequest(url,
      {
        orgLevelId: orgLevelId, activeOnly: activeOnly, startDate: startDate, endDate: endDate
      }))
      .then((response: ResponseBody<Employee[], PaginationMeta>) => _.map(response.data, (data: any) => this.employeeListMapService.mapToEmployee(data, itemDef, employeeListItemId)));
    return promise;
  }

  public getEmployeeActionDefinitions(applicationId: number, orgLevelId: number): Promise<EmployeeActionDefinitions> {
    Assert.isNotNull(applicationId);
    Assert.isNotNull(orgLevelId);

    const url: string = `${this.getApiRoot()}/${organizationConfig.apiroot}/${applicationId}/employeeActionDefinitions`;

    let promise: Promise<EmployeeActionDefinitions> = this.apiUtilService.request<IEmployeeActionDefinitions, PaginationMeta>(
      this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId }))
      .then((response: ResponseBody<IEmployeeActionDefinitions, PaginationMeta>) => this.employeeListMapService.mapToEmployeeActionDefinitions(response.data));
    return promise;
  }

  public terminateAgencyEmployees(employees: Employee[]) {
    Assert.isNotNull(employees);

    const url: string = `${this.getApiRoot()}/${employeeListConfig.api.employees.root}/${employeeListConfig.api.employees.actions.massterminate}`;
    const request = this.urlParamsService.createPostRequest(url, _.map(employees, (emp: Employee) => +emp['EmpId']));
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
    return promise;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}

import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
import { WfmSynDetails, statusCheck_EmployeeSync } from '../../../../configuration/models/wfm-sync';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export/excel-export-data';
import { process } from '@progress/kendo-data-query';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import * as _ from 'lodash';


@Component({
  selector: 'slx-wfm-sync-response',
  templateUrl: './wfm-sync-response.component.html',
  styleUrls: ['./wfm-sync-response.component.scss']
})
export class WfmSyncResponseComponent implements OnInit {
  public viewCol: any[] = [{ field: "StartDate", title: 'Start Date' },
  { field: "endDate", title: 'End Date' }, { field: "syncType", title: 'Sync Type' },
  { field: "errorMessage", title: "Error Message" },{ field: "errorCode", title: "Error Code" },{ field: "employeeName", title: "Employee Name" }];
  public dataView: WfmSynDetails[] = [];
  public syncId: string;
  public isActiveOnly: boolean = false;
  public actualData: WfmSynDetails[] = [];
  public filterData: WfmSynDetails[] = [];
  public appConfig: IApplicationConfig;
  constructor(
    private wfmsyncService: WfmSyncService,
    private route: ActivatedRoute
  )
  { 
    this.allData = this.allData.bind(this); 
  }

  ngOnInit() {
    this.appConfig = appConfig;
    this.syncId = this.route.snapshot.url[0].path;
    this.res();
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataView, {
        sort: [{ field: 'syncId', dir: 'asc' }],
      }).data
    };
    return result;
  }
  public res() {
    this.wfmsyncService.employeeSyncDetails(this.syncId).then((value2: any) => {
      this.dataView = value2.data;
      this.actualData = statusCheck_EmployeeSync(value2.data);
      this.setDefaultData(this.isActiveOnly);
    });
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
  onDisplaySettingsChanged(e) {
    this.setDefaultData(e);
  }
  setDefaultData(isActiveOnly) {
    let filterData = ["FAILED", "QUEUED", "", "NULL","RESUBMITTED","PENDING","EVENT PAUSED","EVENT STOPPED","UN KNOWN", "ACCEPTED"];
    this.isActiveOnly = isActiveOnly;
    let onlyInsert = _.filter(this.actualData, (item) => filterData.includes(item.status));
    this.dataView = this.isActiveOnly ? this.actualData : onlyInsert;
   
  }
  setValue(data: any) {
    // if(this.dataView.length>0){
    //   _.map(this.dataView, (item) => {

    //   });
    // }
    return data;
  }
}


import {combineLatest} from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { IControlState, ControlStateKey } from '../../../../core/models/settings/component-states';
import { StateResetTypes } from '../../../../core/models/settings/reset-types';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { GenericListManagementService } from '../../../../organization/services/index';
import { GenericListDefinition, GenericList, GenericRow, GenericListRequest } from '../../../../organization/models/generic-list/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { genericListConfig } from '../../../../organization/generic-list.config';

import { Observable ,  Subscription } from 'rxjs';


import { UserApplication } from '../../../../state-model/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { StateManagementService, ComponentStateStorageService, ColumnManagementService } from '../../../../common/services/index';
import { RangeDates, IRangeDates } from '../../../../common/models/range-dates';

@Component({
  moduleId: module.id,
  selector: 'slx-latenesses',
  templateUrl: 'latenesses.component.html',
  styleUrls: ['latenesses.component.scss'],
  providers: [GenericListManagementService, StateManagementService, ColumnManagementService]
})
export class LatenessesComponent implements OnInit, OnDestroy {

  @mutableSelect('application')
  public application: Observable<UserApplication>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public appConfig: IApplicationConfig;

  public state: {
    isLoading: boolean;
    startDate: Date;
    endDate: Date;
  };

  @unsubscribe()
  private definitionsLoadedSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private valuesLoadedSubscription: Subscription;
  @unsubscribe()
  private loadSubscription: Subscription;

  private currentOrgLevel: OrgLevel;
  private currentApplication: UserApplication;
  private listDefinition: GenericListDefinition;
  private listValues: GenericList;

  private stateKey: ControlStateKey;
  private m_dateFiltersControlId: string = 'DateFiltersNgx';
  private m_dateFiltersResetType: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;

  constructor(private genericListManagementService: GenericListManagementService, private stateManagement: StateManagementService, private storageService: ComponentStateStorageService) {
    this.state = {
      isLoading: false,
      startDate: moment().startOf('year').toDate(),
      endDate: moment().endOf('year').toDate()
    };
  }

  public ngOnInit(): void {

    this.appConfig = appConfig;
    this.stateManagement.init('LatenessesComponent');

    this.loadSubscription = this.stateManagement.loadData$.subscribe(() => {
      this.loadValues();
    });

    this.orgLevelSubscription = this.orgLevel$.pipe(
      combineLatest(this.application))
      .subscribe((value: [OrgLevel, UserApplication]) => {
        let [orgLevel, application]: [OrgLevel, UserApplication] = value;
        if (!orgLevel || !orgLevel.id || !application.id) {
          return;
        }
        this.currentOrgLevel = orgLevel;
        this.currentApplication = application;
        this.restoreFilters ();
        this.loadDefinitions();
      });

    this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe((defs: GenericListDefinition) => {
      this.stateManagement.onComponentActiveStateChanged({});
    });
    this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe((list: GenericList) => {
      this.state.isLoading = false;
      this.stateManagement.loadedData({});
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.state.startDate = startDate;
    this.state.endDate = endDate;
    this.saveFilters();
  }

  private loadDefinitions(): void {
    this.state.isLoading = true;
    this.genericListManagementService.loadListDefinition(this.currentApplication.id, this.currentOrgLevel.id, genericListConfig.latenesses, false);
  }

  private loadValues(): void {
    this.state.isLoading = true;
    let req: GenericListRequest = new GenericListRequest();
    req.startDate = this.state.startDate;
    req.endDate = this.state.endDate;
    this.genericListManagementService.loadListValues(this.currentOrgLevel.id, req);
  }

  private saveFilters(): void {
    this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId,
      {
        value: { startDate: this.state.startDate, endDate: this.state.endDate }
      },
      this.m_dateFiltersResetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
  }

  private restoreFilters(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
    if (state && state.value) {
      if (state.value.startDate) this.state.startDate = moment(state.value.startDate).toDate();
      if (state.value.endDate) this.state.endDate = moment(state.value.endDate).toDate();
    }
  }


}

export * from './staffing-predictor-map.service';
export * from './staffing-predictor-api.service';
export * from './staffing-predictor-management.service';

import { Provider } from '@angular/core';

import { StaffingPredictorMapService } from './staffing-predictor-map.service';
import { StaffingPredictorApiService } from './staffing-predictor-api.service';
import { StaffingPredictorManagementService } from './staffing-predictor-management.service';

export const services: Provider[] = [
    StaffingPredictorMapService,
    StaffingPredictorApiService,
    StaffingPredictorManagementService
];

import { Provider } from '@angular/core';

export * from './punch-profile-api.service';
export * from './punch-profile-management.service';

import { PunchProfileApiService } from './punch-profile-api.service';
import { PunchProfileManagementService } from './punch-profile-management.service';

export const services: Provider[] = [
  PunchProfileApiService,
  PunchProfileManagementService
];

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../../common/utils/index';
import { ScheduleDailyDetails, IScheduleDailyDetails, ScheduleDailyDifferences, IScheduleDailyDifferences } from '../../models/index';



@Injectable()
export class ScheduleDailyDetailsMapService {

  public mapToScheduleDailyDetails(dto: IScheduleDailyDetails): ScheduleDailyDetails {
    let data = new ScheduleDailyDetails();
    data.workDate = dateTimeUtils.convertFromDtoString(dto.workDate);
    data.orgLevelId = dto.orgLevelId;
    data.employeeId = dto.employeeId;
    data.employeeName = dto.employeeName;
    data.shiftGroupId = dto.shiftGroupId;
    data.shiftGroupName = dto.shiftGroupName;
    data.shiftId = dto.shiftId;
    data.shiftName = dto.shiftName;
    data.unitId = dto.unitId;
    data.unitName = dto.unitName;
    data.positionId = dto.positionId;
    data.positionName = dto.positionName;
    data.hours = dto.hours;
    return data;
  }

  public mapToScheduleDailyDetailsRecords(records: IScheduleDailyDetails[]): ScheduleDailyDetails[] {
    return _.map(records, (record: IScheduleDailyDetails) => {
      return this.mapToScheduleDailyDetails(record);
    });
  }

  public mapToScheduleDailyDifferences(dto: IScheduleDailyDifferences): ScheduleDailyDifferences {
    let data = new ScheduleDailyDifferences();
    data.workDate = dateTimeUtils.convertFromDtoString(dto.workDate);
    data.orgLevelId = dto.orgLevelId;
    data.positionId = dto.positionId;
    data.positionName = dto.positionName;
    data.shiftGroupId = dto.shiftGroupId;
    data.shiftGroupName = dto.shiftGroupName;
    data.shiftId = dto.shiftId;
    data.shiftName = dto.shiftName;
    data.unitId = dto.unitId;
    data.unitName = dto.unitName;
    data.scheduledHours = dto.scheduledHours;
    data.scheduledCount = dto.scheduledCount;
    data.requiredCount = dto.requiredCount;
    data.requiredHours = dto.requiredHours;
    data.differencesCount = dto.differencesCount;
    data.differencesHours = dto.differencesHours;
    data.isAnyPartialShiftIsAssigned = dto.isAnyPartialShiftIsAssigned;
    return data;
  }

  public mapToScheduleDailyDifferencesRecords(records: IScheduleDailyDifferences[]): ScheduleDailyDifferences[] {
    return _.map(records, (record: IScheduleDailyDifferences) => {
      return this.mapToScheduleDailyDifferences(record);
    });
  }
}

<kendo-grid #kendoGrid
class="slx-blue-grid slx-grid-slim-rows slx-full-height slx-no-header-right-padding"
[ngClass]="{'slx-change-sensitive-grid': afterRecalculatedMode}"
[rowClass]="rowCallback"
[filterable]="'menu'"
[filter]="gridState.state.filter"
[scrollable]="false"
[data]="gridState?.view">
  <kendo-grid-column media="(max-width: 1000px)">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="summary-par">
        <span class="summary-par-header">Code Description:</span>
        <span class="summary-par-value">{{ dataItem.name }}</span>
      </div>
      <div class="summary-par" *ngIf="shiftDiffBasedOnTime">
        <span class="summary-par-header">Shift Code:</span>
        <span class="summary-par-value">{{ dataItem.shiftCodeName }}</span>
      </div>
      <div class="summary-par">
        <span class="summary-par-header">Position:</span>
        <span class="summary-par-value">{{ dataItem.position }}</span>
      </div>
      <div class="summary-par">
        <span class="summary-par-header">Hours:</span>
        <span *ngIf="displayOptions==='number'" class="summary-par-value">{{ dataItem.interval | slxInterval }}</span>
        <span *ngIf="displayOptions==='hmstring'" class="summary-par-value">{{ dataItem.interval | slxDurationHM }}</span>
      </div>
      <div class="summary-par">
        <span class="summary-par-header">Amount:</span>
        <span class="summary-par-value">{{ dataItem.amount | slxMoney }}</span>
      </div>
      <div class="summary-par">
        <span class="summary-par-header">Export Code:</span>
        <span class="summary-par-value">{{ dataItem.exportCode }}</span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="name" width="100" media="(min-width: 1001px)">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Code Desc</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="shiftCodeName" width="100" media="(min-width: 1001px)" *ngIf="shiftDiffBasedOnTime">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Shift Code</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="position" width="150" media="(min-width: 1001px)">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="interval" width="60" media="(min-width: 1001px)">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Hours</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span *ngIf="displayOptions==='number'" class="right-align">{{ dataItem.interval | slxInterval }}</span>
      <span *ngIf="displayOptions==='hmstring'" class="right-align">{{ dataItem.interval | slxDurationHM }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="amount" width="60" media="(min-width: 1001px)">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Amount</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span class="right-align">{{dataItem.amount | slxMoney }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="exportCode" width="60" media="(min-width: 1001px)">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Export Code</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Assert, StringUtils } from '../../../framework/index';

import { VersionSubscribeService } from '../../../common/index';
import { AuthenticationService } from '../../services/index';
import { SessionActions } from '../../actions/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { IApplicationConfig, appConfig } from '../../../app.config';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { User, Session, AuthResponse, AuthStatusCode } from '../../../state-model/models/index';
import { ResponseBody, Meta, StatusCodes } from '../../../core/models/index';
import * as M from 'minimatch';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NotificationsApiService } from '../../../../app/portal/services/notifications/notifications-api.service';
import { UserMenuPinService } from '../../../core/services/user-menu-pin/user-menu-pin.service';
import { LocalStorageService } from '../../../core/services/local-storage/local-storage.service';

@Component({
    moduleId: module.id,
    selector: 'slx-auth-handler',
    templateUrl: 'auth-handler.component.html',
    styleUrls: ['auth-handler.component.scss']
  })
export class AuthHandler implements OnInit 
{
    @Output()
    public onAuthenticated: EventEmitter<User>;

    public appConfig: IApplicationConfig;
    public promoUrl: SafeResourceUrl;
    isIframe = false;
    loginDisplay = false;
    claim: any;
    private readonly _destroying$ = new Subject<void>();

    constructor
        (   private router: Router,
            private route: ActivatedRoute,
            private versionSubscriber: VersionSubscribeService,
            private authenticationService: AuthenticationService,
            private sessionActions: SessionActions,
            private userActivityService: UserActivityService,
            public sanitizer: DomSanitizer,
            private messageCenterNotification: NotificationsApiService,
            private userMenuPinService: UserMenuPinService,
            private localStorageService: LocalStorageService
        ) 
    {
        Assert.isNotNull(router, 'router');
        Assert.isNotNull(route, 'route');
        this.onAuthenticated = new EventEmitter<User>();
        this.appConfig = appConfig;
        this.promoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.appConfig.links.promoInfoLink);
    }
    
    ngOnInit() 
    {
        let state:string = "";
        this.route.queryParams.forEach(param => {
            state = param["state"];
        });

        if(state)
        {
          this.localStorageService.set(appConfig.b2sSSoLoginKey, true);
            this.authenticationService.validateAuthSession(state)
            .then((authResponse: AuthResponse) => {
            console.log(authResponse);
                this.onAuthenticationSucceeded(authResponse);
            })
            .catch((error: ResponseBody<any, Meta>) =>
            console.log(error) 
                //this.onAuthenticationFailed(error)
            );
        }
        else
        {
            //this.onAuthenticationFailed(error)
        }
    }

    private onAuthenticationSucceeded(authResponse: AuthResponse): void {
    
        Assert.isNotNull(authResponse, 'authResponse');
        this.messageCenterNotification.buildConnection();
        this.messageCenterNotification.startConnection();
        if (authResponse.statusCode === AuthStatusCode.valid) {
          Assert.isNotNull(authResponse.session, 'session');
          this.onAuthenticated.emit(authResponse.session.user);
          this.redirectLoggedInUser();
        }
        else if (authResponse.statusCode === AuthStatusCode.invalidUserOrPassword)
        {
          console.log('onAuthenticationSucceeded' + authResponse.statusCode);
        }
        else if(authResponse.statusCode === AuthStatusCode.invalidIPAddress)
        {
          this.redirectInavlidIPAddress();
        }
      }

      private redirectLoggedInUser(): void {
        this.userActivityService.start();
        this.userMenuPinService.getUserMenuPinStatus();
        this.router.navigate(['apps']);
      }

      public redirectInavlidIPAddress(): void {
          this.authenticationService.navigateToInvalidIPAddress();
      }

      private onAuthenticationFailed(error: ResponseBody<any, Meta>): void {
        Assert.isNotNull(error, 'error');
    
        
      }
}
import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ReportParameter } from '../../../models/index';
import { Observable ,  Subscription } from 'rxjs';
import { IApplicationConfig, appConfig } from '../../../../../app/app.config';
import { dateTimeUtils } from '../../../../common/utils/index';
import { ReportsApiService } from '../../../services/reports-api.service';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../app-settings/services';
@Component({
  moduleId: module.id,
  selector: 'slx-date-report-parameter',
  templateUrl: 'date-report-parameter.component.html',
  styleUrls: ['date-report-parameter.component.scss'],
  providers: [ReportsApiService]
})
export class ReportDateParameterComponent {
  public includeThousandsSeparator: boolean = false;
  public isRetroEnd: boolean = false;
  public isWageComp1End: boolean = false;
  public isAllMissingPunchesEnd: boolean = false;
  public allowSelectFutureDateFlag : boolean = false;
  @Input()
  public index: number;
  public daysRange: string;
  @Input()
  public parameters: ReportParameter[];
  isPBJRNHrs: boolean;
  @Input()
  public set parameter(value: ReportParameter) {
    this.processParameter(value);
    this.m_parameter = value;
    this.parameterBound = this.getParameterBound();
    if(this.parameters !=undefined)  this.isPBJRNHrs = this.parameters.find(x=> x.name =='Show_dates') ? true : false; 
  }

  public get componentName(): string {
    return `parameter_${this.index}`;
  }

  public appConfig: IApplicationConfig;

  public get parameter(): ReportParameter {
    return this.m_parameter;
  }
  public parameterBound: ReportParameter;


  private m_minValue: Date;

  private m_maxValue: Date;

  private m_parameter: ReportParameter;

  private readonly start_date: string = 'start_date';
  private readonly end_date: string = 'end_date';
  private readonly retro_pay: string = 'retro_pay';
  private readonly wage_comp1: string = 'EEO_Report_Wage_comp1';
  private readonly all_missing_punches: string = 'ta_all_historical_missing_punches';

  public get minValue(): Date {
     return this.parameter.name === this.end_date && this.parameterBound && this.parameterBound.value
     && dateTimeUtils.isInValidPeriod(this.parameterBound.value) ? this.parameterBound.value : this.m_minValue;   
  }

  public set minValue(value : Date) {
    this.m_minValue = value;
  }

  public set maxValue(value: Date) {
    this.m_maxValue = value;
  }

  public get maxValue(): Date {
    if(this.isPBJRNHrs){
      return  this.allowSelectFutureDateFlag ? moment(moment().startOf('day').toDate()).add(180, 'day').startOf('day').toDate() : (this.parameter.name === this.start_date || this.parameter.name === this.end_date)
      && dateTimeUtils.isInValidPeriod(this.parameterBound.value)
      ?  moment().subtract(1, 'day').startOf('day').toDate() : this.m_maxValue;
    }
    return  this.parameter.name === this.start_date
        && this.parameterBound && this.parameterBound.value
    && dateTimeUtils.isInValidPeriod(this.parameterBound.value)
    && this.parameterBound.value ? this.parameterBound.value : this.m_maxValue;
     }

  public get maxEndValue(){
    if(this.isPBJRNHrs && (this.parameter.name === this.start_date || this.parameter.name === this.end_date) ){
      return (this.parameter.name === this.start_date || this.parameter.name === this.end_date)
      && dateTimeUtils.isInValidPeriod(this.parameterBound.value)
      ?  moment().subtract(1, 'day').startOf('day').toDate() : this.m_maxValue;
    }
  }
 
  constructor(private reportAPIService :ReportsApiService, private appSettingManageService: AppSettingsManageService) {
    this.getSettings();    
    this.appConfig = appConfig;
    this.m_minValue = moment(appConfig.minCorrectDate).toDate();
    this.m_maxValue = moment(appConfig.maxCorrectDate).toDate();    
  }

  public async getSettings(): Promise<void> {
    let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
    this.allowSelectFutureDateFlag = appServerConfig.AllowSelectFutureDateRNMinHours;  
  }




  private processParameter(parameter: ReportParameter): void {
    let date: Date = undefined;
    if (parameter.defaultValue) {
      let defaultValue: string = parameter.defaultValue.toString().toLowerCase();
        date = this.getValueFromDefault(defaultValue);
        parameter.value = date;     
    }   
  }

  private getParameterBound(): ReportParameter {
    let bound: ReportParameter = undefined;
    if (this.parameter.name === this.start_date) {
      let p: ReportParameter = _.find(this.parameter.reportDefinition.parameters, (p: ReportParameter) => p.name === this.end_date);
      if (p)
        bound = p;
    } else if (this.parameter.name === this.end_date) {
      let p: ReportParameter = _.find(this.parameter.reportDefinition.parameters, (p: ReportParameter) => p.name === this.start_date);
      if (p) {
        bound = p;
      }
      this.isRetroEnd = _.some(this.parameter.reportDefinition.parameters, (p: ReportParameter) => p.name === this.retro_pay);
      this.isWageComp1End = this.parameter.reportDefinition && _.isEqual(this.parameter.reportDefinition.name, this.wage_comp1);
      this.isAllMissingPunchesEnd = this.parameter.reportDefinition && _.isEqual(this.parameter.reportDefinition.name, this.all_missing_punches);
    }
    return bound;
  }

  private getValueFromDefault(defaultValue: any): Date {
    let date: Date = undefined;
    if (defaultValue === 'yesterday') {
      date = moment().subtract(1, 'day').startOf('day').toDate();
    } else if (defaultValue === 'today') {
      date = moment().startOf('day').toDate();
    } else if (defaultValue === 'sevendaysprior') {
      date = moment().subtract(7, 'day').startOf('day').toDate();
    }
      else if (defaultValue === 'thirtydaysprior'){
        date = moment().subtract(31, 'day').startOf('day').toDate();
      }
    return date;
  }
  public get isMoreThanYear(): boolean {
    let retroPay: ReportParameter = _.find(this.parameter.reportDefinition.parameters, (p: ReportParameter) => p.name === this.retro_pay);
    let wageComp1: boolean = this.parameter.reportDefinition && _.isEqual(this.parameter.reportDefinition.name, this.wage_comp1);
    let allMissingPunches: boolean = this.parameter.reportDefinition && _.isEqual(this.parameter.reportDefinition.name, this.all_missing_punches);
    if (retroPay || wageComp1) {
      let end: ReportParameter = _.find(this.parameter.reportDefinition.parameters, (p: ReportParameter) => p.name === this.end_date);
      let start: ReportParameter = _.find(this.parameter.reportDefinition.parameters, (p: ReportParameter) => p.name === this.start_date);
      if (moment(end.value).diff(start.value, 'day') > 366) {
        this.daysRange = '1 year';
        return true;
      }
    }
    if (allMissingPunches) {
      let end: ReportParameter = _.find(this.parameter.reportDefinition.parameters, (p: ReportParameter) => p.name === this.end_date);
      let start: ReportParameter = _.find(this.parameter.reportDefinition.parameters, (p: ReportParameter) => p.name === this.start_date);
      if (moment(end.value).diff(start.value, 'day') > 89) {
        this.daysRange = '90 days';
        return true;
      }
    }
    return false;
  }
}

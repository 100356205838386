import { Provider } from '@angular/core';

export * from './collapsible-section.service';
export * from './messages.service';
export * from './attachments-management.service';
export * from './duration-input/duration-input.service';

import { CollapsibleSectionService } from './collapsible-section.service';
import { AttachmentsManagementService } from './attachments-management.service';
import { DurationInputService } from './duration-input/duration-input.service';
export const services: Provider[] = [
  CollapsibleSectionService
];

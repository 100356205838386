import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import {
  EmployeeListComponent
} from './components/index';

export const routes: Routes = [
  {
    path: 'employee_list',
    component: EmployeeListComponent,
  }
];
/* export const routesModule: ModuleWithProviders = RouterModule.forChild(routes); */

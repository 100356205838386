import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { process, State } from '@progress/kendo-data-query';
import { NgModel, NgForm, FormControl } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { KendoGridCustomSelectionHelper } from '../../../../common/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { RoleDefinition } from '../../../../organization/models/index';
import { RolesDefinitionManagementService } from '../../../services/index';

export type roleRecord = { role: RoleDefinition, isSelected: boolean };

@Component({
  moduleId: module.id,
  selector: 'slx-roles-definition-grid',
  templateUrl: 'roles-definition-grid.component.html',
  styleUrls: ['roles-definition-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesDefinitionGridComponent implements OnInit, OnDestroy {
  public appConfig: IApplicationConfig;
  public records: RoleDefinition[];
  public gridState: KendoGridStateHelper<RoleDefinition>;
  public roleToClone: RoleDefinition;
  public isNewMode: boolean;
  public editedRowIndex: number;
  public selectionHelper: KendoGridCustomSelectionHelper<RoleDefinition>;

  @ViewChild('roleForm', {static: true})
  public roleForm: NgForm;

  public editedRecord: RoleDefinition;

  private managementService: RolesDefinitionManagementService;
  private savedEditedRole: RoleDefinition;

  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private addCmdSubscription: Subscription;
  @unsubscribe()
  private cloneCmdSubscription: Subscription;
  @unsubscribe()
  private roleSavedSubscription: Subscription;
  @unsubscribe()
  private selectionSubscription: Subscription;

  @ViewChild('grid', {static: true})
  private grid: GridComponent;

  private changeDetector: ChangeDetectorRef;
  private firstTimeAfterAddMode: boolean;

  constructor(managementService: RolesDefinitionManagementService, changeDetector: ChangeDetectorRef) {
    this.managementService = managementService;
    this.changeDetector = changeDetector;
    this.gridState = new KendoGridStateHelper<RoleDefinition>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(
      (records: RoleDefinition[]) => {
        this.records = records;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.addCmdSubscription = this.managementService.addCmd$.subscribe((value: any) => {
      this.addHandler();
    });
    this.cloneCmdSubscription = this.managementService.cloneCmd$.subscribe((value: RoleDefinition) => {
      this.cloneHandler(value);
    });

    this.roleSavedSubscription = this.managementService.onRoleSaved$.subscribe((result: { savedRole: RoleDefinition, records: RoleDefinition[], index: number }) => {
      if (result.index !== -1 && result.savedRole) {
        this.records[result.index] = result.savedRole;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }
    });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.selectionSubscription = this.gridState.onSelectionChanged.subscribe((roles: RoleDefinition[]) => {
      if (roles.length > 0) {
        this.managementService.onSelectRecord(roles[0]);
      } else {
        this.managementService.onSelectRecord(null);
      }
    });

    this.selectionHelper.clearSelection();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.selectionHelper.clearSelection();
    this.managementService.onSelectRecord(null);
  }

  public addHandler(): void {
    this.closeEditor();
    this.refreshGrid();
    this.isNewMode = true;
    let newRole: RoleDefinition = new RoleDefinition();
    newRole.name = 'New Role';
    this.grid.addRow(newRole);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public cloneHandler(value: RoleDefinition): void {
    this.closeEditor();
    this.refreshGrid();
    this.roleToClone = value;
    let newRole: RoleDefinition = new RoleDefinition();
    newRole.name = 'New Role';
    this.grid.addRow(newRole);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public editHandler(event: { rowIndex: number, dataItem: RoleDefinition }): void {
    this.closeEditor();
    this.editedRowIndex = event.rowIndex;
    this.editedRecord = event.dataItem;
    this.savedEditedRole = Object.assign({}, event.dataItem);
    this.grid.editRow(this.editedRowIndex);
    this.managementService.onEditItem(this.editedRecord);
  }

  public cancelHandler(): void {
    if (this.editedRecord) {
      _.merge(this.editedRecord, this.savedEditedRole);
    }
    this.closeEditor();
    this.managementService.onCancelEdit(this.editedRecord);
  }

  public removeHandler(event: { sender: any, rowIndex: number, dataItem: RoleDefinition, isNew: boolean }): void {
    if (!event.dataItem) {
      return;
    }
    this.managementService.onRemoveRecord(event.dataItem);
    this.refreshGrid();
  }

  public closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);
    this.isNewMode = false;
    this.roleToClone = undefined;
    this.editedRowIndex = undefined;
    this.editedRecord = undefined;
    this.savedEditedRole = undefined;
  }

  public saveHandler(event: { sender: any, rowIndex: number, dataItem: RoleDefinition, isNew: boolean }): void {
    if (this.roleToClone) {
      this.managementService.onCloneRecord(this.roleToClone, event.dataItem);
    } else if (this.isNewMode) {
      this.managementService.onAddRecord(event.dataItem);
    } else {
      this.managementService.onSaveRecord(event.dataItem);
    }
    this.closeEditor();
    this.refreshGrid();
  }

  public onKeyName(event: KeyboardEvent, dataItem: RoleDefinition, nameField: NgModel): void {
    if (!nameField) {
      return;
    }
    const name: string = nameField.value;
    const existRole: RoleDefinition = _.find(this.records, (record: RoleDefinition) => record.name === name && record.id !== dataItem.id);
    if (existRole) {
      nameField.control.setErrors({ unique: true });
    }
  }
  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    let filtered = _.filter(this.records, x => x.name != "System Administrator");
    this.gridState.view = process(filtered, this.gridState.state);
    this.selectionHelper.view = this.gridState.view;
  }
}


import * as _ from 'lodash';

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';

import { environment as envConfig } from '../../../../environments/environment';
import { ILoginEvent } from '../../../authentication/models/index';
import { AuthenticationService } from '../../../authentication/services/index';
import { IUser } from '../../../authentication/store/index';
import { DeviceDetectorService } from '../../services/index';
import { UserExtendedSettingsManagementService } from '../../../authentication/services/user-extended-settings/user-extended-settings-management.service';
import { Observable ,Subscription} from 'rxjs';
import { OrgLevel } from '../../../state-model/models';

@Component({
  moduleId: module.id,
  selector: 'slx-init-pendo',
  templateUrl: 'init-pendo.component.html',
})

export class InitPendoComponent implements OnInit, AfterViewInit {
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  
  @unsubscribe()
  private authSubscription: Subscription;
  private orgLevelSubscription: Subscription;

  private pendoInit: boolean = false;
  private zendeskInitialized: boolean = false;
  public orgLevel: OrgLevel;
 private isLiveChatenabled : boolean = false;
  constructor(private authenticationService: AuthenticationService, private deviceDetectorService: DeviceDetectorService
    ,  private _userSettingsManagementService: UserExtendedSettingsManagementService) {
  }

  public ngOnInit(): void {
    this.authSubscription = this.authenticationService.login$.subscribe((event: ILoginEvent) => {
      if (!event.isRenewAuthentication) {
        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
          this.orgLevel = orgLevel;
       if(this.orgLevel && this.v6Details) this.initPendo(event.alias, event.username, event.email, event.roles, true);
        })
      }
    });
  }

  public ngAfterViewInit(): void {
    if (!this.pendoInit) {
      const alias: string = this.authenticationService.getAlias();
      const user: IUser = this.authenticationService.getUser();
      if (this.authenticationService.isAuthenticated()) {
        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
          this.orgLevel = orgLevel;
          if(this.orgLevel && this.v6Details) this.initPendo(alias, user.username, user.email, user.roles, false);
        });
      }
    }
  }

  private async initPendo(alias: string, username: string, email: string, roles: string[], onLogin: boolean) {
    const pendo: any = _.get(window, 'pendo');
    const correctAlias: string = _.toUpper(_.size(alias) > 0 ? alias : 'default');
    const correctEmail: string = _.size(email) > 0 ? email : '';
    const correctRole: string = _.size(roles) > 0 ? roles.join(',') : '';
    const accountId = _.toUpper(`${username}@${correctAlias}`);
    const device = this.deviceDetectorService.isDesktop ? 'desktop' : 'mobile';
    this.pendoInit = true;
    const initData = {
      visitor: {
        id: accountId,
        email: correctEmail,
        roles: correctRole,
        device: device,
        org_name : this.orgLevelDetails(this.orgLevel), // Selected Org name  
      },
      account: {
        id: correctAlias,                              // alias
        customer_name : this.topOrgName, // Top level name
        org_id: this.orgLevelId(this.orgLevel),        // OrgLevelId for the selected organisation 
        org_type : this.orgLevelType(this.orgLevel),    // Type of organisation
        env: envConfig.ENV,
        application: 'V6'
      }
    };
    if (_.isObjectLike(pendo) && _.size(pendo) > 0) {
      if (_.isFunction(pendo.isReady) && pendo.isReady() && onLogin) {
        console.log('Exec pendo.updateOptions(), after user logged in', initData);
        pendo.updateOptions(initData);
        return;
      }
      console.log(`Exec pendo.initialize(), ${onLogin ? 'after user logged in' : 'after user opened app (without logged in)'}`, initData);
      pendo.initialize(initData);
    await this._userSettingsManagementService.getLiveChatMenu(); 
    if(this._userSettingsManagementService.isLiveChatMenuenabled){
      if (envConfig.ZENDESK_APIKEY && !this.zendeskInitialized) {
        setTimeout(() => {
          this.zendeskInitialized = true;
            var y: HTMLScriptElement = document.createElement('script');
            y.id = 'ze-snippet';
            y.src = `https://static.zdassets.com/ekr/snippet.js?key=${envConfig.ZENDESK_APIKEY}`;
            var z = document.getElementsByTagName('script')[0];
            z.parentNode.appendChild(y);
        }, 100);
      }
    }
      return;
    }

    console.error(`Pendo didn\'t load correctly. Pendo: `, pendo);
  }

  public orgLevelId(orgLevel){
    if(orgLevel)
    return orgLevel?.type?.toLowerCase() === 'department' ? orgLevel.parentId : orgLevel.id
  }

  public orgLevelDetails(orgLevel: OrgLevel){
    if(orgLevel?.type?.toLowerCase() === 'department') {
      return this.v6Details.orgLevel.treeViewNamePath[this.v6Details.orgLevel.treeViewNamePath.length - 1];
    }
    else return orgLevel.name;
  }

  public orgLevelType(orgLevel: OrgLevel){
    if(orgLevel?.type?.toLowerCase() === 'department') return 'Organization'
    else return orgLevel.type;
 }

 public get v6Details(){
 return  JSON.parse(localStorage.getItem('slx-worklinx-v6-app'));
 }

 public get topOrgName(){
  return localStorage.getItem('topOrgName')
 }

}

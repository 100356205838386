<kendo-grid #kendoGrid class="slx-full-height" [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [groupable]="{ showFooter: true }"
  [group]="gridState.state.group"
  [filterable]="true"
  [filter]="gridState.state.filter"
  (dataStateChange)="gridState.dataStateChange($event)"
  selectable="false"
  [rowHeight]="48"
>

  <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" field="isSelected" width="40" [style]="{ 'border-left-width': '0' }">
    <ng-template kendoGridHeaderTemplate>
      <input type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onToggleAllSelected()">
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange()">
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Name" field="employee.name">
    <ng-template kendoGridHeaderTemplate>
      Name
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [employeeSectionLink]="dataItem?.employee.id">
        {{dataItem?.employee.name}}
        </a>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Department" field="department.name">
    <ng-template kendoGridHeaderTemplate>
      Department
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.department.name}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Position" field="position.name">
    <ng-template kendoGridHeaderTemplate>
      Position
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.position.name}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Badge #" field="badge" width="160">
    <ng-template kendoGridHeaderTemplate>
      Badge #
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.badge}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Hired" field="employee.dateHired" width="200">
    <ng-template kendoGridHeaderTemplate>
      Hired
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.employee.dateHired | amDateFormat: appConfig.dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="showAssigned" title="Assigned Timeclocks" width="100">
    <ng-template kendoGridHeaderTemplate>
      <span class="ellipsisClass">Assigned Timeclocks</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.assignments.length}}/{{totalTimeclocks}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <div *kendoGridDetailTemplate="let dataItem; showIf:showDetailsGrid">
    <slx-timeclock-assignment-timeclocks [employee]="dataItem" [records]="dataItem.assignments"></slx-timeclock-assignment-timeclocks>
  </div>
</kendo-grid>

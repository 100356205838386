import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { WcRosterToolbarService, WcCommonManagementService, WcComparisonsManagementService } from '../../../services/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';

import { ColumnSettingsStorageService, ColumnManagementService, StateManagementService, ComponentStateStorageService } from '../../../../../common/services/index';

@Component({
  selector: 'slx-wc-comparisons',
  templateUrl: './wc-comparisons.component.html',
  styleUrls: ['./wc-comparisons.component.scss'],
  providers: [
    WcComparisonsManagementService,
    WcCommonManagementService,
    WcRosterToolbarService,
    ColumnManagementService,
    ColumnSettingsStorageService,
    StateManagementService,
    ComponentStateStorageService,

  ]
})
export class WcComparisonsComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: WcComparisonsManagementService) {}

  public ngOnInit() {
    this.manService.init('WcComparisons');
    this.subscriptions.onLoad = this.manService
      .subscribeToLoading((value: boolean) => (this.isLoading = value));
  }

  public ngOnDestroy(): void {
    this.manService.destroy();
  }
}

/*****
    At this momment this component doesn't used at all
 *****/
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { process, State, aggregateBy } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';
import { IColumnSettings } from '../../../../core/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';

import { TimecardsDisplayManagementService } from '../../../services/index';
import {
  TimecardsSummary,
  TimecardsEmployee,
  TimecardsAction,
  TimecardsActionCmd,
  TimecardsState,
  TimecardsColumnState,
  ITimecardsLastSelectionState
} from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-timecards-display-grid',
  templateUrl: 'timecards-display-grid.component.html',
  styleUrls: ['timecards-display-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimecardsDisplayGridComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public records: TimecardsEmployee[];
  public state: TimecardsState;
  public isAllSelected: boolean;
  public container: TimecardsSummary;
  public total: any = {};
  public gridState: KendoGridStateHelper<TimecardsEmployee>;

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;
  @ViewChild('form', {static: true})
  private form: NgForm;
  private managementService: TimecardsDisplayManagementService;
  private highlightedRows: number[];
  private selectedRows: number[];
  private firstInit: boolean;

  private aggregates: any = [
    { field: 'totalHours', aggregate: 'sum' },
    { field: 'regularPay', aggregate: 'sum' },
    { field: 'overtimeAndExtra', aggregate: 'sum' },
    { field: 'totalPay', aggregate: 'sum' },
    { field: 'workedHours', aggregate: 'sum' }
  ];

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private stateChangedSubscription: Subscription;
  @unsubscribe()
  private actionSubscription: Subscription;
  @unsubscribe()
  private stateSubscription: Subscription;

  private changeDetector: ChangeDetectorRef;
  private elementRef: ElementRef;


  constructor(managementService: TimecardsDisplayManagementService, changeDetector: ChangeDetectorRef, elementRef: ElementRef) {
    this.managementService = managementService;
    this.changeDetector = changeDetector;
    this.gridState = new KendoGridStateHelper<TimecardsEmployee>();
    this.elementRef = elementRef;
    this.highlightedRows = [];
    this.selectedRows = [];
    this.firstInit = true;
  }

  public ngOnInit(): void {
    this.managementService.init();
    this.appConfig = appConfig;
    this.stateChangedSubscription = this.managementService.onLoaded$.subscribe(
      (container: TimecardsSummary) => {
        this.container = container;
        this.records = container.records;
        if (this.selectedRows.length > 0) {
          this.updateRecords();
        }
        this.refreshGrid();

        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.actionSubscription = this.managementService.onActionCmd$.subscribe((v: TimecardsAction): void => {
      if (v.cmd === TimecardsActionCmd.excelExport) {
        this.grid.saveAsExcel();
      }
      if (v.cmd === TimecardsActionCmd.pdfExport) {
        this.grid.saveAsPDF();
      }
    });

    this.stateSubscription = this.managementService.onStateChanged$
      .subscribe((state: TimecardsState) => {
        this.state = state;
        this.highlightedRows.length = 0;
        this.selectedRows.length = 0;

        if (this.state.lastViewedEmployee > 0) {
          this.highlightedRows.push(this.state.lastViewedEmployee);
        }
        if (this.state.lastViewedPage > 0) {
          this.gridState.state.skip = this.state.lastViewedPage;
          this.refreshGrid();
        }
        if (this.state.lastSelectedEntries && this.state.lastSelectedEntries.length > 0) {
          this.selectedRows.push(...state.lastSelectedEntries);
        }
        this.firstInit = false;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public isVisible(field: string, payCode?: boolean): boolean {
    if (!this.state) {
      return false;
    }
    let columnState: TimecardsColumnState = payCode? this.state.payCodeColumns: this.state.empColumns;
    if (!columnState || !columnState.columnsMap[field]) {
      return false;
    }
    let column: IColumnSettings = columnState.columnsMap[field];
    if (!this.state.isShowPayRates && column.payload && column.payload.payRateRelated) {
      return false;
    }
    return column.displayed;
  }

  public onCellClick(cell: CellClickEvent): void {
    const record: TimecardsEmployee = _.get(cell, 'dataItem', null);
    if (_.isObject(record)) {
      const currentId: number = record.employeePosition.employee.id;
      const previousId: number = _.head(this.highlightedRows);
      this.highlightedRows.length = 0;
      if (currentId !== previousId) {
        this.highlightedRows.push(currentId);
      }
    }
  }

  public isRowSelected(): Function {
    return (event: { dataItem: TimecardsEmployee, index: number }): boolean => {
      const employeeId = event.dataItem.employeePosition.employee.id;
      return _.indexOf(this.highlightedRows, employeeId) !== -1;
    };
  }

  public onHighlightEmployee(employeeId: number): void {
    this.managementService.onSaveLastSelectionState(
      { selectedEmployee: employeeId, skipRecords: this.gridState.state.skip } as ITimecardsLastSelectionState
    );
  }

  public getWidth(field: string): number {
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].width : 50;
  }

  public getFilter(field: string): string {
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].filter : null;
  }

  public onToggleAllSelected(): void {
    _.forEach(this.records, (record: TimecardsEmployee) => {
      record.isSelected = this.isAllSelected;
    });
    this.selectionChange();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public selectionChange(): void {
    let selectedRecords: TimecardsEmployee[] = [];
    selectedRecords = _.filter(this.records, (record: TimecardsEmployee) => {
      return record.isSelected === true;
    });
    this.isAllSelected = this.records && selectedRecords ? this.records.length === selectedRecords.length : false;
    this.managementService.onRecordsSelected(selectedRecords);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private updateRecords(): void {
    _.forEach(this.records, (record: TimecardsEmployee) => {
      const index: number = _.indexOf(this.selectedRows, record.employeePosition.employee.id);
      if (index !== -1) {
        record.isSelected = true;
      }
    });
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
    this.total = aggregateBy(this.records, this.aggregates);
  }
}


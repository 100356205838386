import { NgForm } from '@angular/forms';
import { Component, Input, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AddEmployeeWizardActions } from '../../../../store/index';
import { AddEmployeeModel, AddEmployeeRestrictions } from '../../../../models/index';
import { AddEmployeeBasicComponent } from '../add-employee/add-employee-basic.component';
import { mutableSelect } from '../../../../../../core/decorators/index';
import { EmployeeValidatorAdapter, EmployeeDefinitionsApiService } from '../../../../../../organization/services/index';
import * as _ from 'lodash';
import { IconAccess } from '../../../../../../portal/models/messages/icon-access.model';
import { NotificationsApiService } from '../../../../../../portal/services';

@Component({
  moduleId: module.id,
  selector: 'slx-add-employee-personal',
  templateUrl: 'add-employee-personal.component.html',
  styleUrls: ['add-employee-personal.component.scss']
})
export class AddEmployeePersonalComponent extends AddEmployeeBasicComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() public addEmployeeRestrictions: AddEmployeeRestrictions;
  @ViewChild('personalForm', { static: true }) mainForm: NgForm;

  @mutableSelect(['addEmployeeWizard', 'model'])
  public addEmployeeWizard$: Observable<AddEmployeeModel>;
  public optInTemplateText: string = '';
  public isOptInCheckboxEnabled: boolean = false;
  public isMessageCenterEnabled: boolean = false;

  private employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;

  constructor(addEmployeeWizardActions: AddEmployeeWizardActions, employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter, employeeDefinitionsApiService: EmployeeDefinitionsApiService, private notificationApiService: NotificationsApiService) {
    super(addEmployeeWizardActions);
    this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    
  }

  public ngOnInit(): void {
    this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe((model: AddEmployeeModel) => {
      this.onWizard(model);
      this.checkMessageCenterAccess();
      this.getSMSTemplate();
    });
  }

  public ngAfterViewInit(): void {
    this.addChangesSubscriptionToForm(this.mainForm);
  }

  public get emailValidation(): string {
    return EmployeeValidatorAdapter.emailValidation;
  }
  
  public async checkMessageCenterAccess(): Promise<void> {
    const orgLevelId: number = _.get(this.addEmployeeModel, 'organization.orgLevelId');
    const access: IconAccess = await this.notificationApiService.getIconAccessCheck(orgLevelId);
    this.isMessageCenterEnabled = access.isMessageCenterEnabled;
  }

  public getSMSTemplate(): void {
    const orgLevelId: number = _.get(this.addEmployeeModel, 'organization.orgLevelId');
    this.employeeDefinitionsApiService.getSmsTemplate(orgLevelId)
      .then(template => {
        this.optInTemplateText = template;
      }).catch((reason: any) => {
    });
  }

  public onMobileNoChange(): void {
    if (this.addEmployeeModel && (!_.isNull(this.addEmployeeModel.mobilePhone) && this.addEmployeeModel.mobilePhone != '')) {
      this.isOptInCheckboxEnabled = true;
      this.addEmployeeModel.optInRequest = true;
    }
    else {
      this.isOptInCheckboxEnabled = false;
      this.addEmployeeModel.optInRequest = false;
    }
  }

}

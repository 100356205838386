import * as _ from 'lodash';
import { Component, OnDestroy, OnInit, Provider } from '@angular/core';
import { IDialog, DialogOptions, DialogOptions2, DialogModeSize } from '../../../../../../common/models';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
import { NewEnrollmentModel, BenefitsEmpEnrollmentFlat } from '../../../../models';
import { EmployeeSectionsBenefitsManagementApiService, BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { ModalService } from '../../../../../../common';
import { LookupService } from '../../../../../../organization';
import { BenefitTierDefinition } from '../../../../models/employee-sections-benefits-management/benefit-tier-definition';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';
import { BenefitEmployeeManagementService } from '../../../../../../app-modules/benefits/services/benefit-employees/index'
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { Subscription } from 'rxjs';
@Component({
  selector: 'slx-enroll-employee-flat',
  templateUrl: './enroll-employee-flat.component.html',
  styleUrls: ['./enroll-employee-flat.component.scss']
})
export class EnrollEmployeeFlatComponent implements OnInit, OnDestroy, IDialog {

  public static openDialog(
    model: NewEnrollmentModel,
    apiService: EmployeeSectionsBenefitsManagementApiService,
    modalService: ModalService,
    lookupService: LookupService,
    callback?: (result: boolean) => void
  ): EnrollEmployeeFlatComponent {


    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.width = 1050;
    dialogOptions.height = 400;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: LookupService,
        useValue: lookupService
      },
      {
        provide: ModalService,
        useValue: modalService
      },
      {
        provide: NewEnrollmentModel,
        useValue: model
      },
      {
        provide: EmployeeSectionsBenefitsManagementApiService,
        useValue: apiService
      }
    ];

    const title = `Enroll Employee in Benefits`;
    let component = modalService.globalAnchor.openDialog2(
      EnrollEmployeeFlatComponent,
      title,
      dialogOptions,
      resolvedProviders,
      callback
    );

    return component;

  }

  public dialogResult: boolean;

  public appConfig: IApplicationConfig;

  public isLoading: boolean;

  public groupName: string;
  public effectiveDate: Date;

  public tiers: BenefitTierDefinition[];
  public selectedTier: BenefitTierDefinition;

  public minEmpCont: number = 0;
  public maxEmpCont: number = 99999999.99;
  public stepEmpCont: number = 0.01;
  public empCont: number;

  public minEECont: number = 0;
  public maxEECont: number = 99999999.99;
  public stepEECont: number = 0.01;
  public eeCont: number;

  public currencyFormat = 'c2';

  public flatSetttings: BenefitsEmpEnrollmentFlat;

  public payrollDedStartDate: Date;
  public payrollDedEndDate: Date;
  public dedStartDate: Date;
  public dedEndDate: Date;
  public endDate: Date;
  public startDate: Date;
  public canMapPayroll: boolean;
  public hasDedError: boolean = false;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private model: NewEnrollmentModel,
    private apiService: EmployeeSectionsBenefitsManagementApiService,
    private options: DialogOptions,
    private modalService: ModalService,
    private lookupService: LookupService,
    private manService: BenefitEmployeeManagementService,
    private man: BenefitsEnrollmentSectionManagementService
  ) {
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.isLoading = true;
    this.groupName = this.model.plan.name;
    this.effectiveDate = this.model.effectiveDate;
    this.payrollDedStartDate = this.model.effectiveDate;
    this.payrollDedEndDate = _.isNull(this.model.plan.payrollDeductionEndDate) ? this.model.plan.endDate : this.model.plan.payrollDeductionEndDate;
    this.model.plan.payrollDeductionStartDate = this.payrollDedStartDate;
    this.model.plan.payrollDeductionEndDate = this.payrollDedEndDate;
    this.dedStartDate = _.isNull(this.model.plan.dedStartDate) ? this.model.plan.startDate : this.model.plan.dedStartDate;
    this.dedEndDate = _.isNull(this.model.plan.dedEndDate) ? this.model.plan.endDate: this.model.plan.dedEndDate;
    this.endDate = this.model.plan.endDate;
    this.startDate = _.isNull(this.model.plan.startDate) ? this.model.effectiveDate : this.model.plan.startDate;

    this.canMapPayroll = this.model.canMapPayroll;
    this.apiService.getBenefitTiers(this.model.plan.benefitLineId)
      .then((tiers: BenefitTierDefinition[]) => {
        this.tiers = tiers;
        this.isLoading = false;
      })
    this.subscriptions.saveButtonState = this.man.subscribeToChangePayrollDeductionDate((hasDedError: boolean) => {
      this.hasDedError = hasDedError;
    });

  }

  ngOnDestroy(): void {
    
  }

  public onChangeTier(tier: BenefitTierDefinition): void {
    this.selectedTier = tier;
    this.isLoading = true;
    this.apiService.getBenefitPlanFlat(tier.id)
      .then((flat: BenefitsEmpEnrollmentFlat) => {
        this.flatSetttings = flat;
        this.empCont = flat.empContribution;
        this.eeCont = flat.erContribution;
        this.isLoading = false;
      })
  }

  public onEnroll(): void {
    this.flatSetttings.empContribution = this.empCont;
    this.flatSetttings.erContribution = this.eeCont;
    this.model.flatSettings = this.flatSetttings;
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Discard Changes',
      'Are you sure you want to cancel? You will lose all unsaved selections.',
      this.modalService,
      (isCancel: boolean) => {
        if (isCancel) {
          this.dialogResult = false;
          this.modalService.closeWindow(this.options.windowUniqueId);
        }
      },
      options);
  }

  public OnDeductionEndDateChange(date: Date) {
    this.model.plan.payrollDeductionEndDate = date;
  }
  public OnDeductionStartDateChange(date: Date) {
    this.model.plan.payrollDeductionStartDate = date;
  }

}

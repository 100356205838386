import { Component, OnDestroy, OnInit, Provider } from '@angular/core';
import { DatePipe } from '@angular/common'
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { DialogOptions, IDialog } from '../../../../common/index';
import { DialogOptions2 } from '../../../../common/models/dialog-options';
import { ModalService } from '../../../../common/services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { ColDef } from 'ag-grid-community';
import { PayCycle } from '../../../../organization/index';
import { DailyTimecardApiService } from '../../services/employee-ta-log/employee-ta-log-api.service';
import { EmployeeTAPunchLogRecord, EmployeeTAPayLogRecord } from '../../models/employee-ta-log';
import { PayCycleHelperService } from './../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';

@Component({
    selector: 'slx-employee-ta-log',
    templateUrl: 'employee-ta-log.component.html',
    styleUrls: ['employee-ta-log.component.scss']
  })
export class EmployeeTALogComponent implements IDialog, OnInit, OnDestroy {

  @unsubscribe()
  private userSubscription: Subscription;

  public options: DialogOptions;
  public dialogResult: boolean;
  private modalService: ModalService;
  public static requestedDate: Date;
  public static employeeId: any;
  public static orgLevelId: any;
  public isLoading:boolean=false;
  private managementService: DailyTimecardApiService;
  public currentPaycycle: PayCycle;

  columnDefs: ColDef[] = [
    { field: "PunchDate", headerName:"Date", cellStyle: { 'padding': "6px" }, width: 155 },
    { field: "PunchInTime", headerName:"In", cellStyle: { 'padding': "6px" }, width: 155 },
    { field: "PunchOutTime", headerName:"Out", cellStyle: { 'padding': "6px" }, width: 155 },
    { field: "Interval", headerName:"Interval", cellStyle: { 'padding': "6px" }, width: 155},
  ];
  columnDefPayCode: ColDef[] = [
    { field: "Code", headerName:"Code", cellStyle: { 'padding': "6px" }, width: 155  },
    { field: "Paid", headerName:"Paid", cellStyle: { 'padding': "6px" }, width: 155  },
    { field: "Interval", headerName:"Interval", cellStyle: { 'padding': "6px" }, width: 155  },
    { field: "Amount", headerName:"Amount", cellStyle: { 'padding': "6px" }, width: 155  },
  ];
  public payCycle: PayCycle = new PayCycle();
  public badge: any;
  public payPolicy: any;
  public shiftDiffPolicy: any;
  public exempt: any;
  public payPeriod: any;
  public rowData: any[];
  public rowPayCodeData: any[] = [];
  public rowPaySummary: any[] = [];
  public punchInTime: any;
  public punchOutTime: any;
  public rowHeight : number= 35;
  constructor(
    public datepipe: DatePipe,
    options: DialogOptions,
    managementService: DailyTimecardApiService,
    modalService: ModalService,
    private payCycleHelper: PayCycleHelperService,
  ) {
    this.modalService = modalService;
    this.options = options;
    this.managementService = managementService;
  }

  public async ngOnInit() {
   await this.getBatchId(EmployeeTALogComponent.employeeId);
   await this.findPaycycle();
   await this.loadTimeCardDetails(EmployeeTALogComponent.employeeId, EmployeeTALogComponent.requestedDate);
  }

  public async loadTimeCardDetails(employeeId: number,  date:any): Promise<void> {
    this.isLoading=true;
    await this.managementService.getDailyTimecard(employeeId,date).then(
       (res: any) => {
        if(res)
        {
       this.payPolicy = res.payPolicy;
       this.shiftDiffPolicy = res.shiftDifferentialPolicy;
       this.exempt = res.exemptStatus;

       let paydata:EmployeeTAPayLogRecord;
      this.rowPaySummary = _.map(res.earningStatistics, sum => this.mapPaySummary(sum));
      this.rowPayCodeData = _.map(res.payRules, rule => this.mapPayRules(rule));
      this.rowData = []; 
      res.dailyPunches.forEach((result) => {
       let data: EmployeeTAPunchLogRecord;
       if(result.timeclock)
       {
       if(result.type.name.includes('in'))
       {
        this.punchInTime = dateTimeUtils.convertFromDtoDateTimeString(result.time);
       }
       else
       {
        this.punchOutTime = dateTimeUtils.convertFromDtoDateTimeString(result.time);
       }
            
       if(this.punchOutTime)
       {
          data = new EmployeeTAPunchLogRecord(); 
          data.PunchDate = this.datepipe.transform(result.punchDate, 'MM/dd/yyyy');
          if(this.punchInTime)
          {
            data.PunchInTime = this.datepipe.transform(this.punchInTime, 'h:mm a');
          }
          data.PunchOutTime = this.datepipe.transform(this.punchOutTime, 'h:mm a');
          let interval: number = Math.round((((this.punchOutTime - this.punchInTime) / (1000 * 60 * 60) % 24) * 100)) / 100; 
          data.Interval = Number.isNaN(interval) ? '0.00' : (Math.round(interval*4)/4).toFixed(2);

          this.rowData.push(data); 
          this.punchInTime =  undefined;
          this.punchOutTime = undefined;
       }
      } 
        });        
        }
        this.isLoading=false;
       }
     );
  }
  
public mapPayRules(record: any): EmployeeTAPayLogRecord {
  const paydata: EmployeeTAPayLogRecord = new EmployeeTAPayLogRecord();
  paydata.Code = record.ruleDefinition.description;
  paydata.Paid = this.rowPaySummary.includes(record.ruleDefinition.description)? 'Y' : 'N';
  
  paydata.Amount = record.moneyAmount;

  let start = dateTimeUtils.convertFromDtoDateTimeString(record.start);
  let end = dateTimeUtils.convertFromDtoDateTimeString(record.end);
  paydata.Interval = (Math.round((dateTimeUtils.convertFromDtoDurationString(record.interval).asHours()) * 100 )/ 100).toFixed(2);
  return paydata;
  
}

public mapPaySummary(record: any): string {
  return record.codeName;
}
  private async  findPaycycle(): Promise<void> {
    this.isLoading=true;
    this.currentPaycycle = null;
    await this.payCycleHelper.getPayCycleByDate(EmployeeTALogComponent.requestedDate, EmployeeTALogComponent.orgLevelId, +EmployeeTALogComponent.employeeId)
      .then((cycle: PayCycle) => {

        if(cycle)
        {
        this.currentPaycycle = cycle;
        this.payPeriod= this.datepipe.transform(cycle.startDate, 'MM/dd/yyyy') + "-"+ this.datepipe.transform(cycle.endDate, 'MM/dd/yyyy');
        this.isLoading=false;
        }
      });
  }

  private async  getBatchId(employeeId: number): Promise<void> {
    this.isLoading=true;
    this.badge = null;
    await this.managementService.getBatchId(employeeId)
      .then((badgeId: any) => {
        this.badge = badgeId;
      });
  }

  public static openDialog(
    modalService: ModalService,
    employeeId: number,
    requestDate: Date,
    orgLevelId: number
  ): any {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.height= 640;
    dialogOptions.width=700;
    //dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.minHegiht = 640;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions,
      }
    ];
    EmployeeTALogComponent.requestedDate = requestDate;
    EmployeeTALogComponent.employeeId = employeeId;
    EmployeeTALogComponent.orgLevelId = orgLevelId;

    let header: string = 'TA Log';
    modalService.globalAnchor.openDialog2(
      EmployeeTALogComponent,
      header,
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {}
    );
    return null;
  }

  public onClose(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    //console.info('Destroy Add Employee popup');
  }

}

import {of as observableOf,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';
import * as _ from 'lodash';
import { Component, Provider, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { DialogOptions2, IDialog, LoaRequestClass, DialogModeSize } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';

import { LoaRequestDialogOptions, LoaRequest, LoaRequestEmployee } from '../../../models/index';

import { screenUtils } from '../../../../../common/utils/index';

import { EmployeeShortInfo } from '../../../../../organization/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-creation-dialog',
  templateUrl: 'lm-creation-dialog.component.html',
  styleUrls: ['lm-creation-dialog.component.scss']
})
export class LMCreationDialogComponent implements IDialog, AfterViewInit {
  public dialogOptions: LoaRequestDialogOptions;
  public dialogResult: boolean;

  @ViewChild('container', { read: ElementRef, static: true })
  private containerElem: ElementRef;
  private options: DialogOptions2;
  private modalService: ModalService;
  private readonly height = {
    [LoaRequestClass.Intermittent]: 704,
    [LoaRequestClass.Continuous]: 564
  };
  private readonly extraHeightIndent = 40;

  public static openDialogForRequest(orgLevelId: number, loaRequest: LoaRequest, modalService: ModalService): LMCreationDialogComponent {
    const options = new LoaRequestDialogOptions(orgLevelId, loaRequest);
    return LMCreationDialogComponent.openDialog(options, modalService);
  }

  public static openDialogForEmployee(employeeShortInfo: EmployeeShortInfo, orgLevelId: number, requestClass: LoaRequestClass, modalService: ModalService): LMCreationDialogComponent {
    const loaRequest = new LoaRequest();
    loaRequest.requestClass = requestClass;
    loaRequest.employee = new LoaRequestEmployee(employeeShortInfo.id, employeeShortInfo.name);
    loaRequest.organization = _.cloneDeep(employeeShortInfo.organization);
    loaRequest.department = _.cloneDeep(employeeShortInfo.department);
    loaRequest.position = _.cloneDeep(employeeShortInfo.position);

    const options = new LoaRequestDialogOptions(orgLevelId, loaRequest);
    return LMCreationDialogComponent.openDialog(options, modalService);
  }

  public static openDialog(options: LoaRequestDialogOptions, modalService: ModalService): LMCreationDialogComponent {
    const title = `${_.isFinite(_.get(options, 'loaRequest.id')) ? 'Edit' : 'Create New'} Leave of Absence Request`;
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.width = 670;
    dialogOptions.height = 260;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: LoaRequestDialogOptions,
        useValue: options
      }
    ];
    return modalService.globalAnchor.openDialog2(
      LMCreationDialogComponent,
      title,
      dialogOptions,
      resolvedProviders
    );
  }

  constructor (
    dialogOptions: LoaRequestDialogOptions,
    options: DialogOptions2,
    modalService: ModalService,
  ) {
    this.dialogOptions = dialogOptions;
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public ngAfterViewInit(): void {
    if (_.isFinite(this.dialogOptions.loaRequest.empId)) {
      const subscr = observableOf(true).pipe(
        delay(100))
        .subscribe(() => {
          subscr.unsubscribe();
          this.updatePopupHeight(this.dialogOptions.loaRequest);
        });
      return;
    }

    this.updatePopupHeight(this.dialogOptions.loaRequest);
  }

  public onRequestChange(req: LoaRequest): void {
    this.updatePopupHeight(req);
  }

  public onAction(isSave: boolean): void {
    if (isSave) {
      this.save();
    } else {
      this.cancel();
    }
  }

  public save(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public cancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  private updatePopupHeight(req: LoaRequest): void {
    const empId = req.empId;
    const container: HTMLElement = this.containerElem.nativeElement;
    if (_.isFinite(empId) && _.isElement(container) && !screenUtils.isMobile) {
      const popup = $(container).closest('kendo-popup');
      if (popup.length > 0) {
        const newHeight = this.height[req.requestClass] + this.extraHeightIndent;
        $(popup).css('height', newHeight);
        popup?.find('.window-container')?.css('height', newHeight);
        popup?.find('.headless-modal-content')?.css('height', newHeight);
      }
    }
  }
}

<slx-spinner [show]="state.isLoading" class="response">
    <div class="slx-high-box">
        <div class="row slx-high-box__body slx-main-content-indents res">

            <div class="row heading">
                <p>
                    The Employee Synchronization process will ensure that your Employee Records are consistent all
                    the time between your HCM and Smartlinx environments.​
                </p>
            </div>

            <div class="row">
                <div class="row ">
                    <div class="col-xs-3">
                        <label class="attribute-heading">Employee Sync Options:</label>
                        <label class="rd-label">
                            <slx-radio-button class="radiobtn" name="allemployee" fieldName="allemployee" option="all"
                                caption="All Employee" [(ngModel)]="order" (ngModelChange)="onInputChange($event)">
                            </slx-radio-button>
                            <slx-radio-button class="radiobtn1" name="specificFacility" fieldName="specificFacility"
                                option="organization" caption="Specific Facility" [(ngModel)]="order"
                                (ngModelChange)="onInputChange($event)">
                            </slx-radio-button>
                            <slx-radio-button class="radiobtn2" name="employeeOnDemand" fieldName="employeeOnDemand"
                                option="onDemand" caption="Employee On Demand" [(ngModel)]="order"
                                (ngModelChange)="onInputChange($event)">
                            </slx-radio-button>
                        </label>
                    </div>

                    <div class="col-xs-9  textarea-sync  ">
                        <div *ngIf="!syncNow">
                            <div class="row checkbox-sync">
                                <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
                                    <slx-checkbox-input slx-input name="active" fieldName="active" caption="Active"
                                        [(ngModel)]="active" (ngModelChange)="onInputActiveCheckChange($event)">
                                    </slx-checkbox-input>
                                </slx-input-decorator>
                                <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
                                    <slx-checkbox-input slx-input name="Inactive" fieldName="Inactive"
                                        caption="InActive" [(ngModel)]="termination"
                                        (ngModelChange)="onInputInActiveCheckChange($event)">
                                    </slx-checkbox-input>
                                </slx-input-decorator>
                                <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
                                    <slx-checkbox-input [readonly]="(active||termination)" slx-input name="currentYear"
                                        fieldName="currentYear" caption="Current Year" [(ngModel)]="currentYear">
                                    </slx-checkbox-input>
                                </slx-input-decorator>
                                <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block pre">
                                    <slx-checkbox-input [readonly]="active" slx-input name="previousYear"
                                        fieldName="previousYear" caption="Previous Year" [(ngModel)]="previousYear">
                                    </slx-checkbox-input>
                                </slx-input-decorator>
                            </div>
                            <div *ngIf="allEmployee && !specificFacility && !employeeOnDemand">
                                <div name="q4">
                                    <p>This process provides the ability to import Active or Inactive Employee as a
                                        single employee record or employees within a
                                        specific facility or all employees within your organization.</p>
                                    <p>The intended use of this synchronization process is to aide with the Initial
                                        Employee Data Synchronization, or support facility
                                        acquisitions or just the need to synchronize records that may have errored out
                                        due to data mapping issues.</p>
                                </div>
                            </div>
                            <div *ngIf="!allEmployee && specificFacility && !employeeOnDemand">
                                <div class="selectcontent"></div>
                                <label class=label for="facility">Select a Facility:</label>
                                <div>
                                    <slx-dropdown-input slx-input class="facility" [options]="facility"
                                        [(ngModel)]="facilityid" valueField="id" textField="name" placeholder="facility"
                                        name="facility" required>
                                    </slx-dropdown-input>
                                </div>
                            </div>
                            <div *ngIf="!allEmployee && !specificFacility && employeeOnDemand">
                                <label class="emp">Payroll Number(s)</label>
                                <div className="ta">
                                    <textarea id=empid-sync slx-input class="ta-sync" type="text" rows="3" name="detail"
                                        placeholder="Enter up to 10 payroll numbers seperated by a comma "
                                        [(ngModel)]="employeeid" style="width:95%"
                                        (keypress)="allowNumericDigitsOnlyOnKeyUp($event)">
                                </textarea>
                                    <span for="empid-sync" class="msg"> {{msg}}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="syncNow">

                            <div class="screenaction">
                                Employee records Synchronization in progress.
                            </div>
                            <div>
                                Sync Transaction Id: {{sycId}} in Progress
                                <br>
                                <br>
                            </div>
                            <div>
                                In-30 minutes return to Employee Sync Report Screen for result
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="row wrapper ">
              
                   
                

                <div class='col-xs-10 fields-wrapper'>
                    <div class="refreshbtn">
                    <button type="submit" [disabled]="refreshdisable" class="theme-button-apply btn-syncrefresh refreshbtn"  (click)="refreshync($event)">
                        Refresh</button>
                    </div>

                    <button type="submit" class="theme-button-apply btn-sync" (click)="employeesyncabc($event)">
                        Sync Now</button>
                </div>
                <div class='col-xs-1'></div>

            </div>


            <kendo-splitter-pane [resizable]="false">
            </kendo-splitter-pane>
            <kendo-grid [data]="gridData" [kendoGridBinding]="gridData" [sortable]="{ mode: 'single' }"
                filterable="menu" [pageable]="true" [pageSize]="10" class="grid slx-full-height slx-blue-grid">
                <kendo-grid-column field="syncId" title="Transaction Id" width="160"></kendo-grid-column>
                <kendo-grid-column title="Transaction Date" field="syncStartDate" width="160" filter="date">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.syncStartDate | date: 'MM/dd/yyyy'}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Sync Start Time" field="syncStartDate" width="150" [filterable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.syncStartDate | date: 'HH:mm:ss'}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Sync End Time" field="syncEndDate" width="150" [filterable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.syncEndDate | date: 'HH:mm:ss'}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Sync Option" field="syncType" width="130">
                </kendo-grid-column>
                <kendo-grid-column title="Organization Name" field="orgName" width="170">
                </kendo-grid-column>
                <kendo-grid-column title="Total Record Count" field="totalCount" width="170" [filterable]="false">
                </kendo-grid-column>
                <kendo-grid-column title="Success" field="successCount" width="110" [filterable]="false">
                </kendo-grid-column>
                <kendo-grid-column title="Error" field="errorCount" width="100" [filterable]="false">
                </kendo-grid-column>
                <kendo-grid-column title="Added Count" field="addedEmpCount" width="130" [filterable]="false">
                </kendo-grid-column>
                <kendo-grid-column title="Active Records" field="activeCount" width="150" [filterable]="false">
                </kendo-grid-column>
                <kendo-grid-column title="Terminated Records" field="terminateCount" width="180" [filterable]="false">
                </kendo-grid-column>

                <kendo-grid-column field="View" width="100" [filterable]="false">
                    <ng-template kendoGridCellTemplate let-data>
                        <a kendoButton (click)="employeeViewDetails(data)">View Details</a>

                    </ng-template>
                </kendo-grid-column>
                <ng-template kendoGridNoRecordsTemplate>
                    <p>There is no data to display</p>
                </ng-template>

                <kendo-grid-messages filterMenuTitle="Filter">
                </kendo-grid-messages>  
            </kendo-grid>
        </div>
    </div>
</slx-spinner>
import { Provider } from '@angular/core';

export * from './lm-management.service';
export * from './lm-roster/lm-roster-management.service';
export * from './lm-creation-absence/lm-creation-absence.management.service';
export * from './lm-recurrence/lm-recurrence.service';
export * from './lm-api.service';
export * from './lm-map.service';
export * from './loa-filter-resolver/loa-filter-resolver.service';
export * from './lm-request-mapper/lm-request-mapper.service';
export * from './lm-type-configuration/lm-type-api.service';

import { LMManagementService } from './lm-management.service';
import { LMRosterManagementService } from './lm-roster/lm-roster-management.service';
import { LMCreationAbsenceManagementService } from './lm-creation-absence/lm-creation-absence.management.service';
import { LMRecurrenceService } from './lm-recurrence/lm-recurrence.service';
import { LMApiService } from './lm-api.service';
import { LMMapService } from './lm-map.service';
import { LoaFilterResolver } from './loa-filter-resolver/loa-filter-resolver.service';
import { LMRequestMapperService } from './lm-request-mapper/lm-request-mapper.service';
import { LMTypeApiService } from './lm-type-configuration/lm-type-api.service';

export const services: Provider[] = [
  LMManagementService,
  LMApiService,
  LMMapService,
  LoaFilterResolver,
  LMTypeApiService
];


import { moment } from '../../../common';
import { dateTimeUtils } from '../../../common/utils/index';
import { IShift, Shift, TaAbsenceCode } from '../../../organization/models/lookup/index';

export interface IDailyAbsence {
  id: number;
  name: string;
  start: string;
  end: string;
  interval: string;
  shift: IShift;
  isDeleted: boolean;
  updateSchedule: boolean;
}
export interface IDailyAllAbsence {
  id: number;
  name: string;
  start: string;
  end: string;
  interval: string;
  shift: IShift;
  isDeleted: boolean;
  updateSchedule: boolean;
}
export class DailyAbsence {
  public id: number;
  public name: string;
  public start: Date;
  public end: Date;
  public rawInterval: number;

  public get rawIntervalMin(): number {
    return this.rawInterval / 60000;
  }

  public set rawIntervalMin(value: number) {
    this.rawInterval = value * 60000;
  }

  public get intervalMin(): number {
    return this.interval / 60000;
  }

  public set intervalMin(value: number) {
    this.interval = value * 60000;
  }

  public get unpaidIntervalMin(): number {
    return this.unpaidInterval / 60000;
  }

  public set unpaidIntervalMin(value: number) {
    this.unpaidInterval = value * 60000;
  }

  public interval: number;
  public intervalHM: Date;

  public unpaidInterval: number;

  public shift: Shift;
  public hasLinkedSa: boolean;
  public absence: TaAbsenceCode;
  public isServerSide: boolean;
  public isDeleted: boolean;
  public updateSchedule: boolean;

  public calculateInterval(): void {
    if (moment(this.end).isSameOrAfter(moment(this.start))) {
      let diff: number = moment.range(this.start, this.end).diff();
      let unpaidDuration = 0;
      if (this.shift) {
        let dateRange: { start: Date, end: Date } = dateTimeUtils.getDateRangeFromTimeRange(this.start, this.shift.start, this.shift.end);
        const shiftTime: number = moment.range(dateRange.start, dateRange.end).diff();
        const shiftPaidDuration = dateTimeUtils.getTimeTotalSeconds(this.shift.duration) * 1000;
        if (shiftTime > shiftPaidDuration) {
          unpaidDuration = shiftTime - shiftPaidDuration;
        }
      }
      this.interval = (diff - unpaidDuration);
      this.unpaidInterval = unpaidDuration;
    } else {
      this.interval = 0;
      this.unpaidInterval = 0;
    }
  }

  public copyIntervalToRaw(): void {
    this.rawInterval = this.interval + this.unpaidInterval;
  }

  public checkHasLinkedSa(): void {
    if (!this.absence || !this.shift) {
      this.hasLinkedSa = false;
      return;
    }
    this.hasLinkedSa = !!this.absence.code;
  }
}
export class DailyAllAbsence {
  public id: number;
  public name: string;
  public start: Date;
  public end: Date;
  public rawInterval: number;
  public errors: NumberMap<string>;
  public get rawIntervalMin(): number {
    return this.rawInterval / 60000;
  }

  public set rawIntervalMin(value: number) {
    this.rawInterval = value * 60000;
  }

  public get intervalMin(): number {
    return this.interval / 60000;
  }

  public set intervalMin(value: number) {
    this.interval = value * 60000;
  }

  public get unpaidIntervalMin(): number {
    return this.unpaidInterval / 60000;
  }

  public set unpaidIntervalMin(value: number) {
    this.unpaidInterval = value * 60000;
  }

  public interval: number;
  public intervalHM: Date;

  public unpaidInterval: number;

  public shift: Shift;
  public hasLinkedSa: boolean;
  public absence: TaAbsenceCode;
  public isServerSide: boolean;
  public isDeleted: boolean;
  public updateSchedule: boolean;

  public calculateInterval(): void {
    if (moment(this.end).isSameOrAfter(moment(this.start))) {
      let diff: number = moment.range(this.start, this.end).diff();
      let unpaidDuration = 0;
      if (this.shift) {
        let dateRange: { start: Date, end: Date } = dateTimeUtils.getDateRangeFromTimeRange(this.start, this.shift.start, this.shift.end);
        const shiftTime: number = moment.range(dateRange.start, dateRange.end).diff();
        const shiftPaidDuration = dateTimeUtils.getTimeTotalSeconds(this.shift.duration) * 1000;
        if (shiftTime > shiftPaidDuration) {
          unpaidDuration = shiftTime - shiftPaidDuration;
        }
      }
      this.interval = (diff - unpaidDuration);
      this.unpaidInterval = unpaidDuration;
    } else {
      this.interval = 0;
      this.unpaidInterval = 0;
    }
  }

  public copyIntervalToRaw(): void {
    this.rawInterval = this.interval + this.unpaidInterval;
  }

  public checkHasLinkedSa(): void {
    if (!this.absence || !this.shift) {
      this.hasLinkedSa = false;
      return;
    }
    this.hasLinkedSa = !!this.absence.code;
  }
}

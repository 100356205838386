<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  (dataStateChange)="gridState.dataStateChange($event)"

  [slxKendoGridFiltersState]="gridState"
  (kendoGridStateChanged)="onGridStateChanged($event)"
>
  <kendo-grid-column
    title="Employee Name"
    field="employee.name"
    width="150"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="wc-emp" [title]="dataItem.employee?.name">
        <img class="wc-emp__img" [employeeThumbinalSrc]="dataItem.employee?.id" alt="Employee Photo" />
        <span class="wc-emp__text">{{ dataItem.employee?.name }}</span>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Facility"
    field="organization.name"
    width="150"
    filter="text"
    [hidden]="!isVisible('organization')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Facility</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Department"
    field="department.name"
    width="180"
    filter="text"
    [hidden]="!isVisible('department')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Department</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.department?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Position"
    field="position.name"
    width="180"
    filter="text"
    [hidden]="!isVisible('position')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Accident Date"
    field="incidentDateTime"
    width="120"
    filter="date"
    [hidden]="!isVisible('incidentDateTime')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Accident Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="wc-link" (click)="onOpenReport(dataItem)">{{ dataItem.incidentDateTime | amDateFormat: appConfig.dateFormat }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Cause of Injury"
    field="accidentCause.description"
    width="180"
    filter="text"
    [hidden]="!isVisible('accidentCause')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Cause of Injury</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.accidentCause?.description }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Type of Injury"
    field="injuryType.description"
    width="180"
    filter="text"
    [hidden]="!isVisible('injuryType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Type of Injury</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.injuryType?.description }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Body Part"
    field="bodyPart.description"
    width="180"
    filter="text"
    [hidden]="!isVisible('bodyPart')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Body Part</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.bodyPart?.description }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Injury Description"
    field="injuryOccured.description"
    width="90"
    filter="text"
    [hidden]="!isVisible('injuryOccured')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Injury Description</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.howInjuryOccur }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['desktop']"
    title=""
    class="overflow-visible-cell"
    [width]="50"
    [filterable]="false"
    [sortable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="m-repeat-injury">
        <span class="m-repeat-injury__btn"
          [popper]="incidentInto"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-end'"
          [popperDisableStyle]="'true'"
        >
          <i class="fas fa-list-ul" aria-hidden="true"></i>
        </span>
        <popper-content #incidentInto class="slx-popper">
          <section class="m-repeat-injury__popper">
            <div class="flex flex-direction-row m-repeat-injury__group" *slxHiddenOn="['tablet']">
              <p class="m-repeat-injury__field">
                <span class="m-repeat-injury__title">Department</span>
                <span class="m-repeat-injury__value">{{ dataItem.department?.name }}</span>
              </p>
              <p class="m-repeat-injury__field">
                <span class="m-repeat-injury__title">Position</span>
                <span class="m-repeat-injury__value">{{ dataItem.position?.name }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-repeat-injury__group">
              <p class="m-repeat-injury__field">
                <span class="m-repeat-injury__title">Accident date</span>
                <span class="m-repeat-injury__value">
                  <span class="wc-link" (click)="onOpenReport(dataItem)">{{ dataItem.incidentDateTime | amDateFormat: appConfig.dateFormat }}</span>
                </span>
              </p>
              <p class="m-repeat-injury__field">
                <span class="m-repeat-injury__title">Cause of Injury</span>
                <span class="m-repeat-injury__value">{{ dataItem.accidentCause?.description }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-repeat-injury__group">
              <p class="m-repeat-injury__field">
                <span class="m-repeat-injury__title">Type of Injury</span>
                <span class="m-repeat-injury__value">{{ dataItem.injuryType?.description }}</span>
              </p>
              <p class="m-repeat-injury__field">
                <span class="m-repeat-injury__title">Body Part</span>
                <span class="m-repeat-injury__value">{{ dataItem.bodyPart?.description }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-repeat-injury__group">
              <p class="m-repeat-injury__field">
                <span class="m-repeat-injury__title">Injury Description</span>
                <span class="m-repeat-injury__value">{{ dataItem.injuryOccured?.description }}</span>
              </p>
            </div>
          </section>
        </popper-content>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel fileName="Workers' Comp Repeat Injury.xlsx" [fetchData]="retriveAllPages()"></kendo-grid-excel>
  <kendo-grid-pdf fileName="Workers' Comp Repeat Injury.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

<slx-spinner [show]="isLoading">
<div id="mapping-modal-body" class="modal-body">
  <div class="top-panel">
    <button
      type="button"
      (click)="showBenefitPayrollExistingMappingDialog()"
      class="slx-button slx-blue slx-margin-r slx-mobile-adapted mapping-btn pull-left"
      id="mapping-button"
    >
    <span class="slx-button__text">View Existing Mapping</span>
    </button>
    <slx-switcher [(ngModel)]="ignoredDeductions" (ngModelChange)="onTogglerChanged($event)" label="Ignored Deductions"
    labelOn="On" labelOff="Off"></slx-switcher>
  </div>
  <div class="note">
    Select the unmapped payroll deduction types below to map to benefits in HR.
  </div>
  <div class="mapping-grid">
    <kendo-grid
      #kendoGrid
      class="slx-blue-grid slx-grid-slim-rows slx-full-height mapping-grid__height"
      slxKendoGridState="BENEFITS_PAYROLL_MAPPING"
      [data]="gridState.view"
      [filterable]="'menu'"
      [filter]="gridState.state.filter"
      [skip]="gridState.state.skip"
      [sortable]="true"
      [sort]="gridState.state.sort"
      [height]="'100%'"
      (dataStateChange)="gridState.dataStateChange($event)"
    >
      <kendo-grid-column
        title="Payroll Deduction Type"
        width="150"
        field="description"
        filter="text"
        [sortable]="true"
        [filterable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Payroll Deduction</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.description }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="Create & Map"
        width="120"
        field="isIgnored"
        [sortable]="false"
        [filterable]="false"
      >
        <ng-template kendoGridHeaderTemplate>
          <div class="flex">
            <slx-input-decorator
              class="header-checkbox__margin"
              className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-checkbox-small"
            >
              <slx-checkbox-input
                slx-input
                name="isAllDeductionSelected"
                fieldName="isAllDeductionSelected"
                [(ngModel)]="isAllDeductionSelected"
                (ngModelChange)="onChangeIsAllDeductionSelected()"
              >
              </slx-checkbox-input>
            </slx-input-decorator>
            <span class="margin-top__create-benefit">Ignore Deduction(s)</span>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <slx-input-decorator
            className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-checkbox-small"
          >
            <slx-checkbox-input
              slx-input
              name="deduction{{ dataItem.id }}"
              fieldName="deduction{{ dataItem.id }}"
              [(ngModel)]="dataItem.isIgnored"
              (ngModelChange)="onChangeDeductionisIgnored(dataItem)"
            >
            </slx-checkbox-input>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="Benefit Group"
        width="150"
        field="benefitGroup"
        filter="text"
        [sortable]="false"
        [filterable]="false"
      >
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Benefit Group</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <slx-dropdown-list
            [readonly]="dataItem.isIgnored"
            class="slx-wide"
            name="shiftGroups"
            [options]="benefitGroups"
            [defaultItem]="defaultGroupItem"
            valueField="id"
            titleField="name"
            [(ngModel)]="dataItem.benefit"
            (ngModelChange)="onChangebenefitGroups(dataItem)"
          >
          </slx-dropdown-list>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-messages filterMenuTitle="Filter">
      </kendo-grid-messages>  
    </kendo-grid>
  </div>
</div>
<div
  class="modal-footer"
>
  <button class="btn green" (click)="save()" [disabled]="this.benefitPayrollCreateMapping.length === 0 && !hasIgnoreChanges">
    <span class="btn-text__padding"><i class="fas fa-save slx-button__icon" aria-hidden="true"></i>Create</span>
  </button>
  <button
    class="btn red"
    (click)="cancel()"
  >
    <i class="fa fa-times" aria-hidden="true"></i>
    <span class="btn-text__padding">Cancel</span>
  </button>
</div>
</slx-spinner>

import { Provider } from '@angular/core';
import { SortService } from '@progress/kendo-angular-grid';
import { MessageApiService } from './message-api.service';
import { SlateMessagesApiService } from './slate-messages-api.service';

export const services: Provider[] = [
    MessageApiService,
    SortService,
    SlateMessagesApiService
];

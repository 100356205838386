
import { NgModule, Injectable } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { HotkeyModule } from 'angular2-hotkeys';
import { AgmCoreModule } from '@agm/core';

import { appRoutes } from './app.routes';
import { AppComponent } from './components/index';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { CommonModule } from './common/index';
import { CoreModule } from './core/index';
import { AuthenticationModule } from './authentication/index';
import { OrganizationModule, TimeclockDataService, MonitoringService } from './organization/index';
import { PortalModule } from './portal/index';
import { ConfigurationModule } from './configuration/index';
import { IAppState, middleware, enhancers, rootReducer } from './store/index';
import { VersionSubscribeService } from './common/services/index';
import { SimpleNotificationsModule } from './core/components/angular2-notifications/simple-notifications.module';
import { appConfig } from './app.config';
import { NgIdleModule } from '@ng-idle/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import * as Hammer from 'hammerjs';
import { rootEpics } from './state-model/state-model.module';
import { Epic } from 'redux-observable';
import { WindowRef } from './core/services/window/window-ref.model';
import { IntegrationModule } from './app-modules/logi-integration/logi-integration.module';
export function getWindow(): Window { return window; }
export function getDocument(): Document { return document; }
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AuthInterceptor } from './core/services/api/api-interceptor';
import { RouterModule } from '@angular/router';
import { NgxPopperModule } from 'ngx-popper';
import { DataService } from './configuration/services/pay-code-details/data.service';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { EliteDashboardModule } from './app-modules/ElieteDashboard/elite-dashboard.module';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'swipe': { direction: Hammer.DIRECTION_ALL }
  };
}

@NgModule({
  imports: [
    HotkeyModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    DialogsModule,
    ButtonsModule,
    ChartsModule,
    CommonModule,
    DialogModule,
    CoreModule,
    AuthenticationModule,
    OrganizationModule,
    PortalModule,
    IntegrationModule,
    EliteDashboardModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    ProgressBarModule,
    ConfigurationModule,
    NgReduxModule,
    NgxPopperModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    NgIdleModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyC1IuFsMTuPeZ5nGlaEBj34jAjwf3b-KFM",
      libraries: ['places']
    })
  ],
  declarations: [AppComponent],
  providers: [WindowRef,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>,
    versionSubscriber: VersionSubscribeService, private monitoringService: MonitoringService) {
    const epicMap = [];
    rootEpics.forEach((epic: Epic<any>) => {
      const epicMiddleware = createEpicMiddleware();
      middleware.push(epicMiddleware);
      epicMap.push({epicMiddleware, epic});
    });
    ngRedux.configureStore(rootReducer, {}, middleware, enhancers);
    epicMap.forEach((v) => {
      v.epicMiddleware.run(v.epic);
    });
    versionSubscriber.runDialogListener();
  }
}

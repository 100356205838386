import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { ReplaySubject ,  Subject ,  Subscription } from 'rxjs';
import { Assert } from '../../../../framework/index';
import { BenefitEligibilityRulesApiService } from './benefit-eligibility-rules-api.service';
import {
  BenefitEligibilityRule,
  BenefitEligibilityRulesChangeRequest,
  BenefitDetailsTier,
  BenefitEligibilityRuleStatus,
} from '../../models/index';

@Injectable()
export class BenefitEligibilityRulesManagementService {
  constructor(private apiService: BenefitEligibilityRulesApiService) {}

  private rulesByTierName: StringMap<BenefitEligibilityRule[]> = {};

  private loading$ = new Subject<boolean>();
  private benefitEligibilityRuleById$ = new ReplaySubject<BenefitEligibilityRule>(1);
  private benefitEligibilityRulesByTierId$ = new ReplaySubject<KeyValuePair<string, BenefitEligibilityRule[]>>(1);
  private benefitEligibilityRulesNotBelongsToTier$ = new ReplaySubject<BenefitEligibilityRule[]>(1);

  public destroy(): void {
    this.loading$.complete();
    this.benefitEligibilityRuleById$.complete();
    this.benefitEligibilityRulesByTierId$.complete();
    this.benefitEligibilityRulesNotBelongsToTier$.complete();
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToLoadBenefitRuleById(callback: (rule: BenefitEligibilityRule) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.benefitEligibilityRuleById$.subscribe(callback);
  }

  public subscribeToLoadBenefitRulesByTierId(
    callback: (rules: KeyValuePair<string, BenefitEligibilityRule[]>) => void
  ): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.benefitEligibilityRulesByTierId$.subscribe(callback);
  }

  public subscribeToLoadBenefitRulesNotBelongsToTier(
    callback: (rules: BenefitEligibilityRule[]) => void
  ): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.benefitEligibilityRulesNotBelongsToTier$.subscribe(callback);
  }

  public async loadBenefitEligibilityRulesByTierId(
    benefitTierId: number,
    benefitTierName: string,
    isEditMode: boolean
  ): Promise<void> {
    this.loading$.next(true);
    try {
      const benefitEligibilityRules = await this.apiService.getBenefitEligibilityRulesByTierId(benefitTierId);

      const existingBenefitEligibilityRules = isEditMode
        ? _.merge(benefitEligibilityRules, this.rulesByTierName[benefitTierName] || [])
        : benefitEligibilityRules;

      const rulesPair: KeyValuePair<string, BenefitEligibilityRule[]> = {
        key: benefitTierName,
        value: existingBenefitEligibilityRules,
      };

      this.rulesByTierName[benefitTierName] = existingBenefitEligibilityRules;

      this.benefitEligibilityRulesByTierId$.next(rulesPair);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading$.next(false);
    }
  }

  public getExistingBenefitEligibilityRulesByTierName(benefitTierName: string): BenefitEligibilityRule[] {
    const benefitEligibilityRules: BenefitEligibilityRule[] = this.rulesByTierName[benefitTierName] || [];

    return benefitEligibilityRules;
  }

  public async loadBenefitEligibilityRuleById(ruleId: number): Promise<void> {
    this.loading$.next(true);
    try {
      const benefitEligibilityRule = await this.apiService.getBenefitEligibilityRule(ruleId);

      this.benefitEligibilityRuleById$.next(benefitEligibilityRule);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading$.next(false);
    }
  }

  public async loadBenefitEligibilityRulesNotBelongsToTier(benefitTierId: number): Promise<void> {
    this.loading$.next(true);
    try {
      const benefitEligibilityRules = await this.apiService.getBenefitEligibilityRulesNotBelongsToTier(benefitTierId);

      this.benefitEligibilityRulesNotBelongsToTier$.next(benefitEligibilityRules);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading$.next(false);
    }
  }

  private async changeEligibilityRules(
    orgLevelId: number,
    benefitTierId: number,
    benefitEligibilityRules: BenefitEligibilityRule[]
  ): Promise<void> {
    try {
      const rulesToAdd: BenefitEligibilityRule[] = _.filter(
        benefitEligibilityRules,
        (item: BenefitEligibilityRule) => item.status === BenefitEligibilityRuleStatus.Added
      );
      const rulesToUpdate: BenefitEligibilityRule[] = _.filter(
        benefitEligibilityRules,
        (item: BenefitEligibilityRule) => item.status === BenefitEligibilityRuleStatus.Updated
      );
      const rulesToDelete: number[] = _.chain(benefitEligibilityRules)
        .filter((item: BenefitEligibilityRule) => item.status === BenefitEligibilityRuleStatus.Deleted)
        .map((item: BenefitEligibilityRule) => item.id)
        .value();

      const changeRequest: BenefitEligibilityRulesChangeRequest = new BenefitEligibilityRulesChangeRequest({
        rulesToAdd: rulesToAdd,
        rulesToUpdate: rulesToUpdate,
        rulesToDelete: rulesToDelete,
      });

      await this.apiService.changeEligibilityRules(orgLevelId, benefitTierId, changeRequest);
    } catch (e) {
      console.error(e);
    }
  }

  public deleteBenefitEligibilityRule(benefitEligibilityRuleId: number, tierName: string) {
    if (!benefitEligibilityRuleId) {
      return;
    }

    const benefitRulesByTierName = this.rulesByTierName[tierName] || [];
    const alreadyExistedBenefitRule = _.find(
      benefitRulesByTierName,
      (benefitRule) => benefitRule.id === benefitEligibilityRuleId
    );

    if (!alreadyExistedBenefitRule) {
      return;
    }

    let newBenefitRules: BenefitEligibilityRule[] = [];
    if (alreadyExistedBenefitRule.status === BenefitEligibilityRuleStatus.Added) {
      newBenefitRules = _.without(benefitRulesByTierName, alreadyExistedBenefitRule);
    } else {
      alreadyExistedBenefitRule.status = BenefitEligibilityRuleStatus.Deleted;
      newBenefitRules = [...benefitRulesByTierName];
    }

    this.updateRulesByTierName(newBenefitRules, tierName);
  }

  public updateBenefitEligibilityRule(updatedBenefitRule: BenefitEligibilityRule, tierName: string) {
    if (!updatedBenefitRule) {
      return;
    }
    const benefitRulesByTierName = this.rulesByTierName[tierName] || [];

    const newBenefitRules = _.map(benefitRulesByTierName, (benefitRule) =>
      benefitRule.id === updatedBenefitRule.id ? updatedBenefitRule : benefitRule
    );

    this.updateRulesByTierName(newBenefitRules, tierName);
  }

  public addBenefitEligibilityRule(benefitEligibilityRule: BenefitEligibilityRule, tierName: string) {
    let benefitRulesByTierName = this.rulesByTierName[tierName] || [];
    const existingRule = _.find(benefitRulesByTierName, rule => rule.id === benefitEligibilityRule.id);
    if(existingRule) {
      benefitRulesByTierName = _.without(benefitRulesByTierName, existingRule);
    }

    const newBenefitRules = [...benefitRulesByTierName, benefitEligibilityRule];
    this.updateRulesByTierName(newBenefitRules, tierName);
  }

  private updateRulesByTierName(newBenefitRules: BenefitEligibilityRule[], tierName: string) {
    this.rulesByTierName[tierName] = newBenefitRules;

    const rulesPair: KeyValuePair<string, BenefitEligibilityRule[]> = {
      key: tierName,
      value: newBenefitRules,
    };

    this.benefitEligibilityRulesByTierId$.next(rulesPair);
  }

  public async saveRules(orgLevelId: number, benefitTiers: BenefitDetailsTier[]) {
    if (_.isEmpty(this.rulesByTierName)) {
      return;
    }

    this.loading$.next(true);
    try {
      const changeEligibilityRulesPromises = _.map(benefitTiers, (tier: BenefitDetailsTier) => {
        const rules = this.rulesByTierName[tier.name];
        return !rules ? Promise.resolve() : this.changeEligibilityRules(orgLevelId, tier.id, rules);
      });

      await Promise.all(changeEligibilityRulesPromises);

      this.clearExistingMappedRules();
      await this.refreshTiersRuleList(orgLevelId, benefitTiers);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading$.next(false);
    }
  }

  public getUsedRuleNames(
    benefitEligibilityRules: BenefitEligibilityRule[],
    benefitEligibilityRulesNotBelongToTier: BenefitEligibilityRule[]
  ) {
    const benefitRulesListUsedNames = _.map(benefitEligibilityRules, (rule) => rule.name);
    const benefitExistingRulesListUsedNames = _.map(benefitEligibilityRulesNotBelongToTier, (rule) => rule.name);
    const rulesAlreadyUsedNames = _.concat(benefitRulesListUsedNames, benefitExistingRulesListUsedNames);
    return rulesAlreadyUsedNames;
  }

  public addExistigBenefitEligibilityRule(
    benefitRuleToUpdate: BenefitEligibilityRule,
    benefitName: string
  ): BenefitEligibilityRule {
    const updatedBenefitRule = new BenefitEligibilityRule({
      id: benefitRuleToUpdate.id,
      name: benefitRuleToUpdate.name,
      description: benefitRuleToUpdate.description,
      type: benefitRuleToUpdate.type,
      rule: benefitRuleToUpdate.rule,
      ruleV5: benefitRuleToUpdate.ruleV5,
      isDeleted: false,
      modifiedAt: benefitRuleToUpdate.modifiedAt,
      modifiedBy: benefitRuleToUpdate.modifiedBy,
      addedAt: benefitRuleToUpdate.addedAt,
      addedBy: benefitRuleToUpdate.addedBy,
      ruleStatements: benefitRuleToUpdate.ruleStatements,
      status: BenefitEligibilityRuleStatus.Added,
    });

    this.addBenefitEligibilityRule(updatedBenefitRule, benefitName);

    return updatedBenefitRule;
  }

  public updateRuleTierName(newName: string, oldName: string) {
    const rules = this.rulesByTierName[oldName];
    if (rules) {
      this.rulesByTierName[newName] = rules;
      delete this.rulesByTierName[oldName];
    }
  }

  public clearExistingMappedRules() {
    this.rulesByTierName = {};
  }

  private async refreshTiersRuleList(orgLevelId: number, benefitTiers: BenefitDetailsTier[]) {
    _.forEach(benefitTiers, (tier: BenefitDetailsTier) => {
      if (this.rulesByTierName[tier.name]) {
        this.loadBenefitEligibilityRulesByTierId(tier.id, tier.name, false);
        this.loadBenefitEligibilityRulesNotBelongsToTier(tier.id);
      }
    });
  }
}


import {combineLatest, filter} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { Observable ,  ReplaySubject ,  Subject ,  Subscription } from 'rxjs';

import { Assert } from '../../../../framework/index';

import { ColumnSettingsStorageService, ColumnManagementService, ComponentStateStorageService, StateManagementService } from '../../../../common/services/index';
import { LMRequestMapperService } from '../../services/lm-request-mapper/lm-request-mapper.service';
import { mutableSelect } from '../../../../core/decorators/index';
import { IDateRange, DateRange, IColumnSettings, StateResetTypes } from '../../../../core/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { LoaRequest, LoaRosterState, LoaMappedRequest, LoaRequestContainer, ILoaFilter, MetaContainer, LoaSecurityActions } from '../../models/index';
import { LMApiService } from '../lm-api.service';
import { LoaCategory } from '../../../../common/models';
import { LMManagementService } from '../lm-management.service';
@Injectable()
export class LMRosterManagementService {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private subscriptions: StringMap<Subscription> = {};

  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private loaRequestsLoaded$ = new ReplaySubject<LoaRequestContainer>(1);
  private state$ = new ReplaySubject<LoaRosterState>(1);
  private viewMode$ = new Subject<boolean>();
  private calendarMode$ = new Subject<boolean>();
  private loading$ = new Subject<boolean>();
  private categoryFilter$ = new ReplaySubject<LoaCategory[]>(1);
  private dateRange$ = new ReplaySubject<IDateRange>(1);
  private exportTo$ = new Subject<boolean>();
  private routeFilters$ = new ReplaySubject<ILoaFilter>();
  private viewListState$ = new Subject<boolean>();
  private viewDayState$ = new Subject<boolean>();

  private orgLevel: OrgLevel;
  private state: LoaRosterState;
  private sDate: Date;
  private eDate: Date;
  private defaultStartDate: Date;
  private defaultEndDate: Date;
  private filter: ILoaFilter;
  private componentId: string;
  private columnsVisibilityKey = 'columnsVisibility';
  private readonly oneDayMs: number = 1000 * 60 * 60 * 24;
  private readonly maxDays: number = 30;
  private viewCalender: string = 'viewCalender';
  private viewDay: string = 'viewDay';
  private resetBy: StateResetTypes = StateResetTypes.SessionEnd;

  constructor (
    private requestMapper: LMRequestMapperService,
    private apiService: LMApiService,
    private columnSettingsStorageService: ColumnSettingsStorageService,
    private columnManagementService: ColumnManagementService,
    private storageService: ComponentStateStorageService,
    private stateManagement: StateManagementService,
    private managementService: LMManagementService
  ) {
    const now = new Date();
    this.defaultStartDate = now;
    this.defaultEndDate = new Date(now.getTime() + (this.oneDayMs * this.maxDays));
  }

  public init(componentId: string): void {
    this.componentId = componentId;
    this.changeDateRange(new DateRange(this.defaultStartDate, this.defaultEndDate), false);
    this.restoreState();
    this.subscribeToData();
  }

  public destroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public changeRouteData(routeData: any): void {
    this.updateFilter();
    if (routeData) {
      const filters = _.find(_.values(routeData), (d) => _.isObjectLike(d) && (d.category || d.loaType || d.action || d.empId));
      this.updateFilter(_.get(filters, 'category') || '', _.get(filters, 'loaType') || '', _.get(filters, 'action') || '', _.get(filters, 'empId') || NaN);
    }
    this.routeFilters$.next(this.filter);
    this.subscribeToOrgLevel();
  }

  public subscribeToRouteFilter(callback: (f: ILoaFilter) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.routeFilters$.subscribe(callback);
  }

  public subscribeToOrgLevelChanged(callback: (e: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToLoading(callback: (isLoading: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToRequestsLoaded(callback: (r: LoaRequestContainer) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loaRequestsLoaded$.subscribe(callback);
  }

  public changeDateRange(r: IDateRange, reloadRequests: boolean = true): void {
    if (moment(r.startDate).isSame(this.sDate) && moment(r.endDate).isSame(this.eDate)) return;

    this.sDate = r.startDate;
    this.eDate = r.endDate;

    this.dateRange$.next(r);
    if (reloadRequests) {
      this.loadLoaRequests();
    }
  }

  public subscribeToDateRange(callback: (r: IDateRange) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.dateRange$.subscribe(callback);
  }

  public changeViewMode(isListView: boolean): void {
    this.loading$.next(true);
    setTimeout(() => {
      this.loading$.next(false);
    }, 500);
    this.viewMode$.next(isListView);
    this.saveListViewState(isListView);
  }

  public subscribeToViewMode(callback: (isListView: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.viewMode$.subscribe(callback);
  }

  public mapRequests(requests: LoaRequest[]): LoaMappedRequest[] {
    return _.map(requests, (r: LoaRequest) => this.requestMapper.mapRequest(r));
  }

  public changeCalenderMode(isDay: boolean): void {
    this.calendarMode$.next(isDay);
    this.saveCalanderViewState(isDay);
  }

  public subscribeToCalenderMode(callback: (isDay: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.calendarMode$.subscribe(callback);
  }

  public changeCategoryFilter(filters: LoaCategory[]): void {
    this.categoryFilter$.next(filters);
  }

  public subscribeToCategoryFilter(callback: (f: LoaCategory[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.categoryFilter$.subscribe(callback);
  }

  public exportTo(isPDF: boolean): void {
    this.exportTo$.next(isPDF);
  }

  public subscribeToExport(callback: (isPDF: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.exportTo$.subscribe(callback);
  }

  public saveState(): void {
    this.columnSettingsStorageService.setColumnsState(this.componentId, this.columnsVisibilityKey, this.state.columns);
  }

  public changeState(s: LoaRosterState): void {
    this.state = s;
    this.state$.next(s);
  }

  public subscribeToChangeState(callback: (s: LoaRosterState) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.state$.subscribe(callback);
  }

  public subscribeToChangeListViewState(callback: (s: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.viewListState$.subscribe(callback);
  }

  public subscribeToChangeDayViewState(callback: (s: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.viewDayState$.subscribe(callback);
  }

  public async loadLoaRequestsByOrgLevel(): Promise<LoaRequest[]> {
    return this.loadRequestsByOrgLevel(this.orgLevel);
  }

  public async loadLoaRequests(): Promise<LoaRequest[]> {
    return this.loadRequests(this.orgLevel, this.sDate, this.eDate);
  }

  private async loadRequestsByOrgLevel(orgLevel: OrgLevel): Promise<LoaRequest[]> {
    if (!_.isFinite(_.get(orgLevel, 'id'))) return;
    this.loading$.next(true);
    return this.apiService.getLoaRequestsByOrgLevel(orgLevel.id)
      .then((res: MetaContainer<LoaRequestContainer>) => {
        let actions = res.actions;
        this.setActions(actions);
        let data = res.data
        this.changeDateRange(new DateRange(data.start, data.end), false);
        this.loaRequestsLoaded$.next(data);
        this.loading$.next(false);
        return data.requests;
      })
      .catch((err) => {
        this.loading$.next(false);
        console.error(err);
        return [];
      });
  }

  private setActions(actions: string[]): void {
    this.managementService.canDelete = _.findIndex(actions, x => x == LoaSecurityActions.canDelete) >= 0;
    this.managementService.canAddEdit = _.findIndex(actions, x => x == LoaSecurityActions.canAddEdit) >= 0;
  }

  private async loadRequests(orgLevel: OrgLevel, sDate: Date, eDate: Date): Promise<LoaRequest[]> {
    if (!_.isFinite(_.get(orgLevel, 'id')) || !_.isDate(sDate) || !_.isDate(eDate)) return;

    this.loading$.next(true);
    return this.apiService.getLoaRequests(orgLevel.id, sDate, eDate)
      .then((cont: MetaContainer<LoaRequestContainer>) => {

        let actions = cont.actions;
        this.setActions(actions);

        this.loaRequestsLoaded$.next(cont.data);
        this.loading$.next(false);
        return cont.data.requests;
      })
      .catch((err) => {
        this.loading$.next(false);
        console.error(err);

        return [];
      });
  }

  private subscribeToOrgLevel(): void {
    if (!this.subscriptions.orgLevel) {
      this.subscriptions.orgLevel = this.orgLevel$.pipe(
        filter((o: OrgLevel) => o && _.isFinite(o.id)))
        .subscribe((orgLevel: OrgLevel) => {
          if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
          this.orgLevel = orgLevel;
          this.orgLevelChanged$.next(this.orgLevel);
        });
    }
  }

  private subscribeToData(): void {
    this.subscriptions.data = this.routeFilters$.pipe(
      combineLatest(this.orgLevelChanged$))
      .subscribe(() => {
        if (_.size(this.filter.category) > 0 || _.size(this.filter.loaType) > 0) {
          this.loadLoaRequestsByOrgLevel();
          this.updateFilter();
        } else {
          this.loadLoaRequests();
        }
      });
  }

  private saveListViewState(isList: boolean): void {
    this.storageService.setControlState(this.componentId,
       this.viewCalender,
       { value: isList },
       this.resetBy);
   }

   private saveCalanderViewState(isDay: boolean): void {
    this.storageService.setControlState(this.componentId,
       this.viewDay,
       { value: isDay },
       this.resetBy);
   }

  private restoreState(): void {
    const state = new LoaRosterState();
    state.createColumns();

    this.stateManagement.init(this.componentId, true);
    this.columnManagementService.init(this.componentId);
    this.columnManagementService.initializeGroupWithColumns(this.columnsVisibilityKey, state.columns);

    this.subscriptions.viewListState = this.stateManagement.onInit$
    .subscribe(() => {
      const state = this.storageService.getControlState(this.componentId, this.viewCalender);
      const value = _.isBoolean(state.value) ? state.value : true;
       this.viewListState$.next(value);
       this.changeViewMode(value);
    });

    this.subscriptions.viewDayState = this.stateManagement.onInit$
    .subscribe(() => {
      const state = this.storageService.getControlState(this.componentId, this.viewDay);
      const value = _.isBoolean(state.value) ? state.value : false;
       this.viewDayState$.next(value);
       this.changeCalenderMode(value);
    });
    this.subscriptions.state = this.columnManagementService.groupInitialized$.pipe(
      filter((event) => event.group === this.columnsVisibilityKey))
      .subscribe((event) => {
        this.changeState(state);
      });
  }

  private updateFilter(category: string = '', loaType: string = '', action: string = '', empId: number = NaN): void {
    this.filter = {
      category,
      loaType,
      action,
      empId
    };
  }
}

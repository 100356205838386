import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { EmployeeSectionsBase, EnrollementAttachmentFile, IAttachmentFile } from '../../../../models';
import * as _ from 'lodash';
import { dateTimeUtils } from '../../../../../../common/utils/index';
import { IApplicationConfig, appConfig } from '../../../../../../../app/app.config';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
import { GridDataResult, GridComponent } from '@progress/kendo-angular-grid';

@Component({
  moduleId: module.id,
  selector: 'slx-benefit-attachments',
  templateUrl: './benefit-attachments.component.html',
  styleUrls: ['./benefit-attachments.component.scss']
})
export class BenefitAttachmentsComponent implements OnInit, OnDestroy {

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  public records: EnrollementAttachmentFile[];
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<EnrollementAttachmentFile>;
  public pageSize: number = 5;
  public isLoading: boolean;
  // Cannot combine @Input decorators with query decorators
  //@ViewChild('kendoGrid', { static: true })
  @Input() public employeeId: number;
  private grid: GridComponent;

  @Input() public isEditable: number;

  constructor(private modalService: ModalService, private manService: BenefitsEnrollmentSectionManagementService) {
    this.gridState = new KendoGridStateHelper<EnrollementAttachmentFile>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'addedOn', dir: 'desc' }];
    this.appConfig = appConfig;
  }

  ngOnInit() {
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });
   this.subscriptions.benefitAttachmentLoadSubscription= this.manService.subscribeToBenefitsAttachmentsLoad((v: EnrollementAttachmentFile[]) => {
      this.records = v;
      this.isLoading = false;
      this.refreshGrid();
    });
  }

  private attachmentSubscription(): void {
    this.manService.getAttachmentsData(this.employeeId);
  }

  public ngOnDestroy(): void {
  }

  public onClickDelete(dataItem: EnrollementAttachmentFile): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Delete Attachment',
      'Are you sure you want to remove the document?',
      this.modalService,
      (isDelete: boolean) => {
        if (isDelete) {
          this.isLoading = true;
          this.manService.deleteAttachment(dataItem.id).then(() => {
            this.isLoading = false;
            this.records = _.filter(this.records, (a) => a.id !== dataItem.id);
            this.refreshGrid();
          });
        }
      },
      options);
  }

  public onDocumentFileClick(dataItem: EnrollementAttachmentFile): void {
    this.isLoading = true;
    this.manService.downloadAttachment(dataItem.id).then(() => {
      this.isLoading = false;
    });
  }

  public onAddFile(data): void {
    {
      this.isLoading = true;
      this.manService.addFilesToSave(data, this.employeeId).then(() => {
        this.attachmentSubscription();
      });
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

}



import { Component, OnInit, OnDestroy, Input, NgZone, ViewChild, Host } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import { EmployeeSectionsContacts, EmployeeSectionsTemporalModel, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionBridgeService, EmployeeSectionsPersonalApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeDefinitionsApiService } from '../../../../../organization';
import { NotificationsService } from '../../../../../core/components';
import { NotificationsApiService } from '../../../../../portal/services';
import {unsubscribe } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { EmployeeValidatorAdapter } from '../../../../../organization/services/index';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-contacts',
  templateUrl: 'employee-sections-contacts.component.html',
  styleUrls: ['employee-sections-contacts.component.scss']
})
export class EmployeeSectionsContactsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input() public employeeSectionsContacts: EmployeeSectionsContacts;
  @Input() public employeeId: number;
  
  public firstName: string;
  public status: string;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('form')
  public ngForm: NgForm;
  public enableEmailOptIn: boolean = false;
  public optInTemplateText: string = '';
  public isMessageCenterEnabled = false;
  private orgLevelId: number;
  public employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter;

  @unsubscribe()
  private profileSubscriptions: Subscription;

  constructor(
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService,
    private employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    private notificationsService: NotificationsService,
    private notificationsApiService: NotificationsApiService,
    private employeeSectionBridgeService: EmployeeSectionBridgeService,
    employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter
  ) {
    super(decorator, ngZone);
    this.firstName = decorator.employeeShortInfo.firstName;
    this.status = decorator.employeeShortInfo.status;
    this.orgLevelId = decorator.employeeShortInfo.organization.orgLevelId;
    this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsContacts;
  }

  public get emailValidation(): string {
    return EmployeeValidatorAdapter.emailValidation;
  }
  
  public ngOnInit(): void {
    this.loadSubsection();
    this.initSubscriptions();
  }

  private initSubscriptions(): void {
    this.profileSubscriptions=this.employeeSectionBridgeService.subscribeTorUpdateContactSection((mobileNumber: string)=>{      
     this.employeeSectionsContacts.mobile.fieldValue=mobileNumber;
    });
  }
  private async checkMessageCenterStatus(): Promise<void> {
    try {
      const messageCenterStatus = await this.notificationsApiService.getIconAccessCheck();
      this.isMessageCenterEnabled = messageCenterStatus.isMessageCenterEnabled;
    } catch (e) { }
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsPersonalApiService.getPersonalContacts(this.employeeId)
      .then((employeeSectionsContacts: EmployeeSectionsContacts) => {
        this.employeeSectionsContacts = employeeSectionsContacts;
        this.checkPrimaryEmail(employeeSectionsContacts);
        this.stopProgress();
        if (this.employeeSectionsContacts) {
          this.onLoadBindControls();
        }
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  protected doSave(effectiveDate: Date): void {
    this.employeeSectionsPersonalApiService.setPersonalContacts(this.employeeId, this.employeeSectionsContacts, effectiveDate)
      .then((status: any) => {
        this.employeeSectionBridgeService.reloadProfileSection(this.employeeSectionsContacts);
        this.onActionComplete(true);
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    return this.metaFieldsTemporalDirtyChecker(this.employeeSectionsContacts);
  }

  public onLoadBindControls(): void {
    if (this.employeeSectionsContacts) {
      if (this.employeeShortInfo && this.employeeShortInfo.position) {
        this.orgLevelId = this.employeeShortInfo.position.orgLevelId;
      }
      this.getTemplate();
    }
  }

  public async resendOptInSMS(): Promise<void> {
    this.startProgress();

    try {
      await this.employeeDefinitionsApiService.resendOptInSms([this.employeeId], this.orgLevelId);
      this.notificationsService.success('SMS message sent', 'SMS opt in message was sent successfully');
    } catch (e) { }

    this.loadSubsection();
    this.stopProgress();
  }

  public async resendOptInEmail(): Promise<void> {
    this.startProgress();

    try {
      await this.employeeDefinitionsApiService.resendOptInEmail([this.employeeId]);
      this.notificationsService.success('Email sent', 'Email opt in instructions were sent successfully');
    } catch (e) { }

    this.loadSubsection();
    this.stopProgress();
  }

  public async getTemplate(): Promise<void> {
    await this.checkMessageCenterStatus();

    if (!this.isMessageCenterEnabled) {
      return;
    }

    if (this.employeeSectionsContacts.empOptIn === 0) {
      if (_.isNull(this.employeeSectionsContacts.email1.rawValue)) {
        this.optInTemplateText = `Employee doesn't have an email on their profile`;
      } else {
        this.optInTemplateText = await this.employeeDefinitionsApiService.getEmailTemplate();
      }
    } else if (this.employeeSectionsContacts.empOptIn === 2) {
      this.optInTemplateText = await this.employeeDefinitionsApiService.getSmsTemplate(this.orgLevelId);
    }
  }

  public checkPrimaryEmail(employeeSectionsContacts: EmployeeSectionsContacts): void {
    this.enableEmailOptIn = _.isNull(employeeSectionsContacts.email1.rawValue);
  }
}

import { Injectable } from '@angular/core';
import { RuleDto } from '../../models/pay-code-details/dtos/rule.dto';
import { RuleModel } from '../../models/pay-code-details/models/rule.model';

@Injectable()
export class RuleMapService {
    public mapToModel(dto: RuleDto): RuleModel {
        let model: RuleModel = new RuleModel();
        Object.assign(model, dto);
        model.ruleDescription = dto.description;
        model.startDate = dto.startDate ? new Date(dto.startDate) : undefined;
        model.endDate = dto.endDate ? new Date(dto.endDate) : undefined;
        model.isNew = false;

        if (model.ruleFormulas && model.ruleFormulas.length > 0) {
            model.ruleFormulas.forEach(formula => {
                if (formula.variableId1 && formula.variableId1.includes('Custom Field(')) {
                    formula.variableId1 = formula.variableId1.replace(/Custom Field\(([^)]+)\)/, '$1').trim();
                }
                
                if (formula.variableId2 && formula.variableId2.includes('Custom Field(')) {
                    formula.variableId2 = formula.variableId2.replace(/Custom Field\(([^)]+)\)/, '$1').trim();
                }
            });
        }
        return model;
    }

    public mapToDto(model: RuleModel): RuleDto {
        return {
            id: model.id,
            description: model.ruleDescription,
            startDate: model.startDate instanceof Date ? model.startDate.toISOString() : model.startDate,
            endDate: model.endDate instanceof Date ? model.endDate.toISOString() : model.endDate,
            period: model.period,
            organizationId: model.organizationId,
            departmentId: model.departmentId,
            jobCode: model.jobCode,
            exceptionId: model.exceptionId,
            whereClause: model.whereClause
        };
    }

    public mapToModels(dtos: RuleDto[]): RuleModel[] {
        return dtos.map(dto => this.mapToModel(dto));
    }

    public mapToDtos(models: RuleModel[]): RuleDto[] {
        return models.map(model => this.mapToDto(model));
    }
}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { EmployeePunchRequestManagementService } from './../../../../time-and-attendance/services/punches/employee-punch-request-management.service';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { EmployeePunchRequest } from './../../../../time-and-attendance/models';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-punch-request-grid',
  templateUrl: './employee-punch-request-grid.component.html',
  styleUrls: ['./employee-punch-request-grid.component.scss']
})
export class EmployeePunchRequestGridComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public records: EmployeePunchRequest[];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    public manageService: EmployeePunchRequestManagementService) {
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.subscriptions.onLoaded = this.manageService
      .subscribeToLoadedRecords((r: EmployeePunchRequest[]) => this.assignRecords(r));
    this.manageService.init();
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  private assignRecords(records: EmployeePunchRequest[]): void {
    this.records = records;
    this.manageService.refreshGrid();
  }

  public sortChangeHandler(sort: SortDescriptor[]): void {
    this.manageService.setSort(sort);
  }

  public pageChangeHandler(event: PageChangeEvent): void {
    this.manageService.pageChange(event);
  }

  public filterChangeHandler(event: any): void {
    this.manageService.filterChange(event);
  }

  public get dateFormat(): string {
    return '{0:MM/dd/yyyy}';
  }

  public get dateWithTimeFormat(): string {
    return '{0:MM/dd/yyyy HH:mm a}';
  }

}

import { Component, ViewChild, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../app/core/decorators';
import { OrgLevel } from '../../../../app/state-model/models';
import { ScheduleCycle } from '../../../../app/organization';
import * as _ from 'lodash';
import { DetailScreenService } from '../../services';
import { DetailsPartner } from '../../models';
import { ISession } from '../../../../app/authentication/store';
import { AgencyMapping } from '../../../../app/configuration/models';
import { AgenciesApiService } from '../../../../app/configuration/services';
import { AgencyStaffingConsoleApiService } from '../../services/agency-staffing-console/agency-staffing-console-api.service';
import { AgencyShiftDetails } from '../../models/agency-staffing-console/agency-shift-details';
import { AgencyShiftSummary, AgencyShiftSummaryDetails } from '../../models/agency-staffing-console/agency-shift-short-details';
import * as jspdf from 'jspdf';
import autoTable from 'jspdf-autotable'
import { AgencyStaffingConsoleGridComponent } from './agency-staffing-console-grid/agency-staffing-console-grid.component';
import * as XLSX from 'xlsx'; 
 
@Component({
  selector: 'slx-agency-staffing-console',
  templateUrl: './agency-staffing-console.component.html',
  styleUrls: ['./agency-staffing-console.component.scss']
})
export class AgencyStaffingConsoleComponent implements OnInit {
  @ViewChild(AgencyStaffingConsoleGridComponent, { static: false }) private agencyGridComponent: AgencyStaffingConsoleGridComponent;
  public filters:any[]= [];
  public filterAgency:any[]=[];
  public customerId: string;
  public scheduleCycles: ScheduleCycle[];
  selectedScheduleCycle: ScheduleCycle;

  public shiftDetails:AgencyShiftDetails[]= [];
  public shiftSummary: AgencyShiftSummary[] =[];
 
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;
 
  @mutableSelect(['session'])
  public user$: Observable<ISession>;
  public isLoading:boolean
  public selectedOrgLevel: OrgLevel;
  private paramDate: moment.Moment;
  public alias: string;
  public agencyMap: any;
  public agencyListData: DetailsPartner[] = [];
  public agencyData: DetailsPartner;
  public filteredAgencyListData:DetailsPartner[]=[];
  public weekFilter=['Week','Day', 'Month'];
  public selectedValue = 'Week';
  public weekChanged:boolean;
  public selectedWeekFilter: string
  public filterAll:boolean = true;
  public exportRecords:AgencyShiftDetails[] =[];
  public filterEnabled:boolean= false;
  public leftGrid:boolean= false;
  public exportExcel:boolean= false;
  public multiSheetExcel:string[]= [];
  public selectCancelled:boolean= false;
  public selectAssigned:boolean= false;
  public selectPending:boolean= false;
  public selectPendingAction:boolean= false;
  public selectFailed:boolean = false;

  public isConsoleSummaryReady:boolean = false;
  public isConsoleDetailsReady:boolean = false;
  public processedPartner: string[] = [];
  public totalCount: number = 0;
  public shiftStateCount: AgencyShiftSummaryDetails[] = [];
  public selectedDate;
  public selectedStartWeek;
  public selectedEndWeek;
  public filterStartDate;
  public filterEndDate;
  @unsubscribe()
  private userSubscription: Subscription;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  shiftState: string[];

  constructor( private detailScreenService: DetailScreenService,
    private agenciesApiService: AgenciesApiService,
    private agencyConsoleApiService:AgencyStaffingConsoleApiService
  ) { 
  this.isLoading= true;
  this.weekChanged= true;
  this.selectedStartWeek = moment().weekday(0);
  this.selectedEndWeek = moment().weekday(6);
  this.filterStartDate = this.selectedStartWeek.format('MM-DD-YYYY');
  this.filterEndDate = this.selectedEndWeek.format('MM-DD-YYYY');
  }

  ngOnInit() {
    this.userSubscription = this.user$.subscribe((session: ISession) => {
      if (session) {
        this.alias = session.alias;
        this.customerId = session.alias;
      }
    });
    this.orgLevelSubscription = this.orgLevel$.subscribe((selectedOrgLevel: OrgLevel) => {
      this.orgLevel = selectedOrgLevel;
      // Update agency information
      this.getPartnerAgenciesPositions(this.orgLevel);
     
      // 2022-04-20
      // day - start and end date is same
      // weekly = weekly start and weekly end
      // month == month start and month end
      this.getShiftDetails(this.selectedStartWeek.format('YYYY-MM-DD'),this.selectedEndWeek.format('YYYY-MM-DD'));
      //this.getShiftDetails(startDate,endDate);
     
    }); 
  }      

  public async getPartnerAgenciesPositions( selectedOrgLevel : OrgLevel): Promise<void>  {
   this.isLoading= true;
    await this.agenciesApiService.getAgencies(selectedOrgLevel.id).then((mappings: AgencyMapping[]) => {
      this.agencyMap = mappings.reduce(function(map, obj) {
        map[obj.agency.id] = obj.agency.name;
        return map;
    }, {});
    this.agencyDetails(selectedOrgLevel).then((res) => {
      this.agencyListData =[];
      if ( res == null || res.length == 0 ) {
        return;
      }
  
      let filteredAgencies = res.filter((o: DetailsPartner) => o.status =='active' );
      if ( filteredAgencies == null || filteredAgencies.length == 0) {
         return;
      }     
      filteredAgencies.forEach((element) => {
        this.agencyData = element;
        this.agencyData.agency_name = this.agencyMap[this.agencyData.agency_id];
        this.agencyListData.push(this.agencyData);
        this.filteredAgencyListData= this.agencyListData;
      
      });
      this.filteredAgencyListData= this.agencyListData = this.filteredAgencyListData.sort((n1,n2) => {

        let agencyName1 = n1.agency_name;
        if ( agencyName1 != null ) {
          agencyName1 = agencyName1.trim();

        }
        let agencyName2 = n2.agency_name;
        if ( agencyName2 != null ) {
          agencyName2 = agencyName2.trim();
          
        }

        if (agencyName1 > agencyName2) {
            return 1;
        }
    
        if (agencyName1 < agencyName2) {
            return -1;
        }
    
        return 0;
      });
      this.isLoading=false;
   });
  
  });
  
  }

  agencyDetails(orgLevel: OrgLevel): Promise<any> {
    return this.detailScreenService.getAgencyDetails(orgLevel, this.customerId);
  }

  public filterChange(filter:string, event: any): void {
    this.filterEnabled= !this.filterEnabled;
    if(filter=='all') {
      this.filterAll= true; 
      this.selectCancelled= false;
      this.selectPendingAction= false;
      this.selectAssigned= false;
      this.selectPending= false;
      this.selectFailed= false;
      this.filters=[];
      this.filters[0]=[{'filterName': 'all', 'checked': true}]
    }
    else {
      this.filterAll= false;
      this.filters[this.filters.length]=[{'filterName': filter, 'checked':event.target.checked}]
    }
  }
 
  public agencyFilter(filter:any): void {
      this.filteredAgencyListData=[];
      filter.forEach(agencyId=>{      
       this.agencyListData.filter(agency=>{
         if(agency.agency_id==agencyId){
           this.filteredAgencyListData.push(agency);
         }})
      });
      if(this.filteredAgencyListData.length== 0) {
        this.filteredAgencyListData= this.agencyListData;
      }

    }
  public weekFilterChnaged(filter: any): void {

    if(filter== 'Day') {
     this.selectedWeekFilter= 'day'
    }
    else if(filter== 'Week') {
     this.selectedWeekFilter= 'week'
    }
    else if(filter== 'Month') {
      this.selectedWeekFilter= 'month'
    }
  }

  public selectAllRecords(value): void {
    this.filterAll=value
  }
  public selectedDateRange(range:any){
    let shiftStartDate= range.startDate.format('YYYY-MM-DD');
    let shiftEndDate= range.endDate.format('YYYY-MM-DD');
    this.filterStartDate = range.startDate.format('MMMM DD, YYYY');
    this.filterEndDate = range.endDate.format('MMMM DD, YYYY');
    this.getShiftDetails(shiftStartDate,shiftEndDate);
  }

  public exportToExcel() {
    
    this.getExportRecords(); 
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    this.filteredAgencyListData.forEach(agency => {

      this.prepareAgencyShiftSummary(agency.partner_id);
      let records = this.exportRecords.filter(record => record.partnerId == agency.partner_id);
      if(records.length == 0)
      {
        records.push(new AgencyShiftDetails());
      }
      if(agency.agency_name!=null){
        let rowsData = [];
        records.map(x => {
          rowsData.push({
            "State": x.state, 
            "Employee Name": x.employeeName, 
            "Shift Date": x.shiftDate ? moment(x.shiftDate).format('MM/DD/YYYY') : "", 
            "Shift": x.shift, 
            "Hours": x.hours, 
            "Position": x.position, 
            "Unit": x.unit, 
            "Attributes": x.attributes, 
            "Worked Hours": x.workedHours, 
            "Meal Break Duration": x.mealBreakDuration,
            "Cancellation Type": x.cancellationType, 
            "Cancellation Date": x.cancellationDate ? moment(x.cancellationDate).format('MM/DD/YYYY') : ""
          })
        });

        const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(rowsData);
        XLSX.utils.book_append_sheet(wb, ws, agency.agency_name.trim().length > 30 ? agency.agency_name.substring(0,30).trim() : agency.agency_name.trim());
      }
    });
  
    XLSX.writeFile(wb, 'AgencyStaffingConsole_'+this.filterStartDate+"-"+this.filterEndDate+'.xlsx');
  }
  public getExportRecords() {
    this.shiftState = ['Assigned', 'Pending', 'Pending Action', 'Cancelled', 'Failed']
    if(this.filterAll) {
      this.exportRecords = this.shiftDetails;

    }
    else  {
      let records:any;
      this.shiftState = [] ;
      this.exportRecords = [];
      this.filters.forEach(filter => {
       let state:boolean = false;
        if (filter[0].filterName == 'pending') {
         state = filter[0].checked;
         if(state) {
          records = this.shiftDetails.filter(el => el.state == "Pending");
          this.shiftState.push('Pending');               
           this.exportRecords = this.exportRecords.concat(records);
        }
         else {
          this.exportRecords =  this.exportRecords.filter(el => el.state != "Pending");
          this.shiftState = this.shiftState.filter(e =>e !='Pending');
         }
        }
        if (filter[0].filterName == 'assigned') {
          state = filter[0].checked;
         if(state) {
          records = (this.shiftDetails.filter(el => el.state == "Assigned"));
          this.shiftState.push('Assigned');
          this.exportRecords = this.exportRecords.concat(records);
        }
         else {
          this.exportRecords =  this.exportRecords.filter(el => el.state != "Assigned");
          this.shiftState = this.shiftState.filter(e =>e !='Assigned');
         }
        }
        if (filter[0].filterName == 'cancelled') {
          state = filter[0].checked;
          if(state) {
           records=  this.shiftDetails.filter(el => el.state == "Cancelled");
           this.shiftState.push('Cancelled')
            this.exportRecords = this.exportRecords.concat(records);
          }
          else {
            this.exportRecords =  this.exportRecords.filter(el => el.state != "Cancelled");
            this.shiftState = this.shiftState.filter(e =>e !='Cancelled');
           }
        }
        if (filter[0].filterName == 'requested') {
          state = filter[0].checked;
          if(state) {
           records = this.shiftDetails.filter(el => el.state == "Pending Action");
           this.shiftState.push('Pending Action');
            this.exportRecords = this.exportRecords.concat(records);
          }
          else {
            this.exportRecords =  this.exportRecords.filter(el => el.state != "Pending Action");
            this.shiftState = this.shiftState.filter(e => e != 'Pending Action');
           }
        }
        if(filter[0].filterName == 'failed'){
          state = filter[0].checked;
          if(state) {
           records = this.shiftDetails.filter(el => el.state == "Failed");
           this.shiftState.push('Failed');
            this.exportRecords = this.exportRecords.concat(records);
          }
          else {
            this.exportRecords =  this.exportRecords.filter(el => el.state != "Failed");
            this.shiftState = this.shiftState.filter(e => e != 'Failed');
           }
        }
      });
    }
  }

  public captureScreen(){
    this.getExportRecords(); 
    let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    let current = new Date();
    let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    let cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
    let dateTime = cDate + ' ' + cTime;
    let count: number = 0;
    
    pdf.setFontSize(10);
    pdf.text("Downloaded On: "+dateTime, 5, 5);

    pdf.setFontSize(10);
    pdf.text("Date Filter: "+this.filterStartDate+" - "+this.filterEndDate, 5, 15);

    this.filteredAgencyListData.forEach(agency => {
      if(agency.agency_name!=null){
        this.prepareAgencyShiftSummary(agency.partner_id);
        let records = this.exportRecords.filter(record => record.partnerId == agency.partner_id);
        if(count > 0)
        {
          pdf.addPage();
        }      

        pdf.setFontSize(14);
        pdf.text(agency.agency_name.trim(), 5, 25);

        pdf.setFontSize(14);
        pdf.text("Total Agency Staff: "+ this.totalCount, 5, 35);

        // *********** Prepare Partner Info Table ****************
        //prepare grid columns to be displayed
        var partnerCol = [];
        partnerCol.push(["State", "Count"]);
        var partnerRows = [];

        //prepare grid data
        this.shiftStateCount.filter(x => x.partnerId === agency.partner_id).forEach(item =>{
          var temp = [item.state, item.shiftCount];
          partnerRows.push(temp);
        });

        //prepare and add table to the pdf file
        autoTable(pdf, {
          startY: 40,
          theme: 'striped',
          pageBreak: 'auto',
          tableWidth: 'auto',
          margin: {top: 5, right:5, bottom: 5, left: 5},
          head: partnerCol,
          body: partnerRows
        })
        
        // ********* Prepare Shift Details Table ****************
        //prepare grid columns to be displayed
        var col = [];
        col.push(["State", "Employee Name", "Shift Date", "Shift", "Hours", "Position", "Unit", "Attributes", "Worked Hours", "Meal Break Duration", "Cancellation Type", "Cancellation Date"]);
        var rows = [];

        //prepare grid data
        records.forEach(item =>{
          var temp = [item.state, 
                      item.employeeName, 
                      item.shiftDate ? moment(item.shiftDate).format('MM/DD/YYYY') : "", 
                      item.shift, 
                      item.hours, 
                      item.position, 
                      item.unit, 
                      item.attributes, 
                      item.workedHours, 
                      item.mealBreakDuration, 
                      item.cancellationType, 
                      item.cancellationDate ? moment(item.cancellationDate).format('MM/DD/YYYY') : ""];
          rows.push(temp);
        });

        if(rows.length == 0)
        {
          rows.push([{ content: 'No records found.', colSpan: 12, styles: { halign: 'center' } }]);
        }

        //prepare and add table to the pdf file
        autoTable(pdf, {
          theme: 'grid',
          pageBreak: 'auto',
          tableWidth: 'auto',
          margin: {top: 10, right:5, bottom: 10, left: 5},
          styles: {overflow: 'linebreak', fontSize: 8},
          columnStyles: {
            0: {cellWidth: 18},
            1: {cellWidth: 20},
            2: {cellWidth: 20},
            3: {cellWidth: 22},
            4: {cellWidth: 12},
            5: {cellWidth: 10},
            6: {cellWidth: 10},
            7: {cellWidth: 20},
            8: {cellWidth: 14},
            9: {cellWidth: 14},
            10: {cellWidth: 20},
            11: {cellWidth: 20}
          },
          head: col,
          body: rows
        })
        count++;
      
    }
});

   pdf.save('AgencyStaffingConsole_'+this.filterStartDate+"-"+this.filterEndDate+'.pdf'); // Generated PDF
  }
  

  public getMultiSheetExcel(record) {
       
  
  } 
  public async getShiftDetails(shiftStartDate:string, shiftEndDate:string): Promise<void> {
    this.isConsoleDetailsReady = false;
    await this.agencyConsoleApiService.getShiftDetails(shiftStartDate,shiftEndDate).then((details)=>{
      this.shiftDetails=details;
      this.isConsoleDetailsReady = true;
    });

    this.isConsoleSummaryReady = false;
    await this.agencyConsoleApiService.getShiftSummary(shiftStartDate,shiftEndDate,this.customerId).then((shortDetails)=> {
      this.shiftSummary= shortDetails;
      this.isConsoleSummaryReady = true;
      
    });  
   
  }

  public prepareAgencyShiftSummary(partnerId) {
    let shiftState = this.shiftState
    this.shiftSummary.map(x => {
      if (x.partnerId === partnerId) {
        this.totalCount = x.totalCount;
        this.processedPartner.push(partnerId);
        this.shiftStateCount = [];
        shiftState.forEach(state => {
          let summary: AgencyShiftSummaryDetails = new AgencyShiftSummaryDetails();
          summary.partnerId = partnerId;
          summary.state = state;
          let data = x.agencyShiftCount.find(e => e.state == state);
          if (data !== null && data !== undefined) {
            summary.shiftCount = data.shiftCount;
          }
          else {
            summary.shiftCount = 0;
          }
          this.shiftStateCount.push(summary);
        });    
      
      }
      else {
        if (!this.processedPartner.includes(partnerId)) {
          this.shiftStateCount = [];
          this.totalCount = 0;
          shiftState.forEach(state => {
            let summary: AgencyShiftSummaryDetails = new AgencyShiftSummaryDetails();
            summary.partnerId = partnerId;
            summary.shiftCount = 0;
            summary.state = state

            this.shiftStateCount.push(summary);
          });
        }
      }
    });
  }

  
} 
 
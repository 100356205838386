import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription ,  Observable } from 'rxjs';

import { appConfig } from '../../../../../app.config';
import { unsubscribeAll, mutableSelect } from '../../../../../core/decorators/index';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import {
  BenefitDetailsProviderEntity,
  BenefitDetailsLine,
  BenefitDetailsEditModes,
  BenefitDetailsUserActions,
  BenefitDetailsCalcMethod,
  BenefitDetailsProviderLineStandartEntity,
  BenefitDetailsTier
} from '../../../models/index';
import { BenefitDetailsManagementService, BenefitDetailsStandartManagementService, BenefitDetailsPermissionService } from '../../../services/index';
import { OrgLevel } from '../../../../../state-model/models/index';

@Component({
  selector: 'slx-benefit-details-standart',
  templateUrl: './benefit-details-standart.component.html',
  styleUrls: ['./benefit-details-standart.component.scss'],
  providers: [BenefitDetailsStandartManagementService]
})

export class BenefitDetailsStandartComponent implements OnInit, OnDestroy {
  public valueIconClass : string ='fas fa-circle blueIconClass';
  public selectedBenefitLine: BenefitDetailsLine;
  public benefitLines: BenefitDetailsLine[] = [];
  public calcMethod: BenefitDetailsCalcMethod;
  public providerPlanDate: Date = null;
  public providerOrglevelName: string;
  public isEditMode = false;
  public canSave = false;
  public canEdit = false;
  public canDelete = false;
  public isShownFormula = true;
  public canAddCovOptions = false;
  public minEffectiveDate: Date;
  public maxEffectiveDate: Date;
  public updateCovOptionRatesMsg: string = 'Are you sure you want to change. This will update enrollment records of previously enrolled employees.';
  public updateCovFlatMsg:string = 'You are updating contribution value(s). Values for already enrolled employees will not be updated.';
  public updateCov401KMsg:string = 'You are updating value(s) under coverage options. Values for already enrolled employees will not be updated.';
  public appConfig = appConfig;

  public get isCreatingNew(): boolean {
    return _.isObjectLike(this.selectedProvider) && this.selectedProvider.isDraft;
  }

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private selectedProvider: BenefitDetailsProviderEntity;
  private selectedProviderLineId: number;
  private providers: BenefitDetailsProviderEntity[];


  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;

  constructor(
    private modalService: ModalService,
    private commonManService: BenefitDetailsManagementService,
    private permissionService: BenefitDetailsPermissionService,
    public manService: BenefitDetailsStandartManagementService
  ) {
  }

  public ngOnInit() {
    this.manService.init('BenefitDetailsStandartComponent');

    this.subscriptions.providers = this.commonManService
      .subscribeToLoadProviders((p: BenefitDetailsProviderEntity[]) => (this.providers = p));

    this.subscriptions.selectProvider = this.commonManService
      .subscribeToSelectProvider((p: BenefitDetailsProviderEntity) => {
        if (_.isObjectLike(p)) {
          this.selectedProvider = p;
          this.benefitLines = p.current.benefitLines || [];
          this.providerOrglevelName = p.current.orgLevelName || '';
        }
      });

    this.subscriptions.selectProviderLine = this.commonManService
      .subscribeToSelectProviderLine((pl: BenefitDetailsProviderLineStandartEntity) => {
        this.selectedBenefitLine = pl.current.line;
        this.selectedProviderLineId = pl.current.id;
        this.minEffectiveDate = pl.current.line.startDate;
        this.maxEffectiveDate = pl.current.line.endDate;
      });

    this.subscriptions.state = this.manService
      .subscribeToChangeState((v: boolean) => (this.isShownFormula = v));

    this.subscriptions.calcMethod = this.manService
      .subscribeToChangeCalcMethod((calcMethod: BenefitDetailsCalcMethod) => (this.calcMethod = calcMethod));

    this.subscriptions.providerPlanDate = this.manService
      .subscribeToChangeProviderPlanDate((d: Date) => {
        this.providerPlanDate = d;
      });

    this.subscriptions.editMode = this.permissionService
      .subscribeToEditMode((v: BenefitDetailsEditModes) => {
        this.isEditMode = v.providerInfo;
      });

    this.subscriptions.userActions = this.permissionService
      .subscribeToUserActions((v: BenefitDetailsUserActions) => {
        Promise.resolve()
          .then(() => {
            this.canSave = v.canSave;
            this.canEdit = v.canEdit;
            this.canDelete = v.canDelete;
            this.canAddCovOptions = v.canAddCovOptions;
          });
      });

      this.subscriptions.saveState = this.permissionService
      .subscribeToSaveState((canSave: boolean) => {
        Promise.resolve()
          .then(() => {
            this.canSave = canSave;
          });
      });

      this.subscribeToOrgLevel();
  }

  public subscribeToOrgLevel(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
      });
  }

  public ngOnDestroy(): void {
    this.manService.destroy();
  }

  public get hasProviders(): boolean {
    return _.isArray(this.providers) && _.size(this.providers) > 0;
  }

  public onChangeProviderPlanDate(): void {
    this.manService.changeProviderPlanDate(this.providerPlanDate);
  }

  public onChangeBenefitLine(pl: BenefitDetailsLine): void {
    if (_.isNumber(this.selectedProviderLineId) && _.isObjectLike(pl) && this.selectedProviderLineId !== pl.id) {
      this.selectedProviderLineId = pl.id;
      this.manService.selectProviderLine(pl);
    }
  }

  public onEdit(): void {
    this.manService.startEditingProvider();
  }

  public onCancel(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Discard Changes',
      'Are you sure you want to discard all changes?',
      this.modalService,
      (isCancel: boolean) => {
        if (isCancel) {
          if (this.selectedProvider.isDraft) {
            this.manService.removeDraftProvider();
            this.manService.duplicateValue=false;
          } else {
            this.manService.cancelEditingProvider();
          }
        }
      },
      options);
  }

  public async onSave(): Promise<void> {
    if (!this.selectedProvider.isDraft && this.manService.hasChangesInCovOptions()) {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      options.buttonOKtext = 'Yes';
      ConfirmDialog2Component.openDialog(
        'Save Confirmation',
        this.getTitle(),
        this.modalService,
        async (isSave: boolean) => {
          if (isSave) {
             await this.save();
          } else {
            this.manService.cancelEditingProvider();
          }
        },
        options);
    } else {
      await this.save();
    }
  }

  private async save(): Promise<void> {
    if (this.selectedProvider.isDraft) {
      await this.manService.saveProvider();
    } else {
      await this.manService.saveProviderLine();
    }
    this.manService.updateEmployees(true);
  }

  public onDelete(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Delete Benefit Plan',
      'Are you sure you want to delete this benefit? This will unenroll all the enrolled employees and dependents.',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.manService.deleteProvider();
        }
      },
      options);
  }

  public onChangeShowFormula(): void {
    this.manService.changeShownFormulaState(this.isShownFormula);
  }

  public onAdd() {
    this.manService.addNewCoverageOption();
  }

  public getTitle(): string {
    return this.calcMethod ?
      this.calcMethod.isOptionsRates ? this.updateCovOptionRatesMsg :
      this.calcMethod.isFlat ? this.updateCovFlatMsg :
      this.updateCov401KMsg : '';
  }
}

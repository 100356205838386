import * as _ from 'lodash';
import { Component, OnInit, Host, NgZone, Input, ViewChild } from '@angular/core';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { ModalService } from '../../../../../common/services';
import { EmployeeSectionsEmploymentApiService } from '../../../services';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { NgForm, AbstractControl } from '@angular/forms';
import { EmployeeSectionsBase } from '../../../models';
import { WcIncidentRosterContainer, WcIncidentRosterEntry } from '../../../../../app-modules/workers-compensation/models';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { unsubscribe } from '../../../../../core/decorators';
import { Subscription } from 'rxjs';
import { EmployeeIncidents, IEmployeeIncidents } from '../../../models/employee-sections-employment/employee-incidents';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { WCIncidentReportDialogComponent } from '../../../../../app-modules/workers-compensation/components/crud';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-workers-comp',
  templateUrl: 'employee-sections-workers-comp.component.html',
  styleUrls: ['employee-sections-workers-comp.component.scss']
})
export class EmployeeSectionsWorkersCompComponent extends EmployeeSectionsBasicComponent implements OnInit {

  public typeFilters: any[];

  @Input()
  public set workersComp(c: EmployeeIncidents) {
    this.m_workersComp = c;
    if (this.m_workersComp) {
      this.generateFilters(this.m_workersComp.records);
    }
    this.refreshGrid();
  }

  public get workersComp(): EmployeeIncidents {
    return this.m_workersComp;
  }

  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }

  @Input()
  public set employeeId(value: number) {
    this.m_employeeId = value;
  }

  public get employeeId(): number {
    return this.m_employeeId;
  }

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<WcIncidentRosterEntry>;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public pageSize: number = 5;
  public paceholderlink:any="-------------------";

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild('gridForm', { static: true })
  private ngFormChild: NgForm;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  private m_employeeId: number;
  private m_workersComp: EmployeeIncidents;

  constructor (
    private modalService: ModalService,
    private apiService: EmployeeSectionsEmploymentApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone
  ) {
    super(decorator, ngZone);
    this.appConfig = appConfig;
    decorator.isEditableByConfiguration = false;

    this.gridState = new KendoGridStateHelper<WcIncidentRosterEntry>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'reportDateTime', dir: 'desc' }];

  }

  public ngOnInit(): void {
    this.createSubscriptions();
  }

  public createSubscriptions(): void {

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.m_workersComp;
  }

  public onStartAdd(): void {
    this.openNewReportDialog();
  }

  public onSelectItem(item: WcIncidentRosterEntry): void {
    this.openEditReportDialog(item.id);
  }

  public pageChanged(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  protected openNewReportDialog(): void {
    WCIncidentReportDialogComponent.openDialog({ reportId: null, employeeId: this.m_employeeId }, this.modalService, (result: boolean) => {
      this.loadSubsection();
    });
  }

  protected openEditReportDialog(reportId: number): void {
    WCIncidentReportDialogComponent.openDialog({ reportId: reportId, employeeId: null }, this.modalService, (result: boolean) => {
      this.loadSubsection();
    });
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.apiService.getEmployeeWrokersCompensation(this.employeeId)
      .then((subsectionData: EmployeeIncidents) => {
        this.workersComp = subsectionData;
        this.refreshGrid();
        this.stopProgress();
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  private generateFilters(records: WcIncidentRosterEntry[]): void {
    this.typeFilters = this.generateTypeFilters();
  }

  private generateTypeFilters (): any {
    return [
      {
        text: 'Incident',
        value: 0
      },
      {
        text: 'Incident/OSHA',
        value: 1
      },
      {
        text: 'Incident/WC',
        value: 2
      },
      {
        text: 'Incident/OSHA/WC',
        value: 1|2
      }
    ]
  }

  private refreshGrid(): void {
    if (!this.m_workersComp) {
      this.gridView = null;
      return;
    }

    this.gridState.view = process(this.m_workersComp.records, this.gridState.state);
  }



}

<slx-spinner [show]="isShownSpinner">
  <div class="dep-header">
    <span class="dep-header__date">
      <slx-datepicker #sDate="ngModel"
        class="slx-wide"
        [minDate]="minStartDate"
        [maxDate]="endDate"
        [(ngModel)]="startDate"
        (ngModelChange)="onChangeStartDate()"
        [acceptNullDate]="false"
        [emitValueAnyway]="true"
        [readonly]="isDisabledDates"
        placeholder="Benefit Start Date"
        name="startDate"
      ></slx-datepicker>
      <span *ngIf="sDate.errors" class="slx-error-block error-box">
        <span *ngIf="sDate.errors.required" errorMessage for="required"></span>
        <span *ngIf="sDate.errors.date" errorMessage>Date is out of valid range</span>
        <span *ngIf="sDate.errors.minDate" errorMessage>The selected date is not valid for one or more plans selected</span>
        <span *ngIf="sDate.errors.maxDate" errorMessage>The selected date is not valid for one or more plans selected</span>
      </span>
      <span *ngIf="!sDate.errors && benefitsError && isSDateChanged" class="slx-error-block error-box">
        Selected Benefit Plans do not have common dates.<br> Please select plans that have common start and end dates.
      </span>
      <span *ngIf="!sDate.errors && !benefitsError && dependentsError && isSDateChanged" class="slx-error-block error-box">
        At least one of the selected dependents is not eligible for <br> the selected start date of coverage due to the date of birth - {{ latestDepDOB | amDateFormat: appConfig.dateFormat }}
      </span>
    </span>
    <span class="dep-header__date">
      <slx-datepicker #eDate="ngModel"
        class="slx-wide"
        [minDate]="startDate"
        [maxDate]="maxEndDate"
        [(ngModel)]="endDate"
        (ngModelChange)="onChangeEndDate()"
        [acceptNullDate]="false"
        [emitValueAnyway]="true"
        [readonly]="isDisabledDates"
        placeholder="Benefit End Date"
        name="endDate"
      ></slx-datepicker>
      <span *ngIf="eDate.errors" class="slx-error-block error-box">
        <span *ngIf="eDate.errors.required" errorMessage for="required"></span>
        <span *ngIf="eDate.errors.date" errorMessage>Date is out of valid range</span>
        <span *ngIf="eDate.errors.minDate" errorMessage>The selected date is not valid for one or more plans selected</span>
        <span *ngIf="eDate.errors.maxDate" errorMessage>The selected date is not valid for one or more plans selected</span>
      </span>
      <span *ngIf="!eDate.errors && benefitsError && isEDateChanged" class="slx-error-block error-box">
        Selected Benefit Plans do not have common dates.<br> Please select plans that have common start and end dates.
      </span>
      <span *ngIf="!eDate.errors && !benefitsError && dependentsError && isEDateChanged" class="slx-error-block error-box">
        At least one of the selected dependents is not eligible for <br> the selected start date of coverage due to the date of birth - {{ latestDepDOB | amDateFormat: appConfig.dateFormat }}
      </span>
    </span>
  </div>
  <p *ngIf="isCheckSelected && benefitsError" class="slx-error-block error-box">Selected Benefit Plans do not have common dates. Please select plans that have common start and end dates.</p>
  <p *ngIf="isCheckSelected && !benefitsError && dependentsError" class="slx-error-block error-box"> At least one of the selected dependents is not eligible for the selected start date of coverage due to the date of birth - {{ latestDepDOB | amDateFormat: appConfig.dateFormat }}</p>
  <kendo-grid class="benefits-grid"
    [data]="gridState.view"
    [sortable]="{ mode: 'multiple' }"
    [sort]="gridState.state.sort"
    [filterable]="'menu'"
    [filter]="gridState.state.filter"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="gridState.state.skip"

    (dataStateChange)="gridState.dataStateChange($event)"
  >
    <ng-template kendoGridNoRecordsTemplate>
      <ng-container *ngIf="isMultipleDependents">There are no eligible benefits to add the selected dependents to</ng-container>
      <ng-container *ngIf="!isMultipleDependents">There are no eligible benefits to add the selected dependent to</ng-container>
    </ng-template>

    <kendo-grid-checkbox-column [width]="44">
      <ng-template kendoGridHeaderTemplate>
        <input type="checkbox" class="k-checkbox checkbox-employee checkbox-header-center" [disabled]="isDisabledSelection" [checked]="isCheckedAll" (change)="onSelectAll()">
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <input type="checkbox" class="k-checkbox checkbox-employee" [disabled]="isDisabledSelection" [checked]="isCheckedEmp(dataItem)" (change)="onSelectSingle(dataItem)">
      </ng-template>
    </kendo-grid-checkbox-column>

    <kendo-grid-column field="benefitName">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Benefit</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.benefitName }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="empBenefitStartDate" filter="date">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Benefit Start Date</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.empBenefitStartDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="empBenefitEndDate" filter="date">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Benefit End Date</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.empBenefitEndDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="benefitOption.type">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Benefit Option</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.benefitOption?.code }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-messages filterMenuTitle="Filter">
    </kendo-grid-messages>  
  </kendo-grid>
</slx-spinner>


import * as _ from 'lodash';
import { moment } from '../../../../common';

import { Observable, Subject, Subscription } from 'rxjs';
import { combineLatest, debounceTime, first } from 'rxjs/operators';
import { Lookup, LookupType } from './../../../../organization/models/lookup/lookup-definition';
import { LookupService } from './../../../../organization/services/lookup/lookup.service';
import { ISchedulePosition } from './../../../models/master-schedule/ischedule-position';
import { BudgetedParsPopupComponent } from './../../budgeted-pars/budgeted-pars-popup.component';

import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { CensusEntriesPopupComponent } from '../../census-entries-popup/census-entries-popup.component';
import { DailyUnitAssignmentNavigationService, ModalService, ScheduleEntryNavigationService } from './../../../../common/services/index';
import { hasClass, isChildOf } from './../../../../common/utils/index';
import { SidebarActions } from './../../../../portal/actions/sidebar.actions';
import { CensusSaveRequest } from './../../../models/census';
import { MasterScheduleContextMenuRequest } from './../../../models/master-schedule/master-schedule-context-menu-request';

import { CellEvent, Column, GridOptions, GridReadyEvent, RowNode, SelectionChangedEvent, SortChangedEvent } from 'ag-grid-community';
import { ScheduleDailyDetailsDialogComponent, ScheduleDailyDifferencesDialogComponent } from '../../schedule-daily-details/index';
import { MasterScheduleDayInfoDialogComponent } from '../master-schedule-day-info-dialog/master-schedule-day-info-dialog.component';
import { EmployeeSectionNavigationService } from './../../../../common/services/navigation/employee-section-navigation.service';
import { MasterScheduleActions } from './../../../store/master-schedule/master-schedule.actions';
import { MasterScheduleOptionsDialogComponent } from './master-schedule-options-dialog.component';


import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';




import { GenerateScheduleSummary, IScheduleActions, ScheduleActions, ScheduleCycleSummaryViewAction } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';

import { appConfig, IApplicationConfig } from '../../../../app.config';

import { LookupApiService } from '../../../../organization/services/index';

import { EmployeeGridData, IMasterScheduleEntryRow, IMasterScheduleRow, IMasterScheduleSubtotalRow, IMasterScheduleTotalRow, isMasterScheduleEntryRow, isMasterScheduleSubtotalRow, isMasterScheduleTotalRow, MasterScheduleData, MasterScheduleDayInfo, MasterScheduleEmployeeChange, MasterScheduleEntryCell, MasterScheduleEntryRow, MasterScheduleFilters, MasterScheduleRecordType, MasterScheduleSettings, MasterScheduleTotalCell, ScheduleDailyDetailsRequest, ScheduleDailyDifferencesRequest, ScheduleEntryEditItem, TotalsDescription } from '../../../../scheduler/models/index';

import { ActivatedRoute, Router } from '@angular/router';
import { TOOLBAR_SERVICE } from '../../../../core/services/index';
import { ScheduleCycleSummaryDialogComponent } from '../../../../organization/components/schedule-cycle-summary/index';
import { IQuickEditStatusEvent, MasterScheduleManagementService, MasterScheduleQuickEditService, MasterScheduleToolbarService } from '../../../services/index';
import { IMasterSchedulePosition } from '../../../store/master-schedule/master-schedule.types';
import { EmployeesStateSelector } from '../../../store/selectors/index';
import { MasterScheduleGridHelper } from './master-schedule-grid-helper';

import { PopperContent, PopperController } from 'ngx-popper';
import { MasterScheduleGroupingType, MasterScheduleGroupingTypes } from '../../../models/master-schedule/master-schedule-grouping-type';
import { ViewIndividualScheduleDialogComponent } from '../view-individual-schedule-dialog/view-individual-schedule-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'slx-master-schedule-grid',
  templateUrl: 'master-schedule-grid.component.html',
  styleUrls: ['master-schedule-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MasterScheduleGridComponent implements OnInit, OnDestroy, AfterViewInit {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['masterSchedule', 'scrollPositions'])
  public scrollPositions$: Observable<StringMap<IMasterSchedulePosition>>;

  @mutableSelect(['masterSchedule', 'selectedRow'])
  public selectedRow$: Observable<string>;

  @ViewChild('censusPopperConent', { static: true })
  public censusPopperConent: PopperContent;

  //Cannot have multiple query decorators on the same class member
  //@ViewChild('popperInitiator', { static: true })
  @ViewChild(PopperController, { static: true })
  public set popperInitiator(directive: PopperController) {
    this.m_popperInitiator = directive;
  }

  public datesRange: moment.Moment[];

  public showGrid: boolean;
  public rowData: any[];
  public rowCount: string;
  public footerData: any[];

  public gridScrollElement: HTMLElement;
  public gridHelper: MasterScheduleGridHelper;

  public appConfig: IApplicationConfig;

  public gridOptions: GridOptions;

  public censusPopperStyles: any;

  public actions: ScheduleActions;

  public censusCellElement: HTMLElement;
  public selectedCensusDate: Date;
  @ViewChild('censusWindow')
  public censusWindow: CensusEntriesPopupComponent;

  private m_popperInitiator: PopperController;
  private popperMobileStyles: any = {

  };
  private popperDesktopStyles: any = {
  };

  private errorMessage: string;
  private scrollTop: number;
  private scrollLeft: number;
  private restoreScroll: boolean;
  private selectedRowNode: RowNode;

  @unsubscribe()
  private censusSavedSubscription: Subscription;
  @unsubscribe()
  private censusCancelSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private loadedTotalsSubscription: Subscription;
  @unsubscribe()
  private filtersSubscription: Subscription;
  @unsubscribe()
  private settingsSubscription: Subscription;
  @unsubscribe()
  private selectedRowSubscription: Subscription;
  @unsubscribe()
  private quickEditActiveSubscription: Subscription;
  @unsubscribe()
  private quickEditUndoSubscription: Subscription;
  @unsubscribe()
  private actionsSubscription: Subscription;
  @unsubscribe()
  private filtersRestoredSubscription: Subscription;

  private m_footerData: any[];
  private hideSubtotals: boolean = false;
  private changeDetector: ChangeDetectorRef;
  private masterScheduleActions: MasterScheduleActions;
  private managementService: MasterScheduleManagementService;
  private internalData: MasterScheduleData;
  private element: ElementRef;
  private lookupApiService: LookupApiService;
  private masterScheduleEmployeeChange: MasterScheduleEmployeeChange;
  private router: Router;
  private route: ActivatedRoute;
  private modalService: ModalService;
  private censusRowElement: HTMLElement;
  private selectedCell: MasterScheduleTotalCell;
  private isQuickEditStarted: boolean;
  private isQuickEditConfigurationError: boolean;
  private gridReady$: Subject<GridReadyEvent>;
  private dataApplied$: Subject<any>;

  constructor(modalService: ModalService,
    changeDetector: ChangeDetectorRef,
    lookupApiService: LookupApiService,
    masterScheduleActions: MasterScheduleActions,
    managementService: MasterScheduleManagementService,
    element: ElementRef, router: Router, route: ActivatedRoute,
    private sidebarActions: SidebarActions,
    @Inject(TOOLBAR_SERVICE) private masterScheduleToolbarService: MasterScheduleToolbarService,
    private masterScheduleQuickEditService: MasterScheduleQuickEditService,
    lookupService: LookupService
  ) {
    this.gridReady$ = new Subject();
    this.dataApplied$ = new Subject();
    this.modalService = modalService;
    this.changeDetector = changeDetector;
    this.managementService = managementService;
    this.lookupApiService = lookupApiService;
    this.masterScheduleActions = masterScheduleActions;
    this.element = element;
    this.router = router;
    this.route = route;
    this.gridHelper = new MasterScheduleGridHelper();
    this.gridHelper.state = {
      startDate: null,
      endDate: null,
      numWeeks: 0,
      isEmployeeScheduleChangedEvent: false,
      cellDisplay: 'unit',
      positionGroupColShown: true,
      positionColShown: true,
      dateOfHireColShown: true,
      dateTerminatedColShown: false,
      shiftColShown: true,
      sumShiftsPerWeek: false,
      avgWeeklyRotationsHrs: false,
      displayOrder: 'name',
      separateSeconaryPositions: false,
      pinTotalRows: true,
      weeklyTotals: true,
      positionSubtotals: true,
      dailyTotals: true,
      idealSchedule: true,
      openShifts: true,
      difference: true,
      census: true,
      scheduledPpd: true,
      staffingRatio: true,
      actualHours: true,
      actualPpd: true,
      budgetedPars: true,
      viewTotalsFTEs: false,
      positionGrouping: MasterScheduleGroupingTypes.ByPosition,
      employeeTypeColShown: true
    };
    this.gridOptions = <GridOptions>{};
    this.gridOptions.pinnedBottomRowData = [];
    this.selectedRowNode = undefined;
    //this.gridOptions.rowHeight = 30;
    this.gridOptions.getRowClass = (params: any) => {
      if (isMasterScheduleSubtotalRow(params.data)) {
        return 'subtotal-row';
      }
      return null;
    };
    this.gridOptions.getRowStyle = (params: any) => this.gridHelper.getRowStyle(params);
    this.gridOptions.groupHeaderHeight = 50;
    this.quickEditActiveSubscription = this.masterScheduleQuickEditService.quickEditStatusChanged$.subscribe((event: IQuickEditStatusEvent) => {
      this.isQuickEditStarted = event.isStarted;
      this.isQuickEditConfigurationError = event.isConfigurationError;
    });
    this.quickEditUndoSubscription = this.masterScheduleQuickEditService.quickEditUndo$.subscribe((items: ScheduleEntryEditItem[]) => {
      if (this.internalData) {
        _.forEach(items, (item: ScheduleEntryEditItem) => {
          const row = _.find(this.internalData.employees, (row: IMasterScheduleEntryRow) => {
            return row.id === item.employeeId;
          });
          if (!row) {
            return;
          }
          let dateOn: string = EmployeesStateSelector.getDateKey(item.date);
          row.cell[dateOn] = item.cellForUndo;
        });
        this.applyGrid(this.internalData);
      }
    });
    this.gridOptions.onGridReady = ((event: GridReadyEvent): void => {
      this.gridReady$.next(event);
    });

    lookupService.getLookup({lookupType: LookupType.empType})
      .then((value: Lookup<any>)=> {
        this.gridHelper.empTypesMap = _.keyBy(value.items, value.valueField);
        if (this.gridOptions.api) {
          this.gridOptions.api.redrawRows();
        }
      });
  }

  public ngAfterViewInit(): void {

    this.settingsSubscription = this.masterScheduleToolbarService.onSettingsChanged$.subscribe((settings: MasterScheduleSettings) => {
      let groupingChanged = false;
      this.gridHelper.state.positionGroupColShown = settings.columns.columnsMap['positionGroupName'].displayed;
      this.gridHelper.state.positionColShown = settings.columns.columnsMap['position'].displayed;
      this.gridHelper.state.dateOfHireColShown = settings.columns.columnsMap['dateOfHire'].displayed;
      this.gridHelper.state.dateTerminatedColShown = settings.columns.columnsMap['dateTerminated'].displayed;
      this.gridHelper.state.avgWeeklyRotationsHrs = settings.columns.columnsMap['avgWeeklyRotationsHrs'].displayed;
      this.gridHelper.state.shiftColShown = settings.columns.columnsMap['shift'].displayed;
      this.gridHelper.state.employeeTypeColShown = settings.columns.columnsMap['employeeType'].displayed;
      this.gridHelper.state.cellDisplay = settings.display.cellDisplay;
      this.gridHelper.state.displayOrder = settings.display.displayOrder;

      this.gridHelper.state.separateSeconaryPositions = settings.display.separateSeconaryPositions;
      this.gridHelper.state.pinTotalRows = settings.display.pinTotalRows;

      if (this.gridHelper.state.positionGrouping.id !== settings.display.positionGrouping.id) {
        groupingChanged = true;
      }

      this.gridHelper.state.positionGrouping = settings.display.positionGrouping;

      this.gridHelper.state.weeklyTotals = settings.totals.weeklyTotals;
      this.gridHelper.state.positionSubtotals = settings.totals.positionSubtotals;
      this.gridHelper.state.dailyTotals = settings.totals.dailyTotals;
      this.gridHelper.state.idealSchedule = settings.totals.idealSchedule;
      this.gridHelper.state.openShifts = settings.totals.openShifts;
      this.gridHelper.state.difference = settings.totals.difference;
      this.gridHelper.state.census = settings.totals.census;
      this.gridHelper.state.scheduledPpd = settings.totals.scheduledPpd;
      this.gridHelper.state.staffingRatio = settings.totals.staffingRatio;
      this.gridHelper.state.actualHours = settings.totals.actualHours;
      this.gridHelper.state.actualPpd = settings.totals.actualPpd;
      this.gridHelper.state.budgetedPars = settings.totals.budgetedPars;
      this.gridHelper.state.viewTotalsFTEs = settings.totals.viewTotalsFTEs;

      this.setColumnsState();
      this.gridOptions.isExternalFilterPresent = () => { return true; };
      this.gridOptions.doesExternalFilterPass = (node: any) => {
        if (!this.hideSubtotals) {
          return true;
        }
        if (isMasterScheduleSubtotalRow(node.data)) {
          return false;
        }
        return true;
      };
      if (this.internalData) {
        if (!groupingChanged) {
          this.applyGrid(this.internalData);
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }
    });

    this.selectedRowSubscription = this.selectedRow$.pipe(first()).subscribe((row: string) => {
      if (!this.gridOptions.api || row === null || row === undefined) {
        return;
      }
      this.selectedRowNode = this.gridOptions.api.getRowNode(row);
      if (this.selectedRowNode) {
        this.selectedRowNode.setSelected(true, true, true);
      }
    });

    this.filtersRestoredSubscription = this.managementService.filtersRestored$.pipe(
      combineLatest(this.gridReady$),
      combineLatest(this.dataApplied$),
      debounceTime(500),)
      .subscribe(([[filters, gridEvent], dataApplied]: [[MasterScheduleFilters, GridReadyEvent], any]): void => {
        if (filters.selectedCell) {
          const rowId = this.getRowIdByEmpId(filters.selectedCell.empId);
          this.gridOptions.api.ensureIndexVisible(rowId, 'middle');
          this.gridOptions.api.ensureColumnVisible(filters.selectedCell.columnId);
          this.makeRowSelected(rowId);
        }
      });
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.filtersSubscription = this.managementService.filtersChanged$.subscribe((filters: MasterScheduleFilters) => {
      let startDate: Date = filters.dateFrom;
      let endDate: Date = moment(startDate).add(filters.weekNumber, 'weeks').subtract(1, 'day').toDate();
      let datesRange = moment.range(startDate, endDate);
      this.datesRange = Array.from(datesRange.by('days'));
      this.gridHelper.state.startDate = startDate;
      this.gridHelper.state.endDate = endDate;
      this.gridHelper.state.numWeeks = filters.weekNumber;
      this.gridHelper.createColumnDefs(filters);
      this.showGrid = true;
    });

    this.loadedSubscription = this.managementService.onLoaded$.subscribe((data: MasterScheduleData) => {
      this.internalData = data;
      this.applyGrid(this.internalData);
    });

    this.loadedTotalsSubscription = this.managementService.onTotalsLoaded$.subscribe((data: MasterScheduleData) => {
      this.internalData = data;
      this.applyGrid(this.internalData, true);
    });

    this.actionsSubscription = this.managementService.actions$.subscribe((actions: IScheduleActions) => {
      this.actions = actions;
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }


  public showDailyDetails(date: Date, position: ISchedulePosition): void {
    let req: ScheduleDailyDetailsRequest = new ScheduleDailyDetailsRequest();
    req.dateOn = date;
    req.orgLevelId = this.managementService.currentOrgLevelId;
    const ids = this.managementService.currentFilters.filters.getIds();
    if (position) {
      if (position.id > 0) {
        req.positionId  = position.id;
      } else {
        req.positionGroupId = position.positionGroupId;
      }
    } else {
      req.positionId = ids.positionId;
    }
    req.shiftGroupId = ids.shiftGroupId;
    req.unitId = ids.unitId;
    ScheduleDailyDetailsDialogComponent.openDialog(req, this.modalService, (goDua: boolean) => {
      if (goDua) {
        let navigateService = new DailyUnitAssignmentNavigationService(this.router, this.route);
        navigateService.navigateToDailyUnitAssignmentDate(date, [req.positionId], undefined, true);
      }
    });
  }

  public showDailyDifferences(date: Date): void {
    let req: ScheduleDailyDifferencesRequest = new ScheduleDailyDifferencesRequest();
    req.dateOn = date;
    req.orgLevelId = this.managementService.currentOrgLevelId;
    const ids = this.managementService.currentFilters.filters.getIds();
    req.positionId = ids.positionId;
    req.shiftGroupId = ids.shiftGroupId;
    req.unitId = ids.unitId;
    req.positionGroupId = ids.positionGroupId;
    ScheduleDailyDifferencesDialogComponent.openDialog(req, this.modalService, (goDua: boolean) => {
      if (goDua) {
        let navigateService = new DailyUnitAssignmentNavigationService(this.router, this.route);
        navigateService.navigateToDailyUnitAssignmentDate(date, undefined, undefined, true);
      }
    });
  }

  public onSortChanged($event: SortChangedEvent): void {
    let prevValue = this.hideSubtotals;
/*     let sortState = $event.api.getSortModel();
    if (sortState.length !== 0) {
      this.hideSubtotals = true;
    } else {
      this.hideSubtotals = false;
    } */

    const value = $event.columnApi.getColumnState().find(s => s.sort != null)
    if (value) {
      this.hideSubtotals = true;
    } else {
        this.hideSubtotals = false;
    }

    if (prevValue !== this.hideSubtotals) {
      this.gridOptions.api.onFilterChanged();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }

  public onCellClicked($event: CellEvent): void {

    if (isMasterScheduleSubtotalRow($event.node.data) && this.gridHelper.isDayColumn($event.column.getColDef().field)) {
      let subtotalRow: IMasterScheduleSubtotalRow = $event.node.data;
      let date = this.gridHelper.getDayDate($event.column.getColDef().field);
      this.showDailyDetails(date, subtotalRow.position);
      return;
    }
    if (isMasterScheduleTotalRow($event.node.data) && this.gridHelper.isDayColumn($event.column.getColDef().field)) {
      let totalRow: IMasterScheduleTotalRow = $event.node.data;
      if (totalRow.name === TotalsDescription.totalTotals) {
        let date = this.gridHelper.getDayDate($event.column.getColDef().field);
        this.showDailyDetails(date, undefined);
        return;
      }

      if (totalRow.name === TotalsDescription.totalDifference || totalRow.name === TotalsDescription.totalOpenShifts || totalRow.name === TotalsDescription.totalPARLevels) {
        let date = this.gridHelper.getDayDate($event.column.getColDef().field);
        this.showDailyDifferences(date);
        return;
      }

      if (totalRow.name === TotalsDescription.totalCensus) {

        if (this.censusWindow && this.censusWindow.existsUnsavedData()) return;
        this.selectedCell = <MasterScheduleTotalCell>this.gridHelper.getCell($event);

        if (!this.selectedCell) {
          if (this.censusWindow) this.censusWindow.closeWindow();
          return;
        }
        this.sidebarActions.setRightSidebar(false);
        this.saveCensusElements(<any>$event.event.target);
        this.selectedCensusDate = this.gridHelper.getDayDate($event.column.getColDef().field);
        this.showCensusWindow();

        return;
      }

      if (totalRow.name === TotalsDescription.totalBudgetedPars) {
        const date = this.gridHelper.getDayDate($event.column.getColDef().field);
        const dialog: BudgetedParsPopupComponent = BudgetedParsPopupComponent.openDialog(
          this.modalService,
          this.managementService,
          (result: boolean) => { }
        );
        dialog.setDate(date);
        return;
      }

    }

    let empId: string = $event.node.data.id;
    if (empId !== null && empId !== undefined)
      if (parseInt(empId) === 0) {
        return;
      }

    if ($event.column.getColDef().field === 'name') {
      const parsedEmpId = parseInt(empId, 10);
      let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
      this.managementService.selectEmployeeCell({ empId: parsedEmpId, rowId: $event.rowIndex, columnId: $event.colDef.field });
      navService.NavigateToEmployeeSections(parsedEmpId, false);
    } else {
      if ($event.column.getColDef().field === 'actions') {
        let request: MasterScheduleContextMenuRequest = new MasterScheduleContextMenuRequest();
        request.empId = empId;
        request.empName = $event.node.data.name;
        request.startDate = this.gridHelper.state.startDate;
        request.endDate = this.gridHelper.state.endDate;
        request.showGenerateSchedule = true;
        request.isFromDifferntDepartment = $event.node.data.isFromDifferntDepartment;
        request.isPosted = $event.node.data.isPosted;
        request.isStartCycleDate = this.managementService.isStartCycle(request.startDate);
        request.hasRotations = $event.node.data.hasRotations;
        request.scheduleActions = this.actions;
        request.shiftsPerWeek =  $event.node.data.sumShiftsPerWeek;
        request.rowData = $event.node.data;
        MasterScheduleOptionsDialogComponent.openDialog(request, this.modalService, (result: boolean, cmd: string) => {
          if (result && cmd) {
            if (cmd === 'IndividualSchedule') {
              ViewIndividualScheduleDialogComponent.showDialog(this.modalService, request, (hasChanges: boolean) => {
                this.managementService.reloadIndividualSchedule(hasChanges);
              });
            } else if (cmd === 'GenerateSchedule') {
              this.managementService.generateEmpSchedule(parseInt(empId), this.gridHelper.state.startDate, this.gridHelper.state.endDate)
                .then((result: GenerateScheduleSummary) => {
                  if (result && result.messages && result.messages.length > 0) {
                    this.showSummary(result);
                  }
                });
            } else if (cmd === 'DeleteSchedule') {
              this.managementService.deleteScheduleForEmployee(this.managementService.currentOrgLevelId, +empId, this.gridHelper.state.startDate, this.gridHelper.state.endDate);
            } else if (cmd === 'CreateRotationFromSchedule') {
              this.managementService.createRotationFromSchedule(this.managementService.currentOrgLevelId, +empId, request.selectedCommandPayload.startDate, request.selectedCommandPayload.weeks);
            }
            return;
          }
        });
      } else {
        if (this.gridHelper.isDayColumn($event.column.getColDef().field)) {
          let date = this.gridHelper.getDayDate($event.column.getColDef().field);
          let ev: Event = $event.event;
          let el: HTMLElement = <HTMLElement>ev.target;
          let day: string = EmployeesStateSelector.getDateKey(date);
          if (_.includes(el.className, 'ms-badge') || _.includes(el.className, 'ms-badge-tooltip')) {
            let req = new MasterScheduleDayInfo();
            req.dateOn = date;
            req.entryRow = $event.node.data;
            req.dayCell = req.entryRow.cell[day];
            MasterScheduleDayInfoDialogComponent.openDialog(req, this.modalService, (result: boolean, cmd: any) => {
              return;
            });
          } else {
            if (this.isQuickEditStarted) {
              if (this.isQuickEditConfigurationError) {
                return;
              }
              const row: MasterScheduleEntryRow = $event.node.data;
              if (!row.cell[day]) {
                row.cell[day] = new MasterScheduleEntryCell();
                row.cell[day].dateOn = date;
                row.cell[day].shiftsInfo = [];
              }
              const cell: MasterScheduleEntryCell = row.cell[day];
              if (cell.isInQuickEdit) {
                if (hasClass($event.event.srcElement, 'quick-edit-undo-button') || isChildOf($event.event.srcElement, 'quick-edit-undo-button')) {
                  this.masterScheduleQuickEditService.undoEditCommandFromId(row.id, cell.dateOn);
                  return;
                }
              }
              this.masterScheduleQuickEditService.quickEditCommand(this.modalService, row, cell).then(() => {
                $event.node.setData(row);
              });
              return;
            }
            const parsedEmpId = parseInt(empId, 10);
            let entryService: ScheduleEntryNavigationService = new ScheduleEntryNavigationService(this.router, this.route);
            this.managementService.selectEmployeeCell({ empId: parsedEmpId, rowId: $event.rowIndex, columnId: $event.colDef.field });
            entryService.NavigateToScheduleEntry(parsedEmpId, date);
          }
        }
      }
    }
  }

  public onExport(): void {
    let params: any = {
      skipHeader: false,
      columnGroups: true,
      skipFooters: false,
      skipGroups: false,
      skipFloatingTop: false,
      skipFloatingBottom: false,
      allColumns: false,
      onlySelected: false,
      suppressQuotes: false,
      fileName: 'Master Schedule Export.xls',
      columnSeparator: ',',
      processHeaderCallback: (params: any) => {

        if (params.column && params.column.colDef) {
          let val: string = params.column.colDef.headerName;
          if (val && val.indexOf('main-date-header-text') !== -1) {
            // strip html tags out from week days
            return val.replace(/<[^>]*>/g, '');
          }
          return val;
        }
        return '';
      },
      processCellCallback: (params: any) => {
        let colId = params.column.getColDef().field;
        if (!this.gridHelper.isDayColumn(colId)) {
          if (_.isNumber(params.value)) {
            return params.value ? params.value.toFixed(4) : '0';
          }
          if (colId === 'position' && params.value && (isMasterScheduleEntryRow(params.node.data) || isMasterScheduleSubtotalRow(params.node.data))) {
            return params.value;
          }
          if (params.node.data && params.node.data.employeeType && params.column.getColDef().field === 'employeeType') {
            return params.node.data.employeeType.name;
          }
        }
        return params.value;
      }
    };

    this.gridOptions.api.exportDataAsExcel(params);
  }

  public onComponentStateChanged(event: any): void {
    if (this.restoreScroll && this.gridScrollElement) {
      this.restoreScroll = false;
      this.gridScrollElement.scrollTop = this.scrollTop;
      this.gridScrollElement.scrollLeft = this.scrollLeft;
      if (this.gridOptions.api) {
        this.gridOptions.api.redrawRows();
      }
    }
  }

  public onSelectionChanged(event: SelectionChangedEvent): void {
    if (!this.gridOptions.api) {
      return;
    }
    let selectedRows: RowNode[] = this.gridOptions.api.getSelectedNodes();
    if (selectedRows.length > 0) {
      this.selectedRowNode = selectedRows[0];
      this.masterScheduleActions.setSelectedRow(this.selectedRowNode.id);
    }
  }

  public onCensusPopperShown(popper: PopperController): void {

    this.censusCancelSubscription = this.censusWindow.onCanceled.subscribe((censusSaveRequest: CensusSaveRequest) => {
      if (this.m_popperInitiator) {
        this.m_popperInitiator.hide();
      }
    });

    this.censusSavedSubscription = this.censusWindow.onSaved.subscribe((censusSaveRequest: CensusSaveRequest) => {
      if (censusSaveRequest.copyToFutureDates) {      
        if (this.m_popperInitiator) {
          this.m_popperInitiator.hide();
        }
      } else {
        this.censusCellElement.innerHTML = this.censusWindow.currentCapacity.toFixed(2);
        this.selectedCell.totalValue = this.censusWindow.currentCapacity;
        this.m_popperInitiator.hide();
      }
      this.masterScheduleActions.fetchTotals();
    });
  }

  public onCensusPopperHidden(popper: PopperController): void {
    if (this.censusSavedSubscription) this.censusSavedSubscription.unsubscribe();
    if (this.censusCancelSubscription) this.censusSavedSubscription.unsubscribe();
    this.clearCensusElements();
  }

  private makeRowSelected(rowId: number) {
    this.gridOptions.api.selectIndex(rowId, false, true);
  }

  private saveCensusElements(cell: HTMLElement): void {
    this.censusCellElement = cell;
  }

  private clearCensusElements(): void {
    this.selectedCell = null;
    this.censusCellElement = null;
  }

  private showCensusWindow(): void {
    if (this.m_popperInitiator) {

      this.m_popperInitiator.hide();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();

      this.m_popperInitiator.targetElement = this.censusCellElement;
      this.m_popperInitiator.show();

      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();

      this.censusWindow.updateInfoBy(this.selectedCensusDate);

      this.censusPopperStyles = this.popperMobileStyles;
      // always fit parent container
      this.censusWindow.fullSize = true;
    }
  }

  private onQuickFilterChanged($event: any): void {
    this.gridOptions.api.setQuickFilter($event.target.value);
  }

  private getRowIdByEmpId(empId: number): number {
    // let rowId = 0;
    // _.forIn(this.rowData, (row: any) => {
    //   rowId++;
    //   if(parseInt(row.id, 10) === empId) {
    //     return;
    //   }
    // });
    // if (rowId === this.rowData.length)
    //   return 0;
    // else
    //   return rowId;
    return _.findIndex(this.rowData, (row) => {
      return row.id === empId;
    });
  }

  private applyGrid(msData: MasterScheduleData, mergeRequired: boolean = false): void {
    let data: EmployeeGridData = this.managementService.calculateData(msData, this.gridHelper.state.separateSeconaryPositions, this.gridHelper.state.viewTotalsFTEs, this.gridHelper.state.positionGrouping);
    let rows: IMasterScheduleRow[];
    if (!this.gridHelper.state.positionSubtotals) {
      rows = _.filter(data.rows, (r: IMasterScheduleRow) => !isMasterScheduleSubtotalRow(r));
    } else {
      rows = data.rows;
    }

    if (this.gridHelper.state.positionGrouping) {
      if (this.gridHelper.state.positionGrouping.id === MasterScheduleGroupingType.BYPOSITION) {
        if (this.gridHelper.state.displayOrder === 'seniority') {
          if (this.gridHelper.state.separateSeconaryPositions) {
            rows = _.sortBy(rows, 'sortSequence', 'position.name', 'recordType', 'secondarySort', 'seniority');
          } else {
            rows = _.sortBy(rows, 'sortSequence', 'position.name', 'recordType', 'seniority');
          }
        } else {
          if (this.gridHelper.state.separateSeconaryPositions) {
            rows = _.sortBy(rows, 'sortSequence', 'position.name', 'recordType', 'secondarySort', 'name');
          } else {
            rows = _.sortBy(rows, 'sortSequence', 'position.name', 'recordType', 'name');
          }
        }

      } else if (this.gridHelper.state.positionGrouping.id === MasterScheduleGroupingType.BYPOSITIONGROUP) {
        if (this.gridHelper.state.displayOrder === 'seniority') {
          if (this.gridHelper.state.separateSeconaryPositions) {
            rows = _.sortBy(rows, 'sortSequence', 'positionGroupName', 'recordType', 'secondarySort', 'seniority');
          } else {
            rows = _.sortBy(rows, 'sortSequence', 'positionGroupName', 'recordType', 'seniority');
          }
        } else {
          if (this.gridHelper.state.separateSeconaryPositions) {
            rows = _.sortBy(rows, 'sortSequence', 'positionGroupName', 'recordType', 'secondarySort', 'name');
          } else {
            rows = _.sortBy(rows, 'sortSequence', 'positionGroupName', 'recordType', 'name');
          }
        }
      } else {
        throw new Error(
          `Master Schedule Behaviour is not implemented For type: ${this.gridHelper.state.positionGrouping.name}`
        );
      }
    }

    if (mergeRequired) {
      this.mergeEmployeeData(this.rowData, rows);
    } else {
      this.rowData = rows;
    }

    let totalMap: StringMap<IMasterScheduleRow> = {};
    this.m_footerData = [];
    totalMap = _.keyBy(data.totals, (r: IMasterScheduleRow) => r.name);

    if (this.gridHelper.state.dailyTotals && totalMap[TotalsDescription.totalTotals]) {
      this.m_footerData.push(totalMap[TotalsDescription.totalTotals]);
    }
    if (this.gridHelper.state.idealSchedule && totalMap[TotalsDescription.totalPARLevels]) {
      this.m_footerData.push(totalMap[TotalsDescription.totalPARLevels]);
    }
    if (this.gridHelper.state.budgetedPars && totalMap[TotalsDescription.totalBudgetedPars]) {
      this.m_footerData.push(totalMap[TotalsDescription.totalBudgetedPars]);
    }
    if (this.gridHelper.state.openShifts && totalMap[TotalsDescription.totalOpenShifts]) {
      this.m_footerData.push(totalMap[TotalsDescription.totalOpenShifts]);
    }
    if (this.gridHelper.state.difference && totalMap[TotalsDescription.totalDifference]) {
      this.m_footerData.push(totalMap[TotalsDescription.totalDifference]);
    }
    if (this.gridHelper.state.census && totalMap[TotalsDescription.totalCensus]) {
      this.m_footerData.push(totalMap[TotalsDescription.totalCensus]);
    }
    if (this.gridHelper.state.scheduledPpd && totalMap[TotalsDescription.totalPPD]) {
      this.m_footerData.push(totalMap[TotalsDescription.totalPPD]);
    }
    if (this.gridHelper.state.staffingRatio && totalMap[TotalsDescription.totalStaffingRatio]) {
      this.m_footerData.push(totalMap[TotalsDescription.totalStaffingRatio]);
    }
    if (this.gridHelper.state.actualHours && totalMap[TotalsDescription.totalActualHours]) {
      this.m_footerData.push(totalMap[TotalsDescription.totalActualHours]);
    }
    if (this.gridHelper.state.actualPpd && totalMap[TotalsDescription.totalActualPPD]) {
      this.m_footerData.push(totalMap[TotalsDescription.totalActualPPD]);
    }
    if (this.gridHelper.state.pinTotalRows) {
      this.footerData = this.m_footerData;
    } else {
      this.footerData = [];
      this.rowData = this.mergeFooterData(this.rowData, this.m_footerData);
      if (this.gridOptions.api) {
        this.gridOptions.api.redrawRows();
      }
    }
    this.restoreScroll = true;
    if (this.gridOptions.api && this.selectedRowNode) {
      this.selectedRowNode.setSelected(true, true);
    }
    this.setColumnsState();

    this.dataApplied$.next();
  }

  private mergeFooterData(rowData: IMasterScheduleRow[], footerData: IMasterScheduleRow[]): IMasterScheduleRow[] {
    const existingRows: IMasterScheduleRow[] = _.filter(rowData, (row: IMasterScheduleRow) => row.recordType === MasterScheduleRecordType.Total);
    if (existingRows.length === 0) {
      return _.concat(rowData, footerData);
    } else {
      const footerMap = _.keyBy(footerData, (row: IMasterScheduleRow) => row.name);
      _.forEach(existingRows, (row: IMasterScheduleRow) => {
        const newRow: IMasterScheduleRow = footerMap[row.name];
        row.cell = _.size(row.cell) >= _.size(newRow.cell) ? row.cell : newRow.cell;
      });
      return rowData;
    }
  }

  private mergeEmployeeData(gridRows: IMasterScheduleRow[], rowsToMerge: IMasterScheduleRow[]): void {
    let subtotalGridRows = _.filter(_.map(gridRows, (row: IMasterScheduleRow, index: number): any => {
      let iteratedRow: any = {};
      iteratedRow.rowId = index;
      iteratedRow.row = row;
      return iteratedRow;
    }), row => row.row.recordType === MasterScheduleRecordType.Subtotal);
    let subtotalRowsToMerge = _.filter(rowsToMerge, (row: IMasterScheduleRow) => row.recordType === MasterScheduleRecordType.Subtotal);
    _.forIn(subtotalGridRows, (indexedRowToUpdate: any) => {
      let rowToUpdate: IMasterScheduleSubtotalRow = indexedRowToUpdate.row;
      let rowToMerge;

      if (!this.gridHelper.state.positionGrouping || this.gridHelper.state.positionGrouping.id === MasterScheduleGroupingType.BYPOSITION) {
        rowToMerge = _.find(subtotalRowsToMerge, (row: IMasterScheduleSubtotalRow) => {
          return ((row.position ? row.position.id : 0) === (rowToUpdate.position ? rowToUpdate.position.id : 0)) &&
            (row.positionGroupName === '' || row.positionGroupName === rowToUpdate.positionGroupName);
        });
      } else {
        rowToMerge = _.find(subtotalRowsToMerge, (row: IMasterScheduleSubtotalRow) => {
          return (row.positionGroupName === rowToUpdate.positionGroupName);
        });
      }
      if (!!rowToMerge) {
        rowToUpdate.cell = rowToMerge.cell;
        rowToUpdate.position = rowToMerge.position;
      }
    });

    let nodesToRefresh: RowNode[] = [];
    _.forIn(subtotalGridRows, (row: any) => {
      let rowNode = this.gridOptions.api.getRowNode(row.rowId);
      nodesToRefresh.push(rowNode);
    });

    this.gridOptions.api.refreshCells({
      rowNodes: nodesToRefresh,
      force: true
    });
  }

  private setColumnsState(): void {
    if (!this.gridOptions.columnApi) {
      return;
    }

    const columns = this.gridOptions.columnApi.getColumns();

    _.forEach(columns, (column: Column) => {
      if (column.getColDef().field === 'positionGroupName') {
        this.gridOptions.columnApi.setColumnVisible(column, this.gridHelper.state.positionGroupColShown);
      }
      if (column.getColDef().field === 'position') {
        this.gridOptions.columnApi.setColumnVisible(column, this.gridHelper.state.positionColShown);
      }
      if (column.getColDef().field === 'hireDate') {
        this.gridOptions.columnApi.setColumnVisible(column, this.gridHelper.state.dateOfHireColShown);
      }
      if (column.getColDef().field === 'dateTerminated') {
        this.gridOptions.columnApi.setColumnVisible(column, this.gridHelper.state.dateTerminatedColShown);
      }
      if (column.getColDef().field === 'homeShiftName') {
        this.gridOptions.columnApi.setColumnVisible(column, this.gridHelper.state.shiftColShown);
      }
      if (column.getColDef().field === 'avgWeeklyRotationsHrs') {
        this.gridOptions.columnApi.setColumnVisible(column, this.gridHelper.state.avgWeeklyRotationsHrs);
      }
      if (column.getColDef().field === 'employeeType') {
        this.gridOptions.columnApi.setColumnVisible(column, this.gridHelper.state.employeeTypeColShown);
      }
      if (this.gridHelper.isWeekTotalColumn(column.getColDef().field)) {
        this.gridOptions.columnApi.setColumnVisible(column, this.gridHelper.state.weeklyTotals);
      }
    });

    _.forEach(this.gridHelper.weeks, (week: any[]) => {
      _.forEach(week, (dayCol: any) => {
        if (!this.gridHelper.isWeekTotalColumn(dayCol.field)) {
          let width;
          switch (this.gridHelper.state.cellDisplay) {
            case 'shiftTimes':
              width = 150;
              break;
            case 'shiftName':
              width = 90;
              break;
            case 'shiftNameAndUnit':
              width = 150;
              break;
            case 'shiftDurationHours':
              width = 80;
              break;
            case 'shiftDurationMin':
              width = 80;
              break;
            default:
              width = 80;
          }
          this.gridOptions.columnApi.setColumnWidth(dayCol.field, width);
        }
      });
    });
  }
  private showSummary(summary: GenerateScheduleSummary): void {
    ScheduleCycleSummaryDialogComponent.openDialog(summary, this.modalService, (action: ScheduleCycleSummaryViewAction) => {
      if (!action) {
        return;
      }
      if (action.action === 'NavigateToEmployee') {
        const es = new EmployeeSectionNavigationService(this.router, this.route);
        es.NavigateToEmployeeSections(action.employeeId, false);
      }
      if (action.action === 'NavigateToScheduleEntry') {
        const se = new ScheduleEntryNavigationService(this.router, this.route);
        se.NavigateToScheduleEntry(action.employeeId, action.date);
      }
    });
  }
}




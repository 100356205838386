import { DialogOptions2, DialogModeSize } from './../../../../common/models/dialog-options';
import { Component, OnInit, OnDestroy, ViewChild, Provider } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ModalService, ColumnManagementService } from '../../../../common/services/index';
import { IDialog, DialogOptions } from '../../../../common/models/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { GenericListManagementService } from '../../../../organization/services/index';
import { GenericListDefinition, GenericList, GenericListRequest } from '../../../../organization/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { GenericGridComponent } from '../../../../organization/components/index';

import { Observable ,  Subscription } from 'rxjs';

import { UserApplication, OrgLevel } from '../../../../state-model/models/index';
import { IOperationalConsoleEntry, OperationalConsoleEntry, OperationalConsoleField, OperationalConsoleDialogParameters } from '../../models/index';
import { genericGridConfig, IGenericGridConfig } from '../../../../organization/components/generic-list/generic-grid/generic-grid.config';

@Component({
  moduleId: module.id,
  selector: 'slx-operational-console-dialog',
  templateUrl: 'operational-console-dialog.component.html',
  styleUrls: ['operational-console-dialog.component.scss'],
  providers: [GenericListManagementService, ColumnManagementService]
})
export class OperationalConsoleDialogComponent implements OnInit, OnDestroy, IDialog {

  @ViewChild(GenericGridComponent, {static: true})
  public grid: GenericGridComponent;
  public gridConfig: IGenericGridConfig;

  public dialogResult: boolean;
  public appConfig: IApplicationConfig;
  public isLoading: boolean;
  public parameters: OperationalConsoleDialogParameters;

  @unsubscribe()
  private definitionsLoadedSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private loadSubscription: Subscription;
  @unsubscribe()
  private valuesLoadedSubscription: Subscription;

  private genericListManagementService: GenericListManagementService;
  private listDefinition: GenericListDefinition;
  private listValues: GenericList;
  private options: DialogOptions2;
  private modalService: ModalService;

  public static openDialog(params: OperationalConsoleDialogParameters, modalService: ModalService): OperationalConsoleDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: OperationalConsoleDialogParameters,
        useValue: params
      },
    ];
    let dialog: OperationalConsoleDialogComponent = modalService.globalAnchor.openDialog2(
      OperationalConsoleDialogComponent,
      params.field.title,
      dialogOptions,
      resolvedProviders
    );

    return dialog;
  }

  constructor(parameters: OperationalConsoleDialogParameters, genericListManagementService: GenericListManagementService, options: DialogOptions2, modalService: ModalService) {
    this.parameters = parameters;
    this.genericListManagementService = genericListManagementService;
    this.options = options;
    this.modalService = modalService;
    this.isLoading = true;

    this.gridConfig = _.assign({}, genericGridConfig);
    this.gridConfig.excelExport.fileName = `${parameters.field.colName}.xlsx`;
    this.gridConfig.pdfExport.fileName = `${parameters.field.colName}.pdf`;
  }

  public ngOnInit(): void {
    this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$
      .subscribe((defs: GenericListDefinition) => this.loadValues());

    this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$
      .subscribe((list: GenericList) => (this.isLoading = false));

    this.loadDefinitions();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public get isCustomMode(): boolean {
    return !_.isNil(this.parameters.employeeLinkClick);
  }

  public onExportToExcel(): void {
    this.grid.exportToExcel();
  }

  public onExportToPdf(): void {
    this.grid.exportToPdf();
  }

  public onEmployeeLinkClick(empId: number): void {
    this.closeDialog();
    this.parameters.employeeLinkClick.emit(empId);
  }

  private closeDialog(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  private loadDefinitions(): void {
    this.isLoading = true;
    this.genericListManagementService.loadListDefinition(this.parameters.application.id, this.parameters.orgLevel.id, this.parameters.field.colName);
  }

  private loadValues(): void {
    this.isLoading = true;
    let req: GenericListRequest = new GenericListRequest();
    const startOfDay: moment.Moment = moment(this.parameters.date).startOf('day');
    const posId = _.get(this.parameters, 'position.id');
    req.startDate = startOfDay.toDate();
    req.endDate = startOfDay.endOf('day').toDate();
    if (_.isNumber(posId)) {
      req.positionId = posId;
    }
    this.genericListManagementService.loadListValues(this.parameters.orgLevel.id, req);
  }
}

import { Component, Provider, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogModeSize, DialogOptions, DialogOptions2, IDialog, KendoGridStateHelper, ModalService } from '../../../../../app/common';
import { ISession } from '../../../../..//app/authentication/store';
import { mutableSelect } from '../../../../../app/core/decorators';
import { appConfig, IApplicationConfig } from '../../../../../app/app.config';
import { DailyOpenShiftDetailsData, DetailsPartner, OpenShiftSummary, Unit } from '../../../../../app/scheduler/models';
import { DetailScreenService } from '../../../../../app/scheduler/services';
import { ShiftRequestPopupComponent } from '../../shift-request-popup/shift-request-popup.component';
import * as _ from 'lodash';
import { PositionsConfigurationApiService, ShiftsApiService, UnitsApiService } from '../../../../../app/configuration/services';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { IdealScheduleList, IdealSchedulePosition } from '../../../../../app/configuration/models';
import * as moment from 'moment';
import { OrgLevel } from '../../../../../app/state-model/models';
import { OpenShift, OpenShiftSchedule } from '../../../../../app/scheduler/models/daily-unit-assignment/add-open-shift';
import { ShiftRequestService } from '../../../../../app/scheduler/services/schedule/shift-request.service';

@Component({
  selector: 'slx-add-open-shifts',
  templateUrl: './add-open-shifts-v1.component.html',
  styleUrls: ['./add-open-shifts-v1.component.scss']
})
export class AddOpenShiftsV1Component implements  IDialog {
  @ViewChild('grid', { static: true })
  public grid: GridComponent;

  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  public selectedOpenShiftSummary: OpenShiftSummary;
  public options: DialogOptions;
  public dialogResult: boolean;
  public static items: any;
  private modalService: ModalService;
  private appConfig: IApplicationConfig;
  public storeSelectedData: DailyOpenShiftDetailsData;
  public storeSelectedDataArr: any = [];
  public static requestedDate: Date;
  public static orgDetails: any;
  public static agencyDetails: DetailsPartner[];
  public static currentOffset: number;
  public isAddDisabled: boolean = false;
  public userName: string;
  public alias: string;
  public login: string;
  public userId: number;
  public positionMappingError: boolean = true;
  public shiftStarDateError: boolean = true;
  public gridState: KendoGridStateHelper<OpenShift>;
  public gridData: any[];
  static agencyDetailsData: DetailsPartner[];
  public shifts: any;
  public isLoading: boolean = false;
  public units: Unit[];
  public positions: any;
  public positionData: any;
  public isSelectedShift: boolean = false;
  public selectedOpenShift: OpenShift[] = [];
  public openShift: OpenShift = new OpenShift();
  public displayId = 1;
  public minDate: Date = new Date();
  public idealScheduleDetails: IdealScheduleList;
  public idealSchedulePosition: IdealSchedulePosition;
  public maxDate: Date;
  public pageSize: number
  public max: number = 25;
  public min: number = 1;
  public isMappedShift: boolean;
  public unitData: Unit[];
  public shiftsData: any;
  constructor(
    options: DialogOptions,
    modalService: ModalService,
    private shiftApiService: ShiftsApiService,
    private positionApiService: PositionsConfigurationApiService,
    private detailScreenService: DetailScreenService,
    private shiftRequestService: ShiftRequestService,
    private unitApiService: UnitsApiService,
  ) {
    this.modalService = modalService;
    this.options = options;
    this.gridState = new KendoGridStateHelper();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.group = [];
    this.appConfig = appConfig;
    this.getShiftDetails();
    this.getUnits();
    this.getPositions();
    this.openShift.startDateRange = this.requestedDate;
    this.openShift.endDateRange = new Date(moment(this.openShift.startDateRange).add(7, 'days').calendar());
    this.maxDate = new Date(moment(this.openShift.startDateRange).add(30, 'days').calendar());

  }

  public getMaxDate(): Date {

    return new Date(moment(this.openShift.startDateRange).add(30, 'days').calendar());

  }
  public updateEndDate(event): void {

    this.openShift.endDateRange = new Date(moment(event).add(7, 'days').calendar());

  }
  public static openDialog(
    groups: any,
    modalService: ModalService,
    orgLevel: OrgLevel,
    requestDate: Date,
    agencyData: DetailsPartner[]
  ): any {


    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.minHegiht = 800;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions,
      },
      {
        provide: DailyOpenShiftDetailsData,
        useValue: groups,
      },
    ];
    AddOpenShiftsV1Component.requestedDate = requestDate;
    AddOpenShiftsV1Component.orgDetails = orgLevel;
    AddOpenShiftsV1Component.agencyDetailsData = agencyData;
    this.currentOffset = +_.get(orgLevel, 'location.timeZoneOffset') || 0;
    let header: string = `Add Open Shift for  ${orgLevel.name}`;
    modalService.globalAnchor.openDialog2(
      AddOpenShiftsV1Component,
      header,
      dialogOptions,
      resolvedProviders,
      () => { }
    );

    return AddOpenShiftsV1Component;
  }

  public get requestedDate() {
    return AddOpenShiftsV1Component.requestedDate;
  }

  public get orgdetailsData() {
    return AddOpenShiftsV1Component.orgDetails;
  }

  public get agencyDetailsData() {
    return AddOpenShiftsV1Component.agencyDetails;
  }

  public get getcurrentOffset() {
    return ShiftRequestPopupComponent.currentOffset;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    //console.info('Destroy Add Employee popup');
  }
  public getShiftDetails(): void {
    this.isLoading = true;
    this.shiftApiService.getShiftsList(this.orgdetailsData.id).
      then((result) => {
        this.shifts = result.records;
        this.shiftsData = this.shifts;
        this.isLoading = false;
      }).catch(() => {
        console.log("error");
        this.isLoading = false;
      });


  }
  public getUnits(): void {
    this.unitApiService.getUnitsList(this.orgdetailsData.id).
      then((result: { records: Unit[] }) => {
        this.units = result.records;
        this.unitData = this.units;
      }).catch(() => {
        console.log("error Units")
      });

  }
  public getPositions(): void {
    this.positionApiService.getPositions(this.orgdetailsData.id).
      then((result) => {
        this.positions = result.positions;
        this.positionData = this.positions;
      }).catch(() => {

      });
  }

  public addOpenShifts(): void {
    this.openShift.startDate = this.openShift.startDateRange.toLocaleDateString();
    this.openShift.endDate = this.openShift.endDateRange.toLocaleDateString();
    let tempOpenShift = _.cloneDeep(this.openShift);
    if (this.selectedOpenShift.length == 0) {
      this.selectedOpenShift.push(tempOpenShift);
    }
    else {
     this.isUniqueShifts();
      if (!this.isMappedShift) {
        this.isMappedShift = true;
        this.selectedOpenShift.push(tempOpenShift);
      }
    }
    if (this.selectedOpenShift.length > 0) {
      this.isSelectedShift = true;
    }
    else {
      this.isSelectedShift = false;
    }
    this.resetOpenShift()
    this.refreshGrid();

  }
  public isUniqueShifts() :void {
    this.isMappedShift = false;
    // Error if shift with same defination found 
    this.selectedOpenShift.forEach(element => {
      let position: any[] = [this.openShift.position];
      let m_position: any[] = [element.position];
      if (m_position[0].id == position[0].id) {
        let unit: any[] = [this.openShift.unit];
        let m_unit: any[] = [element.unit];
        if (m_unit[0].id == unit[0].id) {
          let shifts: any[] = [this.openShift.Shifts];
          let m_shifts: any[] = [element.Shifts];
          if (m_shifts[0].id == shifts[0].id) {
            console.log("Ideal Schedule is already created");
            this.isMappedShift = true;
          }
        }
      }

    });
  }
  public resetOpenShift(): void {
    this.openShift = new OpenShift();
    this.openShift.startDateRange = this.requestedDate;
    this.openShift.endDateRange = new Date(moment(this.requestedDate).add(7, 'days').calendar());;
  }
  public cancelHandler(dataItem: OpenShift): void {
    this.selectedOpenShift = this.selectedOpenShift.filter(el => el.displayId != dataItem.displayId);
    this.refreshGrid();

  }
  private refreshGrid(): void {
    if (this.selectedOpenShift.length == 0) {
      this.isSelectedShift = false;
      this.gridState.view = null;
      return;
    }
    let displayId: number = 1;
    this.selectedOpenShift.forEach((item) => {
      item.displayId = displayId++;

    });
    this.pageSize = this.selectedOpenShift.length;
    this.gridState.view = process(this.selectedOpenShift, this.gridState.state);

  }
  public onCancel() {
    this.dialogResult = false;
    this.shiftRequestService.openShiftRequest(true);
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onAdd(): void {
    let idealSchedule: OpenShiftSchedule = new OpenShiftSchedule();
    idealSchedule.openShifts = this.selectedOpenShift;
    idealSchedule.orgLevelId = this.orgdetailsData.id;
    this.isLoading = true;
    this.detailScreenService.saveIdealSchedule(this.orgdetailsData.id, idealSchedule).then((e) => {

      this.shiftRequestService.openShiftRequest(true);
      this.isLoading = false;
      this.dialogResult = false;
      this.modalService.closeWindow(this.options.windowUniqueId);
    }).catch((e) => {
      this.isLoading = false;
      this.dialogResult = false;
      this.modalService.closeWindow(this.options.windowUniqueId);
    });

  }
  public isSelected(): boolean {
    if (this.openShift.position == null || this.openShift.unit == null || this.openShift.Shifts == null || this.openShift.count < 0 || this.openShift.count == null || this.openShift.count > 25) {

      return true;

    }
    else {
      return false;
    }
  }
  public getDateRange(dataItem: OpenShift): string {
    let startDateRange = dataItem.startDateRange
    let endDateRange = dataItem.endDateRange;

    return `${startDateRange.toLocaleDateString()} - ${endDateRange.toLocaleDateString()}`;
  }
  public filterPosition(value): void {
    this.positionData = this.positions.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);

  }
  public filterUnits(value): void {
    this.unitData = this.units.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  public filterShifts(value): void {
    this.shiftsData = this.shifts.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

}


import { Injectable } from '@angular/core';
import { PayPolicyDto } from '../../models/pay-policies/dtos/pay-policy.dto';
import { PayPolicyRuleDto } from '../../models/pay-policies/dtos/pay-policy-rule.dto';
import { PayPolicy } from '../../models/pay-policies/models/pay-policy.model';
import { PayPolicyRule } from '../../models/pay-policies/models/pay-policy-rule.model';

@Injectable()
export class PayPoliciesMapService {

    // Maps PayPolicyDto to PayPolicy
    public mapToModel(dto: PayPolicyDto): PayPolicy {
        let model: PayPolicy = new PayPolicy();
        Object.assign(model, dto);
        return model;
    } 

    // Maps PayPolicy to PayPolicyDto
    public mapToDto(model: PayPolicy): PayPolicyDto {
        let dto: PayPolicyDto = {
            id: model.id,
            description: model.description,
            organizationId: model.organizationId,
            type: model.type,
            deleteInd: model.deleteInd,
            lastUpdatedDate: model.lastUpdatedDate,
            lastUpdateId: model.lastUpdateId
        };
        return dto;
    }

    // Maps PayPolicyRuleDto to PayPolicyRule
    public mapRuleToModel(dto: PayPolicyRuleDto): PayPolicyRule {
        let model: PayPolicyRule = new PayPolicyRule();
        Object.assign(model, dto);
        return model;
    }

    // Maps PayPolicyRule to PayPolicyRuleDto
    public mapRuleToDto(model: PayPolicyRule): PayPolicyRuleDto {
        let dto: PayPolicyRuleDto = {
            id: model.id,
            policyId: model.policyId,
            organizationId: model.organizationId,
            exceptionId: model.exceptionId,
            description: model.description,
            notes: model.notes,
            isActive: model.isActive,
            startDate: model.startDate,
            endDate: model.endDate,
            lastUpdatedDate: model.lastUpdatedDate,
            deleteInd: model.deleteInd
        };
        return dto;
    }

    // Maps array of PayPolicyDtos to PayPolicy
    public mapToModels(dtos: PayPolicyDto[]): PayPolicy[] {
        return dtos.map(dto => this.mapToModel(dto));
    }

    // Maps array of PayPolicy to PayPolicyDtos
    public mapToDtos(models: PayPolicy[]): PayPolicyDto[] {
        return models.map(model => this.mapToDto(model));
    }

    // Maps array of PayPolicyRuleDtos to PayPolicyRule
    public mapRulesToModels(dtos: PayPolicyRuleDto[]): PayPolicyRule[] {
        return dtos.map(dto => this.mapRuleToModel(dto));
    }

    // Maps array of PayPolicyRule to PayPolicyRuleDtos
    public mapRulesToDtos(models: PayPolicyRule[]): PayPolicyRuleDto[] {
        return models.map(model => this.mapRuleToDto(model));
    }
}
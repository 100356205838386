import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Host, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { moment } from '../../../../../common';
import { unsubscribe } from '../../../../../core/decorators/index';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { cancelEvent, ConfirmDialogComponent, ConfirmOptions, DialogOptions, KendoGridStateHelper, ModalService, removeEvent, saveEvent } from '../../../../../common/index';
import { mutableSelect } from '../../../../../core/decorators/index';
import { Assert } from '../../../../../framework/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { AccrualPolicy, DynamicAccrualPolicy, EmployeePolicyAssignment, EmployeeSectionsBase, EmployeeSectionsPolicyAssignments, EndingBalanceDialogOptions } from '../../../models';
import { EmployeeSectionsAccrualsApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { DynamicPolicyDialogComponent } from './dynamic-policy-dialog/dynamic-policy-dialog.component';
import { EndingBalanceDialogComponent } from './ending-balance-dialog/ending-balance-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-policy-assignments',
  templateUrl: 'employee-sections-policy-assignments.component.html',
  styleUrls: ['employee-sections-policy-assignments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsPolicyAssignmentsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input('employeeSectionsPolicyAssignments')
  public set policyAssignments(employeeSectionsPolicyAssignments: EmployeeSectionsPolicyAssignments) {
    this.employeeSectionsPolicyAssignments = employeeSectionsPolicyAssignments;

    const canEdit: boolean = _.get(employeeSectionsPolicyAssignments, 'actions.canEdit', null);
    if (_.isBoolean(canEdit)) {
      this.canEdit = canEdit;
    }
    this.refreshGrid();
    this.updateLookups();
    this.originalPolicyAssignments = _.cloneDeep(employeeSectionsPolicyAssignments);
  }

  @Input()
  public set employeeId(value: number) {
    this.m_employeeId = value;
    this.updateLookups();
  }

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }

  public addMode: boolean;
  public gridState: KendoGridStateHelper<EmployeePolicyAssignment>;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public appConfig: IApplicationConfig;
  public canEdit: boolean;
  public employeeSectionsPolicyAssignments: EmployeeSectionsPolicyAssignments;
  public accrualPolicyLookup: AccrualPolicy[] = [];
  public editingItem: EmployeePolicyAssignment;
  public minDate: Date = new Date(1900, 1, 1);
  public now: Date = moment().startOf('day').toDate();
  public dPolicyError: boolean = false;
  public dateError: boolean = false;
  public originalPolicyAssignments: EmployeeSectionsPolicyAssignments;
  private addCounter: number = 0;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild('gridForm', { static: true })
  private ngFormChild: NgForm;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridRemoveSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;
  @unsubscribe()
  private formValueChangeSubscription: Subscription;

  private m_employeeId: number;
  private orgLevelId: number;
  private allowDynamicPolicy: boolean;
  private employeeSectionsAccrualsApiService: EmployeeSectionsAccrualsApiService;

  constructor(
    private modalService: ModalService,
    employeeSectionsAccrualsApiService: EmployeeSectionsAccrualsApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone,
    private changeDetector: ChangeDetectorRef) {
    super(decorator, ngZone);
    this.decorator.isEditableByConfiguration = false;
    Assert.isNotNull(employeeSectionsAccrualsApiService, 'employeeSectionsAccrualsApiService');
    this.employeeSectionsAccrualsApiService = employeeSectionsAccrualsApiService;
    this.gridState = new KendoGridStateHelper<EmployeePolicyAssignment>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'startDate', dir: 'desc' }];
    this.gridState.state.take = 5;
    this.createSubscriptions();

  }
  public ngOnInit(): void {
    super.ngOnInit();
    this.checkAllowDynamicPolicy();
  }

  public ngOnDestroy(): void {
  }
  private checkAllowDynamicPolicy() {
    this.employeeSectionsAccrualsApiService.getAllowDynamicAccrualPolicies()
      .then((allowDynamicPolicy: boolean) => {
        this.allowDynamicPolicy = allowDynamicPolicy;
      });
  }

  public createSubscriptions(): void {

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.gridEditSubscription = this.gridState.onEdit$.subscribe((item: EmployeePolicyAssignment): void => {
      this.editingItem = item;
      this.addMode = false;
    });

    this.gridCancelSubscription = this.gridState.onCancel$.subscribe((item: cancelEvent<EmployeePolicyAssignment>): void => {
      this.resetAddMode();
    });

    this.gridSaveSubscription = this.gridState.onSave$.subscribe((item: saveEvent<EmployeePolicyAssignment>): void => {
      this.gridSaveSubscribe(item.dataItem, item.isNew);
    });

    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe((item: removeEvent<EmployeePolicyAssignment>): void => {
      this.gridRemoveSubscribe(item.dataItem);
    });

  }

  private gridSaveSubscribe(item: EmployeePolicyAssignment, isNew: boolean): void {
    item.lastUpdatedDate = moment().startOf('day').toDate();
    let selPolicy: string = '';
    let policy = _.without(this.employeeSectionsPolicyAssignments.records, item);
    _.forEach(policy, function (el) {
      if (item.endDate) {
        if (el.startDate >= item.startDate && el.startDate <= item.endDate && el.endDate <= item.endDate && el.endDate != null) {
          selPolicy = selPolicy == '' ? el.policyName : selPolicy + ', ' + el.policyName;
        }
      }
      else {
        if (el.startDate >= item.startDate) {
          selPolicy = selPolicy == '' ? el.policyName : selPolicy + ', ' + el.policyName;
        }
      }

    })
    if (selPolicy != '') {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      ConfirmDialogComponent.openDialog(
        'Warning',
        'These policy (' + selPolicy + ') will be deleted, Would you like to continue?',
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.saveChanges(item, isNew);
          }
          else {
            this.resetPreviousDates(item);
          }
        }, options);
    }
    else {
      if (isNew) {
        let source = this.employeeSectionsPolicyAssignments.records;
        this.employeeSectionsPolicyAssignments.records = _.concat(source, [item]);
      }
      this.confirmSaveChanges(item);
    }
    this.resetAddMode();
  }


  private resetPreviousDates(item: EmployeePolicyAssignment): void {
    if (item.id != 0) {
      let orgPA = _.find(this.originalPolicyAssignments.records, ['id', item.id]);
      item.startDate = new Date(orgPA.startDate);
      if (item.endDate) {
        item.endDate = new Date(orgPA.endDate);
      }
      this.sortChange(this.gridState.state.sort);
      this.changeDetected();
    }
  }

  public resetAddMode(): void {
    this.addMode = false;
    this.editingItem = null;
    this.dateError = false;
    this.changeDetected();
  }
  private saveChanges(item: EmployeePolicyAssignment, isNew: boolean): void {
    if (isNew) {
      let source = this.employeeSectionsPolicyAssignments.records;
      this.employeeSectionsPolicyAssignments.records = _.concat(source, [item]);

    }
    this.confirmSaveChanges(item);
  }

  private changeDetected(): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private gridRemoveSubscribe(item: EmployeePolicyAssignment): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.notes = `Note that as a result of this transaction,
    the policy name will be permanently associated with this employee’s accrual transaction history.
    This will ensure completeness in the audit trail history for all policy deletions.`;
    ConfirmDialogComponent.openDialog(
      'Confirmation',
      'Are you sure that you want to remove this policy?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.doRemove(item);
          this.changeDetected();
        }
      }, options);
  }

  protected confirmSaveChanges(item: EmployeePolicyAssignment): void {
    this.startProgress();
    item.empId = this.m_employeeId;
    if (!this.allowDynamicPolicy) {
      item.policyIdList = _.find(this.accrualPolicyLookup, ['policyName', item.policyName]).id.toString();
    }
    else {
      let policyNameList: string[] = _.split(item.policyName, '-');
      item.policyIdList = _.join(_.map(_(this.accrualPolicyLookup).keyBy('policyName').at(policyNameList).value(), 'id'), '-');
    }
    this.employeeSectionsAccrualsApiService.postAccrualsPolicyAssignments(this.m_employeeId, item)
      .then((employeeSectionsPolicyAssignments: any) => {
        this.stopProgress();
        this.employeeSectionsPolicyAssignments = employeeSectionsPolicyAssignments;
        this.originalPolicyAssignments = _.cloneDeep(employeeSectionsPolicyAssignments);
        this.state.isLoaded = true;
        this.gridState.state.skip = 0;
        this.canEdit = _.get(this.employeeSectionsPolicyAssignments, 'actions.canEdit', false);
        this.refreshGrid();
        this.changeDetected();

      }).catch((reason: any) => {
        this.stopProgress();
        this.onActionError(reason);
      });
  }

  protected doRemove(item: EmployeePolicyAssignment): void {
    item.deleted = true;
    this.deletePolicyAssignment(item);
  }

  private async deletePolicyAssignment(assignment: EmployeePolicyAssignment): Promise<void> {
    this.startProgress();

    try {
      const employeeSectionsPolicyAssignments = await this.employeeSectionsAccrualsApiService.deletePolicyAssignment(this.m_employeeId, assignment.id);

      this.employeeSectionsPolicyAssignments = employeeSectionsPolicyAssignments;
      this.originalPolicyAssignments = _.cloneDeep(employeeSectionsPolicyAssignments);
      this.state.isLoaded = true;
      this.gridState.state.skip = 0;
      this.canEdit = _.get(this.employeeSectionsPolicyAssignments, 'actions.canEdit', false);
      this.refreshGrid();
      this.changeDetected();
    } catch (error) {
      this.onActionError(error);
    }

    this.stopProgress();
  }

  public minDateLimit(item: EmployeePolicyAssignment): Date {

    if (_.isNil(item)) {
      return this.minDate;
    }
    if (this.employeeShortInfo && this.employeeShortInfo.dateHired) {
      return this.employeeShortInfo.dateHired;
    }
    return this.now;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsPolicyAssignments;
  }

  public loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsAccrualsApiService.getAccrualsPolicyAssignments(this.m_employeeId)
      .then((employeeSectionsPolicyAssignments: EmployeeSectionsPolicyAssignments) => {
        this.employeeSectionsPolicyAssignments = employeeSectionsPolicyAssignments;
        this.state.isLoaded = true;
        this.gridState.state.skip = 0;
        this.canEdit = _.get(this.employeeSectionsPolicyAssignments, 'actions.canEdit', false);
        this.refreshGrid();
        this.originalPolicyAssignments = _.cloneDeep(employeeSectionsPolicyAssignments);
        this.stopProgress();
        this.changeDetected();
      }).catch(() => {
        this.stopProgress();
      });

  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public pageChange(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  public refreshGrid(): void {
    if (!this.employeeSectionsPolicyAssignments) {
      this.gridView = null;
      return;
    }

    let filteredRecords: EmployeePolicyAssignment[] = this.employeeSectionsPolicyAssignments.records;
    let sortedRecords: EmployeePolicyAssignment[] = orderBy(filteredRecords, this.sort);
    let pagedRecords: EmployeePolicyAssignment[] = sortedRecords.slice(this.gridState.state.skip, this.gridState.state.skip + this.gridState.state.take);

    this.gridView = {
      data: pagedRecords,
      total: sortedRecords.length
    };

    this.gridState.view = process(this.employeeSectionsPolicyAssignments.records, this.gridState.state);
  }

  public onShowEndingBalance(item: EmployeePolicyAssignment): void {
    this.startProgress();
    this.employeeSectionsAccrualsApiService.getAccrualsPolicyBalance(this.m_employeeId, item.id)
      .then((accrualBalance: EndingBalanceDialogOptions) => {
        EndingBalanceDialogComponent.openDialog(accrualBalance, this.modalService);
        this.stopProgress();
        this.changeDetected();
      }).catch(() => {
        this.stopProgress();
      });
  }
  public isEndDateRequired(item: EmployeePolicyAssignment): boolean {
    let isRequired = false;
    let employeePolicyAssignment: EmployeePolicyAssignment = _.find(this.originalPolicyAssignments.records, ['id', item.id]);
    if (!_.isNull(employeePolicyAssignment.endDate)) {
      isRequired = true;
    }
    return isRequired;
  }
  public updateLookups(): void {
    if (this.m_employeeId && this.employeeSectionsPolicyAssignments) {
      this.employeeSectionsAccrualsApiService.getAccrualsPolicyList(this.m_employeeId, this.now.toDateString())
        .then((accrualPolicy: AccrualPolicy[]) => {
          this.accrualPolicyLookup = accrualPolicy;
        });
    }
  }

  public onAddNew(): void {
    this.addMode = true;
    this.grid.addRow(this.addNewRow());
    if (this.allowDynamicPolicy) { this.dPolicyError = true; }
  }

  private addNewRow(): EmployeePolicyAssignment {
    const newEmpPolicyAssignments: EmployeePolicyAssignment = new EmployeePolicyAssignment();
    newEmpPolicyAssignments.startDate = moment().startOf('day').toDate();
    newEmpPolicyAssignments.endDate = null;
    newEmpPolicyAssignments.id = 0;
    newEmpPolicyAssignments.policyIdList = "";
    newEmpPolicyAssignments.policyName = "";
    return newEmpPolicyAssignments;
  }

  public openDynamicPolicyDialog(item: EmployeePolicyAssignment): void {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 450;
    dialogOptions.width = 350;
    let dialog: DynamicPolicyDialogComponent = this.modalService.globalAnchor.openDialog(
      DynamicPolicyDialogComponent,
      '',
      dialogOptions, undefined, (result: boolean) => {
        if (result) {
          item.policyIdList = dialog.SelectedPolicyIds;
          item.policyName = dialog.SelectedPolicyNames;
          if (dialog.SelectedPolicyIds != '') {
            this.dPolicyError = false;
          }
          this.changeDetected();
        }
      });
    dialog.employeeId = this.m_employeeId;
    dialog.dynamicAccrualPolicy = this.setDynamicAccrualPolicy(item);
    dialog.accrualPolicyLookup = this.accrualPolicyLookup;
    if (dialog.initialized) {
      dialog.addCounter = this.addCounter;
      if (dialog.dynamicAccrualPolicy && dialog.dynamicAccrualPolicy.length == 0) {
        dialog.onAddPolicy();
      }
    }

  }

  private setDynamicAccrualPolicy(item: EmployeePolicyAssignment): DynamicAccrualPolicy[] {
    let dAccrualPolicy: DynamicAccrualPolicy[] = [];
    if (item.policyName != "") {
      if (item.policyName.indexOf("-") != -1) {
        let policyNameArr = item.policyName.split('-');
        if (policyNameArr && policyNameArr.length > 0) {
          for (var i = 0; i < policyNameArr.length; i++) {
            this.addCounter++;
            let accrualPolicy: DynamicAccrualPolicy = new DynamicAccrualPolicy();
            accrualPolicy.id = this.addCounter;
            accrualPolicy.policyName = policyNameArr[i].trim();
            accrualPolicy.isError = false;
            let acType = _.find(this.accrualPolicyLookup, ['policyName', policyNameArr[i].trim()]);
            accrualPolicy.accrualType = _.first(acType.rules).accrualTypeName;
            accrualPolicy.policyId = acType.id.toString();
            dAccrualPolicy.push(accrualPolicy);
          }
        }
      }
      else {
        let accrualPolicy: DynamicAccrualPolicy = new DynamicAccrualPolicy();
        accrualPolicy.id = 1;
        accrualPolicy.policyName = item.policyName;
        accrualPolicy.isError = false;
        let acType = _.find(this.accrualPolicyLookup, ['policyName', item.policyName.trim()]);
        accrualPolicy.accrualType = _.first(acType.rules).accrualTypeName;
        accrualPolicy.policyId = acType.id.toString();
        dAccrualPolicy.push(accrualPolicy);
      }
    }
    return dAccrualPolicy;
  }


  public onChangedDate(item: EmployeePolicyAssignment): void {

    if (this.employeeSectionsPolicyAssignments.effectiveDate) {
      const range = moment.range(item.startDate, this.employeeSectionsPolicyAssignments.effectiveDate);
      let dateDiff = range.diff('days')
      if (dateDiff > 0) {
        let message = '';
        if (item.id == 0) {
          message = 'Assigning a new policy with start date prior to the last manual transaction date "' + moment(this.employeeSectionsPolicyAssignments.effectiveDate).format(appConfig.dateFormat) + '", may have an impact on accrual calculations. Would you like to continue?'
        }
        else {
          message = 'Editing an existing policy with start date prior to the last manual transaction date "' + moment(this.employeeSectionsPolicyAssignments.effectiveDate).format(appConfig.dateFormat) + '", may have an impact on accrual calculations. Would you like to continue?'
        }
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Warning',
          message,
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.validateGapExists(item, true);
            }
            else {
              this.setPreviousDates(item);
            }
          }, options);
      }
      else {
        this.validateGapExists(item, true);
      }
    }
    else {
      this.validateGapExists(item, true);
    }
  }

  private setPreviousDates(item: EmployeePolicyAssignment): void {
    let orgPA = _.find(this.originalPolicyAssignments.records, ['id', item.id]);
    item.startDate = new Date(orgPA.startDate);
    if (item.endDate) {
      item.endDate = new Date(orgPA.endDate);
      if (item.startDate > item.endDate) {
        this.dateError = true;
      } else {
        this.dateError = false;
      }
    }
    this.refreshGrid();
    this.changeDetected();

  }

  public onChangedEndDateEvent(item: EmployeePolicyAssignment, endDate: Date): void {
    const date = moment(endDate, 'MM/DD/YYYY');

    if (date.isValid()) {
      item.endDate = new Date(endDate);
      this.validateGapExists(item, false);
    }
  }

  public onChangedEndDate(item: EmployeePolicyAssignment): void {
    this.validateGapExists(item, false);
  }

  private validateGapExists(item: EmployeePolicyAssignment, isStartDate: boolean): void {
    this.dateError = false;
    if (item.endDate) {
      if (item.startDate > item.endDate) {
        this.dateError = true;
        return;
      }
    }

      let fromDate = new Date();
      let toDate = new Date();
      let currIndex = _.findIndex(this.employeeSectionsPolicyAssignments.records, ['id', item.id]);
      if (isStartDate) {
        fromDate = this.employeeSectionsPolicyAssignments.records[currIndex + 1].endDate
        toDate = item.startDate;
      }
      else {
        fromDate = this.employeeSectionsPolicyAssignments.records[currIndex - 1].startDate
        toDate = item.endDate;
      }
      fromDate = new Date(moment(fromDate).format(appConfig.dateFormat));
      toDate = new Date(moment(toDate).format(appConfig.dateFormat));

    let range:any;
    if (isStartDate)
      range = moment.range(fromDate, toDate);
    else
      range = moment.range(toDate, fromDate);
      let dateDiff = range.diff('days')
      if ((dateDiff > 1 && isStartDate) || (dateDiff > 1 && !isStartDate)) {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        let message = '';
        if (isStartDate) {
          message = 'Please review your changes. A gap exists from(' + moment(fromDate).format(appConfig.dateFormat) + ') to (' + moment(toDate).format(appConfig.dateFormat) + '). Do you wish to continue?';
        }
        else {
          message = 'Please review your changes. A gap exists from(' + moment(toDate).format(appConfig.dateFormat) + ') to (' + moment(fromDate).format(appConfig.dateFormat) + '). Do you wish to continue?';
        }
        ConfirmDialogComponent.openDialog(
          'Warning',
          message,
          this.modalService,
          (result: boolean) => {
            if (!result) {
              this.setPreviousDates(item);
            }


          }, options);
      }
  }
}




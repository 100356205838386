<slx-spinner [show]="isLoading" class="slx-add-open-shift-spinner">
    <div class="slx-shift-details" >
        <div class="add-shift-details"> Shift details for {{requestedDate | amDateFormat: appConfig.dateFormat
            }}</div>
        <div class="screen-message">
            <p>The numeric value entered in the New Shift Total Count field will replace the value in the Total Shifts column.
                This is a full replacement of the Total Shifts value and not an addition or subtraction of Open Shifts.</p>
        </div>
        <div class="row slx-no-margin">
            <div class="col-xs-12 col-sm-6 col-md-2">
                <p class="slx-no-margin">Position</p>
                <div class="new-shift">
                    <kendo-dropdownlist [data]="positionData" class="positionData " [(ngModel)]="openShift.position"
                        [filterable]="true" textField="name" valueField="id" (filterChange)="filterPosition($event)">
                    </kendo-dropdownlist>

                </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-2">
                <p class="slx-no-margin">Shift</p>
                <div class="new-shift">
                    <kendo-dropdownlist [data]="shiftsData" class="shiftsData" [(ngModel)]="openShift.Shifts"
                        [filterable]="true" textField="name" valueField="id" (filterChange)="filterShifts($event)">
                    </kendo-dropdownlist>

                </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-2">
                <p class="slx-no-margin">Unit</p>
                <div class="new-shift">
                    <kendo-dropdownlist [data]="unitData" class="unitData " [(ngModel)]="openShift.unit"
                        [filterable]="true" textField="name" valueField="id" (filterChange)="filterUnits($event)">
                    </kendo-dropdownlist>

                </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-2">
                <p class="slx-no-margin">Number of Shifts</p>
                <div class="new-shift">
                    <slx-number #countField="ngModel" class="slx-wide slx-input-number" [min]="newCount" [max]="max"
                        [slxMin]="newCount" [slxMax]="max" [decimals]="0" [autoCorrect]="true"
                        [(ngModel)]="openShift.count" name="countField">
                    </slx-number>
                </div>
            </div>
            <div class="col-xs-4 col-sm-2">
                <div class="new-shift pull-left">

                    <button type="button" class="slx-button slx-with-icon slx-add-shift  pull-right"
                        [disabled]="isNewOpenShift()" (click)="addNewOpenShift()">
                        <i class="fal fa-plus-circle" aria-hidden="true"></i>
                        &nbsp;&nbsp; Add Shift &nbsp;&nbsp;</button>
                </div>
            </div>
        </div>
        <div class="slx-row slx-create-shift">
            <span *ngIf="isMappedShift" class="slx-error-block slx-existing-shift pull-left">Values for the
                selected Position, Shift and Unit combination pre-exists below.</span>

        </div>
        
        <div>
            <div class="row header slx-content-toolbar-indents addShift-toolbar"></div>
        </div>
        <div>
            <kendo-grid
                class="slx-full-height slx-blue-grid slx-grid-slim-rows slx-full-height slx-blue-grid openshift-details shift-grid"
                #openShiftGridView 
                [data]="openShiftGrid.view" 
                [sortable]="{ mode: 'multiple' }"
                [sort]="openShiftGrid.state.sort" 
                [groupable]="false"
                [group]="openShiftGrid.state.group"
                [pageSize]="openShiftPageSize"
                [rowSelected]="isRowSelected"
                [selectable]="{  mode: 'multiple' }"  
                (dataStateChange)="dataStateChange($event)"
                filterable="menu"
                [filter]="state.filter" [resizable]="true">

                <kendo-grid-checkbox-column class="k-text-center" width="40"    >
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <input type="checkbox" *ngIf="!dataItem.isAdded" class="k-checkbox multiSelectCheck"
                            [disabled]="isUpdatedShiftCount(dataItem)" (change)="shiftChange(dataItem)"
                            [kendoGridSelectionCheckbox]="rowIndex">
                    </ng-template>
                </kendo-grid-checkbox-column>
                <kendo-grid-column title="Position" field="position.name" width="100" >
                    <ng-template kendoGridHeaderTemplate>Position</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem?.position?.name}}</ng-template>

                </kendo-grid-column>

                <kendo-grid-column title="Shift" field="Shifts.name" width="80" >
                    <ng-template kendoGridHeaderTemplate>Shift</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem?.Shifts?.name}}</ng-template>

                </kendo-grid-column>

                <kendo-grid-column title="Unit" field="unit.name" width="80" >
                    <ng-template kendoGridHeaderTemplate>Unit</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem?.unit?.name}}</ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                      </ng-template>
                </kendo-grid-column> 
                <kendo-grid-column title="Shifts Assigned" field="scheduledShiftCount" width="100" filter="numeric">
                    <ng-template kendoGridHeaderTemplate>Shifts Assigned</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.scheduledShiftCount}}</ng-template>
                </kendo-grid-column>
                 <kendo-grid-column title="Shifts Overage" field="overScheduleCount" width="100" filter="numeric">
                    <ng-template kendoGridHeaderTemplate>Shifts Overage</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.overScheduleCount}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Scheduled Count" field="pendingEmployeeRequests" [width]="pendingShiftsColWidth" filter="numeric">
                    <ng-template kendoGridHeaderTemplate>Open Shifts Pending with Employees</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.pendingEmployeeRequests}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Agency Pending Requests" field="pendingAgencyRequests" [width]="agencyColWidth" *ngIf="isEnabled"  filter="numeric">
                    <ng-template kendoGridHeaderTemplate>Open Shifts Pending with Agency</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.pendingAgencyRequests}}</ng-template>
                </kendo-grid-column>
             
                <kendo-grid-column title="UnAssigned Open Shift" field="openShiftCount" width="120"  filter="numeric">
                    <ng-template kendoGridHeaderTemplate>Unassigned Shifts Open</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>{{getUnassignedShiftCount(dataItem)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="TotalShift" field="totalShifts" [width]="totalShiftsColWidth" filter="numeric">
                    <ng-template kendoGridHeaderTemplate>Total Shifts</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>{{getTotalShift(dataItem)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Open" field="count" width="90" [filterable]="false">
                    <ng-template kendoGridHeaderTemplate>New Shift Total Count
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <slx-number #countField="ngModel" class="slx-wide slx-input-number" [min]="min" [max]="max"
                            [slxMin]="min" [slxMax]="max" [decimals]="0" [autoCorrect]="true"
                            [(ngModel)]="dataItem.count" name="countField">
                        </slx-number>
                     
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Message" field="message" width="90"    [filterable]="false">
                    <ng-template kendoGridHeaderTemplate>Message</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem><span *ngIf ="getDisplayMessage(dataItem)">Shift Total Count cannot go below the sum of the Assigned and Pending Shifts.  Please review and manage the Shifts Pending on the Open Shift Management screen.</span> </ng-template>
                                  </kendo-grid-column>
            </kendo-grid>
        </div>
        <div class="row">
            <div class="col col-exception">
                <button type="button" class="slx-button slx-with-icon add-shift pull-right"
                    [disabled]="isShiftSelected()" (click)="addOpenShifts()">
                    <i class="fal fa-plus-circle" aria-hidden="true"></i>
                    &nbsp;&nbsp; &nbsp;&nbsp;Add Selected Shift(s)&nbsp;&nbsp;</button>
            </div>
        </div>

        <div class="row header slx-content-toolbar-indents addShift-toolbar"></div>
        <div class="no-selected-shift" *ngIf="!isSelectedShift">
            No shift has been added
        </div>
        <div class="added-shifts-header" *ngIf="isSelectedShift">Review Added Shift(s)</div>
        <div class="slx-full-height slx-blue-grid slx-grid-slim-rows slx-full-height slx-blue-grid added-shift"
            *ngIf="isSelectedShift">
            <kendo-grid #grid class="addedShift-grid" [data]="gridState.view" [sortable]="{ mode: 'multiple' }"
                [sort]="gridState.state.sort" [groupable]="false" [group]="gridState.state.group" [pageSize]="pageSize"
                [skip]="gridState.state.skip" (pageChange)="gridState.pageChange($event)"
                (dataStateChange)="gridState.dataStateChange($event)" (groupChange)="gridState.groupChange($event)">

                <kendo-grid-column title="Position" field="positionName" [width] ="getPositionWidth">
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.position.name}}</ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="Shift" field="shiftName" width="90">
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.Shifts.name}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Unit" field="unitName" width="90">
                    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.unit.name}}</ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Number of shifts" field="count" width="90">
                    <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.count}} </ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-number-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="Action" title="" width="80" filterable="false">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <button (click)="deleteHandler(dataItem)"
                            class="slx-button slx-grey slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks grid-btn-pad">
                            <i class="fas fa-ban" aria-hidden="true"></i> </button>
                    </ng-template>

                </kendo-grid-column>
                <kendo-grid-messages filterMenuTitle="Filter">
                </kendo-grid-messages>  
            </kendo-grid>
        </div>
        <div class="row header slx-content-toolbar-indents addShift-toolbar"></div>

        <div class="row" *ngIf="isSelectedShift">
            <div class="sm-col-2"> <button type="button" (click)="onAdd()"
                    class="btn create-open-shifts slx-addOpen-shiftButton">Add Open Shift</button>
            </div>
            <div class="sm-col-2">
                <button type="button" (click)="onCancel()"
                    class="btn btn-default slx-addOpen-shiftButton cancel">Discard</button>
            </div>
        </div>
    </div>
</slx-spinner>
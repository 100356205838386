import { Component, OnInit, OnDestroy, Input, Provider } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import * as moment from 'moment';
import { Subscription ,  Observable } from 'rxjs';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';

import {
  ArrivalDeparturesContainer, AttendanceTypeDefinition, ArrivalsDeparturesAnalyticsDefinition, AttendanceType, ArrivalsDeparturesAnalytics, Arrival
} from '../../../models/index';

import { ArrivalDeparturesManagementService } from '../../../services/index';
import * as _ from 'lodash';
@Component({
  moduleId: module.id,
  selector: 'slx-arrivals-widget',
  templateUrl: 'arrivals-widget.component.html',
  styleUrls: ['arrivals-widget.component.scss']
})
export class ArrivalsWidgetComponent implements OnInit, OnDestroy {
  public appConfig: IApplicationConfig;

  @Input()
  public dateOn: Date;
  public isLoading: boolean;
  public arrivalDeparturesContainer: ArrivalDeparturesContainer;
  public lateSum: number = 0;
  public recordsSum: number = 0;
  public collapsed: boolean;

  private arrivalDeparturesManagementService: ArrivalDeparturesManagementService;

  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;

  private activatedRoute: ActivatedRoute;
  private router: Router;

  constructor(activatedRoute: ActivatedRoute, router: Router, arrivalDeparturesManagementService: ArrivalDeparturesManagementService) {
    Assert.isNotNull(arrivalDeparturesManagementService, 'budgetManagementService');
    this.arrivalDeparturesManagementService = arrivalDeparturesManagementService;
    this.activatedRoute = activatedRoute;
    this.router = router;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.loadStatusSubscription = this.arrivalDeparturesManagementService.onLoadStatus$.subscribe((val: boolean) => { this.isLoading = val; });

    this.loadedSubscription = this.arrivalDeparturesManagementService.onLoaded$.subscribe(
      (container: ArrivalDeparturesContainer) => {
        this.arrivalDeparturesContainer = container;
        this.recordsSum = container.arrivals ? container.arrivals.length : 0;
        this.lateSum = _.sumBy(this.arrivalDeparturesContainer.arrivals, (item: Arrival) => item.late);
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onInFieldClickHandler(arrival: Arrival): void {
    if (arrival.arrivalTime) {
      this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.in, this.dateOn, arrival.arrivalTime);
    } else if (!arrival.arrivalTime && arrival.scheduled === 0) {
      this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.unscheduled, this.dateOn);
    }
  }

  public onScheduledFieldClickHandler(arrival: Arrival): void {
    this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.scheduled, this.dateOn, arrival.arrivalTime);
  }

  public onLateFieldClickHandler(arrival: Arrival): void {
    this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.late, this.dateOn, arrival.arrivalTime);
  }

  public onDifferenceFieldClickHandler(arrival: Arrival): void {
    if (arrival.arrivalTime) {
      this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.difference, this.dateOn, arrival.arrivalTime);
    } else if (!arrival.arrivalTime && arrival.scheduled === 0) {
      this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.unscheduled, this.dateOn);
    }
  }

  public onRowSelectedHandler(arrivals: Arrival[]): void {
    const start = _.first(arrivals);
    const end = _.last(arrivals);
    if (start && end) {
      this.arrivalDeparturesManagementService.onArrivalRangeChanged({ startDate: start.arrivalTime, endDate: end.arrivalTime });
    } else {
      this.arrivalDeparturesManagementService.onArrivalRangeChanged(null);
    }
  }

  public toggleState(): void {
    this.collapsed = !this.collapsed;
  }

  private navigateToArrivalsDeparturesDetails(attendanceTypeDefinition: AttendanceTypeDefinition, arrivalsDeparturesAnalyticsDefinition: ArrivalsDeparturesAnalyticsDefinition, dateOn: Date, time: Date = null): void {
    let params: Params = undefined;
    if (time) {
      params = {
        date: moment(dateOn).format(appConfig.linkDateFormat),
        time: moment(time).format(appConfig.linkDateTimeFormat),
        attendance_type: attendanceTypeDefinition,
        analytics: arrivalsDeparturesAnalyticsDefinition
      };
    } else {
      params = {
        date: moment(dateOn).format(appConfig.linkDateFormat),
        attendance_type: attendanceTypeDefinition,
        analytics: arrivalsDeparturesAnalyticsDefinition
      };
    }
    this.router.navigate(['arrivals_departures_details'], { relativeTo: this.activatedRoute.pathFromRoot[2], queryParams: params });
  }
}

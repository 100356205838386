<form #form="ngForm">
    <div class="editor-container">

        <div class="field-container wrapped">
            <div class="col">
                <slx-input-decorator>
                    <slx-dropdown-input slx-input [options]="positionsFiltered" titleField="name" name="positions" placeholder="Reassign to position"
                        [(ngModel)]="position" [required]="true">
                    </slx-dropdown-input>
                    <span errorMessage for="required">Please, select position</span>
                </slx-input-decorator>

                <slx-input-decorator>
                  <slx-date-picker-ngx slx-input
                    placeholder="Effective Date"
                    name="effectiveDate"
                    [required]="true"
                    [maxDate]="currentDate"
                    [(ngModel)]="effectiveDate"
                  ></slx-date-picker-ngx>
                </slx-input-decorator>


            </div>

        </div>

        <div class="actions-bar">
            <div class="col"></div>
            <button class="action-button save-btn" (click)="onOk ()" [disabled]="form.invalid">Reassign</button>
            <button class="action-button cancel-btn" (click)="onCancel ()">Cancel</button>
        </div>

    </div>
</form>

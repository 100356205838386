import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MomentModule } from 'angular2-moment';
import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { NgxPopperModule } from 'ngx-popper';
import { ComponentsLibraryModule } from '../../components-library/index';

import { components, entryComponents, exportComponents } from './components/index';
import { services } from './services/index';
import { EmployeeModule } from '../../employee/employee/index';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        MomentModule,
        GridModule,
        PDFModule,
        ExcelModule,
        DropDownsModule,
        LayoutModule,
        OrganizationModule,
        EmployeeModule,
        CommonAppModule,
        NgxPopperModule,
        ComponentsLibraryModule
    ],
    declarations: [
        ...components,
    ],
    providers: [
        ...services,
    ],
    exports: [
        ...exportComponents
    ]
})
export class PerformanceManagementModule { }

import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'slx-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss']
})
export class SpinnerComponent {
  @Input()
  public show: boolean;
  @Input()
  public showMessage:boolean = false;
  @Input()
  public heightNotFill: boolean = false; //100% not works with flex in Safari, but I am worry about other places
  @Input()
  public onlyLock: boolean = false;

}


import {first} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, NavigationExtras,
    Params, Resolve, UrlSegment
} from '@angular/router';

import { OrgLevel } from '../../../state-model/models/index';
import { PayCycle } from '../../../organization/models/lookup/index';
import { appConfig } from '../../../app.config';
import { RangeDates } from '../../../common/models/range-dates';
import { BaseResolver } from '../../../common/index';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';
import { PayCycleHelperService } from '../pay-cycle-helper/pay-cycle-helper.service';

@Injectable()
export class PayCycleResolver extends BaseResolver<PayCycle> implements Resolve<PayCycle> {

    constructor(
        router: Router,
        private busService: ApplicationStateBusService,
        private payCycleHelper: PayCycleHelperService) {
        super(router);
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<PayCycle> {

        let range: RangeDates = this.getRangeDates(route);

        if (!range.startDate) range.startDate = moment().startOf('day').toDate();

        return this.getOrgLevel().then((orgLevel: OrgLevel) => {
            return this.payCycleHelper.getPayCycleByDate(range.startDate, orgLevel.id).then((payCycle: PayCycle) => {
                if (payCycle) {
                    if (!this.validateRangeDates(range, payCycle)) {
                        range.startDate = payCycle.startDate;
                        range.endDate = payCycle.endDate;
                        this.navigateToPath(route, this.createQueryParams(route, range));
                    }
                }
                return payCycle;
            });
        });
    }

    public createQueryParams(route: ActivatedRouteSnapshot, range: RangeDates): Params {
        const params: Params = super.createQueryParams(route);
        params['startDate'] = moment(range.startDate).format(appConfig.linkDateFormat);
        params['endDate'] = moment(range.endDate).format(appConfig.linkDateFormat);
        return params;
    }

    protected getOrgLevel(): Promise<OrgLevel> {
        let promise: Promise<OrgLevel> = this.busService.orgLevelSelected$.pipe(first()).toPromise();
        return promise;
    }

    protected validateRangeDates(range: RangeDates, payCycle: PayCycle): boolean {
        return !moment(range.startDate).isSame(payCycle.startDate) || !moment(range.endDate).isSame(payCycle.endDate);
    }

    protected getRangeDates(route: ActivatedRouteSnapshot): RangeDates {

        let startDateParam: string = _.toString(route.queryParamMap.get('startDate'));
        let endDateParam: string = _.toString(route.queryParamMap.get('endDate'));

        let startDate: Date = startDateParam ? moment(startDateParam, appConfig.linkDateFormat).toDate() : null;
        let endDate: Date = endDateParam ? moment(endDateParam, appConfig.linkDateFormat).toDate() : null;

        let range: RangeDates = new RangeDates();
        range.startDate = startDate;
        range.endDate = endDate;

        return range;
    }

}


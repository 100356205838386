
import {of as observableOf, from as observableFrom,  Observable } from 'rxjs';

import {catchError, map, mergeMap, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IPayloadAction } from '../../../state-model/models/index';
import { WizardActions } from '../../../common/store/index';
import { AddEmployeeWizardActions } from '../store/add-employee-wizard/add-employee-wizard.actions';
import { AddEmployeeModel } from '../models/index';
import { EmployeeActivitiesApiService } from '../services/employee-activities/employee-add-api.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../store/store';
import { AddEmployeeWizardStatus } from '../store/add-employee-wizard/add-employee-wizard.types';
import { ValidationError } from '../../../core/models/index';

@Injectable()
export class AddEmployeeWizardStateEpics {

  private employeeActivitiesApiService: EmployeeActivitiesApiService;
  private ngRedux: NgRedux<IAppState>;
  constructor(employeeActivitiesApiService: EmployeeActivitiesApiService, ngRedux: NgRedux<IAppState>) {
    this.employeeActivitiesApiService = employeeActivitiesApiService;
    this.ngRedux = ngRedux;
  }

  public addEmployee = action$ => action$.pipe(
    filter(({ type }: { type: string }) => type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE),
    mergeMap((action: IPayloadAction) => {
      const model: AddEmployeeModel = action.payload;
      return observableFrom(this.employeeActivitiesApiService.addNewEmployee(model)).pipe(
        map((response: any) => this.getEmployeeStatusForSuccess(response, model)),
        catchError((error: any) => this.getEmployeeStatusForError(error)
      ));
    }));
    
/*   public addEmployee = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const addEmployee$: Observable<IPayloadAction> = action$.pipe(filter(({ type }: { type: string }) => type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE));
    return addEmployee$.pipe(mergeMap((action: IPayloadAction) => {
        const model: AddEmployeeModel = action.payload;
        return observableFrom(this.employeeActivitiesApiService.addNewEmployee(model)).pipe(
          map( (response: any) => {
            return {
              type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_SUCCESS,
              payload: {
                status: AddEmployeeWizardStatus.success,
                newEmpId: response.id,
                payrollNumber : response.payrollNumber,
                model: {...model, payrollNumber : response.payrollNumber}
              }
            };
          }),
          catchError((error: any) => {
            if (error instanceof ValidationError) {
              return observableOf(
                {
                  type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_VALIDATION_ERROR,
                  payload: {
                    status: AddEmployeeWizardStatus.validationFailed
                  }
                });
            }
            return observableOf(
            {
              type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_ERROR,
              payload: {
                status: AddEmployeeWizardStatus.error,
                newEmpId: 0
              }
            });
          }),);
      }));
  } */

  private getEmployeeStatusForSuccess(response: any, model: AddEmployeeModel): IPayloadAction {
    return {
      type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_SUCCESS,
      payload: {
        status: AddEmployeeWizardStatus.success,
        newEmpId: response.id,
        payrollNumber : response.payrollNumber,
        model: {...model, payrollNumber : response.payrollNumber}
      }
    };    
  }

  private getEmployeeStatusForError(error: any): Observable<IPayloadAction> {
    if (error instanceof ValidationError) {
      return observableOf(
        {
          type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_VALIDATION_ERROR,
          payload: {
            status: AddEmployeeWizardStatus.validationFailed
          }
        });
    }
    return observableOf(
    {
      type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_ERROR,
      payload: {
        status: AddEmployeeWizardStatus.error,
        newEmpId: 0
      }
    });
  }

  public addEmployeeFinish = action$ => action$.pipe(
    filter(({ type }: { type: string }) => type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_SUCCESS ||  
                                           type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_ERROR),
    mergeMap((action: IPayloadAction) => {
      return observableOf(
      {
        type: WizardActions.SELECT_NEXT_STEP,
        payload: null
      });
    }));

/*   public addEmployeeFinish = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const addEmployee$: Observable<IPayloadAction> = action$.pipe(filter(({ type }: { type: string }) =>
    type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_SUCCESS ||  type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_ERROR));
    return addEmployee$.pipe(mergeMap((action: IPayloadAction) => {
            return observableOf(
            {
              type: WizardActions.SELECT_NEXT_STEP,
              payload: null
            });
      }));
  } */
}

<form novalidate #form="ngForm">

    <div class="col-sm-6 col-lg-3 textareaHeight" *ngIf="specialConsideration">
      <slx-input-decorator className="slx-area-input slx-no-arearesize">
        <textarea  slx-input type="text" maxlength="50" [readonly]="!state.isEditMode" placeholder="Special Consideration" name="conField"
        [(ngModel)]="specialConsideration.fieldValue" [inputPolicy]="specialConsideration.Policy"></textarea>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3 textareaHeight" *ngIf="specialConsideration2">
      <slx-input-decorator className="slx-area-input slx-no-arearesize">
        <textarea slx-input type="text" maxlength="50" [readonly]="!state.isEditMode" name="conField2" placeholder="Special Consideration II"
        [(ngModel)]="specialConsideration2.fieldValue" [inputPolicy]="specialConsideration2.Policy"></textarea>
      </slx-input-decorator>
    </div>
    
      <div class="col-sm-3 col-lg-3 textareaHeight" *ngFor="let field of employeeSectionsCustomFields?.fields;let idx=index;">
        <div [ngSwitch]="field?.dataType.toLowerCase()">
         
            <slx-input-decorator *ngSwitchCase="'date'">

              <div class="date-field-wrapper" *ngIf="field?.fieldData.Policy.isEditable">

<!--                 <input slx-date-picker slx-input #dateType="ngModel" [placeholder]="field.displayName" name="{{field.displayName + idx}}"  disabled="true"
                [(ngModel)]="field?.fieldData.fieldValue" format="MM/dd/yyyy" [inputPolicy]="field?.fieldData.Policy"
                [readonly]="!state.isEditMode" (ngModelChange)="onChangeDate($event)"> -->
   
                <slx-date-picker-ngx slx-input
                #dateType="ngModel"
                name="{{field.displayName + idx}}"
                placeholder="{{field.displayName}}"
                [(ngModel)]="field?.fieldData.fieldValue"
                [inputPolicy]="field?.fieldData.Policy"
                [readonly]="!state.isEditMode"
                (ngModelChange)="onChangeDate($event)"
                calendarType="classic"
              ></slx-date-picker-ngx>                

              </div>
              
              <textarea slx-input [readonly]="true" type="text" maxlength="50" [inputPolicy]="field?.fieldData.Policy" 
              *ngIf="!field?.fieldData.Policy.isEditable"  style="resize: none;"
              [(ngModel)]="field?.fieldData.fieldValue" [placeholder]="field.displayName"
              name="{{field.displayName + idx}}"></textarea>

              <div class="slx-error-block margintop10" [ngClass]="{'disableError' : !isInvalidData}" *ngIf="state.isEditMode && isInvalidData">
                Date format should be MM/DD/YYYY
              </div>

              <div class="margintop20" *ngIf="state.isEditMode && !isInvalidData && field?.fieldData.fieldValue != null">
                <i class="fas trashIcon fa-trash-alt"
                  (click)="removeFieldValue(field)"
                  aria-hidden="true"></i>
              </div>

            </slx-input-decorator>


          <slx-input-decorator *ngSwitchCase="'int'">
            <input slx-input [readonly]="!state.isEditMode" [inputPolicy]="field?.fieldData.Policy" type="number"
              [(ngModel)]="field?.fieldData.fieldValue" class="k-textbox" [placeholder]="field.displayName"
              name="{{field.displayName + idx}}" />
              <div class="slx-error-block margintop10" id="integerErrorMsg" *ngIf="checkIntLength(field)">
                Maximum length should be 2147483647
            </div>
          </slx-input-decorator>
          <slx-input-decorator *ngSwitchCase="'decimal'">
            <input slx-input [readonly]="!state.isEditMode" [inputPolicy]="field?.fieldData.Policy" type="number"
              [(ngModel)]="field?.fieldData.fieldValue" class="k-textbox" [placeholder]="field.displayName"
              name="{{field.displayName + idx}}" />
              <div class="slx-error-block margintop10" id="decimalErrorMsg" *ngIf="checkDecimalLength(field)">
                Maximum upto 999,999.00 & Decimal upto {{field.dataTypePrecision}}
            </div>
          </slx-input-decorator>
          <slx-input-decorator *ngSwitchCase="'datetime'" className="slx-no-background">
            <input slx-input [readonly]="!state.isEditMode" [inputPolicy]="field?.fieldData.Policy" 
            *ngIf="!field?.fieldData.Policy.isEditable"
              [(ngModel)]="field?.fieldData.fieldValue" class="k-textbox" [placeholder]="field.displayName"
              name="{{field.displayName + idx}}" />
            
            <input *ngIf="field?.fieldData.Policy.isEditable" slx-date-time-picker slx-input name="{{field.displayName}}" [placeholder]="field.displayName"
              [(ngModel)]="field?.fieldData.fieldValue" [inputPolicy]="field?.fieldData.Policy" [readonly]="!state.isEditMode">
          </slx-input-decorator>
          <slx-input-decorator *ngSwitchCase="'bit'">
            <slx-dropdown-input slx-input name="{{field.displayName}}" [readonly]="!state.isEditMode"
              [options]="bitValues" placeholder="{{field.displayName}}" [inputPolicy]="field?.fieldData.Policy" [(ngModel)]="field?.fieldData.fieldValue">
            </slx-dropdown-input>
          </slx-input-decorator>
          <slx-input-decorator *ngSwitchCase="'char'">
            <slx-dropdown-input slx-input name="{{field.displayName}}" [readonly]="!state.isEditMode"
              [options]="charValues" placeholder="{{field.displayName}}" [inputPolicy]="field?.fieldData.Policy" [(ngModel)]="field?.fieldData.fieldValue">
            </slx-dropdown-input>
          </slx-input-decorator>
          <slx-input-decorator *ngSwitchDefault className="slx-area-input slx-no-arearesize">
            <textarea slx-input type="text" [inputPolicy]="field?.fieldData.Policy" maxlength="{{field?.dataTypeLength}}" [readonly]="!state.isEditMode" name="{{field.displayName}}" [placeholder]="field.displayName"
              [(ngModel)]="field?.fieldData.fieldValue"></textarea>
          </slx-input-decorator>
        </div>
      </div>
</form>

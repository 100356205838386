<form [formGroup]="formGroup">
  <div class="slx-el-editor-container">
    <div class="col-xs-12 col-md-4">
      <slx-input-decorator>
        <slx-dropdown-input slx-input required="true" [lookup]="organizationLookup" name="organization" placeholder="Organization"
          formControlName="organization">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="col-xs-12 col-md-4">
      <slx-input-decorator>
        <slx-dropdown-input slx-input required="true" [readonly]="!item.organization" [lookup]="departmentLookup" name="department" placeholder="Department"
          formControlName="department" (ngModelChange)="loadModels()">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="col-xs-12 col-md-4">
      <slx-input-decorator>
        <input slx-input required="true" formControlName="name"  name="name" placeholder="Timeclock Name">
        <span errorMessage for="required"></span>
        <span errorMessage for="uniqueTimeclock">Timeclock with this name, Physical Id and ID already exist</span>
      </slx-input-decorator>
    </div>
    <div class="col-xs-12 col-md-4">
      <slx-input-decorator>
        <slx-dropdown-input slx-input required="true" [readonly]="!item.organization" [lookup]="clockModelLookup" name="model" placeholder="Timeclock Model"
          formControlName="model">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="col-xs-12 col-md-2">
      <slx-input-decorator>
        <slx-kendo-number slx-input number
          [decimals]="2"
          [step]="1"
          [required]="true"
          [format]="'#'"
          placeholder="Timeclock Id"
          formControlName="id"
          name="id"
        ></slx-kendo-number>
        <span errorMessage for="number"></span>
        <span errorMessage for="required"></span>
        <span errorMessage for="uniqueTimeclock">Timeclock with this name, Physical Id and ID already exist</span>
      </slx-input-decorator>
    </div>
    <div class="col-xs-12 col-md-2">
      <slx-input-decorator>
        <slx-kendo-number slx-input number
          [decimals]="2"
          [step]="1"
          [required]="true"
          [format]="'#'"
          placeholder="Physical Id"
          formControlName="physicalId"
          name="physicalId"
        ></slx-kendo-number>
        <span errorMessage for="number"></span>
        <span errorMessage for="required"></span>
        <span errorMessage for="uniqueTimeclock">Timeclock with this name, Physical Id and ID already exist</span>
      </slx-input-decorator>
    </div>
    <div class="col-xs-12 col-md-2">
      <slx-input-decorator>
        <slx-ipv4-input slx-input formControlName="ipaddress" name="ipaddress" placeholder="Ip Address"></slx-ipv4-input>
        <span errorMessage for="ipv4"></span>
      </slx-input-decorator>
    </div>
    <div class="col-xs-12 col-md-2">
      <slx-input-decorator className="slx-no-border slx-small-font slx-no-padding">
        <slx-checkbox-input slx-input name="isVirtual" fieldName="isMessagesEnabled" formControlName="isMessagesEnabled" placeholder="Is Messages Enabled"
          caption=""></slx-checkbox-input>
      </slx-input-decorator>
    </div>

    <div class="col-xs-12 col-md-6" [ngClass]="{'msg-hidden': !isMessagesEnabled}">
      <slx-input-decorator>
        <input slx-input maxlength="32" formControlName="cmdMessageLine1" name="cmdMessageLine1" placeholder="CMD Message Line1">
      </slx-input-decorator>
    </div>
    <div class="col-xs-12 col-md-6" [ngClass]="{'msg-hidden': !isMessagesEnabled}">
      <slx-input-decorator>
        <input slx-input maxlength="32" formControlName="cmdMessageLine2" name="cmdMessageLine2" placeholder="CMD Message Line2">
      </slx-input-decorator>
    </div>
    <div class="col-xs-12 col-md-6" [ngClass]="{'msg-hidden': !isMessagesEnabled}">
      <slx-input-decorator>
        <input slx-input maxlength="32" formControlName="cmdMessageLine3" name="cmdMessageLine3" placeholder="CMD Message Line3">
      </slx-input-decorator>
    </div>
    <div class="col-xs-12 col-md-6" [ngClass]="{'msg-hidden': !isMessagesEnabled}">
      <slx-input-decorator>
        <input slx-input maxlength="32" formControlName="cmdMessageLine4" name="cmdMessageLine4" placeholder="CMD Message Line4">
      </slx-input-decorator>
    </div>

    <div class="col-xs-12 col-md-3">
      <slx-input-decorator>
        <slx-dropdown-input slx-input required="true" [lookup]="timezoneLookup" name="timezone" placeholder="Timezone" formControlName="timezone">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>

    <div class="col-xs-12 col-md-1">
      <slx-input-decorator className="slx-no-border slx-small-font slx-no-padding">
        <slx-checkbox-input slx-input name="isVirtual" fieldName="isVirtual" formControlName="isVirtual" placeholder="Is Virtual" (click)="onIsVirtualClick()"
          caption=""></slx-checkbox-input>
      </slx-input-decorator>
    </div>

    <div class="col-xs-12 col-md-2" *ngIf="canSetOpenShiftRequests">
      <slx-input-decorator className="slx-no-border slx-small-font slx-no-padding">
        <slx-checkbox-input slx-input name="openShiftRequestsEnabled" fieldName="openShiftRequestsEnabled" formControlName="openShiftRequestsEnabled" placeholder="Enable Open Shift Requests"
          caption=""></slx-checkbox-input>
      </slx-input-decorator>
    </div>

    <div class="col-xs-12 flex-horizontal">
      <button class="slx-el-action-button" (click)="onSaveClick ($event)" [disabled]="formGroup.invalid">Save</button>
      <button class="slx-el-action-button" (click)="onCancelClick ($event)">Cancel</button>
    </div>
  </div>
</form>

<slx-spinner [show]="isLoading">
  <div class="model-header">
    <div class="col-xs-12 ">
      <button type="button" class="slx-button pull-left slx-blue generate-button" (click)="toggleSsnVisibility()">
        <span class="slx-button__text">{{ isSsnVisible ? 'Hide SSN' : 'Show SSN' }}</span>
      </button>
      <button type="button" class="slx-button pull-right slx-blue generate-button" [disabled]=isButtonEnable
        (click)="showGenerate1094c()">
        <span class="slx-button__text">Generate 1094-Cs PDF</span>
      </button>
      <button type="button" class="slx-button pull-right slx-blue generate-button" [disabled]=isButtonEnable
        (click)="showGenerate1095c()">
        <span class="slx-button__text">Generate 1095-Cs PDF</span>
      </button>
      <button type="button" class="slx-button pull-right slx-blue generate-button" [disabled]=isButtonEnable
        (click)="showGenerateXML()">
        <span class="slx-button__text">Generate Corrected XML</span>
      </button>
    </div>
  </div>

  <div class="modal-body">
    <kendo-grid class="slx-full-height slx-blue-grid aca-export-data-grid" [data]="gridState.view"
      [sortable]="{ mode: 'multiple' }" [sort]="gridState.state.sort" [filterable]="'menu'"
      [filter]="gridState.state.filter" [scrollable]="'scrollable'" [skip]="gridState.state.skip"
      (filterChange)="filterChange($event)" (dataStateChange)="gridState.dataStateChange($event)">

      <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" field="isSelected" [locked]="false"
        width="40px">
        <ng-template kendoGridHeaderTemplate>
          <slx-checkbox-button class="slx-no-indents" [(ngModel)]="isAllSelected"
            (ngModelChange)="onToggleAllSelected()">
          </slx-checkbox-button>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <slx-checkbox-button class="slx-no-indents" [(ngModel)]="dataItem.isSelected"
            (ngModelChange)="onCheckedEmployee(dataItem.isSelected)">
          </slx-checkbox-button>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Name" field="employeeName" [sortable]="true" width="180">
        <ng-template kendoGridHeaderTemplate>
          Name
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [title]="dataItem.employeeName">{{dataItem.employeeName}}</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Record ID" field="recordId" [filterable]="true" [sortable]="true" width="75">
        <ng-template kendoGridHeaderTemplate>
          Record ID
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [title]="dataItem.recordId">{{dataItem.recordId}}</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="SSN in File" field="ssn" [filterable]="true" width="80">
        <ng-template kendoGridHeaderTemplate>
          SSN in File
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{getSSN(dataItem.ssn)}}</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="SSN Match" field="ssnStatus" [filterable]="false" width="80">
        <ng-template kendoGridHeaderTemplate>
          SSN Match
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div *ngIf="dataItem.ssnStatus===1" [title]="dataItem.ssnStatus">OK</div>
          <div *ngIf="dataItem.ssnStatus===2" [title]="dataItem.ssnStatus">
            <a (click)="matchRecord(dataItem)">No Match</a>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-messages filterMenuTitle="Filter">
      </kendo-grid-messages>  
    </kendo-grid>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn grey" (click)="onBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>Back
    </button>
    <button type="button" class="btn red" (click)="onCancel()">
      <i class="fa fa-times" aria-hidden="true"></i>Cancel
    </button>
  </div>
</slx-spinner>
<div>
  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
    <div *ngFor="let detailsItem of details?.details; let detailsItemIndex = index" class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingOne">
        <h4 class="panel-title">
          <a role="button" class="messages-host" data-toggle="collapse" data-parent="#accordion" [attr.href]="'#' + detailsItemIndex" aria-expanded="true" [attr.aria-controls]="detailsItemIndex">
            <div class="info-host">
              <span class="theme-md-text">{{detailsItem?.position?.name}}, {{detailsItem?.shift?.name}}, {{detailsItem?.unit?.name}}</span>
            </div>
            <div class="message-state-host">
              <span class="fa-stack fa-lg">
                <i class="far fa-stack-2x icon-info" aria-hidden="true" [ngClass]="{
                    'fa-circle': detailsItem?.newMessageCount > 0,
                    'fa-square': detailsItem?.shiftCount > 0,
                    'fa-check-circle': detailsItem?.shiftCount == 0
                  }"></i>
                <strong *ngIf="detailsItem?.shiftCount > 0" class="fa-stack-1x theme-exsm-text">{{detailsItem?.shiftCount}}</strong>
              </span>
              <div *ngIf="detailsItem?.newMessageCount > 0">
                <span class="fa-stack fa-1x">
                <i class="far fa-envelope fa-stack-2x messages-icon" aria-hidden="true"></i>
                <span class="badge  theme-exsm-text">{{detailsItem?.newMessageCount}}</span>
                </span>
              </div>
            </div>
            <div class="action-host">
              <div class="dropdown icon-action-menu">
                <button class="btn btn-circle dropdown-toggle btn-elipsis-blue" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <i class="fa fa-ellipsis-h fa-lg" aria-hidden="true"></i>
                          </button>
                <ul class="dropdown-menu" aria-labelledby="group-activities">
                  <li (click)="onEditOpenShiftsClick($event, detailsItem)"><a><i class="fas fa-pencil-alt" aria-hidden="true"></i> Edit Open Shifts count</a></li>
                  <!--<li><a><i class="far fa-envelope"></i> SMS Text Employee(s)</a></li>-->
                </ul>
              </div>
            </div>
            <div>
              <i class="control-host fa fa-angle-right fa-2x" aria-hidden="true"></i>
            </div>
          </a>
        </h4>
      </div>
      <div [attr.id]="detailsItemIndex" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
        <div class="panel-body">
          <slx-daily-shift-messages [details]="detailsItem"></slx-daily-shift-messages>
        </div>
      </div>
    </div>
  </div>
</div>

<kendo-grid class="slx-full-height slx-blue-grid export-data-grid"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [pageSize]="pageSize"
  [pageable]="false"
  (sortChange)="sortChange($event)"
  (filterChange)="filterChange($event)"
  [scrollable]="'scrollable'"
>
  <kendo-grid-column title="Status" locked="true" field="status" filter="status">
    <ng-template kendoGridHeaderTemplate>
      Status
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngIf="dataItem.status === exportDataStatus.Waiting" class="export-data-status export-data-waiting">
        <i class="fas fa-clock export-data-waiting icon" aria-hidden="true"></i>
        <span>Waiting</span>
      </div>
      <div *ngIf="dataItem.status === exportDataStatus.Completed || dataItem.status === exportDataStatus.Expired" class="export-data-status export-data-completed">
        <i class="fas fa-check-circle export-data-completed icon" aria-hidden="true"></i>
        <span>Completed</span>
      </div>
      <div *ngIf="dataItem.status === exportDataStatus.InProgress" class="export-data-status export-data-in-progress">
        <i class="fas fa-spinner fa-pulse export-data-in-progress icon" aria-hidden="true"></i>
        <span>In progress</span>
      </div>
      <div *ngIf="dataItem.status == exportDataStatus.Failed && dataItem.reason" class="export-data-status export-data-fail" 
        [popper]="failedReason" [popperTrigger]="'hover'" [popperPlacement]="'bottom-left'" [popperDisableStyle]="'true'"
        (click)="showReasonInfoPopup(dataItem)">
          <i class="fas fa-times-circle export-data-fail icon" aria-hidden="true"></i>
          <span>Failed</span>
          <popper-content #failedReason class="slx-popper">
            <div class="export-data-popper">
              <span>{{dataItem.reason}}</span>
            </div>
          </popper-content>
      </div>
      <div *ngIf="dataItem.status == exportDataStatus.Failed && !dataItem.reason" class="export-data-status export-data-fail">
        <i class="fas fa-times-circle export-data-fail icon" aria-hidden="true"></i>
        <span>Failed</span>
      </div>
      <div *ngIf="dataItem.status == exportDataStatus.Cancelled" class="export-data-status export-data-cancel">
        <i class="fas fa-times-circle export-data-cancel icon" aria-hidden="true"></i>
        <span>Cancelled</span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Executed By" field="executedBy" locked="false">
    <ng-template kendoGridHeaderTemplate>
      Executed By
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.executedBy}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Started On" field="startedOn" locked="false">
    <ng-template kendoGridHeaderTemplate>
      Started On
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.startedOn | amDateFormat: appConfig.dateTimeFormatUS}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Completed On" field="completedOn" locked="false">
    <ng-template kendoGridHeaderTemplate>
      Completed On
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.completedOn | amDateFormat: appConfig.dateTimeFormatUS}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Download" locked="true" [sortable]="false" [filterable]="false">
    <ng-template kendoGridHeaderTemplate>
      Download
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="export-download" *ngIf="dataItem.status === exportDataStatus.Completed && !downloadIsAvailable(dataItem)"
        [popper]="downloadWarning" [popperTrigger]="'hover'" [popperPlacement]="'bottom-left'" [popperDisableStyle]="'true'">
        <em class="fas fa-download export-download-button dowload-disabled" (click)="showDownloadInfoPopup(dataItem)"></em>
        <popper-content #downloadWarning class="slx-popper">
          <div class="export-data-popper">
            <span>{{messages.downloadNotAvailable}}</span>
          </div>
        </popper-content>
      </div>
      <div class="export-download" *ngIf="dataItem.status === exportDataStatus.Completed && downloadIsAvailable(dataItem)">
        <em class="fas fa-download export-download-button" (click)="downloadFile(dataItem)"></em>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

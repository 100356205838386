<label class="selector-title">Payroll Cycle</label>
&nbsp;

<slx-actions-list>
  <slx-actions-button className="auto-width" [popperContent]="yearsMenu" [popperPosition]="'bottom-start'">
    {{selectedYear}} <span class="caret"></span>
  </slx-actions-button>
  <popper-content #yearsMenu>
    <slx-actions-list-item *ngFor="let year of years" (onClick)="onSelectYear(year)" [className]="selectedYear === year ? 'active' : ''">
      {{year}}
    </slx-actions-list-item>
  </popper-content>
</slx-actions-list>
&nbsp;

<slx-actions-list>
  <slx-actions-button className="auto-width" [popperContent]="cycleMenu" [popperPosition]="'bottom-start'">
    <span *ngIf="!selectedPayCycle">Please select Pay Cycle</span>
    <span *ngIf="selectedPayCycle">{{selectedPayCycle.startDate | amDateFormat: appConfig.dateFormat}}-<span *ngIf="selectedPayCycle.endDate">{{selectedPayCycle.endDate | amDateFormat: appConfig.dateFormat}}</span></span>
    <span class="caret"></span>
  </slx-actions-button>
  <popper-content #cycleMenu>
    <slx-actions-list-item *ngFor="let item of yearPayCycles[selectedYear]" (onClick)="onPayCycleSelect(item)" [className]="isSelected(item) ? 'active' : ''">
      {{item?.startDate | amDateFormat: appConfig.dateFormat}} - <span *ngIf="item.endDate">{{item.endDate | amDateFormat: appConfig.dateFormat}}</span>
    </slx-actions-list-item>
  </popper-content>
</slx-actions-list>

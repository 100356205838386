import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject ,  Subscription } from 'rxjs';

import { NavigationMenuItem, IAccessibleProvider } from '../../../models/index';
import { ApplicationStateBusService } from '../../application-state-bus/application-state-bus.service';

@Injectable({
  providedIn: 'root',
})
export class MenuAccessibleProviderService implements IAccessibleProvider {
  public accessibleChanged$: Subject<any>;

  private allowedMenu: StringMap<NavigationMenuItem>;
  constructor(private busService: ApplicationStateBusService) {
    this.accessibleChanged$ = new Subject();
    this.busService.subscribeToMenuLoaded((menu: NavigationMenuItem[]) => this.setMenu(menu));
  }

  public subscribeToAccessibleChanged(callback: () => void): Subscription {
    return this.accessibleChanged$.subscribe(callback);
  }

  public setMenu(menu: NavigationMenuItem[]): void {
    this.allowedMenu = {};
    this.flattenMenu(menu);
    this.accessibleChanged$.next();
  }

  public flattenMenu(menu: NavigationMenuItem[]): void {
    if (!menu) {
      return;
    }
    _.forEach(menu, (m: NavigationMenuItem) => {
      if (!this.allowedMenu[m.name]) {
        this.allowedMenu[m.name] = m;
      }
      this.flattenMenu(m.childs);
    });
  }

  public isAccessible(menuName: string): boolean {
    return !!this.allowedMenu[menuName];
  }

  public getMenuItem(menuName: string): NavigationMenuItem {
    return this.allowedMenu[menuName];
  }
}

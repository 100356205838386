import * as moment from 'moment';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ScheduleConsoleFilterItem, ScheduleConsoleEntry, ScheduleConsoleCycles, Comparison } from '../../models/index';
import { Subject } from 'rxjs';
import { LocationUnit, Shift } from '../../../../organization/models/index';

@Injectable()
export class ScheduleConsoleFiltersService {

    public onApplyOverviewFilters: Subject<any>;
    public onApplyConsoleFilters: Subject<ScheduleConsoleFilterItem[]>;
    public onClearFilters: Subject<any>;
    public onFiltersApplied: Subject<any>;

    public set isDisabledGrouping(value: boolean) {
        this.m_isDisabledGrouping = value;
    }

    public get isDisabledGrouping(): boolean {
        return this.m_isDisabledGrouping;
    }

    public set isGroupedByUnit(value: boolean) {
        this.m_isGroupedByUnit = value;
    }

    public get isGroupedByUnit(): boolean {
        return this.m_isGroupedByUnit;
    }

    public set isGroupedByShiftGroup(value: boolean) {
        this.m_isGroupedByShiftGroup = value;
    }

    public get isGroupedByShiftGroup(): boolean {
        return this.m_isGroupedByShiftGroup;
    }

    public set consoleData(data: ScheduleConsoleCycles) {
        this.m_scheduleConsoleData = data;
        if (this.currentConsoleFilters.length > 0) {
            this.consoleFilters();
        }
    }

    private m_isDisabledGrouping: boolean;
    private m_isGroupedByUnit: boolean;
    private m_isGroupedByShiftGroup: boolean;

    private m_scheduleConsoleData: ScheduleConsoleCycles;
    private currentConsoleFilters: ScheduleConsoleFilterItem[];
    private isDirectCare: boolean;
    private unitFilter: LocationUnit[];
    private shiftFilter: Shift[];

    constructor() {
        this.currentConsoleFilters = [];
        this.onClearFilters = new Subject();
        this.onFiltersApplied = new Subject();
        this.onApplyOverviewFilters = new Subject();
        this.onApplyConsoleFilters = new Subject<ScheduleConsoleFilterItem[]>();
    }


    public overviewFiltersChanged(unitFilter: LocationUnit[], shiftFilter: Shift[]): void {
        this.applyFilters();
    }

    public consoleFiltersChanged(filters: ScheduleConsoleFilterItem[], isDirectCare: boolean): void {
        this.isDirectCare = isDirectCare;
        if (filters) {
            this.currentConsoleFilters = filters;
        } else {
            this.currentConsoleFilters = [];
        }
        this.applyFilters();
    }

    public applyFilters(): void {
        if (!this.m_scheduleConsoleData) return;
        // reset all filters
        this.resetFilters();
        // apply overview filters first
        this.overviewFilters();
        // apply common console filters
        this.consoleFilters();

        this.onFiltersApplied.next();
    }

    private resetFilters(resetState: boolean = false): void {

        // reset all hidden flags
        if (!this.m_scheduleConsoleData) return;

        _.forEach(this.m_scheduleConsoleData.groupByPosition, (position: ScheduleConsoleEntry) => {
            position.hidden = resetState;
        });


        _.forEach(this.m_scheduleConsoleData.groupByUnit, (unit: ScheduleConsoleEntry) => {
            _.forEach(unit.values, (shift: ScheduleConsoleEntry) => {
                _.forEach(shift.values, (position: ScheduleConsoleEntry) => {
                    position.hidden = resetState;
                });
                shift.hidden = resetState;
            });
            unit.hidden = resetState;
        });

        _.forEach(this.m_scheduleConsoleData.groupByShift, (shift: ScheduleConsoleEntry) => {
            _.forEach(shift.values, (unit: ScheduleConsoleEntry) => {
                _.forEach(unit.values, (position: ScheduleConsoleEntry) => {
                    position.hidden = resetState;
                });
                unit.hidden = resetState;
            });
            shift.hidden = resetState;
        });

        this.onClearFilters.next();
    }

    private overviewFilters(): void {
        this.applyOverviewFilters(this.unitFilter, this.shiftFilter);
        this.onApplyOverviewFilters.next();
    }

    private consoleFilters(): void {
        this.applyConsoleFilters(this.currentConsoleFilters);
        this.onApplyConsoleFilters.next(this.currentConsoleFilters);
    }

    private applyOverviewFilters(unitFilter: LocationUnit[], shiftFilter: Shift[]): void {

        if (!this.m_scheduleConsoleData) return;

        _.forEach(this.m_scheduleConsoleData.groupByPosition, (entry: ScheduleConsoleEntry) => {
            this.processEntry(entry, unitFilter, shiftFilter);
        });
    }

    private processEntry(entry: ScheduleConsoleEntry, unitFilter: LocationUnit[], shiftFilter: Shift[]): void {
        let hasGroup: boolean = false;
        let hasUnit: boolean = false;
        _.forEach(entry.values, (c: Comparison) => {
            _.each(shiftFilter, (shiftFilteritem: Shift) => {
                if (c.shiftGroup.id === shiftFilteritem.id) {
                    hasGroup = true;
                }
            });
            _.each(unitFilter, (unitFilteritem: LocationUnit) => {
                if (c.unit.id === unitFilteritem.id) {
                    hasUnit = true;
                }
            });

        });

        if (!(shiftFilter && shiftFilter.length > 0)) {
            hasGroup = true;
        }

        if (!(unitFilter && unitFilter.length > 0)) {
            hasUnit = true;
        }

        entry.hidden = !(hasUnit && hasGroup);
    }


    private applyConsoleFilters(filters: ScheduleConsoleFilterItem[]): void {

        if (!this.m_scheduleConsoleData) return;
        const hasFilter: boolean = filters.length > 0;
        if (!this.isDirectCare && !hasFilter) return;

        if (this.isDirectCare && !hasFilter) {
            this.resetFilters(true);
            return;
        }

        const filtersNames: string[] = _.map(filters, (filter: ScheduleConsoleFilterItem) => filter.name);

        _.forEach(this.m_scheduleConsoleData.groupByUnit, (unit: ScheduleConsoleEntry) => {
            if (!unit.hidden) {
                let shownShifts: boolean[] = [];
                _.forEach(unit.values, (shift: ScheduleConsoleEntry) => {
                    if (!shift.hidden) {
                        let shownPositions: boolean[] = [];
                        _.forEach(shift.values, (position: ScheduleConsoleEntry) => {
                            if (!position.hidden) {
                                position.hidden = _.indexOf(filtersNames, position.name) === -1;
                                if (!position.hidden) shownPositions.push(true);
                            }
                        });
                        shift.hidden = shownPositions.length === 0;
                        if (!shift.hidden) shownShifts.push(true);
                    }
                });
                unit.hidden = shownShifts.length === 0;
            }
        });

        _.forEach(this.m_scheduleConsoleData.groupByShift, (shift: ScheduleConsoleEntry) => {
            if (!shift.hidden) {
                let shownUnits: boolean[] = [];
                _.forEach(shift.values, (unit: ScheduleConsoleEntry) => {
                    if (!unit.hidden) {
                        let shownPositions: boolean[] = [];
                        _.forEach(unit.values, (position: ScheduleConsoleEntry) => {
                            position.hidden = _.indexOf(filtersNames, position.name) === -1;
                            if (!position.hidden) shownPositions.push(true);
                        });
                        unit.hidden = shownPositions.length === 0;
                        if (!unit.hidden) shownUnits.push(true);
                    }
                });
                shift.hidden = shownUnits.length === 0;
            }
        });

        _.forEach(this.m_scheduleConsoleData.groupByPosition, (entry: ScheduleConsoleEntry) => {
            if (!entry.hidden) {
                entry.hidden = _.indexOf(filtersNames, entry.name) === -1;
            }
        });
    }

}

<!-- #bugInIE11WithEmployeeRecord (ngIf) -->
<form novalidate #form="ngForm" *ngIf="employeeSectionsPayroll">
  <div class="col-xs-12">
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input type="text" [required]="!employeeShortInfo?.isAgency && employeeSectionsPayroll?.isPayrollNumberRequired" maxlength="255" 
          [readonly]="isNgpUser || !state.isEditMode" name="payrollNumber" placeholder="Payroll Number"
          [server]="{ validationName: payrollNumberValidation, 
                      parameters: [employeeSectionsPayroll?.homeOrgLevelId, employeeId], 
                      validationAdapter: employeeValidatorAdapter, 
                      componentName: 'payrollNumber', 
                      form: form }"
          [inputPolicy]="employeeSectionsPayroll?.payrollNumber.Policy" [(ngModel)]="employeeSectionsPayroll?.payrollNumber.fieldValue">
        <span errorMessage for="required"></span>
        <span errorMessage [for]="payrollNumberValidation">msg from server</span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-kendo-number slx-input number
          [(ngModel)]="employeeSectionsPayroll?.fileNumber.fieldValue"
          [inputPolicy]="employeeSectionsPayroll?.fileNumber.Policy"
          [readonly]="!state.isEditMode"
          format="#"
          placeholder="File Number"
          name="fileNumber"
        ></slx-kendo-number>
        <span errorMessage for="number"></span>
        <span errorMessage for="min">Cannot be less than {{ employeeSectionsPayroll?.fileNumber?.Policy?.minValue }}</span>
        <span errorMessage for="max">Cannot be greater than {{ employeeSectionsPayroll?.fileNumber?.Policy?.maxValue }}</span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-dropdown-input slx-input [lookup]="{lookupType: 'empType', employeeId: employeeId}" [readonly]="!state.isEditMode" name="empType"
          placeholder="Emp Type" [inputPolicy]="employeeSectionsPayroll?.empType.Policy" [(ngModel)]="employeeSectionsPayroll?.empType.fieldValue">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-kendo-number slx-input number
          [(ngModel)]="employeeSectionsPayroll?.benefitHours.fieldValue"
          [inputPolicy]="employeeSectionsPayroll?.benefitHours.Policy"
          [decimals]="2"
          [min]="0"
          [slxMin]="0"
          [slxMax]="999999.99"
          [max]="999999.99"
          [readonly]="!state.isEditMode"
          name="benefitHours"
          placeholder="Benefit Hours"
        ></slx-kendo-number>
        <span errorMessage for="min">The value should be greater than 0</span>
        <span errorMessage for="max">The value should be less than 999999.99</span>
        <span errorMessage for="number"></span>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input type="text" *ngIf="!employeeSectionsPayroll?.ssn.Policy.isEditable"
          slx-input [readonly]="true" name="ssn"
          placeholder="SSN" [ngModel]="employeeSectionsPayroll?.ssn.fieldValue"
          [inputPolicy]="employeeSectionsPayroll?.ssn.Policy"
          >

        <slx-ssn-input *ngIf="employeeSectionsPayroll?.ssn.Policy.isEditable"
          slx-input [readonly]="!state.isEditMode" name="ssn"
          [server]="{ validationName: ssnValidation, 
                      parameters: [employeeSectionsPayroll?.homeOrgLevelId, employeeId], 
                      validationAdapter: employeeValidatorAdapter, 
                      componentName: 'ssn', 
                      form: form }"
          [originalValue]="originalSSN"
          [required]="addEmployeeRestrictions?.ssn.isRequired"
          ssn placeholder="SSN" [inputPolicy]="employeeSectionsPayroll?.ssn.Policy" [(ngModel)]="employeeSectionsPayroll?.ssn.fieldValue">
        </slx-ssn-input>
        <span errorMessage for="ssn"></span>
        <span errorMessage for="required"></span>
        <span errorMessage [for]="ssnValidation">msg from server</span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3" *ngIf="ftDateNeeded">
      <slx-input-decorator>
          <slx-date-picker-ngx slx-input date
            [(ngModel)]="employeeSectionsPayroll?.ftDate.fieldValue"
            [readonly]="!state.isEditMode"
            [inputPolicy]="employeeSectionsPayroll?.ftDate.Policy"
            placeholder="FT Date"
            name="ftDate"
          ></slx-date-picker-ngx>
        <span errorMessage for="required"></span>
        <span errorMessage for="date"></span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3 rate-h">
      <slx-input-decorator>
        <slx-date-picker-ngx slx-input date
          [(ngModel)]="employeeSectionsPayroll?.lastIncreaseDate.fieldValue"
          [readonly]="!state.isEditMode"
          [inputPolicy]="employeeSectionsPayroll?.lastIncreaseDate.Policy"
          placeholder="Last Increase Date"
          name="lastIncreaseDate"
          [minDate]="employeeSectionsPayroll?.hireDate.fieldValue"
        ></slx-date-picker-ngx>
        <span errorMessage for="required"></span>
        <span errorMessage for="date"></span>
        <span errorMessage for="minDate">The value should be greater or equal than hire date {{ employeeSectionsPayroll?.hireDate.fieldValue | amDateFormat: appConfig.dateFormat }}</span>
      </slx-input-decorator>
    </div>

    <div *ngIf="employeeSectionsPayroll?.isEnabledInreaseReason && decimalLimit" class="col-sm-6 col-lg-3 rate-h">
      <slx-input-decorator>
        <slx-dropdown-input slx-input
        [lookup]="{lookupType: 'increaseReason'}"
        [readonly]="!state.isEditMode" name="increaseReason"
        placeholder="Increase Reason"
        [inputPolicy]="employeeSectionsPayroll?.payRate.Policy"
        emptyOption
        [(ngModel)]="increaseReason">
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>


    <div class="col-sm-6 col-lg-3 rate-h hire-date-col">
      <slx-input-decorator>
        <slx-date-picker-ngx slx-input date
          [(ngModel)]="employeeSectionsPayroll?.hireDate.fieldValue"
          [required]="true"
          [readonly]="!state.isEditMode"
          [inputPolicy]="employeeSectionsPayroll?.hireDate.Policy"
          placeholder="Hire Date"
          name="hireDate"
          [acceptNullDate]="false"
          [minDate]="minHireDate"
          [maxDate]="maxHireDate"
        ></slx-date-picker-ngx>
        <span errorMessage for="required">Date is invalid or out of valid range</span>
        <span errorMessage for="date"></span>
        <span errorMessage for="minDate">Hire date can't be within 14 years of birth date</span>
        <span errorMessage for="maxDate">Must be less than or equal to {{ maxHireDate | amDateFormat: appConfig.dateFormat }}</span>
      </slx-input-decorator>
      <span *ngIf="state.isEditMode && displayMaxDateTip"
        class="hire-date-icon"
        slxTooltip="The date selected must be less than or equal to the effective date for the employee’s current position {{ maxHireDate | amDateFormat: appConfig.dateFormat }}"
        tipPosition="top"
        tipClass="slx-warning-tooltip"
      >
        <i class="fas fa-info-circle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="col-sm-6 col-lg-3 rate-h">
      <slx-input-decorator>
        <input slx-input type="text" maxlength="255" [readonly]="true" name="acaType" placeholder="ACA Type" [inputPolicy]="employeeSectionsPayroll?.acaType.Policy"
          [(ngModel)]="employeeSectionsPayroll?.acaType.fieldValue">
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input type="text" maxlength="50"
          [readonly]="!state.isEditMode" name="union" placeholder="Union"
          [inputPolicy]="employeeSectionsPayroll?.union.Policy"
          [(ngModel)]="employeeSectionsPayroll?.union.fieldValue">
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3"></div>
    <div class="col-sm-6 col-lg-3" *ngIf="employeeSectionsPayroll?.rehireDate">
      <slx-input-decorator>
        <slx-date-picker-ngx slx-input
          [ngModel]="employeeSectionsPayroll?.rehireDate"
          [readonly]="true"
          placeholder="Rehire Date"
          name="rehireDate"
        ></slx-date-picker-ngx>
      </slx-input-decorator>
    </div>
  </div>
  <div>
    <div class="col-xs-12">
      <h4 class="brcramp">{{employeeSectionsPayroll?.orgNavBreadcrumb}}</h4>
      <hr>
    </div>
  </div>
  <div class="col-xs-12">
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-dropdown-input slx-input [lookup]="positionLookupRequest" [readonly]="!state.isEditMode" name="position" placeholder="Position"
          [inputPolicy]="employeeSectionsPayroll?.position.Policy" [(ngModel)]="employeeSectionsPayroll?.position.fieldValue"
          (ngModelChange)="positionChanged($event)">
        </slx-dropdown-input>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-dropdown-input slx-input  [lookup]="payPolicyLookupRequest" [readonly]="!state.isEditMode" name="payPolicy" placeholder="Pay Policy"
          [inputPolicy]="employeeSectionsPayroll?.payPolicy.Policy" [(ngModel)]="employeeSectionsPayroll?.payPolicy.fieldValue">
        </slx-dropdown-input>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-dropdown-input slx-input emptyOption [lookup]="shiftDiffPolicyLookupRequest" [readonly]="!state.isEditMode" name="shiftDiffPolicy"
          placeholder="Shift Diff Policy" [inputPolicy]="employeeSectionsPayroll?.shiftDiffPolicy.Policy" [(ngModel)]="employeeSectionsPayroll?.shiftDiffPolicy.fieldValue">
        </slx-dropdown-input>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">

      <slx-input-decorator *ngIf="!employeeSectionsPayroll?.payType?.Policy?.isEditable">
          <input slx-input type="text" maxlength="255" [readonly]="true" name="payType" placeholder="Pay Type"
            [inputPolicy]="employeeSectionsPayroll?.payType.Policy"
            [(ngModel)]="employeeSectionsPayroll?.payType.fieldValue.description">
      </slx-input-decorator>

      <div class="row" *ngIf="employeeSectionsPayroll?.payType?.Policy?.isEditable">
        <div class="col-xs-6" *ngFor="let type of payTypeLookup?.items; let idx = index;">
          <slx-input-decorator className="slx-no-border">
            <slx-radio-input slx-input [readonly]="!state.isEditMode" name="payType{{type.name}}" fieldName="{{type.name}}" valueField="name"
              [option]="type" [caption]="type.name" [placeholder]="idx==0?'Pay Type':''" [ngModel]="employeeSectionsPayroll?.payType.fieldValue"
              (ngModelChange)="updatePayType($event)">
            </slx-radio-input>
          </slx-input-decorator>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-dropdown-input slx-input lookup="exemptStatus" [readonly]="!state.isEditMode" name="exemptStatus" placeholder="Exempt Status"
          [inputPolicy]="employeeSectionsPayroll?.exemptStatus.Policy" [(ngModel)]="employeeSectionsPayroll?.exemptStatus.fieldValue">
        </slx-dropdown-input>
      </slx-input-decorator>
    </div>

    <div class="col-sm-6 col-lg-3 rate-h" *ngIf="decimalLimit">
      <slx-input-decorator>
        <slx-money-input slx-input [decimalLimit]="decimalLimit" [requireDecimal]="false" [allowNegative]="false" [readonly]="!state.isEditMode || employeeSectionsPayroll?.isPayRateComponents"
          name="payRate" placeholder="{{getRateTitle}}" min="0" slxMin="0" [slxMax]="getRateLimit" number
                         [inputPolicy]="employeeSectionsPayroll?.payRate.Policy"
                         [(ngModel)]="employeeSectionsPayroll?.payRate.fieldValue">
        </slx-money-input>

        <div *ngIf="employeeSectionsPayroll?.isPayRateComponents && employeeSectionsPayroll?.payRate?.Policy?.isEditable">
          <button type=button class="bt-generate" title="Pay Rate Component"
            [popover]="payRatePopover">
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
          </button>
          <div class="popover-content-arrow">
            <popover-content #payRatePopover placement="left" topOffset="-40">
              <slx-pay-rate-component
              [payRatesRecords]="employeeSectionsPayroll?.payRateComponents.fieldValue"
              [state] = "state.isEditMode"
              (onPayRateChange)="payRateChange($event)"></slx-pay-rate-component>
            </popover-content>
          </div>
        </div>
        <div class="warning-triangle" *ngIf="isOverSalary">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"
             slxTooltip="This rate appears high, are you sure this is an {{getRateTitle}} employee?"
             tipPosition="right" tipClass="slx-warning-tooltip"></i>
        </div>
        <div errorMessage for="min">The value should be greater than or equal to 0</div>
        <span errorMessage for="number"></span>
        <span errorMessage for="max">Should not exceed {{getRateLimit}}</span>
      </slx-input-decorator>
    </div>

    <div class="col-sm-6 col-lg-3 rate-h">
      <slx-input-decorator>
        <slx-money-input slx-input [decimalLimit]="2" [requireDecimal]="false" [allowNegative]="false" [readonly]="!state.isEditMode"
          name="payRate2" placeholder="Pay Rate 2" [slxMax]="getRateLimit" number
                         [inputPolicy]="employeeSectionsPayroll?.payRate2.Policy"
                         [(ngModel)]="employeeSectionsPayroll?.payRate2.fieldValue"
                         (ngModelChange)="onPayRateChanged('payRate2')">
        </slx-money-input>
        <div class="warning-triangle" *ngIf="isOverSalary">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"
             slxTooltip="This rate appears high, are you sure this is an {{getRateTitle}} employee?"
             tipPosition="right" tipClass="slx-warning-tooltip"></i>
        </div>
        <span errorMessage for="number"></span>
        <span errorMessage for="max">Should not exceed {{getRateLimit}}</span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3 rate-h">
      <slx-input-decorator>
        <slx-money-input slx-input [decimalLimit]="2" [requireDecimal]="false" [allowNegative]="false" [readonly]="!state.isEditMode"
          name="payRate3" placeholder="Pay Rate 3" [slxMax]="getRateLimit" number
                         [inputPolicy]="employeeSectionsPayroll?.payRate3.Policy"
                         [(ngModel)]="employeeSectionsPayroll?.payRate3.fieldValue"
                         (ngModelChange)="onPayRateChanged('payRate3')">
        </slx-money-input>
        <div class="warning-triangle" *ngIf="isOverSalary">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"
             slxTooltip="This rate appears high, are you sure this is an {{getRateTitle}} employee?"
             tipPosition="right" tipClass="slx-warning-tooltip"></i>
        </div>
        <div errorMessage for="min">The value should be greater than 0</div>
        <span errorMessage for="number"></span>
        <span errorMessage for="max">Should not exceed {{getRateLimit}}</span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-kendo-number slx-input number
          [(ngModel)]="employeeSectionsPayroll?.standardWeeklyHours.fieldValue"
          (ngModelChange)="checkSalariedHours()"
          [inputPolicy]="employeeSectionsPayroll?.standardWeeklyHours.Policy"
          [decimals]="2"
          [min]="0"
          [slxMin]="0"
          [readonly]="!state.isEditMode"
          [required]="isSalaryPayType"
          name="standardWeeklyHours"
          placeholder="Standard Weekly Hours"
        ></slx-kendo-number>
        <span errorMessage for="min">The value should be greater than 0</span>
        <span errorMessage for="number"></span>
        <span errorMessage for="required">The field is required for Salary pay type</span>
        <span errorMessage for="isSalariedWithoutHours">This field can't be 0 if Pay type = Salaried</span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-money-input slx-input [decimalLimit]="2" [requireDecimal]="false" [allowNegative]="false" [readonly]="true" name="annualSalary"
          placeholder="Annual Salary"
          [inputPolicy]="employeeSectionsPayroll?.annualSalary.Policy"
          [(ngModel)]="employeeSectionsPayroll?.annualSalary.fieldValue">
        </slx-money-input>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input number type="text" maxlength="255" name="costCenterCode" placeholder="Cost Center Code"
          [readonly]="!state.isEditMode"
          [(ngModel)]="employeeSectionsPayroll.costCenterCode.fieldValue"
          [inputPolicy]="employeeSectionsPayroll?.costCenterCode.Policy"
          >
        <span errorMessage for="number">Please Enter Valid Cost Center Code</span>
        <span errorMessage for="costCenterAllocations">There are errors in Cost Center Allocation config</span>
      </slx-input-decorator>
      <div *ngIf="state.isEditMode && employeeSectionsPayroll?.costCenterAllocations?.Policy?.isEditable">
        <button type="button" class="bt-generate simple" [popover]="costPopover">
          <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
          <span *ngIf="allocationsSize > 0"
            class="allocations-size"
            title="There are some cost center allocations assigned"
          >{{ allocationsSize }}</span>
        </button>
        <div class="popover-content-arrow">
          <popover-content #costPopover placement="left" topOffset="-40">
            <slx-cost-center-split
              [allocations]="employeeSectionsPayroll?.costCenterAllocations.fieldValue"
              [employeeId]="employeeId"
              (onChangeAllocations)="onCostCenterChanged($event)"
            ></slx-cost-center-split>
          </popover-content>
        </div>
      </div>
      <div *ngIf="!state.isEditMode || !employeeSectionsPayroll?.costCenterAllocations?.Policy?.isEditable" class="bt-generate">
        <span *ngIf="allocationsSize > 0"
          class="allocations-size view-only"
          title="There are some cost center allocations assigned"
        >{{ allocationsSize }}</span>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-spinner [show]="isPbjIdLoading">
        <slx-input-decorator>
          <input slx-input slxPbjId [slxMaxLength]="pbjIdMaxSymbols" type="text" [readonly]="!state.isEditMode || !pbjIsManual || !pbjEnabled" name="pbjId" placeholder="PBJ ID"
            [(ngModel)]="employeeSectionsPayroll?.pbjId.fieldValue" [inputPolicy]="employeeSectionsPayroll?.pbjId.Policy"
            [server]="{ validationName: pbjIdValidation, 
                        parameters: [employeeId], 
                        validationAdapter: employeeValidatorAdapter, 
                        componentName: 'pbjId', 
                        form: form }">
            <span errorMessage for="pbjId">You can use letters, digits and , . \ -</span>
            <span errorMessage for="slxMaxLength">Maximum is 9 symbols</span>
            <span errorMessage [for]="pbjIdValidation">msg from server</span>
        </slx-input-decorator>
        <button *ngIf="state.isEditMode && pbjIsManual && employeeSectionsPayroll?.pbjId?.Policy?.isRequired && pbjEnabled" type=button class="bt-generate"
          [ngClass]="{'bt-generate-auto': !pbjIsManual}" (click)="generatePbjId()">
          <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
        </button>
      </slx-spinner>
    </div>
    <div class="col-sm-6 col-lg-3" *ngIf="isSupervisorEnabled">
        <div  *ngIf="state.isEditMode"  >
        <div class="slx-input-group" >
          <slx-autocomplete-combobox-input   class="slxwidth"  placeholder="Supervisor" name="selectedSupervisor" [lookup]="supervisorOptions"
            [readonly]="!state.isEditMode" [(ngModel)]="selectedSupervisor"
            [titleField]="employeeSectionsPayroll?.supervisor?.name">
          </slx-autocomplete-combobox-input>
          </div>
          <div class="slx-readonly" *ngIf="selectedSupervisor?.id > 0 && !selectedSupervisor?.isDefault">
            <label class="supervisor-warning  ">Not the default supervisor</label>
          </div>
        </div>
        <div class="slx-form-group slx-readonly" *ngIf="!state.isEditMode">
          <label class="slx-label">Supervisor</label>
          <div class="slx-input-group kendo kendo-common kendo-default">
            <div class="address-text">
              {{employeeSectionsPayroll?.supervisor?.name}}
            </div>
            <div class="slx-readonly" *ngIf="selectedSupervisor?.id > 0 && !selectedSupervisor?.isDefault">
              <label class="supervisor-warning  ">Not the default supervisor</label>
            </div>
          </div>
        </div>
    </div>
  </div>
</form>


import {combineLatest} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';


import { appConfig, IApplicationConfig } from '../../../app.config';
import { ActivatedRoute, Params } from '@angular/router';
import { LeaveRequestEntries, LeaveRequestEntry, LeaveRequestFilter } from '../../models/index';
import { LeaveRequestApiService } from '../../services/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { IRangeDates } from '../../../common/models/range-dates';
import { ScheduleAbsence } from '../../../organization/models/index';
import { LookupApiService } from '../../../organization/services/index';
import { Actions } from '../../../core/models/index';


@Component({
  moduleId: module.id,
  selector: 'slx-leave-request',
  templateUrl: 'leave-request.component.html',
  styleUrls: ['leave-request.component.scss'],
})
export class LeaveRequestComponent implements OnInit, OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  public startDate: Date;
  public endDate: Date;
  public appConfig: IApplicationConfig;
  public state: {
    isLoading: boolean;
  };
  public currentOrgLevel: OrgLevel;
  public absencesCodes: ScheduleAbsence[];
  public filters: LeaveRequestFilter[];
  public actions: Actions;
  public leaveRequests: LeaveRequestEntry[];

  private lookupApiService: LookupApiService;
  private leaveRequestApiService: LeaveRequestApiService;
  private route: ActivatedRoute;

  @unsubscribe()
  private orglevelSubscription: Subscription;

  constructor(
    leaveRequestApiService: LeaveRequestApiService,
    lookupApiService: LookupApiService,
    route: ActivatedRoute
  ) {
    this.route = route;
    this.appConfig = appConfig;
    this.leaveRequestApiService = leaveRequestApiService;
    this.lookupApiService = lookupApiService;

    this.filters= [];
    this.startDate =  null;
    this.endDate  =  null;

    this.state = {
      isLoading: true
    };
  }

  public ngOnInit(): void {
    this.orglevelSubscription = this.orgLevel$.pipe(
      combineLatest(this.route.queryParams))
      .subscribe(([orgLevel, queryParams]: [OrgLevel, Params]) => {
        if (!orgLevel || !orgLevel.id) {
          return;
        }
        let queryOrgLevelId: number = Number(queryParams['orgLevelId']);
        if (queryOrgLevelId > 0 && queryOrgLevelId !== orgLevel.id) {
          return;
        }
        this.currentOrgLevel = orgLevel;
        if (queryParams['startDate'] && queryParams['endDate']) {
          this.startDate =  moment(queryParams['startDate'], appConfig.linkDateFormat).toDate();
          this.endDate  =  moment(queryParams['endDate'], appConfig.linkDateFormat).toDate();
        }

        if (_.isDate(this.startDate) && _.isDate(this.endDate)) {
          this.loadGridData();
        }
        this.loadAbsences();
      });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onDatesChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.loadGridData();
  }

  public onFiltersChanged(filters: LeaveRequestFilter[]): void {
    this.filters = filters;
  }

  public onUpdateLoading(isLoading: boolean): void {
    this.state.isLoading = isLoading;
    if (!isLoading) {
      this.loadGridData();
    }
  }

  private loadGridData(): void {
    this.state.isLoading = true;
    this.leaveRequestApiService
      .getLeaveRequests(this.currentOrgLevel.id, this.startDate, this.endDate)
      .then((leaveRequests: LeaveRequestEntries) => {
        this.leaveRequests = leaveRequests.requests.slice(0);
        this.actions = leaveRequests.actions;
        this.state.isLoading = false;
      });
  }

  private loadAbsences(): void {
    this.lookupApiService.getScheduleAbsences(this.currentOrgLevel.id)
      .then((absences: ScheduleAbsence[]) => {
        this.absencesCodes = absences;
      });
  }
}


import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import * as _ from 'lodash';
import * as moment from 'moment';
import { DateRange } from 'moment-range';

import { createValuAccessor } from '../../utils/index';

@Component({
  selector: 'slx-calendar',
  template: '',
  providers: [createValuAccessor(CalendarComponent)]
})
export class CalendarComponent implements OnInit, ControlValueAccessor {

  @Input()
  public set minDate(momentValue: moment.Moment) {
    this.minDateMoment = momentValue;
    if (this.kendoCalendar) {
      this.kendoCalendar.navigate(this.internalValue.toDate()[0], 'month');
    }
  }

  @Input()
  public set maxDate(momentValue: moment.Moment) {
    this.maxDateMoment = momentValue;
    if (this.kendoCalendar) {
      this.kendoCalendar.navigate(this.internalValue.toDate()[0], 'month');
    }
  }

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;


  private internalValue: DateRange;
  private elementRef: ElementRef;
  private kendoCalendar: kendo.ui.Calendar;

  private minDateMoment: moment.Moment;
  private maxDateMoment: moment.Moment;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  public ngOnInit(): void {
    this.createCalendar();
  }

  public writeValue(value: DateRange): void {
    this.internalValue = value;
    this.kendoCalendar.value(this.internalValue && this.internalValue.toDate ? this.internalValue.toDate()[0] : new Date());
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  protected createCalendar(): void {
    this.kendoCalendar = <any>$(this.elementRef.nativeElement).kendoCalendar({
      disableDates: (d: Date) => {
        return this.compareDates(d);
      },
      change: (e: kendo.ui.CalendarEvent) => {
        this.onChangeCallback(moment(e.sender.current()));
      }
    });
    this.kendoCalendar = $(this.elementRef.nativeElement).data('kendoCalendar');
  }

  private compareDates(date: Date): boolean {

    let disable: boolean = false;

    if (this.minDateMoment) {
      disable = this.minDateMoment.isAfter(moment(date));
    }
    if (this.maxDateMoment && !disable) {
      disable = this.maxDateMoment.isBefore(moment(date));
    }

    return disable;
  }

}

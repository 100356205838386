import * as _ from 'lodash';

import { Subscription } from 'rxjs';

/**
   * Decorator that allow to unsubscribe all subscriptions at once.
**/
export function unsubscribeAll(destroyMethodName: string = 'ngOnDestroy'): PropertyDecorator {
  return function decorate(target: any, key: string): void {
    const destroyFunction: Function = target[destroyMethodName];
    if (!_.isFunction(destroyFunction)) {
      throw new TypeError(`Cannot apply 'unsubscribeAll' decorator, '${destroyMethodName}' is not an function`);
    }

    target[destroyMethodName] = function () {
      destroyFunction.apply(this);

      const subscriptions: StringMap<Subscription> = this[key];
      if (!_.isObjectLike(subscriptions)) {
        throw new TypeError(`Cannot unsubscribe, property '${key}' is not an object`);
      }

      _.forEach(subscriptions, (s: Subscription) => {
        if (s && s.unsubscribe) {
          s.unsubscribe();
        }
      });
      this[key] = {};
    };
  };
}

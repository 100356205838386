import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { mutableSelect, unsubscribe } from '../../../../core/decorators';
import { Observable, Subscription } from 'rxjs';
import { OrgLevel } from '../../../../state-model/models';
import { AccrualPolicy, AccrualPolicyDropDown, Rules, SeniorityRules } from '../../models/index';
import * as _ from 'lodash';
import { AccrualsPolicyApiService } from '../../services/accruals-policy/accruals-policy-api.service';
import { Actions } from '../../../../core/models/index';
import { FileService } from './../../../../common/services/file/file.service';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import { AppSettingsManageService } from './../../../../../app/app-settings/services';
import { AppServerConfig } from './../../../../../app/app-settings/model/app-server-config';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray, Validators, FormControlDirective, FormControlName, AbstractControl, ValidationErrors } from '@angular/forms';
import { AccrualsPolicyMapService } from '../../services/accruals-policy/accruals-policy-map.service';
import { NotificationsService } from './../../../../../app/core/components';
import { CommonValidators, ConfirmDialogComponent, ConfirmOptions, ModalAnchorDirective, ModalService } from './../../../../../app/common';
import { appConfig, IApplicationConfig } from './../../../../../app/app.config';
import { AccrualPolicyDialogInfo } from '../../models/accruals-policy/accrual-policy-dialog-info';
import { AccrualPolicyManagementService } from '../../services/accruals-policy/accrual-policy-management.service';
import { LocalStorageService } from './../../../../../app/core/services';
import { carryOverTypeValidator, dateRangeValidator } from '../../pipes/date-range.validator';

@Component({
  moduleId: module.id,
  selector: 'slx-accruals-policy',
  templateUrl: 'accruals-policy.component.html',
  styleUrls: ['accruals-policy.component.scss'],
  providers: [AccrualsPolicyApiService]
})
export class AccrualsPolicyComponent implements OnInit {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @ViewChild(ModalAnchorDirective, { static: true })
  private modalAnchor: ModalAnchorDirective;

  public orgLevelId: number;
  public isCreateorEditPolicy: boolean;
  public isCreateorEditAccrualsRolePermission: boolean;
  public isCopyPolicy: boolean;
  public isCopyAccrualsRolePermission: boolean;
  public isDeleteAccrualsRolePermission: boolean;
  public policyForm: UntypedFormGroup;
  public text: string;
  public appConfig: IApplicationConfig;
  public isNewPolicy: boolean = false;
  public errorMessage: string = "";
  public isShow: boolean = false;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  public accrualPolicies: AccrualPolicy[] = [];
  public accrualRules: Rules[] = [];
  public originalAccrualRules: Rules[] = [];
  public accrualActions: Actions;
  public isLoading: boolean = false;
  public isPolicyLoading: boolean = false;
  public isDisabled: boolean;
  public isExpanded: boolean = false;
  public accDropDown: any;
  public lastIndex: number;
  public isBenefitTypeVisible: boolean = false;
  public disabled: boolean;
  public disableSeniorityRule: boolean;
  public isViewed: number = 0;
  public isActive: boolean = false;
  public isEditActive: boolean = false;
  public dialogInfo: any;
  public ruleId: number;
  public allOtherHoursDropDown: any;
  public oldPolicyName: string;
  public oldBenefitName: string;
  public m_minimumDate: Date = new Date("1/1/2014");
  public m_maximumDate: Date = new Date("6/6/2079");
  public rdoAccrualsTypeDates: number;
  public rdoAccrualsTypePeriod: number;
  public sendData: any[] = [];
  private subscriptions: StringMap<Subscription> = {};
  public isDeletePolicy: boolean;
  public isAbilitytoChangeAccrualRuleTypeBasedonSeniority: boolean;
  public orgLevelName: string;

  constructor(private accrualsPolicyDefinitionsApiService: AccrualsPolicyApiService,
    public manService: AccrualPolicyManagementService,
    private fileService: FileService,
    private appSettingManageService: AppSettingsManageService,
    public fb: UntypedFormBuilder,
    public accrualPolicyMapService: AccrualsPolicyMapService,
    private modalService: ModalService,
    public notificationService: NotificationsService,
    public cdRef: ChangeDetectorRef,
    public localService: LocalStorageService,
    public renderer: Renderer2) {
    this.appConfig = appConfig;
    this.accDropDown = AccrualPolicyDropDown;
    this.dialogInfo = AccrualPolicyDialogInfo;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.manService.menuBtnClick) {
        this.isShow = false;
        this.manService.isActive = false;
      }
      this.manService.menuBtnClick = false;
    });
  }

  public ngOnInit() {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevelId = orgLevel.id;
      this.orgLevelName = orgLevel.name;
      this.bindOnLoad();
      this.getSettings();
      if(this.manService.isDisabled){
        this.manService.isDisabled = false;
      }
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public onSectionExpandChanged(accrualPolicyFormGroup: UntypedFormGroup, policyId: number) {
    this.isPolicyLoading = true;
    accrualPolicyFormGroup.get('expanded').setValue(!accrualPolicyFormGroup.get('expanded').value);
    setTimeout(() => {
      this.isPolicyLoading = false;
    }, 1000);
  }

  public onExpandCollapse(isExpand: boolean): void {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    var policiesArray = this.policyForm.get('policies') as UntypedFormArray;
    policiesArray.controls.forEach(control => {
      control.get('expanded').setValue(isExpand);
    })
  }

  public bindOnLoad() {
    if (this.orgLevelId) {
      this.isLoading = true;
      this.accrualsPolicyDefinitionsApiService.getAccrualPolicyDetailed(this.orgLevelId)
        .then((accrualPolicyDefinition: any) => {
          this.isLoading = false;
          this.accrualPolicies = accrualPolicyDefinition.accrualPolicy;
          this.manService.accrualPoliciesData = this.accrualPolicies;
          this.validateExistingPolicyNames();
          this.manService.accrualPolicies = accrualPolicyDefinition.accrualPolicy;
          this.accrualActions = accrualPolicyDefinition.actions;
          this.isCreateorEditAccrualsRolePermission = accrualPolicyDefinition.CreateorEditAccruals;
          this.isDeleteAccrualsRolePermission = accrualPolicyDefinition.DeleteAccruals;
          this.isCopyAccrualsRolePermission=accrualPolicyDefinition.CopyAccrualPolicy;
          this.buildAndLoadForm();
        })
    }
  }

  public buildAndLoadForm() {
    this.policyForm = new UntypedFormGroup({
      policies: new UntypedFormArray([])
    });
    this.fetchFormValues(this.accrualPolicies);
  }

  public fetchFormValues(data: any) {
    var policiesArray = this.policyForm.get('policies') as UntypedFormArray;
    data.forEach(policy => {
      var policyGroup = this.patchForm(policy);
      policiesArray.push(policyGroup);
    });
  }

  public patchForm(data: any): UntypedFormGroup {
    return this.fb.group({
      id: new UntypedFormControl(data.id),
      policyName: new UntypedFormControl(data.policyName),
      orgLevelId: new UntypedFormControl(data.orgLevelId),
      policyStartDate: new UntypedFormControl(data.policyStartDate),
      policyEndDate: new UntypedFormControl(data.policyEndDate),
      rules: this.patchRulesArray(data.rules),
      // for ui purpose only
      oldPolicyName: new UntypedFormControl(data.policyName),
      expanded: new UntypedFormControl(this.isExpanded),
      areRulesPresent: new UntypedFormControl(data.rules.length > 0),
      isPolicyViewMode: new UntypedFormControl(true),
      isNewPolicy: new UntypedFormControl(this.isNewPolicy)
    });
  }

  private patchRulesArray(rules: Array<Rules>) {
    var rulesFormArray = new UntypedFormArray([]);
    rules.forEach((c) => {
      rulesFormArray.push(this.fb.group({
        id: new UntypedFormControl(c.id),
        ruleName: new UntypedFormControl(c.ruleName),
        accrualTypeId: new UntypedFormControl(c.accrualTypeId),
        accrualTypeName: new UntypedFormControl(c.accrualTypeName),
        startDate: new UntypedFormControl(c.startDate),
        endDate: new UntypedFormControl(c.endDate),
        anchorDateSource: new UntypedFormControl(c.anchorDateSource),
        accrualCalcType: new UntypedFormControl(c.accrualCalcType),
        accrualCalcTypeName: new UntypedFormControl(c.accrualCalcTypeName),
        accruedOnPeriod: new UntypedFormControl(c.accruedOnPeriod),
        maxBasisHours: new UntypedFormControl(c.maxBasisHours),
        minimumRequiredHours: new UntypedFormControl(c.minimumRequiredHours),
        deferralPeriodValue: new UntypedFormControl(c.deferralPeriodValue),
        deferralPeriodType: new UntypedFormControl(c.deferralPeriodType),
        probationValue: new UntypedFormControl(c.probationValue),
        probationType: new UntypedFormControl(c.probationType),
        maxAnnualEarnedType: new UntypedFormControl(c.maxAnnualEarnedType),
        rollOverMax: new UntypedFormControl(c.rollOverMax),
        deleted: new UntypedFormControl(c.deleted),
        rateFormula: new UntypedFormControl(c.rateFormula),
        carryover: new UntypedFormControl(c.carryover),
        carryoverDay: new UntypedFormControl(c.carryoverDay),
        carryoverType: new UntypedFormControl(c.carryoverType),
        carryoverValue: new UntypedFormControl(c.carryoverValue),
        carryoverToAccrualTypeName: new UntypedFormControl(c.carryoverToAccrualTypeName),
        isBenefitViewMode: true,
        oldPolicyName: new UntypedFormControl(this.oldPolicyName),
        oldBenefitName: new UntypedFormControl(this.oldBenefitName),
        rdoAccrualsTypePeriod: new UntypedFormControl(this.rdoAccrualsTypePeriod),
        rdoAccrualsTypeDates: new UntypedFormControl(this.rdoAccrualsTypeDates),
        addNewBenefit: new UntypedFormControl(false),
        seniorityRules: this.patchSeniorityRulesArray(c.seniorityRules),
        //ui purpose only
        areSeniorityRulesPresent: new UntypedFormControl(c.seniorityRules.length > 0)
      }))
    })
    return rulesFormArray;
  }

  private patchSeniorityRulesArray(seniorityRules: Array<SeniorityRules>) {
    var seniorityRulesFormArray = new UntypedFormArray([]);
    seniorityRules.forEach((d) => {
      seniorityRulesFormArray.push(this.fb.group({
        id: new UntypedFormControl(d.id),
        ruleId: new UntypedFormControl(d.ruleId),
        periodFromValue: new UntypedFormControl(d.periodFromValue),
        periodFromType: new UntypedFormControl(d.periodFromType),
        periodToValue: new UntypedFormControl(d.periodToValue),
        periodToType: new UntypedFormControl(d.periodToType),
        accrualRate: new UntypedFormControl(d.accrualRate),
        rateUnit: new UntypedFormControl(d.rateUnit),
        basisUnit: new UntypedFormControl(d.basisUnit),
        rateFormula: new UntypedFormControl(d.rateFormula),
        accruedOnDates: new UntypedFormControl(d.accruedOnDates),
        minRequiredHours: new UntypedFormControl(d.minRequiredHours),
        accruedOnDay: new UntypedFormControl(d.accruedOnDay),
        maxAccruedAnnual: new UntypedFormControl(d.maxAccruedAnnual),
        maxAccruedBalance: new UntypedFormControl(d.maxAccruedBalance),
        carryoverValue: new UntypedFormControl(d.carryoverValue),
        carryoverType: new UntypedFormControl(d.carryoverType),
        carryoverDay: new UntypedFormControl(d.carryoverDay),
        carryoverValueType: new UntypedFormControl(d.carryoverValueType),
        carryoverExpirationDays: new UntypedFormControl(d.carryoverExpirationDays),
        carryoverToAccrualTypeId: new UntypedFormControl(d.carryoverToAccrualTypeId),
        carryoverToAccrualTypeName: new UntypedFormControl(d.carryoverToAccrualTypeName),
        ruleCalcType: new UntypedFormControl(d.ruleCalcType),
        rollOverMax: new UntypedFormControl(d.rollOverMax),
        accruedOn_Seniority: new UntypedFormControl(d.accruedOn_Seniority),
        basisHour_Seniority: new UntypedFormControl(d.basisHour_Seniority) 
      }));
    });
    return seniorityRulesFormArray;
  }

  public addBenefit(accrualPolicyFormGroup: UntypedFormGroup, rules: UntypedFormGroup, index: number) {
    document.getElementById("myDropdown").classList.toggle("show");
    this.manService.isShow = false;
    let rulesFormArray = accrualPolicyFormGroup.controls['rules'] as UntypedFormArray;
    let rulesFormGroup = rulesFormArray.controls[index] as UntypedFormGroup;
    if (!rulesFormGroup.get('isBenefitViewMode').value) {
      rulesFormGroup.get('isBenefitViewMode').setValue(true);
      rulesFormGroup.controls.startDate.setErrors(null);
      rulesFormGroup.controls.endDate.setErrors(null);
      this.disabled = false;
    }
    rulesFormArray.push(this.fb.group({
      id: new UntypedFormControl(1),
      ruleName: new UntypedFormControl(''),
      accrualTypeId: new UntypedFormControl(''),
      accrualTypeName: new UntypedFormControl('', [Validators.required, this.manService.noWhitespaceValidator]),
      startDate: new UntypedFormControl(null),
      endDate: new UntypedFormControl(null),
      anchorDateSource: new UntypedFormControl(''),
      accrualCalcType: new UntypedFormControl(1),
      accrualCalcTypeName: new UntypedFormControl(''),
      accruedOnPeriod: new UntypedFormControl('Pay Period'),
      maxBasisHours: new UntypedFormControl('', [Validators.min(1), Validators.max(999)]),
      minimumRequiredHours: new UntypedFormControl(''),
      deferralPeriodValue: new UntypedFormControl(''),
      deferralPeriodType: new UntypedFormControl(''),
      probationValue: new UntypedFormControl(''),
      probationType: new UntypedFormControl(''),
      maxAnnualEarnedType: new UntypedFormControl(''),
      rollOverMax: new UntypedFormControl(''),
      deleted: new UntypedFormControl(''),
      rateFormula: new UntypedFormControl(''),
      carryover: new UntypedFormControl(''),
      carryoverDay: new UntypedFormControl('', [carryOverTypeValidator]),
      carryoverType: new UntypedFormControl('Entered Value'),
      carryoverValue: new UntypedFormControl(''),
      carryoverToAccrualTypeName: new UntypedFormControl(''),
      isBenefitViewMode: false,
      oldPolicyName: this.oldPolicyName,
      oldBenefitName: this.oldBenefitName,
      rdoAccrualsTypePeriod: new UntypedFormControl(this.rdoAccrualsTypePeriod),
      rdoAccrualsTypeDates: new UntypedFormControl(this.rdoAccrualsTypeDates),
      addNewBenefit: true,
      seniorityRules: this.fb.array([this.fb.group({
        id: new UntypedFormControl(1),
        ruleId: new UntypedFormControl(''),
        periodFromValue: new UntypedFormControl(''),
        periodFromType: new UntypedFormControl('Days'),
        periodToValue: new UntypedFormControl(''),
        periodToType: new UntypedFormControl('Months'),
        accrualRate: new UntypedFormControl(''),
        rateUnit: new UntypedFormControl('hours'),
        basisUnit: new UntypedFormControl('Hour'),
        rateFormula: new UntypedFormControl(''),
        accruedOnDates: new UntypedFormControl(''),
        minRequiredHours: new UntypedFormControl(''),
        accruedOnDay: new UntypedFormControl(''),
        maxAccruedAnnual: new UntypedFormControl(''),
        maxAccruedBalance: new UntypedFormControl(''),
        carryoverValue: new UntypedFormControl(''),
        carryoverType: new UntypedFormControl('Entered Value'),
        carryoverDay: new UntypedFormControl(''),
        carryoverValueType: new UntypedFormControl(''),
        carryoverExpirationDays: new UntypedFormControl(''),
        carryoverToAccrualTypeId: new UntypedFormControl(''),
        carryoverToAccrualTypeName: new UntypedFormControl(''),
        ruleCalcType: new UntypedFormControl(''),
        rollOverMax: new UntypedFormControl(''),
        accruedOn_Seniority: new UntypedFormControl('Pay Period'),
        basisHour_Seniority: new UntypedFormControl('') 
      })])
    }, {validator: dateRangeValidator}))
  }

  // Add Seniority Band under the Benefit Type
  public addseniorityRule(accrualPolicyFormGroup: UntypedFormGroup, index: number, srIndex: number) {
    let rulesFormArray = accrualPolicyFormGroup.controls['rules'] as UntypedFormArray;
    let seniorityRulesFormGroup = rulesFormArray.controls[index] as UntypedFormGroup;
    let seniorityRulesFormArray = seniorityRulesFormGroup.controls['seniorityRules'] as UntypedFormArray;
    seniorityRulesFormArray.push(
      this.fb.group({
        id: new UntypedFormControl(seniorityRulesFormArray.length + 1),
        ruleId: new UntypedFormControl(0),
        periodFromValue: new UntypedFormControl(''),
        periodFromType: new UntypedFormControl('Days'),
        periodToValue: new UntypedFormControl(''),
        periodToType: new UntypedFormControl('Months'),
        accrualRate: new UntypedFormControl(''),
        rateUnit: new UntypedFormControl('hours'),
        basisUnit: new UntypedFormControl('Hour'),
        rateFormula: new UntypedFormControl(''),
        accruedOnDates: new UntypedFormControl(''),
        minRequiredHours: new UntypedFormControl(''),
        accruedOnDay: new UntypedFormControl(''),
        maxAccruedAnnual: new UntypedFormControl(''),
        maxAccruedBalance: new UntypedFormControl(''),
        carryoverValue: new UntypedFormControl(''),
        carryoverType: new UntypedFormControl('Entered Value'),
        carryoverDay: new UntypedFormControl(''),
        carryoverValueType: new UntypedFormControl(''),
        carryoverExpirationDays: new UntypedFormControl(''),
        carryoverToAccrualTypeId: new UntypedFormControl(''),
        carryoverToAccrualTypeName: new UntypedFormControl(''),
        ruleCalcType: new UntypedFormControl(''),
        rollOverMax: new UntypedFormControl(''),
        accruedOn_Seniority: new UntypedFormControl('Pay Period'),
        basisHour_Seniority: new UntypedFormControl('') 
      }));
  }

  public removeSeniorityRule(accrualPolicyFormGroup: UntypedFormGroup, childIndex: number, childParentIndex: number) {
    let rulesFormArray = accrualPolicyFormGroup.controls['rules'] as UntypedFormArray;
    let seniorityRulesFormGroup = rulesFormArray.controls[childIndex] as UntypedFormGroup;
    let seniorityRulesFormArray = seniorityRulesFormGroup.controls['seniorityRules'] as UntypedFormArray;
    seniorityRulesFormArray.removeAt(childParentIndex);
  }

  createPolicyForm() {
    this.isNewPolicy = true;
    this.manService.isShow = false;
    this.policyForm = new UntypedFormGroup({
      policies: new UntypedFormArray([
        this.fb.group({
          id: new UntypedFormControl(1),
          policyName: new UntypedFormControl('', [Validators.required, this.manService.noWhitespaceValidator, this.manService.policyNameValidator]),
          orgLevelId: new UntypedFormControl(this.orgLevelId),
          lastUpdateId: new UntypedFormControl(''),
          lastUpdateDate: new UntypedFormControl(''),
          policyStartDate: new UntypedFormControl(''),
          policyEndDate: new UntypedFormControl(''),
          expanded: new UntypedFormControl(true),
          isNewPolicy: new UntypedFormControl(this.isNewPolicy),
          rules: this.fb.array([this.fb.group({
            id: new UntypedFormControl(1),
            ruleName: new UntypedFormControl(''),
            accrualTypeId: new UntypedFormControl(''),
            accrualTypeName: new UntypedFormControl('', [Validators.required, this.manService.noWhitespaceValidator]),
            startDate: new UntypedFormControl(null),
            endDate: new UntypedFormControl(null),
            anchorDateSource: new UntypedFormControl(''),
            accrualCalcType: new UntypedFormControl(1),
            accrualCalcTypeName: new UntypedFormControl(''),
            accruedOnPeriod: new UntypedFormControl('Pay Period'),
            maxBasisHours: new UntypedFormControl(''),
            minimumRequiredHours: new UntypedFormControl(''),
            deferralPeriodValue: new UntypedFormControl(''),
            deferralPeriodType: new UntypedFormControl(''),
            probationValue: new UntypedFormControl(''),
            probationType: new UntypedFormControl(''),
            maxAnnualEarnedType: new UntypedFormControl('Anniversary Year'),
            rollOverMax: new UntypedFormControl(''),
            deleted: new UntypedFormControl(''),
            rateFormula: new UntypedFormControl(''),
            carryover: new UntypedFormControl(''),
            carryoverDay: new UntypedFormControl('', [carryOverTypeValidator]),
            carryoverType: new UntypedFormControl('Entered Value'),
            carryoverValue: new UntypedFormControl(''),
            carryoverToAccrualTypeName: new UntypedFormControl(''),
            isBenefitViewMode: false,
            addNewBenefit: true,
            rdoAccrualsTypePeriod: new UntypedFormControl(''),
            rdoAccrualsTypeDates: new UntypedFormControl(''),
            oldPolicyName: null,
            oldBenefitName: null,
            seniorityRules: this.fb.array([this.fb.group({
              id: new UntypedFormControl(1),
              ruleId: new UntypedFormControl(0),
              periodFromValue: new UntypedFormControl(''),
              periodFromType: new UntypedFormControl('Days'),
              periodToValue: new UntypedFormControl(''),
              periodToType: new UntypedFormControl('Months'),
              accrualRate: new UntypedFormControl(''),
              rateUnit: new UntypedFormControl('hours'),
              basisUnit: new UntypedFormControl('Hour'),
              rateFormula: new UntypedFormControl(''),
              accruedOnDates: new UntypedFormControl(''),
              minRequiredHours: new UntypedFormControl(''),
              accruedOnDay: new UntypedFormControl(''),
              maxAccruedAnnual: new UntypedFormControl(''),
              maxAccruedBalance: new UntypedFormControl(''),
              carryoverValue: new UntypedFormControl(''),
              carryoverType: new UntypedFormControl('Entered Value'),
              carryoverDay: new UntypedFormControl(''),
              carryoverValueType: new UntypedFormControl(''),
              carryoverExpirationDays: new UntypedFormControl(''),
              carryoverToAccrualTypeId: new UntypedFormControl(''),
              carryoverToAccrualTypeName: new UntypedFormControl(''),
              ruleCalcType: new UntypedFormControl(''),
              rollOverMax: new UntypedFormControl(''),
              accruedOn_Seniority: new UntypedFormControl('Pay Period'),
              basisHour_Seniority: new UntypedFormControl('') 
            })])
          }, {validator: dateRangeValidator})
          ])
        })
      ])
    });
  }

  public generateDynamicUniqId(items: AccrualPolicy[]): string {
    let key: string = _.map(items, (item: AccrualPolicy) => {
      return item.headerName;
    }).join('_');
    return `${this.orgLevelId}_${key}`;
  }

  public getAccrualPolicy(accrualPolicyFormGroup: UntypedFormGroup, rulesFormGroup: UntypedFormGroup) {
    if (this.orgLevelId) {
      let policyId = accrualPolicyFormGroup.get('id').value;
      this.accrualsPolicyDefinitionsApiService.getAccrualPolicy(this.orgLevelId, policyId)
        .then((accrualPolicy: AccrualPolicy) => {
          let rulesData = accrualPolicy.rules;
          this.localService.set("Rules", rulesData);
          this.getAllOtherHoursDropDownItem(rulesData);
        })
    }
  }

  public getAllOtherHoursDropDownItem(items: any[]) {
    let data = [''];
    items.forEach(x => {
      if (x.id != this.ruleId) {
        data.push(x.accrualTypeName);
      }
    });
    this.allOtherHoursDropDown = new Set(data);
  }

  public newBenefitDropdown() {
    let data = [''];
    let rulesData: any = this.localService.get("Rules");
    rulesData.forEach(x => {
      data.push(x.accrualTypeName);
    });
    this.allOtherHoursDropDown = new Set(data);
  }

  // Populate Accrual Policy in the Reactive Form UI
  public accrualPoliciesFormArray() {
    var policiesArray = this.policyForm.get('policies') as UntypedFormArray;
    return policiesArray.controls;
  }

  // Populate Benefit types in the Reactive Form UI
  public rule(accrualPolicyFormGroup: UntypedFormGroup) {
    let rulesFormArray = accrualPolicyFormGroup.controls['rules'] as UntypedFormArray;
    return rulesFormArray.controls;
  }

  // Populate Seniority Band Based on Benefit Types in the Reactive Form UI
  public seniorityRule(accrualPolicyFormGroup: UntypedFormGroup, index: number) {
    let rulesFormArray = accrualPolicyFormGroup.controls['rules'] as UntypedFormArray;
    let seniorityRulesFormGroup = rulesFormArray.controls[index] as UntypedFormGroup;
    let seniorityRulesFormArray = seniorityRulesFormGroup.controls['seniorityRules'] as UntypedFormArray;
    return seniorityRulesFormArray.controls;
  }

  // Edit the Existing Policy
  public editPolicy(accrualPolicy: UntypedFormGroup) {
    accrualPolicy.get('isPolicyViewMode').setValue(false);
    this.isDisabled = true;
    this.localService.remove('Policy Names');
    let policyName: any = [];
    this.accrualPolicies.forEach(x => {
      if (x.policyName != accrualPolicy.get('policyName').value) {
        policyName.push(x.policyName);
      }
    })
    this.localService.set('Policy Names', policyName);
  }

  public existingPolicyCheck(e: any, accrualPolicy: UntypedFormGroup) {
    let accrualPolicyNameControl = accrualPolicy.controls['policyName'];
    accrualPolicyNameControl.setValidators([this.manService.policyNameValidator]);
  }

  public cancelPolicy(accrualPolicyFormGroup: UntypedFormGroup) {
    accrualPolicyFormGroup.get('isPolicyViewMode').setValue(true);
    this.isDisabled = false;
    this.isPolicyLoading = true;
    setTimeout(() => {
      this.isPolicyLoading = false;
    }, 1000);
    if (accrualPolicyFormGroup.dirty) {
      let policyId = accrualPolicyFormGroup.get('id').value;
      for (let i in this.accrualPolicies) {
        if (this.accrualPolicies[i].id == policyId) {
          this.isExpanded = true;
          let data = this.patchForm(this.accrualPolicies[i]);
          setTimeout(() => {
            this.validateExistingPolicyNames();
          }, 2000);
          return accrualPolicyFormGroup.reset(data.value);
        }
      }
    }
  }

  public savePolicyChanges(accrualPolicyFormGroup: UntypedFormGroup) {
    if (this.policyForm.valid) {
      accrualPolicyFormGroup.get('isPolicyViewMode').setValue(true);
      this.isDisabled = false;
      this.isLoading = true;
      let AccrualPolicyName: any = this.accrualPolicyMapService.updatePolicyName(accrualPolicyFormGroup.value);
      this.accrualsPolicyDefinitionsApiService.updatePolicyName(this.orgLevelId, AccrualPolicyName).then((res) => {
        if (res.data !== null && res.data == 'Policy name updated successfully') {
          this.notificationService.success('Updated', res.data);
          this.bindOnLoad();
        }
        else {
          this.notificationService.error('Error', res.data);
          this.bindOnLoad();
        }
      }).catch((err) => {
        console.log(err);
        this.bindOnLoad();
      });
      this.isShow = !this.isShow;
      this.disabled = false;
    }
    else {
      console.log(this.policyForm);
      this.notificationService.info('Policy Form Invalid', 'Missing Required Fields')
    }
  }

  public deletePolicy(accrualPolicy: UntypedFormGroup) {
    let orgLevelId = this.orgLevelId;
    let policyName = this.accrualPolicyMapService.deletePolicy(accrualPolicy.value);
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialogComponent.openDialog(
      'Warning',
      'These policy will be deleted, Would you like to continue?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.isPolicyLoading = true;
          this.accrualsPolicyDefinitionsApiService.deleteAccrualPolicy(orgLevelId, policyName).then((res) => {
            this.bindOnLoad();
            this.notificationService.info('Delete Info', res.data);
            this.isPolicyLoading = false;
          }).catch((err) => {
            console.log(err);
            this.bindOnLoad();
          });
        }
      }, options);
    if (!this.manService.isShow) {
      this.manService.isShow = true;
    }
  }

  public deleteBenefit(accrualPolicy: UntypedFormGroup, accrualBenefit: UntypedFormGroup) {
    let orgLevelId = this.orgLevelId;
    let obj = {
      Accrual_Policy_Id: accrualBenefit.get('id').value,
      policyName: accrualPolicy.get('policyName').value,
      Benefit_Type: accrualBenefit.get('accrualTypeName').value
    }
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialogComponent.openDialog(
      'Warning',
      'These policy Benefit will be deleted, Would you like to continue?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.isPolicyLoading = true;
          this.accrualsPolicyDefinitionsApiService.deleteAccrualBenefit(orgLevelId, obj).then((res) => {
            this.bindOnLoad();
            this.notificationService.info('Delete Info', res.data);
            this.isPolicyLoading = false;
          }).catch((err) => {
            console.log(err);
            this.bindOnLoad();
          });
        }
      }, options);
    if (!this.manService.isShow) {
      this.manService.isShow = true;
    }
    this.manService.dropDownClose(accrualBenefit);
  }

  public editRule(accrualPolicyFormGroup: UntypedFormGroup, ruleFormGroup: UntypedFormGroup, ruleIndex: number) {
    ruleFormGroup.get('isBenefitViewMode').setValue(false);
    accrualPolicyFormGroup.get('isPolicyViewMode').setValue(true);
    this.oldPolicyName = accrualPolicyFormGroup.get('policyName').value;
    this.oldBenefitName = ruleFormGroup.get('accrualTypeName').value;
    this.isDisabled = true;
    this.isEditActive = !this.isEditActive;
    this.ruleId = ruleFormGroup.get('id').value;
    this.getAccrualPolicy(accrualPolicyFormGroup, ruleFormGroup);
    let sr = ruleFormGroup.value;
    if (sr.seniorityRules.length == 0) {
      this.addseniorityRule(accrualPolicyFormGroup, ruleIndex, null);
    }
    if(this.manService.isShow) {
      this.manService.isShow = false;
    }
  }

  public cancelRule(accrualPolicyFormGroup: UntypedFormGroup, ruleFormGroup: UntypedFormGroup, index: number) {
    let rulesFormArray = accrualPolicyFormGroup.controls['rules'] as UntypedFormArray;
    let seniorityRulesFormGroup = rulesFormArray.controls[index] as UntypedFormGroup;
    let seniorityRulesFormArray = seniorityRulesFormGroup.controls['seniorityRules'] as UntypedFormArray;
    ruleFormGroup.get('isBenefitViewMode').setValue(true);
    this.isDisabled = false;
    this.isNewPolicy = false;
    this.isExpanded = false;
    this.manService.dropDownClose(ruleFormGroup);
    this.clearAll();
    this.bindOnLoad();
    let invalidIndex = seniorityRulesFormArray.controls.findIndex((control) => control.get('id').value == '');
    if (invalidIndex != -1) {
      this.removeSeniorityRule(accrualPolicyFormGroup, index, invalidIndex);
    }
    if (accrualPolicyFormGroup.dirty) {
      let policyId = accrualPolicyFormGroup.get('id').value;
      for (let i in this.accrualPolicies) {
        if (this.accrualPolicies[i].id == policyId) {
          this.isExpanded = false;
          let data = this.patchForm(this.accrualPolicies[i]);
          this.manService.dropDownClose(ruleFormGroup);
          return accrualPolicyFormGroup.reset(data.value);
        }
      }
    }
    if (!this.manService.isShow) {
      this.manService.isShow = true;
    }
    
  }

  public saveRuleChanges(accrualPolicyFormGroup: UntypedFormGroup, ruleFormGroup: UntypedFormGroup) {
    if (this.policyForm.valid) {
      ruleFormGroup.get('isBenefitViewMode').setValue(true);
      accrualPolicyFormGroup.get('policyStartDate').setValue(ruleFormGroup.get('startDate').value);
      accrualPolicyFormGroup.get('policyEndDate').setValue(ruleFormGroup.get('endDate').value);
      let newBenefitId = ruleFormGroup.get('id').value;
      this.manService.setRequiredValues(accrualPolicyFormGroup, ruleFormGroup);
      this.isDisabled = false;
      this.isLoading = true;
      if (ruleFormGroup.get('addNewBenefit').value) {
        ruleFormGroup.get('oldBenefitName').setValue(null);
      }
      let AccrualPolicy: any = this.accrualPolicyMapService.reverseMapAccrualPolicy(accrualPolicyFormGroup.value);
      AccrualPolicy.rules.forEach(x => {
        if (x.id == this.ruleId && !ruleFormGroup.get('addNewBenefit').value) {
          if (!accrualPolicyFormGroup.get('isNewPolicy').value) {
            x.oldPolicyName = this.oldPolicyName;
            x.oldBenefitName = this.oldBenefitName;
          }
          AccrualPolicy.rules = [x];
        }
        if (x.id == newBenefitId && ruleFormGroup.get('addNewBenefit').value) {
          AccrualPolicy.rules = [x];
        }
      })
      this.accrualsPolicyDefinitionsApiService.editAccrualPolicy(this.orgLevelId, AccrualPolicy).then((res) => {
        if (res == 'Record Saved Successfully.') {
          if (accrualPolicyFormGroup.get('isNewPolicy').value) {
            this.notificationService.success('Policy Created', res);
            this.isNewPolicy = false;
          }
          else {
            this.notificationService.success('Updated', res);
            this.isNewPolicy = false;
          }
          this.bindOnLoad();
        }
        else {
          this.notificationService.error('Error', res);
          this.isNewPolicy = false;
          this.bindOnLoad();
        }
      }).catch((err) => {
        console.log(err);
        this.isNewPolicy = false;
        this.bindOnLoad();
      });
      if (!this.manService.isShow) {
        this.manService.isShow = true;
      }
      this.manService.dropDownClose(ruleFormGroup);
      this.clearAll();
    }
    else {
      console.log(this.policyForm);
      this.notificationService.info('Policy Form Invalid', 'Missing Required Fields')
    }
  }

  public onClickExportExcel(): void {
    this.isLoading = true;
    this.accrualsPolicyDefinitionsApiService.getAccrualPolicyExportToExcel(this.orgLevelId)
      .then((file: FileBlobResponse) => {
        this.fileService.saveToFileSystem(file.blob, file.file);
        this.isLoading = false;
      }).catch((reason: any) => {
        this.isLoading = false;
      });
  }

  public onClickExportPdf(): void {
    this.isLoading = true;
    this.accrualsPolicyDefinitionsApiService.getAccrualPolicyExportToPdf(this.orgLevelId)
      .then((file: FileBlobResponse) => {
        this.fileService.saveToFileSystem(file.blob, file.file);
        this.isLoading = false;
      }).catch((reason: any) => {
        this.isLoading = false;
      });

  }

  public async getSettings(): Promise<void> {
    let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
    this.isCreateorEditPolicy = appServerConfig.CreateorEditPolicy;
    this.isDeletePolicy = appServerConfig.Deletepolicy;
    this.isCopyPolicy=appServerConfig.CopyAccrualPolicy;
    this.isAbilitytoChangeAccrualRuleTypeBasedonSeniority = appServerConfig.AbilitytoChangeAccrualRuleTypeBasedonSeniority;
  }

  public getMinDate(child: UntypedFormGroup): Date {
    return child.get('startDate').value ? new Date(child.get('startDate').value) : new Date();
  }

  // getMaxDate to get date
  public getMaxOrEndDate(child: UntypedFormGroup): Date {
    return child.get('endDate').value ? new Date(child.get('endDate').value) : this.m_maximumDate;
  }

  public getMaxDate(child: UntypedFormGroup): Date {
    return child.get('endDate').value ? new Date(child.get('endDate').value) : this.manService.maxDate();
  }

  public maxDate(): Date {
    return this.m_maximumDate;
  }

  public clearAll() {
    this.manService.isActive = false;
    this.allOtherHoursDropDown = null;
    this.isDisabled = false;
    this.localService.remove("Rules");
    this.localService.remove("Policy Names");
  }

  public validateExistingPolicyNames() {
    let policyName: any = [];
    this.accrualPolicies.forEach(x => {
      policyName.push(x.policyName);
    })
    this.localService.set('Policy Names', policyName);
  }
  public carryoverDayChange(value: string, control: AbstractControl) {
    const regex = /(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)(0[1-9]|[12]\d|3[01])/;
    const isValidValue = regex.test(value.toLowerCase());
    if(!isValidValue) {
      control.setErrors({ 'carryoverDayInvalid': true })
    }
    else {
      control.setErrors(null);
    }
  }

}



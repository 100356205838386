import { OrgLevel } from '../../../../../state-model/models/index';
import { ChildTreeView } from '../../../../../components-library/models/tree-view';
import { Input, Directive } from '@angular/core';

@Directive()
export class PbjNewExportHierarchyHelper {
    @Input()
    public canGeneratePBJFromAgency: boolean;
    organizationIds : number[]=[];
    constructor() { }

    setOrgHierarchy(orgLevel: OrgLevel, type) {
        let topOrg = new ChildTreeView();
        topOrg.text = orgLevel.name;
        topOrg.value =  orgLevel.id ;
        if( type == 'mealConfigDialog' && orgLevel.type == 'Organization') topOrg.value = orgLevel.organizationId;
        topOrg.children = [];
        if (orgLevel.childs.length > 0) {
          orgLevel.childs.forEach(childOrg => {
            if (childOrg.type != 'Department' && childOrg.id != 1000) {
              let child = new ChildTreeView();
              child.text = childOrg.name;
              child.value = childOrg.id;
              if( type == 'mealConfigDialog' && childOrg.type == 'Organization') child.value = childOrg.organizationId;
              child.children = [];
              if (childOrg.name.toLowerCase() == 'agency' && this.canGeneratePBJFromAgency) topOrg.children.push(child);
              if (childOrg.name.toLowerCase() != 'agency') topOrg.children.push(child);
              child.collapsed = true;
              if (childOrg.childs.length > 0) {
                this.setSubLevelOrgHierarchy(childOrg, child, type);
              }
              this.setOrgHierarchy(childOrg,type);
            }
          })
        }
        return topOrg
      }
    
      setSubLevelOrgHierarchy(org1, obj, type) {
        org1.childs.forEach(childOrg => {
          if (childOrg.type != 'Department') {
            let child = new ChildTreeView();
            child.text = childOrg.name;
            child.value = childOrg.id;
            if( type == 'mealConfigDialog' && childOrg.type == 'Organization') child.value = childOrg.organizationId;
            child.children = [];
            child.collapsed = true;
            if (childOrg.name.toLowerCase() == 'agency' && this.canGeneratePBJFromAgency) obj.children.push(child);
            if (childOrg.name.toLowerCase() != 'agency') obj.children.push(child);
            if (childOrg.childs.length > 0) {
              this.setSubLevelOrgHierarchy(childOrg, child,type);
            }
          }
        });
      }

  public getAllMealConfigOrgIds(orgLevel: OrgLevel){
    if(orgLevel.type!="Organization" && orgLevel.type!="Department"){
      if(orgLevel.childs.length > 0){
      let orgs1 = orgLevel.childs.filter(x=> x.type=="Organization");
       orgs1 = orgs1.filter( x=> x.organizationId!=1000);
      if(orgs1.length > 0) this.organizationIds = this.organizationIds.concat(orgs1.map(child => child.organizationId)); 
        orgLevel.childs.forEach( child =>{
          this.getAllMealConfigOrgIds(child);
        })
      }
  }
  sessionStorage.setItem('allConfigOrgIds', JSON.stringify(this.organizationIds));
 }
}
export * from './app-settings-manage.service';
export * from './app-settings-api.service';
export * from './app-settings-map.service';
import { Provider } from '@angular/core';
import { AppSettingsManageService } from './app-settings-manage.service';
import { AppSettingsApiService } from './app-settings-api.service';
import { AppSettingsMapService } from './app-settings-map.service';


export const services: Provider[] = [AppSettingsApiService, AppSettingsManageService, AppSettingsMapService];

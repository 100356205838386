
import {combineLatest} from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ConfirmDialogComponent } from './../../../common/components/confirm-dialog/confirm-dialog.component';
import { DialogOptions } from './../../../common/models/dialog-options';
import { ModalService } from './../../../common/services/modal/modal.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, UrlSegment, Router, Params } from '@angular/router';

import { Observable ,  Subscription } from 'rxjs';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';

import { CommonValidators } from '../../../common/validators/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';

import { CensusEntryApiService } from '../../services/index';
import { Census, CensusEntry, CensusSaveRequest } from '../../models/index';
import { ScheduleValidators } from '../../validators/index';
import { appConfig } from '../../../app.config';
import { Assert } from '../../../framework/index';

import { NgForm } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-census-entries-page',
  templateUrl: 'census-entries-page.component.html',
  styleUrls: ['census-entries-page.component.scss']
})
export class CensusEntriesPageComponent {

  public entryDate: Date;
  @unsubscribe()
  private routeSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute) {
    this.routeSubscription = this.activatedRoute.params.pipe(
      combineLatest(this.activatedRoute.queryParams))
      .subscribe(([params, queryParams]) => {
        let dateOn = queryParams['date'];
        if (!dateOn) {
          dateOn = new Date();
        }
        if (!this.entryDate) {
          this.entryDate = moment(dateOn, appConfig.linkDateFormat).toDate();
        }
      });
  }
}

import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { Subject ,  ReplaySubject ,  Subscription } from 'rxjs';

import { ZonedDate } from '@progress/kendo-date-math';

import { Assert } from '../../../../framework/assert/assert';

import { LookupService } from '../../../../organization/services/index';

import { Lookup, LookupType, Employee, LoaAttachment, ReadFile } from '../../../../organization/models/index';
import { IDateRange, DateRange } from '../../../../core/models/index';

import { LMApiService } from '../lm-api.service';
import {
  LoaType,
  LoaRequest,
  LoaRepeat,
  LoaRequestEmployee,
  RecurrenceFrequencies,
  RecurrenceFrequencyDefinition,
  RecurrenceFrequency,
  WeekDay,
  WeekDays,
  RecurrenceRuleOption,
  OffsetPosition,
  LoaMappedRequest
} from '../../models/index';
import { LMManagementService } from '../../services/lm-management.service';
import { LMRecurrenceService } from '../../services/lm-recurrence/lm-recurrence.service';
import { LMRequestMapperService } from '../../services/lm-request-mapper/lm-request-mapper.service';
import { FileService } from '../../../../common/index';
import { LoaRequestClass } from '../../../../common/models';


@Injectable()
export class LMCreationAbsenceManagementService {
  private orgLevelId: number;
  private m_loaRequest: LoaRequest;
  private loading$ = new ReplaySubject<boolean>(1);
  private addedNewLoa$ = new ReplaySubject<LoaRequest>(1);
  private requestChange$ = new ReplaySubject<LoaRequest>(1);
  private closePopup$ = new Subject<LoaRequest>();
  private editModeOn: boolean = false;
  private recurrenceInitialized: boolean = false;
  private readonly weekDaysNames = _.values(WeekDays);
  private readonly dayOfStartRecurrence = WeekDays.monday;
  private customDates: DateRange[] = [];

  constructor(
    private manService: LMManagementService,
    private recurrenceService: LMRecurrenceService,
    private mapperService: LMRequestMapperService,
    private lookupService: LookupService,
    private apiService: LMApiService,
    private fileService: FileService
  ) { }

  public get loaRequest(): LoaRequest {
    return _.cloneDeep(this.m_loaRequest);
  }

  public get isContinuous(): boolean {
    return _.get(this.m_loaRequest, 'requestClass') === LoaRequestClass.Continuous;
  }

  public get isIntermittent(): boolean {
    return _.get(this.m_loaRequest, 'requestClass') === LoaRequestClass.Intermittent;
  }

  public get canEditRequest(): boolean {
    return this.manService.canAddEdit;
  }

  public get canDeleteRequest(): boolean {
    return this.manService.canDelete;
  }

  public get editMode(): boolean {
    return this.editModeOn;
  }

  public get canChange(): boolean {
    return this.canEditRequest && this.editMode;
  }

  public get isCreatingNew(): boolean {
    return !_.isFinite(this.m_loaRequest && this.m_loaRequest.id);
  }

  public get hasEstimatedDates(): boolean {
    return _.isDate(this.m_loaRequest.estStartDate) && _.isDate(this.m_loaRequest.estEndDate);
  }

  public get hasActualDates(): boolean {
    return _.isDate(this.m_loaRequest.actStartDate) && _.isDate(this.m_loaRequest.actEndDate);
  }

  public get recurrenceFrequencies(): RecurrenceFrequency[] {
    return this.recurrenceService.getRecurrenceFrequencies();
  }

  public get weekDays(): WeekDay[] {
    return this.recurrenceService.getWeekDays();
  }

  public get offsetPositions(): OffsetPosition[] {
    return this.recurrenceService.getOffsetPositions();
  }

  public get isCustom(): boolean {
    return this.m_loaRequest.isCustom;
  }

  public get isDaily(): boolean {
    return this.m_loaRequest.isDaily;
  }

  public get isWeekly(): boolean {
    return this.m_loaRequest.isWeekly;
  }

  public get isMonthly(): boolean {
    return this.m_loaRequest.isMonthly;
  }

  public get hasCorrectContinuousDates(): boolean {
    return this.m_loaRequest.hasCorrectContinuousDates;
  }

  public get hasCorrectRepeatableDates(): boolean {
    return this.m_loaRequest.hasCorrectRepeatableDates;
  }

  public get hasCorrectCustomDates(): boolean {
    return this.m_loaRequest.hasCorrectCustomDates;
  }

  private get weekDayIndex(): number {
    return _.indexOf(this.weekDaysNames, this.dayOfStartRecurrence);
  }

  public init(orgLevelId: number, req: LoaRequest): void {
    this.orgLevelId = orgLevelId;
    this.m_loaRequest = _.cloneDeep(req);
    this.customDates = this.m_loaRequest.loaRepeat.customDates;
    if (this.m_loaRequest && this.orgLevelId) {
      if (this.isCreatingNew) {
        this.toggleEditMode(true);
      }
      this.updateRequestData();
    }
  }

  public initRecurrenceService(rrule: string, freq: RecurrenceFrequencyDefinition): void {
    if (!this.recurrenceInitialized) {
      let reccurence: string;
      if (_.isString(rrule) && _.size(rrule) > 0) {
        reccurence = this.recurrenceService.parseRule(rrule);
      }
      if (_.isString(freq) && _.size(freq) > 0) {
        reccurence = this.recurrenceService.setRule(freq, this.weekDayIndex);
      }
      this.m_loaRequest.loaRepeat.recurrenceRule = _.isString(reccurence) && reccurence || '';
      this.recurrenceInitialized = true;
    }
  }

  public destroy(): void {
    this.m_loaRequest = null;
    this.loading$.complete();
    this.addedNewLoa$.complete();
    this.requestChange$.complete();
    this.closePopup$.complete();
    this.recurrenceInitialized = false;
  }

  public loadRequests(): void {
    this.manService.loadRequests();
  }

  public async loadAbsenceTypes(): Promise<LoaType[]> {
    return this.apiService.getLoaTypes();
  }

  public async loadEmployees(): Promise<Lookup> {
    return this.lookupService.getLookup({ lookupType: LookupType.employeeList, orgLevelId: this.orgLevelId, isActive: true });
  }

  public async createLoaRequest(): Promise<LoaRequest> {
    return this.apiService.createLoaRequest(this.m_loaRequest);
  }

  public async updateLoaRequest(): Promise<LoaRequest> {
    return this.apiService.updateLoaRequest(this.m_loaRequest);
  }

  public async deleteLoaRequest(): Promise<void> {
    return this.apiService.deleteLoaRequest(this.m_loaRequest.id);
  }

  public async deleteAttachment(attachmentId: number): Promise<void> {
    await this.apiService.deleteAttachment(attachmentId, this.m_loaRequest.id);
    this.m_loaRequest.attachments = _.filter(this.m_loaRequest.attachments, (a) => a.id !== attachmentId);
  }

  public async attachSavedFiles(loa: LoaRequest): Promise<LoaRequest> {
    if (_.size(this.m_loaRequest.filesToSave) > 0) {
      return this.apiService.saveAddedFiles(loa.id, this.m_loaRequest.filesToSave);
    }
    return Promise.resolve(loa);
  }

  public getAbsenceDays(): IDateRange[] {
    return this.mapperService.buildAbsenceDays(this.m_loaRequest);
  }

  public changeLoading(isLoading: boolean): void {
    this.loading$.next(isLoading);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }

  public closePopup(): void {
    this.closePopup$.next();
  }

  public subscribeToClosePopup(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.closePopup$.subscribe(callback);
  }

  public async setEmployeeId(empId: number): Promise<void> {
    let lookup: Lookup = await this.loadEmployees();
    let employee = _.find(lookup.items, x => {
      return x.id == empId;
    });
    if (employee) {
      this.setEmployee(employee);
    }
  }

  public setEmployee(emp: Employee): void {
    if (_.isObjectLike(emp) && _.isFinite(emp.id)) {
      this.m_loaRequest = this.addEmployeeToRequest(emp);
      this.requestChange$.next(this.m_loaRequest);
    }
  }

  public subscribeToChangedRequest(callback: (e: LoaRequest) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.requestChange$.subscribe(callback);
  }

  public toggleEditMode(editModeOn?: boolean): void {
    this.editModeOn = _.isBoolean(editModeOn) ? editModeOn : !this.editModeOn;
  }

  public async readAddedFiles(files: File[]): Promise<ReadFile[]> {
    const promises = _.map(files, (f: File) => this.readFileData(f));
    let readedFiles: ReadFile[] = [];
    try {
      const binaryData = await Promise.all(promises);
      readedFiles = this.mapDateToReadFiles(files, binaryData);
    } catch (err) {
      console.error(err);
    }
    return readedFiles;
  }

  public addFilesToSave(files: ReadFile[]): void {
    if (_.isArray(files)) {
      this.m_loaRequest.filesToSave = [...this.m_loaRequest.filesToSave, ...files.concat()];
    }
  }

  public deleteFileToSave(file: ReadFile): void {
    if (_.isObjectLike(file)) {
      this.m_loaRequest.filesToSave = _.filter(this.m_loaRequest.filesToSave, (f) => f.fileName !== file.fileName);
    }
  }

  public getFilesToSave(): ReadFile[] {
    if (_.isArray(this.m_loaRequest.filesToSave)) {
      return this.m_loaRequest.filesToSave.concat();
    }
    return [];
  }

  public getStartOfDay(d: Date): Date {
    return new Date(new Date(d.getTime()).setHours(0, 0, 0, 0));
  }

  public getEndOfDay(d: Date): Date {
    return new Date(new Date(d.getTime()).setHours(23, 59, 0, 0));
  }

  public setRequestDate(date: Date): void {
    this.m_loaRequest.requestDate = date;
  }

  public getRequestDate(): Date {
    return this.m_loaRequest.requestDate;
  }

  public setEstimatedDates(sDate: Date, eDate: Date): void {
    let hasChanges = false;
    if (_.isDate(sDate)) {
      hasChanges = true;
      this.m_loaRequest.estStartDate = sDate;
    }
    if (_.isDate(eDate)) {
      hasChanges = true;
      this.m_loaRequest.estEndDate = eDate;
    }
    if (hasChanges) {
      this.evaluateAbsencePeriodFromRule();
      this.requestChange$.next(this.m_loaRequest);
    }
  }

  public setActualDates(sDate: Date, eDate: Date): void {
    if (_.isDate(sDate)) {
      this.m_loaRequest.actStartDate = sDate;
    }
    if (_.isDate(eDate)) {
      this.m_loaRequest.actEndDate = eDate;
    }
    this.requestChange$.next(this.m_loaRequest);
  }

  public getEstamatedDates(): IDateRange {
    return this.getEstOrActualDates(true);
  }

  public getActualDates(): IDateRange {
    return this.getEstOrActualDates(false);
  }

  public getLoaDates(): IDateRange {
    const estimated = this.getEstamatedDates();
    const actual = this.getActualDates();
    let sDate: Date = _.isDate(estimated.startDate) ? new Date(estimated.startDate.getTime()) : null;
    let eDate: Date = _.isDate(estimated.endDate) ? new Date(estimated.endDate.getTime()) : null;

    if (_.isDate(actual.startDate)) {
      sDate = new Date(actual.startDate.getTime());
    }
    if (_.isDate(actual.endDate)) {
      eDate = new Date(actual.endDate.getTime());
    }

    return new DateRange(sDate, eDate);
  }

  public setExceptions(exceptions: IDateRange[]): void {
    if (_.isArray(exceptions)) {
      this.m_loaRequest.exceptionDates = exceptions.concat();
    }
  }

  public getExceptions(): IDateRange[] {
    if (_.size(this.m_loaRequest.exceptionDates) > 0) {
      return this.m_loaRequest.exceptionDates.concat();
    }
    return [];
  }

  public setNotes(notes: string): void {
    if (_.isString(notes)) {
      this.m_loaRequest.notes = notes;
    }
  }

  public getNotes(): string {
    if (_.isString(this.m_loaRequest.notes)) {
      return this.m_loaRequest.notes;
    }
    return '';
  }

  public setAbsenceType(type: LoaType): void {
    this.m_loaRequest.type = type;
  }

  public getAbsenceType(): LoaType {
    if (_.isObjectLike(this.m_loaRequest.type)) {
      return this.m_loaRequest.type;
    }
    return null;
  }

  public setAbsenceReason(reason: string): void {
    this.m_loaRequest.reason = reason;
  }

  public getAbsenceReason(): string {
    if (_.isString(this.m_loaRequest.reason)) {
      return this.m_loaRequest.reason;
    }
    return '';
  }

  public setAttachments(attachments: LoaAttachment[]): void {
    if (_.isArray(attachments)) {
      this.m_loaRequest.attachments = attachments.concat();
    }
  }

  public getAttachments(): LoaAttachment[] {
    if (_.size(this.m_loaRequest.attachments) > 0) {
      return this.m_loaRequest.attachments.concat();
    }
    return [];
  }

  public setCustomDates(dates: IDateRange[]): void {
    const loaRepeat = _.get(this.m_loaRequest, 'loaRepeat');
    if (_.isObjectLike(loaRepeat)) {
      loaRepeat.customDates = _.map(dates, (r: IDateRange) => new DateRange(new Date(r.startDate.getTime()), new Date(r.endDate.getTime())));
      this.customDates = loaRepeat.customDates;
      this.requestChange$.next(this.m_loaRequest);
    }
    this.evaluateAbsencePeriodFromCustomDates();
  }

  public getCustomDates(): IDateRange[] {
     const loaRepeat = _.get(this.m_loaRequest, 'loaRepeat');
    if (_.isObjectLike(loaRepeat)) {
      return _.map(loaRepeat.customDates, (r: IDateRange) => new DateRange(new Date(r.startDate.getTime()), new Date(r.endDate.getTime())));
    }
    return [];
  }

  public setFrequency(freq: RecurrenceFrequencyDefinition): void {
    const reccurence = this.recurrenceService.createRRule(freq, this.weekDayIndex);
    this.m_loaRequest.loaRepeat = new LoaRepeat('', _.isString(reccurence) && reccurence || '');
    this.m_loaRequest.loaRepeat.customDates = this.customDates;
    this.evaluateAbsencePeriodFromRule();
  }

  public setRecurrence(freq: RecurrenceFrequencyDefinition, data: RecurrenceRuleOption): string {
    const recurrenceRule = this.recurrenceService.setRecurrence(freq, data);
    const loaRepeat = _.get(this.m_loaRequest, 'loaRepeat');
    if (_.isObjectLike(loaRepeat)) {
      loaRepeat.recurrenceRule = recurrenceRule;
    }
    this.evaluateAbsencePeriodFromRule();

    return recurrenceRule;
  }

  public getRecurrence(): RecurrenceRuleOption {
    return this.recurrenceService.getRecurrence();
  }

  public setEstAllDay(isChecked: boolean): void {
    this.m_loaRequest.estAllDayCheckbox = !!isChecked;
  }

  public getEstAllDay(): boolean {
    if (this.m_loaRequest && this.m_loaRequest.estStartDate && this.m_loaRequest.estEndDate) {
      if (moment(this.m_loaRequest.estStartDate).format("hh:mm:ss a") == '12:00:00 am'
          && moment(this.m_loaRequest.estEndDate).format("hh:mm:ss a") == '11:59:00 pm') {
        return this.m_loaRequest.estAllDayCheckbox = true;
      }
      else {
        return this.m_loaRequest.estAllDayCheckbox = false;
      }
    }
    else {
      return this.m_loaRequest.estAllDayCheckbox
    }
  }

  public setActAllDay(isChecked: boolean): void {

    this.m_loaRequest.actAllDayCheckbox = !!isChecked;
  }

  public getActAllDay(): boolean {
    if (this.m_loaRequest && this.m_loaRequest.actStartDate && this.m_loaRequest.actEndDate) {
      if (moment(this.m_loaRequest.actStartDate).format("hh:mm:ss a") == '12:00:00 am'
          && moment(this.m_loaRequest.actEndDate).format("hh:mm:ss a") == '11:59:00 pm') {
        return this.m_loaRequest.actAllDayCheckbox = true;
      }
      else {
        return this.m_loaRequest.actAllDayCheckbox = false;
      }
    }
    else {
      return this.m_loaRequest.actAllDayCheckbox;
    }

  }

  public getLoaRepeat(): LoaRepeat {
    if (_.isObjectLike(this.m_loaRequest.loaRepeat)) {
      return this.m_loaRequest.loaRepeat;
    }
    return new LoaRepeat();
  }

  public downloadAttachment(attachmentId: number): Promise<void> {
    try {
      let promise: Promise<void> = this.apiService.downloadAttachment(attachmentId)
        .then((file) => {
          return this.fileService.saveToFileSystem(file.blob, file.file);
        });
      return promise;
    } catch (e) {
      console.error(e);
    }
}

  private getEstOrActualDates(isEstDates: boolean): IDateRange {
    let sDate = _.get(this.m_loaRequest, isEstDates ? 'estStartDate' : 'actStartDate');
    let eDate = _.get(this.m_loaRequest, isEstDates ? 'estEndDate' : 'actEndDate');
    if (!_.isDate(sDate)) sDate = null;
    if (!_.isDate(eDate)) eDate = null;

    return new DateRange(sDate, eDate);
  }

  private mapDateToReadFiles(files: File[], binaryData: (string | ArrayBuffer)[]): ReadFile[] {
    return _.map(files, (file: File, i: number) => {
      const index = file.name.lastIndexOf('.');
      const name = file.name.slice(0, index);
      const ext = file.name.slice(index + 1);
      const data = new Blob([binaryData[i]]);
      return new ReadFile(name, file.size, file.type, ext, data);
    });
  }

  private readFileData(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve: (v: ArrayBuffer) => void, reject: (r: any) => void) => {
      const fr: FileReader = new FileReader();
      fr.onloadend = (): void => {
        const buffer = fr.result as ArrayBuffer;
        resolve(buffer);
      };
      fr.onerror = (): void => {
        reject(fr.error);
      };
      fr.readAsArrayBuffer(file);
    });
  }

  private addEmployeeToRequest(emp: Employee): LoaRequest {
    const loa = new LoaRequest();
    loa.employee = new LoaRequestEmployee(emp.id, emp.name);
    loa.organization = _.cloneDeep(emp.organization);
    loa.department = _.cloneDeep(emp.department);
    loa.position = _.cloneDeep(emp.position);
    loa.requestClass = this.m_loaRequest.requestClass;
    loa.requestDate = this.m_loaRequest.requestDate;
    return loa;
  }

  private evaluateAbsencePeriodFromRule(): void {
    const estStartDate = this.m_loaRequest.estStartDate;
    const rule = _.get(this.m_loaRequest.loaRepeat, 'recurrenceRule') || '';
    if (_.isDate(estStartDate) && _.size(rule) > 0) {
      const range = this.mapperService.evaluateClearAbsencePeriod(estStartDate, rule);
      if (_.isDate(range.startDate)) {
        this.m_loaRequest.absencePeriod = new DateRange(range.startDate, range.endDate);
      }
    }
  }

  private evaluateAbsencePeriodFromCustomDates(): void {
    const customDates = _.get(this.m_loaRequest.loaRepeat, 'customDates');
    if (_.size(customDates) > 0) {
      const sorted = _.sortBy(customDates, (r: IDateRange) => r.startDate.getTime());
      this.m_loaRequest.absencePeriod = new DateRange(_.head(sorted).startDate, _.last(sorted).endDate);
    }
  }

  private updateRequestData(): void {
    if (_.isFinite(this.m_loaRequest.empId)
      && _.isNil(this.m_loaRequest.employee.name)
      && _.isNil(this.m_loaRequest.position)
      && _.isNil(this.m_loaRequest.department)
      && _.isNil(this.m_loaRequest.organization)
    ) {
      this.setEmployeeId(this.m_loaRequest.empId);
    }
  }
}

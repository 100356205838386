
import {combineLatest} from 'rxjs/operators';
import {
  Component, OnDestroy, OnInit, ViewEncapsulation, Provider, ViewChild
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Observable ,  Subscription } from 'rxjs';

import { EmployeeListActions } from '../../store/index';
import { UserApplication } from '../../../../state-model/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { EmployeeListService } from '../../services/index';
import {
  EmployeeListItem,
  Employee,
  EmployeeActionDefinition,
  EmployeeFieldDefinition,
  EmployeeActionDefinitions
} from '../../models/index';
import { IEmployeeListState, IViewEmployeeListState, IOrgLevelEmployeeListState } from '../../store/employee-list/index';

import { GroupSmsComponent } from '../group-sms/group-sms.component';
import { AssignPayPolicyComponent } from '../assign-pay-policy/assign-pay-policy.component';
import { AssignAccrualsPolicyComponent } from '../assign-accruals-policy/assign-accruals-policy.component';
import { CreateESSPolicyComponent } from '../create-ess-policy/create-ess-policy.component';
import { AssignPayCyclesComponent } from '../assign-pay-cycles/assign-pay-cycles.component';
import { IRangeDates } from '../../../../common/models/range-dates';

import { employeeListConfig } from '../../employee-list.config';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../common/services/index';
import { ModalRef, IDialogComponent, DialogOptions2, DialogModeSize } from '../../../../common/models/index';
import { ConfirmOptions, ConfirmDialogComponent } from '../../../../common/index';
import { EmployeeSectionNavigationService } from '../../../../employee/employee-sections/services/index';
import { EmployeeGridComponent } from '../employee-grid/employee-grid.component';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN, FILTERED_EMPLOYEES_TOKEN } from '../../../../core/models/index';
import { IColumnSettings } from '../../../../core/models/index';
import { EmployeeListCommonService } from '../../services/employee-list/employee-list-common.service'
import { ResendOptSmsComponent } from '../resend-opt-sms/resend-opt-sms.component';
import { SendEmailInstructionsComponent } from '../send-email-instructions/send-email-instructions.component';
import { SlateProfileAssignmentComponent } from '../slate-profile-assignment/slate-profile-assignment.component';
import { MassRateAdjustmentsDialogComponent } from '../mass-rate-adjustments/mass-rate-adjustments.component';
import { NotificationsService } from '../../../../core/components';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-list',
  templateUrl: 'employee-list.component.html',
  styleUrls: ['employee-list.component.scss'],
  //encapsulation: ViewEncapsulation.None,
  providers: [StateManagementService, ColumnManagementService]

})
export class EmployeeListComponent implements OnInit, OnDestroy {
  @mutableSelect('application')
  public application: Observable<UserApplication>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['employeeList'])
  public employeeListState$: Observable<IEmployeeListState>;

  public state: {
    isLoading: boolean;
    isActiveOnly: boolean;
  };
  public isActiveOnly: boolean;
  public employeeListColumns: IColumnSettings[] = [];
  public employeeListItem: EmployeeListItem;
  public employeeListItems: EmployeeListItem[];
  public employeeListItems1: EmployeeListItem[];
  public actionDefinitions: EmployeeActionDefinitions = new EmployeeActionDefinitions();
  public selectedEmployees: Employee[];
  public employees: Employee[];
  public dateRange: IRangeDates;
  public columnsMap: StringMap<IColumnSettings>;
  public isColumnLocked: boolean = false;
  public get groupName(): string {
    return `EmployeeList_${_.isUndefined(this.employeeListItem) || _.isNull(this.employeeListItem) ? '0' : this.employeeListItem.id}`;
  }
  public get listDefinitionsIsEmpty(): boolean {
    return this.employeeListItems ? this.employeeListItems.length === 0 : true;
  }

  public get isCallLog(): boolean {
    return this.employeeListItem ? this.employeeListItem.listName === 'Call Log' : false;
  }

  public get isAgencyWorkerCleanUp(): boolean {
    return this.employeeListItem ? this.employeeListItem.listName === 'Agency Worker Cleanup' : false;
  }

  public get fileName(): string {
    return this.employeeListItem.listName + "_" + moment().format("MM/DD/YYYY");
  }

  private currentEmployees: Employee[];
  private selectedEmployeeIds: string[] = [];
  private currentOrgLevel: OrgLevel;

  private getEmployeeList: () => Promise<Employee[]>;
  @ViewChild('kendoDefault')
  private employeeGrid: EmployeeGridComponent;
  @unsubscribe()
  private employeesSubscription: Subscription;
  @unsubscribe()
  private employeesActionSubscription: Subscription;
  @unsubscribe()
  private columnsChangedSubscription: Subscription;

  private showFunctionsDict: any = {};

  constructor(private employeeListCommonService: EmployeeListCommonService, private employeeListService: EmployeeListService, private modalService: ModalService,
    private router: Router, private route: ActivatedRoute, private employeeListActions: EmployeeListActions, private stateManagement: StateManagementService,
    private readonly columnManagementService: ColumnManagementService, private readonly notificationsService: NotificationsService) {
    this.employeeListItems = [];
    this.state = {
      isLoading: false,
      isActiveOnly: true
    };
    this.isActiveOnly = this.state.isActiveOnly;
    this.showFunctionsDict = {
      'mass-assign-slate-profile': (actionDefinition: EmployeeActionDefinition) => { this.showDialog2(SlateProfileAssignmentComponent, actionDefinition); },
      'send-sms': (actionDefinition: EmployeeActionDefinition) => { this.showDialog2(GroupSmsComponent, actionDefinition); },
      'assign-pay-policy': (actionDefinition: EmployeeActionDefinition) => { this.showWindow(AssignPayPolicyComponent, actionDefinition); },
      'assign-accruals-policy': (actionDefinition: EmployeeActionDefinition) => { this.showWindow(AssignAccrualsPolicyComponent, actionDefinition); },
      'create-ess-policy': (actionDefinition: EmployeeActionDefinition) => { this.showWindow(CreateESSPolicyComponent, actionDefinition); },
      'create-pay-cycles': (actionDefinition: EmployeeActionDefinition) => { this.showWindow(AssignPayCyclesComponent, actionDefinition); },
      'resend-opt-sms': (actionDefinition: EmployeeActionDefinition) => { this.showDialog2(ResendOptSmsComponent, actionDefinition); },
      'send-email-instructions': (actionDefinition: EmployeeActionDefinition) => { this.showDialog2(SendEmailInstructionsComponent, actionDefinition); },
      'mass-rate-adjustments': (actionDefinition: EmployeeActionDefinition) => { this.showDialog2(MassRateAdjustmentsDialogComponent, actionDefinition); }
    };
    this.dateRange = { startDate: moment().endOf('day').add(-1, 'week').toDate(), endDate: moment().endOf('day').toDate() };
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.modalService.closeAllWindows();
  }

  public ngOnInit(): void {
    this.state.isLoading = true;
    this.stateManagement.init('EmployeeListComponent');
    this.employeesActionSubscription = this.orgLevel$.pipe(
      combineLatest(this.application))
      .subscribe((value: [OrgLevel, UserApplication]) => {
        let [orgLevel, application]: [OrgLevel, UserApplication] = value;
        if (!orgLevel || !orgLevel.id || !application.id) {
          return;
        }
        if (this.employeesSubscription) {
          this.employeesSubscription.unsubscribe();
          this.employeesSubscription = null;
        }
        this.employeeListService.getEmployeeListItems(application, orgLevel.id).then((employeeList: EmployeeListItem[]) => {
          this.employeeListItems1 = employeeList.filter(s=>s.listName !='Basic Information');
           this.employeeListItems = employeeList.filter(s=>s.listName ==='Basic Information').concat(this.employeeListItems1);
          if (this.employeeListItems.length === 0) {
            this.state.isLoading = false;
          } else {
            this.subscribeToState();
          }
        });
        this.employeeListService.getEmployeeActionDefinitions(application.id, orgLevel.id)
          .then((actionDefinitions: EmployeeActionDefinitions) => {
            this.actionDefinitions = actionDefinitions;
          });
      });
    this.columnsChangedSubscription = this.columnManagementService.columnsChanged$.pipe(combineLatest()).subscribe((eventArgs: any) => {

      if (_.filter(eventArgs[0].columns, (e): boolean => e.displayed == true).length > 0) { this.isColumnLocked = true; }
      else { this.isColumnLocked = false; }

      setTimeout(() => {
        this.employeeGrid.refreshColumns();
      }, 200);
    });
  }

  public subscribeToState(): void {
    if (this.employeesSubscription) {
      return;
    }
    this.employeesSubscription = this.orgLevel$.pipe(
      combineLatest(this.application, this.employeeListState$))
      .subscribe((value: [OrgLevel, UserApplication, IEmployeeListState]) => {
        let [orgLevel, application, employeeListState]: [OrgLevel, UserApplication, IEmployeeListState] = value;
        let lastIsActive: boolean = this.state.isActiveOnly;
        if (!orgLevel || !orgLevel.id || !application.id) {
          return;
        }

        this.state.isActiveOnly = this.isActiveOnly = employeeListState.isActive;

        let orgLevelState: IOrgLevelEmployeeListState = employeeListState.stateByOrglevel[orgLevel.id];
        // #step_1 save original value
        let viewName: number = employeeListState.viewName;
        let employeeListItem: EmployeeListItem;
        if (viewName) {
          // #step_2 try to find employeeListItem by viewName
          employeeListItem = _.find(this.employeeListItems, (item: EmployeeListItem) => { return item.id === viewName; });
        }

        if (!employeeListItem) {
          // #step_3 if employeeListItem did not find, select first list as default and set viewName from employeeListItem.id
          employeeListItem = this.employeeListItems[0];
          viewName = employeeListItem.id;
        }

        if (orgLevelState) {
          if (orgLevelState.stateByView) {
            // #step_4 if skip these 3 steps we will get incorrect viewState
            let viewState: IViewEmployeeListState = orgLevelState.stateByView[viewName];
            if (viewState) {
              this.selectedEmployeeIds = viewState.itemIds;
            } else {
              this.selectedEmployeeIds = [];
            }
          }
        }

        if (!employeeListItem || (this.employeeListItem && (this.employeeListItem.id === employeeListItem.id))) {
          if (this.currentOrgLevel && this.currentOrgLevel.id === orgLevel.id && lastIsActive === this.state.isActiveOnly) {
            return;
          }
        }

        this.changeEmployeesListStatusFieldVisibility(employeeListItem, employeeListState.isActive);
        this.currentOrgLevel = orgLevel;
        this.employeeListItem = employeeListItem;
        this.stateManagement.onComponentActiveStateChanged({ employeeListItem: this.employeeListItem.id, orgLevel: this.currentOrgLevel.id, isActiveOnly: this.state.isActiveOnly });
        this.columnManagementService.init('EmployeeListComponent');
        let employeeFilteredcolumns = _.filter(this.employeeListItem.fields, this.employeeListCommonService.isSpecColumn
        );

        this.columnManagementService.initGroup(this.groupName, _.filter(employeeFilteredcolumns,
          (field: EmployeeFieldDefinition): boolean => !field.hidden).length);
        this.loadEmployees();
      });
  }

  public onItemSelect(employeeListItem: EmployeeListItem): void {
    this.setViewState(employeeListItem, this.state.isActiveOnly);
  }

  public onDisplaySettingsChanged(): void {
    this.setViewState(this.employeeListItem, this.isActiveOnly);
  }

  public setViewState(employeeListItem: EmployeeListItem, isActiveOnly: boolean): void {
    this.employeeListActions.selectView(employeeListItem ? employeeListItem.id : null, isActiveOnly);
  }

  public onGroupActivityActionSelect(actionDefinition: EmployeeActionDefinition): void {
    if (this.showFunctionsDict[actionDefinition.type]) {
      this.showFunctionsDict[actionDefinition.type](actionDefinition);
    } else {
      this.modalService.globalAnchor.openInfoDialog('Warning', actionDefinition.displayName + ' are not implemented');
    }
  }

  public getWinProviders(actionDefinition: EmployeeActionDefinition): Provider[] {
    let resolvedProvidersConf: Provider[] = [
      { provide: EMPLOYEES_ID_TOKEN, useValue: this.currentEmployees },
      { provide: EmployeeActionDefinition, useValue: actionDefinition },
      { provide: OrgLevel, useValue: this.currentOrgLevel },
      { provide: FILTERED_EMPLOYEES_TOKEN, useValue: this.employeeGrid.getfilteredEmployeeList() },
      { provide: ISACTIVEONLY_ID_TOKEN, useValue: this.isActiveOnly }
    ];
    return resolvedProvidersConf;
  }

  public showWindow(winClass: any, actionDefinition: EmployeeActionDefinition): void {

    let ref: ModalRef<any> = this.modalService.globalAnchor.open(
      winClass,
      actionDefinition.displayName,
      this.getWinProviders(actionDefinition)
    );

    let win: any = ref.reference;

    let onCancelSubscription: Subscription;
    let onCompleteSubscription: Subscription;

    if (this.canActAsModal(win)) {
      const unsubscribe: () => void = () => {
        this.modalService.closeWindow(ref.uniqueId);
        if (onCancelSubscription) onCancelSubscription.unsubscribe();
        if (onCompleteSubscription) onCompleteSubscription.unsubscribe();
      };
      onCancelSubscription = win.onCancel.subscribe(() => unsubscribe());
      onCompleteSubscription = win.onComplete.subscribe(() => unsubscribe());
    }
  }

  public showDialog2(winClass: any, actionDefinition: EmployeeActionDefinition): void {
    winClass.openDialog(this.modalService, this.getWinProviders(actionDefinition), (result: boolean) => {
      if (result) {
        this.loadEmployees();
      }
    });
  }

  public employeeSelected(employees: Employee[]): void {
    if (!this.selectedEmployees && !employees) { return; }
    if ((this.selectedEmployees && this.selectedEmployees.length === 0) && (employees && employees.length === 0)) { return; }
    this.selectedEmployees = employees;
    let itemIds: string[] = _.map(this.selectedEmployees, (emp: Employee) => { return emp[employeeListConfig.employeeIdentifierName]; });
    this.employeeListActions.selectItems(this.employeeListItem ? this.employeeListItem.id : null, this.state.isActiveOnly, itemIds);
  }

  public employeesFiltered(employees: Employee[]): void {
    this.currentEmployees = employees;
  }

  public employeeOpen(employee: Employee): void {
    let empId: string = employee[employeeListConfig.employeeIdentifierName];
    let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
    navService.NavigateToEmployeeSections(+empId);
  }

  public onDatesRangeChanged($event: IRangeDates): void {
    this.dateRange.startDate = $event.startDate;
    this.dateRange.endDate = $event.endDate;
    this.loadEmployees();
  }

  private canActAsModal(component: any): component is IDialogComponent {
    return _.has(component, 'onCancel') && _.has(component, 'onComplete') && component.onCancel !== undefined && component.onComplete !== undefined;
  }

  private loadEmployees(): void {
    if (this.employeeListItem) {
      this.state.isLoading = true;

      this.employeeListService.getEmployees(this.employeeListItem.id, this.currentOrgLevel.id, this.state.isActiveOnly, this.employeeListItem, this.dateRange.startDate, this.dateRange.endDate)
        .then((employees: Employee[]) => {
          this.currentEmployees = this.employees = employees;
          this.employeeGrid.setEmployeesToSelect(this.selectedEmployeeIds);
          this.state.isLoading = false;
          this.stateManagement.loadedData({ employeeListItem: this.employeeListItem.id, orgLevel: this.currentOrgLevel.id, isActiveOnly: this.state.isActiveOnly });
          if (this.employeeGrid.gridState.state.sort.length == 0) {
            this.employeeGrid.sortByNameInAscending();
          }
        });

    } else {
      this.currentEmployees = this.employees = [];
    }
  }

  private changeEmployeesListStatusFieldVisibility(employeeListItem: EmployeeListItem, hidden: boolean): void {
    _.each(employeeListItem.fields, (field: EmployeeFieldDefinition) => {
      if (field.fieldName.toLowerCase() === 'status') {
        field.hidden = hidden;
      }

      if (field.fieldName.toLowerCase() === 'empterminationdate') {
        field.hidden = hidden;
      }
      
      if (field.fieldName.toLowerCase() === 'termdate') {
        field.hidden = hidden;
      }
    });
  }

  public onTerminateAgencyWorkers(){
    if(this.selectedEmployees && this.selectedEmployees.length > 0){
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      options.width = 350;
      options.height = 240;
      options.notes = 'Also note, if you are terminating a large number of Agency records it may take up to 30-60 seconds to complete.';
      ConfirmDialogComponent.openDialog(
        'Warning', `You are about to terminate the selected inactive agency records. Please select OK to continue or Cancel to return.`,
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.state.isLoading = true;
            this.employeeListService.terminateAgencyEmployees(this.selectedEmployees)
            .then(() => {
                this.loadEmployees();
                this.notificationsService.success('Success', 'Agency workers have been terminated successfully');
            })
            .catch(() => {
              this.notificationsService.error('Error', 'Failed to terminate agency workers. Please try again.');
            })
            .finally(() => {
              this.state.isLoading = false;
            });
          }
        },
        options
      );
    }
    else{
      this.modalService.globalAnchor.openInfoDialog('Warning', 'No Agency records were selected, please select the Agency records that you would like to terminate.');
    }
  }
}

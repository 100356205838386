<slx-spinner [show]="isLoading$ | async" class="response">
  <div class="slx-high-box">
    <div class="row slx-high-box__body slx-main-content-indents res">
      <div class="row data">
        <label class="attribute-heading">Employee Sync(Migration)</label>
        <slx-input-decorator class="org slx-no-border slx-no-error-block">
          <slx-dropdown-input
            slx-input
            [options]="organizationLookup"
            valueField="id"
            [(ngModel)]="selectedOrganization"
            (ngModelChange)="onOrganizationFilterChanged()"
            name="organization"
            placeholder="Organization"
            required
          >
          </slx-dropdown-input>
        </slx-input-decorator>
        <slx-input-decorator class="org slx-no-border slx-no-error-block">
          <slx-dropdown-input
            slx-input
            [options]="departmentLookup"
            valueField="id"
            [(ngModel)]="selectedDepartment"
            (ngModelChange)="onDepartmentFilterChanged($event)"
            placeholder="Department"
            name="department"
            required
          >
          </slx-dropdown-input>
        </slx-input-decorator>
        <slx-input-decorator class="org slx-no-border slx-no-error-block">
          <slx-dropdown-input
            slx-input
            [options]="positionLookup"
            valueField="id"
            [(ngModel)]="selectedPosition"
            (ngModelChange)="onPositionFilterChanged()"
            placeholder="Position"
            name="position"
            [disabled]="canSelectPosition"
            required
          >
          </slx-dropdown-input>
        </slx-input-decorator>
        <div class="display-toggle">
          <button
            type="submit"
            class="theme-button-apply btn-sync"
            [disabled]="isSyncDisabled$ | async"
            (click)="syncData()"
          >
            Sync Now
          </button>
          <button
            type="submit"
            class="theme-button-apply btn-syncrefresh"
            (click)="onRefreshClicked()">Refresh
          </button>
        </div>
      </div>
      <kendo-grid
        #grid="kendoGrid"
        [kendoGridBinding]="viewModel$ | async"
        [pageable]="true"
        filterable="menu"
        [filter]="filter$ | async"
        (filterChange)="onFilterChanged($event)"
        [sortable]="{ mode: 'multiple' }"
        [pageSize]="pageSize"
        class="grid slx-full-height slx-blue-grid"
      >
        <kendo-grid-checkbox-column [width]="35">
          <ng-template kendoGridHeaderTemplate>
            <input
              type="checkbox"
              [checked]="isCheckedAll$ | async"
              [disabled]="isCheckAllDisabled$ | async" (change)="toggleCheckAll($event)"
            />
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <input
              type="checkbox"
              [checked]="dataItem.ischecked === true"
              (change)="onCheckboxChange($event, dataItem)"
              [disabled]="dataItem.isDisabled"
            />
          </ng-template>
        </kendo-grid-checkbox-column>
        <kendo-grid-column field="payRollNumber" title="Employee ID" width="160"></kendo-grid-column>
        <kendo-grid-column field="employeeName" title="Employee Name" width="160"></kendo-grid-column>
        <kendo-grid-column field="empStatus" title="Employee Status" width="160"></kendo-grid-column>
       <kendo-grid-column title="Term Date" field="terminationDate" width="160" filter="date" [format]="dateFormat">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.terminationDate | date: 'MM/dd/yyyy' }}
      </ng-template>
    </kendo-grid-column>
        <kendo-grid-column field="action" title="Action" width="160"></kendo-grid-column>
        <kendo-grid-column title="Organization" field="orgName" width="130"> </kendo-grid-column>
        <kendo-grid-column title="Department" field="depName" width="130"> </kendo-grid-column>
        <kendo-grid-column title="Position" field="positionName" width="170"> </kendo-grid-column>
        <kendo-grid-column title="Company ID" field="companyId" width="170"> </kendo-grid-column>
        <kendo-grid-column title="Company Code" field="coCode" width="170"> </kendo-grid-column>
        <kendo-grid-column title="Paygroup" field="paygroup" width="170"></kendo-grid-column>
        <kendo-grid-column title="Status" field="status" width="170"> </kendo-grid-column>
        <kendo-grid-column title="Failed At" field="failedAt" width="170"> </kendo-grid-column>
        <kendo-grid-column title="Message" field="errorMessage" width="170"> </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <p>There is no data to display</p>
        </ng-template>
        <kendo-grid-messages filterMenuTitle="Filter">
        </kendo-grid-messages>  
      </kendo-grid>
    </div>
  </div>
</slx-spinner>



import { combineLatest, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';

import { MealDeductionSettings, ShiftModes } from '../../../models/index';
import { MealDeductionManagementService } from '../../../services/index';
import { mutableSelect, unsubscribe, destroyService } from '../../../../../core/decorators/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../../../organization/services/index';
import { UntypedFormGroup } from '@angular/forms';
import { DropDownItem } from '../../../../../common/models';

@Component({
  moduleId: module.id,
  selector: 'slx-meal-deduction-new-configuration',
  templateUrl: './meal-deduction-new-configuration.component.html',
  styleUrls: ['./meal-deduction-new-configuration.component.scss'],
  providers: [MealDeductionManagementService]
})
export class MealDeductionNewConfigurationComponent implements OnInit {
  public shiftModes: DropDownItem[];
  public shiftMode: DropDownItem;
  public shiftOneMin = 1;
  public shiftOneMax = 7;
  public shiftTwoMin = 9;
  public shiftTwoMax = 15;
  public step = 1;

  @Input()
  public hideDescription: boolean;

  @Output()
  public onSaved: EventEmitter<boolean> = new EventEmitter();
   @Output() 
   public isMealPropsChanged : EventEmitter<boolean>= new EventEmitter();

  public get isValid (): boolean {
      return this.isFormValid;
  }

  public set mealDeductionSettings (value: MealDeductionSettings) {
      this.m_mealDeductionSettings = value;
      this.shiftMode = _.first(this.shiftModes.filter(t => t.name == this.m_mealDeductionSettings.shift_Type));
      this.updateFormValidity ();
  }

  public get mealDeductionSettings ():MealDeductionSettings {
      return this.m_mealDeductionSettings;
  }

  public m_mealDeductionSettings: MealDeductionSettings;

  public state: {
      isLoading: boolean;
  };

  public maxTreshold: number = 1439;
  public minTreshold: number = 1;
  public currentOrgLevel: OrgLevel;

  @ViewChild('configurationForm', {static: true}) public form: UntypedFormGroup;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private savedSubscription: Subscription;
  @unsubscribe()
  private formSubscription: Subscription;

  private isFormValid: boolean;

  constructor(
      private managementService: MealDeductionManagementService,
      private orgLevelWatchService: OrgLevelWatchService
  ) {
      this.state = {
          isLoading: false,
      };

      this.shiftModes = _.map(ShiftModes, (n: string, i: number) => new DropDownItem(i + 1, n));
  }

  public ngOnInit(): void {

      this.formSubscription = this.form.valueChanges.subscribe(() => {
          this.updateFormValidity();
        });

      this.loadStatusSubscription = this.managementService.onLoadStatus$
          .subscribe((value: boolean) => {
              this.state.isLoading = value;
          });

      this.loadedSubscription = this.managementService.onLoaded$
          .subscribe((value: MealDeductionSettings) => {
              this.mealDeductionSettings = value;
          });

      this.savedSubscription = this.managementService.onSaved$
          .subscribe((result: boolean) => {
              this.onSaved.emit(result);
          });

      this.orgLevelSubscription = this.orgLevel$.pipe(
          combineLatest(this.orgLevelWatchService.orgLevelTreeLoaded$),
          map((value: [OrgLevel, boolean]) => value[0]))
          .subscribe((orgLevel: OrgLevel) => {

              if (orgLevel && orgLevel.id === null) return;

              if (!this.currentOrgLevel || this.currentOrgLevel.id !== orgLevel.id) {
                  this.currentOrgLevel = orgLevel;
                  this.managementService.onOrglevelChanged(this.currentOrgLevel);
              }
          });
  }

  public onChangeShiftMode(): void {
      this.mealDeductionSettings.shift_Type = this.shiftMode.name;
  }

  public mealDeductionModified(): void {
    this.isMealPropsChanged.emit(true);
  } 

  public get isMidShift(): boolean {
      return this.shiftMode.name === _.last(this.shiftModes).name;
  }

  public save(): void {
      this.managementService.saveConfiguration(this.mealDeductionSettings);
  }

  public saveByOrg(organizationId: number): void {
      this.mealDeductionSettings.organizationId = organizationId;
      this.managementService.saveConfiguration(this.mealDeductionSettings);
  }

  private updateFormValidity(): any {

      if (this.m_mealDeductionSettings) {
          this.isFormValid =
          !_.isNil(this.m_mealDeductionSettings.enableForAgency)
          && !_.isNil(this.m_mealDeductionSettings.enableForExemptS)
          && !_.isNil(this.m_mealDeductionSettings.enableForExemptT)
          && !_.isNil(this.m_mealDeductionSettings.enableForExemptX)
          && !_.isNil(this.m_mealDeductionSettings.enableForExemptY)
          && !_.isNil(this.m_mealDeductionSettings.enableForNonExempt)
          && !_.isNil(this.m_mealDeductionSettings.maxPunchTreshold)
          && (!this.isMidShift
          || this.m_mealDeductionSettings.maxPunchTreshold >= this.minTreshold
          && this.m_mealDeductionSettings.maxPunchTreshold <= this.maxTreshold
          && (!_.isNil(this.m_mealDeductionSettings.midShiftOne) && this.isMidShift)
          && (!_.isNil(this.m_mealDeductionSettings.midShiftTwo) && this.isMidShift)
          && (this.m_mealDeductionSettings.midShiftOne > 0 && this.m_mealDeductionSettings.midShiftOne < 9 && this.isMidShift)
          && (this.m_mealDeductionSettings.midShiftTwo > 8 && this.m_mealDeductionSettings.midShiftTwo < 16 && this.isMidShift));
          return;
      }

      this.isFormValid = false;
      this.isMealPropsChanged.emit(true);
  }

}

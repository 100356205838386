import * as _ from 'lodash';
import { Component, OnInit, OnChanges, OnDestroy, Input, Output, SimpleChanges, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { unsubscribeAll } from '../../../core/decorators/index';

import { ReadFile, AttachmentFile, UiAttachmentFile } from '../../../organization/models/index';

import { AttachmentsManagementService } from '../../services/index';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../common/index';


@Component({
  moduleId: module.id,
  selector: 'slx-attachments',
  templateUrl: 'attachments.component.html',
  styleUrls: ['attachments.component.scss'],
  providers: [AttachmentsManagementService]
})
export class AttachmentsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public canUpload: boolean = true;

  @Input()
  public canDownload: boolean = true;

  @Input()
  public canDelete: boolean = true;

  @Input()
  public set disabled(v: boolean) {
    this.m_disabled = !!v;
    if (this.m_disabled && _.isObjectLike(this.inputFile) && _.isObjectLike(this.inputFile.nativeElement)) {
      this.resetErrors(this.inputFile.nativeElement);
    }
  }

  public get disabled(): boolean {
    return this.m_disabled;
  }

  @Input()
  public showTitle: boolean = false;

  @Input()
  public maxFiles: number = 10;

  @Input()
  public maxFileSizeBytes: number = 5485760;
  
  @Input()
  showFileTypeAsTooltip: boolean=false; 
  
  @Input()
  public acceptedFileTypes: Array<string> = [
    '.csv', '.doc', '.docm', '.docx', '.txt', '.xls', '.xlsm', '.xlsx'
  ];

  @Input()
  public set attachments(v: AttachmentFile[]) {
    if (_.isArray(v)) {
      this.storedAttachments = v;
      this.processAttachments();
    }
  }

  @Input()
  public set filesToAttach(v: ReadFile[]) {
    if (_.isArray(v)) {
      this.storedFiles = v;
      this.processAttachments();
    }
  }

  @Output()
  public addedFiles = new EventEmitter<ReadFile[]>();

  @Output()
  public deletedFile = new EventEmitter<ReadFile>();

  @Output()
  public deletedAttachment = new EventEmitter<AttachmentFile>();

  @Output()
  public downloadAttachment = new EventEmitter<AttachmentFile>();

  @ViewChild('inputFile')
  public inputFile: ElementRef<HTMLInputElement>;
  public get hasFiles(): boolean {
    return _.size(this.files) > 0;
  }
  public get acceptedFileTypesInputFormat(): string {
    return this.attachmentService.acceptedFileTypesInputFormat;
  }
  public get acceptedFileTypesReadable(): string {
    return this.attachmentService.acceptedFileTypesReadable;
  }
  public get maxFileSize(): string {
    return this.attachmentService.maxFileSize;
  }
  public getTooltip(): string {
    return "(Allowed file types: " + this.acceptedFileTypesReadable + ", " + this.maxFileSize +" Max)";
  }

  public files: UiAttachmentFile[] = [];
  public errors: { maxFiles?: boolean, maxFileSize?: boolean, fileType?: boolean } = {};

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private storedAttachments: AttachmentFile[] = [];
  private storedFiles: ReadFile[] = [];
  private m_disabled: boolean = false;

  constructor(private modalService: ModalService,
              private attachmentService: AttachmentsManagementService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    this.attachmentService.init(this.maxFiles, this.maxFileSizeBytes, this.acceptedFileTypes);
  }

  public ngOnInit(): void { }

  public ngOnDestroy(): void { }

  public isDownloadable(file: UiAttachmentFile): boolean {
    return this.canDownload && file.isAttachment;
  }

  public async onFileChange(event: MouseEvent): Promise<void> {
    if (!this.disabled) {
      const fileList: FileList = _.get(event, 'target.files');
      const result = this.attachmentService.validateFiles(fileList, event);

      if (result.files.length > 0) {
        const readFiles = await this.attachmentService.readAddedFiles(result.files);
        const files = this.attachmentService.mapToFiles(readFiles);
        this.files = this.files.concat(files);
        this.addedFiles.emit(readFiles);
      }

      this.errors = result.errors;
    }
  }

  public onResetErrors(event: MouseEvent): void {
    this.resetErrors(event.target as HTMLInputElement);
  }

  public onDownloadFile(file: UiAttachmentFile): void {
    if (this.isDownloadable(file)) {
      this.downloadAttachmentFile(file);
    }
  }

  public onClickDelete(file: UiAttachmentFile): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Delete Attachment',
      'Are you sure you want to delete the attachment?',
      this.modalService,
      (isDelete: boolean) => {
        if (isDelete) {
          this.onDeleteAttachment(file);
        }
      },
      options);
  }

  public onDeleteAttachment(file: UiAttachmentFile): void {
    if (!this.disabled) {
      if (file.isAttachment) {
        this.deleteAttachment(file);
      } else {
        this.deleteFile(file);
      }
    }
  }

  private downloadAttachmentFile(file: UiAttachmentFile): void {
    this.downloadAttachment.emit(file.sourceItem as AttachmentFile);
  }

  private deleteAttachment(file: UiAttachmentFile): void {
    const files = this.files.concat();
    _.remove(files, (f: UiAttachmentFile) => _.isFinite(f.id) && f.id === file.id);
    this.files = files;
    this.deletedAttachment.emit(file.sourceItem as AttachmentFile);
  }

  private deleteFile(file: UiAttachmentFile): void {
    const files = this.files.concat();
    _.remove(files, (f: UiAttachmentFile) => !_.isFinite(f.id) && f.fileName === file.fileName);
    this.files = files;
    this.deletedFile.emit(file.sourceItem as ReadFile);
  }

  private processAttachments(): void {
    const attachments = this.attachmentService.mapToFiles(this.storedAttachments);
    const files = this.attachmentService.mapToFiles(this.storedFiles);
    this.files = attachments.concat(files);
  }

  private resetErrors(elem: HTMLInputElement): void {
    this.attachmentService.resetInput(elem);
    this.errors = {};
  }
}


import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ReplaySubject ,  Subscription ,  Observable ,  BehaviorSubject } from 'rxjs';
import { OrgLevel } from '../../../state-model/models/index';
import { appConfig } from '../../../app.config';

import { mutableSelect } from '../../../core/decorators/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { PollingPeriodService } from '../../../core/services/polling-period/polling-period.service';
import { IDestroyService } from '../../../core/models/index';

import {
  TimeclockApiService
} from './time-clock-api.service';
import {
  TimeclockDailySummaryContainer,
  RebootClockContainer,
  IRebootStatus} from '../../models/index';
import { TokenValidityService } from '../../../core/services/token-validity/token-validity.service';
import { NotificationsService } from '../../../core/components';
import { TaSignalrService } from '../../../../app/time-and-attendance/services/signalR/ta-signalr-service';

@Injectable()
export class TimeclockDataService implements IDestroyService {

  public onLoaded$: ReplaySubject<TimeclockDailySummaryContainer>;
  public onError$: ReplaySubject<any>;
  public clockStatus: boolean;
  // public rebootApiStatus: any;

  private rebootApiStatus$ = new BehaviorSubject(false);
  public rebootApiStatus = this.rebootApiStatus$.asObservable();

  @mutableSelect('orgLevel')
  private orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;
  private isLoading: boolean;
  

  private orgLevelSubscription: Subscription;
  private heartbeatSubscription: Subscription;
  private applicationHeartbeatService: PollingPeriodService;

  constructor(private timeclockApiService: TimeclockApiService,
     private userActivityService: UserActivityService, 
     private tokenValidity: TokenValidityService,
     private notificationService: NotificationsService,
     private taSignalrService:TaSignalrService) {
    this.applicationHeartbeatService = new PollingPeriodService(userActivityService, this.tokenValidity);
    this.onLoaded$ = new ReplaySubject(1);
    this.onError$ = new ReplaySubject(1);
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public init(): void {
    this.subscribeToOrgLevel();
    this.subscribeToHeartbeat();
  }

  public dispose(): void {
    this.unsubscribeOrgLevel();
    this.unsubscribeHeartbeat();
  }

  public loadTimeclocks(): void {
    if (this.orgLevel && _.isNumber(this.orgLevel.id)) {
      this.loadData(this.orgLevel.id, false);
    }
  }

  public rebootClock(orgLevelId: number, clockId: number): any {
    var rebootObj = new RebootClockContainer;
    rebootObj.RealpostId = clockId;
    rebootObj.Status = 'Pending';
    this.taSignalrService.isRebootInitiated$.next(true);
    this.timeclockApiService.rebootClock(orgLevelId, rebootObj).then((result: IRebootStatus) => {
      if(result){
        if (result.isSuccess) {
          this.rebootApiStatus$.next(true) ;
          this.taSignalrService.isRebootInitiated$.next(true);
          this.notificationService.success('Sucess', "Rebooting Initialized");
        }
        else if (!result.isSuccess) {
          this.rebootApiStatus$.next(false) ;
          this.notificationService.error('Request Error', "Unable to connect to clock, please reboot from the clock directly");
        }
      }
      return result;
    }).catch((err: any) => {
      this.notificationService.error('Request Failed', "Something went wrong!");
      this.rebootApiStatus$.next(false) ;
      return err;
    })
  }

  private loadData(orgLevelId: number, isPolling: boolean): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.timeclockApiService.getTimeclockDailySummaryContainer(orgLevelId, isPolling)
        .then((result: TimeclockDailySummaryContainer) => {
          this.onLoaded$.next(result);
          this.isLoading = false;
        }).catch((error: any) => {
          this.onError$.next(error);
          this.isLoading = false;
        });
    }
  }

  private subscribeToOrgLevel(): void {
    if (!this.orgLevelSubscription) {
      this.orgLevelSubscription = this.orgLevel$.pipe(
        filter((o: OrgLevel) => o && _.isNumber(o.id) && (!this.orgLevel || this.orgLevel.id !== o.id)))
        .subscribe((o: OrgLevel) => {
          this.orgLevel = o;
          this.loadData(this.orgLevel.id, false);
        });
    }
  }

  private subscribeToHeartbeat(): void {
    if (!this.heartbeatSubscription) {
      this.heartbeatSubscription = this.applicationHeartbeatService.onHeartbeat.subscribe(() => this.loadData(this.orgLevel.id, true));
      this.applicationHeartbeatService.listen(appConfig.notifyPoolingInterval);
    }
  }

  private unsubscribeOrgLevel(): void {
    if (this.orgLevelSubscription) {
      this.orgLevelSubscription.unsubscribe();
      this.orgLevelSubscription = null;
    }
  }

  private unsubscribeHeartbeat(): void {
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
      this.heartbeatSubscription = null;
    }

    this.applicationHeartbeatService.stop();
  }
}


<!-- Import Download Template Container Starts -->
<slx-spinner [show]="stateService.isLoading">
    <div *ngIf="importTemplateRequest.isDownloadTemplate && !isFileUploadMessageShown" class="import-template-container">
        <div class="positions-import-template-container">
            <kendo-grid [class.active]="importTemplateRequest.exceptionData.length > 0" class="slx-import-template-grid"
                [kendoGridBinding]="importTemplateRequest.positionList" [filterable]="'menu'">
                <kendo-grid-checkbox-column [width]="30">
                    <ng-template kendoGridHeaderTemplate>
                        <input type="checkbox" class="k-checkbox checkbox-import-template"
                            [checked]="isAllScheduleCheck"
                            (change)="selectAllIdealSchedule($event, importTemplateRequest.positionList)" />
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox" [value]="dataItem.position.isChecked === true"
                            [checked]="dataItem.position.isChecked === true"
                            (change)="onChangeIdealScheduleCheck($event, dataItem)" />
                    </ng-template>
                </kendo-grid-checkbox-column>
                <kendo-grid-column title="Position Name" field="position.name" filter="text" [width]="200">
                </kendo-grid-column>
                <kendo-grid-column [width]="150">
                    <ng-template kendoGridHeaderTemplate>
                        Configuration Type
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <slx-dropdown-list class="slx-wide slx-small-label"
                            [options]="filterConfigData(IdealScheduleConfigTypes)"
                            [isInvalidType]="isInvalidConfigType(dataItem)" [ngModel]="dataItem.idealScheduleType"
                            (ngModelChange)="onChangeIdealScheduleConfigType($event, dataItem)" valueField="id"
                            titleField="name"></slx-dropdown-list>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [width]="150">
                    <ng-template kendoGridHeaderTemplate>
                        Census Type
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <slx-dropdown-list class="slx-wide slx-small-label" [options]="IdealScheduleConfigCensusOptions"
                            [isInvalidType]="isInvalidCensusType(dataItem)" [ngModel]="dataItem.idealScheduleCensus"
                            (ngModelChange)="onChangeIdealScheduleCensusType($event, dataItem)" valueField="id"
                            titleField="name"></slx-dropdown-list>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-messages filterMenuTitle="Filter">
                </kendo-grid-messages>  
            </kendo-grid>
        </div>
        <div *ngIf="importTemplateRequest.exceptionData && importTemplateRequest.exceptionData.length > 0"
            class="positions-import-template-exception-container">
            <kendo-grid [class.active]="importTemplateRequest.exceptionData.length > 0" class="slx-import-template-grid"
                [kendoGridBinding]="importTemplateRequest.exceptionData" [filterable]="'menu'">
                <kendo-grid-column title="Position Name" field="positionName" filter="text"
                    [width]="150"></kendo-grid-column>
                <kendo-grid-column [width]="150">
                    <ng-template kendoGridHeaderTemplate>
                        Exception
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span style="color: red;">{{dataItem.errorMessage}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filterMenuTitle="Filter">
                </kendo-grid-messages>  
            </kendo-grid>
        </div>
    </div>
    <!-- Import Download Template Container Ends -->



    <!-- Import Selected Ideal Schedule Container Starts -->
    <div *ngIf="!importTemplateRequest.isDownloadTemplate && !isFileUploadMessageShown" class="import-template-container">
        <div class="slx-start-end-date-container">
            <slx-daterange class="slx-width-320" name="dateRange" startDatePlaceholder="From" endDatePlaceholder="To"
                [disableFutureDates]="false" [hasApplyButton]="false" [startDate]="startDate" [endDate]="endDate"
                (rangeDateChanged)="onDateRangeChanged($event)"></slx-daterange>
        </div>
        <div class="slx-upload-container">
            <div>
                <slx-file-upload [isDisabled]="isDatePopupTriggered" (validateChanges)="validateNewChanges($event)"
                    (cancelChanges)="discardUpload($event)"></slx-file-upload>
            </div>
            <div></div>
        </div>
        <div *ngIf="exceptionList.length > 0" class="slx-OrgLevel-Table">
            <kendo-grid class="slx-orgLevel-import-template-grid" [kendoGridBinding]="exceptionList"
                [filterable]="'menu'">
                <kendo-grid-column title="Error Code" field="errorCode" filter="text" [width]="75"></kendo-grid-column>
                <kendo-grid-column title="Error Message" field="errorMessage" [width]="300"
                    [filterable]="false"></kendo-grid-column>
            </kendo-grid>
        </div>
        <div *ngIf="apiExceptionList.length > 0" class="slx-OrgLevel-Table">
            <kendo-grid class="slx-orgLevel-import-template-grid" [kendoGridBinding]="apiExceptionList"
                [filterable]="'menu'">
                <kendo-grid-column title="Position Name" field="positionName" filter="text" [width]="150"></kendo-grid-column>
                <kendo-grid-column title="Error Message" field="errorMessage" [width]="300"
                    [filterable]="false"></kendo-grid-column>

                    <kendo-grid-messages filterMenuTitle="Filter">
                    </kendo-grid-messages>  
            </kendo-grid>
        </div>
    </div>
    <!-- Import Selected Ideal Schedule Container Ends -->



    <!-- Popup modal Discard and Submit Button Starts -->
    <div class="footer-btns-container">
        <button [disabled]="isDatePopupTriggered" *ngIf="!importTemplateRequest.isDownloadTemplate && !isFileUploadMessageShown" type="button"
            class="discard-btn" (click)="discard(importTemplateRequest.isDownloadTemplate)">
            Discard
        </button>
        <button [disabled]="isDatePopupTriggered" *ngIf="importTemplateRequest.isDownloadTemplate && !isFileUploadMessageShown" type="button"
            [ngClass]="saveDisabled ? 'disabled' : 'save-btn'" (click)="save(importTemplateRequest.isDownloadTemplate)">
            {{importTemplateRequest.buttonName}}
        </button>
        <button [disabled]="isDatePopupTriggered" *ngIf="!importTemplateRequest.isDownloadTemplate && !isFileUploadMessageShown" type="button"
            [ngClass]="!excelValidatorService.isFileExist ? 'disabled' : 'save-btn'" (click)="save(importTemplateRequest.isDownloadTemplate)">
            {{importTemplateRequest.buttonName}}
        </button>
        <button *ngIf="!importTemplateRequest.isDownloadTemplate && isFileUploadMessageShown" type="button"
            class="discard-btn" (click)="discard(importTemplateRequest.isDownloadTemplate)">
            Close
        </button>
    </div>
    <!-- Popup modal Discard and Submit Button Ends -->

    <div class="file-upload-process-information" *ngIf="isFileUploadMessageShown">
        <div>The import was successfully initiated, the status of the import can be viewed by visiting the File Upload page</div>
        <div><a href="" (click)="navigateToFileUploadPage()">Click Here to Navigate to the File Upload Page</a></div>
    </div>
</slx-spinner>
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';
import { Assert } from '../../../framework/index';
import {  unsubscribeAll } from '../../../core/decorators/index';
import { CompanyCounts, IPublishType, W2sInfo, W2sRecord } from '../../models/w2s/w2s-model';
import { W2sApiService } from './w2s-api.service';
import { FileService } from '../../../../app/common/services/file/file.service';

@Injectable()
export class W2sManagementService {

  private loading$: Subject<boolean>;
  private recordAlreadyPublished$: Subject<IPublishType>;
  private recordsLoaded$: Subject<W2sInfo>;
  private countsLoaded$: Subject<CompanyCounts>;
  private records:W2sInfo;  

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private apiService: W2sApiService,
    private fileService: FileService
  ) { 
    }

  public init(): void {    
    this.loading$ = new Subject<boolean>();
    this.recordAlreadyPublished$ = new Subject<IPublishType>();
    this.recordsLoaded$ = new Subject<W2sInfo>();
    this.countsLoaded$ = new Subject<CompanyCounts>();
  }

  public destroy(): void {   
    this.loading$.complete();
    this.recordsLoaded$.complete();    
    this.recordAlreadyPublished$.complete();
    this.countsLoaded$.complete();
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }

  public subscribeToLoadedRecords(callback: (b: W2sInfo) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.recordsLoaded$.subscribe(callback);
  }

  public subscribeToLoadedCounts(callback: (b: CompanyCounts) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.countsLoaded$.subscribe(callback);
  }

  public subscribeToRecordsAlreadyPublished(callback: (b: IPublishType) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.recordAlreadyPublished$.subscribe(callback);
  }


  public publishLoadedRecords(orgLevelId:number, year:number):void{
    this.loading$.next(true);
    let Ids:number[]=_.map(this.records.records,(item:W2sRecord)=>(item.w2Document.id));
    this.apiService.publishRecords(orgLevelId,year,Ids)
    .then((updateCount: any) => {
      if(updateCount==0)
      {
        this.recordAlreadyPublished$.next({"publish": true, "unPublish": false});  
        this.loading$.next(false);    
      }
      else
      {
        
        this.loadW2s(orgLevelId, year);
      }
        
        
      })
      .catch (() => {
        this.loading$.next(false);
      });
  }
  public loadW2s(orgLevelId:number, year:number): void {   
  
    this.loading$.next(true);
    this.apiService.getW2sRecords(orgLevelId,year)
      .then((w2sRecords: W2sInfo) => {
        this.records=w2sRecords;
        this.recordsLoaded$.next(w2sRecords);       
        this.loading$.next(false);
      })
      .catch (() => {
        this.loading$.next(false);
      });
  }

  public async downloadW2sDocument(exportId: number,fileName:string): Promise<void> {
    this.loading$.next(true);
    try {
      const file = await this.apiService.downloadW2Document(exportId);
      this.fileService.saveToFileSystem(file.blob, fileName);
    } catch (e) {
      this.loading$.next(false);
    } finally {
      this.loading$.next(false);
    }
  }

  public unPublishLoadedRecords(orgLevelId:number, year:number):void{
    this.loading$.next(true);
    let Ids:number[]=_.map(this.records.records,(item:W2sRecord)=>(item.w2Document.id));
    this.apiService.unPublishRecords(orgLevelId,year,Ids)
    .then((updateCount: any) => {
      if(updateCount==0)
      {
        this.recordAlreadyPublished$.next({"publish": false, "unPublish": true});  
        this.loading$.next(false);    
      }
      else
      {
        
        this.loadW2s(orgLevelId, year);
      }
        
        
      })
      .catch (() => {
        this.loading$.next(false);
      });

    }
  
  public getCompanyCounts(orgLevelId:number, year:number): void{
    this.loading$.next(true);
    this.apiService.getCompanyCounts(orgLevelId,year)
    .then((companyCounts: CompanyCounts) => {
      this.countsLoaded$.next(companyCounts);
      this.loading$.next(false);
    })
    .catch (() => {
      this.loading$.next(false);
    });
  }
}

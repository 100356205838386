import { Component, OnInit, OnDestroy, Input, Output, Host, ViewChild, NgZone, Provider } from '@angular/core';
import * as moment from 'moment';
import { Subscription ,  Observable } from 'rxjs';
import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';

import { Assert } from '../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { PostedSchedule } from '../../models/index';
import { ScheduleApiService } from '../../services/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { RangeDates, IRangeDates } from '../../../common/models/range-dates';

@Component({
  moduleId: module.id,
  selector: 'slx-posted-schedules',
  templateUrl: 'posted-schedules.component.html',
  styleUrls: ['posted-schedules.component.scss']
})
export class PostedSchedulesComponent implements OnInit, OnDestroy {

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  public startDate: Date;
  public endDate: Date;
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<PostedSchedule>;
  public state: {
    isLoading: boolean;
  };

  private scheduleApiService: ScheduleApiService;
  private gridEventSubscription: Subscription;
  private gridSelectSubscription: Subscription;
  private postedSchedules: PostedSchedule[];
  private currentOrgLevel: OrgLevel;

  @unsubscribe()
  private orglevelSubscription: Subscription;
  private pageSize: number = 50;

  constructor(scheduleApiService: ScheduleApiService) {
    Assert.isNotNull(scheduleApiService, 'scheduleApiService');
    this.scheduleApiService = scheduleApiService;
    this.gridState = new KendoGridStateHelper<PostedSchedule>();
    this.gridState.state.sort = [{ field: 'scheduleCycleStartDate', dir: 'asc' }];
    this.gridEventSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
    this.appConfig = appConfig;
    this.state = {
      isLoading: false
    };
    this.startDate = moment().startOf('year').toDate();
    this.endDate = moment().endOf('year').toDate();
  }

  public ngOnInit(): void {
    this.state.isLoading = true;
    this.orglevelSubscription = this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        this.currentOrgLevel = orgLevel;
        this.doLoad();
      });
  }

  protected doLoad(): void {
    this.state.isLoading = true;
    this.scheduleApiService.getPostedSchedules(this.currentOrgLevel.id, this.startDate, this.endDate)
      .then((postedSchedules: PostedSchedule[]) => {
        this.postedSchedules = postedSchedules;
        this.state.isLoading = false;
        this.refreshGrid();
      })
      .catch(() => {
        this.state.isLoading = false;
      });
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.doLoad();
  }

  public ngOnDestroy(): void {
    if (this.gridEventSubscription) {
      this.gridEventSubscription.unsubscribe();
    }
  }

  private refreshGrid(): void {
    if (!this.postedSchedules) {
      this.gridState.view = null;
      return;
    }
    let sortedRecords: PostedSchedule[] = orderBy(this.postedSchedules, this.gridState.state.sort);
    let groupedRecords: PostedSchedule[] | GroupResult[] = groupBy(sortedRecords, this.gridState.state.group);
    let pagedRecords: PostedSchedule[] = sortedRecords.slice(this.gridState.state.skip, this.gridState.state.skip + this.pageSize);
    this.gridState.view = {
      data: groupedRecords,
      total: groupedRecords.length
    };
  }
}


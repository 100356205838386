<slx-spinner [show]="state.isLoading">
<kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"   [data]="gridState.view" [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
   (sortChange)="gridState.sortChange($event)" 
   [filterable]="'menu'"
  [filter]="gridState.state.filter"
   (filterChange)="filterChangeHandler($event)"
  (dataStateChange)="gridState.dataStateChange($event)" selectable="true"
  (selectionChange)="gridState.selectionChange($event)" [pageable]="true" [pageSize]="pageSize" (stateRestored)="gridState.dataStateChange($event)"
  [skip]="gridState.state.skip" (pageChange)="pageChangeHandler($event)">
  <ng-template kendoGridToolbarTemplate position="top">
    <form novalidate>
      <div>
        <slx-date-range-ngx
        name="startEndDate"[startDate]="startDate"
        [endDate]="endDate"
        (rangeDateChanged)="onFilterDateChanged($event)"
      ></slx-date-range-ngx>
      <div class="slx-lr-toolbar__filters actionsAlign">
        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent"
                             [popperPosition]="'bottom-end'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (click)="onClickExportExcel('excel')">Export to Excel</slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </div>
      </div>
    </form>    
  </ng-template>
  
  <kendo-grid-column field="organizationName" filter="text">
    <ng-template kendoGridHeaderTemplate>
      OrgLevel
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.organization?.name }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="departmentName" filter="text">
    <ng-template kendoGridHeaderTemplate>
      Department
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.department?.name }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="scheduleCycleStartDate" filter="date" [format]="dateFormat">
    <ng-template kendoGridHeaderTemplate>
      Schedule Cycle Start Date
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a *ngIf="orgTypeDepartment" (click)="navigateToOpenShiftManagement(dataItem.scheduleCycle?.startDate)"> {{ dataItem.scheduleCycle?.startDate | amDateFormat: appConfig.dateFormat }}</a>
      <span *ngIf="!orgTypeDepartment">{{ dataItem.scheduleCycle?.startDate | amDateFormat: appConfig.dateFormat }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="scheduleCycleEndDate" filter="date" [format]="dateFormat">
    <ng-template kendoGridHeaderTemplate>
      Schedule Cycle End Date
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.scheduleCycle?.endDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="schedulecyclestart" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      Days Until Schedule Cycle Starts
    </ng-template>
    <ng-template kendoGridCellTemplate  let-dataItem>
      <span [class.negativeDayCount]="dataItem.schedulecyclestart<0">{{dataItem.schedulecyclestart}} days</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="countOfEmployees" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      Count Of Employees
    </ng-template>
    <ng-template kendoGridCellTemplate  let-dataItem>
      {{dataItem.countOfEmployees}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="countOfshifts" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      Count Of Shifts
    </ng-template>
    <ng-template kendoGridCellTemplate  let-dataItem>
      {{dataItem.countOfshifts}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="SchedulesNotPosted.xlsx" [fetchData]="retriveAllPages()">
    <kendo-excelexport-column-group [title]="getDateRange()" [headerCellOptions]="{ textAlign:'left',bold: true  }"
        [headerCellOptions]="{ textAlign: 'center' }">
        <kendo-excelexport-column title="OrgLevel" field="organizationName" [width]="170" [headerCellOptions]="{ bold: true }">
        </kendo-excelexport-column>
        <kendo-excelexport-column title="Department" field="departmentName" [width]="170" [headerCellOptions]="{ bold: true }">
        </kendo-excelexport-column>
        <kendo-excelexport-column title="Schedule Cycle Start Date" field="scheduleCycleStartDate" [width]="170" [cellOptions]="{ textAlign: 'center' }" [headerCellOptions]="{ bold: true }">
        </kendo-excelexport-column>
        <kendo-excelexport-column title="Schedule Cycle End Date" field="scheduleCycleEndDate" [width]="170" [cellOptions]="{ textAlign: 'center' }" [headerCellOptions]="{ bold: true }">
        </kendo-excelexport-column>
        <kendo-excelexport-column title="Days Until Schedule Cycle Starts" field="schedulecyclestartDay" [width]="200" [cellOptions]="{ textAlign: 'center' }" [headerCellOptions]="{ bold: true }">
        </kendo-excelexport-column>
        <kendo-excelexport-column title="Count Of Employees" field="countOfEmployees" [width]="130" [cellOptions]="{ textAlign: 'center' }" [headerCellOptions]="{ bold: true }">
        </kendo-excelexport-column>
        <kendo-excelexport-column title="Count Of shifts" field="countOfshifts" [width]="130" [cellOptions]="{ textAlign: 'center' }" [headerCellOptions]="{ bold: true }">
        </kendo-excelexport-column>
    </kendo-excelexport-column-group>
  </kendo-grid-excel>
  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>
</slx-spinner>

import { Component, OnInit, OnDestroy, Input, Output, Host, ViewChild, NgZone, Provider, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { NgForm, AbstractControl } from '@angular/forms';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import {
  EmployeeSectionsAvailability, IAvailabilityIndexRecord,
  IEmployeeSectionsAvailability, IEmployeeAvailabilityDay,
  EmployeeWeeklyAvailability
} from '../../../models/employee-sections-schedule/employee-sections-availability';
import { EmployeeSectionsBase, EmployeeAvailabilityHour } from '../../../models/index';
import { DayOfWeek } from '../../../../../common/models/index';
import { EmployeeSectionsScheduleApiService, WeekDayService } from '../../../services/index';

import { LookupService } from '../../../../../organization/services/index';
import { Lookup, LookupType, ILookupRequest } from '../../../../../organization/models/index';
import { AvailabilityDateRange } from '../../../../../organization/models/index';

import { Assert } from '../../../../../framework/assert/assert';
import { PopoverContentComponent } from '../../../../../common/index';
import { RangeDates, IRangeDates } from '../../../../../common/models/range-dates';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-availability',
  templateUrl: 'employee-sections-availability.component.html',
  styleUrls: ['employee-sections-availability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EmployeeSectionsAvailabilityComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {
  @Input()
  public set startDate(value: Date) {
    this.m_startDate = value;
    if (this.availabilityDateRange) {
      this.availabilityDateRange.startDate = value;
    }
  }
  public get startDate(): Date {
    return this.m_startDate;
  }

  @Input()
  public set endDate(value: Date) {
    this.m_endDate = value;
    if (this.availabilityDateRange) {
      this.availabilityDateRange.endDate = value;
    }
  }
  public get endDate(): Date {
    return this.m_endDate;
  }

  @Input()
  public employeeId: number;

  @ViewChild('form')
  public ngForm: NgForm;

  public configHours: any[] = [
    { count: 1 },
    { count: 2 },
    { count: 3 },
    { count: 4 },
    { count: 5 },
    { count: 6 },
    { count: 7 },
    { count: 8 },
    { count: 9 },
    { count: 10 },
    { count: 11 },
    { count: 12 }
  ];
  public selectedConfigHour: any;
  //TODO: Move into appropriate service or config
  public hours: number[] = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  public availabilityLookup: Lookup;

  public get weeklyAvailability(): EmployeeWeeklyAvailability {
    return this.m_weeklyAvailability;
  }

  public set weeklyAvailability(value: EmployeeWeeklyAvailability) {
    this.m_weeklyAvailability = value;
  }

  public get availabilityDateRange(): AvailabilityDateRange {
    return this.m_availabilityDateRange;
  }

  public set availabilityDateRange(value: AvailabilityDateRange) {
    if (this.isAnyAvailabilityRecord) {
      this.m_availabilityDateRange = value;
      if (value) {
        this.startDate = value.startDate;
        this.endDate = value.endDate;
      }
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }

  public get isAnyAvailabilityRecord(): boolean {
    return this.availabilityLookup && this.availabilityLookup.items && this.availabilityLookup.items.length > 0;
  }

  public availabilitySection: EmployeeSectionsAvailability;

  private m_startDate: Date = moment().startOf('year').toDate();
  private m_endDate: Date = moment().endOf('year').toDate();
  private changeDetector: ChangeDetectorRef;
  private m_availabilityDateRange: AvailabilityDateRange;
  private m_weeklyAvailability: EmployeeWeeklyAvailability;

  @Input()
  public set employeeSubsectionAvailability(value: EmployeeSectionsAvailability) {

    if (value !== null && value !== undefined) {
      this.availabilitySection = value;

      if (value.availabilityDateRange) {
        this.availabilityDateRange = value.availabilityDateRange;
      }

      if (value.weeklyAvailability) {
        this.weeklyAvailability = value.weeklyAvailability;
      }
    }
  }

  public get employeeSubsectionAvailability(): EmployeeSectionsAvailability {
    return this.availabilitySection;
  }

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  private employeeSectionsScheduleApiService: EmployeeSectionsScheduleApiService;

  constructor(
    private weekDayService: WeekDayService,
    private lookupService: LookupService,
    employeeSectionsScheduleApiService: EmployeeSectionsScheduleApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone, changeDetector: ChangeDetectorRef) {
    super(decorator, ngZone);
    this.changeDetector = changeDetector;
    Assert.isNotNull(employeeSectionsScheduleApiService, 'employeeSectionsScheduleApiService');
    this.employeeSectionsScheduleApiService = employeeSectionsScheduleApiService;
    this.selectedConfigHour = this.configHours[7];
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSubsectionAvailability;
  }

  public onHourClick(hour: EmployeeAvailabilityHour): void {
    Assert.isNotNull(this.weeklyAvailability, 'weeklyAvailability');
    if (hour.isAvailable) {
      hour.isAvailable = false;
      return;
    }
    let weeklyHours: EmployeeAvailabilityHour[] = this.weeklyAvailability.hours;
    let foundWeeklyHourIndex: number = _.findIndex(weeklyHours, weeklyHour => weeklyHour.day === hour.day && weeklyHour.hour === hour.hour);
    if (foundWeeklyHourIndex >= 0) {
      for (let i: number = 0; i < this.selectedConfigHour.count; i++) {
        let weeklyHourIndex: number = foundWeeklyHourIndex + i;
        weeklyHourIndex = weeklyHourIndex >= weeklyHours.length ? weeklyHourIndex % weeklyHours.length : weeklyHourIndex;
        if (!weeklyHours[weeklyHourIndex].isAvailable) {
          weeklyHours[weeklyHourIndex].isAvailable = true;
        }
      }
    }
  }

  public onAcceptClearAvailability(acceptPopover: PopoverContentComponent): void {
    acceptPopover.hide();
    this.clearAvailabilityRecords();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.loadAvailabilityRanges(false);
  }

  public onSelectedRecordChanged(evt: any): void {
    this.loadSubsection();
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  protected loadSubsection(): void {
    this.startProgress();

    this.employeeSectionsScheduleApiService.getAvailability(this.employeeId, this.startDate, this.endDate)
      .then((availability: EmployeeSectionsAvailability) => {
        this.employeeSubsectionAvailability = availability;
        this.stopProgress();
        if (this.isDestroyed) {
          return;
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected doSave(effectiveDate: Date): void {
    this.startProgress();

    this.employeeSectionsScheduleApiService.setAvailability(this.employeeId, this.startDate, this.endDate, this.employeeSubsectionAvailability)
      .then((response: any) => {
        this.stopProgress();
        this.loadAvailabilityRanges(true);
        if (this.isDestroyed) {
          return;
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }
  private loadAvailabilityRanges(reloadSubsection: boolean): void {
    
    this.startProgress();
    this.lookupService.getLookup({ lookupType: LookupType.availabilityDefinition, employeeId: this.employeeId, orgLevelId: undefined, updateCacheForced: true })
      .then((availabilityLookup: Lookup) => {

        if (availabilityLookup && availabilityLookup.items && availabilityLookup.items.length === 0) {
          let availabilityRecordEmpty: any = { name: 'No Records Available' };
          this.m_availabilityDateRange = availabilityRecordEmpty;
        }

        this.availabilityLookup = availabilityLookup;
        if (reloadSubsection) {
          this.loadSubsection();
        } else {
          this.stopProgress();
        }
        if (this.isDestroyed) {
          return;
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });
  }

  private clearAvailabilityRecords(): void {
    let weeklyHours: EmployeeAvailabilityHour[] = this.weeklyAvailability.hours;
    _.forEach(weeklyHours, (weeklyHour: EmployeeAvailabilityHour): void => {
      weeklyHour.isAvailable = false;
    });
  }
}


import {debounceTime} from 'rxjs/operators';
import {
  Directive, Input, Output, ElementRef, OnDestroy, OnChanges, SimpleChanges,
  HostListener, OnInit, Host, AfterViewInit, Optional, EventEmitter, ChangeDetectorRef, Renderer2
} from '@angular/core';
import * as _ from 'lodash';

import { Subscription ,  Subject } from 'rxjs';


import { GridComponent, ColumnComponent, PageChangeEvent, DataStateChangeEvent, GridDataResult, SelectionEvent, DetailExpandEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IGridState, ControlStateKey, ISortState } from '../../../../core/models/index';
import { ScrollWatchEvent } from '../../../models/index';
import { ComponentStateStorageService, StateManagementService, FilterStateManagementService } from '../../../services/index';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';

@Directive({
  selector: '[slxKendoGridState]',
  providers: [FilterStateManagementService]
})
export class KendoGridStateDirective implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input('slxKendoGridState')
  public gridId: string;
  @Input()
  public keyId: string;
  @Input()
  public filtersManagement: boolean;
  @Input()
  public slxKendoGridStatePageSize = 50;
  @Output()
  public stateRestored: EventEmitter<DataStateChangeEvent>;

  private onScroll$: Subject<ScrollWatchEvent>;
  private state: IGridState;
  private stateKey: ControlStateKey;
  private scrollElement: Element;
  private scrollInitialized: boolean;
  private expandedRows: StringMap<number>;

  @destroyService()
  private filterState: FilterStateManagementService;

  @unsubscribe()
  private scrollSubscription: Subscription;
  @unsubscribe()
  private contextChangeSubscription: Subscription;
  @unsubscribe()
  private expandChangeSubscription: Subscription;
  @unsubscribe()
  private dataStaeChangeSubscription: Subscription;
  @unsubscribe()
  private filterStateChangeSubscription: Subscription;

  private scrollistenerFn: () => void;

  constructor( @Host() private grid: GridComponent, private changeDetector: ChangeDetectorRef,
    private storageService: ComponentStateStorageService, @Optional()
    private stateManagement: StateManagementService,
    filterState: FilterStateManagementService, private renderer: Renderer2
  ) {
    this.filterState = filterState;
    this.onScroll$ = new Subject();
    this.stateRestored = new EventEmitter<DataStateChangeEvent>();
    this.expandedRows = {};
  }

  public ngOnInit(): void {
    if (!this.stateManagement || !this.stateManagement.isInitialized) {
      return;
    }
    this.stateManagement.registerGrid(this.gridId);
    this.contextChangeSubscription = this.stateManagement.loadedData$.subscribe((key: StringMap<any>) => {
      this.switchState(key);
    });
    this.scrollSubscription = this.onScroll$.pipe(
      debounceTime(500))
      .subscribe((event: ScrollWatchEvent) => {
        this.saveScrollState(event.scrollTop, event.scrollLeft);
      });

    this.filterStateChangeSubscription = this.filterState.filterStateSaved$.subscribe((event: { gridId: string, state: IGridState }) => {
      if (this.gridId !== event.gridId) {
        return;
      }
      this.state = event.state;
    });
  }

  public ngAfterViewInit(): void {
    this.dataStaeChangeSubscription = this.grid.dataStateChange.asObservable().subscribe((value: DataStateChangeEvent) => {
      this.saveState(value);
    });

    this.expandChangeSubscription = this.grid.detailExpand.asObservable().subscribe((event: { index: number, dataItem: any }) => {
      let key = this.getRowKey(event.index, event.dataItem);
      if (key) {
        this.expandedRows[key] = event.index;
      }
      this.saveExpandedRowsState();
    });

    this.grid.detailCollapse.asObservable().subscribe((event: { index: number, dataItem: any }) => {
      let key = this.getRowKey(event.index, event.dataItem);
      if (key) {
        this.expandedRows[key] = undefined;
      }
      this.saveExpandedRowsState();
    });

    let el: HTMLElement = this.grid.wrapper.nativeElement;
    this.scrollElement = el.querySelector('.k-grid-content.k-virtual-content');
    if (this.scrollElement) {
      this.scrollistenerFn = this.renderer.listen(this.scrollElement, 'scroll', (event: MouseEvent) => this.scrollHandler(event));
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['gridId'] || changes['filtersManagement']) {
      if (!this.gridId) {
        return;
      }
      this.filterState.enabled = this.filtersManagement;
      this.filterState.setGridId(this.gridId);
    }
  }

  // Must be, see #issueWithAOTCompiler
  public ngOnDestroy(): void {
    if (this.stateManagement) {
      this.stateManagement.unregisterGrid(this.gridId);
    }
    if (this.scrollElement && this.scrollistenerFn) {
      this.scrollistenerFn();
    }
  }

  public setScrollPosition(top: number, left: number): void {
    if (!this.scrollElement) {
      return;
    }

    this.scrollElement.scrollTop = top ? top : 0;
    this.scrollElement.scrollLeft = left ? left : 0;
  }

  public setState(state: IGridState): void {
    let ev: DataStateChangeEvent = {
      skip: state.skip ? state.skip : 0,
      take: state.take ? state.take : this.slxKendoGridStatePageSize,
      sort: _.map(state.sort, (st: ISortState) => {
        return { field: st.field, dir: st.dir };
      }),
      filter: this.filtersManagement ? null : this.grid.filter
    };
    if (this.grid && !this.grid.pageable) {
      ev.take = Infinity;
    }
    this.stateRestored.emit(ev);
  }

  public setExpandedRows(ids: string[]): void {
    this.expandedRows = {};
    _.forEach(ids, (id: string) => {
      let index: number = null;
      if (!this.keyId) {
        index = _.toInteger(id);
      } else {
        index = this.findDataItemIndex(this.keyId, id);
      }
      if (_.isNumber(index)) {
        this.expandedRows[id] = index;
        this.grid.expandRow(index);
        let dataItem = this.getDataItem(index);
        if (dataItem) {
          this.grid.detailExpand.emit({ index: index, dataItem: this.getDataItem(index) } as DetailExpandEvent);
        }
      }
    });
  }

  private scrollHandler(event: MouseEvent): void {
    let ev: ScrollWatchEvent = new ScrollWatchEvent(event);
    ev.scrollTop = this.scrollElement.scrollTop;
    ev.scrollLeft = this.scrollElement.scrollLeft;
    ev.scrollEvent = event;
    ev.clientHeight = this.scrollElement.clientHeight;
    ev.clientWidth = this.scrollElement.clientWidth;
    this.onScroll$.next(ev);
  }

  private loadState(): void {
    this.state = this.storageService.getGridState(this.stateManagement.componentKey, this.gridId, this.stateKey);
    this.setState(this.state);

    //let chance to render grid
    setTimeout(() => {
      this.setExpandedRows(this.state.expandedDetailsIds);
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }, 150);
    setTimeout(() => {
      this.setScrollPosition(this.state.scrollTop, this.state.scrollLeft);
    }, 250);
  }

  private saveScrollState(top: number, left: number): void {
    if (!this.stateManagement || !this.state) return;
    this.state.scrollTop = top;
    this.state.scrollLeft = left;
    this.storageService.setGridState(this.stateManagement.componentKey, this.gridId, this.state, this.stateKey);
  }

  private saveState(ev: DataStateChangeEvent): void {
    if (!this.stateManagement || !this.state) {
      return;
    }
    this.state.skip = ev.skip;
    this.state.take = ev.take;
    this.state.sort = _.map(ev.sort, (st: ISortState) => {
      return { field: st.field, dir: st.dir };
    });
    this.storageService.setGridState(this.stateManagement.componentKey, this.gridId, this.state, this.stateKey);
  }

  private saveSortState(skip: number, take: number): void {
    if (!this.stateManagement) return;
    this.state.skip = skip;
    this.state.take = take;
    this.storageService.setGridState(this.stateManagement.componentKey, this.gridId, this.state, this.stateKey);
  }

  private saveExpandedRowsState(): void {
    if (!this.stateManagement || !this.state) return;
    this.state.expandedDetailsIds = [];
    _.forIn(this.expandedRows, (value: number, key: string) => {
      if (value !== undefined) {
        this.state.expandedDetailsIds.push(key);
      }
    });
    this.storageService.setGridState(this.stateManagement.componentKey, this.gridId, this.state, this.stateKey);
  }

  private switchState(context: StringMap<any>): void {
    this.stateKey = new ControlStateKey(context);
    this.loadState();
  }

  private rowCounts(): number {
    if ((<GridDataResult>this.grid.data).data) {
      return (<GridDataResult>this.grid.data).data.length;
    } else {
      return (<Array<any>>this.grid.data).length;
    }
  }

  private findDataItemIndex(keyField: string, key: string): number {
    let dataItems: any[];
    if (!this.grid.data) {
      return null;
    }
    if ((<GridDataResult>this.grid.data).data) {
      dataItems = (<GridDataResult>this.grid.data).data;
    } else {
      dataItems = (<Array<any>>this.grid.data);
    }
    let index = _.findIndex(dataItems, (item: any) => {
      let id = _.get(item, keyField);
      if (!id) {
        return false;
      }
      return id.toString() === key;
    });
    return index;
  }

  private getDataItem(rowIndex: number): any {
    let dataItem: any;
    if ((<GridDataResult>this.grid.data).data) {
      dataItem = (<GridDataResult>this.grid.data).data[rowIndex];
    } else {
      dataItem = (<Array<any>>this.grid.data)[rowIndex];
    }
    return dataItem;
  }

  private getRowKey(index: number, dataItem: any): string {
    if (this.keyId) {
      if (!dataItem) {
        return null;
      }
      return _.get(dataItem, this.keyId);
    }
    return index.toString();
  }
}


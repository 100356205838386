
import { combineLatest, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { SortDescriptor } from '@progress/kendo-data-query';
import { ServerCompositeFilterDescriptor, ServerQuery, IFilteredItems, PagingData } from '../../../../core/models/index';
import { ServerFilterService, addObjectToFilter, addFilter, addFieldToFilter, removeFilter } from '../../../../core/services/index';
import { LookupService, OrgLevelWatchService } from '../../../../organization/services/index';
import { Lookup, LookupType, UserAction } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { AuditTrailEmployeeAction } from '../../models/index';
import { AuditTrailApiService, AuditTrailMapService } from '../../services/index';

import { Observable ,  Subscription } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { RangeDates, IRangeDates } from '../../../../common/models/range-dates';
import * as moment from 'moment';
import { StateManagementService } from '../../../../common/services/index';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { ControlStateKey, IControlState, StateResetTypes } from '../../../../core/models/settings/index';
import { AuditTrailEvent, AuditTrailEventCmd } from '../../models/audit-trail/audit-trail-event';
import { AuditTrailManagementService } from '../../services/audit-trail/audit-trail-management.service';


@Component({
  moduleId: module.id,
  selector: 'slx-audit-trail',
  templateUrl: 'audit-trail.component.html',
  styleUrls: ['audit-trail.component.scss'],
  providers: [AuditTrailManagementService, ServerFilterService, StateManagementService]
})
export class AuditTrailComponent implements OnInit, OnDestroy {

  public state: {
    isLoading: boolean;
  };
  public actionList: IFilteredItems<AuditTrailEmployeeAction>;
  public isreadonly: boolean;
  public fromFilter: Date;
  public toFilter: Date;
  public selectedAreas: string[];
  public currentOrgLevel: OrgLevel;
  public pagingData: PagingData;
  public gridSort: SortDescriptor[];
  public selectedEmps: number[];
  public canExportData = false;
  public disableGlobalAreaFilter: boolean;
  public isActiveEmpOnly = true;

  public lookups: {
    auditTrailAreas: any[];
    employeeList: any[];
  };

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private filterChangeSubscription: Subscription;
  @unsubscribe()
  private loadSubscription: Subscription;

  private m_filtersControlId: string = 'FiltersNgx';
  private m_resetType: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
  private stateKey: ControlStateKey;


  constructor(private apiService: AuditTrailApiService,
    private serverFilterService: ServerFilterService,
    private orgLevelWatchService: OrgLevelWatchService,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private managementService: AuditTrailManagementService,
    private lookupService: LookupService) {
    this.pagingData = { skip: 0, take: 50 };
    this.gridSort = [{ field: 'auditDateTime', dir: 'desc' }];
    this.lookups = {
      auditTrailAreas: [],
      employeeList: []
    };
  }

  public ngOnInit(): void {

    this.selectedEmps = [];
    this.selectedAreas = [];
    this.state = { isLoading: false };
    this.fromFilter = moment().add(-2, 'weeks').toDate();
    this.toFilter = moment().toDate();
    this.stateManagement.init('AuditTrailComponent', false);
    this.managementService.subscribeToUserActionsChanged((actions: UserAction[]) => {
      this.canExportData = _.some(actions, x => x.name === 'Export To Excel');
    });

    this.orgLevelSubscription = this.orgLevel$.pipe(
      combineLatest(this.orgLevelWatchService.orgLevelTreeLoaded$),
      map((value: [OrgLevel, boolean]) => value[0]))
      .subscribe((orgLevel: OrgLevel) => {
        if (!this.currentOrgLevel || this.currentOrgLevel.id !== orgLevel.id) {
          this.currentOrgLevel = orgLevel;
          this.state.isLoading = true;

          Promise.all([
            this.updateLookups()
          ]).then(() => {
            this.serverFilterService.clearFilter();
            this.restoreStates();
            this.refreshData(false);
            this.managementService.loadUserActions(orgLevel.id);
          });
        }
      });

    // after all restore actions complete, subscribe to updates
    this.filterChangeSubscription = this.serverFilterService.changes$.subscribe((filter: ServerCompositeFilterDescriptor) => {
      if (!this.currentOrgLevel) {
        return;
      }
      this.refreshData(true);
    });

    this.loadSubscription = this.stateManagement.loadData$.subscribe(() => {
      this.refreshData(false);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public refreshData(localFilterChanged: boolean): void {
    this.state.isLoading = true;

    const areaFilters = this.serverFilterService.extractFilterValues('area');
    if (areaFilters && areaFilters.length > 0) {
      this.disableGlobalAreaFilter = true;
      this.selectedAreas = [];
    } else {
      this.disableGlobalAreaFilter = false;
      this.updateAreaServerFilters();
    }

    if(localFilterChanged){
      this.pagingData.skip = 0;
    }

    this.updateDateServerFilters();
    this.updateEmployeeServerFilters();

    this.apiService.getEmployeeActions(this.createQuery(this.pagingData), this.currentOrgLevel.id, this.disableGlobalAreaFilter)
      .then((val: IFilteredItems<AuditTrailEmployeeAction>) => {
        this.state.isLoading = false;
        this.actionList = val;
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  public filterDateChanged(dates: IRangeDates): void {
    this.fromFilter = moment(dates.startDate).startOf('day').toDate();
    this.toFilter = moment(dates.endDate).endOf('day').toDate();
    this.pagingData = { skip: 0, take: 50 };
    this.saveFilterStates();
  }

  public filterEmpChanged(selected: number[]): void {
    //todo move this logic to separate filter component
    this.selectedEmps = selected;
    this.updateEmployeeServerFilters();
    this.pagingData = { skip: 0, take: 50 };
    this.saveFilterStates();
  }

  public areaSelected(areas: string[]): void {
    this.selectedAreas = areas;
    this.updateAreaServerFilters();
    this.pagingData = { skip: 0, take: 50 };
    this.saveFilterStates();
  }

  public async isActiveOnlyChanged(): Promise<any> {
    return this.lookupService.getLookup({
      lookupType: LookupType.employeeList,
      orgLevelId: this.currentOrgLevel.id,
      employeeId: undefined,
      isActive: this.isActiveEmpOnly
    }).then((res) => {
      this.lookups.employeeList = res.items;
    });
  }

  public gridPageChanged(pagingData: PagingData): void {
    this.pagingData = pagingData;
    this.refreshData(this.disableGlobalAreaFilter);
  }

  public gridSortChanged(sort: SortDescriptor[]): void {
    this.gridSort = sort;
    this.refreshData(this.disableGlobalAreaFilter);
  }

  public onExportToExcel(): void {
    let action: AuditTrailEvent = new AuditTrailEvent();
    action.cmd = AuditTrailEventCmd.excelExport;
    if(this.actionList.count <= this.pagingData.take) {
      action.payload = this.actionList;
      this.managementService.onActionCmd(action);
      return;
    }
    this.state.isLoading = true;
    const paging = new PagingData();
    paging.skip = 0;
    paging.take = this.actionList.count;
    this.apiService.getEmployeeActions(this.createQuery(paging), this.currentOrgLevel.id, this.disableGlobalAreaFilter)
      .then((val: IFilteredItems<AuditTrailEmployeeAction>) => {
        this.state.isLoading = false;
        action.payload = val;
        this.managementService.onActionCmd(action);
        this.state.isLoading = false;
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  public onExportToPdf(): void {
    let action: AuditTrailEvent = new AuditTrailEvent();
    action.cmd = AuditTrailEventCmd.pdfExport;
    this.managementService.onActionCmd(action);
  }

  private createQuery(paging: PagingData): ServerQuery {
    const globalAreaFilters = this.serverFilterService.extractFilterValues('area_global');
    if (globalAreaFilters && globalAreaFilters.length > 0) {
      const query = _.cloneDeep(this.serverFilterService.createQuery(paging, this.gridSort));
      removeFilter(query.filter, 'area_global');

      if (this.selectedAreas && this.selectedAreas.length > 0) {
        let filter: ServerCompositeFilterDescriptor = { filters: [], logic: 'or' };

        _.forEach(this.selectedAreas, (ar: string) => {
          addFieldToFilter(filter, 'area', 'contains', ar);
        });

        addFilter(query.filter, filter);
      }
      return query;
    }

    return this.serverFilterService.createQuery(paging, this.gridSort);
  }

  private updateDateServerFilters(): void {
    this.serverFilterService.removeFilter('auditDateTime');
    if (this.fromFilter) {
      this.serverFilterService.composeFilter({ field: 'auditDateTime', operator: 'gte', value: this.fromFilter });
    }
    if (this.toFilter) {
      this.serverFilterService.composeFilter({ field: 'auditDateTime', operator: 'lte', value: this.toFilter });
    }
  }

  private updateEmployeeServerFilters(): void {
    this.serverFilterService.removeFilter('employeeId');
    if (this.selectedEmps && this.selectedEmps.length) {
      let filter: ServerCompositeFilterDescriptor = { filters: [], logic: 'or' };
      _.forEach(this.selectedEmps, (empId: number) => {
        addObjectToFilter(filter, { employeeId: empId });
      });
      this.serverFilterService.addFilter(filter);
    }
  }

  private updateAreaServerFilters(): void {
    this.serverFilterService.removeFilter('area_global');

    const hasAll: boolean = !!_.find(this.selectedAreas, (a: string) => a === 'All');
    if (!this.selectedAreas || this.selectedAreas.length === 0 || hasAll) {
      return;
    }

    let filter: ServerCompositeFilterDescriptor = { filters: [], logic: 'or' };

    _.forEach(this.selectedAreas, (ar: string) => {
      addFieldToFilter(filter, 'area_global', 'contains', ar);
    });

    this.serverFilterService.addFilter(filter);
  }

  private updateLookups(): Promise<any> {
    let auditTrailAreasPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.auditTrailAreas,
      orgLevelId: this.currentOrgLevel.id,
      employeeId: undefined
    });

    let employeeList: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.employeeList,
      orgLevelId: this.currentOrgLevel.id,
      employeeId: undefined,
      isActive: this.isActiveEmpOnly
    });

    return Promise.all([auditTrailAreasPromise, employeeList]).then((arrays: Lookup[]) => {
      this.lookups.auditTrailAreas = arrays[0].items;
      this.lookups.employeeList = arrays[1].items;
    });
  }

  private saveFilterStates(): void {
    let state: IControlState = {
      value: { empFilter: this.selectedEmps, selectedAreas: this.selectedAreas, fromDate: this.fromFilter, toDate: this.toFilter }
    };
    this.storageService.setControlState(this.stateManagement.componentKey, this.m_filtersControlId, state,
      this.m_resetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.m_filtersControlId);
  }

  private restoreStates(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.m_filtersControlId);
    if (state && state.value) {
      if (state.value.empFilter) this.selectedEmps = state.value.empFilter;
      if (state.value.selectedAreas) this.selectedAreas = state.value.selectedAreas;
      if (state.value.fromDate) this.fromFilter = moment(state.value.fromDate).toDate();
      if (state.value.toDate) this.toFilter = moment(state.value.toDate).toDate();
    }
  }
}

import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { W2sInfo, W2sRecord } from '../../../models/w2s/w2s-model';
import { W2sManagementService } from '../../../services/w2s/w2s-management.service';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { screenUtils } from '../../../../common/utils';
import { InfoDialogComponent, ModalService } from '../../../../common';
import { PdfDataStatus } from '../../../../reports/enums/pdf-data-status';

@Component({
  selector: 'slx-w2s-grid',
  templateUrl: './w2s-grid.component.html',
  styleUrls: ['./w2s-grid.component.scss']
})
export class W2sGridComponent implements OnInit, OnDestroy {
  public records: W2sRecord[]=[];
  public gridState: KendoGridStateHelper<W2sRecord[]>;
  public orgLevel: OrgLevel;
  public pageSize: number = 50;
  public columnsGroupName: string = 'W2sRecords';
  public isReordable:boolean = false;
  public cells: any;
  public w2sState:number = 0;
  public appConfig: IApplicationConfig;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  public get pdfDataStatus() { return PdfDataStatus; }
  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }
  public get failedReason(): string { 
    return "This Form W-2 did not publish correctly and is therefore not visible to the employee. Please try publishing again at your earliest convenience. If issues persist, please contact the Smartlinx Support team.";
  }

  constructor(
    private w2sManagementService: W2sManagementService,
    private modalService: ModalService
    
    ) {
    this.gridState = new KendoGridStateHelper<W2sRecord[]>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'fullName', dir: 'desc' } ];
    this.appConfig = appConfig;
  }

  public ngOnInit() {

    this.subscriptions.onLoaded = this.w2sManagementService
      .subscribeToLoadedRecords((r: W2sInfo) => this.setRecords(r.records));

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());
  }
  private setRecords(records: W2sRecord[]): void {
    this.records = records;
    let selectedRecords=_.filter(this.records,(item:W2sRecord)=>(item.w2Document.isApproved==0));
          
      if(this.records.length==0)
      {
        this.w2sState=0;
      }
      else
      {
        if(selectedRecords.length==0)
        {
          this.w2sState=1;
        }
        else
        {
          this.w2sState=2;
        }
      }
      
   
    this.refreshGrid();
  }
  public ngOnDestroy(): void { }
  
  
  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  public downloadW2SFile(exportId:number,fileName:string): void {
    this.w2sManagementService.downloadW2sDocument(exportId,fileName);
  }

  public showReasonInfoPopup(data: W2sRecord): void {
    if (this.isMobile && data.w2Document.status.toLowerCase() === PdfDataStatus.FAILED.toLowerCase()) {
      InfoDialogComponent.OpenDialog('Information', this.failedReason, this.modalService);
    }
  }
}

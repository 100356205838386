import { Directive, Input, OnDestroy, AfterContentInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Assert } from '../../../../framework/index';
import { EmpScrollSection } from '../../models/index';
import { EmpScrollService } from '../../services/index';

@Directive({
  selector: '[scrollSection]',
})
export class ScrollSectionDirective implements AfterContentInit {
  @Input('scrollSection')
  private sectionId: string;
  private scrollSubscription: Subscription;
  private scrollService: EmpScrollService;
  private elementRef: ElementRef;

  constructor(scrollService: EmpScrollService, elementRef: ElementRef) {
    this.scrollService = scrollService;
    this.elementRef = elementRef;
    this.sectionId = '';
  }

  public ngAfterContentInit(): void {
    Assert.isTrue(!!this.sectionId, 'sectionId');
    const element: HTMLElement = this.elementRef.nativeElement;
    const section: EmpScrollSection = this.createSection(element);
    this.scrollService.addSection(section);
  }

  private createSection(element: HTMLElement): EmpScrollSection {
    const section: EmpScrollSection = new EmpScrollSection();
    section.id = this.sectionId;
    section.element = element;

    return section;
  }
}


export class RuleFormulaModel {
    public id: number;
    public ruleId?: number;
    public variableId1?: string;
    public operator?: string;
    public variableId2?: string;
    public isNew: boolean;
    public valueType1: string;
    public valueType2: string;
    public type1: string;
    public type2: string;
}
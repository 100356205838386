<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents cp-header">
      <div class="col-xs-2 col-md-2 col-lg-2 padd-l-0">
          <slx-dropdown-list class="slx-wide slx-short slx-fit"
              name="agency_name"
              valueField="partner_id" titleField="agency_name"
              placeholder="Select Agency"
              [options]="agencyListData"
              [(ngModel)] = "selectedPartner"
              (ngModelChange)="selectionChange($event)"
              >
          </slx-dropdown-list>
      </div>
      <div class="col-xs-8 col-md-8 col-lg-8 padd-l-0 positionError">
        <span
          *ngIf="management.access.restrictedByOrgLevel"
          class="edit-info-warning"
        >Positions are editable only at Department level
      </span>
      </div>
      <div class="col-xs-2 col-md-2 col-lg-2 padd-l-0">
        <button type="button"
          class="slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks"
          [slx-list-add-operation]="onAddItem">
          <i class="fa fa-plus slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Add Position</span>
        </button>
      </div>
     
     
    </div>

    <div class="slx-high-box__body" *ngIf="state.configureMode">
      <kendo-tabstrip class="slx-tabs">
        <kendo-tabstrip-tab [title]="positionName" [selected]="isFirst" *ngFor="let positionName of partnerPositions; first as isFirst" (select)="onTabSelected()" >
          <ng-template kendoTabContent >
            <!-- Loop throug-->
            <slx-partner-position-mapping [positionName] ="positionName" [customerId]="customerId"  [partnerId]="selectedPartnerId" [addAction] = "addAction"  [positionsList]="positionList" [restrictedByOrgLevel]="management.access.restrictedByOrgLevel">
            </slx-partner-position-mapping>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </div>
</slx-spinner>

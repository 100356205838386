import * as moment from 'moment';
import * as _ from 'lodash';

const randomColor = require('randomcolor');

import { Injectable, InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { Comparison, ComparisonItem, ChartMenuItem, ChartType, DisplayDefinition, DisplayType, ConsoleConfig, ScheduleConsoleDayIndicator, ScheduleConsoleViewItem, ScheduleConsoleEntry, ScheduleConsoleCycles } from '../../models/index';
import { ValuePairWidgetConfig, ValuePairChartInput, ValuePairChartDataService, ValuePairChartSeriesInput } from '../../../../common/index';
import { DetailGroupViewSettingsTypes } from './../../../models/index';
import { PairColorScheme } from '../../../../common/models/pair-color-scheme/pair-color-scheme';
import { ScheduleConsoleMapService } from '..';

@Injectable()
export class ScheduleConsoleChartService {

  public static makeChartInput(comparison: Comparison, consoleConfig: ConsoleConfig): ValuePairChartInput {
    const item: ScheduleConsoleViewItem = ScheduleConsoleChartService.makeSCViewItem(comparison, consoleConfig.displayType);
    const chartInput = new ValuePairChartInput();

    chartInput.additionalInfo = comparison;
    chartInput.value1 = item.scheduled.value;
    chartInput.label1 = item.actualText;
    chartInput.value2 = item.scheduled.limit;
    chartInput.label2 = item.limitText;
    chartInput.lowerLimit = consoleConfig.lowerLimit;
    chartInput.upperLimit = consoleConfig.upperLimit;

    const absDifference = Math.abs(item.scheduled.value) - Math.abs(item.scheduled.limit);

    if (absDifference !== 0) {
      chartInput.additionalValue = (item.scheduled.value > item.scheduled.limit ? '+' : '') + `${absDifference.toFixed(1)}`;
    } else {
      chartInput.additionalValue = null;
    }

    switch (consoleConfig.displayType) {
      case DisplayType.idealScheduleHours:
        chartInput.additionalLabel = 'Hours';
        break;
      case DisplayType.idealSchedulePPD:
        chartInput.additionalLabel = 'PPD';
        break;
      case DisplayType.budgetedHours:
        chartInput.additionalLabel = 'Hours';
        break;
      case DisplayType.budgetedPPD:
        chartInput.additionalLabel = 'PPD';
        break;
    }

    return chartInput;
  }

  public static makeChartSeriesInputs(consoleCycles: ScheduleConsoleCycles, consoleConfig: ConsoleConfig, filled?: boolean): ValuePairChartSeriesInput[] {
    const entries: ScheduleConsoleEntry[] = [];
    const seriesInputs: ValuePairChartSeriesInput[] = [];

    _.forEach(consoleCycles.groupByPosition, (consoleEntry: ScheduleConsoleEntry) => {
      const entry: ScheduleConsoleEntry = _.cloneDeep(consoleEntry);
      const comparisons: Comparison[] = [];

      const uniqueComparisonsDates: moment.Moment[] = _.map(
        _.uniqWith(entry.values, (a: Comparison, b: Comparison) => a.date.isSame(b.date)),
        (comparison: Comparison) => comparison.date
      );

      _.forEach(uniqueComparisonsDates, (date: moment.Moment) => {
        const dateComparisons = _.filter(entry.values, (comparison: Comparison) => comparison.date === date);
        comparisons.push(Comparison.mergeComparisons(...dateComparisons));
      });

      entry.values = comparisons;
      entries.push(entry);
    });

    const colors = randomColor({
      count: entries.length,
      seed: 'chart-series',
    });

    _.forEach(entries, (consoleEntry: ScheduleConsoleEntry, index: number) => {
      if (!consoleEntry.hidden) {
        const seriesInput = new ValuePairChartSeriesInput();
        seriesInput.seriesColor = colors[index];
        seriesInput.seriesLabel = consoleEntry.name;
        seriesInput.valuePairs = [];

        _.forEach(consoleEntry.values, (comparison: Comparison) => {
          const input = ScheduleConsoleChartService.makeChartInput(comparison, consoleConfig);
          seriesInput.valuePairs.push(input);
        });
        seriesInputs.push(seriesInput);
      }
    });

    return seriesInputs;
  }

  public static getComparisonColor(comparison: Comparison, consoleConfig: ConsoleConfig, widgetConfig: ValuePairWidgetConfig): PairColorScheme {
    const input = this.makeChartInput(comparison, consoleConfig);
    const color = ValuePairChartDataService.getColor(input, widgetConfig);

    return color;
  }

  public static makeWeeklyColorMap(comparisons: Comparison[], consoleConfig: ConsoleConfig, widgetConfig: ValuePairWidgetConfig): ScheduleConsoleDayIndicator[] {
    return _.map(comparisons, (comparison: Comparison) => {
      const color: PairColorScheme = ScheduleConsoleChartService.getComparisonColor(comparison, consoleConfig, widgetConfig);

      return new ScheduleConsoleDayIndicator(color.fontColor1, comparison.date.toDate());
    });
  }

  public static makeSCViewItem(comparison: Comparison, displayType: DisplayDefinition): ScheduleConsoleViewItem {
    let { idealHours, idealPPD, budgetedHours, budgetedPPD }: Comparison = comparison;
    let actualText: string = '';
    let limitText: string = '';
    let viewMode: string = '';
    let scheduled: ComparisonItem = null;
    switch (displayType) {
      case DisplayType.idealScheduleHours:
        actualText = 'Hours';
        limitText = 'Ideal Hours';
        viewMode = DetailGroupViewSettingsTypes.hours;
        scheduled = idealHours;
        break;
      case DisplayType.idealSchedulePPD:
        actualText = 'PPD';
        limitText = 'Ideal PPD';
        viewMode = DetailGroupViewSettingsTypes.ppd;
        scheduled = idealPPD;
        break;
      case DisplayType.budgetedHours:
        actualText = 'Budgeted Hours';
        limitText = 'Budgeted Hours';
        viewMode = DetailGroupViewSettingsTypes.hours;
        scheduled = budgetedHours;
        break;
      case DisplayType.budgetedPPD:
        actualText = 'Budgeted PPD';
        limitText = 'Budgeted PPD';
        viewMode = DetailGroupViewSettingsTypes.ppd;
        scheduled = budgetedPPD;
        break;
    }

    return new ScheduleConsoleViewItem(actualText, limitText, viewMode, scheduled);
  }
}

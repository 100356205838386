import { NgModel, NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener, Input, Renderer2, SimpleChanges, OnChanges } from '@angular/core';
import { CustomDomEvents } from '../../models/index';
import * as _ from 'lodash';
@Directive({
  selector: '[slxTrim]'
})
export class SlxTrimStringValueDirective {
    @Input()
    public slxTrim: 'both' | 'start' | 'end' = 'both';
    private ngControl: any;
    private ngControlHost: any;

    constructor(ngControl: NgControl) {
        this.ngControl = ngControl;
        this.ngControlHost = ngControl.valueAccessor;
    }

    @HostListener('blur', ['$event'])
    public onBlur(event: Event): void {
        let newValue: string;
        switch(this.slxTrim) {
            case 'start':
                newValue = _.trimStart(this.ngControl.model);
            break;
            case 'end':
                newValue = _.trimEnd(this.ngControl.model);
            break;
            default:
                newValue = _.trim(this.ngControl.model);
        }

        if (newValue !== this.ngControl.model) {
            this.ngControlHost.writeValue(newValue);
            this.ngControl.update.emit(newValue);
        }
    }
}

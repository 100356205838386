import { Directive, ElementRef, Input, ContentChild, OnInit, OnChanges, Optional, Host, Inject, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription ,  Subject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { IControlState, ControlStateKey, StateResetTypes } from '../../../core/models/index';
import { ComponentStateStorageService, StateManagementService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';
import { scheduleMicrotask } from '../../../core/utils/index';

@Directive({
  selector: '[slxPropertyInitState]'
})
export class PropertyInitStateDirective implements OnInit {

  @Input()
  public set propertySave(value: any) {
    this.valueChanged(value);
  }
  @Output()
  public propertySaveChange = new EventEmitter<any>();

  @Input('slxPropertyInitState')
  public set resetType(type: StateResetTypes) {
    if (!type) {
      return;
    }
    this.m_resetType = type;
  }
  @Input()
  public controlId: string;

  @unsubscribe()
  private initSubscription: Subscription;
  @unsubscribe()
  private valueChangesSubscription: Subscription;

  private m_resetType: StateResetTypes;
  private state: IControlState;

  constructor(private storageService: ComponentStateStorageService, private stateManagement: StateManagementService) {
    this.m_resetType = StateResetTypes.OrgLevelChange | StateResetTypes.SessionEnd;
  }

  public ngOnInit(): void {
    if (!this.controlId) {
      throw Error('State directive can be used only with controlId');
    }

    this.initSubscription = this.stateManagement.onInit$.subscribe(() => {
      scheduleMicrotask(() => { this.loadState(); });
    });

  }

  public valueChanged(val: any): void {
    if(!this.state) {
      return;
    }
    this.storageService.mapValueToState(val, this.state);
    this.storageService.setControlState(this.stateManagement.componentKey, this.controlId, this.state, this.m_resetType);
  }

  private loadState(): void {
    this.state = this.storageService.getControlState(this.stateManagement.componentKey, this.controlId);
    if (this.state.value !== undefined) {
      let value: any = this.storageService.mapValueFromState(this.state);
      this.propertySaveChange.emit(value);
    }
  }
}

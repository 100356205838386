<div class="history-bar">
    <button class="back-btn" slxBackLink>
        <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
        Back
    </button>
    <!--
    <button class="nav-btn forward-btn" disabled slxForwardLink>
        <i class="fa fa-caret-right" aria-hidden="true"></i>
    </button>
    -->
    <div *ngIf="item" class="text-line">
        <span class="title-label" *ngIf="item.isAsync">{{item.title | async}}</span>
        <span class="title-label" *ngIf="!item.isAsync">{{item.title}}</span>
    </div>
</div>


import {filter} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { unsubscribe } from './../../../core/decorators/unsubscribe-decorator';
import { ModalService } from './../../../common/services/modal/modal.service';
import { ScheduleApiService } from './../../services/schedule/schedule-api.service';
import { LookupType } from './../../../organization/models/lookup/lookup-definition';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Assert } from '../../../framework/index';

import { Subscription ,  Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../app.config';
import { ScheduleCycle } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import {
  OpenShiftShortDetailsWrapper, OpenShiftSummary,
  OpenShiftShortDetailsSetWrapper, IOpenShiftMessageCountChangedEventArgs, PostScheduleSettings, IOpenShiftCountChangedEventArgs
} from '../../models/index';
import { OpenShiftManagementApiService, OpenShiftManagementManagementService } from '../../services/index';
import { LookupApiService } from '../../../organization/services/index';
import { WeeklyData, DailyData } from '../../../core/models/index';

import { mutableSelect } from '../../../core/decorators/index';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';

@Component({
  moduleId: module.id,
  selector: 'slx-open-shift-management',
  templateUrl: 'open-shift-management.component.html',
  styleUrls: ['open-shift-management.component.scss']
})
export class OpenShiftManagementComponent implements OnInit, OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public selectedOrgLevel: OrgLevel;
  public selectedOpenShiftSummary: OpenShiftSummary;

  public state: {
    isLoading: boolean;
  };
  public scheduleCycles: ScheduleCycle[];
  public selectedScheduleCycle: ScheduleCycle;
  public summaryDetailsSet: OpenShiftShortDetailsSetWrapper;
  public appConfig: IApplicationConfig;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  private selectedStartDate: Date;
  private selectedShortDetails: OpenShiftShortDetailsWrapper;
  private filters: any;
  private paramDate: moment.Moment;
  private onShiftCountChangedByPartialShift: Subscription;

  @unsubscribe()
  private routeSubscripion: Subscription;
  notificationData: any;

  constructor(
    private openShiftManagementApiService: OpenShiftManagementApiService,
    public openShiftManagementManagementService: OpenShiftManagementManagementService,
    private lookupApiService: LookupApiService,
    private scheduleApiService: ScheduleApiService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationsService: NotificationsService) {
      this.state = {
        isLoading: false,
      };
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.orgLevelSubscription = this.orgLevel$.pipe(
      filter((o: OrgLevel) => !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id))
      .subscribe((o: OrgLevel) => {
        this.selectedOrgLevel = o;
        this.loadScheduleCycles(this.selectedOrgLevel);
      });

      this.routeSubscripion = this.activatedRoute.queryParams
      .subscribe((queryParams: any) => {
        let dateOn = queryParams['date'];
        if (dateOn) {
          this.paramDate = moment(dateOn, appConfig.linkDateFormat);
        }
      });

    this.routeSubscripion = this.openShiftManagementManagementService.loading$
      .subscribe((isShown: boolean) => {
        this.state.isLoading = isShown;
      });

      this.onShiftCountChangedByPartialShift = this.openShiftManagementManagementService.onOpenShiftCountChangedByPartialShift$.subscribe(
        (args: IOpenShiftCountChangedEventArgs) => this.refreshPage());
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onSummaryItemSelected(shortDetails: OpenShiftShortDetailsWrapper): void {
    this.selectDailyItem(shortDetails);
  }

  public onScheduleCycleSelected(cycle: ScheduleCycle): void {
    this.setScheduleCycleSelected(cycle);
  }

  public onFilterChanged(filters: any): void {
    this.filters = filters;
    this.loadScheduleCycleSummary();
  }

  public postSchedule(): void {
    this.state.isLoading = true;
    this.scheduleApiService.postSchedule(this.selectedOrgLevel, this.selectedScheduleCycle.startDate.toDate()
    ,this.selectedScheduleCycle.endDate.toDate(),this.openShiftManagementManagementService.postSettings)
      .then((value: any) => {
        this.state.isLoading = false;
        this.notificationsService.success('Post Schedule', 'Schedule has been successfully posted.');
        this.loadScheduleCycleSummary();
      }).catch((reason: any) => {
        this.state.isLoading = false;
        this.notificationsService.error('Post Schedule', 'Schedule posting failed.');
      });
  }

  public savePostSettings(): void {
    this.state.isLoading = true;
    this.openShiftManagementManagementService.updatePostSettingsByCycle(this.selectedScheduleCycle);
    this.scheduleApiService.savePostSettings(this.selectedOrgLevel, this.openShiftManagementManagementService.postSettings)
      .then((value: any) => {
        this.state.isLoading = false;
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  public loadPostSettings(orgLevel: OrgLevel): Promise<PostScheduleSettings> {
    return this.scheduleApiService.loadPostSettings(orgLevel, this.selectedScheduleCycle.startDate.toDate(), this.selectedScheduleCycle.endDate.toDate());
  }

  private selectDailyItem(shortDetails: OpenShiftShortDetailsWrapper): void {
    this.selectedShortDetails = shortDetails;
    this.loadDailySummary(this.selectedOrgLevel, this.selectedShortDetails);
  }

  private loadScheduleCycles(orgLevel: OrgLevel): void {
    this.selectedOpenShiftSummary = null;
    this.selectedOrgLevel = orgLevel;
    this.selectedStartDate = null;
    this.lookupApiService.getScheduleCycles(orgLevel.id)
      .then((cycles: ScheduleCycle[]) => {
        this.scheduleCycles = cycles;
        if (!this.scheduleCycles) return;

        let dateNow: moment.Moment = this.paramDate ? this.paramDate : moment().startOf('day');
        let curentCycle: ScheduleCycle = _.find(this.scheduleCycles, (cycle: ScheduleCycle) => {
          if (dateNow.isBetween(cycle.startDate, cycle.endDate, null, '[]'))
            return true;
          else
            return false;
        });
        if (curentCycle) {
          this.selectedScheduleCycle = curentCycle;
          this.setScheduleCycleSelected(curentCycle);
        }
      });
  }

  private getDailyDetailsByDate(dateOn: moment.Moment): OpenShiftShortDetailsWrapper {
    let details: OpenShiftShortDetailsWrapper = undefined;
    _.forEach(this.summaryDetailsSet.weeklyData, (weeklyData: WeeklyData<OpenShiftShortDetailsWrapper>) => {
      _.forEach(weeklyData.days, (dayData: DailyData<OpenShiftShortDetailsWrapper>) => {
        if (dayData.startOfDay.isSame(dateOn.startOf('day'))) {
          details = dayData.data;
        }
      });
    });
    return details;
  }

  private async setScheduleCycleSelected(cycle: ScheduleCycle): Promise<void> {
    if (cycle) {
      this.selectedScheduleCycle = cycle;
      this.openShiftManagementManagementService.getSelectedScheduleCycle(this.selectedScheduleCycle);
      this.selectedStartDate = cycle.startDate.toDate();
      try {
        this.state.isLoading = true;
        let settings: PostScheduleSettings = await this.loadPostSettings(this.selectedOrgLevel);
        this.openShiftManagementManagementService.postSettingsLoaded(settings);
      } catch (error) {
        throw error;
      } finally {
        this.state.isLoading = false;
        this.loadScheduleCycleSummary();
      }
    }
  }

  private loadScheduleCycleSummary(): void {
    this.selectedOpenShiftSummary = null;
    if (!this.selectedOrgLevel || !this.selectedStartDate) {
      return;
    }
    this.state.isLoading = true;
    this.openShiftManagementApiService.getOpenShiftScheduleCycleSummary(this.selectedOrgLevel.id, this.selectedStartDate, this.filters)
      .then((summaryDetailsSetWrap: OpenShiftShortDetailsSetWrapper) => {
        this.openShiftManagementManagementService.setCanPostSchedule(summaryDetailsSetWrap.canPostSchedule);
        this.openShiftManagementManagementService.setCanEditOpenShiftCount(summaryDetailsSetWrap.canEditOpenShiftCount);
        this.openShiftManagementManagementService.setCanSendSMS(summaryDetailsSetWrap.canSendSMS);
        this.summaryDetailsSet = summaryDetailsSetWrap;
        this.state.isLoading = false;
        this.tryToSelectTodaysScheduleDetails();
      });
  }

  private tryToSelectTodaysScheduleDetails(): void {
    let date: moment.Moment;
    if (this.selectedShortDetails) {
      date = moment(this.selectedShortDetails.date);
    }
    let dateNow: moment.Moment = date ? date : moment().startOf('day');
    let todaysDailyDetails: OpenShiftShortDetailsWrapper = this.getDailyDetailsByDate(dateNow);
    if (todaysDailyDetails) {
      this.openShiftManagementManagementService.selectDailyDetails({ dailySummaryDetails: todaysDailyDetails });
    }
  }

  private loadDailySummary(orgLevel: OrgLevel, shortDetails: OpenShiftShortDetailsWrapper): void {
    this.selectedOpenShiftSummary = null;
    Assert.isNotNull(orgLevel, 'orgLevel');
    Assert.isNotNull(shortDetails, 'shortDetails');
    this.state.isLoading = true;
    this.openShiftManagementApiService.getOpenShiftSummary(this.selectedOrgLevel.id, shortDetails.date, this.filters).then((openShiftSummary: OpenShiftSummary): void => {
      this.selectedOpenShiftSummary = openShiftSummary;
      this.state.isLoading = false;
    });
  }
  public updatePartnerShiftCount(isPartnerShift:boolean) :void {
    this.state.isLoading = true;
    if(isPartnerShift) {
    this.openShiftManagementApiService.getOpenShiftScheduleCycleSummary(this.selectedOrgLevel.id, this.selectedStartDate, this.filters)
      .then((summaryDetailsSetWrap: OpenShiftShortDetailsSetWrapper) => {
        this.summaryDetailsSet = summaryDetailsSetWrap;
        this.state.isLoading = false;
      });
    }
    
  }

  public refreshPage(): void {
    this.loadScheduleCycles(this.selectedOrgLevel);
  }
}

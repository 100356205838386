export const environment = {
  production: true,
  API: 'https://slxusc-prd136-v6-app01-be.azurewebsites.net',
  TA_SERVICES_API: "https://v6-dev-api-man.azure-api.net/prod",
  IDENTITY_API_URL: 'https://identityauthprod.smartlinx6.com',
  ENV: 'PROD',
  MARKETING_PAGE_LNK: 'https://v6-marketingpage.smartlinxsolutions.com/login-v6-01.html',
  FROALA_EDITOR_LICENCE: 'fA5C3F3B2C-7J2A4C3B1C6E2D1F4H1F1rA-16C-13pf1gxI-8xoB5A2ytq==',
  GOOGLE_MAPS_API_LICENSE: undefined,
  PENDO_LICENCE: '15c64758-bb83-4e5f-56c6-b77ac15375dc',
  ZENDESK_APIKEY: 'a3b8ed75-379a-4e97-b525-603a8f979d60',
  AG_GRID_LICENCE: 'Using_this_{AG_Grid}_Enterprise_key_{AG-053430}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Smartlinx_Solutions,_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Smartlinx}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Smartlinx}_need_to_be_licensed___{Smartlinx}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{1_May_2025}____[v3]_[01]_MTc0NjA1NDAwMDAwMA==bb4981321816c5a078c34a318cf75cea',
  APP_INSIGHT_INSTRUMENTATION_KEY: '3cb22324-ce40-40c4-a828-b02c8da0b5ef',
  APP_UI_URL: 'https://www.smartlinx6.com'
};

import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { ReportAttendanceSetting, ReportConfigPositionCategory, ReportStaffingSetting } from '../../models/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { ReportsManagementService } from '../../services/index';
import { unsubscribe, unsubscribeAll } from '../../../core/decorators/index';
import { Subscription } from 'rxjs';
interface Item {
    id: number,
    name: string
}

@Component({
    selector: 'slx-report-attendance-config',
    templateUrl: './report-attendance-config.component.html',
    styleUrls: ['./report-attendance-config.component.scss']
})
export class ReportAttendanceConfigComponent implements OnInit, OnDestroy{
    public dailyAttendanceOptions: Array<Item> = [];
    public gridState: KendoGridStateHelper<ReportAttendanceSetting>;
    public records: ReportAttendanceSetting;
    public isLoading: boolean = true;
    public dialogResult: boolean;
    public readonly pageSize = 50;
    @Input()
    public forcedLoad: boolean;
    @Output()
    public hasValueChanged = new EventEmitter<boolean>();
    @Output()
    public reportConfigChanged = new EventEmitter<ReportAttendanceSetting>();
    @unsubscribeAll()
    public subscriptions: StringMap<Subscription> = {};

    constructor(private reportsManagementService: ReportsManagementService) {
        this.gridState = new KendoGridStateHelper<ReportAttendanceSetting>();
        this.gridState.view = null;
        this.dailyAttendanceOptions = [{ id: 0, name: ' ' }, { id: 1, name: 'RNs' }, { id: 2, name: 'LPNs' }, { id: 3, name: 'Others' }];
    }
    public ngOnInit() {
        this.reportsManagementService.loadAttendanceSettingsData(this.forcedLoad);
        this.subscriptions.loadAttendanceSettingsSubscription = this.reportsManagementService.onReportAttendanceSettingsLoaded$.subscribe((reportAttendanceSetting: ReportAttendanceSetting) => {
            this.records = reportAttendanceSetting;
            this.refreshGrid();
        });
    }
    public refreshGrid(): void {
        if (!this.records) {
            this.gridState.view = null;
            this.isLoading = false;
            return;
        }
        this.gridState.view = process(this.records.positionCategory, this.gridState.state);
        this.isLoading = false;
    }
    public ngOnDestroy(): void {
        // See #issueWithAOTCompiler
      }
    public getPositionCategory(id: number): any {
        return this.dailyAttendanceOptions[id];
    }

    public setPositionCategory(positionCategoryType: ReportConfigPositionCategory, positionCategory: any): void {
        if (positionCategoryType.positionCategoryType !== positionCategory.id) {
            positionCategoryType.positionCategoryType = positionCategory.id;
            this.hasValueChanged.emit(true);
            this.reportConfigChanged.emit(this.records);
        }
    }
}

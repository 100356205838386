import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { GroupDescriptor, process, State } from '@progress/kendo-data-query';
import { Subscription ,  Observable } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';

import { DetailRow, DetailField, OpenShiftWrapper, OpenShiftSummary, OpenShiftDetails } from '../../../models/index';
import { OpenShiftManagementApiService } from '../../../services/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { screenUtils, IScreenUtils } from '../../../../common/utils/index';

@Component({
    moduleId: module.id,
    selector: 'slx-switch-employee-shift',
    templateUrl: 'switch-employee-shift.component.html',
    styleUrls: ['switch-employee-shift.component.scss']
})

export class SwitchEmployeeShiftComponent implements OnInit, OnDestroy {

    @Input()
    public date: Date;

    @Input()
    public set selectedItem(value: DetailRow) {
        this.m_selectedItem = value;
        let field: DetailField;

        field = this.getField(value.fields, 'EmpName');
        if (field) this.name = field.value;

        field = this.getField(value.fields, 'JobCode');
        if (field) this.positionId = parseInt(field.value);

        field = this.getField(value.fields, 'JobDescription');
        if (field) this.position = field.value;

        field = this.getField(value.fields, 'ShiftName');
        if (field) this.shift = field.value;

        field = this.getField(value.fields, 'UnitName');
        if (field) this.unit = field.value;

    }

    public get selectedItem(): DetailRow {
        return this.m_selectedItem;
    }

    public get isFilterable(): boolean {
      return !this.isMobile;
    }

    @Output()
    public onCancel: EventEmitter<any>;
    @Output()
    public onSave: EventEmitter<OpenShiftDetails>;

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    public selectedShift: OpenShiftWrapper;

    public position: string;
    public positionId: number;
    public name: string;
    public shift: string;
    public unit: string;

    @unsubscribe()
    public orgLevelSubscription: Subscription;
    @unsubscribe()
    public gridRefreshSubscription: Subscription;

    public currentOrgLevel: OrgLevel;

    public gridState: KendoGridStateHelper<OpenShiftWrapper>;
    public records: OpenShiftWrapper[] = [];

    private shiftSummary: OpenShiftSummary;
    private m_selectedItem: DetailRow;
    private screenUtils: IScreenUtils;
    private isMobile: boolean;

    constructor(private openShiftAPi: OpenShiftManagementApiService) {
        this.onCancel = new EventEmitter<any>();
        this.onSave = new EventEmitter<OpenShiftDetails>();
        this.gridState = new KendoGridStateHelper<OpenShiftWrapper>();
        this.gridState.state.group = [{ field: 'details.shiftGroupName' }];
        this.screenUtils = screenUtils;
    }

    public ngOnInit(): void {
      this.isMobile = screenUtils.isMobile;

        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
            this.refreshGrid();
        });

        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            this.currentOrgLevel = orgLevel;
            this.openShiftAPi.getOpenShiftSummary(this.currentOrgLevel.id, this.date, { positionIds: [this.positionId]}).then((shifts: OpenShiftSummary) => {
                this.shiftSummary = shifts;
                let groupedOpenShifts: _.Dictionary<OpenShiftDetails[]> = _.groupBy(shifts.details, shift => [shift.position.id, shift.unit.id, shift.shift.id].join());
                let openShifts: OpenShiftDetails[] = [];
                _.each(groupedOpenShifts, (groupedShift: OpenShiftDetails[]) => {
                  if (groupedShift.length > 0) {
                    let openShiftInfo: OpenShiftDetails = Object.assign(groupedShift[0], {});
                    openShiftInfo.adjustedHours = _.sumBy(groupedShift, (shift: OpenShiftDetails) => shift.adjustedHours);
                    openShiftInfo.adjustedShiftCount = _.sumBy(groupedShift, (shift: OpenShiftDetails) => shift.adjustedShiftCount);
                    openShiftInfo.idealHours = _.sumBy(groupedShift, (shift: OpenShiftDetails) => shift.idealHours);
                    openShiftInfo.idealShiftCount = _.sumBy(groupedShift, (shift: OpenShiftDetails) => shift.idealShiftCount);
                    openShiftInfo.openHours = _.sumBy(groupedShift, (shift: OpenShiftDetails) => shift.openHours);
                    openShiftInfo.openShiftCount = _.sumBy(groupedShift, (shift: OpenShiftDetails) => shift.openShiftCount);
                    openShiftInfo.overShiftCount = _.sumBy(groupedShift, (shift: OpenShiftDetails) => shift.overShiftCount);
                    openShiftInfo.scheduledHours = _.sumBy(groupedShift, (shift: OpenShiftDetails) => shift.scheduledHours);
                    openShiftInfo.scheduledShiftCount = _.sumBy(groupedShift, (shift: OpenShiftDetails) => shift.scheduledShiftCount);
                    openShiftInfo.workTimeHours = _.sumBy(groupedShift, (shift: OpenShiftDetails) => shift.workTimeHours);
                    if (openShiftInfo.openShiftCount > 0)  {
                      openShifts.push(openShiftInfo);
                    }
                  }
                });
                let wrappers: OpenShiftWrapper[] = [];
                _.each(openShifts, (details: OpenShiftDetails) => {
                    let wrapper: OpenShiftWrapper = new OpenShiftWrapper();
                    wrapper.details = details;
                    wrappers.push(wrapper);
                });
                this.records = wrappers;
                this.refreshGrid();
            });
        });
    }

    public ngOnDestroy(): void {
      // #issueWithAOTCompiler
    }

    public onSaveChanges(): void {
        this.onSave.emit(this.selectedShift.details);
    }

    public onDiscardChanges(): void {
        this.onCancel.emit();
    }

    public selectionChange(record: OpenShiftWrapper, state: boolean): void {
        if (record.isSelected) {
            this.selectedShift = record;
        } else {
            this.selectedShift = null;
        }
        _.each(this.records, (r: OpenShiftWrapper) => {
            if (r !== record) {
                r.isSelected = false;
            }
        });
    }

    private getField(fields: DetailField[], name: string): DetailField {
        return _.find(fields, (field: DetailField) => {
            return field.name === name;
        });
    }

    private refreshGrid(): void {

        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    }

}

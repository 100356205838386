
import {combineLatest} from 'rxjs/operators';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription ,  Observable } from 'rxjs';

import { ActivatedRoute, Params, Router } from '@angular/router';

import { ChangeManagementService } from '../../../../common/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { UserProfileNavigationService } from '../../../../common/services/index';
import { Organization } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';

import { UserProfileModel, UserRoleRelationModel, UserRoleRecordWrapper } from '../../../models/index';
import { UserProfileManagementService } from '../../../services/index';

import { UserProfileSectionProfileComponent } from '../user-profile-section-profile/user-profile-section-profile.component';
import { UserProfileSectionRolesComponent } from '../user-profile-section-roles/user-profile-section-roles.component';
import { UserProfileSectionType } from '../../../models/users/models/user-profile-section-type';
import { UserRolesSaveData } from '../../../models/users/models/user-roles-save-data';
import { UserProfileSectionBaseComponent } from '../user-profile-sections/user-profile-section-base.component';

@Component({
    moduleId: module.id,
    selector: 'slx-user-profile',
    templateUrl: 'user-profile.component.html',
    styleUrls: ['user-profile.component.scss'],
    providers: [UserProfileManagementService]
})

export class UserProfileComponent implements OnInit, OnDestroy {

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    public userProfile: UserProfileModel;

    public profileSectionValid: boolean;

    @unsubscribe()
    public orgLevelSubscription: Subscription;

    @unsubscribe()
    public sectionErrorSubscription: Subscription;

    @unsubscribe()
    public userCreatedSubscription: Subscription;

    @unsubscribe()
    public userStateSubscription: Subscription;

    @unsubscribe()
    public routeSubscripion: Subscription;

    @unsubscribe()
    public addRolesModeSubscription: Subscription;

    @unsubscribe()
    public profileLoadedSubscription: Subscription;

    @unsubscribe()
    public onSectionSaveSubscription: Subscription;

    @unsubscribe()
    public sectionDiscardedSubscription: Subscription;

    @unsubscribe()
    public onSectionEnteredEditState: Subscription;

    public addMode: boolean;
    public addRolesMode: boolean;

    public currentOrgLevel: OrgLevel;

    public state: {
        isLoading: boolean;
        infoLocked: boolean;
        rolesLocked: boolean;
        hasEditingSections: boolean;
    };

    @ViewChild('profileSection', {static: true})
    public profileSection: UserProfileSectionProfileComponent;

    @ViewChild('rolesSection', {static: true})
    public rolesSection: UserProfileSectionRolesComponent;

    private m_userId: number = null;

    constructor(private changeService: ChangeManagementService, private management: UserProfileManagementService, private activatedRoute: ActivatedRoute, private router: Router, private location: Location) {
        this.state = {
            isLoading: false,
            infoLocked: false,
            rolesLocked: false,
            hasEditingSections: false
        };
        this.subscribeRouter();
    }

    public ngOnInit(): void {

        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            this.currentOrgLevel = orgLevel;
        });

        this.profileLoadedSubscription = this.management.onProfileLoaded$.subscribe((userProfile: UserProfileModel) => {
            this.userProfile = userProfile;
            this.state.isLoading = false;
        });

        this.userCreatedSubscription = this.management.onUserCreated$.subscribe((userProfile: UserProfileModel) => {
            this.changeService.clearChanges();
            let navigation: UserProfileNavigationService = new UserProfileNavigationService(this.router, this.activatedRoute, this.location);
            navigation.navigateToUserProfile(userProfile.id);
            this.state.isLoading = false;
        });

        this.userStateSubscription = this.management.onStatusChanged$.subscribe((result: number) => {
            this.state.isLoading = false;
        });

        this.addRolesModeSubscription = this.management.onAddRoleModeSwitch$.subscribe((state: boolean) => {
            this.addRolesMode = state;
        });

        this.sectionErrorSubscription = this.management.onSectionServiceError$.subscribe((type: UserProfileSectionType) => {
            switch (type) {
                case UserProfileSectionType.PROFILE:
                    this.state.infoLocked = true;
                    break;
                case UserProfileSectionType.ROLES:
                    this.state.rolesLocked = true;
                    break;
                case UserProfileSectionType.ALL:
                    this.state.isLoading = false;
                    break;
            }
        });

        this.onSectionEnteredEditState = this.management.onSectionEnteredEditState$.subscribe((section: UserProfileSectionBaseComponent) => {
            this.state.hasEditingSections = true;
        });

        this.sectionDiscardedSubscription = this.management.onSectionDiscarded$.subscribe((section: UserProfileSectionBaseComponent) => {
            this.state.hasEditingSections = false;
        });

        this.onSectionSaveSubscription = this.management.onSectionSaved$.subscribe(() => {
            this.state.hasEditingSections = false;
            if (this.m_userId) {
                this.management.getUserRoles(this.m_userId);
            }
        });
    }

    public ngOnDestroy(): void {
        // See #issueWithAOTCompiler
    }

    public activateUser(): void {
        this.state.isLoading = true;
        this.management.activateUser();
    }

    public disableUser(): void {
        this.state.isLoading = true;
        this.management.disableUser();
    }

    public onAddUser(): void {
        this.state.isLoading = true;
        let profile: UserProfileModel = this.profileSection.getSaveData();
        let rolesSectionSaveData: UserRolesSaveData = this.rolesSection.getSaveData();
        let roles: UserRoleRelationModel[] = rolesSectionSaveData.tempRoles;
        this.management.createNewUserOnServer(profile, roles);
    }

    public profileValidChange(valid: boolean): void {
        this.profileSectionValid = valid;
    }

    private subscribeRouter(): void {
        this.routeSubscripion = this.activatedRoute.params.pipe(combineLatest(this.activatedRoute.queryParams)).subscribe(([params, queryParams]) => {
            this.management.changeService = this.changeService;
            if (_.has(params, 'userId')) {
                this.m_userId = params['userId'];
                this.addMode = false;
                this.changeService.setCurrentComponentId('user_profile');
                this.management.getUserProfile(this.m_userId);
                this.management.getUserRoles(this.m_userId);
            } else {
                this.addMode = true;
                this.changeService.setCurrentComponentId('user_create');
                this.management.createNewUser();
            }

            this.state.isLoading = true;
            this.management.loadRoles();
        });
    }
}

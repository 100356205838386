<form novalidate #form="ngForm">
  <div class="col-xs-12 plr30">
    <div class="emp-title-box">
      <span class="theme-header-text">{{employeeFullName}}</span>
    </div>
  </div>
  <div class="col-xs-12 plr30">
    <div class="col-sm-6 zero-padding">
      <slx-input-decorator>
        <slx-date-picker-ngx slx-input
          [(ngModel)]="requestToTerminate.date"
          [minDate]="minDate"
          [required]="true"
          placeholder="Date of Termination"
          name="date"
        ></slx-date-picker-ngx>
        <span errorMessage for="required"></span>
        <span errorMessage for="minDate">Field cannot be less than last approved pay cycle {{employeeShortInfo?.effectiveDate | amDateFormat: appConfig.dateFormat }}</span>
      </slx-input-decorator>
    </div>
  </div>

  <div class="col-xs-12 plr30">  
      <div class="col-sm-6 zero-padding">
        <slx-input-decorator className="slx-form-group slx-no-label slx-no-border">
          <slx-checkbox-input slx-input name="doNotRehire" fieldName="doNotRehire" caption="Do Not Rehire" placeholder="Do Not Rehire"
            [(ngModel)]="requestToTerminate.doNotRehire">
            </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-sm-6 zero-padding">
        <slx-input-decorator className="slx-form-group slx-no-label slx-no-border">
          <slx-checkbox-input slx-input name="voluntary" fieldName="voluntary" caption="Voluntary" placeholder="Voluntary" [(ngModel)]="requestToTerminate.voluntary">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
  </div>
    
  <div class="col-xs-12 plr30">  
    <div class="col-sm-6 zero-padding">
      <slx-input-decorator>
        <slx-dropdown-input slx-input required="true" [lookup]="reasons" name="reason" placeholder="Termination Reason"
          [(ngModel)]="requestToTerminate.reason" (ngModelChange)="onReasonChange($event)">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
  </div>

  <div class="col-xs-12 plr30">
    
    <slx-input-decorator className="slx-form-group slx-form-expand">
      <textarea slx-input type="text" required="true" maxlength="255" rows="5" name="note" placeholder="Termination Notes" [(ngModel)]="requestToTerminate.notes"></textarea>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
  </div>

  <div class="col-xs-12 top-border">  
    <div class="plr15">
      <slx-input-decorator>
        <slx-email-input slx-input        
        slx-email
        name="personalEmail"
        placeholder="Email Address"
        [(ngModel)]="requestToTerminate.email"
        [server]="{ validationName: emailValidation, 
                    parameters: [requestToTerminate.employeeId], 
                    validationAdapter: employeeActivitiesValidatorAdapter, 
                    componentName: 'personalEmail', 
                    form: form }"
        maxlength="255"
        >
        </slx-email-input>
        <span errorMessage for="email"></span>
        <span errorMessage [for]="emailValidation"></span>
      </slx-input-decorator>
    </div>
    <div class="terminate_email_msg plr15">Personal Email Address is highly recommended.<br> Terminated users will not able to use Smartlinx Go without it.</div>
  </div>
</form>

<kendo-datepicker #kendoDatepicker class="{{ cssClass }}"
  [class.active]="isActive"
  name="dateInput"
  [min]="minDate"
  [max]="maxDate"
  [format]="format"
  [readonly]="readonly"
  [disabledDates]="disabledDateArray"
  [value]="currentValue"
  (valueChange)="onValueChanged($event)"
  (open)="onCalendarOpen()"
  (close)="onCalendarClose()"
  (blur)="onBlurInput()"
  [popupSettings]="{popupClass:'slx-date-picker-ngx-popup'}"
>
  <ng-template kendoCalendarCellTemplate let-date>
    <span [ngClass]="getCalendarCellClass(date)">{{ date.getDate() }}</span>
  </ng-template>
</kendo-datepicker>

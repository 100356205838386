import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridComponent } from '@progress/kendo-angular-grid';
import { GroupDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { DetailScreenService } from '../../../scheduler/services/detail-screen/detail-screen-api.service';
import { ISession } from '../../../../app/authentication/store/index';
import { PartnerData } from '../../../../app/common/models/integrations/partner-data.model';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { ConfirmOptions } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import {
  ConfirmDialogComponent,
  KendoGridCustomSelectionHelper,
  KendoGridStateHelper,
  ModalService,
} from '../../../common/index';
import { screenUtils } from '../../../common/utils/index';
import { destroyService, mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { EditableListActionKind } from '../../models/editableList/editable-list-action.model';
import { AgencyMapping, PartnerPosition, PartnerPositionDefinition, PositionModel, RemovePositionRequest } from '../../models/index';
import { PositionsContainer } from '../../models/positions/positions-container';
import { AccessManagementService, AgenciesApiService, PositionsManagementService } from '../../services/index';
import { PartnerConfigApiService } from '../../services/integrations/partner-config-api.service';
import { PartnerConfigManagementService } from '../../services/integrations/partner-config-management.service';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { DetailsPartner } from '../../../scheduler/models/detail-screen/details-partner';

@Component({
  moduleId: module.id,
  selector: 'slx-partner-configure-positions',
  templateUrl: 'configure-partner-positions.component.html',
  styleUrls: ['configure-partner-positions.component.scss'],
  providers: [PositionsManagementService, AccessManagementService, PartnerConfigManagementService],
})
export class ConfigurePartnerPositionsComponent implements OnInit, OnDestroy {
  agencyMap: any;
  public agencyListData: DetailsPartner[] = [];
  public agencyData: DetailsPartner;
  public get container(): PositionsContainer {
    return this.m_container;
  }

  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }

  public onAddItem: () => void;
  public onDeletedItems: () => void;
  public selectedPartner: string;
  private detailScreenService: DetailScreenService;
  public crudHelper: ConfigurationComponentHelper<PositionModel>;
  public gridState: KendoGridStateHelper<PositionModel>;
  public selectionHelper: KendoGridCustomSelectionHelper<PositionModel>;
  public partnerPositionList: PartnerPosition[];
  public partnerPositionDefinitionList: PartnerPositionDefinition[];

  public state: {
    isLoading: boolean;
    configureMode: boolean;
    copyMode: boolean;
  };

  public partners: PartnerData[];
  public partnerPositions: string[];
  public appConfig: IApplicationConfig;
  public essTemplatesEnabled: boolean;
  public groups: GroupDescriptor[] = [{ field: 'positionGroupName' }];
  @destroyService()
  public management: PositionsManagementService;

  private m_container: PositionsContainer;

  @ViewChild('kendoGrid')
  private set grid(value: GridComponent) {
    if (this.crudHelper) this.crudHelper.grid = value;
    this.m_grid = value;
  }
  private get grid(): GridComponent {
    return this.m_grid;
  }
  private m_grid: GridComponent;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private removeSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private savedSubscription: Subscription;
  @unsubscribe()
  private onloadedSubscription: Subscription;

  @unsubscribe()
  private orgChangeSubscription: Subscription;

  @mutableSelect(['session'])
  public user$: Observable<ISession>;
  
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;

  @unsubscribe()
  private userSubscription: Subscription;

  @unsubscribe()
  private orgLevelSubscripion: Subscription;

  public currentOrgLevel: OrgLevel;

  public exportedFileHeader: string;

  public customerId: string;
  public selectedPartnerName: string;
  public selectedPartnerId: string;
  public positionList: any = [];

  public subscriptionId: number = 1;
  public partnerStatus: string;
  private addAction: EventEmitter<boolean> = new EventEmitter();
  constructor(
    management: PositionsManagementService,
    private modalService: ModalService,
    private configAPIService: PartnerConfigApiService,
    private manageService: PartnerConfigManagementService,
    detailScreenService: DetailScreenService,
    private agenciesApiService: AgenciesApiService
  ) {
    this.management = management;
    this.detailScreenService = detailScreenService;
    this.gridState = new KendoGridStateHelper<PositionModel>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

    this.crudHelper = new ConfigurationComponentHelper<PositionModel>();
    this.crudHelper.gridState = this.gridState;
    this.crudHelper.selectionHelper = this.selectionHelper;
    this.crudHelper.management = management;

    // directive intercepted methods (scope bug)
    this.onAddItem = () => {
      this.addAction.emit(true);
    };

    this.onDeletedItems = () => {
      this.crudHelper.deleteSelected();
    };
  }

  public ngOnInit(): void {
    this.state = {
      isLoading: false,
      configureMode: true,
      copyMode: false,
    };

    this.appConfig = appConfig;

    this.userSubscription = this.user$.subscribe((session: ISession) => {
      if (session) {
        this.customerId = session.alias;
      }
    });
    this.orgLevelSubscripion = this.orgLevel$.subscribe((selectedOrgLevel: OrgLevel) => {
      this.orgLevel = selectedOrgLevel;
      // Update agency information
      this.getPartnerAgenciesPositions(this.orgLevel);
    });


    this.savedSubscription = this.management.onItemSaved$.subscribe((item: PositionModel) => {
      this.gridState.editedRecord = null;
      this.gridState.savedEditedRecord = null;
    });

    this.onloadedSubscription = this.management.onLoaded$.subscribe((state) => {
      this.gridState.state.group = this.groups;
    });

    this.stateSubscription = this.management.onStateChanged$.subscribe(
      (state: { isLoading: boolean; configureMode: boolean; copyMode: boolean }) => {
        if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
        if (_.has(state, 'configureMode')) this.state.configureMode = state.configureMode;
        if (_.has(state, 'copyMode')) this.state.copyMode = state.copyMode;
        this.essTemplatesEnabled = this.management.essTemplatesEnabled;
      }
    );

    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: PositionModel[]): void => {
      this.crudHelper.selectionChange(_.first(records), 0);
    });

    this.removeSubscription = this.management.removeItemsCmd$.subscribe((request: RemovePositionRequest) => {
      if (!request.requireReassign) {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Confirmation',
          'Do you want to delete the position?',
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.management.doRemoveItem(request.itemToDelete);
            }
          },
          options
        );
      }
    });

    this.crudHelper.grid = this.grid;
    this.crudHelper.init();
    this.management.init();

    this.orgChangeSubscription = this.management.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.currentOrgLevel = orgLevel;
    });
  }

  getPartnerAgenciesPositions( selectedOrgLevel : OrgLevel) {
    this.agenciesApiService.getAgencies(selectedOrgLevel.id).then((mappings: AgencyMapping[]) => {
        this.agencyMap = mappings.reduce(function(map, obj) {
          map[obj.agency.id] = obj.agency.name;
          return map;
      }, {});
      this.agencyDetails(selectedOrgLevel).then((res) => {
        this.agencyListData =[];
        if ( res == null || res.length == 0 ) {
          return;
        }
        // filter and for active agencies, then call async calls positionmap for
        let filteredAgencies = res.filter((o: DetailsPartner) => o.status =='active' );
        if ( filteredAgencies == null || filteredAgencies.length == 0) {
           return;
        }
        filteredAgencies.forEach((element) => {
          this.agencyData = element;
          this.agencyData.agency_name = this.agencyMap[this.agencyData.agency_id];
          this.agencyListData.push(this.agencyData);
        });
     });

    });
  }

  agencyDetails(orgLevel: OrgLevel): Promise<any> {
    return this.detailScreenService.getAgencyDetails(orgLevel, this.customerId);
  }

  // Must be, see #issueWithAOTCompiler
  public ngOnDestroy(): void {
    if (this.crudHelper) {
      this.crudHelper.destroy();
    }
  }

  public onCopyItems(event: MouseEvent): void {
    event.preventDefault();
    this.management.openCopyItems();
  }

  public switchToConfigure(): void {
    this.management.closeCopyItems();
  }

  public extenalEditorAction(action: EditableListActionKind): void {
    if (action === EditableListActionKind.COMPLETE_EDIT) {
      this.crudHelper.onSaveItem({ dataItem: this.management.editingItem, isNew: this.management.isEditingNewItem });
    } else if (action === EditableListActionKind.CANCEL_EDIT) {
      this.crudHelper.onCancelItem(this.management.editingItem);
    }
  }

  public onMobileRendererEvent(action: EditableListActionKind, item: PositionModel, index: number): void {
    if (action === EditableListActionKind.SELECTION_CHANGE) {
      this.crudHelper.selectionChange(item, index);
    }
  }

  public getGridData(): () => ExcelExportData {
    return (): ExcelExportData => {
      return {
        data: _.cloneDeep(this.gridState.view.data),
        group: this.gridState.state.group,
      };
    };
  }

  public selectionChange(selectedObj: DetailsPartner) {
    this.state.isLoading = true;
    this.state.configureMode = false;
    this.filterPositionList();
    this.selectedPartnerName = selectedObj.agency_name;
    this.selectedPartnerId = selectedObj.partner_id;

    this.manageService.getPartnerPositionsDefinitions(selectedObj.partner_id).then((response: any) => {
      this.partnerPositionDefinitionList = response;
      this.partnerPositions = this.partnerPositionDefinitionList.map((elem) => elem.partnerPositionName).sort();
      this.state.isLoading = false;
      this.state.configureMode = true;
    });
  }

  filterPositionList() {
    let positionsData: any = [];
    this.gridState.view.data.forEach((e) => {
      e.items.forEach((ele) => {
        positionsData.push(ele);
      });
    });
    this.positionList = positionsData;
  }
}

import { Pipe, Injectable, PipeTransform, ElementRef } from '@angular/core';

@Pipe({
  name: 'hasattribute',
  pure: false
})
@Injectable()
export class HasAttributePipe implements PipeTransform {
  public transform(element: ElementRef | HTMLElement, attributeName?: string): any {
    if (!attributeName) return null;
    if (!element) return null;
    if (element instanceof ElementRef) {
      let elementRef: ElementRef = <ElementRef>element;
      if (!elementRef.nativeElement) return null;
      return elementRef.nativeElement.hasAttribute(attributeName);
    } else {
      let elementHTML: HTMLElement = <HTMLElement>element;
      return elementHTML.hasAttribute(attributeName);
    }

  }
}

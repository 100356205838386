import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { appConfig, IApplicationConfig } from '../../../../../app.config';

import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../../core/decorators/index';

import { KendoGridStateHelper } from '../../../../../common/models/index';
import { EmployeeCallLogManagementService } from '../../../services/employee-call-log/employee-call-log-management.service';
import { EmployeeCallLog, EmployeeCallLogRecord } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-call-log-grid',
  templateUrl: 'employee-call-log-grid.component.html',
  styleUrls: ['employee-call-log-grid.component.scss'],
})
export class EmployeeCallLogGridComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<any>;
  @Input()
  public pageSize: number;
  @Input()
  public employeeLink: number;

  public appConfig: IApplicationConfig;

  public typeFilters: any[];
  public errorFilters: any[];

  public isMessageCenterEnabled: boolean;

  private records: EmployeeCallLog;
  private managementService: EmployeeCallLogManagementService;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private resetGridStateubscription: Subscription;
  @unsubscribe()
  private messageCenterEnabledSubscription: Subscription;

  constructor(managementService: EmployeeCallLogManagementService) {
    this.gridState = new KendoGridStateHelper();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'sentTimeFC', dir: 'desc' }];
    this.gridState.state.group = [];

    this.managementService = managementService;

    this.pageSize = 20;
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe((records: EmployeeCallLog) => {
        this.generateFilters(records.records);
        this.records = records;
        this.gridState.state.skip = 0;
        this.refreshGrid();
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: any): void => {
      this.refreshGrid();
    });

    this.resetGridStateubscription = this.managementService.resetGridState$.subscribe((v: any): void => {
      this.gridState.state.skip = 0;
      this.gridState.state.sort = [{ field: 'sentTimeFC', dir: 'desc' }];
      this.gridState.state.group = [];
    });

    this.messageCenterEnabledSubscription = this.managementService.messageCenterEnabled$.subscribe(isMessageCenterEnabled => {
      this.isMessageCenterEnabled = isMessageCenterEnabled;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private generateFilters(records: EmployeeCallLogRecord[]): void {
    this.typeFilters = this.generateFilter(records, 'type');
    this.errorFilters = this.generateFilter(records, 'error');
  }

  private generateFilter(records: EmployeeCallLogRecord[], field: string): any[] {
    let temp: EmployeeCallLogRecord[] = _.uniqBy(records, field);
    temp = _.filter(temp, ( item: any ) => item[field] !== null );
    return _.map(temp, (item: any) => this.generateFilterItem(item[field]));
  }

  private generateFilterItem(value: string): any {
    return {
      text: value,
      value: value
    };
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.state.take = this.pageSize;
    this.gridState.view = { data: null, total: null };
    this.gridState.view = process(this.records.records, this.gridState.state);
  }
}


import { Provider } from '@angular/core';

export * from './wc-common-management.service';
export * from './wc-incident-roster-management.service';
export * from './wc-summary-management.service';
export * from './wc-repeat-injury-management.service';
export * from './wc-osha-management.service';
export * from './wc-incident-tracking-management.service';
export * from './wc-roster-toolbar.service';
export * from './wc-roster-api.service';
export * from './wc-roster-map.service';
export * from './wc-comparisons-management.service';

import { WcCommonManagementService } from './wc-common-management.service';
import { WcIncidentRosterManagementService } from './wc-incident-roster-management.service';
import { WcSummaryManagementService } from './wc-summary-management.service';
import { WcRepeatInjuryManagementService } from './wc-repeat-injury-management.service';
import { WcOshaManagementService } from './wc-osha-management.service';
import { WcIncidentTrackingManagementService } from './wc-incident-tracking-management.service';
import { WcRosterToolbarService } from './wc-roster-toolbar.service';
export * from './wc-create-osha-management.service';
import { WcRosterApiService } from './wc-roster-api.service';
import { WcRosterMapService } from './wc-roster-map.service';
import { WcComparisonsManagementService } from './wc-comparisons-management.service';

export const rosterServices: Provider[] = [
  WcRosterApiService,
  WcRosterMapService
];

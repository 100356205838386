import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { components, exportComponents, DropCoveragePopupComponent, EnrollEmployeeSelectPlanPopupComponent, benefitmanagementEntryComponents } from './components/index';
import { services } from './services/index';
import { directives, exportDirectives } from './directives/index';
import { pipes, exportPipes } from './pipes/index';
import { CoreModule } from '../../core/index';
import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { MetaMapService } from '../../core/services/index';
import { TextMaskModule } from 'angular2-text-mask';
import { MomentModule } from 'angular2-moment';
import { NgxPopperModule } from 'ngx-popper';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { EmployeeModule } from '../employee/index';
import {
  EmployeeSectionsNoteEditComponent,
  EmployeeSectionsPayCycleConfComponent,
  EmployeeSectionsAttendancePointsDefinitionComponent,
  EmployeeSectionsRotationsSettingsComponent,
  RotationModifyDialogComponent,
  EndingBalanceDialogComponent,
  DynamicPolicyDialogComponent,
  EmployeeDependentBenefitsDialogComponent,
  EmployeeDependentDropEnrollmentDialogComponent,
  BenefitPlanSaveNotesDialogComponent,
  BenefitAddAttachmentsDialogComponent,
  BenefitClassDialogComponent,
  EmployeeSectionsWarningsDialogComponent
} from './components/index';
import { EmployeePositionSelectorComponent } from '../employee/components/index';
import { AppSettingsModule } from '../../app-settings/index';
import { EssTemplatesModule } from '../../app-modules/ess-templates/ess-templates.module';
import { LeaveManagementModule } from '../../app-modules/leave-management/leave-management.module';
import { WorkersCompensationModule } from '../../app-modules/workers-compensation/workers-compensation.module';
import { ComponentsLibraryModule } from '../../components-library/components-library.module';
import { ConfirmBenefitChangesDialogComponent } from './components/index';
import { ResetBalanceDialogComponent } from './components/employee-sections-accruals/employee-sections-transactions/reset-balance-dialog/reset-balance-dialog.component';
import { BoolYNPipe } from '../../common/pipes/boolyn';
import { EmployeeSectionsCertificateComponent } from './components/employee-sections-personal/employee-sections-agency-certificate/employee-sections-agency-certificate.component';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        EmployeeModule,
        OrganizationModule,
        CommonAppModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TextMaskModule,
        GridModule,
        MomentModule,
        NgxPopperModule,
        AppSettingsModule,
        EssTemplatesModule,
        LeaveManagementModule,
        WorkersCompensationModule,
        ComponentsLibraryModule,
        EssTemplatesModule,
        LayoutModule,
        PDFModule,
        ExcelModule
    ],
    declarations: [...components, ...directives, ...pipes, EmployeeSectionsCertificateComponent],
    providers: [...services, MetaMapService, BoolYNPipe],
    exports: [
        ...exportPipes,
        ...exportComponents,
        ...exportDirectives
    ]
})
export class EmployeeSectionsModule {
}

import { Component, HostListener, OnDestroy, OnInit, Provider, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { ScheduleRotationsManagementService } from '../../services/scheduled-rotation-template/schedule-rotations-management.service';
import { DeviceDetectorService, ModalService } from '../../../common/services/index';
import { DateRange } from '../../../core/models/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { OpenPositionsControlApiService } from '../../services';
import { Subscription ,  Observable } from 'rxjs';
import { OrgLevel } from '../../../state-model/models/index';
import * as _ from 'lodash';
import { OpenConfigOrgLevelType, OpenShiftPositions, OpenShiftPostionsDetails, OpenShiftPostionsOrgDetails } from '../../models';
import { IRangeDates } from '../../../common/models/range-dates';
import { DialogModeSize, DialogOptions, DialogOptions2 } from '../../../common';
import { PositionGroupSlideOutComponent } from './position-group-slide-out/position-group-slide-out.component';

@Component({
  moduleId: module.id,
  selector: 'slx-open-position-control',
  templateUrl: 'open-position-control.component.html',
  styleUrls: ['open-position-control.component.scss']
})
export class OpenPostionControlComponent implements OnInit, OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public currentOrgLevel: OrgLevel;
  public screenWidth: number;

  public openShiftPositions: OpenShiftPositions = new OpenShiftPositions();
  selectedDate = moment().toDate();
  dateFrom = moment().toDate();
  public startDate = moment().startOf('week').toDate();
  public actualEndDate = moment(this.startDate).add(4, 'week').toDate();
  public endDate = moment(this.actualEndDate).subtract(1, 'day').toDate();
  public dateRange: DateRange = new DateRange(null, null);
  isLoading: boolean = false;
  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }
  @unsubscribe()
  private orglevelSubscription: Subscription;

  constructor(public manService: ScheduleRotationsManagementService,
    private devDetector: DeviceDetectorService,
    private modalService: ModalService,
    private openPositionsControlApiService: OpenPositionsControlApiService) {
    this.manService.getScheduleCycles();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
  }
  public ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.orglevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.currentOrgLevel = orgLevel;
      if (_.isDate(this.startDate) && _.isDate(this.endDate)) {
        this.loadOpenPositions();
      }
    });
  }

  openPositionGroupSlideOut(orgName: string, departmentDetails: OpenShiftPostionsOrgDetails) {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = this.isMobile ? 370 : 978;
    dialogOptions.showCloseButton = true;
    dialogOptions.className = 'slx-opc-dialog-custom';
    dialogOptions.isMobile = this.isMobile;
    dialogOptions.headerClassName = dialogOptions.isMobile ? 'slx-opc-dialog-header': '';
    let deptDetails: OpenConfigOrgLevelType = new OpenConfigOrgLevelType();
    deptDetails.orgLevelId = departmentDetails.orgLevelId;
    deptDetails.orgLevelName = departmentDetails.departmentName;
    deptDetails.orgLevelType = 'Department';
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ModalService,
        useValue: this.modalService
      },
      {
        provide: OpenConfigOrgLevelType,
        useValue: deptDetails
      }
    ];
    let dialog: PositionGroupSlideOutComponent = this.modalService.globalAnchor.openDialog(
      PositionGroupSlideOutComponent, orgName + ': ' + departmentDetails.departmentName, dialogOptions, resolvedProviders,
      (result: boolean, uniqueId?: string) => {
      });
    dialog.orgLevelId = departmentDetails.orgLevelId;
    dialog.startDate = this.startDate;
    dialog.endDate = this.endDate;
    if (dialog.initialized) {
      dialog.LoadData();
    }
  }

  public ngOnDestroy(): void { }

  public onDatesChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.loadOpenPositions();
  }

  public get dateRangeWidth(): number {
    return this.isDesktop ? 325 : 246;
  }

  private loadOpenPositions(): void {
    this.isLoading = true;
    this.openPositionsControlApiService.getOpenPositions(this.currentOrgLevel.id, this.startDate, this.endDate)
      .then((openShiftPositions: OpenShiftPositions) => {
        this.openShiftPositions = openShiftPositions;
        this.isLoading = false;
      });
  }

  public get isMobile(){  
    return this.screenWidth<768 ? true : false
  }
}


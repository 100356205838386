<slx-spinner [show]="isLoading" novalidate>
    <slx-generic-grid [genericGridConfig]="gridConfig" (selectionChanged)="onSelectionChanged($event)"
                      gridKey="OVERTIME_APPROVALS" [columnCustomCssClass]="pdfColorClass">
        <form #form="ngForm" class="overtime-form overtime-fix">

          <slx-date-range-ngx
            name="startEndDate"
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            [startDate]="startDate"
            [endDate]="endDate"
            (rangeDateChanged)="onFilterDateChanged($event)"
          ></slx-date-range-ngx>

          <div class="spacer"></div>

          <div *ngIf="someOvertimesAreLocked">Payroll cycle or Timecard is in locked state</div>


          <div class="overtime-form-buttons overtime-buttons-fix">
            <button type="button" class="theme-button-apply main-action-button" (click)="onUnapprove()" [disabled]="!canMakeUnapproveAction">Un-Approve Overtime</button>
            <button type="button" class="theme-button-apply main-action-button" (click)="onApprove()" [disabled]="!canMakeApproveAction">Approve Overtime</button>
          </div>
            <div *ngIf="!isMobile" class="overtime-buttons-fix">
              <slx-actions-list>
                <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">
                  Actions <span class="caret"></span>
                </slx-actions-button>
                <popper-content #popperContent>
                  <slx-actions-list-item (onClick)="onExportToExcel()">Export to Excel</slx-actions-list-item>
                  <slx-actions-list-item (onClick)="onExportToPdf()">Export to PDF</slx-actions-list-item>
                </popper-content>
              </slx-actions-list>
            </div>
        </form>
    </slx-generic-grid>
</slx-spinner>

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TelepunchLocationsManagementService } from '../../../services/telepunch-locations/telepunch-locations-management.service';
import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { TelepunchLocation, TelepunchLocationsContainer } from '../../../models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'slx-telepunch-locations-mobile',
    templateUrl: 'telepunch-locations-mobile.component.html',
    styleUrls: ['telepunch-locations-mobile.component.scss']
})

export class TelepunchLocationsMobileComponent implements OnInit {

    @ViewChild('templateForm', { static: true })
    private mainForm: NgForm;

    public appConfig: IApplicationConfig;
    public collapsed: StringMap<boolean>;

    @unsubscribe()
    private dataLoadedSubscription: Subscription;

    @unsubscribe()
    private itemSavedSubscription: Subscription;

    @unsubscribe()
    private addItemClickedSubscription: Subscription;

    @unsubscribe()
    private mainFormSubscription: Subscription;

    private originalData: TelepunchLocationsContainer;

    constructor(public management: TelepunchLocationsManagementService) {

    }

    public ngOnInit(): void {
        this.appConfig = appConfig;
        this.collapsed = {};

        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
            if (this.mainForm.dirty) {
              this.management.markAsDirty();
            }
          });

        this.dataLoadedSubscription = this.management.onLoaded$.subscribe((container: TelepunchLocationsContainer) => {
            this.originalData = _.cloneDeep(container);
        });

        this.itemSavedSubscription = this.management.onItemSaved$.subscribe((location: TelepunchLocation) => {
            let orig: TelepunchLocation = _.find(this.originalData.records, tp => tp.id === location.id);
            this.copyFields(location, orig);
        });

        this.addItemClickedSubscription = this.management.addItemClicked$.subscribe((value: any) => {
            this.onChangedState(this.management.createEmptyItem());
        });
    }

    public getCurrentState(...indexes: number[]): boolean {
        const key: string = _.map(indexes, (index: number) => String(index)).join('');
        return _.isBoolean(this.collapsed[key]) ? this.collapsed[key] : (this.collapsed[key] = true);
    }

    public onChangedState(...indexes: number[]): void {
        const key: string = _.map(indexes, (index: number) => String(index)).join('');
        this.collapsed[key] = !this.collapsed[key];
    }

    public save(loc: TelepunchLocation): void {
        this.management.onSaveItem({ dataItem: loc, isNew: !loc.id });
    }

    public cancel(loc: TelepunchLocation): void {
        if (loc.id > 0) {
            let orig: TelepunchLocation = _.find(this.originalData.records, tp => tp.id === loc.id);
            this.copyFields(orig, loc);
        } else {
            this.management.removeEmptyItem(loc);
        }
    }

    public remove(loc: TelepunchLocation): void {
        if (loc.id > 0) {
            this.management.onRemoveItem(loc);
        } else {
            this.management.removeEmptyItem(loc);
        }
    }

    private copyFields(src: TelepunchLocation, dest: TelepunchLocation): void {
        dest.authorized = src.authorized;
        dest.phone = src.phone;
        dest.locationName = src.locationName;
    }
}

import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import * as _ from 'lodash';
import * as moment from 'moment';


import { ScheduleCycleMessages, ScheduleCycleSummaryViewAction } from '../../../models/index';
import { Observable ,  Subscription } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';


@Component({
  moduleId: module.id,
  selector: 'slx-schedule-cycle-summary',
  templateUrl: 'schedule-cycle-summary.component.html',
  styleUrls: ['schedule-cycle-summary.component.scss']
})
export class ScheduleCycleSummaryComponent {
  @Input()
  public summary: ScheduleCycleMessages;
  @Output()
  public actionEvent: EventEmitter<ScheduleCycleSummaryViewAction>;

  public appConfig: IApplicationConfig;

  constructor() {
    this.appConfig = appConfig;
    this.actionEvent = new EventEmitter();
  }

  public onAction(action: ScheduleCycleSummaryViewAction): void {
    this.actionEvent.emit(action);
  }
}

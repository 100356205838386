import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';

import { Subscription } from 'rxjs';

import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';

import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitDetailsTier, BenefitDetailsOption, BenefitDetailsFormula } from '../../../../models/index';

@Component({
  selector: 'benefit-option-details-formula-with-option-grid',
  templateUrl: 'benefit-option-details-formula-with-option-grid.component.html',
  styleUrls: ['benefit-option-details-formula-with-option-grid.component.scss']
})
export class BenefitOptionDetailsFormulaWithOptionGridComponent implements OnInit, OnDestroy {
  public formulaGridState: KendoGridStateHelper<BenefitDetailsFormula>;
  public gridState: KendoGridStateHelper<BenefitDetailsOption>;
  public appConfig: IApplicationConfig;
  public data: BenefitDetailsTier[] = [];
  public isLoading: boolean = true;
  public isShowingExpired: boolean = false;
  public employeeContribution: number | string = 0;
  public employerContribution: number | string = 0;
  public coverage: any = 0;

  @Input()
  public tierDetails: BenefitDetailsTier;
  @Input()
  public benefitOptionData: BenefitDetailsOption[];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
  ) {
    this.gridState = new KendoGridStateHelper<BenefitDetailsOption>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.formulaGridState = new KendoGridStateHelper<BenefitDetailsFormula>();
    this.formulaGridState.view = null;
    this.formulaGridState.state.skip = 0;
    this.appConfig = appConfig;
  }

  public ngOnInit() {
    this.data = [this.tierDetails];
    this.populateEmployeeContribution();
    this.populateEmployerContribution();
    this.populateCoverage();
    this.refreshGrid();

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());
  }

  public populateEmployeeContribution() {
    if(this.tierDetails && this.tierDetails.empFormula) {
      if (this.tierDetails.empFormula.isFixedCalcType) {
        this.employeeContribution = this.tierDetails.empFormula.fixedAmount;
      } else if (this.tierDetails.empFormula.isFormulaCalcType) {
        this.employeeContribution = 0;
      } else {
        this.employeeContribution = 0;
      }
    }
  }

  public populateEmployerContribution() {
    if(this.tierDetails && this.tierDetails.erFormula) {
      if (this.tierDetails.erFormula.isFixedCalcType) {
        this.employerContribution = this.tierDetails.erFormula.fixedAmount;
      } else if (this.tierDetails.erFormula.isFormulaCalcType) {
        this.employerContribution = 0;
      } else {
        this.employerContribution = 0;
      }
    }
  }

  public populateCoverage() {
    if(this.tierDetails && this.tierDetails.coverageFormula) {
      if (this.tierDetails.coverageFormula.isFixedCalcType) {
        this.coverage = this.tierDetails.coverageFormula.fixedAmount;
      } else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
        this.coverage = 0;
      } else {
        this.coverage = 0;
      }
    }
  }

  public ngOnDestroy(): void {

  }

  private refreshGrid(): void {
    this.isLoading = false;
    if (this.benefitOptionData) {
      this.gridState.view = process(this.benefitOptionData, this.gridState.state);
    } else {
      this.gridState.view = null;
    }

    if (this.data) {
      this.formulaGridState.view = process(this.data, this.formulaGridState.state);
    } else {
      this.formulaGridState.view = null;
    }
  }

}

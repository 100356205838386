import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';
import { RouteData } from '../../models/index';
import { Assert } from '../../../framework/index';

import { IBreadcrumbItemConfig, BreadcrumbItem, FrendlyNameCallback, TransformRouteCallback, TransformIconCallback, IsHiddenCallback } from '../../models/index';

type parseResult = { parseOk: boolean, resultUrl: string, message?: string };

@Injectable()
export class BreadcrumbStateService {

  public get breadcrumbs(): BreadcrumbItem[] {
    return this.m_breadcrumbs;
  }
  public get icon(): string | Promise<string> {
    return this.m_icon;
  }

  public changeNotify$: Subject<BreadcrumbItem[]>;
  public clickOnBreadcrumb$: Subject<BreadcrumbItem>;

  private config: IBreadcrumbItemConfig;
  private currentState: NumberMap<BreadcrumbItem>;
  private routeStack: string[];
  private lastRoutesPath: BreadcrumbItem[];
  private m_breadcrumbs: BreadcrumbItem[];
  private m_icon: string | Promise<string>;
  private ignoreUrlParmeters = ['orgLevelId'];

  private m_routeStackToRestore: string[];
  private m_lastRoutesPathToRestore: BreadcrumbItem[];

  constructor() {
    this.changeNotify$ = new Subject();
    this.clickOnBreadcrumb$ = new Subject();
    this.currentState = {};
  }
  public configure(config: IBreadcrumbItemConfig): void {
    this.config = config;
    this.routeStack = [];
  }

  public addNameCallback(id: string, callback: FrendlyNameCallback): void {
    let result: IBreadcrumbItemConfig[] = this.findConfigById(id);
    _.forEach(result, (conf: IBreadcrumbItemConfig) => {
      conf.nameCallback = callback;
    });
  }

  public addIconCallback(id: string, callback: TransformIconCallback): void {
    let result: IBreadcrumbItemConfig[] = this.findConfigById(id);
    _.forEach(result, (conf: IBreadcrumbItemConfig) => {
      conf.iconCallback = callback;
    });
  }

  public addIsHiddenCallback(id: string, callback: IsHiddenCallback): void {
    let result: IBreadcrumbItemConfig[] = this.findConfigById(id);
    _.forEach(result, (conf: IBreadcrumbItemConfig) => {
      conf.isHidden = callback;
    });
  }

  public addTransformCallback(id: string, callback: TransformRouteCallback): void {
    let result: IBreadcrumbItemConfig[] = this.findConfigById(id);
    _.forEach(result, (conf: IBreadcrumbItemConfig) => {
      conf.transformCallback = callback;
    });
  }

  public findConfigById(id: string): IBreadcrumbItemConfig[] {
    Assert.isNotNull(this.config, 'BreadcrumbStateService service not configured');
    let result: IBreadcrumbItemConfig[] = [];
    if (this.config.id === id) {
      result.push(this.config);
    }
    let nestedResult: IBreadcrumbItemConfig[] = this.findConfigByIdInChilds(id, this.config.childs, true);
    result.push(...nestedResult);
    return result;
  }

  public clickOnBreadcrumb(item: BreadcrumbItem): void {
    this.clickOnBreadcrumb$.next(item);
  }

  public subscribeToClickOnBreadcrumb(callback: (i: BreadcrumbItem) => void): Subscription {
    if (!_.isFunction(callback)) throw new TypeError('Cannot subscribe to "clickOnBreadcrumb$", "callback" is not a function');
    return this.clickOnBreadcrumb$.subscribe(callback);
  }

  public restoreLastRoute(): void {
    if (this.m_lastRoutesPathToRestore) {
      this.lastRoutesPath = this.m_lastRoutesPathToRestore;
    }
    if (this.m_routeStackToRestore) {
      this.routeStack = this.m_routeStackToRestore;
    }
    this.resetLastRouteBackup();
  }

  public resetLastRouteBackup(): void {
    this.m_lastRoutesPathToRestore = null;
    this.m_routeStackToRestore = null;
  }

  public popPrevRoute(): string | string[] {
    this.m_lastRoutesPathToRestore = _.map(this.lastRoutesPath);
    this.m_routeStackToRestore = _.map(this.routeStack);

    if (this.routeStack.length > 1) {
      this.routeStack.pop();
      return this.routeStack.pop();
    }
    if (this.lastRoutesPath.length > 1) {
      let lastRoute = this.lastRoutesPath[this.lastRoutesPath.length - 2];
      return lastRoute.linkPath;
    }
    return null;
  }

  public removePrevRoute(): void {
    if (this.routeStack.length > 1) {
      this.routeStack.pop();
    }
  }

  public async buildBreadcrumbs(eventUrl: string): Promise<BreadcrumbItem[]> {
    let currentUrl: string;
    let state: NumberMap<BreadcrumbItem> = {};
    let result: parseResult;
    let urlsArray: string[] = eventUrl.slice(1).split('/');
    result = this.processUrl(state, '', urlsArray, 0, 0, null);
    Assert.isFalse(!result.parseOk, result.message);
    this.currentState = state;
    let items: BreadcrumbItem[] = _.values<BreadcrumbItem>(state);
    this.resolveIcon(items);
    await this.resolveMandatoryPromises(items);
    let removeIgnores: BreadcrumbItem[] = _.filter(items, (item: BreadcrumbItem) => {
      return !item.isHidden;
    });
    if (removeIgnores.length > 1) {
      removeIgnores = _.slice(removeIgnores, 1);
    }
    let temp = [];
    _.forEach(removeIgnores, (item: BreadcrumbItem) => {
      if (item.config.prefix) {
        const prefix = this.createItem(<any>item.linkPath, item.config.prefix.id, item.config.prefix , item.queryParams);
        temp.push(prefix);
      }
      temp.push(item);
    });
    removeIgnores = temp;
    if (!this.equalsConfigPath(items, this.lastRoutesPath)) {
      if (this.isRedirectConfigPath(items, this.lastRoutesPath)) {
        this.removePrevRoute();
      }
      this.routeStack.push(eventUrl);
    }
    this.lastRoutesPath = items;
    this.m_breadcrumbs = removeIgnores;
    setTimeout(() => this.changeNotify$.next(this.m_breadcrumbs), 1);
    return removeIgnores;
  }

  private resolveIcon(urls: BreadcrumbItem[]): void {
    let icon: string | Promise<string>;
    _.forEach(urls, (url: BreadcrumbItem) => {
      if (url.icon) {
        icon = url.icon;
      }
    });
    this.m_icon = icon;
  }

  private async resolveMandatoryPromises(items: BreadcrumbItem[]): Promise<any> {
    const itemsWithIsHiddenPromises: BreadcrumbItem[] = _.filter(items, (item: BreadcrumbItem) => {
      return item.isHidden instanceof Promise;
    });
    const isHiddenPromises: Promise<boolean>[] = _.map(itemsWithIsHiddenPromises, (item: BreadcrumbItem) => {
      return (<any>item.isHidden).then((value: boolean) => item.isHidden = value);
    });
    return Promise.all(isHiddenPromises);
  }

  private processUrl(state: NumberMap<BreadcrumbItem>, currentUrl: string, urlsArray: string[], srcIndex: number, dstIndex: number, currentConfNode: IBreadcrumbItemConfig): parseResult {
    let result: parseResult;
    let routeConfig: IBreadcrumbItemConfig;
    let next: { url: string, queryParams: StringMap<any> } = this.getNextUrl(urlsArray, srcIndex);
    //input route path in ended
    if (!next) {
      return { parseOk: true, resultUrl: currentUrl };
    }
    let queryParams: StringMap<any> = next.queryParams;
    let itemUrl: string = next.url;
    let url: string = `${currentUrl}/${itemUrl}`;
    let currentRouteItem: BreadcrumbItem = this.currentState[dstIndex];

    //current route is ended
    if (!currentRouteItem) {
      routeConfig = this.findNextConfig(itemUrl, currentConfNode);
      if (!routeConfig) {
        return { parseOk: false, resultUrl: url, message: `id ${itemUrl} not found in childs ${url}, check br route config` };
      }
      if (routeConfig.mustRefreshEveryTime || routeConfig.id !== itemUrl) {
        //chek value === exist route, dynamic cannot
        let exist: IBreadcrumbItemConfig = _.first(this.findConfigById(itemUrl));
        if (exist && exist.mustRefreshEveryTime) {
          return { parseOk: false, resultUrl: url, message: `id ${itemUrl} cannot be existing config id and be isDynamic value ${url}, check br route config` };
        } else if (exist && routeConfig.id !== itemUrl) {
          return { parseOk: false, resultUrl: url, message: `id ${itemUrl} cannot be existing config id and it's NOT isDynamic value ${url}, check br route config` };
        }
      }
      state[dstIndex] = this.createItem(url, itemUrl, routeConfig, queryParams);
      return this.processUrl(state, url, urlsArray, srcIndex + 1, dstIndex + 1, routeConfig);
    }

    //already in path
    if (currentRouteItem.config.id === itemUrl) {
      if (currentRouteItem.config.mustRefreshEveryTime) {
        state[dstIndex] = this.createItem(url, itemUrl, currentRouteItem.config, queryParams);
      } else {
        state[dstIndex] = currentRouteItem;
        this.assignQueryParams(currentRouteItem, next.queryParams, currentRouteItem.config);
      }
      return this.processUrl(state, url, urlsArray, srcIndex + 1, dstIndex + 1, currentRouteItem.config);
    }

    //changed path
    if (currentRouteItem.config.canVirtual) {
      let res: parseResult = this.processUrl(state, currentUrl, urlsArray, srcIndex, dstIndex + 1, currentRouteItem.config);
      if (res.parseOk) {
        state[srcIndex] = currentRouteItem;
        this.assignQueryParams(currentRouteItem, next.queryParams, currentRouteItem.config);
        return res;
      }
    }

    //already in path isDynamic
    if (currentRouteItem.config.isDynamic) {
      //chek value === exist route, dynamic cannot
      let exist: IBreadcrumbItemConfig[] = this.findConfigById(itemUrl);
      if (!exist || exist.length === 0) {
        if (currentRouteItem.config.mustRefreshEveryTime) {
          state[dstIndex] = this.createItem(url, itemUrl, currentRouteItem.config, queryParams);
        } else {
          state[dstIndex] = currentRouteItem;
          this.assignQueryParams(currentRouteItem, next.queryParams, currentRouteItem.config);
        }
        return this.processUrl(state, url, urlsArray, srcIndex + 1, dstIndex + 1, currentRouteItem.config);
      }
      return { parseOk: false, resultUrl: url, message: `id ${itemUrl} cannot be existing config id and be isDynamic value ${url}, check br route config` };
    }

    //not found in virtual, creation of new path
    this.clearCurrentState(srcIndex);
    routeConfig = this.findNextConfig(itemUrl, currentConfNode);
    if (!routeConfig) {
      return { parseOk: false, resultUrl: url, message: `id ${itemUrl} not found in childs ${url}, check br route config` };
    }
    state[dstIndex] = this.createItem(url, itemUrl, routeConfig, queryParams);
    return this.processUrl(state, url, urlsArray, srcIndex + 1, dstIndex + 1, routeConfig);
  }

  private getNextUrl(urlsArray: string[], index: number): { url: string, queryParams: StringMap<any> } {
    if (index >= urlsArray.length) {
      return null;
    }
    let url: string = urlsArray[index];
    let val: string[] = url.split('?');
    let queryParams: StringMap<any>;
    if (val.length > 1) {
      queryParams = this.parseQuery(val[1]);
    }
    return { url: val[0], queryParams: queryParams };
  }

  private parseQuery(queryString: string): StringMap<any> {
    let query: StringMap<any> = {};
    let pairs = queryString.split('&');
    _.forEach(pairs, (pair: string) => {
      let p = pair.split('=');
      query[decodeURIComponent(p[0])] = decodeURIComponent(p[1] || '');
    });
    return query;
  }

  private findConfigByIdInChilds(id: string, childs: IBreadcrumbItemConfig[], checkNested: boolean): IBreadcrumbItemConfig[] {
    let result: IBreadcrumbItemConfig[] = [];
    if (!childs) {
      return null;
    }
    _.forEach(childs, (child: IBreadcrumbItemConfig) => {
      if (child.id === id) {
        result.push(child);
        return;
      }
      if (checkNested) {
        let nestedResult: IBreadcrumbItemConfig[] = this.findConfigByIdInChilds(id, child.childs, true);
        if (nestedResult) {
          result.push(...nestedResult);
        }
      }
    });
    return result;
  }

  private findNextConfig(id: string, root: IBreadcrumbItemConfig): IBreadcrumbItemConfig {
    if (root === null) {
      return this.config;
    }
    if (!root) {
      return null;
    }
    let result: IBreadcrumbItemConfig = null;
    if (!root.childs) {
      return null;
    }
    _.forEach(root.childs, (child: IBreadcrumbItemConfig) => {
      if (child.id === id || child.isDynamic) {
        result = child;
        return;
      }
    });
    return result;
  }

  private createItem(currentUrl: string, itemUrl: string, currentNode: IBreadcrumbItemConfig, queryParams: StringMap<any>): BreadcrumbItem {
    let routeItem: BreadcrumbItem = new BreadcrumbItem();
    routeItem.config = currentNode;
    routeItem.displayType = currentNode.displayType ? currentNode.displayType : 'link';
    routeItem.isNotLink = currentNode.isNotLink;
    this.assignCallbacks(routeItem, currentUrl, itemUrl, currentNode);
    this.assignQueryParams(routeItem, queryParams, currentNode);
    return routeItem;
  }

  private assignQueryParams(routeItem: BreadcrumbItem, queryParams: StringMap<any>, currentNode: IBreadcrumbItemConfig): void {
    if (queryParams && _.isNil(currentNode.transformCallback)) {
      routeItem.queryParamsLink = _.omit(queryParams, this.ignoreUrlParmeters);
      routeItem.queryParams = queryParams
    }
  }

  private assignCallbacks(routeItem: BreadcrumbItem, currentUrl: string, itemUrl: string, currentNode: IBreadcrumbItemConfig): void {
    if (currentNode.nameCallback) {
      routeItem.title = currentNode.nameCallback(currentUrl, itemUrl);
      routeItem.isAsync = (routeItem.title instanceof Promise);
    } else {
      routeItem.title = currentNode.title;
    }
    if (currentNode.iconCallback) {
      routeItem.icon = currentNode.iconCallback(currentUrl, itemUrl);
    } else {
      routeItem.icon = currentNode.icon;
    }

    if (_.isFunction(currentNode.isHidden)) {
      routeItem.isHidden = currentNode.isHidden(currentUrl, itemUrl);
    } else {
      routeItem.isHidden = currentNode.isHidden;
    }

    if (currentNode.transformCallback) {
      let data: RouteData = currentNode.transformCallback(currentUrl);
      routeItem.linkPath = data.path;
      routeItem.queryParams = data.queryParams;
      routeItem.queryParamsLink = data.queryParams;
    } else {
      routeItem.linkPath = currentUrl;
    }
  }

  private clearCurrentState(fromIndex: number): void {
    let times: number = _.keys(this.currentState).length - fromIndex;
    _.times(times, (num: number) => {
      this.currentState[num + fromIndex] = undefined;
    });
  }

  private equalsConfigPath(path1: BreadcrumbItem[], path2: BreadcrumbItem[]): boolean {
    if (!path1 && !path2) {
      return true;
    }
    if ((!path1 && path2) || (path1 && !path2)) {
      return false;
    }
    if (path1.length !== path2.length) {
      return false;
    }
    let res = true;
    _.times(path1.length, (num: number) => {
      const item1 = path1[num];
      const item2 = path2[num];
      if (item1.config.id !== item2.config.id) {
        res = false;
      }
      if (item1.queryParams && !item2.queryParams) {
        res = false;
      } else if (!item1.queryParams && item2.queryParams) {
        res = false;
      } else if (item1.queryParams && item2.queryParams) {
        _.each(_.keys(item2.queryParams), (key: string) => {
          if (_.has(item1.queryParams, key)) {
            res = false;
          } else if (_.get(item1.queryParams, key) !== _.get(item2.queryParams, key)) {
            res = false;
          }
        });
      }
    });
    return res;
  }

  private isRedirectConfigPath(path1: BreadcrumbItem[], path2: BreadcrumbItem[]): boolean {
    if (!path1 && !path2) {
      return false;
    }
    if ((!path1 && path2) || (path1 && !path2)) {
      return false;
    }
    if (path2.length + 1 !== path1.length) {
      return false;
    }
    let lastItem = path1[path2.length];
    if (!lastItem.config.canUseForRedirect) {
      return false;
    }
    let res = true;
    _.times(path2.length, (num: number) => {
      const item1 = path1[num];
      const item2 = path2[num];
      if (item1.config.id !== item2.config.id) {
        res = false;
      }
    });
    return res;
  }
}

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Observable ,  Subscription } from 'rxjs';
import { OrgLevel } from '../../../../../state-model/models/index';
import { ExportDataManagementService } from '../../../services';
import { ExportDataConfigurationParameter } from '../../../models';
import { LookupService } from '../../../../../organization/services/index';
import { Lookup, LookupType, LookupDefinition } from '../../../../../organization/models/index';
import { Months } from '../../../../../common';
import { NgModel } from '@angular/forms';
import { VirtualizationSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  moduleId: module.id,
  selector: 'slx-export-data-parameter',
  templateUrl: 'export-data-parameter.component.html',
  styleUrls: ['export-data-parameter.component.scss']
})
export class ExportDataParameterComponent implements OnInit {

  public get orgLevel(): OrgLevel {
    return this.exportDataManagementService.orgLevel;
  }

  @Input()
  public parameter: ExportDataConfigurationParameter;

  @Input()
  public submitted: boolean;

  @Input()
  public disabled: boolean;

  public virtual: VirtualizationSettings = {
    itemHeight: 28
  };

  public options: any[];
  private lookup: Lookup;

  public fieldValue: any = null;

  public get dataTypeName(): string {
    if (this.parameter && this.parameter.dataType)
      return this.parameter.dataType.type;

    return undefined;
  }

  public get lookupValueField(): string {
    if (this.lookup !== undefined) {
      return this.lookup.valueField;
    }

    return 'id';
  }

  public get lookupTitleField(): string {
    if (this.lookup !== undefined) {
      return this.lookup.titleField;
    }

    return 'name';
  }

  constructor(private lookupService: LookupService,
              private exportDataManagementService: ExportDataManagementService) { }

  ngOnInit() {
    if (this.parameter.dataType.type === 'lookup') {
      this.updateLookups();
    }
  }

  private updateLookups(): void {
    switch(this.parameter.lookupType) {
      case 'paycycles': this.parameter.lookupType = 'payCycles'; break;
      case 'getobjacc': this.parameter.lookupType = 'getObjAcc'; break;
    }

    if (this.parameter.sourceType === 'api') {
      let lookupPromise: Promise<Lookup> = this.lookupService.getLookup({
        lookupType: this.parameter.lookupType as LookupDefinition,
        orgLevelId: this.orgLevel.id,
        employeeId: undefined
      });

      lookupPromise.then((lookup: Lookup) => {
        if (this.parameter.lookupType = 'payCycles') {
          this.options = _.orderBy(_.map(_.groupBy(lookup.items, x => [x.startDate, x.endDate].join()), x => _.first(x)), ['endDate', 'startDate'], ['desc', 'desc']);
        } else {
          this.options = lookup.items;
        }
        this.lookup = lookup;
      });
    } else if (this.parameter.sourceType === 'internal') {
      switch(this.parameter.lookupType.toLocaleLowerCase()) {
        case 'year': this.options = this.getYearLookup(); break;
        case 'quarter': this.options = this.getQuarterLookup(); break;
        case 'month': this.options = this.getMonthLookup(); break;
        case 'monthyears': this.options = this.getMonthYearLookup(); break;
        default: break;
      }
    } else {
      if (this.parameter.source) {
        const items = JSON.parse(this.parameter.source) as string[];
        this.options = _.map(items, x => { return { id: x, name: x }});
      }
    }
  }

  private getYearLookup(): any[] {
    const year = moment().year();
    const options: any[] = [];
    for(let idx = 0; idx < 5; idx++) {
      options.push({id: year - idx, name: year - idx});
    }

    return _.orderBy(options);
  }

  private getQuarterLookup(): any[] {
    const options: any[] = [];
    for(let idx = 1; idx <= 4; idx++) {
      options.push({id: idx, name: idx});
    }

    return _.orderBy(options);
  }

  private getMonthYearLookup(): any[] {
    const options: any[] = [];
    const year = moment().year();
    const month = moment().month();
    let val: string;
    for(let idx1 = 0; idx1 < 5; idx1++) {
      for(let idx2 = idx1 === 0 ? month : 11; idx2 >= 0; idx2--) {
        val = `${Months[idx2].shortName} - ${year-idx1}`;
        options.push({id: val, name: val});
      }
    }

    return options;
  }

  private getMonthLookup(): any[] {
    return Months;
  }

  public onChangeValue(parameter: NgModel) {
    if (this.parameter.dataType.type === 'lookup') {
      this.parameter.value = parameter.value ? parameter.value[this.lookupValueField] : '';
    } else {
      this.parameter.value = parameter.value;
    }
    this.parameter.isValid = parameter.valid;
  }
}

export * from './logi-api.service';
export * from './logi-messaging.service';

import { LogiApiService  } from './logi-api.service';
import { LogiMessagingServices  } from './logi-messaging.service';

export const services: any[] = [
  LogiApiService,
  LogiMessagingServices
];

<kendo-grid #grid kendoGridFixFreezeHeader slxKendoGridMobileManager class="slx-full-height slx-blue-grid slx-no-border"
  slxKendoGridState="PJB_EXPORT_ERROR_DATA" (stateRestored)="gridState.dataStateChange($event)"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [slxGroupable]="{ showFooter: true }"
  [slxGroupableMobile]="false"
  [group]="gridState.state.group"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [pageSize]="pageSize"
  [skip]="gridState.state.skip"
  [pageable]="true"
  (pageChange)="pageChanged($event)"
  (sortChange)="sortChange($event)"
  (dataStateChange)="gridState.dataStateChange($event)"
>
  <!--(filterChange)="filterChange($event)"-->

  <kendo-grid-column media="xs" title="PBJ Log">
    <ng-template kendoGridCellTemplate let-dataItem>
      <dl>
        <dt>Error Id</dt>
        <dd>{{ dataItem?.errorId }}</dd>

        <dt>Entry Type</dt>
        <dd>{{ dataItem?.entryType }}</dd>

        <dt>Employee Master ID</dt>
        <dd>{{ dataItem?.employeeMasterId }}</dd>

        <dt>Section</dt>
        <dd>{{ dataItem?.section }}</dd>

        <dt>Field</dt>
        <dd>{{ dataItem?.field }}</dd>

        <dt>Error Message</dt>
        <dd>{{ dataItem?.errorMessage }}</dd>

        <dt>Name</dt>
        <dd>{{ dataItem?.employeeName }}</dd>

        <dt>Payroll No</dt>
        <dd>{{ dataItem?.employeePayrollNumber }}</dd>

        <dt>Ref Date</dt>
        <dd>{{ dataItem?.referenceDate | amDateFormat: appConfig.dateFormat }}</dd>

        <dt>Export By</dt>
        <dd>{{ dataItem?.exportedBy }}</dd>
      </dl>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Error Id" width="150" media="sm" field="errorId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Error Id</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.errorId }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-numeric-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-numeric-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Entry Type" width="300" media="sm" field="entryType">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Entry Type</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value }}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.entryType }}
      </ng-template>
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                   [column]="column"
                   [filter]="filter"
                   [filterService]="filterService"
                  >
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>

  <kendo-grid-column *ngIf="!pbjIdConfigEnabled" title="Emp Master ID" width="150" field="employeeMasterId" media="sm">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Emp Master ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeMasterId }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="pbjIdConfigEnabled" title="PBJ ID" width="150" media="sm" field="pbjId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>PBJ ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.pbjId }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Section" width="150" media="sm" field="section">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Section</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.section }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Field" width="150" media="sm" field="field">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Field</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.field }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Error Message" width="300" media="sm" field="errorMessage">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Error Message</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.errorMessage }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Name" width="150" media="sm" field="employeeName">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Name</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeName }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Payroll No" width="150" media="sm" field="employeePayrollNumber">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Payroll No</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeePayrollNumber }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Ref Date" width="180" media="sm" field="referenceDate" filter="date">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Ref Date</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.referenceDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <!-- <kendo-grid-column title="Export By" width="150" media="sm" field="exportedBy">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Export By</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.exportedBy }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter" slxKendoGridServerFilter>
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column> -->

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

<kendo-grid #exportGrid *ngIf="isExportInProgress && currentExportType != xlsExportType" class="export-grid slx-blue-grid slx-no-border"
[data]="exportGridState.view"
[sortable]="{ mode: 'multiple' }"
[sort]="exportGridState.state.sort"
[group]="exportGridState.state.group"
[filterable]="'menu'"
[filter]="exportGridState.state.filter"
[pageable]="false"
>

  <kendo-grid-column title="Error Id" width="150" field="errorId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Error Id</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.errorId }}
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column *ngIf="!pbjIdConfigEnabled" title="Emp Master ID" width="150" field="employeeMasterId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Emp Master ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeMasterId }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="pbjIdConfigEnabled" title="PBJ ID" width="150" field="pbjId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>PBJ ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.pbjId }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Section" width="150" field="section">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Section</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.section }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Field" width="150" field="field">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Field</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.field }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Error Message" width="150" field="errorMessage">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Error Message</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.errorMessage }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Name" width="150" field="employeeName">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Name</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeName }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Payroll No" width="150" field="employeePayrollNumber">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Payroll No</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeePayrollNumber }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Ref Date" width="150" field="referenceDate" filter="date">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Ref Date</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.referenceDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-pdf fileName="export-{{exportId}}-errors-data.pdf"
  paperSize="A4" [scale]="pdfScale" [landscape]="true" [allPages]="true" [repeatHeaders]="true"
  >

  <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>

    <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
    <div class="page-template">
        <div class="header">
            <h4>{{pbjHeader?.organization?.name}} ({{pbjHeader?.startDate| amDateFormat: appConfig.dateFormat}} - {{pbjHeader?.endDate| amDateFormat: appConfig.dateFormat}}) Exported on {{pbjHeader?.exportDate| amDateFormat: appConfig.dateTimeFormatUS}}</h4>
        </div>
        <div class="footer">
          Page {{ pageNum }} of {{ totalPages }}
        </div>
      </div>
    </ng-template>

  </kendo-grid-pdf>

  <kendo-grid-excel fileName="export-{{exportId}}-errors-data.xlsx"></kendo-grid-excel>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import * as _ from 'lodash';

import { Component, Input, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Host } from '@angular/core';
import { SortDescriptor, State, process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import {
  ConfirmDialog2Component,
  ConfirmOptions,
  KendoGridStateHelper,
  ModalService
} from '../../../../../common/index';

import { EmployeeSubsectionWarnings, EmployeeWarningBasic, EmployeeWarningExtended } from '../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../services/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-warnings',
  templateUrl: 'employee-sections-warnings.component.html',
  styleUrls: ['employee-sections-warnings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsWarningsComponent implements OnInit, OnDestroy {
  @Input()
  public set employeeSectionsWarnings(v: EmployeeSubsectionWarnings) {
    this.assignData(v, null);
  }

  @Input()
  public set employeeId(v: number) {
    this.assignData(null, v);
  }

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  public get canAddWarning(): boolean {
    return this.canAdd;
  }
  public get canEditWarning(): boolean {
    return this.canEdit;
  }
  public get canDeleteWarning(): boolean {
    return this.canDelete;
  }

  public gridState = new KendoGridStateHelper<EmployeeWarningBasic>();
  public warnings: Array<EmployeeWarningBasic> = [];
  public empId: number;
  public isLoading: boolean;

  @unsubscribeAll()
  public subscriptions: StringMap<Subscription> = {};
  public appConfig: IApplicationConfig = appConfig;
  public pageSize = 10;

  private canAdd: boolean;
  private canEdit: boolean;
  private canDelete: boolean;

  constructor(
    private readonly manService: EmployeeSectionWarningsManagementService,
    private readonly modalService: ModalService,
    private readonly changeDetector: ChangeDetectorRef,
    @Host() private decorator: EmployeeSubSectionsDecoratorComponent
  ) {
    this.gridState = new KendoGridStateHelper<EmployeeWarningBasic>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'issuedDate', dir: 'desc' }];
    this.gridState.state.take = this.pageSize;
    
    this.decorator.isEditableByConfiguration = false;
  }

  public ngOnInit(): void {
    this.manService.init(this.empId);

    this.subscriptions.loadedWarnings = this.manService.subscribeToLoadedWarnings((warnings: Array<EmployeeWarningBasic>) => {
      this.warnings = warnings;
      this.updateGrid();
    });

    this.subscriptions.reloadWarnings = this.manService.subscribeToReloadWarnings(() => {
      this.manService.getWarnings();
    });

    this.subscriptions.spinner = this.manService.subscribeToSpinner((isShown: boolean) => {
      this.isLoading = isShown;
      this.checkChanges();
    });

    this.subscriptions.refreshGrid = this.gridState.onRefreshGrid.subscribe(() => {
      this.updateGrid();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onDownload(warningBasic: EmployeeWarningBasic): void {
    this.manService.downloadWarning(warningBasic.id);
  }

  public onSaveWarning(warning: EmployeeWarningExtended): void {
    if (_.isFinite(warning.id) && warning.id > 0) {
      this.manService.editWarning(warning);
    } else {
      this.manService.addWarning(warning);
    }
  }

  public onRemoveWarning(warning: EmployeeWarningBasic): void {
    const options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'Delete Warning',
      'Are you sure that you want to remove this warning?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.manService.deleteWarning(warning);
        }
      },
      options
    );
  }

  public assignData(subsection: EmployeeSubsectionWarnings, empId: number): void {
    if (_.isObjectLike(subsection) && _.isArray(subsection.warnings)) {
      this.warnings = subsection.warnings;
      this.canAdd = subsection.actions.canAdd;
      this.canEdit = subsection.actions.canEdit;
      this.canDelete = subsection.actions.canDelete;
      this.updateGrid();
    }
    if (_.isFinite(empId)) {
      this.empId = empId;
    }
  }

  private updateGrid(): void {
    this.refreshGrid();
    this.checkChanges();
  }

  private checkChanges(): void {
    this.changeDetector.detectChanges();
  }

  private refreshGrid(): void {
    if (!this.warnings) {
      this.gridState.view = null;

      return;
    }
    this.gridState.view = process(this.warnings, this.gridState.state);
  }

}

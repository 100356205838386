import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable ,  ReplaySubject ,  Subscription ,  Subject } from 'rxjs';
import { Actions } from '../../../core/models/field/actions-type';
import { ManagementBaseService } from '../../../core/services/index';
import { Department, Organization } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { LookupApiService } from '../../../organization/services/lookup/lookup-api.service';
import { ChangeManagementService } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { IConfigutrationContainer } from '../../models/configiration-container.interface';
import { UnitsContainer } from '../../models/location-units/units-container';
import { Unit } from '../../models/location-units/unit';
import { UnitsApiService } from '../../services/units/units-api.service';
import { Assert } from '../../../framework/assert/assert';

@Injectable()
export class UnitsConfigurationManagementService extends ManagementBaseService<UnitsContainer, any> implements IConfigurationManagementService {

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    public removeItemsCmd$: ReplaySubject<Unit>;
    public addItemCmd$: ReplaySubject<Unit>;
    public editItemCmd$: ReplaySubject<Unit>;
    public viewRefresh$: Subject<boolean>;
    public dataChanged$: Subject<boolean>;

    public editingItem: any;
    public isEditingNewItem: boolean;

    public get container(): IConfigutrationContainer {
        return this.m_container;
    }

    private m_container: IConfigutrationContainer;

    private currentOrgLevel: OrgLevel;

    @unsubscribeInService()
    private orgLevelSubscription: Subscription;

    constructor(public access: AccessManagementService, public changeService: ChangeManagementService, private api: UnitsApiService, private lookup: LookupApiService) {
        super();
        this.removeItemsCmd$ = new ReplaySubject<Unit>();
        this.addItemCmd$ = new ReplaySubject<Unit>();
        this.editItemCmd$ = new ReplaySubject<Unit>();
        this.dataChanged$ = new Subject<boolean>();
        this.viewRefresh$ = new Subject<boolean>();
    }

    public init(): void {

        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = false;
        this.access.allowDepartmentLevel = true;

        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            if (_.isNumber(orgLevel.id)) {
                this.currentOrgLevel = orgLevel;
                this.access.orgLevelType = this.currentOrgLevel.type;
                this.onStateChanged$.next({ orgLevelChanged: true, configureMode: true, copyMode: false });
                this.fetchRecords();
            }
        });
    }

    public markAsDirty(): void {
        this.changeService.changeNotify ();
    }

    public setSelectedCount(count: number): void {
        this.access.selectedItemsCount = count;
    }

    public onAddItem(item: any): void {
        this.changeService.changeNotify ();
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    }

    public onEditItem(item: any): void {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    }

    public onCancelEditItem(): void {
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.editItemCmd$.next(null);
        this.changeService.clearChanges ();
    }

    public onRemoveItem(itemToDelete: Unit): void {
        this.removeItemsCmd$.next(itemToDelete);
    }

    public doRemoveItem(item: Unit, reassignToUnit: Unit): void {
        Assert.isNotNull(item);
        let reassignId: number = reassignToUnit ? reassignToUnit.id : null;
        this.api.removeUnit(item.id, this.currentOrgLevel.id, reassignId)
            .then((items: Unit[]) => {
                this.access.lockActions = false;
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    public onSaveItem(info: { dataItem: Unit, isNew: boolean }): void {
        if (info.isNew) {
            this.addItem(info.dataItem);
        } else {
            this.updateItem(info.dataItem);
        }
    }

    protected addItem(item: Unit): void {
        _.each(this.m_container.records, (p: Unit) => {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.saveUnit(item, this.currentOrgLevel.id)
            .then((item: Unit) => {
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    protected updateItem(item: Unit): void {
        _.each(this.m_container.records, (p: Unit) => {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.saveUnit(item, this.currentOrgLevel.id)
            .then((item: Unit) => {
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    protected fetchRecords(): void {
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        this.api.getUnitsList(this.currentOrgLevel.id).
            then((result: { actions: Actions, records: Unit[] }) => {
                this.changeService.clearChanges ();
                this.m_container = new UnitsContainer();
                this.m_container.records = result.records;
                this.access.actions = result.actions;
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.onLoaded$.next(this.m_container);
                this.onStateChanged$.next({ isLoading: false });
            }).catch(() => {
                this.access.lockActions = false;
                this.onStateChanged$.next({ isLoading: false });
            });

    }
}


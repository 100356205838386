import { Component, Input, OnDestroy, OnInit } from '@angular/core';



import { Observable ,  Subscription } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { ISession } from '../../../authentication/store/index';

import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { AppSettingsManageService } from '../../../app-settings/services';


const imageCache: StringMap<string> = {};
const VIEW_MODE_KEY: string = 'view_mode';
const FILTERS: string = 'filters';
const DATE: string = 'date';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-unit-router',
  templateUrl: 'daily-unit-router.component.html',
  styleUrls: ['daily-unit-router.component.scss'],
  providers: [StateManagementService],
})
export class DailyUnitRouterComponent implements OnInit, OnDestroy {
  @Input()
  public set dateOn(value: Date) {
    this.m_dateOn = value;
  }

  @Input()
  public shiftGroup: boolean;

  public get dateOn(): Date {
    return this.m_dateOn;
  }

  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  @unsubscribe()
  private userSubscription: Subscription;

  public state: {
    isLoading: boolean;
    isSelectPropagated: boolean;
    switchShiftMode: boolean;
  };

  private m_dateOn: Date;

  public isGridView:boolean;

  public alias: string;


  constructor(private stateManagement: StateManagementService, private appSettingsManageService: AppSettingsManageService) {
    this.appSettingsManageService.getAppServerConfig()
    .then((appConfig)=> {
      this.isGridView = appConfig.duagrid;
    });
  }

  public ngOnInit(): void {
    this.userSubscription = this.user$.subscribe((session: ISession) => {
      if (session) {
        this.alias = session.alias;
      }
    });
    this.stateManagement.init('DailyUnitRouterComponent', true);
   
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }
  
  public showGridView() : boolean {
    if(this.isGridView===undefined){
      return false;
    }
    else{
      return true;
    }
  }

}

import { Injectable } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';
import { Assert } from '../../framework/index';

@Injectable()
export class CollapsibleSectionService {
  private collapseAll$: Subject<boolean>;

  private m_isExpand: boolean;

  public get isExpand(): boolean {
    return this.m_isExpand;
  }

  constructor() {
    this.collapseAll$ = new Subject<boolean>();
  }

  public toggleAllSections(isExpand: boolean): void {
    this.m_isExpand = isExpand;
    this.collapseAll$.next(isExpand);
  }

  public subscribeToToggleSections(callback: (isExpand: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.collapseAll$.subscribe(callback);
  }
}

<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  (dataStateChange)="gridState.dataStateChange($event)"

  [slxKendoGridFiltersState]="gridState"
  (kendoGridStateChanged)="onGridStateChanged($event)"
>
  <kendo-grid-column
    title="Employee Name"
    field="employee.name"
    width="150"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="wc-emp" [title]="dataItem.employee?.name">
        <img class="wc-emp__img" [employeeThumbinalSrc]="dataItem.employee?.id" alt="Employee Photo" />
        <span class="wc-emp__text">{{ dataItem.employee?.name }}</span>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Facility"
    field="organization.name"
    width="150"
    filter="text"
    [hidden]="!isVisible('organization')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Facility</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Department"
    field="department.name"
    width="180"
    filter="text"
    [hidden]="!isVisible('department')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Department</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.department?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Position"
    field="position.name"
    width="180"
    filter="text"
    [hidden]="!isVisible('position')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Date of Injury"
    field="incidentDateTime"
    width="120"
    filter="date"
    [hidden]="!isVisible('incidentDateTime')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Date of Injury</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="wc-link" (click)="onOpenReport(dataItem)">{{ dataItem.incidentDateTime | amDateFormat: appConfig.dateFormat }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Days Lost"
    field="oshaDaysLost"
    width="100"
    filter="numeric"
    [hidden]="!isVisible('oshaDaysLost')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Days Lost</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.oshaDaysLost }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Days Restrict"
    field="restrictedDutyDaysNum"
    width="100"
    filter="numeric"
    [hidden]="!isVisible('restrictedDutyDaysNum')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Days Restrict</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.restrictedDutyDaysNum }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Injury Description"
    field="injuryType.description"
    width="150"
    filter="text"
    [hidden]="!isVisible('injuryType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Injury Description</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.injuryType?.description }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="OSHA Case Number"
    field="oshaCaseNumber"
    width="120"
    filter="text"
    [hidden]="!isVisible('oshaCaseNumber')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>OSHA Case Number</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.oshaCaseNumber }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['desktop']"
    title=""
    class="overflow-visible-cell"
    [width]="50"
    [filterable]="false"
    [sortable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="m-osha">
        <span class="m-osha__btn"
          [popper]="incidentInto"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-end'"
          [popperDisableStyle]="'true'"
        >
          <i class="fas fa-list-ul" aria-hidden="true"></i>
        </span>
        <popper-content #incidentInto class="slx-popper">
          <section class="m-osha__popper">
            <div class="flex flex-direction-row m-osha__group" *slxHiddenOn="['tablet']">
              <p class="m-osha__field">
                <span class="m-osha__title">Department</span>
                <span class="m-osha__value">{{ dataItem.department?.name }}</span>
              </p>
              <p class="m-osha__field">
                <span class="m-osha__title">Position</span>
                <span class="m-osha__value">{{ dataItem.position?.name }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-osha__group">
              <p class="m-osha__field">
                <span class="m-osha__title">Date of Injury</span>
                <span class="m-osha__value">
                  <span class="wc-link" (click)="onOpenReport(dataItem)">{{ dataItem.incidentDateTime | amDateFormat: appConfig.dateFormat }}</span>
                </span>
              </p>
              <p class="m-osha__field">
                <span class="m-osha__title">Days Lost</span>
                <span class="m-osha__value">{{ dataItem.daysLost }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-osha__group">
              <p class="m-osha__field">
                <span class="m-osha__title">Days Restrict</span>
                <span class="m-osha__value">{{ dataItem.restrictedDutyDaysNum }}</span>
              </p>
              <p class="m-osha__field">
                <span class="m-osha__title">Injury Description</span>
                <span class="m-osha__value">{{ dataItem.injuryType?.description }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-osha__group">
              <p class="m-osha__field">
                <span class="m-osha__title">OSHA Case Number</span>
                <span class="m-osha__value">{{ dataItem.oshaCaseNumber }}</span>
              </p>
            </div>
          </section>
        </popper-content>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel fileName="Workers' Comp OSHA Budget Tracker.xlsx" [fetchData]="retriveAllPages()"></kendo-grid-excel>
  <kendo-grid-pdf fileName="Workers' Comp OSHA Budget Tracker.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

export class PayPolicyRule {
    public id: number;
    public policyId: string;
    public organizationId?: number;
    public exceptionId: number;
    public description?: string;
    public notes?: string;
    public isActive?: boolean;
    public startDate?: Date;
    public endDate?: Date;
    public lastUpdatedDate?: Date;
    public deleteInd?: boolean;
}

import { Provider } from '@angular/core';

export * from './punch-attestation-api.service';
export * from './punch-attestation-management.service';
export * from './punch-attestation-map.service';

import { PunchAttestationApiService } from './punch-attestation-api.service';
import { PunchAttestationManagementService } from './punch-attestation-management.service';
import { PunchAttestationMapService } from './punch-attestation-map.service';

export const services: Provider[] = [
  PunchAttestationApiService,
  PunchAttestationManagementService,
  PunchAttestationMapService
];

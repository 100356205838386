import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

import { Subscription ,  Observable } from 'rxjs';
import { OrgLevelWatchService } from '../../services/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import { unsubscribe } from '../../../core/decorators/index';

import * as moment from 'moment';
import { OrgLevelFlat } from '../../models/index';


@Component({
  moduleId: module.id,
  selector: 'slx-org-level-search',
  templateUrl: 'org-level-search.component.html',
  styleUrls: ['org-level-search.component.scss'],
})
export class OrgLevelSearchComponent implements OnInit, OnDestroy {
  public selectedOrgLevel: OrgLevelFlat;
  public state: {
    isLoading: boolean;
  };
  public gridState: KendoGridStateHelper<OrgLevelFlat>;

  private records: OrgLevelFlat[];
  private orgLevelWatchService: OrgLevelWatchService;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private selectionSubscription: Subscription;


  constructor(orgLevelWatchService: OrgLevelWatchService) {
    this.orgLevelWatchService = orgLevelWatchService;

    this.state = {
      isLoading: false,
    };
    this.gridState = new KendoGridStateHelper<OrgLevelFlat>();
  }

  public ngOnInit(): void {
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
    this.selectionSubscription = this.gridState.onSelectionChanged.subscribe((selectedRecords: OrgLevelFlat[]) => {
      if (!selectedRecords) {
        this.selectedOrgLevel = null;
        return;
      }
      this.selectedOrgLevel = _.first(selectedRecords);
    });
    this.loadOrgLevels();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private loadOrgLevels(): void {
    this.records = this.orgLevelWatchService.getFlatList();
    this.refreshGrid();
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}

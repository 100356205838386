import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable ,  Subscription } from 'rxjs';

import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { BenefitsNavigationService, ModalService, StateManagementService } from '../../../../common/services/index';
import { OrgLevel } from '../../../../state-model/models';
import { BenefitListManagementService } from './../../services/benefit-console/benefit-list-management.service';
import { BenefitPayrollMappingDialogComponent } from './benefit-payroll-mapping-dialog/benefit-payroll-mapping-dialog.component';
import { BenefitConsoleCommonService } from './../../services/benefit-console/benefit-console-common.service';
import { BenefitDetailsGroup, BenefitDetailsConfig } from '../../models';
import { BenefitConsoleDetailsCommonService } from '../../services/benefit-console/benefit-console-details-common.service';

@Component({
  selector: 'slx-benefit-console',
  templateUrl: 'benefit-console.component.html',
  styleUrls: ['benefit-console.component.scss'],
  providers: [BenefitListManagementService, BenefitConsoleCommonService, StateManagementService, BenefitConsoleDetailsCommonService],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BenefitConsoleComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public activeTab: number = 0;
  public BenefitPlansList: string[] = [];
  public selectedBenefitPlan: string;
  public tabSwitch: boolean;
  public isShowingExpired: boolean = null;
  public planTypes: BenefitDetailsGroup[] = [];
  public benefitPlansLoading: boolean = true;
  public currentExpandedGroups: number[];
  public canMapPayroll: boolean = false;

  private orgLevel: OrgLevel;
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private manService: BenefitListManagementService,
    public commonService: BenefitConsoleCommonService,
    private modalService: ModalService,
    public stateManagement: StateManagementService,
    public consoleDetailCommonService: BenefitConsoleDetailsCommonService
  ) {
  }

  public ngOnInit() {
    this.consoleDetailCommonService.init('BenefitConsoleDetailsCommonService');

    this.manService.getBenefitPlanHeaders();

    this.subscriptions.orgLevel = this.manService
      .subscribeToOrgLevel((v: OrgLevel) => {
        this.orgLevel = v;
        if (_.isBoolean(this.isShowingExpired)) {
          this.manService.getBenefitList();
        }
      });

    this.BenefitPlansList = ['Recent Activity', 'Plans by Expiration'];
    this.selectedBenefitPlan = 'Recent Activity';

    this.subscriptions.showExpired = this.manService
    .subscribeToIsLoadingExpired((r: boolean) => {
      this.isShowingExpired = r;
      this.manService.getBenefitList();
    });

    this.subscriptions.onLoaded = this.manService
      .subscribeToPlanHeaders((v: BenefitDetailsConfig) => {
        this.planTypes = v.groups;
        this.canMapPayroll = v.canMapPayroll;
      });

    this.subscriptions.onLoad = this.manService
    .subscribeToPlanGroupsLoading((isLoading: boolean) => {
      this.benefitPlansLoading = isLoading;
    });

    this.subscriptions.expandedGroups = this.commonService.
    subscribetoExpandedGroups((currentExpandedGroups) => {
      this.currentExpandedGroups = currentExpandedGroups;
    });
  }

  public refreshData() {
    this.manService.getBenefitList();
    _.forEach(this.currentExpandedGroups, (groupId) => {
      this.manService.getBenefitPlanTypes(groupId, this.isShowingExpired);
    });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
    this.manService.destroy();
    this.commonService.destroy();
  }

  public onClickTab(tab: number): void {
    if (this.activeTab === tab) return;

    this.activeTab = tab;
    this.isActiveMenu(this.activeTab);
  }

  public isActiveMenu(tabOrder: number): boolean {
    return (this.activeTab === tabOrder) ? true : false;
  }

  public onTogglerChanged(showExpired: boolean) {
    this.manService.changeShowingExpiredPlans(showExpired);
  }

  public benefitPlanChange(planName: string) {
    this.selectedBenefitPlan = planName;
    this.tabSwitch = (planName === 'Recent Activity') ? true : false;
  }

  public showBenefitPayrollMappingDialog() {
    BenefitPayrollMappingDialogComponent.openDialog(this.modalService, (result: boolean) => {
      if(result) { this.refreshData(); }
    });
  }
}

import { PaycodeExceptionModel } from "../../models/pay-code-details/models/paycode-exception.model";
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ApiUtilService } from "../../../common";
import { PaycodeExceptionMapService } from "../pay-code-details/paycode-exception-map.service";
import { Organization } from "../../models/pay-code-details/models/organization";

@Injectable()
export class CopyPayCodesAPIService {

    private readonly taApiRoot: string = this.getTAServicesApiRoot(); // Use the TA Services API root directly

    constructor(
        private apiUtilService: ApiUtilService,
        private mapService: PaycodeExceptionMapService
    ) { }

    // PaycodeExceptions
    public async getPaycodeExceptions(orgId: number): Promise<PaycodeExceptionModel[]> {
        const url: string = `${this.taApiRoot}/paycodeexceptions/organizations/${orgId}/paycodeexceptions/all`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        try {
            const response = await this.apiUtilService.request<any, any>(request);
            if (response && response.data) {
                return response.data.map(this.mapService.mapToModel);
            }
            return []; // Return empty list if no data found
        } catch (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                console.warn('No paycode exceptions found for organization ID:', orgId);
                return []; // Return empty list for 404 errors
            }
            this.handleError(error);
            throw error;
        }
    }

    public async getOrganizationDetails(): Promise<Organization[]> {
        const url: string = `${this.taApiRoot}/organizations`;
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        try {
            const response = await this.apiUtilService.request<any, any>(request);
            if (response && response.data) {
                return response.data;
            }
            throw new Error(`No organizations found.`);
        } catch (error) {
            if (error.status === 404 || (error.error && error.error.status === 404)) {
                console.warn('No organizations found.');
                return null; // Return null for 404 errors
            }
            this.handleError(error);
            throw error;
        }
    }

    public async copyPayCodes(
        payCodeIds: number[],
        organizationIds: number[],
        currentOrganizationId: number,
        overrideMatchingException: boolean,
        copyOnlyIfExists: boolean
    ): Promise<void> {
        const url: string = `${this.taApiRoot}/paycodeexceptions/copy`;
    
        const body = {
            payCodeIds: payCodeIds,
            organizationIds: organizationIds,
            currentOrganizationId: currentOrganizationId,
            overrideMatchingException: overrideMatchingException,
            copyOnlyIfExists: copyOnlyIfExists
        };
    
        // Set headers to ensure the correct content type
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    
        // Create the request object with method POST, URL, body, and headers
        const request: HttpRequest<any> = new HttpRequest('POST', url, body, { headers });
    
        try {
            console.log(JSON.stringify(body));
            const response = await this.apiUtilService.request<any, any>(request);
            console.log('Pay codes copied successfully.');
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    private getTAServicesApiRoot(): string {
        return `${this.apiUtilService.getTAServicesApiRoot()}/taappend`;
    }

    private handleError(error: any): void {
        if (error.status === 404 || (error.error && error.error.status === 404))
            console.warn('Resource not found:', error);
        if (error.status === 500 || (error.error && error.error.status === 500)) {
            console.error('Internal server error:', error);
        } else {
            console.error('An error occurred:', error);
        }
    }
}
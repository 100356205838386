import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { DataStateChangeEvent, GridComponent, RowArgs } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from '../../../../../app/app.config';
import { ConfirmDialog2Component, ConfirmOptions, KendoGridStateHelper, ModalService } from '../../../../../app/common';
import { Unit, IdealScheduleList } from '../../../../../app/configuration/models';
import { ShiftsApiService, UnitsApiService, PositionsConfigurationApiService } from '../../../../../app/configuration/services';
import { mutableSelect, unsubscribe } from '../../../../../app/core/decorators';
import { Details, OpenShiftSummary } from '../../../../scheduler/models';
import { OpenShiftSchedule, OpenShiftV2, PendingShiftValue } from '../../../../scheduler/models/daily-unit-assignment/add-open-shift';
import { DetailScreenService, OpenShiftManagementApiService, ScheduleEntryApiService } from '../../../../scheduler/services';
import { ShiftRequestService } from '../../../../scheduler/services/schedule/shift-request.service';
import { process, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { ShiftDefinition } from '../../../../../app/organization';
import { NotificationsService } from '../../../../../app/core/components';
import { OrgLevel } from '../../../../../app/state-model/models';
import { Observable, Subscription } from 'rxjs';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { scheduleMicrotask } from '../../../../core/utils';

@Component({
  selector: 'slx-add-open-shifts-v2',
  templateUrl: './add-open-shifts-v2.component.html',
  styleUrls: ['./add-open-shifts-v2.component.scss']
})
export class AddOpenShiftsV2Component implements OnInit, AfterViewInit {

  @ViewChild('grid', { static: true })
  public grid: GridComponent;

  @ViewChild('openShiftGridView', { static: true })
  public openShiftGridView: GridComponent;
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['session'])
  public selectedOpenShiftSummary: OpenShiftSummary;
  public requestedDate: Date;
  public gridState: KendoGridStateHelper<OpenShiftV2>;
  public openShiftGrid: KendoGridStateHelper<OpenShiftSummary>;
  public shifts: any;
  public isLoading: boolean = false;
  public units: Unit[];
  public positions: any;
  public positionData: any;
  public isSelectedShift: boolean = false;
  public selectedOpenShift: OpenShiftV2[] = [];
  public openShift: OpenShiftV2 = new OpenShiftV2();
  public displayId: any[] = [];
  public idealScheduleDetails: IdealScheduleList;
  public pageSize: number;
  public openShiftPageSize: number = 10;
  public max: number = 99;
  public min: number = 0;
  public newCount: number = 1;
  public isMappedShift: boolean;
  public unitData: Unit[];
  public shiftsData: any;
  public openShiftSummary: any;
  public newOpenShift: OpenShiftV2[] = [];
  public newShift: OpenShiftV2[] = [];
  public newShiftcount: number = 1;
  public orgLevelId: number;
  public ShiftSummaryDetails: OpenShiftV2[];
  private editedRowIndex: number;
  public shiftUpdated: boolean = false;
  public isEnabled: boolean = false;
  public openShiftDetails: OpenShiftV2[] = [];
  public isRowSelected = (e: RowArgs): boolean => this.displayId.indexOf(e.dataItem.displayId) >= 0;
  public shiftDetails: OpenShiftV2[] = [];
  public appConfig: IApplicationConfig;
  pendingShiftValue: PendingShiftValue[] = [];
  public pendingShift: PendingShiftValue[] = [];
  public state: State = {};
  public orgLevel: OrgLevel;
  public isUpdatedPendingShift: boolean = false;
  public openShiftSummaryDetails: OpenShiftSummary;
  public isOrglevelPayPeriodApproved: boolean = false;
  public isModifyPayPeriodApproved: boolean = false;
  public screenWidth: number;
  public isLeftSideNavbarOpen : boolean = false;
  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  @unsubscribe()
  private isLeftSidebarOpenSubscripion: Subscription;

  constructor(
    private shiftApiService: ShiftsApiService,
    private unitApiService: UnitsApiService,
    private positionApiService: PositionsConfigurationApiService,
    private detailScreenService: DetailScreenService,
    private shiftRequestService: ShiftRequestService,
    private openShiftManagementApiService: OpenShiftManagementApiService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private changeDetector: ChangeDetectorRef, private scheduleEntryApiService: ScheduleEntryApiService,
    private modalService: ModalService,private appSettingsManageService: AppSettingsManageService
  ) {
    this.gridState = new KendoGridStateHelper();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.group = [];
    this.openShiftGrid = new KendoGridStateHelper();
    this.openShiftGrid.state.skip = 0;
    this.openShiftGrid.state.sort = [];
    this.openShiftGrid.state.group = [];
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
  }

  public ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        this.orgLevel = orgLevel
      });
    this.route.queryParams.subscribe((s) => {
      this.orgLevelId = s.orgLevelId;
      if (s.isAgencyEnabled == 'false') {
        this.isEnabled = false;
      }
      else {
        this.isEnabled = true;
      }
      this.requestedDate = moment(s.dateOn, appConfig.linkDateFormat).toDate();
      this.getShiftDetails();
      this.getUnits();
      this.getPositions();
      this.appConfig = appConfig;

    });
    this.getSettings();
    this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe((value: boolean) => {
      scheduleMicrotask(() => { this.isLeftSideNavbarOpen = value; });
    });

  }

  ngAfterViewInit(): void {
    $( "a.k-grid-filter-menu.k-grid-header-menu" ).attr('title','Filter');
  }

  ngAfterViewChecked() {

    this.changeDetector.detectChanges();
  }
  public async getOpenShiftDetails(): Promise<void> {
    this.isLoading = true;
    let filter: any = null
    await this.openShiftManagementApiService.getOpenShiftSummary(this.orgLevelId, this.requestedDate, filter).then((openShiftSummary: OpenShiftSummary) => {
      this.pendingShiftValue = this.shiftRequestService.pendingShiftDetails;

      openShiftSummary.details = openShiftSummary.details.filter( e => e.parentShiftId < 0);

      this.openShiftSummaryDetails = openShiftSummary;
      
      if (openShiftSummary.details.length != 0 && this.pendingShiftValue.length == 0 && this.isEnabled) {
        this.loadDetails(openShiftSummary);
      }
      else {
        this.isLoading = false;
        this.refreshOpenShift();
      }

    });
  }

  public async loadDetails(openShiftSummary): Promise<void> {
    await this.detailScreenService.getDailyUnits(this.orgLevel, this.requestedDate).then(details => {
      this.getShiftState(details, openShiftSummary);
      this.isLoading = false;
    });
  }
  public getShiftState(details: Details, openShiftSummary): void {
    this.pendingShift = [];
    details.groups.forEach(group => {
      group.rows.forEach(element => {
        element.fields.forEach(el => {
          if (el.name == 'PartnerId' && el.value != '') {
            let isAssigned = element.fields.filter(e => e.name == 'EmpName' && e.value == '')
            if (isAssigned.length > 0) {
              this.getValues(element.fields);
            }
          }
        });
      });
    });
    this.pendingShiftValue = this.pendingShift;

    this.refreshOpenShift();

  }

  public getValues(element): void {
    let pendingShift: PendingShiftValue = new PendingShiftValue;
    let position, shift, unit
    element.forEach(el => {
      if (el.name == "JobCode") {
        position = el.value;
      }
      else if (el.name == "ShiftName") {
        shift = el.value;
      }
      else if (el.name == "UnitName") {
        unit = el.value;
      }
    });
    pendingShift.key = position + '_' + shift + '_' + unit;
    pendingShift.value = 1;
    let isExist: boolean = false;
    this.pendingShift.forEach(element => {
      if (element.key == pendingShift.key) {
        element.value += 1;
        isExist = true;
      }

    });
    if (!isExist) {
      this.pendingShift.push(pendingShift);
    }
  }
  public refreshOpenShift(): void {
    this.openShiftDetails = [];
    let displayId: number = 1;
    let detailSummary = this.openShiftSummaryDetails.details;
    let details = this.sortOpenShiftDetails(detailSummary)
    details.forEach(element => {
      let openShift: OpenShiftV2 = new OpenShiftV2();
      openShift.displayId = displayId++;
      openShift.isNewShift = false;
      openShift.isAdded = false;
      openShift.scheduledShiftCount = element.scheduledShiftCount;
      openShift.overScheduleCount = element.overScheduleCount;
      openShift.Shifts = (element.shift);
      openShift.position = (element.position);
      openShift.unit = (element.unit);
      openShift.openShiftCount = element.openShiftCount;
      openShift.totalShifts= this.getTotalShift(element);
      openShift.pendingEmployeeRequests = element.newMessageCount;
      this.openShiftDetails.push(openShift);
    });
    this.ShiftSummaryDetails = this.openShiftDetails;
    this.openShiftPageSize = this.openShiftDetails.length;
    this.pendingShiftValue.forEach(element => {
      let el = element.key.split('_');
      this.pendingShiftDetails(el, element.value);
    });
    this.openShiftGrid.view = null;
    this.openShiftGrid.view = process(this.openShiftDetails, this.state);
    this.newShiftcount = this.openShiftDetails.length + 1;
    this.openShift = new OpenShiftV2();
    this.openShift.displayId = this.newShiftcount;
    this.openShift.isNewShift = true;
    this.openShift.isAdded = true;
    this.openShift.count = 0;


  }
  public sortOpenShiftDetails(details) {
    //find Shift Details
    let tempShift: ShiftDefinition[] = [];
    this.shifts.forEach(element => {
      tempShift.push(element)
    });
    details.forEach(element => {
      let tempShiftDetails = tempShift.filter(el => el.id == (element.parentShiftId > 0 ? element.parentShiftId : element.shift.id));
      element.shift = tempShiftDetails[0];
    });
    details.sort(function (a, b) {
      return a.position.name.localeCompare(b.position.name) || (a.shift.startTime - b.shift.startTime) || a.unit.name.localeCompare(b.unit.name)
    });
    return details;
  }
  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.openShiftGrid.view = process(this.openShiftDetails, this.state)
  }
  public async getShiftDetails(): Promise<void> {
    this.isLoading = true;
    await this.shiftApiService.getShiftsList(this.orgLevelId).
      then((result) => {
        this.shifts = result.records.filter(e => e.parentShiftId < 0);
        this.shiftsData = this.shifts;
        this.getOpenShiftDetails();
      }).catch(() => {
        console.log("error");
        this.isLoading = false;
      });
  }
  public getUnits(): void {
    this.unitApiService.getUnitsList(this.orgLevelId).
      then((result: { records: Unit[] }) => {
        this.units = result.records;
        this.unitData = this.units;

      }).catch(() => {
        console.log("error Units")
      });
  }
  public getPositions(): void {
    this.positionApiService.getPositions(this.orgLevelId).
      then((result) => {
        this.positions = result.positions;
        this.positionData = this.positions;
      }).catch(() => {
      });
  }

  public filterPosition(value): void {
    this.positionData = this.positions.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);

  }
  public filterUnits(value): void {
    this.unitData = this.units.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  public filterShifts(value): void {
    this.shiftsData = this.shifts.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1  
    );
  }
  public shiftChange(dataItem: OpenShiftV2): void {
    dataItem.count = -1;
    this.displayId = this.displayId.filter(el => el != dataItem.displayId);
  }
  public isShiftSelected(): boolean {
    if (this.displayId.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }
  public isUpdatedShiftCount(dataItem: OpenShiftV2): boolean {
    let isupdated: boolean = true;
    if (dataItem == undefined) {
      isupdated = true;
    }
    else {
      if (dataItem.count == null || dataItem.count == -1) {
        this.displayId = this.displayId.filter(el => el != dataItem.displayId);
        isupdated = true;
      }
      else {
        let overageShift = this.getShiftsOverage(dataItem);
        let filledShift = dataItem.pendingAgencyRequests + dataItem.pendingEmployeeRequests + dataItem.scheduledShiftCount+overageShift;
        if (dataItem.count < filledShift) {
          this.displayId = this.displayId.filter(el => el != dataItem.displayId);
          isupdated = true;
        }
        else {
          this.displayId.push(dataItem.displayId)
          let tempDisplayId = this.displayId.filter((item, i, ar) => ar.indexOf(item) === i);
          this.displayId = tempDisplayId;
          this.shiftUpdated = true;
          isupdated = false;
        }
      }
    }
    return isupdated;
  }
  public isNewOpenShift(): boolean {
    if (this.openShift.position.length == 0 || this.openShift.unit.length == 0 || this.openShift.Shifts.length == 0 || this.openShift.count < 1 || this.openShift.count > 99) {
      return true;
    }
    else {
      //validation for Unique shift/position/unit combination 
      return this.isUniqueShifts();

    }
  }
  public isUniqueShifts(): boolean {
    if (this.openShiftDetails.length == 0) {
      return false;
    }
    else {
      this.isMappedShift = false;
      // // Error if shift with same defination found 
      if (this.openShiftDetails.length > 0) {
        let existingShiftDetails = this.openShiftDetails.concat(this.shiftDetails);

        existingShiftDetails.forEach(element => {
          let position: any = this.openShift.position;
          let m_position: any = element.position;
          let unit: any = this.openShift.unit;
          let m_unit: any = element.unit;
          let shifts: any = this.openShift.Shifts;
          let m_shifts: any = element.Shifts;
          if ((m_position.id == position.id) && (m_unit.id == unit.id) && (m_shifts.id == shifts.id)) {

            this.isMappedShift = true;
          }
        });
      }
      return this.isMappedShift;
    }
  }
  public addNewOpenShift(): void {
    this.openShift.isNewShift = true;
    this.openShift.isAdded = false;
    this.openShiftDetails.unshift(this.openShift);
    this.shiftUpdated = true;
    //adding new row  
    this.openShift = new OpenShiftV2();
    this.openShift.isNewShift = true;
    this.newShiftcount = this.newShiftcount + 1;
  }
  public addOpenShifts(): void {
    this.getSelectedShift();
    this.shiftDetails = this.shiftDetails.concat(this.selectedOpenShift)
    if (this.openShift == null) {

      let tempOpenShift = _.cloneDeep(this.openShift);
      this.shiftDetails.push(tempOpenShift);
    }
    if (this.shiftDetails.length > 0) {
      this.isSelectedShift = true;
    }
    else {
      this.isSelectedShift = false;
    }
    this.resetOpenShift()
    this.refreshGrid();

  }
  public getDisplayMessage(dataItem: OpenShiftV2): boolean {
    let isError: boolean = false;
    if (dataItem.count >= 0) {
      let overageShift = this.getShiftsOverage(dataItem);
      let filledShift = dataItem.pendingAgencyRequests + dataItem.pendingEmployeeRequests + dataItem.scheduledShiftCount+overageShift;
      if (dataItem.count < filledShift) {
        isError = true;
      }
    }
    if (dataItem.count == null || dataItem.count == -1) {
      isError = false;
    }
    return isError;

  }
  public getSelectedShift(): void {
    this.selectedOpenShift = [];
    // update Display Id 
    let tempDisplayId = this.displayId.filter((item, i, ar) => ar.indexOf(item) === i);
    // update selected Shift
    tempDisplayId.forEach(element => {
      this.openShiftDetails.forEach(el => {
        if (el.displayId == element) {
          this.selectedOpenShift.push(el)
        }
      });

    });
  }
  public getUnassignedShiftCount(dataItem): number {
    let unassignedShift = dataItem.openShiftCount - (dataItem.pendingAgencyRequests + dataItem.pendingEmployeeRequests);
    return unassignedShift >= 0 ? unassignedShift : 0
  }
  public getShiftsOverage(dataItem) :number {
    let overageShift = dataItem.openShiftCount - (dataItem.pendingAgencyRequests + dataItem.pendingEmployeeRequests);
    return overageShift < 0 ? Math.abs(overageShift) : 0
  }
  public getTotalShift(dataItem): number {

    let unassignedShift = this.getUnassignedShiftCount(dataItem);
    let overageShift = this.getShiftsOverage(dataItem);
   return unassignedShift + (dataItem.pendingAgencyRequests || 0) + (dataItem.pendingEmployeeRequests || 0) + dataItem.scheduledShiftCount+overageShift
  }
  private refreshGrid(): void {
    if (this.shiftDetails.length == 0) {
      this.isSelectedShift = false;
      this.gridState.view = null;
      return;
    }
    this.pageSize = this.shiftDetails.length;
    this.gridState.view = process(this.shiftDetails, this.gridState.state);

  }

  public resetOpenShift(): void {
    let tempDisplayId = this.displayId.filter((item, i, ar) => ar.indexOf(item) === i);
    tempDisplayId.forEach(element => {
      this.openShiftDetails = this.openShiftDetails.filter(el => el.displayId != element)
    });
    this.openShiftGrid.view = process(this.openShiftDetails, this.state)
    this.displayId = [];
    this.openShift = new OpenShiftV2();
    this.openShift.isNewShift = true;
  }

  public deleteHandler(dataItem: OpenShiftV2): void {
    let deletedShift = this.shiftDetails.filter(el => el.displayId == dataItem.displayId);
    if (!dataItem.isNewShift) {
      deletedShift[0].count = -1;
      this.openShiftDetails = this.openShiftDetails.concat(deletedShift);
      this.openShiftDetails.sort((a, b) => a.displayId < b.displayId ? -1 : 1);
    }
    else {
      this.openShiftDetails = this.openShiftDetails.filter(el => el.displayId != dataItem.displayId);

    }
    this.shiftDetails = this.shiftDetails.filter(el => el.displayId != dataItem.displayId);
    this.refreshGrid();
    this.openShiftGrid.view = process(this.openShiftDetails, this.state)

  }

  public onCancel() {
    this.navigateToDailyUnitGrid()

  }

  public onAdd(): void {
    this.isLoading = true;
    let idealSchedule: OpenShiftSchedule = new OpenShiftSchedule();
    idealSchedule.openShiftsV2 = this.shiftDetails;
    idealSchedule.orgLevelId = this.orgLevelId;
    idealSchedule.requestedDate = this.requestedDate.toLocaleDateString();
    if(this.isModifyPayPeriodApproved) this.approvedPayperiodForOrgLevel(idealSchedule);
    else {
      this.saveIdealScheduleV2(idealSchedule);
    }    
  }

  public navigateToDailyUnitGrid(): void {
    this.router.navigateByUrl(this.getUrlTree());
  }

  public getUrlTree(): UrlTree {
    let params: any = {
      keepOrgLevelBreadcrmb: true,
      dateOn: moment(this.requestedDate).format(appConfig.linkDateFormat)
    };
    return this.router.createUrlTree(
      ['daily_unit_assignment'],
      {
        relativeTo: this.route.pathFromRoot[2],
        queryParams: params
      });
  }
  public pendingShiftDetails(details: any[], value: number) {
    this.openShiftDetails.forEach(element => {
      let m_position: any = element.position;
      let m_unit: any = element.unit;
      let m_shifts: any = element.Shifts;
      if ((m_position.id == parseInt(details[0])) && (m_shifts.name == details[1]) && (m_unit.name == details[2])) {
        element.pendingAgencyRequests = value;
      }

    });

  }

  public approvedPayperiodForOrgLevel(idealSchedule){
    this.scheduleEntryApiService.checkApprovedPayperiodForOrgLevel(this.orgLevel.id , idealSchedule.requestedDate, idealSchedule.requestedDate).then(data =>{
      this.isOrglevelPayPeriodApproved = data;
      if(this.isOrglevelPayPeriodApproved){
         const message = this.openShiftSummaryDetails.canEditScheduleApprovedPeriod ? `This will modify a schedule in an approved pay period and impact the PBJ Calculation for the employee, are you sure you want to continue?` : 'You do not have permissions to modify a schedule in an approved pay period';
        let popupOptions: ConfirmOptions = new ConfirmOptions();
        popupOptions.showCancel = true;
        popupOptions.showOK = this.openShiftSummaryDetails.canEditScheduleApprovedPeriod ? true : false;;
        popupOptions.buttonOKtext = 'Ok';
        popupOptions.buttonCanceltext = this.openShiftSummaryDetails.canEditScheduleApprovedPeriod ? 'Cancel' : 'Ok';
          ConfirmDialog2Component.openDialog(
            'Warning',
            message,
            this.modalService,
            (result: boolean) => {
              if (result) {
                this.saveIdealScheduleV2(idealSchedule);
              }
              else{
                this.isLoading = false;
              }
           },popupOptions)        
      }
      else {
        this.saveIdealScheduleV2(idealSchedule);
      }
    })
  }

  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.isModifyPayPeriodApproved =  config.ModifySchedulesApprovedinPayPeriods;
  }

  private saveIdealScheduleV2(idealSchedule){
    this.detailScreenService.saveIdealScheduleV2(this.orgLevelId, idealSchedule).then((e) => {
      this.shiftRequestService.openShiftRequest(true);
      this.isLoading = false;
      this.notificationsService.success('success', 'Successfully submitted Open shift request');
      this.navigateToDailyUnitGrid();
    }).catch((e) => {
      this.isLoading = false;
      this.navigateToDailyUnitGrid();
    });  
  }

  get isDesktop(): boolean {
   if( this.screenWidth  >= this.appConfig.devices.desktopWidth.min) return true;
  }

  get isTablet(): boolean {
   if((this.screenWidth  >= this.appConfig.devices.mobileWidth.max) && (this.screenWidth <= this.appConfig.devices.tabletWidth.max)) return true;
  }

 get isMobile(): boolean {
  if(this.screenWidth  <= this.appConfig.devices.mobileWidth.max) return true;
 }
  get pendingShiftsColWidth(): number {
    if (this.isDesktop) return 120
    else if(this.isTablet) return 220
    else if(this.isMobile) return 270
 }

 get agencyColWidth(): number {
  if (this.isDesktop) return 120
    else if(this.isTablet) return 200
    else if(this.isMobile) return 250
 }

 get totalShiftsColWidth(): number {
  if (this.isDesktop) return 90
  else return 110
}

  public get getPositionWidth(){
    if(this.screenWidth < 690) return 130
    else return 80
  }
}




import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MomentModule } from 'angular2-moment';

import { CommonModule as CommonAppModule } from '../../common/index';
import { LogiHostComponent } from './components/logi-host.component';
import { services } from './services/index';
import { rootEpics } from '../../state-model/state-model.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        DropDownsModule,
        MomentModule,
        CommonAppModule
    ],
    declarations: [
        LogiHostComponent
    ],
    providers: [
        ...services
    ],
    exports: [
        LogiHostComponent
    ]
})
export class IntegrationModule {
}

import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { DetailsAgencyPosition, DetailsAgencyModel, AgencyAttributesField, AttributesModel, AttributeValue, DetailsPartnerAttributes } from '../../models';
import { DetailsPartner } from "../../../scheduler/models/detail-screen/details-partner";
import { DetailsListAgencyPosition } from '../../models/detail-screen/details-list-agency-position';
import { DateRangeService } from '@progress/kendo-angular-dateinputs';
import * as _ from 'lodash';
import { PendingShiftValue } from '../../models/daily-unit-assignment/add-open-shift';

@Injectable({
  providedIn: 'root',
})
export class ShiftRequestService {
  public subject = new Subject<any>();
  public positionSubject = new Subject<any>();
  public editAgency = new Subject<any>();
  public shiftdetails:any[] =[];
  public displayId = new Subject<any>();
  public updateShift = new Subject<any>();
  public isrequestAgency = new Subject<boolean>();
  public openShiftManagementSubject = new Subject<any>();
  public UpdateShift = new Subject<boolean>();
  public isrequestAgencyStaffCancelled: boolean = false;
  public deletedDisplayId: number;
  public partnerAttributes: DetailsPartnerAttributes[] = [];
  dropDownOptions: any[];
  public shiftRecords: any[] = [];
  public isRequestFromDua = false;
  currentShiftRecords: any[];
  public isOpenShiftClosed =  new Subject<boolean>();  
  public pendingShiftDetails:PendingShiftValue[] =[];
  constructor() {

  }

  sharedGridData: any = null;
  PosistionData: DetailsAgencyPosition[] = null;
  PartnerPostionMapData: Map<string, DetailsListAgencyPosition> = new Map<string, DetailsListAgencyPosition>();
  agencyList: DetailsPartner[] = null;
  public getInentivePayDropDownValues(agencyList) {
    this.dropDownOptions = [];

    if (agencyList && Array.isArray(agencyList) && agencyList.length > 0) {
      agencyList.forEach(element => {
        if (element && element.partner_attributes && Array.isArray(element.partner_attributes) && element.partner_attributes.length > 0) {
          element.partner_attributes?.filter(el => {
            if (el.name == "incentivepayPercent") {
              this.dropDownOptions = _.cloneDeep(el.attr_values);
            }
          });
        }
      });
    }
    return this.dropDownOptions;
  }
  setGridData(data) {
    this.sharedGridData = data;
  }

  getGridData() {
    return this.sharedGridData;
  }

  sendclickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  positionsClickEvent() {
    this.positionSubject.next();
  }
  public editAgencyStaffRequest(shiftDetails: any): void {
    this.editAgency.next(shiftDetails);
    this.displayId.next(shiftDetails.displayId)
  }
  public editAgencyDetails(): Observable<any> {
    return this.editAgency.asObservable();

  }
  public openShiftRequest(isClosed:boolean): void {
    this.isOpenShiftClosed.next(isClosed);
  }
  public getopenShiftWindowStatus(): Observable<any> {
    return this.isOpenShiftClosed.asObservable();

  }
  public getDisplayId(): Observable<any> {
    return this.displayId.asObservable();
  }
  public updateShifts(addShift: boolean): void {
    this.updateShift.next(addShift);
  }
  public updateShiftRequests(): Observable<any> {
    return this.updateShift.asObservable();
  }
  public updateRequestAgency(): void {
    this.isrequestAgency.next(true)
  }
  public getshiftChanges(): Observable<boolean> {
    return this.isrequestAgency.asObservable();
  }
  redirectPositionMappingEvent(): Observable<any> {
    return this.positionSubject.asObservable();
  }

  filterOpenShiftsData(data) {
    let arr = [];
    if (data && data.length > 0) {
      data.forEach((elem, i) => {
        if (
          (data[i].fields.find((a) => a.name === 'EmpId').value === 0 ||
            data[i].fields.find((a) => a.name === 'EmpId').value === '0') &&
          data[i].fields.find((a) => a.name === 'SlxpartnerdataId').value === '0' &&
          data[i].fields.find((a) => a.name === 'MessageCount').value === '0'
        ) {
          arr.push(data[i]);
        }
      });
    }
    return arr;
  }

  getValue(shiftArr, fieldName) {
    let returnData = null;
    shiftArr.forEach((el) => {
      if (el.name === fieldName) {
        returnData = el.value;
      }
    });
    return returnData;
  }

  filterGridData(data, agencyDetails) {
    let DataArr = [];
    data.forEach((elem, i) => {
      let InPar = data[i].fields ? this.getValue(data[i].fields, 'InPar') : '';
      let EmpId = data[i].fields ? this.getValue(data[i].fields, 'EmpId') : '';
      let EmpName = data[i].fields ? this.getValue(data[i].fields, 'EmpName') : '';
      let DepartmentId = data[i].fields ? this.getValue(data[i].fields, 'DepartmentId') : '';
      let PositionGroupId = data[i].fields ? this.getValue(data[i].fields, 'PositionGroupId') : '';
      let PositionGroupName = data[i].fields ? this.getValue(data[i].fields, 'PositionGroupName') : '';
      let JobCode = data[i].fields ? this.getValue(data[i].fields, 'JobCode') : '';
      let JobDescription = data[i].fields ? this.getValue(data[i].fields, 'JobDescription') : '';
      let ShiftGroupId = data[i].fields ? this.getValue(data[i].fields, 'ShiftGroupId') : '';
      let ShiftGroup = data[i].fields ? this.getValue(data[i].fields, 'ShiftGroup') : '';
      let ShiftId = data[i].fields ? this.getValue(data[i].fields, 'ShiftId') : '';
      let ShiftName = data[i].fields ? this.getValue(data[i].fields, 'ShiftName') : '';
      let ShiftStart = data[i].fields ? this.getValue(data[i].fields, 'ShiftStart') : '';
      let ShiftEnd = data[i].fields ? this.getValue(data[i].fields, 'ShiftEnd') : '';
      let UnitId = data[i].fields ? this.getValue(data[i].fields, 'UnitId') : '';
      let UnitName = data[i].fields ? this.getValue(data[i].fields, 'UnitName') : '';
      let SchedHours = data[i].fields ? this.getValue(data[i].fields, 'SchedHours') : '';
      let Hours = data[i].fields ? this.getValue(data[i].fields, 'Hours') : '';
      let IsOvertime = data[i].fields ? this.getValue(data[i].fields, 'IsOvertime') : '';
      let PPD = data[i].fields ? this.getValue(data[i].fields, 'PPD') : '';
      let SlxpartnerdataId = data[i].fields ? this.getValue(data[i].fields, 'SlxpartnerdataId') : '';
      let RequeststatusId = data[i].fields ? this.getValue(data[i].fields, 'RequeststatusId') : '';
      let SlxagencyId = data[i].fields ? this.getValue(data[i].fields, 'SlxagencyId') : '';
      let MessageCount = data[i].fields ? this.getValue(data[i].fields, 'MessageCount') : '';
      let PartnerId = data[i].fields ? this.getValue(data[i].fields, 'PartnerId') : '';
      let PositionErrorMessage = true;
      // let DetailsPartners : DetailsPartner[] = agencyDetails;
      // let SeletedAgency:DetailsPartner = agencyDetails[0];
      // let agencyListObj = this.setPartnerModelData(agencyDetails);
      // let AgencyList: DetailsAgencyModel[] = _.cloneDeep(agencyListObj);
      let PartnerErrorMessage = true;
      let objectData = {
        InPar,
        EmpId,
        EmpName,
        DepartmentId,
        PositionGroupId,
        PositionGroupName,
        JobCode,
        JobDescription,
        ShiftGroupId,
        ShiftGroup,
        ShiftId,
        ShiftName,
        ShiftStart,
        ShiftEnd,
        UnitId,
        UnitName,
        SchedHours,
        Hours,
        IsOvertime,
        PPD,
        SlxpartnerdataId,
        RequeststatusId,
        SlxagencyId,
        MessageCount,
        PositionErrorMessage,
        PartnerId,
        // DetailsPartners,
        // SeletedAgency,
        // AgencyList,
        PartnerErrorMessage
      };
      DataArr.push(objectData);
    });
    DataArr.sort((a, b) => (a.ShiftStart > b.ShiftStart) ? 1 : -1);
    return DataArr;
  }

  setPartnerModelData(agencyDetails: DetailsPartner[]) {

    let AgencyList: DetailsAgencyModel[] = [];
    let agancyChecked: boolean = true;
    if (agencyDetails.length == 1) {
      agancyChecked = true;
    }

    agencyDetails.forEach(e => {
      let modelData: DetailsAgencyModel = new DetailsAgencyModel();
      modelData.partnerId = e.partner_id;
      modelData.name = e.agency_name;
      modelData.isChecked = agancyChecked;
      modelData.shiftStatus = e.shiftStatus !=null ? e.shiftStatus : null;
      modelData.agencyId = e.agency_id;
      modelData.sub_type_id = e.sub_type_id;
      modelData.sub_type_name = e.sub_type_name;
      if (e.partner_attributes.length > 0) {
        this.getFormFields(e.partner_attributes, e.agency_id).subscribe( result =>
          {modelData.partner_attributes = result;}
        );
        modelData.attributes = this.setPartnerAttibutes(e.partner_attributes);
      } else {
        modelData.partner_attributes = [];
        modelData.attributes = [];
      }
      AgencyList.push(modelData);
    });
    return AgencyList;

  }

  setPartnerAttibutes(agencyAttributeData: DetailsPartnerAttributes[]) {
    let attributeList: AttributesModel[] = [];
    if (agencyAttributeData.length > 0) {
      agencyAttributeData.forEach((item: DetailsPartnerAttributes) => {
        let attrValuesList: AttributeValue[] = [];
        let attrobj: AttributesModel = new AttributesModel();
        if (item.type == "dropdown") {
          let dropdownValues = item.attr_values;
          dropdownValues.forEach(element => {
            if (element.id == item.attr_default_values) {
              attrValuesList.push(element);
            }
          });
        }
        if (item.type == "checkbox") {
          attrobj.value = item.attr_default_values == "true";
          attrValuesList = item.attr_values;
        }
        if (item.type == "textbox") {
          attrobj.value = item.attr_default_values;
        }
        attrobj.name = item.name;
        attrobj.id = 0;
        attrobj.partnerAttrId = 0;
        attrobj.attrValues = attrValuesList;
        attributeList.push(attrobj);
      });
    }
    return attributeList;
  }

  getFormFields(agencyAttributeData: DetailsPartnerAttributes[], agencyId: number) {
    const inputs: AgencyAttributesField<string>[] = [];
    agencyAttributeData.forEach((e: DetailsPartnerAttributes) => {
      let formField: AgencyAttributesField<string> = new AgencyAttributesField<string>();
      if (e.type == "textbox") {
        formField = new AgencyAttributesField<string>({
          controlType: e.type,
          key: e.name,
          label: e.display_name,
          type: e.type,
          required: true,
          validator: "number",
          order: e.order,
          value: e.attr_values.toString(),
          id: agencyId + "_" + e.name,
        })
      }

      if (e.type == "checkbox") {
        formField = new AgencyAttributesField<string>({
          controlType: e.type,
          key: e.name,
          label: e.display_name,
          required: true,
          order: e.order,
          id: agencyId + "_" + e.name,
        });
      }
      if (e.type == "dropdown") {
        let dropdownData = e.attr_values;
        formField = new AgencyAttributesField<string>({
          controlType: e.type,
          key: e.name,
          label: e.display_name,
          options: dropdownData,
          order: e.order,
          id: agencyId + "_" + e.name
        });
      }
      inputs.push(formField);
    });
    let finalInputData = inputs.sort((a, b) => a.order - b.order);
    return of(finalInputData);
  }

  setPartnerPositionMapData(key: string, data: DetailsAgencyPosition[]) {
    let detailsListAgencyPosition: DetailsListAgencyPosition = this.PartnerPostionMapData.get(key);
    if (detailsListAgencyPosition == null) {
      detailsListAgencyPosition = new DetailsListAgencyPosition();
    }
    detailsListAgencyPosition.positions = data;
    this.PartnerPostionMapData.set(key, detailsListAgencyPosition);
  }

  getPartnerPositionMapData() {
    return this.PartnerPostionMapData;
  }

  setAgencies(data: DetailsPartner[]) {
    this.agencyList = data;
  }

  getAgencies() {
    return this.agencyList;
  }

 
  public getPartnerAttribute(name: string): AttributeValue {
    let attrValues: AttributeValue = new AttributeValue();
    attrValues.name = name;
    attrValues.value = true;
    return attrValues;
  }

  public getPartnerModel(agencyDetails: DetailsAgencyModel[]) {
    let AgencyList: DetailsAgencyModel[] = [];
    let agancyChecked: boolean = true;
    if (agencyDetails.length == 1) {
      agancyChecked = true;
    }
    
    agencyDetails.forEach(e => {
      let modelData: DetailsAgencyModel = new DetailsAgencyModel();
      modelData.partnerId = e.partnerId;
      modelData.name = e.name;
      modelData.isChecked = agancyChecked;
      modelData.shiftStatus = e.shiftStatus !=null ? e.shiftStatus : null;
      modelData.agencyId = e.agencyId;
      if (e.attributes.length > 0) {
        // modelData.partner_attributes = this.getFormFields(e.partner_attributes, e.agency_id);
        modelData.attributes = this.getPartnerAttibutes(e.attributes);
      } else {
        modelData.partner_attributes = [];
        modelData.attributes = [];
      }
      AgencyList.push(modelData);
    });
   
    return AgencyList;

  }
  getPartnerAttibutes(agencyAttributeData: AttributesModel[]) {
    let attributeList: AttributesModel[] = [];
    if (agencyAttributeData.length > 0) {
      agencyAttributeData.forEach((item: AttributesModel) => {
        let attrValuesList: AttributeValue[] = [];
        let attrobj: AttributesModel = new AttributesModel();
       // item.attr_values[0].value = true;
        attrValuesList = this.getPartnerAttributeValues(item.attrValues);

        attrobj.name = item.name
        attrobj.id = 0;
        attrobj.value = item.value;
        attrobj.partnerAttrId = 0;
        attrobj.attrValues = attrValuesList;
        attributeList.push(attrobj);

      });
      return attributeList;
    }
  }
  getPartnerAttributeValues( agencyAttributeValues : AttributeValue[] ) {
    let attributeValuesList: AttributeValue[] = [];
    if (agencyAttributeValues.length > 0) {
      agencyAttributeValues.forEach((item: AttributeValue) => {
        attributeValuesList.push( _.cloneDeep(item));
      });
    }
    return attributeValuesList;
  }
}
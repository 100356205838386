import { Component, OnDestroy, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

import { unsubscribe } from '../../../../core/decorators/index';
import { DailyPunchesManagementService } from '../../../services/index';
import { LinePunch, EmployeeDailyPunches, TimeCardModel, DailyLinePunchActions, TimeCardDisplayOptions } from '../../../models/index';
import { DailyTimecardManagementService } from '../../../services/index';
import { ChangeManagementService } from '../../../../common/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-section-punches-management',
  templateUrl: 'section-punches-management.component.html',
  styleUrls: ['section-punches-management.component.scss'],
  providers: [DailyPunchesManagementService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionPunchesManagementComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public timecard: TimeCardModel;

  @Output()
  public applyChanges: EventEmitter<LinePunch[]>;
  @Output()
  public changingPunches: EventEmitter<LinePunch[]>;

  public userActions: DailyLinePunchActions;
  public displayOptions: TimeCardDisplayOptions;

  public state: {
    isLoading: boolean;
  };
  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private startChangingPunchesSubscription: Subscription;
  @unsubscribe()
  private changingPunchesSubscription: Subscription;
  @unsubscribe()
  private cancelChangingPunchesSubscription: Subscription;
  @unsubscribe()
  private discardChangesSubscription: Subscription;
  @unsubscribe()
  private canEditChangedSubscription: Subscription;
  @unsubscribe()
  private optionsChangedSubscription: Subscription;


  private changeGroup: string = 'SectionPunchesManagementComponent';

  constructor(private timecardsManagementService: DailyTimecardManagementService, private managementService: DailyPunchesManagementService,
    private changeDetector: ChangeDetectorRef, private changeManagementService: ChangeManagementService) {
    this.state = {
      isLoading: false
    };
    this.applyChanges = new EventEmitter<LinePunch[]>();
    this.changingPunches = new EventEmitter<LinePunch[]>();

    this.userActions = new DailyLinePunchActions(true, false);
  }

  public ngOnInit(): void {
    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });

    this.startChangingPunchesSubscription = this.managementService.onStartChangingPunches$
      .subscribe(() => {
        this.timecardsManagementService.onPunchesEditAction(true);
      });

    this.cancelChangingPunchesSubscription = this.managementService.onCancelChangingPunches$
      .subscribe(() => {
        this.timecardsManagementService.onPunchesEditAction(false);
      });

    this.changingPunchesSubscription = this.managementService.onChangingPunches$
      .subscribe((changes: LinePunch[]) => {
        this.timecard.dailyPunches = changes;
        this.managementService.markClosingPunches(this.timecard.dailyPunches);
        const res = this.managementService.validatePairs(this.timecard.dailyPunches);
        this.timecard.hasMissingPunches = res.hasInMissingPunch || res.hasOutMissingPunch;
        this.managementService.setPanches(this.timecard.dailyPunches);
        this.timecardsManagementService.onPunchesEditAction(false);
        this.changeManagementService.changeNotify(this.changeGroup);
      });

    this.discardChangesSubscription = this.managementService.onDiscardChanges$
      .subscribe(() => {
        this.timecardsManagementService.onPunchesDiscardAction();
        this.managementService.setPanches(this.timecard.dailyPunches);
        this.changeManagementService.clearChanges(this.changeGroup);
      });

    this.canEditChangedSubscription = this.timecardsManagementService.canEditChanged$
      .subscribe((canEdit: boolean) => {
        this.managementService.canEditChanged(canEdit);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

      this.optionsChangedSubscription = this.timecardsManagementService.displayOptionSelected$
      .subscribe((options: TimeCardDisplayOptions) => {
        this.displayOptions = options;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['timecard'] && this.timecard) {
      this.managementService.setPanches(this.timecard.dailyPunches);
      this.managementService.canEditChanged(this.timecard.canEditTimecard && this.timecard.canEditOwnTimecard);
      const res = this.managementService.validatePairs(this.timecard.dailyPunches);
      this.timecard.hasMissingPunches = res.hasInMissingPunch || res.hasOutMissingPunch;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }
}


export * from './punch-attestation/punch-attestation.component';
export * from './punch-attestation-header/punch-attestation-header.component';
export * from './punch-attestation-section/punch-attestation-section.component';

import { PunchAttestationComponent } from './punch-attestation/punch-attestation.component';
import { PunchAttestationHeaderComponent } from './punch-attestation-header/punch-attestation-header.component';
import { PunchAttestationSectionComponent } from './punch-attestation-section/punch-attestation-section.component';

export const components: any = [
  PunchAttestationComponent,
  PunchAttestationHeaderComponent,
  PunchAttestationSectionComponent
];

import { AppServerConfig } from './../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../app-settings/services/app-settings-manage.service';
import { Injectable, isDevMode } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import {
  Idle, DEFAULT_INTERRUPTSOURCES,
  DocumentInterruptSource, StorageInterruptSource
} from '@ng-idle/core';
import { unsubscribeInService } from '../../../core/decorators/index';

import * as moment from 'moment';
import { Subscription ,  Subject } from 'rxjs';

import { Assert } from '../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { IDestroyService, ThrottlingChangeEvent, TabMode } from '../../../core/models/index';
import { ThrottlingService } from '../throttling/throttling.service';

@Injectable()
export class UserActivityService implements IDestroyService {

  public onTimeoutThresholdReached: Subject<any>;
  public onInactivityTimedOut: Subject<any>;
  public onIncativityReseted: Subject<any>;
  public onNeedToHideIncativityDialog: Subject<any>;
  public onTimeoutCountdown: Subject<number>;

  @unsubscribeInService()
  public onNavigationStarted: Subscription;
  @unsubscribeInService()
  public throttleSubscrtiption: Subscription;

  public get isActivityTimeoutReached(): boolean {
    return this.m_activityTimeoutReached;
  }

  private state: serviceState = serviceState.initial;
  private appConfig: IApplicationConfig;
  private m_activityTimeoutReached: boolean = false;
  private isThresholdReached: boolean = false;
  private appServerConfig: AppServerConfig;
  private servicesInited: boolean;

  constructor(private idle: Idle, private throttle: ThrottlingService, private router: Router, private appSettingsManageService: AppSettingsManageService) {
    Assert.isNotNull(idle, 'idle');
    Assert.isNotNull(router, 'router');
    this.onNavigationStarted = this.router.events.subscribe((value: NavigationStart) => {
      if (!!value && !!this.idle) {
        this.idle.interrupt();
      }
    });

    this.onTimeoutThresholdReached = new Subject();
    this.onInactivityTimedOut = new Subject();
    this.onNeedToHideIncativityDialog = new Subject();
    this.onIncativityReseted = new Subject();
    this.onTimeoutCountdown = new Subject();
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public async start(): Promise<void> {
    if (this.state !== serviceState.started && this.state !== serviceState.starting) {
      this.state = serviceState.starting;
      if (!this.servicesInited) {
        await this.initServices();
      }
      this.startWatcher();
    }
  }

  public stop(): void {
    if (this.state === serviceState.started || this.state === serviceState.starting) {
      this.idle.stop();
      this.state = serviceState.stoped;
    }
  }

  public reset(): void {
    this.idle.interrupt();
  }

  public get thresholdSeconds(): number {
    return this.appServerConfig.thresholdSeconds;
  }

  public get timeoutSeconds(): number {
    return this.appServerConfig.timeoutSeconds;
  }

  private startWatcher(): void {
    try {
      if (this.state === serviceState.started)
        throw new Error('Cannot start activity service since it has already beed started.');
      this.isThresholdReached = false;
      this.m_activityTimeoutReached = false;

      this.idle.watch();

      this.state = serviceState.started;

      if (isDevMode() && !appConfig.session.enableInactivityMonitorInDevMode)
        this.stop();

    } catch (error) {
      this.state = serviceState.failed;
      throw error;
    }
  }

  private async initServices(): Promise<void> {
    this.appServerConfig = await this.appSettingsManageService.getAppServerConfig();

    this.idle.setIdle(this.thresholdSeconds);
    this.idle.setTimeout(this.timeoutSeconds);
    let interruptSources: any[] = [new DocumentInterruptSource('keydown mousedown touchstart'), new StorageInterruptSource()];
    //this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.setInterrupts(interruptSources);

    let idleName: string = `user-inactivity-idle${moment().format()}`;

    this.idle.setIdleName(idleName);
    this.subscribeToIdle();
    this.subscribeToThrottle();
    this.servicesInited = true;
  }

  private subscribeToIdle(): void {
    this.idle.onTimeout.subscribe(() => {
      this.m_activityTimeoutReached = true;
      this.state = serviceState.stoped;
      this.onNeedToHideIncativityDialog.next();
      this.onInactivityTimedOut.next();
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.isThresholdReached = false;
      this.onIncativityReseted.next();
    });
    this.idle.onIdleStart.subscribe(() => {
      if (!this.isThresholdReached) {
        this.isThresholdReached = true;
        this.onTimeoutThresholdReached.next();
      }
    });
    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      this.onTimeoutCountdown.next(countdown);
    });
  }

  private subscribeToThrottle(): void {
    this.throttle.reset();
    this.throttleSubscrtiption = this.throttle.subscribeToModeChange((e: ThrottlingChangeEvent) => {
      // if we coming back to front from background tab intervals possible where paused and we should check real time elapsed
      if (e.mode === TabMode.FRONT) {
        let totalTimeToLogin: number = this.thresholdSeconds + this.timeoutSeconds;
        if (e.timeFromChangeMode > totalTimeToLogin * 1000 && !this.m_activityTimeoutReached) {
          this.stop();
          this.m_activityTimeoutReached = true;
          this.onNeedToHideIncativityDialog.next();
          this.onInactivityTimedOut.next();
        }
      }
    });
  }
}

enum serviceState {
  initial = 0,
  started = 1,
  stoped = 2,
  starting = 3,
  failed = -1
}

import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import {
  KendoGridCustomSelectionHelper,
  KendoGridStateHelper,
  Month
} from '../../../common/index';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { ConfirmDialogComponent } from './../../../common/components/confirm-dialog/confirm-dialog.component';
import { ModalService } from '../../../common/services/modal/modal.service';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { AccessManagementService } from '../../services/accessManagement/access-management.service';
import { HolidaysApiService } from '../../services/index';
import { Holiday } from '../../models/index';
import { HolidaysConfigurationManagementService } from '../../services/holidays/holidays-configuration-management.service';
import { NgForm } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-holidays',
  templateUrl: 'holidays.component.html',
  styleUrls: ['holidays.component.scss'],
  providers: [HolidaysConfigurationManagementService, AccessManagementService]
})

export class HolidaysComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig = appConfig;

  public onAddItem: () => void;
  public onDeletedItems: () => void;

  public crudHelper: ConfigurationComponentHelper<Holiday>;
  public gridState: KendoGridStateHelper<Holiday>;
  public selectionHelper: KendoGridCustomSelectionHelper<Holiday>;

  public state: {
    isLoading: boolean;
    configureMode: boolean;
    copyMode: boolean;
    isEditing: boolean;
  };

  public yearItems: number[];
  public currentYear: number;

  @destroyService()
  public management: HolidaysConfigurationManagementService;

  private months: Month[];

  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private editSubscription: Subscription;
  @unsubscribe()
  private savedSubscription: Subscription;
  @unsubscribe()
  private removeSubscription: Subscription;
  @unsubscribe()
  private addSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private mainFormSubscription: Subscription;

  @ViewChild('templateForm')
  private mainForm: NgForm;

  @ViewChild('kendoGrid')
  private set grid(value: GridComponent) {
    if (this.crudHelper) this.crudHelper.grid = value;
    this.m_grid = value;
  }

  private get grid(): GridComponent {
    return this.m_grid;
  }

  private m_grid: GridComponent;

  constructor(management: HolidaysConfigurationManagementService,
    public access: AccessManagementService,
    private modalService: ModalService) {
    this.management = management;
    this.currentYear = moment().year();
    this.yearItems = _.range(this.currentYear - 5, this.currentYear + 4, 1);

    this.gridState = new KendoGridStateHelper<Holiday>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
    this.crudHelper = new ConfigurationComponentHelper<Holiday>();
    this.crudHelper.gridState = this.gridState;
    this.crudHelper.selectionHelper = this.selectionHelper;
    this.crudHelper.management = management;

    // directive intercepted methods (scope bug)
    this.onAddItem = () => {
      let item: Holiday = new Holiday();
      item.date = moment().startOf('day').toDate();
      item.start = moment().startOf('day').subtract(1, 'hours').toDate();
      item.end = moment().startOf('day').add(23, 'hours').toDate();
      this.crudHelper.addItem(item);
    };

    this.onDeletedItems = () => {
      this.crudHelper.deleteSelected();
    };
  }

  public ngOnInit(): void {
    this.state = {
      isLoading: false,
      configureMode: true,
      copyMode: false,
      isEditing: false
    };

    this.stateSubscription = this.management.onStateChanged$.subscribe((state: { isLoading: boolean, configureMode: boolean, copyMode: boolean }) => {
      if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
      if (_.has(state, 'configureMode')) this.state.configureMode = state.configureMode;
      if (_.has(state, 'copyMode')) this.state.copyMode = state.copyMode;
    });

    this.editSubscription = this.management.editItemCmd$.subscribe((item: Holiday) => {

      if (item) {

        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
          if (this.mainForm && this.mainForm.dirty) {
            this.management.markAsDirty();
          }
        });
      } else {
        if (this.mainFormSubscription) {
          this.mainFormSubscription.unsubscribe();
        }
      }

      this.state.isEditing = (item !== null);

    });

    this.savedSubscription = this.management.onItemSaved$.subscribe((item: Holiday) => {
      this.state.isEditing = false;
      this.gridState.refreshGrid();
    });

    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: Holiday[]): void => {
      this.crudHelper.selectionChange(_.first(records), 0);
    });

    this.removeSubscription = this.management.removeItemsCmd$.subscribe((item: Holiday) => {
      this.showRemoveDialog(item);
    });

    this.addSubscription = this.management.addItemCmd$.subscribe((item: Holiday) => {
      this.state.isEditing = true;
    });

    this.crudHelper.grid = this.grid;
    this.crudHelper.init();
    this.management.setYear(this.currentYear);
    this.management.init();
  }

  // Must be, see #issueWithAOTCompiler
  public ngOnDestroy(): void {
    if (this.crudHelper) {
      this.crudHelper.destroy();
    }
  }

  public onSelectYear(year: number): void {
    this.currentYear = year;
    this.management.setYear(this.currentYear);
  }

  public saveItem(): void {
    this.crudHelper.onSaveItem({ dataItem: this.management.editingItem, isNew: this.management.isEditingNewItem });
  }

  public discardChanges(): void {
    this.crudHelper.cancelEdit(this.gridState.editedRowIndex);
  }

  public showRemoveDialog(selected: Holiday): void {
    ConfirmDialogComponent.openDialog('Remove holidays', 'Do you want to delete the holiday?', this.modalService,
      (result: boolean) => {
        if (result) {
          this.management.doRemoveItem(selected);
        }
      }
    );
  }

  public onCopyItems(event: MouseEvent): void {
    event.preventDefault();
    this.management.openCopyItems();
  }

  public switchToConfigure(): void {
    this.management.closeCopyItems();
  }
}

import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

import * as moment from 'moment';
import * as _ from 'lodash';

import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/index';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { VacationPlanSettings, IVacationPlanColumn, ShiftReplacementRequest } from '../../../models/index';

import { ModalService } from '../../../../common/services/modal/modal.service';
import { ShiftReplacementOpenComponent } from '../../shift-replacement/shift-replacement-open/shift-replacement-open.component';
import { StateManagementService } from '../../../../common/services/index';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { ControlStateKey, IControlState, StateResetTypes } from '../../../../core/models/settings/index';


@Component({
  moduleId: module.id,
  selector: 'slx-vacation-planner-header',
  templateUrl: 'vacation-planner-header.component.html',
  styleUrls: ['vacation-planner-header.component.scss'],
  providers: [StateManagementService]
})
export class VacationPlannerHeaderComponent implements OnInit, OnDestroy {

  @Output()
  public settingsChanged: EventEmitter<VacationPlanSettings>;

  public appConfig: IApplicationConfig;
  public yearItems: number[];
  public currentYear: number;
  public settings: VacationPlanSettings;
  public columnsMenuOpened: boolean;

  @unsubscribe()
  private loadSubscription: Subscription;

  private m_yearFilterControlId: string = 'YearFiltersNgx';
  private m_columnFilterControlId: string = 'ColumnFiltersNgx';
  private m_resetType: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
  private stateKey: ControlStateKey;

  constructor(private modalService: ModalService,
              private stateManagement: StateManagementService,
              private storageService: ComponentStateStorageService) {
    this.settingsChanged = new EventEmitter<VacationPlanSettings>();
    this.currentYear = moment().year();
    this.yearItems = _.range(this.currentYear - 5, this.currentYear + 4, 1);
  }

  public ngOnInit(): void {
    this.stateManagement.init('VacationPlannerHeaderComponent', false);

    this.appConfig = appConfig;
    this.settings = new VacationPlanSettings();
    this.settings.reload = true;
    this.settings.year = this.currentYear;
    this.settings.columns = [
      {
        name: 'position',
        description: 'Position',
        displayed: false,
        sortField: 'position.name'
      },
      {
        name: 'type',
        description: 'Type',
        displayed: false,
        sortField: 'employee.employeeType.name'
      },
      {
        name: 'dateOfHire',
        description: 'Date of Hire',
        displayed: false,
        sortField: 'employee.dateHired'
      },
      {
        name: 'unit',
        description: 'Unit',
        displayed: false,
        sortField: 'unit.name'
      },
      {
        name: 'shift',
        description: 'Shift',
        displayed: false,
        sortField: 'shift.name'
      },
      {
        name: 'group',
        description: 'Group',
        displayed: false,
        sortField: 'position.group'
      },
      {
        name: 'days',
        description: 'Days',
        displayed: false,
        sortField: 'vacationDays'
      },
      {
        name: 'hours',
        description: 'Hours',
        displayed: false,
        sortField: 'vacationHours'
      }
    ];
    this.loadSubscription = this.stateManagement.loadData$.subscribe(() => {
      // this.restoreStates();
    });

    this.restoreStates();
    this.settingsChanged.emit(_.cloneDeep(this.settings));
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onSelectYear(year: number): void {
    this.settings.reload = true;
    this.settings.year = year;
    this.saveYearFilterStates();
    this.settingsChanged.emit(_.cloneDeep(this.settings));
  }
  public columnSelected(): void {
    this.settings.reload = false;
    this.saveColumnStates();
    this.settingsChanged.emit(_.cloneDeep(this.settings));
    this.columnsMenuOpened = false;
  }
  public toggleColumnsMenu(): void {
    this.columnsMenuOpened = !this.columnsMenuOpened;
  }

  public selectAll(event: MouseEvent): void {
    _.forEach(this.settings.columns, (column: IVacationPlanColumn) => {
      column.displayed = true;
    });
  }
  public clearAll(event: MouseEvent): void {
    _.forEach(this.settings.columns, (column: IVacationPlanColumn) => {
      column.displayed = false;
    });
  }
   public onOpenShift(): void {
    let request: ShiftReplacementRequest = new ShiftReplacementRequest();
    request.shiftId = 11;
    request.positionId = 258;
    request.date = new Date();
    request.showDayOffEmployees = true;

    ShiftReplacementOpenComponent.openDialog(request, 1, this.modalService, (result: boolean) => {
      if (result) {
        //open shift api call
      }
    });
  }

  private saveYearFilterStates(): void {
    this.storageService.setControlState(this.stateManagement.componentKey, this.m_yearFilterControlId,
      {
        value: { year: this.settings.year }
      },
      this.m_resetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.m_yearFilterControlId);
  }

  private saveColumnStates(): void {
    let value: any = {};
    _.forEach(this.settings.columns, (column: IVacationPlanColumn) => {
      value[column.name] = column.displayed;
    });
    this.storageService.setControlState(this.stateManagement.componentKey, this.m_columnFilterControlId,
      {
        value: value
      },
      this.m_resetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.m_yearFilterControlId);
  }

  private restoreStates(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.m_yearFilterControlId);
    if (state && state.value) {
      this.settings.year = state.value.year;
    }

    state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_columnFilterControlId);
    if (state && state.value) {
      _.forEach(this.settings.columns, (column: IVacationPlanColumn) => {
        column.displayed = state.value[column.name];
      });
    }
  }
}


import { Injectable } from '@angular/core';
import { BenAdminLink, IBenAdminLink } from '../../../models/index';

@Injectable()
export class BenAdminMenuMapService {
  public mapToModel(item: IBenAdminLink): BenAdminLink {
    let link: BenAdminLink = new BenAdminLink();
    link.linkUrl = item.link_url;
    return link;
  }
}

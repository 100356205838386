import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Subscription ,  Subject } from 'rxjs';

import { Assert } from '../../../framework/assert/assert';
import { ScheduleApiService } from './schedule-api.service';

import { AutoSchedulingConfig, IAutoSchedulingConfig, AutoSchedulingState, IAutoSchedulingState } from '../../models';

@Injectable()
export class MasterScheduleAutoSchedulingService {
  private autoSchedulingSettings$: Subject<AutoSchedulingState>;
  private onLoading$: Subject<boolean>;

  constructor(
    private scheduleApiService: ScheduleApiService
  ) {
    this.autoSchedulingSettings$ = new Subject<AutoSchedulingState>();
    this.onLoading$ = new Subject<boolean>();
  }

  public init(orgLevelId: number): void {
    this.loadAutoSchedulingConfig(orgLevelId);
  }

  public generateDays(length: number): Array<{ id: number, day: number }> {
    const days: number[] = _.range(1, length + 1);

    return _.map(days, (day: number) => ({ id: day, day}));
  }

  public saveAutoScheduling(orgLevelId: number, config: AutoSchedulingConfig): Promise<any> {
    this.onLoading(true);
    return this.scheduleApiService.saveAutoScheduling(orgLevelId , config)
      .then((config: AutoSchedulingState) => {
        this.onLoading(false);
        this.autoSchedulingSettings$.next(config);
      })
      .catch((e: any) => {
        console.info(e);
        this.onLoading(false);
      });
  }

  public deleteAutoScheduling(orgLevelId: number): Promise<any> {
    this.onLoading(true);
    return this.scheduleApiService.deleteAutoScheduling(orgLevelId)
      .then(() => {
        this.onLoading(false);
      })
      .catch((e: any) => {
        console.info(e);
        this.onLoading(false);
      });
  }

  public subscribeToAutoScheduling(callback: (config: AutoSchedulingState) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.autoSchedulingSettings$.subscribe(callback);
  }

  public subscribeToLoadingState(callback: (isLoading: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.onLoading$.subscribe(callback);
  }

  private loadAutoSchedulingConfig(orgLevelId: number): void {
    this.onLoading(true);
    this.scheduleApiService.getAutoScheduling(orgLevelId)
      .then((config: AutoSchedulingState) => {
        this.autoSchedulingSettings$.next(config);
        this.onLoading(false);
      })
      .catch((e: any) => {
        console.info(e);
        this.onLoading(false);
      });
  }

  private onLoading(isLoading: boolean): void {
    this.onLoading$.next(isLoading);
  }
}

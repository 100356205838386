
import {of as observableOf,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';
import * as _ from 'lodash';
import { Component, Provider, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { DialogOptions, IDialog } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { IncidentReportDialogOptions } from '../../../models/index';
import { screenUtils } from '../../../../../common/utils/screenUtils';

@Component({
  moduleId: module.id,
  selector: 'slx-wc-incident-report-dialog',
  templateUrl: 'wc-incident-report-dialog.component.html',
  styleUrls: ['wc-incident-report-dialog.component.scss']
})
export class WCIncidentReportDialogComponent implements IDialog, AfterViewInit {

  public dialogOptions: IncidentReportDialogOptions;
  public dialogResult: boolean;

  @ViewChild('container', { read: ElementRef, static: true })
  private containerElem: ElementRef;
  private options: DialogOptions;
  private modalService: ModalService;

  private readonly height = {
    employeeSearchHeight: 260,
    incidentReportHeight: 600
  };

  private readonly extraHeightIndent = 40;

  public static openDialog(options: IncidentReportDialogOptions, modalService: ModalService, callback?: (result: boolean) => void): WCIncidentReportDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 800;
    dialogOptions.height = 260;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.showCloseButton = false;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: IncidentReportDialogOptions,
        useValue: options
      }
    ];

    let titleString = 'Create New Incident Report';
    if (!_.isNil(options) && _.isFinite(options.reportId)) {
      titleString = 'Edit Incident Report';
    }

    return modalService.globalAnchor.openDialog(
      WCIncidentReportDialogComponent,
      titleString,
      dialogOptions,
      resolvedProviders,
      callback
    );
  }

  constructor (
    dialogOptions: IncidentReportDialogOptions,
    options: DialogOptions,
    modalService: ModalService,
  ) {
    this.dialogOptions = dialogOptions;
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public ngAfterViewInit(): void {
    if (!_.isNil(this.dialogOptions)) {
      if (_.isFinite(this.dialogOptions.employeeId) || _.isFinite(this.dialogOptions.reportId)) {
        const subscr = observableOf(true).pipe(
          delay(100))
          .subscribe(() => {
            subscr.unsubscribe();
            this.updatePopupHeight(_.isFinite(this.dialogOptions.reportId) || _.isFinite(this.dialogOptions.employeeId));
          });
        return;
      }
      this.updatePopupHeight(_.isFinite(this.dialogOptions.reportId) || _.isFinite(this.dialogOptions.employeeId));
    }
  }

  public onAction(isSave: boolean): void {
    if (isSave) {
      this.save();
    } else {
      this.cancel();
    }
  }

  public save(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public cancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onEmployeeSelected(empId: number): void {
    if (this.dialogOptions) {
      this.dialogOptions.employeeId = empId;
    }
    this.updatePopupHeight(true);
  }

  private updatePopupHeight(isReportState: boolean): void {
    const container: HTMLElement = this.containerElem.nativeElement;
    if (_.isElement(container) && !screenUtils.isMobile) {
      const popup = $(container).closest('kendo-popup');
      if (popup.length > 0) {
        if (!isReportState) {
          $(popup).css('height', this.height.employeeSearchHeight + this.extraHeightIndent);
        } else {
          $(popup).css('height', this.height.incidentReportHeight + this.extraHeightIndent);
        }
      }
    }
  }
}

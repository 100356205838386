import { OnInit, Input, Provider, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { Subject ,  Observable ,  Subscription } from 'rxjs';
import { OrgLevel } from '../../../state-model/models/index';
import { EmployeeDetails, OpenEmplyeesDetails } from '../../../organization/models/index';
import { Assert } from '../../../framework/index';
import { ModalService } from '../../../common/index';
import { unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';

import { mutableSelect } from '../../../core/decorators/index';
import { EmployeeDetailsListComponent } from '../../../organization/components/widgets/employee-details-list/employee-details-list.component';

import {
  ArrivalDeparturesContainer, ArrivalDetails
} from '../../models/index';
import { ArrivalsDeparturesApiService } from './arrivals-departures-api.service';
import { IDestroyService, IDateRange } from '../../../core/models/index';
import { ManagementBaseService } from '../../../core/services/index';
import { ArrivalDeparturesFilter } from '../../models/index';

@Injectable()
export class ArrivalDeparturesManagementService extends ManagementBaseService<ArrivalDeparturesContainer, any> {
  public onFilterChanged$: Subject<ArrivalDeparturesFilter>;
  public filter: ArrivalDeparturesFilter;

  constructor(
    private arrivalsDeparturesApiService: ArrivalsDeparturesApiService,
    private modalService: ModalService) {
    super();
    this.arrivalsDeparturesApiService = arrivalsDeparturesApiService;
    this.onFilterChanged$ = new Subject();
    this.filter = new ArrivalDeparturesFilter();
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public loadData(orgLevelId: number, dateOn: Date): void {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(dateOn, 'dateOn');
    this.onLoadStatusChanged(true);
    this.arrivalsDeparturesApiService.getArrivalsDepartures(orgLevelId, dateOn)
      .then((result: ArrivalDeparturesContainer) => {
        this.onLoaded$.next(result);
        this.onLoadStatusChanged(false);
      });
  }

  public onArrivalRangeChanged(range: IDateRange): void {
    this.filter.arrivalsRange = range;
    this.onFilterChanged$.next(this.filter);
  }

  public onDeparturesRangeChanged(range: IDateRange): void {
    this.filter.departuresRange = range;
    this.onFilterChanged$.next(this.filter);
  }
}

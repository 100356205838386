import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MomentModule } from 'angular2-moment';
import { CalendarModule } from '@progress/kendo-angular-dateinputs';
import { NgxPopperModule } from 'ngx-popper';
import { ChartModule, ChartsModule, SparklineModule } from '@progress/kendo-angular-charts';

import { CommonModule as CommonAppModule } from '../../common/common.module';
import { OrganizationModule } from '../../organization/index';
import { ComponentsLibraryModule } from '../../components-library/index';
import { scheduleConsoleComponents, exportScheduleConsoleComponents, scheduleConsoleEntryComponents } from './components/index';
import { scheduleConsoleServices } from './services/index';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';

@NgModule({
    imports: [
        CommonModule,
        ComponentsLibraryModule,
        CommonAppModule,
        OrganizationModule,
        MomentModule,
        FormsModule,
        CalendarModule,
        NgxPopperModule,
        ChartModule,
        ChartsModule,
        SparklineModule,
        GridModule,
        ExcelModule,
        PDFModule
    ],
    declarations: [
        ...scheduleConsoleComponents
    ],
    providers: [
        ...scheduleConsoleServices
    ],
    exports: [
        ...exportScheduleConsoleComponents
    ]
})
export class ScheduleConsoleModule {
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { unsubscribeAll } from '../../../../core/decorators/index';
import { ColumnManagementService, StateManagementService } from '../../../../common/services/index';

import { AccrualsTransactionsManagementService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-accruals-transactions',
  templateUrl: 'accruals-transactions.component.html',
  styleUrls: ['accruals-transactions.component.scss'],
  providers: [ColumnManagementService, StateManagementService, AccrualsTransactionsManagementService]
})
export class AccrualsTransactionsComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private accrualsManagementService: AccrualsTransactionsManagementService) { }

  public ngOnInit() {
    this.accrualsManagementService.init();
    this.subscriptions.loading = this.accrualsManagementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  public ngOnDestroy(): void {
    this.accrualsManagementService.destroy();
  }
}

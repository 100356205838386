import { Directive, Input, ElementRef, HostListener, OnInit, OnChanges, SimpleChanges, Host, Output, EventEmitter, Renderer2, Optional, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GridComponent, EditEvent, SaveEvent, CancelEvent, GridDataResult, CellClickEvent, ColumnComponent } from '@progress/kendo-angular-grid';
import * as domUtils from '../../../utils/domUtils';

@Directive({
  selector: '[slxKendoGridRowClick]',
})
export class KendoGridRowClickDirective implements OnInit, OnChanges, OnDestroy {
  @Input('slxKendoGridRowClick')
  public canStartEdit: number;
  @Input()
  public editedRowIndex: number;
  @Input()
  public cancelOnBlur: boolean;
  @Input()
  public ignoreParentClass: string;
  @Input()
  public disableRowClick: string;

  @Output() public editRow: EventEmitter<EditEvent> = new EventEmitter<EditEvent>();
  @Output() public saveRow: EventEmitter<SaveEvent> = new EventEmitter<SaveEvent>();
  @Output() public cancelRow: EventEmitter<CancelEvent> = new EventEmitter<CancelEvent>();
  public currentRowIndex: number;
  public currentDataItem: any;

  private listenerFn: () => void;

  constructor( @Host() private grid: GridComponent, @Optional() private ngForm: NgForm, private el: ElementRef, private renderer: Renderer2) { }

  public ngOnInit(): void {
    this.grid.cellClick.asObservable().subscribe((clickEvent: CellClickEvent) => {

      if (clickEvent.type !== 'click') {
        return;
      }

      if(this.disableRowClick) {
        return;
      }

      if (clickEvent.isEdited || clickEvent.columnIndex === 0) {
        return;
      }
      if (this.currentRowIndex !== undefined && this.currentRowIndex !== clickEvent.rowIndex) {
        if (!this.canLeave()) {
          return;
        }
        this.emitBlur();
        this.currentRowIndex = undefined;
      }
      const ev: Event = clickEvent.originalEvent;
      if (domUtils.isChildOf(ev.target, 'slx-inore-by-kendo-row-click-directive') || (this.ignoreParentClass && domUtils.isChildOf(ev.target, this.ignoreParentClass))) {
        return;
      }
      if (!this.canStartEdit) {
        return;
      }

      this.currentDataItem = this.getDataItem(clickEvent.rowIndex);
      let event: EditEvent = {
        sender: this.grid,
        rowIndex: clickEvent.rowIndex,
        dataItem: this.currentDataItem,
        isNew: false
      };
      this.editRow.emit(event);
    });

    setTimeout(() => {
      if (!this.listenerFn) {
        this.listenerFn = this.renderer.listen('document', 'click', ({ target }) => this.docClickHandler(target));
      }
    }, 100);
  }

  public docClickHandler(target: any): void {
    if(this.disableRowClick) {
      return;
    }
    const tr = domUtils.closest(target, 'tr');
    if (tr && !tr.parentElement) {
      return;
    }
    if (!domUtils.isChildOf(target, 'k-grid-content', this.el.nativeElement) && !domUtils.hasClass(target, 'slx-inore-by-kendo-row-click-directive')) {
      if (this.ignoreParentClass && domUtils.isChildOf(target, this.ignoreParentClass)) {
        return;
      }
      if (this.currentRowIndex !== undefined) {
        if (!this.canLeave()) {
          return;
        }
        this.emitBlur();
      }
    }
  }

  public emitBlur(): void {
    if(this.disableRowClick) {
      return;
    }
    if (!this.cancelOnBlur) {
      let event: SaveEvent = {
        sender: this.grid,
        rowIndex: this.currentRowIndex,
        dataItem: this.getDataItem(this.currentRowIndex),
        isNew: false,
        formGroup: null
      };
      this.saveRow.emit(event);
    } else {
      let event: CancelEvent = {
        sender: this.grid,
        rowIndex: this.currentRowIndex,
        dataItem: this.getDataItem(this.currentRowIndex),
        isNew: false,
        formGroup: null
      };
      this.cancelRow.emit(event);
    }
    this.currentRowIndex = undefined;
  }

  public ngOnDestroy(): void {
    if (this.listenerFn) {
      this.listenerFn();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['canStartEdit']) {
      if (this.canStartEdit) {
        setTimeout(() => {
          this.currentRowIndex = this.editedRowIndex;
        }, 100);
      }
    }
    if (changes['editedRowIndex']) {
      this.currentRowIndex = this.editedRowIndex;
    }
  }

  private canLeave(): boolean {
    if (!this.ngForm) {
      return true;
    }
    if (this.currentRowIndex !== undefined && !this.ngForm.valid) {
      return false;
    }
    return true;
  }
  private getDataItem(rowIndex: number): any {
    let dataItem: any;
    if ((<GridDataResult>this.grid.data).data) {
      dataItem = (<GridDataResult>this.grid.data).data[rowIndex];
    } else {
      dataItem = (<Array<any>>this.grid.data)[rowIndex];
    }
    return dataItem;
  }
}

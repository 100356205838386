import { Provider } from '@angular/core';

export * from './pm-management.service';
export * from './pm-roster-management.service';
export * from './pm-creation-management.service';
export * from './pm-attachment-management.service';
export * from './performance-management-api.service';
export * from './performance-management-map.service';
export * from './pm-configuration-management.service';

import { PmManagementService } from './pm-management.service';
import { PmRosterManagementService } from './pm-roster-management.service';
import { PmCreationManagementService } from './pm-creation-management.service';
import { PmAttachmentManagementService } from './pm-attachment-management.service';
import { PerformanceManagementApiService } from './performance-management-api.service';
import { PerformanceManagementMapService } from './performance-management-map.service';
import { PmConfigurationManagementService } from './pm-configuration-management.service';

export const services: Provider[] = [
  PmManagementService,
  PerformanceManagementApiService,
  PerformanceManagementMapService,
  PmConfigurationManagementService
];


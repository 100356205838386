import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { Subscription ,  Subject } from 'rxjs';
import { Router, NavigationExtras, Params } from '@angular/router';

import { Assert } from '../../../framework/index';
import { Session, AuthResponse, AuthStatusCode } from '../../../state-model/models/index';
import { SessionService } from '../../../core/services/session/session.service';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { LoggerService } from '../../../core/services/logger/logger.service';
import { AuthenticationApiService } from '../authentication-api/authentication.api-service';
import { SessionActions } from '../../actions/index';
import { authenticationConfig } from '../../authentication.config';
import { unsubscribeInService } from '../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { PollingPeriodService } from '../../../core/services/polling-period/polling-period.service';
import { IDestroyService, UserPasswordSettings } from '../../../core/models/index';
import { ForgotPassword, ResetPassword, ILoginEvent, LoginEvent, OAuth2AuthorizeRequest } from '../../models/index';
import { TokenValidityService } from '../../../core/services/token-validity/token-validity.service';
import { IUser } from '../../../authentication/store/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { LocalStorageService } from '../../../core/services';
  

@Injectable()
export class AuthenticationService implements IDestroyService {
  public isAliasChanged: boolean;
  public isAliasFirstOrChanged: boolean;
  public onLogout: Subject<any>;
  public login$: Subject<ILoginEvent>;

  private lastAlias: string;
  private tokenRequestIsInProcess: boolean = false;
  private appConfig: IApplicationConfig = appConfig;
  private inValidIPAddress: boolean = false;

  @unsubscribeInService()
  private checkTokenSubscription: Subscription;
  private applicationHeartbeatService: PollingPeriodService;

  constructor(
    private tokenValidity: TokenValidityService,
    private sessionService: SessionService,
    private authenticationApiService: AuthenticationApiService,
    private sessionActions: SessionActions,
    private router: Router,
    private loggerService: LoggerService,
    private userActivityService: UserActivityService,
    private modalService: ModalService,
    private localStorageService: LocalStorageService
  ) {
    Assert.isNotNull(sessionService, 'sessionService');
    Assert.isNotNull(authenticationApiService, 'authenticationApiService');
    Assert.isNotNull(sessionActions, 'sessionActions');
    Assert.isNotNull(router, 'router');
    Assert.isNotNull(userActivityService, 'userActivityService');
    Assert.isNotNull(tokenValidity, 'tokenValidity');

    this.lastAlias = null;
    this.applicationHeartbeatService = new PollingPeriodService(this.userActivityService, this.tokenValidity);
    if (this.appConfig.session.checkTokenExpirationPeriod < 5) {
      this.loggerService.warning(`Check session expiration period(${this.appConfig.session.checkTokenExpirationPeriod})` +
        ` is too low this can cause to unexpected behavior and extra web service calls. Take in attention.`);
    }

    this.checkTokenSubscription = this.applicationHeartbeatService.onHeartbeat.subscribe(() => {
      this.checkTokenExpiration();
    });

    this.applicationHeartbeatService.listen(this.appConfig.session.checkTokenExpirationPeriod * 1000);
    this.onLogout = new Subject<any>();
    this.login$ = new Subject<ILoginEvent>();
  }


  public authenticate(username: string, password: string, alias: string, isFirstLogin: boolean): Promise<AuthResponse> {

    Assert.isNotNull(username, 'username');
    Assert.isNotNull(password, 'password');


    let promise: Promise<AuthResponse> = this.authenticationApiService.authenticate(username, password, alias);

    promise.then((data: AuthResponse) => {

      if (data.statusCode === AuthStatusCode.valid) {
        this.checkAliasChanging(alias, false);
        if (isFirstLogin) {
          this.sessionActions.userLoggedIn();
          this.sessionActions.startClearSession();
        }
        this.sessionActions.saveSession(data.session);
        this.applicationHeartbeatService.listen(this.appConfig.session.checkTokenExpirationPeriod * 1000);
        this.tokenRequestIsInProcess = false;
        this.login$.next(new LoginEvent(username, data.session.user.email, data.session.user.roles, alias, this.isAliasFirstOrChanged, false));
      }
    }).catch(() => {
      this.checkAliasChanging(alias, true);
      this.sessionActions.startClearSession();
      this.tokenRequestIsInProcess = false;
    });

    this.tokenRequestIsInProcess = true;

    return promise;
  }

  public async getAuthorizationCode(authorizationRequest: OAuth2AuthorizeRequest): Promise<any> {
    Assert.isNotNull(authorizationRequest, 'authorizationRequest');
    let promise: Promise<AuthResponse> = this.authenticationApiService.getAuthorizationCode(authorizationRequest);
    return promise;
  }

  public validateAuthSession(state: string): Promise<AuthResponse> {

    Assert.isNotNull(state, 'state');


    let promise: Promise<AuthResponse> = this.authenticationApiService.validateAuthSession(state);

    promise.then((data: AuthResponse) => {

      if (data.statusCode === AuthStatusCode.valid) {
        //this.checkAliasChanging(alias, false);
        //if (isFirstLogin) {
          this.sessionActions.userLoggedIn();
          this.sessionActions.startClearSession();
        //}
        this.sessionActions.saveSession(data.session);
        this.applicationHeartbeatService.listen(this.appConfig.session.checkTokenExpirationPeriod * 1000);
        this.tokenRequestIsInProcess = false;
        //this.login$.next(new LoginEvent(userName, data.session.user.email, data.session.user.roles, alias, this.isAliasFirstOrChanged, false));
      }     
    }).catch(() => {
      //this.checkAliasChanging(alias, true);
      this.sessionActions.startClearSession();
      this.tokenRequestIsInProcess = false;
    });

    this.tokenRequestIsInProcess = true;

    return promise;
  }

  public forgotPassword(usernameWithAlias: string, recaptcha: string): Promise<ForgotPassword> {
    Assert.isNotNull(usernameWithAlias, 'usernameWithAlias');
    Assert.isNotNull(recaptcha, 'recaptcha');

    return this.authenticationApiService.requestResetPassword(usernameWithAlias, recaptcha);
  }

  public getResetPasswordInfo(token: string): Promise<any> {
    Assert.isNotNull(token, 'token');

    let promise: Promise<any> = Promise.resolve('system');

    return promise;
  }

  public setToken(token: string): Promise<Session> {
    let session: Session = new Session(token);
    this.sessionActions.saveSession(session);

    return this.renewAuthentication().then((session: Session) => {
      this.sessionActions.userLoggedIn();
      this.sessionActions.startClearSession();
      this.sessionActions.saveSession(session);
      this.applicationHeartbeatService.listen(this.appConfig.session.checkTokenExpirationPeriod * 1000);
      this.tokenRequestIsInProcess = false;
      this.login$.next(new LoginEvent(session.user.username, session.user.email, session.user.roles, session.alias, this.isAliasFirstOrChanged, true));
      return session;
    });

  }

  public resetPasswordByToken(token: string, username: string, password: string, alias?: string): Promise<ResetPassword> {
    Assert.isNotNull(token, 'token');
    Assert.isNotNull(username, 'username');
    Assert.isNotNull(password, 'password');

    return this.authenticationApiService.resetPasswordByToken(token, username, password, alias);
  }

  public resetPasswordByOldPassword(username: string, oldPassword: string, newPassword: string, alias?: string): Promise<ResetPassword> {

    Assert.isNotNull(username, 'username');
    Assert.isNotNull(oldPassword, 'oldPassword');
    Assert.isNotNull(newPassword, 'newPassword');

    return this.authenticationApiService.resetPasswordByOldPassword(username, oldPassword, newPassword, alias);

  }


  public isAuthenticated(): boolean {
    let isAuthenticated: boolean = !this.sessionService.isExpired();
    return isAuthenticated;
  }

  public getAlias(): string {
    return this.sessionService.getAlias();
  }

  public getUser(): IUser {
    return this.sessionService.getUser();
  }

  public navigateToResetPassword(username: string, alias: string): void {

    Assert.isNotNull(username, 'username');

    let queryParams: Params = {};
    let paramsObj = new Object();
    paramsObj[authenticationConfig.login.username] = username;

    if (alias) {
      paramsObj[authenticationConfig.login.alias] = alias;
    }
    let paramStr = JSON.stringify(paramsObj);
    let base64token: string = btoa(paramStr);
    queryParams["id"] = base64token;

    let navigationExtras: NavigationExtras = {
      queryParams: queryParams
    };

    this.onLogout.next();
    this.modalService.closeAllWindows();
    this.sessionActions.startClearSession();

    this.router.navigate(['login', 'reset_password'], navigationExtras);
  }

  public navigateToInvalidIPAddress(): void {
    this.onLogout.next();
    this.modalService.closeAllWindows();
    this.sessionActions.startClearSession();
    this.router.navigate(['login_ip_restriction']);
  }

  public navigateToLogin() {
    this.applicationHeartbeatService.stop();
    this.modalService.closeAllWindows();
    this.onLogout.next();
    this.router.navigate(['/login']);
  }

  public logout(): void {
    this.authenticationApiService.logout()
      .finally(() => {
        this.sessionActions.startClearSession();
        this.navigateToLogout(this.router);
      });
  }

  public navigateToLogout(router: Router, returnPath?: string): void {
    this.applicationHeartbeatService.stop();
    let urls: string[] = returnPath ? _.split(returnPath, '?', 2) : [];
    let returnUrl: string = urls.length > 0 ? urls[0] : null;
    let returnQs: string = urls.length > 1 ? urls[1] : null;

    let queryParams: Params = {};
    if (returnUrl) {
      queryParams[authenticationConfig.login.returnUrlQueryParameter] = returnUrl;
    }
    if (returnQs) {
      queryParams[authenticationConfig.login.returnUrlQueryStringParameter] = returnQs;
    }
    let navigationExtras: NavigationExtras = {
      queryParams: queryParams
    };
    this.modalService.closeAllWindows();
    this.onLogout.next();
    this.router.navigate(['/login'], navigationExtras);
    
    var b2cSSologin = this.localStorageService.get<boolean>(appConfig.b2sSSoLoginKey)
    if(b2cSSologin)
    {
      this.localStorageService.remove(appConfig.b2sSSoLoginKey);
      window.open(`${appConfig.api.identityUrl}/signout`);
    }
  }

  public loadPasswordPolicyUnsecure(alias: string): Promise<UserPasswordSettings> {
    return this.authenticationApiService.loadPasswordPolicyUnsecure(alias);
  }


  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  private renewAuthentication(): Promise<Session> {
    let promise: Promise<Session> = <any>this.authenticationApiService.renew();

    promise.then((session: Session) => {
      if (!session.isIPAllowed) {
        this.navigateToInvalidIPAddress();
      }
      else {
        this.sessionActions.saveSession(session);
      }

      this.tokenRequestIsInProcess = false;
    }).catch(() => {
      // Don't clear session unless it has expired.    
      if (!this.sessionService.isIpAddressValid()) {
        this.navigateToInvalidIPAddress();
      }
      else if (this.sessionService.isExpired()) {
        this.sessionActions.clearSession();
      }

      this.tokenRequestIsInProcess = false;
    });

    this.tokenRequestIsInProcess = true;

    return promise;
  }

  private checkAliasChanging(alias: string, isReset: boolean): void {
    this.isAliasFirstOrChanged = _.isNull(this.lastAlias) || this.lastAlias !== alias;
    this.isAliasChanged = !_.isNull(this.lastAlias) && this.lastAlias !== alias;
    this.lastAlias = isReset ? null : alias;
  }

  private checkTokenExpiration(): void {
    if (this.isAuthenticated()) {
      let token: string = this.sessionService.getToken();
      let sessionExpirationDate: Date = this.sessionService.getTokenExpirationDate();
      let secondsSessionExperiredAfter: number = moment(sessionExpirationDate)
        .diff(moment(), 'seconds');
      if (secondsSessionExperiredAfter <= this.appConfig.session.startRenewUntilSessionExpiredSeconds) {
        this.renewToken();
      }
    }
  }

  private async renewToken(): Promise<void> {
    if (!this.tokenRequestIsInProcess) {
      console.info('Renew token started.');
      try {
        await this.renewAuthentication();
      } catch (error) {
        this.loggerService.warning('Cant renew jwt.', error);
      }
      console.info('Renew token complited.');
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from '@progress/kendo-angular-layout';

import { NgxPopperModule } from 'ngx-popper';
import { MomentModule } from 'angular2-moment';

import { CommonModule as CommonAppModule } from '../../common/index';
import { ComponentsLibraryModule } from '../../components-library/index';
import { OrganizationModule } from '../../organization/index';
import { accrualComponents } from './components/index';
import { services } from './services/index';
import { RecalculateDialogComponent } from './components/recalculate-dialog/recalculate-dialog.component';
import { BoldPipe } from './pipes/text-bold.pipe';
import { CopyAccrualPolicyComponent } from './components/accruals-policy/copy-accrual-policy/copy-accrual-policy.component';
import { CopiedPolicyResultDialogComponent } from './components/accruals-policy/copied-policy-result-dialog/copied-policy-result-dialog.component';

@NgModule({
    imports: [
        HttpClientModule,
        CommonModule,
        CommonAppModule,
        GridModule,
        PDFModule,
        ExcelModule,
        DropDownsModule,
        LayoutModule,
        NgxPopperModule,
        ComponentsLibraryModule,
        MomentModule,
        OrganizationModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        ...accrualComponents,
        BoldPipe,
        CopyAccrualPolicyComponent,
        CopiedPolicyResultDialogComponent
    ],
    providers: [
        ...services,
        DecimalPipe
    ],
    exports: []
})
export class AccrualsModule { }


import {combineLatest, map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';


import { IControlState, StateResetTypes } from './../../../../../core/models/settings/index';
import { ComponentStateStorageService } from './../../../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from './../../../../../common/services/state-management/state-management.service';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { PbjExportLogManagementService, MealDeductionManagementService } from '../../../services/index';
import { PbjNewExportDialog } from '../pbj-new-export-dialog/pbj-new-export-dialog.component';
import { PbjNewExportOptions, PBJExportResult, MealDeducitonsDialogOptions, PBJExportRequest, MealDeductionSettings } from '../../../models/index';
import { mutableSelect, unsubscribe, destroyService } from '../../../../../core/decorators/index';
import { ServerFilterService } from '../../../../../core/services/index';
import { NotificationsService } from '../../../../../core/components/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../../../organization/services/index';
import { IRangeDates } from '../../../../../common/models/range-dates';
import { ModalService } from '../../../../../common/services/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../../../common/components/index';
import { appMessages } from '../../../../../app.messages';
import { MealDeductionNewConfigurationDialogComponent } from '../../meal-deduction/meal-deduction-new-configuration-dialog/meal-deduction-new-configuration-dialog.component';
import { AppServerConfig } from '../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../app-settings/services/index';
import { MealDeductionConfigurationDialogComponent } from '../../meal-deduction';
import { PbjNewExportHierarchyHelper } from '../pbj-new-export/pbj-new-export-hierarchy-helper';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-log-export',
  templateUrl: 'pbj-log-export.component.html',
  styleUrls: ['pbj-log-export.component.scss'],
  providers: [ServerFilterService, PbjExportLogManagementService, StateManagementService]
})
export class PbjLogExportComponent extends PbjNewExportHierarchyHelper implements OnInit, OnDestroy {
  public state: {
    isLoading: boolean;
  };
  public appConfig: IApplicationConfig;
  public currentOrgLevel: OrgLevel;
  public startDate: Date;
  public endDate: Date;
  public mealDeductionSettings: MealDeductionSettings;
  public disableNewExportBtn: boolean;
  public isAgencyOrglevel: boolean;
  public IsPBJExportCorpLevelEnabled: boolean;
  public canGeneratePBJFromAgency: boolean;
  private exportRequest: PBJExportRequest;

  private filterDates: IRangeDates;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private exportSubscription: Subscription;
  @unsubscribe()
  private mealDeducionSettingsLoadSubscription: Subscription;
  @destroyService()
  private managementService: PbjExportLogManagementService;
  private datesControlKey: string;
  private resetBy: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;

  constructor(
    managementService: PbjExportLogManagementService,
    private orgLevelWatchService: OrgLevelWatchService,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private mealDeducitonManagement: MealDeductionManagementService,
    private appSettingsManageService: AppSettingsManageService,
  ) {
    super();
    this.managementService = managementService;
    this.state = {
      isLoading: false,
    };
    this.datesControlKey = 'startDatePeriod';
    const today: moment.Moment = moment().startOf('day');
    this.endDate = today.toDate();
    this.startDate = today.subtract(6, 'month').toDate();
  }

  public ngOnInit(): void {
    this.stateManagement.init('PbjLogExportComponent', true);
    this.appConfig = appConfig;

    this.appSettingsManageService.getAppServerConfig().then((config: AppServerConfig) => {
      this.IsPBJExportCorpLevelEnabled = config.IsPBJExportCorpLevelEnabled;
      this.managementService.IsPBJExportCorpLevelEnabled = this.IsPBJExportCorpLevelEnabled;

      this.mealDeducionSettingsLoadSubscription = this.mealDeducitonManagement.onLoaded$.subscribe((s: MealDeductionSettings) => {
        this.mealDeductionSettings = s;
        this.disableNewExportBtn = false;
        this.canGeneratePBJFromAgency = s.generatePBJFromAgency;
      });

      this.orgLevelSubscription = this.orgLevel$.pipe(
        combineLatest(this.orgLevelWatchService.orgLevelTreeLoaded$, this.stateManagement.onInit$),
        map((value: [OrgLevel, boolean, any]) => value[0]))
        .subscribe((orgLevel: OrgLevel) => {

          if (orgLevel && orgLevel.id === null) return;

          if (!this.currentOrgLevel || this.currentOrgLevel.id !== orgLevel.id) {
            this.currentOrgLevel = orgLevel;

            this.restoreDates();
            this.managementService.filterRangeChanged(this.startDate, this.endDate);
            this.managementService.orgLevelChanged(this.currentOrgLevel);

            this.mealDeducitonManagement.onOrglevelChanged(this.currentOrgLevel);
          }
          this.isAgencyOrglevel = false;
          if (this.currentOrgLevel.organizationId == 1000) {
            this.isAgencyOrglevel = true;
          }
        });

      this.exportSubscription = this.managementService.exportPerformed$
        .subscribe((value: PBJExportResult) => {
          this.showConfirmDialog(value);
        });

      this.loadStatusSubscription = this.managementService.onLoadStatus$
        .subscribe((value: boolean) => {
          this.state.isLoading = value;
        });
    });

    this.mealDeducionSettingsLoadSubscription = this.mealDeducitonManagement.onLoaded$.subscribe((s: MealDeductionSettings) => {
      this.mealDeductionSettings = s;
      this.disableNewExportBtn = false;
      this.canGeneratePBJFromAgency = s.generatePBJFromAgency;
    });

    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onDataRangeChange({ startDate, endDate }: IRangeDates): void {
    this.filterDates = { startDate, endDate };
    this.managementService.startDate = startDate;
    this.managementService.endDate = endDate;
  }

  public applyFilter(): void {
    if (this.filterDates) {
      let startDate: Date = this.filterDates.startDate, endDate: Date = this.filterDates.endDate;
      this.saveDates(startDate, endDate);
      this.managementService.filterRangeChanged(startDate, endDate);
      this.managementService.IsPBJExportCorpLevelEnabled = this.IsPBJExportCorpLevelEnabled;
      this.managementService.applyFilter();
    }
  }

  public onNewExport(): void {
    let mealSettingsConfigured: boolean = this.mealDeductionSettings && !_.isNil(this.mealDeductionSettings.recordId) && this.mealDeductionSettings.recordId !== 0;
    let options: PbjNewExportOptions = new PbjNewExportOptions(`${this.currentOrgLevel.name} PBJ - XML Export`, !mealSettingsConfigured,this.IsPBJExportCorpLevelEnabled);
    const dialog: PbjNewExportDialog = PbjNewExportDialog.openDialog(options, this.modalService, (result: boolean): void => {
      if (result) {
        this.exportRequest = dialog.request;
        if (!this.IsPBJExportCorpLevelEnabled) {
          this.exportRequest.orgLevelId = this.currentOrgLevel.id;
          this.managementService.performExport(this.exportRequest);
          this.notificationsService.success(`${appMessages.success.pbjExportStarted.title}`, appMessages.success.pbjExportStarted.message);
        }
        else if (this.IsPBJExportCorpLevelEnabled) {
          this.exportRequest.parentId = this.currentOrgLevel.id;
          this.managementService.performBatchExport(this.exportRequest);
        }
      } else {
        this.exportRequest = null;
      }
    });
  }

  public onConfigure(): void {
    const options: MealDeducitonsDialogOptions = new MealDeducitonsDialogOptions('PBJ - Meal Deduction Settings', false, this.isConfigureDisabled);
    if(this.IsPBJExportCorpLevelEnabled){
      this.mealDeducitonManagement.setSelectedIds(this.organizationIds);
      MealDeductionNewConfigurationDialogComponent.openDialog(options, this.modalService, (result: boolean): void => {
        if (result) {
          this.disableNewExportBtn = true;
          this.mealDeducitonManagement.reload();
        } else {
          this.disableNewExportBtn = false;
        }
      });
    }else{
    MealDeductionConfigurationDialogComponent.openDialog(options, this.modalService, (result: boolean): void => {
      if (result) {
        this.disableNewExportBtn = true;
        this.mealDeducitonManagement.reload();
      } else {
        this.disableNewExportBtn = false;
      }
    });
    }

  }

  public getConfigureTooltip(settings: MealDeductionSettings): string {
    if ((settings && settings.canConfigure && (!this.isAgencyOrglevel || this.canGeneratePBJFromAgency)) || this.IsPBJExportCorpLevelEnabled ){
      return 'Configure Meal Deduction';
    }
    return 'You have no rights to Configure Meal Deduction in this organization';
  }

  private saveDates(sDate: Date, eDate: Date): void {
    const startDate: string = moment(sDate).format(appConfig.dateFormat);
    const endDate: string = moment(eDate).format(appConfig.dateFormat);
    this.storageService.setControlState(this.stateManagement.componentKey, this.datesControlKey, { value: { startDate, endDate } }, this.resetBy);
  }

  private restoreDates(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.datesControlKey);
    let startDate: moment.Moment = moment(_.get(state.value, 'startDate', null));
    let endDate: moment.Moment = moment(_.get(state.value, 'endDate', null));
    if (startDate.isValid() && endDate.isValid()) {
      this.startDate = startDate.toDate();
      this.endDate = endDate.toDate();
    }
  }

  private showConfirmDialog(value: PBJExportResult): void {
    let message: string;
    const options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    if (value.isSuccess) {
      message = 'A new PBJ Export has been successfully created';
      this.managementService.applyFilter();
    } else {
      message = 'An error occurred during the PBJ Export. \n';
      message += value.errorMessage ?
        value.errorMessage :
        `Please review the error log for Export ID ${value.exportId} for additional details`;
    }
    ConfirmDialogComponent.openDialog(
      'PBJ Export',
      message,
      this.modalService,
      (result: boolean) => {
        // TODO
      }, options);
  }

  get isConfigureDisabled(): boolean {
     if(this.IsPBJExportCorpLevelEnabled) return false;
    if(this.mealDeductionSettings && this.mealDeductionSettings.canConfigure) return false;
  }
}

//import { ProgressbarComponent } from './progressbar.component';
import * as moment from 'moment';
import * as _ from 'lodash';
import { param } from 'jquery';
import { AcaC1095CustomGroupHeader as AcaC1095CustomGroupHeader } from './aca-c1095-custom-gh.component';

export class AcaC1095AuditAgGridHelper {
    public columnDefs: any[];
    public autoGroupColumnDef: any[];
    public defaultColumnDefs: any = {
        resizable: false,
        suppressMenu: true,
        suppressMovable: true,
        suppressContextMenu: true
    };

    public createColumnDefs(): void {
        this.columnDefs = [];
        this.autoGroupColumnDef = [];
        this.columnDefs = [
            {
                headerName: 'Employee Name',
                showRowGroup: 'employeeName',
                width: 150,
                pinned: 'left',
                cellStyle: this.cellStyling(),
                headerClass: 'ag-header-no-group-row',
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressCount: true
                },
                colId: 'employeeId',
                sort: 'asc',

            },
            {
                field: 'employeeName', rowGroup: true, hide: true
            },
            {
                headerName: 'Year',
                field: 'year',
                pinned: 'left',
                width: 90,
                headerTooltip: 'year',
                resizable: false,
                headerClass: 'ag-header-no-group-row ',
                cellClass: 'ag-year-cell',
                cellRenderer: this.getCellRender()
            },
            {
                headerName: 'Month',
                width: 150,
                pinned: 'left',
                showRowGroup: 'month',
                cellStyle: this.cellStyling(),
                headerClass: 'ag-header-no-group-row ',
                cellRenderer: 'agGroupCellRenderer',
                cellClass: 'ag-custom-cell',
                cellRendererParams: {
                    suppressCount: true,
                }
            },
            { field: 'month', rowGroup: true, hide: true },
            {
                headerName: 'Suggested',
                headerClass: 'slx-aca-1095-audit-column-group',
                cellStyle: {
                    textAlign: 'center'
                },
                headerGroupComponentFramework: AcaC1095CustomGroupHeader,
                children: [
                    {
                        headerName: 'Line 14',
                        field: 'sugCode',
                        width: 90,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    },
                    {
                        headerName: 'Line 15',
                        field: 'sugCost',
                        width: 90,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    },
                    {
                        headerName: 'Line 16',
                        field: 'sugHarbor',
                        width: 90,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    },
                    {
                        headerName: 'Line 17',
                        field: 'sugZip',
                        width: 110,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    }
                ]
            },
            {
                headerName: 'Edited',
                headerClass: 'slx-aca-1095-audit-column-group',
                cellStyle: {
                    textAlign: 'center'
                },
                headerGroupComponentFramework: AcaC1095CustomGroupHeader,
                children: [
                    {
                        headerName: 'Line 14',
                        field: 'selCode',
                        width: 90,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    },
                    {
                        headerName: 'Line 15',
                        field: 'selCost',
                        width: 90,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    },
                    {
                        headerName: 'Line 16',
                        field: 'selHarbor',
                        headerTooltip: 'selLine17',
                        width: 90,
                        headerClass: 'grid-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    },
                    {
                        headerName: 'Line 17',
                        field: 'selZip',
                        width: 110,
                        headerClass: 'grid-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    },

                    {
                        headerName: 'Username',
                        field: 'selectedBy',
                        headerTooltip: 'User name',
                        width: 90,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    },
                    {
                        headerName: 'Datetime',
                        field: 'selectedOn',
                        headerTooltip: 'Edited Datetime',
                        width: 150,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    }
                ]
            },
            {
                headerName: 'Confirmed',
                headerClass: 'slx-aca-1095-audit-column-group',
                cellStyle: {
                    textAlign: 'center'
                },
                headerGroupComponentFramework: AcaC1095CustomGroupHeader,
                children: [
                    {
                        headerName: 'Confirmed',
                        field: 'confirmedInd',
                        headerTooltip: 'confirmed Ind',
                        width: 110,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    },
                    {
                        headerName: 'Confirmed By',
                        field: 'confirmedBy',
                        width: 110,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    },
                    {
                        headerName: 'Datetime',
                        field: 'confirmedOn',
                        width: 150,
                        headerClass: 'ag-header-row',
                        cellClass: 'ag-custom-cell',
                        cellRenderer: this.getCellRender()
                    }]
            }
        ];
    }

    public getCellRender(): any {
        let elm: any = this;
        return function (params): string {

            return elm.getCellHtmlTemplate(params);
        }
        return elm;
    }
    public getCellHtmlTemplate(params: any): string {
        let html: string = '';
        let node: any = '';
        if (params.node.level == 0 || params.node.level == 1) {
          if (params.node.level == 1) {
            node = params.node.allLeafChildren[0];
          }
          if (params.node.level == 0) {
            node = _.first(_.orderBy(params.node.allLeafChildren, c => moment(c.data.selectedOn), ['desc']));
          }
          if (params.node.allLeafChildren[0].data.expendMonth) {
            html += `<div style='display:none' class='level_${params.node.level}_${params.node.allLeafChildren[0].data.employeeId}_${params.node.allLeafChildren[0].data.month}'>`;
          }
          else if(params.node.allLeafChildren[0].data.expend) {
            html += `<div style='display:none' class='level_${params.node.level}_${params.node.allLeafChildren[0].data.employeeId}_${params.node.allLeafChildren[0].data.month}'>`;
          }
          else {
            html += `<div class='level_${params.node.level}_${params.node.allLeafChildren[0].data.employeeId}_${params.node.allLeafChildren[0].data.month}'>`;
          }

                if (params.column.colId == 'sugCode' || params.column.colId == 'selCode') {
                    if (node.data.sugCode != node.data.selCode) {
                        html += `<div class='ag-Line-mismacth-cell'>${params.column.colId == 'sugCode' ? node.data.sugCode : node.data.selCode}</div>`;
                    }
                    else {
                        html += `<div class='ag-custom-cell'>${params.column.colId == 'sugCode' ? node.data.sugCode : node.data.selCode}</div>`;
                    }
                }
                else if (params.column.colId == 'sugCost' || params.column.colId == 'selCost') {
                    if (node.data.sugCost != node.data.selCost) {
                        html += `<div class='ag-Line-mismacth-cell'>${params.column.colId == 'sugCost' ? node.data.sugCost : node.data.selCost}</div>`;
                    }
                    else {
                        html += `<div class='ag-custom-cell'>${params.column.colId == 'sugCost' ? node.data.sugCost : node.data.selCost}</div>`;
                    }
                }
                else if (params.column.colId == 'sugHarbor' || params.column.colId == 'selHarbor') {
                    if (node.data.sugHarbor != node.data.selHarbor) {
                        html += `<div class='ag-Line-mismacth-cell'>${params.column.colId == 'sugHarbor' ? node.data.sugHarbor : node.data.selHarbor}</div>`;
                    }
                    else {
                        html += `<div class='ag-custom-cell'>${params.column.colId == 'sugHarbor' ? node.data.sugHarbor : node.data.selHarbor}</div>`;
                    }
                }
                else if (params.column.colId == 'sugZip' || params.column.colId == 'selZip') {
                    if (node.data.sugZip != node.data.selZip) {
                        html += `<div class='ag-Line-mismacth-cell'>${params.column.colId == 'sugZip' ? node.data.sugZip : node.data.selZip}</div>`;
                    }
                    else {
                        html += `<div class='ag-custom-cell'>${params.column.colId == 'sugZip' ? node.data.sugZip : node.data.selZip}</div>`;
                    }
                }
                else if (params.column.colId == 'year') {
                    html += `<div class='ag-year-cell'>${node.data.year}</div>`;
                }
                else if (params.column.colId == 'selectedBy') {
                    html += `<div class='ag-custom-cell'>${node.data.selectedBy}</div>`;
                }
                else if (params.column.colId == 'selectedOn') {
                    html += `<div class='ag-custom-cell'>${node.data.selectedOn}</div>`;
                }
                else if (params.column.colId == 'confirmedInd') {
                    html += `<div class='ag-custom-cell'>${node.data.confirmedInd}</div>`;
                }
                else if (params.column.colId == 'confirmedBy') {
                    html += `<div class='ag-custom-cell'>${node.data.confirmedBy}</div>`;
                }
                else if (params.column.colId == 'confirmedOn') {
                    html += `<div class='ag-custom-cell'>${node.data.confirmedOn}</div>`;
                }
                else if (params.column.colId == 'month') {
                    html += `<div class='ag-custom-cell'>${node.data.month}</div>`;
                }
                else {
                    html += `<div class='ag-custom-cell'></div>`;
                }
          html += '</div>';
        }
        else if (params.node.level == 2) {
            html = this.loadCellData(params);
        }
        return html;
    }

    public loadCellData(params: any): string {
        let html: string = '';
        if (params.column.colId == 'sugCode' || params.column.colId == 'selCode') {
            if (params.data.sugCode != params.data.selCode) {
                html = `<div class='ag-Line-mismacth-cell'>${params.column.colId == 'sugCode' ? params.data.sugCode : params.data.selCode}</div>`;
            }
            else {
                html = `<div class='ag-custom-cell'>${params.column.colId == 'sugCode' ? params.data.sugCode : params.data.selCode}</div>`;
            }
        }
        else if (params.column.colId == 'sugCost' || params.column.colId == 'selCost') {
            if (params.data.sugCost != params.data.selCost) {
                html = `<div class='ag-Line-mismacth-cell'>${params.column.colId == 'sugCost' ? params.data.sugCost : params.data.selCost}</div>`;
            }
            else {
                html = `<div class='ag-custom-cell'>${params.column.colId == 'sugCost' ? params.data.sugCost : params.data.selCost}</div>`;
            }
        }
        else if (params.column.colId == 'sugHarbor' || params.column.colId == 'selHarbor') {
            if (params.data.sugHarbor != params.data.selHarbor) {
                html = `<div class='ag-Line-mismacth-cell'>${params.column.colId == 'sugHarbor' ? params.data.sugHarbor : params.data.selHarbor}</div>`;
            }
            else {
                html = `<div class='ag-custom-cell'>${params.column.colId == 'sugHarbor' ? params.data.sugHarbor : params.data.selHarbor}</div>`;
            }
        }
        else if (params.column.colId == 'sugZip' || params.column.colId == 'selZip') {
            if (params.data.sugZip != params.data.selZip) {
                html = `<div class='ag-Line-mismacth-cell'>${params.column.colId == 'sugZip' ? params.data.sugZip : params.data.selZip}</div>`;
            }
            else {
                html = `<div class='ag-custom-cell'>${params.column.colId == 'sugZip' ? params.data.sugZip : params.data.selZip}</div>`;
            }
        }
        else if (params.column.colId == 'year') {
            html = `<div class='ag-year-cell'>${params.data.year}</div>`;
        }
        else if (params.column.colId == 'month') {
            html = `<div class='ag-custom-cell'>${params.data.month}</div>`;
        }
        else if (params.column.colId == 'selectedBy') {
            html = `<div class='ag-custom-cell'>${params.data.selectedBy}</div>`;
        }
        else if (params.column.colId == 'selectedOn') {
            html = `<div class='ag-custom-cell'>${params.data.selectedOn}</div>`;
        }
        else if (params.column.colId == 'confirmedInd') {
            html = `<div class='ag-custom-cell'>${params.data.confirmedInd}</div>`;
        }
        else if (params.column.colId == 'confirmedBy') {
            html = `<div class='ag-custom-cell'>${params.data.confirmedBy}</div>`;
        }
        else if (params.column.colId == 'confirmedOn') {
            html = `<div class='ag-custom-cell'>${params.data.confirmedOn}</div>`;
        }
        else {
            html = `<div class='ag-custom-cell'></div>`;
        }
        return html;
    }


    public cellStyling() {
        return {
            'height': '30px',
            'width': '150px',
            'color': '#0092CB',
            'font-family': 'Helvetica Neue,Helvetica, Arial, sans-serif',
            'font-size': '13px',
            'line-height': '15px',
            'display': 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'padding-top': '2px'
        };
    }

    public getRowStyle(params: any): any {

        if (params.node.rowIndex % 2 === 0) {
            return this.getEvenStyle();
        } else {
            return this.getOddStyle();
        }
    }

    private getEvenStyle(): any {
        return {
            'background-color': '#ffffff',
            'color': '#333;'
        };
    }

    private getOddStyle(): any {
        return {
            'background-color': '#E6E9EF',
            'color': '#333;'
        };
    }
}

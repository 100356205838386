<form #form="ngForm" novalidate>
  <kendo-grid #gridEnrollment class="slx-benefit-management-grid auto-width"
    [data]="gridState.view"
    [sortable]="{ mode: 'multiple' }"
    [sort]="gridState.state.sort"
    [filterable]="'menu'"
    [filter]="gridState.state.filter"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="gridState.state.skip"

    (dataStateChange)="gridState.dataStateChange($event)"
    (edit)="gridState.editHandler($event)"
    (save)="gridState.saveHandler($event)"
    (cancel)="gridState.cancelHandler($event)"
    (remove)="gridState.removeHandler($event)"
  >
    <kendo-grid-command-column title="Edit" width="90" [locked]="true">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="flex">
          <button [disabled]="!canEdit" kendoGridEditCommand
            type="button" class="slx-button slx-only-icon slx-toolbar slx-blue-white-text"
          >
            <i class="fas fa-edit" aria-hidden="true"></i>
          </button>
          <button kendoGridRemoveCommand *ngIf="!isEditingRow(dataItem)" [disabled]="!canDelete"
            type="button" class="slx-button slx-only-icon slx-toolbar slx-red-white-text"
          >
            <i class="fas fa-trash-alt" aria-hidden="true"></i>
          </button>
          <button [disabled]="!form.valid" kendoGridSaveCommand
            type="button" class="slx-button slx-only-icon slx-toolbar slx-blue-white-text"
          >
            <i class="fas fa-save" aria-hidden="true"></i>
          </button>
          <button kendoGridCancelCommand *ngIf="isEditingRow(dataItem)"
            type="button" class="slx-button slx-only-icon slx-toolbar slx-red-white-text"
          >
            <i class="fas fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column field="benefitName" width="300">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Benefit</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.benefitName }}
      </ng-template>

      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        {{ dataItem.benefitName }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="startDate" filter="date" width="200">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Benefit Start Date</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.startDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input
                               [(ngModel)]="dataItem.startDate"
                               [minDate]="dataItem.empBenefitStartDate"
                               [maxDate]="dataItem.endDate || dataItem.empBenefitEndDate"
                               [acceptNullDate]="false"
                               [constantValidation]="true"
                               [emitValueAnyway]="true"
                               placeholder="Benefit Start Date"
                               name="startDate{{rowIndex}}"
                               [required]="true"></slx-date-picker-ngx>
          <span errorMessage for="date"></span>
          <span errorMessage for="required"></span>
          <span errorMessage for="minDate">Min acceptable date {{ dataItem.empBenefitStartDate | amDateFormat: appConfig.dateFormat }}</span>
          <span errorMessage for="maxDate">Max acceptable date {{ (dataItem.endDate || dataItem.empBenefitEndDate) | amDateFormat: appConfig.dateFormat }}</span>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="endDate" filter="date" width="200">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Benefit End Date</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="!canDrop">{{ dataItem.endDate | amDateFormat: appConfig.dateFormat }}</ng-container>
        <span class="emp-link" *ngIf="canDrop"
          [slxEmployeeDependentDropEnrollmentModal]="{ startDate: dataItem.empBenefitStartDate, endDate: dataItem.empBenefitEndDate }"
          (dropEnrollment)="onDropDependentEnrollment($event, dataItem)">
          <span>{{ dataItem.endDate | amDateFormat: appConfig.dateFormat | slxHideMaxDate}}</span>
        </span>
      </ng-template>

      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input
            [(ngModel)]="dataItem.endDate"
            [minDate]="dataItem.startDate || dataItem.empBenefitStartDate"
            [maxDate]="dataItem.empBenefitEndDate"
            [acceptNullDate]="false"
            [constantValidation]="true"
            [emitValueAnyway]="true"
            placeholder="Benefit End Date"
            name="endDate{{rowIndex}}"
            [required]="true"
          ></slx-date-picker-ngx>
          <span errorMessage for="date"></span>
          <span errorMessage for="required"></span>
          <span errorMessage for="minDate">Min acceptable date {{ (dataItem.startDate || dataItem.empBenefitStartDate) | amDateFormat: appConfig.dateFormat }}</span>
          <span errorMessage for="maxDate">Max acceptable date {{ dataItem.empBenefitEndDate | amDateFormat: appConfig.dateFormat }}</span>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="benefitOption" width="300">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Benefit Option</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.benefitOption?.code }}
      </ng-template>

      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        {{ dataItem.benefitOption?.code }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-messages filterMenuTitle="Filter">
    </kendo-grid-messages>  
  </kendo-grid>
</form>

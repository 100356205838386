import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { WcReport } from '../../../models';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { appConfig } from '../../../../../app.config';
import { loaActionsDropdownItems, LoaActionsDropDownItem } from '../../../../../common/models';

@Component({
    moduleId: module.id,
    selector: 'slx-wc-incident-report-header',
    templateUrl: 'wc-incident-report-header.component.html',
    styleUrls: ['wc-incident-report-header.component.scss']
})

export class WcIncidentReportHeaderComponent implements OnInit, OnDestroy {

    public get isCollapsed(): boolean {
        return this.m_isCollapsed;
    }

    public empName: string;
    public posName: string;
    public orgName: string;
    public depName: string;
    public empType: string;
    public dateHiredStr: string;
    public gender: string;
    public race: string;
    public dateOfBirthStr: string;
    public maritalStatus: string;
    public dependents: string;
    public age: string;
    public address: string;
    public ssn: string;
    public empId: number;

    public editMode: boolean;

    public report: WcReport;

    public leaveOptions = loaActionsDropdownItems;

    private m_isCollapsed: boolean = false;

    @unsubscribeAll()
    public subscriptions: StringMap<Subscription> = {};

    constructor (
        private management: WCReportManagementService,
        private changeDetector: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.subscriptions.load = this.management.subscribeToReportLoad(x => {

            this.report = x;

            if (this.report && this.report.employee) {

                this.empId = this.report.employee.id;

                if (this.report.employee.employee) {
                    this.empName = this.prettyString(this.report.employee.employee.name);
                } else {
                    this.empName = '';
                }

                if (this.report.employee.position) {
                    this.posName = this.prettyString(this.report.employee.position.name);
                } else {
                    this.posName = '';
                }

                if (this.report.employee.organization) {
                    this.orgName = this.prettyString(this.report.employee.organization.name);
                } else {
                    this.orgName = '';
                }

                if (this.report.employee.department) {
                    this.depName = this.prettyString(this.report.employee.department.name);
                } else {
                    this.depName = '';
                }

                this.empType = this.prettyString(this.report.employee.empType);
                this.gender = this.prettyString(this.report.employee.gender);
                this.race = this.prettyString(this.report.employee.race);
                this.maritalStatus = this.prettyString(this.report.employee.maritalStatus);
                this.dependents = this.report.employee.dependents.toString();
                this.ssn = this.prettyString(this.report.employee.ssn);
                this.age = _.isFinite(this.report.employee.age) ? this.report.employee.age.toString() : '';

                const zip = this.prettyString(this.report.employee.zip);
                const state = this.prettyString(this.report.employee.state);
                const city = this.prettyString(this.report.employee.city);
                const address = this.prettyString(this.report.employee.address);
                this.address = `${address}, ${city}, ${state}, ${zip}`;

                let date = this.report.employee.dateHired;
                this.dateHiredStr = _.isDate(date) ? this.toDateString(date) : '' + this.prettyString(date);

                date = this.report.employee.dateOfBirth;
                this.dateOfBirthStr = _.isDate(date) ? this.toDateString(date) : '' + this.prettyString(date);

            } else {
                this.empName = '';
                this.posName = '';
                this.orgName = '';
                this.depName = '';
                this.empType = '';
                this.gender = '';
                this.race = '';
                this.age = '';
                this.maritalStatus = '';
                this.dateHiredStr = '';
                this.empId = null;
            }

        });
    }

    public ngOnDestroy(): void {}

    public collapsedChange(isCollapsed: boolean): void {
        this.m_isCollapsed = isCollapsed;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    }

    public onClickEmployee(): void {
        this.management.navigateToUserProfile(this.report.employee.id);
    }

    public createNewLOARequest(type: LoaActionsDropDownItem): void {
        this.management.navigateToLeaveManagementWithAction(type, this.report.employee.id);
    }

    private toDateString(date: Date): string {
        if (date) {
            return moment(date).format(appConfig.dateFormat);
        }
        return '';
    }

    private prettyString(str: string): string {
        if (_.isNil(str)) {
            return '';
        }
        return str;
    }

}

import { Component, EventEmitter, Input, OnInit,Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CancelEvent, EditEvent, GridComponent, GridItem, SaveEvent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';

@Component({
  selector: 'slx-org-structure-codes-tab',
  templateUrl: './org-structure-codes-tab.component.html',
  styleUrls: ['./org-structure-codes-tab.component.scss'],
})
export class OrgStructureCodesTabComponent implements OnInit {
  @Input()
  public tabName: string = '';
  @Input()
  public tabData = [];
  @Output() saveDataHandler = new EventEmitter();
  public columnName: string;
  public formGroup: UntypedFormGroup;
  private editedRowIndex: number;
  public invalidField = false;
  @Input()
  public isReadonly = false;
  public errorText = '';
  constructor() {}

  ngOnInit() {
    this.columnName = this.tabName;
  }

  public editHandler({ sender, rowIndex, dataItem }: EditEvent): void {
    this.closeEditor(sender);
   this.invalidField = false;
   this.errorText = '';
    this.formGroup = new UntypedFormGroup({
      Description: new UntypedFormControl(dataItem.description),
      ShortCode: new UntypedFormControl(
        dataItem.shortCode,
        Validators.compose([
          Validators.required,
          Validators.pattern(
            this.tabName === 'Positions'
              ? '^[0-9]{1,4}'
              : this.tabName === 'Organization'
              ? '^(?!0)[0-9]{5}'
              : this.tabName === 'Departments'
              ? '^[0-9]{1,4}'
              : '^(?!0)[0-9]{2}'
          ),
        ])
      ),
        PayrollDescription: new UntypedFormControl(
        dataItem.payrollDescription,
        Validators.compose(this.tabName === 'Positions' ? [Validators.required] : [])
      ),
    });

    this.editedRowIndex = rowIndex;
    // put the row in edit mode, with the `FormGroup` build above
    this.formGroup.updateValueAndValidity();
    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler(args: CancelEvent): void {
    // close the editor for the given row
    this.invalidField = false;
    this.closeEditor(args.sender, args.rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    let shortCode = formGroup.value.ShortCode;  
    if(_.toInteger(shortCode)=== 0 || _.toInteger(shortCode) < 0){
      this.invalidField = true;
      this.errorText = `Error: The value entered is invalid for ${this.tabName}.`
      return ;
    }else{
      this.invalidField = false;
      this.errorText = '';
    }  
    if(this.tabName === 'Departments' || this.tabName === 'Positions') {
      let validateScode = shortCode.padStart(4, '0');    
      this.formGroup.controls.ShortCode.setValue(validateScode);      
    }
    if(this.tabName === 'Positions'){
      let description = formGroup.value.PayrollDescription.trim();    
      this.formGroup.controls.PayrollDescription.setValue(description); 
    }
    this.formGroup.updateValueAndValidity();
    const checkDuplicate = _.findIndex(this.tabData, (el) => _.toInteger(el.shortCode) === _.toInteger(shortCode));
    if (checkDuplicate !== -1 && this.editedRowIndex !== checkDuplicate) {
      this.invalidField = true;
      this.errorText = `Error: The value entered is already in use for a different ${this.tabName}.`
      return ;
    }else{
      this.invalidField = false;
      this.errorText = '';
    }
    this.saveDataHandler.emit({
      rowIndex,
      formGroup,
      isNew,
      tabName: this.tabName
    });
    sender.closeRow(rowIndex);
  }
  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
  public trackByItem(index: number, item: GridItem): any {
    return item.data['shortCode'];
  }

  public debouncedSearch = (event) => this.search(event.target.value);
  public trimChar = (event) =>{
    if(event.target.value.trim().length === 0){
        this.invalidField = true;
    }else {
      this.invalidField = false;
    }
  };
  public search(value: any) {
    if(value.length === 0){
      this.invalidField = false;
      return;
    }
    if(isNaN(value)){
      this.invalidField = true;
      this.errorText = "Error: The Code should be numeric only."
      return;
    }
    this.invalidField = false;   
  }
  public getMaxLength() {
    return this.tabName === 'Positions'
      ? 4
      : this.tabName === 'Organization'
      ? 5
      : this.tabName === 'Departments'
      ? 4
      : 2;
  }
}

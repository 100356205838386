import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { ReportConfigPositionCategory, ReportStaffingSetting } from '../../models/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { ReportsManagementService } from '../../services/index';
import { unsubscribe, unsubscribeAll } from '../../../core/decorators/index';
import { Subscription } from 'rxjs';
interface Item {
    id: number,
    name: string
}

@Component({
    selector: 'slx-report-daily-today-staff-config',
    templateUrl: './report-daily-today-staff-config.component.html',
    styleUrls: ['./report-daily-today-staff-config.component.scss']
})
export class ReportDailyTodayStaffConfigComponent implements OnInit, OnDestroy {
    public dailyAttendanceOptions: Array<Item> = [];
    public gridState: KendoGridStateHelper<ReportStaffingSetting>;
    public records: ReportStaffingSetting;
    public isLoading: boolean = true;
    public dialogResult: boolean;
    public readonly pageSize = 50;
    @Input()
    public forcedLoad: boolean;
    @Output()
    public hasValueChanged = new EventEmitter<boolean>();
    @Output()
    public reportConfigChanged = new EventEmitter<ReportStaffingSetting>();

    @unsubscribeAll()
    public subscriptions: StringMap<Subscription> = {};
    constructor(private reportsManagementService: ReportsManagementService) {
        this.gridState = new KendoGridStateHelper<ReportStaffingSetting>();
        this.gridState.view = null;
        this.dailyAttendanceOptions = [{ id: 0, name: ' ' }, { id: 1, name: 'RNs' }, { id: 2, name: 'LPNs' }, { id: 3, name: 'Others' }];
        this.gridState.refreshGridAfterRestoreState = true;
    }


    public ngOnInit() {
        this.reportsManagementService.loadStaffingSettingsData(this.forcedLoad);
        this.subscriptions.loadStaffingSettingsSubscription = this.reportsManagementService.onReportStaffingSettingsLoaded$.subscribe((reportStaffingSetting: ReportStaffingSetting) => {
            this.records = reportStaffingSetting;
            this.refreshGrid();
        });

    }
    public refreshGrid(): void {
        if (!this.records) {
            this.gridState.view = null;
            this.isLoading = false;
            return;
        }
        this.gridState.view = process(this.records.positionCategory, this.gridState.state);
        this.isLoading = false;
    }

    public ngOnDestroy(): void {
        // See #issueWithAOTCompiler
    }
    public getPositionCategory(id: number): any {
        return this.dailyAttendanceOptions[id];
    }

    public setPositionCategory(positionCategoryType: ReportConfigPositionCategory, positionCategory: any): void {
        if (positionCategoryType.positionCategoryType !== positionCategory.id) {
            positionCategoryType.positionCategoryType = positionCategory.id;
            this.hasValueChanged.emit(true);
            this.reportConfigChanged.emit(this.records);
        }
    }

    public onShiftDateChanged(): void {
        this.hasValueChanged.emit(true);
        this.reportConfigChanged.emit(this.records);
    }
}

<div *ngIf="showGrid" >
 
  <kendo-grid
  #kendoGrid
  class="slx-full-height slx-blue-grid dua-grid"
  [ngClass]="singleRowSelected || this.gridState.view.data.length== 2 ? 'single-row-selected' : ''"
  [data]="gridState?.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [group]="gridState.state.group"
  [selectable]="{ mode: 'single' }"
  [scrollElementSelector]="'.k-grid-content.k-virtual-content'"
  [pageable]="false"
  [pageSize]="pageSize"
  [skip]="gridState.state.skip"
  (pageChange)="gridState.pageChange($event)"
  (dataStateChange)="dataStateChange($event)"
  [resizable]="true"
  (sortChange)="createSorting($event)"
  (filterChange)="filterChange($event)" >
  
  <kendo-grid-span-column>
    <kendo-grid-column field="EmpName" title="Employee" width="280px"> </kendo-grid-column>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="user-image">
        <div
        class="circular-portrait "
          *ngIf="!isEmptyEmployee(dataItem)"
          [ngClass]="{ 'employee-ot-image-border': isOvertime(dataItem) && !isEmptyEmployee(dataItem) }"
        >
          <img
            class="employee-logo rounded-image"
            [employeeThumbinalSrc]="dataItem.EmpId"
            alt="Employee Photo"
            *ngIf="dataItem.EmpId && dataItem.SlxpartnerdataId == '0'"
          />
         <div  *ngIf="dataItem.SlxpartnerdataId != '0'">
          <i class="fal fa-user-circle agency-logo"></i>
         </div> 
        </div>
        <div class="circular-portrait" *ngIf="isEmptyEmployee(dataItem) && !isNoPatner(dataItem) && !showMutliPartnerRows(dataItem)">
          <i class="fal fa-user-circle agency-logo"></i>
        </div>
        <div class="clickable fa-stack fa employee-ot-icon" *ngIf="isOvertime(dataItem) && !isEmptyEmployee(dataItem)">
          <i class="fa fa-circle fa-stack-2x employee-ot-icon-back" aria-hidden="true"></i>
          <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
          <span class="fa-stack-1x overtime-criteria">OT</span>
        </div>
      </div>
      <div class="usename">
        <span *ngIf="showMutliPartnerRows(dataItem) && !isNoPatner(dataItem)">{{dataItem.EmpName}}</span>
        <span *ngIf="isEmptyEmployee(dataItem) && !isNoPatner(dataItem) && !showMutliPartnerRows(dataItem)">{{dataItem.EmpName}}</span>
        <a *ngIf="!isEmptyEmployee(dataItem)" [employeeSectionLink]="dataItem.EmpId">{{ dataItem.EmpName }}</a>
        <span *ngIf="dataItem.SlxpartnerdataId != '0' && showMutliPartnerRows(dataItem)" class="ShiftInfo"
          ><i
            class="fa fa-info-circle"
            aria-hidden="true"
            [slxTooltip]="getShiftRequestDateTip(dataItem)"
            tipPosition="left"
          ></i
        ></span>
      </div>
      <div class="duaBadge">
        <ng-container *ngFor="let colorCode of dataItem.EmpAssignedType | employeeTypeColorCodes">
            <span class="theme-tooltip">
                <i class="fas fa-circle badge-circle" [style.color]="colorCode.value"></i>
                <span class="theme-tooltiptext">
                    {{ colorCode.toolTipMessage }}
                </span>
            </span>
        </ng-container>
    </div>
    </ng-template>
  </kendo-grid-span-column>
  <kendo-grid-column field="Hours" title="Ideal Hours" filter="numeric" width="140px"> 
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.Hours | number : '1.2-2'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="JobDescription" title="Position" width="140px"> 
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.JobDescription | removeAsterisk}} 
      <span>
        <i *ngIf="dataItem.JobDescription.endsWith('*')" 
           class="fas fa-users" 
           title="Employee has a position that is grouped with this position">
        </i>
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="SchedHours" title="Scheduled Hours" filter="numeric" width="160px">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.SchedHours | number : '1.2-2'}}
      <span *ngIf="dataItem.IsConstraint">
        <i aria-hidden="true" class="fas fa-ban" title="Employee is scheduled for {{dataItem.SchedHours}} hours of {{dataItem.IsConstraint}} during this shift"
        ></i>
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="ShiftName" title="Shift" width="120px">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="slx-parameter-partial-label" *ngIf="displayPartial(dataItem)"><div><label>Partial</label></div></div>
      <a *ngIf="!isEmptyEmployee(dataItem)" (click)="openShift(dataItem)" class="gridShiftName">{{dataItem.ShiftName}}</a>
      <span *ngIf="isEmptyEmployee(dataItem)">{{dataItem.ShiftName}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="UnitName" title="Unit" width="100px"> </kendo-grid-column>
  <kendo-grid-column field="AgencyName" title="Agency" width="120px" *ngIf="IsEnabled">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span  *ngIf="!isEmptyEmployee(dataItem)">{{dataItem.AgencyName}}</span>
      <span  *ngIf="isEmptyEmployee(dataItem)"></span>
    </ng-template>
  </kendo-grid-column>
  
  <kendo-grid-column field="isInPar" title="Actions" width="100px" [filterable]="false">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Actions</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="group-column">
        <span
          class="clickable fa-stack fa-2x switch-employee-action actionIcon"
          *ngIf="!isEmptyEmployee(dataItem) && !isInPar(dataItem) && !checkPartnerData(dataItem)"
          (click)="switchEmployee(dataItem)"
        >
          <i class="fa fa-circle fa-stack-2x" aria-hidden="true"></i>
          <i
            class="fas fa-exchange-alt fa-rotate-90 fa-stack-1x fa-inverse"
            aria-hidden="true"
            slxTooltip="Switch Employee Shift"
            tipPosition="left"
          ></i>
        </span>
        <span
          class="clickable fa-stack fa-2x add-employee-action actionIcon"
          *ngIf="isEmptyEmployee(dataItem) && isNoPatner(dataItem) && dataItem.AllowAssignment"
          (click)="fillShift(dataItem)"
        >
          <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
          <i class="fa fa-plus fa-stack-1x" aria-hidden="true" slxTooltip="Add Employee" tipPosition="left"></i>
        </span>
        <span
          class="clickable fa-stack fa-2x remove-employee-action actionIcon"
          *ngIf="!isEmptyEmployee(dataItem) && !checkPartnerData(dataItem)"
          (click)="removeEmployee(dataItem)"
        >
          <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
          <i
            class="fa fa-minus fa-stack-1x"
            aria-hidden="true"
            [slxTooltip]="getRemoveTip(dataItem)"
            tipPosition="left"
          ></i>
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
  [sortable]="false"
  [filterable]="false"
  class="overflow-visible-cell"
  width="120"
>
  <ng-template kendoGridCellTemplate let-dataItem>
    <div
      *ngIf="!isEmptyEmployee(dataItem) || (isEmptyEmployee(dataItem) && !isNoPatner(dataItem))"
      class="dropdown icon-action-menu btn-sizes">
      <slx-action-list>      
        <slx-action-button [popperContent]="popperContent" [popperPosition]="'bottom-end'"  >
          <span
           type="button"
            data-toggle="dropdown"
            aria-haspopup="true" >
            <i class="fa fa-ellipsis-h fa-2x actionIcon" aria-hidden="true"></i>
          </span> 
        </slx-action-button>
        <popper-content #popperContent [ngClass]="singleRowSelected ? 'dua-popper-content' : ''">
            <slx-action-list-item
            *ngIf="checkPartnerData(dataItem) && cancelRequestShift && checkPartnerData(dataItem)"
            (click)="cancelConfirmation(dataItem,'facility')"   >
            <i class="fa fa-ban" aria-hidden="true"></i> <span class="acton-icons">Cancel</span>
            </slx-action-list-item>
          <span *ngIf="checkCancelActions(dataItem, 'noshow')">
            <slx-action-list-item
            (click)="cancelConfirmation(dataItem, 'noshow')" >
            <i class="fa fa-ban" aria-hidden="true"></i> <span class="acton-icons">Cancel No Show</span>
            </slx-action-list-item>
           </span>
           <span *ngIf="checkCancelActions(dataItem, 'calledout')"> 
            <slx-action-list-item
              *ngIf="checkPartnerData(dataItem) &&  cancelRequestShift && checkPartnerData(dataItem) && !showMutliPartnerRows(dataItem)"
              (click)="cancelConfirmation(dataItem,'calledout')" >
              <i class="fa fa-ban" aria-hidden="true"></i> <span class="acton-icons">Cancel Called Out </span>
            </slx-action-list-item>
          </span>
            <slx-action-list-item
              *ngIf="canReplaceEmployee && !checkPartnerData(dataItem)"
              (click)="replaceEmployee(dataItem)" >
              <i class="fas fa-exchange-alt" aria-hidden="true"></i> <span class="acton-icons">Replace</span>
            </slx-action-list-item>
            <slx-action-list-item
              *ngIf="!isEmptyEmployee(dataItem)"
              (click)="openIndividual(dataItem)"          >
              <i class="far fa-calendar-check" aria-hidden="true"></i>
              <span class="acton-icons">Individual Schedule</span>
            </slx-action-list-item>
        </popper-content>
      </slx-action-list>
    </div>
    <div class="message-badge-host">
      <slx-pending-messages-badge
        *ngIf="(isEmptyEmployee(dataItem) && getMessageCount(dataItem) > 0) || isChildShiftHasRequest(dataItem)"
        [zoom]="true"
        [messageCount]="getMessageCount(dataItem) || childRequestCount(dataItem)"
        slxTooltip="Open Shift Requests"
        tipPosition="left"
      ></slx-pending-messages-badge>
    </div>
  </ng-template>
</kendo-grid-column>


  <ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="showMutliPartnerRows"> 
    <slx-partner-agency-details *ngIf="dataItem.SlxpartnerdataId > 0" [requestId]="dataItem.SlxpartnerdataId" [agencyMap]="agencyMap">
    </slx-partner-agency-details>
    <slx-daily-unit-grid-partialshift *ngIf="dataItem.HasPartialShift" [partials]="dataItem.PartialShiftDailyUnitGridEmployee" (onFillShiftPartial)="onFillShiftPartial($event)">
  </slx-daily-unit-grid-partialshift>
  </ng-template>

  <kendo-grid-messages filterMenuTitle="Filter"  >
  </kendo-grid-messages>
</kendo-grid>



<div id="excelGrid" style="display: none" *ngIf="showGrid">
  <slx-export-excel-grid #exportRef *ngIf="GroupedListArray" [ExportGroupData]="GroupedListArray" [PartnerIsEnabled] ="IsEnabled" [dateOn] = "dateOn"></slx-export-excel-grid>

</div>

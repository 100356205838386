<slx-spinner [show]="state.isLoading" [showMessage]="state.showSpinnerMessage">

    <div class="btn-align duplicate-workers-header">
        <div>
            <slx-dropdown-list class="slx-wide slx-small-label" [options]="recordControls" placeholder ="Show Records" valueField="value" titleField="text" [(ngModel)]="dispoState" (ngModelChange)="getFilteredRecords($event)">
                </slx-dropdown-list>
        </div>
        <span class="d-flex">
         <button type="button" (click)="getRefreshedRecords()" class="slx-button slx-min-w90 slx-margin-l refresh-btn">Refresh</button>   
         <i aria-hidden="true" class="fa fa-info-circle info-icon refresh-info-icon"><span [slxTooltip]="getRefreshToolTip()" [tipClass]="'wide-tip'" tipPosition="bottom"></span></i>
       
         <button type="button" class="hideShowIcon slx-toolbar" [popper]="columnsToggler"
            [popperTrigger]="'click'" [popperPlacement]="'bottom-start'" [popperDisableStyle]="true">
            <i class="fas fa-sliders-h" aria-hidden="true"></i>
        </button>
        <popper-content #columnsToggler>
            <div class="slx-pbj-popper">
            <p class="pbj-popper-title">Duplicate Workers Columns to Display</p>
            <div class="button-container">
                <div *ngFor="let columnName of colNamesForSettings" class="col-12 col-to-hide d-flex">
                    <input class="mt-0 check-styling" type="checkbox" id="{{ column }}" kendoCheckBox
                        [checked]="!isHidden(columnName)" (change)="hideColumn(columnName, $event.target)" />
                    <label class="k-checkbox-label col-align" for="{{ columnName }}">{{ columnName }}</label>
                </div>
                <button class="slx-button slx-margin-r" type="button" (click)="selectClearCols('select')"> Select All </button>
                <button class="slx-button" type="button"(click)="selectClearCols('clear')"> Clear All</button>
            </div>
            </div>
        </popper-content>
        <span class="action-btns">
            <slx-actions-list>
                <slx-actions-button className="low-height auto-width" [popperContent]="Actionsettings"
                    [popperPosition]="'bottom-end'">
                    <i class="fas fa-cog" aria-hidden="true"></i> <span style="margin-left: 10px">Actions</span>
                </slx-actions-button>
                <popper-content #Actionsettings>
                    <slx-actions-list-item (click)="exportExcel()">Export to Excel</slx-actions-list-item>
                    <slx-actions-list-item (click)="openExportMatchesDialog(true)">Export Matches</slx-actions-list-item>
                </popper-content>
            </slx-actions-list>
        </span>
       </span>
    </div>
    <ng-template #template let-anchor>
      Highest point score (maximum of 26 points) is the most likely match. Lowest point score (minimum of 4 points) is the least likely match. Fuzzy Match logic is used comparing different data points such as Name, Email, SSN, DOB, Phone, etc.
      </ng-template>

      <span kendoTooltip class="hide-scroll"
      showOn="none"
      [tooltipTemplate]="template"
      filter=".k-grid td"
      (mouseover)="showTooltip($event)"
        >
    <kendo-grid #exportKendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height duplicate-workers-grid" [data]="gridState.view" [pageable]="true" [pageSize]="pageSize" [skip]="gridState.state.skip"   (pageChange)="pageChange($event)"        [reorderable]="true" (columnReorder)="restrictReorder($event)" [filterable]="'menu'"
        [filter]="gridState.state.filter"  (excelExport)="onExcelExport($event)"
        (filterChange)="filterDuplicates($event)" [sortable]="{ mode: 'multiple' }" [sort]="gridState.state.sort"  (sortChange)="sortChange($event)" (dataStateChange)="gridState.dataStateChange($event)">
        <ng-container *ngFor="let column of columns.columns">
              <kendo-grid-column  field="{{column.name}}" [width] = "column.width" [filter]="column.filter"
                                title="{{column.description}}" *ngIf="!isHidden(column.description)" [locked]="getIsColumnLocked(column.name)">
                <ng-template kendoGridHeaderTemplate>
                    <span class="text-align">{{column.description}}</span>
                    <i aria-hidden="true" *ngIf="column.description == 'Match Score'" class="fa fa-info-circle info-icon match-score"></i>
                  </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <slx-dropdown-list *ngIf="column.name == 'disposition'" class="slx-wide slx-small-label" [options]="dispoOptions"
                        [ngModel]="setSelectedPosition(dataItem.disposition)" valueField="value" titleField="text"
                    (ngModelChange)="handleDisposition($event, dataItem)">
                    </slx-dropdown-list>
                    <span *ngIf="column.name == 'disposition_Date'">{{ dataItem.disposition_Date | amDateFormat: appConfig.dateFormat }}</span>
                     <a *ngIf="column.name == 'pbJ_ID_1'" [employeeSectionLink]="dataItem?.emp_id_1">{{dataItem[column.name]}}</a> 
                 
                    <a *ngIf="column.name == 'pbJ_ID_2'" [employeeSectionLink]="dataItem?.emp_id_2">{{dataItem[column.name]}}</a>    
                    <span *ngIf="column.name == 'birthday_1'">{{dataItem[column.name] | amDateFormat: appConfig.dateFormat}} 
                    </span>
                    <span *ngIf="column.name == 'birthday_2'">{{dataItem[column.name] | amDateFormat: appConfig.dateFormat}} 
                    </span>
                    
                    <span *ngIf="column.name != 'pbJ_ID_1' && column.name != 'pbJ_ID_2' && column.name != 'birthday_1' && column.name != 'birthday_2' && column.name !='disposition' && column.name != 'disposition_Date'">{{dataItem[column.name]}}</span>
                </ng-template>
            </kendo-grid-column>
        </ng-container>
     
        <kendo-grid-excel [fetchData]="getExportData" [fileName]="xlsxName"></kendo-grid-excel>

        <kendo-grid-messages filterMenuTitle="Filter">
        </kendo-grid-messages>  
    </kendo-grid>
    </span>
</slx-spinner>
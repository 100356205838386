
import {interval as observableInterval,  Subscription ,  Subject ,  Observable } from 'rxjs';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { ThrottlingService } from '../throttling/throttling.service';
import { unsubscribeInService } from '../../decorators/index';
import { ThrottlingChangeEvent, TabMode } from '../../models/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { SessionService } from '../session/session.service';


@Injectable()
export class TokenValidityService {

    public onTokenExpired: Subject<any>;
    public onTokenRenewed: Subject<any>;

    @unsubscribeInService()
    private throttleSubscrtiption: Subscription;
    @unsubscribeInService()
    private renewSubscription: Subscription;
    @unsubscribeInService()
    private exprireSubscription: Subscription;
    @unsubscribeInService()
    private intervalSubscription: Subscription;

    constructor(private throttle: ThrottlingService, private session: SessionService) {

        this.onTokenExpired = new Subject<any>();
        this.onTokenRenewed = new Subject<any>();

        this.throttleSubscrtiption = this.throttle.subscribeToModeChange((e: ThrottlingChangeEvent) => this.subscribeToModeChange(e));

        this.renewSubscription = this.session.onTokenRenewed.subscribe(() => this.subscribeToTokenRenewed());

        this.exprireSubscription = this.session.onTokenRemoved.subscribe(() => this.subscribeToTokenRemoved());
    }

    private subscribeToModeChange(e: ThrottlingChangeEvent): void {
      // if we coming back to front from background tab intervals possibly where paused and we should check if token exprired immediatelly
      if (e.mode === TabMode.FRONT) {
        if (this.session.isExpired()) {
            this.clearInterval();
            this.onTokenExpired.next();
        }
      }
    }

    private subscribeToTokenRenewed(): void {
      this.clearInterval();
      let tokenExpDate: Date = this.session.getTokenExpirationDate();
      let diff: number = moment(tokenExpDate).subtract(appConfig.session.startRenewUntilSessionExpiredSeconds / 2, 'seconds').diff(moment());
      if (diff > 0) {
          this.intervalSubscription = observableInterval(diff)
              .subscribe(() => {
                  this.clearInterval();
                  this.onTokenExpired.next();
              });
          this.onTokenRenewed.next();
      }
    }

    private subscribeToTokenRemoved(): void {
      this.clearInterval();
      this.onTokenExpired.next();
    }

    private clearInterval(): void {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
            this.intervalSubscription = null;
        }
    }

}

import * as _ from 'lodash';
import { ElementRef, NgZone } from '@angular/core';

import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';

import { ReplaySubject ,  Subscription } from 'rxjs';


import { ViewModeSettings, SearchModeSettings, CoordsModeSettings, MapCircleChangedEvent } from '../../../models/index';
import { DomSanitizer } from '@angular/platform-browser';
import { SlxUrlSafePipe} from '../../../../common/pipes/slx-url-safe';
import { IApplicationConfig, appConfig } from '../../../../../app/app.config';
export class MapCircle {
  public lat: number;
  public lng: number;
  public radius: number;
  constructor(lat: number, lng: number, r: number) {
    this.lat = lat;
    this.lng = lng;
    this.radius = r;
  }
}

@Component({
    moduleId: module.id,
    selector: 'slx-geolocation-map',
    templateUrl: 'geolocation-map.component.html',
    styleUrls: ['geolocation-map.component.scss']
})
export class GeolocationMapComponent implements OnInit, OnDestroy {
  @Input()
  public set viewModeSettings(s: ViewModeSettings) {
    // console.log("debug view",s);
    if (_.isObjectLike(s)) {
      this.checkViewModeSettings(s);
    }
  }
  @Input()
  public set activeModeSettings(s: SearchModeSettings | CoordsModeSettings) {
    // console.log("debug active",s);
    
    if (_.isObjectLike(s) || _.isNull(s)) {
      this.checkActiveModeSettings(s);
    }
  }
  @Input()
  public set overrideBy(v: { lat: number, lng: number, overriddenDistance : number, distance : number }) {
    // console.log("debug INPUT override",v);
    if (_.isObjectLike(v) && _.isFinite(v.lat) && _.isFinite(v.lng) || _.isNull(v)) {
     this.applyOverrideMode(v);
    }
  }


  @Output()
  public radiusChanged: EventEmitter<number>;
  @Output()
  public centerChange: EventEmitter<MapCircleChangedEvent>;
  @Output()
  public addressNotFound: EventEmitter<boolean>;

  public get hasError(): boolean {
    return _.isString(this.notFoundFor) && _.size(this.notFoundFor) > 0;
  }

  private get gMaps(): any {
    return (window as unknown as Window & { google: any }).google.maps;
  }

  private readonly externalAppUIUrl: string = appConfig.links.appLink;
  private readonly googleMapsApiKey: string = process.env["GOOGLE_MAPS_API_LICENSE"];
  public mapLat: number;
  public mapLng: number;
  public mapZoom: number;
  public searchForPlace: string;
  public circle: MapCircle;
  public freezedCircle: MapCircle;
  private viewMode: ViewModeSettings;
  private coordinatesMode: CoordsModeSettings;
  private searchMode: SearchModeSettings;
  private viewModeReady$: ReplaySubject<void> = new ReplaySubject<void>();
  private coordinatesModeReady$: ReplaySubject<void> = new ReplaySubject<void>();
  private searchModeReady$: ReplaySubject<void> = new ReplaySubject<void>();
  public overrideModeOn: boolean = false;
  public notFoundFor: string = '';
  public isUrl : boolean = false;
  public url : string;
  public geoUrl : string;
  public location : StringMap<any>;
  public viewModeDefaults = { radius: 300, commonZoom: 7, placeZoom: 16 };

  public circleSettings = {
    canDrag: true,
    canEdit: true,
    circleColor: '#0092cb',
    circleOpacity: 0.5,
    freezedCanDrag: false,
    freezedCanEdit: false,
    freezedCircleColor: '#f68d2e',
    freezedCircleOpacity: 0.5
  };
  public defaults = {
    maxRadius: 1000
  };

  public errorCode : number = 0;

  private subscriptions: StringMap<Subscription> = {};
  private readonly fields: string = 'formatted_address,geometry,id,name,place_id,types';

  constructor()  {
    this.radiusChanged = new EventEmitter<number>();
    this.centerChange = new EventEmitter<MapCircleChangedEvent>();
    this.addressNotFound = new EventEmitter<boolean>();
    this.geoUrl = "/assets/geo/geojs.html?url=" + encodeURI(this.externalAppUIUrl) + "&license=" + this.googleMapsApiKey + "&";
    
  }

  public ngOnInit(): void {

    window.addEventListener('message', (event) => {
    //   console.log("debug message", event, event.data.from, this.externalAppUIUrl, event.origin);
      // Ensure the origin is trusted (security measure)
      if ( this.externalAppUIUrl == null || !this.externalAppUIUrl.startsWith(event.origin) ||  event.data.from === undefined || event.data.from !== 'geo' ) {
         console.log("debug Not GEO message", event);
        return;
      }

  
      // Access the data sent by the iframe
      const message = event.data;
      // console.log("debug received message", message);
    
      if ( message.mode == 'error') {
        let errorMessage = "failed";
        this.errorCode = message.errorCode;
        if ( message.errorCode == 3 ) {
          errorMessage = this.searchMode.addressForSearch;
        }
        
        this.showErrorMessage(errorMessage);
      } else {

        this.location = { lat : message.lat, lng : message.lng};
        // console.log("debug received mode", message.mode);
        // Common for blue or orange circle
        if ( message.modifiedField ==  'radius') {
          this.onRadiusChange(message.radius);
        }
        // Find - maps to the blue circle
        if ( message.mode == 'find' || message.mode == 'l' ) {
          // console.log("debug updating address coordinates");
          this.showResults(this.location);
        
        } else {
           // override modes - orange circle
           if ( message.modifiedField ==  'center' ) {
            let c =  new MapCircleChangedEvent(this.location.lat, this.location.lng, true);
            this.onCenterChange(c);
          }
        }
      }
    
    });

    
    this.subscriptions.viewMode = this.viewModeReady$
      .subscribe(() => {
        // console.log("debug", this.viewMode);
          this.mapLat = this.viewMode.lat;
          this.mapLng = this.viewMode.lng;
          this.mapZoom = this.viewMode.zoom;
        });

    this.subscriptions.searchMode = this.searchModeReady$
      .subscribe(() => {
          this.findAddress(this.searchMode.addressForSearch);
        });

    this.subscriptions.searchMode = this.coordinatesModeReady$
      .subscribe(() => {
          this.findLocation();
        });
  }

  
  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }


  public onRadiusChange(r: number): void {
    let radius = r;
    /******
      We need to make sure user doesn't be able to set radius more than 1000,
      the only way to do this - update radius immediately if it exceeds max radius value
    ******/
    if (radius > this.defaults.maxRadius) {
      radius = this.circle.radius === this.defaults.maxRadius ? this.defaults.maxRadius - 1 : this.defaults.maxRadius;
    }
    if (this.circle.radius === radius) {
      return;
    }
    this.circle.radius = radius;
    this.radiusChanged.next(radius);
  }

  public onCenterChange(c: { lat: number, lng: number }, dragged: boolean = true): void {
    this.centerChange.next(new MapCircleChangedEvent(c.lat, c.lng, dragged));
  }

  private checkViewModeSettings(s: ViewModeSettings): void {
    if (_.isNumber(s.lat) && _.isNumber(s.lng) && _.isNumber(s.zoom)) {
      this.viewMode = new ViewModeSettings(s.lat, s.lng, s.zoom);
      this.notFoundFor = null;
      this.viewModeReady$.next();
    }
  }

  private checkActiveModeSettings(s: SearchModeSettings | CoordsModeSettings): void {
    // console.log("debug checkActiveModeSettings", s);
    this.notFoundFor = null;
    if (_.isNull(s)) {
      this.resetToViewMode();
    } else if (s instanceof CoordsModeSettings) {
      this.applyCoordinatesMode(s);
    } else if (s instanceof SearchModeSettings) {
      this.applySearchMode(s);
    }
  }

  private applyCoordinatesMode(s: CoordsModeSettings): void {
    // console.log("debug CoordsModeSettings", s);
    if (_.isNumber(s.lat) && _.isNumber(s.lng) && _.isNumber(s.circleRadius) && _.isNumber(s.zoom)) {
      this.coordinatesMode = new CoordsModeSettings(s.lat, s.lng, s.circleRadius, s.zoom);
      this.isUrl = true;
      this.url =  this.geoUrl + "mode=l&lat=" + s.lat + "&lng=" + s.lng + "&radius=" + s.circleRadius;

      this.coordinatesModeReady$.next();
    }
  }

  private applySearchMode(s: SearchModeSettings): void {
    
    
    // console.log("debug applySearchMode", s);
    if (_.isString(s.addressForSearch) && _.isNumber(s.circleRadius) && _.isNumber(s.zoom)) {
      this.isUrl = true;
      this.url =  this.geoUrl + "address=" + s.addressForSearch + "&radius=" + s.circleRadius;

      let d = new Date();
      // console.log("debug inside sesss", s, d.getMilliseconds());
      this.searchMode = new SearchModeSettings(s.addressForSearch, s.circleRadius, s.zoom);
      this.searchModeReady$.next();
    }
  }

  private applyOverrideMode(coords: { lat: number, lng: number, overriddenDistance : number, distance: number }): void {
    // console.log("debug applyoverride received cords settings", coords);
    this.notFoundFor = null;
    const overrideModeOn = !_.isNull(coords);
    if (this.overrideModeOn !== overrideModeOn) {
      this.overrideModeOn = overrideModeOn;
      // console.log("debug applyoverride", this.overrideModeOn);
      this.isUrl = true;
      if (this.overrideModeOn) {
        this.freezedCircle = new MapCircle(this.circle.lat, this.circle.lng, this.circle.radius);
        this.circle = new MapCircle(coords.lat, coords.lng, this.circle.radius);
       
      this.url =  this.geoUrl + "mode=o&lat=" + this.freezedCircle.lat + "&lng=" + this.freezedCircle.lng + "&radius=" + coords.distance + "&olat=" +
      coords.lat + "&olng=" + coords.lng + "&oradius=" + this.circle.radius;

        this.onCenterChange(this.getCoords(this.circle), false);
        // console.log("debug applyoverride", this.freezedCircle, this.circle);
      } else {
        if (_.isObjectLike(this.circle) && _.isObjectLike(this.freezedCircle)) {
          this.circle = new MapCircle(this.freezedCircle.lat, this.freezedCircle.lng, this.freezedCircle.radius);
          this.url =  this.geoUrl + "mode=l&lat=" + this.circle.lat + "&lng=" + this.circle.lng + "&radius=" + this.circle.radius;
          // console.log("debug applyoverride", this.circle);
          this.onCenterChange(this.getCoords(this.circle), false);
        }
        this.freezedCircle = null;
      }
    }
  }

  private resetToViewMode(): void {
    // console.log("debug reset view mode");
    //this.activeModeSettings = null;
    this.mapLat = this.viewMode.lat;
    this.mapLng = this.viewMode.lng;
    this.mapZoom = this.viewMode.zoom;
    this.notFoundFor = null;
    this.url =  this.geoUrl + "address=";
    this.circle = null;
  }

  private findAddress(address: string): void {
    // console.log("debug findAddress", address);
    this.isUrl = true;
    this.url =  this.geoUrl + "address=" + address + "&radius=" + this.viewModeDefaults.radius + "&mode=find" ;
    
  }

  private showResults(results:any): void {
    const coords = this.getCoords(results);
    this.mapLat = coords.lat;
    this.mapLng = coords.lng;

    if (this.searchMode && this.searchMode.zoom) {
      this.mapZoom = this.searchMode.zoom;
    }

    let radius = this.viewModeDefaults.radius;
    
    if (this.searchMode && this.searchMode.circleRadius) {
      radius = this.searchMode.circleRadius;
    }

    this.circle = new MapCircle(coords.lat, coords.lng, radius);
    this.notFoundFor = null;
    this.addressNotFound.next(true);
    this.onCenterChange(coords, false);
  }

  private findLocation(): void {
    // console.log("debug findlocation ",this.coordinatesMode);
    const coords = this.getCoords(this.coordinatesMode);
    this.mapLat = coords.lat;
    this.mapLng = coords.lng;
    this.mapZoom = this.coordinatesMode.zoom;
    this.circle = new MapCircle(coords.lat, coords.lng, this.coordinatesMode.circleRadius);
  }

  private showErrorMessage(searchText: string): void {
    this.notFoundFor = searchText;
    this.addressNotFound.next(false);
  }

  private getCoords(obj: StringMap<any>): { lat: number, lng: number } {
    const lat = _.isFunction(obj.lat) ? obj.lat() : obj.lat;
    const lng = _.isFunction(obj.lng) ? obj.lng() : obj.lng;

    return { lat, lng };
  }

  
 
}

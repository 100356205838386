import { Provider } from '@angular/core';

export * from './roster/index';
export * from './crud/index';
export * from './config/index';
export * from './workers-compensation-table/workers-compensation-table-api.service';
export * from './workers-compensation-table/workers-compensation-table-map.service';

import { rosterServices } from './roster/index';
import { crudServices } from './crud/index';
import { configServices } from './config/index';
import { WorkersCompensationTableApiService } from './workers-compensation-table/workers-compensation-table-api.service';
import { WCTMapService } from './workers-compensation-table/workers-compensation-table-map.service';

export const services: Provider[] = [
    rosterServices,
    crudServices,
    configServices,
    WorkersCompensationTableApiService,
    WCTMapService
];

import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable ,  ReplaySubject ,  Subscription ,  Subject } from 'rxjs';
import { Actions } from '../../../core/models/field/actions-type';
import { ManagementBaseService } from '../../../core/services/index';
import { Department, Organization } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { LookupApiService } from '../../../organization/services/lookup/lookup-api.service';
import { ChangeManagementService } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { IConfigutrationContainer } from '../../models/configiration-container.interface';
import { AbsenceModel } from '../../models/absences/absence.model';
import { AbsencesContainer } from '../../models/absences/absences-container';
import { AbsenceConfigurationApiService } from '../../services/absences/absence-configuration-api.service';

@Injectable()
export class AbsencesConfigurationManagementService extends ManagementBaseService<AbsencesContainer, any> implements IConfigurationManagementService {

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    public removeItemsCmd$: ReplaySubject<AbsenceModel>;
    public addItemCmd$: ReplaySubject<AbsenceModel>;
    public editItemCmd$: ReplaySubject<AbsenceModel>;
    public viewRefresh$: Subject<boolean>;
    public dataChanged$: Subject<boolean>;

    public editingItem: any;
    public isEditingNewItem: boolean;

    public get container(): IConfigutrationContainer {
        return this.m_container;
    }

    private m_container: IConfigutrationContainer;

    private currentOrgLevel: OrgLevel;

    @unsubscribeInService()
    private orgLevelSubscription: Subscription;

    constructor(public access: AccessManagementService, public changeService: ChangeManagementService, private api: AbsenceConfigurationApiService, private lookup: LookupApiService) {
        super();
        this.removeItemsCmd$ = new ReplaySubject<AbsenceModel>();
        this.addItemCmd$ = new ReplaySubject<AbsenceModel>();
        this.editItemCmd$ = new ReplaySubject<AbsenceModel>();
        this.dataChanged$ = new Subject<boolean>();
        this.viewRefresh$ = new Subject<boolean>();
    }

    public init(): void {

        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = false;

        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            if (_.isNumber(orgLevel.id)) {
                this.currentOrgLevel = orgLevel;
                this.access.orgLevelType = this.currentOrgLevel.type;
                this.onStateChanged$.next({orgLevelChanged:true, configureMode: true, copyMode: false });
                this.fetchRecords();
            }
        });
    }

    public markAsDirty (): void {
        this.changeService.changeNotify ();
    }

    public setSelectedCount(count: number): void {
        this.access.selectedItemsCount = count;
    }

    public onAddItem(item: any): void {
        this.changeService.changeNotify ();
        this.access.lockActions = true;
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    }

    public onEditItem(item: any): void {
        this.access.lockActions = true;
        this.editingItem = item;
        this.editItemCmd$.next(item);
    }

    public onCancelEditItem(): void {
        this.access.lockActions = false;
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.editItemCmd$.next(null);
        this.changeService.clearChanges ();
    }

    public onRemoveItem(itemToDelete: AbsenceModel): void {
        this.removeItemsCmd$.next(itemToDelete);
    }

    public doRemoveItem(item: AbsenceModel): void {
        this.onStateChanged$.next({ isLoading: true });
        this.api.deleteAbsence(this.currentOrgLevel.id, item)
            .then((items: AbsenceModel[]) => {
                this.access.lockActions = false;
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    public onSaveItem(info: { dataItem: AbsenceModel, isNew: boolean }): void {
        if (info.isNew) {
            this.addItem(info.dataItem);
        } else {
            this.updateItem(info.dataItem);
        }
    }

    protected addItem(item: AbsenceModel): void {
        _.each(this.m_container.records, (p: AbsenceModel) => {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.addAbsence(this.currentOrgLevel.id, item)
            .then((items: AbsenceModel[]) => {
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.changeService.clearChanges();
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    protected updateItem(item: AbsenceModel): void {
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.saveAbsence(this.currentOrgLevel.id, item)
            .then((items: AbsenceModel[]) => {
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.viewRefresh$.next(false);
                this.changeService.clearChanges();
                this.onStateChanged$.next({ isLoading: false });
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    protected fetchRecords(): void {
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        this.api.getAbsences(this.currentOrgLevel.id).
            then((result: { actions: Actions, records: AbsenceModel[] }) => {
                this.changeService.clearChanges ();
                this.m_container = new AbsencesContainer();
                this.m_container.records = result.records;
                this.access.actions = result.actions;
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.onLoaded$.next(this.m_container);
                this.onStateChanged$.next({ isLoading: false });
            }).catch(() => {
                this.access.lockActions = false;
                this.onStateChanged$.next({ isLoading: false });
            });

    }
}


import * as _ from 'lodash';
import * as moment from 'moment';
import { Shift, IShift } from '../../models/shifts/shift';
import { Injectable } from '@angular/core';
import { LookupMapService } from '../../../organization/services/lookup/lookup-map.service';
import { ShiftDefinition, IShiftDefinition, ILookupEntity, LookupEntity } from '../../../organization/models/lookup/index';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import { ShiftSetting } from '../../models/shifts/shift-setting';

@Injectable()
export class ShiftsMapService {

    private lookupMapService: LookupMapService;

    constructor(lookupMapService: LookupMapService) {
        this.lookupMapService = lookupMapService;
    }

    public mapShifts(data: IShift[]): Shift[] {        
        let shiftMap = new Map<number,Shift>();
        let shifts: Shift[] = [];
        let allPartailShiftList: Shift[] = [];
        _.each(data, (dto: IShift) => {            
            if (dto.parentShiftId > 0)
            {
                //store partials shifts in temporary storage
                let s = this.convertDataToShift(dto);
                allPartailShiftList.push(s);
 
            }
            else
            {
                let s = this.convertDataToShift(dto);
                shiftMap.set(dto.id,s)
                shifts.push(s);
            }
        });

        _.each(allPartailShiftList, (ps:Shift) => {
                if(shiftMap.has(ps.parentShiftId)) {
                    shiftMap.get(ps.parentShiftId).partialShiftList.push(ps);
                }
        });
        // Sort partial shifts for each main shift based on start and end time
        shifts.forEach(shift => {
            if (!shift.partialShiftList || shift.partialShiftList.length === 0) {
                return;
            }
            const parentStartTime = moment(shift.start);
            shift.partialShiftList.sort((a, b) => {
                // Get start times as moment objects
                const aStartTime = moment(a.start);
                const bStartTime = moment(b.start);

                // Normalize start times by adding a day if they are less than the parent start time
                const normalizeStartTime = (startTime: moment.Moment): moment.Moment => 
                    startTime.isBefore(parentStartTime) ? startTime.add(1, 'day') : startTime;

                // Sort based on the normalized start times
                return normalizeStartTime(aStartTime).valueOf() - normalizeStartTime(bStartTime).valueOf();
            });
        });
        return shifts;
    }

    public convertDataToShift(data: IShift): Shift {

        let definition: ShiftDefinition = this.lookupMapService.mapShiftDefinition(<IShiftDefinition>data);

        let shift: Shift = new Shift();

        shift.id = definition.id;
        shift.name = definition.name;
        shift.employeesCount = definition.employeesCount;
        shift.usedInIdealSchedule = definition.usedInIdealSchedule;
        shift.group = definition.group;

        let start: moment.Moment = moment(definition.startDate);
        let end: moment.Moment = moment(definition.endDate);
        if (start.isAfter(end)) {
            end.add(1, 'days');
        }

        shift.start = start.toDate();
        shift.end = end.toDate();

        shift.startTime = dateTimeUtils.getTimeTotalSeconds(shift.start);
        shift.endTime = dateTimeUtils.getTimeTotalSeconds(shift.end);

        shift.duration = Math.round(dateTimeUtils.getDurationDiffHours(shift.start, shift.end) * 100) / 100;
        if (definition.lunchDurationDate) {
            shift.lunchDuration = _.ceil(definition.lunchDurationDate.getHours() + definition.lunchDurationDate.getMinutes() / 60, 2);
        } else {
            shift.lunchDuration = 0;
        }
        if(shift.lunchDuration) shift.duration = _.ceil(shift.duration - shift.lunchDuration, 2);

        shift.lastUpdateUsername = data.lastUpdateUsername;
        shift.lastUpdateDate = dateTimeUtils.convertFromDtoString(data.lastUpdateDate);
        shift.defaultshift = data.defaultshift;
        shift.setDefaultShift = data.defaultshift === 0 ? 'NO' : 'YES';
        shift.hasPartialShift = data.hasPartialShift;
        shift.parentShiftId = data.parentShiftId;
        shift.shiftSetting = new ShiftSetting();
        return shift;
    }

    public convertShiftToData(shift: Shift): IShift {
        let duration: moment.Duration = moment.duration(shift.duration, 'hours');
        let durationStr: string = dateTimeUtils.convertToDtoDurationString(duration);

        let lunchDuration: moment.Duration = moment.duration(shift.lunchDuration, 'hours');
        let lunchDurationStr: string = dateTimeUtils.convertToDtoDurationString(lunchDuration);

        let data: IShift = {
            id: shift.id,
            name: shift.name,
            start: dateTimeUtils.convertToDtoHourMinutesString(shift.start),
            end: dateTimeUtils.convertToDtoHourMinutesString(shift.end),
            duration: durationStr,
            employeesCount: shift.employeesCount,
            usedInIdealSchedule: shift.usedInIdealSchedule,
            lastUpdateUsername: shift.lastUpdateUsername,
            lastUpdateDate: dateTimeUtils.convertToDtoString(shift.lastUpdateDate),
            lunchDuration: lunchDurationStr,
            group: this.convertGroupToData(shift.group),
            setDefaultShift: shift.setDefaultShift,
            defaultshift: shift.setDefaultShift === 'YES' ? 1 : 0,
            hasPartialShift: shift.hasPartialShift,
            parentShiftId: shift.parentShiftId <= 0 ? null : shift.parentShiftId,
            partialShiftList: shift.partialShiftList.length > 0 ? this.converPartialShifts(shift.partialShiftList) : [],
            isUpdated: shift.isUpdated,
            isDeleted: shift.isDeleted,
            shiftSetting: shift.hasPartialShift ? { 
                            shiftId : shift.id,
                            allowAgencyAccess : shift.shiftSetting.allowAgencyAccess,
                            allowPartialRequest : shift.shiftSetting.allowPartialRequest 
                           } : new ShiftSetting() 
        };
        return data;
    }
    converPartialShifts(partials: Shift[]): IShift[] {
       let shiftlist: IShift[]  = [] ; 
       if(partials){
       _.each(partials, s => shiftlist.push (this.convertShiftToData(s)));
       }  
       return shiftlist;
    }

    public convertGroupToData(group: LookupEntity): ILookupEntity {
        let data: ILookupEntity = {
            id: group.id,
            name: group.name
        };
        return data;
    }
}

import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Subscription } from 'rxjs';

import { appConfig } from '../../../../../app.config';

import { unsubscribeAll } from '../../../../../core/decorators/index';

import { DateRange, IDateRange, IColumnSettings } from '../../../../../core/models/index';
import { WcNavigationService } from '../../../../../common/services/index';

import { OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';

import { WcViewItem, WcIncidentAmountItem, IWcRosterToolbarState, GenerateOshaFormAction, IGenerateOshaFormAction } from '../../../models/index';
import { WcRosterToolbarService } from '../../../services/index';

@Component({
  selector: 'slx-wc-roster-toolbar',
  templateUrl: './wc-roster-toolbar.component.html',
  styleUrls: ['./wc-roster-toolbar.component.scss'],
})
export class WcRosterToolbarComponent implements OnInit, OnDestroy {
  public dateRange: DateRange = new DateRange(null, null);
  public viewsList: WcViewItem[];
  public incidentsList: WcIncidentAmountItem[];
  public currentView: WcViewItem;
  public currentIncidentAmount: WcIncidentAmountItem;
  public prevView: WcViewItem;
  public state: IWcRosterToolbarState;
  public isActiveBtn: boolean = true;
  public toolbarActionsAllowed: boolean = true;
  public generatePDF300AAllowed: boolean = true;
  public generateExcel301Allowed: boolean = true;
  public generateExcel301AllowedDate: boolean = true;
  public canCreateNewIncident: boolean;
  public listHeight: number = 400;
  public generateOshaFormAction: IGenerateOshaFormAction;
  public ytdList: number[] = [];
  public ytdListDefaultVal: number[] = [];

  private orgLevel: OrgLevel;
  private navService: WcNavigationService;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private toolbarService: WcRosterToolbarService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.navService = new WcNavigationService(this.router, this.activatedRoute);
  }

  public ngOnInit(): void {
    this.generateOshaFormAction = new GenerateOshaFormAction();
    this.viewsList = this.toolbarService.getViewsList();
    this.incidentsList = this.toolbarService.getIncidentsList();
    this.currentIncidentAmount = this.toolbarService.getCurrentIncident();
    this.ytdList = this.toolbarService.ytdListRender();
    this.ytdListDefaultVal = [moment().year()];
    this.onYTDChange(this.ytdListDefaultVal);

    this.subscriptions.activatedRoute = this.activatedRoute.url
      .subscribe((url: UrlSegment[]) => {
        this.currentView = this.toolbarService.defineView(url);

      });

    this.subscriptions.range = this.toolbarService
      .subscribeToInitDateRange((r: IDateRange) => this.assignInitDateRange(r));

    this.subscriptions.state = this.toolbarService
      .subscribeToDefineState((s: IWcRosterToolbarState) => (this.state = s));

    this.subscriptions.orgLevel = this.toolbarService
      .subscribeToOrgLevel((o: OrgLevel) => this.getOrgLevel(o));

    this.subscriptions.permissionsChanged = this.toolbarService
      .subscribeToPermissionsChanged((canAdd: boolean) => (this.canCreateNewIncident = canAdd));

    this.subscriptions.toolbarActions = this.toolbarService
      .subscribeToToolbarActions((actionsAllowed: boolean) => (this.toolbarActionsAllowed = actionsAllowed));

    this.subscriptions.newIncident = this.toolbarService
      .subscribeToNewIncident((isActive: boolean) => (this.isActiveBtn = isActive));

  }

  public ngOnDestroy(): void {
    this.toolbarService.destroy();
  }

  public onYTDChange(selectedYearsList: number[]): void {

    if (selectedYearsList) {
      selectedYearsList.sort()
    } else {
      selectedYearsList = [moment().year()];
    }
    this.toolbarService.selectedYearRange(selectedYearsList);
  }

  public onChangeDates(range: DateRange): void {
    this.generateExcel301AllowedTime(range);
    this.toolbarService.changeDateRange(range);
  }

  public onClickCreate(): void {
    this.toolbarService.clickNewIncident(false);
    this.toolbarService.openNewReportDialog();
  }

  public onChangeView(): void {
    if (!_.isObjectLike(this.prevView)) {
      this.prevView = this.currentView;
      return;
    }
    if (this.prevView.path !== this.currentView.path && _.get(this.orgLevel, 'id')) {
      this.prevView = this.currentView;
      this.navService.navigateByPath(this.orgLevel.id, this.currentView.path);
    }
  }

  public onClickExport(isPDF: boolean): void {
    this.toolbarService.exportTo(isPDF);
  }

  public onChangeIncidentsAmount(): void {
    this.toolbarService.changeIncidentsAmount(this.currentIncidentAmount.id);
  }

  public onClickToggleSelection(isSelectAll: boolean): void {
    _.forEach(this.state.columns, (column: IColumnSettings) => {
      column.displayed = isSelectAll;
    });
    this.toolbarService.saveState(this.state);
  }

  public onChangeColumn(): void {
    this.toolbarService.saveState(this.state);
  }

  public isDesktop(): boolean {
    return screen.width > appConfig.mobileMaxWidth;
  }

  public getRangeWidth(): number {
    return this.isDesktop() ? 325 : 246;
  }

  public getBtnWidth(): number {
    return this.isDesktop() ? 175 : 35;
  }

  public getIncidentWidth(): number {
    return _.get(this.currentView, 'isRepeatInjuryList') ? 65 : 0;
  }

  private assignInitDateRange(r: IDateRange): void {
    if (
      _.isDate(r.startDate) &&
      _.isDate(r.endDate) &&
      (!moment(this.dateRange.startDate).isSame(r.startDate) || !moment(this.dateRange.endDate).isSame(r.endDate))
    ) {
      this.dateRange = r;
      this.generateExcel301AllowedTime(this.dateRange);
    }
  }

  public getOrgLevel(o: OrgLevel) {
    this.orgLevel = o;
    this.generatePDF300AAllowed = false;
    this.generateExcel301Allowed = false;
    if (o.type === OrgLevelType.organization) {
      this.generatePDF300AAllowed = true;
      this.generateExcel301Allowed = true;
    }
  }

  public generateOSHAForm(formAction: string) {
    return this.toolbarService.generateOSHAForm300A(formAction);
  }

  public generateExcel301AllowedTime(range: DateRange) {
    this.generateExcel301AllowedDate = (range.startDate.getFullYear() < 2016);
  }

  public getTitleValue() {
    if (this.generateExcel301AllowedDate) {
      return 'Per OSHA, the CSV cannot be generated for years prior to 2016';
    } else if (!this.generateExcel301Allowed) {
      return 'Please Select a Facility';
    } else {
      return ' ';
    }
  }

  public get IsGenerateActionEnabled() {
    return (!this.toolbarActionsAllowed || !this.generateExcel301Allowed || this.generateExcel301AllowedDate);
  }
}
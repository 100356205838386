import { Injectable } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';
import { EmployeeSearchBase } from '../../../../employee/employee/services/employee-search-base/employee-search-base';
import { Lookup, LookupType } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';

@Injectable()
export class LookupEmployeeProvider extends EmployeeSearchBase {
  private employees$ = new Subject<Lookup>();

  constructor(private lookupService: LookupService) {
    super();
  }

  public subscribeToEmployees(callback: (v: Lookup) => void): Subscription {
    return this.employees$.subscribe(callback);
  }

  public async getEmployees(orgLevelId: number, isActive: boolean): Promise<void> {
    const lookup = await this.lookupService.getLookup({
      lookupType: LookupType.employeeList,
      orgLevelId: orgLevelId,
      isActive: isActive
    });
    this.employees$.next(lookup);
  }
}

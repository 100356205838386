import { ValuePairChartModel } from './../../../../models/value-pair-chart/value-pair-chart-model';
import { Assert } from './../../../../../framework/assert/assert';
import { ValuePairChartDataService } from './../../../../services/value-pair-chart-data/value-pair-chart-data.service';
import { PairColorScheme } from './../../../../models/pair-color-scheme/pair-color-scheme';
import { ValuePairChartInput } from './../../../../models/value-pair-chart/value-pair-chart-input';
import { Subject } from 'rxjs';
import { Component, Input, EventEmitter, Output, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SeriesClickEvent } from '@progress/kendo-angular-charts';

@Component({
    moduleId: module.id,
    selector: 'slx-bar-chart-vertical-value-pair',
    templateUrl: 'bar-chart-vertical-value-pair.component.html',
    styleUrls: ['bar-chart-vertical-value-pair.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
export class BarChartVerticalValuePairComponent implements OnInit {

    @Input()
    public hideLabel: boolean = false; //Hiding label
    @Output()
    public onClick: EventEmitter<any>;

    public isFull: boolean;

    public originalValue1: number;
    public originalValue2: number;
    public displayValue1: number = 0;
    public displayValue2: number = 0;
    public labelValue: number;
    public labelColor: string;
    public seriesColors: string[];
    public fontColor1: string;
    public fontColor2: string;

    constructor(
      private changeDetection: ChangeDetectorRef,
      private dataService: ValuePairChartDataService
    ) {
      this.onClick = new EventEmitter<any>();
    }
    public ngOnInit(): void {
      Assert.isNotNull(this.dataService, 'dataService');

      this.dataService.onModelLoaded.subscribe((model: ValuePairChartModel) => {
        this.setValues(model.input);
        this.setColors(model.color);
        this.setLabel(model.additionalColor);

        this.changeDetection.markForCheck();
        this.changeDetection.detectChanges();
      });
    }

    public get maxValue(): number {
      if (this.originalValue1 === 0 && this.originalValue2 === 0) return 1;
      return Math.max(this.originalValue1, this.originalValue2);
    }

    public get isNegative(): boolean {
      return this.originalValue1 < this.originalValue2;
    }

    public setValues(values: ValuePairChartInput): void {
      this.originalValue1 = values.value1;
      this.originalValue2 = values.value2;
      this.isFull = this.originalValue1 === this.originalValue2;
      if (this.originalValue2 > 0) {
        this.displayValue2 = Math.abs(this.originalValue1 - this.originalValue2);
      }
      this.displayValue1 = this.maxValue - this.displayValue2;
      if(this.originalValue1 === 0 && this.originalValue2 === 0) {
        this.displayValue1 = 1;
      }
    }

    public setColors(colors: PairColorScheme): void {
      if (colors) {
        this.seriesColors = [colors.backgroundColor1, colors.backgroundColor2];
        this.fontColor1 = colors.fontColor1;
        this.fontColor2 = colors.fontColor2;
      }
      // } else {
      //   this.fontColor1 = '#FFFFFF';
      //   this.fontColor2 = '#CCCCCC';
      // }
    }

    public setLabel(color: string): void {
      this.labelValue = this.originalValue1 - this.originalValue2;
      this.labelColor = color;
    }

    public handleClick(event: SeriesClickEvent): void {
      if (event.originalEvent && event.originalEvent.type === 'contextmenu') {
        return;
      }

      this.onClick.emit(event);
    }
}

import { ILookupEntity, LookupEntity } from "./lookup-entity";

export interface IAgencyDefinition extends ILookupEntity {
  id: number;
  name: string;
  orgLevelId: number;
}

export class AgencyDefinition extends LookupEntity {
  public id: number;
  public name: string;
  public orgLevelId: number;
}


import {debounceTime} from 'rxjs/operators';
import { Subject } from 'rxjs';


export function debounce(miliseconds: number = 0): MethodDecorator {
  return function decorate(target: any, propertyKey: string, descriptor: PropertyDescriptor): TypedPropertyDescriptor<any> {
    let originalMethod: Function = descriptor.value;
    let originalContext: any = target;
    let subject: Subject<any[]> = new Subject();

    descriptor.value = function (...args: any[]): void {
      originalContext = this;
      subject.next(args);
    };

    subject.pipe(
      debounceTime(miliseconds))
      .subscribe((args: any[]) => {
        originalMethod.call(originalContext, ...args);
      });

    return descriptor;
  };
}

import { Directive, ElementRef, Input, ContentChild, OnInit, OnChanges, Optional, Host, Inject, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription ,  Subject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { IControlState, ControlStateKey, StateResetTypes } from '../../../core/models/index';
import { ComponentStateStorageService, StateManagementService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';

@Directive({
  selector: '[slxControlActiveState][formControlName],[slxControlActiveState][formControl],[slxControlActiveState][ngModel]'
})
export class ControlActiveStateDirective implements OnInit, OnDestroy {

  @Input('slxControlActiveState')
  public set resetType(type: StateResetTypes) {
    if (!type) {
      return;
    }
    this.m_resetType = type;
  }

  public get controlId(): string {
    return this.m_controlId.toString();
  }

  @unsubscribe()
  private contextChangeSubscription: Subscription;
  @unsubscribe()
  private valueChangesSubscription: Subscription;

  private m_resetType: StateResetTypes;
  private m_controlId: string | number;
  private state: IControlState;
  private stateKey: ControlStateKey;
  private accessor: ControlValueAccessor;

  constructor( @Host() private ngControl: NgControl, private storageService: ComponentStateStorageService, private stateManagement: StateManagementService) {
    this.accessor = ngControl.valueAccessor;
    this.m_resetType = StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
    this.contextChangeSubscription = this.stateManagement.onComponentActiveStateChanged$.subscribe((key: StringMap<any>) => {
      this.switchState(key);
    });
  }

  public ngOnInit(): void {
    this.m_controlId = this.ngControl.name;
    if (!this.m_controlId) {
      throw Error('State directive can be used only with control which has a name');
    }
    this.stateManagement.registerControl(this.controlId);
  }
  public ngOnDestroy(): void {
    this.stateManagement.unregisterControl(this.controlId);
  }

  public valueChanged(val: any): void {
    this.storageService.mapValueToState(val, this.state);
    this.storageService.setControlState(this.stateManagement.componentKey, this.controlId, this.state, this.m_resetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.controlId);
  }

  private loadState(): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
    this.state = this.storageService.getControlState(this.stateManagement.componentKey, this.controlId, this.stateKey);
    if (this.state.value !== undefined) {
      let value: any = this.storageService.mapValueFromState(this.state);
      this.accessor.writeValue(value);
      this.ngControl.viewToModelUpdate(value);
    }
    this.valueChangesSubscription = this.ngControl.valueChanges.subscribe((x: any): any => {
      this.valueChanged(x);
    });
    this.stateManagement.controlRestored(this.controlId);
  }

  private switchState(context: StringMap<any>): void {
    this.stateKey = new ControlStateKey(context);
    this.loadState();
  }
}

import { Provider } from '@angular/core';

export * from './application/application.service';
export * from './breadcrumb/breadcrumb-value-resolver.service';
export * from './left-sidebar/left-sidebar.service';
export * from './screen-availability/screen-availability.service';
export * from './application-state-management/application-state-management.service';
export * from './url-state-management/url-state-management.service';
export * from './applications-dashboard/application-dashboard-management.service';
export * from './applications-dashboard/application-dashboard-api.service';
export * from './applications-dashboard/application-dashboard-map.service';
export * from './side-effects/side-effects.service';
export * from './notifications/notifications-api.service';
export * from './notifications/notifications-map.service';
export * from './org-level-resolver/org-level-resolver.service';

import { ApplicationService } from './application/application.service';
import { BreadcrumbValueResolverService } from './breadcrumb/breadcrumb-value-resolver.service';
import { LeftSidebarManagementService } from './left-sidebar/left-sidebar.service';
import { ScreenAvailabilityService } from './screen-availability/screen-availability.service';
import { ApplicationStateManagementService } from './application-state-management/application-state-management.service';
import { UrlStateManagementService } from './url-state-management/url-state-management.service';
import { ApplicationDashboardManagementService } from './applications-dashboard/application-dashboard-management.service';
import { ApplicationDashboardApiService } from './applications-dashboard/application-dashboard-api.service';
import { ApplicationsDashboardMapService } from './applications-dashboard/application-dashboard-map.service';
import { SideEffectsService } from './side-effects/side-effects.service';
import { NotificationsApiService } from './notifications/notifications-api.service';
import { NotificationsMapService } from './notifications/notifications-map.service';
import { OrgLevelResolver } from './org-level-resolver/org-level-resolver.service';
import { MessageNotificationService } from './notifications/msg-notifications.service';
import { PunchAttestationResolver } from '../../app-modules/punch-attestation/services/punch-attestation.resolver';
import { PunchProfileResolver } from '../../app-modules/punch-profile/services/punch-profile.resolver';

export const portalServices: Provider[] = [
  ApplicationService,
  BreadcrumbValueResolverService,
  LeftSidebarManagementService,
  ScreenAvailabilityService,
  ApplicationStateManagementService,
  UrlStateManagementService,
  ApplicationDashboardManagementService,
  ApplicationDashboardApiService,
  ApplicationsDashboardMapService,
  SideEffectsService,
  NotificationsApiService,
  NotificationsMapService,
  OrgLevelResolver,
  MessageNotificationService,
  PunchAttestationResolver,
  PunchProfileResolver
];

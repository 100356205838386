import { BenefitDetailsLine } from './../../../models/benefit-details/benefit-details-line';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../common/services/index';
import { BenefitDetailsManagementService, BenefitDetailsPermissionService } from '../../../services/index';
import { OrgLevel } from '../../../../../state-model/models';
import { BenefitDetailsEditModes, BenefitDetailsLineStandart, BenefitDetailsProviderLineStandartEntity, BenefitDetailsUserActions } from '../../../models/index';

@Component({
  selector: 'slx-benefit-details-toolbar',
  templateUrl: './benefit-details-toolbar.component.html',
  styleUrls: ['./benefit-details-toolbar.component.scss'],
})
export class BenefitDetailsToolbarComponent implements OnInit, OnDestroy {
  public enabledExpire = false;
  public enabledExtend = false;
  public enabledRenew = false;
  public canExpire = false;
  public canExtend = false;
  public canRenew = false;
  public selectedProviderLine: BenefitDetailsLineStandart;
  public nextLineDetails: BenefitDetailsLine;
  public isEditMode: boolean;
  @Input()
  public benefitDeduction2:boolean = false;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private navService: BenefitsNavigationService;
  private orgLevel: OrgLevel;

  constructor(
    private manService: BenefitDetailsManagementService,
    private permissionService: BenefitDetailsPermissionService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.navService = new BenefitsNavigationService(this.router, this.activatedRoute);
  }

  public ngOnInit(): void {
    this.subscriptions.orgLevel = this.manService
      .subscribeToOrgLevel((v: OrgLevel) => (this.orgLevel = v));

    this.subscriptions.selectProviderLine = this.manService
      .subscribeToSelectProviderLine((pl: BenefitDetailsProviderLineStandartEntity) => {
        this.selectedProviderLine = pl.current;
        this.nextLineDetails = pl.nextLineDetails;
      });

    this.subscriptions.userActions = this.permissionService
      .subscribeToUserActions((v: BenefitDetailsUserActions) => {
        this.enabledExpire = v.enabledExpire;
        this.enabledExtend = v.enabledExtend;
        this.enabledRenew = v.enabledRenew;
        this.canExpire = v.canExpire;
        this.canExtend = v.canExtend;
        this.canRenew = v.canRenew;
      });

    this.subscriptions.edit = this.permissionService.subscribeToEditMode((mode: BenefitDetailsEditModes) => {
      this.isEditMode = mode.benefitInfo || mode.coverageOptions || mode.providerInfo;
    });
  }

  public ngOnDestroy(): void { }

  public get canMakeExpire(): boolean {
    return this.enabledExpire && this.canExpire;
  }
  public get canMakeExtend(): boolean {
    return this.enabledExtend && this.canExtend;
  }
  public get canMakeRenew(): boolean {
    return this.enabledRenew && this.canRenew;
  }

  public get isShowingExpiredPlans(): boolean {
    return this.manService.isLoadingExpiredPlans;
  }

  public set isShowingExpiredPlans(v: boolean) {
    this.manService.isLoadingExpiredPlans = v;
  }

  public onExpirePlan(date: Date): void {
    if (this.canExpire) {
      this.manService.expirePlan(date);
    }
  }

  public onExtendPlan(date: Date): void {
    if (this.canExtend) {
      this.manService.extendPlan(date);
    }
  }

  public onRenewPlan(date: Date): void {
    if (this.canRenew) {
      this.manService.renewPlan(date);
    }
  }

  public onClickOverview(): void {
    this.navService.navigateToConsole(this.orgLevel.id);
  }

}


import { from as observableFrom,  Observable, empty, from } from 'rxjs';
import { mergeMap, sample, catchError, map, filter, mapTo, tap } from 'rxjs/operators';
import { merge, combineLatest, of, zip } from 'rxjs';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import * as _ from 'lodash';
import { ResponseBody } from '../../core/models/index';
import { Meta } from '../../core/models/api/meta';

import { IPayloadAction } from '../../state-model/models/index';
import { SessionActions } from '../../authentication/actions/index';
import { MasterScheduleActions } from '../store/master-schedule/master-schedule.actions';
import { LookupApiService, ScheduleCycleHelperService } from '../../organization/services/index';
import { ScheduleApiService, MasterScheduleManagementService } from '../services/index';
import { IChangedScheduleEntry, IMasterScheduleFilters } from '../store/master-schedule/master-schedule.types';
import { EmployeeScheduleDefinition, ScheduleCycle, ScheduleEntryDefinition, EmployeeScheduleDefinitionContainer } from '../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../state-model/models/index';
import { Employee, ScheduleTotalSummary, ScheduleEntry } from '../models/index';
import { mutableSelect } from '../../core/decorators/index';

const DEBUG = false;

@Injectable()
export class MasterScheduleEpics {
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['masterSchedule', 'filters'])
  public filters$: Observable<IMasterScheduleFilters>;

  constructor(
    private scheduleApiService: ScheduleApiService, private lookupApiService: LookupApiService, private scheduleCycleHelperService: ScheduleCycleHelperService) {
  }

  public entriesChange = action$ => {
    const entriesChange$ = action$.pipe(
      filter(({ type }: IPayloadAction) => type === MasterScheduleActions.SCHEDULE_ENTRIES_CHANGE));
    return entriesChange$.pipe(
      mergeMap((action: IPayloadAction) => {
        let om: Promise<EmployeeScheduleDefinition>[] = [];
        _.forEach(action.payload.entries, (entry: IChangedScheduleEntry) => {
          om.push(this.updateMasterSchedule(action.payload.orgLevelId, entry));
        });
        return observableFrom(Promise.all(om)).pipe(map((result: EmployeeScheduleDefinition[]) => ({
          type: MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED,
          payload: result
        })),
          catchError((error: any) => of({ type: MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED_ERROR, payload: { hasError: true, errorMessage: error.message } })));
      }));
  }

  public updateMasterSchedule(orgLevelId: number, entry: IChangedScheduleEntry): Promise<EmployeeScheduleDefinition> {
    let p: Promise<EmployeeScheduleDefinition> = this.scheduleCycleHelperService.getScheduleCycleByDate(entry.date, orgLevelId)
      .then((value: ScheduleCycle) => {
        if (!value) {
          return null;
        }
        return this.scheduleApiService.getEmployeeSchedule(orgLevelId, entry.employeeId, value.startDate.toDate(), value.endDate.toDate())
          .then((val: EmployeeScheduleDefinition) => {
            return val;
          });
      });
    return p;
  }

  /*------------------------ FETCH MASTER SCHEDULE DATA -----------------------------*/
  public fetchMasterScheduleData = action$ => action$.pipe(
    this.combinedMasterScheduleFilters$,
    /* mergeMap allows for multiple inner subscriptions to be active at a time */
    mergeMap(([action, orgLevel, filters]: [IPayloadAction, OrgLevel, IMasterScheduleFilters]) => this.processMasterSchedules(orgLevel, filters)));  

  /* combineLatest will not emit an initial value until each observable emits at least one value. */
  private combinedMasterScheduleFilters$ = action$ => combineLatest([this.filteredMasterScheduleData$(action$), 
    this.filteredOrgLevel$(), 
    this.filteredSchedule$()]);

  /* filter for relevant payload actions */
  private filteredMasterScheduleData$ = action$ => action$.pipe(
    filter(({ type }: IPayloadAction) => type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA),
    tap(() => { if (DEBUG) console.log('    [filteredMasterScheduleData$ will emit...]') }));    

  /* generates the inner observable that calculates the master schedule data payload action */
  private processMasterSchedules(orgLevel: OrgLevel, filters: IMasterScheduleFilters): Observable<IPayloadAction> {
    return from(
        Promise.all([
            this.scheduleApiService.getEmployeesSchedule(orgLevel, filters),
            this.scheduleApiService.getTotals(orgLevel, filters)]
        )).pipe(
        map(([container, totals]: [EmployeeScheduleDefinitionContainer, ScheduleTotalSummary]) => ({
            type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS,
            payload: {
                actions: container.actions,
                employees: container.definitions,
                totals
            }
        })),
        catchError((error: any) => of({ type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR, payload: { hasError: true, errorMessage: error.message } })));     
  }        

  public fetchEmployeesSchedule = action$ => {
    const fetchEmployeesSchedule$: Observable<IPayloadAction> = action$
      .pipe(filter(({ type }: IPayloadAction) => type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE));
  
    return combineLatest([fetchEmployeesSchedule$, this.orgLevel$.pipe(filter((o: OrgLevel) => o.type === OrgLevelType.department)), this.filters$]).pipe(
      sample(fetchEmployeesSchedule$),
      mergeMap(([action, orgLevel, filters]: [IPayloadAction, OrgLevel, IMasterScheduleFilters]) => {
  
        console.log('FETCHEMPLOYEESSCHEDULE$ OBSERVABLE MAP MERGE');
        
        return from(this.scheduleApiService.getEmployeesSchedule(orgLevel, filters)).pipe(
          map((result: EmployeeScheduleDefinitionContainer) => ({
            type: MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS,
            payload: {
              actions: result.actions,
              employees: result.definitions
            }
          })),
          catchError((error: any) => of({ 
            type: MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_ERROR, 
            payload: { hasError: true, errorMessage: error.message } 
          })));
      }));
  }

  /*------------------------ FETCH TOTALS --------------------------------*/
  public fetchTotals = action$ => action$.pipe(
    this.combinedFilters$,
    mergeMap(([action, orgLevel, filters]: [IPayloadAction, OrgLevel, IMasterScheduleFilters]) => this.processSchedules(action, orgLevel, filters)));

  /* combineLatest will not emit an initial value until each observable emits at least one value. */
  private combinedFilters$ = action$ => combineLatest([this.filteredTotals$(action$), 
                                                      this.filteredOrgLevel$(), 
                                                      this.filteredSchedule$()]);

  /* filter for relevant payload actions */
  private filteredTotals$ = action$ => action$.pipe(
      filter(({ type }: IPayloadAction) => type === MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED ||
                                          type === MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS ||
                                          type === MasterScheduleActions.FETCH_TOTALS),
      tap(() => { if (DEBUG) console.log('    [filteredTotals$ will emit...]') }));

  /* filter for relevant organization level */
  private filteredOrgLevel$ = () => this.orgLevel$.pipe(
      filter((val: OrgLevel) => val.type === OrgLevelType.department),
      tap(() => { if (DEBUG) console.log('    [filteredOrgLevel$ will emit...]') })); 

  /* mostly for debugging purposes, we could have used this.filters$ directly */
  private filteredSchedule$ = () => this.filters$.pipe(
      tap(() => { if (DEBUG) console.log('    [filteredSchedule$ will emit...]') }));

  /* generates the inner observable that calculates the totals payload action */
  private processSchedules(action: IPayloadAction, orgLevel: OrgLevel, filters: IMasterScheduleFilters): Observable<IPayloadAction> {
      this.processFilters(action, filters);  

      return from(this.scheduleApiService.getTotals(orgLevel, filters)).pipe(
      map((result: ScheduleTotalSummary) => ({
          type: MasterScheduleActions.FETCH_TOTALS_SUCCESS,
          payload: result
      })),
      catchError((error: any) => of({ type: MasterScheduleActions.FETCH_TOTALS_ERROR, payload: { hasError: true, errorMessage: error.message } })));        
  }

  /* recalculate filter dates based on payload action */
  private processFilters(action: IPayloadAction, filters: IMasterScheduleFilters) {
      if (action.type === MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED) {
          let rows: EmployeeScheduleDefinition[] = action.payload;
          let dateFirst: moment.Moment = null;
          let dateEnd: moment.Moment = null;

          _.forEach(rows, (row: EmployeeScheduleDefinition) => {
              _.forEach(row.entries, (entry: ScheduleEntryDefinition) => {
              if (!dateFirst || dateFirst.isAfter(entry.dateOn)) {
                  dateFirst = moment(entry.dateOn);
              }
              if (!dateEnd || dateEnd.isBefore(entry.dateOn)) {
                  dateEnd = moment(entry.dateOn);
              }
              });
          });

          filters.dateFrom = dateFirst.toDate();
          filters.weekNumber = this.scheduleCycleHelperService.calcWeeks(dateFirst, dateEnd);
      }
  }  

  public resetPayCycle = action$ => action$.pipe(
    filter(({ type }: { type: string }) => type === SessionActions.CLEAR_SESSION),
    mergeMap((action: IPayloadAction) => {
      return of(
        {
          type: MasterScheduleActions.MASTER_SCHEDULE_CLEAR_PAYCYCLE,
          payload: null
        });
    }));
   
    public completeDataLoading = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
      const fetchMasterScheduleData$: Observable<IPayloadAction> = action$.pipe(
        filter(({ type }: IPayloadAction) =>
          type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS ||
          type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR));
      const fetchEmployeesSchedule$: Observable<IPayloadAction> = action$.pipe(
        filter(({ type }: IPayloadAction) =>
          type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS ||
          type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_ERROR));
      const generateSchedule$: Observable<IPayloadAction> = action$.pipe(
        filter(({ type }: IPayloadAction) =>
          type === MasterScheduleActions.GENERATE_SCHEDULE_ERROR ||
          type === MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS));
      const deleteEmpSchedule$: Observable<IPayloadAction> = action$.pipe(
        filter(({ type }: IPayloadAction) =>
          type === MasterScheduleActions.DELETE_EMP_SCHEDULE_ERROR ||
          type === MasterScheduleActions.DELETE_EMP_SCHEDULE_SUCCESS));
      const createEmpRotFromSchedule$: Observable<IPayloadAction> = action$.pipe(
        filter(({ type }: IPayloadAction) =>
          type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_ERROR ||
          type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_SUCCESS));

      return merge(
        fetchEmployeesSchedule$, 
        fetchMasterScheduleData$, 
        deleteEmpSchedule$, 
        createEmpRotFromSchedule$, 
        generateSchedule$).pipe(map(() =>  ({
            type: MasterScheduleActions.COMPLETE_DATA_LOADING
          }) 
        ));
    }

  public completeLoading = action$ => action$.pipe(
    this.setCombinedFilters$, 
    mapTo({ type: MasterScheduleActions.COMPLETE_LOADING }));

  private setCombinedFilters$ = action$ => merge(this.setIndependentFilter$(action$), this.setDependencyFilter$(action$));

  private setIndependentFilter$ = action$ => action$.pipe(
    filter((val: IPayloadAction) =>
      val.type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS ||
      val.type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR || 
      val.type === MasterScheduleActions.GENERATE_SCHEDULE_ERROR ||
      val.type === MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS ||
      val.type === MasterScheduleActions.DELETE_EMP_SCHEDULE_ERROR ||
      val.type === MasterScheduleActions.DELETE_EMP_SCHEDULE_SUCCESS ||
      val.type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_ERROR ||
      val.type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_SUCCESS),
      tap(() => { if (DEBUG) console.log('    [setIndependentFilter$] wil emit...') }),
      mapTo({ type: 'INDEPENDENT_ACTIONS' }));

  private setDependencyFilter$ = action$ => zip(
    action$.pipe(filter((val: IPayloadAction) =>
      val.type === MasterScheduleActions.FETCH_TOTALS_SUCCESS ||
      val.type === MasterScheduleActions.FETCH_TOTALS_ERROR)),
    action$.pipe(filter((val: IPayloadAction) =>
      val.type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS ||
      val.type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_ERROR))).pipe(
        tap(() => { if (DEBUG) console.log('    [setDependencyFilter$ will emit...]') }),
        mapTo({ type: 'TOTALS_AND_EMPLOYEES' }));   
}

<slx-spinner [show]="Loadstate.isLoading">
  <kendo-grid #grid class="slx-full-height slx-blue-grid slx-grid-slim-rows shift-grid"
    [data]="gridView"
    [filter]="state.filter"
    filterable="menu"
    (dataStateChange)="dataStateChange($event)"
    [navigable]="true"
    (selectionChange)="gridSelectedShift($event)"
    [selectable]="{  mode: 'multiple' }"
    [reorderable]="true"
    kendoGridSelectBy="displayId"
    [(selectedKeys)]="selectedDisplayId" >     
    
    <kendo-grid-checkbox-column  [headerClass]="{ 'text-center': true }"  
                                 [headerStyle]="{ 'background-color': 'white', color: 'black', 'line-height': '1em' }" 
                                 width="50px" 
                                 [resizable]="false" 
                                 [columnMenu]="false"
                                 [showSelectAll]="true">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <input type="checkbox" class="td-checkbox" [kendoGridSelectionCheckbox]="rowIndex" [disabled]="isDisabledShift()" >
      </ng-template>
    </kendo-grid-checkbox-column>
    <kendo-grid-column  [headerStyle]="{ 'background-color': 'white', color: 'black', 'line-height': '1em' }"   field="ShiftName" title="Shift" width="150px"  >
      <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.ShiftName }}
          <div *ngIf="dataItem.ShiftStartDateErrorMessage" class="positionError">The Shift is in the past.</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column  [headerStyle]="{ 'background-color': 'white', color: 'black', 'line-height': '1em' }"field="Hours" title="Ideal Hours" filter="numeric" width="150px" > 
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.Hours | number : '1.2-2'}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-span-column>
    <kendo-grid-column 
      field="JobDescription" [headerStyle]="{ 'background-color': 'white', color: 'black', 'line-height': '1em' }"  width="150px"
      title="Position"
      width="200px">
    </kendo-grid-column>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.JobDescription }}
        <div *ngIf="!dataItem.PositionErrorMessage" class="positionError">This position is not mapped</div>
      </ng-template>
    </kendo-grid-span-column>
    <kendo-grid-column
      field="UnitName" [headerStyle]="{ 'background-color': 'white', color: 'black', 'line-height': '1em' }"
      title="Units"
      width="100px"   >
    </kendo-grid-column>
    <kendo-grid-messages filterMenuTitle="Filter">
    </kendo-grid-messages>  
    </kendo-grid>
</slx-spinner>
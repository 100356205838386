import { TimeclockAssignmentRestriction } from '../../../../employee/employee-sections/models/index';
import { Component, OnDestroy, Provider, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { LookupApiService } from '../../../../organization/services/index';
import { TimeclockDefinition, TimeclockRestrictionDefinition, EmployeeDefinition } from '../../../../organization/models/index';
import {
  TimeclockAssignmentState, TimeclockRestrictionTotal, TimeclockAssignmentEmployee, TimeclockAssignment, TimeclockRestriction, AssignMode, assignMode
} from '../../../models/index';

export type timeclockDefinitionWrapper = { isSelected: boolean, timeclock: TimeclockDefinition, isAssigned?: boolean };
export interface TimeclocksDefinitionByEmployees {
  [index: string]: TimeclockDefinition[];
}

@Component({
  moduleId: module.id,
  selector: 'slx-timeclock-assignment-dialog',
  templateUrl: 'timeclock-assignment-dialog.component.html',
  styleUrls: ['timeclock-assignment-dialog.component.scss']
})
export class TimeclockAssignmentDialogComponent implements OnInit, OnDestroy {
  public options: DialogOptions;
  public dialogResult: boolean;
  public mode: AssignMode;
  public selectedRestriction: TimeclockRestrictionDefinition;
  public definitions: timeclockDefinitionWrapper[];
  public restrictions: TimeclockRestrictionDefinition[];
  public definitionsByEmployees: TimeclocksDefinitionByEmployees;
  public noChangeRestriction: TimeclockRestrictionDefinition;
  public isAllSelected: boolean;
  public gridState: KendoGridStateHelper<timeclockDefinitionWrapper>;
  public selectedUnassignedTimclocks = false;

  public get hasRestrictionError(): boolean {
    const restrictionId = _.get(this.selectedRestriction, 'id', -1);
    return restrictionId === -1 && this.selectedUnassignedTimclocks && this.isReAssignedMode();
  }

  public get selectedDefinitions(): TimeclockDefinition[] {
    if (this.isChangeRestriction()) {
      return _.map(this.definitions, (def: timeclockDefinitionWrapper) => def.timeclock);
    }

    return _.reduce(this.definitions, (collector: TimeclockDefinition[], def: timeclockDefinitionWrapper): TimeclockDefinition[] => {
      if (def.isSelected) {
        collector.push(def.timeclock);
      }

      return collector;
    }, []);
  }

  public get isRestrictionSelected(): boolean {
    const restrictionId: number = _.get(this.selectedRestriction, 'id', null);

    return _.isNumber(restrictionId);
  }

  public get isDefinitionsSelected(): boolean {
    return _.size(this.selectedDefinitions) > 0;
  }

  private selectedRecords: TimeclockAssignmentEmployee[];
  private state: TimeclockAssignmentState;
  private selectedTimeclocks: TimeclockDefinition[];
  private lookupApiService: LookupApiService;
  private modalService: ModalService;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  public static openDialog(
    header: string,
    req: TimeclockAssignmentState,
    mode: AssignMode,
    modalService: ModalService,
    callback: (result: boolean, assignment: TimeclockAssignment[]) => void
  ): TimeclockAssignmentDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 950;
    dialogOptions.height = 550;
    dialogOptions.showCloseButton = true;
    if (mode === AssignMode.changeRestriction) {
      dialogOptions.height = 160;
    }
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: TimeclockAssignmentState,
        useValue: req
      },
      {
        provide: AssignMode,
        useValue: mode
      }
    ];
    let dialog: TimeclockAssignmentDialogComponent = modalService.globalAnchor
      .openDialog(TimeclockAssignmentDialogComponent, header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        let assignments: TimeclockAssignment[] = dialog.makeTimeclocksAssignment();
        callback(result, assignments);
      });

    return dialog;
  }

  constructor(
    options: DialogOptions,
    modalService: ModalService,
    lookupApiService: LookupApiService,
    state: TimeclockAssignmentState,
    mode: AssignMode
  ) {
    this.mode = mode;
    this.options = options;
    this.modalService = modalService;
    this.lookupApiService = lookupApiService;
    this.state = state;

    this.gridState = new KendoGridStateHelper<timeclockDefinitionWrapper>();
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.selectedRecords = [];
    this.selectedTimeclocks = [];
    this.definitions = _.map(this.state.timeclocks, (def: TimeclockDefinition) => {
      return { isSelected: false, timeclock: def };
    });
    this.restrictions = _.map(this.state.totals, (total: TimeclockRestrictionTotal) => {
      return total.restriction;
    });

    this.dialogResult = false;
    this.noChangeRestriction = new TimeclockRestrictionDefinition();
    this.noChangeRestriction.id = -1;
    this.noChangeRestriction.name = 'No change';
    if (this.isChangeRestriction()) {
      this.selectedRestriction = this.restrictions[0];
      this.filterRecordBySelectedEmployees();
    } else {
      if (this.isAssignMode()) {
        this.selectedRestriction = this.restrictions[0];
      } else {
        this.filterForUnassignement();
        this.selectedRestriction = this.noChangeRestriction;
      }
    }
  }

  public ngOnInit(): void {
    this.refreshGrid();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public isAssignMode(): boolean {
    return this.mode === AssignMode.assign;
  }

  public isReAssignedMode(): boolean {
    return this.mode === AssignMode.reassign;
  }

  public isChangeRestriction(): boolean {
    return this.mode === AssignMode.changeRestriction;
  }

  public isUnassignMode(): boolean {
    return this.mode === AssignMode.unassign;
  }

  public isDisabled(): boolean {
    let result: boolean;
    switch (true) {
      case this.isAssignMode():
        result = !this.isRestrictionSelected || !this.isDefinitionsSelected;
        break;
      case this.isReAssignedMode():
        result = !this.isRestrictionSelected || !this.isDefinitionsSelected || this.hasRestrictionError;
        break;
      case this.isChangeRestriction():
        result = !this.isRestrictionSelected;
        break;
      case this.isUnassignMode():
        result = !this.isDefinitionsSelected;
        break;
    }
    return result;
  }

  public onSelectionChanged(isAllSelected: boolean, item: timeclockDefinitionWrapper): void {
    this.selectedUnassignedTimclocks = false;
    this.selectedTimeclocks = _.reduce(this.definitions, (collector: TimeclockDefinition[], entry: timeclockDefinitionWrapper) => {
      if (isAllSelected) {
        entry.isSelected = this.isAllSelected;
        collector.push(entry.timeclock);
        if (!entry.isAssigned) {
          this.selectedUnassignedTimclocks = true;
        }
      } else if (entry.isSelected) {
        collector.push(entry.timeclock);
        if (!entry.isAssigned) {
          this.selectedUnassignedTimclocks = true;
        }
      }

      return collector;
    }, []);
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public makeTimeclocksAssignment(): TimeclockAssignment[] {
    if (this.isChangeRestriction()) {
      return this.groupByPersonalTimeclocks();
    }

    return this.groupBySelectedTimeclocks();
  }

  private groupBySelectedTimeclocks(): TimeclockAssignment[] {
    const timeclockRestrictions: TimeclockRestriction[] = this.makeTimeclockRestriction(this.selectedTimeclocks, this.selectedRestriction);

    return _.map(this.state.selectedEmployees, (employee: EmployeeDefinition) => this.makeTimeclockAssignment(employee, timeclockRestrictions));
  }

  private groupByPersonalTimeclocks(): TimeclockAssignment[] {
    return _.reduce(this.selectedRecords, (collector: TimeclockAssignment[], record: TimeclockAssignmentEmployee) => {
      const timeclockRestrictions: TimeclockRestriction[] = this.makeTimeclockRestriction(record.assignments, this.selectedRestriction);
      collector.push(this.makeTimeclockAssignment(record.employee, timeclockRestrictions));

      return collector;
    }, []);
  }

  private makeTimeclockAssignment(employee: EmployeeDefinition, timeclockRestrictions: TimeclockRestriction[]): TimeclockAssignment {
    let timeclock: TimeclockAssignment = new TimeclockAssignment();
    timeclock.employee = employee;
    timeclock.timeclockRestrictions = timeclockRestrictions;

    return timeclock;
  }

  private makeTimeclockRestriction(entries: Array<TimeclockDefinition | TimeclockAssignmentRestriction>, restriction: TimeclockRestrictionDefinition): TimeclockRestriction[] {
    return _.map(entries, (entry: TimeclockDefinition | TimeclockAssignmentRestriction) => {
      const record: TimeclockRestriction = new TimeclockRestriction();
      record.timeclock = entry instanceof TimeclockDefinition ? entry : (entry as TimeclockAssignmentRestriction).timeclock;
      record.restriction = restriction;

      return record;
    });
  }

  private filterRecordBySelectedEmployees(): void {
    const selectedEmployees: EmployeeDefinition[] = this.state.selectedEmployees;
    _.forEach(this.state.records, (record: TimeclockAssignmentEmployee) => {
      const index: number = _.findIndex(selectedEmployees, (emp: EmployeeDefinition) => emp.id === record.employee.id);
      if (index !== -1) {
        this.selectedRecords.push(record);
      }
    });
  }

  private filterForUnassignement(): void {
    const timeclockIds: number[] = [];
    const selectedEmployees: EmployeeDefinition[] = this.state.selectedEmployees;
    _.forEach(this.state.records, (record: TimeclockAssignmentEmployee) => {
      const index: number = _.findIndex(selectedEmployees, (emp: EmployeeDefinition) => emp.id === record.employee.id);
      if (index !== -1) {
        _.forEach(record.assignments, (assignment: TimeclockAssignmentRestriction) => {
          timeclockIds.push(assignment.timeclock.id);
        });
      }
    });

    _.forEach(this.definitions, (def: timeclockDefinitionWrapper) => {
      def.isAssigned = !!_.find(timeclockIds, (timeclockId: number) => timeclockId === def.timeclock.id);
    });
    this.refreshGrid();
  }

  private refreshGrid(): void {
    if (!this.definitions) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.definitions, this.gridState.state);
  }
}

import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/index';
import { WindowRef } from '../../../../core/services/window/window-ref.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-elite-dashboard',
  templateUrl: 'elite-dashboard-create.component.html',
  styleUrls: ['elite-dashboard-create.component.scss'],
})
export class CreateEliteDashboardComponent implements OnInit{

  public isIFrameLoading: boolean = true;
  public isDataLoading: boolean = false;
  public isActive: boolean = true;
  public eliteUrl: string = 'https://dev-logi-10x.smartlinxsolutions.com/powerbidesignerlatest/home/CreateReport';
  safeURL: SafeHtml;

  @unsubscribe()
  private loadStateChangedSubscription: Subscription;

  @unsubscribe()
  private logiLinkChangedSubscription: Subscription;

  @unsubscribe()
  private logiLoadedSubscription: Subscription;

  @unsubscribe()
  private logiActionSubscription: Subscription;

  constructor(
    private router: Router,
    private winRef: WindowRef,
    private domSanitizer: DomSanitizer
  ) {
  }
  ngOnInit() {    
    this.safeURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.eliteUrl);
    this.isIFrameLoading = false;
  }
}

import { Provider } from '@angular/core';

export * from './payroll-export-api.service';
export * from './payroll-export-map.service';
export * from './payroll-export-management.service';

import { PayrollExportApiService } from './payroll-export-api.service';
import { PayrollExportMapService } from './payroll-export-map.service';
import { PayrollExportSignalrService } from './payroll-export-signalr.service';

export const payrollExportServices: Provider[] = [
  PayrollExportApiService,
  PayrollExportMapService,
  PayrollExportSignalrService
];

import { IdealScheduleOverviewRequest } from './../../../models/ideal-schedule/ideal-schedule-overview-request';
import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { unsubscribe } from '../../../../core/decorators/index';
import { TOOLBAR_SERVICE } from '../../../../core/services/index';
import { IdealSchedulePositionDefinition, IdealScheduleList, IdealSchedulePositionHistoryRecord, IdealSchedulePosition, IdealSchedulePositionPeriod } from '../../../models/index';
import { IdealScheduleStateService, IdealScheduleToolbarService } from '../../../services/index';
import { IPosition } from '../../../../organization/index';
import { ModalService, StartEndDates, StartEndDatesDialogComponent, ConfirmDialogComponent, ConfirmChangesDialogComponent, DialogOptions } from '../../../../common/index';
import { IdealScheduleOverviewDialogComponent } from '../ideal-schedule-overview-dialog/ideal-schedule-overview-dialog.component';
import { IdealScheduleComponent } from '../ideal-schedule.component';
import { IdealScheduleImportTemplate, ImportTemplateDto, ImportedPositionsDto } from './../../../../configuration/models/ideal-schedule/ideal-schedule-import-template';
import { IdealScheduleImportTemplateDialogComponent } from '../ideal-schedule-import-template-dialog/ideal-schedule-import-template-dialog.component';
import { AppSettingsManageService } from './../../../../../app/app-settings/services';
import { AppServerConfig } from './../../../../../app/app-settings/model/app-server-config';

@Component({
  moduleId: module.id,
  selector: 'slx-ideal-schedule-toolbar',
  templateUrl: 'ideal-schedule-toolbar.component.html',
  styleUrls: ['ideal-schedule-toolbar.component.scss']
})
export class IdealScheduleToolbarComponent implements OnInit, OnDestroy {

  public positions: IPosition[];
  isDisabled: boolean = false;

  public get selectedPosition(): IPosition {
    return this.m_selectedPosition;
  }

  public set selectedPosition(value: IPosition) {
    this.m_selectedPosition = value;
  }

  private m_selectedPosition: IPosition;
  private positionDefinitions: IdealSchedulePositionDefinition[];
  @Input() captureIdealScheduleScreen: (args: any) => void;
  @Input() exportToExcel: (args: any) => void;
  @Input() checkData: () => void;

  @ViewChild('popupRef') popupRefChild: IdealScheduleComponent;


  @unsubscribe()
  private positionDefinitionsSubscription: Subscription;

  public isDownloadImportOptionEnabled: boolean = false;

  constructor(
    @Inject(TOOLBAR_SERVICE) private idealScheduleToolbarService: IdealScheduleToolbarService,
    public stateService: IdealScheduleStateService,
    private changeDetector: ChangeDetectorRef,
    private modalService: ModalService,
    private appSettingsManageService: AppSettingsManageService
  ) {
    this.positions = [];
    this.positionDefinitions = [];
  }

  public ngOnInit(): void {
    this.positionDefinitionsSubscription = this.stateService.onIdealScheduleList$.subscribe(
      (list: IdealScheduleList) => {
        if (!list) {
          return;
        }

        this.positionDefinitions = list.positions;
        this.positions = _.map(list.positions, positionDefinition => positionDefinition.position);

        const firstPosition = _.first(this.positionDefinitions);

        if (this.stateService.selectedPositionDefinition) {
          this.selectedPosition = this.stateService.selectedPositionDefinition.position;
        } else if (firstPosition) {
          this.selectedPosition = firstPosition.position;
          this.stateService.setSelectedPositionDefinition(firstPosition);
        }
        if (this.stateService.selectedPosition != undefined && this.stateService.selectedPosition.ranges && this.stateService.selectedPosition.ranges[0] == null) this.isDisabled = true;
      });

    this.appSettingsManageService.getAppServerConfig().then((config: AppServerConfig) => {
      this.isDownloadImportOptionEnabled = config.isDownloadImportOptionEnabled;
    });

  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onPositionChanged(position: IPosition): void {
    if (this.selectedPosition.id !== position.id && this.stateService.selectedPosition && this.stateService.selectedPosition.hasChanges) {
      ConfirmChangesDialogComponent.openDialog('Warning', this.modalService, (result: boolean) => {
        if (result) {
          this.changePositionInState(position);
        } else {
          this.selectedPosition = _.clone(this.selectedPosition);
        }
      });
    } else {
      this.changePositionInState(position);
    }
    this.stateService.GetIdealShiftValidationData();
  }

  public changePositionInState(position: IPosition): void {
    this.selectedPosition = position;
    const positionDefinition = _.find(this.positionDefinitions, positionDefinition =>
      positionDefinition.position.id === position.id
    );
    if(this.stateService.selectedPositionDefinition && 
      this.stateService.selectedPositionDefinition.position.id == positionDefinition.position.id) return;
    this.stateService.setSelectedPositionDefinition(positionDefinition);
  }

  public onPeriodChanged(period: IdealSchedulePositionPeriod): void {
    if (this.stateService.selectedPositionDefinitionPeriod.name !== period.name) {
      this.stateService.selectedPositionPeriodChanged(period);
    }
  }

  public onSave(): void {
    let position: IdealSchedulePosition = this.stateService.selectedPosition;
    let initDates: StartEndDates = {
      startDate: moment(position.startDate),
      endDate: moment(position.endDate)
    };

    StartEndDatesDialogComponent.openDialog(
      initDates,
      this.modalService, (result: boolean, resultDates: StartEndDates) => {
        if (result) {
          this.stateService.savePosition(position, resultDates.startDate.toDate(), resultDates.endDate.toDate());
        }
      });
  }

  public onDiscard(): void {
    ConfirmDialogComponent.openDialog(
      'Discard changes',
      'There is unsaved data on this screen. If you proceed, the unsaved data will be lost. Are you sure you wish to proceed?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.stateService.discardChanges();
        }
      });
  }

  public onOverviewClick(): void {
    const request: IdealScheduleOverviewRequest = new IdealScheduleOverviewRequest();
    request.overviewDate = this.stateService.selectedPositionDefinitionPeriod.endDate;
    IdealScheduleOverviewDialogComponent.openDialog(this.modalService, (result: boolean) => {
      _.noop();
    }, request);
  }

  public getActionCaption() {
    return this.isDisabled == true ? "Data cannot be exported when there are no records" : "";
  }


  public openDownloadImportTemplateModal(isDownloadTemplate: boolean): void {
    // ImportTemplateRequest data should be load after api works done
    // Import Template Dialog and Download Import Template Dialog both are used by one component called IdealScheduleImportTemplateDialogComponent
    this.stateService.state.isLoading = true;
    const importTemplateRequest: IdealScheduleImportTemplate = new IdealScheduleImportTemplate();
    importTemplateRequest.header = isDownloadTemplate ? 'Ideal Schedule Import' : 'Import Ideal Schedule';
    importTemplateRequest.buttonName = isDownloadTemplate ? 'Download Template' : 'Apply Imported Ideal Schedule';
    importTemplateRequest.organizationList = this.stateService.organizationDetails;
    importTemplateRequest.positionList = this.stateService.currentRangeConfigPositions;
    importTemplateRequest.isDownloadTemplate = isDownloadTemplate;
    importTemplateRequest.exceptionData = [];
    importTemplateRequest.isDefaultShiftGroupEnabled = this.stateService.isDefaultShiftGroupFlagEnabled;
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 800;
    dialogOptions.height = 500;
    dialogOptions.showCloseButton = true;
    dialogOptions.className = 'slx-import-template-custom';
    setTimeout(() => {
      this.stateService.state.isLoading = false;
      IdealScheduleImportTemplateDialogComponent.openDialog(importTemplateRequest, dialogOptions, this.modalService, (result: boolean, data: any) => console.log(result));
    }, 1000);
  }


}

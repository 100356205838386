<div class="top">
  <button type="button" class="button filters flex-button" [popover]="multiselectPopover">
    <span class="flex-button-header">
      <span class="caption">{{caption}}</span>
      <i class="fa fa-angle-down icon" aria-hidden="true"></i>
    </span>
    <div class="sub-header">Selected: {{selectedCount}}</div>
  </button>

  <div class="popover-content-arrow">
    <popover-content #multiselectPopover class="filters" disableClickOutside="true" placement="bottom">
      <ul class="multi-select-filter" #multiselectFilter>
        <li *ngFor="let item of lookup">
          <slx-checkbox-input slx-input
                              [caption]="item.name" [(ngModel)]="item.isSelected"
                              (ngModelChange)="onChange($event, item)">
          </slx-checkbox-input>
        </li>
      </ul>
    </popover-content>
  </div>
</div>


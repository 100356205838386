<slx-spinner class="dua-spinner" [show]="isLoading$ | async">
    <div class="slx-high-box">
      <div class="row slx-high-box__body slx-main-content-indents res">
        <div class="display-toggle">
          <button type="submit" class="theme-button-apply btn-sync"  [disabled]="isSyncDisabled$ | async" (click)="syncData()">Sync Now</button>
          <button
              type="submit"
              class="theme-button-apply btn-syncrefresh"
              (click)="onRefreshClicked()">Refresh
            </button>
        </div>
      <!-- </div> -->
        <div class="row">
          <kendo-grid
            #grid="kendoGrid"
            [kendoGridBinding]="viewModel$ | async"
            [pageable]="true"
            filterable="menu"
            [sortable]="{ mode: 'multiple' }"
            [pageSize]="pageSize"
            [filter]="filter$ | async"
            (filterChange)="onFilterChanged($event)"
            class="grid slx-full-height slx-blue-grid"
          >
            <kendo-grid-checkbox-column [width]="35">
              <ng-template kendoGridHeaderTemplate>
                <input
                  type="checkbox"
                  [checked]="isCheckedAll$ | async"
                  [disabled]="isCheckAllDisabled$ | async" (change)="toggleCheckAll($event)"
                />
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <input
                  type="checkbox"
                  [checked]="dataItem.ischecked"
                  (change)="onCheckboxChange($event, dataItem)"
                  [disabled]="dataItem.isDisabled"
                />
              </ng-template>
            </kendo-grid-checkbox-column>

            <kendo-grid-column field="orgLevelId" title="Orglevel ID" width="130"></kendo-grid-column>
            <kendo-grid-column *ngIf="subType === 'position'" field="orgLevelName" title="Position" width="170"></kendo-grid-column>
            <kendo-grid-column *ngIf="subType === 'position'" field="jobCode" title="Job Code" width="160"></kendo-grid-column>
            <kendo-grid-column *ngIf="subType !== 'position'" field="orgLevelName" title="OrgLevel Name" width="170">
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
              >
                <slx-multicheck-filter
                  [isPrimitive]="true"
                  [field]="column.field"
                  [filterService]="filterService"
                  [currentFilter]="filter"
                  [data]="distinctPrimitive(column.field, false)"
                ></slx-multicheck-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Org Type" field="orgLevelType" width="110"> </kendo-grid-column>
            <kendo-grid-column title="Action" field="action" width="90">
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
              >
                <slx-multicheck-filter
                  [isPrimitive]="true"
                  [field]="column.field"
                  [filterService]="filterService"
                  [currentFilter]="filter"
                  [data]="distinctPrimitive(column.field, false)"
                ></slx-multicheck-filter>
              </ng-template>
             </kendo-grid-column>
            <kendo-grid-column title="Organization" field="orgName" width="130"> </kendo-grid-column>
            <kendo-grid-column title="Company ID" field="companyId" width="130">
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
              >
                <slx-multicheck-filter
                  [isPrimitive]="true"
                  [field]="column.field"
                  [filterService]="filterService"
                  [currentFilter]="filter"
                  [data]="distinctPrimitive(column.field, true)"
                ></slx-multicheck-filter>
              </ng-template>
             </kendo-grid-column>
            <kendo-grid-column title="Company Code" field="companyCode" width="150">
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
              >
                <slx-multicheck-filter
                  [isPrimitive]="true"
                  [field]="column.field"
                  [filterService]="filterService"
                  [currentFilter]="filter"
                  [data]="distinctPrimitive(column.field, false)"
                ></slx-multicheck-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Paygroup" field="paygroup" width="170">
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
              >
                <slx-multicheck-filter
                  [isPrimitive]="true"
                  [field]="column.field"
                  [filterService]="filterService"
                  [currentFilter]="filter"
                  [data]="distinctPrimitive(column.field, false)"
                ></slx-multicheck-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Short Code" field="shortCode" width="150">
            </kendo-grid-column>
            <kendo-grid-column title="Status" field="status" width="110">
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
              >
                <slx-multicheck-filter
                  [isPrimitive]="true"
                  [field]="column.field"
                  [filterService]="filterService"
                  [currentFilter]="filter"
                  [data]="distinctPrimitive(column.field, false)"
                ></slx-multicheck-filter>
              </ng-template>
             </kendo-grid-column>
            <kendo-grid-column title="Failed At" field="failedAt" width="170" > </kendo-grid-column>
            <kendo-grid-column title="Message" field="errorMessage" width="170">
            </kendo-grid-column>
            <ng-template kendoGridNoRecordsTemplate>
              <p>There is no data to display</p>
            </ng-template>
            <kendo-grid-messages filterMenuTitle="Filter">
            </kendo-grid-messages>  
          </kendo-grid>
        </div>
      </div>
    </div>
  </slx-spinner>


import {of as observableOf,  Subscription ,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';

import { appConfig, IApplicationConfig } from '../../../../../../app.config';

import { EmployeeWarningSections, EmployeeWarningExtended, EmployeeWarningSectionEmployeeRemarks } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-warnings-employee-remarks',
  templateUrl: 'employee-sections-warnings-employee-remarks.component.html',
  styleUrls: ['employee-sections-warnings-employee-remarks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsWarningsEmployeeRemarksComponent implements OnInit, OnDestroy {
  
  public remarks: EmployeeWarningSectionEmployeeRemarks;

  public remarksText: string;
  public employeeSign: string;
  public employeeSignDate: Date;

  @unsubscribeAll()
  public subscriptions: StringMap<Subscription> = {};
  @ViewChild('form', { static: true })
  public form: NgForm;
  public appConfig: IApplicationConfig = appConfig;

  constructor(
    private readonly manService: EmployeeSectionWarningsManagementService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.remarks = this.manService.getEmployeeRemarksSection();

    this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(warning => {
      this.assignRemarks(warning);
      this.assignRemarksData();
      this.updateView();
    });

    this.subscriptions.statusChanges = this.form.statusChanges.subscribe(() => {
      this.manService.changeSectionValidity(EmployeeWarningSections.StandardEmployeeRemarks, this.form.valid);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onChangeRemarks(): void {
    this.remarks.data.employeeRemarks = this.remarksText;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeEmpSign(): void {
    this.remarks.data.employeeSign = this.employeeSign;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeEmpSignDate(): void {
    this.remarks.data.employeeSignDate = this.employeeSignDate;
    this.manService.markWarningAsEdited(true);
  }


  private updateView(): void {
    observableOf(true).pipe(
      delay(200))
      .subscribe(() => this.changeDetector.detectChanges());
  }

  private assignRemarks(warning: EmployeeWarningExtended): void {
    const remarks = _.find(warning.sections, s => s.isStandardEmployeeRemarks);
    if (_.isObjectLike(remarks) && _.isObjectLike(remarks.data)) {
      this.remarks = remarks as EmployeeWarningSectionEmployeeRemarks;
    }
  }

  private assignRemarksData(): void {
    this.remarksText = this.remarks.data.employeeRemarks;
    this.employeeSign = this.remarks.data.employeeSign;
    this.employeeSignDate = this.remarks.data.employeeSignDate;
  }
}

import * as _ from 'lodash';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { NotificationsService } from '../../../../../core/components/index';

import { LMCreationAbsenceManagementService, LMRecurrenceService, LMRequestMapperService } from '../../../services/index';

import { LoaRequestDialogOptions, LoaRequestEmployee, LoaRequest } from '../../../models/index';
import { Employee } from '../../../../../organization/models/index';

import { dateTimeUtils } from '../../../../../common/utils';

class ErrorCreationLoaRequest extends Error {
  constructor(public message: string, public error: any) {
    super();
  }
}

@Component({
  moduleId: module.id,
  selector: 'slx-lm-creation-container',
  templateUrl: 'lm-creation-container.component.html',
  styleUrls: ['lm-creation-container.component.scss'],
  providers: [LMCreationAbsenceManagementService, LMRecurrenceService, LMRequestMapperService]
})
export class LMCreationContainerComponent implements OnInit, OnDestroy {
  @Input()
  public set options(v: LoaRequestDialogOptions) {
    if (_.isObjectLike(v)) {
      this.m_orgLevelId = v.orgLevelId;
      this.loaRequest = v.loaRequest;
    }
  }

  @Output()
  public action = new EventEmitter<boolean>();

  @Output()
  public requestChange = new EventEmitter<LoaRequest>();

  public isLoading = false;
  public errorMessage = '';

  public get hasEmployee(): boolean {
    return _.isFinite(_.get(this.loaRequest, 'empId'));
  }

  public get canEditRequest(): boolean {
    return this.manService.canEditRequest;
  }  
  
  public get editModeOn(): boolean {
    return this.manService.editMode;
  }

  public get isCreatingNew(): boolean {
    return this.manService.isCreatingNew;
  }

  public get canSave(): boolean {
    return (_.get(this.loaRequest, 'readyToSave') && !(_.isEmpty(this.manService.getAbsenceReason())) && this.isValidDates());
  }

  public get hasError(): boolean {
    return _.size(this.errorMessage) > 0;
  }

  public get orgLevelId(): number {
    return this.m_orgLevelId;
  }

  private m_orgLevelId: number;
  private loaRequest: LoaRequest;
  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: LMCreationAbsenceManagementService, private notificationService: NotificationsService) { }

  public ngOnInit(): void {
    this.manService.init(this.orgLevelId, this.loaRequest);
    this.subscriptions.loading = this.manService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
    this.subscriptions.loading = this.manService.subscribeToClosePopup(() => {
      this.action.emit(true);
    });
    this.subscriptions.request = this.manService.subscribeToChangedRequest((r: LoaRequest) => {
      this.loaRequest = r;
      this.requestChange.emit(r);
    });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
    this.manService.destroy();
  }

  public async onSave(): Promise<void> {
    this.isLoading = true;
    if (this.isCreatingNew) {
      await this.saveRequest();
    } else {
      await this.updateRequest();
    }
    this.isLoading = false;
  }

  public onCancel(): void {
    this.action.emit(false);
  }

  public onEdit(): void {
    this.manService.toggleEditMode(true);
  }

  public onSearchLoadStatusChange(isLoading: boolean): void {
    this.manService.changeLoading(isLoading);
  }

  public onEmployeeSelected(emp: Employee): void {
    this.manService.setEmployee(emp);
  }

  private async saveRequest(): Promise<void> {
    try {
      const loa = await this.saveLoaRequest();
      await this.attachSavedFiles(loa);
      this.manService.loadRequests();
      this.notificationService.success('Request created', 'Leave of Absence Request created successfully');
      this.action.emit(true);
    } catch (err) {
      this.errorMessage = err && err.message || 'An error has occurred, can\'t create Absence request';
      console.error(err instanceof ErrorCreationLoaRequest ? err.error : err);
    }
  }

  private async updateRequest(): Promise<void> {
    try {
      const loa = await this.manService.updateLoaRequest();
      await this.attachSavedFiles(loa);
      this.manService.loadRequests();
      this.notificationService.success('Request updated', 'Leave of absence request updated successfully');
      this.action.emit(true);
    } catch (err) {
      this.errorMessage = err && err.message || 'An error has occurred, can\'t update Absence request';
      console.error(err);
    }
  }

  private async saveLoaRequest(): Promise<LoaRequest> {
    let loa: LoaRequest = null;
    try {
      loa = await this.manService.createLoaRequest();
    } catch (err) {
      throw new ErrorCreationLoaRequest('An error has occurred during creating Absence request', err);
    }
    return loa;
  }

  private async attachSavedFiles(loa: LoaRequest): Promise<LoaRequest> {
    let newLoa: LoaRequest = null;
    try {
      newLoa = await this.manService.attachSavedFiles(loa);
    } catch (err) {
      throw new ErrorCreationLoaRequest('An error has occurred during saving attachments of Absence request', err);
    }
    return newLoa;
  }

  private isValidDates(): boolean{
    return this.isValidDate(this.loaRequest.estStartDate) && this.isValidDate(this.loaRequest.estEndDate)  && this.isValidDate(this.loaRequest.actStartDate)  && this.isValidDate(this.loaRequest.actEndDate);
  }

  private isValidDate(date: Date): boolean {
    return dateTimeUtils.validateDate(date);
  }
}

<div class="header-info">
  <span class="inline-block" *ngIf="isVisible('regularHours')">
    Regular Hours: {{ empEarning?.regularHours | number: ".2-2" }}
  </span>
  <span class="inline-block" *ngIf="isVisible('regularPay')">
    Regular Pay: {{ empEarning?.regularPay | number: ".2-2" | slxMoney }}
  </span>
  <span class="inline-block" *ngIf="isVisible('overtimeAndExtraHours')">
    Overtime and Extra Hours: {{ empEarning?.overtimeAndExtraHours | number: ".2-2" }}
  </span>
  <span class="inline-block" *ngIf="isVisible('overtimeAndExtraPay')">
    Overtime and Extra Pay: {{ empEarning?.overtimeAndExtraPay | number: ".2-2"| slxMoney }}
  </span>
</div>
<div class="detail-grid">
  <kendo-grid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" scrollable="none" [sortable]="false"
    [sort]="gridState.state.sort" [groupable]="false" [filterable]="false">

    <kendo-grid-column media="xs" title="Items" headerClass="detail-header" class="detail-cell">
      <ng-template kendoGridCellTemplate let-dataItem>
        <!-- template for mobile -->
        <h4>{{ dataItem?.payRule?.payRule.description }}</h4>
        <dl>
          <dt>Department</dt>
          <dd>{{ dataItem?.earning.department.name }}</dd>

          <dt>Position</dt>
          <dd>{{ dataItem?.earning.position.name }}</dd>
          <div *ngIf="!dataItem?.payRule?.isMoneyAmountRule">
            <dt>Pay Rate</dt>
            <dd>{{ dataItem?.payRule?.payRate| number: ".2-2"| slxMoney }}</dd>

            <dt>Hours</dt>
            <dd>{{ dataItem?.payRule?.hours }}</dd>
          </div>
          <div *ngIf="dataItem?.payRule?.isMoneyAmountRule">
            <dt>Pay Rate</dt>
            <dd>{{ dataItem?.payRule?.moneyAmount| number: ".2-2"| slxMoney }}</dd>
          </div>
        </dl>
        <h5 *ngIf="dataItem.first">
          Totals
        </h5>
        <dl *ngIf="dataItem.first">
          <dt>Regular Hours</dt>
          <dd>{{ dataItem.earning.RegularHours| number: ".2-2" }}</dd>

          <dt>Regular Pay</dt>
          <dd>{{ dataItem.earning.regularPay| number: ".2-2"| slxMoney }}</dd>

          <dt> Overtime and Other Hours</dt>
          <dd>{{ dataItem.earning.overtimeAndExtraHours | number: ".2-2" }}</dd>

          <dt> Overtime and Other Pay</dt>
          <dd>{{ dataItem.earning.overtimeAndExtraPay | number: ".2-2"| slxMoney }}</dd>
        </dl>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [hidden]="!isVisible('department')" title="Department" media="lg" headerClass="detail-header" class="detail-cell">
      <ng-template kendoGridHeaderTemplate>
        Department
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.earning.department.name}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [hidden]="!isVisible('position')" title="Position" media="sm" headerClass="detail-header" class="detail-cell">
      <ng-template kendoGridHeaderTemplate>
        Position
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.earning.position.name}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [hidden]="!isVisible('costCenterCode')" title="Cost Center Code" media="lg" headerClass="detail-header" class="detail-cell">
      <ng-template kendoGridHeaderTemplate>
        Cost Center Code
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.earning.costCenterCode}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column media="sm" field="payRule?.payRule.description" headerClass="detail-header" class="detail-cell">
      <ng-template kendoGridHeaderTemplate>
        Pay Rule
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.payRule?.payRule.description}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [hidden]="!isVisible('payRate') || !state.isShowPayRates" title="Pay Rate" media="sm" headerClass="detail-header" class="detail-cell numeric-cell">
      <ng-template kendoGridHeaderTemplate>
        Pay Rate
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.payRule?.payRate| number: ".2-2"| slxMoney}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column media="sm" headerClass="detail-header" class="detail-cell numeric-cell">
      <ng-template kendoGridHeaderTemplate let-dataItem>
        Hours/Pay
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="!dataItem?.payRule?.isMoneyAmountRule">{{dataItem?.payRule.hours| number: ".2-2"}}</span>
        <span *ngIf="dataItem?.payRule?.isMoneyAmountRule">{{dataItem?.payRule.moneyAmount| number: ".2-2"| slxMoney}}</span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div class="direct-care-toolbar">
  <slx-toolbar class="direct-care-toolbar__helper" [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate alignMode="left" [width]="dateRangeWidth">
      <span class="direct-care-toolbar__control">
        <slx-daterange
          class="slx-blue-btn direct-care-toolbar__range"
          name="startEndDate"
          [hasApplyButton]="isDesktop"
          [disableFutureDates]="false"
          [startDate]="dateRange.startDate"
          [endDate]="dateRange.endDate"
          (rangeDateChanged)="onChangeDates($event)"
        ></slx-daterange>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="35">
      <span class="direct-care-toolbar__control as-flex-start mt-16"  *ngIf="!isCollapsed">
        <slx-columns-config-button
            [colGroupKey]="columnsStateName"
            [blueStyleConfig]="{ btnClass: 'slx-button slx-only-icon slx-toolbar', iconClass: 'fa fa-cog' }"
            title="Columns settings"
          ></slx-columns-config-button>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="95">
      <span class="direct-care-toolbar__control as-flex-start mt-16" *ngIf="!isCollapsed">
        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-cog'" 
          [popper]="popperContent"
          [popperTrigger]="'click'"
          popperPlacement="bottom-start"
          [popperDisableStyle]="'true'"
          [popperPositionFixed]="true" >Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (onClick)="onClickExport(false)">Export to Excel</slx-action-list-item>
            <slx-action-list-item (onClick)="onClickExport(true)">Export to PDF</slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </span>
    </ng-template>
  </slx-toolbar>
</div>


import {of as observableOf,  Observable ,  Subscription } from 'rxjs';

import {delay} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { AbstractControl, NgModel } from '@angular/forms';

import { SortDescriptor, process } from '@progress/kendo-data-query';

import { appConfig, IApplicationConfig } from '../../../../../app.config';

import { unsubscribeAll } from '../../../../../core/decorators/index';
import { NotificationsService } from '../../../../../core/components/index';
import {
  KendoGridStateHelper,
  IBenefitsEmployeeDependentsEnrollment,
  BenefitsEmployeeDependentsEnrollmentValidity,
  BENEFITS_EMPLOYEE_DEPENDENTS_ENROLLMENT
} from '../../../../../common/index';

import { EmployeeDependentBenefitsOptions } from '../../../models/index';
import { BenefitEligibleDependentBenefit, BenefitEmployeeDependent, BenefitEmployeeDependentsEnrollments } from '../../../../../app-modules/benefits/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-dependent-benefits',
  templateUrl: 'employee-dependent-benefits.component.html',
  styleUrls: ['employee-dependent-benefits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeDependentBenefitsComponent implements OnInit, OnDestroy {
  @Input()
  public set options(v: EmployeeDependentBenefitsOptions) {
    if (_.isObjectLike(v)) {
      this.selectedDependents = v.dependents;
      this.loadEligibleBenefits();
    }
  }

  @Input()
  public set dependentsEnrollments(v: BenefitEmployeeDependentsEnrollments) {
    if (_.isObjectLike(v)) {
      this.enrollDependents(v);
    }
  }

  @Output()
  public selectBenefits = new EventEmitter<BenefitEmployeeDependentsEnrollments>();

  @Output()
  public enrollmentDone = new EventEmitter<boolean>();

  public get isShownSpinner(): boolean {
    return this.isEnrolling || this.isLoadingBenefits;
  }
  public get isMultipleDependents(): boolean {
    return _.size(this.selectedDependents) > 1;
  }
  public gridState: KendoGridStateHelper<BenefitEligibleDependentBenefit>;
  public eligibleBenefits: BenefitEligibleDependentBenefit[] = [];
  public selectedDependents: Array<BenefitEmployeeDependent> = [];
  public startDate: Date = null;
  public endDate: Date = null;
  public minStartDate: Date = null;
  public maxEndDate: Date = null;
  public pageSize: number = 50;
  public benefitsError: boolean = false;
  public dependentsError: boolean = false;
  public isDisabledDates: boolean = true;
  public isDisabledSelection: boolean = false;
  public isLoadingBenefits: boolean = true;
  public isCheckedAll: boolean;
  public isEnrolling: boolean;
  public latestDepDOB: Date = null;
  public appConfig: IApplicationConfig;
  public isSDateChanged: boolean = false;
  public isEDateChanged: boolean = false;
  public isCheckSelected: boolean = false;


  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private selectedBenefits = new Map<number, BenefitEligibleDependentBenefit>();
  private filteredRecords: Array<BenefitEligibleDependentBenefit>;

  constructor(
    @Inject(BENEFITS_EMPLOYEE_DEPENDENTS_ENROLLMENT)
    private manService: IBenefitsEmployeeDependentsEnrollment<BenefitEligibleDependentBenefit, BenefitEmployeeDependentsEnrollments, BenefitsEmployeeDependentsEnrollmentValidity>,
    private notificationService: NotificationsService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<BenefitEligibleDependentBenefit>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'benefitName', dir: 'asc' }, { field: 'empBenefitStartDate', dir: 'asc' }];
  }

  public ngOnInit(): void {
    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe((v): void => {
        this.updateBenefits();
      });
  }

  public ngOnDestroy(): void { }

  public isCheckedEmp(ben: BenefitEligibleDependentBenefit): boolean {
    return this.selectedBenefits.has(ben.empEnrollmentId);
  }

  public onSelectAll(): void {

    if (this.isCheckedAll) {
      this.selectedBenefits.clear();
      this.isCheckedAll = false;
    } else {
      _.forEach(this.filteredRecords, e => {
        this.selectedBenefits.set(e.empEnrollmentId, e);
      });
      this.isCheckedAll = true;
    }
    this.validateEnrollments();
    this.selectedControl(false, false, true);
  }

  public onSelectSingle(ben: BenefitEligibleDependentBenefit): void {
    if (this.selectedBenefits.has(ben.empEnrollmentId)) {
      this.selectedBenefits.delete(ben.empEnrollmentId);
      this.isCheckedAll = false;
    } else {
      this.selectedBenefits.set(ben.empEnrollmentId, ben);
      if (_.size(this.filteredRecords) === this.selectedBenefits.size) {
        this.isCheckedAll = true;
      }
    }
    this.validateEnrollments();
    this.selectedControl(false, false, true);
  }

  public loadEligibleBenefits(): void {
    this.isLoadingBenefits = true;
    this.manService.getDependentsEligibleBenefits(_.map(this.selectedDependents, dep => dep.id))
      .then((eligibleBenefits) => {
        this.eligibleBenefits = eligibleBenefits;
        this.isDisabledSelection = _.size(eligibleBenefits) === 0;
        this.isLoadingBenefits = false;
        this.updateBenefits();
      });
  }

  public onChangeStartDate(): void {
    this.selectedControl(true, false, false);
    this.validateDates();
  }

  public onChangeEndDate(): void {
    this.selectedControl(false, true, false);
    this.validateDates();
  }

  private selectedControl(isDate: boolean, isEdate: boolean, isCheck: boolean): void {
    this.isSDateChanged = isDate;
    this.isEDateChanged = isEdate;
    this.isCheckSelected = isCheck;
  }

  public updateBenefits(): void {
    this.refreshGrid();
    this.changeDetector.markForCheck();
  }

  private validateEnrollments(): void {
    const selBenefits = Array.from(this.selectedBenefits.values());
    const benefits = _.map(selBenefits, b => [b.empBenefitStartDate, b.empBenefitEndDate] as [Date, Date]);
    const dependents = _.map(this.selectedDependents, d => d.birthDate);

    const validity = this.manService.validateSelection(benefits, dependents);
    this.startDate = validity.startDate;
    this.endDate = validity.endDate;
    this.minStartDate = validity.minDate;
    this.maxEndDate = validity.maxDate;

    this.latestDepDOB = validity.latestDepDOB;
    this.benefitsError = validity.isErrorInvalidBenefits();
    this.dependentsError = validity.isErrorInvalidDependents();
    this.isDisabledDates = this.benefitsError || this.dependentsError || validity.isErrorNoBenefits() || validity.isErrorNoDependents();

    this.emitDependentsEnrollments(validity.hasError, this.selectedDependents, selBenefits);
    this.detectChanges();
  }

  private validateDates(): void {
    const selBenefits = Array.from(this.selectedBenefits.values());

    const validity = this.manService.validateDates(this.startDate, this.endDate);
    this.latestDepDOB = validity.latestDepDOB;
    this.benefitsError = validity.isErrorInvalidBenefits();
    this.dependentsError = validity.isErrorInvalidDependents();
    this.isDisabledSelection = this.benefitsError || this.dependentsError || validity.isErrorInvalidStartOrEndDate();

    this.emitDependentsEnrollments(validity.hasError, this.selectedDependents, selBenefits);
    this.detectChanges();
  }

  private emitDependentsEnrollments(hasError: boolean, dependents: Array<BenefitEmployeeDependent>, benefits: Array<BenefitEligibleDependentBenefit>): void {
    let depsbenefits: BenefitEmployeeDependentsEnrollments = null;
    if (!hasError) {
      depsbenefits = new BenefitEmployeeDependentsEnrollments(this.startDate, this.endDate, dependents, benefits);
    }
    this.selectBenefits.emit(depsbenefits);
  }

  private enrollDependents(v: BenefitEmployeeDependentsEnrollments): void {
    this.isEnrolling = true;
    this.manService.enrollDependents(v)
      .then(() => {
        this.notificationService.success('Dependents enrolled', 'Selected dependents were successfully added to the benefits');
        this.enrollmentDone.emit(true);
      })
      .catch(() => {
        this.enrollmentDone.emit(false);
        this.isEnrolling = false;
      });
  }

  private detectChanges(): void {
    observableOf(true).pipe(
      delay(25))
      .subscribe(() => this.changeDetector.detectChanges());
  }

  private refreshGrid(): void {
    if (!this.eligibleBenefits) {
      return;
    }
    this.filteredRecords = process(this.eligibleBenefits, { filter: this.gridState.state.filter, sort: this.gridState.state.sort }).data;
    this.gridState.view = process(this.eligibleBenefits, this.gridState.state);
  }
}

export * from './login/login.component';
export * from './login-form/login-form.component';
export * from './login-dialog/login-dialog.component';
export * from './forgot-password-form/forgot-password-form.component';
export * from './client-message/client-message.component';
export * from './reset-password-form/reset-password-form.component';
export * from './login-ip-restriction-message/login-ip-restriction-message.component';


import { LoginComponent } from './login/login.component';
import { SsoErrorComponent } from './sso-error/sso-error.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';
import { ClientMessageComponent } from './client-message/client-message.component';
import { LoginIpRestrictionMessageComponent } from './login-ip-restriction-message/login-ip-restriction-message.component';
import { AuthHandler } from './auth-handler/auth-handler.component';


export const exportComponents: any[] = [
  ClientMessageComponent
];

export const components: any[] = [
  LoginComponent,
  SsoErrorComponent,
  AuthHandler,
  LoginFormComponent,
  LoginDialogComponent,
  ForgotPasswordFormComponent,
  ResetPasswordFormComponent,
  LoginIpRestrictionMessageComponent,
  ...exportComponents  
];

export const entryComponents: any[] = [
  LoginDialogComponent,
  LoginFormComponent,
  ForgotPasswordFormComponent,
  ResetPasswordFormComponent,
  LoginIpRestrictionMessageComponent
];

import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { unsubscribeAll } from '../../../../../core/decorators/index';

import { ColumnSettingsStorageService, ColumnManagementService, StateManagementService, ComponentStateStorageService } from '../../../../../common/services/index';

import { WcCommonManagementService, WcIncidentTrackingManagementService, WcRosterToolbarService } from '../../../services/index';

@Component({
  selector: 'slx-wc-incident-tracking',
  templateUrl: './wc-incident-tracking.component.html',
  styleUrls: ['./wc-incident-tracking.component.scss'],
  providers: [
    WcCommonManagementService,
    WcIncidentTrackingManagementService,
    WcRosterToolbarService,
    ColumnManagementService,
    ColumnSettingsStorageService,
    StateManagementService,
    ComponentStateStorageService
  ]
})
export class WcIncidentTrackingComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: WcIncidentTrackingManagementService) {}

  public ngOnInit() {
    this.manService.init('WcIncidentTracking');

    this.subscriptions.onLoad = this.manService
      .subscribeToLoading((value: boolean) => (this.isLoading = value));
  }

  public ngOnDestroy(): void {
    this.manService.destroy();
  }
}

import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { UntypedFormControl, AbstractControl, NgControl } from '@angular/forms';
import { Assert, StringUtils } from '../../framework/index';

@Pipe({
  name: 'errors',
  pure: false
})
@Injectable()
export class Errors implements PipeTransform {
  public transform(form: UntypedFormControl | NgControl, controlPath: string): StringMap<any> {
    Assert.isNotNull(form, 'form');

    if (form instanceof NgControl || StringUtils.isNullOrEmpty(controlPath)) {
      return form.errors;
    }

    let control: AbstractControl = form.get(controlPath);
    if (!control) {
      return undefined;
    }
    return control.errors;
  }
}


import {combineLatest} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';


import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import { KendoGridStateHelper } from '../../../../common/models/index';

import { UserRolesManagementService } from '../../../services/index';
import { RolesWithUsersContainerDecorator, RolesWithUsersDecorator } from '../../../models/index';
import { ActivatedRoute } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  moduleId: module.id,
  selector: 'slx-user-roles-assignement-grid',
  templateUrl: 'user-roles-assignement-grid.component.html',
  styleUrls: ['user-roles-assignement-grid.component.scss']
})

export class UserRolesAssignementGridComponent implements OnInit, OnDestroy {
  @ViewChild('kendoGrid', {static: true})
  public kendoGrid:GridComponent;

  @Input()
  public filterString: string;

  @Input()
  public set rolesDecorator(value: RolesWithUsersContainerDecorator) {
    this.m_rolesDecorator = value;
    this.setFilter(this.filterString);
    this.refreshGrid();
  }

  public gridState: KendoGridStateHelper<RolesWithUsersDecorator>;

  @unsubscribe()
  private routeSubscription: Subscription;

  private m_rolesDecorator: RolesWithUsersContainerDecorator;

  constructor(private activatedRoute: ActivatedRoute) {
    this.gridState = new KendoGridStateHelper<RolesWithUsersDecorator>();
  }

  public ngOnInit(): void {
    this.refreshGrid();

    this.routeSubscription = this.activatedRoute.params.pipe(
      combineLatest(this.activatedRoute.queryParams))
      .subscribe(([params, queryParams]) => {
        this.setFilter(queryParams['search']);
        this.refreshGrid();
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private setFilter (filter: string): void {
    this.filterString = filter;
    if(this.m_rolesDecorator) {
      this.m_rolesDecorator.setFilter(filter);
    }
  }
  private refreshGrid(): void {

    if (!this.m_rolesDecorator) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.m_rolesDecorator.roles, this.gridState.state);
    for (let i = 0; i < this.m_rolesDecorator.roles.length; i++) {
      this.kendoGrid.collapseRow(i);
    }
  }
}

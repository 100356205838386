import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AuthHandler } from './authentication/components/auth-handler/auth-handler.component';
export const appRoutes: Routes = [
  {
    // Needed for hash routing
    path: 'validator',
    component: AuthHandler
  },
  { path: '', redirectTo: 'apps', pathMatch: 'full' }
];

/* export const appRoutesModule: ModuleWithProviders = RouterModule.forRoot(appRoutes, { useHash: true }); */

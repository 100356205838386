
import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Observable ,  ReplaySubject ,  Subject ,  Subscription } from 'rxjs';

import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import {
  BenefitDetailsBasic,
  BenefitDetailsConfig,
  BenefitDetailsGroup,
  BenefitDetailsProvider,
  BenefitDetailsSavedProvider,
  BenefitDetailsLineStandart,
  BenefitGroupLoader,
  BenefitLineInfo,
  BenefitPayrollMapping,
  BenefitPayrollExistingMapping,
  BenefitPayrollCreateMapping
} from '../../models/index';
import { BenefitListApiService } from './benefit-list-api.service';
import { BenefitConsoleDetailsCommonService } from './benefit-console-details-common.service';

@Injectable()
export class BenefitListManagementService {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;
  private benefitGroup: BenefitDetailsGroup;
  private providers: BenefitDetailsProvider[] = [];
  private loadExpriredPlans: boolean = false;
  private selectedProvider: BenefitDetailsProvider;
  private selectedProviderLine: BenefitDetailsLineStandart;

  private recordsLoaded$ = new ReplaySubject<BenefitLineInfo[]>(1);
  private planHeadersLoaded$ = new ReplaySubject<BenefitDetailsConfig>(1);
  private planTypesLoaded$ = new ReplaySubject<BenefitDetailsProvider[]>(null);

  private loading$ = new Subject<boolean>();
  private isLoadingExpired$ = new ReplaySubject<boolean>(1);
  private planTypesLoading$ = new Subject<BenefitGroupLoader>();
  private planGroupsLoading$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private configChanged$ = new ReplaySubject<BenefitDetailsConfig>(1);
  private loadProviders$ = new ReplaySubject<BenefitDetailsProvider[]>(1);
  private changeGroup$ = new ReplaySubject<BenefitDetailsGroup>(1);
  private selectProvider$ = new ReplaySubject<BenefitDetailsProvider>(1);
  private selectProviderLine$ = new ReplaySubject<BenefitDetailsLineStandart>(1);


  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private apiService: BenefitListApiService,
    private commonService: BenefitConsoleDetailsCommonService
  ) {
    this.init();
  }

  public init(): void {
    this.subscribeToOrgLevelChanges();
    this.subscriptions.restoreShowExpired = this.commonService.subscribeToShowExpiredPlansState((isShow: boolean) => {
      this.changeShowingExpiredPlans(isShow);
    });
  }

  public destroy(): void {
    this.orgLevel = null;
    this.loading$.complete();
    this.orgLevelChanged$.complete();
    this.configChanged$.complete();
    this.loadProviders$.complete();
    this.changeGroup$.complete();
    this.selectProvider$.complete();
    this.selectProviderLine$.complete();
    this.recordsLoaded$.complete();
    this.planTypesLoading$.complete();
    this.planTypesLoaded$.complete();
    this.planHeadersLoaded$.complete();
    this.isLoadingExpired$.complete();
  }

  public get isLoadingExpiredPlans(): boolean {
    return this.loadExpriredPlans;
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToIsLoadingExpired(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.isLoadingExpired$.subscribe(callback);
  }

  public subscribeToPlanTypeLoading(callback: (v: BenefitGroupLoader) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.planTypesLoading$.subscribe(callback);
  }

  public subscribeToPlanGroupsLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.planGroupsLoading$.subscribe(callback);
  }

  public subscribeToLoadedRecords(callback: (v: BenefitLineInfo[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.recordsLoaded$.subscribe(callback);
  }

  public subscribeToPlanTypeRecords(callback: (v: BenefitDetailsProvider[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.planTypesLoaded$.subscribe(callback);
  }

  public subscribeToPlanHeaders(callback: (v: BenefitDetailsConfig) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.planHeadersLoaded$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (v: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelChanged$.subscribe(callback);
  }

  public changeShowingExpiredPlans(isShow: boolean): void {
    this.loadExpriredPlans = isShow;
    this.isLoadingExpired$.next(isShow);
    this.commonService.saveIsShowingExpiredPlansState(isShow);
  }

  public subscribeToChangeConfig(callback: (v: BenefitDetailsConfig) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.configChanged$.subscribe(callback);
  }

  public setLoadingStatus(isLoading: boolean): void {
    this.loading$.next(isLoading);
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$.pipe(
      filter((o: OrgLevel) => _.isFinite(_.get(o, 'id'))))
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;

        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
      });
  }



  public getBenefitList(): void {
    this.loading$.next(true);
    this.apiService.getBenefitList(this.orgLevel.id, this.isLoadingExpiredPlans)
      .then((container: BenefitLineInfo[]) => {
        this.recordsLoaded$.next(container);
      }).catch(() => {
        this.recordsLoaded$.next([]);
      }).finally(() => {
        this.loading$.next(false);
      });
  }

  public getBenefitPlanHeaders(): void {
    this.planGroupsLoading$.next(true);
    this.apiService.getBenefitPlanHeaders(this.orgLevel.id)
      .then((container: BenefitDetailsConfig) => {
        this.planHeadersLoaded$.next(container);
      }).finally(() => {
        this.planGroupsLoading$.next(false);
      });
  }

  public getBenefitPlanTypes(groupId, showExpired): void {
    this.planTypesLoading$.next({ groupId: groupId, isLoading: true });
    this.apiService.getBenefitPlanType(this.orgLevel.id, groupId, showExpired)
      .then((container: BenefitDetailsProvider[]) => {
        this.planTypesLoaded$.next(container);
      }).finally(() => {
        this.planTypesLoading$.next({ groupId: groupId, isLoading: false });
      });
  }
}

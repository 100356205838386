
import {combineLatest} from 'rxjs/operators';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';

import { GridComponent } from '@progress/kendo-angular-grid';

import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { UserApplication, OrgLevel } from '../../../../../state-model/models/index';
import { OperationalConsoleEntry } from '../../../../../employee/employee-list/models/index';

import { OperationalConsoleStateService } from '../../../../services/operational-console/operational-console-state.service';

@Component({
  moduleId: module.id,
  selector: 'slx-operational-console-header',
  templateUrl: 'operational-console-header.component.html',
  styleUrls: ['operational-console-header.component.scss'],
})
export class OperationalConsoleHeaderComponent implements OnInit, OnDestroy {
  public currentDate: Date;
  public orgLevel: OrgLevel;
  public application: UserApplication;
  public isLoading: boolean;
  public gridState: KendoGridStateHelper<OperationalConsoleEntry>;
  public entries: OperationalConsoleEntry[];
  @ViewChild('kendoGrid', {static: true})
  public grid: GridComponent;

  @mutableSelect('application')
  public application$: Observable<UserApplication>;
  @mutableSelect('orgLevel')
  private orgLevel$: Observable<OrgLevel>;
  @unsubscribe()
  private orglevelSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private employeeLinkClickSubscription: Subscription;
  private employeeLinkClick: EventEmitter<number>;

  constructor(
    public stateService: OperationalConsoleStateService
  ) {
    stateService.currentDate = moment().toDate();
    // stateService.isLoading = true;
    stateService.entries = [];
    this.employeeLinkClick = new EventEmitter<number>();
  }

  public ngOnInit(): void {
    this.orglevelSubscription = this.orgLevel$.pipe(
      combineLatest(this.application$))
      .subscribe(([orgLevel, application]: [OrgLevel, UserApplication]) => {
        if (!orgLevel || !orgLevel.id || !application || !application.id) return;

        this.stateService.orgLevel = orgLevel;
        this.stateService.application = application;
        this.onDateChanged();
      });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onDateChanged(): void {
    let old: boolean = this.stateService.canLoadData;
    this.stateService.canLoadData = true;
    this.stateService.loadData();
    this.stateService.canLoadData = old;
  }

  public onExportToExcel(): void {
    this.grid.saveAsExcel();
  }

  public onExportToPdf(): void {
    this.grid.saveAsPDF();
  }
}


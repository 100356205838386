import * as _ from 'lodash';

import { Component, Input, ContentChild, TemplateRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { createValuAccessor } from '../../../common/utils/index';
import { AutoCompleteComponent, PopupSettings, VirtualizationSettings } from '@progress/kendo-angular-dropdowns';
import { AutocompleteItemDirective } from '../../directives/autocomplete/autocomplete.directive';

@Component({
  moduleId: module.id,
  selector: 'slx-autocomplete',
  templateUrl: 'autocomplete.component.html',
  providers: [createValuAccessor(AutocompleteComponent)]
})
export class AutocompleteComponent implements ControlValueAccessor {
  @Input()
  public set options(items: Array<string | StringMap<any>>) {
    if (_.isArray(items) && _.size(items) > 0) {
      this.sourceListItems = items;
      this.listItems = items.concat();
    }
  }

  @Input()
  public titleField: string;

  @Input()
  public readonly: boolean;

  @Input()
  public placeholder: string;

  @Input()
  public strictSearch: boolean;

  @Input()
  public suggest: boolean;

  @Input()
  public rightIcon: string = 'fas fa-search';

  @Input()
  public setNullAfterInit: boolean = false;

  @Input()
  public popupSettings: PopupSettings = {
    popupClass: 'slx-autocomplete-popup'
  };

  @Input()
  public virtual: VirtualizationSettings = {
    itemHeight: 28
  };

  @Output()
  public blur: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public focus: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public valueChange: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild(AutocompleteItemDirective, { read: TemplateRef, static: true })
  public autocompleteItem: AutocompleteItemDirective;

  @ViewChild('autocomplete')
  public autocomplete: AutoCompleteComponent;

  public get value(): string {
    return this.m_value;
  }

  public set value(v: string) {
    if (!v) {
      if (this.m_value !== null) {
        this.m_value = null;
        this.onChangeCallback(this.m_value);
      }

      return;
    }
    if (!_.isEqual(this.m_value, v)) {
      this.m_value = v;
      const item: string | StringMap<any> = this.findItemInList(this.m_value);
      this.onChangeCallback(item ? item : null);
    }
  }

  public listItems: Array<string | StringMap<any>>;
  private sourceListItems: Array<string | StringMap<any>>;
  private m_value: string;

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  constructor() {
    this.titleField = 'name';
    this.m_value = null;
    this.strictSearch = false;
    this.suggest = false;
  }

  public onValueChange(value: any): void {
    this.valueChange.emit(value);
  }

  public onBlur(value: any): void {
    this.blur.emit(value);
  }

  public onFocus(value: any): void {
    this.focus.emit(value);
  }

  public writeValue(v: string | StringMap<any>): void {
    if (!_.isNil(v)) {
      this.value = this.getValue(v);
    } else if (this.setNullAfterInit) {
      this.m_value = null;
    }
  }

  public onFilterItems(v: string | StringMap<any>): void {
    this.autocomplete.loading = true;
    const value: string = this.getValue(v);

    this.listItems = _.filter(this.sourceListItems, (i: string | StringMap<any>) => {
      let item: string = this.getValue(i);
      if (this.strictSearch) {
        const separatedWords: string[] = item.split(' ');
        return _.some(separatedWords, (word: string) => _.startsWith(word.toLowerCase(), value.toLowerCase()));
      } else {
        return _.includes(item.toLowerCase(), value.toLowerCase());
      }
    });

    this.autocomplete.loading = false;
  }

  public get hasPlaceholder(): boolean {
    return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
  }

  public findItemInList(v: any): string | StringMap<any> {
    return _.find(this.sourceListItems, (i: string | StringMap<any>) => {
      const value: string = this.getValue(i);

      return value === v;
    });
  }

  @Input()
  public itemDisabled(itemArgs: { dataItem: string, index: number }): boolean {
    return false;
  }
  public registerOnChange(fn: () => void): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  public getValue(item: string | StringMap<any>): string {
    if (_.isString(item)) {
      return item;
    } else if (_.isObjectLike(item) && !_.isArray(item)) {
      return item[this.titleField];
    } else {
      return null;
    }
  }
}

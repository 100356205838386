export interface IPbjConfig {
  settings: {
    reconciliation: {
      maxDaysRange: number
    },
    export: {
      xlsx: {
        titleCell: {
          fontSize: number;
          borderBottom: {
            size: number;
            color: string;
          }
        };
        headerCell: {
          fontSize: number;
          color: string;
          background: string;
        };
      }
      pdf: {
        margins: {
          top: number;
          bottom: number;
          left: number;
          right: number;
        };
        unscaledGridColumnWidthMM: number;
      }
    }
  };
  api: {
    pbj: {
      root: string;
      export: {
        root: string;
        header: string;
        performExport: string;
        performBatchIDExport:string;
        getBatchIDExportData:string;
        getChildExportData:string;
        data: string;
        downloadBlobZipFile:string;
        DownloadExportSummary:string;
        DownloadBulkExport:string;
        downloadExcelFile:string;
        downloadPDFFile:string;
      },
      manualEntry: {
        root: string,
      }
      reconciliation: {
        root: string,
      },
      mealDeduction: {
        root: string;
        settings: string;
        allOrganizations: string;
      },
      duplicateWorkers:{
        root :string,
        GetEmployeeDupes:string;
        GetEmployeeDupesRefresh:string;
        changeDisposition:string;
        getExportMatchesDefaultDate:string;
        updateMatchesExportDate:string;
      }
    },
    signalR: {
      root: string;
      signalRHub: string;
      signalRApi: string;
      methodName:string;
    }
  };
}

export const pbjConfig: IPbjConfig = {
  settings: {
    reconciliation: {
      maxDaysRange: 145
    },
    export: {
      xlsx: {
        titleCell: {
          fontSize: 22, borderBottom: { size: 2, color: '#000000' }
        },
        headerCell: {
          fontSize: 14,
          color: '#ffffff',
          background: '#666666'
        }
      },
      pdf: {
        margins: {
          top: 20,
          bottom: 20,
          left: 10,
          right: 10
        },
        unscaledGridColumnWidthMM: 56
      }
    }
  },
  api: {
    pbj: {
      root: 'pbj',
      export: {
        root: 'export',
        header: 'header',
        performExport: 'performExport',
        performBatchIDExport:'performBatchIDExport',
        getBatchIDExportData:'getBatchIDExportData',
        getChildExportData:'getChildExportData',
        data: 'data',
        downloadBlobZipFile:'downloadBlobZipFile',
        DownloadExportSummary: 'DownloadExportSummary',
        DownloadBulkExport : 'DownloadBulkExport',
        downloadExcelFile:'DownloadExcelFile',
        downloadPDFFile: 'DownloadPDFFile'
      },
      manualEntry: {
        root: 'manualTimeEntries',
      },
      reconciliation: {
        root: 'reconciliation',
      },
      mealDeduction: {
        root: 'mealDeduction',
        settings: 'settings',
        allOrganizations: 'allOrganizations'
      },
      duplicateWorkers: {
        root: 'duplicateWorker',
        GetEmployeeDupes : 'GetEmployeeDupes',
        GetEmployeeDupesRefresh : 'GetEmployeeDupesRefresh',
        changeDisposition: 'changeDisposition',
        getExportMatchesDefaultDate:'GetExportMatchesDefaultDate',
        updateMatchesExportDate: 'updateMatchesExportDate'
      }
    },
    signalR: {
      root: 'exports',
      signalRHub: 'pbj-export-data',
      signalRApi: 'signalr',
      methodName: 'PBJExportGroupName'
    }
  }
};

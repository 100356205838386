import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, Observable, Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/operators';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { ManagementBaseService } from '../../../core/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { IUser } from '../../../authentication/store/index';
import { OrgLevel, User } from '../../../state-model/models/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import * as _ from 'lodash';
import { PayPoliciesAPIService } from './pay-policies-api.service';
import { PayPolicy } from '../../models/pay-policies/models/pay-policy.model';
import { PayPolicyRule } from '../../models/pay-policies/models/pay-policy-rule.model';
import { PayPoliciesContainer } from '../../models/pay-policies/pay-policies-container';

@Injectable()
export class PayPoliciesManagementService extends ManagementBaseService<any, any> implements IConfigurationManagementService {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['session', 'user'])
  public user$: Observable<IUser>;
  editItemCmd$: ReplaySubject<any>;

  public get container(): PayPoliciesContainer {
    return this.m_container;
  }

  private m_container: PayPoliciesContainer = new PayPoliciesContainer();

  @unsubscribeInService()
  private appDataSubscription: Subscription;

  private currentOrgLevel: OrgLevel;
  private currentUser: User;

  constructor(
    public access: AccessManagementService,
    public changeService: ChangeManagementService,
    private api: PayPoliciesAPIService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  editingItem: any;
  isEditingNewItem: boolean;
  onSaveItem: (result: { dataItem: any; isNew: boolean; }) => void;
  onRemoveItem: (item: any) => void;
  onEditItem: (item: any) => void;
  onCancelEditItem: () => void;
  setSelectedCount: (count: number) => void;
  onAddItem: (item: any) => void;
  addItemCmd$: ReplaySubject<any>;
  viewRefresh$: Subject<boolean>;
  removeItemsCmd$: ReplaySubject<any>;
  markAsDirty(): void {
    throw new Error('Method not implemented.');
  }

  public init(): void {
    this.onStateChanged$.next({ isLoading: true });

    this.access.allowCorporationLevel = false;
    this.access.allowOrganizationLevel = true;
    this.access.allowDepartmentLevel = true;

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id) {
        this.m_container.id = +id;
      }
    });

    this.appDataSubscription = this.orgLevel$.pipe(
      combineLatest(this.user$)
    ).subscribe((value: [OrgLevel, User]) => {
      let [orgLevel, user]: [OrgLevel, User] = value;
      if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
        return;
      }
      this.currentOrgLevel = orgLevel;
      this.currentUser = user;
      this.access.orgLevelType = this.currentOrgLevel.type;
      this.onStateChanged$.next({ isLoading: false });
    });
  }

  public loadPayPolicies(orgId: number): Promise<void> {
    return this.api.getAllPayPolicies(orgId).then(payPolicies => {
      // Filter pay policies by organizationId
      this.m_container.records = payPolicies;
    }).catch(error => {
      this.notificationsService.error('Error', 'Failed to load pay policies.');
      console.error('Error loading pay policies:', error);
      throw error;
    });
  }

  public loadPayPolicyById(organizationId: number, policyId: string): Promise<void> {
    return this.api.getPayPolicyById(organizationId, policyId).then(payPolicy => {
      this.m_container.selectedPolicy = payPolicy;
    }).catch(error => {
      this.notificationsService.error('Error', `Failed to load pay policy with ID ${policyId}.`);
      console.error('Error loading pay policy:', error);
      throw error;
    });
  }

  public createPayPolicy(organizationId: number, policy: PayPolicy): Promise<void> {
    return this.api.createPayPolicy(organizationId, policy).then(() => {
      this.notificationsService.success('Success', 'Pay policy created successfully.');
    }).catch(error => {
      this.notificationsService.error('Error', 'Failed to create pay policy.');
      console.error('Error creating pay policy:', error);
      throw error;
    });
  }

  public deletePayPolicyById(organizationId: number, policyId: string): Promise<void> {
    return this.api.deletePayPolicyById(organizationId, policyId).then(() => {
      this.notificationsService.success('Success', 'Pay policy deleted successfully.');
    }).catch(error => {
      this.notificationsService.error('Error', `Failed to delete pay policy with ID ${policyId}.`);
      console.error('Error deleting pay policy:', error);
      throw error;
    });
  }

  public loadPayPolicyRules(organizationId: number): Promise<void> {
    return this.api.getAllPayPolicyRules(organizationId).then(rules => {
      this.m_container.policyRules = rules;
    }).catch(error => {
      this.notificationsService.error('Error', 'Failed to load pay policy rules.');
      console.error('Error loading pay policy rules:', error);
      throw error;
    });
  }

  public loadPayPolicyRulesByPolicyId(organizationId: number, policyId: string): Promise<void> {
    return this.api.getPayPolicyRulesByPolicyId(organizationId, policyId).then(rules => {
      this.m_container.policyRules = rules; // No mapping needed, API service handles it
    }).catch(error => {
      this.notificationsService.error('Error', `Failed to load rules for policy with ID ${policyId}.`);
      console.error('Error loading pay policy rules:', error);
      throw error;
    });
  }

  public createPayPolicyRule(organizationId: number, policyId: string, rule: PayPolicyRule): Promise<void> {
    return this.api.createPayPolicyRule(organizationId, policyId, rule).catch(error => {
      console.error('Error creating pay policy rule:', error);
      throw error;
    });
  }

  public saveMultiplePolicyRules(organizationId: number, policyId: string, rules: PayPolicyRule[], paycodes: any[]): Promise<void> {
    let successCount = 0;
    let errorCount = 0;

    return Promise.all(
      rules.map((rule, index) =>
        this.createPayPolicyRule(organizationId, policyId, rule)
          .then(() => {
            successCount++;
            paycodes[index].hasChanged = false;  // Reset hasChanged to false on successful save
          })
          .catch(() => errorCount++)
      )
    ).then(() => {
      if (errorCount === 0) {
        this.notificationsService.success('Success', `${successCount} pay policy rules saved successfully.`);
      } else if (successCount > 0) {
        this.notificationsService.warn('Partial Success', `${successCount} rules saved, but ${errorCount} failed.`);
      } else {
        this.notificationsService.error('Error', 'Failed to save all pay policy rules.');
      }
    });
  }

  public deletePayPolicyRule(organizationId: number, policyId: string, ruleId: number): Promise<void> {
    return this.api.deletePayPolicyRule(organizationId, policyId, ruleId).then(() => {
      this.notificationsService.success('Success', 'Pay policy rule deleted successfully.');
    }).catch(error => {
      this.notificationsService.error('Error', `Failed to delete rule with ID ${ruleId} for policy ${policyId}.`);
      console.error('Error deleting pay policy rule:', error);
      throw error;
    });
  }

  public async isPolicyUsed(organizationId: number, policyId: string): Promise<boolean> {
    try {
      const isUsed = await this.api.isPolicyUsed(organizationId, policyId);
      return isUsed;
    } catch (error) {
      this.notificationsService.error('Error', `Failed to check if policy with ID ${policyId} is used.`);
      console.error('Error checking if pay policy is used:', error);
      throw error;
    }
  }
}

import {combineLatest} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';

import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { GenericListManagementService } from '../../../../organization/services/index';
import { GenericListDefinition, GenericList, GenericListRequest } from '../../../../organization/models/generic-list/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { genericListConfig } from '../../../../organization/generic-list.config';

import { Observable ,  Subscription } from 'rxjs';


import { UserApplication } from '../../../../state-model/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { IRangeDates } from '../../../../common/models/range-dates';
import { ColumnManagementService, StateManagementService } from '../../../../common/services';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-comments',
  templateUrl: 'schedule-comments.component.html',
  styleUrls: ['schedule-comments.component.scss'],
  providers: [GenericListManagementService, StateManagementService, ColumnManagementService]
})
export class ScheduleCommentsComponent implements OnInit, OnDestroy {

  @mutableSelect('application')
  public application: Observable<UserApplication>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public appConfig: IApplicationConfig;
  public startDate: Date;
  public endDate: Date;

  public state: {
    isLoading: boolean;
  };

  @unsubscribe()
  private definitionsLoadedSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private valuesLoadedSubscription: Subscription;
  @unsubscribe()
  private loadSubscription: Subscription;

  private currentOrgLevel: OrgLevel;
  private currentApplication: UserApplication;

  constructor(private genericListManagementService: GenericListManagementService,
              private stateManagement: StateManagementService) {
    this.state = {
      isLoading: false
    };
    this.startDate = moment().startOf('year').toDate();
    this.endDate = moment().endOf('year').toDate();
  }

  public ngOnInit(): void {
    this.stateManagement.init('ScheduleCommentsComponent', false);
    this.appConfig = appConfig;
    this.orgLevelSubscription = this.orgLevel$.pipe(
      combineLatest(this.application))
      .subscribe((value: [OrgLevel, UserApplication]) => {
        let [orgLevel, application]: [OrgLevel, UserApplication] = value;
        if (!orgLevel || !orgLevel.id || !application.id) {
          return;
        }
        this.currentOrgLevel = orgLevel;
        this.currentApplication = application;
        this.loadDefinitions();
      });

    this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe((defs: GenericListDefinition) => {
      this.stateManagement.onComponentActiveStateChanged({});
      this.loadValues();
    });

    this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe((list: GenericList) => {
      this.state.isLoading = false;
      this.stateManagement.loadedData({});
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.loadValues();
  }

  private loadDefinitions(): void {
    this.state.isLoading = true;
    this.genericListManagementService.loadListDefinition(this.currentApplication.id, this.currentOrgLevel.id, genericListConfig.scheduleComments, false);
  }

  private loadValues(): void {
    this.state.isLoading = true;
    let req: GenericListRequest = new GenericListRequest();
    req.startDate = this.startDate;
    req.endDate = this.endDate;
    this.genericListManagementService.loadListValues(this.currentOrgLevel.id, req);
  }
}

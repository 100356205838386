import { Injectable } from '@angular/core';
import { delay, filter, mapTo, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ResponseBody, StateResetTypes } from '../../core/models/index';
import { Meta } from '../../core/models/api/meta';

import { IPayloadAction } from '../../state-model/models/index';
import { OrgLevelApiService } from '../services/index';
import { OrganizationTreeContext } from '../models/index';
import { OrgLevel } from '../../state-model/models/index';
import { OrgLevelActions } from '../actions/index';
import { Observable } from 'rxjs';
import { SessionActions } from '../../authentication/actions/index';
import { ComponentStateStorageService } from '../../common/services/index';
import { OrgLevelWatchService } from '../../organization/services/index';
import { CacheUtilService } from '../../common/services/cache/cache.service';
import { AuthenticationService } from '../../authentication/services/index';


@Injectable()
export class OrgLevelEpics {
  constructor(
    private orgLevelApiService: OrgLevelApiService,
    private storageService: ComponentStateStorageService,
    private orgLevelWatchService: OrgLevelWatchService,
    private cacheUtilService: CacheUtilService
  ) {
  }

  public selectOrgLevel = (action$) => action$.pipe(
    filter(({ type }: IPayloadAction) => type === OrgLevelActions.SELECT_ORG_LEVEL),
    mergeMap((action: IPayloadAction) => {
      const { orgLevel, context }: { orgLevel: OrgLevel, context: OrganizationTreeContext } = action.payload;
      let actionType: string = '';
      actionType = OrgLevelActions.SELECT_ORG_LEVEL_SUCCESS;
      return of({
        type: actionType,
        payload: {
          context: context,
          orgLevel: orgLevel
        }
      });
    }));

  public deselectOrgLevel = action$ => action$.pipe(
    filter(({ type }: IPayloadAction) => type === SessionActions.USER_LOGGED_IN),
    mergeMap((action: IPayloadAction) => {
      this.orgLevelWatchService.resetOrgLevel();
      this.cacheUtilService.clear();
      return of({
        type: OrgLevelActions.DESELECT_ORG_LEVEL
      });
    }));

/*   public selectOrgLevel = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    return action$.pipe(filter(({ type }: IPayloadAction) => type === OrgLevelActions.SELECT_ORG_LEVEL))
      .pipe(mergeMap((action: IPayloadAction) => {
        const { orgLevel, context }: { orgLevel: OrgLevel, context: OrganizationTreeContext } = action.payload;
        let actionType: string = '';
        actionType = OrgLevelActions.SELECT_ORG_LEVEL_SUCCESS;
        return of({
          type: actionType,
          payload: {
            context: context,
            orgLevel: orgLevel
          }
        });
      }));
  } */

/*   public deselectOrgLevel = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    return action$.pipe(filter(({ type }: IPayloadAction) => type === SessionActions.USER_LOGGED_IN))
      .pipe(mergeMap((action: IPayloadAction) => {
        this.orgLevelWatchService.resetOrgLevel();
        this.cacheUtilService.clear();
        return of({
          type: OrgLevelActions.DESELECT_ORG_LEVEL
        });
      }));
  } */
}

import { Component, Input } from '@angular/core';
import { FiveStarRatingOrganization } from '../../models/index';
import { StaffingPredictorManagementService } from '../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'slx-fivestar-predictor-rating-badge',
  templateUrl: './fivestar-predictor-rating-badge.component.html',
  styleUrls: ['./fivestar-predictor-rating-badge.component.scss']
})
export class FivestarPredictorRatingBadgeComponent {

  @Input()
  public model: FiveStarRatingOrganization;

  @Input()
  public hasRightErrors: boolean = false;

  @Input()
  public showDetails: boolean = true;

  @unsubscribe()
  private hideSubscription: Subscription;

  public hideToPrint: boolean;

  constructor(private management: StaffingPredictorManagementService) { }

  public ngOnInit(): void {
    this.hideSubscription = this.management.hideOnPrint$.subscribe((hide: boolean) => {
      this.hideToPrint = hide;
    });
  }

  public onShowDetails(): void {
    this.management.showDetailsFiveStar();
  }

  public checkForCondMargin(model, val): boolean {
    if (((model.totalNursingAvgDailyHours >= 100 || model.rNAvgDailyHours >= 100 || model.totalNursingAvgDailyHoursWeekEnd >=100) && val < 100) || 
    ((model.totalNursingAvgDailyHours >= 1000 || model.rNAvgDailyHours >= 1000 || model.totalNursingAvgDailyHoursWeekEnd >=1000) && val < 1000)) {
      return true;
    }
    return false;
  }

}

import { LookupService } from './../../../organization/services/lookup/lookup.service';
import { ConfirmDialogComponent, ConfirmOptions } from './../../../common/components/confirm-dialog/confirm-dialog.component';
import { IColumnSettings } from './../../../core/models/settings/column-settings';
import { ModalService } from './../../../common/services/modal/modal.service';
import { Subscription ,  Observable } from 'rxjs';
import { ShiftSwapInfo } from './../../models/shift-swap/shift-swap-info';
import { Injectable } from '@angular/core';
import { ShiftSwapApiService } from './shift-swap-api.service';
import { ShiftSwapStatus } from '../../models/shift-swap/shift-swap-status';
import { ShiftSwapType } from '../../models/shift-swap/shift-swap-type';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators';
import { OrgLevel } from '../../../state-model/models';
import { ShiftSwapApproveDenyPopupComponent } from '../../components/shift-swap/shift-swap-approve-deny-popup/shift-swap-approve-deny-popup.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ShiftSwapDetails } from '../../models/shift-swap/shift-swap-details';

@Injectable()
export class ShiftSwapManageService {
    public list: ShiftSwapInfo[];

    public startDate: Date = new Date();
    public endDate: Date = moment(new Date()).add(1, 'month').toDate();
    public orgLevelId: number = 1;
    public type = ShiftSwapType.All;
    public status = ShiftSwapStatus.Accepted;
    public statusList = _.values(ShiftSwapStatus) as ShiftSwapStatus[];
    public typeList = _.values(ShiftSwapType) as ShiftSwapType[];

    public isLoading: boolean = false;

    public displayedColumns: IColumnSettings[];
    public columns: IColumnSettings[];

    @mutableSelect(['orgLevel'])
    private orgLevel$: Observable<OrgLevel>;
    @unsubscribeInService()
    private orgLevelSubscription: Subscription;

    constructor(private apiService: ShiftSwapApiService, private modalService: ModalService) {}

    public init(): void {
      this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
        this.orgLevelId = orgLevel.id;
        this.refreshList();
      });

      this.createColumns();
    }

    public destroy(): void {}

    public changeDateRange(sDate: Date, eDate: Date): void {
      if (
        this.startDate.toLocaleString() === sDate.toLocaleString()
        && this.endDate.toLocaleString() === eDate.toLocaleString()
      ) return;

      this.startDate = sDate;
      this.endDate = eDate;
      this.refreshList();
    }

    public changeStatus(status: ShiftSwapStatus): void {
      if (this.status === status) return;

      this.status = status;
      this.refreshList();
    }

    public changeType(type: ShiftSwapType): void {
      if (this.type === type) return;

      this.type = type;
      this.refreshList();
    }

    public refreshList(): void {
        this.isLoading = true;
        this.apiService.getList(this.orgLevelId, this.startDate, this.endDate, this.type, this.status)
        .then((list: ShiftSwapInfo[]) => {
            this.list = list;
            this.isLoading = false;
        });
    }

    public showDetails(shiftSwap: ShiftSwapInfo): void {
        ShiftSwapApproveDenyPopupComponent.openDialog(shiftSwap, this.modalService, (needUpdate: boolean) => {
           if (needUpdate) {
                this.refreshList();
           }
        });
    }

    public approve(shiftSwap: ShiftSwapInfo, comment: string = null): Promise<any> {
        if (this.isLoading) {
            return Promise.reject();
        }
        if (shiftSwap.status !== ShiftSwapStatus.Accepted) {
            return new Promise((resolve) => {
                let options: ConfirmOptions = new ConfirmOptions();
                options.showCancel = true;
                options.showOK = true;
                ConfirmDialogComponent.openDialog('Shift Swap Approve',
                    `Employee ${shiftSwap.submittedTo.name} is yet to accept this shift swap request. Are you sure you want to approve?`,
                    this.modalService, (result: boolean) => {
                        if (result) {
                            resolve(this.approveShiftSwap(shiftSwap, comment));
                        }
                    }, options);
            });
        } else {
            return this.approveShiftSwap(shiftSwap, comment);
        }
    }

    public deny(shiftSwap: ShiftSwapInfo, comment: string = null): Promise<any> {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        return this.apiService.deny(this.orgLevelId, shiftSwap.shiftSwapId, comment)
            .then((value: any) => {
                this.refreshList();
            })
            .catch((reason: any) => {
                this.isLoading = false;
            });
    }

    public loadDetails(shiftSwap: ShiftSwapInfo): Promise<ShiftSwapDetails> {
        return this.apiService.getDetails(this.orgLevelId, shiftSwap.shiftSwapId).then((details: ShiftSwapDetails) => {
            return details;
        });
    }

    public recalculateColumns(): void {
        this.displayedColumns = _.filter(this.columns, c => c.displayed);
    }

    private approveShiftSwap(shiftSwap: ShiftSwapInfo, comment: string = null): Promise<any> {
        this.isLoading = true;
        return this.apiService.approve(this.orgLevelId, shiftSwap.shiftSwapId, comment)
            .then((value: any) => {
                this.refreshList();
            })
            .catch((reason: any) => {
                this.isLoading = false;
            });
    }

    private createColumns(): void {
        this.columns = [
            { name: 'dateHired', description: 'Date of Hire', displayed: true },
            { name: 'unionStatus', description: 'Union Status', displayed: true },
            { name: 'projectedHours', description: 'Projected Hours', displayed: true },
            { name: 'projectedForOvertime', description: 'Projected for Overtime', displayed: true },
            { name: 'type', description: 'Emp Type', displayed: true },
            { name: 'position', description: 'Position', displayed: true },
            { name: 'department', description: 'Department', displayed: true },
        ];
        this.recalculateColumns();
    }
}

 <div class="row tog-btn">
    <slx-checkbox-toggle
    class="active-only-field tog"
    className="toggle-right"
    [(ngModel)]="isActiveOnly"
    (ngModelChange)="onDisplaySettingsChanged($event)"
    caption="Display All"
    checkedText="Yes"
    uncheckedText="No"
  ></slx-checkbox-toggle>
  <button class="theme-button-apply view" (click)="exportToExcel(grid)">Download to Excel</button>
</div>
<kendo-grid #grid="kendoGrid" [data]="dataView" [kendoGridBinding]="dataView" [pageable]="true" filterable="menu"
    [sortable]="{ mode: 'single' }" [pageSize]="50" class="grid slx-blue-grid"
    >
    <kendo-grid-column field="syncId" title="Transaction ID" filterable="false"></kendo-grid-column>
    <kendo-grid-column field="startDate" title="Created Date">
        <ng-template kendoGridHeaderTemplate>
            Created Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.startDate | amDateFormat: appConfig.dateTimeFormatUS }}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="status" title="Status"></kendo-grid-column>
    <kendo-grid-column field="employeeId" title="Payroll Number"></kendo-grid-column>
    <kendo-grid-column field="employeeName" title="Employee Name"></kendo-grid-column>
    <kendo-grid-column field="errorMessage" title="Message"></kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate>
        <p>There is no data to display</p>
    </ng-template>
    <kendo-grid-excel fileName="EmployeeSyncReport.xlsx" [fetchData]="allData"></kendo-grid-excel>
    <kendo-grid-messages filterMenuTitle="Filter">
    </kendo-grid-messages>  
</kendo-grid>


import { PayrollExportTestStateStatusLabels } from './../../../models/payroll-export-test/payroll-export-test-state-statuses';
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { process, State } from '@progress/kendo-data-query';

import { unsubscribe } from '../../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { PayrollExportGroup, PayrollExportFacility } from '../../../models/index';
import { KendoGridStateHelper, ScrollWatchEvent } from '../../../../../common/models/index';
import { AppServerConfig } from './../../../../../app-settings/model/app-server-config';
import { PayrollExportTestState } from '../../../models/payroll-export-test/payroll-export-test-state';
import { AppSettingsManageService } from './../../../../../../app/app-settings/services';

@Component({
    moduleId: module.id,
    selector: 'slx-payroll-export-test-grid',
    templateUrl: 'payroll-export-test-grid.component.html',
    styleUrls: ['payroll-export-test-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayrollExportTestGridComponent implements OnInit, OnDestroy, OnChanges {

   
    @Input()
    public tests: PayrollExportTestState[];
    public appConfig: IApplicationConfig;
    @Input()
    public isAccrualModuleEnabled;
    @Output()
    public testClicked: EventEmitter<PayrollExportTestState> = new EventEmitter();
    public gridState: KendoGridStateHelper<PayrollExportTestState>;
    @unsubscribe()
    private gridRefreshSubscription: Subscription;
    public NegativeAccrualExportTest: boolean;
   
   
  constructor(private changeDetector: ChangeDetectorRef, public appSettingsManageService: AppSettingsManageService) {
        this.gridState = new KendoGridStateHelper<PayrollExportTestState>();
    }


    public ngOnInit(): void {
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
        this.refreshGrid();
        this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
        });
    }
  
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['tests']) {
        this.refreshGrid();
        }
    }

    public ngOnDestroy(): void {
        // See #issueWithAOTCompiler
    }

    public getClassColor(status: PayrollExportTestStateStatusLabels): string {
        switch(status) {
            case PayrollExportTestStateStatusLabels.PASS:
            return 'pets-pass';
            case PayrollExportTestStateStatusLabels.FAIL:
            return 'pets-fail';
            case PayrollExportTestStateStatusLabels.ALERT:
            return 'pets-alert';
        }
        return '';
    }

    public onTestClicked(test: PayrollExportTestState): void {
    this.testClicked.emit(test);
    }

    public async isLinkEnabled(testId: number) {
    let isLink = true;    
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.NegativeAccrualExportTest =  config.NegativeAccrualExportTest;
      if (testId === 8) {
        isLink = !this.isAccrualModuleEnabled || this.NegativeAccrualExportTest ? true : false;
      }
    return isLink;

    }


    private refreshGrid(): void {
        if (!this.tests) {
            this.gridState.view = null;
        return;
        }
        this.gridState.view = process(this.tests, this.gridState.state);
    }
}

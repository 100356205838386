
import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { UrlSegment } from '@angular/router';
import * as moment from 'moment';

import { Observable ,  ReplaySubject ,  Subject ,  Subscription } from 'rxjs';

import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { ManagementBaseService } from '../../../core/services/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { DateRange, IDateRange } from '../../../core/models/index';

import { StateManagementService, ComponentStateStorageService, ModalService } from '../../../common/services/index';
import { StateResetTypes, IControlState } from '../../../core/models/index';

import { PmReviewRecord, PmReviewRecords, PMDialogOptions } from '../models/index';
import { PmManagementService } from './pm-management.service';
import { PerformanceManagementApiService } from './performance-management-api.service';

@Injectable()
export class PmRosterManagementService {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;
  private dateRange: DateRange;

  private loading$ = new Subject<boolean>();
  private recordsLoaded$ = new Subject<PmReviewRecord[]>();
  private dateRange$ = new Subject<DateRange>();
  private exportTo$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private createNewReview$ = new Subject<boolean>();

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private manService: PmManagementService,
    private apiService: PerformanceManagementApiService
  ) {}

  public init(): void {
    this.subscribeToOrgLevelChanges();
    this.subscribeToLoadReviews();
  }

  public destroy(): void {
    this.orgLevel = null;

    this.loading$.complete();
    this.recordsLoaded$.complete();
    this.dateRange$.complete();
    this.exportTo$.complete();
    this.orgLevelChanged$.complete();
    this.createNewReview$.complete();
  }

  public getDefaultDateRange(): IDateRange {
    const d = new Date();

    return new DateRange(new Date(d.getFullYear() - 1, d.getMonth(), d.getDate() + 1), d);
  }

  public subscribeToCanCreateNewReview(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.createNewReview$.subscribe(callback);
  }

  public openReviewPopup(review: PmReviewRecord): void {
    const title = `${_.isObjectLike(review) ? 'Edit' : 'Create New'} Review`;
    this.manService.openReviewPopup(title, review);
  }

  public changeDateRange(r: IDateRange): void {
    this.dateRange = r;
    this.dateRange$.next(r);
    this.loadReviews();
  }

  public subscribeToDateRange(callback: (r: DateRange) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.dateRange$.subscribe(callback);
  }

  public exportTo(isPDF: boolean): void {
    this.exportTo$.next(isPDF);
  }

  public subscribeToExport(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.exportTo$.subscribe(callback);
  }

  public subscribeToLoadedRecords(callback: (b: PmReviewRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.recordsLoaded$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public loadReviews(): void {
    if (
      !_.isFinite(_.get(this.orgLevel, 'id'))
      || !_.isDate(_.get(this.dateRange, 'startDate'))
      || !_.isDate(_.get(this.dateRange, 'endDate'))
    ) return;

    this.loading$.next(true);
    this.apiService.getReviewRecords(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate)
      .then((records: PmReviewRecords<PmReviewRecord>) => {
        this.createNewReview$.next(records.canCreateNewReview);
        this.recordsLoaded$.next(records.records);
        this.loading$.next(false);
      })
      .catch (() => {
        this.loading$.next(false);
      });
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$.pipe(
      filter((o: OrgLevel) => o && _.isFinite(o.id)))
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
        this.loadReviews();
      });
  }

  private subscribeToLoadReviews(): void {
    this.subscriptions.loadReviews = this.manService.subscribeToLoadReviews(() => this.loadReviews());
  }
}

import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ErrorHandlingService } from '../services/index';
import { appMessages } from '../../app.messages';
import { HandledError } from '../models/errors/handled-error';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public constructor(@Inject(ErrorHandlingService) private errorHandlingService: ErrorHandlingService) {
  }

  public handleError(error: any): void {
    // do something with the exception
    if (error instanceof HandledError) {
      return;
    }
    this.errorHandlingService.fault(appMessages.error.general, error);
  }
}

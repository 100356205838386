import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { Subject ,  Observable ,  Subscription } from 'rxjs';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ChangeManagementService } from '../../../common/services/index';
import { ManagementBaseService } from '../../../core/services/index';

import { RoleDefinition } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupApiService } from '../../../organization/services/index';
import { RolesApiService } from './roles-api.service';


@Injectable()
export class RolesDefinitionManagementService extends ManagementBaseService<RoleDefinition[], any> {

  public selectRecord$: Subject<RoleDefinition>;
  public addCmd$: Subject<any>;
  public clearChanges$: Subject<any>;
  public cloneCmd$: Subject<RoleDefinition>;
  public onRoleSaved$: Subject<{ savedRole: RoleDefinition, records: RoleDefinition[], index: number }>;
  public records: RoleDefinition[];
  private apiService: RolesApiService;
  private lookupApiService: LookupApiService;

  constructor(apiService: RolesApiService, lookupApiService: LookupApiService, public changeService: ChangeManagementService) {
    super();
    this.selectRecord$ = new Subject<RoleDefinition>();
    this.addCmd$ = new Subject<any>();
    this.clearChanges$ = new Subject<any>();
    this.cloneCmd$ = new Subject<RoleDefinition>();
    this.onRoleSaved$ = new Subject<{ savedRole: RoleDefinition, records: RoleDefinition[], index: number }>();
    this.apiService = apiService;
    this.lookupApiService = lookupApiService;
    this.loadRoles();
  }

  public onSelectRecord(role: RoleDefinition): void {
    this.selectRecord$.next(role);
  }

  public loadRoles(): void {
    this.onLoadStatusChanged(true);
    this.apiService.getRoles()
      .then((records: RoleDefinition[]) => {
        this.records = records;
        this.onLoaded(records);
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
        this.clearChanges$.next (false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
        this.clearChanges$.next (false);
      });
  }
  public onSaveRecord(role: RoleDefinition): void {
    this.onLoadStatusChanged(true);
    this.apiService.saveRole(role)
      .then((savedRole: RoleDefinition) => {
        let index: number = _.findIndex(this.records, { id: role.id });
        if (index !== -1) {
          this.records[index] = savedRole;
          this.onRoleSaved$.next({ savedRole: savedRole, records: this.records, index: index });
        }
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
        this.clearChanges$.next (false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
        this.clearChanges$.next (false);
      });
  }

  public onRemoveRecord(role: RoleDefinition): void {
    this.onLoadStatusChanged(true);
    this.apiService.deleteRole(role.id)
      .then((res: any) => {
        this.records = _.filter(this.records, (r: RoleDefinition) => r.id !== role.id);
        this.onLoaded(this.records);
        this.onLoadStatusChanged(false);
        this.clearChanges$.next (false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
        this.clearChanges$.next (false);
      });
  }

  public onCloneRecord(srcRole: RoleDefinition, role: RoleDefinition): void {
    this.onLoadStatusChanged(true);
    this.apiService.cloneRole(role, srcRole.id)
      .then((res: any) => {
        this.loadRoles();
        this.changeService.clearChanges();
        this.clearChanges$.next (false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
        this.clearChanges$.next (false);
      });
  }

  public onAddRecord(role: RoleDefinition): void {
    this.onLoadStatusChanged(true);
    this.apiService.addRole(role)
      .then((res: any) => {
        this.loadRoles();
        this.changeService.clearChanges();
        this.clearChanges$.next (false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
        this.clearChanges$.next (false);
      });
  }

  public onEditItem(item: any): void {
    this.changeService.changeNotify();
    this.changeNotify$.next ();
  }

  public onCancelEdit(item: any): void {
    this.changeService.clearChanges();
    this.clearChanges$.next (false);
  }

  public addCmd(): void {
    this.changeService.changeNotify();
    this.changeNotify$.next ();
    this.addCmd$.next(null);
  }

  public cloneCmd(role: RoleDefinition): void {
    this.changeService.changeNotify();
    this.changeNotify$.next ();
    this.cloneCmd$.next(role);
  }
}

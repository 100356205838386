<slx-spinner [show]="loader"></slx-spinner>
<div *ngFor="let partner of agencyAttributesList">
    <kendo-panelbar [class]="partner.name" (stateChange)="onPanelChange(partner)">         
        <kendo-panelbar-item title="" [expanded]="!partner.partnerExpanded" >
          <ng-template kendoPanelBarItemTitle>
            <span class="expand-panel-icon" ><em [className]="!partner.partnerExpanded ? 'fa fa-minus' : 'fa fa-plus'"></em></span>
            <span>{{partner.name}}</span>
          </ng-template>           
          <ng-template kendoPanelBarContent>
            <button *ngIf="partner.shiftStatus === 4" class="error-icon" [slxTooltip]="'Request Failed from Agency.'" tipPosition="right"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></button>
              <div class="w-100" *ngFor="let input of partner.partner_attributes; let itemIndex=index;">
               <span>{{input.name}}</span>
                <div [ngSwitch]="input.controlType" class="padt5 attributes">
                  <div *ngSwitchCase="'textbox'" >
                    <div *ngIf="isIncentiveDetails(partner.partnerId)">
                      <label slx-label>{{getDisplayName(partner.attributes[itemIndex].name)}}</label>
                        <input slx-input type="text"
                        class="attr-textbox"
                          [readonly]="true"
                          [name]="input.label"
                          *ngIf="!serval"
                          [placeholder]="input.label"
                          [(ngModel)]="partner.attributes[itemIndex].value">
                  
                      </div>
                     </div>
                 
                  <div *ngSwitchCase="'checkbox'" >
                      <span *ngIf="verifyCommonAttributes(partner.attributes[itemIndex].name)">
                        <label>{{getDisplayName(partner.attributes[itemIndex].name)}} </label>                     
                        <div *ngFor="let checkboxItem of partner.attributes[itemIndex].attrValues ; let checkBoxIndex=index;">
                         
                          <span >
                            <slx-radio-button class="si-checkbox"
                            [readonly]="true"
                            [caption]="checkboxItem.name"
                            [(ngModel)]="partner.attributes[itemIndex].attrValues[checkBoxIndex].value"
                            [name]="checkboxItem.name" >
                            </slx-radio-button>
                          </span> 
                        </div>
                      </span>
                      <span *ngIf="!verifyCommonAttributes(partner.attributes[itemIndex].name)"> 
                        <div *ngFor="let checkboxItem of partner.attributes[itemIndex].attrValues ; let checkBoxIndex=index;">
                          <slx-checkbox-button class="si-checkbox"
                              [readonly]="true"
                              [(ngModel)]="partner.attributes[itemIndex].attrValues[checkBoxIndex].value"
                              [placeholder]= "checkboxItem.name"
                              name="checkboxItem.name"
                              >
                        </slx-checkbox-button>
                      
                      </div> 
                      </span>
                     
                  </div>
                  <div *ngSwitchCase="'dropdown'" >
                    <div *ngIf="isIncentiveDetails(partner.partnerId)">
                      <label>{{getDisplayName(partner.attributes[itemIndex].name)}} </label>   
                      <slx-dropdown-input slx-input
                        [readonly]="true"
                        [options]="input.options"
                        name="{{input.label}}" 
                        [(ngModel)]="partner.attributes[itemIndex].attrValues[0]"
                        (ngModelChange)="changeDropdownValue($event)">
                      </slx-dropdown-input>                    
                  </div>
                 </div>
                 </div>
              </div>
            </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
</div>


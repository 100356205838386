import { Provider } from '@angular/core';

export * from './authentication/authentication.service';
export * from './authentication-api/authentication.api-service';
export * from './authentication-api/authentication.map-service';
export * from './authentication-guard/authentication.guard';

export * from './client-message/client-message-api.service';
export * from './client-message/client-message-map.service';
export * from './client-message/client-message-management.service';

export * from './password-reset/password-reset-management.service';
export * from './password-reset/password-validator-adapter';
export * from './sso-guard/sso.guard';

import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationApiService } from './authentication-api/authentication.api-service';
import { AuthenticationMapService } from './authentication-api/authentication.map-service';
import { AuthenticationGuard } from './authentication-guard/authentication.guard';

import { ClientMessageApiService } from './client-message/client-message-api.service';
import { ClientMessageMapService } from './client-message/client-message-map.service';
import { ClientMessageManagementService } from './client-message/client-message-management.service';
import { UserExtendedSettingsManagementService } from './user-extended-settings/user-extended-settings-management.service';
import { UserExtendedSettingsApiService } from './user-extended-settings/user-extended-settings-api.service'
import { UserExtendedSettingsMapService } from './user-extended-settings/user-extended-settings-map.service'
import { PasswordResetManagementService } from './password-reset/password-reset-management.service';
import { PasswordValidatorAdapter } from './password-reset/password-validator-adapter';
import { SsoGuard } from './sso-guard/sso.guard';

export const services: Provider[] = [
  AuthenticationService,
  AuthenticationApiService,
  AuthenticationMapService,
  AuthenticationGuard,
  ClientMessageApiService,
  ClientMessageMapService,
  ClientMessageManagementService,
  UserExtendedSettingsMapService,
  UserExtendedSettingsApiService,
  UserExtendedSettingsManagementService,
  PasswordResetManagementService,
  PasswordValidatorAdapter,
  SsoGuard
];

<slx-spinner [show]="isLoadingBenefitType">
    <div class="slx-high-box workers-compensation-configuration">

      <div class="slx-high-box__body">
          <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
          [data]="gridState.view"
          [filterable]="'menu'"
          [filter]="gridState.state.filter"
          [skip]="gridState.state.skip"
          [sortable]="true"
          [sort]="gridState.state.sort"
          [pageable]="isPageable"
          [pageSize]="pageSize"
          [skip]="gridState.state.skip"
          [height]="400"
          (dataStateChange)="gridState.dataStateChange($event)"

          [slxKendoGridFiltersState]="gridState"
          >

            <kendo-grid-column title="Plan" width="170" field="name"
               [sortable]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Plan</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a (click)="onClickBenefit(dataItem)"><strong>{{ dataItem.name }}</strong></a>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Enrolled" width="100" filter="numeric"
               field="totalEnrolledEmployees" [sortable]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Enrolled</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.totalEnrolledEmployees }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Start Date" width="125" field="startOfStartDate" filter="date"
              [sortable]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Start Date</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.startOfStartDate | amDateFormat: appConfig.dateFormat  }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Expiration Date" width="150" field="startOfEndDate" filter="date"
              [sortable]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Expiration Date</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.startOfEndDate | amDateFormat: appConfig.dateFormat  }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Org Level" width="200" field="orgLevelName"
               [sortable]="true" filter="text">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Org Level</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.orgLevelName }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-messages filterMenuTitle="Filter">
            </kendo-grid-messages>  
          </kendo-grid>

      </div>
    </div>
  </slx-spinner>

import { Provider } from '@angular/core';
import { ReportsApiService } from './reports-api.service';
import { ReportsMapService } from './reports-map.service';
import { ReportsManagementService } from './reports-management.service';
import { W2sManagementService } from './w2s/w2s-management.service';
import { W2sApiService } from './w2s/w2s-api.service';
import { W2sMapService } from './w2s/w2s-map.service';


export { ReportsManagementService } from './reports-management.service';

export const services: Provider[] = [
  ReportsApiService, ReportsMapService, ReportsManagementService,W2sManagementService,W2sApiService,W2sMapService
];

import * as _ from 'lodash';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { appConfig, IApplicationConfig } from '../../../../../app.config';

import { unsubscribeAll } from '../../../../../core/decorators/index';

import { IDateRange, DateRange } from '../../../../../core/models/index';
import { EmployeeSectionsBenefitsManagementService } from '../../../services/index';
import { BenefitTerminationReason, BenefitDependentDroppedEnrollment } from '../../../../../app-modules/benefits/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-dependent-drop-enrollment',
  templateUrl: 'employee-dependent-drop-enrollment.component.html',
  styleUrls: ['employee-dependent-drop-enrollment.component.scss'],
})
export class EmployeeDependentDropEnrollmentComponent implements OnInit, OnDestroy {
  @Input()
  public set options(v: IDateRange) {
    if (_.isObjectLike(v)) {
      this.restrictions = v;
      this.coverageDate = new Date(v.endDate.getTime());
      this.deendentEnrollment.coverageEndDate = this.coverageDate;
    }
  }

  @Output()
  public dropEnrollment = new EventEmitter<BenefitDependentDroppedEnrollment>();

  public terminationReasons: BenefitTerminationReason[] = [];
  public restrictions: IDateRange;
  public terminationReason: BenefitTerminationReason = null;
  public eventDate: Date = new Date();
  public coverageDate: Date = null;
  public isLoading: boolean = true;
  public appConfig: IApplicationConfig;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private deendentEnrollment: BenefitDependentDroppedEnrollment;

  constructor(
    private manService: EmployeeSectionsBenefitsManagementService
  ) {
    this.appConfig = appConfig;
    this.deendentEnrollment = new BenefitDependentDroppedEnrollment(null, this.eventDate, this.coverageDate, this.terminationReason);
  }

  public ngOnInit(): void {
    this.loadTerminationReasons();
  }

  public ngOnDestroy(): void {}

  public onChangedTermReason(reason: BenefitTerminationReason): void {
    this.deendentEnrollment.reason = reason;
    this.emitDropEnrollment();
  }

  public onChangedEventDate(date: Date): void {
    this.deendentEnrollment.eventDate = date;
    this.emitDropEnrollment();
  }

  public onChangedCovDate(date: Date): void {
    this.deendentEnrollment.coverageEndDate = date;
    this.emitDropEnrollment();
  }

  public loadTerminationReasons(): void {
    this.isLoading = true;
    this.manService.getBenefitTerminationReasons()
      .then((reasons) => {
        this.terminationReasons = reasons;
        this.isLoading = false;
      });
  }

  private emitDropEnrollment(): void {
    this.dropEnrollment.emit(_.cloneDeep(this.deendentEnrollment));
  }
}

import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/index';
import * as _ from 'lodash';
import { Actions } from '../../../../core/models/index';
import { EditableListComponent } from '../../../../configuration/components/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { TimeCardModel, EarningStatistic, DailyTimecardChangeStatus, TimeCardDisplayOptions } from '../../../models/index';
import { DailyTimecardManagementService, editAction, resetAction } from '../../../services/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { process, State } from '@progress/kendo-data-query';
import { RowClassArgs } from '@progress/kendo-angular-grid';

@Component({
  moduleId: module.id,
  selector: 'slx-timecard-summary-list',
  templateUrl: 'summary-list.component.html',
  styleUrls: ['summary-list.component.scss']
})
export class SummaryListComponent implements OnInit, OnDestroy {

  @Input()
  public set model(value: TimeCardModel) {
    if (!value) {
      return;
    }
    this.employeeId = value.employee.id;
    this.shiftDiffBasedOnTime = value.shiftDiffBasedOnTime;
    this.records = value.earningStatistics;
    if (value.recalculated) {
      this.afterRecalculatedMode = true;
      setTimeout(() => {
        this.afterRecalculatedMode = false;
        this.refreshGrid();
      }, appConfig.showChangesTimeoutMs);
    }
    this.refreshGrid();
  }
  public employeeId: number;
  public records: EarningStatistic[];
  public afterRecalculatedMode: boolean;
  public displayOptions: TimeCardDisplayOptions;
  public shiftDiffBasedOnTime: boolean;

  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<EarningStatistic>;

  @unsubscribe()
  public containerSubscription: Subscription;
  @unsubscribe()
  public modelSubscription: Subscription;
  @unsubscribe()
  public optionsChangedSubscription: Subscription;

  public rowCallback: (context: RowClassArgs) => any;

  constructor(private managementService: DailyTimecardManagementService) {

    this.gridState = new KendoGridStateHelper<EarningStatistic>();

    this.optionsChangedSubscription = this.managementService.displayOptionSelected$
    .subscribe((options: TimeCardDisplayOptions) => {
      this.displayOptions = options;
    });

    this.rowCallback = (context: RowClassArgs) => {
      const item: EarningStatistic = context.dataItem;
      switch (item.changeStatus) {
        case DailyTimecardChangeStatus.deleted:
          return { 'slx-deleted-row': true };
        case DailyTimecardChangeStatus.added:
          return { 'slx-new-row': true };
        case DailyTimecardChangeStatus.changed:
          return { 'slx-changed-row': true };
        default:
          return {};
      }
    };
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }


  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    if (!this.afterRecalculatedMode) {
      this.records = _.filter(this.records, (r: EarningStatistic) => {
        return r.changeStatus !== DailyTimecardChangeStatus.deleted;
      });
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

}

import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';

import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';

import { GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

import { unsubscribeAll } from '../../../../../core/decorators/index';
import { StateResetTypes } from '../../../../../core/models/settings/reset-types';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { DeviceDetectorService, EmployeeSectionNavigationService } from '../../../../../common/services/index';
import { OrgLevel } from '../../../../../state-model/models/index';

import { PmRosterManagementService } from '../../../services/index';

import { PmReviewRecord } from '../../../models/index';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';

import { PerformanceManNavigationService } from '../../../../../common/services/index';


@Component({
  selector: 'slx-pm-roster-grid',
  templateUrl: './pm-roster-grid.component.html',
  styleUrls: ['./pm-roster-grid.component.scss']
})
export class PmRosterGridComponent implements OnInit, OnDestroy {
  public records: PmReviewRecord[];
  public gridState: KendoGridStateHelper<PmReviewRecord[]>;
  public appConfig: IApplicationConfig;
  public orgLevel: OrgLevel;
  public pageSize: number = 50;
  public canEdit: boolean;
  public xlsxName = 'Performance_Roster_';
  public pdfName = 'Performance_Roster_';
  public columnsGroupName: string = 'PerformanceManagementRoster';

  public get isLockedColumn(): boolean {
    return this.devDetector.isDesktop;
  }

  public get isReordable(): boolean {
    return this.devDetector.isDesktop;
  }

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor (private pmManagementService: PmRosterManagementService, private devDetector: DeviceDetectorService, private router: Router, private route: ActivatedRoute) {
    this.gridState = new KendoGridStateHelper<PmReviewRecord[]>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.gridComponentKey = 'PMGridState';
    this.gridState.gridControlStateKey = 'GridFiltersState';
    this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
    this.gridState.refreshGridAfterRestoreState = true;
    this.gridState.assignRestoredState = true;
    this.gridState.state.sort = [
      { field: 'employee.name', dir: 'asc' },
      { field: 'reviewDate', dir: 'asc' }
    ];
    this.appConfig = appConfig;


  }

  public ngOnInit() {
    const date = new Date().toLocaleDateString();
    this.xlsxName += `${date}.xlsx`;
    this.pdfName += `${date}.pdf`;

    this.subscriptions.onLoaded = this.pmManagementService
      .subscribeToLoadedRecords((r: PmReviewRecord[]) => this.assignRecords(r));

    this.subscriptions.export = this.pmManagementService
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());
  }

  public ngOnDestroy(): void {}

  public onOpenReport(entry: PmReviewRecord): void {
    this.pmManagementService.openReviewPopup(entry);
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }

  public onEmpNameClick (empId: number): void {
    let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
    const urlTree = navService.getUrlTreeFromRoot(empId, true);
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigateByUrl(urlTree, extras);
  }

  public onEmpIconClick(empId: number):void{
    let pmNavService = new PerformanceManNavigationService(this.router, this.route);
    pmNavService.navigateToReviews(empId);
  }

  private assignRecords(records: PmReviewRecord[]): void {
    this.records = records;
    this.refreshGrid();
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}

import * as _ from 'lodash';
import { Component, Input, ViewEncapsulation, OnInit, EventEmitter, Output } from '@angular/core';
import { StyledUserApplication } from '../../../../organization/models/index';
import { SoDashboardCounters, TaDashboardCounters } from '../../../models/index';
import { DashboardSOAlertEnum, AlertConfig } from '../../../../portal/models/index';
import * as moment from 'moment';
import { appConfig } from './../../../../app.config';
import { ApplicationDashboardManagementService } from '../../../services/index';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-so-dashboard',
  templateUrl: 'so-dashboard.component.html',
})

export class SoDashboardComponent implements OnInit {
  @Input()
  public application: StyledUserApplication;
  
  @Input()
  public progressbar: boolean;

  @Output()
  public onLoading = new EventEmitter<boolean>();

  public links: StringMap<string>;
  public datesParams: any;
  public alertConfigOrderAndVisibilty: AlertConfig[];
  private counters: SoDashboardCounters;

  @unsubscribe()
  private countersSubscription: Subscription;

  constructor(private appDashboardMngtService: ApplicationDashboardManagementService) {
    this.links = {
      openShiftRequest: 'so_console',
      employeeProjectedOvertime: 'so_console',
      ptoRequest: 'leave_requests'
    };

    this.datesParams = {
      startDate: moment().startOf('year').format(appConfig.linkDateFormat),
      endDate: moment().endOf('year').format(appConfig.linkDateFormat)
    };

    this.onLoading.emit(true);
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngOnInit(): void {
    this.countersSubscription = this.appDashboardMngtService
      .subscribeToCountersLoaded((counters: StringMap<SoDashboardCounters>) => {
        this.mapCounters(counters['Scheduler']);
        this.setCounter();
      });
  }

  public mapCounters(counters: SoDashboardCounters) {
    this.counters = _.isObject(counters) && _.size(counters) > 0 ? counters : new SoDashboardCounters();
    this.loadAlerts();
  }

  public loadAlerts(): void {
    this.appDashboardMngtService.getDashboardAppAlerts(this.application.id)
      .then(data => {
        this.alertConfigOrderAndVisibilty = _.filter(data, (t) => t.visible);
        this.setCounter();
        this.onLoading.emit(false);
      });
  }

  public onHasChanges(hasChanges: boolean): void {
    if (hasChanges) {
      this.loadAlerts();
    }
  }

  public getRouterLink(path: string): string[] {
    return [this.application.link, path];
  }

  public setCounter() {
    let appAlerts: AlertConfig[] = this.alertConfigOrderAndVisibilty;
    _.forEach(appAlerts, (record: AlertConfig) => {
      switch (record.alertId) {
        case DashboardSOAlertEnum.openShiftReq:
          record.counter = this.counters.openShiftRequest;
          break;
        case DashboardSOAlertEnum.empProjOvertime:
          record.counter = this.counters.employeeProjectedOvertime;
          break;
        case DashboardSOAlertEnum.ptoReq:
          record.counter = this.counters.ptoRequest;
          break;
      }
    });
  }

}

import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { moment } from '../../../../common';
import { ConfirmDialogComponent, ConfirmOptions, DialogOptions, ModalService } from '../../../../common/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { EMPLOYEES_ID_TOKEN, FILTERED_EMPLOYEES_TOKEN } from '../../../../core/models/index';
import { AccrualPolicy, DynamicAccrualPolicy } from '../../../../organization/models/accruals/index';
import { AccrualApiService, EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { employeeListConfig } from '../../employee-list.config';
import { Employee, EmployeeActionDefinition } from '../../models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { EmployeeGridComponent } from '../employee-grid/employee-grid.component';
import { ColumnManagementService } from './../../../../common/services/column-settings/column-management.service';
import { DynamicPolicyDialogComponent } from './dynamic-policy-dialog/dynamic-policy-dialog.component';
@Component({
  moduleId: module.id,
  selector: 'slx-assign-accruals-policy',
  templateUrl: 'assign-accruals-policy.component.html',
  styleUrls: ['assign-accruals-policy.component.scss'],
  providers: [ColumnManagementService]
})
export class AssignAccrualsPolicyComponent implements OnInit {
  @Output()
  public onCancel: EventEmitter<any>;
  @ViewChild(EmployeeGridComponent, { static: true })
  private employeeGrid: EmployeeGridComponent;
  @Output()
  public onComplete: EventEmitter<string>;
  public actionEmployees: Employee[];
  public employees: Employee[];
  public fieldDefinition: EmployeeActionDefinition;
  public orgLevel: OrgLevel;
  public allowDynamicPolicy: boolean = false;
  public filteredEmployee: Employee[] = [];
  public accrualPolicyLookup: AccrualPolicy[] = [];
  public state: {
    isLoading: boolean;
  };
  public dialogResult: boolean;
  public effectiveDate: Date;
  public effectiveDateSelected: boolean = false;
  public effectiveDateChanged: boolean = false;
  public canSend: boolean;
  public maxDate: Date;
  public anyPolicySelected: boolean = false;
  private policyIdList: string;
  private m_initialized: boolean = false;
  private policyNameList: string;
  private selectedEmployees: Employee[];
  private modalService: ModalService;
  private groupActivitiesService: GroupActivitiesApiService;
  private accrualApiService: AccrualApiService;
  private dynamicPolicyId = 0;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;
  constructor(groupActivitiesService: GroupActivitiesApiService,
    employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    modalService: ModalService,
    accrualApiService: AccrualApiService,
    fieldDefinition: EmployeeActionDefinition,
    orgLevel: OrgLevel,
    @Inject(EMPLOYEES_ID_TOKEN) employees: Employee[],
    @Inject(FILTERED_EMPLOYEES_TOKEN) filteredEmployee: Employee[]

  ) {
    this.groupActivitiesService = groupActivitiesService;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    this.modalService = modalService;
    this.accrualApiService = accrualApiService;
    this.fieldDefinition = fieldDefinition;
    this.orgLevel = orgLevel;
    this.employees = employees;
    this.onCancel = new EventEmitter<any>();
    this.onComplete = new EventEmitter<string>();
    this.state = {
      isLoading: false,
    };
    this.effectiveDate = null;
    this.filteredEmployee = filteredEmployee;
  }

  private checkAllowDynamicPolicy() {
    this.accrualApiService.getAllowDynamicAccrualPolicies()
      .then((allowDynamicPolicy: boolean) => {
        this.allowDynamicPolicy = allowDynamicPolicy;
      });
  }

  public onChangeEffectiveDate(effectiveDate: Date): void {
    this.effectiveDate = effectiveDate;
    this.effectiveDateSelected = true;
    this.effectiveDateChanged = true;
    this.validateEmployee(this.effectiveDate, this.actionEmployees);
    this.getPolicyList();
  }

  public getPolicyList() {
    if (this.effectiveDate) {
      this.accrualApiService.getAccrualsPoliciesRecords(this.orgLevel.id, this.effectiveDate)
        .then((accrualPolicy: AccrualPolicy[]) => {
          this.accrualPolicyLookup = accrualPolicy;
        });
    }
  }

  public openDynamicPolicyDialog(): void {
    this.state.isLoading = true;
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 450;
    dialogOptions.width = 350;
    let dialog: DynamicPolicyDialogComponent = this.modalService.globalAnchor.openDialog(
      DynamicPolicyDialogComponent,'',
      dialogOptions, undefined, (result: boolean) => {
        if (result) {
          this.state.isLoading = false;
          this.policyIdList = dialog.SelectedPolicyIds;
          this.policyNameList = dialog.SelectedPolicyNames;
          if (dialog.SelectedPolicyIds != '') {
            this.anyPolicySelected = true;
          }
        }
        else {
          this.state.isLoading = false;
        }
      });
    if (dialog && !dialog.isLoading && dialog.initialized) {
      dialog.accrualPolicyLookup = this.accrualPolicyLookup;
      dialog.dynamicAccrualPolicy = this.setDynamicAccrualPolicy();
      dialog.loadSubsection();
    }
  }

  private setDynamicAccrualPolicy() {
    let dAccrualPolicy: DynamicAccrualPolicy[] = [];
    if (!_.isNull(this.policyNameList) && !_.isEmpty(this.policyNameList) ) {
        let policyNameArr = this.policyNameList.split('-');
          for (var i = 0; i < policyNameArr.length; i++) {
            this.dynamicPolicyId++;
            let accrualPolicy: DynamicAccrualPolicy = new DynamicAccrualPolicy();
            accrualPolicy.id = this.dynamicPolicyId;
            accrualPolicy.policyName = policyNameArr[i].trim();
            accrualPolicy.isError = false;
            let acType = _.find(this.accrualPolicyLookup, ['policyName', policyNameArr[i].trim()]);
            accrualPolicy.accrualType = _.first(acType.rules).accrualTypeName;
            accrualPolicy.policyId = acType.id.toString();
            dAccrualPolicy.push(accrualPolicy);
          }
    }
    return dAccrualPolicy;
  }


  public ngOnInit(): void {
    this.m_initialized = true;
    this.maxDate = new Date();
    this.checkAllowDynamicPolicy();
    this.loadEmployeeData();
  }

  public loadEmployeeData() {
    this.state.isLoading = true;
    let employeeIds: string[] = _.map(this.filteredEmployee, (employee: Employee) => employee[employeeListConfig.employeeIdentifierName]);
    this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, true, employeeIds, this.fieldDefinition)
      .then((actionEmployees: Employee[]) => {
        if (this.effectiveDate) {
          this.validateEmployee(this.effectiveDate, actionEmployees);
        }
        else {
          this.actionEmployees = actionEmployees;
        }
      });
    this.state.isLoading = false;
  }

  public updateSelectedPolicy(item: AccrualPolicy): void {
    this.policyIdList = item.id.toString();
    this.anyPolicySelected = true;
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public employeesSelect(selectedEmployees: Employee[]): void {
    this.selectedEmployees = selectedEmployees;
    this.canSend = this.selectedEmployees && this.selectedEmployees.length > 0;
  }

  public confirmAssignPolicy() {
    let message = '';
    let count = this.selectedEmployees.length;
    message = count + ' employees will be affected with this change. A recalculation of their accruals will take a few moments. Do you want to continue?';
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    options.buttonCanceltext = 'No';
    ConfirmDialogComponent.openDialog(
      'Warning',
      message,
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.assignPolicy();
        }
      }, options);
  }

  public assignPolicy(): void {
    let accrualsPolicyAssignment: any = {
      empIdList: _.map(this.selectedEmployees, (employee: Employee) => employee[employeeListConfig.employeeIdentifierName]),
      policyIdList: this.policyIdList.split('-').map(Number),
      startDate: dateTimeUtils.convertToDtoString(this.effectiveDate)
    }
    this.state.isLoading = true;
    this.employeeDefinitionsApiService.assignAccrualsPolicies(this.orgLevel.id, accrualsPolicyAssignment)
      .then((status: any) => {
        this.state.isLoading = false;
        this.afterSave();
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  public afterSave(): void {
    this.canSend = false;
    let message = 'Changes have been applied and the list of employees will refresh to show the updated information';
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    ConfirmDialogComponent.openDialog(
      'Information',
      message,
      this.modalService,
      (result: boolean) => {
        if (result) {
          if (this.employeeGrid) {
            this.employeeGrid.isAllSelected = false;
            this.employeeGrid.onToggleAllSelected();
          }
          this.loadEmployeeData();
        }

      }, options);
  }


  public validateEmployee(date: Date, actionEmployees:Employee[]): void {
    const selectedEmployees: Employee[] = _.filter(actionEmployees, (employee: Employee) => {
      let dateDiff = 0;
      if (moment.isDate(employee.EmployeeAccrualMinimumEffectiveDate)) {
        dateDiff = moment.range(date, employee.EmployeeAccrualMinimumEffectiveDate).diff('days');
      }
      else {
        dateDiff = moment.range(date, employee.EmpDtHire).diff('days');
      }
      if (dateDiff > 0) {
        employee.Selectable = false;
        employee.isSelected = false;
        employee.Tooltip = "Selected date is before the employee activation / hire date / Latest Manual transaction. Please select another date.";
      }
      else {
        employee.Selectable = true;
        employee.Tooltip="" ;
      }
      return (employee);
    });
    if (this.effectiveDateChanged && this.employeeGrid) {
      this.employeeGrid.isAllSelected = false;
      this.employeeGrid.onToggleAllSelected();
    }
    this.actionEmployees = selectedEmployees;

  }
}

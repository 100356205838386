import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription ,  Observable ,  ReplaySubject } from 'rxjs';


import * as _ from 'lodash';

import { mutableSelect } from '../../../core/decorators/index';
import { ApplicationStateBusService } from '../../../organization/services/index';
import { NavigationMenuItem } from '../../../organization/models/index';
import { ManagementBaseService, BreadcrumbStateService } from '../../../core/services/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { LogiApiService } from '../services/logi-api.service';
import { OrgLevel } from '../../../state-model/models/index';
import { ILogiRouteInfo, isLogiRouteInfo, BreadcrumbItem } from '../../../core/models/index';
import { LogiMessagingServices } from '../services/logi-messaging.service';

@Injectable()
export class LogiManagementService extends ManagementBaseService<any, any> {

  public logiUrlChanged$: ReplaySubject<any>;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private selectedOrgLevel: OrgLevel;

  private secureKey: string;
  private logiUrl: string;
  private selecetedRouteInfo: ILogiRouteInfo;
  private isLoading: boolean = false;
  private currentUrl: string = '';
  private initialized: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private logiApiService: LogiApiService,
    private applicationStateBusService: ApplicationStateBusService,
    private userActivityService: UserActivityService,
    private logiMessagingService: LogiMessagingServices,
    private breadcrumbService: BreadcrumbStateService
  ) {
    super();
  }

  public initialize(currentUrl: string): void {
    this.currentUrl = currentUrl;
    this.subscriptions = {};
    this.logiMessagingService.initialize();
    this.logiUrlChanged$ = new ReplaySubject<any>();

    this.subscriptions.orgLevel = this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        this.processOrglevelChange(orgLevel);
      });

    this.subscriptions.routeInfo = this.applicationStateBusService.subscribeToRouteInfo((data: ILogiRouteInfo) => {
      this.processMenuChange(data);
    });

    this.subscriptions.clickInMenu = this.applicationStateBusService.subscribeToClickMenu((menu: NavigationMenuItem) => {
      if (this.initialized && _.includes(this.currentUrl, menu.link)) {
        this.reloadLogi();
      }
    });

    this.subscriptions.clickInBreadcrumb = this.breadcrumbService.subscribeToClickOnBreadcrumb((i: BreadcrumbItem) => {
      if (this.initialized && _.includes(this.currentUrl, i.linkPath)) {
        this.reloadLogi();
      }
    });

    this.subscriptions.urlChanged = this.logiUrlChanged$.subscribe(() => {
      this.initialized = true;
    });
  }

  public destroy(): void {
    this.logiMessagingService.destroy();
    super.destroy();
  }

  public subscribeToLogiLoaded(callback: () => void): Subscription {
    if (!_.isFunction(callback)) throw new TypeError('Can\'t subscribe, argument is not a function');

    return this.logiMessagingService.subscribeToLoaded(callback);
  }

  public subscribeToLogiUserAction(callback: (e: string) => void): Subscription {
    if (!_.isFunction(callback)) throw new TypeError('Can\'t subscribe, argument is not a function');
    return this.logiMessagingService.subscribeToUserAction(callback);
  }

  public resetCounterUserInactivity(): void {
    this.userActivityService.reset();
  }

  private biuldLogiParams(secureKey: string, reportName: string): string {
    return `session=${secureKey}&report=${reportName}`;
  }

  private async processMenuChange(data: ILogiRouteInfo): Promise<any> {
    if (_.isObject(data)
      && isLogiRouteInfo(data)
      && (_.isNull(this.selecetedRouteInfo) || _.isUndefined(this.selecetedRouteInfo) || this.selecetedRouteInfo.componentId !== data.componentId)) {
      this.selecetedRouteInfo = data;
      this.reloadLogi();
    }
  }

  private async processOrglevelChange(orgLevel: OrgLevel): Promise<any> {
    if (_.isNull(this.selectedOrgLevel) ||
      _.isUndefined(this.selectedOrgLevel) ||
      orgLevel.id !== this.selectedOrgLevel.id) {
      this.selectedOrgLevel = orgLevel;
      this.reloadLogi();
    }
  }

  private async reloadLogi(): Promise<any> {
    if (!_.isNull(this.selectedOrgLevel)
      && !_.isUndefined(this.selectedOrgLevel)
      && !_.isNull(this.selecetedRouteInfo)
      && !_.isUndefined(this.selecetedRouteInfo)
      && !this.isLoading) {
      await this.requestSecureKey();
      let logiParameters = this.biuldLogiParams(this.secureKey, this.selecetedRouteInfo.logiComponentId);
      let logiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.logiUrl}index.html?${logiParameters}`);
      this.logiUrlChanged$.next(logiUrl);
      console.info(`Logi URL: ${logiUrl}`);
    }
  }

  private async requestSecureKey(): Promise<any> {
    this.isLoading = true;
    this.onLoadStatusChanged(true);
    try {
      let responseData = await this.logiApiService.requestSecureKey(this.selectedOrgLevel.id, this.selecetedRouteInfo.componentId);
      this.secureKey = responseData.key;
      this.logiUrl = responseData.url;
      this.logiMessagingService.setLogiUrl(responseData.url);
      this.onLoadStatusChanged(false);
      this.isLoading = false;
    } catch (exc) {
      this.onLoadStatusChanged(false);
      this.isLoading = false;
      throw exc;
    }
  }
}

<kendo-grid class="slx-full-height slx-blue-grid"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  (sortChange)="sortChange($event)"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  (filterChange)="filterChange($event)"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="gridState.state.skip"
  (pageChange)="pageChanged($event)"
  (dataStateChange)="gridState.dataStateChange($event)"
>
  <!--(filterChange)="filterChange($event)"-->

  <kendo-grid-column media="xs" title="PBJ Log">
    <ng-template kendoGridCellTemplate let-dataItem>
      <dl>
        <dt>Export ID</dt>
        <dd> <a [slxPbjExportDetailsLink]="dataItem?.exportId"><i class="fa fa-info-circle" aria-hidden="true"></i> {{ dataItem?.exportId }}</a></dd>

        <dt>Organization</dt>
        <dd>{{ dataItem?.organization?.name }}</dd>

        <dt>Export Date</dt>
        <dd>{{ dataItem?.exportDate | amDateFormat: appConfig.dateFormat }}</dd>

        <dt>Start Date</dt>
        <dd>{{ dataItem?.startDate | amDateFormat: appConfig.dateFormat }}</dd>

        <dt>End Date</dt>
        <dd>{{ dataItem?.endDate | amDateFormat: appConfig.dateFormat }}</dd>

        <dt>Staffing Type</dt>
        <dd>{{ dataItem?.staffingType }}</dd>

        <dt>Census Type</dt>
        <dd>{{ dataItem?.censusType }}</dd>

        <dt>Emp Inf</dt>
        <dd>{{ dataItem?.containsEmployeeInfo | boolyn }}</dd>
        <dt>Staffing Inf</dt>
        <dd>{{ dataItem?.containsStaffingInfo | boolyn }}</dd>
        <dt>Census Inf</dt>
        <dd>{{ dataItem?.containsCensusInfo | boolyn }}</dd>
        <dt>Details</dt>
        <dd>{{ dataItem?.details }}</dd>
        <dt>Export By</dt>
        <dd>{{ dataItem?.exportedBy }}</dd>
      </dl>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Export ID" width="150" media="sm" field="exportId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Export ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [slxPbjExportDetailsLink]="dataItem?.exportId"><i class="fa fa-info-circle" aria-hidden="true"></i> {{ dataItem?.exportId }}</a>
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-numeric-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-numeric-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Organization" width="250" media="sm" field="organizationName">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Organization</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.organizationName }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column width="120" media="sm" field="exportDate" filter="date">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Export Date</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.exportDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column width="120" media="sm" field="startDate" filter="date">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Start Date</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.startDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column width="120" media="sm" field="endDate" filter="date">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>End Date</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.endDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column width="130" media="sm" field="staffingType">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Staffing Type</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.staffingType }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column width="130" media="sm" field="censusType">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Census Type</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.censusType }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column width="100" media="sm" field="containsEmployeeInfo">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Emp Inf</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.containsEmployeeInfo | boolyn }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-boolean-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-boolean-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column width="100" media="sm" field="containsStaffingInfo">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Staffing Inf</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.containsStaffingInfo | boolyn }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-boolean-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-boolean-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column width="100" media="sm" field="containsCensusInfo">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Census Inf</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.containsCensusInfo | boolyn }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-boolean-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-boolean-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column width="120" media="sm" field="details">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Details</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.details }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column width="120" media="sm" field="exportedBy">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Export By</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.exportedBy }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import * as _ from 'lodash';
import * as moment from 'moment';

import { Directive, ElementRef, Input, OnInit, OnDestroy, Renderer2, HostListener, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { isAcceptableControlValueAccessor } from '../../../core/models/index';

@Directive({
  selector: '[slxDatePager][ngModel]'
})
export class DatePagerDirective implements OnInit, OnDestroy {

  @Input()
  public set readonly(value: boolean) {
    this.m_readonly = value;
    this.updateButtons();
  }

  @Input()
  public set pagerMinDate(value: Date) {
    this.m_minDate = value;
    this.updateButtons();
  }

  @Input()
  public set pagerMaxDate(value: Date) {
    this.m_maxDate = value;
    this.updateButtons();
  }

  @Input('targetContainer')
  public container: HTMLElement | any;
  @Input('btnLeftClass')
  public buttonLeftClass: string;
  @Input('btnRightClass')
  public buttonRightClass: string;

  @Input()
  public styleContainerClass: string = 'slx-date-pager';


  @Output() public onDateChanged: EventEmitter<Date>;

  private elementRef: ElementRef;
  private renderer: Renderer2;
  private buttonLeft: HTMLButtonElement;
  private buttonRight: HTMLButtonElement;
  private mobileButtonLeft: HTMLButtonElement;
  private mobileButtonRight: HTMLButtonElement;
  private buttonTypes: { left: string; right: string; };

  private m_minDate: Date;
  private m_maxDate: Date;
  private m_readonly: boolean;

  private clickHandlers: (() => void)[] = [];

  constructor(elementRef: ElementRef, renderer: Renderer2, public model: NgModel) {
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.buttonTypes = { left: 'left', right: 'right' };
    this.buttonLeftClass = '';
    this.buttonRightClass = '';
    this.onDateChanged = new EventEmitter<Date>();
  }

  public ngOnInit(): void {
    let container: HTMLElement = _.get(this.container, 'elementRef.nativeElement', this.container);

    if (_.isElement(container)) {
      this.buttonLeft = this.createButton(this.buttonTypes.left);
      this.buttonRight = this.createButton(this.buttonTypes.right);
      this.mobileButtonLeft = this.createButton(this.buttonTypes.left, true);
      this.mobileButtonRight = this.createButton(this.buttonTypes.right, true);
      container.classList.add(this.styleContainerClass);
      if (this.styleContainerClass === 'slx-date-pager-vert' || this.styleContainerClass === 'slx-date-pager-date') {
        this.renderer.appendChild(container, this.mobileButtonLeft);
        let holder: HTMLDivElement = this.createButtonsHolder();
        this.renderer.appendChild(holder, this.buttonRight);
        this.renderer.appendChild(holder, this.buttonLeft);
        this.renderer.appendChild(container, holder);
        this.renderer.appendChild(container, this.mobileButtonRight);
      } else {
        this.renderer.appendChild(container, this.buttonLeft);
        this.renderer.appendChild(container, this.buttonRight);
      }
    }
    this.updateButtons();
  }

  public ngOnDestroy(): void {
    _.each(this.clickHandlers, (handler: () => void) => {
      handler();
    });
  }

  @HostListener('ngModelChange', ['$event'])
  public onModelChange(event: any): void {
    this.updateButtons(event);
  }

  private createButtonsHolder(): HTMLDivElement {
    let holder: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.addClass(holder, 'date-buttons-holder');
    return holder;
  }

  private createButton(type: string, forMobile?: boolean): HTMLButtonElement {
    const button: HTMLButtonElement = this.renderer.createElement('button');
    const icon: HTMLElement = this.renderer.createElement('i');

    const isLeftButton: boolean = type === this.buttonTypes.left;
    const buttonClass: string = isLeftButton ? this.buttonLeftClass : this.buttonRightClass;
    const clickHandler: () => void = isLeftButton ? this.onPrevDate.bind(this) : this.onNextDate.bind(this);

    const buttonClasses: string[] = ['theme-iconed-accent-button', `slx-date-pager-${type}`, buttonClass];
    if (forMobile) buttonClasses.push('mobile-show-btn');
    this.addClasses(button, buttonClasses);
    let clickWrapper: () => void = this.renderer.listen(button, 'mouseup', clickHandler);
    this.clickHandlers.push(clickWrapper);
    this.renderer.setAttribute(icon, 'title', isLeftButton ? 'Previous day' : 'Next day');
    this.renderer.appendChild(button, icon);

    const iconClasses: string[] = ['far', `fa-arrow-alt-circle-${type}`];
    this.addClasses(icon, iconClasses);
    this.renderer.setAttribute(icon, 'aria-hidden', 'true');
    this.renderer.setStyle(icon, 'width', '100%');
    this.renderer.setStyle(icon, 'text-align', 'center');

    return button;
  }

  private addClasses(node: HTMLElement, classes: string[]): void {
    _.forEach(classes, (className) => {
      if (_.isString(className) && className.length > 0) {
        this.renderer.addClass(node, className);
      }
    });
  }

  private onPrevDate(event: any): void {
    const m: moment.Moment = moment(this.model.value).subtract(1, 'day');
    let dateOn: Date = m.toDate();
    if (this.m_minDate) {
      if (m.isSameOrAfter(this.m_minDate)) {
        this.changeValue(dateOn);
      } else {
        dateOn = moment(this.m_minDate).toDate();
        this.changeValue(dateOn);
      }
    } else {
      this.changeValue(dateOn);
    }
  }

  private onNextDate(event: any): void {
    const m: moment.Moment = moment(this.model.value).add(1, 'day');
    let dateOn: Date = m.toDate();
    if (this.m_maxDate) {
      if (m.isSameOrBefore(this.m_maxDate)) {
        this.changeValue(dateOn);
      } else {
        dateOn = moment(this.m_maxDate).toDate();
        this.changeValue(dateOn);
      }
    } else {
      this.changeValue(dateOn);
    }
  }

  private changeValue(value: Date): void {
    if (isAcceptableControlValueAccessor(this.model.valueAccessor)) {
      let res = this.model.valueAccessor.changeValue(value);
      this.model.valueAccessor.writeValue(value);
      if (res instanceof Promise) {
        res.then((accept: boolean) => {
          if (accept) {
            this.onDateChanged.emit(value);
            this.updateButtons();
          }
        });
      }
    } else {
      this.model.control.setValue(value);
      this.onDateChanged.emit(value);
      this.updateButtons();
    }
  }
  private updateButtons(modelValue: any = null): void {

    let leftDisabled: boolean;
    let rightDisabled: boolean;

    if (this.m_readonly) {
      leftDisabled = true;
      rightDisabled = true;
    } else {
      if (!this.model || !this.model.value) return;     
      if (!this.model.value && modelValue) return;
      
      let dateValue: moment.Moment = modelValue ? moment(modelValue) : moment(this.model.value);
      let nextDateValue: moment.Moment = moment(dateValue).add(1, 'day');
      let prevDateValue: moment.Moment = moment(dateValue).add(-1, 'day');

      if (this.m_minDate) {
        if (moment(this.m_minDate).isAfter(prevDateValue, 'day')) {
          leftDisabled = true;
        } else {
          leftDisabled = false;
        }
      } else {
        leftDisabled = false;
      }

      if (this.m_maxDate) {
        if (moment(this.m_maxDate).isBefore(nextDateValue, 'day')) {
          rightDisabled = true;
        } else {
          rightDisabled = false;
        }
      } else {
        rightDisabled = false;
      }
    }

    if (this.buttonLeft) this.buttonLeft.disabled = leftDisabled;
    if (this.mobileButtonLeft) this.mobileButtonLeft.disabled = leftDisabled;
    if (this.buttonRight) this.buttonRight.disabled = rightDisabled;
    if (this.mobileButtonRight) this.mobileButtonRight.disabled = rightDisabled;
  }
}

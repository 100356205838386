import { Injectable } from '@angular/core';
import { OrgLevelType } from '../../../state-model/models/index';
import { Subscription ,  Observable ,  Subject } from 'rxjs';
import { Actions, ActionsType } from '../../../core/models/field/actions-type';

import * as _ from 'lodash';

@Injectable()
export class AccessManagementService implements IAccessManagementService {

    private static readonly USER_CAN_EDIT: number = 1;
    private static readonly USER_CAN_ADD: number = 2;
    private static readonly USER_CAN_DELETE: number = 4;

    public set actions(value: Actions) {
        this.m_Actions = value;
        let flags: number = 0;
        if (value.canEdit) flags = flags | AccessManagementService.USER_CAN_EDIT;
        if (value.canDelete) flags = flags | AccessManagementService.USER_CAN_DELETE;
        if (value.canAdd) flags = flags | AccessManagementService.USER_CAN_ADD;
        this.setPermissions(flags);
    }

    public get actions(): Actions {
        return this.m_Actions;
    }

    public set selectedItemsCount(count: number) {
        this.m_hasSelected = count > 0;
        this.updateState();
    }

    public set lockActions(value: boolean) {
        this.m_LockActions = value;
        this.updateState();
    }

    public get lockActions(): boolean {
        return this.m_LockActions;
    }

    public set orgLevelType(value: OrgLevelType) {
        this.m_OrgLevelType = value;
        this.updateState();
    }

    public set allowDepartmentLevel(value: boolean) {
        if (value) {
            this.m_allowedOrgLevels.push(OrgLevelType.department);
        } else {
            this.m_allowedOrgLevels = _.without(this.m_allowedOrgLevels, OrgLevelType.department);
        }
    }

    public set allowOrganizationLevel(value: boolean) {
        if (value) {
            this.m_allowedOrgLevels.push(OrgLevelType.organization);
        } else {
            this.m_allowedOrgLevels = _.without(this.m_allowedOrgLevels, OrgLevelType.organization);
        }
    }

    public set allowCorporationLevel(value: boolean) {
        if (value) {
            this.m_allowedOrgLevels.push(OrgLevelType.corporation);
        } else {
            this.m_allowedOrgLevels = _.without(this.m_allowedOrgLevels, OrgLevelType.corporation);
        }
    }

    public get actionTypesList(): string[] {
        return [
            ActionsType.add,
            ActionsType.delete,
            ActionsType.edit,
        ];
    }

    // getters for direct bindings to model
    public get canEdit(): boolean { return this.m_CanEdit; }
    public get canAdd(): boolean { return this.m_CanAdd; }
    public get canDelete(): boolean { return this.m_CanDelete; }
    public get canDoDelete(): boolean { return this.m_CanDoDelete; }
    public get restrictedByOrgLevel(): boolean { return this.m_restrictByOrgLevel; }

    public get isDepartment(): boolean {
        return this.m_isDepartment;
    }

    public get isCorporation(): boolean {
        return this.m_isCorporation;
    }

    public get isOrganization(): boolean {
        return this.m_isOrganization;
    }


    private m_CanAdd: boolean;
    private m_CanEdit: boolean;
    private m_CanDelete: boolean;
    private m_CanDoDelete: boolean;
    private m_LockActions: boolean;
    private m_restrictByOrgLevel: boolean;

    private m_Actions: Actions;
    private m_permissions: number = 0;
    private m_hasSelected: boolean;
    private m_OrgLevelType: OrgLevelType = OrgLevelType.department;
    private m_allowedOrgLevels: OrgLevelType[] = [];


    private m_isDepartment: boolean;
    private m_isOrganization: boolean;
    private m_isCorporation: boolean;

    private subject: Subject<any> = new Subject<any>();

    public subscribe(listener: () => void): Subscription {
        return this.subject.asObservable().subscribe(listener);
    }

    public checkAction(action: string): boolean {

        if (action === ActionsType.add) return this.m_CanAdd;
        if (action === ActionsType.edit) return this.m_CanEdit;
        if (action === ActionsType.delete) return this.m_CanDoDelete;

        return false;
    }

    protected checkFlag(flag: number): boolean {
        if (!this.m_LockActions && _.includes(this.m_allowedOrgLevels, this.m_OrgLevelType)) {
            return (this.m_permissions & flag) > 0;
        }
        return false;
    }

    protected setPermissions(flags: number): void {
        this.m_permissions = flags;
        this.updateState();
    }

    protected updateState(): void {

        if (!this.m_LockActions && _.includes(this.m_allowedOrgLevels, this.m_OrgLevelType)) {
            this.m_CanEdit = (this.m_permissions & AccessManagementService.USER_CAN_EDIT) > 0;
            this.m_CanAdd = (this.m_permissions & AccessManagementService.USER_CAN_ADD) > 0;
            this.m_CanDelete = ((this.m_permissions & AccessManagementService.USER_CAN_DELETE) > 0);
            this.m_CanDoDelete = ((this.m_permissions & AccessManagementService.USER_CAN_DELETE) > 0) && this.m_hasSelected;
            this.m_restrictByOrgLevel = false;
        } else if (!this.m_LockActions) {
            this.m_restrictByOrgLevel = true;
            this.m_CanEdit = false;
            this.m_CanAdd = false;
            this.m_CanDelete = false;
            this.m_CanDoDelete = false;
        } else {
            this.m_restrictByOrgLevel = false;
            this.m_CanEdit = false;
            this.m_CanAdd = false;
            this.m_CanDelete = false;
            this.m_CanDoDelete = false;
        }

        this.m_isCorporation = this.m_OrgLevelType === OrgLevelType.corporation;
        this.m_isOrganization = this.m_OrgLevelType === OrgLevelType.organization;
        this.m_isDepartment = this.m_OrgLevelType === OrgLevelType.department;

        this.notify();
    }

    protected notify(): void {
        this.subject.next();
    }
}

export interface IAccessManagementService {
    actions: Actions;
    actionTypesList: string[];
    checkAction(action: string): boolean;
    subscribe(listener: () => void): Subscription;
}

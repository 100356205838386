import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { GridModule } from '@progress/kendo-angular-grid';
import { MomentModule } from 'angular2-moment';

import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { ComponentsLibraryModule } from '../../components-library/index';
import { services } from './services/index';
import { directives } from './directives/index';
import { components, exportComponents, entryComponents } from './components/index';

@NgModule({
    imports: [
        FormsModule,
        HttpClientModule,
        CommonAppModule,
        CommonModule,
        OrganizationModule,
        MomentModule,
        GridModule,
        ComponentsLibraryModule
    ],
    declarations: [
        ...components, ...directives
    ],
    providers: [
        ...services
    ],
    exports: [
        ...exportComponents, ...directives
    ]
})
export class PayUnitsModule { }

import { EventEmitter, Output, Directive } from '@angular/core';
import * as XLSX from 'xlsx';

@Directive()
export class ExcelFileExtractHelper {

    @Output()
    public validateChanges: EventEmitter<any> = new EventEmitter();

    public excelData = {
        excelHeader: [],
        excelData: []
    }

    constructor() { }

    public convertToBinaryString(event) {
        let workBook = null;
        let jsonData = null;
        const reader = new FileReader();
        const file = event;
        reader.onload = () => {
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            const sheet = workBook.Sheets[workBook.SheetNames[0]];
            jsonData = XLSX.utils.sheet_to_json(sheet);
            this.convertToArrayBuffer(event, jsonData);
        }
        reader.readAsBinaryString(file);
    }

    private convertToArrayBuffer(event, uploadedData: any) {
        const headerReader: FileReader = new FileReader();
        headerReader.onload = (e: any) => {
            const data: Uint8Array = new Uint8Array(e.target.result);
            const workBook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
            const sheetName = workBook.SheetNames[0];
            const workSheet: XLSX.WorkSheet = workBook.Sheets[sheetName];
            const jsonData: any = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
            this.excelData.excelHeader = jsonData[0];
            this.excelData.excelData = uploadedData;
            this.validateChanges.emit(this.excelData);
        }
        headerReader.readAsArrayBuffer(event);
    }
}
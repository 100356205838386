<div #radioInput class="md-radio-inline form-control">
  <div class="md-radio"  *ngFor="let opt of optionList">
    <input type="radio"
           [disabled]="inEdit ? null : true"
           [(ngModel)]="value"
           [checked]="opt===value"
           [value]="opt"
           [attr.id]="opt | property: valueMemberPath" name="optionComponent" class="md-radiobtn">
    <label [attr.for]="opt | property: valueMemberPath">
      <span class="inc"></span>
      <span class="check"></span>
      <span class="box"></span> {{opt | property: descriptionMemberPath }}</label>
  </div>
</div>

<slx-spinner [show]="isLoading">
    <div class="slx-high-box benefit-employees-eligible ">
        <div class="slx-high-box__body">
            <form #form="ngForm" novalidate class="slx-full-height">
                <kendo-grid #kendoGrid
                    class="slx-blue-grid slx-transparent-selection slx-grid-slim-rows slx-full-height"
                    [data]="gridState?.view"
                    [filterable]="'menu'"
                    [sortable]="true"
                    [pageable]="true"
                    [pageSize]="pageSize"
                    [filter]="gridState.state.filter"
                    [sort]="gridState.state.sort"
                    [skip]="gridState.state.skip"
                    [selectable]="selectableSettings"
                    (dataStateChange)="gridState.dataStateChange($event)"
                  >

                    <kendo-grid-column title="Employee" width="150" field="employeeName">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Employee</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <a [employeeSectionNewWindowLink]="dataItem.employeeId">
                                {{dataItem.employeeName}}
                            </a>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Position" width="110" field="position.name">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Position</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.position.name}}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Department" width="120" field="department.name"
                        *slxHiddenOn="['tablet', 'mobile']">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Department</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.department.name}}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Type" width="110" field="type.name">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Type</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.type.name}}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Employer Contribution" width="200" class="slx-blue-grid__cell-align-top" field="employerContributionAmount">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Employer Contribution</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <slx-number class="slx-wide slx-input-number"
                              #erContr="ngModel"
                              [min]="minAmt"
                              [max]="maxAmt"
                              [step]="1"
                              [decimals]="2"
                              [autoCorrect]='false'
                              [(ngModel)]="dataItem.employerContributionAmount"
                              (ngModelChange)="onContributionChange()"
                              [format]="format"
                              [required]="true"
                              name="erContribution_{{ dataItem.employeeId }}"
                            ></slx-number>
                            <div *ngIf="erContr.errors" class="slx-error-block">
                              <span *ngIf="erContr.errors.required" errorMessage for="required"></span>
                              <span *ngIf="erContr.errors.min" errorMessage>Employer contribution have to be greater or equal to {{ minAmt }}</span>
                              <span *ngIf="erContr.errors.max" errorMessage>Employer contribution have to be less or equal to {{ maxAmt }}</span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Employee Contribution" width="200" class="slx-blue-grid__cell-align-top" field="employeeContributionAmount">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Employee Contribution</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <slx-number class="slx-wide slx-input-number"
                              #empContr="ngModel"
                              [min]="minAmt"
                              [max]="maxAmt"
                              [step]="1"
                              [decimals]="2"
                              [autoCorrect]='false'
                              [format]="format"
                              [(ngModel)]="dataItem.employeeContributionAmount"
                              (ngModelChange)="onContributionChange()"
                              [required]="true"
                              name="empContribution_{{ dataItem.employeeId }}"
                            ></slx-number>
                            <div *ngIf="empContr.errors" class="slx-error-block">
                              <span *ngIf="empContr.errors.required" errorMessage for="required"></span>
                              <span *ngIf="empContr.errors.min" errorMessage>Employee contribution have to be greater or equal to {{ minAmt }}</span>
                              <span *ngIf="empContr.errors.max" errorMessage>Employee contribution have to be less or equal to {{ maxAmt }}</span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-messages filterMenuTitle="Filter">
                    </kendo-grid-messages>  
                </kendo-grid>
            </form>
        </div>
    </div>
</slx-spinner>

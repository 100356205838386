import { Directive, ElementRef, AfterViewInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ScrollWatchEvent } from '../../models/index';

@Directive({
  selector: '[scrollElementSelector]',
})
export class ScrollElementSelectorDirective implements OnDestroy, AfterViewInit {
  @Input()
  public scrollElementSelector: string;
  public onScroll$: ReplaySubject<ScrollWatchEvent>;

  private elementRef: ElementRef;
  private scrollElement: Element;
  private listenerFn: () => void;

  constructor(elementRef: ElementRef,  private renderer: Renderer2) {
    this.elementRef = elementRef;
    this.onScroll$ = new ReplaySubject<ScrollWatchEvent>(1);
  }

  public ngAfterViewInit(): void {
    let el: HTMLElement = this.elementRef.nativeElement;
    this.scrollElement = el.querySelector(this.scrollElementSelector);
    this.listenerFn = this.renderer.listen(this.scrollElement, 'scroll', (event: MouseEvent) => this.onScroll(event));
  }


  public ngOnDestroy(): void {
    if (this.listenerFn) {
      this.listenerFn();
    }
  }

  public setScrollPosition(top: number, left: number): void {
    if (!this.scrollElement) {
      return;
    }
    this.scrollElement.scrollTop = top;
    this.scrollElement.scrollLeft = left;
  }

  private onScroll(event: MouseEvent): void {
    let ev: ScrollWatchEvent = new ScrollWatchEvent(event);
    ev.scrollTop = this.scrollElement.scrollTop;
    ev.scrollLeft = this.scrollElement.scrollLeft;
    this.onScroll$.next(ev);
  }
}

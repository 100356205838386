import { IAppState, rootReducer, deimmutify, reimmutify } from './store';
import { IApplication } from './application/index';
import { IOrgLevel } from './org-level/index';
import { ISidebar } from './sidebar/index';
import { environment } from '../../environments/environment';
import { createLogger } from 'redux-logger';
import { devToolsEnhancer, EnhancerOptions } from 'redux-devtools-extension';

/* tslint:disable */
const persistState = require('redux-localstorage');
const applicationMainKey = 'slx-worklinx-v6-app';
/* tslint:enable */

export {
  IAppState,
  IApplication,
  IOrgLevel,
  ISidebar,
  rootReducer,
  reimmutify,
};

export function clearPersistedMainState(): void {
  localStorage.removeItem(applicationMainKey);
}

export let middleware: any[] = [];
export let enhancers: any = [
  persistState(
    '',
    {
      key: applicationMainKey,
      serialize: (store: any) => JSON.stringify(deimmutify(store)),
      deserialize: (state: any) => reimmutify(JSON.parse(state)),
    })
];

if (!environment.production) {
  middleware.push(
    createLogger({
      level: 'info',
      collapsed: true,
      stateTransformer: deimmutify,
    }));

    enhancers.push(devToolsEnhancer({}));
    //enhancers.push(devToolsEnhancer({trace: true, traceLimit: 25})); // only when required 

  // const env: any = window || this;
  // if (env.devToolsExtension) {
  //   enhancers.push(env.devToolsExtension());
  // }
}

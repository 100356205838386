<kendo-grid #grid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" sortable="true" [sort]="gridState.state.sort"
  [filterable]="false" [filter]="gridState.state.filter" scrollable="none" class="kendo-nested-grid">

  <kendo-grid-column title="" [sortable]="true" field="description" media="sm" width="268" [filterable]="false" [headerStyle]="{'display':'none'}">
    <ng-template kendoGridHeaderTemplate>
      Description
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="flex justify-content__space-between align-items__center">
        <span>{{dataItem.description}}</span>
          <slx-switcher
            *ngIf="!!dataItem.productModule"
            [disabled]="!dataItem.productModule.isEditable"
            [(ngModel)]="dataItem.productModule.isEnabled"
            (ngModelChange)="productModuleChange(dataItem)"
            label=""
            className="slx-switcher-grey-off"
            labelOn="On"
            labelOff="Off">
        </slx-switcher>
     </div>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
</kendo-grid-column>

  <ng-template ngFor let-role [ngForOf]="container?.roles">
    <kendo-grid-column title=""
      [sortable]="false"
      media="sm"
      [filterable]="false"
      width="100"
      [headerStyle]="{'display':'none'}"
      [hidden]="!roleColumnsState.isVisible(role)"
    >
      <ng-template kendoGridHeaderTemplate>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <slx-roles-menu-access-toggler [rowItem]="dataItem" [roleId]="role.id" (roleToggled)="onRoleMenuModuleToggled($event, dataItem)"></slx-roles-menu-access-toggler>
      </ng-template>
    </kendo-grid-column>
  </ng-template>
  <ng-template kendoGridDetailTemplate let-dataItem>
    <div class="role-details-grid">
      <slx-roles-menu-grid [container]="dataItem" (roleMenuChanged)="roleMenuChanged($event, dataItem)"></slx-roles-menu-grid>
    </div>
  </ng-template>
</kendo-grid>

import { PbjModes } from './app-setting-keys';
import { IUserPermissions } from './user-permissons';
import { NightShiftSetting } from './night-shift-setting';

export class AppServerConfig {
  public thresholdSeconds: number;
  public timeoutSeconds: number;
  public PbjEnabled: boolean;
  public PbjMode: PbjModes;
  public payrollExpManagementEnabled: boolean;
  public WorkerDupesReport :boolean;
  public maxFileSizeLimit: number;
  public userPermissions: IUserPermissions;
  public essTemplatesEnabled: boolean;
  public enablePunchAttestation: boolean;
  public nightShiftSettings: NightShiftSetting[];
  public bswiftIntegrationEnabled: boolean;
  public enterpriseId: number;
  public TwilioAccountSid: string;
  public TwilioAuthToken: string;
  public TwilioSenderPhone: string;
  public requireTimeclockForPunches: boolean;
  public serverUtcOffsetSec: number;
  public EnableRate4DecimalPlaces: boolean;
  public duagrid: boolean;
  public partnerintegrations: boolean;
  public enableIncreaseReason: boolean;
  public addTimeCardComments: boolean;
  public IsGOClient: boolean;
  public preventOverlappingPunches: boolean;
  public isSupervisorEnabled: boolean;
  public allowToExcludeFromTotals: boolean;
  public CreateorEditPolicy: boolean;
  public Deletepolicy: boolean;
  public CopyAccrualPolicy: boolean;
  public isBenefitDeductionEnabled: boolean;
  public isNotesEnabled: boolean;
  public isTermHistoryEditEnabled: boolean;
  public preventoverlappingabsences: boolean;
  public enableOpenShiftOnFly: boolean;
  public ispositionHistoryWithAttributeEnabled: boolean;
  public five_star_v2_enabled: boolean;
  public five_star_goal_section_enabled: boolean;
  public isBenefitDeductionEnabled2: boolean;
  public TimecardRedesign: boolean;
  public ScheduleNotPosted: boolean;
  public IncreaseFrequencyofShiftsPostedNotifications : boolean;
  public AbilityForAdminToTurnOnAndOffAutoShiftPickUp : boolean;
  public NegativeAccrualExportTest: boolean;
  public ScheduleRotationTemplate: boolean;
  public AbilitytoChangeAccrualRuleTypeBasedonSeniority: boolean;
  public isV6ReHireEnhacementEnabled: boolean;
  public IsPBJExportCorpLevelEnabled: boolean;
  public isDefaultShiftEnabled: boolean;
  public IsPBJConsecutiveHrsEnabled:boolean;
  public IsNextgenPayrollEnabled:boolean;
  public isDownloadImportOptionEnabled: boolean;
  public AllowSelectFutureDateRNMinHours: boolean;
  public ModifySchedulesApprovedinPayPeriods:boolean;
  public NextgenPhasedRollout: boolean;
  public isPartialShiftEnabled: boolean;
  public defaultPartialShiftCount: number;
  public ExportToPayrollFrequency: number;
  public ExportToPayrollDuration: number;
  public IsNextgenTimeExportEnabled:boolean;
  public useTZnotOffset:boolean;
}
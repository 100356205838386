import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';

import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';

import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

import { unsubscribeAll } from '../../../../../../core/decorators/index';

import { appConfig, IApplicationConfig } from '../../../../../../app.config';
import { StateResetTypes } from '../../../../../../core/models/settings/reset-types';
import { DeviceDetectorService } from '../../../../../../common/services/index';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { OrgLevel } from '../../../../../../state-model/models/index';

import { WcIncidentRosterEntry, WcSummaryRosterState, WcIncidentRosterContainer } from '../../../../models/index';
import { WcSummaryManagementService } from '../../../../services/index';

@Component({
  selector: 'slx-wc-summary-grid',
  templateUrl: 'wc-summary-grid.component.html',
  styleUrls: ['wc-summary-grid.component.scss']
})
export class WcSummaryGridComponent implements OnInit, OnDestroy {
  public records: WcIncidentRosterEntry[];
  public storedRecords: WcIncidentRosterEntry[];
  public gridState: KendoGridStateHelper<WcIncidentRosterEntry>;
  public appConfig: IApplicationConfig;
  public orgLevel: OrgLevel;
  public state: WcSummaryRosterState;
  public pageSize: number = 50;
  public canEdit: boolean;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor (private manService: WcSummaryManagementService, protected devDetector: DeviceDetectorService) {
    this.gridState = new KendoGridStateHelper<WcIncidentRosterEntry>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [
      { field: 'organization.name', dir: 'asc' },
      { field: 'employee.name', dir: 'asc' }
    ];
    this.gridState.gridComponentKey = 'WCSummaryGrid';
    this.gridState.gridControlStateKey = 'GridFiltersState';
    this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
    this.appConfig = appConfig;
  }

  public ngOnInit() {
    this.subscriptions.onLoaded = this.manService
      .subscribeToLoadedRecords((r: WcIncidentRosterContainer) => {
        this.canEdit = r.canEdit;
        this.assignRecords(r.records);
      });

    this.subscriptions.state = this.manService
      .subscribeToChangeState((s: WcSummaryRosterState) => this.applyState(s));

    this.subscriptions.export = this.manService
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());
  }

  public ngOnDestroy(): void {}

  public onOpenReport(entry: WcIncidentRosterEntry): void {
    this.manService.openEditReportDialog(entry.id);
  }

  public onGridStateChanged(state: State): void {
    if (_.isObjectLike(state)) {
      this.gridState.updateStateWithDelay(state);
    }
  }

  public isVisible(field: string): boolean {
    return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].displayed : false;
  }

  public retriveAllPages(): () => ExcelExportData {
    return (): ExcelExportData => (
      {
        data: process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
      }
    ) as ExcelExportData;
  }

  private assignRecords(records: WcIncidentRosterEntry[]): void {
    this.records = records;
    this.storedRecords = _.clone(records);
    this.refreshGrid();
  }

  private applyState(s: WcSummaryRosterState): void {
    this.state = s;
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.records, this.gridState.state);
  }
}

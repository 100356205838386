import { Component, OnDestroy, OnInit, Input, Output, ElementRef, EventEmitter, NgZone, Host, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, NgForm, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsLicense, EmployeeSectionsLicenses, EmployeeSectionsTemporalModel, EmployeeSectionsBase } from '../../../models/index';
import { LookupApiService } from '../../../../../organization/services/lookup/lookup-api.service';
import { EmployeeSectionsPersonalApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { BudgetedPosition } from '../../../../../organization/models/lookup/budgeted-position';
import * as _ from 'lodash';
import { FieldAccessType } from '../../../../../core/models/field/field-access-type';
import { ICommonConfig, commonConfig } from '../../../../../common/common.config';
import { ModalService } from '../../../../../common/index';
import { LicenseType } from '../../../../../../app/organization';
import { Observable, Subscription } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../../../app/core/decorators';
import { OrgLevel } from '../../../../../../app/state-model/models';
@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-licenses',
  templateUrl: 'employee-sections-licenses.component.html',
  styleUrls: ['employee-sections-licenses.component.scss']
})
export class EmployeeSectionsLicensesComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input()
  public set employeeSectionsLicenses(licenses: EmployeeSectionsLicenses) {
    this.licenses = licenses;
    if (this.licenses && this.licenses.employeeSectionsLicenses) {
      _.each(this.licenses.employeeSectionsLicenses, (l, index) => l.indexSeq = index);
    }
    this.actualLicenses = this.calcActualLicenses();
  }

  @Input() public employeeId: number;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  public orgLevelId: number;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('form')
  public ngForm: NgForm;

  public budgetedPositionLookup: BudgetedPosition[];
  public licenseTypeLookup: LicenseType[];
  public licenseTypeLookupMap: NumberMap<LicenseType>;
  public licenses: EmployeeSectionsLicenses;
  public actualLicenses: EmployeeSectionsLicense[];
  public budgetedPositionMap: NumberMap<BudgetedPosition>;
  public commonConfig: ICommonConfig;
  public reachMaximum: boolean;

  private employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService;
  private lookupApiService: LookupApiService;
  private startEdit: boolean;
  private maximumLicenses: number = 10;


  constructor(employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService,
    lookupApiService: LookupApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private modalService: ModalService,
    private changeDetector: ChangeDetectorRef
  ) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsPersonalApiService, 'employeeSectionsPersonalApiService');
    this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
    this.lookupApiService = lookupApiService;
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevelId = orgLevel.id;
    });
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.licenses;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.commonConfig = commonConfig;
    this.loadLookups();
  }

  public removeLicense(lic: EmployeeSectionsLicense): void {
    lic.removed = true;
    _.each(this.licenses.employeeSectionsLicenses, (l, index) => l.indexSeq = index);

    this.actualLicenses = [];
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();

    this.actualLicenses = this.calcActualLicenses();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();

    this.form.updateValueAndValidity();
  }

  public addNewLicense(): void {
    let lic: EmployeeSectionsLicense = new EmployeeSectionsLicense();
    lic.licenseNumber = this.licenses.licenseNumberMetaData.createBasedOn();
    lic.licenseNumber.securityAttribute.access = FieldAccessType.full;
    lic.expirationDate = this.licenses.expirationDateMetaData.createBasedOn();
    lic.expirationDate.fieldValue = '';
    lic.expirationDate.securityAttribute.access = FieldAccessType.full;
    lic.budgetedPosition = this.licenses.budgetedPositionMetaData.createBasedOn();
    lic.budgetedPosition.securityAttribute.access = FieldAccessType.full;
    lic.budgetedPosition.fieldValue = new BudgetedPosition();
    lic.licenseType = this.licenses.licenseTypeMetaData.createBasedOn();
    lic.licenseType.securityAttribute.access = FieldAccessType.full;
    lic.licenseType.fieldValue = new LicenseType();
    lic.issueDate = this.licenses.issueDateMetaData.createBasedOn();
    lic.issueDate.fieldValue = '';
    lic.issueDate.securityAttribute.access = FieldAccessType.full;
    this.licenses.employeeSectionsLicenses.push(lic);
    lic.seq = 0;
    _.each(this.licenses.employeeSectionsLicenses, (l, index) => l.indexSeq = index);

    this.actualLicenses = [];

    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();

    this.actualLicenses = this.calcActualLicenses();

    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();

    this.form.updateValueAndValidity();

    if (this.reachMaximum) {
      this.modalService.globalAnchor.openInfoDialog('Attention', 'You reached maximum of creating licenses');
    }

  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsPersonalApiService.getPersonalLicenses(this.employeeId)
      .then((employeeSectionsLicenses: EmployeeSectionsLicenses) => {
        this.licenses = employeeSectionsLicenses;
        this.actualLicenses = this.calcActualLicenses();
        this.state.isLoaded = true;
        this.stopProgress();
        this.state.isEditMode = this.startEdit;
        this.startEdit = false;
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  public setEditState(editState: boolean): void {
    this.startEdit = editState;
  }

  public calcActualLicenses(): EmployeeSectionsLicense[] {
    let res: EmployeeSectionsLicense[] = [];
    this.reachMaximum = false;
    if (!this.licenses) return res;
    res = _.filter(this.licenses.employeeSectionsLicenses, function (lic: EmployeeSectionsLicense): boolean {
      return !lic.removed;
    });
    this.reachMaximum = res.length >= this.maximumLicenses;
    return res;
  }

  public checkDuplicateLicenses(): void {
    let i: number = 0;
    let j: number = 0;
    let hasErrors: boolean = false;
    _.forEach(this.actualLicenses, (lic: EmployeeSectionsLicense) => {
      lic.hasDuplicateAtSeq = -1;
    });
    _.times(this.actualLicenses.length, () => {
      let lic1: EmployeeSectionsLicense = this.actualLicenses[i];
      j = i + 1;
      _.times(this.actualLicenses.length - i - 1, () => {
        let lic2: EmployeeSectionsLicense = this.actualLicenses[j];
        let exDateEq: boolean = moment(lic1.expirationDate.fieldValue).startOf('day').isSame(moment(lic2.expirationDate.fieldValue).startOf('day'));
        let licNumEq: boolean = lic1.licenseNumber.fieldValue === lic2.licenseNumber.fieldValue;
        let licTypEq: boolean = lic1.licenseType.fieldValue.licenseTypeID === lic2.licenseType.fieldValue.licenseTypeID;
        
        if (exDateEq && licNumEq && licTypEq) {
          lic2.hasDuplicateAtSeq = i;
          hasErrors = true;
        }
        j++;
      });
      i++;
    });
    if (hasErrors) {
      this.form.setErrors({ 'duplicate': true });
    }
  }

  public loadLookups(): void {
    this.getBudgetedPositionLookupData();
    this.getLicenseTypeLookupData();
  }

  public getBudgetedPositionLookupData(): void {
    if (this.budgetedPositionLookup) return;
    this.lookupApiService.getBudgetedPositions(this.employeeId, 0)
      .then((budgetedPositionLookup: BudgetedPosition[]) => {
        this.budgetedPositionLookup = budgetedPositionLookup;
        this.budgetedPositionMap = {};
        _.forEach(this.budgetedPositionLookup, (pos: BudgetedPosition) => {
          this.budgetedPositionMap[pos.id] = pos;
        });
      });
  }

  public getLicenseTypeLookupData(): void {
    if (this.licenseTypeLookup) return;
    this.lookupApiService.getLicenseType(this.employeeId, 0)
      .then((licenseTypeLookup: LicenseType[]) => {
        this.licenseTypeLookup = licenseTypeLookup;
        this.licenseTypeLookupMap = {};
        _.forEach(this.licenseTypeLookup, (pos: LicenseType) => {
          this.licenseTypeLookupMap[pos.licenseTypeID] = pos;
        });
      });
  }

  protected doSave(effectiveDate: Date): void {
    this.employeeSectionsPersonalApiService.setPersonalLicenses(this.employeeId, this.actualLicenses, this.licenses.fieldsMeta)
      .then((status: any) => {
        this.onActionComplete(true);
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    this.checkDuplicateLicenses();
    return this.metaFieldsTemporalDirtyArrayChecker(this.licenses.employeeSectionsLicenses);
  }
}

import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';

import { GridComponent, RowArgs } from '@progress/kendo-angular-grid';

import { appConfig, IApplicationConfig } from './../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { PopoverContentComponent } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { GeolocationEntity, IGeolocationEvent, GeolocationDialogOptions } from '../../../models/index';
import { GeolocationManagementService } from '../../../services/index';
import { GeolocationDialogComponent } from '../geolocation-dialog/geolocation-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'slx-geolocation-grid',
  templateUrl: 'geolocation-grid.component.html',
  styleUrls: ['geolocation-grid.component.scss']
})
export class GeolocationGridComponent implements OnInit, OnDestroy {
  public records: GeolocationEntity[];
  public gridState: KendoGridStateHelper<any>;
  public isDisabled: boolean;
  public appConfig: IApplicationConfig;
  public orgLevel: OrgLevel;

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;
  private highlightedRows: number[] = [];
  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: GeolocationManagementService, private modalService: ModalService) {
    this.gridState = new KendoGridStateHelper<any>();
    this.gridState.view = null;
    this.gridState.state.sort = [{ field: 'employee.name', dir: 'asc' }];
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.subscriptions.entitySelected = this.manService.subscribeToOrgLevelChanged((orgLevel: OrgLevel) => {
      this.orgLevel = orgLevel;
    });

    this.subscriptions.loaded = this.manService.subscribeToEntitiesLoaded((records: GeolocationEntity[]) => {
      this.records = records;
      this.refreshGrid();
    });

    this.subscriptions.refresh = this.gridState.onRefreshGrid.subscribe(() => this.refreshGrid());

    this.subscriptions.buttons = this.manService.subscribeToGeoEvent((event: IGeolocationEvent) => {
      if (event.isSave || event.isUpdate || event.isCancel) {
        this.clearGridSelection();
      } else if (event.isAdd) {
        this.isDisabled = true;
      } else if (event.isExportToPDF) {
        this.grid.saveAsPDF();
      } else if (event.isExportToExcel) {
        this.grid.saveAsExcel();
      }
    });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onClickEdit(entity: GeolocationEntity): void {
    this.isDisabled = true;
    this.manService.entitySelected(entity);
    this.highlightedRows.push(entity.id);
  }

  public onClickDelete(entity: GeolocationEntity, acceptPopover: PopoverContentComponent): void {
    this.deleteEntity(entity);
    acceptPopover.hide();
  }

  public onClickCancel(acceptPopover: PopoverContentComponent): void {
    acceptPopover.hide();
  }

  public onClickMap(entity: GeolocationEntity, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const coords = entity.location();
    const options = new GeolocationDialogOptions(coords.lat, coords.lng, 640, 508, 17);
    GeolocationDialogComponent.openDialog(options, this.modalService);
  }

  public canEdit(entity: GeolocationEntity): boolean {
    return entity.orgLevelId === _.get(this.orgLevel, 'id');
  }

  public isParentOrgLevel(entity: GeolocationEntity): boolean {
    return this.manService.isParentOrgLevel(_.get(this.orgLevel, 'id'), entity.orgLevelId);
  }

  public clearGridSelection(): void {
    this.highlightedRows.length = 0;
    this.isDisabled = false;
  }

  public isRowSelected(): (e: RowArgs) => boolean {
    return (event: RowArgs): boolean => {
      const entityId = (event.dataItem as GeolocationEntity).id;
      return _.indexOf(this.highlightedRows, entityId) !== -1;
    };
  }

  private async deleteEntity(entity: GeolocationEntity): Promise<void> {
    await this.manService.deleteGeolocation(entity);
    this.manService.loadGeolocations(entity.orgLevelId);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.records, this.gridState.state);
  }
}

import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ReplaySubject ,  Subject ,  Observable ,  Subscription } from 'rxjs';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { ManagementBaseService } from '../../../core/services/index';
import { Actions } from '../../../core/models';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ChangeManagementService } from '../../../common/services/index';
import { PbjOrganizationContainer } from '../../models/pbj-organizations/pbj-organizations-container';
import { IConfigutrationContainer } from '../../models/configiration-container.interface';
import { PbjOrganization, PbjOrganizationModel } from '../../models/index';
import { PbjOrganizationsApiService } from './pbj-organizations-api.service';
import { OrgLevel } from '../../../state-model/models/index';


@Injectable()
export class PbjOrganizationsConfigurationManagementService extends ManagementBaseService<PbjOrganizationContainer, any> implements IConfigurationManagementService {

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    public get container(): IConfigutrationContainer {
        return this.m_container;
    }

    // user can't add new items in this component
    public get isEditingNewItem(): boolean {
        return false;
    }

    public editingItem: any;
    public addItemCmd$: ReplaySubject<any>;
    public editItemCmd$: ReplaySubject<any>;
    public viewRefresh$: Subject<boolean>;
    public removeItemsCmd$: ReplaySubject<any>;
    public onItemSaved$: ReplaySubject<PbjOrganizationModel>;

    @unsubscribeInService()
    private orgLevelSubscription: Subscription;
    private currentOrgLevel: OrgLevel;

    private m_container: PbjOrganizationContainer;

    constructor(public changeService: ChangeManagementService, private api: PbjOrganizationsApiService) {
        super();
        this.removeItemsCmd$ = new ReplaySubject<any>();
        this.addItemCmd$ = new ReplaySubject<PbjOrganizationModel>();
        this.editItemCmd$ = new ReplaySubject<PbjOrganizationModel>();
        this.onItemSaved$ = new ReplaySubject<PbjOrganizationModel>();
        this.viewRefresh$ = new Subject<boolean>();
    }

    public init(): void {
        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            if (_.isNumber(orgLevel.id)) {
                this.currentOrgLevel = orgLevel;
                this.fetchRecords();
            }
        });
    }

    public markAsDirty(): void {
        this.changeService.changeNotify();
    }

    //#region IConfigurationManagementService implementation

    // no add or remove actions planned for this component
    public onRemoveItem(itemToDelete: PbjOrganizationModel): void {
        _.noop();
    }

    public onAddItem(item: any): void {
        _.noop();
    }

    public setSelectedCount(count: number): void {
        _.noop();
    }

    public onEditItem(item: any): void {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    }

    public onCancelEditItem(): void {
        this.changeService.clearChanges();
        this.editingItem = null;
        this.editItemCmd$.next(null);
    }

    public onSaveItem(info: { dataItem: PbjOrganizationModel, isNew: boolean }): void {
        this.updateItem(info.dataItem);
    }

    //#endregion IConfigurationManagementService implementation

    protected fetchRecords(): void {
        this.onStateChanged$.next({ isLoading: true });
        this.api.getPbjOrganizations()
            .then((result: PbjOrganizationContainer) => {
                this.changeService.clearChanges();
                this.m_container = result;
                this.editingItem = null;
                this.onLoaded$.next(this.m_container);
                this.onStateChanged$.next({ isLoading: false });
            }).catch(() => {
                this.onStateChanged$.next({ isLoading: false });
            });

    }

    protected updateItem(item: PbjOrganizationModel): void {
        this.onStateChanged$.next({ isLoading: true });
        this.api.saveOrganization(item, item.pbjOrganization.id)
            .then(() => {
                this.changeService.clearChanges();
                this.onItemSaved$.next(this.editingItem);
                this.editingItem = null;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            }).catch(() => {
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    private updateOrganizationModel(from: PbjOrganizationModel, to: PbjOrganizationModel): void {
        to.pbjOrganization = from.pbjOrganization;
        to.lastUpdateDate = from.lastUpdateDate;
        to.lastUpdateUsername = from.lastUpdateUsername;
    }
}


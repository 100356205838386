  import * as _ from 'lodash';
  import * as moment from 'moment';

  import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
  import { Router, ActivatedRoute } from '@angular/router';
  import { Subscription } from 'rxjs';

  import { appConfig, IApplicationConfig } from '../../../../../app.config';
  import { unsubscribe, destroyService } from '../../../../../core/decorators/index';
  import { OrgLevel } from '../../../../../state-model/models/index';

  import { PbjReconciliationManagementService } from '../../../services/index';
  import { PbjReconciliationEmployee } from '../../../models/index';

  @Component({
    moduleId: module.id,
    selector: 'slx-pbj-reconciliation-employee',
    templateUrl: 'pbj-reconciliation-employee.component.html',
    styleUrls: ['pbj-reconciliation-employee.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class PbjReconciliationEmployeeComponent implements OnInit, OnDestroy {
    public orgLevel: OrgLevel;
    public appConfig: IApplicationConfig;
    public expanded: boolean;
    public currentEmpId: number;
    public currentEmpName: string;
    public parentOrganizationName: string;
    public isLoading: boolean;

    @destroyService()
    private managementService: PbjReconciliationManagementService;
    @unsubscribe()
    private orgLevelSubscription: Subscription;
    @unsubscribe()
    private reconSubscription: Subscription;
    @unsubscribe()
    private loadStatusSubscription: Subscription;

    constructor(
      managementService: PbjReconciliationManagementService,
      private router: Router,
      private route: ActivatedRoute,
      private cdr: ChangeDetectorRef
    ) {
      this.appConfig = appConfig;
      this.managementService = managementService;
      this.currentEmpId = 0;
      this.isLoading = true;
    }

    public ngOnInit(): void {
      this.managementService.init(this.router, this.route, this.expanded = true);

      this.reconSubscription = this.managementService.subscribeToReconEmployee((data: PbjReconciliationEmployee) => {
        this.currentEmpId = data.employeeId;
        this.currentEmpName = data.employeeName;
      });

      this.loadStatusSubscription = this.managementService.onLoadStatus$.subscribe((state: boolean) => {
        this.isLoading = state;
        this.cdr.detectChanges();
      });
      this.orgLevelSubscription = this.managementService.subscribeToOrgLevel((orgLevel: OrgLevel) => {
        this.orgLevel = orgLevel;
        this.cdr.detectChanges();
      });
    }

    public ngOnDestroy(): void {
      // See #issueWithAOTCompiler
      this.managementService.destroy();
    }
  }

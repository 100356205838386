import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  ReplaySubject ,  Observable ,  Subscription ,  Subject } from 'rxjs';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ManagementBaseService } from '../../../core/services/index';

import { ChangeManagementService, ModalService, StateManagementService, ComponentStateStorageService } from '../../../common/services/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupApiService, ProductModuleMapService } from '../../../organization/services/index';
import { RolesApiService } from './roles-api.service';
import {
  RolesContainer, Role, SaveRolesRequest, RoleRightRecord, RoleFieldRecord,
  RolesProfileRow, RolesSectionRow, RolesSubsectionRow, RolesFieldRow, RoleField, RoleRight,
  RolesRightGroupRow, RolesRightRow, RolesRightModuleRow,
  RolesMenuModuleGroupRow, RolesMenuModuleRow, RolesMenuRow, RolesSubmenuRow,
  RoleMenuModule, RoleMenu, RoleSubmenu, RoleMenuModuleRecord,
  RoleComponentsModule, RoleComponent,
  RolesComponentsModuleGroupRow, RolesComponentsModuleRow, RolesComponentRow, RoleComponentsModuleRecord,
  RoleColumnsState, RoleColumn
} from '../../models/index';

import { IControlState, IColumnSettings, StateResetTypes } from '../../../core/models/index';
import { ProductModule } from '../../../organization/models/index';
import { ConfirmOptions, ConfirmDialogComponent } from '../../../common/components/index';

@Injectable()
export class RolesManagementService extends ManagementBaseService<RolesContainer, any> {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public roleRightFilterChanged$: ReplaySubject<{ filter: CompositeFilterDescriptor, type: string }>;
  public rolesColumnsStateChanged$: BehaviorSubject<RoleColumnsState>;
  public clearChanges$: Subject<any>;
  public currentOrgLevel: OrgLevel;
  public container: RolesContainer;

  @unsubscribeInService()
  private loadSubscription: Subscription;
  @unsubscribeInService()
  private initSubscription: Subscription;

  private apiService: RolesApiService;
  private lookupApiService: LookupApiService;
  private readonly componentId: string = 'UserRolesComponent';
  private readonly controlName: string = 'rolesColumns';
  private readonly resetBy: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;

  constructor(
    apiService: RolesApiService,
    lookupApiService: LookupApiService,
    private productModuleMapService: ProductModuleMapService,
    public changeService: ChangeManagementService,
    private stateService: StateManagementService,
    private storageService: ComponentStateStorageService,
    private modalService: ModalService
  ) {
    super();
    this.clearChanges$ = new Subject();
    this.roleRightFilterChanged$ = new ReplaySubject(1);
    this.rolesColumnsStateChanged$ = new BehaviorSubject<RoleColumnsState>(new RoleColumnsState());
    this.apiService = apiService;
    this.lookupApiService = lookupApiService;

    this.stateService.init(this.componentId);

    this.initSubscription = this.stateService.onInit$.subscribe(() => {
      this.loadAccessTable();
    });

    this.subscribeToLoad(false);
  }

  public reLoadAccessTable(): void {
    this.subscribeToLoad(true);
    this.loadAccessTable();
  }

  public loadAccessTable(): void {
    this.onLoadStatusChanged(true);
    this.apiService.getRoleAccessTable()
      .then((container: RolesContainer) => {
        this.container = container;
        this.onLoaded(container);
        this.onLoadStatusChanged(false);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public roleRightsFilterChanged(filter: CompositeFilterDescriptor, type: string): void {
    const data: { filter: CompositeFilterDescriptor, type: string } = { filter, type };
    this.roleRightFilterChanged$.next(data);
  }

  public recalcRights(roleId: number): void {
    _.forEach(this.container.rightGroupRows, (row: RolesRightGroupRow) => {
      row.recalcStatus(roleId);
    });
    this.changeNotify$.next('recalcRights');
  }

  public async saveChanges(): Promise<any> {
    let req: SaveRolesRequest = new SaveRolesRequest();
    req.roleFields = [];
    req.roleRights = [];
    req.otherRoleRights = [];
    req.roleMenuModules = [];
    req.roleComponentModules = [];

    //collect dirty fields
    _.forEach(this.container.profileRows, (profile: RolesProfileRow) => {
      _.forEach(profile.childRows, (section: RolesSectionRow) => {
        _.forEach(section.childRows, (subsection: RolesSubsectionRow) => {
          _.forEach(subsection.childRows, (field: RolesFieldRow) => {
            _.forEach(field.roles, (role: Role) => {
              let f: RoleField = field.mapByRole[role.id];
              if (f.isDirty) {
                let fRecord: RoleFieldRecord = new RoleFieldRecord();
                fRecord.field = f;
                fRecord.roleId = role.id;
                fRecord.sectionName = section.mapByRole[role.id].name;
                fRecord.subsectionName = subsection.mapByRole[role.id].name;
                req.roleFields.push(fRecord);
              }
            });
          });
        });
      });
    });
    //collect dirty rights
    _.forEach(this.container.rightGroupRows, (group: RolesRightGroupRow) => {
      _.forEach(group.childRows, (rightModule: RolesRightModuleRow) => {
        _.forEach(rightModule.childRows, (right: RolesRightRow) => {
          _.forEach(right.roles, (role: Role) => {
            let r: RoleRight = right.mapByRole[role.id];
            if (r.isDirty) {
              let rRecord: RoleRightRecord = new RoleRightRecord();
              rRecord.roleId = role.id;
              rRecord.rightId = r.id;
              rRecord.isEnabled = r.isEnabled;
              req.roleRights.push(rRecord);
            }
          });
        });
      });
    });
    //collect dirty other rights
    _.forEach(this.container.otherRightGroupRows, (group: RolesRightGroupRow) => {
      _.forEach(group.childRows, (rightModule: RolesRightModuleRow) => {
        _.forEach(rightModule.childRows, (right: RolesRightRow) => {
          _.forEach(right.roles, (role: Role) => {
            let r: RoleRight = right.mapByRole[role.id];
            if (r.isDirty) {
              let rRecord: RoleRightRecord = new RoleRightRecord();
              rRecord.roleId = role.id;
              rRecord.rightId = r.id;
              rRecord.isEnabled = r.isEnabled;
              req.otherRoleRights.push(rRecord);
            }
          });
        });
      });
    });
    //collect dirty menus
    _.forEach(this.container.menuModuleGroupRows, (groupModuleRow: RolesMenuModuleGroupRow) => {
      _.forEach(groupModuleRow.childRows, (menuModuleRow: RolesMenuModuleRow) => {
        _.forEach(menuModuleRow.childRows, (menuRow: RolesMenuRow) => {
          _.forEach(menuRow.roles, (role: Role) => {
            let m: RoleMenu = menuRow.mapByRole[role.id];
            if (m.isDirty) {
              let mRecord: RoleMenuModuleRecord = new RoleMenuModuleRecord();
              mRecord.menuId = m.id;
              mRecord.isEnabled = m.isEnabled;
              mRecord.roleId = role.id;
              req.roleMenuModules.push(mRecord);
            }
          });
          _.forEach(menuRow.childRows, (submenuRow: RolesSubmenuRow) => {
            _.forEach(submenuRow.roles, (role: Role) => {
              let f: RoleSubmenu = submenuRow.mapByRole[role.id];
              if (f.isDirty) {
                let sRecord: RoleMenuModuleRecord = new RoleMenuModuleRecord();
                sRecord.menuId = f.id;
                sRecord.isEnabled = f.isEnabled;
                sRecord.roleId = role.id;
                req.roleMenuModules.push(sRecord);
              }
            });
          });
        });
      });
    });
    //collect dirty components

    _.forEach(this.container.componentsModuleGroupRows, (groupModuleRow: RolesComponentsModuleGroupRow) => {
      _.forEach(groupModuleRow.childRows, (componentsModuleRow: RolesComponentsModuleRow) => {
        _.forEach(componentsModuleRow.childRows, (componentRow: RolesComponentRow) => {
          _.forEach(componentRow.roles, (role: Role) => {
            let m: RoleComponent = componentRow.mapByRole[role.id];
            if (m.isDirty) {
              let mRecord: RoleComponentsModuleRecord = new RoleComponentsModuleRecord();
              mRecord.componentId = m.id;
              mRecord.isEnabled = m.isEnabled;
              mRecord.roleId = role.id;
              req.roleComponentModules.push(mRecord);
            }
          });
        });
      });
    });

    //collect dirty product modules
    const modules = _.filter(this.container.productModules, (m: ProductModule) => m.isDirty);
    req.productModules = _.map(modules, (m: ProductModule) => this.productModuleMapService.mapToProductModule(m));
    let turnOfModules = _.filter(modules, (m: ProductModule) => m.isDisabled);
    if (turnOfModules.length > 0) {
      const names = _.map(turnOfModules, (m: ProductModule) => `"${m.name}"`);
      const checkString = '"Message Center"'
      _.remove(names, (name) => name == checkString);
      if (names.length) {
        const res = await this.confirmModules(_.join(names));
        if (!res) {
          return;
        }
      }
    }
    this.onLoadStatusChanged(true);
    this.apiService.saveRoleAccessTable(req)
      .then((container: RolesContainer) => {
        this.container = container;
        this.onLoaded(container);
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
        this.clearChanges$.next();
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
        this.clearChanges$.next();
      });
  }

  public async confirmModules(modulesList: string): Promise<boolean> {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.className = 'slx-confirm-wrap';
    options.width = 500;
    options.height = 250;
    return new Promise((resolve, reject) => {
      ConfirmDialogComponent.openDialog(
        'Confirmation',
        `Are you sure you want to Turn OFF ${modulesList}?\r\nIf yes, you will lose all data for ${modulesList} and this cannot be undone.\r\nData entered in V6 will be lost once the nightly process is run to reactivate the ${modulesList} in V5`,
        this.modalService,
        (result: boolean) => {
          resolve(result);
        }, options);

    });
  }
  public dicardChanges(): void {
    this.changeService.clearChanges();
    this.clearChanges$.next();
    this.reLoadAccessTable();
  }

  public onChangeNotify(source: string): void {
    this.changeService.changeNotify(source);
    this.changeNotify$.next(source);
  }

  public changeRolesColumnsState(roleColumnsState: RoleColumnsState): void {
    this.saveControl(roleColumnsState.columns);
    this.rolesColumnsStateChanged$.next(roleColumnsState);
  }

  private subscribeToLoad(skipFirstCallSubscription: boolean): void {
    let firstCall: boolean = true;
    this.loadSubscription = this.onLoaded$.subscribe((container: RolesContainer) => {
      if (skipFirstCallSubscription && firstCall) {
        firstCall = false;
        return;
      }

      const restoredFilter: RoleColumn[] = this.restoreControl();
      const roleColumnsState: RoleColumnsState = new RoleColumnsState(container.roles);
      if (_.isArray(restoredFilter)) {
        if (_.size(restoredFilter) !== _.size(container.roles)) {
          let newColumns = _.differenceWith(roleColumnsState.columns, restoredFilter, _.isEqual);
          _.each(newColumns, (col: RoleColumn) => col.visible = true);
        }
        roleColumnsState.applyState(restoredFilter);
      } else {
        let defaultRoles = _.slice(roleColumnsState.columns, 0, 5);
        _.each(defaultRoles, r => r.visible = true);
        roleColumnsState.applyFilter(defaultRoles);
      }
      this.changeRolesColumnsState(roleColumnsState);
      this.loadSubscription.unsubscribe();
      firstCall = false;
    });
  }

  private saveControl(columns: RoleColumn[]): void {

    this.storageService.setControlState(
      this.stateService.componentKey,
      this.controlName,
      {
        value: columns
      },
      this.resetBy
    );
  }

  private restoreControl(): RoleColumn[] {
    const state: IControlState = this.storageService.getControlState(this.stateService.componentKey, this.controlName);
    const savedColumns: RoleColumn[] = _.get(state, 'value');
    if (_.isArray(savedColumns)) {
      return savedColumns;
    }
    return null;
  }
}

import { Component, OnInit, Input, OnDestroy, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

import { Subscription ,  Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../../app.config';

import { PunchLogEntry } from '../../../models/index';
import { PunchLogsManagementService } from '../../../services/punches/punch-logs-management.service';
import { mutableSelect, unsubscribe, destroyService } from '../../../../core/decorators/index';


@Component({
  moduleId: module.id,
  selector: 'slx-punch-logs',
  templateUrl: 'punch-logs.component.html',
  styleUrls: ['punch-logs.component.scss'],
  providers: [PunchLogsManagementService]
})
export class PunchLogsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public employeeId: number;
  @Input()
  public employeeName: number;

  public state: {
    isLoading: boolean;
  };
  @Input()
  public date: Date;

  public appConfig: IApplicationConfig;
  public groupName: string = 'PunchLogs';

  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;

  @destroyService()
  private managementService: PunchLogsManagementService;
  constructor(managementService: PunchLogsManagementService) {
    this.managementService = managementService;
    this.state = {
      isLoading: false,
    };
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onDateChange(): void {
    this.reload();
  }

  public onExport(): void {
    this.managementService.export();
  }

  public reload(): void {
    if (!this.employeeId || !this.date) {
      return;
    }
    let startDate = moment(this.date).startOf('day').toDate();
    let endDate = moment(this.date).endOf('day').toDate();
    this.managementService.reloadLogs(startDate, endDate, this.employeeId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['employeeId'] || changes['date']) {
      this.reload();
    }
  }
}

<slx-spinner [show]="isLoading">
    <div class="slx-high-box workers-compensation-configuration">
      <div class="slx-high-box__body">

          <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
          [data]="gridState.view"
          [skip]="gridState.state.skip"
          [sortable]="{ mode: 'multiple' }"
          [sort]="gridState.state.sort"
          [filterable]="'menu'"
          [filter]="gridState.state.filter"
          [pageable]="isPageable"
          [pageSize]="pageSize"
          [skip]="gridState.state.skip"
          (dataStateChange)="gridState.dataStateChange($event)"

          [slxKendoGridFiltersState]="gridState"
          >

            <kendo-grid-column title="Plan" width="200" field="name"
               [sortable]="true" filter="text">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Plan</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a (click)="onClickBenefit(dataItem)"><strong>{{ dataItem.name }}</strong></a>
              </ng-template>
            </kendo-grid-column>


            <kendo-grid-column title="Group" width="100"
               field="benefitGroupName" [sortable]="true" filter="text">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Group</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.benefitGroupName }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Expiration Date" width="150" field="endDate" filter="date"
              [sortable]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Expiration Date</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.endDate | amDateFormat: appConfig.dateFormat }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-messages filterMenuTitle="Filter">
            </kendo-grid-messages>  
          </kendo-grid>

      </div>
    </div>
  </slx-spinner>

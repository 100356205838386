import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { PagingData } from '../../../../core/models/index';
import { mutableSelect, unsubscribe, unsubscribeAll } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models';
import { MessageSortingOptions } from '../../models/index';
import { ModalService } from '../../../../common/services/index';
import { SmsComposeNewDialogComponent } from '../sms-compose-new-dialog/sms-compose-new-dialog.component';
import { MessageListComponent } from '../message-list/message-list.component';
import { MessagesManagementService } from '../../services/messages-management.service';
import { scheduleMicrotask } from '../../../../core/utils/index';
import { MessageNotificationService } from '../../../../portal/services/notifications/msg-notifications.service';
import { SlateMessagesManagementService } from '../../services/slate-messages-management.service';
import { SlateMessageGroupInfo } from '../../models/slate-message-group-info';
import { ComposeNewTabsPermissions } from '../../models/ComposeNewTabsPermissions';
@Component({
  moduleId: module.id,
  selector: 'slx-message-center',
  templateUrl: 'message-center.component.html',
  styleUrls: ['message-center.component.scss']
})
export class MessageCenterComponent implements OnInit, OnDestroy {
  public canSendSMS: boolean = false;
  public canSendSlate: boolean = false;
  public canViewSlateTab: boolean = false;
  public canViewSmsTab: boolean = false;
  public activeTab: number = 0;
  public isLoading: boolean = true;
  public isAllSelected: boolean;
  public collapsed: StringMap<boolean>;
  public pagingData: PagingData;
  public pageSize: number = 100;
  public loadOlderThanSixMonths = false;
  public sortingOptions = Object.values(MessageSortingOptions);
  public archiveReadUnreadCount: any;
  public smsReadUnreadCount: any;
  public isMyMessage: boolean;
  public selectedSlateMessage: SlateMessageGroupInfo;
  public slateMessagesCount: number;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private orgLevel: OrgLevel;
  public isArchiveButtonClicked: boolean = false;
  public isRestoreButtonClicked: boolean = false;
  public isMarkAsReadButtonClicked: boolean = false;
  public isMarkAsUnreadButtonClicked: boolean = false;
  public selectedMessageIds: string[] = [];

  public selectedReadIds: string[] = [];
  public selectedUnreadIds: string[] = [];

  @ViewChild(MessageListComponent) childComponent: MessageListComponent;
  public isExpanded: boolean = false;
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<any>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  orgLevelID = 1;
  selectedId = '';
  selectedCheckboxEvent = '';
  isDisabled = false;
  constructor(private modalService: ModalService, private messageManService: MessagesManagementService,
    private slateMessageManagementService: SlateMessagesManagementService,
    private messageNotificationService: MessageNotificationService) {
  }


  public ngOnInit() {
    this.messageManService.isMsgCenterLoaded = true;
    this.isMyMessage = this.messageManService.restoreFilterState();

    this.orgLevelSubscription = this.orgLevel$.subscribe((level: OrgLevel) => {
      if (level && level.id && ((this.orgLevel && (level.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevel = new OrgLevel();
        this.orgLevel.id = level.id;
        this.orgLevelID = level.id;
      }
    });

    this.subscriptions.id = this.messageManService
      .subscribeToMessageCount((v) => {
        this.smsReadUnreadCount = v;
      });
    this.subscriptions.changetab = this.messageManService
      .subscribeChangeActiveTab((v) => {
        this.activeTab = 0;

      });
    this.subscriptions.id = this.messageManService
      .subscribeToGetMessageId((v) => {
        this.selectedId = v;

      });

    this.subscriptions.unreadSmsCount = this.messageNotificationService.subscribeToUnreadSmsCount((res => {
      this.smsReadUnreadCount = res;
    }));
    this.subscriptions.unreadArchiveCount = this.messageNotificationService.subscribeToUnreadArchiveCount((res => {
      this.archiveReadUnreadCount = res;
    }));

    this.messageManService.getComposeNewTabsPermissions(this.orgLevelID).then((cNewTabs: ComposeNewTabsPermissions)=>{
      this.canSendSMS = cNewTabs.canSendSMS;
      this.canSendSlate = cNewTabs.canSendSlate;
      this.canViewSlateTab = cNewTabs.canViewSlateTab;
      this.canViewSmsTab = cNewTabs.canViewSmsTab;

      if(this.canViewSmsTab)
      {
      this.activeTab = 0;
      this.getUnreadCount();
      }
      else if(this.canViewSlateTab)
      {
        this.onClickTab(2);
      }

    });
    this.subscriptions.selectedSlateMessage = this.slateMessageManagementService.selectedMessage$.subscribe((res => {
      this.selectedSlateMessage = res;
    }));
  }

  isSelectedUnreadIdsEmpty() {
    const obj = this.setCountObj();
    if ((this.selectedUnreadIds.length > 0 || this.selectedCheckboxEvent === 'Unread' || this.selectedCheckboxEvent === 'All')) {
      return false;
    } else {
      return true;
    }
  }

  isSelectedReadIdsEmpty() {
    const obj = this.setCountObj();
    if ((this.selectedReadIds.length > 0 || this.selectedCheckboxEvent === 'Read' || this.selectedCheckboxEvent === 'All') && (obj.total !== obj.unRead)) {
      return false;
    } else {
      return true;
    }
  }

  public composeNewDialog() {
    SmsComposeNewDialogComponent.openDialog(this.modalService, (result: boolean) => {
      if (result) { }
    });
  }
  public onClickTab(tab: number): void {
    if (this.activeTab === tab || this.isDisabled) return;
    this.activeTab = tab;
    this.isActiveMenu(this.activeTab);
    this.getUnArchiveCount();
    this.selectedId = '';
  }

  public isActiveMenu(tabOrder: number): boolean {
    return (this.activeTab === tabOrder) ? true : false;
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
    this.messageManService.isMsgCenterLoaded = false;
  }

  updateMsgCenterSelectedMessageIds(event) {
    this.selectedMessageIds = event;
  }

  updateMessagesCount(event) {
    this.slateMessagesCount = event;
  }

  getArchiveReadUnreadCount(event) {
    this.getUnArchiveCount();
  }

  archiveMessages() {
    this.childComponent.updateArchiveMessages();
  }

  restoreMessages() {
    this.childComponent.updateUnArchiveMessages();
    this.getUnArchiveCount();
  }

  markAsReadMessages() {
    this.childComponent.updateReadMessages();
  }

  markAsUnreadMessages() {
    this.childComponent.updateUnreadMessages();
  }

  public getUnreadCount() {
    this.messageNotificationService.getUnreadSmsCount(this.orgLevelID, this.isMyMessage);
  }

  getUnArchiveCount() {
    this.messageNotificationService.getUnreadArchiveCount(this.orgLevelID, this.isMyMessage);
  }

  getSelectedCheckboxEvent(event) {
    if (this.activeTab === 0) {
      if ((event === 'Unread' && this.smsReadUnreadCount.unRead > 0) || (event === 'Read' && this.smsReadUnreadCount.total > 0 && this.smsReadUnreadCount.total !== this.smsReadUnreadCount.unRead) || (event === 'All' && this.smsReadUnreadCount.unRead > 0 && this.smsReadUnreadCount.total > 0)) {
        this.selectedCheckboxEvent = event;
      } else if (event === 'All' || event === 'None') {
        this.selectedCheckboxEvent = '';
      }
    } else if (this.activeTab === 1) {
      if ((event === 'Unread' && this.archiveReadUnreadCount.unRead > 0) || (event === 'Read' && this.archiveReadUnreadCount.total > 0 && this.archiveReadUnreadCount.total !== this.archiveReadUnreadCount.unRead) || (event === 'All' && this.archiveReadUnreadCount.unRead > 0 && this.archiveReadUnreadCount.total > 0)) {
        this.selectedCheckboxEvent = event;
      } else if (event === 'All' || event === 'None') {
        this.selectedCheckboxEvent = '';
      }
    }

  }

  setCountObj() {
    let obj = { unRead: 0, total: 0 };
    if (this.activeTab === 0 && this.smsReadUnreadCount) {
      obj.unRead = this.smsReadUnreadCount.unRead;
      obj.total = this.smsReadUnreadCount.total;
    } else if (this.activeTab === 1 && this.archiveReadUnreadCount) {
      obj.total = this.archiveReadUnreadCount.total;
      obj.unRead = this.archiveReadUnreadCount.unRead;
    }
    return obj;
  }

  disabledTab(event) {
    this.isDisabled = event;
  }

  public onChangeFilter(): void {
    if(this.activeTab === 1)
    {
    this.getUnArchiveCount();
    }
    this.messageManService.updateMessageFilter(this.isMyMessage);
  }
}

<kendo-grid class="slx-full-height slx-blue-grid slx-white-cells"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  [resizable]="true"
  (dataStateChange)="gridState.dataStateChange($event)"
>
  <kendo-grid-column title="Name" field="loaRequest.employee.name" width="180" [class]="{'slx-padding-6': true}">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="flex align-items__center calendar-emp" [title]="dataItem.loaRequest?.employee?.name" (click)="onOpenLoaRequestDialog(dataItem)">
        <img class="calendar-emp__img" [employeeThumbinalSrc]="dataItem.loaRequest?.employee?.id" alt="Employee Photo" />
        <strong class="calendar-emp__text">{{ dataItem.loaRequest?.employee?.name }}</strong>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Info"
    width="200"
    [sortable]="false"
    [filterable]="false"
    [class]="{'slx-padding-0': true}"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Info</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="flex justify-content__space-between align-items__center req-type cursor-pointer"
        (click)="onOpenLoaRequestDialog(dataItem)"
      >
        <em class="req-type__color" [style.backgroundColor]="dataItem?.loaRequest?.typeColor"></em>
        <i class="fas req-type__icon"
          [style.color]="dataItem?.loaRequest?.typeColor"
          [ngClass]="{ 'fa-repeat': dataItem?.loaRequest?.isContinuous, 'fa-random': dataItem?.loaRequest?.isIntermittent }"
          aria-hidden="true"
        ></i>
        <span class="req-type__text flex__grow">
          <span class="req-type__info">Type: <strong>{{ dataItem?.loaRequest?.typeName }}</strong></span>
          <span class="req-type__info">Start: <strong>{{ getStartDate(dataItem) | amDateFormat: appConfig.dateFormat }}</strong></span>
          <span class="req-type__info">Est. Return: <strong>{{ getEndDate(dataItem) | amDateFormat: appConfig.dateFormat }}</strong></span>
        </span>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    width="500"
    [sortable]="false"
    [filterable]="false"
    [headerClass]="{'slx-padding-0': true}"
    [class]="{'slx-padding-0': true}"
  >
    <ng-template kendoGridHeaderTemplate>
      <slx-lm-calendar-grid-header [dateRange]="dateRange" [daysLength]="daysLength" [showNavButtons]="true"></slx-lm-calendar-grid-header>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <slx-lm-schedule-line
        [loaRequest]="dataItem"
        [dateRange]="dateRange"
        [daysLength]="daysLength"
        (clickOnDay)="onOpenLoaRequestDialog(dataItem)"
      ></slx-lm-schedule-line>
    </ng-template>
  </kendo-grid-column>
  
  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>


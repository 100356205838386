import { Component, OnInit, OnDestroy } from '@angular/core';
import { PayPolicy } from '../../models/pay-policies/models/pay-policy.model';
import { PayPolicyRule } from '../../models/pay-policies/models/pay-policy-rule.model';
import { Subscription, Observable } from 'rxjs';
import { OrgLevel } from '../../../state-model/models/index';
import { mutableSelect } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common/index';
import { appConfig } from '../../../app.config';
import { PayPoliciesManagementService } from '../../services/pay-policies/pay-policies-management.service';
import { AccessManagementService } from '../../services';
import { CopyPayCodesManagementService } from '../../services/copy-paycodes/copy-paycodes-management.service';
import { PaycodeExceptionModel } from '../../models/pay-code-details/models/paycode-exception.model';
import { Location } from '@angular/common';

@Component({
  selector: 'slx-pay-policies',
  templateUrl: 'pay-policies.component.html',
  styleUrls: ['pay-policies.component.scss'],
  providers: [
    AccessManagementService,
    PayPoliciesManagementService,
    CopyPayCodesManagementService
  ]
})
export class PayPoliciesComponent implements OnInit, OnDestroy {
  public currentTab: 'payPolicy' | 'shiftDiffPolicy' = 'payPolicy';
  public filteredPolicies: PayPolicy[] = [];
  public selectedPolicyId: string;
  public selectedPolicyRules: PayPolicyRule[] = [];
  public paycodeExceptions: PaycodeExceptionModel[] = [];
  public paycodeWithRules: any[] = [];
  public newPolicyId: string = '';
  public isPopupOpen: boolean = false;
  public organizationId: number;
  public orgLevelId: number;
  public appConfig = appConfig;
  public subscriptions: Subscription[] = [];
  public saveEnabled: boolean = false;

  public state = { isLoading: false };

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;  // Observable for orgLevel

  constructor(
    private management: PayPoliciesManagementService,
    private copyPayCodesManagementService: CopyPayCodesManagementService,
    private stateManagement: StateManagementService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.state.isLoading = true;
    this.subscriptions.push(
      this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
        if (orgLevel) {
          this.organizationId = orgLevel.organizationId;
          this.orgLevelId = orgLevel.id;
          this.loadPayPolicies(this.organizationId);
          this.loadPayCodes(this.organizationId);
        }
      })
    );
    this.bindPageLoadData();
    this.management.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  switchTab(tab: 'payPolicy' | 'shiftDiffPolicy'): void {
    this.currentTab = tab;
    this.loadPayPolicies(this.organizationId);
    this.loadPayCodes(this.organizationId);
  }

  loadPayPolicies(orgId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.state.isLoading = true;

      this.management.loadPayPolicies(orgId).then(() => {
        this.filteredPolicies = this.management.container.records.filter(
          policy => policy.type == (this.currentTab === 'payPolicy' ? 'P' : 'S')
        );

        if (this.filteredPolicies.length > 0) {
          this.selectedPolicyId = this.filteredPolicies[0].id;
          this.onPolicySelect();
          resolve(true); // Resolve the promise as successful
        } else {
          this.paycodeWithRules = [];
          this.state.isLoading = false;
          resolve(false); // Resolve the promise but indicate no policies were found
        }
      }).catch(error => {
        console.error('Error loading pay policies:', error);
        reject(error); // Reject the promise if there's an error
      }).finally(() => {
        this.state.isLoading = false; // Ensure the loading state is reset
      });
    });
  }

  loadPayCodes(orgId: number): void {
    this.copyPayCodesManagementService.loadPayCodes(orgId).then(() => {
      const groupFilter = this.currentTab === 'payPolicy'
        ? (groupId) => groupId != 14 && groupId != 10
        : (groupId) => groupId == 14;

      // Filter based on the group and eliminate duplicates by description
      const uniquePayCodesMap = new Map();
      this.copyPayCodesManagementService.container.records.forEach(code => {
        if (groupFilter(code.groupId) && !uniquePayCodesMap.has(code.exceptionDescription)) {
          uniquePayCodesMap.set(code.exceptionDescription, code);
        }
      });

      this.paycodeExceptions = Array.from(uniquePayCodesMap.values());
      this.mapPayCodesToRules();
    }).catch(error => {
      console.error('Error loading pay codes:', error);
    });
  }

  onPolicySelect(): void {
    if (this.selectedPolicyId) {
      this.state.isLoading = true;
      this.management.loadPayPolicyRulesByPolicyId(this.organizationId, this.selectedPolicyId).then(() => {
        this.mapPayCodesToRules();
        this.resetSaveButton();
        this.state.isLoading = false;
      }).catch(() => {
        this.state.isLoading = false;
      });
    }
  }

  openAddPopup(): void {
    this.isPopupOpen = true;
  }

  closeAddPopup(): void {
    this.isPopupOpen = false;
    this.newPolicyId = '';
  }

  goBack(): void {
    this.location.back();  // Go back to the previous page
  }

  addNewPolicy(): void {
    if (!this.newPolicyId) return;

    // Check for spaces in the policy name
    if (/\s/.test(this.newPolicyId)) {
      alert('Policy name cannot contain spaces!');
      return;
    }

    const existingPolicy = this.filteredPolicies.find(policy => policy.id === this.newPolicyId);
    if (existingPolicy) {
      alert('Policy with this ID already exists!');
      return;
    }

    const newPolicy: PayPolicy = {
      id: this.newPolicyId,
      organizationId: this.organizationId,
      description: this.newPolicyId,
      type: this.currentTab === 'payPolicy' ? 'P' : 'S',
      deleteInd: false,
      lastUpdatedDate: new Date(),
      lastUpdateId: 'System'
    };

    this.state.isLoading = true;
    this.management.createPayPolicy(this.organizationId, newPolicy).then(() => {
      this.loadPayPolicies(this.organizationId).then(success => {
        if (success) {
          this.selectedPolicyId = this.newPolicyId;  // Set the new policy as selected
          this.onPolicySelect();  // Refresh the table with the new policy
        }

        this.closeAddPopup();  // Close popup after saving and selecting
      });
    }).catch(error => {
      console.error('Error creating new policy:', error);
    }).finally(() => {
      this.state.isLoading = false;
    });
  }

  deletePolicy(): void {
    if (!this.selectedPolicyId) return;  // Ensure a policy is selected

    this.state.isLoading = true;

    // First, check if the policy is in use
    this.management.isPolicyUsed(this.organizationId, this.selectedPolicyId).then(isUsed => {
      this.state.isLoading = false;

      if (isUsed) {
        // Alert the user that the policy can't be deleted
        alert('This policy cannot be deleted because it is currently in use.');
      } else {
        // Confirm deletion with the user
        if (confirm('Are you sure you want to delete this policy?')) {
          this.state.isLoading = true;

          // Proceed to delete the policy
          this.management.deletePayPolicyById(this.organizationId, this.selectedPolicyId).then(() => {
            // Reload the policy list after deletion
            this.loadPayPolicies(this.organizationId);
          }).catch(error => {
            console.error('Error deleting policy:', error);
          }).finally(() => {
            this.state.isLoading = false;
          });
        }
      }
    }).catch(error => {
      this.state.isLoading = false;
      console.error('Error checking if policy is used:', error);
    });
  }

  mapPayCodesToRules(): void {
    const policyRules = this.management.container.policyRules;

    this.paycodeWithRules = this.paycodeExceptions.map(paycode => {
      const matchingRule = policyRules.find(rule => rule.exceptionId === paycode.id);

      return {
        id: matchingRule ? matchingRule.id : this.generateUniqueId(),  // Generate a new id if rule doesn't exist
        exceptionId: paycode.id,
        exceptionDescription: paycode.exceptionDescription,
        isActive: matchingRule ? matchingRule.isActive : false,
        description: matchingRule ? matchingRule.description : '',
        notes: matchingRule ? matchingRule.notes : '',
        paid: paycode.paidInd ? 'Y' : 'N',
        effectiveDate: matchingRule && matchingRule.startDate ? this.formatDate(matchingRule.startDate) : null,
        endDate: matchingRule && matchingRule.endDate ? this.formatDate(matchingRule.endDate) : null,
        hasChanged: false  // Track whether this row has been changed
      };
    });
  }

  handleDescriptionClick(event: MouseEvent, id: string): void {
    if (event) {
      event.preventDefault();
    }
    const newPath = 'apps/common/paycode_details?orgLevelId=' + this.orgLevelId + '&id=' + id;
    const url = window.location.origin + '/#/' + newPath;
    window.location.href = url;
  }

  generateUniqueId(): number {
    const existingIds = new Set(
      this.paycodeWithRules.map(paycode => paycode.id).concat(
        this.selectedPolicyRules.map(rule => rule.id)
      )
    );
    let newId: number;
    do {
      newId = Math.floor(Math.random() * 1000000) + 1;  // Generate a random id
    } while (existingIds.has(newId));
    return newId;
  }

  formatDate(date: string | Date): string {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');  // Ensure 2 digits for month
    const day = `${d.getDate()}`.padStart(2, '0');  // Ensure 2 digits for day
    const year = d.getFullYear();
    return [year, month, day].join('-');
  }

  onFieldChange(paycode: any): void {
    paycode.hasChanged = true;  // Mark this paycode as changed
    this.saveEnabled = true;  // Enable the save button
  }

  resetSaveButton(): void {
    this.saveEnabled = false;  // Reset save button to disabled
  }

  bindPageLoadData(): void {
    this.initServices();
    this.appConfig = appConfig;
  }

  saveChanges(): void {
    this.state.isLoading = true;
    const changedPaycodes = this.paycodeWithRules.filter(paycode => paycode.hasChanged);

    const rulesToSave = changedPaycodes.map(paycode => ({
      id: paycode.id,
      exceptionId: paycode.exceptionId,
      isActive: paycode.isActive,
      description: paycode.description,
      notes: paycode.notes,
      startDate: new Date('2009-01-01'),
      endDate: new Date('2079-06-06'),
      lastUpdatedDate: new Date(),
      policyId: this.selectedPolicyId,
      organizationId: this.organizationId,
      deleteInd: false
    }));

    this.management.saveMultiplePolicyRules(this.organizationId, this.selectedPolicyId, rulesToSave, changedPaycodes)
      .then(() => {
        this.resetSaveButton();
        this.state.isLoading = false;
      })
      .catch(error => {
        console.error('Error saving pay policy rules:', error);
        this.state.isLoading = false;
      });
  }

  private initServices(): void {
    this.stateManagement.init('PayPoliciesComponent');
  }
}
<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents cp-header">
      <div class="flex create-new-employers-block">
        <button type="button" [title]="tooltip"
                class="slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks"
                (click)="onAddNew()" [disabled]="!isSystemUser">
          <i class="fa fa-plus slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Create New Company</span>
        </button>
        <button type="button" [disabled]="!canEdit" (click)="onManageGroups()" [title]="tooltip"
                class="slx-button slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks group-btn">
          <i class="fas fa-edit slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Edit ALE Groups</span>
        </button>
        <button *ngIf="isAccessEmployers" class="slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks" (click)="navigateToPBJOrg()"
        >
          Organizations
        </button>
      </div>
      <div class="flex align-items__center justify-content__flex-end ">
        <div class="employers-action-cls">
          <slx-action-list class="slx-wide" *ngIf="canExport">
            <slx-action-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions <span class="caret"></span></slx-action-button>
            <popper-content #popperContent>
              <slx-actions-list-item (onClick)="onExportToExcel()">Export to Excel</slx-actions-list-item>
              <slx-actions-list-item (onClick)="onExportToPdf()">Export to PDF</slx-actions-list-item>
            </popper-content>
          </slx-action-list>
        </div>
      </div>
    </div>
    <div class="slx-high-box__body">
      <form #templateForm="ngForm" novalidate
            class="slx-high-box__body slx-main-content-indents">
        <kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells slx-cell-padding"
                    [data]="gridState.view"
                    [filterable]="'menu'"
                    [group]="gridState.state.group"
                    [selectable]="true"
                    [pageable]="false"
                    [sort]="gridState.state.sort"
                    [sortable]="{ mode: 'single' }"
                    [filter]="gridState.state.filter"
                    (filterChange)="filterChange($event)"
                    [resizable]="true"  
                    (selectionChange)="gridState.selectionChange($event)"
                    (dataStateChange)="gridState.dataStateChange($event)"
                    [scrollable]="'virtual'">
          <kendo-grid-command-column title="Edit" [width]="100" [minResizableWidth]="80">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <button [disabled]="!isSystemUser" (click)="onEditItems(dataItem)" [title]="tooltip" type="button" class="slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks grid-btn-pad">
                <i class="fas fa-edit" aria-hidden="true"></i>
              </button>
              <button [disabled]="!isSystemUser" [popover]="confirmDeleting" [title]="tooltip" class="slx-button slx-grey slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks grid-btn-pad">
                <i class="fas fa-trash" aria-hidden="true"></i>
              </button>
              <popover-content #confirmDeleting title="Are you sure you want to delete this Company?" placement="auto right">
                <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r" (click)="onClickDelete(true, confirmDeleting, dataItem)">Delete</button>
                <button type="button" class="slx-button slx-narrow" (click)="onClickDelete(false, confirmDeleting, file)">Cancel</button>
              </popover-content>
            </ng-template>
          </kendo-grid-command-column>
          <kendo-grid-column title="Employer" field="legalName" [filterable]="true" width="180">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align" [title]="dataItem.legalName">{{ dataItem.legalName }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="FEIN" field="feinWithoutDash" [filterable]="true" width="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align" [title]="dataItem.federalEIN">{{ dataItem.federalEIN }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Trade Name" field="tradeName" [filterable]="true" width="180">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align" [title]="dataItem.tradeName">{{ dataItem.tradeName }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="ALE Group" field="aleGroupName" [filterable]="true" width="120">
            <ng-template kendoGridGroupHeaderTemplate let-value="value">
              <span [title]="aleGroupName">{{value}}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Primary Contact" field="primaryContact" [filterable]="true" width="180">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align" [title]="dataItem.primaryContact">{{ dataItem.primaryContact }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Legal Address" field="legalAddress" [filterable]="true" width="200">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align" [title]="dataItem.legalAddress"> {{ dataItem.legalAddress }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Phone" field="phone" [filterable]="true" width="120">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align" [title]="dataItem.phone">{{ dataItem.phone }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-excel fileName="Employers.xlsx" [fetchData]="getGridData()">
            <kendo-excelexport-column title="Employer" field="legalName" [width]="300"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="FEIN" field="federalEIN" [width]="100"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Trade Name" field="tradeName" [width]="230"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="ALE Group" field="aleGroupName" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Primary Contact" field="primaryContact" [width]="160"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Legal Address" field="legalAddress" [width]="300"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Phone" field="phone" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          </kendo-grid-excel>
          <kendo-grid-pdf fileName="Employers.pdf" [allPages]="true">
            <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
            <kendo-grid-column field="legalName" title="Employer"></kendo-grid-column>
            <kendo-grid-column field="federalEIN" title="FEIN"></kendo-grid-column>
            <kendo-grid-column field="tradeName" title="Trade Name"></kendo-grid-column>
            <kendo-grid-column field="aleGroupName" title="ALE Group"></kendo-grid-column>
            <kendo-grid-column field="primaryContact" title="Primary Contact"></kendo-grid-column>
            <kendo-grid-column field="legalAddress" title="Legal Address"></kendo-grid-column>
            <kendo-grid-column field="phone" title="Phone"></kendo-grid-column>
          </kendo-grid-pdf>
          <kendo-grid-messages filterMenuTitle="Filter">
          </kendo-grid-messages>  
        </kendo-grid>
      </form>
    </div>
  </div>
</slx-spinner>

<label class="slx-multiselect" [ngClass]="{ 'slx-open': isOpen }">
  <span *ngIf="(externalPlaceholderText || placeholder) && externalPlaceholder" class="slx-multiselect__label">{{ externalPlaceholderText? externalPlaceholderText: placeholder }}</span>
  <kendo-multiselect #multiselect class="slx-multiselect__multiselect"
    [data]="options"
    [(ngModel)]="value"
    [textField]="titleField"
    [valueField]="valueField"
    [valuePrimitive]="valuePrimitive"
    [tagMapper]="getSelectedOptionsMapper()"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [clearButton]="false"
    [filterable]="true"
    (filterChange)="onFilterChange($event)"
    (removeTag)="onRemoveUnifiedOption()"
    (open)="onOpen()"
    (close)="onClose($event)"
    [virtual]="virtual"
  >
    <ng-template kendoMultiSelectItemTemplate let-dataItem>
      <span class="slx-multiselect-item">{{ dataItem[titleField] }}</span>
    </ng-template>
   
    <!-- Fix Bug#316893 - using CSS to display up/down arrow. instead of code. 
    
    <ng-template kendoSuffixTemplate>
      <button
        kendoButton
        (click)="handleToggleClick(multiselect)"
        fillMode="flat"
        style="height: 100%; background-color: white;"
      ><span style="vertical-align: middle;font-size: large;">&#9662;</span></button>
    </ng-template> 
    -->
  </kendo-multiselect> 
</label>
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ReportParameter } from '../../../models/index';
import { Observable ,  Subscription } from 'rxjs';
import { ReportsManagementService } from '../../../services/index';
import { LookupType } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';

@Component({
  moduleId: module.id,
  selector: 'slx-report-parameter',
  templateUrl: 'report-parameter.component.html',
  styleUrls: ['report-parameter.component.scss']
})
export class ReportParameterComponent implements OnInit{
  public IsPBJConsecutiveHrsEnabled: boolean;
  numberOfHrs : number = 8;
  public get orgLevel(): OrgLevel {
    return this.reportsManagementService.selectedOrgLevel;
  }

  @Input()
  public index: number;

  @Input()
  public parameter: ReportParameter;

  @Input()
  public parameters : ReportParameter[];
  public get dataTypeName(): string {
    if (this.parameter && this.parameter.dataType)
      return this.parameter.dataType.name;

    return undefined;
  }

  public get isPayCycle(): boolean {
    if (this.parameter && this.parameter.dataType) {
      return this.parameter.dataType.lookupName === LookupType.payCycles;
    }
    return false;
  }

  constructor(private reportsManagementService: ReportsManagementService, private appSettingsManageService:AppSettingsManageService) { }

  ngOnInit(): void {
    this.appSettingsManageService.getAppServerConfig().then((config: AppServerConfig) => {
      this.IsPBJConsecutiveHrsEnabled = config.IsPBJConsecutiveHrsEnabled;
    });
  }

  // public showOptions(selectHrsType){
  //   (this.parameter.name == 'Show_dates') 
  //   {
  //     this.isShowDatesChecked.emit(selectHrsType);
  //   }
  // }
}

import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Assert } from '../../../framework/index';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { ScheduleDefinitionsMapService, ApplicationStateBusService } from '../../../organization/services/index';

import { schedulerConfig } from '../../scheduler.config';
import { IndividualScheduleMapService } from './individual-schedule-map.service';

import {
  IIndSchOrgLevelEmployees, IndSchOrgLevelEmployees,
  IndSchEmployeeSchedulesRequest, IndSchDefWrapper,
  IndSchEntryWrapper,
  ScheduleEntryEditItem
} from '../../models/index';
import {
  IEmployeeScheduleDefinition, IScheduleEntryDefinition,
  ISaveIndividualScheduleRequest, ISaveMultipleIndividualScheduleRequest, ScheduleCycleMessages, IScheduleCycleMessages
} from '../../../organization/models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class IndividualScheduleApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private individualScheduleMapService: IndividualScheduleMapService,
    private scheduleDefinitionsMapService: ScheduleDefinitionsMapService,
    private urlParamsService: UrlParamsService,
    private applicationStateBusService: ApplicationStateBusService) {
  }

  public getOrgLevelEmployees(orgLevelId: number, startDate: Date, scheduleWeeksCount: number): Promise<IndSchOrgLevelEmployees> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${schedulerConfig.api.schedule.orglevel.employees}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({
        startDate: dateTimeUtils.convertToDtoString(startDate),
        scheduleWeeksCount: scheduleWeeksCount
      })
    });
    let promise: Promise<IndSchOrgLevelEmployees> = this.apiUtilService.request<IIndSchOrgLevelEmployees, Meta>(request)
      .then((response: ResponseBody<IIndSchOrgLevelEmployees, Meta>) => this.individualScheduleMapService.mapOrgLevelEmployees(response.data));
    return promise;
  }

  public getOrgLevelEmployeeScheduleDefinitions(req: IndSchEmployeeSchedulesRequest): Promise<IndSchDefWrapper> {
    Assert.isNotNull(req, 'req');
    const url: string = `${this.getOrgLevelApiRoot(req.orgLevelId)}/${schedulerConfig.api.schedule.orglevel.employee}/${req.employeeId}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({
        startDate: dateTimeUtils.convertToDtoString(req.startDate),
        scheduleWeeksCount: req.scheduleWeeksCount
      })
    });
    let promise: Promise<IndSchDefWrapper> = this.apiUtilService.request<IEmployeeScheduleDefinition, Meta>(request)
      .then((response: ResponseBody<IEmployeeScheduleDefinition, Meta>) => this.individualScheduleMapService.mapEmployeeScheduleDefinition(response.data, req.startDate, req.scheduleWeeksCount));
    return promise;
  }

  public saveEmployeeScheduleDefinitions(employeeId: number,  changedEntries: IndSchEntryWrapper[], datesToClear: Date[]): Promise<number> {
    Assert.isNotNull(changedEntries, 'records');
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getApiRoot()}/${employeeId}`;
    let modelDTO: ISaveIndividualScheduleRequest = this.individualScheduleMapService.mapToSaveIndividualScheduleRequest(employeeId, changedEntries, datesToClear);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.applicationStateBusService.resetCache('TimecardsApiServiceCache');
        return response.data;
      });
    return promise;
  }

  public saveScheduleDefinitions(req: ISaveMultipleIndividualScheduleRequest): Promise<ScheduleCycleMessages> {
    const url: string = `${this.getApiRoot()}`;
    let request = this.urlParamsService.createPostRequest(url, req);
    let promise: Promise<ScheduleCycleMessages> = this.apiUtilService.request<IScheduleCycleMessages, Meta>(request)
      .then((response: ResponseBody<IScheduleCycleMessages, Meta>) => this.scheduleDefinitionsMapService.mapGenerateScheduleMessages(response.data));
    return promise;
  }

  public getLastTenScheduleRecords(orglevelId: number, startDate: Date, endDate: Date): Promise<ScheduleEntryEditItem[]> {
    const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.schedule.root}/${schedulerConfig.api.schedule.orglevel.root}/${orglevelId}/${schedulerConfig.api.schedule.startDate}/${dateTimeUtils.convertToDtoString(startDate)}/${schedulerConfig.api.schedule.endDate}/${dateTimeUtils.convertToDtoString(endDate)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<ScheduleEntryEditItem[]> = this.apiUtilService.request<ScheduleEntryEditItem[], Meta>(request)
      .then((response: ResponseBody<ScheduleEntryEditItem[], Meta>) => this.scheduleDefinitionsMapService.mapEmpScheduleQuickEditEntries(response.data));
      return promise;
  }

  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}/${schedulerConfig.api.schedule.orglevel.root}/${orgLevelId}`;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}`;
  }
}

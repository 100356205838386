import { EmployeeTerminationReason } from './../../../../../organization/models/lookup/employee-termination-reason';
import { Lookup, LookupType } from './../../../../../organization/models/lookup/lookup-definition';
import { LookupService } from './../../../../../organization/services/lookup/lookup.service';
import { Component, OnInit, OnDestroy, Input, Host, ViewChild, NgZone } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';

import { EmployeeSectionsTerminationHistoryEntries, EmployeeSectionsTerminationHistoryEntry, EmployeeSectionsTemporalModel, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-terminations-history',
  templateUrl: 'employee-sections-terminations-history.component.html',
  styleUrls: ['employee-sections-terminations-history.component.scss']
})
export class EmployeeSectionsTerminationsHistoryComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input()
  public set employeeSectionsTerminationHistoryEntries(terminations: EmployeeSectionsTerminationHistoryEntries) {
    this.terminations = terminations;
  }
  @Input() public employeeId: number;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('form')
  public ngForm: NgForm;
  public terminateReasons: Lookup = null;
  public terminations: EmployeeSectionsTerminationHistoryEntries;
  public get terminationEntries(): EmployeeSectionsTerminationHistoryEntry[] {
    return this.terminations ? this.terminations.terminationEntries : null;
  }

  constructor(
    private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone, private lookupService: LookupService,) {
    super(decorator, ngZone);
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.terminations;
  }

  public ngOnInit(): void {
   
    this.lookupService.getLookup({ lookupType: LookupType.employeeTerminationReason2, employeeId: undefined, orgLevelId: undefined })
    .then((reason: Lookup) => {
       this.terminateReasons = reason;  
    });
  }
 public empreasons: EmployeeTerminationReason[] =null
  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmploymentTerminationsHistory(this.employeeId)
      .then((term: EmployeeSectionsTerminationHistoryEntries) => {
        this.terminations = term;
         this.empreasons =this.getUniqueReasons(this.terminations.terminationEntries);
            if(this.empreasons !== undefined)
            this.terminateReasons.items = _.sortBy(_.uniqBy([...this.terminateReasons.items,...this.empreasons],'reason'),'reason');
        this.stopProgress();
      })
      .catch(() => {
        this.stopProgress();
      });
  }

  private getUniqueReasons(entries :EmployeeSectionsTerminationHistoryEntry[]):EmployeeTerminationReason[]{
    let reasons =_.without(_.uniq(_.map(entries, 'reason.fieldValue[reason]')),undefined);
   return  _.map(reasons, v => this.mapTerminateReason(v))
    
  }
  public mapTerminateReason(data: string): EmployeeTerminationReason {
    if (!data ) {
      return null;
    }
    let value: EmployeeTerminationReason = new EmployeeTerminationReason();
    value.reason = data;
    return value;
  }
  
  protected doSave(effectiveDate: Date): void {
    this.employeeSectionsEmploymentApiService.setEmploymentTerminationHistory(this.employeeId, this.terminations, effectiveDate)
      .then((status: any) => {
        this.onActionComplete(true);
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    return this.metaFieldsTemporalDirtyArrayChecker(this.terminationEntries);
  }
}


import { filter, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IPayloadAction } from '../../state-model/models/index';
import { SessionActions } from '../actions/index';
import { Observable } from 'rxjs';
import { ComponentStateStorageService } from '../../common/services/index';
import { StateResetTypes } from '../../core/models/index';



@Injectable()
export class SessionEpics {
  constructor(private router: Router, private storageService: ComponentStateStorageService) {
  }

  public clearSession = action$ => action$.pipe(
    filter(({ type }: { type: string }) => type === SessionActions.START_CLEAR_SESSION),
    mergeMap((action: IPayloadAction) => {
      this.storageService.clearComponentStates(StateResetTypes.SessionEnd);
      return of({
        type: SessionActions.CLEAR_SESSION
      });
    }));
}

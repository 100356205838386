import { Injectable } from '@angular/core';
import { Subject ,  ReplaySubject ,  Observable ,  Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ManagementBaseService } from '../../../core/services/index';
import { LookupApiService } from '../../../organization/services/index';

import { EmployeeShortInfo,Shift, Position, PayCycle } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { PunchesApiService } from './punches-api.service';
import { PunchesMapService } from './punches-map.service';
import {
  LinePunch, EmployeeDailyPunchesStatus, EmployeeDailyPunchesState,
  EmployeeDailyPunchesHeader, EmployeesPunchesHeaderContainer, EmployeesPunchesContainer,
  EmployeeDailyPunches,
  PunchesDisplaySettings, DailyPunchesShift, PunchesEventFilter, PunchStatus
} from '../../models/index';
import { PunchesFilterHelper } from './punches-filter-helper';
import { ConstraintsApiService } from '../../../organization/services/index';
import { debounce } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common/services/index';
import { StateResetTypes } from '../../../core/models/index';
@Injectable()
export class PunchesManagementService extends ManagementBaseService<EmployeesPunchesContainer, any> {
  public onSaveAllChanges$: Subject<any>;
  public onLoadedHeaderPunches$: Subject<EmployeeDailyPunches>;
  public onLoadHeaderPunchesStatus$: ReplaySubject<{ value: boolean, employeeId: number, date: Date }>;
  public onSavedEmpPunches$: Subject<EmployeeDailyPunches[]>;
  public onFilterSet$: ReplaySubject<PunchesDisplaySettings>;
  public onFilterApplied$: Subject<EmployeeDailyPunches[]>;
  public onOrgLevelLoaded$: ReplaySubject<OrgLevel>;
  public saveFilters$: Subject<any>;
  public isPaidRestBreakEnabled$ :ReplaySubject<boolean>;
  private readonly filterControlKey: string = 'EmployeePunchesFilters';
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  public currentOrgLevel: OrgLevel;

  public startDate: Date;
  public endDate: Date;
  public lastPayCycle: PayCycle;
  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  private filter: PunchesDisplaySettings;
  private onlyMissingNextFilter: boolean;
  private onlyPunchRequestNextFilter: boolean;

  constructor(private apiService: PunchesApiService, private mapService: PunchesMapService, private lookupApiService: LookupApiService,
    private constraintsApiService: ConstraintsApiService, private stateManagement: StateManagementService
  ) {
    super();
    this.onSaveAllChanges$ = new Subject();
    this.onSavedEmpPunches$ = new Subject();
    this.onFilterApplied$ = new Subject();
    this.onFilterSet$ = new ReplaySubject();
    this.onOrgLevelLoaded$ = new ReplaySubject(1);
    this.onLoadedHeaderPunches$ = new Subject();
    this.onLoadHeaderPunchesStatus$ = new ReplaySubject();
    this.saveFilters$ = new Subject();
    this.isPaidRestBreakEnabled$ = new ReplaySubject(1);

    this.orgLevelSubscription = this.orgLevel$
      .pipe(filter((o: OrgLevel) => !this.currentOrgLevel || o && this.currentOrgLevel.id !== o.id))
      .subscribe((o: OrgLevel) => {
        if (!o || !o.id) {
          return;
        }
        this.onOrgLevelChanged(o);
      });
  }

  public setOnlyMissingNextFilter(): void {
    this.onlyMissingNextFilter = true;
  }

  public setOnlyPunchRequestNextFilter(): void {
    this.onlyPunchRequestNextFilter = true;
  }

  public saveFilters(): void {
    this.saveFilters$.next();
  }

  public setOnlyMissingFilter(filter: PunchesDisplaySettings): void {
    filter.empInvalidPunches = false;
    filter.empMissingPunches = true;
    filter.invalidLogin = true;
    filter.empNoPunches = false;
    filter.empScheduleOnly = false;
    filter.empValid = false;
    filter.empRequest = false;
    //event filters
    filter.eventFilter.empPunch = true;
    filter.eventFilter.editPunch = true;
    filter.eventFilter.essRequest = true;
    filter.eventFilter.invalidPunch = true;
    filter.eventFilter.invalidLogin = true;
    filter.eventFilter.schedule = true;
    filter.eventFilter.deleted = true;
  }

  public setOnlyPunchRequestFilter(filter: PunchesDisplaySettings): void {
    filter.empInvalidPunches = false;
    filter.empMissingPunches = false;
    filter.empNoPunches = false;
    filter.empScheduleOnly = false;
    filter.empValid = false;
    filter.empRequest = true;
    filter.invalidLogin = false;
    //event filters
    filter.eventFilter.empPunch = false;
    filter.eventFilter.editPunch = false;
    filter.eventFilter.essRequest = true;
    filter.eventFilter.invalidPunch = false;
    filter.eventFilter.invalidLogin = false;
    filter.eventFilter.schedule = false;
    filter.eventFilter.deleted = false;
  }

  public get empRequestFilterSelected(): boolean {
    return this.filter.empRequest;
  }

  public get empMissingPunchesFilterSelected(): boolean {
    return this.filter.empMissingPunches;
  }

  @debounce(1000)
  public loadOrgLevelPunches(orgLevelId: number, startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.onLoadStatusChanged(true);
    this.apiService.getOrgLevelDailyPunchesHeader(orgLevelId, startDate, endDate)
      .then((headersContainer: EmployeesPunchesHeaderContainer) => {
        let container = this.mapService.transformContainer(headersContainer);
        container.filteredEntities = this.filterPunches(container.entities, this.filter);
        this.onLoaded(container);
        this.onLoadStatusChanged(false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public loadPunches(empPunches: EmployeeDailyPunches): void {
    this.onLoadHeaderPunchesStatus$.next({ value: true, employeeId: empPunches.header.employee.id, date: empPunches.header.date });
    this.apiService.getOrgLevelLinePunches(this.currentOrgLevel.id, empPunches.header.date, empPunches.header.employee.id)
      .then((p: EmployeeDailyPunches) => {
        empPunches.isLocked = p.header && (p.header.isTimecardLocked || p.header.isPayCycleLocked || p.header.isOrganizationPayrollLocked || p.header.isTimecardApproved);
        this.isPaidRestBreakEnabled$.next(p.header.isPaidRestBreakEnabled);
        if (empPunches.header && p.header) {
          empPunches.header.comment = p.header.comment;
          empPunches.header.hasLicenseRestriction = p.header.hasLicenseRestriction;
          empPunches.header.isPaidRestBreakEnabled = p.header.isPaidRestBreakEnabled;
        }
        empPunches.punches = p.punches;
        let sorted: LinePunch[] = _.orderBy(p.punches, (p: LinePunch) => moment(p.time).unix, 'asc');
        empPunches.firstIn = _.find(sorted, (p: LinePunch) => p.type && (p.punchStatus === PunchStatus.Valid || p.punchStatus === PunchStatus.Edited) && p.type.isIn);
        empPunches.lastOut = _.findLast(sorted, (p: LinePunch) => p.type && (p.punchStatus === PunchStatus.Valid || p.punchStatus === PunchStatus.Edited) && p.type.isOut);
        this.constraintsApiService.isPayrollSubmittedForEmployee(empPunches.header.employee.id, empPunches.header.date, empPunches.header.date)
          .then((res: boolean) => {
            empPunches.isPayrollCycleSubmitted = res;
            this.onLoadedHeaderPunches$.next(empPunches);
            this.onLoadHeaderPunchesStatus$.next({ value: false, employeeId: empPunches.header.employee.id, date: empPunches.header.date });
          });
      })
      .catch((reason: any) => {
        this.onLoadHeaderPunchesStatus$.next({ value: false, employeeId: empPunches.header.employee.id, date: empPunches.header.date });
      });
  }

  public saveAllChanges(): void {
    this.onSaveAllChanges$.next();
  }

  public saveEmployeePunches(empPunchesList: EmployeeDailyPunches[], container:EmployeesPunchesContainer): void {
    this.onLoadStatusChanged(true);
    this.apiService.saveDailyPunches(this.currentOrgLevel.id, empPunchesList)
      .then((res: EmployeeDailyPunches[]) => {
        if(res.length === 0 && empPunchesList.length === 1){
          _.remove(container.entities,(edp:EmployeeDailyPunches)=> edp.header === empPunchesList[0].header);
        }
        this.onSavedEmpPunches$.next(res);
        this.onLoadStatusChanged(false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public applyFilter(container: EmployeesPunchesContainer, filter: PunchesDisplaySettings): void {
    if (filter.hasOwnProperty('filter')) {
      this.filter = (filter as any).filter;
    } else {
      this.filter = filter;
    }
    this.setFilter(this.filter);
    if (container) {
      container.filteredEntities = this.filterPunches(container.entities, this.filter);
      this.onFilterApplied$.next(container.filteredEntities);
    }
  }

  public applyCurrentFilter(container: EmployeesPunchesContainer): void {
    this.filter = this.restoreFilters(this.filter);
    if (container) {
      container.filteredEntities = this.filterPunches(container.entities, this.filter);
      this.onFilterApplied$.next(container.filteredEntities);
    }
  }

  public setFilter(filter: PunchesDisplaySettings): void {
    if (!this.filter) {
      this.filter = filter;
    }    
    this.onFilterSet$.next(this.filter);
    this.stateManagement.setControlState(this.filterControlKey, {
      value: this.filter
    }, StateResetTypes.None);
  }    
  private filterPunches(entities: EmployeeDailyPunches[], filter: PunchesDisplaySettings): EmployeeDailyPunches[] {
    if (!filter) {
      return entities;
    }
    if (this.onlyMissingNextFilter) {
      this.setOnlyMissingFilter(filter);
      this.onlyMissingNextFilter = false;
      this.saveFilters();
    }
    if(this.onlyPunchRequestNextFilter){
      this.setOnlyPunchRequestFilter(filter);
      this.onlyPunchRequestNextFilter = false;
      this.saveFilters();
    }
    let filteredList: EmployeeDailyPunches[] = entities;

    if (_.isObject(filter.shiftFilter) && !_.isArray(filter.shiftFilter)) {
      const shiftsFilter = filter.shiftFilter[this.currentOrgLevel.id];
      if (_.isArray(shiftsFilter) && _.size(shiftsFilter) > 0) {
        filteredList = _.filter(filteredList, (record: EmployeeDailyPunches) => {
          const res = _.filter(record.header.scheduledShifts, (ds: DailyPunchesShift) => _.includes(shiftsFilter, ds.shift.id));

          return _.size(res) > 0;
        });
      }
    }

    if (_.isObject(filter.positionFilter) && !_.isArray(filter.positionFilter)) {
      const posFilter = filter.positionFilter[this.currentOrgLevel.id];
      if (_.isArray(posFilter) && _.size(posFilter) > 0) {
        filteredList = _.filter(filteredList, (record: EmployeeDailyPunches) => {
          return _.includes(posFilter, record.header.position.id);
        });
      }
    }

    filteredList = _.filter(filteredList, (record: EmployeeDailyPunches) => {
      let res = false;
      res = res || (filter.empMissingPunches && (record.header.state & EmployeeDailyPunchesState.missing) === EmployeeDailyPunchesState.missing);
      res = res || (filter.empInvalidPunches && (record.header.state & EmployeeDailyPunchesState.invalid) === EmployeeDailyPunchesState.invalid);
      res = res || (filter.empScheduleOnly && (record.header.state & EmployeeDailyPunchesState.scheduledonly) === EmployeeDailyPunchesState.scheduledonly);
      res = res || (filter.empValid && record.header.status === EmployeeDailyPunchesStatus.valid);
      res = res || (filter.empNoPunches && (record.header.state & EmployeeDailyPunchesState.nopunches) === EmployeeDailyPunchesState.nopunches);
      res = res || (filter.empRequest && (record.header.state & EmployeeDailyPunchesState.emprequest) === EmployeeDailyPunchesState.emprequest);
      res = res || (filter.invalidLogin && (record.header.state & EmployeeDailyPunchesState.invalidlogin) === EmployeeDailyPunchesState.invalidlogin);
      return res;
    });

    const filterHelper: PunchesFilterHelper = new PunchesFilterHelper();
    _.forEach(filteredList, (record: EmployeeDailyPunches) => {
      filterHelper.filter(record.punches, filter.eventFilter);
    });

    /*
    filteredList = _.filter(filteredList, (record: EmployeeDailyPunches) => {
      let showedPunch: LinePunch = _.find(record.punches, (punch: LinePunch) => punch.show);
      return !!showedPunch;
    });*/
    return filteredList;
  }
  private restoreFilters(filter: PunchesDisplaySettings): PunchesDisplaySettings {
    let state = this.stateManagement.getControlState(this.filterControlKey);
    if (state && state.value !== undefined) {
      filter = state.value;
      this.restoreEventFilters(filter);
      return filter;
    }
    if (filter === undefined || !filter) {
      filter = new PunchesDisplaySettings();
      filter.empMissingPunches = true;
      filter.empInvalidPunches = true;
      filter.empScheduleOnly = true;
      filter.empValid = true;
      filter.empNoPunches = true;
    }
    this.restoreEventFilters(filter);
    return filter;
  }
  private restoreEventFilters(filters: PunchesDisplaySettings): void {
    if (!filters.eventFilter) {
      filters.eventFilter = new PunchesEventFilter();
      filters.eventFilter.empPunch = true;
      filters.eventFilter.editPunch = true;
      filters.eventFilter.essRequest = true;
      filters.eventFilter.invalidPunch = true;
      filters.eventFilter.invalidLogin = true;
      filters.eventFilter.schedule = true;
      filters.eventFilter.deleted = false;
    }
  }
  private onOrgLevelChanged(o: OrgLevel): void {
    if (this.currentOrgLevel && this.currentOrgLevel.id === o.id) {
      return;
    }
    this.currentOrgLevel = o;
    this.onOrgLevelLoaded$.next(o);
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpRequest } from '@angular/common/http';

import { Assert } from '../../../framework/index';
import { SessionService } from '../session/session.service';
import { ApiService } from '../api/api.service';
import { ResponseBody, Meta, AuthError } from '../../models/index';
import { authenticationConfig } from '../../../authentication/authentication.config';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';
import { SlxHttpRequest } from '../../../core/models/index';

/**
 * Send authenticated request to API.
 */
@Injectable()
export class AuthApiService {

  public apiRequestStarted: Subject<boolean>;

  constructor(private sessionService: SessionService, private  apiService: ApiService) {
    Assert.isNotNull(sessionService, 'sessionService');
    Assert.isNotNull(apiService, 'apiService');

    this.apiRequestStarted = new Subject();
  }

  public requestForFile(request: HttpRequest<any>): Promise<FileBlobResponse> {
    Assert.isNotNull(request, 'request');

    this.apiRequestStarted.next(true);

    request = this.setAuthorizationHeader(request);
    let promise: Promise<FileBlobResponse> = this.apiService.requestForFile(request);

    return promise;
  }

  public requestUnauthorized<TData, TMeta extends Meta>(request: HttpRequest<any>, alias: string): Promise<ResponseBody<TData, TMeta>> {
    Assert.isNotNull(request, 'request');

    this.apiRequestStarted.next(false);

    request = this.setAliasHeader(request, alias);

    let promise: Promise<ResponseBody<TData, TMeta>> = this.apiService.request<TData, TMeta>(request);

    return promise;
  }

  public requestUnauthorizedNoAlias<TData, TMeta extends Meta>(request: HttpRequest<any>): Promise<ResponseBody<TData, TMeta>> {
    Assert.isNotNull(request, 'request');
    let alias = '';
    this.apiRequestStarted.next(false);
    request = this.setAliasHeader(request, alias);

    let promise: Promise<ResponseBody<TData, TMeta>> = this.apiService.request<TData, TMeta>(request);

    return promise;
  }

  public requestNew<TData, TMeta extends Meta>(request: SlxHttpRequest<any>): Promise<ResponseBody<TData, TMeta>> {
    Assert.isNotNull(request, 'request');

    this.apiRequestStarted.next(true);

    request.httpRequest = this.setAuthorizationHeader(request.httpRequest);
    let promise: Promise<ResponseBody<TData, TMeta>> = this.apiService.requestNew<TData, TMeta>(request);

    return promise;
  }

  public request<TData, TMeta extends Meta>(request: HttpRequest<any>): Promise<ResponseBody<TData, TMeta>> {
    Assert.isNotNull(request, 'request');

    this.apiRequestStarted.next(true);

    request = this.setAuthorizationHeader(request);
    let promise: Promise<ResponseBody<TData, TMeta>> = this.apiService.request<TData, TMeta>(request);

    return promise;
  }

  public getToken(): string {
    if (this.sessionService.isExpired()) {
      throw new Error('User not authenticated.');
    }

    return this.sessionService.getToken();
  }

  private setAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {

    Assert.isNotNull(request, 'request');

    let token: string = this.getToken();
    let tokenWithSchema: string = `${authenticationConfig.api.headerSchema} ${token}`;

    return request.clone({
      headers: request.headers.append(authenticationConfig.api.header, tokenWithSchema)
    });
  }

  private setAliasHeader(request: HttpRequest<any>, alias: string): HttpRequest<any> {
    Assert.isNotNull(request, 'request');
    let aliasName = alias ? alias : 'default';
    return request.clone({
      headers: request.headers.append(authenticationConfig.api.aliasHeader, aliasName)
    });
  }
}

import { Provider } from '@angular/core';

export * from './budget/budget-api.service';
export * from './budget/budget-map.service';
export * from './budget/budget-management.service';

export * from './arrivals-departures/arrivals-departures-api.service';
export * from './arrivals-departures/arrivals-departures-map.service';
export * from './arrivals-departures/arrivals-departures-management.service';
export * from './arrivals-departures/arrivals-departures-details-management.service';
export * from './arrivals-departures/arrivals-departures-timeline-details.service';

export * from './timeclock-assignment/timeclock-assignment-api.service';
export * from './timeclock-assignment/timeclock-assignment-map.service';
export * from './timeclock-assignment/timeclock-assignment-management.service';


export * from './timecards/timecards-api.service';
export * from './timecards/timecards-map.service';
export * from './timecards/timecards-storage.service';
export * from './timecards/timecards-display-management.service';
export * from './timecards/individual-timecards-management.service';

export * from './daily-timecard/daily-timecard-api.service';
export * from './daily-timecard/daily-timecard-map.service';
export * from './daily-timecard/daily-timecard-management.service';
export * from './daily-timecard/daily-timecard-toolbar.service';

export * from './exception-console/exception-console-api.service';
export * from './exception-console/exception-console-map.service';

export * from './punches/punches-api.service';
export * from './punches/punches-map.service';
export * from './punches/punches-management.service';
export * from './punches/daily-punches-management.service';
export * from './punches/punch-logs-management.service';
export * from './punches/daily-punches-resolver.service';

export * from './supervisor-assignment/supervisor-assignment-api.service';
export * from './supervisor-assignment/supervisor-assignment-map.service';

export * from './timecards-summary/timecards-summary-api.service';
export * from './timecards-summary/timecards-summary-map.service';
export * from './timecards-summary/timecards-summary-management.service';

export * from './ta-console/ta-console-toolbar.service';

export * from './exception-console/exception-console-resolver.service';
export * from './timecards/timecards-check-rights.service';
export * from './ta-common/timecard-display-common.service';


export * from './attendance-points/attendance-points-api.service';
export * from './attendance-points/attendance-points-map.service';

import { BudgetApiService } from './budget/budget-api.service';
import { BudgetMapService } from './budget/budget-map.service';
import { ArrivalsDeparturesApiService } from './arrivals-departures/arrivals-departures-api.service';
import { ArrivalsDeparturesMapService } from './arrivals-departures/arrivals-departures-map.service';
import { TimeclockAssignmentApiService } from './timeclock-assignment/timeclock-assignment-api.service';
import { TimeclockAssignmentMapService } from './timeclock-assignment/timeclock-assignment-map.service';
import { TimecardsApiService } from './timecards/timecards-api.service';
import { TimecardsMapService } from './timecards/timecards-map.service';
import { TimecardsStorageService } from './timecards/timecards-storage.service';
import { DailyTimecardApiService } from './daily-timecard/daily-timecard-api.service';
import { DailyTimecardMapService } from './daily-timecard/daily-timecard-map.service';

import { ExceptionConsoleApiService } from './exception-console/exception-console-api.service';
import { ExceptionConsoleMapService } from './exception-console/exception-console-map.service';

import { PunchesApiService } from './punches/punches-api.service';
import { PunchesMapService } from './punches/punches-map.service';
import { DailyPunchesResolver } from './punches/daily-punches-resolver.service';

import { SupervisorAssignmentApiService } from './supervisor-assignment/supervisor-assignment-api.service';
import { SupervisorAssignmentMapService } from './supervisor-assignment/supervisor-assignment-map.service';

import { TimecardSummaryApiService } from './timecards-summary/timecards-summary-api.service';
import { TimecardSummaryMapService } from './timecards-summary/timecards-summary-map.service';
import { TimecardsSummaryManagementService } from './timecards-summary/timecards-summary-management.service';

import { TaConsoleToolbarService } from './ta-console/ta-console-toolbar.service';

import { ExceptionConsoleResolver } from './exception-console/exception-console-resolver.service';
import { TimecardsCheckRightsService } from './timecards/timecards-check-rights.service';
import { TimecardDisplayCommonService } from './ta-common/timecard-display-common.service';

import { AttendancePointsApiService } from './attendance-points/attendance-points-api.service';
import { AttendancePointsMapService } from './attendance-points/attendance-points-map.service';

import { EmployeePunchRequestApiService } from './punches/employee-punch-request-api.service';
import { EmployeePunchRequestMapService } from './punches/employee-punch-request-map.service';

export const services: Provider[] = [
  BudgetApiService,
  BudgetMapService,
  ArrivalsDeparturesApiService,
  ArrivalsDeparturesMapService,
  TimeclockAssignmentApiService,
  TimeclockAssignmentMapService,
  TimecardsApiService,
  TimecardsMapService,
  TimecardsStorageService,
  DailyTimecardApiService,
  DailyTimecardMapService,
  ExceptionConsoleApiService,
  ExceptionConsoleMapService,
  SupervisorAssignmentApiService,
  SupervisorAssignmentMapService,
  PunchesApiService,
  PunchesMapService,
  TimecardSummaryApiService, TimecardSummaryMapService,
  TaConsoleToolbarService,
  DailyPunchesResolver,
  ExceptionConsoleResolver,
  TimecardsCheckRightsService,
  TimecardDisplayCommonService,
  AttendancePointsApiService,
  AttendancePointsMapService,
  EmployeePunchRequestApiService,
  EmployeePunchRequestMapService
];


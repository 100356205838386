import { Component, OnInit, Provider } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { Assert, StringUtils } from '../../../framework/index';
import { authenticationConfig } from '../../authentication.config';

import { VersionSubscribeService } from '../../../common/index';
import { AuthenticationService } from '../../services/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { DialogOptions, IDialog } from '../../../common/models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { SessionService } from '../../../core/services/session/session.service';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { IUser } from '../../store/session/session.types';
import { Credentials } from '../../models/index';
import { SessionActions } from '../../actions/index';
import { UserMenuPinService } from '../../../core/services/user-menu-pin/user-menu-pin.service';


@Component({
  moduleId: module.id,
  selector: 'slx-login-dialog',
  templateUrl: 'login-dialog.component.html',
  styleUrls: ['login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit, IDialog {
  public dialogResult: boolean = false;
  public currentUsername: string = '';
  private failedLoginAttemptCount: number = 0;

  public static openDialog(modalService: ModalService, callback: (result: boolean) => void): LoginDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 475;
    dialogOptions.width = 375;
    dialogOptions.hideTitleBar = true;
    dialogOptions.showCloseButton = false;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];
    let dialog: LoginDialogComponent = modalService.globalAnchor
      .openDialog(LoginDialogComponent, 'Login', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result);
      });
    return dialog;
  }

  constructor(private authenticationService: AuthenticationService,
    private sessionService: SessionService, private userActivityService: UserActivityService, private userMenuPinService: UserMenuPinService,
    private options: DialogOptions, private router: Router, private sessionActions: SessionActions, private modalService: ModalService) {
  }

  public ngOnInit(): void {
    this.userActivityService.stop();
    let currentUser: IUser = this.sessionService.getUser() || this.sessionService.lastUser;
    let currentAlias: string = this.sessionService.getAlias() || this.sessionService.lastAlias;
    if (currentUser) {
      this.currentUsername = (!!currentAlias && currentAlias !== '' && currentAlias !== 'default') ? `${currentUser.username}@${currentAlias}` : currentUser.username;
    }
  }

  public onAuthenticated(): void {
    this.close();
    this.userActivityService.start();
    this.userMenuPinService.getUserMenuPinStatus();
  }

  public onAuthenticationFailed(): void {
    this.failedLoginAttemptCount++;
    if (this.failedLoginAttemptCount >= appConfig.session.maxExpiredSessionLoginAttempts) {
      this.authenticationService.navigateToLogout(this.router, this.router.url);
    }
  }

  public onLogout(username: string): void {
    this.authenticationService.navigateToLogout(this.router, this.router.url);
  }

  private close(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}

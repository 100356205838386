import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';

export const requireDecimalMultiValidationKey = 'requireDecimalMulti';

function validationDecimal(input: string): boolean {
  if (input.length == 0) {
      return false;
  }
  if (input.length > 1 && input.startsWith("0", 0)) {
      return false;
  }
  for (let i = 0; i < input.length; i++) {
      const character: string = input.charAt(i);
      const characterVal: number = parseInt(character);
      if (!(Number.isInteger(characterVal) || character == '.')) {
          return false;
      }
  }
  return true;
}

function checkSplitValueDecimal(input: string): boolean {
  const numsWithDecmials: string[] = input.split(",");
  for (let i = 0; i < numsWithDecmials.length; i++) {
      if (numsWithDecmials[i].trim().split(".").length > 2) {
          return false;
      }
      if (!(validationDecimal(numsWithDecmials[i].trim()))) {
          return false;
      }
  }
  return true;
}

export function requireDecimalMultiValidator(): ValidatorFn {

  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = _.trim(control.value);

    const isValid = checkSplitValueDecimal(value);
    return !isValid ? { [requireDecimalMultiValidationKey]: true } : null;
  };

  return validator;
}

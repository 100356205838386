import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';

import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../common/models/index';

import { UserProfileSectionBaseComponent } from '../user-profile-sections/user-profile-section-base.component';
import { OrgLevelSelectorComponent } from '../org-levels-selector/org-levels-selector.component';

import { RoleDefinition, OrgLevelFlat } from '../../../../organization/models/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';

import { UserProfileSectionType } from '../../../models/users/models/user-profile-section-type';
import { UserProfileModel, UserRoleRelationModel, UserRoleRecordWrapper, OrgLevelRecordWrapper } from '../../../models/index';
import { UserProfileManagementService } from '../../../services/index';
import { UserRolesSaveData } from '../../../models/users/models/user-roles-save-data';

@Component({
    moduleId: module.id,
    selector: 'slx-user-profile-section-roles',
    templateUrl: 'user-profile-section-roles.component.html',
    styleUrls: ['user-profile-section-roles.component.scss']
})

export class UserProfileSectionRolesComponent extends UserProfileSectionBaseComponent implements OnInit, OnDestroy {

    public get isValid(): boolean {
        return this.tempRoles ? this.tempRoles.length > 0 : false;
    }

    public records: UserRoleRecordWrapper[] = [];
    public roles: UserRoleRelationModel[] = [];
    public tempRoles: UserRoleRelationModel[] = [];

    public userProfile: UserProfileModel;
    public isAllSelected: boolean;

    public allRolesList: RoleDefinition[];

    public selectedRole: RoleDefinition;
    public selectedRoleRelation: UserRoleRelationModel;

    public orgLevelTypesList: { name: string, id: string }[];

    public selectedOrgLevelType: { name: string, id: string };

    public orgLevelSeparator: string = ' > ';

    public state: {
        isEditMode: boolean;
        isLoading: boolean;
        addMode: boolean;
    };

    @unsubscribe()
    public userProfileSubscription: Subscription;

    @unsubscribe()
    public userRolesSubscription: Subscription;

    @unsubscribe()
    public allRolesSubscription: Subscription;

    @unsubscribe()
    public addRolesModeSubscription: Subscription;

    @unsubscribe()
    public saveAddedSubscription: Subscription;

    @unsubscribe()
    public cancelRolesModeSubscription: Subscription;

    @unsubscribe()
    public onDeleteRolesSubscription: Subscription;

    @unsubscribe()
    public onResetRolesSubscription: Subscription;

    @unsubscribe()
    public orgLevelTreeSubscription: Subscription;

    public gridState: KendoGridStateHelper<UserRoleRecordWrapper>;

    public hasSelectedRoles: boolean;
    public hasSelectedOrgLevels: boolean;

    @ViewChild('orgLevelsSelector')
    private selector: OrgLevelSelectorComponent;

    private selectedRecords: UserRoleRecordWrapper[] = [];

    constructor(protected management: UserProfileManagementService, private orgLevelWatchService: OrgLevelWatchService) {
        super(management);
        this.m_type = UserProfileSectionType.ROLES;
        this.gridState = new KendoGridStateHelper<UserRoleRecordWrapper>();
        this.clearSelection();
    }

    public ngOnInit(): void {

        this.state = {
            isEditMode: false,
            isLoading: false,
            addMode: false
        };

        super.ngOnInit();

        this.orgLevelTreeSubscription = this.orgLevelWatchService.orgLevelTreeLoaded$.subscribe(() => {
            let flatList: OrgLevelFlat[] = this.orgLevelWatchService.getFlatList();
            const groupedByType: any = _.keyBy(flatList, (flatLvl: OrgLevelFlat) => flatLvl.orgLevel.type);
            this.orgLevelTypesList = _.map(_.keys(groupedByType), (key: string) => { return { id: key, name: key }; });
            this.selectedOrgLevelType = _.first(this.orgLevelTypesList);
        });


        this.userProfileSubscription = this.management.onProfileLoaded$.subscribe((userProfile: UserProfileModel) => {
            this.userProfile = userProfile;
            if (this.userProfile.isNew) {
                this.management.editSection(this);
            }
        });

        this.userRolesSubscription = this.management.onUserRolesLoaded$.subscribe((roles: UserRoleRelationModel[]) => {
            this.roles = roles;
            this.tempRoles = this.management.cloneRoles(roles);
            this.updateRecords();
            this.refreshGrid();
        });

        this.allRolesSubscription = this.management.onAllRolesLoaded$.subscribe((roles: RoleDefinition[]) => {
            this.allRolesList = roles;
        });

        this.addRolesModeSubscription = this.management.onAddRoleModeSwitch$.subscribe((state: boolean) => {
            this.state.addMode = state;
            this.selectedRoleChanged();
        });

        this.saveAddedSubscription = this.management.onSaveSelectedRole$.subscribe(() => {
            this.onSaveAddedRole();
        });

        this.cancelRolesModeSubscription = this.management.onCancelSelectedRole$.subscribe(() => {
            this.onCancelAdd();
        });

        this.onDeleteRolesSubscription = this.management.onDeleteRoles$.subscribe(() => {
            this.onDeleteRole();
        });

        this.onResetRolesSubscription = this.management.onResetRoles$.subscribe(() => {
            this.onResetRoles();
        });

        this.clearSelection();
    }

    public ngOnDestroy(): void {
        // See #issueWithAOTCompiler
        super.ngOnDestroy();
    }

    public getSaveData(): UserRolesSaveData {
        let saveData: UserRolesSaveData = new UserRolesSaveData();
        saveData.addMode = this.state.addMode;
        if (!saveData.addMode) {
            saveData.tempRoles = this.tempRoles;
        }
        return saveData;
    }

    public onChangesSaved(): void {
        this.state.isLoading = false;
        this.state.isEditMode = false;
        this.clearSelection();
        if (this.state.addMode) {
            this.state.addMode = false;
        }
    }

    public discardChanges(): void {
        this.tempRoles = this.management.cloneRoles(this.roles);
        this.updateRecords();
        this.refreshGrid();
        this.state.isEditMode = false;
        this.state.addMode = false;
        this.clearSelection();
    }

    public onToggleAllSelected(): void {
        _.forEach(this.records, (record: UserRoleRecordWrapper) => {
            record.isSelected = this.isAllSelected && Boolean(record.selectable);
        });
        this.selectionChange();
    }

    public selectionChange(): void {
        this.selectedRecords = _.filter(this.records, (record: UserRoleRecordWrapper) => {
            return Boolean(record.isSelected) === true;
        });

        this.hasSelectedRoles = this.selectedRecords.length > 0;
    }

    public onAddClick(): void {
        this.hasSelectedOrgLevels = false;
        this.management.onAddRole();
    }

    public onDeleteClick(): void {
        this.onDeleteRole();
    }

    public onSaveAddedClick(): void {
        this.onSaveAddedRole();
    }

    public onCancelAddClick(): void {
        this.management.onCancelAddedRole();
        this.hasSelectedOrgLevels = false;
    }

    public selectedRoleChanged(): void {

        let relationModel: UserRoleRelationModel;
        if (this.selectedRole) {
            relationModel = _.find(this.tempRoles, (roleRelation: UserRoleRelationModel) => {
                return roleRelation.roleDefinition.id === this.selectedRole.id;
            });
        }
        this.selectedRoleRelation = relationModel;
    }

    public onOrgLevelSelectedCountChange(count: number): void {
        Promise.resolve(null).then(() => {
            this.hasSelectedOrgLevels = count > 0;
        });
    }

    protected clearSelection(): void {
        _.each(this.records, (record: UserRoleRecordWrapper) => {
            record.isSelected = false;
        });
        this.selectionChange();
    }

    protected onResetRoles(): void {
      this.selectedRecords = _.filter(this.records, (record: UserRoleRecordWrapper) => Boolean(record.selectable));
      this.onDeleteRole();
    }

    protected onDeleteRole(): void {
        let removedRoles: UserRoleRelationModel[] = [];
        _.each(this.tempRoles, (role: UserRoleRelationModel) => {
            role.orgLevels = _.filter(role.orgLevels, (lvl: OrgLevel) => {
                let selectedRecord: UserRoleRecordWrapper = _.find(this.selectedRecords, (record: UserRoleRecordWrapper) => {
                    return record.orglevel.id === lvl.id && record.role.id === role.roleDefinition.id;
                });
                return !selectedRecord;
            });
            if (role.orgLevels.length === 0) removedRoles.push(role);
        });
        this.tempRoles = _.without(this.tempRoles, ...removedRoles);
        this.isAllSelected = false;
        this.clearSelection();
        this.updateRecords();
        this.refreshGrid();
    }

    protected onSaveAddedRole(): void {
        if (this.selector) {
            let relationModel: UserRoleRelationModel = _.find(this.tempRoles, (roleRelation: UserRoleRelationModel) => {
                return roleRelation.roleDefinition.id === this.selectedRole.id;
            });

            if (!relationModel) {
                // adding new role
                relationModel = new UserRoleRelationModel();
                relationModel.roleDefinition = this.selectedRole;
                relationModel.userId = this.userProfile.id;
                this.tempRoles.push(relationModel);
            }

            // add levels
            let allRecords = this.selector.records;
            let roleOrgLevels: OrgLevel[] = [];
            const typesByDepth: OrgLevelType[] = [
                OrgLevelType.corporation,
                OrgLevelType.region,
                OrgLevelType.organization,
                OrgLevelType.department
            ];

            let editDepth: number = _.findIndex(typesByDepth, (type: OrgLevelType) => {
                return this.selectedOrgLevelType.name === type;
            });

            let recordByOrgLevelId: NumberMap<OrgLevelRecordWrapper> = [];
            _.each(allRecords, (r: OrgLevelRecordWrapper) => {
                let itemDepth: number = _.findIndex(typesByDepth, (type: OrgLevelType) => {
                    return r.orgLevel.orgLevel.type === type;
                });
                r.depth = itemDepth;
                recordByOrgLevelId[r.orgLevel.orgLevel.id] = r;
            });

            _.sortBy(allRecords, ['depth']);

            let currentDepth: number = typesByDepth.length - 1;
            while (currentDepth >= 0) {
                _.each(allRecords, (r: OrgLevelRecordWrapper) => {
                    if (r.depth === currentDepth) {
                        if (r.depth !== editDepth) {
                            if (r.orgLevel.orgLevel.hasChilds) {
                                let childCount: number = _.size (r.orgLevel.orgLevel.childs);
                                if (childCount > 1) {
                                    let notSelectedChild: OrgLevel = _.find(r.orgLevel.orgLevel.childs, (o: OrgLevel) => {
                                        return recordByOrgLevelId[o.id].isSelected === false;
                                    });
                                    if (notSelectedChild) {
                                        r.isSelected = false;
                                    } else {
                                        r.isSelected = true;
                                        let flatChildList: OrgLevel[] = this.orgLevelWatchService.getFlatChildOrglevels(r.orgLevel.orgLevel.id);
                                        _.each(flatChildList, (o: OrgLevel) => {
                                            recordByOrgLevelId[o.id].isSelected = false;
                                        });
                                    }
                                }
                            }
                        } else {
                            if (r.isSelected && r.orgLevel.orgLevel.hasChilds) {
                                let flatChildList: OrgLevel[] = this.orgLevelWatchService.getFlatChildOrglevels(r.orgLevel.orgLevel.id);
                                _.each(flatChildList, (o: OrgLevel) => {
                                    recordByOrgLevelId[o.id].isSelected = false;
                                });
                            }
                        }
                    }
                });
                currentDepth--;
            }
            _.each(allRecords, (r: OrgLevelRecordWrapper) => {
                if (r.isSelected) {
                    roleOrgLevels.push(r.orgLevel.orgLevel);
                }
            });

            relationModel.orgLevels = roleOrgLevels;
        }

        // regenerate flat list
        this.state.addMode = false;
        this.updateRecords();
        this.refreshGrid();

        this.management.onSaveAddedRole();
    }

    protected onCancelAdd(): void {
        this.state.addMode = false;
        this.management.onCancelAddedRole();
        this.management.onCancelAddedRole();
    }

    protected updateRecords(): void {
        let records: UserRoleRecordWrapper[] = [];
        _.each(this.tempRoles, (role: UserRoleRelationModel) => {
            _.each(role.orgLevels, (orgLevel: OrgLevel) => {
                let record: UserRoleRecordWrapper = new UserRoleRecordWrapper();
                record.role = role.roleDefinition;
                record.orglevel = orgLevel;
                records.push(record);
            });
        });
        this.records = records;
    }

    private refreshGrid(): void {

        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    }
}

import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { LookupMapService } from '../lookup/lookup-map.service';
import { EmployeeDefinitionsMapService } from '../employee/employee-definitions-map.service';

import { DateTimeService, IDateRange } from '../../../common/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import {
  EmpType, IShiftDefinition, ScheduleDefinition, IScheduleDefinition, IPosition,
  GenerateScheduleSummary, IGenerateScheduleSummary, ScheduleCycleSummaryMessage, IScheduleCycleSummaryMessage, SummaryMessageLevel,
  ScheduleCycleMessages, IScheduleCycleMessages
} from '../../../organization/models/index';
import {
  IEmployeeScheduleDefinition, EmployeeScheduleDefinition,
  IScheduleEntryDefinition, ScheduleEntryDefinition,
  IScheduledShiftDefinition, ScheduledShiftDefinition
} from '../../models/index';
import {
  IPrescheduledOvertime, PrescheduledOvertime
} from '../../models/schedule/index';
import { MasterScheduleShiftInfo, ScheduleEntryEditItem } from '../../../scheduler/models/index';
@Injectable()
export class ScheduleDefinitionsMapService {
  private lookupMapService: LookupMapService;
  private employeeDefinitionsMapService: EmployeeDefinitionsMapService;
  private dateTimeService: DateTimeService;

  constructor(lookupMapService: LookupMapService, employeeDefinitionsMapService: EmployeeDefinitionsMapService, dateTimeService: DateTimeService) {
    this.lookupMapService = lookupMapService;
    this.employeeDefinitionsMapService = employeeDefinitionsMapService;
    this.dateTimeService = dateTimeService;
  }

  public mapToScheduledShiftDefinitionDto(data: ScheduledShiftDefinition): IScheduledShiftDefinition {
    let durationDTO: string;
    if (data.duration) {
      durationDTO = dateTimeUtils.convertFromNumberToDtoDurationString(data.duration, 'ms');
    } else {
      durationDTO = dateTimeUtils.convertFromNumberToDtoDurationString(data.shift ? data.shift.durationHours : 0, 'h');
    }
    let dto: IScheduledShiftDefinition = {
      department: !_.isNil(data.department) ? this.lookupMapService.mapDepartmentDto(data.department) : undefined,
      constraint: !_.isNil(data.constraint) && (data.constraint.id || data.constraint.code) ? this.lookupMapService.mapConstraintDefinitionDto(data.constraint) : undefined,
      position: !_.isNil(data.position) ? this.lookupMapService.mapPositionDto(data.position) : undefined,
      unit: !_.isNil(data.unit) && (data.unit.id || data.unit.name) ? this.lookupMapService.mapLocationUnitDto(data.unit) : undefined,
      shift: !_.isNil(data.shift) ? this.lookupMapService.mapShiftDefinitionDto(data.shift) : undefined,
      absence: !_.isNil(data.absence) ? this.lookupMapService.mapScheduleAbsenceDto(data.absence) : undefined,
      partialAbsence: !_.isNil(data.partialAbsence) ? this.lookupMapService.mapSchedulePartialAbsenceDto(data.partialAbsence) : undefined,
      start: this.dateTimeService.convertToUTC(data.start),
      end: this.dateTimeService.convertToUTC(data.end),
      duration: durationDTO,
      prescheduledOvertime: !_.isNil(data.prescheduledOvertime) ? this.mapPrescheduledOvertime(data.prescheduledOvertime) : undefined
    };
    return dto;
  }

  public mapToScheduledShiftDefinition(dto: IScheduledShiftDefinition, dateOn: Date): ScheduledShiftDefinition {


    let data: ScheduledShiftDefinition = new ScheduledShiftDefinition();
    data.start = moment(dto.start).toDate();
    data.end = moment(dto.end).toDate();
    if (dto.shift) {
      let shift: IShiftDefinition = {
        id: dto.shift.id,
        name: dto.shift.name,
        start: dto.start ? moment(dto.start).format(appConfig.militaryTimeFormat) : '',
        end: dto.end ? moment(dto.end).format(appConfig.militaryTimeFormat) : '',
        duration: this.dateTimeService.getDuration(moment(dto.start), moment(dto.end)),
        lunchDuration: '',  //TODO
        group: null,        //TODO
        employeesCount: 0,   //TODO
        usedInIdealSchedule: false //TODO
      };
      data.shift = this.lookupMapService.mapShiftDefinition(shift);
    } else {
      data.shift = undefined;
    }

    data.department = !_.isNil(dto.department) ? this.lookupMapService.mapDepartment(dto.department) : undefined;
    data.position = !_.isNil(dto.position) ? this.lookupMapService.mapPosition(dto.position) : undefined;

    data.unit = !_.isNil(dto.unit) ? this.lookupMapService.mapLocationUnit(dto.unit) : undefined;
    data.constraint = !_.isNil(dto.constraint) ? this.lookupMapService.mapConstraintDefinition(dto.constraint) : undefined;
    if (dto.absence) {
      data.absence = this.lookupMapService.mapScheduleAbsence(dto.absence);
    }
    if (dto.partialAbsence) {
      data.partialAbsence = this.lookupMapService.mapSchedulePartialAbsence(dto.partialAbsence);
    }
    data.duration = dateTimeUtils.convertFromDtoDurationStringToNumber(dto.duration, 'ms');
    data.prescheduledOvertime = !_.isNil(dto.prescheduledOvertime) ? this.mapPrescheduledOvertime(dto.prescheduledOvertime) : undefined;
    return data;
  }

  public mapPrescheduledOvertime(dto: IPrescheduledOvertime): PrescheduledOvertime {
    let data: PrescheduledOvertime = new PrescheduledOvertime();
    data.start = dto.start;
    data.end = dto.end;
    data.duration = dto.duration;
    return data;
  }

  public mapToScheduleEntryDefinitionDto(data: ScheduleEntryDefinition): IScheduleEntryDefinition {
    let dto: IScheduleEntryDefinition = {
      shifts: _.map(data.shifts, (entry: ScheduledShiftDefinition) => {
        return this.mapToScheduledShiftDefinitionDto(entry);
      }),
      dateOn: this.dateTimeService.convertToUTC(data.dateOn),
      isPosted: data.isPosted,
      isReplacing: data.isReplacing,
      isReplaced: data.isReplaced,
      isPreScheduledOvertime: data.isPreScheduledOvertime,
      isOvertimeApproach: data.isOvertimeApproach,
      isAgency: data.isAgency,
      hasAbsence: data.hasAbsence,
      hasPartialAbsence: data.hasPartialAbsence,
      scheduledInDifferentShiftGroup: data.scheduledInDifferentShiftGroup
    };
    return dto;
  }

  public mapToScheduleEntryDefinition(dto: IScheduleEntryDefinition): ScheduleEntryDefinition {
    let data: ScheduleEntryDefinition = new ScheduleEntryDefinition();
    data.dateOn = new Date(dto.dateOn);
    data.isPosted = dto.isPosted;
    data.isReplacing = dto.isReplacing;
    data.isReplaced = dto.isReplaced;
    data.isPreScheduledOvertime = dto.isPreScheduledOvertime;
    data.isOvertimeApproach = dto.isOvertimeApproach;
    data.isAgency = dto.isAgency;
    data.hasAbsence = dto.hasAbsence;
    data.hasPartialAbsence = dto.hasPartialAbsence;
    data.scheduledInDifferentShiftGroup = dto.scheduledInDifferentShiftGroup;
    const shifts = _.map(dto.shifts, (entry: IScheduledShiftDefinition) => {
      return this.mapToScheduledShiftDefinition(entry, data.dateOn);
    });
    data.shifts = _.orderBy(shifts, (s: ScheduledShiftDefinition) => s.start);
    return data;
  }

  public mapToEmployeeScheduleDefinition(dto: IEmployeeScheduleDefinition): EmployeeScheduleDefinition {
    let data: EmployeeScheduleDefinition = new EmployeeScheduleDefinition();
    data.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.homeShift = dto.homeShift ? this.lookupMapService.mapShiftDefinition(dto.homeShift) : null;
    data.homeUnit = this.lookupMapService.mapLocationUnit(dto.homeUnit);
    data.canEditScheduleApprovedPayPeriod = dto.canEditScheduleApprovedPayPeriod;
    data.entries = _.map(dto.entries, (entry: IScheduleEntryDefinition) => {
      return this.mapToScheduleEntryDefinition(entry);
    });
    if (dto.secondaryPositions) {
      data.secondaryPositions = _.map(dto.secondaryPositions, (p: IPosition) => this.lookupMapService.mapPosition(p));
    }
    return data;
  }

  public mapScheduleDefinition(dto: IScheduleDefinition): ScheduleDefinition {
    let data: ScheduleDefinition = new ScheduleDefinition();
    data.startTime = dateTimeUtils.convertFromDtoString(dto.startTime);
    data.endTime = dateTimeUtils.convertFromDtoString(dto.endTime);
    data.hours = dto.hours;
    return data;
  }

  public mapScheduleDefinitionDto(data: ScheduleDefinition): IScheduleDefinition {
    let dto: IScheduleDefinition = {
      startTime: dateTimeUtils.convertToDtoString(data.startTime),
      endTime: dateTimeUtils.convertToDtoString(data.endTime),
      hours: data.hours
    };
    return dto;
  }

  public mapEmployeeScheduleDefinitions(records: IEmployeeScheduleDefinition[]): EmployeeScheduleDefinition[] {
    return _.map(records, (record: IEmployeeScheduleDefinition) => {
      return this.mapToEmployeeScheduleDefinition(record);
    });
  }

  mapGenerateScheduleMessages(dto: IScheduleCycleMessages): ScheduleCycleMessages {
    let data: GenerateScheduleSummary = new GenerateScheduleSummary();
    data.scheduleCycleStartDate = dateTimeUtils.convertFromDtoString(dto.scheduleCycleStartDate);
    data.scheduleCycleEndDate = dateTimeUtils.convertFromDtoString(dto.scheduleCycleEndDate);
    data.messages = _.map(dto.messages, (msg: IScheduleCycleSummaryMessage) => this.mapGenerateScheduleSummaryMessage(msg));
    return data;
  }

  mapGenerateScheduleSummary(dto: IGenerateScheduleSummary): GenerateScheduleSummary {
    let data: GenerateScheduleSummary = new GenerateScheduleSummary();
    data.scheduleCycleStartDate = dto ? dateTimeUtils.convertFromDtoString(dto.scheduleCycleStartDate) : null;
    data.scheduleCycleEndDate = dto ? dateTimeUtils.convertFromDtoString(dto.scheduleCycleEndDate) : null;
    data.orgLevelId = dto ? dto.orgLevelId : null;
    data.messages = dto ? _.map(dto.messages, (msg: IScheduleCycleSummaryMessage) => this.mapGenerateScheduleSummaryMessage(msg)) : [];
    data.schedule = dto ? this.mapEmployeeScheduleDefinitions(dto.schedule) : [];
    return data;
  }

  mapGenerateScheduleSummaryMessage(dto: IScheduleCycleSummaryMessage): ScheduleCycleSummaryMessage {
    let data: ScheduleCycleSummaryMessage = new ScheduleCycleSummaryMessage();
    data.workDate = dateTimeUtils.convertFromDtoString(dto.workDate);
    data.employeeId = dto.employeeId;
    data.employeeName = dto.employeeName;
    data.positionId = dto.positionId;
    data.positionName = dto.positionName;
    data.shiftId = dto.shiftId;
    data.shiftName = dto.shiftName;
    data.unitId = dto.unitId;
    data.unitName = dto.unitName;
    switch (dto.level) {
      case 'Info':
        data.summaryMessageLevel = SummaryMessageLevel.info;
        break;
      case 'Exception':
        data.summaryMessageLevel = SummaryMessageLevel.exception;
        break;
      case 'Error':
        data.summaryMessageLevel = SummaryMessageLevel.error;
        break;
      default:
        data.summaryMessageLevel = SummaryMessageLevel.undefined;
        break;
    }
    data.text = dto.text;
    return data;
  }

  public mapEmpScheduleQuickEditEntries(dto: ScheduleEntryEditItem[]): ScheduleEntryEditItem[] {
    let scheduleEntries: ScheduleEntryEditItem[] = [];
    scheduleEntries = _.map(dto, (entry: ScheduleEntryEditItem) => this.mapEmpScheduleQuickEditEntrie(entry));
    return scheduleEntries;
  }

  public mapEmpScheduleQuickEditEntrie(entry: any): ScheduleEntryEditItem{
    const entries: ScheduleEntryEditItem = new ScheduleEntryEditItem();
    entries.employeeId = entry.employeeId;
    entries.employeeName = entry.employeeName;
    entries.date = dateTimeUtils.convertFromDtoString(entry.workDate);
    entries.shift = new MasterScheduleShiftInfo();
    entries.shift = entry.shift;
    entries.shift.shiftName = entry.shift.shiftName;
    entries.shift.unitName = entry.shift.unitName;
    entries.shift.absenceCode = entry.shift.absenceCode;
    return entries;
  }
}

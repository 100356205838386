<kendo-grid #kendoGrid kendoGridFixFreezeHeader class="slx-full-height slx-blue-grid"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  (pageChange)="gridState.pageChange($event)"
  (dataStateChange)="gridState.dataStateChange($event)"
  [skip]="gridState.state.skip"
  [scrollable]="'scrollable'"
  slxKendoGridState="EXCEPTION_CONSOLE_ROLLUP"
>
  <kendo-grid-column media="(max-width: 500px)" title="Items">
    <ng-template kendoGridCellTemplate let-dataItem>
      <dl>
        <dt>{{ orgLevelTitle }}</dt>
        <dd>{{  getOrgLevelName(dataItem) }}</dd>

        <dt>TotalExceptionsCount</dt>
        <dd>{{ dataItem.totalExceptionsCount| number: ".0-0" }}</dd>

        <dt>Total Hours</dt>
        <dd>{{ dataItem.totalHours| number: ".2-2" }}</dd>

        <dt>Regular Hours</dt>
        <dd>{{ dataItem.regularHours| number: ".2-2" }}</dd>

        <dt>OT Hours</dt>
        <dd>{{ dataItem.overtimeHours| number: ".2-2" }}</dd>

        <dt>Absense Hours</dt>
        <dd>{{ dataItem.absenceHours| number: ".2-2" }}</dd>
      </dl>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [title]="orgLevelTitle" [kendoGridColumnConfig]="state.columnsMap" width="100"
    kendoGridColumnId="organization" media="sm" [field]="orgColumnField">
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <a (click)="onOrgLevelClicked(dataItem)">
            {{ getOrgLevelName(dataItem) }}
        </a>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="paycycle" media="sm" field="payCycle.description">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Payroll Cycle</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      <a (click)="onPaycycleClicked(dataItem)">
        {{dataItem?.payCycle?.description}}
      </a>
    </ng-template>
  </kendo-grid-column>
  
  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="missingPunches" media="sm" field="missingPunches"
    class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Pending/Approved Missing Punches</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.missingPunches}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['pendingMissingPunchesCount']?.sum  | number: ".0-0"}}/{{total['approvedMissingPunchesCount']?.sum  | number: ".0-0"}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="totalExceptionsCount" media="sm" field="totalExceptionsCount"
    class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Exceptions</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.totalExceptionsCount | number: ".0-0"}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['totalExceptionsCount']?.sum  | number: ".0-0"}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngFor="let col of state.dynamicColumns" 
    [kendoGridColumnConfig]="state.columnsMap"
    [kendoGridColumnId]="col.name" media="sm" 
    class="numeric-cell" footerClass="numeric-cell"
    [sortable]="'true'"
    [field]="col.payload.exceptionField"
    >

      <ng-template kendoGridHeaderTemplate  let-column="column">
        <span slxKendoGridHeaderTooltip>{{column.title}}</span>
      </ng-template>

      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-column="column">
          {{ dataItem.getExceptionCount (col.name) | number: ".0-0" }}
      </ng-template>

      <ng-template kendoGridFooterTemplate let-column>
        <strong>{{ total[col.name] | number: ".0-0"}}</strong>
      </ng-template>
      
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>

  </kendo-grid-column>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import { Directive, OnInit, OnDestroy, HostBinding, HostListener, Self, Input } from '@angular/core';
import { ModalService } from '../../../common/index';
import { Subscription } from 'rxjs';

import { Actions, ActionsType } from '../../../core/models/field/actions-type';

import { IAccessManagementService, AccessManagementService } from '../../services/accessManagement/access-management.service';

@Directive()
export class StateManagementDirectiveBase implements OnInit, OnDestroy {

  public operation: string;
  public interceptedMethod: () => {};

  protected stateManagement: IAccessManagementService;
  protected modalService: ModalService;
  protected subscription: Subscription;

  @HostBinding('attr.disabled')
  protected disabled: boolean;

  constructor(stateManagement: IAccessManagementService, modalService: ModalService) {
    this.stateManagement = stateManagement;
    this.modalService = modalService;
    this.operation = ActionsType.noop;
  }

  public ngOnInit(): void {

    this.stateManagement.subscribe(() => {
      this.updateHost();
    });

    this.updateHost();
  }

  public ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  protected updateHost(): void {
    let result: boolean = this.stateManagement.checkAction(this.operation);
    if (!result) {
      this.disabled = true;
    } else {
      this.disabled = undefined;
    }
  }

  @HostListener('click', ['$event'])
  protected interceptAction(event: MouseEvent): void {
    let result: boolean = this.stateManagement.checkAction(this.operation);
    if (result) {
      if (this.interceptedMethod) this.interceptedMethod();
    } else {
      this.modalService.globalAnchor.openConfirmDialog('Warning', 'This action is forbidden', (selection: boolean) => {
        console.debug('action prevented');
      });
      event.preventDefault();
      event.stopPropagation();
    }
  }
}


@Directive({
  selector: '[slx-list-delete-operation]'
})
export class ListDeleteOperationDirective extends StateManagementDirectiveBase implements OnInit {

  @Input('slx-list-delete-operation')
  public interceptedMethod: () => {};

  constructor(stateManagement: AccessManagementService, modalService: ModalService) {
    super(stateManagement, modalService);
    this.operation = ActionsType.delete;
  }
}

@Directive({
  selector: '[slx-list-add-operation]'
})
export class ListAddOperationDirective extends StateManagementDirectiveBase implements OnInit {

  @Input('slx-list-add-operation')
  public interceptedMethod: () => {};

  constructor(stateManagement: AccessManagementService, modalService: ModalService) {
    super(stateManagement, modalService);
    this.operation = ActionsType.add;
  }
}

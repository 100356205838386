import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { unsubscribe } from '../../../../core/decorators/index';
import { ChangeManagementService } from '../../../../common/services/index';
import { StaffingPredictorManagementService } from '../../services/index';
import { StaffingRatingOrganization, StaffingPredictorOrganizationInfo, FiveStarRatingOrganization, FiveStarPredictorOrganizationInfo } from '../../models/index';
import { Subscription } from 'rxjs';
import { ModalService, ConfirmChangesDialogComponent } from '../../../../common/index';
@Component({
    moduleId: module.id,
    selector: 'slx-staffing-predictor-section',
    templateUrl: 'staffing-predictor-section.component.html',
    styleUrls: ['staffing-predictor-section.component.scss']
})

export class StaffingPredictorSectionComponent implements OnInit {

    @Input()
    public organization: StaffingRatingOrganization;

    @Input()
    public fiveStarOrganization: FiveStarRatingOrganization;

    @Input()
    public isLoading : boolean = true;
    @Input()
    public five_star_v2_enabled: boolean = false;

    @Input()
    public five_star_goal_section_enabled: boolean = false;

    @Input()
    public sectionIndex: number = 0;
     public goalRating: number;
    public isValueChanged :boolean = false;
    public get hasErrors(): boolean {
        if (this.five_star_v2_enabled) {
            return this.fiveStarOrganization ? this.fiveStarOrganization.noCcn
                || this.fiveStarOrganization.noCensus
                || this.fiveStarOrganization.noCmsData
                || this.fiveStarOrganization.noHoursData
                || this.fiveStarOrganization.rnZeroDays || this.fiveStarOrganization.noCaseMixRNHoursInfo ||this.fiveStarOrganization.noTotalNurseTurnoverInfo || this.fiveStarOrganization.noCaseMixTotalNurseHoursInfo || this.fiveStarOrganization.noStaffingRatingPastRatedPeriodInfo || this.fiveStarOrganization.noAdministratorDeparturesInfo : false;
        } else {
            return this.organization ? this.organization.noCcn
                || this.organization.noCensus
                || this.organization.noCmsData
                || this.organization.noHoursData
                || this.organization.rnZeroDays : false;
        }
    }

    get ratingValues() {
        return this.five_star_v2_enabled ? this.fiveStarOrganization.rating.value : this.organization.rating.value;
    }

    get ratingMaxValues() {
        return this.five_star_v2_enabled ? this.fiveStarOrganization.rating.maxValue : this.organization.rating.maxValue;
    }

    public get isCollapsed(): boolean {
        return this.m_isCollapsed;
    }

    public fullInfo: StaffingPredictorOrganizationInfo | FiveStarPredictorOrganizationInfo;

    @unsubscribe()
    private modelLoadedSubscription: Subscription;

    private m_isCollapsed: boolean = true;

    constructor(private managementService: StaffingPredictorManagementService,
        private changeDetector: ChangeDetectorRef,
        private changeManagement: ChangeManagementService, private modalService: ModalService) {
        this.managementService.onLoadStatus$.subscribe((val) => {
            this.isLoading = val;
        })
        this.managementService.isGoalValueChanged$.subscribe(val => {
            this.isValueChanged = val;
            if (this.isValueChanged) this.changeManagement.changeNotify();
            else this.changeManagement.clearChanges();
        })

    }

    public ngOnInit(): void {

        this.modelLoadedSubscription = this.managementService.onOrganizationInfoLoaded$.subscribe((data: StaffingPredictorOrganizationInfo | FiveStarPredictorOrganizationInfo) => {
            if(!this.five_star_v2_enabled){
            if (data.organization.organization.id === this.organization.organization.id) {
                this.fullInfo = data;
                this.fullInfo.organization = this.organization;
                this.changeDetector.markForCheck();
                this.changeDetector.detectChanges();
            }
            }
            else{
             if (data.organization.organization.id === this.fiveStarOrganization.organization.id) {
                this.fullInfo = data;
                this.fullInfo.organization = this.fiveStarOrganization;
                this.changeDetector.markForCheck();
                this.changeDetector.detectChanges();
            }   
            }
        });

        if (!this.m_isCollapsed && this.five_star_v2_enabled) {
            this.loadFullInfo();
        }
    }

    public collapsedChange(isCollapsed: boolean, index: number): void {
        this.m_isCollapsed = isCollapsed;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();        
        if(isCollapsed == true && this.isValueChanged == true) {
            this.m_isCollapsed = false;
            ConfirmChangesDialogComponent.openDialog('Warning', this.modalService, (result: boolean) => {
                if(result) {
                    this.m_isCollapsed = true;
                    this.isValueChanged = false;
                    this.managementService.disableCollapsableContainer$.next([false, this.sectionIndex]);
                }
                else this.m_isCollapsed = false;
                
        })
        }
        if (!this.m_isCollapsed) {
            this.loadFullInfo();
        }
    }

    private loadFullInfo(): void {
        const startDate = this.managementService.container.startDate;
        const endDate = this.managementService.container.endDate;
        if (this.fullInfo) {
            if (moment(startDate).isSame(this.fullInfo.startDate) && moment(endDate).isSame(this.fullInfo.endDate)) {
                return;
            }
        }
        const orgId = this.organization ? this.organization.organization.id : this.fiveStarOrganization.organization.id;
        this.managementService.organizationId = orgId;
        this.managementService.loadOrganizationInfo(orgId, startDate, endDate, this.five_star_v2_enabled);
    }
    public getGoalRating(value){
        this.goalRating = value;
    }
}

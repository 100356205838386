import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StaffingPredictorManagementService } from '../../services/index';
import { FiveStarRatingOrganization, StaffingPredictorOrganizationInfo, StaffingRatingOrganization } from '../../models/index';
import { PbjNavigationService } from '../../../../common/services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'slx-fivestar-predictor-star-rating',
  templateUrl: './fivestar-predictor-star-rating.component.html',
  styleUrls: ['./fivestar-predictor-star-rating.component.scss']
})
export class FivestarPredictorStarRatingComponent {

  @Input()
  public srOrganization: FiveStarRatingOrganization;
  @Input()
  public dataModel: StaffingPredictorOrganizationInfo;
  @Input()
  public five_star_goal_section_enabled: boolean = false;
  @Input()
  public isLoading :boolean = false;
  private navigation: PbjNavigationService;
  @Output() starRating= new EventEmitter<number>();

  @Input()
  public sectionIndex: number;

  @unsubscribe()
  private hideSubscription: Subscription;

  public hideToPrint: boolean;

  public get hasError(): boolean {
      return !this.hasData || this.noCcn || this.noCensus || this.noCmsData || this.noHoursData || this.rnZeroDays || this.isFiveStarRatingAvailable;
  }

  public get hasData(): boolean {
      return this.dataModel != null;
  }

  public get noCcn(): boolean {
      return this.hasData ? this.srOrganization.noCcn : false;
  }

  public get noCensus(): boolean {
      return this.hasData && !this.noCcn ? this.srOrganization.noCensus : false;
  }

  public get noHoursData(): boolean {
      return this.hasData && !this.noCcn && !this.noCensus ? this.srOrganization.noHoursData : false;
  }

  public get noCmsData(): boolean {
      return this.hasData && !this.noCcn && !this.noCensus && !this.noHoursData ? this.srOrganization.noCmsData : false;
  }

  public get rnZeroDays(): boolean {
    return this.hasData && !this.noCcn && !this.noCensus && !this.noHoursData ? this.srOrganization.rnZeroDays : false;
  }

  // public get outdatedData(): boolean {
  //     return this.hasData && !this.noCcn ? this.srOrganization.outdatedData : false;
  // }
  public get isFiveStarRatingAvailable(): boolean {
      if (this.hasData && !this.noCcn && !this.noHoursData && !this.noCensus && !this.noCmsData && !this.rnZeroDays && (this.srOrganization.noStaffingRatingPastRatedPeriodInfo || this.srOrganization.noCaseMixTotalNurseHoursInfo || this.srOrganization.noCaseMixRNHoursInfo || this.srOrganization.noTotalNurseTurnoverInfo || this.srOrganization.noAdministratorDeparturesInfo) )
      {
      return true
      }
      return false
  }

  constructor(private router: Router,
      private route: ActivatedRoute,
      private management: StaffingPredictorManagementService
  ) { 
    this.navigation = new PbjNavigationService(this.router, this.route);
  }

  public ngOnInit(): void {
    this.hideSubscription = this.management.hideOnPrint$.subscribe((hide: boolean) => {
      this.hideToPrint = hide;
    });
  }

  public goToConfigScreen(): void {
      this.navigation.NavigateToPbjOrganizationsConfiguration();
  }

  public getRating(value:number){
      this.starRating.emit(value);
  }
}

import * as _ from 'lodash';
import {Component, ElementRef, OnDestroy,OnInit} from "@angular/core";
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { Aca1095cManagementService } from '../../../services';
import { Subscription } from 'rxjs';


@Component({
  selector: 'aca-1095c-grid-column-header',
  templateUrl: './aca-1095-c-grid-column-header.component.html',
  styleUrls: ['./aca-1095-c-grid-column-header.component.scss'],
})

export class Aca1095cGridColumnHeaderComponent implements OnInit, OnDestroy, IHeaderAngularComp {
    public params: MyParams;
     
    private quickMonthColumnHeaderAction : Subscription;
       
    constructor(
      private aca1095cManagementService:Aca1095cManagementService
      ) {
        this.quickMonthColumnHeaderAction = this.aca1095cManagementService.subscribeToQuickMonthAction( (event:any) =>{
          if(event && event.name !== 'All') {
          this.params.link[event.name] = event.value;
          }
        });  
    }

    agInit(params: MyParams): void {
        this.params = params;
   }

    public ngOnInit() {
      this.quickMonthColumnHeaderAction = this.aca1095cManagementService.subscribeToQuickMonthColumnHeaderAction( (event:any) =>{
          this.params.link[event.name] = event.value;
      });  
    }
    
    ngOnDestroy() {
      this.quickMonthColumnHeaderAction.unsubscribe();
    }
    
    public getColumnName(name:string):string
    {
      if(_.isEqual(name,'All'))
      {
        return "";
      }
      else
      {
        return name;
      }
    }
    
    public onMonthLinkClick(event:any,action:boolean)
    {
      this.aca1095cManagementService.setQuickMonthAction({name:event.name,value:action});
    }
    

    refresh(params) : boolean {
        return true;
    }
}
interface MyParams extends IHeaderParams {
  link: any;
  name:string;  
}
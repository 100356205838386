import { Injectable, Type } from '@angular/core';
import * as _ from 'lodash';
import { Assert } from '../../../framework/index';

import { Subscription ,  Observable ,  Subject } from 'rxjs';
import { IChangeModel, ChangesDialogOptions } from '../../models/index';
import { ModalService } from '../modal/modal.service';
import { ConfirmChangesDialogComponent } from '../../components/confirm-changes-dialog/confirm-changes-dialog.component';
import { SessionService, BreadcrumbStateService } from '../../../core/services/index';

export interface INotifyChanged {
  componentId: string;
  changes: IChangeModel;
}

@Injectable()
export class ChangeManagementService {

  public changesEvent: Subject<INotifyChanged>;

  private changes: StringMap<IChangeModel>;
  private componentId: string;
  constructor(private modalService: ModalService, private sessionService: SessionService, private breadcrumpbService: BreadcrumbStateService) {
    this.changes = {};
    this.changesEvent = new Subject();
  }


  public setCurrentComponentId(componentId: string): void {
    this.componentId = componentId;
  }
  public clearCurrentComponentId(): void {
    this.componentId = undefined;
  }

  public changeNotify<T>(group?: string): void {
    if (!this.changes[this.componentId]) {
      this.changes[this.componentId] = { id: this.componentId, hasChanges: true, groups: {} };
    }
    if (group) {
      this.changes[this.componentId].groups[group] = true;
    }
    this.onChanges(this.componentId);
  }

  public clearChanges<T>(group?: string): void {
    if (this.changes[this.componentId] && group) {
      if (!this.changes[this.componentId].groups) {
        this.changes[this.componentId] = undefined;
        this.onChanges(this.componentId);
        return;
      }
      this.changes[this.componentId].groups[group] = undefined;
      const k: string = _.findKey(this.changes[this.componentId].groups, (gr: boolean) => gr !== undefined);
      if (!k) {
        this.changes[this.componentId] = undefined;
      }
      this.onChanges(this.componentId);
      return;
    }
    this.changes[this.componentId] = undefined;
    this.onChanges(this.componentId);
  }

  public canMoveForward<T>(componentId?: string, options?: ChangesDialogOptions): Promise<boolean> {
    let canMove: boolean = this.isPristine(componentId ? componentId : this.componentId);
    if (canMove || this.sessionService.isExpired()) {
      return Promise.resolve(true);
    }
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      ConfirmChangesDialogComponent.openDialog('Warning', this.modalService, (result: boolean) => {
        if (result) {
          this.changes = {};
          this.breadcrumpbService.resetLastRouteBackup();
        } else {
          this.breadcrumpbService.restoreLastRoute();
        }
        resolve(result);
      }, options);
    });
    return promise;
  }

  public checkPristine<T>(componentId?: string, options?: ChangesDialogOptions, group?: string): Promise<boolean> {
    let canMove: boolean = this.isPristine(componentId ? componentId : this.componentId, group);
    if (canMove || this.sessionService.isExpired()) {
      return Promise.resolve(true);
    }
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      ConfirmChangesDialogComponent.openDialog('Warning', this.modalService, (result: boolean) => {
        if (result) {
          this.changes = {};
        }
        resolve(result);
      }, options);
    });
    return promise;
  }

  private isPristine(type?: string, group?: string): boolean {
    if (type) {
      if(group) {
        if(!this.changes[type]) {
          return true;
        }
        return !this.changes[type].groups;
      }
      return !this.changes[type];
    } else {
      const firstDirtyKey: string = _.findKey(this.changes, (p: IChangeModel) => {
        return p !== undefined;
      });
      return !firstDirtyKey;
    }
  }

  private onChanges(componentId: string): void {
    this.changesEvent.next({ componentId: componentId, changes: this.changes[this.componentId] });
  }
}


import { NgModule } from '@angular/core';
import * as models from './models/index';
import { Epic } from 'redux-observable';

export let rootEpics: Epic<any>[] = [];

@NgModule({
    imports: [],
    declarations: [],
    providers: [],
    exports: []
})
export class StateModelModule {
}

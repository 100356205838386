import { Component, OnInit, OnDestroy, Input, Provider, ViewChild, HostListener } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { NgForm } from '@angular/forms';
import { IColumnSettings } from '../../../../core/models/index';

import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
  RowArgs,
  SelectionEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { timeAndAttendanceConfig, ITimeAndAttendanceConfig } from '../../../time-and-attendance.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { EmployeeDefinition } from '../../../../organization/models/index';
import { unsubscribe } from '../../../../core/decorators/index';

import { IndividualTimecardsManagementService } from '../../../services/index';
import {
  IndividualTimecardsContainer, IndividualTimecardsDay, TimecardsEarning, TimecardsAction, TimecardsActionCmd, IndividualTimecardsState, TimecardsColumnState
} from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-individual-timecards-grid',
  templateUrl: 'individual-timecards-grid.component.html',
  styleUrls: ['individual-timecards-grid.component.scss']
})
export class IndividualTimecardsGridComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public taConfig: ITimeAndAttendanceConfig;
  public records: IndividualTimecardsDay[];
  public state: IndividualTimecardsState;
  public gridState: KendoGridStateHelper<IndividualTimecardsDay>;

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;
  @ViewChild('form', {static: true})
  private form: NgForm;
  private managementService: IndividualTimecardsManagementService;
  private container: IndividualTimecardsContainer;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private stateChangedSubscription: Subscription;
  @unsubscribe()
  private actionSubscription: Subscription;
  @unsubscribe()
  private stateSubscription: Subscription;

  private aggregates: any = [
    { field: 'workedHours', aggregate: 'sum' },
    { field: 'totalHours', aggregate: 'sum' },
    { field: 'regularPay', aggregate: 'sum' },
    { field: 'overtimeAndExtra', aggregate: 'sum' },
    { field: 'totalPay', aggregate: 'sum' },
  ];
  private total: any = {};
  private highlightedRows: string[];

  constructor(managementService: IndividualTimecardsManagementService) {
    this.managementService = managementService;
    this.gridState = new KendoGridStateHelper<IndividualTimecardsDay>();
    this.gridState.state.group = [{ field: 'week', aggregates: this.aggregates }];
    this.gridState.state.sort = [{ field: 'date', dir: 'asc' }];
    this.highlightedRows = [];
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.taConfig = timeAndAttendanceConfig;
    this.stateChangedSubscription = this.managementService.onLoaded$.subscribe(
      (container: IndividualTimecardsContainer) => {
        this.container = container;
        this.records = container.records;
        this.refreshGrid();
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.actionSubscription = this.managementService.onActionCmd$.subscribe((v: TimecardsAction): void => {
      if (v.cmd === TimecardsActionCmd.excelExport) {
        this.grid.saveAsExcel();
      }
      if (v.cmd === TimecardsActionCmd.pdfExport) {
        this.grid.saveAsPDF();
      }
    });

    this.stateSubscription = this.managementService.onStateChanged$
      .subscribe((state: IndividualTimecardsState) => {
        this.state = state;
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public getUnit(field: string, value: any): string {
    if (!value) { return null; }
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].unit : null;
  }

  public isVisible(field: string, payCode?: boolean): boolean {
    if (!this.state) {
      return false;
    }
    let columnState: TimecardsColumnState = payCode? this.state.payCodeColumns: this.state.empColumns;
    if (!columnState || !columnState.columnsMap[field]) {
      return false;
    }
    let column: IColumnSettings = columnState.columnsMap[field];
    if (!this.state.isShowPayRates && column.payload && column.payload.payRateRelated) {
      return false;
    }
    return column.displayed;
  }

  public onSelectionChanged(selection: SelectionEvent): void {
    const selectedRow: RowArgs = _.head(selection.selectedRows);
    const deselectedRow: RowArgs = _.head(selection.deselectedRows);
    let prevSelectedRecord: string = null;
    const path: string = 'dataItem.date';

    if (deselectedRow) {
      prevSelectedRecord = this.stringifyDate(_.get(deselectedRow, path, null));
      this.highlightedRows.length = 0;
    }

    if (selectedRow) {
      const currentSelectedRecord: string = this.stringifyDate(_.get(selectedRow, path, null));
      if (prevSelectedRecord !== currentSelectedRecord) {
        this.highlightedRows.push(currentSelectedRecord);
      }
    }
  }

  public isRowSelected(): Function {
    return (event: { dataItem: IndividualTimecardsDay, index: number }): boolean => {
      return _.indexOf(this.highlightedRows, this.stringifyDate(event.dataItem.date)) !== -1;
    };
  }

  public getWidth(field: string): number {
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].width : 50;
  }

  public getFilter(field: string): string {
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].filter : null;
  }

  private stringifyDate(date: Date): string {
    return moment(date).format(appConfig.dateTimeFormat);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.state.group.forEach((group: any) => group.aggregates = this.aggregates);
    this.gridState.view = process(this.records, this.gridState.state);
    this.total = aggregateBy(this.records, this.aggregates);
  }
}


import { Injectable } from '@angular/core';
import { AppSettingsApiService } from './app-settings-api.service';
import { AppSetting } from '../model/app-setting';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppSettingKey, PbjModes } from '../model/app-setting-keys';
import { AppServerConfig } from '../model/app-server-config';
import { UserPermissions } from '../model/user-permissons';
import { NightShiftSetting } from '../model/night-shift-setting';
import { appConfig } from '../../app.config';

@Injectable()
export class AppSettingsManageService {
  private appServerConfig: AppServerConfig;

  private defaultConfig: AppServerConfig = {
    thresholdSeconds: 900,
    timeoutSeconds: 300,
    PbjEnabled: false,
    PbjMode: PbjModes.Manual,
    WorkerDupesReport : false,
    payrollExpManagementEnabled: false,
    maxFileSizeLimit: 4096,
    essTemplatesEnabled: false,
    enablePunchAttestation: false,
    userPermissions: new UserPermissions(),
    nightShiftSettings: [],
    bswiftIntegrationEnabled: false,
    enterpriseId: null,
    TwilioAccountSid: null,
    TwilioAuthToken: null,
    TwilioSenderPhone: null,
    requireTimeclockForPunches: false,
    serverUtcOffsetSec: moment().tz(appConfig.serverTimezone).utcOffset(),
    EnableRate4DecimalPlaces: false,
    duagrid: false,
    partnerintegrations: false,
    enableIncreaseReason: false,
    addTimeCardComments: false,
    IsGOClient: false,
    isSupervisorEnabled: false,
    preventOverlappingPunches: false,
    allowToExcludeFromTotals: false,
    CreateorEditPolicy: false,
    Deletepolicy: false,
    CopyAccrualPolicy:false,
    isBenefitDeductionEnabled: false,
    isNotesEnabled: false,
    isTermHistoryEditEnabled: false,
    preventoverlappingabsences: false,
    enableOpenShiftOnFly: false,
    ispositionHistoryWithAttributeEnabled:false,
    five_star_v2_enabled: false,
    five_star_goal_section_enabled: false,
    isBenefitDeductionEnabled2:false,
    TimecardRedesign: false,
    IncreaseFrequencyofShiftsPostedNotifications : false,
    AbilityForAdminToTurnOnAndOffAutoShiftPickUp :false,
    ScheduleNotPosted: false,
    NegativeAccrualExportTest: false,
    ScheduleRotationTemplate: false,
    AbilitytoChangeAccrualRuleTypeBasedonSeniority: false,
    isV6ReHireEnhacementEnabled: false,
    IsPBJExportCorpLevelEnabled: false,
    isDefaultShiftEnabled: false,
    ModifySchedulesApprovedinPayPeriods: false,
    IsPBJConsecutiveHrsEnabled:false,
    IsNextgenPayrollEnabled:false,
    isDownloadImportOptionEnabled: false,
    AllowSelectFutureDateRNMinHours: false,
    NextgenPhasedRollout: false,
    isPartialShiftEnabled: false,
    defaultPartialShiftCount: 2,
    ExportToPayrollFrequency: 0,
    ExportToPayrollDuration: 0,
    IsNextgenTimeExportEnabled:false,
    useTZnotOffset: false
};

  constructor(
    private apiService: AppSettingsApiService
  ) { }

  public async getAppServerConfig(): Promise<AppServerConfig> {
    return await this.loadConfig();
  }

  private async loadConfig(): Promise<AppServerConfig> {
    try {
      const settings = await this.apiService.getAppSettings();
      this.appServerConfig = settings;
      this.merge(this.appServerConfig, this.defaultConfig);
      return this.appServerConfig;
    } catch (e) {
      return this.defaultConfig;
    }
  }

  private merge(server: AppServerConfig, def: AppServerConfig): void {
    server.thresholdSeconds = server.thresholdSeconds ? server.thresholdSeconds : def.thresholdSeconds;
    server.timeoutSeconds = server.timeoutSeconds ? server.timeoutSeconds : def.timeoutSeconds;
    server.PbjMode = server.PbjMode ? server.PbjMode : def.PbjMode;
    server.PbjEnabled = !_.isNil(server.PbjEnabled) ? server.PbjEnabled : def.PbjEnabled;
    server.maxFileSizeLimit = server.maxFileSizeLimit ? server.maxFileSizeLimit : def.maxFileSizeLimit;
    server.bswiftIntegrationEnabled = server.bswiftIntegrationEnabled ? server.bswiftIntegrationEnabled : def.bswiftIntegrationEnabled;
    server.enterpriseId = server.enterpriseId ? server.enterpriseId : def.enterpriseId;
    server.TwilioAccountSid = server.TwilioAccountSid ? server.TwilioAccountSid : def.TwilioAccountSid;
    server.TwilioAuthToken = server.TwilioAuthToken ? server.TwilioAuthToken : def.TwilioAuthToken;
    server.TwilioSenderPhone = server.TwilioSenderPhone ? server.TwilioSenderPhone : def.TwilioSenderPhone;
    server.requireTimeclockForPunches = server.requireTimeclockForPunches ? server.requireTimeclockForPunches : def.requireTimeclockForPunches;
    server.serverUtcOffsetSec = server.serverUtcOffsetSec ? +server.serverUtcOffsetSec : def.serverUtcOffsetSec;
    server.EnableRate4DecimalPlaces = !_.isNil(server.EnableRate4DecimalPlaces) ? server.EnableRate4DecimalPlaces : def.EnableRate4DecimalPlaces;
    server.duagrid = !_.isNil(server.duagrid) ? server.duagrid : def.duagrid;
    server.partnerintegrations = !_.isNil(server.partnerintegrations) ? server.partnerintegrations : def.partnerintegrations;
    server.enableIncreaseReason = !_.isNil(server.enableIncreaseReason) ? server.enableIncreaseReason : def.enableIncreaseReason;
    server.isSupervisorEnabled = !_.isNil(server.isSupervisorEnabled) ? server.isSupervisorEnabled : def.isSupervisorEnabled;
    server.preventOverlappingPunches = !_.isNil(server.preventOverlappingPunches) ? server.preventOverlappingPunches : def.preventOverlappingPunches;
    server.CreateorEditPolicy = !_.isNil(server.CreateorEditPolicy) ? server.CreateorEditPolicy : def.CreateorEditPolicy;
    server.Deletepolicy = !_.isNil(server.Deletepolicy) ? server.Deletepolicy : def.Deletepolicy;
    server.CopyAccrualPolicy = !_.isNil(server.CopyAccrualPolicy) ? server.CopyAccrualPolicy : def.CopyAccrualPolicy;
    server.isBenefitDeductionEnabled = !_.isNil(server.isBenefitDeductionEnabled) ? server.isBenefitDeductionEnabled : def.isBenefitDeductionEnabled;
    server.isNotesEnabled = !_.isNil(server.isNotesEnabled) ? server.isNotesEnabled : def.isNotesEnabled;
    server.isTermHistoryEditEnabled = !_.isNil(server.isTermHistoryEditEnabled) ? server.isTermHistoryEditEnabled : def.isTermHistoryEditEnabled;
    server.preventoverlappingabsences = !_.isNil(server.preventoverlappingabsences) ? server.preventoverlappingabsences : def.preventoverlappingabsences;
    server.ispositionHistoryWithAttributeEnabled = !_.isNil(server.preventoverlappingabsences) ? server.ispositionHistoryWithAttributeEnabled : def.ispositionHistoryWithAttributeEnabled;
    server.five_star_v2_enabled = !_.isNil(server.five_star_v2_enabled) ? server.five_star_v2_enabled : def.five_star_v2_enabled;
    server.five_star_goal_section_enabled = !_.isNil(server.five_star_goal_section_enabled) ? server.five_star_goal_section_enabled : def.five_star_goal_section_enabled;
    server.isBenefitDeductionEnabled2 = !_.isNil(server.isBenefitDeductionEnabled2) ? server.isBenefitDeductionEnabled2 : def.isBenefitDeductionEnabled2;
    server.TimecardRedesign = !_.isNil(server.TimecardRedesign) ? server.TimecardRedesign : def.TimecardRedesign;
    server.ScheduleNotPosted = !_.isNil(server.ScheduleNotPosted) ? server.ScheduleNotPosted : def.ScheduleNotPosted;
    server.WorkerDupesReport = !_.isNil(server.WorkerDupesReport) ? server.WorkerDupesReport : def.WorkerDupesReport;
    server.IncreaseFrequencyofShiftsPostedNotifications = !_.isNil(server.IncreaseFrequencyofShiftsPostedNotifications) ? server.IncreaseFrequencyofShiftsPostedNotifications : def.IncreaseFrequencyofShiftsPostedNotifications;
    server.AbilityForAdminToTurnOnAndOffAutoShiftPickUp = !_.isNil(server.AbilityForAdminToTurnOnAndOffAutoShiftPickUp) ? server.AbilityForAdminToTurnOnAndOffAutoShiftPickUp : def.AbilityForAdminToTurnOnAndOffAutoShiftPickUp;
    server.NegativeAccrualExportTest = !_.isNil(server.NegativeAccrualExportTest) ? server.NegativeAccrualExportTest : def.NegativeAccrualExportTest;
    server.ScheduleRotationTemplate = !_.isNil(server.ScheduleRotationTemplate) ? server.ScheduleRotationTemplate : def.ScheduleRotationTemplate;
    server.AbilitytoChangeAccrualRuleTypeBasedonSeniority = !_.isNull(server.AbilitytoChangeAccrualRuleTypeBasedonSeniority) ? server.AbilitytoChangeAccrualRuleTypeBasedonSeniority : def.AbilitytoChangeAccrualRuleTypeBasedonSeniority;
    server.IsPBJExportCorpLevelEnabled = !_.isNil(server.IsPBJExportCorpLevelEnabled) ? server.IsPBJExportCorpLevelEnabled : def.IsPBJExportCorpLevelEnabled;
    server.isV6ReHireEnhacementEnabled = !_.isNil(server.isV6ReHireEnhacementEnabled) ? server.isV6ReHireEnhacementEnabled : def.isV6ReHireEnhacementEnabled;
    server.isDefaultShiftEnabled = !_.isNil(server.isDefaultShiftEnabled) ? server.isDefaultShiftEnabled : def.isDefaultShiftEnabled;
    server.IsPBJConsecutiveHrsEnabled = !_.isNil(server.IsPBJConsecutiveHrsEnabled) ? server.IsPBJConsecutiveHrsEnabled : def.IsPBJConsecutiveHrsEnabled;
    server.IsNextgenPayrollEnabled = !_.isNil(server.IsNextgenPayrollEnabled) ? server.IsNextgenPayrollEnabled : def.IsNextgenPayrollEnabled;
    server.isDownloadImportOptionEnabled = !_.isNil(server.isDownloadImportOptionEnabled) ? server.isDownloadImportOptionEnabled : def.isDownloadImportOptionEnabled;
    server.AllowSelectFutureDateRNMinHours = !_.isNil(server.AllowSelectFutureDateRNMinHours) ? server.AllowSelectFutureDateRNMinHours : def.AllowSelectFutureDateRNMinHours;
    server.NextgenPhasedRollout = !_.isNil(server.NextgenPhasedRollout) ? server.NextgenPhasedRollout : def.NextgenPhasedRollout;
    server.isPartialShiftEnabled = !_.isNil(server.isPartialShiftEnabled) ? server.isPartialShiftEnabled : def.isPartialShiftEnabled;
    server.defaultPartialShiftCount = !_.isNil(server.defaultPartialShiftCount) ? server.defaultPartialShiftCount : def.defaultPartialShiftCount;
    server.ExportToPayrollFrequency = server.ExportToPayrollFrequency ? server.ExportToPayrollFrequency : def.ExportToPayrollFrequency;
    server.ExportToPayrollDuration = server.ExportToPayrollDuration ? server.ExportToPayrollDuration : def.ExportToPayrollDuration;
    server.IsNextgenTimeExportEnabled = !_.isNil(server.IsNextgenTimeExportEnabled) ? server.IsNextgenTimeExportEnabled : def.IsNextgenTimeExportEnabled;
    server.useTZnotOffset = !_.isNil(server.useTZnotOffset) ? server.useTZnotOffset : def.useTZnotOffset;
  }
}


import {combineLatest} from 'rxjs/operators';
import { screenUtils } from './../../../../common/utils/screenUtils';
import { ActivatedRoute, Router, Params } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription ,  Observable ,  Subject } from 'rxjs';
import { from } from 'rxjs';

import { UserApplication, OrgLevel } from '../../../../state-model/models/index';
import { PayCycle } from '../../../../organization/models/index';
import { StateResetTypes } from '../../../../core/models/settings/reset-types';
import { IControlState, ControlStateKey } from '../../../../core/models/settings/component-states';
import { mutableSelect } from './../../../../core/decorators/redux-decorators';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { GenericListManagementService } from '../../../../organization/services/generic-list/generic-list-management.service';
import { GenericListRequest, GenericList, GenericRow, GenericField, GenericFieldDefinition, GenericListDefinition } from '../../../../organization/models/generic-list/index';
import { OvertimeApprovalsApiService } from '../../services/overtime-approvals/overtime-approvals-api.service';
import { genericGridConfig, IGenericGridConfig } from '../../../../organization/components/generic-list/generic-grid/generic-grid.config';
import { GenericGridComponent } from '../../../../organization/components/generic-list/generic-grid/generic-grid.component';
import { LookupApiService, ApplicationStateBusService } from '../../../../organization/services/index';
import { appConfig, IApplicationConfig } from './../../../../app.config';
import { genericListConfig } from '../../../../organization/generic-list.config';
import { IRangeDates } from '../../../../common/models/range-dates';
import { StateManagementService } from './../../../../common/services/state-management/state-management.service';
import { A4 } from '../../../../common/models/media/paper-sizes';
import { ColumnManagementService } from '../../../../common';
import { IGridState } from '../../../../core/models/index';
import { TimecardsApiService } from '../../../../time-and-attendance/services/timecards/timecards-api.service';

@Component({
  moduleId: module.id,
  selector: 'slx-overtime-approvals',
  templateUrl: 'overtime-approvals.component.html',
  styleUrls: ['overtime-approvals.component.scss'],
  providers: [GenericListManagementService, StateManagementService, ColumnManagementService]
})
export class OvertimeApprovalsComponent implements OnInit, OnDestroy {
  public startDate: Date;
  public endDate: Date;
  public isLoading: boolean;
  public get canMakeApproveAction(): boolean {
    return this.selectedApprove.length > 0 &&
      _.every(this.selectedApprove, fields => !fields.IsPayCycleLocked.value.value && !fields.IsTimecardLocked.value.value);
  }
  public get canMakeUnapproveAction(): boolean {
    return this.selectedUnapprove.length > 0 &&
      _.every(this.selectedUnapprove, fields => !fields.IsPayCycleLocked.value.value && !fields.IsTimecardLocked.value.value);
  }

  public get someOvertimesAreLocked(): boolean {
    return _.some(this.selectedApprove, fields => fields.IsPayCycleLocked.value.value || fields.IsTimecardLocked.value.value) ||
      _.some(this.selectedUnapprove, fields => fields.IsPayCycleLocked.value.value || fields.IsTimecardLocked.value.value);
  }

  @ViewChild(GenericGridComponent, { static: true })
  public grid: GenericGridComponent;
  public gridConfig: IGenericGridConfig;

  private selectedApprove: StringMap<GenericField>[];
  private selectedUnapprove: StringMap<GenericField>[];
  private appConfig: IApplicationConfig;
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  private orgLevel: OrgLevel;
  private application: UserApplication;
  @unsubscribe()
  private definitionsLoadedSubscription: Subscription;
  private valuesLoadedSubscription: Subscription;
  @unsubscribe()
  private loadSubscription: Subscription;

  private readonly m_dateFiltersControlId: string = 'DateFiltersNgx';
  private readonly m_dateFiltersResetType: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
  private stateKey: ControlStateKey;
  private empIdsFilter: number[] = null;
  public pdfColorClass: string = 'timecard-pdf-grid';

  constructor(
    private genericListManagementService: GenericListManagementService,
    private apiService: OvertimeApprovalsApiService,
    private lookupApiService: LookupApiService,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private activatedRoute: ActivatedRoute, private router: Router,
    private appBusService: ApplicationStateBusService,
    private timecardApiService: TimecardsApiService
  ) {

    const mom: moment.Moment = moment();


    this.endDate = moment().toDate();
    this.startDate = moment().subtract(30, 'days').toDate();

    this.isLoading = true;
    this.selectedApprove = [];
    this.selectedUnapprove = [];

    this.gridConfig = _.assign({}, genericGridConfig);
    this.gridConfig.pdfExport.fileName = 'OvertimeApprovals.pdf';
    this.gridConfig.pdfExport.autoscale = true;
    this.gridConfig.pdfExport.paperSize = A4.name;
    this.gridConfig.pdfExport.landscape = true;
    this.gridConfig.excelExport.fileName = 'OvertimeApprovals.xlsx';
  }

  public ngOnInit(): void {

    this.stateManagement.init('OvertimeApprovalsComponent', true);
    this.appConfig = appConfig;
    this.loadApp();
    this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe((list: GenericList) => {
      this.isLoading = false;
      this.stateManagement.loadedData({});
      this.grid.gridState.state.take = this.grid.pageSize;
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onSelectionChanged(items: GenericRow[]): void {
    this.selectedApprove.length = 0;
    this.selectedUnapprove.length = 0;
    _.forEach(items, (item: GenericRow) => {
      if (item.fields.IsOvertimeApproved.value.value === 'Yes') {
        this.selectedUnapprove.push(item.fields);
      } else {
        this.selectedApprove.push(item.fields);
      }
    });
  }

  public onExportToExcel(): void {
    this.grid.exportToExcel();
  }

  public onExportToPdf(): void {
    this.grid.exportToPdf();
  }

  public onApprove(): void {
    this.isLoading = true;
    this.apiService.approve(this.selectedApprove)
      .then(this.success.bind(this))
      .catch(this.failed.bind(this));
  }

  public onUnapprove(): void {
    this.isLoading = true;
    this.apiService.unapprove(this.selectedUnapprove)
      .then(this.success.bind(this))
      .catch(this.failed.bind(this));
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    if (this.startDate.getTime() !== startDate.getTime() || this.endDate.getTime() !== endDate.getTime()) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.saveFilters();
      this.loadValues();
      this.resetStateSkipValue(this.grid.gridKey, this.stateKey);
    }
  }

  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }


  private loadApp(): void {
    this.orgLevelSubscription = this.orgLevel$.pipe(
      combineLatest(this.appBusService.appSelected$, this.activatedRoute.queryParams))
      .subscribe((value: [OrgLevel, UserApplication, Params]) => {
        let [orgLevel, application, queryParams]: [OrgLevel, UserApplication, Params] = value;
        if (!orgLevel || !orgLevel.id || !application.id) {
          return;
        }
        let queryOrgLevelId: number = Number(queryParams['orgLevelId']);
        if (queryOrgLevelId > 0 && queryOrgLevelId !== orgLevel.id) {
          return;
        }

        this.orgLevel = orgLevel;
        this.application = application;
        let startDate = queryParams['startDate'];
        let endDate = queryParams['endDate'];
        if (queryParams['empIds']) {
          this.empIdsFilter = _.concat([], queryParams['empIds']);
        }
        if (startDate && endDate) {
          this.startDate = moment(startDate, appConfig.linkDateFormat).toDate();
          this.endDate = moment(endDate, appConfig.linkDateFormat).toDate();
        } else {
          this.restoreFilters();
        }
        if (this.startDate && this.endDate) {
          this.loadData();
        } else {
          this.loadDataWithPayCycles();
        }
      });
  }

  private loadData(): void {
    this.definitionsLoadedSubscription = this.loadDefinitions()
      .subscribe(() => this.load());
  }

  private loadDataWithPayCycles(): void {
    this.definitionsLoadedSubscription = this.loadDefinitions().pipe(
      combineLatest(this.loadPayCycles()))
      .subscribe(() => this.load());
  }

  private load(): void {
    this.stateManagement.onComponentActiveStateChanged({});
    this.loadValues();
    this.definitionsLoadedSubscription.unsubscribe();
  }

  private success(error: any): void {
    this.timecardApiService.clearIndividualTimecardsCacheData();
    this.isLoading = false;
    this.loadValues();
  }

  private failed(error: any): void {
    this.isLoading = false;
  }

  private loadValues(): void {
    this.isLoading = true;
    this.selectedApprove.length = 0;
    this.selectedUnapprove.length = 0;
    let req: GenericListRequest = new GenericListRequest();
    req.startDate = this.startDate;
    req.endDate = this.endDate;
    if (this.empIdsFilter && this.empIdsFilter.length > 0) {
      req.ids = this.empIdsFilter;
    }
    this.genericListManagementService.loadListValues(this.orgLevel.id, req);
  }

  private loadDefinitions(): Subject<GenericListDefinition> {
    this.isLoading = true;
    this.genericListManagementService.loadListDefinition(
      this.application.id,
      this.orgLevel.id,
      genericListConfig.overtimeApprovals
    );

    return this.genericListManagementService.onDefinitionsLoaded$;
  }

  private loadPayCycles(): Observable<PayCycle[]> {
    const promise: Promise<PayCycle[]> = this.lookupApiService.getPayCyles(this.orgLevel.id);
    promise.then((cycles: PayCycle[]) => this.setPayCycle(cycles));

    return from(promise);
  }

  private setPayCycle(cycles: PayCycle[]): void {
    const currentDate: moment.Moment = moment();
    const currentCycles = _.filter(cycles, (cycle) => {
      const currStartDate: moment.Moment = moment(cycle.startDate);
      const currEndDate: moment.Moment = moment(cycle.endDate);
      return moment(currentDate).isBetween(currStartDate, currEndDate);
    });

    let startDate: PayCycle = _.first(currentCycles);
    if (startDate) {
      this.startDate = startDate.startDate;
      this.endDate = startDate.endDate;
    }
    this.saveFilters();

  }

  private saveFilters(): void {
    this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId,
      {
        value: { startDate: this.startDate, endDate: this.endDate }
      },
      this.m_dateFiltersResetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
  }

  private restoreFilters(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
    if (state && state.value) {
      if (state.value.startDate) this.startDate = moment(state.value.startDate).toDate();
      if (state.value.endDate) this.endDate = moment(state.value.endDate).toDate();
    }
  }

  private resetStateSkipValue(gridKey: string, stateKey: ControlStateKey) {
    let state: IGridState = this.stateManagement.getGridState(gridKey, stateKey);
    state.skip = 0;
    this.stateManagement.setGridState(gridKey, state, stateKey);
  }

}

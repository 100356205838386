import { WfmSyncResponseComponent } from './../configuration/components/wfm-sync/wfm-sync-response/wfm-sync-response.component';
import { LicenseTypesComponent } from './../configuration/components/license-types/license-types.component';
import { PayrollExportTestComponent } from './../app-modules/payroll/components/payroll-export-test/payroll-export-test/payroll-export-test.component';
import { UserPasswordSettingsComponent } from './../configuration/components/user-password-settings/user-password-settings.component';
import { LogPhoneCallComponent } from './../employee/employee/components/log-phone-call/log-phone-call.component';
import { TimeclockCommunicationComponent } from './../employee/employee/components/timeclock-communication/timeclock-communication.component';

import { Routes } from '@angular/router';

import {
  AuthorizessoComponent,
  PortalEmployeeComponent
} from './components/index';
import { EmployeeListComponent, LatenessesComponent, DirectCareHoursComponent, ScheduleCommentsComponent, ProjectedHoursComponent } from '../employee/employee-list/components/index';
import { EmployeeSectionsComponent } from '../employee/employee-sections/components/index';
import {
  MasterScheduleComponent,
  ScheduleEntryComponent,
  VacationPlannerComponent,
  IndividualScheduleComponent,
  IndividualScheduleEmployeeComponent,
  DetailScreenComponent,
  DailyUnitGridComponent,
  PostScheduleComponent,
  PostedSchedulesComponent,
  OpenShiftManagementComponent,
  LeaveRequestComponent,
  CensusEntriesPageComponent,
  DailyUnitRouterComponent
} from '../scheduler/components/index';

import { AddEmployeeComponent, EmployeeCallLogsComponent } from '../employee/employee/components/index';
import { EmployeeSearchComponent } from '../employee/employee-search/components/index';
import{
  AbsencesComponent,
  UnitsComponent,
  ConfigurePositionsComponent,
  ConfigurePartnerPositionsComponent,
  ConfigureConstraintsComponent,
  ConfigureAgencyComponent,
  UserRolesAssignementComponent,
  UserProfileComponent,
  AddAgencyEmployeeComponent,
  ScheduleAgencyEmployeeComponent,
  TimeclocksListComponent,
  RolesManagementComponent,
  IdealScheduleComponent,
  ShiftsComponent,
  HolidaysComponent,
  AttendancePointsConfigComponent,
  ConfigurePbjOrganizationsComponent,
  PayCodeConfigurationComponent,
  PayCodeDetailsComponent,
  CopyPayCodesComponent,
  TelepunchLocationsComponent,
  ConfigureCostCentersComponent,
  GeolocationComponent,
  ConfigureEmployersComponent,
  AcaMeasurementComponent,
  ManageAcaPeriodsComponent,
  IpManagementComponent,
  TempReadingsComponent,
  Aca1095cComponent,
  AcaC1095AuditComponent,
  UserLoginManagement,
  PayPoliciesComponent
} from '../configuration/components/index';
import {
  BudgetListComponent,
  TimeclockAssignmentComponent,
  TimecardsDisplayComponent,
  TimecardSummaryComponent,
  IndividualTimecardsComponent,
  ExceptionConsolePageComponent,
  SupervisorAssignmentComponent,
  TaConsoleComponent,
  DailyPunchesPageComponent,
  TimeclockReviewComponent,
  AttendanccePointsTotalsComponent
} from '../time-and-attendance/components/index';
import { OperationalConsoleComponent, ScheduleConsoleComponent } from '../scheduler/schedule-console/components/index';
import { DailyTimeCardComponent } from '../time-and-attendance/components/daily-timecard/index';
import { AttendancePointsComponent } from '../time-and-attendance/components/index';
import { ApprovalRequiredCodesComponent, OvertimeApprovalsComponent } from './../app-modules/approvals/components/index';
import {
  PbjLogExportComponent,
  PbjLogExportDetailsComponent,
  PbjManualEntryComponent,
  PbjReconciliationComponent,
  PbjReconciliationEmployeeComponent
} from '../app-modules/pbj/components/index';
import { PbjReconciliationResolver } from '../app-modules/pbj/services/index';
import { PayrollExportComponent } from '../app-modules/payroll/components/index';
import { CanLeaveGuard } from '../common/services/index';
import { OrgLevelResolver } from './services/index';
import { DailyPunchesResolver, ExceptionConsoleResolver } from '../time-and-attendance/services/index';
import { EssTemplatesComponent } from '../app-modules/ess-templates/components/index';
import { ShiftSwapComponent } from '../scheduler/components/shift-swap';
import { LMTypeConfigurationComponent } from '../app-modules/leave-management/components/lm-type-configuration/index';
import { PunchAttestationComponent } from '../app-modules/punch-attestation/components/index';
import { WorkersCompensationTableComponent } from '../app-modules/workers-compensation/components/workers-compensation-table/index';

import { BenefitClassConfigurationComponent } from '../app-modules/benefits/components/index';
import { ConfigurePerformanceManagementComponent } from '../app-modules/performance-management/components';
import { AuditTrailComponent } from '../app-modules/audit-trail/components';
import { AuditTrailConfigComponent } from '../app-modules/audit-trail/components/audit-trail-config/audit-trail-config.component';
import { AcaExportComponent } from '../app-modules/export-data/components/aca-export/aca-export.component';
import { MessageCenterComponent } from '../app-modules/message-center/components/index';
import { NotificationsComponent } from '../configuration/components/notifications/notifications.component';
import { EmployeePunchRequestComponent } from '../time-and-attendance/components/punches/employee-punch-request/employee-punch-request.component';
import { AgencyStaffingConsoleComponent } from '../scheduler/components/agency-staffing-console/agency-staffing-console.component';
import { ClientActivationComponent } from '../configuration/components/client-activation/client-activation.component';
import { RequestAgencyStaffComponent } from '../scheduler/components/request-agency-staff/request-agency-staff.component';
import { PunchProfileComponent } from '../app-modules/punch-profile/components';
import { PunchAttestationResolver } from '../app-modules/punch-attestation/services/punch-attestation.resolver';
import { PunchProfileResolver } from '../app-modules/punch-profile/services/punch-profile.resolver';
import { SchedulesNotPostedComponent } from '../scheduler/components/schedules-not-posted/schedules-not-posted.component';
import { WfmSyncComponent } from '../configuration/components/wfm-sync/wfm-sync.component';
import { AddOpenShiftsV2Component } from '../scheduler/components/add-open-shifts/add-open-shifts-v2/add-open-shifts-v2.component';
import { WfmDailytransactionReportComponent } from '../configuration/components/wfm-sync/wfm-dailytransaction-report/wfm-dailytransaction-report.component';
import { ScheduledRotationsComponent } from '../scheduler/components/scheduled-rotations/scheduled-rotations.component';
import { OpenPostionControlComponent } from '../scheduler/components/open-position-control/open-position-control.component';
import { WorkLocationSyncComponent } from '../configuration/components/wfm-sync/work-location-sync/work-location-sync.component';
import { EventReportComponent } from '../configuration/components/wfm-sync/event-report/event-report.component';
import { WorkLocationMigrationComponent } from '../configuration/components/wfm-sync/work-location-migration/work-location-migration.component';
import { OrgSyncComponent } from '../configuration/components/wfm-sync/org-sync/org-sync.component';
import { EmployeeSyncComponent } from '../configuration/components/wfm-sync/employee-sync/employee-sync.component';
import { OrganizationSyncComponent } from '../configuration/components/wfm-sync/organization-sync/organization-sync.component';
import { EventErrorReportComponent } from '../configuration/components/wfm-sync/event-error-report/event-error-report.component';
import { PostEventSyncComponent } from '../configuration/components/wfm-sync/post-event-sync/post-event-sync.component';
import { SyncDashboardMigrationComponent } from '../configuration/components/wfm-sync/sync-dashboard-migration/sync-dashboard-migration.component';
import { SyncDashboardComponent } from '../configuration/components/wfm-sync/sync-dashboard/sync-dashboard.component';
import { ShiftGroupComponent } from '../configuration/components/shift-group/shift-group.component';
import { PbjDuplicateWorkersComponent } from '../app-modules/pbj/components/pbj-duplicate-workers/pbj-duplicate-workers.component';
import { OrgStructureCodeComponent } from '../configuration/components/payroll/code-mapping/org-structure-code/org-structure-code.component';

import { FileUploadsComponent } from '../configuration/components/file-uploads/file-uploads.component';
import { TAEventReportComponent } from '../configuration/components/wfm-sync/ta-event-report/ta-event-report.component';
import { WFMOngoingSyncComponent } from '../configuration/components/wfm-sync/wfm-ongoing-sync/wfm-ongoing-sync.component';
import { WFMMigrationSyncComponent } from '../configuration/components/wfm-sync/wfm-migration-sync/wfm-migration-sync.component';
import { PaydataEventReportComponent } from '../configuration/components/wfm-sync/paydata-event-report/paydata-event-report.component';
import { PayrollPayCodesComponent } from '../configuration/components/payroll/payroll-pay-codes/payroll-pay-codes.component';
import { EliteDashboardComponent } from '../app-modules/ElieteDashboard/components/elite-dashboard.component';
import { CreateEliteDashboardComponent } from '../app-modules/ElieteDashboard/components/create-report/elite-dashboard-create.component';
import { ViewEliteDashboardComponent } from '../app-modules/ElieteDashboard/components/view-report/elite-dashboard-view.component';
export const globalRoutes: Routes = [
  {
    path: 'elite_dashboard_create_report',
    component: CreateEliteDashboardComponent,
    data: { componentId: 'elite_dashboard_create_report'}
  },
  {
    path: 'elite_dashboard_view_report',
    component: ViewEliteDashboardComponent,
    data: { componentId: 'elite_dashboard_view_report'}
  },
  {
    path: 'elite_dashboard',
    component: EliteDashboardComponent,
    data: { componentId: 'elite_dashboard'}
  },
  {
    path: 'code_mapping',
    component: OrgStructureCodeComponent,
    data: { componentId: 'code_mapping'}
  },
  {
    path: 'payroll_paycodes',
    component: PayrollPayCodesComponent,
    canDeactivate: [CanLeaveGuard],
    data: { componentId: 'payroll_pay_codes'}
  },
  {
    path: 'employee_list',
    resolve: [OrgLevelResolver],
    component: EmployeeListComponent,
    data: { componentId: 'employee_list', accessContext: 'OrgLevel', orgLevelChangeDisabled: false }
  },
  {
    path: 'authorize_sso',
    component: AuthorizessoComponent,
  },
  {
    path: 'add_employee',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: AddEmployeeComponent,
    data: { componentId: 'add_employee', accessContext: 'OrgLevel', orgLevelChangeDisabled: true }
  },

  {
    path: 'call_log',
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        component: EmployeeCallLogsComponent,
        data: { componentId: 'call_log', accessContext: 'OrgLevel' }
      },
      {
        path: 'send_clock_message',
        resolve: [OrgLevelResolver],
        component: TimeclockCommunicationComponent,
        data: { componentId: 'send_clock_message', accessContext: 'OrgLevel' }
      },
      {
        path: 'log_phone_call',
        resolve: [OrgLevelResolver],
        component: LogPhoneCallComponent,
        data: { componentId: 'log_phone_call', accessContext: 'OrgLevel' }
      }
    ]
  },
  {
    path: 'configure_units',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: UnitsComponent,
    data: { componentId: 'configure_units', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_absences',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: AbsencesComponent,
    data: { componentId: 'configure_absences', accessContext: 'OrgLevel' }
  },
  {
    path: 'positions',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ConfigurePositionsComponent,
    data: { componentId: 'positions', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_constraints',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ConfigureConstraintsComponent,
    data: { componentId: 'configure_constraints', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_shifts',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ShiftsComponent,
    data: { componentId: 'configure_shifts', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_Shift_Group',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ShiftGroupComponent,
    data: { componentId: 'configure_Shift_Group', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_roles',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: RolesManagementComponent,
    data: { componentId: 'configure_roles', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_ideal_schedule',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: IdealScheduleComponent,
    data: { componentId: 'configure_ideal_schedule', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_users',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: UserRolesAssignementComponent,
    data: { componentId: 'configure_users', accessContext: 'OrgLevel' }
  },

  {
    path: 'configure_telepunch_locations',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: TelepunchLocationsComponent,
    data: { componentId: 'configure_telepunch_locations' }
  },
  {
    path: 'configure_cost_centers',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ConfigureCostCentersComponent,
    data: { componentId: 'configure_cost_centers', accessContext: 'OrgLevel' }
  },
  {
    path: 'user/:userId/profile',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: UserProfileComponent,
    data: { componentId: 'user_profile', accessContext: 'OrgLevel', orgLevelChangeDisabled: true }
  },
  {
    path: 'user/create',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: UserProfileComponent,
    data: { componentId: 'user_create', accessContext: 'OrgLevel', orgLevelChangeDisabled: true }
  },
  {
    path: 'holidays',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: HolidaysComponent,
    data: { componentId: 'holidays', accessContext: 'OrgLevel' }
  },
  {
    path: 'user_password_settings',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: UserPasswordSettingsComponent,
    data: { componentId: 'user_password_settings' }
  },
  {
    path: 'geolocation_punches',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: GeolocationComponent,
    data: { componentId: 'user_password_settings' }
  },
  {
    path: 'intg_notifications',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: NotificationsComponent,
    data: { componentId: 'intg_notifications', accessContext: 'OrgLevel' }
  },

  {
    path: 'license_types',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: LicenseTypesComponent,
    data: { componentId: 'license_types', accessContext: 'OrgLevel' }
  },
];

export const appCommonRoutes: Routes = [
  ...globalRoutes,
  {
    path: 'master_schedule',
    resolve: [OrgLevelResolver],
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        component: MasterScheduleComponent,
        data: { componentId: 'master_schedule', accessContext: 'OrgLevel' }
      },
      {
        canDeactivate: [CanLeaveGuard],
        resolve: [OrgLevelResolver],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        path: 'employee/:employeeId/shifts/:date',
        component: ScheduleEntryComponent,
        data: { componentId: 'schedule_entry', accessContext: 'Employee', orgLevelChangeDisabled: true }
      },
      {
        path: 'details_screen/:date',
        resolve: [OrgLevelResolver],
        component: DetailScreenComponent,
        data: { componentId: 'details_screen', accessContext: 'OrgLevel' }
      },
      {
        path: 'post/:date',
        resolve: [OrgLevelResolver],
        component: PostScheduleComponent,
        data: { componentId: 'post', accessContext: 'OrgLevel' }
      },
    ]
  },
  {
    path: 'employee/:employeeId/shifts/:date',
    resolve: [OrgLevelResolver],
    component: ScheduleEntryComponent,
    data: { componentId: 'schedule_entry', accessContext: 'Employee' }
  },
  {
    path: 'daily_unit_assignment',
    resolve: [OrgLevelResolver],
    component: DailyUnitRouterComponent,
    data: { componentId: 'daily_unit_assignment', accessContext: 'OrgLevel' }
  },
  {
    path: 'daily_unit_assignment/request_agency_staff',
    resolve: [OrgLevelResolver],
    component: RequestAgencyStaffComponent,
    data: { componentId: 'request_agency_staff', orgLevelChangeDisabled: true }
  },
  {
    path: 'daily_unit_assignment/add_open_shifts',
    resolve: [OrgLevelResolver],
    component: AddOpenShiftsV2Component,
    data: { componentId: 'add_open_shifts' , orgLevelChangeDisabled: true }
  },
  {
    path: 'daily_unit_assignment/add_open_shifts/:date',
    resolve: [OrgLevelResolver],
    component: AddOpenShiftsV2Component,
    data: { componentId: 'add_open_shifts' , orgLevelChangeDisabled: true }
  },
  {
    path: 'daily_unit_assignment/request_agency_staff/:date',
    resolve: [OrgLevelResolver],
    component: RequestAgencyStaffComponent,
    data: { componentId: 'request_agency_staff', orgLevelChangeDisabled: true }
  },
  {
    path: 'daily_unit_assignment/:date',
    resolve: [OrgLevelResolver],
    component: DailyUnitRouterComponent,
    data: { componentId: 'daily_unit_assignment', accessContext: 'OrgLevel' }
  },
  {
    path: 'pto_planner',
    resolve: [OrgLevelResolver],
    component: VacationPlannerComponent,
    data: { componentId: 'pto_planner', accessContext: 'OrgLevel' }
  },
  {
    path: 'individual_schedule',
    resolve: [OrgLevelResolver],
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        component: IndividualScheduleComponent,
        data: { componentId: 'individual_schedule', accessContext: 'OrgLevel' }
      },
      {
        path: 'employee/:employeeId',
        resolve: [OrgLevelResolver],
        component: IndividualScheduleEmployeeComponent,
        data: { componentId: 'individual_schedule', accessContext: 'Employee', orgLevelChangeDisabled: true }
      }
    ]
  },
  {
    path: 'posted_schedules',
    resolve: [OrgLevelResolver],
    component: PostedSchedulesComponent,
    data: { componentId: 'posted_schedules', accessContext: 'OrgLevel' }
  },
  {
    path: 'schedules_not_posted',
    resolve: [OrgLevelResolver],
    component: SchedulesNotPostedComponent,
    data: { componentId: 'schedules_not_posted', accessContext: 'OrgLevel' }
  },
  {
    path: 'scheduled_rotations',
    resolve: [OrgLevelResolver],
    component: ScheduledRotationsComponent,
    data: { componentId: 'scheduled_rotations', accessContext: 'OrgLevel' },
    canDeactivate : [CanLeaveGuard]
  },
  {
    path: 'budget_management',
    resolve: [OrgLevelResolver],
    component: BudgetListComponent,
    data: { componentId: 'budget_management', accessContext: 'OrgLevel' }
  },
  {
    path: 'leave_requests',
    resolve: [OrgLevelResolver],
    component: LeaveRequestComponent,
    data: { componentId: 'leave_requests', accessContext: 'OrgLevel' }
  },
  {
    path: 'latenesses',
    resolve: [OrgLevelResolver],
    component: LatenessesComponent,
    data: { componentId: 'latenesses', accessContext: 'OrgLevel' }
  },
  {
    path: 'attendance_points',
    resolve: [OrgLevelResolver],
    component: AttendancePointsComponent,
    data: { componentId: 'attendance_points', accessContext: 'OrgLevel' }
  },
  {
    path: 'attendance_points_totals',
    resolve: [OrgLevelResolver],
    component: AttendanccePointsTotalsComponent,
    data: { componentId: 'attendance_points_totals', accessContext: 'OrgLevel' }
  },
  {
    path: 'attendance_points_config',
    resolve: [OrgLevelResolver],
    component: AttendancePointsConfigComponent,
    canDeactivate: [CanLeaveGuard],
    data: { componentId: 'attendance_points_config', accessContext: 'OrgLevel' }
  },
  {
    path: 'direct_care_hours',
    resolve: [OrgLevelResolver],
    component: DirectCareHoursComponent,
    data: { componentId: 'direct_care_hours', accessContext: 'OrgLevel' }
  },
  {
    path: 'schedule_comments',
    resolve: [OrgLevelResolver],
    component: ScheduleCommentsComponent,
    data: { componentId: 'schedule_comments', accessContext: 'OrgLevel' }
  },
  {
    path: 'projected_hours',
    resolve: [OrgLevelResolver],
    component: ProjectedHoursComponent,
    data: { componentId: 'projected_hours', accessContext: 'OrgLevel' }
  },
  {
    path: 'Open_Position_Control',
    resolve: [OrgLevelResolver],
    component: OpenPostionControlComponent,
    data: { componentId: 'Open_Position_Control' }
  },
  {
    path: 'so_console',
    resolve: [OrgLevelResolver],
    component: ScheduleConsoleComponent,
    data: { componentId: 'so_console', accessContext: 'OrgLevel' }
  },
  {
    path: 'shift_swaps',
    resolve: [OrgLevelResolver],
    component: ShiftSwapComponent,
    data: { componentId: 'shift_swaps', accessContext: 'OrgLevel' }
  },
  {
    path: 'operational_dashboard',
    resolve: [OrgLevelResolver],
    component: OperationalConsoleComponent,
    data: { componentId: 'operational_dashboard', accessContext: 'OrgLevel' }
  },
  {
    path: 'so_console/:date',
    resolve: [OrgLevelResolver],
    component: ScheduleConsoleComponent,
    data: { componentId: 'so_console', accessContext: 'OrgLevel' }
  },
  {
    path: 'ta_console',
    resolve: [OrgLevelResolver],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    component: TaConsoleComponent,
    data: { componentId: 'ta_console', accessContext: 'OrgLevel' }
  },
  {
    path: 'open_shift_management',
    resolve: [OrgLevelResolver],
    component: OpenShiftManagementComponent,
    data: { componentId: 'open_shift_management', accessContext: 'OrgLevel' }
  },
  {
    path: 'employee_search', //temporary
    resolve: [OrgLevelResolver],
    component: EmployeeSearchComponent,
    data: { componentId: 'employee_search', accessContext: 'OrgLevel' }
  },
  {
    path: 'set_up_timeclocks',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: TimeclocksListComponent,
    data: { componentId: 'set_up_timeclocks', accessContext: 'OrgLevel' }
  },
  {
    path: 'punch_attestation',
    resolve: [OrgLevelResolver, PunchAttestationResolver],
    canDeactivate: [CanLeaveGuard],
    component: PunchAttestationComponent,
    data: { componentId: 'punch_attestation', accessContext: 'OrgLevel' },
  },
  {
    path: 'punch_profile',
    resolve: [OrgLevelResolver, PunchProfileResolver],
    canDeactivate: [CanLeaveGuard],
    component: PunchProfileComponent,
    data: { componentId: 'punch_profile', accessContext: 'OrgLevel' }
  },
  {
    path: 'positions',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ConfigurePositionsComponent,
    data: { componentId: 'positions', accessContext: 'OrgLevel' }
  },
  {
    path: 'license_types',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: LicenseTypesComponent,
    data: { componentId: 'license_types', accessContext: 'OrgLevel' }
  },
  {
    path: 'intg_position_mapping',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ConfigurePartnerPositionsComponent,
    data: { componentId: 'intg_position_mapping', accessContext: 'OrgLevel' }
  },
  {
    path: 'client_activation',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ClientActivationComponent,
    data: { componentId: 'client_activation', accessContext: 'OrgLevel' }
  },
  {
    path: 'employee_sync',
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        canDeactivate: [CanLeaveGuard],
        component: WfmSyncComponent,
        data: { componentId: 'employee_sync', accessContext: 'OrgLevel'}
      },
      {
        path: ':syncId/sync_response',
        resolve: [OrgLevelResolver],
        canDeactivate: [CanLeaveGuard],
        component: WfmSyncResponseComponent,
        data: { componentId: 'employee_sync', accessContext: 'OrgLevel'}
      },
    ]

  },
  {
    path: 'daily_transaction_report',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: WfmDailytransactionReportComponent,
    data: { componentId: 'daily_transaction_report', accessContext: 'OrgLevel'}
  },
  {
    path: 'paydata_event_report',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: PaydataEventReportComponent,
    data: { componentId: 'paydata_event_report', accessContext: 'OrgLevel'}
  },
  {
    path: 'work_location_migration',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: WFMMigrationSyncComponent,
    data: { componentId: 'work_location_migration', accessContext: 'OrgLevel'}
  },
  {
    path: 'work_location',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: WFMOngoingSyncComponent,
    data: { componentId: 'work_location', accessContext: 'OrgLevel'}
  },
  {
    path: 'employee_org_Sync',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: EmployeeSyncComponent
  },
  {
    path: 'event_report',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: EventReportComponent,
    data: { componentId: 'event_report', accessContext: 'OrgLevel'}
  },
  {
    path: 'ta_event_report',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: TAEventReportComponent,
    data: { componentId: 'ta_event_report', accessContext: 'OrgLevel'}
  },
  {
    path: 'sync_dashboard',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: SyncDashboardComponent,
    data: { componentId: 'sync_dashboard', accessContext: 'OrgLevel'}
  },
  
  {
    path: 'sync_dashboard_migration',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: SyncDashboardMigrationComponent,
    data: { componentId: 'sync_dashboard_migration', accessContext: 'OrgLevel'}
  },
  {
    path: 'employee_sync',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: WfmSyncComponent,
    data: { componentId: 'employee_sync', accessContext: 'OrgLevel'}
  },
  {
    path: 'intg',
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        component: ConfigurePartnerPositionsComponent,
        data: { componentId: 'intg_position_mapping', accessContext: 'OrgLevel' }
      },
      {
        path: 'intg_position_mapping',
        resolve: [OrgLevelResolver],
        canDeactivate: [CanLeaveGuard],
        component: ConfigurePartnerPositionsComponent,
        data: { componentId: 'intg_position_mapping', accessContext: 'OrgLevel' }
      },

    ]
  },

  {
    path: 'configure_organizations',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ConfigurePbjOrganizationsComponent,
    data: { componentId: 'configure_organizations', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_paycodes',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: PayCodeConfigurationComponent,
    data: { componentId: 'configure_paycodes', accessContext: 'OrgLevel' }
  },
  {
    path: 'paycode_details',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: PayCodeDetailsComponent,
    data: { componentId: 'paycode_details', accessContext: 'OrgLevel' }
  },
  {
    path: 'copy_paycodes',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: CopyPayCodesComponent,
    data: { componentId: 'copy_paycodes', accessContext: 'OrgLevel' }
  },
  {
    path: 'paycode_policies',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: PayPoliciesComponent,
    data: { componentId: 'paycode_policies', accessContext: 'OrgLevel' }
  },
  {
    path: 'timeclock_assignment',
    resolve: [OrgLevelResolver],
    component: TimeclockAssignmentComponent,
    data: { componentId: 'timeclock_assignment', accessContext: 'OrgLevel' }
  },
  {
    path: 'review_timeclocks',
    resolve: [OrgLevelResolver],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    component: TimeclockReviewComponent,
    data: { componentId: 'review_timeclocks', accessContext: 'OrgLevel' }
  },
  {
    path: 'temp_sensor_reading',
    resolve: [OrgLevelResolver],
    component: TempReadingsComponent,
    data: { componentId: 'temp_sensor_reading', accessContext: 'OrgLevel' }
  },
  {
    path: 'manage_agency',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ConfigureAgencyComponent,
    data: { componentId: 'manage_agency', accessContext: 'OrgLevel' }
  },
  {
    path: 'timecards',
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        component: TimecardsDisplayComponent,
        data: { componentId: 'timecards', accessContext: 'OrgLevel' }
      },
      {
        path: 'summary',
        resolve: [OrgLevelResolver],
        component: TimecardSummaryComponent,
        data: { componentId: 'timecard_summary', accessContext: 'OrgLevel' }
      },
      {
        path: 'employee/:employeeId',
        resolve: [OrgLevelResolver],
        component: IndividualTimecardsComponent,
        data: { componentId: 'individual_timecards', accessContext: 'Employee', orgLevelChangeDisabled: true }
      },
      {
        canDeactivate: [CanLeaveGuard],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: [OrgLevelResolver],
        path: 'employee/:employeeId/:date',
        component: DailyTimeCardComponent,
        data: { componentId: 'daily_timecard', accessContext: 'Employee', orgLevelChangeDisabled: true }
      }
    ]
  },
  {
    path: 'exception_console',
    resolve: { orgLevel: OrgLevelResolver, exceptionConsoleData: ExceptionConsoleResolver },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    component: ExceptionConsolePageComponent,
    data: { componentId: 'exception_console', accessContext: 'OrgLevel' }
  },
  {
    path: 'assign_supervisor',
    resolve: [OrgLevelResolver],
    component: SupervisorAssignmentComponent,
    data: { componentId: 'assign_supervisor', accessContext: 'OrgLevel' }
  },
  {
    path: 'agency_schedule',
    resolve: [OrgLevelResolver],
    component: AddAgencyEmployeeComponent,
    data: { componentId: 'agency_schedule', accessContext: 'OrgLevel', orgLevelChangeDisabled: true }
  },
  {
    path: 'agency_schedule_shift',
    resolve: [OrgLevelResolver],
    component: ScheduleAgencyEmployeeComponent,
    data: { componentId: 'agency_schedule_shift', accessContext: 'OrgLevel', orgLevelChangeDisabled: true }
  },

  {
    path: 'intg_staffing_agency_report_console',
    resolve: [OrgLevelResolver],
    component: AgencyStaffingConsoleComponent,
    data: { componentId: 'intg_staffing_agency_report_console', accessContext: 'OrgLevel', orgLevelChangeDisabled: false }
  },
  {
    path: 'File_Uploads',
    resolve: [OrgLevelResolver],
    component: FileUploadsComponent,
    data: { componentId: 'File_Uploads', accessContext: 'OrgLevel', orgLevelChangeDisabled: false }
  },
  {
    path: 'daily_census',
    resolve: [OrgLevelResolver],
    component: CensusEntriesPageComponent,
    data: { componentId: 'daily_census', accessContext: 'OrgLevel' }
  },
  {
    canDeactivate: [CanLeaveGuard],
    path: 'daily_punches',
    resolve: { orgLevel: OrgLevelResolver, punchesRouteData: DailyPunchesResolver },
    component: DailyPunchesPageComponent,
    data: { componentId: 'daily_punches', accessContext: 'OrgLevel' }
  },
  {
    path: 'employee_punch_request',
    resolve: [OrgLevelResolver],
    component: EmployeePunchRequestComponent,
    data: { componentId: 'employee_punch_request', accessContext: 'OrgLevel' }
  },
  {
    path: 'overtime_approvals',
    resolve: [OrgLevelResolver],
    component: OvertimeApprovalsComponent,
    data: { componentId: 'overtime_approvals', accessContext: 'OrgLevel' }
  },
  {
    path: 'approval_required_codes',
    resolve: [OrgLevelResolver],
    component: ApprovalRequiredCodesComponent,
    data: { componentId: 'approval_required_codes', accessContext: 'OrgLevel' }
  },
  {
    path: 'pbj_manual_entry',
    resolve: [OrgLevelResolver],
    component: PbjManualEntryComponent,
    data: { componentId: 'pbj_manual_entry', accessContext: 'OrgLevel' }
  },
  {
    path: 'workers_compensation_table',
    resolve: [OrgLevelResolver],
    component: WorkersCompensationTableComponent,
    data: { componentId: 'workers_compensation_table', accessContext: 'OrgLevel' }
  },
  {
    path: 'pbj_reconciliation',
    children: [
      {
        path: '',
        resolve: { orgLevel: OrgLevelResolver, pbjReconciliationData: PbjReconciliationResolver },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        component: PbjReconciliationComponent,
        data: { componentId: 'pbj_reconciliation' },
      },
      {
        path: 'employee/:employeeId',
        resolve: [OrgLevelResolver],
        component: PbjReconciliationEmployeeComponent,
        data: { componentId: 'pbj_reconciliation', orgLevelChangeDisabled: true }
      }
    ]
  },
  {
    path: 'pbj_exports',
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        component: PbjLogExportComponent,
        data: { componentId: 'pbj_exports', accessContext: 'OrgLevel' }
      },
      {
        path: ':exportId',
        resolve: [OrgLevelResolver],
        component: PbjLogExportDetailsComponent,
        data: { componentId: 'pbj_exports', accessContext: 'OrgLevel' }
      }
    ]
  },
  {
    path: 'pbj-duplicate-workers',
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        component: PbjDuplicateWorkersComponent,
        data: { componentId: 'pbj-duplicate-workers', accessContext: 'OrgLevel' }
      },
    ]
  },
  {
    path: 'payroll_export_management',
    resolve: [OrgLevelResolver],
    component: PayrollExportComponent,
    data: { componentId: 'payroll_export_management' }
  },
  {
    path: 'payroll_export',
    resolve: [OrgLevelResolver],
    component: PayrollExportTestComponent,
    data: { componentId: 'payroll_export' }
  },
  {
    path: 'ess_templates',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: EssTemplatesComponent,
    //loadChildren: '../app-modules/ess-templates/ess-templates.module#EssTemplatesModule',
    data: { componentId: 'ess_templates', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_leave_types',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: LMTypeConfigurationComponent,
    data: { componentId: 'configure_leave_types' }
  },
  {
    path: 'configure_benefit_classes',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: BenefitClassConfigurationComponent,
    data: { componentId: 'configure_benefit_classes', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_performance_management',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: ConfigurePerformanceManagementComponent,
    data: { componentId: 'configure_performance_management', accessContext: 'OrgLevel' }
  },
  {
    path: 'configure_employers',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: ConfigureEmployersComponent,
    data: { componentId: 'configure_employers', accessContext: 'OrgLevel' }
  },
  {
    path: 'audit_trail_employee',
    resolve: [OrgLevelResolver],
    component: AuditTrailComponent,
    data: { componentId: 'audit_trail_employee', accessContext: 'OrgLevel' }
  },
  {
    path: 'audit_trail_config',
    resolve: [OrgLevelResolver],
    component: AuditTrailConfigComponent,
    data: { componentId: 'audit_trail_config', accessContext: 'OrgLevel' }
  },
  {
    path: 'measurement_details',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: AcaMeasurementComponent,
    data: { componentId: 'measurement_details', accessContext: 'OrgLevel' }
  },
  {
    path: 'aca_exports',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: AcaExportComponent,
    data: { componentId: 'aca_exports', accessContext: 'OrgLevel' }
  },
  {
    path: 'manage_aca_periods',
    resolve: [OrgLevelResolver],
    canDeactivate: [CanLeaveGuard],
    component: ManageAcaPeriodsComponent,
    data: { componentId: 'manage_aca_periods', accessContext: 'OrgLevel' }
  },
  {
    path: 'ip_whitelisting',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: IpManagementComponent,
    data: { componentId: 'ip_whitelisting', accessContext: 'OrgLevel' }
  },
  {
    path: 'user_login_management',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: UserLoginManagement,
    data: { componentId: 'user_login_management', accessContext: 'OrgLevel' }
  },
  {
    path: 'ten_ninty_five_c',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: Aca1095cComponent,
    data: { componentId: 'ten_ninty_five_c', accessContext: 'OrgLevel' }
  },
  {
    path: 'aca_c1095_audit',
    canDeactivate: [CanLeaveGuard],
    resolve: [OrgLevelResolver],
    component: AcaC1095AuditComponent,
    data: { componentId: 'aca_c1095_audit', accessContext: 'OrgLevel' }
  },
  {
    path: 'message_center',
    canDeactivate: [CanLeaveGuard],
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        component: MessageCenterComponent,
        data: { componentId: 'message_center_sms', accessContext: 'OrgLevel' }
      }
    ]
  },
  {
    path: 'message_center_sms',
    canDeactivate: [CanLeaveGuard],
    children: [
      {
        path: '',
        redirectTo: 'sms',
        pathMatch: 'full',
        resolve: [OrgLevelResolver]
      },
      {
        path: 'sms',
        resolve: [OrgLevelResolver],
        component: MessageCenterComponent,
        data: { componentId: 'message_center_sms', accessContext: 'OrgLevel' }
      }
    ]
  }

];

export const employeeSectionRoutes: Routes = [
  {
    path: 'employee',
    component: PortalEmployeeComponent,
    children: [
      {
        canDeactivate: [CanLeaveGuard],
        path: ':employeeId/employee_sections',
        component: EmployeeSectionsComponent,
        data: { componentId: 'employee_record', accessContext: 'Employee', orgLevelChangeDisabled: true }
      }
    ]
  }
];


export const userProfileRoutes: Routes = [
  {
    path: 'user',
    component: PortalEmployeeComponent,
    data: { componentId: 'user', accessContext: 'OrgLevel' },
    children: [
      {
        path: ':userId/profile',
        component: UserProfileComponent,
        data: { componentId: 'user_profile', accessContext: 'OrgLevel' }
      },
      {
        path: 'create',
        component: UserProfileComponent,
        data: { componentId: 'user_create', accessContext: 'OrgLevel', orgLevelChangeDisabled: true }
      }
    ]
  }
];

import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs';

import { unsubscribe } from '../../../../../core/decorators/index';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { DateRange } from '../../../../../core/models/index';

import { PbjReconciliationManagementService } from '../../../services/index';
import { PbjReconciliationEmployee, PbjReconciliationOrglevel } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-reconciliation-toolbar',
  templateUrl: 'pbj-reconciliation-toolbar.component.html',
  styleUrls: ['pbj-reconciliation-toolbar.component.scss']
})
export class PbjReconciliationToolbarComponent implements OnInit, OnDestroy {
  @Input()
  public type: string;

  public startDate: Date;
  public endDate: Date;
  public expanded: boolean;
  public appConfig: IApplicationConfig;
  public maxSecondsRange: number;
  public maxRangeError: string = 'The dates range cannot be more than 145 days';

  @unsubscribe()
  private subscribtionToReconEmployee: Subscription;
  @unsubscribe()
  private subscribtionToReconOrglevel: Subscription;
  @unsubscribe()
  private subscribtionToExpanded: Subscription;
  private employeeId: number;

  constructor(
    private managementService: PbjReconciliationManagementService,
    private activatedRoute: ActivatedRoute
  ) {
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.subscribtionToExpanded = this.managementService.subscribeToExpandedDetails((isOn: boolean) => {
      this.expanded = isOn;
    });

    if (this.isEmployeeMode) {
      this.subscribeToReconEmployee();
    } else {
      this.subscribeToReconOrglevel();
    }
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public get isEmployeeMode(): boolean {
    return this.type === 'employee';
  }

  public onExportToPdf(): void {
    this.managementService.exportToPdf();
  }

  public onExportToExcel(): void {
    this.managementService.exportToExcel();
  }

  public onDateRangeChanged(dateRange: DateRange): void {
    const sDate: Date = _.get(dateRange, 'startDate');
    const eDate: Date = _.get(dateRange, 'endDate');
    if (_.isDate(sDate) && _.isDate(eDate)) {
      this.managementService.storeDates(sDate, eDate);
      if (this.isEmployeeMode) {
        this.managementService.navigateToReconciliationEmployee(this.employeeId, sDate, eDate);
      } else {
        this.managementService.navigateToReconciliation(null, sDate, eDate);
      }
    }
  }

  public onTogglerChanged(isOn: boolean): void {
    this.managementService.changeExpandedDetails(isOn);
  }

  private subscribeToReconEmployee(): void {
    this.subscribtionToReconEmployee = this.managementService.subscribeToReconEmployee((data: PbjReconciliationEmployee) => {
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.maxSecondsRange = data.maxDaysRange;
      this.employeeId = data.employeeId;
    });
  }

  private subscribeToReconOrglevel(): void {
    this.subscribtionToReconOrglevel = this.managementService.subscribeToReconOrglevel((data: PbjReconciliationOrglevel) => {
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.maxSecondsRange = data.maxDaysRange;
    });
  }
}

import * as _ from 'lodash';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';

import { LookupApiService } from '../../../../organization/services/index';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
import { TimeclocksApiService, AccessManagementService } from '../../../services/index';

import { OrgLevel } from '../../../../state-model/models/index';
import { Timeclock, EditableListActionKind, TimeclockContainer } from '../../../models/index';
import { TimeclockModelDefinition } from '../../../../organization/models/index';

import { AppServerConfig } from '../../../../app-settings/model/app-server-config';

import { CustomListActorBase } from '../../editableList/custom-list-actor.base';

@Component({
  moduleId: module.id,
  selector: 'slx-timeclock-renderer',
  templateUrl: 'timeclock-renderer.component.html',
  styleUrls: ['timeclock-renderer.component.scss'],
  providers: [
    {
      provide: CustomListActorBase,
      useExisting: forwardRef(() => TimeclockRendererComponent)
    }
  ]
})

export class TimeclockRendererComponent extends CustomListActorBase implements OnInit {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @Input()
  public set context(value: any) {
    this.item = value.item;
    this.rowIndex = value.rowIndex;
  }

  @Input()
  public item: Timeclock;

  @Input()
  public action: TimeclockContainer;

  @Input()
  public rowIndex: number;

  @Input()
  public editable: boolean = true;

  @Input()
  public selectable: boolean = true;

  public get appConfig(): IApplicationConfig {
    return this.m_appConfig;
  }

  public get container(): TimeclockContainer {
    return this.m_container;
  }

  public get stateManagement(): AccessManagementService {
    return this.m_stateManagement;
  }

  private m_stateManagement: AccessManagementService;
  private timeclocksApiService: TimeclocksApiService;
  private appSettingsManageService: AppSettingsManageService;
  public lookupService: LookupApiService;
  public originalItem: Timeclock;
  private currentOrgLevel: OrgLevel;
  private m_container: TimeclockContainer;
  private m_appConfig: IApplicationConfig;
  public clockModelLookup: TimeclockModelDefinition[];

  public enableQR: boolean = false;
  public editTimeclockConfig: boolean = true;
  public isCollapsed: boolean = true;
  public eIdErrorMessage: boolean = false;

  public eId: number;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  constructor(
    timeclocksApiService: TimeclocksApiService,
    lookupService: LookupApiService,
    appSettingsManageService: AppSettingsManageService,
    stateManagement: AccessManagementService) {
    super();
    this.lookupService = lookupService;
    this.timeclocksApiService = timeclocksApiService;
    this.m_stateManagement = stateManagement;
    this.appSettingsManageService = appSettingsManageService;
  }

  public onToggleCollapseClick(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public ngOnDestroy(): void {
  }

  public ngOnInit(): void {
    this.m_appConfig = appConfig;
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.currentOrgLevel = orgLevel;
    });
    this.getTimeclockModelData();
    this.getEnterpriseId();
  }

  public onEditClick(): void {
    this.actionEmitter.emit(EditableListActionKind.START_EDIT);
  }

  public onItemSelect(): void {
    this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
  }

  public getTimeclockModelData(): void {
    let orgLevelId: number = this.item.organization.orgLevelId;
    this.lookupService.getTimeclockModelDefinitions(orgLevelId)
      .then((value: TimeclockModelDefinition[]) => {
        this.clockModelLookup = value;
      });
  }

  public getTimeClockContainerData(): void {
    this.timeclocksApiService.getTimeclocks(this.currentOrgLevel.id).
      then((container: TimeclockContainer) => {
        this.m_container = container;
        this.m_stateManagement.actions = container.actions;
      }).catch((e: Error) => {
      });
  }

  public getEnterpriseId(): void {
    this.appSettingsManageService.getAppServerConfig()
      .then((conf: AppServerConfig) => {
        this.eId = conf.enterpriseId;
      });
  }

  public onClockModelSelected(event: TimeclockModelDefinition): void {
    if (!this.eId) {
      this.item.isQRPunchEnabled = false;
      this.enableQR = false;
    } else {
      this.enableQR = event.qrPunchSupported;
    }
  }

  public onEditMode(item: Timeclock): void {
    if (_.isNull(this.eId)) {
      this.eIdErrorMessage = true;
    }
    this.originalItem = _.cloneDeep(item);
    item.isEditable = true;
    if (this.isCollapsed) {
      this.onToggleCollapseClick();
    }
  }

  public onSave(item: Timeclock): void {
    item.isEditable = false;
    this.timeclocksApiService.saveTimeclock(item, this.currentOrgLevel.id).then(() => {
      this.originalItem = this.item;
      this.enableQR = false;
    }).catch(() => {
    });
    if (!this.isCollapsed) {
      this.onToggleCollapseClick();
    }
  }

  public onCancel(item: Timeclock): void {
    item.model = this.originalItem.model;
    item.isEditable = false;
    this.enableQR = false;
    this.eIdErrorMessage = false;
    if (!this.isCollapsed) {
      this.onToggleCollapseClick();
    }
  }

}

import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';

import { Observable ,  Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { LookupApiService } from '../../../organization/services/index';
import { PayCycle } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';

export class Year {
  public year: number;

  constructor(year: number) {
    this.year = year;
  }
}

export class Range {
  public range: string;
  public year: number;
  public payCycle: PayCycle;

  constructor(range: string, year: number, payCycle: PayCycle) {
    this.range = range;
    this.year = year;
    this.payCycle = payCycle;
  }
}

@Component({
  moduleId: module.id,
  selector: 'slx-paycycle-dropdown',
  templateUrl: 'paycycle-dropdown.component.html'
})
export class PayCycleDropdownComponent implements OnInit, OnDestroy {
  @Input('resetSelectedPayCycleOnEmployeeChange')
  public set resetSelectedPayCycle(val: boolean) {
    if (_.isBoolean(val)) {
      this._resetSelectedPayCycle = val;
    }
  }
  @Input('employeeId')
  public set empId(val: number) {
    if (_.isNumber(val) && this.employeeId !== val) {
      // const resetSelectedPayCycle: boolean = _.isNumber(this.employeeId);
      const resetSelectedPayCycle: boolean = this._resetSelectedPayCycle;
      this.employeeId = val;
      this.loadPayCycles(resetSelectedPayCycle);
    }
  }

  @Input('orgLevelId')
  public set setOrgLevelId(val: number) {
    if (_.isNumber(val) && this.customOrgLevelId !== val) {
      // 138762 - Retain currently selected paycycle on organisation change
      const resetSelectedPayCycle: boolean = false;
      this.customOrgLevelId = val;
      if (this.customOrgLevelId > 0) {
        this.loadPayCycles(resetSelectedPayCycle, this.customOrgLevelId);
      }
    }
  }

  @Input('selectedPayCycle')
  public set setPayCycle(val: PayCycle) {
    if (_.isObject(val) && _.isDate(val.startDate) && _.isDate(val.endDate)) {
      this.selectedPayCycle = val;
      this.selectPayCycle();
    }
  }

  @Input('setDefaultIfNotSelected')
  public set setDefault(val: boolean) {
    if (_.isBoolean(val)) {
      this.isSetDefaultPayCycle = val;
      if (val) {
        this.selectPayCycle();
      }
    }
  }

  @Output()
  public payCycleSelected: EventEmitter<PayCycle>;
  @Output()
  public payCyclesLoaded: EventEmitter<PayCycle[]>;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public yearList: Year[];
  public rangeList: Range[];
  public origRangeList: Range[];
  public defaultRange: Range;
  public appConfig: IApplicationConfig;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  private lookupApiService: LookupApiService;
  private orgLevel: OrgLevel;
  private origPayCycles: PayCycle[];
  private selectedPayCycle: PayCycle;
  private employeeId: number;
  private customOrgLevelId: number;
  private m_year: Year;
  private m_range: Range;
  private isSetDefaultPayCycle: boolean;
  private _resetSelectedPayCycle: boolean = true;

  constructor(lookupApiService: LookupApiService) {
    this.lookupApiService = lookupApiService;
    this.appConfig = appConfig;
    this.payCycleSelected = new EventEmitter<PayCycle>();
    this.payCyclesLoaded = new EventEmitter<PayCycle[]>();
    this.defaultRange = new Range('Please select Pay Cycle', 0, null);
    this.isSetDefaultPayCycle = true;
  }

  public ngOnInit(): void {
    this.orgLevelSubscription = this.orgLevel$
      .pipe(filter((o: OrgLevel) => _.isNumber(o.id)))
      .subscribe((o: OrgLevel) => {
        if (!_.isNumber(this.customOrgLevelId)) {
          const orgLevelId: number = _.get(this.orgLevel, 'id');
          if (orgLevelId === o.id) return;

          this.orgLevel = o;
          this.loadPayCycles(_.isNumber(orgLevelId), this.orgLevel.id);
        }
      });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public set currentYear(year: Year) {
    if (_.get(this.m_year, 'year') !== _.get(year, 'year')) {
      this.setupPayCycle(year, this.currentRange);
    }
  }

  public get currentYear(): Year {
    return this.m_year;
  }

  public set currentRange(range: Range) {
    if (this.m_range !== range) {
      this.m_range = range;
      if (_.isDate(_.get(range, 'payCycle.startDate')) && _.isDate(_.get(range, 'payCycle.endDate'))) {
        this.payCycleSelected.emit(range.payCycle);
      }
    }
  }

  public get currentRange(): Range {
    return this.m_range;
  }

  public loadPayCycles(resetSelectedPayCycle: boolean, orgLevelId?: number): void {
    let id: number = orgLevelId || _.get(this.orgLevel, 'id');
    if (_.isNumber(id) && id > 0) {
      if (resetSelectedPayCycle) {
        this.selectedPayCycle = null;
        this.m_range = null;
        this.m_year = null;
      }
      this.lookupApiService.getPayCyles(id, this.employeeId)
        .then((cycles: PayCycle[]) => {
          this.origPayCycles = _.sortBy(cycles, (item: PayCycle) => { return moment(item.startDate).unix(); });
          this.payCyclesLoaded.emit(cycles);

          this.createYearsList();
          this.createRangesList();
          this.selectPayCycle();
        });
    }
  }

  private createYearsList(): void {
    const yearsList: StringMap<Year> = _.reduce(this.origPayCycles, (accum: StringMap<Year>, cycle: PayCycle) => {
      const year: number = moment(cycle.startDate).year();
      accum[year] = new Year(year);

      return accum;
    }, {});

    this.yearList = _.reverse(_.values(yearsList));
  }

  private createRangesList(): void {
    const rangeList: StringMap<any> = _.reduce(this.origPayCycles, (accum: StringMap<any>, cycle: PayCycle) => {
      accum[cycle.description] = new Range(cycle.description, moment(cycle.startDate).year(), cycle);

      return accum;
    }, {});

    this.origRangeList = _.reverse(_.values(rangeList));
  }

  private filteringRanges(year: Year): Range[] {
    return _.filter(this.origRangeList, (range: Range) => year.year === range.year);
  }

  private selectPayCycle(): void {
    if (!_.isArray(this.yearList) || !_.isArray(this.origRangeList)) return;

    const sDate: Date = _.get(this.selectedPayCycle, 'startDate');
    const eDate: Date = _.get(this.selectedPayCycle, 'endDate');

    if (_.isDate(sDate) && _.isDate(eDate)) {
      const startDate: moment.Moment = moment(sDate);
      const endDate: moment.Moment = moment(eDate);

      let year: Year = _.find(this.yearList, { year: sDate.getFullYear() });
      let currentRange: Range = null;
      if (year) {
        const ranges: Range[] = this.filteringRanges(year);
        currentRange = _.find(ranges, (r: Range) => startDate.isSame(r.payCycle.startDate) && endDate.isSame(r.payCycle.endDate));
      } else {
        year = _.head(this.yearList);
      }
      this.setupPayCycle(year, currentRange);
    } else if (this.isSetDefaultPayCycle) {
      const currentYear = _.head(this.yearList);
      this.setupPayCycle(currentYear, null);
    }
  }

  private setupPayCycle(year: Year, currentRange: Range): void {
    if(!year) {
      this.rangeList = [];
    }
    if (!this.m_year || this.m_year !== year || !this.currentRange || this.currentRange !== currentRange) {
      this.m_year = year;
      let ranges: Range[] = this.filteringRanges(year);
      this.rangeList = ranges;
      let setFirstRange: boolean = _.isNil(currentRange) || !this.isCurrentRangeIsAvailableInPaycycleRange(this.rangeList, currentRange);
      if (setFirstRange) {
        this.currentRange = _.first(this.rangeList);
      } else {
        this.currentRange = currentRange;
      }
    }
  }

  private isCurrentRangeIsAvailableInPaycycleRange(rangeList, currentRange) {
    let result = false;
    if (rangeList && rangeList instanceof Array && rangeList.length > 0 && currentRange) {
      result = rangeList.indexOf(currentRange) > -1;
    }
    return result;
  }
}

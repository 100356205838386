import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { WCCompensationReport, WcReport, WcLookupsContainer } from '../../../models';
import { Lookup } from '../../../../../organization';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { PopoverContentComponent } from '../../../../../common';
import { ReportFormBase } from '../report-form-base';
import { UntypedFormGroup, NgModel } from '@angular/forms';
import { ThreeChoiceEnum } from '../../../models/three-choice.enum';
import { wcConfig } from '../../../workers-compensation.config';

@Component({
    moduleId: module.id,
    selector: 'slx-wc-worker-compensation-report',
    templateUrl: 'wc-worker-compensation-report.component.html',
    styleUrls: ['wc-worker-compensation-report.component.scss']
})

export class WcWorkerCompensationReportComponent extends ReportFormBase implements OnInit, OnDestroy {

    public get isCreatingNew(): boolean {
        return this.report && !_.isFinite(this.report.id);
    }

    public get comp(): WCCompensationReport {
        return this.report ? this.report.compReport : null;
    }

    public get minimumDate(): Date {
        return this.m_minimumDate;
    }

    @ViewChild('wcReport', { static: true })
    public wcReportForm: UntypedFormGroup;

    @ViewChild('erPhoneNumber')
    public erPhoneNumber: NgModel;

    @ViewChild('preparerPhoneNumber')
    public preparerPhoneNumber: NgModel;

    @ViewChild('totalGrossWeekly')
    public totalGrossWeekly: NgModel;

    @ViewChild('numberOfWeeksUsed')
    public numberOfWeeksUsed: NgModel;

    @ViewChild('valOfDiscountedFringes')
    public valOfDiscountedFringes: NgModel;

    public report: WcReport;
    public lookups: WcLookupsContainer;
    public statesLookup: Lookup;

    @unsubscribeAll()
    public subscriptions: StringMap<Subscription> = {};


    public threeChoiceOptions: { id: ThreeChoiceEnum, name: string }[] = [
        { id: ThreeChoiceEnum.Yes, name: 'Yes' }
        , { id: ThreeChoiceEnum.No, name: 'No' }
        , { id: ThreeChoiceEnum.Unknown, name: 'Unknown' }
    ];

    public bodySides: string[] = [
        'left', 'right', 'both', 'unknown'
    ];

    private m_minimumDate: Date;

    constructor(private management: WCReportManagementService) {
        super();
        this.m_minimumDate = moment(wcConfig.settings.minDateString).toDate();
    }

    public ngOnInit(): void {

        this.subscriptions.load = this.management.onLoaded$
            .pipe(combineLatest(this.management.wcLookupsLoaded$))
            .subscribe(async ([report, lookups]: [WcReport, WcLookupsContainer]) => {
                this.lookups = lookups;
                this.report = report;
                this.updateLostDaysCount();
                if (this.isCreatingNew) {
                    this.populateDates();
                }
            });

        this.subscriptions.form = this.wcReportForm.statusChanges.subscribe(x => {
            if (this.wcReportForm.dirty && this.wcReportForm.touched) {
                this.management.onChangeNotify();
            }
            this.management.setWcFormValidity(this.wcReportForm.valid);
        });
        this.loadStatesLookup();
        if (this.isCreatingNew) {
            this.report.compReport.dateTreatmentReceived = null;
        }
    }

    public ngOnDestroy(): void { }

    public async onClickDisable(acceptPopover: PopoverContentComponent): Promise<void> {
        acceptPopover.hide();
        this.management.changeLoading(true);
        try {
            await this.management.disableWCReport();
            this.management.changeLoading(false);
        } catch (e) {
            console.error(e);
        }
    }

    public onClickCancel(acceptPopover: PopoverContentComponent): void {
        acceptPopover.hide();
    }

    public loadStatesLookup(): void {
        this.management.loadStates()
            .then((l: Lookup) => {
                this.statesLookup = l;
            });
    }

    public onisLostDaysChanged(enable: boolean): void {
        if (this.comp) {
            if (enable) {
                this.comp.dateLoseBegan = moment().toDate();
                this.comp.dateLoseEnd = moment().add(1, 'day').toDate();
            } else {
                this.comp.dateLoseBegan = null;
                this.comp.dateLoseEnd = null;
            }
        }
        this.updateLostDaysCount();
    }

    public updateLostDaysCount() {
        if (this.comp) {
            if (this.comp.isLosingTime) {
                if (this.comp.dateLoseBegan && this.comp.dateLoseEnd) {
                    this.comp.lostDaysCount = moment(this.comp.dateLoseEnd).diff(this.comp.dateLoseBegan, 'day');
                    return;
                }
            }
        }
        this.comp.lostDaysCount = 0;
    }

    public updateLostEndDate(days: number): void {
        if (this.comp) {
            if (this.comp.isLosingTime) {
                if (!this.comp.dateLoseBegan) {
                    this.comp.dateLoseBegan = moment().toDate();
                }
                this.comp.lostDaysCount = days;
                this.comp.dateLoseEnd = moment(this.comp.dateLoseBegan).add(days, 'day').toDate();
            }
        }
    }

    public populateDates(): void {
        if (this.comp) {
            this.comp.dateErNotified = new Date();
            this.comp.lastDateWorked = new Date();
            this.comp.expectReturnDate = null;
            this.comp.preparedDate = new Date();
        }
    }
}

<div class="main-container">
    <slx-spinner [show]="state.isLoading" novalidate>

        <!-- Sticky header for the tabs, policy dropdown, and buttons -->
        <div class="sticky-header">
            <!-- Tabs for Pay Policy and Shift Differential Policy -->
            <div class="tabs">
                <button class="tab" [class.active]="currentTab === 'payPolicy'" [disabled]="currentTab === 'payPolicy'"
                    (click)="switchTab('payPolicy')">Pay Policy
                </button>
                <button class="tab" [class.active]="currentTab === 'shiftDiffPolicy'"
                    [disabled]="currentTab === 'shiftDiffPolicy'" (click)="switchTab('shiftDiffPolicy')">Shift
                    Differential Policy
                </button>
            </div>

            <!-- Dropdown for selecting policies and Add/Delete buttons -->
            <div class="dropdown-container-with-buttons">
                <label for="policySelect">Policy</label>
                <select id="policySelect" [(ngModel)]="selectedPolicyId" (change)="onPolicySelect()">
                    <option *ngFor="let policy of filteredPolicies" [value]="policy.id">{{ policy.description }}
                    </option>
                </select>

                <!-- Add/Delete buttons next to the dropdown -->
                <button type="button" class="btn-add" (click)="openAddPopup()">Add</button>
                <button type="button" class="btn-delete" (click)="deletePolicy()">Delete</button>
            </div>

            <!-- Right-aligned Save/Cancel buttons -->
            <div class="button-group-right">
                <button type="button" class="btn-cancel gray-btn" (click)="goBack()">Cancel</button>
                <button type="button" class="btn-save" [disabled]="!saveEnabled" (click)="saveChanges()">Save</button>
            </div>
        </div>

        <!-- Add Policy Pop-up -->
        <div *ngIf="isPopupOpen" class="popup-overlay">
            <div class="popup">
                <h3>Add New Policy</h3>

                <label for="policyId">Policy Name</label>
                <input type="text" id="policyId" [(ngModel)]="newPolicyId">

                <div class="popup-buttons">
                    <button type="button" (click)="addNewPolicy()">Add Policy</button>
                    <button type="button" (click)="closeAddPopup()">Close</button>
                </div>
            </div>
        </div>

        <!-- Display message if no policies exist -->
        <div *ngIf="filteredPolicies.length === 0 && !state.isLoading">
            <p>You must create a {{ currentTab === 'payPolicy' ? 'pay policy' : 'shift diff policy' }} first.</p>
        </div>

        <!-- Table displaying pay codes with mapped rules -->
        <table *ngIf="filteredPolicies.length > 0">
            <thead>
                <tr>
                    <th>Pay Code</th>
                    <th>Paid</th>
                    <th>Active</th>
                    <th>Description</th>
                    <th>Notes</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let paycode of paycodeWithRules">
                    <td>
                        <a (click)="handleDescriptionClick($event, paycode.exceptionId)">
                            {{ paycode.exceptionDescription }}
                        </a>
                    </td>
                    <td>{{ paycode.paid }}</td>

                    <!-- Checkbox for Active status -->
                    <td>
                        <input type="checkbox" [(ngModel)]="paycode.isActive" (change)="onFieldChange(paycode)" />
                    </td>

                    <!-- Editable textbox for Rule -->
                    <td>
                        <input type="text" [(ngModel)]="paycode.description" (input)="onFieldChange(paycode)" />
                    </td>

                    <!-- Editable textbox for Notes -->
                    <td>
                        <input type="text" [(ngModel)]="paycode.notes" (input)="onFieldChange(paycode)" />
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Message when no pay codes are available for the selected policy -->
        <div *ngIf="filteredPolicies.length > 0 && paycodeWithRules.length === 0 && !state.isLoading">
            <p>No pay codes available for the selected policy.</p>
        </div>

    </slx-spinner>
</div>
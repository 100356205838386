import { Component, Provider, InjectionToken, Inject } from '@angular/core';
import {
  IDialog,
  DialogOptions,
  DialogOptions2,
  DialogModeSize,
} from '../../../../../../app/common/models/index';
import { ModalService } from './../../../../../common/services/index';
import { BenefitPayrollExisingMapping, BenefitPayrollExistingMapping } from '../../../models';
import { BenefitListManagementService } from '../../../services/benefit-console/benefit-list-management.service';
import { Subscription } from 'rxjs';
import { BenefitPayrollMappingManagementService } from '../../../services/benefit-console/benefit-payroll-mapping-management.service';

@Component({
  selector: 'slx-benefit-payroll-existing-mapping-dialog',
  templateUrl: './benefit-payroll-existing-mapping-dialog.component.html',
  styleUrls: ['./benefit-payroll-existing-mapping-dialog.component.scss'],
  providers: [ BenefitPayrollMappingManagementService ]
})
export class BenefitPayrollExistingMappingDialogComponent implements IDialog {
  public dialogResult: boolean;
  public options: DialogOptions2;
  public modalService: ModalService;
  public benefitPayrollExistingMap: BenefitPayrollExisingMapping[] = [];
  public isLoading: boolean = false;

  private subscriptions: StringMap<Subscription> = {};

  constructor(
    options: DialogOptions2,
    modalService: ModalService,
    private manService: BenefitPayrollMappingManagementService
  ) {
    this.options = options;
    this.modalService = modalService;
    this.getExistingMappingData();
  }

  public static openDialog(
    modalService: ModalService,
    callback: (result: boolean) => void
  ): BenefitPayrollExistingMappingDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions,
      }
    ];
    let dialog: BenefitPayrollExistingMappingDialogComponent = modalService.globalAnchor.openDialog2(
      BenefitPayrollExistingMappingDialogComponent,
      'Payroll/Benefits Existing Mapping',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result);
      }
    );
    return dialog;
  }

  public async getExistingMappingData() {
    this.isLoading = true;
    await this.manService.getpayrollExistingMapping();
    this.subscriptions.onLoaded = this.manService
    .subscribeToBenefitPayrollExistingMapping((v: BenefitPayrollExistingMapping[]) => {
      this.benefitPayrollExistingMap = v;
      this.isLoading = false;
    });
  }

  public close(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}

import { SessionActions } from './../../../authentication/actions/session.actions';
import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';

import { select } from '@angular-redux/store';
import { Observable ,  Subscription } from 'rxjs';

import { Assert } from '../../../framework/index';
import { LoggerService, SessionService, ThrottlingService, TokenValidityService } from '../../../core/services/index';
import { IncativityNotificationComponent } from '../../../common/components/inactivity-notification/inactivity-notification.component';

import { ApplicationActions } from '../../actions/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { unsubscribe } from '../../../core/decorators/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { LoginDialogComponent } from '../../../authentication/components/login-dialog/login-dialog.component';
import { AuthenticationService } from '../../../authentication/services/index';
import { clearPersistedMainState } from '../../../store/index';
import { ApplicationStateBusService } from '../../../organization/services/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { OrgLevel } from '../../../state-model/models/org-level/index';
import { PbjExportLogManagementService } from '../../../app-modules/pbj/services';
import { PBJDownloadExcelPdf, PBJDownloadExcelPdfOptions } from '../../../app-modules/pbj/models/pbj-export/pbjChildGridDownload';
import { NotificationsService } from '../../../core/components';
import { PbjSignalrService } from '../../services/pbj-signalr/pbj-signalr.service';

@Component({
  moduleId: module.id,
  selector: 'slx-portal',
  templateUrl: 'portal.component.html',
  styleUrls: ['portal.component.scss']
})
export class PortalComponent implements OnDestroy, OnInit {
  @select(['sidebar', 'isRightSidebarOpen'])
  public isRightSidebarOpen: Observable<boolean>;

  @select(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  @unsubscribe()
  private thresholdReachedSubscription: Subscription;

  @unsubscribe()
  private timeoutReachedSubscription: Subscription;

  @unsubscribe()
  private inactivityTimeoutSubscription: Subscription;

  @unsubscribe()
  private inactivityResetedSubscription: Subscription;

  @unsubscribe()
  private tokenExpiredSubscription: Subscription;

  @unsubscribe()
  private orgLevelsLoadedSubscription: Subscription;

  @unsubscribe()
  private isPbjLoadedSubscription: Subscription;

  private incativityNotificationComponent: IncativityNotificationComponent;

  private loginDialogComponent: LoginDialogComponent;

  constructor(private appStateBusService: ApplicationStateBusService, private loggerService: LoggerService, private applicationActions: ApplicationActions,
    private userActivityService: UserActivityService, private modalService: ModalService,
    private authenticationService: AuthenticationService, private router: Router,
    private sessionAction: SessionActions, private thtottling: ThrottlingService,
    private tokenValidity: TokenValidityService,
    private pbjSignalrService: PbjSignalrService,
    private pbjExportLogManagementService: PbjExportLogManagementService,
    private sessionService: SessionService,
    private notificationsService: NotificationsService,

  ) {

    Assert.isNotNull(loggerService, 'loggerService');
  }

  public ngOnInit(): void {

    this.orgLevelsLoadedSubscription = this.appStateBusService.subscribeToOrgLevelsLoaded((orgLevels: OrgLevel[]) => {
      scheduleMicrotask(() => {
        if (orgLevels.length === 0) {
          this.modalService.globalAnchor.openInfoDialog(
            'Warning',
            `Your user role does not appear to have access to this part of the application.
            Please have your System Administrator review your account to make sure that the appropriate access levels are granted.`,
            () => {
              this.authenticationService.navigateToLogout(this.router);
            }
          );
        }
      });
    });

    this.thresholdReachedSubscription = this.userActivityService.onTimeoutThresholdReached.subscribe(() => {
      this.incativityNotificationComponent =
        IncativityNotificationComponent.openDialog(this.modalService,
          (result: boolean): void => {
            //DO NOTHING
          });
    });

    this.inactivityResetedSubscription = this.userActivityService.onIncativityReseted.subscribe(() => {
      this.closeInactivityNotification();
    });

    this.inactivityTimeoutSubscription = this.userActivityService.onNeedToHideIncativityDialog.subscribe(() => {
      this.closeInactivityNotification();
    });

    this.timeoutReachedSubscription = this.userActivityService.onInactivityTimedOut.subscribe(() => {
      clearPersistedMainState();
      this.sessionAction.clearSession();
      if (!this.loginDialogComponent) {
        this.loginDialogComponent = LoginDialogComponent.openDialog(this.modalService,
          (result: boolean): void => {
            this.loginDialogComponent = null;
          });
      }
    });

    this.tokenExpiredSubscription = this.tokenValidity.onTokenExpired.subscribe(() => {
      clearPersistedMainState();
      this.sessionAction.clearSession();
      if (!this.loginDialogComponent) {
        this.loginDialogComponent = LoginDialogComponent.openDialog(this.modalService,
          (result: boolean): void => {
            this.loginDialogComponent = null;
          });
      }
    });

    this.isPbjLoadedSubscription = this.pbjSignalrService.isPDFDownloadInitiated$.subscribe((result: boolean): void => { 
      if(result) {
        this.pbjSignalrService.init();

        this.pbjSignalrService.pbjDownloadOptionsFeedback$.subscribe((args: any[])=>{
          let downloadOptions: PBJDownloadExcelPdfOptions = args && args.length > 2 ? JSON.parse(args[2]) : null;
          if(this.sessionService.getAlias().toLowerCase() === args[1].toLowerCase() && this.sessionService.getUser().id === downloadOptions.UserId){
            this.notificationsService.success(
              "Your download is ready!, Downloading now."
            );
            let pdfExcelObj = new PBJDownloadExcelPdf();
            pdfExcelObj.type = downloadOptions.FileType.toLowerCase();
            pdfExcelObj.exportId= downloadOptions.ExportIds === null ? "0": downloadOptions.ExportIds;
            pdfExcelObj.batchId = downloadOptions.BatchId;
            pdfExcelObj.alias= null;
            pdfExcelObj.userId= null;
            pdfExcelObj.orgLevelId = downloadOptions.OrgLevelId;
            this.pbjExportLogManagementService.downloadExcelPDF(pdfExcelObj);
          }
        })
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private closeInactivityNotification(): void {
    if (this.incativityNotificationComponent) {
      this.incativityNotificationComponent.close();
      this.incativityNotificationComponent = null;
    }
  }
}

import { appConfig } from './../../../app.config';
import { Injectable } from '@angular/core';
import { ReplaySubject ,  Observable ,  Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ColumnSettingsDefinitions } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ColumnSettingsStorageService } from '../../../common/services/index';
import { PayCycle } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { StateManagementService } from '../../../common/services/index';
import { ComponentStateStorageService } from '../../../common/services/component-state/component-state-storage.service';
import { ControlStateKey, IControlState, StateResetTypes } from '../../../core/models/settings/index';
import { IDestroyService } from '../../../core/models/index';
import { ActivatedRoute } from '@angular/router';
import { PunchesRollupState, PunchesRollupRecord } from '../../models/index';
import { PunchesApiService } from './punches-api.service';
import { PunchUtils } from '../../utils/punch-utils';

@Injectable()
export class PunchesRollupManagementService implements IDestroyService {
  public onStateChanged$: ReplaySubject<PunchesRollupState>;
  public onLoadStatus$: ReplaySubject<boolean>;
  public onLoaded$: ReplaySubject<PunchesRollupRecord[]>;
  public currentOrgLevel: OrgLevel;
  public records: PunchesRollupRecord[];
  public state: PunchesRollupState;
  public startDate: Date;
  public endDate: Date;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  @unsubscribeInService()
  private routeSubscripion: Subscription;

  private m_resetType: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
  private stateKey: ControlStateKey;
  private readonly m_dateFiltersControlId: string = 'DateFiltersNgx';


  constructor(private apiService: PunchesApiService,
    private columnSettingsStorageService: ColumnSettingsStorageService,
    private stateService: StateManagementService,
    private storageService: ComponentStateStorageService,
    private activatedRoute: ActivatedRoute) {
      this.initialize();
  }

  public initialize(): void {
    this.stateService.init('PunchesRollupComponent', false);
    this.onLoadStatus$ = new ReplaySubject(1);
    this.onStateChanged$ = new ReplaySubject(1);
    this.onLoaded$ = new ReplaySubject(1);

    this.routeSubscripion = this.activatedRoute.queryParams
      .subscribe((queryParams: any) => {
        let sd: Date;
        let ed: Date;
        if (queryParams['startDate'] && queryParams['endDate']) {
          this.startDate =  moment(queryParams['startDate'], appConfig.linkDateFormat).toDate();
          this.endDate =  moment(queryParams['endDate'], appConfig.linkDateFormat).toDate();
        } else {
          this.restoreFilters();
        }
      });

    this.orgLevelSubscription = this.orgLevel$
      .pipe(filter((o: OrgLevel) => !this.currentOrgLevel || o && this.currentOrgLevel.id !== o.id))
      .subscribe((o: OrgLevel) => {
        this.onOrgLevelChanged(o);
      });
    this.state = new PunchesRollupState();
    this.state.createColumns();
    this.state.mapColumns();
    this.state.columns = this.columnSettingsStorageService.getColumnsState(ColumnSettingsDefinitions.PUNCHES_ROLLUP.toString(), undefined, this.state.columns);
    this.onStateChanged(this.state);
  }
  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public onStateChanged(state: PunchesRollupState): void {
    this.onStateChanged$.next(state);
    this.columnSettingsStorageService.setColumnsState(ColumnSettingsDefinitions.PUNCHES_ROLLUP.toString(), undefined, this.state.columns);
  }

  public onLoadStatusChanged(isLoading: boolean): void {
    this.onLoadStatus$.next(isLoading);
  }

  public loadPunchesRollupRecords(orgLevelId: number, startDate: Date, endDate: Date): void {
    this.onLoadStatusChanged(true);
    this.apiService.getPunchesRollupRecords(orgLevelId, startDate, endDate)
      .then((records: PunchesRollupRecord[]) => {
        let grouped: PunchesRollupRecord[] = this.needShowDepartments ? this.group(records) : records;
        this.onLoaded(grouped);
        this.onLoadStatusChanged(false);
      })
      .catch((reason: any) => {
        this.onLoaded([]);
        this.onLoadStatusChanged(false);
      });
  }

  public onLoaded(records: PunchesRollupRecord[]): void {
    this.onLoaded$.next(records);
  }

  public setDates(startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.loadRecords();
    this.saveFilters();
  }

  public getOrgLevelTitle(): string {
    if (this.needShowDepartments) {
      return 'Department';
    } else {
      return 'Home Organization';
    }
  }

  public get needShowDepartments(): boolean {
    return this.currentOrgLevel.type ===  OrgLevelType.organization;
  }

  private onOrgLevelChanged(o: OrgLevel): void {

    if (!PunchUtils.isRollup(o)) {
      return;
    }

    this.currentOrgLevel = o;
    this.loadRecords();
  }

  private loadRecords(): void {
    if (this.currentOrgLevel && this.startDate && this.endDate) {
      this.loadPunchesRollupRecords(this.currentOrgLevel.id, this.startDate, this.endDate);
    }
  }

  private group(records: PunchesRollupRecord[]): PunchesRollupRecord[] {
    let totals: PunchesRollupRecord[] = [];
    let dict: _.Dictionary<PunchesRollupRecord[]> = _.groupBy(records, (record: PunchesRollupRecord) => {
      return record.payCycle.description;
    });

    _.forEach(dict, (items:PunchesRollupRecord[]) => {
      let rec: PunchesRollupRecord = new PunchesRollupRecord();
      rec.payCycle = items[0].payCycle;
      rec.approvedTimecards = _.sumBy(items, item => item.approvedTimecards) / items.length;
      rec.validTimecards = _.sumBy(items, item => item.validTimecards);
      rec.missingPunches = _.sumBy(items, item => item.missingPunches);
      rec.pendingEmpRequest = _.sumBy(items, item => item.pendingEmpRequest);
      rec.invalidPunches = _.sumBy(items, item => item.invalidPunches);
      rec.scheduledPunches = _.sumBy(items, item => item.scheduledPunches);
      totals.push(rec);
    });
    return totals;
  }

  private saveFilters(): void {
    this.storageService.setControlState(this.stateService.componentKey, this.m_dateFiltersControlId,
      {
        value: { startDate: this.startDate, endDate: this.endDate }
      },
      this.m_resetType, this.stateKey);
    this.stateService.controlValueChanged(this.m_dateFiltersControlId);
  }

  private restoreFilters(): void {
    let state: IControlState = this.storageService.getControlState(this.stateService.componentKey, this.m_dateFiltersControlId);
    if (state && state.value) {
      if (state.value.startDate) this.startDate = moment(state.value.startDate).toDate();
      if (state.value.endDate) this.endDate = moment(state.value.endDate).toDate();
    } else {
      this.endDate = new Date();
      this.endDate.setHours(0, 0, 0, 0);
      this.startDate = moment().subtract(14, 'days').toDate();
    }
  }
}

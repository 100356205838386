
import {of as observableOf,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';
import * as _ from 'lodash';
import { Component, Provider, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { DialogOptions, IDialog, LoaRequestClass } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { screenUtils } from '../../../../../common/utils/screenUtils';

import { PMDialogOptions } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-pm-create-review-popup',
  templateUrl: 'pm-create-review-popup.component.html',
  styleUrls: ['pm-create-review-popup.component.scss']
})
export class PmCreateReviewPopupComponent implements IDialog, AfterViewInit {
  public options = new PMDialogOptions();
  public dialogResult: boolean;

  @ViewChild('container', { read: ElementRef, static: true })
  private containerElem: ElementRef;

  private hasEmployee: boolean;
  private dialogOptions: DialogOptions;
  private modalService: ModalService;

  private readonly height = {
    employeeHeight: 260,
    reviewHeight: 650
  };
  private readonly extraHeightIndent = 40;

  public static openDialog(windowTitle: string, options: PMDialogOptions, modalService: ModalService, callback?: (result: boolean, uniqueId?: string) => void): PmCreateReviewPopupComponent {
    const dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 670;
    dialogOptions.height = 260;
    dialogOptions.fullHeightOnMobile = true;
    const resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: PMDialogOptions,
        useValue: options
      }
    ];


    return modalService.globalAnchor.openDialog(
      PmCreateReviewPopupComponent,
      windowTitle,
      dialogOptions,
      resolvedProviders,
      callback
    );
  }

  constructor (
    options: PMDialogOptions,
    dialogOptions: DialogOptions,
    modalService: ModalService,
  ) {
    this.options = options;
    this.dialogOptions = dialogOptions;
    this.modalService = modalService;
    this.dialogResult = false;
    this.hasEmployee = _.isObjectLike(_.get(this.options, 'review.employee'));
  }

  public ngAfterViewInit(): void {
    if (this.hasEmployee) {
      const subscr = observableOf(true).pipe(
        delay(100))
        .subscribe(() => {
          subscr.unsubscribe();
          this.runUpdatingHeight(true);
        });
    }
  }

  public onEmployeeSelected(): void {
    this.hasEmployee = true;
    this.runUpdatingHeight(true);
  }

  public onAction(isSave: boolean): void {
    if (isSave) {
      this.save();
    } else {
      this.cancel();
    }
  }

  private save(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  private cancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  private runUpdatingHeight(isReviewMode: boolean): void {
    if (!screenUtils.isMobile) {
      this.updatePopupHeight(isReviewMode);
    }
  }

  private updatePopupHeight(isReviewMode: boolean): void {
    const container: HTMLElement = this.containerElem.nativeElement;
    if (_.isElement(container)) {
      const popup = $(container).closest('kendo-popup');
      if (popup.length > 0) {
        var maxHeight = Math.min(screen.height - (100 + this.extraHeightIndent), this.height.reviewHeight + this.extraHeightIndent);
        let height = isReviewMode ? maxHeight : this.height.employeeHeight + this.extraHeightIndent;
        popup.css('height', height);
      }
    }
  }
}

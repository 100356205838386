import { OrgLevel, OrgLevelType} from './../../../../state-model/models/index';
import { Component, OnDestroy, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Actions } from '../../../../core/models/index';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { unsubscribe } from '../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { TimeCardModel, TimeCardDisplayOptions } from '../../../models/index';
import { DailyTimecardManagementService, editAction, resetAction } from '../../../services/index';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { ScheduledShiftDefinition } from '../../../../organization/models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { EmployeeDefinitionsApiService, OrgLevelWatchService } from '../../../../organization/services/index';
import { EmployeeShortInfo } from '../../../../organization/models/index';


@Component({
  moduleId: module.id,
  selector: 'slx-timecard-shifts-list',
  templateUrl: 'timecard-shifts-list.component.html',
  styleUrls: ['timecard-shifts-list.component.scss']
})
export class TimeCardShiftsListComponent implements OnInit, OnDestroy {

  @Input()
  public set model(value: TimeCardModel) {
    if (!value) {
      return;
    }
    this.m_model = value;
    this.employeeId = value.employee.id;
    this.dateOn = value.date;
    this.records = value.schedule;

    this.loadEmployeeInfo();
  }
  public get model(): TimeCardModel {
    return this.m_model;
  }

  public employeeId: number;
  public dateOn: Date;
  public records: ScheduledShiftDefinition[];
  public gridState: KendoGridStateHelper<ScheduledShiftDefinition>;
  public appConfig: IApplicationConfig;
  public canEditTimecard: boolean;
  public displayOptions: TimeCardDisplayOptions;
  public employeeDepartmentId: number;

  private m_model: TimeCardModel;
  @unsubscribe()
  private resetSubscription: Subscription;

  @unsubscribe()
  private canEditChangedSubscription: Subscription;
  @unsubscribe()
  private optionsChangedSubscription: Subscription;

  constructor(private managementService: DailyTimecardManagementService,
              private employeeApiService: EmployeeDefinitionsApiService,
              private orgLevelWatchService: OrgLevelWatchService) {
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.resetSubscription = this.managementService.resetSection$.pipe(filter((m: resetAction) => m.target === 'schedule')).subscribe((m: resetAction) => {
      this.records = m.model.schedule;
    });

    this.canEditChangedSubscription = this.managementService.canEditChanged$
      .subscribe((canEdit: boolean) => {
        this.canEditTimecard = canEdit;
      });

    this.optionsChangedSubscription = this.managementService.displayOptionSelected$
      .subscribe((options: TimeCardDisplayOptions) => {
        this.displayOptions = options;
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private loadEmployeeInfo(): void {
    this.employeeDepartmentId = 0;
    this.employeeApiService.getEmployeeShortInfoCached(this.employeeId).then((info: EmployeeShortInfo) => {
      if (info.department) {
        let orgLevel: OrgLevel = this.orgLevelWatchService.getOrgLevelByRelatedItemId(info.department.id, OrgLevelType.department);
        if (orgLevel) {
          this.employeeDepartmentId = orgLevel.id;
        }
      }
    }).catch((reason: any) => {
      this.employeeDepartmentId = 0;
    });
  }
}

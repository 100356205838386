import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, Observable, Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/operators';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { ManagementBaseService } from '../../../core/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { IUser } from '../../../authentication/store/index';
import { OrgLevel, User } from '../../../state-model/models/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { CopyPayCodesContainer } from '../../models/copy-paycodes/copy-paycodes-container';
import { CopyPayCodesAPIService } from './copy-paycodes-api.service';
import * as _ from 'lodash';

@Injectable()
export class CopyPayCodesManagementService extends ManagementBaseService<any, any> implements IConfigurationManagementService {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['session', 'user'])
  public user$: Observable<IUser>;
  editItemCmd$: ReplaySubject<any>;

  public get container(): CopyPayCodesContainer {
    return this.m_container;
  }

  private m_container: CopyPayCodesContainer = new CopyPayCodesContainer();

  @unsubscribeInService()
  private appDataSubscription: Subscription;

  private currentOrgLevel: OrgLevel;
  private currentUser: User;

  constructor(
    public access: AccessManagementService,
    public changeService: ChangeManagementService,
    private api: CopyPayCodesAPIService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  editingItem: any;
  isEditingNewItem: boolean;
  onSaveItem: (result: { dataItem: any; isNew: boolean; }) => void;
  onRemoveItem: (item: any) => void;
  onEditItem: (item: any) => void;
  onCancelEditItem: () => void;
  setSelectedCount: (count: number) => void;
  onAddItem: (item: any) => void;
  addItemCmd$: ReplaySubject<any>;
  viewRefresh$: Subject<boolean>;
  removeItemsCmd$: ReplaySubject<any>;
  markAsDirty(): void {
    throw new Error('Method not implemented.');
  }

  public init(): void {
    this.onStateChanged$.next({ isLoading: true });

    this.access.allowCorporationLevel = false;
    this.access.allowOrganizationLevel = true;
    this.access.allowDepartmentLevel = true;

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id) {
        this.m_container.id = +id;
      }
    });

    this.appDataSubscription = this.orgLevel$.pipe(
      combineLatest(this.user$)
    ).subscribe((value: [OrgLevel, User]) => {
      let [orgLevel, user]: [OrgLevel, User] = value;
      if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
        return;
      }
      this.currentOrgLevel = orgLevel;
      this.currentUser = user;
      this.access.orgLevelType = this.currentOrgLevel.type;
      this.onStateChanged$.next({ isLoading: false });
    });
  }

  public loadPayCodes(orgLevelId: number): Promise<void> {
    return this.api.getPaycodeExceptions(orgLevelId).then(payCodes => {
      this.m_container.records = payCodes;
    }).catch(error => {
      this.notificationsService.error('Error', 'Failed to load pay codes.');
      console.error('Error loading pay codes:', error);
      throw error;
    });
  }

  public loadOrganizations(): Promise<void> {
    return this.api.getOrganizationDetails().then(organizations => {
      this.m_container.organizations = organizations;
    }).catch(error => {
      this.notificationsService.error('Error', 'Failed to load organizations.');
      console.error('Error loading organizations:', error);
      throw error;
    });
  }

  public copyPayCodes(
    payCodeIds: number[],
    organizationIds: number[],
    currentOrganizationId: number,
    overrideMatchingException: boolean,
    copyOnlyIfExists: boolean
  ): Promise<void> {
    return this.api.copyPayCodes(
      payCodeIds,
      organizationIds,
      currentOrganizationId,
      overrideMatchingException,
      copyOnlyIfExists
    ).then(() => {
      this.notificationsService.success('Success', 'Pay codes copied successfully.');
    }).catch(error => {
      this.notificationsService.error('Error', 'Failed to copy pay codes.');
      console.error('Error copying pay codes:', error);
      throw error;
    });
  }
}
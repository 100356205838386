import { Pipe, Injectable, PipeTransform, ElementRef } from '@angular/core';
import { HasAttributePipe } from './hasattribute';
@Pipe({
  name: 'hasnotattribute',
  pure: false
})
@Injectable()
export class HasNotAttributePipe extends HasAttributePipe implements PipeTransform {
  public transform(elementRef: ElementRef | HTMLElement, attributeName?: string): any {
    let isHas: boolean = super.transform(elementRef, attributeName);
    return !isHas;
  }
}

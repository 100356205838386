import * as _ from 'lodash';
import { ViewEncapsulation, Component, OnInit, OnDestroy, Input, NgZone, Host, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { process, State, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, GridComponent, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { KendoGridStateHelper, cancelEvent, saveEvent, removeEvent, ConfirmOptions, ConfirmDialogComponent, ModalService, InfoPreloaderParams } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { RangeDates, IRangeDates } from '../../../../../common/models/range-dates';
import * as moment from 'moment';
import {
  EmployeeAccrualTransaction,
  EmployeeSectionAccrualTransactions,
  EmployeeSectionsBase,
  AccrualTypes,
  AccrualTypesDialogOptions
} from '../../../models';
import { EmployeeSectionsAccrualsApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { ResetBalanceDialogComponent } from './reset-balance-dialog/reset-balance-dialog.component';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { appConfig } from '../../../../../../app/app.config';
import { AppServerConfig } from './../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../../app-settings/services/index';
import { NotificationsService } from './../../../../../../app/core/components';

export class setEmployeeIdToResetBalance {
  public empId: number;
}

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-transactions',
  templateUrl: './employee-sections-transactions.component.html',
  styleUrls: ['./employee-sections-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})

export class EmployeeSectionsTransactionsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  public createdDateTime: string = moment().format(appConfig.militaryDateTimeFormat);
  public employeeName: string;
  public employeePosition: string;
  public employeeType: string;
  public employeePayRollNumber: string;

  @Input('employeeAccrualTransactions')
  public set accrualTransactions(employeeAccrualTransactions: EmployeeSectionAccrualTransactions) {
    this.employeeAccrualTransactions = employeeAccrualTransactions;
    const canEdit: boolean = _.get(employeeAccrualTransactions, 'actions.canEdit', null);
    if (_.isBoolean(canEdit)) {
      this.canEdit = canEdit;
    }
    this.refreshGrid();

  }
  @Input()
  public set employeeId(value: number) {
    this.m_employeeId = value;
  }
  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  @ViewChild('gridForm', { static: true })
  public ngForm: NgForm;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridRemoveSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;

  public addMode: boolean;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public gridState: KendoGridStateHelper<EmployeeAccrualTransaction>;
  public selectedRange: RangeDates;
  public skip: number = 0;
  public pageSize: number = 10;
  public canEdit: boolean;
  public editingItem: EmployeeAccrualTransaction;
  public accrualTypesList: AccrualTypes[] = [];
  public transactionDate: Date;
  public m_employeeId: number;
  public employeeAccrualTransactions: EmployeeSectionAccrualTransactions;
  public deleteAccrualTransactions: EmployeeAccrualTransaction[] = [];
  public employeeActionToolBar: boolean = true;
  public pdfTemplate: any = {
    allPages: true,
    landscape: true,
    paperSize: 'A4',
    scale: 0.7,
    repeatHeaders: true,
    margin: '0.25in',
    marginTop: '0.675in',
    marginBottom: '0.5in',
    marginRight: '0.15in',
    marginLeft: '0.15in'
  }
  public EnableRate4DecimalPlaces: boolean;
  public cells: any;

  public get employeeId(): number {
    return this.m_employeeId;
  }

  constructor(
    private modalService: ModalService,
    private changeDetector: ChangeDetectorRef,
    private employeeSectionsAccrualsApiService: EmployeeSectionsAccrualsApiService,
    private appSettingsManageService: AppSettingsManageService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private notificationService: NotificationsService
  ) {
    super(decorator, ngZone);
    this.gridState = new KendoGridStateHelper<EmployeeAccrualTransaction>();
    this.canEdit = true;
    this.gridState.state.skip = 0;
    this.skip = 0;
    this.createSubscriptions();
    let endDate: moment.Moment = moment().endOf('day');
    let startDate: moment.Moment = moment().endOf('day');
    this.endDate = endDate.add(10, 'day').startOf('day').toDate();
    this.startDate = startDate.subtract(90, 'day').startOf('day').toDate();
    this.gridState.state.take = this.pageSize;
    this.selectedRange = new RangeDates();
    this.selectedRange.startDate = this.startDate;
    this.selectedRange.endDate = this.endDate;
    this.employeeName = this.employeeShortInfo.fullName;
    this.employeePosition = this.employeeShortInfo.position.name;
    this.employeeType = this.employeeShortInfo.type;
    this.employeePayRollNumber = this.employeeShortInfo.payrollNumber;
    this.updateLookups(this.transactionDate);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.updateLookups(this.transactionDate);
    this.getSettings();
    }

  public ngOnDestroy(): void {
  }

  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.EnableRate4DecimalPlaces =  config.EnableRate4DecimalPlaces;
    if(this.EnableRate4DecimalPlaces){
      this.cells = {
        format: '0.0000', 
        textAlign: 'left'
      }
    }
    else{
      this.cells = {
        format: '0.00', 
        textAlign: 'left'
      }
    }
  }

  public get isShowHighPrecision(): boolean {
    return this.EnableRate4DecimalPlaces;
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.employeeAccrualTransactions.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  public onClickExport(isPDF: boolean): void {
    this.exportTo(isPDF);
  }

  public getFileName(isPDF: boolean): string {
    if(isPDF) {
      return this.employeeName + 'Accrual Transactions History From ' + this.getDates() + '.pdf';
    }
    else {
      return this.employeeName + 'Accrual Transactions History From ' + this.getDates() + '.xlsx';
    }
  }

  public getTitle(): string {
    return 'Employee Accrual Transactions for the Period ' + this.getDates();
  }

  public getDates(): string {
    return moment(this.selectedRange.startDate).format(appConfig.dateFormat) + ' to ' + moment(this.selectedRange.endDate).format(appConfig.dateFormat);
  }

  public getEmployeeInfo(): string {
    return this.employeeName + '   ' + this.employeePosition + '   ' + this.employeeType + '   ' + this.employeePayRollNumber;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeAccrualTransactions;
  }

  public createSubscriptions(): void {

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.gridEditSubscription = this.gridState.onEdit$.subscribe((item: EmployeeAccrualTransaction): void => {
      this.resetAddMode(item);
    });

    this.gridCancelSubscription = this.gridState.onCancel$.subscribe((item: cancelEvent<EmployeeAccrualTransaction>): void => {
      this.cancelSubscription();
    });

    this.gridSaveSubscription = this.gridState.onSave$.subscribe((item: saveEvent<EmployeeAccrualTransaction>): void => {
      this.gridSaveSubscribe(item.dataItem, item.isNew);
    });

    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe((item: removeEvent<EmployeeAccrualTransaction>): void => {
      this.removeSubscription(item.dataItem);
    });
  }

  private resetAddMode(item: EmployeeAccrualTransaction): void {
    this.addMode = false;
    this.editingItem = item;
  }

  private gridSaveSubscribe(item: EmployeeAccrualTransaction, isNew: boolean): void {
    item.isDirty = true;
    if (isNew) {
      let source = this.employeeAccrualTransactions.records;
      this.employeeAccrualTransactions.records = _.concat(source, [item]);
      this.addMode = false;
    }
    this.refreshGrid();
  }

  private cancelSubscription(): void {
    this.editingItem = null;
    this.addMode = false;
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private removeSubscription(item: EmployeeAccrualTransaction): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialogComponent.openDialog(
      'Confirmation',
      'Are you sure that you want to remove this transaction?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.doRemove(item);
        }
      }, options);
  }

  public doRemove(item: EmployeeAccrualTransaction): void {
    this.employeeAccrualTransactions.records = _.without(this.employeeAccrualTransactions.records, item);
    item.deleted = true;
    item.isDirty = true;
    this.deleteAccrualTransactions.push(item);
    this.doSave();
    this.refreshGrid();
  }

  public loadOnDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startProgress();
    this.selectedRange = { startDate, endDate };
    this.employeeSectionsAccrualsApiService.getAccrualTransactions(this.employeeId, this.selectedRange.startDate, this.selectedRange.endDate)
      .then((subsectionData: EmployeeSectionAccrualTransactions) => {
        this.employeeAccrualTransactions = subsectionData;
        this.state.isLoaded = true;
        this.skip = 0;
        this.gridState.state.skip = 0;
        this.refreshGrid();
        this.stopProgress();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      })
      .catch(() => {
        this.stopProgress();
      });
  }

  public loadSubsection(): void {
    this.loadOnDateChanged(this.selectedRange);
  }

  public updateLookups(transactionDate): void {
    if (this.m_employeeId && this.employeeAccrualTransactions) {
      this.employeeSectionsAccrualsApiService.getAccrualTypes(this.m_employeeId, transactionDate)
        .then((accrualtype: AccrualTypes[]) => {
          this.accrualTypesList = accrualtype;
        });
    }
  }

  public doSave(): void {
    this.startProgress();
    if (this.deleteAccrualTransactions && this.deleteAccrualTransactions.length > 0) {
      this.employeeAccrualTransactions.records = _.concat(this.employeeAccrualTransactions.records, this.deleteAccrualTransactions);
    }

    let empAccTranUpdate = new EmployeeSectionAccrualTransactions();
    empAccTranUpdate = this.employeeAccrualTransactions;
    empAccTranUpdate.records = _.filter(this.employeeAccrualTransactions.records, (empTransactionRecords) => empTransactionRecords.isDirty);

    if (empAccTranUpdate && empAccTranUpdate.records && empAccTranUpdate.records.length > 0) {
      this.employeeSectionsAccrualsApiService.postAccrualTransactions(this.m_employeeId, empAccTranUpdate, this.selectedRange)
        .then((status: any) => {
          this.state.isLoaded = true;
          this.deleteAccrualTransactions.length = 0;
          this.loadSubsection();
          this.refreshGrid();
          this.stopProgress();
        }).catch((reason: any) => {
          this.stopProgress();
          this.onActionError(reason);
        });
    }
    else {
      this.loadSubsection();
      this.stopProgress();
      this.refreshGrid();
    }
  }

  public colorCode(code: number): any {
    let result: any;
    if (code > 0) {
      result = '#59c152';
    } else if (code < 0) {
      result = '#ff0000';
    } else {
      result = '#000000';
    }
    return result;
  }

  public rowCallback(context: RowClassArgs) {
    if (context.dataItem.isLastManualTransaction == true)
      return {
        manualTransactions: true
      };
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.refreshGrid();
  }

  public noAccrualTrasactionData(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    ConfirmDialogComponent.openDialog(
      'Warning',
      'There is no policy assigned to perform balance reset operations',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.loadSubsection();
        }
      }, options);
  }

  public onResetBal(): void {
    if (!this.m_employeeId) {
      this.noAccrualTrasactionData();
      return;
    }

    let tDate = moment().startOf('day').toDate();

    this.employeeSectionsAccrualsApiService.getAccrualTypes(this.m_employeeId, tDate)
      .then((accrualTypes: AccrualTypes[]) => {
        if (!accrualTypes.length) {
          this.noAccrualTrasactionData();
          return;
        }

        let accr: AccrualTypesDialogOptions = new AccrualTypesDialogOptions();
        accr.accrual = accrualTypes;
        accr.empId = this.employeeId;
        accr.fromDate = this.selectedRange.startDate;
        accr.toDate = this.selectedRange.endDate;
        accr.hireDate = this.employeeShortInfo.dateHired;
        ResetBalanceDialogComponent.openDialog('Reset Balance', this.modalService, accr, (result: boolean) => {
          if (result) {
            this.state.isLoaded = true;
            this.loadSubsection();
            this.state.isLoaded = false;
          }
        });
      });
  }

  public onRecal(): void {
    if (this.m_employeeId) {
      this.employeeSectionsAccrualsApiService.postAccrualsRecalculate(this.m_employeeId).
        then((response: any) => {
          if(response.status && response.data.status) {
            this.state.isLoaded = true;
            this.loadSubsection();
            this.state.isLoaded = false;
            this.notificationService.success('Success', response.data.statusMessage != null && response.data.statusMessage.length > 0 ? response.data.statusMessage : 'Accrual Recaculation completed successfully');
          } else if(response.status && !response.data.status) {
            this.notificationService.error('Error', response.data.statusMessage != null && response.data.statusMessage.length > 0 ? response.data.statusMessage : 'There is error an error occured during accrual recalculation');
          }
        }).catch((reason: any) => {
          this.onActionError(reason);
        });
    }
  }

  public refreshGrid(): void {
    if (!this.employeeAccrualTransactions) {
      this.gridView = null;
      this.stopProgress();
      return;
    }
    this.gridState.view = process(this.employeeAccrualTransactions.records, this.gridState.state);
  }
}

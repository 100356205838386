<div class="form-group form-md-line-input kendo kendo-common kendo-default" [class.has-error]="form && (form | invalid: fieldName)" [class.has-warning]="form && (form | pending: fieldName)">
  <div class="input-group">
    <span *ngIf="modelElement | hasattribute:'required'" class="required-identifier"><i class="far fa-star" aria-hidden="true"></i></span>
    <ng-content select="[ngModel]"></ng-content>
    <label [attr.for]="fieldName">{{label}}</label>
    <span *ngIf="form && (form | pending: fieldName)" class="input-group-addon"><i class="far fa-clock" aria-hidden="true"></i></span>
    <span *ngIf="(modelElement | hasnotattribute:'readonly')" class="input-group-addon"><i class="fas fa-pencil-alt" aria-hidden="true"></i></span>
  </div>
  <div error-messages *ngIf="form" [messages]="errorMessages" [errors]="form | errors: fieldName" class="error-block">
    <ng-content select="[errorMessage]"></ng-content>
  </div>
</div>


import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { StateManagementService, ColumnManagementService, ModalService, ChangeManagementService, FileService } from '../../../common/services';
import { PerformanceManagementApiService } from './performance-management-api.service';
import { LookupService } from '../../../organization/services';
import { NotificationsService } from '../../../core/components';
import { unsubscribeAll, mutableSelect } from '../../../core/decorators';
import { Subscription ,  Observable ,  ReplaySubject ,  Subject } from 'rxjs';
import { OrgLevel } from '../../../state-model/models';
import { PerformanceReviewCode, PmTemplateDefinition, ReadFile, PmAttachment } from '../../../organization';
import { CfgPmCode, PmFile } from '../models';
import { Assert } from '../../../framework/assert/assert';
import { SessionService } from '../../../core/services';

@Injectable()
export class PmConfigurationManagementService {


    @mutableSelect(['orgLevel'])
    private orgLevel$: Observable<OrgLevel>;
    private orgLevel: OrgLevel;

    private loading$ = new Subject<boolean>();
    private closePopup$ = new Subject<void>();
    private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
    private codesLoaded$ = new ReplaySubject<CfgPmCode[]>(1);
    private templatesLoaded$ = new ReplaySubject<PmTemplateDefinition[]>(1);
    private exportCodesTo$ = new Subject<boolean>();
    private exportTemplatesTo$ = new Subject<boolean>();

    private addNewCode$ = new Subject<CfgPmCode>();
    private addNewTemplate$ = new Subject<PmTemplateDefinition>();

    private changeGroupName: string = 'performance-management';
    private changeManagementComponentId: string = 'configure-performance-management';

    @unsubscribeAll('destroy')
    private subscriptions: StringMap<Subscription> = {};

    private templateFilesToUpload: ReadFile[];

    constructor (
        private apiService: PerformanceManagementApiService,
        private lookupService: LookupService,
        private notificationService: NotificationsService,
        private changeService: ChangeManagementService,
        private fileService: FileService,
        private sessionService: SessionService
    ) { }

    public initialize(): void {
        this.changeService.setCurrentComponentId(this.changeManagementComponentId);
        this.subscribeToOrgLevelChanges();
    }

    public destroy(): void {

        this.orgLevel = null;

        this.loading$.complete();
        this.closePopup$.complete();
        this.orgLevelChanged$.complete();
        this.codesLoaded$.complete();
        this.templatesLoaded$.complete();
        this.exportCodesTo$.complete();
        this.exportTemplatesTo$.complete();
        this.addNewCode$.complete();
        this.addNewTemplate$.complete();
    }

    public subscribeToLoading(callback: (v: boolean) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    }

    public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    }

    public subscribeToCodesLoaded(callback: (v: CfgPmCode[]) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.codesLoaded$.subscribe(callback);
    }

    public subscribeToTemplatesLoaded(callback: (v: PmTemplateDefinition[]) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.templatesLoaded$.subscribe(callback);
    }

    public subscribeToAddNewCode(callback: (code: CfgPmCode) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.addNewCode$.subscribe(callback);
    }

    public subscribeToAddNewTemplate(callback: (tpl: PmTemplateDefinition) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.addNewTemplate$.subscribe(callback);
    }

    public subscribeToExportCodes(callback: (v: boolean) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.exportCodesTo$.subscribe(callback);
    }

    public subscribeToExportTemplates(callback: (v: boolean) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.exportTemplatesTo$.subscribe(callback);
    }

    public exportTemplates(isPdf: boolean): void {
        this.exportTemplatesTo$.next(isPdf);
    }

    public exportCodes(isPdf: boolean): void {
        this.exportCodesTo$.next(isPdf);
    }

    public startAddNewCode(): void {
        let newCode: CfgPmCode = new CfgPmCode();
        newCode.orgLevel = this.orgLevel;
        this.addNewCode$.next(newCode);
    }

    public async addNewCode(code: CfgPmCode): Promise<void> {
        this.loading$.next(true);
        try {
            await this.apiService.createCode(code);
            this.loading$.next(false);
        } catch (e) {
            console.log(e);
        } finally {
            await this.loadPerformanceReviewCodes();
            this.loading$.next(false);
        }
    }

    public async updateCode(code: CfgPmCode): Promise<void> {
        this.loading$.next(true);
        try {
            await this.apiService.updateCode(code);
        } catch (e) {
            console.log(e);
        } finally {
            await this.loadPerformanceReviewCodes();
            this.loading$.next(false);
        }
    }

    public async removeCode(code: CfgPmCode): Promise<void> {
        this.loading$.next(true);
        try {
            await this.apiService.removeCode(code.id);
        } catch (e) {
            console.log(e);
        } finally {
            this.loading$.next(true);
            await this.loadPerformanceReviewCodes();
            this.loading$.next(false);
        }
    }

    public async addNewTemplate(tpl: PmTemplateDefinition): Promise<void> {
        this.loading$.next(true);
        try {
            if (this.templateFilesToUpload && _.size(this.templateFilesToUpload) > 0) {
                var newTpl = await this.apiService.createTemplate(tpl);
                await this.attachSavedFiles(newTpl.templateId, this.templateFilesToUpload);
                this.templateFilesToUpload = null;
            } else {
                throw new Error('Can not create template without file');
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.loading$.next(true);
            await this.loadPerformanceReviewTemplates();
            this.loading$.next(false);
        }
    }

    public async updateTemplate(tpl: PmTemplateDefinition): Promise<void> {
        this.loading$.next(true);
        try {
            await this.apiService.updateTemplate(tpl);
        } catch (e) {
            console.log(e);
        } finally {
            await this.loadPerformanceReviewTemplates();
            this.loading$.next(false);
        }
    }

    public async removeTemplate(tpl: PmTemplateDefinition): Promise<void> {
        this.loading$.next(true);
        try {
            await this.apiService.removeTemplate(tpl.templateId);
        } catch (e) {
            console.log(e);
        } finally {
            await this.loadPerformanceReviewTemplates();
            this.loading$.next(false);
        }
    }

    public startAddNewTemplate(files: ReadFile[]): void {

        this.templateFilesToUpload = files;

        let tpl = new PmTemplateDefinition();
        tpl.orgLevel = this.orgLevel.id;
        tpl.orgLevelName = this.orgLevel.name;
        let file = _.first(this.templateFilesToUpload);
        tpl.attachment = new PmAttachment();
        tpl.attachment.fileName = file.fileName;

        var user = this.sessionService.getUser();
        tpl.addedBy = user.username;
        tpl.addedOn = new Date();
        tpl.modifiedBy = user.username;
        tpl.modifiedOn = new Date();

        this.addNewTemplate$.next(tpl);
    }

    private async attachSavedFiles(id: number, files: ReadFile[]): Promise<PmTemplateDefinition> {
        if (_.size(files) > 0) {
            var tpl = this.apiService.saveTemplateAttachments(id, files);
            return tpl;
        }
        return null;
    }

    private subscribeToOrgLevelChanges(): void {
        this.subscriptions.orgLevel = this.orgLevel$.pipe(
            filter((o: OrgLevel) => _.isFinite(_.get(o, 'id'))))
            .subscribe(async (orgLevel: OrgLevel) => {
                if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
                this.orgLevel = orgLevel;
                this.orgLevelChanged$.next(this.orgLevel);
                await this.loadResources();
            });
    }

    private async loadResources(): Promise<any> {
        this.loading$.next(true);
        await this.loadPerformanceReviewCodes();
        await this.loadPerformanceReviewTemplates();
        this.loading$.next(false);
    }

    private async loadPerformanceReviewCodes(): Promise<void> {
        if (this.orgLevel) {
            const codes = await this.apiService.getCodeDefinitions(this.orgLevel.id);
            this.codesLoaded$.next(codes);
        }
    }

    private async loadPerformanceReviewTemplates(): Promise<void> {
        if (this.orgLevel) {
            const templates = await this.apiService.getTemplateDefinitions(this.orgLevel.id);
            this.templatesLoaded$.next(templates);
        }
    }
}
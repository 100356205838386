import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { MetaMapService } from '../../../core/services/index';
import { FieldsMeta, Actions } from '../../../core/models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { PayCodeModel, IPayCodeModel, IPayCodeDTO, PayCode, PayCodesContainer, IAccrualPolicy, AccrualPolicy, IAccrualTypes, AccrualTypes } from '../../models/index';

@Injectable()
export class PayCodesMapService {

    constructor(private metaMapService: MetaMapService) { }

    public mapToPayCodesContainer(data: IPayCodeModel[], meta: FieldsMeta): PayCodesContainer {
        let container: PayCodesContainer = new PayCodesContainer();
        let actions: Actions = this.metaMapService.mapActions(meta);
        container.actions = actions;
        container.records = this.mapToPayCodeModels(data, meta);
        let editExceptionFlag = _.find(meta.actions, (action: string) => {
            return action === 'editExceptions';
        });
        if (editExceptionFlag) {
            container.canEditExceptionFlag = true;
        } else {
            container.canEditExceptionFlag = false;
        }
        let editPaycodestoAccrualMappingFlag = _.find(meta.actions, (action: string) => {
            return action === 'editPaycodestoAccrualMapping';
        });
        if (editPaycodestoAccrualMappingFlag) {
            container.canEditPaycodestoAccrualMappingFlag = true;
        } else {
            container.canEditPaycodestoAccrualMappingFlag = false;
        }
        return container;
    }

    public mapToPayCodeModels(dtos: IPayCodeModel[], meta: FieldsMeta): PayCodeModel[] {
        let models: PayCodeModel[] = [];
        _.each(dtos, (dto: IPayCodeModel) => {
            let model: PayCodeModel = this.mapToPayCodeModel(dto, meta);
            models.push(model);

        });
        return models;
    }

    public mapToPayCodeDtos(models: PayCodeModel[]): IPayCodeDTO[] {
        let dtos: IPayCodeDTO[] = [];
        _.each(models, (model: PayCodeModel) => {
            let dto: IPayCodeDTO = this.mapToPayCodeDto(model.payCode);
            dtos.push(dto);
        });
        return dtos;
    }

    public mapToPayCodeModel(dto: IPayCodeModel, meta?: FieldsMeta): PayCodeModel {
        // later we will possibly pass policies for all fields. so we can map it here.
        let model: PayCodeModel = new PayCodeModel();
        model.payCode = this.mapToPayCode(dto);
        model.lastUpdateUsername = dto.lastUpdateUsername;
        model.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
        return model;
    }

    public clonePayCodesCollection(payCodes: PayCodeModel[]): PayCodeModel[] {
        let collection: PayCodeModel[] = [];
        _.each(payCodes, (payCodeModel: PayCodeModel) => {
            let clonedCode: PayCodeModel = this.mapToPayCodeModel(this.mapToIPayCodeModel(payCodeModel));
            collection.push(clonedCode);
        });
        return collection;
    }

    public mapToIPayCodeModel(model: PayCodeModel): IPayCodeModel {
        let dto: any = this.mapToPayCodeDto(model.payCode);
        dto.lastUpdateDate = dateTimeUtils.convertToDtoString(model.lastUpdateDate);
        dto.lastUpdateUsername = model.lastUpdateUsername;
        return dto;
    }

    public mapToPayCode(dto: IPayCodeDTO): PayCode {
        let model: PayCode = new PayCode();
        model.id = dto.id;
        model.overridesortorder = dto.overridesortorder;
        model.allocationType = dto.allocationType;
        model.amount = dto.amount;
        model.color = dto.color;
        model.department = dto.department;
        model.description = dto.description;
        model.group = dto.group;
        model.interfaceIndicator = dto.interfaceIndicator;
        model.isAca = dto.isAca;
        model.isPaid = dto.isPaid;
        model.isPbj = dto.isPbj;
        model.isException = dto.isException;
        model.payDifferential = dto.payDifferential;
        model.organizationId = dto.organizationId;
        model.isPbjApplicable = dto.isPbjApplicable;
        model.useInTimesheets = dto.useInTimesheets;
        model.isUsedInAccrualCalc = dto.isUsedInAccrualCalc;
        model.accrualPolicies = dto.accrualPolicies;
        model.accrualType = dto.accrualType;
        model.accrualTypes = new AccrualTypes();
        model.accrualTypes.accrualTypeName = dto.accrualType;
        model.accrualPolicyList = dto.accrualPolicies && dto.isUsedInAccrualCalc ? this.mapAccrualPolicyList(dto.accrualPolicies.toString()) : null;
        model.accrualPolicyNames = ((_.isNull(model.accrualPolicyList) || (model.accrualPolicyList && model.accrualPolicyList.length == 0)) && dto.isUsedInAccrualCalc) ? 'All' : _.join(_.map(model.accrualPolicyList, (opt: AccrualPolicy) => opt.policyName), ', ');
        model.allocationTypeId=dto.allocationTypeId;
        model.groupId=dto.groupId;
        return model;
    }

    public mapToPayCodeDto(model: PayCode): IPayCodeDTO {
        return {
            id: model.id,
            overridesortorder:model.overridesortorder,
            allocationType: model.allocationType,
            amount: model.amount,
            color: model.color,
            department: model.department,
            description: model.description,
            group: model.group,
            interfaceIndicator: model.interfaceIndicator,
            isAca: model.isAca,
            isPbj: model.isPbj,
            isPaid: model.isPaid,
            isException: model.isException,
            payDifferential: model.payDifferential,
            organizationId: model.organizationId,
            isPbjApplicable: model.isPbjApplicable,
            useInTimesheets: model.useInTimesheets,
            isUsedInAccrualCalc: model.isUsedInAccrualCalc,
            accrualPolicies: model.isUsedInAccrualCalc ? _.map(model.accrualPolicyList, (opt: AccrualPolicy) => opt.policyName) : [],
            accrualType: model.accrualTypes ? model.accrualTypes.accrualTypeName : model.accrualType,
            allocationTypeId:model.allocationTypeId,
            groupId:model.groupId
        };
    }

    public mapAccrualPolicyList(data: string): AccrualPolicy[] {
        let policyData: string[] = _.split(data, ',');
        let roots: AccrualPolicy[] = [];
        _.forEach(policyData, (item: string) => {
            if (item && item.trim() != '') {
                if (item.trim() != 'All') {
                    let value: AccrualPolicy = new AccrualPolicy();
                    value.policyName = item.trim();
                    roots.push(value);
                }
            }
        });
        return roots;
    }

    public mapAccrualPolicy(data: IAccrualPolicy[]): AccrualPolicy[] {
        let roots: AccrualPolicy[] = [];
        _.forEach(data, (item: IAccrualPolicy) => {
            let value: AccrualPolicy = this.mapAccrualPolices(item);
            roots.push(value);
        });
        return roots;
    }


    public mapAccrualPolices(data: IAccrualPolicy): AccrualPolicy {
        let value: AccrualPolicy = new AccrualPolicy();
        value.id = data.id;
        value.policyName = data.policyName;
        return value;
    }

    public mapAccrualTypes(data: IAccrualTypes[]): AccrualTypes[] {
        let roots: AccrualTypes[] = [];
        _.forEach(data, (item: IAccrualTypes) => {
            let value: AccrualTypes = this.mapAccrualAccrualType(item);
            roots.push(value);
        });
        return roots;
    }

    public mapAccrualAccrualType(data: IAccrualTypes): AccrualTypes {
        let value: AccrualTypes = new AccrualTypes();
        value.accrualTypeId = data.accrualTypeId;
        value.accrualTypeName = data.accrualTypeName;
        return value;
    }

}

<div class="field-container table-record">
    <div class="col actions-column">

        <div class="slx-form-group slx-no-border small-font">
            <div class="slx-input-group">
                <div class="slx-form-control">
                    <input [attr.id]="'selectRow'+rowIndex" type="checkbox" [checked]="selected" (click)="onItemSelect()">
                    <label class="checkbox-label" [attr.for]="'selectRow'+rowIndex">
                                    <span class="check"></span>
                                    <span class="box"></span>
                                </label>
                </div>
            </div>
        </div>
    </div>

    <div class="col name-column text-column">{{item.name}}</div>

</div>
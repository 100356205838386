import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Provider, ViewEncapsulation, ViewChild, SimpleChanges, } from '@angular/core';
import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { DeviceDetectorService, ModalService } from '../../../../common/services/index';
import { DateRange, IDateRange } from '../../../../core/models/index';
import { OpenPositionControlManagementService } from '../../../services';
import { IRangeDates } from '../../../../common/models/range-dates';
import * as moment from 'moment';
import { DialogOptions } from '../../../../common';
import { OpenPositionControlConfigurationComponent } from '../open-position-control-configuration/open-position-control-configuration.component';
import { OrgLevel } from '../../../../state-model/models';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { OpenPositionControlExcel } from './../../../../../app/scheduler/models/open-position-control/open-position-control-excel';
import { Day } from '@progress/kendo-date-math';
import { OpenPositionControlHelper } from '../open-position-control-helper';
@Component({
  selector: 'slx-open-position-control-toolbar',
  templateUrl: './open-position-control-toolbar.component.html',
  styleUrls: ['./open-position-control-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OpenPositionControlToolbarComponent extends OpenPositionControlHelper implements OnInit, OnDestroy {
  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;
  @Output()
  public onDatesChanged: EventEmitter<IRangeDates>;
  @Input()
  public currentOrgLevel: OrgLevel;
  @Input()
  public isMobile:boolean;
  @ViewChild('excelExport', { static: true })
  excelExport: ExcelExportComponent;
  public dateRange: DateRange = new DateRange(null, null);
  public maxDateRange = 1 * 60 * 60 * 24 * 84; // max 84 days
  public maxDateRangeError = 'You cannot specify range more than 84 days';
  public minDate = moment().set({ 'year': 2000, 'month': 0, 'date': 1 }).toDate();
  public maxDate = moment().add(83, 'days').toDate();
  public startDatePlaceHolder = 'Select Week Starting';
  public endDatePlaceHolder = 'Select Week Ending';
  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }

  public get dateRangeWidth(): number {
    return this.isDesktop ? 325 : 246;
  }

  public get newReviewBtnWidth(): number {
    return this.isDesktop ? 175 : 35;
  }

  @Input()
  public openPositionDetails: any;
  public excelPositionDetails: any;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  public exportOpenPositionControlData: any[] = [];
  public topOrgLevels: any[] = [
    'Corporation',
    'Region',
    'Entity',
    'Division',
    'Company',
    'Business',
    'Operator',
    'State',
  ]

  public disableStartDate: Day[] = [Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday, Day.Saturday];
  public disableEndDate: Day[] = [Day.Sunday, Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday];

  constructor(
    public managementService: OpenPositionControlManagementService,
    private devDetector: DeviceDetectorService,
    modalService: ModalService,
  ) {
    super(modalService);
    this.onDatesChanged = new EventEmitter<IRangeDates>();
  }

  public ngOnInit(): void {
    this.defineDateRange();
    this.excelPositionDetails = this.openPositionDetails;
  }

  public ngOnDestroy(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['openPositionDetails']) {
      this.excelPositionDetails = this.openPositionDetails;
    }
  }


  public onChangeDates(range: IDateRange): void {
    this.managementService.changeDateRange(range);
  }

  public defineDateRange(): void {
    this.dateRange = this.managementService.getDefaultDateRange();
    this.onChangeDates(this.dateRange);
  }

  private emitDatesChanges(dateRange: IRangeDates): void {
    if (dateRange.endDate > this.maxDate) return;
    this.onDatesChanged.emit(dateRange);
  }

  public onDatesRangeChanged($event: IRangeDates): void {
    this.dateRange.startDate = $event.startDate;
    this.dateRange.endDate = $event.endDate;
    this.maxDate = moment(this.dateRange.startDate).add(83, 'days').toDate();
    this.emitDatesChanges($event);
  }

  public getDateRange(): string {
    return 'Date Range: ' + moment(this.startDate).format('L') + ' - ' + moment(this.endDate).format('L');
  }

  public exportToExcel() {
    this.exportOpenPositionControlData = [];
    let orgData: OpenPositionControlExcel = new OpenPositionControlExcel();
    orgData.organizationLevel = this.currentOrgLevel.name;
    orgData.totalHourGaps = this.excelPositionDetails.totalHoursGap;
    orgData.totalOpenShifts = this.excelPositionDetails.totalOpenShifts;
    // orgData.totalFTENeeded = this.excelPositionDetails.totalFTENeeded;
    let topLevelIndex = this.topOrgLevels.findIndex((orgType) => orgType === this.currentOrgLevel.type)
    if (topLevelIndex >= 0) {
      this.exportOpenPositionControlData.push(orgData);
    }
    _.forEach(this.excelPositionDetails.openPositionDetails, (organization) => {
      if (organization.orgName) {
        let orgData: OpenPositionControlExcel = new OpenPositionControlExcel();
        orgData.organizationLevel = organization.orgName;
        orgData.totalHourGaps = organization.totalHoursGap;
        orgData.totalOpenShifts = organization.totalOpenShifts;
        // orgData.totalFTENeeded = organization.totalFTENeeded;
        this.exportOpenPositionControlData.push(orgData);
        if (organization.orgDepartmentDetails.length > 0) {
          _.forEach(organization.orgDepartmentDetails, (department) => {
            if (department.departmentName) {
              let orgData: OpenPositionControlExcel = new OpenPositionControlExcel();
              orgData.organizationLevel = organization.orgName + ' > ' + department.departmentName;
              orgData.totalHourGaps = department.totalHoursGap;
              orgData.totalOpenShifts = department.totalOpenShifts;
              // orgData.totalFTENeeded = department.totalFTENeeded;
              this.exportOpenPositionControlData.push(orgData);
            }
          });
        }
      }
    });
    this.excelExport.data = this.exportOpenPositionControlData;
    this.excelExport.save();
  }

  onKeydown(value: any) {
    if (value.keyCode >= 0) {
        return false;
    }
  }
}


import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription ,  Observable } from 'rxjs';
import { State, process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';

import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';

import { OrganizationTreeContext, } from '../../../../../organization/models/index';
import { OrgLevelActions } from '../../../../../organization/actions/index';
import { OrgLevelWatchService } from '../../../../../organization/services/index';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/index';
import { EmployeeSectionNavigationService } from '../../../../../common/services/index';
import { OperationalConsoleApiService } from '../../../../services/index';
import { UserApplication, OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';
import { OperationalConsoleEntry, OperationalConsoleField, OperationalConsoleDialogParameters } from '../../../../../employee/employee-list/models/index';

import { OperationalConsoleDialogComponent } from '../../../../../employee/employee-list/components/index';
import { OperationalConsoleStateService } from '../../../../services/operational-console/operational-console-state.service';
import { ReportExportType } from '../../../../../reports/models/report-export-type';

@Component({
  moduleId: module.id,
  selector: 'slx-operational-console-grid',
  templateUrl: 'operational-console-grid.component.html',
  styleUrls: ['operational-console-grid.component.scss'],
})
export class OperationalConsoleGridComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<OperationalConsoleEntry>;

  @ViewChild('kendoGrid', {static: true})
  public grid: GridComponent;

  @unsubscribe()
  private orglevelSubscription: Subscription;
  @unsubscribe()
  private employeeLinkClickSubscription: Subscription;
  @unsubscribe()
  private filtersChangedSubscription: Subscription;
  @unsubscribe()
  private actionExportSubscription: Subscription;
  private pageSize: number = 30;
  private employeeLinkClick: EventEmitter<number>;

  constructor(
    public stateService: OperationalConsoleStateService,
    private apiService: OperationalConsoleApiService,
    private modalService: ModalService,
    private orgLevelWatchService: OrgLevelWatchService,
    private orgLevelActions: OrgLevelActions,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.gridState = new KendoGridStateHelper();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'date', dir: 'desc' }];

    this.employeeLinkClick = new EventEmitter<number>();
  }

  public ngOnInit(): void {
    this.filtersChangedSubscription = this.stateService.filtersChanged$.subscribe((value) => {
      this.refreshGrid(value);
  });

    this.actionExportSubscription = this.stateService.exportAction$.subscribe((exportType) => {
      switch (exportType) {
        case ReportExportType.Excel:
          this.grid.saveAsExcel();
          break;
        case ReportExportType.Pdf:
          this.grid.saveAsPDF();
          break;
      }
      this.refreshGrid(this.stateService.entries);
      });

    this.employeeLinkClickSubscription = this.employeeLinkClick.subscribe((empId: number) => {
      let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
      navService.NavigateToEmployeeSections(empId, true);
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
}

  public onEntryClick(entry: OperationalConsoleEntry, field: OperationalConsoleField): void {
    if (this.isActiveEntry(field)) {
      const dialogOptions: OperationalConsoleDialogParameters = new OperationalConsoleDialogParameters(
        this.stateService.currentDate, field, entry.position, entry.orgLevel,
        this.stateService.application, this.employeeLinkClick
      );
      OperationalConsoleDialogComponent.openDialog(dialogOptions, this.modalService);
    }
  }

  public onExportToExcel(): void {
    this.grid.saveAsExcel();
  }

  public onExportToPdf(): void {
    this.grid.saveAsPDF();
  }

  public onOrgLevelClick(entry: OperationalConsoleEntry): void {
    if (this.canChangeOrgLevel()) {
      let context: OrganizationTreeContext = new OrganizationTreeContext();
      context.applicationId = this.stateService.application.id;

      let orgLevel: OrgLevel = this.orgLevelWatchService.getOrgLevelById(entry.orgLevel.id);
      if (orgLevel) {
        this.orgLevelActions.select(context, orgLevel);
      }
    }
  }

  public canChangeOrgLevel(): boolean {
    return this.stateService.orgLevel.type !== OrgLevelType.department;
  }

  public getOrgLevelTitle(): string {
    let title: string = '';
    const orgLevelTitle: string = _.get(this.stateService.orgLevel, 'type');
    switch(orgLevelTitle) {
      case OrgLevelType.department:
        title = 'Position';
        break;
      case OrgLevelType.organization:
        title = 'Department';
        break;
      case OrgLevelType.corporation:
        title = 'Organization';
        break;
      default:
        title = 'Organization';
    }

    return title;
  }

  public getTitle(field: string): string {
    const index: number = field.indexOf('.');
    let keyName: string = field;
    if (index !== -1) {
      keyName = field.slice(0, index);
    }

    return OperationalConsoleField.getTitle(keyName);
  }

  public isActiveEntry(field: OperationalConsoleField): boolean {
    return field.value !== 0;
  }

  private refreshGrid(entries: OperationalConsoleEntry[]): void {
    if (entries.length === 0) {
      this.gridState.view = null;

      return;
    }
    this.gridState.view = process<OperationalConsoleEntry>(entries, this.gridState.state);
  }
}


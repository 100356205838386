<div class="ng2-ip-container" [ngClass]="containerClass">
  <div class="ng2-ip-table" [@inputAnim]="inputAnim">
    <ng-template ngFor let-idx [ngForOf]="blocksRef"; let-isLast="last">
      <div class="ng2-ip-table-cell" [class.ng2-ip-disabled]="disabledBlocks[idx]" [ngClass]="invalidBlocks[idx]">
        <input #input
               type="text"
               [value]="blocks[idx] || ''"
               (change)="onChange($event.target.value, idx)"
               (blur)="onBlur(idx)"
               (focus)="onFocus(idx)"
               [disabled]="disabledBlocks[idx]"
               (paste)="onPaste($event, idx)"
               (copy)="onCopy($event, idx)"
               (keypress)="onKeyPress($event, idx)"
               (keyup)="onKeyUp($event, idx)"/>
      </div>
      <span class="ng2-ip-table-cell ng2-ip-sep" *ngIf="!isLast">{{vX.SEP}}</span>
    </ng-template>
  </div>

  <div class="ng2-ip-copy-overlay" *ngIf="showCopySelection">
    <div class="ng2-ip-table" [@copyAnim]="">
      <div class="ng2-ip-copy-title">Copy?</div>
      <a (click)="onCopyDecision('block')">Block</a>
      <a (click)="onCopyDecision('address')">Address</a>
    </div>
  </div>
</div>

import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { ReplaySubject ,  Subject ,  Subscription } from 'rxjs';

import { Assert } from '../../../../framework/index';
import { unsubscribeAll } from '../../../../core/decorators/index';

import { BenefitEmployeesApiService } from '../../../../app-modules/benefits/services/index';
import {
  BenefitEmployeeDependent,
  BenefitEmpDependentEnrollment,
  BenefitEligibleDependentBenefit,
  BenefitDependentDroppedEnrollment,
  BenefitTerminationReason
} from '../../../../app-modules/benefits/models/index';

import { LookupService } from '../../../../organization/services/index';
import { LookupType, Lookup } from '../../../../organization/models/index';

import { EmployeeSubsectionDependents, EmployeeDependentEnrollments } from '../../models/index';

import { EmployeeSectionsBenefitsCommonService } from './employee-sections-benefits-common.service';

class ChangedDependent {
  constructor (
    public dependent: BenefitEmployeeDependent,
    public isCreated = false,
    public isEdited = false,
    public isDeleted = false,
  ) {}
}

class ChangedEnrollment {
  constructor (
    public enrollment: BenefitEmpDependentEnrollment,
    public isCreated = false,
    public isEdited = false,
    public isDeleted = false,
  ) {}
}

@Injectable()
export class EmployeeSectionsBenefitsManagementService {
  private dependents$: ReplaySubject<EmployeeSubsectionDependents>;
  private empId$: ReplaySubject<number>;
  private spinner$: ReplaySubject<boolean>;
  private editMode$: ReplaySubject<boolean>;
  private enrollmentsQuantity$: ReplaySubject<{ id: number, quantity: number }>;
  private changedDependent$: ReplaySubject<ChangedDependent>;
  private changedEnrollment$: ReplaySubject<ChangedEnrollment>;
  private changedEnrollments$: ReplaySubject<EmployeeDependentEnrollments>;
  private genderLookup$: ReplaySubject<Lookup>;
  private stateLookup$: ReplaySubject<Lookup>;
  private reset$: Subject<void>;

  private employeeId: number;
  private dependents: EmployeeSubsectionDependents;
  private dependentBenefit: BenefitEligibleDependentBenefit;
  private dependentEnrolments: Map<number, Array<BenefitEmpDependentEnrollment>>;

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private commonManService: EmployeeSectionsBenefitsCommonService,
    private benefitsApiService: BenefitEmployeesApiService,
    private lookupService: LookupService,
  ) { }

  public init(): void {
    this.dependents$ = new ReplaySubject<EmployeeSubsectionDependents>(1);
    this.empId$ = new ReplaySubject<number>(1);
    this.spinner$ = new ReplaySubject<boolean>(1);
    this.editMode$ = new ReplaySubject<boolean>(1);
    this.enrollmentsQuantity$ = new ReplaySubject<{ id: number, quantity: number }>(1);
    this.changedDependent$ = new ReplaySubject<ChangedDependent>(1);
    this.changedEnrollment$ = new ReplaySubject<ChangedEnrollment>(1);
    this.changedEnrollments$ = new ReplaySubject<EmployeeDependentEnrollments>(1);
    this.genderLookup$ = new ReplaySubject<Lookup>(1);
    this.stateLookup$ = new ReplaySubject<Lookup>(1);
    this.reset$ = new Subject<void>();
    this.dependentEnrolments = new Map<number, Array<BenefitEmpDependentEnrollment>>();

    this.getGenderLookup();
    this.getStateLookup();

    this.subscriptions.update = this.commonManService.subscribeToUpdateDependents(() => {
      this.clearDependentsEnrollments(null);
      this.reset$.next();
    });

    this.subscriptions.spinner = this.commonManService.subscribeToDependentsSpinner((isShown: boolean) => {
      this.toggleSpinner(isShown);
    });
  }

  public destroy(): void {
    this.dependents$.complete();
    this.empId$.complete();
    this.spinner$.complete();
    this.editMode$.complete();
    this.enrollmentsQuantity$.complete();
    this.changedDependent$.complete();
    this.changedEnrollment$.complete();
    this.changedEnrollments$.complete();
    this.genderLookup$.complete();
    this.stateLookup$.complete();
    this.reset$.complete();
    this.dependentEnrolments.clear();

    this.employeeId = null;
    this.dependents = null;
    this.dependentBenefit = null;
    this.dependentEnrolments = null;
  }

  public toggleSpinner(isShown: boolean): void {
    this.spinner$.next(isShown);
  }

  public toggleEditMode(isEdit: boolean): void {
    this.editMode$.next(isEdit);
  }

  public changeDependents(dep: EmployeeSubsectionDependents): void {
    if (_.isObjectLike(dep)) {
      this.dependents = dep;
      this.dependents$.next(dep);
    }
  }

  public changeEnrollmentsQuantity(id: number, quantity: number): void {
    if (_.isFinite(id) && _.isFinite(quantity)) {
      this.enrollmentsQuantity$.next({ id, quantity });
    }
  }

  public changeEmpId(empId: number): void {
    if (_.isFinite(empId) && this.employeeId !== empId) {
      this.employeeId = empId;
      this.empId$.next(empId);
    }
  }

  public loadDependentEnrollments(dep: BenefitEmployeeDependent): void {
    if (_.isObjectLike(dep) && _.isFinite(dep.id)) {
      this.toggleSpinner(true);
      let promise = Promise.resolve(this.dependentEnrolments.get(dep.id));
      if (!this.dependentEnrolments.has(dep.id)) {       
        promise = this.benefitsApiService.getEmployeeDependentEnrollments(dep.id,this.employeeId);
      }

      promise
        .then(e => this.saveDependentEnrollments(dep.id, e))
        .finally(() => this.toggleSpinner(false))
    }
  }

  public clearDependentsEnrollments(depIds: Array<number>): void {
    if (_.isArray(depIds) && _.size(depIds) > 0) {
      _.forEach(depIds, id => {
        this.dependentEnrolments.delete(id);
      });
    } else {
      this.dependentEnrolments.clear();
    }
  }

  public updateDependentsEnrollments(dependents: Array<BenefitEmployeeDependent>): void {
    if (_.isArray(dependents) && _.size(dependents) > 0) {
      this.toggleSpinner(true);

      this.clearDependentsEnrollments(_.map(dependents, dep => dep.id));
      const promises = _.map(dependents, dep => this.benefitsApiService.getEmployeeDependentEnrollments(dep.id,this.employeeId))
      Promise.all(promises)
        .then((enrollments) => {
          _.forEach(enrollments, e => this.saveDependentEnrollments(_.head(e).dependentId, e));
        })
        .finally(() => this.toggleSpinner(false));
    }
  }

  public subscribeToSpinner(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.spinner$.subscribe(callback);
  }

  public subscribeToEditMode(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.editMode$.subscribe(callback);
  }

  public subscribeToDependentsSubsection(callback: (v: EmployeeSubsectionDependents) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.dependents$.subscribe(callback);
  }

  public subscribeToEnrollmentsQuantity(callback: (v: { id: number, quantity: number }) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.enrollmentsQuantity$.subscribe(callback);
  }

  public subscribeToEmployeeId(callback: (v: number) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.empId$.subscribe(callback);
  }

  public subscribeToChangedDependent(callback: (v: ChangedDependent) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.changedDependent$.subscribe(callback);
  }

  public subscribeToChangedEnrollment(callback: (v: ChangedEnrollment) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.changedEnrollment$.subscribe(callback);
  }

  public subscribeToChangedEnrollments(callback: (v: EmployeeDependentEnrollments) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.changedEnrollments$.subscribe(callback);
  }

  public subscribeToGenderLookup(callback: (v: Lookup) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.genderLookup$.subscribe(callback);
  }

  public subscribeToStateLookup(callback: (v: Lookup) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.stateLookup$.subscribe(callback);
  }

  public subscribeToReset(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.reset$.subscribe(callback);
  }

  public createDependent(dataItem: BenefitEmployeeDependent): void {
    this.toggleSpinner(true);
    this.benefitsApiService.createEmployeeDependent(dataItem)
      .then((dep) => this.changedDependent$.next(new ChangedDependent(dep, true)))
      .finally(() => this.toggleSpinner(false));
  }

  public editDependent(dataItem: BenefitEmployeeDependent): void {
    this.toggleSpinner(true);
    this.benefitsApiService.editEmployeeDependent(dataItem)
      .then((dep) => this.changedDependent$.next(new ChangedDependent(dep, false, true)))
      .finally(() => this.toggleSpinner(false));
  }

  public deleteDependent(dataItem: BenefitEmployeeDependent): void {
    this.toggleSpinner(true);
    this.benefitsApiService.deleteEmployeeDependent(dataItem.id)
      .then(() => this.changedDependent$.next(new ChangedDependent(dataItem, false, false, true)))
      .finally(() => this.toggleSpinner(false));
  }

  public addEnrollment(enroll: BenefitEmpDependentEnrollment): void {
    this.toggleSpinner(true);
    this.benefitsApiService.addDependentEnrollment(enroll)
      .then((enr: BenefitEmpDependentEnrollment) => this.changedEnrollment$.next(new ChangedEnrollment(enr, true)))
      .finally(() => this.toggleSpinner(false));
  }

  public editEnrollment(enroll: BenefitEmpDependentEnrollment): void {
    this.toggleSpinner(true);
    this.benefitsApiService.editDependentEnrollment(enroll)
      .then((enr: BenefitEmpDependentEnrollment) => this.changedEnrollment$.next(new ChangedEnrollment(enr, false, true)))
      .finally(() => this.toggleSpinner(false));
  }

  public deleteEnrollment(enroll: BenefitEmpDependentEnrollment): void {
    this.toggleSpinner(true);
    this.benefitsApiService.deleteDependentEnrollment(enroll.id)
      .then(() => this.changedEnrollment$.next(new ChangedEnrollment(enroll, false, false, true)))
      .finally(() => this.toggleSpinner(false));
  }

  public dropDependentEnrollment(enrollment: BenefitDependentDroppedEnrollment): Promise<any> {
    this.toggleSpinner(true);
    return this.benefitsApiService.dropDependentEnrollment(enrollment)
      .finally(() => this.toggleSpinner(false));
  }

  public getGenderLookup(): void {
    this.toggleSpinner(true);
    this.lookupService.getLookup({ lookupType: LookupType.empGender, employeeId: 0 })
      .then((lookup: Lookup) => this.genderLookup$.next(lookup))
      .finally(() => this.toggleSpinner(false));
  }


  public getStateLookup(): void {
    this.toggleSpinner(true);
    this.lookupService.getLookup({ lookupType: LookupType.state })
      .then((lookup: Lookup) => this.stateLookup$.next(lookup))
      .finally(() => this.toggleSpinner(false));
  }

  public getBenefitTerminationReasons(): Promise<BenefitTerminationReason[]> {
    return this.benefitsApiService.getBenefitTerminationReasons();
  }

  private saveDependentEnrollments(depId: number, enrollments: Array<BenefitEmpDependentEnrollment>): void {
    this.dependentEnrolments.set(depId, enrollments);
    this.changedEnrollments$.next(new EmployeeDependentEnrollments(depId, enrollments));
  }
}

import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ReplaySubject ,  Subscription ,  Subject ,  Observable } from 'rxjs';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { ChangeManagementService } from '../../../common/index';
import { EmployersContainer, AleGroupView, AleGroup, Employer, AleGroupAssign } from '../../models/employers/index';
import { EmployersConfigurationApiService } from '../employers/employers-configuration-api.service'
import { EmployersConfigurationMapService } from '../employers/employers-configuration-map.service';
import { Assert } from '../../../framework/index';

@Injectable()
export class EmployersManagementService {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public aleGroups$ = new ReplaySubject<AleGroupView[]>(1);
  private loadingAleGroup$ = new Subject<boolean>();
  private isSaveComplete$ = new Subject<void>();
  private closePopup$ = new Subject<void>();
  private onLoaded$: ReplaySubject<EmployersContainer>;
  private onStateChanged$: ReplaySubject<any>;

  private m_container: EmployersContainer;
  private currentOrgLevel: OrgLevel;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  public orgLevelId: number;

  public assign_group: AleGroupAssign[];
  public readonly defaultGroupId = 0;
  public readonly defaultGroupName = 'ALE Group 1';

  constructor(
    public changeService: ChangeManagementService,
    private api: EmployersConfigurationApiService,
    private apiMapService: EmployersConfigurationMapService) {
    this.aleGroups$ = new ReplaySubject<AleGroupView[]>(1);
    this.onLoaded$ = new ReplaySubject(1);
    this.onStateChanged$ = new ReplaySubject(1);
    this.assign_group = [];
  }

  public init(): void {

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (_.isNumber(orgLevel.id)) {
        this.currentOrgLevel = orgLevel;
        this.orgLevelId = orgLevel.id;
        this.onStateChanged$.next({ orgLevelChanged: true });
        this.fetchRecords();
      }
    });

  }

  public subscribeToLoadingAleGroup(callback: (o: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loadingAleGroup$.subscribe(callback);
  }

  public subscribeToOnLoaded(callback: (o: EmployersContainer) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.onLoaded$.subscribe(callback);
  }

  public subscribeToOnStateChanged(callback: (o: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.onStateChanged$.subscribe(callback);
  }

  public subscribeToLoadAleGroups(callback: (o: AleGroupView[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.aleGroups$.subscribe(callback);
  }

  public markAsDirty(): void {
    this.changeService.changeNotify();
  }

  public async onSaveEmployer(item: Employer): Promise<void> {
    this.onStateChanged$.next({ configureMode: true, isLoading: true });
    await this.api.postEmployers(item.orgLevelId, item).
      then((result: any) => {
        this.orgLevelId = item.orgLevelId;
        this.loadEmployers();
        this.onStateChanged$.next({ configureMode: false, isLoading: true });
      }).catch(() => {
        this.onStateChanged$.next({ configureMode: true, isLoading: true });
      });
  }

  public loadEmployers(): void {
    this.fetchRecords();
  }

  public mapALeGroups(groups: AleGroup[]): AleGroup[] {
    let aleGroupList: AleGroup[] = [];
    let aleDefaultGroup: AleGroup = new AleGroup();
    aleDefaultGroup.groupId = this.defaultGroupId;
    aleDefaultGroup.groupName = this.defaultGroupName;
    aleGroupList.push(aleDefaultGroup);
    aleGroupList = [...aleGroupList, ...groups];
    return aleGroupList;
  }

  protected fetchRecords(): void {
    this.onStateChanged$.next({ isLoading: true });
    this.api.getEmployers(this.orgLevelId).
      then((result: EmployersContainer) => {
        this.m_container = new EmployersContainer();
        this.m_container = result;
        this.onStateChanged$.next({ isLoading: false });
        this.fetchAleGroups();
      }).catch(() => {
        this.onStateChanged$.next({ isLoading: false });
      });
  }

  protected fetchAleGroups(): void {
    this.loadingAleGroup$.next(true);
    this.api.getAleGroups(this.orgLevelId).then((groups: AleGroup[]) => {
      this.m_container.aleGroupList = this.mapALeGroups(groups);
      this.onLoaded$.next(this.m_container);
      const aleGroupView = this.apiMapService.mapToPositionGroupView(this.m_container.records, groups, this.defaultGroupId, this.defaultGroupName);
      this.aleGroups$.next(aleGroupView);
      this.loadingAleGroup$.next(false);
    }).catch(() => {
      this.loadingAleGroup$.next(false);
      this.onStateChanged$.next({ isLoading: false });
    });

  }

  public filteringGroups(g: AleGroupView[], srcText: string): AleGroupView[] {
    const groups = _.cloneDeep(g);
    if (_.size(srcText) > 0) {
      const searchText = _.toLower(srcText);
      return _.reduce(groups, (accum, groupView) => {
        const isDefaultGroup = groupView.groupId === this.defaultGroupId;
        const employers = _.filter(groupView.employers, (pos) => _.includes(_.toLower(pos.legalName), searchText));
        if (isDefaultGroup || !isDefaultGroup && _.size(employers) > 0) {
          accum.push(groupView);
          groupView.employers = employers;
        }
        return accum;
      }, [] as AleGroupView[]);
    }
    return groups;
  }

  public assignGroupsToEmployers(group: AleGroupView, employer: Employer): void {

    employer.aleGroupId = group.groupId === this.defaultGroupId ? null : group.groupId;
    const groups = this.apiMapService.mapToGroupsWithEmployers(group, employer, this.defaultGroupId, this.defaultGroupName);
    _.forEach(this.assign_group, (group) => {
      group.companyIds = group.companyIds.filter(s => s != employer.companyId);
    });
    this.assign_group.push({ aleGroupId: groups.groupId, companyIds: groups.employerIds });

  }

  public async saveGroupAssignments(): Promise<void> {
    this.loadingAleGroup$.next(true);
    this.api.saveAssignGroup(this.currentOrgLevel.id, this.assign_group).
      then((result: any) => {
        this.isSaveComplete$.next();
        this.loadingAleGroup$.next(false);
    }).catch(() => {
      this.loadingAleGroup$.next(false);
    });   
  }

  public async addUpdateGroup(g: AleGroupView): Promise<AleGroupView> {
    this.loadingAleGroup$.next(true);
    let groupView: AleGroupView = null;
    this.onStateChanged$.next({ isLoading: true });
    try {
      const posGroup = await this.api.addUpdateGroup(this.currentOrgLevel.id, g);
      groupView = this.apiMapService.makePosViewOfPosGroup(posGroup);

    } finally {
      this.onStateChanged$.next({ isLoading: false });
      this.loadingAleGroup$.next(false);
      return groupView;
    }
  }

  public async updateGroup(g: AleGroupView): Promise<AleGroupView> {
    let groupView: AleGroupView = null;
    this.loadingAleGroup$.next(true);
    try {
      const posGroup = await this.api.addUpdateGroup(this.currentOrgLevel.id, g);
      groupView = this.apiMapService.makePosViewOfPosGroup(posGroup);
    } finally {
      this.loadingAleGroup$.next(false);
      return groupView;
    }
  }

  public async deleteGroup(group: AleGroupView): Promise<void> {
    let result: any = null;
    this.loadingAleGroup$.next(true);
    try {
      result = await this.api.addUpdateGroup(this.currentOrgLevel.id, group);
    } finally {
      this.loadingAleGroup$.next(false);

      return result;
    }
  }

  public createGroupView(): AleGroupView {
    return this.apiMapService.createGroupView(null, '', this.currentOrgLevel.relatedItemId);
  }

  public requestClosePopup(): void {
    this.changeService.canMoveForward().then(canMove => {
      if (!canMove) {
        return;
      }
      this.closePopup();
    });
  }
  private closePopup(): void {
    this.changeService.clearChanges();
    this.changeService.clearCurrentComponentId();
    this.closePopup$.next();
  }

  public subscribeToClosePopup(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.closePopup$.subscribe(callback);
  }
  public subscribeToSaveComplete(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.isSaveComplete$.subscribe(callback);
  }

  public destroy(): void {
    this.aleGroups$.complete();
    this.loadingAleGroup$.complete();
    this.isSaveComplete$.complete();
    this.closePopup$.complete();
    this.currentOrgLevel = null;
  }
}

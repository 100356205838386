
import {map, combineLatest} from 'rxjs/operators';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable ,  ReplaySubject ,  Subscription ,  Subject } from 'rxjs';

import { Actions } from '../../../core/models/field/actions-type';
import { ManagementBaseService } from '../../../core/services/index';
import {
  LookupType,
  Lookup
} from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { LookupService } from '../../../organization/services/lookup/lookup.service';
import { Month, ChangeManagementService } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { IConfigutrationContainer } from '../../models/configiration-container.interface';
import { Holiday } from '../../models/holidays/holiday.model';
import { HolidaysContainer } from '../../models/holidays/holidays-container';
import { HolidaysApiService } from '../../services/holidays/holidays-api.service';
import { OrgLevelWatchService } from '../../../organization/services/index';

@Injectable()
export class HolidaysConfigurationManagementService extends ManagementBaseService<HolidaysContainer, any> implements IConfigurationManagementService {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public removeItemsCmd$: ReplaySubject<Holiday>;
  public addItemCmd$: ReplaySubject<Holiday>;
  public editItemCmd$: ReplaySubject<Holiday>;
  public onItemSaved$: ReplaySubject<Holiday>;
  public onItemRemoved$: ReplaySubject<Holiday>;
  public viewRefresh$: Subject<boolean>;
  public onDataChanged$: Subject<boolean>;

  public months: Month[];
  public editingItem: any;
  public isEditingNewItem: boolean;

  public get holidayContainer(): HolidaysContainer {
    return this.m_container as HolidaysContainer;
  }

  public get container(): IConfigutrationContainer {
    return this.m_container;
  }

  private m_container: IConfigutrationContainer = new HolidaysContainer();

  private currentOrgLevel: OrgLevel;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;

  private m_initialized: boolean;
  private m_currentYear: number;

  constructor(public access: AccessManagementService, public changeService: ChangeManagementService, private api: HolidaysApiService,
    private lookup: LookupService, private orgLevelWatchService: OrgLevelWatchService) {
    super();
    this.removeItemsCmd$ = new ReplaySubject<Holiday>();
    this.addItemCmd$ = new ReplaySubject<Holiday>();
    this.editItemCmd$ = new ReplaySubject<Holiday>();
    this.onItemSaved$ = new ReplaySubject<Holiday>();
    this.onItemRemoved$ = new ReplaySubject<Holiday>();
    this.onDataChanged$ = new Subject<boolean>();
    this.viewRefresh$ = new Subject<boolean>();
  }

  public init(): void {

    this.access.allowCorporationLevel = false;
    this.access.allowOrganizationLevel = true;
    this.access.allowDepartmentLevel = false;

    this.lookup.getLookup({ lookupType: LookupType.months })
      .then((months: Lookup) => {
        this.months = months.items;

        this.orgLevelSubscription = this.orgLevel$.pipe(
          combineLatest(this.orgLevelWatchService.orgLevelTreeLoaded$),
          map((value: [OrgLevel, boolean]) => value[0]),)
          .subscribe((orgLevel: OrgLevel) => {
            if (!this.currentOrgLevel || this.currentOrgLevel.id !== orgLevel.id) {
              this.currentOrgLevel = this.orgLevelWatchService.getOrgLevelById(orgLevel.id);
              this.access.orgLevelType = this.currentOrgLevel.type;
              this.onStateChanged$.next({ orgLevelChanged: true, configureMode: true, copyMode: false });
              if (this.currentOrgLevel.type === OrgLevelType.organization) {
                this.fetchRecords();
              }
              this.m_initialized = true;
            }
          });
      });
  }

  public markAsDirty(): void {
    this.changeService.changeNotify();
  }

  public openCopyItems(): void {
    this.onStateChanged$.next({ configureMode: false, copyMode: true });
  }

  public closeCopyItems(): void {
    this.onStateChanged$.next({ configureMode: true, copyMode: false });
  }

  public get year(): number {
    return this.m_currentYear;
  }

  public setYear(year: number): void {
    this.m_currentYear = year;
    if (this.m_initialized && this.currentOrgLevel.type === OrgLevelType.organization) {
      this.fetchRecords();
    }
  }

  public setSelectedCount(count: number): void {
    this.access.selectedItemsCount = count;
  }

  public onAddItem(item: any): void {
    this.markAsDirty();
    this.editingItem = item;
    this.isEditingNewItem = true;
    this.addItemCmd$.next(item);
  }

  public onEditItem(item: any): void {
    this.editingItem = item;
    this.editItemCmd$.next(item);
  }

  public onCancelEditItem(): void {
    this.editingItem = null;
    this.isEditingNewItem = false;
    this.editItemCmd$.next(null);
    this.changeService.clearChanges();
  }

  public onRemoveItem(itemsToDelete: Holiday): void {
    this.removeItemsCmd$.next(itemsToDelete);
  }

  public doRemoveItem(item: Holiday): void {
    this.onStateChanged$.next({ isLoading: true });
    this.api.removeHolidays(item.id)
      .then(() => {
        this.access.lockActions = false;
        this.onItemRemoved$.next(item);
        this.onStateChanged$.next({ isLoading: false });
        this.fetchRecords();
      }).catch(() => this.update());
  }

  public onSaveItem(info: { dataItem: Holiday, isNew: boolean }): void {
    if (info.isNew) {
      this.addItem(info.dataItem);
    } else {
      this.updateItem(info.dataItem);
    }
  }

  protected addItem(item: Holiday): void {
    _.each(this.m_container.records, (p: Holiday) => {
      p.isSelected = false;
    });
    this.onStateChanged$.next({ isLoading: true });
    this.access.lockActions = true;
    this.api.addHoliday(item, this.currentOrgLevel.relatedItemId, this.m_currentYear)
      .then((items: Holiday) => {
        this.changeService.clearChanges();
        this.access.lockActions = false;
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.onItemSaved$.next(item);
        this.onStateChanged$.next({ isLoading: false });
        this.fetchRecords();
      }).catch(() => this.update());
  }

  protected updateItem(item: Holiday): void {
    this.onStateChanged$.next({ isLoading: true });
    this.api.editHoliday(item)
      .then((items: Holiday) => {
        this.changeService.clearChanges();
        this.access.lockActions = false;
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.onItemSaved$.next(item);
        this.viewRefresh$.next(false);
        this.onStateChanged$.next({ isLoading: false });
        this.fetchRecords();
      }).catch(() => this.update());
  }

  protected fetchRecords(): void {
    this.access.lockActions = true;
    this.onStateChanged$.next({ isLoading: true });
    this.api.getHolidays(this.currentOrgLevel.relatedItemId, this.m_currentYear)
      .then((records: Holiday[]) => {
        this.changeService.clearChanges();
        _.each(records, (holiday: Holiday) => {
          holiday.months = this.months;
        });
        this.m_container = new HolidaysContainer();
        this.m_container.records = records;
        let actions: Actions = new Actions();
        actions.canAdd = actions.canEdit = actions.canDelete = true;
        this.access.actions = actions;
        this.access.lockActions = false;
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.onLoaded$.next(this.holidayContainer);
        this.onStateChanged$.next({ isLoading: false });
      }).catch(() => {
        this.access.lockActions = false;
        this.onStateChanged$.next({ isLoading: false });
      });
  }

  private update(): void {
    this.access.lockActions = false;
    this.viewRefresh$.next(false);
    this.onStateChanged$.next({ isLoading: false });
  }
}


import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable ,  Subscription } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';

import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../../../common/models/index';
import { process, State } from '@progress/kendo-data-query';
import { StateResetTypes } from '../../../../../../core/models/settings/reset-types';
import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitsNavigationService, StateManagementService, ColumnManagementService } from '../../../../../../common/services/index';
import { OrgLevel } from '../../../../../../state-model/models';
import { IApplicationConfig, appConfig } from '../../../../../../../app/app.config';
import { BenefitLineInfo } from '../../../../models/index';

import { BenefitListManagementService } from './../../../../services/benefit-console';


@Component({
  selector: 'benefit-by-expiration-date',
  templateUrl: 'benefit-expiration-date-component.html',
})
export class BenefitsByExpirationDateComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public data: BenefitLineInfo[] = [];
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<BenefitLineInfo>;
  public isShowingExpired: boolean = false;
  public columnGroup = 'BenefitsByExpirationDate';
  public isPageable = false;
  public pageSize = 50;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private navService: BenefitsNavigationService;
  private orgLevel: OrgLevel;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private manService: BenefitListManagementService
  ) {
    this.gridState = new KendoGridStateHelper<BenefitLineInfo>();
    this.gridState.view = null;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [
      { field: 'endDate', dir: 'desc' },
      { field: 'benefitGroupName', dir: 'asc' },
      { field: 'name', dir: 'asc' }
    ];
    this.gridState.gridComponentKey = 'BenefitsExpirationGrid';
    this.gridState.gridControlStateKey = 'GridFiltersState';
    this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
    this.gridState.assignRestoredState = true;
    this.gridState.refreshGridAfterRestoreState = true;
    this.navService = new BenefitsNavigationService(this.router, this.activatedRoute);
    this.appConfig = appConfig;
  }

  public ngOnInit() {
    this.subscriptions.onLoad = this.manService
      .subscribeToLoading((value: boolean) => (this.isLoading = value));

    this.subscriptions.onLoaded = this.manService
      .subscribeToLoadedRecords((r: BenefitLineInfo[]) => {
        this.data = r;
        this.isPageable = _.size(this.data) > this.pageSize;
        this.refreshGrid();
      });

    this.subscriptions.orgLevel = this.manService
      .subscribeToOrgLevel((v: OrgLevel) => {
        this.orgLevel = v;
      });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onClickBenefit(providerLine: BenefitLineInfo): void {
    const { benefitGroupId, benefitProviderId, id } = providerLine;
    this.navService.navigateToDetails(this.orgLevel.id, benefitGroupId, benefitProviderId, id);
  }

  private refreshGrid(): void {
    this.isLoading = false;
    if (!this.data) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.data, this.gridState.state);
  }

}

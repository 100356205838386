import { Injectable } from '@angular/core';
import { Observable ,  Subscription ,  Subject }    from 'rxjs';
import { Assert } from '../../../framework/index';
import { ScrollWatchEvent, ScrollWatchAreaEvent } from '../../models/index';

@Injectable()
export class ScrollWatchService {
  private toSubscriptionSource: Subject<string>;
  private toSubscription$: Observable<string>;

  private scrollSource: Subject<ScrollWatchEvent>;
  private scroll$: Observable<ScrollWatchEvent>;

  private areaSource: Subject<ScrollWatchAreaEvent>;
  private scrollArea$: Observable<ScrollWatchAreaEvent>;

  constructor() {
    this.toSubscriptionSource = new Subject<string>();
    this.toSubscription$ = this.toSubscriptionSource.asObservable();

    this.scrollSource = new Subject<ScrollWatchEvent>();
    this.scroll$ = this.scrollSource.asObservable();

    this.areaSource = new Subject<ScrollWatchAreaEvent>();
    this.scrollArea$ = this.areaSource.asObservable();
  }

  public subscribeToSubscriptions(callback: (event: string) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.toSubscription$.subscribe(callback);
  }

  public subscribeScrollSource(callback: (event: ScrollWatchEvent) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    this.onSubscribe('ScrollSource');
    return this.scroll$.subscribe(callback);
  }

  public subscribeAreaScroll(callback: (event: ScrollWatchAreaEvent) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    this.onSubscribe('AreaScroll');
    return this.scrollArea$.subscribe(callback);
  }

  public unsubscribe(subscription: Subscription): void {
    Assert.isNotNull(subscription, 'subscription');
    subscription.unsubscribe();
  }

  public onSubscribe(event: string): void {
    Assert.isNotNull(event, 'event');
    this.toSubscriptionSource.next(event);
  }

  public onScrollSource(event: ScrollWatchEvent): void {
    Assert.isNotNull(event, 'event');
    this.scrollSource.next(event);
  }

  public onScrollArea(event: ScrollWatchAreaEvent): void {
    Assert.isNotNull(event, 'event');
    this.areaSource.next(event);
  }
}

import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { select } from '@angular-redux/store';
import { Observable ,  Subscription } from 'rxjs';

import { UserApplication } from '../../../state-model/models/index';
import { OrgLevelBreadcrumbDefinition, OrgLevelBreadcrumbModes } from '../../../organization/models/index';
import { unsubscribe, mutableSelect } from '../../../core/decorators/index';
import { UserMenuPinService } from '../../../core/services/user-menu-pin/user-menu-pin.service';
import { ApplicationStateManagementService } from '../../services/application-state-management/application-state-management.service';

@Component({
  moduleId: module.id,
  selector: 'slx-left-sidebar',
  templateUrl: 'left-sidebar.component.html',
  styleUrls: ['left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit, OnDestroy {
  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen$: Observable<boolean>;

  public app: UserApplication;
  public isOpened: boolean;
  public orgLevelBreadcrumbsMenuMode: OrgLevelBreadcrumbDefinition = OrgLevelBreadcrumbModes.menu;

  @mutableSelect('application')
  private app$: Observable<UserApplication>;
  @unsubscribe()
  private appSubscription: Subscription;
  @unsubscribe()
  private isOpenedSubscription: Subscription;

  constructor(private userMenuPinService: UserMenuPinService, public appStateManagementService: ApplicationStateManagementService) {
    this.app = new UserApplication(0, '');
  }

  public ngOnInit(): void {
    this.appSubscription = this.app$.subscribe((app: UserApplication) => {
      if (!this.app.id && _.isNumber(app.id)) {
        this.app = app;
      }
    });

    this.isOpenedSubscription = this.isLeftSidebarOpen$.subscribe((opened: boolean) => {
      this.isOpened = opened;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public pinMenu(): void {
    this.userMenuPinService.changeUserMenuPinStatus(true);
  }

  public unPinMenu(): void {
    this.userMenuPinService.changeUserMenuPinStatus(false);
  }
}

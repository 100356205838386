<div class="date-cycle-container">
  <a (click)="prevRange()" class="cycle-button"> <i class="fa fa-angle-left range-link" aria-hidden="true"></i></a>
  <!--<div class="dropdown">
    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                {{startDate | amDateFormat: appConfig.dateFormat}} - {{endDate | amDateFormat: appConfig.dateFormat}}
            </button>
    <div class="dropdown-menu" aria-labelledby="group-activities">
      <span class="title"><strong>Select Period</strong></span>
      <div *ngFor="let period of periods">
        <input type="radio" [(ngModel)]="selectedPeriod" (ngModelChange)="onPeriodChanged()" [value]="period" [checked]="period===selectedPeriod"
          [attr.id]="period.id">{{period.name}}<br>
      </div>
    </div>
  </div>-->
  {{internalValue?.start | amDateFormat: appConfig.dateFormat}} - {{internalValue?.end | amDateFormat: appConfig.dateFormat}}
  <a (click)="nextRange()" class="cycle-button"> <i class="fa fa-angle-right range-link" aria-hidden="true"></i></a>
</div>

<div class="range-container">
  <slx-input-decorator>
    <slx-date-picker-ngx slx-input
      [(ngModel)]="selectedDate"
      (ngModelChange)="onDateChanged()"
      [acceptNullDate]="false"
      placeholder="Date"
      name="selectedDate"
    ></slx-date-picker-ngx>
    <span errorMessage for="date"></span>
  </slx-input-decorator>
  <div class="dropdown-buttons">
    <kendo-dropdownbutton [data]="dataModel">
      <a> {{ selectedEndDate | amDateFormat: appConfig.dateFormat }} </a>
      <ng-template kendoDropDownButtonItemTemplate let-dataItem>
        <i class="{{ dataItem.icon }}" aria-hidden="true"></i>
        <slx-input-decorator className="slx-form-group slx-no-border small-font slx-no-label slx-no-error-block">
          <slx-radio-input slx-input name="period{{dataItem.seconds}}" fieldName="period{{dataItem.seconds}}" valueField="seconds"
            [option]="dataItem" [caption]="dataItem.text" placeholder="" [(ngModel)]="selectedRange">
          </slx-radio-input>
        </slx-input-decorator>
        <!--<span class="text-container">&nbsp;{{ dataItem.text }}</span>-->
      </ng-template>
    </kendo-dropdownbutton>
  </div>
</div>

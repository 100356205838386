
<slx-spinner [show]="isLoading">

  <kendo-grid #grid class="slx-full-height slx-blue-grid"
    [data]="gridState.view"
    sortable="true"
    [sort]="gridState.state.sort"
    [selectable]="{ mode: 'single' }"
    [filterable]="'menu'"
    [filter]="gridState.state.filter"
    (cancel)="cancelHandler()"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
    (save)="saveHandler($event)"
    (selectionChange)="gridState.selectionChange($event)"
    (dataStateChange)="gridState.dataStateChange($event)"
  >     

  <kendo-grid-column  title="" width="90">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-rowIndex="rowIndex" let-dataItem="dataItem">
      <div class="flex-horizontal">               
        <button kendoGridSaveCommand  type="button" [disabled]="checkRequiredFields(dataItem)" class="slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks grid-btn-pad">
          <i class="fas fa-save" aria-hidden="true"></i></button> 
        <button kendoGridCancelCommand class="slx-button slx-grey slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks grid-btn-pad">
          <i class="fas fa-ban" aria-hidden="true"></i> </button> 
          <button kendoGridEditCommand type="button"  class="slx-button slx-with-icon command-button">
            <i class="fas fa-pencil-alt slx-button__icon"  aria-hidden="true"></i>
            </button>
           <button kendoGridRemoveCommand type="button" class="slx-button slx-with-icon command-button">
            <i class="fas fa-trash-alt slx-button__icon" aria-hidden="true"></i>
          </button> 
      </div>
    </ng-template>      
  </kendo-grid-column> 
  
   
   
  <kendo-grid-column field="groupEvents" title="Group Events" width="140">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.groupEvents.name}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <div>
      <slx-dropdown-list 
        [options]="notificationGroupEvent"
        #groupEvents="ngModel" 
        [(ngModel)]="dataItem.groupEvents"
        titleField="name"
        valueField="eventGroupId"
        name="eventGroup"
        required>
      </slx-dropdown-list>
    </div>
      <span *ngIf="dataItem.groupEvents == null || dataItem.groupEvents.name == undefined " class="slx-error-block">*</span>
      <span *ngIf="dataItem.groupEvents == null  || dataItem.groupEvents.name == undefined  " errorMessage for="required" class="slx-error-block"></span>
     
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

   <kendo-grid-column field="groupName"  title="Group Name" width="100" >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.groupName}}
    </ng-template> 
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex"> 
      
        <input required="true"  class="slx-nt-input" placeholder="" [(ngModel)]="dataItem.groupName"
          (keyup)="onKeyName($event, dataItem, groupName)" name="groupName" maxlength="100"> 

        <span *ngIf="dataItem.groupName == undefined || dataItem.groupName == ''" class="slx-error-block">*</span>
        <span *ngIf="dataItem.groupName == undefined || dataItem.groupName == ''" errorMessage for="required" class="slx-error-block"></span>
      
        <span *ngIf="uniqueError" errorMessage for="unique">Group Name should be unique</span>
      
    </ng-template> 
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

 <kendo-grid-column field="displayEvents"  title="Notification Type" width="140">
    <ng-template kendoGridHeaderTemplate>
      Notification Type  
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
     
      <div class="panelbar-wrapper">
        <kendo-panelbar>
          <kendo-panelbar-item  title="{{ dataItem.displayEvents}}">
              <kendo-panelbar-item *ngFor="let eventItem of dataItem.groupEvents.events" title="{{ eventItem.name }}"></kendo-panelbar-item>
          </kendo-panelbar-item>
          </kendo-panelbar>
      </div> 
    </ng-template> 
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

      <kendo-multiselect class="slx-multiselect__multiselect"
      kendoMultiSelectSummaryTag
      [data]="getSelectionEvents(dataItem)"
      #events = "ngModel"
      [(ngModel)]="dataItem.groupEvents.events"
      textField="name"
      valueField="eventId"
      [valuePrimitive]="false"
      [popupSettings]="{
        width: 450,
        height: 150
      }"
    >
      <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
        <span class="k-icon k-i-arrow-s"></span>
        {{ dataItems.length }} item(s) selected
      </ng-template>
    </kendo-multiselect>
    <span *ngIf="dataItem.groupEvents.events.length == 0" class="slx-error-block">*</span>
    <span *ngIf="dataItem.groupEvents.events.length == 0" errorMessage for="required" class="slx-error-block"></span>

    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
 
  <kendo-grid-column field="deliveryMethod"  title="Delivery Method" width="140">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.deliveryMethod}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem"  let-rowIndex="rowIndex">
        <kendo-dropdownlist
          [data]="deliveryMethodsList"
         [(ngModel)]="dataItem.deliveryMethod"
        
        ></kendo-dropdownlist>
        <span *ngIf="dataItem.deliveryMethod == undefined || dataItem.deliveryMethod == ''" class="slx-error-block">*</span>
        <span *ngIf="dataItem.deliveryMethod == undefined || dataItem.deliveryMethod == ''" errorMessage for="required" class="slx-error-block"></span>
  
        
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>    
    
    <kendo-grid-column field="displayRoles" title="User Roles" width="140">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="panelbar-wrapper">
          <kendo-panelbar>
            <kendo-panelbar-item  title="{{ dataItem.displayRoles}}">
                <kendo-panelbar-item *ngFor="let roleItem of dataItem.groupRoles" title="{{ roleItem.name }}"></kendo-panelbar-item>
            </kendo-panelbar-item>
            </kendo-panelbar>
        </div> 

      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" >

        
        <kendo-multiselect class="slx-multiselect__multiselect"
        kendoMultiSelectSummaryTag
        [data]="notificationRole"
        [(ngModel)]="dataItem.groupRoles"
        #roles="ngModel"
        textField="name"
        valueField="roleId"
        [valuePrimitive]="false"
        [popupSettings]="{
          width: 450,
          height: 150
        }"
      >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span class="k-icon k-i-arrow-s"></span>
          {{ dataItems.length }} item(s) selected
        </ng-template>
      </kendo-multiselect>

      <span *ngIf="dataItem.groupRoles.length == 0" class="slx-error-block">*</span>
      <span *ngIf="dataItem.groupRoles.length == 0" errorMessage for="required" class="slx-error-block"></span>

      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="modifiedOn"  title="Updated on" width="100" filter="date">
      <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem?.modifiedOn">{{dataItem?.modifiedOn | amDateFormat: appConfig.dateFormat }} </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      </ng-template>
   
    </kendo-grid-column>

    <kendo-grid-column field="modifiedBy"title="Updated by" width="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.modifiedBy}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
    
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>  
 
  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

</slx-spinner>
  


import { NavigationMenuItem } from './../../../organization/models/navigation-menu-item';
import { FavoriteManagementService } from './../../../configuration/services/favorites/favorites-management.service';
import { ModalService } from './../../../common/services/modal/modal.service';
import * as _ from 'lodash';
import { Observable ,  Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';

import { UserApplication } from '../../../state-model/models/index';
import { portalConfig } from '../../portal.config';
import { ApplicationService, ApplicationDashboardManagementService } from '../../services/index';
import { Assert } from '../../../framework/index';
import { OrgLevel, OrgLevelLocation, OrgLevelType } from '../../../state-model/models/index';

import { ApplicationActions } from '../../actions/index';
import { StyledUserApplication, ApplicationDashboardItem, DashboardApplication, DashboardApplications } from '../../../organization/models/index';
import { OrgLevelWatchService } from '../../../organization/services/index';
import { Router } from '@angular/router';
import { IUser } from '../../../authentication/store/index';
import { SoDashboardCounters, TaDashboardCounters, LoaRequestCounters } from '../../models/index';
import { ClientMessageManagementService } from '../../../authentication/services/client-message/client-message-management.service';
import { IApplicationConfig, appConfig } from '../../../app.config';
import { screenUtils } from '../../../common/utils/index';
import { FavoritesConfigurationDialogComponent } from './../../../configuration/components/favorites/favorites-configuration-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'slx-applications-dashboard',
  templateUrl: 'applications-dashboard.component.html',
  styleUrls: ['applications-dashboard.component.scss']
})
export class ApplicationsDashboardComponent implements OnInit, OnDestroy {
  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;
  public applications: StyledUserApplication[];
  public appsIsEmpty: boolean;
  public user: IUser;
  public orgLevelLocation: OrgLevelLocation;
  public appsById: NumberMap<StyledUserApplication>;
  public maxHeight = 400;
  public appConfig: IApplicationConfig;
  public hasItemsToReview: boolean;
  public isLoading: boolean;
  public favoriteIsEmpty: boolean = true;
  public userApps: DashboardApplication[];
  public userAppsPref: StyledUserApplication[];

  @mutableSelect(['session', 'user'])
  private user$: Observable<IUser>;
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;


  @unsubscribe()
  private countersSubscription: Subscription;
  @unsubscribe()
  private userSubscription: Subscription;
  @unsubscribe()
  private dataSubscription: Subscription;
  @unsubscribe()
  private applicationUpdateSubscription: Subscription;
  @unsubscribe()
  private favoriteSubscription: Subscription;
  @unsubscribe()
  private dashboardAppSubscription: Subscription;
  public favoriteList: NavigationMenuItem[];

  private counters: StringMap<SoDashboardCounters | TaDashboardCounters>;

  constructor(
    private applicationService: ApplicationService,
    private appManagementService: ApplicationDashboardManagementService,
    private applicationActions: ApplicationActions,
    private clientMessageManagementService: ClientMessageManagementService,
    private orgLevelWatchService: OrgLevelWatchService,
    private router: Router,
    private route: ActivatedRoute,
    private favoriteService: FavoriteManagementService
  ) {
    Assert.isNotNull(applicationService, 'applicationService');
    Assert.isNotNull(applicationActions, 'applicationActions');

    this.isLoading = true;
    this.user = null;
    this.counters = null;
    this.appConfig = appConfig;
    this.calcMaxHeight();

  }

  public ngOnInit(): void {
    this.applicationUpdateSubscription = this.applicationService.applicationsUpdated$
      .subscribe((apps: StyledUserApplication[]) => {
        this.applications = apps;
        this.loadDashboardApps(apps);
        this.calcMaxHeight();
        if (!this.dataSubscription) {
          this.dataSubscription = this.orgLevel$
            .subscribe((orgLevel: OrgLevel) => {
              if (!orgLevel || !orgLevel.id) return;
              this.orgLevel = orgLevel;
              this.clientMessageManagementService.loadMessages(this.orgLevel.id);
              this.isLoading = true;
              this.orgLevelWatchService.getLocation(this.orgLevel)
                .then((location: OrgLevelLocation) => {
                  this.orgLevelLocation = location;
                });
            });
        }
      });

    this.dashboardAppSubscription = this.appManagementService.dashboardApps$.subscribe((apps: DashboardApplications) => {
      if (apps.applications) {
        this.mapDashboardAppToStyledApp(apps);
        if (!this.orgLevel || !this.orgLevel.id) return;
        this.isLoading = true;
        this.appManagementService.loadAppCounters(this.applications, this.orgLevel);
      }
    });

    this.userSubscription = this.user$.subscribe((user: IUser) => {
      if (user && _.isNumber(user.id)) {
        this.user = user;
      }
    });

    this.favoriteSubscription = this.favoriteService.favoriteItemsUpdated$.subscribe((favorites: NavigationMenuItem[]) => {
      this.favoriteList = favorites;
      this.favoriteIsEmpty = favorites.length === 0;
    });
  }

  public onLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public getCounters(appName: string): any {
    return this.counters && this.counters[appName] ? this.counters[appName] : null;
  }

  public hasUnreadMessages(): boolean {
    return _.isObject(this.user) && _.isNumber(this.user.messagesUnread);
  }

  public isHidden(app: StyledUserApplication): boolean {
    return this.isMobile() && !app.isAvailableOnMobile;
  }

  public isMobile(): boolean {
    return screenUtils.isMobile;
  }

  public calcMaxHeight(): void {
    if (this.isMobile()) {
      this.maxHeight = 1000;
      return;
    }
    let expandedDashboards = 0;
    _.forEach(this.applications, (app: StyledUserApplication) => {
      if (app.loadCounters) {
        expandedDashboards++;
      }
      expandedDashboards = expandedDashboards + (app.dashboards ? app.dashboards.length : 0);
    });

    if (expandedDashboards > 2) {
      this.maxHeight = 600;
    }
  }

  public openFavoritesConfig(): void {
    this.favoriteService.showConfigDialog();
  }

  private updateItemsToReview(): boolean {
    let result: boolean = false;
    _.each(this.applications, (app: StyledUserApplication) => {
      let counters: SoDashboardCounters | TaDashboardCounters | null = this.getCounters(app.name);
      if (counters && counters.total > 0) {
        result = true;
      }
    });
    return result;
  }

  public openAppConfig(): void {
    this.appManagementService.showConfigDialog();
  }


  public async loadDashboardApps(appList: StyledUserApplication[]) {
    let apps = await this.appManagementService.loadApps(true);
    this.mapDashboardAppToStyledApp(apps);
  }

  public mapDashboardAppToStyledApp(apps: DashboardApplications){
    this.userAppsPref = [];
    _.forEach(this.applications, (app: StyledUserApplication) => {
      let result = _.find(apps.applications, ['appId', app.id]);
      if(_.isUndefined(result) || (!_.isUndefined(result) && (_.isUndefined(result.visible) || result.visible)) || app.id == appConfig.moreV5ItemsApplication.id){
        app.index = _.isUndefined(result) ? 999 : result.displayOrder;
        this.userAppsPref.push(app);
      }
    });
    
    this.userAppsPref = _.sortBy(this.userAppsPref, 'index');
    if(this.userAppsPref.length == 0){
      this.appsIsEmpty = true;
    }
  }
}

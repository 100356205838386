import { Router, ActivatedRoute } from '@angular/router';
import { ErrorHandlingService } from './../../../core/services/error-handling/error-handling.service';
import { NotificationMessage } from '../../../app.messages';
import { Injectable } from '@angular/core';
import { ReplaySubject ,  Observable ,  Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

import { ColumnSettingsDefinitions } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ColumnSettingsStorageService, ExceptionConsoleNavigationService } from '../../../common/services/index';
import { ExceptionConsoleContainer, ExceptionConsoleRecord, ExceptionConsoleState } from '../../models/index';
import { PayCycle } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { ExceptionConsoleApiService, TimecardsApiService } from '../index';
import { StateManagementService } from '../../../common/services/index';
import { ComponentStateStorageService } from '../../../common/services/component-state/component-state-storage.service';
import { ControlStateKey, IControlState, StateResetTypes } from '../../../core/models/settings/index';
import { IDestroyService } from '../../../core/models/index';
import { RangeDates } from '../../../common/models/range-dates';
import { dateTimeUtils } from '../../../common/utils/index';
import { TimecardApprovalRequest, PayCycleApprovalRequest } from '../../models/timecards/timecards-approval-request';

@Injectable()
export class ExceptionConsoleManagementService implements IDestroyService {
  public onRecordsSelected$: ReplaySubject<ExceptionConsoleRecord[]>;
  public onStateChanged$: ReplaySubject<ExceptionConsoleState>;
  public onLoadStatus$: ReplaySubject<boolean>;
  public onLoaded$: ReplaySubject<ExceptionConsoleContainer>;
  public container: ExceptionConsoleContainer;
  public currentOrgLevel: OrgLevel;
  public selectedRecords: ExceptionConsoleRecord[];
  public state: ExceptionConsoleState;
  public exactPayCycle: boolean;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;

  private m_exceptionOnlyControlId = 'exceptionOnlyControl';
  private m_resetType: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
  private stateKey: ControlStateKey;

  private currentRange: RangeDates;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ExceptionConsoleApiService,
    private timecardsApiService: TimecardsApiService,
    private columnSettingsStorageService: ColumnSettingsStorageService,
    private stateService: StateManagementService,
    private storageService: ComponentStateStorageService,
    private errorService: ErrorHandlingService) {

    this.onLoadStatus$ = new ReplaySubject(1);
    this.onStateChanged$ = new ReplaySubject(1);
    this.onLoaded$ = new ReplaySubject(1);
    this.onRecordsSelected$ = new ReplaySubject(1);
    this.orgLevelSubscription = this.orgLevel$
      .pipe(filter((o: OrgLevel) => this.validateOrgLevel(o)))
      .subscribe((o: OrgLevel) => {
        this.onOrgLevelChanged(o);
        this.onRecordsSelected([]);
      });

    this.state = new ExceptionConsoleState();
    this.state.createColumns();
    this.state.mapColumns();
    this.state.exceptionOnly = this.getExceptionOnlyStates();
    this.state.columns = this.columnSettingsStorageService.getColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE.toString(), undefined, this.state.columns);

    this.onStateChanged(this.state);
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public onStateChanged(state: ExceptionConsoleState): void {
    this.onStateChanged$.next(state);
    this.columnSettingsStorageService.setColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE.toString(), undefined, this.state.columns);
    this.saveExceptionOnlyStates();
  }

  public onLoadStatusChanged(isLoading: boolean): void {
    this.onLoadStatus$.next(isLoading);
  }

  public loadExceptionConsoleRecords(orgLevelId: number, range: RangeDates, exactPayCycle: boolean): void {
    this.onLoadStatusChanged(true);
    let pc: PayCycle = new PayCycle();
    pc.startDate = range.startDate;
    pc.endDate = range.endDate;
    this.apiService.getExceptionConsoleRecords(orgLevelId, range.startDate, range.endDate, exactPayCycle)
      .then((container: ExceptionConsoleContainer) => {
        this.container = container;
        this.state.setDynamicColumns(this.container.exceptionColumns);
        this.state.exceptionOnly = this.getExceptionOnlyStates();
        this.state.columns = this.columnSettingsStorageService.getColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE.toString(), undefined, this.state.columns);
        this.container.payCycle = pc;
        this.onLoaded(container);
        this.onStateChanged(this.state);
        this.onLoadStatusChanged(false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public approveTimecards(records: ExceptionConsoleRecord[]): void {
    let request = new TimecardApprovalRequest() 
    _.forEach(records, record => {
      let payCycle = _.find(request.payCycles, item=> item.startDate === dateTimeUtils.convertToDtoString(record.payCycle.startDate) && item.endDate === dateTimeUtils.convertToDtoString(record.payCycle.endDate));
      if(!payCycle){
        payCycle = new PayCycleApprovalRequest();
        payCycle.startDate = dateTimeUtils.convertToDtoString(record.payCycle.startDate);
        payCycle.endDate = dateTimeUtils.convertToDtoString(record.payCycle.endDate);
        payCycle.employeeIds = [];
        payCycle.employeeIds.push(record.employee.id);
        request.payCycles.push(payCycle);
      } else {
        payCycle.employeeIds.push(record.employee.id);
      }
    });
    if (request.payCycles.length > 0) {
      this.onLoadStatusChanged(true);
      this.timecardsApiService.approveTimecards(this.currentOrgLevel.id, request)
        .then((result: any) => {
          this.onLoadStatusChanged(false);
        })
        .catch((reason: any) => {
          this.onLoadStatusChanged(false);
        });
    }
  }

  public onLoaded(container: ExceptionConsoleContainer): void {
    this.onLoaded$.next(container);
    this.onRecordsSelected([]);
  }

  public onRecordsSelected(records: ExceptionConsoleRecord[]): void {
    this.selectedRecords = records;
    this.onRecordsSelected$.next(records);
  }

  public navigateToDateRange(range: RangeDates, usePayCycle: boolean): void {
    this.currentRange = range;
    if (this.currentOrgLevel) {
      let nav: ExceptionConsoleNavigationService = new ExceptionConsoleNavigationService(this.router, this.route);
      nav.navigateToExceptionConsoleDates (this.currentOrgLevel.id, range.startDate, range.endDate, usePayCycle);
    }
  }

  public setDateRange(range: RangeDates): void {
    this.currentRange = range;
    if (this.currentOrgLevel) {
      this.loadExceptionConsoleRecords(this.currentOrgLevel.id, this.currentRange, this.exactPayCycle);
    }
  }

  private validateOrgLevel(o: OrgLevel): boolean {
    const orgLevelType: string = _.get(o, 'type');
    const isSameOrgLevel: boolean = _.get(this.currentOrgLevel, 'id') === _.get(o, 'id');

    return !isSameOrgLevel && orgLevelType === OrgLevelType.department;
  }

  private onOrgLevelChanged(o: OrgLevel): void {
    this.currentOrgLevel = o;
    if (this.currentOrgLevel && this.currentRange) {
      this.loadExceptionConsoleRecords(this.currentOrgLevel.id, this.currentRange, this.exactPayCycle);
    }
  }

  private saveExceptionOnlyStates(): void {
    this.storageService.setControlState(this.stateService.componentKey, this.m_exceptionOnlyControlId,
      { value: this.state.exceptionOnly }, this.m_resetType, this.stateKey);
    this.stateService.controlValueChanged(this.m_exceptionOnlyControlId);
  }

  private getExceptionOnlyStates(): boolean {
    let state: IControlState = this.storageService.getControlState('ExceptionConsoleComponent', this.m_exceptionOnlyControlId);
    return (state && state.value) ? state.value : false;
  }

  private extractIdsForModification(records: ExceptionConsoleRecord[]): number[] {
    let filtered: ExceptionConsoleRecord [] = _.filter(records, rec => rec.canModifyOwnTimecard);
    let ids: number[] = _.map(filtered, (record: ExceptionConsoleRecord) => record.employee.id);
    if (filtered.length < records.length) {
      this.errorService.info(new NotificationMessage('Timecard is readonly.',
                            `You are not authorized to approve your own timecard`));
    }
    return ids;
  }
}


<slx-spinner [show]="isLoading">
  <form #gridForm="ngForm" class="content-form">
    <div class="modal-body">

      <slx-benefit-payroll-deduction [groupName]="groupName"
      [canMapPayroll]="canMapPayroll"
      [payrollDedStartDate]="payrollDedStartDate"
      [payrollDedEndDate]="payrollDedEndDate"
      [effectiveDate]="effectiveDate"
      [dedStartDate]="dedStartDate"
      [dedEndDate]="dedEndDate"
      [startDate]="startDate"
      [endDate]="endDate"
      (dedEndDateChange)="OnDeductionEndDateChange($event)"
      (dedStartDateChange)="OnDeductionStartDateChange($event)"
    >
    </slx-benefit-payroll-deduction>

      <div class="toolbar second-toolbar">
        <p class="flex form__ctrl tier-selector">
          <label class="form__label">Select a Benefit Tier</label>
          <span class="flex flex__grow form-dropdown-control">
            <slx-dropdown-list class="slx-wide" [options]="tiers" [ngModel]="selectedTier"
              (ngModelChange)="onChangeTier($event)" name="tiersList" required>
            </slx-dropdown-list>
          </span>
        </p>
        <span class="eligibility-label">
          <span class="eligibility-mark green" *ngIf="model?.plan?.status === 0">Eligible</span>
          <span class="eligibility-mark red" *ngIf="model?.plan?.status === 1">Ineligible</span>
          <span class="eligibility-mark red" *ngIf="model?.plan?.status === 3">Eligibility rule error</span>
        </span>
      </div>

      <div class="grid-holder">

        <span>Select an Option</span>

        <kendo-grid #kendoGrid class="kendo-grid slx-grid-slim-rows" [height]="240" scrollable="'scrollable'"
          [data]="gridState?.view" [sortable]="false" [pageable]="false" [filterable]="false" [groupable]="false"
          [selectable]="selectableSettings" [rowSelected]="isRowSelected"
          (dataStateChange)="gridState.dataStateChange($event)" (selectionChange)="onSelectionChange($event)"
          (stateRestored)="gridState.dataStateChange($event)" (edit)="gridState.editHandler($event)"
          (save)="gridState.saveHandler($event)" (cancel)="gridState.cancelHandler($event)"
          (remove)="gridState.removeHandler($event)">

          <kendo-grid-column title="" [width]="40">
            <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
              <slx-radio-button class="radio-name" [caption]="''" [fieldName]="'selectedCoverageOption'"
                [option]="dataItem" [ngModel]="selectedCoverageOption" [readonly]="true" name="tier{{idx}}">
              </slx-radio-button>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="tierOptionType" title="Type">
            <ng-template kendoGridHeaderTemplate>
              Type
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem.tierOptionType }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="tierOptionCode" title="Code">
            <ng-template kendoGridHeaderTemplate>
              Code
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem.tierOptionCode }}</span>
            </ng-template>
          </kendo-grid-column>


          <kendo-grid-column field="tierOptionCost" title="Cost">
            <ng-template kendoGridHeaderTemplate>
              Cost
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem.tierOptionCost }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="tierOptionEmpContribution" title="Employee Cont.">

            <ng-template kendoGridHeaderTemplate>
              Employee Contribution
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
              <slx-number #tierOptionEmpContributionField="ngModel"
                class="slx-wide slx-input-number"
                [min]="minAmt" [max]="maxAmt"
                [slxMin]="minAmt" [slxMax]="maxAmt"
                [step]="stepcurrency"
                [format]="currencyFormat" [decimals]="2"
                [autoCorrect]="false"
                [(ngModel)]="dataItem.tierOptionEmpContribution"
                (ngModelChange)="onChangeEmpContribution(dataItem)"
                (focus)="onStartEdit(dataItem)"
                name="tierOptionEmpContribution{{idx}}"
              ></slx-number>
              <span *ngIf="tierOptionEmpContributionField.errors" class="slx-error-block pull-left">
                <span *ngIf="tierOptionEmpContributionField.errors.required" errorMessage for="required"></span>
                <span *ngIf="tierOptionEmpContributionField.errors.min || tierOptionEmpContributionField.errors.max" errorMessage>Incorrect value</span>
              </span>
            </ng-template>

          </kendo-grid-column>

        </kendo-grid>
      </div>

      <div class="message-area">
        <span *ngIf="model?.plan?.status === 1" class="warning-message red">You are selecting to enroll the employee in
          a plan they are ineligible for</span>
      </div>
    </div>
    <div class="modal-footer">
      <span id="enroll-button" class="optionRates-info ">

        <button [disabled]="gridForm.invalid || !hasSelected || !hasTierOptRates || hasDedError" type="button"
          (click)="onEnroll()"
            class="btn green"><i class="fas fa-save slx-button__icon" aria-hidden="true"></i>Enroll</button>
            <span class="optionRates__btn-box" *ngIf="(!hasTierOptRates && hasSelected) || showTooltipOnDialogWarning" [slxTooltip]="getOptRatesToolTip()"
            tipPosition="top"></span>
          <div></div>
        </span>

        <button type="button" class="btn red" (click)="onCancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
    </div>
  </form>
</slx-spinner>

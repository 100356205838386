
import {combineLatest} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { ResetPasswordCodes, PasswordResetState, ResetPassword } from '../../models/password-reset';
import { PasswordResetManagementService, AuthenticationService } from '../../services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../core/decorators/index';
import { PasswordValidatorAdapter } from '../../services/password-reset/password-validator-adapter';
import { UserPasswordSettings } from '../../../core/models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-forced-reset-password',
    templateUrl: 'reset-password-form.component.html',
    styleUrls: ['reset-password-form.component.scss'],
    providers: [PasswordResetManagementService]
})
export class ResetPasswordFormComponent implements OnInit {

    @unsubscribe()
    public stateSubscription: Subscription;

    @unsubscribe()
    public routeSubscripion: Subscription;

    public get passwordValidationUnsecure(): string {
        return PasswordValidatorAdapter.passwordValidationUnsecure;
    }

    public get alias(): string {
        return this.m_alias;
    }

    public get username(): string {
        return this.m_username;
    }

    public passwordSettings: UserPasswordSettings;
    public state: PasswordResetState;
    public errorCodes = ResetPasswordCodes;

    public password: string;
    public newPassword: string;
    public confirmNewPassword: string;

    public forgotResetPasswordMode: boolean;
    public forceResetPasswordMode: boolean;

    private m_alias: string;
    private m_username: string;

    constructor(
        public passwordValidator: PasswordValidatorAdapter,
        private route: ActivatedRoute,
        private router: Router,
        private management: PasswordResetManagementService
    ) {
        this.state = new PasswordResetState();
    }

    public ngOnInit(): void {

        this.management.passwordPolicyLoaded$.subscribe((settings: UserPasswordSettings) => {
            this.passwordSettings = settings;
        });

        this.stateSubscription = this.management.stateChange$.subscribe((state: PasswordResetState) => {
            this.state = state;
        });

        this.routeSubscripion = this.route.params.pipe(
            combineLatest(this.route.queryParams))
            .subscribe(([params, queryParams]) => {

                let token = "";
                let username = "";
                let alias = "";
              if (queryParams['id']) {
                let base64token = queryParams['id'];
                let tokenstr = atob(base64token);
                let paramsObj = JSON.parse(tokenstr);
                if (paramsObj['username']) {
                  username = paramsObj['username'];
                }
                if (paramsObj['alias']) {
                  alias = paramsObj['alias'];
                }
                if (paramsObj['token']) {
                  token = paramsObj['token'];
                }

                localStorage.setItem("username", username);
                localStorage.setItem("alias", alias);
                localStorage.setItem("token", token);

                this.router.navigate(
                  [], {
                  queryParams: {
                    id: null,   // Remove base64token query param
                  },
                  queryParamsHandling: 'merge',
                });
              }
              else {
                username = localStorage.getItem("username");
                alias = localStorage.getItem("alias");
                token = localStorage.getItem("token");

                this.m_alias = alias;
                this.m_username = username;

                this.forceResetPasswordMode = !token && !!username;
                this.forgotResetPasswordMode = !!token && !!username;

                this.management.setUserData(token, username, alias);

                this.management.loadPasswordPolicy();
              }
            });

        this.management.init();
    }

    public onResetPasswordClicked(): void {
        localStorage.removeItem("username");
        localStorage.removeItem("alias");
        localStorage.removeItem("token");
        if (this.forgotResetPasswordMode) {
            this.management.forgotResetPassword(this.newPassword);
        } else if (this.forceResetPasswordMode) {
            this.management.resetPasswordByOldPassword(this.newPassword, this.password);
        }
    }

    public hideUnknownErrorMessage(): void {
        this.state.isUnknownError = false;
    }


}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CollapsibleSectionService } from './../../../../components-library/services/collapsible-section.service';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';

import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';

import { ComponentStateStorageService, StateManagementService } from '../../../../common/services/index';
import { DateRangeWithDate, RangeType } from '../../../../components-library/models/index';
import { ControlStateKey, IControlState, StateResetTypes } from '../../../../core/models/index';
import { ChartMenuItem, ConsoleConfig, DisplayDefinition, DisplayType, ScheduleConsoleCycles, ScheduleConsoleEntry, ScheduleConsoleFilterChangedEvent, ScheduleConsoleFilterItem, SoChartMenuItems, SoValuePairWidgetConfig } from '../../models/index';
import { ScheduleConsoleApiService, ScheduleConsoleFiltersService } from '../../services/index';

import { DateRange } from 'moment-range';
import { CollapsibleSectionComponent } from '../../../../components-library/components/index';
import { TOOLBAR_SERVICE } from '../../../../core/services/index';
import { UserApplication } from '../../../../state-model/models';
import { OperationalConsoleStateService } from '../../../services/operational-console/operational-console-state.service';
import { Comparison, ScheduleConsoleDayIndicator, ScheduleConsoleGroupBy, ScheduleConsoleGroupByType } from '../../models/index';
import { ScheduleConsoleChartService, ScheduleConsoleToolbarService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'schedule-console',
  templateUrl: 'schedule-console.component.html',
  styleUrls: ['schedule-console.component.scss'],
  providers: [
    StateManagementService,
    { provide: TOOLBAR_SERVICE, useClass: ScheduleConsoleToolbarService }
  ]
})
export class ScheduleConsoleComponent implements OnInit, OnDestroy {
  @mutableSelect()
  public orgLevel$: Observable<OrgLevel>;
  @mutableSelect('application')
  public application$: Observable<UserApplication>;

  public cycle: DateRange;
  public scheduleConsoleData: ScheduleConsoleCycles;
  public orgLevel: OrgLevel;

  public state: {
    isLoading: boolean;
  };
  public filtersList: ScheduleConsoleFilterItem[];
  public consoleConfig: ConsoleConfig;
  public startOfWeek: string;
  public selectedRangeType: string;
  public selectedDate: Date;
  public groupBy: ScheduleConsoleGroupBy;
  public groupByList: ScheduleConsoleGroupBy[];
  public isPositionData: boolean = false;

  public charts: ChartMenuItem[];
  public shownChart: ChartMenuItem;

  private cycleInterval: number = 7;
  private currentCycle: DateRange;
  private currentRange: DateRangeWithDate;

  @unsubscribe()
  private scheduleConsoleSubscription: Subscription;
  @unsubscribe()
  private loadSubscription: Subscription;
  @unsubscribe()
  private initSubscription: Subscription;
  @unsubscribe()
  private collapseSubscription: Subscription;

  private dateFilterControlId: string = 'DateFilter';
  private configControlKey: string = 'consoleConfig';
  private m_resetType: StateResetTypes = StateResetTypes.SessionEnd;
  private stateKey: ControlStateKey;

  private chartTypeKey: string = 'chartType';
  private groupTypeKey: string = 'groupType';
  private rangeTypeKey: string = 'rangeType';
  private collapseKey: string = 'collapseAll';
  private sectionsKeys: string = 'sectionsKeys';

  private groupContainer: HTMLElement;
  private scrollToValue: number = 0;

  constructor(
    private scheduleConsoleApiService: ScheduleConsoleApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private filtersService: ScheduleConsoleFiltersService,
    private stateService: OperationalConsoleStateService,
    @Inject(TOOLBAR_SERVICE) private consoleToolbarService: ScheduleConsoleToolbarService,
    private collapsibleService: CollapsibleSectionService
  ) {
    this.state = {
      isLoading: true
    };
    this.consoleConfig = new ConsoleConfig(DisplayType.idealScheduleHours, 0, 0);
    this.currentRange = null;
    this.startOfWeek = 'Sunday';
    this.selectedRangeType = 'Week';
    this.charts = SoChartMenuItems;
    this.shownChart = _.head(this.charts);
    this.groupByList = _.map(ScheduleConsoleGroupByType, (value: ScheduleConsoleGroupByType, key: string) => {
      return new ScheduleConsoleGroupBy(key, value);
    });
    this.groupBy = this.groupByList[0];
  }

  public get isGroupedByUnit(): boolean {
    return this.groupBy.name === ScheduleConsoleGroupByType.UNIT;
  }

  public get isGroupedByShiftGroup(): boolean {
    return this.groupBy.name === ScheduleConsoleGroupByType.SHIFT;
  }

  public get isDisabledGrouping(): boolean {
    return this.groupBy.name === ScheduleConsoleGroupByType.NONE;
  }

  public get orgLevelId(): number {
    return this.orgLevel && this.orgLevel.id ? this.orgLevel.id : 0;
  }

  public get isDayRange(): boolean {
    return this.selectedRangeType === 'Day';
  }

  public ngOnInit(): void {
    this.stateService.entries = [];
    this.stateManagement.init('ScheduleConsoleComponent', true);
    this.initSubscription = this.stateManagement.onInit$.subscribe((value: any) => {
      this.onRestored();
    });

    this.filtersService.onApplyConsoleFilters.subscribe((filters: ScheduleConsoleFilterItem[]) => {
      // refresh view?
    });

    this.collapseSubscription = this.collapsibleService.subscribeToToggleSections((isExpand: boolean) => {
      this.saveParam(this.collapseKey, isExpand);
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onRestored(): void {

    this.filtersService.isDisabledGrouping = this.isDisabledGrouping;
    this.filtersService.isGroupedByUnit = this.isGroupedByUnit;
    this.filtersService.isGroupedByShiftGroup = this.isGroupedByShiftGroup;

    this.loadSubscription = this.stateManagement.loadData$
      .subscribe(() => {
        this.loadValues();
      });

    this.scheduleConsoleSubscription = this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        if (
          !orgLevel || !orgLevel.id
          || this.orgLevel && this.orgLevel.id === orgLevel.id
        ) return;

        this.orgLevel = orgLevel;
        this.stateService.orgLevel = orgLevel;

        this.filtersService.isDisabledGrouping = this.isDisabledGrouping;
        this.filtersService.isGroupedByUnit = this.isGroupedByUnit;
        this.filtersService.isGroupedByShiftGroup = this.isGroupedByShiftGroup;
        this.filtersService.consoleFiltersChanged([], false);

        if (this.readyToLoad()) {
          this.stateManagement.loadData();
        } else {
          this.restoreParams();
          this.restoreDateFilter();
          this.restoreConfig();
        }
      });
  }

  public loadValues(): void {
    this.state.isLoading = true;
    this.scheduleConsoleData = null;
    this.filtersList = null;
    this.scheduleConsoleApiService.getScheduleConsoleData(this.orgLevel.id, this.currentRange.startDate, this.currentRange.endDate)
      .then((scheduleConsoleData: ScheduleConsoleCycles) => {
        this.scheduleConsoleData = scheduleConsoleData;
        this.filtersList = this.scheduleConsoleData.filters;

        const lowerLimit: number = this.consoleConfig.lowerLimit || this.scheduleConsoleData.lowerLimit;
        const upperLimit: number = this.consoleConfig.upperLimit || this.scheduleConsoleData.upperLimit;
        this.updateConsoleConfig(null, lowerLimit, upperLimit);

        this.filtersService.isDisabledGrouping = this.isDisabledGrouping;
        this.filtersService.isGroupedByUnit = this.isGroupedByUnit;
        this.filtersService.isGroupedByShiftGroup = this.isGroupedByShiftGroup;
        this.filtersService.consoleData = scheduleConsoleData;

        this.stateManagement.loadedData({});
        this.state.isLoading = false;
        if (scheduleConsoleData && scheduleConsoleData.groupByPosition.length === 0) {
          return this.isPositionData = true;
        } else {
          return this.isPositionData = false;
        }
      })
      .catch(() => {
        this.state.isLoading = false;
      });
  }

  public onConfigChange(config: ConsoleConfig): void {
    this.updateConsoleConfig(config.displayType, config.lowerLimit, config.upperLimit);
    this.saveConfig();
  }

  public onGrouppingChange(groupBy: ScheduleConsoleGroupBy): void {
    if (!_.isEqual(this.groupBy, groupBy)) {
      this.groupBy = groupBy;
      this.filtersService.isGroupedByUnit = this.isGroupedByUnit;
      this.filtersService.isGroupedByShiftGroup = this.isGroupedByShiftGroup;
      this.saveParam(this.groupTypeKey, groupBy.id);
    }
  }

  public onDateRangeChange(range: DateRangeWithDate): void {
    this.currentRange = range;
    this.saveDateFilter(range.selectedDate);
    this.stateService.currentDate = range.selectedDate;
    if (_.isObject(this.orgLevel) && _.isNumber(this.orgLevel.id)) {
      this.stateManagement.loadData();
      this.stateService.loadData();
    }
  }

  public onDateRangeTypeChange(range: RangeType): void {
    this.selectedRangeType = range.name;
    this.saveParam(this.rangeTypeKey, range.name);
  }

  public onShownChartChange(chart: ChartMenuItem): void {
    this.shownChart = chart;
    this.saveParam(this.chartTypeKey, chart.type);
  }

  public getWeek(comparisons: Comparison[]): ScheduleConsoleDayIndicator[] {
    const widgetConfig = new SoValuePairWidgetConfig();

    return ScheduleConsoleChartService.makeWeeklyColorMap(comparisons, this.consoleConfig, widgetConfig);
  }

  public onFilterChange(event: ScheduleConsoleFilterChangedEvent): void {
    if (this.readyToLoad()) {
      this.stateService.consoleFilters = event.filters;

      this.filtersService.isDisabledGrouping = this.isDisabledGrouping;
      this.filtersService.isGroupedByUnit = this.isGroupedByUnit;
      this.filtersService.isGroupedByShiftGroup = this.isGroupedByShiftGroup;
      this.filtersService.consoleFiltersChanged(event.filters, event.isDirectCare);
    }
  }

  public generateDynamicUniqId(items: ScheduleConsoleEntry[]): string {
    let key: string = _.map(items, (item: ScheduleConsoleEntry) => {
      return item.name;
    }).join('_');
    return `${this.orgLevelId}_${key}`;
  }

  public generateStaticUniqId(orgLevel: OrgLevel, isOverview: boolean): string {
    const staticKeyPart: string = isOverview ? '_overview' : '_ungroupped';
    const dynamicKeyPart: string = _.get(orgLevel, 'name', '') + staticKeyPart;

    return `${this.orgLevelId}_${dynamicKeyPart}`;
  }

  public onSectionExpandChanged(section: CollapsibleSectionComponent): void {
    let sections: any = this.restoreParam(this.sectionsKeys, {});
    sections[section.uniqId] = section.expanded;
    this.saveParam(this.sectionsKeys, sections, StateResetTypes.SessionEnd);
  }

  public restoreExpanded(uniqId: string, defValue: boolean = false): boolean {
    let sections: any = this.restoreParam(this.sectionsKeys, {});
    if (sections.hasOwnProperty(uniqId)) {
      return sections[uniqId];
    }
    let collapseAll: boolean = this.restoreParam(this.collapseKey, false);
    return collapseAll || defValue;
  }

  public groupContainerCreated(item: HTMLElement): void {
    this.groupContainer = item;
    this.scrollGroupContainer();
  }

  public scrollToGraphicComparison(item: HTMLElement): void {
    setTimeout(() => {
      let c = item.getBoundingClientRect();
      this.scrollToValue = c.top - 100;
      this.scrollGroupContainer();
    }, 1000);
  }

  private scrollGroupContainer(): void {
    if (this.groupContainer && this.scrollToValue > 0) {
      let gc = this.groupContainer.getBoundingClientRect();
      this.groupContainer.scrollTop = this.scrollToValue - gc.top;
      this.scrollToValue = 0;
      this.groupContainer = null;
    }
  }

  private saveDateFilter(date: Date): void {
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      this.dateFilterControlId,
      { value: { dateOn: date } },
      this.m_resetType,
      this.stateKey
    );
  }

  private restoreDateFilter(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.dateFilterControlId);
    if (state && state.value) {
      if (state.value.dateOn) {
        this.selectedDate = moment(state.value.dateOn).toDate();
      }
    } else {
      const currentDate: Date = new Date();
      this.selectedDate = currentDate;
    }
  }

  private saveConfig(): void {
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      this.configControlKey,
      { value: this.consoleConfig },
      StateResetTypes.None
    );
  }

  private restoreConfig(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.configControlKey);
    const dType: DisplayDefinition = _.get(state, 'value.displayType') || DisplayType.idealSchedulePPD;
    const lLimit: number = +_.get(state, 'value.lowerLimit') || 0;
    const uLimit: number = +_.get(state, 'value.upperLimit') || 0;
    this.updateConsoleConfig(dType, lLimit, uLimit);
  }

  private updateConsoleConfig(dType: DisplayDefinition, lLimit: number, uLimit: number): void {
    const displayType: DisplayDefinition = dType ? dType : this.consoleConfig.displayType;
    const lowerLimit: number = lLimit ? lLimit : this.consoleConfig.lowerLimit;
    const upperLimit: number = uLimit ? uLimit : this.consoleConfig.upperLimit;
    this.consoleConfig = new ConsoleConfig(displayType, lowerLimit, upperLimit);
  }

  private readyToLoad(): boolean {
    return _.isDate(this.selectedDate) &&
      _.isObject(this.orgLevel) && _.isNumber(this.orgLevel.id) &&
      _.isObject(this.currentRange) && _.isDate(this.currentRange.startDate);
  }

  private saveParam(param: string, value: any, resetType: StateResetTypes = StateResetTypes.None): void {
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      param, { value: value },
      resetType
    );
  }

  private restoreParam(param: string, defValue: any): any {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, param);
    if (state && state.value) {
      return state.value;
    } else {
      return defValue;
    }
  }

  private restoreParams(): void {
    this.restoreRangeType();
    this.restoreChartType();
    this.restoreGroupType();
    this.restoreCollapseAll();
  }

  private restoreRangeType(): void {
    this.selectedRangeType = this.restoreParam(this.rangeTypeKey, 'Week');
  }

  private restoreCollapseAll(): void {
    this.collapsibleService.toggleAllSections(this.restoreParam(this.collapseKey, false));
  }

  private restoreChartType(): void {
    let chartType: number = this.restoreParam(this.chartTypeKey, 1);
    this.shownChart = _.find(this.charts, ((chart: ChartMenuItem) => {
      return chart.type === chartType;
    }));
  }

  private restoreGroupType(): void {
    let groupId: string = this.restoreParam(this.groupTypeKey, null);
    if (groupId) {
      this.groupBy = _.find(this.groupByList, ((gb: ScheduleConsoleGroupBy) => {
        return gb.id === groupId;
      }));
    } else {
      this.groupBy = this.groupByList[0];
    }
    this.filtersService.isGroupedByUnit = this.isGroupedByUnit;
    this.filtersService.isGroupedByShiftGroup = this.isGroupedByShiftGroup;
  }

}

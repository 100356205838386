import { Injectable } from '@angular/core';
import { ReplaySubject ,  Subscription ,  Observable ,  Subject } from 'rxjs';
import * as moment from 'moment';

import { IndSchEmployeeSchedulesRequest } from '../../models/index';
import { Assert } from '../../../framework/index';

@Injectable()
export class IndividualScheduleEmpManagementService {
  public onUILock$: ReplaySubject<boolean>;
  public onRequestChanged$: ReplaySubject<IndSchEmployeeSchedulesRequest>;
  public selectedRequest: IndSchEmployeeSchedulesRequest;
  public onEmployeeUpdated$: Subject<any>;
  public isDirty: boolean;
  public cellClicked$: ReplaySubject<string>;

  constructor() {
    this.onRequestChanged$ = new ReplaySubject(1);
    this.onUILock$ = new ReplaySubject(1);
    this.onEmployeeUpdated$ = new Subject();
    this.cellClicked$ = new ReplaySubject(1);
  }

  public onUILock(isLock: boolean): void {
    this.onUILock$.next(isLock);
  }

  public onEmployeeRequest(req: IndSchEmployeeSchedulesRequest): void {
    this.selectedRequest = req;
    this.onRequestChanged$.next(req);
  }

  public employeeUpdated(): void {
    this.onEmployeeUpdated$.next(0);
  }

  public subscribeToCellClicked(callback: (cellClicked: string) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.cellClicked$.subscribe(callback);
  }
}

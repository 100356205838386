
import {first, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable ,  Subject ,  ReplaySubject } from 'rxjs';
import { OrgLevelFlat } from '../../models/index';
import { OrgLevel, OrgLevelLocation, OrgLevelType, OrgLevelTypeDefinition } from '../../../state-model/models/index';
import { Assert } from '../../../framework/index';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';

@Injectable()
export class OrgLevelWatchService {

  /**
  * @deprecated since version 6.1 please use ApplicationStateBusService.orgLevelsLoaded$
  */
  public orgLevelTreeLoaded$: ReplaySubject<boolean>;

  private refreshOrgLevelTree: Subject<any>;
  private orgLevelTreeActualized$: Subject<any>;
  private currentOrgLevel: OrgLevel;
  private orgLevelTree: OrgLevel[];
  private flatList: OrgLevelFlat[];
  private defaultOrgLevel: OrgLevel;

  constructor(private appStateBusService: ApplicationStateBusService) {
    this.orgLevelTreeLoaded$ = new ReplaySubject<boolean>(1);
    this.refreshOrgLevelTree = new Subject<any>();
    this.orgLevelTreeActualized$ = new Subject<any>();
  }

  public reloadOrgTree(): void {
    this.appStateBusService.reloadOrgLevels();
  }

  public getTopLevel(): OrgLevel {
    if (!this.orgLevelTree) return null;
    return this.orgLevelTree[0];
  }

  public setOrgLevelTree(orgLevelTree: OrgLevel[]): void {
    this.orgLevelTree = orgLevelTree;
    this.defaultOrgLevel = _.first(orgLevelTree);
    this.flatList = [];
    this.flattenOrgLevel(null, this.orgLevelTree);
    this.orgLevelTreeLoaded$.next(true);
    this.orgLevelTreeActualized$.next(null);
  }

  public resetOrgLevelTree(): void {
    this.orgLevelTree = null;
    this.defaultOrgLevel = null;
    this.flatList = [];
  }

  public hasOrgLevelTreeLoaded(): boolean {
    return _.size(this.orgLevelTree) > 0;
  }

  public getFlatList(): OrgLevelFlat[] {
    return this.flatList;
  }

  public getOrgLevelPath(orgLevelId: number): OrgLevel[] {
    if (!this.orgLevelTree) {
      return [];
    }
    return this.exploreOrgLevelPath(this.orgLevelTree, orgLevelId);
  }

  public selectOrgLevel(orgLevel?: OrgLevel): void {
    if (!orgLevel) return;
    this.onOrgLevelSelected(orgLevel);
  }

  public getCurrentOrgLevel(): OrgLevel {
    return this.currentOrgLevel;
  }

  public resetOrgLevel(): void {
    this.currentOrgLevel = null;
  }

  public getLocation(orgLevel: OrgLevel): Promise<OrgLevelLocation> {
    if (!orgLevel || orgLevel.type === OrgLevelType.corporation) {
      return Promise.resolve(null);
    }
    if (orgLevel.type === OrgLevelType.department && !orgLevel.location) {
      this.getOrgLevelByIdSafe(orgLevel.parentId)
        .then((parentOrgLevel: OrgLevel) => {
          return this.getLocation(parentOrgLevel);
        });
    }
    return Promise.resolve(orgLevel.location);
  }

  public async getOrgLevelByIdSafeAsync(id: number): Promise<OrgLevel> {
    return await this.getOrgLevelByIdSafe(id);
  }

  public getOrgLevelByIdSafe(id: number): Promise<OrgLevel> {
    if (this.orgLevelTree) {
      const orgLevel = this.getOrgLevelById(id);
      return Promise.resolve(orgLevel);
    }
    return this.orgLevelTreeActualized$.pipe(map((data: OrgLevel[]) => this.getOrgLevelById(id)),first(),).toPromise();
  }

  public getOrgLevelByIdOrDeafault(id: number): Promise<OrgLevel> {
    return this.getOrgLevelByIdSafe(id)
      .then((value: OrgLevel) => {
        if (!value) {
          return this.defaultOrgLevel;
        }
        return value;
      });
  }
  /**
  * @deprecated since version 6.1 please use getOrgLevelByIdSafe
  */
  public getOrgLevelById(orgLevelId: number): OrgLevel {
    let root: OrgLevelFlat = _.find(this.flatList, (o: OrgLevelFlat) => o.orgLevel.id === orgLevelId);
    return root ? root.orgLevel : null;
  }

  public getOrgLevelByRelatedItemId(relatedId: number, orglevelType: OrgLevelTypeDefinition): OrgLevel {
    let root: OrgLevelFlat = _.find(this.flatList, (o: OrgLevelFlat) =>
      o.orgLevel.relatedItemId === relatedId && o.orgLevel.type === orglevelType);
    return root ? root.orgLevel : null;
  }

  public getFlatChildOrglevels(orgLevelId: number): OrgLevel[] {
    let root: OrgLevelFlat = _.find(this.flatList, (o: OrgLevelFlat) => o.orgLevel.id === orgLevelId);
    let acc: OrgLevel[] = [];
    if (root) {
      this.reduceChilds(acc, root.orgLevel);
    }
    return acc;
  }

  private exploreOrgLevelPath(childs: OrgLevel[], orgLevelId: number): OrgLevel[] {
    let result: OrgLevel[] = [];
    _.forEach(childs, (orgLevel: OrgLevel) => {
      if (orgLevel.id === orgLevelId) {
        result.push(orgLevel);
        return;
      } else {
        let childResult: OrgLevel[] = this.exploreOrgLevelPath(orgLevel.childs, orgLevelId);
        if (childResult.length > 0) {
          result = childResult;
          result.unshift(orgLevel);
          return;
        }
      }
    });
    return result;
  }

  private onOrgLevelSelected(orgLevel: OrgLevel): void {
    Assert.isNotNull(orgLevel, 'orgLevel');
    this.currentOrgLevel = orgLevel;
  }

  private flattenOrgLevel(parent: OrgLevel, childs: OrgLevel[]): void {
    _.forEach(childs, (orgLevel: OrgLevel) => {
      let flat: OrgLevelFlat = new OrgLevelFlat();
      flat.orgLevel = orgLevel;
      flat.parentOrgLevel = parent;
      this.flatList.push(flat);
      if (orgLevel.childs && orgLevel.childs.length > 0) {
        this.flattenOrgLevel(orgLevel, orgLevel.childs);
      }
    });
  }
  private reduceChilds(acc: OrgLevel[], orgLevel: OrgLevel): void {
    _.forEach(orgLevel.childs, (o: OrgLevel) => {
      acc.push(o);
      this.reduceChilds(acc, o);
    });
  }
}


import {filter} from 'rxjs/operators';
import { mutableSelect } from './../../../../core/decorators/redux-decorators';
import { OrgLevel } from '../../../../state-model/models/index';
import { Observable ,  Subscription } from 'rxjs';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { OpenShiftManagementApiService } from './../../../services/open-shift-management/open-shift-management-api.service';
import { EditOpenShiftCountComponent } from './../edit-open-shift-count/edit-open-shift-count.component';
import { OPEN_SHIFT_DETAILS_TOKEN } from './../../../../core/models/tokens';
import { DialogOptions } from './../../../../common/models/dialog-options';
import { OpenShiftManagementManagementService } from './../../../services/open-shift-management/open-shift-management-management.service';
import { OpenShiftDetails } from './../../../models/open-shift-management/open-shift-details';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
import { OpenShiftSummary } from './../../../models/open-shift-management/open-shift-summary';
import { KendoGridStateHelper } from './../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import * as _ from 'lodash';
import { EmployeeMessage } from './../../../models/open-shift-management/employee-message';

import { Component, OnInit, OnDestroy, Input, Provider } from '@angular/core';
import { process } from '@progress/kendo-data-query';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-shift-summary-grid',
  templateUrl: 'daily-shift-summary-grid.component.html',
  styleUrls: ['daily-shift-summary-grid.component.scss'],
})
export class DailyShiftSummaryGridComponent implements OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public gridState: KendoGridStateHelper<OpenShiftDetails>;
  @Input()
  public canSendSMS: boolean;
  @Input()
  public canEditOpenShiftCount: boolean;
  @Input()
  public pageSize: number;
  @Input()
  public set summary(value: OpenShiftSummary) {
    this.m_summary = value;
    this.refreshGrid();
  }

  public get summary(): OpenShiftSummary {
    return this.m_summary;
  }

  public selectedOrgLevel: OrgLevel;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private onShowFilledShiftsChanged: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  private m_summary: OpenShiftSummary;
  private showFilledShifts: boolean;

  public partialShifts: OpenShiftDetails[];

  constructor(private managementService: OpenShiftManagementManagementService,
    private openShiftManagementApiService: OpenShiftManagementApiService,
    private modalService: ModalService) {
    this.gridState = new KendoGridStateHelper();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.group = [];

    this.pageSize = 100;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: any): void => {
      this.refreshGrid();
    });

    this.onShowFilledShiftsChanged = this.managementService.onShowFilledShiftsChanged$.subscribe((value: boolean): void => {
        this.showFilledShifts = value;
        this.refreshGrid();
    });

    this.orgLevelSubscription = this.orgLevel$.pipe(
    filter((o: OrgLevel) => !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id))
    .subscribe((o: OrgLevel) => {
      this.selectedOrgLevel = o;
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onEditOpenShiftsClick(groupDetails: OpenShiftDetails, event: MouseEvent): any {
    let shiftCount: number = groupDetails.openShiftCount;
    let options: DialogOptions = new DialogOptions();
    options.height = 280;
    options.width = 320;
    let resolvedProvidersConf: Provider[] = [
      { provide: DialogOptions, useValue: options },
      { provide: OPEN_SHIFT_DETAILS_TOKEN, useValue: groupDetails },
    ];
    let modal: EditOpenShiftCountComponent = this.modalService.globalAnchor.openDialog(
      EditOpenShiftCountComponent,
      'Adjust open shift count',
      options,
      resolvedProvidersConf, (result: boolean, uniqueId?: string) => {
        if (result) {
          this.changeOpenShiftCount(groupDetails, modal.adjustedShiftCount);
        }
      });
  }

  public removeItem(item : EmployeeMessage,groupDetails: OpenShiftDetails){
    this.summary.details.filter(x => x === groupDetails).forEach(ele =>{
      ele.messages = ele.messages.filter(y=> y!== item);
    })
    this.refreshGrid();
  }
  private changeOpenShiftCount(groupDetails: OpenShiftDetails, value: number): any {
    this.openShiftManagementApiService.setOpenShiftScheduleCycleSummary(
      this.selectedOrgLevel.id,
      groupDetails.dateOn,
      { positionId: groupDetails.position.id, shiftId: groupDetails.shift.id, unitId: groupDetails.unit.id, originalShiftCount: groupDetails.idealShiftCount, newShiftCount: value })
      .then((result: any) => {
        let newOpen: number = value - groupDetails.scheduledShiftCount;
        newOpen = newOpen > 0 ? newOpen : 0;
        let shiftCountDiff: number = groupDetails.openShiftCount - newOpen;
        groupDetails.openShiftCount = newOpen;
        groupDetails.calculatedOpenShiftCount = newOpen;
        groupDetails.adjustedShiftCount = value;
        this.managementService.onOpenShiftCountChanged({ dateOn: groupDetails.dateOn, shiftCountDiff: shiftCountDiff });
        if(groupDetails.hasPartialShift) {
          this.managementService.onOpenShiftCountChangedByPartialShift({ dateOn: groupDetails.dateOn, shiftCountDiff: shiftCountDiff });
        }
      });
  }

  private refreshGrid(): void {
     if (!this.summary) {
      this.gridState.view = null;
      return;
    }
    let details: OpenShiftDetails[] = this.summary.details.filter(o => o.parentShiftId == -1);    //get only parent shifts
    //set partialShifts for the parent
    this.partialShifts = this.summary.details.filter(o => o.parentShiftId > 0); 
    _.forEach(details, (shiftInDetails: OpenShiftDetails) => {        //inlcude parent if parent is requested
      shiftInDetails.totalShiftRequests = shiftInDetails.requestedPartialShiftCount + shiftInDetails.newMessageCount + shiftInDetails.partnerShiftCount
      shiftInDetails.partialShifts = this.getPartialShifts(shiftInDetails);
      if(shiftInDetails.partialShifts && shiftInDetails.partialShifts.length>0) {
        //if(shiftInDetails.messages && shiftInDetails.messages.length>0) {
          shiftInDetails.partialShifts.push(shiftInDetails);
        //}
      }
    })
  
    if (!this.showFilledShifts) {
      details = _.filter(details, (osd: OpenShiftDetails) => { return osd.openShiftCount > 0 || osd.calculatedOpenShiftCount > 0; });
    }

    this.gridState.state.take = this.pageSize;
    this.gridState.view = process(details, this.gridState.state);
  }

  private getPartialShifts(openShift: OpenShiftDetails): OpenShiftDetails[] {
    //let partialShiftRequested = this.partialShifts.filter(p => p.parentShiftId === openShift.shift.id && p.unit.id === openShift.unit.id && p.messages.length>0);
    let partialShiftRequested = this.partialShifts.filter(p => p.parentShiftId === openShift.shift.id && p.unit.id === openShift.unit.id && p.position.id === openShift.position.id);
    return partialShiftRequested;
  }
}

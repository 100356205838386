import { LicenseType } from './../../../organization/models/lookup/license-type';

import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

import { Subscription } from 'rxjs';
import { PopoverContentComponent, ChangeManagementService } from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';
import { NgForm } from '@angular/forms';
import { LicenseTypesManagementService } from '../../services/license-types/license-types-management.service';

@Component({
  moduleId: module.id,
  selector: 'slx-license-types',
  templateUrl: 'license-types.component.html',
  styleUrls: ['license-types.component.scss'],
  providers: [LicenseTypesManagementService]
})
export class LicenseTypesComponent implements OnInit, OnDestroy {

  public editedRowIndex: number;
  public editedEntry: LicenseType;
  public isAdding: boolean;

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;

  @ViewChild('form', { static: true })
  private mainForm: NgForm;

  @unsubscribe()
  private mainFormSubscription: Subscription;

  private deletingEntryId: number;
  constructor(
    public manageService: LicenseTypesManagementService,
    private changeService: ChangeManagementService,
  ) {
  }

  public ngOnInit(): void {
    this.manageService.init();
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public addHandler(): void {
    this.closeEditor();

    let type: LicenseType = new LicenseType();
    type.licenseTypeID = 0;
    this.isAdding = true;
    this.editedEntry = type;
    this.grid.addRow(this.editedEntry);
    this.changeService.changeNotify();
  }

  public editHandler(event: { dataItem: LicenseType, rowIndex: number }): void {
    this.closeEditor();

    this.isAdding = false;
    this.editedEntry = Object.assign({}, event.dataItem);
    this.editedRowIndex = event.rowIndex;
    this.grid.editRow(this.editedRowIndex);

    this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
      if (this.mainForm.dirty) {
        this.changeService.changeNotify();
      }
    });
  }

  public saveHandler(): void {
    if (this.isAdding) {
      this.addType(this.editedEntry);
    } else {
      this.saveType(this.editedEntry);
    }
    this.closeEditor();
  }

  public removeHandler(event: { dataItem: LicenseType, rowIndex: number }): void {
    this.deletingEntryId = event.dataItem.licenseTypeID;
  }

  public cancelHandler(): void {
    this.closeEditor();
    this.unsubscribeMainForm();
    this.changeService.clearChanges();
  }

  public sortChangeHandler(sort: SortDescriptor[]): void {
    this.manageService.setSort(sort);
  }

  public closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);
    this.isAdding = false;
    this.editedEntry = undefined;
    this.editedRowIndex = undefined;
  }

  public onPopoverAction(acceptPopover: PopoverContentComponent, isDelete: boolean): void {
    if (isDelete) {
        this.manageService.delete(this.deletingEntryId);
    }
    this.deletingEntryId = 0;
    acceptPopover.hide();
  }


  private addType(type: LicenseType): void {
    this.manageService.add(type).then(() => {
      this.handleApiCallCompletion();
    });
  }

  private saveType(type: LicenseType): void {
    this.manageService.save(type).then(() => {
      this.handleApiCallCompletion();
    });
  }

  private handleApiCallCompletion(): void {
    this.unsubscribeMainForm();
    this.changeService.clearChanges();
  }

  private unsubscribeMainForm(): void {
    if (this.mainFormSubscription) {
      this.mainFormSubscription.unsubscribe();
    }
  }
}

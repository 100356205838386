<form novalidate #resetPasswordForm="ngForm" *ngIf="!state.hasSuccedeed && !state.hasFailed">

    <div class="content-holder">

        <div class="title" *ngIf="forgotResetPasswordMode">
            <span>Password is required and must comply with the requirements</span>
        </div>

        <div class="user-alias" *ngIf="username && alias">{{ username }}@{{ alias }}</div>
        <div class="user-alias" *ngIf="username && !alias">{{ username }}</div>

        <div class="message-area alert alert-danger" *ngIf="forceResetPasswordMode && passwordSettings">

            <!-- output human readable password validation rules here -->

            <span>Password should match following rules:</span>
            <ul>
                <li>
                    <span>Minimum length {{passwordSettings.userPasswordComplexity.pwdLength}} symbols</span>
                </li>
                <li>
                    <span>Minimum {{passwordSettings.userPasswordComplexity.capitalCount}} capital letter(s)</span>
                </li>
                <li>
                    <span>Minimum {{passwordSettings.userPasswordComplexity.specialCount}} special symbol(s)</span>
                </li>
            </ul>

            <span *ngIf="passwordSettings.userPasswordLifetime && passwordSettings.userPasswordLifetime.enabled">Password will expire in {{passwordSettings.userPasswordLifetime.daysCount}} day(s)</span>

        </div>

        <div class="fields-set">

            <div *ngIf="forceResetPasswordMode" class="login-control-group old-password">

                <div class="login-text">
                    *Current Password
                </div>

                <slx-input-decorator className="slx-no-label slx-small-font">
                    <input slx-input placeholder="Password" id="password" name="password" [(ngModel)]="password" #passwordControl="ngModel" required
                        type="password" class="login-control" tabindex="1" [disabled]="state.isLoading">
                    <span errorMessage for="required"></span>
                </slx-input-decorator>

            </div>

            <div class="login-control-group">

                <div class="login-text">
                    *New Password
                </div>

                <slx-input-decorator className="slx-no-label slx-small-font">
                    <input slx-input placeholder="New Password" id="newPassword" name="newPassword" #newPasswordControl="ngModel" [(ngModel)]="newPassword"
                        [server]="{ validationName: passwordValidationUnsecure, 
                                    parameters: [newPassword, alias], 
                                    validationAdapter: passwordValidator, 
                                    componentName: 'newPassword', 
                                    form: resetPasswordForm }"
                        filled required type="password" class="login-control" tabindex="2" [disabled]="state.isLoading">
                    <span errorMessage for="required">Password is required</span>
                    <span errorMessage for="password">Password must comply with the requirements</span>
                    <span errorMessage [for]="passwordValidationUnsecure">Password must comply with the requirements</span>
                </slx-input-decorator>

            </div>

            <div class="login-control-group">

                <div class="login-text">
                    *Confirm New Password
                </div>

                <slx-input-decorator className="slx-no-label slx-small-font">
                    <input slx-input placeholder="Confirm New Password" id="confirmNewPassword" name="confirmNewPassword" #confirmNewPasswordControl="ngModel"
                        [(ngModel)]="confirmNewPassword" [slxEqual]="newPassword" required type="password" class="login-control"
                        tabindex="3" [disabled]="state.isLoading">
                    <span errorMessage for="required">Confirm New Password is required</span>
                    <span errorMessage for="equal">Confirm New Password must match the New Password</span>
                </slx-input-decorator>


            </div>

        </div>

        <div class="actions-bar">

            <button type="button" class="cancel-btn theme-button-cancel margin-r" routerLink="/login" tabindex="4" [disabled]="state.isLoading">
                Cancel
            </button>

            <div class="bar-spacer"></div>

            <button type="submit" class="reset-btn theme-button-apply reset-password-button" [disabled]="!resetPasswordForm.form.valid || state.isLoading"
                (click)="onResetPasswordClicked()" tabindex="5">
                <i class="fal fa-spinner-third fa-spin" aria-hidden="true" *ngIf="state.isLoading"></i>
                Reset Password
            </button>

        </div>

        <div class="alerts-area">
            <div class="alert alert-danger alert-dismissible" *ngIf="state.isUnknownError">
                <button class="close" (click)="hideUnknownErrorMessage()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span>Unexpected error, please try again or contact support.</span>
            </div>
        </div>

    </div>

</form>

<div class="content-holder">

    <div class="alerts-area" *ngIf="state.hasSuccedeed && !state.hasFailed">
        <div class="success">
            <i class="mark fa fa-check-circle" aria-hidden="true"></i>
            <p>Your password has been successfully reset!</p>
        </div>

    </div>

    <div class="alerts-area" *ngIf="state.hasFailed && !state.hasSuccedeed">
        <div class="title" [ngSwitch]="state.error">
            <em *ngSwitchCase="errorCodes.InvalidToken">
                We're sorry, we couldn't find the reset password request. Please try again.
            </em>
            <em *ngSwitchCase="errorCodes.TokenExpired">
                We're sorry, the reset password request has expired. Please try again.
            </em>
            <em *ngSwitchCase="errorCodes.TokenAlreadyUsed">
                We're sorry, the reset password request was already used. Please try again.
            </em>
            <em *ngSwitchDefault>We're sorry, your password could not be reset. Please contact your system administrator.</em>
        </div>

    </div>

    <div class="actions-bar" *ngIf="state.hasFailed || state.hasSuccedeed">
        <button class="theme-button-apply" routerLink="/login" tabindex="1">
            Login
        </button>
    </div>

</div>

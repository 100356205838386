
import {combineLatest} from 'rxjs/operators';
import { ActivatedRoute, Router, Params } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';


import { StateResetTypes } from '../../../../core/models/settings/reset-types';
import { ControlStateKey, IControlState } from '../../../../core/models/settings/component-states';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { ApprovalRequiredCodeRecord } from './../../models/approval-required-codes/approval-required-code-record.model';
import { genericListConfig } from '../../../../organization/generic-list.config';
import { GenericListRequest, GenericList, GenericRow, GenericListDefinition } from './../../../../organization/models/generic-list/index';
import { appConfig, IApplicationConfig } from './../../../../app.config';
import { UserApplication, OrgLevel } from '../../../../state-model/models/index';
import { mutableSelect, unsubscribe } from './../../../../core/decorators/index';
import { GenericListManagementService } from '../../../../organization/services/generic-list/generic-list-management.service';
import { ApprovalRequiredCodesApiService } from './../../services/approval-required-codes/approval-required-codes-api.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IRangeDates } from '../../../../common/models/range-dates';
import { StateManagementService } from './../../../../common/services/state-management/state-management.service';
import { ColumnManagementService } from '../../../../common';

@Component({
  moduleId: module.id,
  selector: 'slx-approval-required-codes',
  templateUrl: 'approval-required-codes.component.html',
  styleUrls: ['approval-required-codes.component.scss'],
  providers: [GenericListManagementService, StateManagementService, ColumnManagementService]
})
export class ApprovalRequiredCodesComponent implements OnInit, OnDestroy {
  public startDate: Date;
  public endDate: Date;

  public state: {
    isLoading: boolean;
    isActiveOnly: boolean;
  };

  @mutableSelect('application')
  public application: Observable<UserApplication>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  public appConfig: IApplicationConfig;

  @unsubscribe()
  private definitionsLoadedSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private valuesLoadedSubscription: Subscription;
  @unsubscribe()
  private loadSubscription: Subscription;

  private currentOrgLevel: OrgLevel;
  private currentApplication: UserApplication;

  private selectedToApprove: ApprovalRequiredCodeRecord[];
  private selectedToUnapprove: ApprovalRequiredCodeRecord[];

  private stateKey: ControlStateKey;
  private readonly m_dateFiltersControlId: string = 'DateFiltersNgx';
  private readonly m_dateFiltersResetType: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;

  constructor(
    private genericListManagementService: GenericListManagementService,
    private apiService: ApprovalRequiredCodesApiService,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private activatedRoute: ActivatedRoute, private router: Router
  ) {
    this.state = {
      isLoading: false,
      isActiveOnly: false
    };
    this.startDate = moment().startOf('year').toDate();
    this.endDate = moment().endOf('year').toDate();
  }

  public get canApprove(): boolean {
    return this.selectedToApprove && this.selectedToApprove.length > 0 &&
      _.every(this.selectedToApprove, fields => !fields.isLocked);
  }

  public get canUnapprove(): boolean {
    return this.selectedToUnapprove && this.selectedToUnapprove.length > 0 &&
    _.every(this.selectedToUnapprove, fields => !fields.isLocked);
  }

  public onSelectionChanged(items: GenericRow[]): void {
    this.selectedToApprove = [];
    this.selectedToUnapprove = [];
    _.forEach(items, (item: GenericRow) => {
      let rec: ApprovalRequiredCodeRecord = new ApprovalRequiredCodeRecord();
      rec.payCodeId = item.fields.ApprovalExceptionId.value.value;
      rec.employeeId = item.fields.EmpId.value.value;
      rec.date = item.fields.ApprovalPunchDate.value.value;
      rec.isLocked = item.fields.IsPayCycleLocked.value.value === 'True' || item.fields.IsTimecardLocked.value.value === 'True';
      if (item.fields.IsCodeApproved.value.value === 'Yes') {
        this.selectedToUnapprove.push(rec);
      } else {
        this.selectedToApprove.push(rec);
      }
    });
  }

  public approve(): void {
    this.state.isLoading = true;
    this.apiService.approve(this.selectedToApprove).then((result: any) => {
      this.state.isLoading = false;
      this.loadValues();
    }).catch((error: any) => {
      this.state.isLoading = false;
    });
  }

  public unapprove(): void {
    this.state.isLoading = true;
    this.apiService.unapprove(this.selectedToUnapprove).then((result: any) => {
      this.state.isLoading = false;
      this.loadValues();
    }).catch((error: any) => {
      this.state.isLoading = false;
    });
  }

  public ngOnInit(): void {
    this.stateManagement.init('ApprovalRequiredCodesComponent', false);
    this.appConfig = appConfig;
    this.orgLevelSubscription = this.orgLevel$.pipe(
      combineLatest(this.application, this.activatedRoute.queryParams))
      .subscribe((value: [OrgLevel, UserApplication, Params]) => {
        let [orgLevel, application, queryParams]: [OrgLevel, UserApplication, Params] = value;
        if (!orgLevel || !orgLevel.id || !application.id) {
          return;
        }
        this.currentOrgLevel = orgLevel;
        this.currentApplication = application;
        let startDate = queryParams['startDate'];
        let endDate = queryParams['endDate'];
        if (startDate && endDate) {
          this.startDate = moment(startDate, appConfig.linkDateFormat).toDate();
          this.endDate = moment(endDate, appConfig.linkDateFormat).toDate();
        } else {
          this.restoreFilters();
        }
        this.loadDefinitions();
      });

    this.loadSubscription = this.stateManagement.loadData$.subscribe(() => {
      this.loadValues();
    });

    this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe((defs: GenericListDefinition) => {
      this.stateManagement.onComponentActiveStateChanged({});
    });
    this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe((list: GenericList) => {
      this.state.isLoading = false;
      this.stateManagement.loadedData({});
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.loadValues();
    this.saveFilters();
  }

  private loadDefinitions(): void {
    this.state.isLoading = true;
    this.genericListManagementService.loadListDefinition(this.currentApplication.id, this.currentOrgLevel.id,
      genericListConfig.approvalRequiredCodes);
  }

  private loadValues(): void {
    this.state.isLoading = true;
    this.selectedToApprove = [];
    this.selectedToUnapprove = [];
    let req: GenericListRequest = new GenericListRequest();
    req.startDate = this.startDate;
    req.endDate = this.endDate;
    this.genericListManagementService.loadListValues(this.currentOrgLevel.id, req);
  }

  private saveFilters(): void {
    this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId,
      {
        value: { startDate: this.startDate, endDate: this.endDate }
      },
      this.m_dateFiltersResetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
  }

  private restoreFilters(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
    if (state && state.value) {
      if (state.value.startDate) this.startDate = moment(state.value.startDate).toDate();
      if (state.value.endDate) this.endDate = moment(state.value.endDate).toDate();
    }
  }
}

import { Observable ,  Subscription } from 'rxjs';


export function unsubscribe(): PropertyDecorator {
  return function decorate(target: any, key: string | symbol): void {
    let ngOnDestroyFunction: Function = target['ngOnDestroy'];
    let ngOnDestroy: Function = function (): void {
      if (this) {
        let subscription: Subscription = this[key];
        if (subscription) {
          if (typeof subscription.unsubscribe === 'function') {
            subscription.unsubscribe();
          } else {
            console.info(`Property ${key.toString()} is not a subscription`);
          }
        }
        if (ngOnDestroyFunction) {
          ngOnDestroyFunction.call(this);
        }
      }
    };
    target['ngOnDestroy'] = ngOnDestroy;
  };
}

import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { AppServerConfig } from '../../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../../app-settings/services/app-settings-manage.service';

import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
@Component({
    moduleId: module.id,
    selector: 'slx-benefit-payroll-deduction',
    templateUrl: './benefit-payroll-deduction.component.html',
    styleUrls: ['./benefit-payroll-deduction.component.scss'],
})
export class BenefitPayrollDeductionComponent implements OnInit,OnDestroy {
    @Input()
    public groupName: string;
    @Input()
    public effectiveDate: Date;
    @Input()
    public payrollDedStartDate: Date;
    @Input()
    public payrollDedEndDate: Date;
    @Input()
    public dedStartDate: Date;
    @Input()
    public dedEndDate: Date;
    @Input()
    public endDate: Date;
    @Input()
    public startDate: Date;
    @Input()
    public canMapPayroll: boolean;
    @Output()
    public dedEndDateChange: EventEmitter<Date>;
    @Output()
    public dedStartDateChange: EventEmitter<Date>;
    @ViewChild('form', {static: true}) 
    public ngForm: NgForm;
    public enableBenefitDeduction:boolean = false;
    @unsubscribeAll()
    private subscriptions: StringMap<Subscription> = {};

    public appConfig: IApplicationConfig;
    public maxDedDate: Date = new Date(appConfig.maxCorrectDate);
    public minDedDate: Date = new Date(appConfig.minCorrectDate);
    public isDedBeforeBeneStartDt: boolean = false;
    public isDedAfterBeneEndDt: boolean = false;
    public isFormValid: boolean = true;
    constructor(
        private man: BenefitsEnrollmentSectionManagementService, 
        private appSettingsManageService: AppSettingsManageService
        ) {
        this.appConfig = appConfig;
        this.dedEndDateChange = new EventEmitter<Date>();
        this.dedStartDateChange = new EventEmitter<Date>();
    }

    public ngOnInit() {
        this.payrollDedStartDate = this.effectiveDate;
        this.payrollDedEndDate = moment(_.isNull(this.payrollDedEndDate) ? this.payrollDedEndDate : this.endDate).startOf('day').toDate();
        this.isDedBeforeBeneStartDt = _.lt(this.payrollDedStartDate, this.startDate);
        this.isDedAfterBeneEndDt = _.gt(this.payrollDedEndDate, this.endDate);
        this.minDedDate = _.isNull(this.dedStartDate) ? this.minDedDate : this.dedStartDate;
        this.maxDedDate = _.isNull(this.dedEndDate) ? this.maxDedDate : this.dedEndDate;

        if (_.gt(this.payrollDedEndDate, this.maxDedDate))
        {
            this.payrollDedEndDate = this.maxDedDate
        }
        this.getSettings();

        this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(() => {
            this.isFormValid = this.ngForm.valid;
            this.man.changePayrollDeductionDate(!this.isFormValid);
        });

    }
    public onChangeStartDate(): void {
        this.isDedBeforeBeneStartDt = _.lt(this.payrollDedStartDate, this.startDate);
        this.dedStartDateChange.emit(this.payrollDedStartDate);
    }
    public onChangeDedEndDate(): void {
        this.isDedAfterBeneEndDt = _.gt(this.payrollDedEndDate, this.endDate);
        this.dedEndDateChange.emit(this.payrollDedEndDate);
    }

    private async getSettings(): Promise<void> {
        const config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
        this.enableBenefitDeduction = config.isBenefitDeductionEnabled;
      }
      
    ngOnDestroy(): void {
        _.forEach(this.subscriptions, (s: Subscription) => {
          if (s && s.unsubscribe) {
            s.unsubscribe();
          }
        });
        this.subscriptions = {};
      }

}

<kendo-grid #kendoGrid
  class="slx-full-height slx-blue-grid slx-white-cells"
  scrollable="'scrollable'"
  [data]="gridState?.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  (dataStateChange)="gridState.dataStateChange($event)"
  (stateRestored)="gridState.dataStateChange($event)"

  [slxKendoGridState]="gridState.gridDefinition"
  [slxKendoGridColumnsGroup]="columnsGroupName">


  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Employee Name"
    field="employee.name"
    [locked]="isLockedColumn"
    [filterable]="false"
    [sortable]="false"
    width="180"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>
      <span class="pm-emp"
        [title]="employee?.name"
        (click)="onEmpNameClick(employee?.id)">
        <img class="pm-emp__img"
          [employeeThumbinalSrc]="employee?.id"
          alt="Employee Photo" />
        <span class="pm-emp__text pm-link">{{ employee?.name }}</span>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Position"
    field="employee.positionName"
    [locked]="isLockedColumn"
    width="150"
    [filterable]="false"
    [sortable]="false"
    >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>{{ employee?.positionName }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Facility"
    field="employee.organizationName"
    width="150"
    [locked]="isLockedColumn"
    [filterable]="false"
    [sortable]="false"
    >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Facility</span>
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>{{ employee?.organizationName }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Hire Date"
    field="employee.dateHired"
    width="110"
    filter="date"
    [filterable]="false"
    [sortable]="false"
    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Hire Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>{{ employee?.dateHired | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Termination Date"
    field="terminationDate"
    width="180"
    filter="date"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Termination Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>
      {{ dataItem.terminationDate | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    field="employee.empStatus"
    title="Employee Status"
    width="150"
    [filterable]="false"
    [sortable]="false"
    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Status</span>
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>{{ employee?.empStatus }}</ng-template>
  </kendo-grid-column>


  <kendo-grid-column field="rating.name"
    title="Rating"
    width="170"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      Rating
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>
      {{ dataItem.rating?.name }}
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column field="reviewRecord.reviewDate"
    title="Review Date"
    width="150"
    filter="date"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    >
    <ng-template kendoGridHeaderTemplate>
      Review Date
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>
      <span class="edit-link"
        (click)="onSelectItem(dataItem)">{{ dataItem.reviewRecord.reviewDate | amDateFormat: appConfig.dateFormat }}</span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    field="reviewType.name"
    title="Review Type"
    width="170"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      Review Type
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>
      {{ dataItem.reviewType?.name }}
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    field="reviewRecord.startDate"
    title="Start Date"
    width="150"
    filter="date"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      Start Date
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>
      {{ dataItem.reviewRecord.startDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    field="reviewRecord.completionDate"
    title="Completion Date"
    width="150"
    filter="date"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      Completion Date
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>
      {{ dataItem.reviewRecord.completionDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Status"
    field="status"
    width="170"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Status</span>
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>{{ dataItem.status }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Reviewed By"
    field="reviewRecord.reviewedBy"
    width="200"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      Reviewed By
    </ng-template>
    <ng-template kendoGridCellTemplate
      let-dataItem>
      {{ dataItem.reviewRecord?.reviewedBy }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['desktop']"
    title=""
    class="overflow-visible-cell action-column"
    [width]="50"
    [filterable]="false"
    [sortable]="false">
    <ng-template kendoGridCellTemplate
      let-dataItem>
      <div class="m-roster">
        <span class="m-roster__btn"
          [popper]="pmInfo"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-end'"
          [popperDisableStyle]="'true'">
          <i class="fas fa-list-ul"
            aria-hidden="true"></i>
        </span>
        <popper-content #pmInfo
          class="slx-popper">
          <section class="m-roster__popper">
            <div class="flex flex-direction-row m-roster__group"
              *slxHiddenOn="['tablet']">
              <p class="m-roster__field">
                <span class="m-roster__title">Position</span>
                <span class="m-roster__value">{{ employee.positionName }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Facility</span>
                <span class="m-roster__value">{{ employee.organizationName }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group"
              *slxHiddenOn="['tablet']">
              <p class="m-roster__field">
                <span class="m-roster__title">Hire Date</span>
                <span class="m-roster__value">{{ employee.dateHired | amDateFormat: appConfig.dateFormat }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Termination Date</span>
                <span
                  class="m-roster__value">{{ dataItem.reviewRecord.terminationDate | amDateFormat: appConfig.dateFormat }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Employee Status</span>
                <span class="m-roster__value">{{ employeeStatus }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Review Date</span>
                <span class="m-roster__value">
                  <span class="pm-link"
                    (click)="onOpenReport(dataItem)">{{ dataItem.reviewRecord.reviewDate | amDateFormat: appConfig.dateFormat }}</span>
                </span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Review Type</span>
                <span class="m-roster__value">{{ dataItem.reviewType?.name }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Start Date</span>
                <span
                  class="m-roster__value">{{ dataItem.reviewRecord.startDate | amDateFormat: appConfig.dateFormat }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Completion Date</span>
                <span
                  class="m-roster__value">{{ dataItem.reviewRecord.completionDate | amDateFormat: appConfig.dateFormat }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Status</span>
                <span class="m-roster__value">{{ dataItem.status }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Reviewed By</span>
                <span class="m-roster__value">{{ dataItem.reviewRecord.reviewedBy }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field m-roster__icon">
                <span class="pm-link"
                  (click)="onEmpNameClick(employee?.id)">
                  <i class="pm-emp__icon fas fa-file-search"
                    aria-hidden="true">&nbsp;</i>Employee Profile
                </span>
              </p>
            </div>
          </section>
        </popper-content>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel [fileName]="xlsxName"
    [fetchData]="retriveAllPages()"></kendo-grid-excel>
  <kendo-grid-pdf [fileName]="pdfName"
    [allPages]="true">
    <kendo-grid-pdf-margin top="1cm"
      left="1cm"
      right="1cm"
      bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnDestroy, Input, Output, Host, ViewChild, NgZone, Provider, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription ,  Observable } from 'rxjs';
import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { BudgetHistory, Budget, RestoreBudgetReq } from '../../../models/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { BudgetDefinition } from '../../../../organization/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { BudgetApiService, BudgetManagementService } from '../../../services/index';
import { BudgetRestoreDialogComponent } from '../budget-restore-dialog/budget-restore-dialog.component';
import { RangeDates, IRangeDates } from '../../../../common/models/range-dates';

@Component({
  moduleId: module.id,
  selector: 'slx-budget-history',
  templateUrl: 'budget-history.component.html',
  styleUrls: ['budget-history.component.scss']
})
export class BudgetHistoryComponent implements OnChanges, OnDestroy {
  @Input()
  public orgLevelId: number;
  @Output()
  public finishEditing: EventEmitter<Budget>;

  public startDate: Date;
  public endDate: Date;
  public appConfig: IApplicationConfig;
  public state: {
    isLoading: boolean;
    isCorporation: boolean;
  };

  public gridState: KendoGridStateHelper<BudgetHistory>;
  public selectedRecord: BudgetHistory;

  private budgetApiService: BudgetApiService;
  private budgetManagementService: BudgetManagementService;
  private modalService: ModalService;

  private budgetHistory: BudgetHistory[];

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;

  constructor(budgetApiService: BudgetApiService, modalService: ModalService) {
    this.finishEditing = new EventEmitter();
    this.modalService = modalService;
    this.budgetApiService = budgetApiService;
    this.gridState = new KendoGridStateHelper<BudgetHistory>();
    this.gridState.state.sort = [{ field: 'budgetDefinition.lastUpdatedDate', dir: 'asc' }];
    //this.gridState.state.group = [{ field: 'budgetDefinition.startDate' }];

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: BudgetHistory[]): void => {
      this.selectedRecord = _.first(records);
    });

    this.appConfig = appConfig;
    this.state = {
      isLoading: false,
      isCorporation: false
    };
    this.startDate = moment().startOf('year').toDate();
    this.endDate = moment().endOf('year').toDate();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onDiscard(): void {
    this.finishEditing.emit(null);
  }

  public onRestore(): void {
    let req: RestoreBudgetReq = new RestoreBudgetReq();
    req.orgLevelId = this.orgLevelId;
    req.sourceBudgetId = this.selectedRecord ? this.selectedRecord.budgetDefinition.id : 0;
    BudgetRestoreDialogComponent.openDialog(req, this.modalService, (result: boolean, cmd: BudgetDefinition) => {
      if (result) {
        this.state.isLoading = true;
        if (cmd.id === 0) {
          this.budgetApiService.cloneBudget(req.sourceBudgetId, cmd)
            .then((addedBudget: Budget) => {
              this.finishEditing.emit(addedBudget);
            });
        } else {
          this.budgetApiService.restoreBudget(req.sourceBudgetId, cmd.id)
            .then((restoredBudget: Budget) => {
              this.state.isLoading = false;
              this.finishEditing.emit(restoredBudget);
            });
        }
      }
    });
  }

  protected doLoad(): void {
    this.state.isLoading = true;
    this.budgetApiService.getBudgetHistory(this.orgLevelId, this.startDate, this.endDate)
      .then((records: BudgetHistory[]) => {
        this.budgetHistory = records;
        this.refreshGrid();
        this.state.isLoading = false;
      });
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.doLoad();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['orgLevelId']) {
      this.doLoad();
    }
  }

  private refreshGrid(): void {
    if (!this.budgetHistory) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.budgetHistory, this.gridState.state);
  }
}


import { Component, OnInit, Input, OnDestroy, Host, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import { Subscription ,  Observable } from 'rxjs';

import { EmployeeLeaveManagement } from '../../../models/employee-sections-employment/index';
import { EmployeeSectionsBase } from '../../../models/employee-sections-base';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { KendoGridStateHelper, ModalService, loaDropdownItems, LoaRequestDropDownItem } from '../../../../../common/index';
import { unsubscribe, mutableSelect } from '../../../../../core/decorators/index';
import { LMCreationDialogComponent } from '../../../../../app-modules/leave-management/components/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { LoaRequest } from '../../../../../app-modules/leave-management/models/index';
import { LMManagementService } from '../../../../../app-modules/leave-management/services';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-leave-management',
  templateUrl: 'employee-sections-leave-management.component.html',
  styleUrls: ['employee-sections-leave-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsLeaveManagementComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  public get canAddEdit (): boolean {
    return this.leaveManagement ? this.leaveManagement.canAddEdit : false;
  }

  public get canDelete (): boolean {
    return this.leaveManagement ? this.leaveManagement.canDelete : false;
  }

  public leaveTypes = loaDropdownItems;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public orgLevel: OrgLevel;

  @Input()
  public set leaveManagement(leaveManagementObject: EmployeeLeaveManagement) {
    this.empLeaveManagement = leaveManagementObject;
    if(!this.empLeaveManagement){
      return;
    }
    this.updateUserRights();
    this.refreshGrid();
  }

  public get leaveManagement(): EmployeeLeaveManagement {
    return this.empLeaveManagement;
  }

  @Input() public employeeId: number;

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }
  public gridState: KendoGridStateHelper<LoaRequest>;
  public pageSize: number = 5;

  public get form(): AbstractControl {
    return null;
  }

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  private empLeaveManagement: EmployeeLeaveManagement;
  private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService;

  constructor(
    employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private modalService: ModalService,
    private managementService: LMManagementService
  ) {
    super(decorator, ngZone);
    this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;

    this.gridState = new KendoGridStateHelper<LoaRequest>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'estStartDate', dir: 'desc' }];
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v): void => {
      this.refreshGrid();
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.loadSubsection();
    this.orgLevelSubscription = this.orgLevel$
      .subscribe(orgLevel => this.orgLevel = orgLevel);
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.empLeaveManagement;
  }

  public createNewRequest(item: LoaRequestDropDownItem): void {
    if (!this.orgLevel) {
      return;
    }

    const dialog = LMCreationDialogComponent.openDialogForEmployee(this.employeeShortInfo, this.orgLevel.id, item.id, this.modalService);

    this.subscribeToDialog(dialog);
  }

  public openRequest(item: LoaRequest): void {
    if (!this.orgLevel) {
      return;
    }

    const dialog = LMCreationDialogComponent.openDialogForRequest(this.orgLevel.id, item, this.modalService);

    this.subscribeToDialog(dialog);
  }

  private subscribeToDialog(dialog: LMCreationDialogComponent): void {
    this.modalService.subscribeOnClose(() => {
      if (dialog.dialogResult) {
        this.loadSubsection();
      }
    });
  }

  public updateUserRights(): void{
    this.managementService.canAddEdit = this.leaveManagement.canAddEdit;
    this.managementService.canDelete = this.leaveManagement.canDelete;
  }

  protected loadSubsection(): void {
    if(!this.empLeaveManagement){
      return;
    }
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmployeeLeaveManagement(this.employeeId)
      .then((data: EmployeeLeaveManagement) => {
        this.leaveManagement = data;
        this.updateUserRights();
        this.refreshGrid();
        this.stopProgress();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }).catch(() => {
        this.stopProgress();
      });
  }

  private refreshGrid(): void {
    if (!this.empLeaveManagement) {
      return;
    }

    this.empLeaveManagement.records.map((each)=>{
      each.daysOnLoaText= each.isIntermittent ? '' : String(each.daysOnLoa);
    });

    this.gridState.view = process(this.empLeaveManagement.records, this.gridState.state);
  }
} 

import * as _ from 'lodash';
import { Injectable, OnDestroy  } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SignalrHub2Service } from '../../../channel/services/signalr/signalr-hub2.service';
import { ThrottlingService, SessionService } from '../../../core/services';
import { exportDataConfig } from '../../../app-modules/export-data/export-data.config';
import { appConfig } from '../../../app.config';

@Injectable()
export class AcaExportEventService implements OnDestroy {
  public exportDataStateChanged$: ReplaySubject<any[]>;
  public notificationStateChanged$: ReplaySubject<boolean>;
  private signalRService: SignalrHub2Service;

  constructor(private throttlingService: ThrottlingService,
              private sessionService: SessionService) {
    this.signalRService = new SignalrHub2Service(this.throttlingService, this.sessionService);
  }

  public init() {
    this.signalRService.init({ url: this.getApiRoot(), hubName: exportDataConfig.api.exports.signalRHub });
    this.exportDataStateChanged$ = this.signalRService.subscribe(`${this.sessionService.getAlias()}_${this.sessionService.getUser().username}_acaExportStatusChanged`);
    this.notificationStateChanged$ = new ReplaySubject<boolean>();

    this.signalRService.connectionStatedChanged.subscribe((data: boolean) => {
      this.notificationStateChanged$.next(data);
    });
  }

  ngOnDestroy() {
    this.exportDataStateChanged$.complete();
    this.signalRService.destroy();
  }

  private getApiRoot(): string {
    let url         = appConfig.api.url;
    let version     = appConfig.api.version2;
    let root        = exportDataConfig.api.root;
    let signalRApi  = exportDataConfig.api.signalr;

    if (!signalRApi) {
      console.log('ACA-EXPORT-EVENT.SERVICE ==> signalRApi is not defined, setting up default value');
      signalRApi = 'signalr';
    }

    return `${url}/${version}/${root}/${signalRApi}`;
  }
}

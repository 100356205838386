import { Provider } from '@angular/core';

import { BENEFITS_EMPLOYEE_DEPENDENTS_ENROLLMENT } from '../../../common/index';

export * from './employee-sections-management.service';
export * from './employee-section-api.service';
export * from './employee-section-map.service';
export * from './employee-section-bridge.service';
export * from './employee-section-personal/employee-section-personal-api.service';
export * from './employee-section-personal/employee-section-personal-map.service';
export * from './employee-section-personal/employee-section-warnings-management.service';
export * from './employee-section-personal/employee-section-warning-map.service';
export * from './employee-section-employment/employee-section-employment-api.service';
export * from './employee-section-employment/employee-section-employment-map.service';
export * from './employee-section-custom/employee-section-custom-api.service';
export * from './employee-section-custom/employee-section-custom-map.service';
export * from './employee-section-audit/employee-section-audit-api.service';
export * from './employee-section-audit/employee-section-audit-map.service';
export * from './employee-section-performance/employee-section-performance-api.service';
export * from './employee-section-performance/employee-section-performance-map.service';
export * from './employee-section-schedule/employee-section-schedule-api.service';
export * from './employee-section-schedule/employee-section-schedule-map.service';
export * from './employee-section-schedule/schedule-service';
export * from './employee-section-navigation.service';
export * from './employee-scroll.service';
export * from './employee-sections-benefits-management/index';
export * from './employee-section-accruals/employee-section-accruals-api.service';
export * from './employee-section-accruals/employee-section-accruals-map.service';

import { EmployeeSectionManagementService } from './employee-sections-management.service';
import { EmployeeSectionApiService } from './employee-section-api.service';
import { EmployeeSectionMapService } from './employee-section-map.service';
import { EmployeeSectionBridgeService } from './employee-section-bridge.service';
import { EmployeeSectionsPersonalApiService } from './employee-section-personal/employee-section-personal-api.service';
import { EmployeeSectionsPersonalMapService } from './employee-section-personal/employee-section-personal-map.service';
import { EmployeeSectionWarningsManagementService } from './employee-section-personal/employee-section-warnings-management.service';
import { EmployeeSectionWarningMapService } from './employee-section-personal/employee-section-warning-map.service';
import { EmployeeSectionsEmploymentApiService } from './employee-section-employment/employee-section-employment-api.service';
import { EmployeeSectionsEmploymentMapService } from './employee-section-employment/employee-section-employment-map.service';
import { EmployeeSectionsCustomApiService } from './employee-section-custom/employee-section-custom-api.service';
import { EmployeeSectionsCustomMapService } from './employee-section-custom/employee-section-custom-map.service';
import { EmployeeSectionsAuditApiService } from './employee-section-audit/employee-section-audit-api.service';
import { EmployeeSectionsAuditMapService } from './employee-section-audit/employee-section-audit-map.service';
import { EmployeeSectionsPerformanceApiService } from './employee-section-performance/employee-section-performance-api.service';
import { EmployeeSectionsPerformanceMapService } from './employee-section-performance/employee-section-performance-map.service';
import { EmployeeSectionsScheduleApiService } from './employee-section-schedule/employee-section-schedule-api.service';
import { EmployeeSectionsScheduleMapService } from './employee-section-schedule/employee-section-schedule-map.service';
import { WeekDayService } from './employee-section-schedule/schedule-service';
import { EmployeeSectionNavigationService } from './employee-section-navigation.service';
import { EmpScrollService } from './employee-scroll.service';
import {
  EmployeeSectionsBenefitsManagementApiService,
  EmployeeSectionsBenefitsManagementMapService,
  EmployeeSectionsBenefitsCommonService,
  EmployeeSectionsBenefitsManagementService,
  BenefitsEnrollmentSectionManagementService,
  EmployeeSectionsBenefitsDependentEnrollmentService
} from './employee-sections-benefits-management/index';
import { EmployeeSectionsAccrualsApiService } from './employee-section-accruals/employee-section-accruals-api.service';
import { EmployeeSectionsAccrualsMapService } from './employee-section-accruals/employee-section-accruals-map.service';

export const services: Provider[] = [
  EmployeeSectionApiService,
  EmployeeSectionMapService,
  EmployeeSectionBridgeService,
  EmployeeSectionsPersonalApiService,
  EmployeeSectionsPersonalMapService,
  EmployeeSectionWarningsManagementService,
  EmployeeSectionWarningMapService,
  EmployeeSectionsEmploymentApiService,
  EmployeeSectionsEmploymentMapService,
  EmployeeSectionsCustomApiService,
  EmployeeSectionsCustomMapService,
  EmployeeSectionsAuditApiService,
  EmployeeSectionsAuditMapService,
  EmployeeSectionsEmploymentApiService,
  EmployeeSectionsEmploymentMapService,
  EmployeeSectionsPerformanceApiService,
  EmployeeSectionsPerformanceMapService,
  EmployeeSectionBridgeService,
  EmployeeSectionsScheduleApiService,
  EmployeeSectionsScheduleMapService,
  EmployeeSectionsBenefitsManagementApiService,
  EmployeeSectionsBenefitsManagementMapService,
  EmployeeSectionsBenefitsCommonService,
  EmployeeSectionsBenefitsManagementService,
  BenefitsEnrollmentSectionManagementService,
  WeekDayService,
  EmpScrollService,
  EmployeeSectionsAccrualsApiService,
  EmployeeSectionsAccrualsMapService,
  { provide: BENEFITS_EMPLOYEE_DEPENDENTS_ENROLLMENT, useClass: EmployeeSectionsBenefitsDependentEnrollmentService }
];

import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LoginComponent, LoginFormComponent, ForgotPasswordFormComponent, ResetPasswordFormComponent,LoginIpRestrictionMessageComponent } from './components/index';
import { SsoErrorComponent } from './components/sso-error/sso-error.component';


export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    children: [
      {
        path: '',
        component: LoginFormComponent
      },
      {
        path: 'forgot_password',
        component: ForgotPasswordFormComponent,
      },
      {
        path: 'reset_password',
        component: ResetPasswordFormComponent
      }  
    ]   
  },
  {
    path: 'login_ip_restriction',
    component: LoginIpRestrictionMessageComponent
  },
  {
    path: 'sso/error',
    component: SsoErrorComponent
  }
];

/* export const routesModule: ModuleWithProviders = RouterModule.forChild(routes); */

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { IpManagementService } from '../../../services/ip-management/ip-management.service';
import { IpSettings } from '../../../models/ip-management/ip-settings.model';

@Component({
  selector: 'slx-ip-management',
  templateUrl: 'ip-management.component.html',
  styleUrls: ['ip-management.component.scss'],
  providers: [IpManagementService],

})

export class IpManagementComponent implements OnInit, OnDestroy {
  public activeTab: number = 0;
  public currentTab: string;
  public ipSettings: IpSettings;
  private subscriptions: StringMap<Subscription> = {}; 
  public enforceWhitelistRestrictions: boolean;
  public enforceBlacklistRestrictions: boolean;
  public isCanEdit: boolean;
  public isCanExport: boolean;
  
  constructor(
    private ipManService: IpManagementService) {
  }

  public ngOnInit(): void {
    this.ipManService.getIpManagementConfig();
    
    this.subscriptions.ipManagementSettings = this.ipManService
    .subscribeToIpSettings((v:IpSettings) => {
     this.ipSettings = v;
     this.enforceWhitelistRestrictions = v.enforceWhitelistRestrictions;
     this.enforceBlacklistRestrictions = v.enforceBlacklistRestrictions;
     this.isCanEdit = v.canEdit;
     this.isCanExport = v.canExport;
    });
    
    this.subscriptions.activeTab = this.ipManService
    .subscribeToActiveTab((v:string) => {
     this.currentTab=v;
    });
  }

  public ngOnDestroy(): void {
  }

  public onClickTab(tab: number): void {
    if (this.activeTab === tab) return;
    
    this.activeTab = tab;
    if(tab === 0){
      this.ipManService.setActiveTab("Allowlist");
    }
    else if(tab === 1){
      this.ipManService.setActiveTab("Denylist");
    }
    this.isActiveMenu(this.activeTab);
  }

  public isActiveMenu(tabOrder: number): boolean {
    return (this.activeTab === tabOrder) ? true : false;
  }

  public onBlacklistChanged(flag:boolean){
      this.enforceBlacklistRestrictions = flag;
      this.ipManService.setRestrictions(this.enforceWhitelistRestrictions,this.enforceBlacklistRestrictions, this.isCanEdit, this.isCanExport);
  }

  public onWhitelistChanged(flag:boolean){
    this.enforceWhitelistRestrictions = flag;
    this.ipManService.setRestrictions(this.enforceWhitelistRestrictions,this.enforceBlacklistRestrictions, this.isCanEdit, this.isCanExport);
  }
  
  public onClickExport(isPDF: boolean): void {
    if (isPDF) {
      this.ipManService.setDownloadFile(this.currentTab, "PDF");
    } else {
      this.ipManService.setDownloadFile(this.currentTab, "Excel");
    }
  }

  public onStartAdd(): void { 
    this.ipManService.addNewIp(true);
    }
}

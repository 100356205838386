import { Provider } from '@angular/core';

import { ExportDataApiService } from './export-data-api.service';
import { ExportDataMapService } from './export-data-map.service';
import { ExportDataManagementService } from './export-data-management.service';
import { ExportDataEventService } from '../../../common/services/export-data/export-data-event.service';

export * from './export-data-map.service';
export * from './export-data-api.service';
export * from './export-data-management.service';

import { AcaExportApiService } from './aca-export/aca-export-api.service';
import { AcaExportMapService } from './aca-export/aca-export-map.service';
import { AcaReportApiService } from './aca-report/aca-report-api.service';
import { AcaReportMapService } from './aca-report/aca-report-map.service';
import { AcaExportManagementService } from './aca-export/aca-export-management.service';
import { AcaExportEventService } from '../../../common/services/aca-export/aca-export-event.service';
import { StateManagementService, DialogOptions2 } from '../../../common';

export * from './aca-export/aca-export-api.service';
export * from './aca-export/aca-export-map.service';
export * from './aca-report/aca-report-api.service';
export * from './aca-report/aca-report-map.service';

export const exportDataServices: Provider[] = [
ExportDataApiService,
ExportDataMapService,
AcaExportApiService,
AcaExportMapService,
AcaReportApiService,
AcaReportMapService,
AcaExportManagementService,
AcaExportEventService,
StateManagementService,
DialogOptions2
];
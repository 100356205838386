import * as _ from 'lodash';

import { ReplaySubject ,  Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

import { Assert } from '../../../../framework/index';
import { StateResetTypes } from '../../../../core/models/index';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { ComponentStateStorageService, StateManagementService } from '../../../../common/services/index';

@Injectable()
export class BenefitConsoleDetailsCommonService {
  private showExpiredPlansState$ = new ReplaySubject<boolean>(1);

  private componentId: string;
  private viewShowExpiredPlans: string = 'notShowingExpired';
  private resetBy: StateResetTypes = StateResetTypes.SessionEnd;

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private storageService: ComponentStateStorageService,
    private stateManagement: StateManagementService
  ) {
    this.init('BenefitConsoleDetailsCommonService');
  }

  public init(componentId: string): void {
    if (_.isNil(this.componentId)) {
      this.componentId = componentId;
      this.restoreState();
    }
  }

  public destroy(): void {  }

  public subscribeToShowExpiredPlansState(callback: (s: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.showExpiredPlansState$.subscribe(callback);
  }

  public saveIsShowingExpiredPlansState(isShowingExpiredPlans: boolean): void {
    this.storageService.setControlState(this.componentId,
      this.viewShowExpiredPlans,
      { value: isShowingExpiredPlans },
      this.resetBy
    );
  }

  public restoreState(): void {
    this.stateManagement.init(this.componentId, true);
    this.subscriptions.isShowingExpiredPlans = this.stateManagement.onInit$
      .subscribe(() => {
        const state = this.storageService.getControlState(this.componentId, this.viewShowExpiredPlans);
        const value = _.isBoolean(state.value) ? state.value : false;
        this.showExpiredPlansState$.next(value);
      });
  }
}

import { DetailRow } from './index';
import { DetailGrouping } from './detail-grouping';
import * as _ from 'lodash';

export class DetailGroup {
  public parLevel: number;
  public postedShiftRepliesCount: number;
  public smsRepliesCount: number;
  public grouping: DetailGrouping[];
  public rows: DetailRow[];
  public isEmptySlotFilterSelected: boolean;
  public isOutOfParFilterSelected: boolean;
  public isOvertimeFilterSelected: boolean;
  public isMessageFilterSelected: boolean;
  public isPendingSlotFilterSelected: boolean;
  public idealPpd: number;
  public scheduledPpd: number;
  public name: string;
  public census: number;
  public groupedIdealScheduleHours: number;
  public groupedScheduledCount: number;
  public groupedScheduledHours: number;

  public filteredFilledSlots: number;
  public filteredTotalSlots: number;
  public filteredScheduledHours: number;
  public filteredFilledPpd: number;
  public filteredTotalPpd: number;
  public filteredTotalIdealHours: number;
  public filterSwitchShiftCount: number;

  public recalculateFilteredValues(): void {
    this.filteredFilledSlots = 0;
    this.filteredTotalSlots = 0;
    this.filteredScheduledHours = 0;
    this.filteredFilledPpd = 0;
    this.filteredTotalPpd = 0;
    this.filteredTotalIdealHours = 0;
    _.forEach(this.rows, (row: DetailRow): void => {
      if (row.getFieldValue('ParentShiftId') === '-1') {  //only count parent for ideal
        if (this.noFilters || (this.isEmptySlotFilterSelected && row.isEmpty && !row.isPendingShift) ||
          (this.isMessageFilterSelected && row.messageCount > 0) ||
          (this.isOutOfParFilterSelected && row.isOutOfPar) ||
          (this.isOvertimeFilterSelected && row.isOvertime) ||
          (this.isPendingSlotFilterSelected && row.isPendingShift)) {
          if (!row.isOutOfPar || (row.isEmpty && !row.isPendingShift) || row.isPendingShift) {
            this.filteredTotalIdealHours += +row.getFieldValue('Hours');
            this.filteredTotalSlots++;
          }
          if (!row.isEmpty) {
            this.filteredScheduledHours += +row.getFieldValue('SchedHours');
            this.filteredFilledSlots++;
          }
        }
      }

      if (row.getFieldValue('ParentShiftId') !== '-1') {
        if (this.noFilters || (this.isEmptySlotFilterSelected && row.isEmpty && !row.isPendingShift) ||
          (this.isMessageFilterSelected && row.messageCount > 0) ||
          (this.isOutOfParFilterSelected && row.isOutOfPar) ||
          (this.isOvertimeFilterSelected && row.isOvertime) ||
          (this.isPendingSlotFilterSelected && row.isPendingShift)) {
          if (!row.isEmpty) {
            this.filteredScheduledHours += +row.getFieldValue('SchedHours');
            this.filteredFilledSlots++;
          }
        }
      }

      if (row.getFieldValue('ExcludeFromCount') === '1') {
        return;
      }
      else {
        if (this.noFilters || (this.isEmptySlotFilterSelected && row.isEmpty && !row.isPendingShift) ||
          (this.isMessageFilterSelected && row.messageCount > 0) ||
          (this.isOutOfParFilterSelected && row.isOutOfPar) ||
          (this.isOvertimeFilterSelected && row.isOvertime) ||
          (this.isPendingSlotFilterSelected && row.isPendingShift)) {
          if (!row.isEmpty) {
            this.filteredFilledSlots++;
          }
          this.filteredScheduledHours += +row.getFieldValue('SchedHours');
        }
      }
    });

    if (this.census > 0) {
      this.filteredFilledPpd = this.filteredScheduledHours / this.census;
      this.filteredTotalPpd = this.filteredTotalIdealHours / this.census;
    }
    this.recalculateSwitch();
  }

  private get noFilters(): boolean {
    return !this.isEmptySlotFilterSelected && !this.isMessageFilterSelected && !this.isOutOfParFilterSelected && !this.isOvertimeFilterSelected && !this.isPendingSlotFilterSelected;
  }

  public get emptySlotCount(): number {
    return _.filter(this.rows, (row: DetailRow) => row.isEmpty && row.getFieldValue('ExcludeFromCount') !== '1').length;
  }

  public get outOfParCount(): number {
    return _.filter(this.rows, (row: DetailRow) => row.isOutOfPar && !row.isEmpty).length;
  }

  public get overtimeCount(): number {
    return _.filter(this.rows, (row: DetailRow) => row.isOvertime).length;
  }

  public get messageCount(): number {
    let empty: DetailRow[] = _.filter(this.rows, (row: DetailRow) => row.isEmpty);
    let uniq: DetailRow[] = _.uniqBy(empty, 'key');
    let mapped: number[] = _.map(uniq, (row: DetailRow) => row.messageCount);
    return _.sum(mapped);
  }

  public get allSlotCount(): number {
    return this.rows.length;
  }

  public get filledSlotCount(): number {
    return this.allSlotCount - this.emptySlotCount;
  }

  public get slots(): string {
    return `${this.filteredFilledSlots}/${this.filteredTotalSlots}`;
  }

  public get shiftGroupId(): number {
    let shiftGroupId: number = -1;
    _.each(this.grouping, (grouping: DetailGrouping) => {
      if (grouping.name === 'ShiftGroupId') {
        shiftGroupId = parseInt(grouping.value);
      }
    });
    return shiftGroupId;
  }
  public get shiftGroupName(): string {
    let shiftGroupName: string;
    _.each(this.grouping, (grouping: DetailGrouping) => {
      if (grouping.name === 'ShiftGroup') {
        shiftGroupName = grouping.value;
      }
    });
    return shiftGroupName;
  }

  public recalculateSwitch(): void {
    this.filterSwitchShiftCount = 0;
    _.forEach(this.rows, (row: DetailRow): void => {
      if ((this.isOutOfParFilterSelected && row.isOutOfPar)
        && !this.isEmptySlotFilterSelected
        && !this.isOvertimeFilterSelected
        && !this.isPendingSlotFilterSelected) {
        this.filteredTotalIdealHours = 0;
        this.filteredTotalSlots = 0;
        this.filteredTotalPpd = 0;
      }
      if (row.isOutOfPar) {
        if (!row.isEmpty && !row.isPartnerEmployee) {
          this.filterSwitchShiftCount++;
        }
      }
    });
  }

  public get pendingShiftCount(): number {
    return _.filter(this.rows, (row: DetailRow) => row.isPendingShift).length;
  }
}


import {combineLatest} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ReplaySubject ,  Subscription ,  Observable ,  Subject } from 'rxjs';

import * as moment from 'moment';

import { IndSchOrgLevelEmployee, IndSchEmployeeSchedulesRequest, IndSchOrgLevelEmployees } from '../../models/index';
import { IndividualScheduleActions } from '../../store/individual-schedule/individual-schedule.actions';
import { IIndividualScheduleState, IOrgLevelIndividualScheduleState, IIndividualScheduleCycle } from '../../store/index';
import { unsubscribeInService, mutableSelect } from '../../../core/decorators/index';
import { ScheduleCycle } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { IndividualScheduleEmpManagementService } from './individual-schedule-emp-management.service';
export type restoreEmpArg = { orgLevelId: number, employeeId: number };
import { IDestroyService } from '../../../core/models/index';
import { Assert } from '../../../framework/index';

@Injectable()
export class IndividualScheduleManagementService implements IDestroyService {
  public onEmpListLock$: ReplaySubject<boolean>;
  public onSelectionChanged$: Subject<IndSchOrgLevelEmployee>;
  public onRestoreEmpSelection$: ReplaySubject<restoreEmpArg>;
  public selectedEmployee: IndSchOrgLevelEmployee;
  public onSearchEmpSelected$: ReplaySubject<number>;
  public toggleColumn$: ReplaySubject<boolean>;
  public loadOrgLvlEmployees$: Subject<ScheduleCycle>;

  private m_showEmployeeList: boolean = true;
  public get showEmployeeList(): boolean {
    return this.m_showEmployeeList;
  }

  public get isDirty(): boolean {
    return this.individualScheduleEmpManagementService.isDirty;
  }
  public selectedCycle: ScheduleCycle;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['individualSchedule'])
  public stateByOrglevel$: Observable<IIndividualScheduleState>;

  @unsubscribeInService()
  private stateSubscription: Subscription;
  @unsubscribeInService()
  private uiLockSubscription: Subscription;

  private restoredEmployeeId: number;
  private restoredOrglevelId: number;
  private restoredStartDate: Date;
  private restoredEndDate: Date;

  private storedReq: IndSchEmployeeSchedulesRequest;

  constructor(private individualScheduleActions: IndividualScheduleActions,
    private individualScheduleEmpManagementService: IndividualScheduleEmpManagementService) {
    this.onSelectionChanged$ = new ReplaySubject(1);
    this.onRestoreEmpSelection$ = new ReplaySubject(1);
    this.onEmpListLock$ = new ReplaySubject(1);
    this.restoredEmployeeId = null;
    this.restoredOrglevelId = null;
    this.onSearchEmpSelected$ = new ReplaySubject(1);
    this.loadOrgLvlEmployees$ = new Subject();

    this.stateSubscription = this.orgLevel$.pipe(
      combineLatest(this.stateByOrglevel$))
      .subscribe(([orgLevel, state]) => {
        if (!orgLevel || !state) {
          return;
        }
        let cycle: IIndividualScheduleCycle = state.stateByOrglevel[orgLevel.id] ? state.stateByOrglevel[orgLevel.id].cycle : null;
        if (!cycle) {
          return;
        }
        if (this.restoredOrglevelId === orgLevel.id
          && moment(cycle.startDate).isSame(this.restoredStartDate)
          && moment(cycle.endDate).isSame(this.restoredEndDate)) {
          return;
        }
        let employeeId: number = state.stateByOrglevel[orgLevel.id] ? state.stateByOrglevel[orgLevel.id].employeeId : null;
        this.onRestoreEmpSelection(orgLevel.id, cycle, employeeId);
      });
    this.uiLockSubscription = individualScheduleEmpManagementService.onUILock$.subscribe((value: boolean) => {
      this.onEmpListLock(value);
    });
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public onEmpListLock(isLock: boolean): void {
    this.onEmpListLock$.next(isLock);
  }
  public onRestoreEmpSelection(orgLevelId: number, cycle: IIndividualScheduleCycle, employeeId: number): void {
    this.restoredOrglevelId = orgLevelId;
    this.restoredEmployeeId = employeeId;
    this.restoredStartDate = cycle.startDate;
    this.restoredEndDate = cycle.endDate;
    this.onRestoreEmpSelection$.next({ orgLevelId: orgLevelId, employeeId: employeeId });
  }

  public onEmployeeSelected(event: IndSchOrgLevelEmployee): void {
    this.selectedEmployee = event;
    this.onSelectionChanged$.next(event);
    if (this.selectedEmployee) {
      this.individualScheduleActions.selectItem(this.selectedEmployee.employee.id);
    }
  }

  public onScheduleCycleSelected(cycle: ScheduleCycle): void {
    this.selectedCycle = cycle;
    this.individualScheduleActions.selectScheduleCycle(cycle);
  }

  public onEmployeeRequest(req: IndSchEmployeeSchedulesRequest): void {
    this.storedReq = req;
    this.individualScheduleEmpManagementService.onEmployeeRequest(req);
  }

  public subscribeToOnSearchEmpSelected(callback: (onSearchEmpSelected: number) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.onSearchEmpSelected$.subscribe(callback);
  }

  public subscribeToLoadOrgLvlEmployees(callback: (cycle: ScheduleCycle) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loadOrgLvlEmployees$.subscribe(callback);
  }


  public onScheduleChange(cycle: ScheduleCycle,weeksCount:number) {
    this.storedReq.startDate = cycle.startDate.toDate();
    this.storedReq.scheduleWeeksCount=weeksCount;
    this.onEmployeeRequest(this.storedReq);
  }

  public changeEmployeeListVisibility(): void {
    this.m_showEmployeeList = !this.m_showEmployeeList;
  }
}

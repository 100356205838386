import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { GroupResult, orderBy, filterBy, process, State } from '@progress/kendo-data-query';

import { appConfig, IApplicationConfig } from '../../../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { LeaveRequestEntry, LeaveRequestDetailEntry, LeaveRequestDetailEntries } from '../../models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { LeaveRequestApiService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { KendoGridStateHelper, saveEvent } from '../../../common/models/index';
import { IndividualScheduleNavigationService, ScheduleEntryNavigationService } from './../../../common/services/index';
import { ScheduleCycleHelperService } from '../../../organization/services/index';
import { ScheduleAbsence, ScheduleCycle } from '../../../organization/models/index';
import { ShiftReplacementRequest, ShiftAddEmployeeCmd, ShiftReplaceCmd } from '../../../scheduler/models/index';
import { ShiftReplacementReplaceComponent } from '../shift-replacement/index';

@Component({
  moduleId: module.id,
  selector: 'slx-leave-request-details',
  templateUrl: 'leave-request-details.component.html',
  styleUrls: ['leave-request-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveRequestDetailsComponent implements OnInit, OnDestroy {
  @Input()
  public leaveRequest: LeaveRequestEntry;

  @Input()
  public absencesCodes: ScheduleAbsence[];

  @Input()
  public orgLevel: OrgLevel;

  @Input()
  public set editMode(value: boolean) {
    if (!this.currentIsEditing) {
      this.isEdit = value;
    }
  }

  @Output()
  public detailsUpdated: EventEmitter<null>;

  @Output()
  public changedEditState: EventEmitter<boolean>;

  public leaveRequestDetails: LeaveRequestDetailEntry[];
  public canReplaceEmployee: boolean;
  public origLeaveRequestDetails: LeaveRequestDetailEntry[];
  public gridState: KendoGridStateHelper<LeaveRequestDetailEntry>;
  public appConfig: IApplicationConfig;
  public isLoading: boolean;
  public isEdit: boolean;
  public pageSize: number;

  private scheduleCycleHelperService: ScheduleCycleHelperService;
  private leaveRequestApiService: LeaveRequestApiService;
  private modalService: ModalService;
  private router: Router;
  private route: ActivatedRoute;
  private navService: IndividualScheduleNavigationService;
  private changeDetector: ChangeDetectorRef;
  private currentIsEditing: boolean;

  @unsubscribe()
  private gridSubscription: Subscription;

  constructor(
    leaveRequestApiService: LeaveRequestApiService,
    scheduleCycleHelperService: ScheduleCycleHelperService,
    modalService: ModalService,
    router: Router,
    route: ActivatedRoute,
    changeDetector: ChangeDetectorRef
  ) {
    this.appConfig = appConfig;
    this.leaveRequestApiService = leaveRequestApiService;
    this.scheduleCycleHelperService = scheduleCycleHelperService;
    this.changeDetector = changeDetector;
    this.modalService = modalService;

    this.router = router;
    this.route = route;
    this.navService = new IndividualScheduleNavigationService(this.router, this.route);
    this.detailsUpdated = new EventEmitter<null>();
    this.changedEditState = new EventEmitter<boolean>();
    this.pageSize = 5;

    this.gridState = new KendoGridStateHelper<LeaveRequestDetailEntry>();
    this.gridState.view = null;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];

    this.isLoading = true;
    this.isEdit = false;
    this.currentIsEditing = false;
  }

  public ngOnInit(): void {
    this.gridSubscription = this.gridState.onRefreshGrid.subscribe((): void => {
      this.refreshGrid();
      this.changeDetector.detectChanges();
    });

    if (_.get(this.orgLevel, 'id') && _.get(this.leaveRequest, 'id')) {
      this.loadData();
    }
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onSaveClick(requestDetails: LeaveRequestDetailEntry): void {
    this.isLoading = true;
    this.leaveRequestApiService.updateDetailsRecord(this.orgLevel.id, this.leaveRequest.id, requestDetails)
      .then((value: number) => {
        this.isLoading = false;
        this.updateEditState(requestDetails, false);
        this.changeDetector.detectChanges();

        this.detailsUpdated.emit();
      });
  }

  public onCancelClick(requestDetails: LeaveRequestDetailEntry): void {
    const leaveRequestDetails: LeaveRequestDetailEntry = _.find(this.origLeaveRequestDetails, { localId: requestDetails.localId});
    if (leaveRequestDetails) {
      _.assign(requestDetails, leaveRequestDetails);
      this.updateEditState(requestDetails, false);
      this.changeDetector.detectChanges();
    }
  }

  public onModelChanged(requestDetails: LeaveRequestDetailEntry): void {
    const leaveRequestDetails: LeaveRequestDetailEntry = _.find(this.origLeaveRequestDetails, { localId: requestDetails.localId});
    if (leaveRequestDetails && requestDetails.absenceReason.code !== leaveRequestDetails.absenceReason.code) {
      this.updateEditState(requestDetails, true);
      this.changeDetector.detectChanges();
    }
  }

  public onFindReplacement(requestDetails: LeaveRequestDetailEntry): void {
    let request: ShiftReplacementRequest = new ShiftReplacementRequest();
    request.replacedEmployeeName = this.leaveRequest.employee.name;
    request.replacedEmployeeId = this.leaveRequest.employee.id;
    request.shiftId = requestDetails.shift.id;
    request.shiftName = requestDetails.shift.name;
    request.positionId = this.leaveRequest.position.id;
    request.positionName = this.leaveRequest.position.name;
    request.date = requestDetails.date;
    request.shiftStart = moment(requestDetails.shift.start).toDate();
    request.shiftEnd = moment(requestDetails.shift.end).toDate();
    request.showDayOffEmployees = true;
    request.orgLevelId = this.orgLevel.id;
    request.scheduleAbsence = requestDetails.absenceReason;
    request.organizationName = this.leaveRequest.organization.name;
    request.unitId = requestDetails.unit.id;
    request.unitName = requestDetails.unit.name;
    request.showSendSmsButton = true;


    ShiftReplacementReplaceComponent.openDialog(request, this.modalService, (result: boolean, cmd: ShiftReplaceCmd): void => {
      if (result && cmd) {
        requestDetails.absenceReason = cmd.scheduleAbsence;
        requestDetails.replacement = cmd.selectedEmployee.employee.name;
        requestDetails.replacedEmployee = cmd.selectedEmployee.employee;
        this.updateEditState(requestDetails, true);
        this.changeDetector.detectChanges();
      }
    });
  }

  public onShowSchedule(requestDetails: LeaveRequestDetailEntry): void {
    this.isLoading = true;
    this.scheduleCycleHelperService.getScheduleCycleByDate(requestDetails.date, this.leaveRequest.department.orgLevelId)
      .then(({ startDate, endDate }: ScheduleCycle) => {
        this.isLoading = false;
        this.navService.NavigateToIndividualScheduleEmp(this.leaveRequest.employee.id, startDate.toDate(), endDate.toDate(), requestDetails.date);
      });
  }

  private updateEditState(reqDetails: LeaveRequestDetailEntry, state: boolean): void {
    this.isEdit = this.currentIsEditing = reqDetails.hasEdited = state;
    this.changedEditState.emit(this.isEdit);
  }

  private loadData(): void {
    this.isLoading = true;
    this.leaveRequestApiService
      .getLeaveRequestDetails(this.orgLevel.id, this.leaveRequest.id)
      .then((leaveRequest: LeaveRequestDetailEntries) => {
        this.leaveRequestDetails = leaveRequest.details;
        this.canReplaceEmployee = leaveRequest.canReplaceEmployee;
        this.origLeaveRequestDetails = _.cloneDeep(this.leaveRequestDetails);

        this.refreshGrid();
        this.isLoading = false;
        this.changeDetector.detectChanges();
      });
  }

  private refreshGrid(): void {
    if (!this.leaveRequestDetails) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.leaveRequestDetails, this.gridState.state);
  }
}


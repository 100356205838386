import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable, OnDestroy } from '@angular/core';

import { Observable ,  ReplaySubject ,  Subject ,  Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ExportDataApiService } from './export-data-api.service';
import { OrgLevel } from '../../../state-model/models/index';
import { Assert } from '../../../framework/index';
import { ExportDataConfigurationInfo, ExportDataExecutionItem, ExportDataExecutionStatus, ExportDataConfigurationParameter } from '../models';
import { mutableSelect, unsubscribeAll, unsubscribe } from '../../../core/decorators';
import { FileService } from '../../../common';
import { ExportDataEventService } from '../../../common/services/export-data/export-data-event.service';
import { IExportDataNotification } from '../models/export-data-notification';
import { ExportDataMapService } from './export-data-map.service';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { TokenValidityService, PollingPeriodService } from '../../../core/services';
import { ExportDataStatus } from '../enums/export-data-status';
import { appConfig } from '../../../app.config';

@Injectable()
export class ExportDataManagementService implements OnDestroy {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;
  public exportConfigurations: ExportDataConfigurationInfo[];

  private loading$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private configurationsLoaded$ = new ReplaySubject<ExportDataConfigurationInfo[]>(1);
  private historyLoaded$ = new ReplaySubject<ExportDataExecutionItem[]>(1);
  private statusLoaded$ = new ReplaySubject<ExportDataExecutionStatus[]>(1);
  private exportExecuted$ = new ReplaySubject<ExportDataExecutionItem>(1);

  private applicationHeartbeatService: PollingPeriodService;
  @unsubscribe()
  private poolingSubscription: Subscription;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private apiService: ExportDataApiService,
              private mapService: ExportDataMapService,
              private fileService: FileService,
              private userActivityService: UserActivityService,
              private tokenValidity: TokenValidityService,
              private exportDataEventService: ExportDataEventService) {
    this.applicationHeartbeatService = new PollingPeriodService(this.userActivityService, this.tokenValidity);
  }

  public init(): void {
    this.subscribeToOrgLevelChanges();

    this.exportDataEventService.init();

    this.subscriptions.exportDataStatusChanged = this.exportDataEventService.exportDataStateChanged$.subscribe((args: any[]) => {
      const executionNotification: IExportDataNotification = args && args.length > 2 ? JSON.parse(args[2]): null;
      if (executionNotification) {
        this.statusLoaded$.next([this.mapService.mapNotificationToExportDataStatus(executionNotification)]);
      }
    });

    this.subscriptions.notificationStateChanged = this.exportDataEventService.notificationStateChanged$.subscribe((enabled: boolean) => {
      if (enabled) {
        if (this.poolingSubscription) {
          this.poolingSubscription.unsubscribe();
          this.applicationHeartbeatService.stop();
        }
      } else {
        if (!this.poolingSubscription || this.poolingSubscription.closed) {
          this.poolingSubscription = this.applicationHeartbeatService.onHeartbeat.subscribe(() => {
            if (this.exportConfigurations) {
              const ids: string[] = this.getStatusesToUpdate();
              if (ids && ids.length > 0) {
                this.loadExportDataStatuses(ids);
              }
            }
          });
          this.applicationHeartbeatService.listen(appConfig.notifyPoolingInterval);
        }
      }
    });
  }

  ngOnDestroy() {
    this.orgLevel = null;
    this.loading$.complete();
    this.configurationsLoaded$.complete();
    this.statusLoaded$.complete();
    this.historyLoaded$.complete();
    this.exportExecuted$.complete();
    this.orgLevelChanged$.complete();
  }

  private getStatusesToUpdate() {
    const ids: string[] = [];
    _.each(this.exportConfigurations, c => {
      if (c.lastExecuted && (c.lastExecuted.status === ExportDataStatus.InProgress || c.lastExecuted.status === ExportDataStatus.Waiting)) {
        ids.push(c.lastExecuted.id);
      }
    });

    return ids;
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (v: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.orgLevelChanged$.subscribe(callback);
  }

  public setLoadingStatus(isLoading: boolean): void {
    this.loading$.next(isLoading);
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .pipe(filter((o: OrgLevel) => _.isFinite(_.get(o, 'id'))))
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;

        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);

        this.configurationsLoaded$.next();
        this.historyLoaded$.next();
        this.statusLoaded$.next();
        this.exportExecuted$.next();
      });
  }

  public subscribeToLoadedConfigurations(callback: (v: ExportDataConfigurationInfo[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.configurationsLoaded$.subscribe(callback);
  }

  public subscribeToLoadedHistory(callback: (v: ExportDataExecutionItem[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.historyLoaded$.subscribe(callback);
  }

  public subscribeToLoadedStatuses(callback: (v: ExportDataExecutionStatus[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.statusLoaded$.subscribe(callback);
  }

  public subscribeToExportExecuted(callback: (v: ExportDataExecutionItem) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportExecuted$.subscribe(callback);
  }

  public loadExportDataConfiguration(): void {
    this.loading$.next(true);
    this.apiService.getExportConfigurationList(this.orgLevel.id)
      .then((data: ExportDataConfigurationInfo[]) => {
        this.exportConfigurations = data;
        this.configurationsLoaded$.next(data);
        this.loading$.next(false);
      });
  }

  public loadExportDataHistory(configurationId: number): void {
    this.loading$.next(true);
    this.apiService.getExportDataHistory(this.orgLevel.id, configurationId)
      .then((data: ExportDataExecutionItem[]) => {
        this.historyLoaded$.next(data);
        this.loading$.next(false);
      });
  }

  public loadExportDataStatuses(ids: string[]): void {
    this.apiService.getExportDataStatuses(this.orgLevel.id, ids)
      .then((data: ExportDataExecutionStatus[]) => {
        this.statusLoaded$.next(data);
      });
  }

  public generateExportData(configurationId: number, params: ExportDataConfigurationParameter[]): void {
    this.loading$.next(true);
    const req = this.mapService.mapToExportDataRequest(this.orgLevel.id, configurationId, params);
    this.apiService.generateExportData(req)
    .then((data: ExportDataExecutionItem) => {
      this.exportExecuted$.next(data);
      this.historyLoaded$.next([data]);
      this.loading$.next(false);
    });
  }

  public async downloadExportData(executionId: string): Promise<void> {
    this.loading$.next(true);
    try {
      const file = await this.apiService.downloadExportData(executionId);
      this.fileService.saveToFileSystem(file.blob, file.file);
      this.loading$.next(false);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading$.next(false);
    }
  }

  public cancelExportData(configurationId: number, executionId: string): void {
    this.loading$.next(true);

    this.apiService.cancelExportData(configurationId, executionId)
    .then((data: ExportDataExecutionStatus) => {
      const status: ExportDataExecutionStatus = { configurationId: configurationId, executionId: executionId, status: ExportDataStatus.Cancelled, changedOn: moment().toDate(), reason: null };
      this.statusLoaded$.next([status]);
      this.loading$.next(false);
    });
  }
}

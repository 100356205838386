<div class="switch-shift">
  <div class="slx-content-toolbar-indents header">
    <form novalidate #form="ngForm" class="row form">
      <div class="col-xs-12 col-md-3 col-lg-3 padd-r-0">
        <slx-input-decorator className="slx-no-error-block">
          <input slx-input type="text" [(ngModel)]="position" name="position" [readonly]="true" placeholder="Position">
        </slx-input-decorator>
      </div>
      <div class="col-xs-6 col-md-3 col-lg-3 padd-r-0">
        <slx-input-decorator className="slx-no-error-block">
          <input slx-input type="text" [(ngModel)]="name" name="name" [readonly]="true" placeholder="Name">
        </slx-input-decorator>
      </div>
      <div class="col-xs-3 col-md-2 col-lg-1 padd-r-0">
        <slx-input-decorator className="slx-no-error-block">
          <input slx-input type="text" [(ngModel)]="shift" name="shift" [readonly]="true" placeholder="Shift">
        </slx-input-decorator>
      </div>
      <div class="col-xs-3 col-md-2 col-lg-1 padd-r-0">
        <slx-input-decorator className="slx-no-error-block">
          <input slx-input type="text" [(ngModel)]="unit" name="unit" [readonly]="true" placeholder="Unit">
        </slx-input-decorator>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-4 btn-box">
        <div class="col-xs-6 col-md-2 col-lg-6">
          <button type="button" class="theme-button-apply margin-r" (click)="onSaveChanges()" [disabled]="!selectedShift" >Save</button>
        </div>
        <div class="col-xs-6 col-md-2 col-lg-6">
          <button type="button" class="theme-button-cancel" (click)="onDiscardChanges()">Cancel</button>
        </div>
      </div>
    </form>
  </div>

  <div class="slx-full-height slx-main-content-indents">
    <kendo-grid #kendoGrid class="grid"
      [data]="gridState.view"
      [filterable]="isFilterable"
      [filter]="gridState.state.filter"
      [groupable]="false"
      [group]="gridState.state.group"
      (groupChange)="gridState.groupChange($event)"
      (dataStateChange)="gridState.dataStateChange($event)"
    >

      <kendo-grid-column title=" " [sortable]="false" [filterable]="false" field="isSelected" width="35px">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input [disabled]="!dataItem.selectable" type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange(dataItem, $event)">
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [hidden]="true" title="Shift Group" field="details.shiftGroupName"></kendo-grid-column>
      <kendo-grid-column title="Shift" field="details.shift.name"></kendo-grid-column>
      <kendo-grid-column title="Hours" field="details.workTimeHours"></kendo-grid-column>
      <kendo-grid-column title="Unit" field="details.unit.name"></kendo-grid-column>
      <kendo-grid-column title="Open" field="details.openShiftCount" filter="numeric"></kendo-grid-column>

      <kendo-grid-messages filterMenuTitle="Filter">
      </kendo-grid-messages>  
    </kendo-grid>
  </div>
</div>

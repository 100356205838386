export * from './employee-search/employee-search.component';
export * from './query-builder/index';
export * from './tree/index';
export * from './search-results/index';

import { EmployeeSearchComponent } from './employee-search/employee-search.component';
import { QueryBuilderComponent, ExpressionSelectorComponent } from './query-builder/index';
import { treeComponents, treeClasses } from './tree/index';
import { EmployeeSearchResultsComponent } from './search-results/index';

export const searchComponents: any[] = [
    EmployeeSearchComponent, ExpressionSelectorComponent,
    QueryBuilderComponent, EmployeeSearchResultsComponent
];

import { Provider } from '@angular/core';

export * from './ess-templates-map.service';
export * from './ess-templates-api.service';
export * from './ess-template-management.service';

import { EssTemplateMapService } from './ess-templates-map.service';
import { EssTemplateApiService } from './ess-templates-api.service';
import { EssTemplateManagementService } from './ess-template-management.service';

export const services: Provider[] = [
  EssTemplateMapService,
  EssTemplateApiService,
  EssTemplateManagementService
];

<slx-spinner [show]="isLoading">
  <div class="model-header">
    <div class="col-xs-12">
      <button type="button" class="slx-button pull-left slx-blue generate-button" (click)="toggleSsnVisibility()">
        <span class="slx-button__text">{{ isSsnVisible ? 'Hide SSN' : 'Show SSN' }}</span>
      </button>
    </div>
  </div>

  <div class="modal-body">
    <kendo-grid class="slx-full-height slx-blue-grid aca-export-data-grid" [data]="gridState.view"
      [sortable]="{ mode: 'multiple' }" [sort]="gridState.state.sort" [filterable]="'menu'"
      [filter]="gridState.state.filter" [scrollable]="'scrollable'" [skip]="gridState.state.skip"
      (filterChange)="filterChange($event)" (dataStateChange)="gridState.dataStateChange($event)">

      <kendo-grid-column width="20">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="radio" class="rd-button" name="match"
            (change)="onConfigChanged(dataItem, $event.target.checked)">
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Name" field="employeeName" [sortable]="true" width="155">
        <ng-template kendoGridHeaderTemplate>
          Name
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [title]="dataItem.employeeName">{{dataItem.employeeName}}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="SSN in File" field="ssn" [filterable]="true" width="50">
        <ng-template kendoGridHeaderTemplate>
          SSN in File
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{getSSN(dataItem.ssn)}}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-messages filterMenuTitle="Filter">
      </kendo-grid-messages>  
    </kendo-grid>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn grey" (click)="onBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>Back
    </button>
    <button type="button" class="btn green" [disabled]=!isButtonEnable (click)="linkRecord()">
      <i class="fa fa-link" aria-hidden="true"></i>Link Records
    </button>
  </div>
</slx-spinner>
import { appConfig, IApplicationConfig } from './../../../app.config';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../core/decorators/index';
import { VersionSubscribeService } from '../../../common/services/index';
import { AppVersionInfo } from '../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  public appConfig: IApplicationConfig = appConfig;
  public versionNumber: string = '';

  @unsubscribe()
  private versionSubscription: Subscription;

  constructor(private versionSubscribeService: VersionSubscribeService) {
  }

  public ngOnInit(): void {
    this.versionSubscription = this.versionSubscribeService.getAppVersion().subscribe((version: AppVersionInfo) => {
      this.versionNumber = this.getAppVersion(version);
    });
    this.versionSubscribeService.refreshAppVersion();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private getAppVersion(version: AppVersionInfo): string {
    let ver: string = `v${version.appVersion}`;
    if (version.serviceVersion) {
      let serVer: string[] = version.serviceVersion.split('.');
      ver = `${ver}-${serVer[serVer.length - 2]}${serVer[serVer.length - 1]}`;
    }
    if (version.dbVersion) {
      let dbVer: string[] = version.dbVersion.split('.');
      ver = `${ver}-${dbVer[dbVer.length - 2]}${dbVer[dbVer.length - 1]}`;
    }
    return ver;
  }
}

import { Component, OnInit, OnDestroy, Input, Provider, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { IColumnSettings } from '../../../../core/models/index';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';

import { TimecardsDisplayManagementService } from '../../../services/index';
import {
  TimecardsSummary, TimecardsEmployee, TimecardsEarning, TimecardsEarningPayRule, TimecardsState, TimecardsColumnState,
  payRuleEarningRecord
} from '../../../models/index';


@Component({
  moduleId: module.id,
  selector: 'slx-timecards-display-detail-grid',
  templateUrl: 'timecards-display-detail-grid.component.html',
  styleUrls: ['timecards-display-detail-grid.component.scss']
})
export class TimecardsDisplayDetailGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public employee: TimecardsEmployee;
  @Input()
  public state: TimecardsState;

  public appConfig: IApplicationConfig;
  public records: payRuleEarningRecord[];
  public gridState: KendoGridStateHelper<payRuleEarningRecord>;
  public empEarning: TimecardsEarning;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  constructor() {
    this.gridState = new KendoGridStateHelper<payRuleEarningRecord>();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngOnChanges(change: SimpleChanges): void {
    if (change['employee']) {
      this.records = _.reduce(this.employee.earnings, (result: payRuleEarningRecord[], earning: TimecardsEarning) => {
        let first: boolean = true;
        let rules: payRuleEarningRecord[] = _.map(earning.rules, (rule: TimecardsEarningPayRule) => {
          let record: payRuleEarningRecord = { earning: earning, payRule: rule, first: first };
          if (first) {
            this.empEarning = earning;
          }
          first = false;
          return record;
        });
        return (result || []).concat(rules);
      }, []);
      this.refreshGrid();
    }
  }

  public isVisible(field: string, payCode?: boolean): boolean {
    if (!this.state) {
      return false;
    }
    let columnState: TimecardsColumnState = payCode? this.state.payCodeColumns: this.state.empColumns;
    if (!columnState || !columnState.columnsMap[field]) {
      return false;
    }
    let column: IColumnSettings = columnState.columnsMap[field];
    if (!this.state.isShowPayRates && column.payload && column.payload.payRateRelated) {
      return false;
    }
    return column.displayed;
  }

  public getWidth(field: string): number {
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].width : 50;
  }

  public getFilter(field: string): string {
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].filter : null;
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}


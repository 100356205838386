<div class="header-list-container">
    <div class="header-list-body">
      <div class="header-list-list">
        <div class="list-container">
          <div *ngFor="let organization of organizations" class="list-item">
            <div class="icon-container">
              <i class="icon fal fa-clock" aria-hidden="true"></i>
            </div>
            <div class="details-container">
              <p class="org-name">{{organization.name}}</p>
              <p class="org-time">{{organizationTimes[organization.id]}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <i x-arrow class="header-list-arrow" aria-hidden="true"></i>
  </div>
import { Component, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { unsubscribe } from '../../../../core/decorators/index';
import { EssTemplateOption, EssTemplate } from '../../models/index';
import { AppSettingsManageService } from '../../../../app-settings/services';

@Component({
  moduleId: module.id,
  selector: 'slx-ess-template-option',
  templateUrl: 'ess-template-option.component.html',
  styleUrls: ['ess-template-option.component.scss']
})
export class EssTemplateOptionComponent implements OnDestroy {
  @Input()
  public isGoLiteTemplateOptions: boolean = false;

  public isGoClient: boolean;

  @Input()
  public template: EssTemplate;

  @Input()
  public option: EssTemplateOption;

  @Input()
  public readonly: boolean;

  @Output()
  public valueChange: EventEmitter<any>;

  constructor(private changeDetectorRef: ChangeDetectorRef,private appSettingsManageService: AppSettingsManageService) {
    this.valueChange = new EventEmitter();
  }

  public ngOnInit(): void {
    this.load();    
  }

  public load(): void {   
    this.getGoClientsetting();
  }

  private async getGoClientsetting(): Promise<void> {   
    const config = await this.appSettingsManageService.getAppServerConfig();    
    this.isGoClient = config.IsGOClient;
    if (this.template.essTemplateDefinition !== null && this.template.essTemplateDefinition !== undefined) {
      this.isGoLiteTemplateOptions = this.template.essTemplateDefinition.name == 'Go Lite' && !this.isGoClient;
    }
  }

  public valueChanged(): void {
    if (!this.option.value) {
      _.forEach(this.option.childs, (child: EssTemplateOption) => {
        child.value = false;
      });
    }
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
    this.valueChange.emit();
  }

  public childValueChange(): void {
    this.valueChange.emit();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}

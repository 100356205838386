import * as moment from 'moment';
import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { ReplaySubject ,  Observable ,  Subscription } from 'rxjs';

import { ChangeManagementService } from '../../../common/services/index';
import { RoleDefinition } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';

import { UserProfileApiService } from './user-profile-api.service';
import { UserProfileMapService } from './user-profile-map.service';
import { UserProfileSectionType } from '../../models/users/models/user-profile-section-type';
import { UserProfileModel, UserRoleRelationModel, UserRoleRecordWrapper } from '../../models/index';
import { UserProfileSectionBaseComponent } from '../../components/users/user-profile-sections/user-profile-section-base.component';
import { UserRolesSaveData } from '../../models/users/models/user-roles-save-data';

@Injectable()
export class UserProfileManagementService {

    public get onResetStates$(): ReplaySubject<any> {
        return this.m_onResetStates;
    }

    public get onProfileLoaded$(): ReplaySubject<UserProfileModel> {
        return this.m_onProfileLoaded;
    }

    public get onStatusChanged$(): ReplaySubject<any> {
        return this.m_onStatusChanged;
    }

    public get onUserCreated$(): ReplaySubject<UserProfileModel> {
        return this.m_onUserCreated;
    }

    public get onUserRolesLoaded$(): ReplaySubject<UserRoleRelationModel[]> {
        return this.m_onRolesLoaded;
    }

    public get onAllRolesLoaded$(): ReplaySubject<RoleDefinition[]> {
        return this.m_onAllRolesLoaded;
    }

    public get onSectionEnteredEditState$(): ReplaySubject<UserProfileSectionBaseComponent> {
        return this.m_onSectionEnteredEditState;
    }

    public get onSectionSave$(): ReplaySubject<UserProfileSectionBaseComponent> {
        return this.m_onSectionSave;
    }

    public get onSectionSaved$(): ReplaySubject<UserProfileSectionBaseComponent> {
        return this.m_onSectionSaved;
    }

    public get onSectionDiscarded$(): ReplaySubject<UserProfileSectionBaseComponent> {
        return this.m_onSectionDiscarded;
    }

    public get onSectionServiceError$(): ReplaySubject<UserProfileSectionType> {
        return this.m_onSectionServiceError;
    }

    public get onAddRoleModeSwitch$(): ReplaySubject<boolean> {
        return this.m_onAddRoleModeSwitch;
    }

    public get onSaveSelectedRole$(): ReplaySubject<any> {
        return this.m_onSaveSelectedRole;
    }

    public get onCancelSelectedRole$(): ReplaySubject<any> {
        return this.m_onCancelSelectedRole;
    }

    public get onDeleteRoles$(): ReplaySubject<any> {
        return this.m_onDeleteRoles;
    }

    public get onResetRoles$(): ReplaySubject<any> {
        return this.m_onResetRoles;
    }

    public changeService: ChangeManagementService;

    private m_onResetStates: ReplaySubject<any>;
    private m_onProfileLoaded: ReplaySubject<UserProfileModel>;
    private m_onUserCreated: ReplaySubject<UserProfileModel>;
    private m_onRolesLoaded: ReplaySubject<UserRoleRelationModel[]>;
    private m_onAllRolesLoaded: ReplaySubject<RoleDefinition[]>;
    private m_onSectionEnteredEditState: ReplaySubject<UserProfileSectionBaseComponent>;
    private m_onSectionSaved: ReplaySubject<UserProfileSectionBaseComponent>;
    private m_onSectionSave: ReplaySubject<UserProfileSectionBaseComponent>;
    private m_onSectionDiscarded: ReplaySubject<UserProfileSectionBaseComponent>;
    private m_onSectionServiceError: ReplaySubject<UserProfileSectionType>;
    private m_onStatusChanged: ReplaySubject<any>;

    private m_onAddRoleModeSwitch: ReplaySubject<boolean>;
    private m_onDeleteRoles: ReplaySubject<null>;
    private m_onResetRoles: ReplaySubject<null>;
    private m_onSaveSelectedRole: ReplaySubject<any>;
    private m_onCancelSelectedRole: ReplaySubject<any>;

    private userProfile: UserProfileModel;

    constructor(private api: UserProfileApiService, private map: UserProfileMapService) {
        this.m_onResetStates = new ReplaySubject(1);
        this.m_onProfileLoaded = new ReplaySubject<UserProfileModel>(1);
        this.m_onUserCreated = new ReplaySubject<UserProfileModel>(1);
        this.m_onRolesLoaded = new ReplaySubject<UserRoleRelationModel[]>(1);
        this.m_onAllRolesLoaded = new ReplaySubject<RoleDefinition[]>(1);
        this.m_onSectionEnteredEditState = new ReplaySubject<UserProfileSectionBaseComponent>(1);
        this.m_onSectionSaved = new ReplaySubject<UserProfileSectionBaseComponent>(1);
        this.m_onSectionSave = new ReplaySubject<UserProfileSectionBaseComponent>(1);
        this.m_onSectionDiscarded = new ReplaySubject<UserProfileSectionBaseComponent>(1);
        this.m_onSectionServiceError = new ReplaySubject<UserProfileSectionType>(1);
        this.m_onStatusChanged = new ReplaySubject<any>(1);
        this.m_onAddRoleModeSwitch = new ReplaySubject<boolean>(1);
        this.m_onSaveSelectedRole = new ReplaySubject<any>(1);
        this.m_onCancelSelectedRole = new ReplaySubject<any>(1);
        this.m_onDeleteRoles = new ReplaySubject<null>(1);
        this.m_onResetRoles = new ReplaySubject<null>(1);
    }

    public reset(): void {
        this.m_onResetStates.next(0);
    }

    public getUserProfile(userId: number): void {
        this.api.getUserProfile(userId).then((userProfile: UserProfileModel) => {
            this.userProfile = userProfile;
            this.m_onProfileLoaded.next(userProfile);
        }).catch((e: any) => {
            this.m_onSectionServiceError.next(UserProfileSectionType.PROFILE);
        });
    }

    public loadRoles(): void {
        this.api.getRoles().then((roles: RoleDefinition[]) => {
            this.m_onAllRolesLoaded.next(roles);
        }).catch((e: any) => {
            this.m_onSectionServiceError.next(UserProfileSectionType.ROLES);
        });
    }

    public getUserRoles(userId: number): void {
        this.api.getUserRoles(userId).then((roles: UserRoleRelationModel[]) => {
            this.m_onRolesLoaded.next(roles);
        }).catch((e: any) => {
            this.m_onSectionServiceError.next(UserProfileSectionType.ROLES);
        });
    }

    public createNewUser(): void {
        let userProfile: UserProfileModel = new UserProfileModel();
        userProfile.isNew = true;
        this.m_onProfileLoaded.next(userProfile);
        this.m_onRolesLoaded.next([]);
    }

    public createNewUserOnServer(userProfile: UserProfileModel, roles: UserRoleRelationModel[]): void {
        this.api.createUser(userProfile, roles).then((profile: UserProfileModel) => {
            this.m_onUserCreated.next(profile);
        }).catch((e: any) => {
            this.m_onSectionServiceError.next(UserProfileSectionType.ALL);
        });
    }

    public disableUser(): void {
        this.api.disableUser(this.userProfile.id).then((result: any) => {
            this.m_onStatusChanged.next(0);
            this.userProfile.isActive = false;
        }).catch((e: any) => {
            this.m_onSectionServiceError.next(UserProfileSectionType.ALL);
        });
    }

    public activateUser(): void {
        this.api.activateUser(this.userProfile.id).then((result: any) => {
            this.m_onStatusChanged.next(1);
            this.userProfile.isActive = true;
        }).catch((e: any) => {
            this.m_onSectionServiceError.next(UserProfileSectionType.ALL);
        });
    }

    public editSection(section: UserProfileSectionBaseComponent): void {
        this.changeService.changeNotify(this.getSectionGroupKey(section));
        this.m_onSectionEnteredEditState.next(section);
    }

    public onAddRole(): void {
        this.m_onAddRoleModeSwitch.next(true);
    }

    public onDeleteRole(): void {
        this.m_onDeleteRoles.next(null);
    }

    public onResetRoles(): void {
        this.m_onResetRoles.next(null);
    }

    public addSelectedRole(): void {
        this.m_onSaveSelectedRole.next(null);
    }

    public cancelAddSelectedRole(): void {
        this.m_onCancelSelectedRole.next(null);
    }

    public onSaveAddedRole(): void {
        this.m_onAddRoleModeSwitch.next(false);
    }

    public onCancelAddedRole(): void {
        this.m_onAddRoleModeSwitch.next(false);
    }

    public saveSection(section: UserProfileSectionBaseComponent): void {
        switch (section.type) {
            case UserProfileSectionType.PROFILE:
                this.api.saveUserProfile(section.getSaveData()).then((userProfile: UserProfileModel) => {
                    this.m_onSectionSaved.next(section);
                    this.changeService.clearChanges(this.getSectionGroupKey(section));
                }).catch((e: any) => {
                    this.m_onSectionServiceError.next(UserProfileSectionType.PROFILE);
                    this.changeService.clearChanges(this.getSectionGroupKey(section));
                });
                this.m_onSectionSave.next(section);
                break;
            case UserProfileSectionType.ROLES:
                let saveData: UserRolesSaveData = section.getSaveData();
                if (!saveData.addMode) {
                    // save all roles
                    this.api.saveUserRoles(this.userProfile.id, saveData.tempRoles).then((roles: UserRoleRelationModel[]) => {
                        this.m_onSectionSaved.next(section);
                        this.changeService.clearChanges(this.getSectionGroupKey(section));
                    }).catch((e: any) => {
                        this.m_onSectionServiceError.next(UserProfileSectionType.ROLES);
                        this.changeService.clearChanges(this.getSectionGroupKey(section));
                    });
                    this.m_onSectionSave.next(section);
                }
                break;
        }
    }

    public discardSection(section: UserProfileSectionBaseComponent): void {
        this.changeService.clearChanges(this.getSectionGroupKey(section));
        this.m_onSectionDiscarded.next(section);
    }

    public cloneProfile(profile: UserProfileModel): UserProfileModel {
        return this.map.cloneUserProfile(profile);
    }

    public cloneRoles(roles: UserRoleRelationModel[]): UserRoleRelationModel[] {
        return this.map.cloneUserRoles(roles);
    }

    private getSectionGroupKey(section: UserProfileSectionBaseComponent): string {
        return 'section' + section.toString();
    }

}

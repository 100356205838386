import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, TemplateRef, ViewChild, Inject } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import * as _ from 'lodash';

import { EditableListActionModel, EditableListActionKind } from '../../../models/index';
import { ListActionsService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-editable-list-row,[slx-editable-list-row]',
  templateUrl: 'editable-list-row.component.html',
  styleUrls: ['editable-list-row.component.scss']
})
export class EditableListRowComponent implements OnInit, OnDestroy {

  @Input()
  public index: number;

  @Input()
  public item: any;

  @Input()
  public editorTemplateRef: TemplateRef<any>;

  public state: {
    selected: boolean;
    editing: boolean;
    inactive: boolean;
  };

  protected actionSubscription: Subscription;

  constructor(private actionsService: ListActionsService) {
    this.state = {
      selected: false,
      editing: false,
      inactive: false
    };
  }

  public ngOnInit(): void {
    this.actionSubscription = this.actionsService.subscribe((msg: EditableListActionModel) => this.onAction(msg));
    let isNew: boolean = this.actionsService.isNew(this.item);
    if (isNew && !this.actionsService.renderIsEditor) {
      this.state.editing = true;
    }
  }

  public ngOnDestroy(): void {
    if (this.actionSubscription) this.actionSubscription.unsubscribe();
  }

  protected onAction(action: EditableListActionModel): void {
    switch (action.kind) {
      case EditableListActionKind.START_EDIT:
        this.onItemEditStart(action.items);
        break;
      case EditableListActionKind.CANCEL_EDIT:
        this.onItemEditCancel(action.items);
        break;
      case EditableListActionKind.COMPLETE_EDIT:
        this.onItemEditCancel(action.items);
        break;
      case EditableListActionKind.SELECTION_CHANGE:
        this.onItemSelected(action.items);
        break;
    }
  }

  protected onItemEditStart(items: any[]): void {
    if (!this.actionsService.renderIsEditor) {
      if (items && items.indexOf(this.item) !== -1) {
        this.state.editing = true;
      } else {
        this.state.editing = false;
        this.state.inactive = true;
      }
    }
  }

  protected onItemSelected(items: any[]): void {
    if (items && items.indexOf(this.item) !== -1) {
      this.state.selected = !this.state.selected;
    } else if (!items) {
      this.state.selected = false;
    }
  }

  protected onItemEditCancel(items: any[]): void {
    if (items && items.indexOf(this.item) !== -1) {
      this.state.editing = false;
      this.state.inactive = false;
    } else {
      this.state.inactive = false;
    }
  }

}

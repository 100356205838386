<kendo-multiselect [data]="dataFiltered" [disabled]="disabled"
    [filterable]="true"
    [clearButton]="false"
    (filterChange)="filterChange($event)"
    placeholder="{{placeholder}}"
    [textField]="titleField"
    [valueField]="valueField"
    [(ngModel)]="internalValue"
    [class.readonly]="readonly"
    [popupSettings]="{ popupClass:'slx-dropdown-multiselect-popup' }"
>
</kendo-multiselect>

    <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height" [data]="gridState.view"
      [filterable]="'menu'"
      [filter]="gridState.state.filter"
      (filterChange)="filterChangeHandler($event)"
      [sortable]="{ mode: 'multiple' }"
      [sort]="gridState.state.sort"
      (sortChange)="sortChange($event)"
      (detailExpand)="detailExpand($event)"
      (detailCollapse)="detailCollapse($event)"
      >
        <kendo-grid-column field="batchStatus"  filter="text" width="150">
            <ng-template kendoGridHeaderTemplate>
                Batch Status
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem >
                <span *ngIf="dataItem.batchProgress===0 && dataItem.batchStatus === 'WAITING'" class="waiting"><i class="fas fa-hourglass-half waiting"></i>Waiting</span>
                <span *ngIf="dataItem.batchProgress===100 && dataItem.batchStatus == 'Completed'" class="success"><i class="fas fa-check-circle success"></i>Completed</span>
                <span *ngIf="dataItem.batchProgress===100 && dataItem.batchStatus === 'COMPLETED WITH ERRORS'" class="warning"><i class="fas fa-check-circle warning"></i>Completed with errors</span>
                <span *ngIf="dataItem.batchProgress===100 && dataItem.batchStatus === 'CANCELLED'" class="error"><i class="fas fa-times-circle error"></i>Cancelled</span>
                <span *ngIf="dataItem.batchProgress===100 && dataItem.batchStatus === 'EXPIRED'" class="error"><i class="fas fa-times-circle error"></i>Expired</span>
                <span *ngIf="dataItem.batchProgress===100 && dataItem.batchStatus === 'FAILED'" class="error"><i class="fas fa-times-circle error"></i>Failed</span>
            <div  class="status-bar">
                    <kendo-progressbar class="bar" [value]="dataItem.batchProgress"
                    *ngIf="dataItem.batchProgress > 0 && dataItem.batchProgress <100"></kendo-progressbar>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="batchId" filter="text" width="120">
            <ng-template kendoGridHeaderTemplate>
                Batch ID
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.batchId }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="parentOrgName" filter="text" width="200">
            <ng-template kendoGridHeaderTemplate>
                Parent Organization
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.parentOrgName}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="exportDate" filter="date" width="140" [format]="dateFormat">
            <ng-template kendoGridHeaderTemplate>
                Export Date
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.exportDate| date: 'MM/dd/yyyy'}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="startDate" filter="date" width="120" [format]="dateFormat" >
            <ng-template kendoGridHeaderTemplate>
                Start Date
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.startDate | date: 'MM/dd/yyyy'}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="endDate" filter="date" width="130">
            <ng-template kendoGridHeaderTemplate>
                End Date </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.endDate | date: 'MM/dd/yyyy'}} </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="staffingType" filter="text" width="150">
            <ng-template kendoGridHeaderTemplate>
                Staffing Type
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.staffingType  | uppercase}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="employeeInfo" filter="text" width="150">
            <ng-template kendoGridHeaderTemplate>
                Employee Info(Y/N)
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="position">
                 {{dataItem.employeeInfo}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="staffingInfo" filter="text" width="170">
            <ng-template kendoGridHeaderTemplate>
                Staffing Info (Y/N)
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="position">
                {{dataItem.staffingInfo}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="userName" filter="text" width="170">
            <ng-template kendoGridHeaderTemplate>
                User Name
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.userName}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column width="100">
            <ng-template kendoGridHeaderTemplate>
                Download
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <slx-action-list class="pop-icon" *ngIf="dataItem.batchProgress===100 && dataItem.batchStatus === 'Completed'">
                    <slx-action-button [popperContent]="popperContent" [popperPosition]="'bottom-end'"
                      > <div class="Icon">
                        <i class="fas fa-download"></i>
                     </div></slx-action-button
                    >
                    <popper-content #popperContent>
                      <slx-action-list-item (onClick)="onDownloadExcelPdf(dataItem.batchId, 'excel')">Download Excel</slx-action-list-item>
                      <slx-action-list-item (onClick)="onDownloadExcelPdf(dataItem.batchId,'pdf')">Download PDF</slx-action-list-item>
                      <slx-action-list-item (onClick)="downloadParentGridData(dataItem.batchId)">Download XML</slx-action-list-item>
                      <slx-action-list-item (onClick)="onDownloadSummary(dataItem, orgLevel.id)">Download Summary</slx-action-list-item>
                      <slx-action-list-item (onClick)="onDownloadBulkExport(dataItem, orgLevel.id)">Download Bulk Export File</slx-action-list-item>
                    </popper-content>
                  </slx-action-list>
            </ng-template>
        </kendo-grid-column>

        <ng-template kendoGridDetailTemplate let-dataItem >
            <div class="childexport">
                <slx-pbj-export-grid-child class="child" [orgLevelId]="orgLevel.id" [childRequestObj]="dataItem"></slx-pbj-export-grid-child>
            </div>
        </ng-template>

        <kendo-grid-messages filterMenuTitle="Filter">
        </kendo-grid-messages>  
    </kendo-grid>

import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { unsubscribeAll } from '../../../../../core/decorators/index';

import { ColumnManagementService, StateManagementService } from '../../../../../common/services/index';

import { PmRosterManagementService } from '../../../services/index';

@Component({
  selector: 'slx-pm-roster',
  templateUrl: './pm-roster.component.html',
  styleUrls: ['./pm-roster.component.scss'],
  providers: [PmRosterManagementService, ColumnManagementService, StateManagementService]
})
export class PmRosterComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private pmManagementService: PmRosterManagementService) {}

  public ngOnInit() {
    this.pmManagementService.init();

    this.subscriptions.loading = this.pmManagementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  public ngOnDestroy(): void {
    this.pmManagementService.destroy();
  }
}

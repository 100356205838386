import * as _ from 'lodash';

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { BenefitDetailsLine, BenefitDetailsTier, BenefitEligibleEmployee, BenefitPayrollDeduction } from '../../../models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';

@Component({
  selector: 'slx-benefit-employees-unenrolled',
  templateUrl: './benefit-employees-unenrolled.component.html',
  styleUrls: ['./benefit-employees-unenrolled.component.scss'],
})
export class BenefitEmployeesUnenrolledComponent {
  public selectedEmployees: BenefitEligibleEmployee[];

  @Input()
  public lineId: number;

  @Input()
  public tierId: number;

  @Input()
  public tierDetails: BenefitDetailsTier;

  @Input()
  public planName: string;

  @Input()
  public canEnroll: boolean;

  @Input()
  public isUserCanEnroll: boolean;


  @Input()
  public date: Date;

  @Input()
  public lowLevelOrgId: number;

  @Input()
  public method: string;
  @Input()
  public benefitDetailsLine: BenefitDetailsLine;

  @Input()
  mappedDeduction: BenefitPayrollDeduction;

  @Input()
  public canMapPayroll: boolean;


  @Output()
  public modeChanged: EventEmitter<string>;

  public mode: 'eligible' | 'enrollment';

  constructor() {
    this.modeChanged = new EventEmitter();
    this.mode = 'eligible';

  }

  public ngOnInit(): void {
    
  }

  public selectEmployees(employees: BenefitEligibleEmployee[]): void {
    this.selectedEmployees = employees;
    this.mode = 'enrollment';
    this.onModeChanged();
  }

  public onEnrollmentCancel(): void {
    this.mode = 'eligible';
    this.onModeChanged();
  }

  public onModeChanged(): void {
    this.modeChanged.next(this.mode);
  }
}


import {filter} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';

import * as _ from 'lodash';

import { IdealScheduleStateService, IdealScheduleToolbarService, IdealScheduleHelperService, IdealScheduleApiService } from '../../../services/index';
import { TOOLBAR_SERVICE } from '../../../../core/services/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { LookupService, Lookup, LookupType } from '../../../../organization/index';
import { ParLevels } from '../../../models/index';
import { IdealScheduleConfigCensusOptions, IdealScheduleConfigCensus } from '../../../models/ideal-schedule/ideal-schedule-config-census';
import { IdealScheduleConfigTypes, IdealScheduleConfigType, IdealScheduleConfigTypeDefinition } from '../../../models/ideal-schedule/ideal-schedule-config-type';
import { LookupMultiselectModel, ModalService, ConfirmDialogComponent, ConfirmChangesDialogComponent, ConfirmOptions } from '../../../../common/index';
import { IdealScheduleShiftGroupDTO, ShiftDetails } from '../../../../configuration/models/ideal-schedule/dto';
import { FooterComponent } from '@progress/kendo-angular-grid';
import { IdealSchedulePositionRange } from '../../../models/ideal-schedule/ideal-schedule-position-range';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';

@Component({
  moduleId: module.id,
  selector: 'slx-ideal-schedule-grid-toolbar',
  templateUrl: 'ideal-schedule-grid-toolbar.component.html',
  styleUrls: ['ideal-schedule-grid-toolbar.component.scss']
})
export class IdealScheduleGridToolbarComponent implements OnInit, OnDestroy {

  public IdealScheduleConfigTypeDefinition = IdealScheduleConfigTypeDefinition;
public IdealScheduleConfigTypes: IdealScheduleConfigType[] = IdealScheduleConfigTypes;
  public IdealScheduleConfigCensusOptions: IdealScheduleConfigCensus[] = IdealScheduleConfigCensusOptions;

  @mutableSelect()
  public orgLevel$: Observable<OrgLevel>;

  public lookups: {
    shiftLookup: Lookup;
    unitLookup: Lookup;
    shiftModels: LookupMultiselectModel[];
    unitModels: LookupMultiselectModel[];
  };

  public selectedShifts: LookupMultiselectModel[];
  public selectedUnits: LookupMultiselectModel[];
  public shiftGroupShift: LookupMultiselectModel;

  public discarding: boolean;
  private currentOrgLevel: OrgLevel;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  @unsubscribe()
  private stateServiceChangedSubscription: Subscription;

  public positionRange: IdealSchedulePositionRange;
  
  constructor(
    @Inject(TOOLBAR_SERVICE) private idealScheduleToolbarService: IdealScheduleToolbarService,
    public stateService: IdealScheduleStateService,
    public lookupService: LookupService,
    public scheduleService: IdealScheduleHelperService,
    public modalService: ModalService, public apiService: IdealScheduleApiService,
    private appSettingManageService: AppSettingsManageService
  ) {
    this.lookups = {
      shiftLookup: null,
      unitLookup: null,
      shiftModels: [],
      unitModels: []
    };

    this.selectedShifts = [];
    this.selectedUnits = [];
  }

  public ngOnInit(): void {
    this.getSettings();
    this.orgLevelSubscription = this.orgLevel$.pipe(
      filter((o: OrgLevel) => _.isNumber(o.id)))
      .subscribe((orgLevel: OrgLevel) => {
        if (!this.currentOrgLevel || this.currentOrgLevel.id !== orgLevel.id) {
          this.currentOrgLevel = orgLevel;
          this.orgLevelUpdated();
        }
      });
    this.stateService.GetIdealShiftValidationData();
    this.stateServiceChangedSubscription = this.stateService.poolChanged$.subscribe(() => {
      this.updateLookups();
    });
  }

  public async getSettings(): Promise<void> {
    let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
    this.stateService.isDefaultShiftGroupFlagEnabled = appServerConfig.isDefaultShiftEnabled;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.currentOrgLevel = null;
  }

  public typeOrCensusChanged(): void {
    if (this.stateService.isFixedCensus) {
      this.updateLookups();
    }
    this.stateService.censusChange$.next(true);
    this.stateService.reconfigureIdealSchedule();
  }

  public BindShiftGroupFixed(): void {

    if (this.selectedShifts.length != 0) {
      _.forEach(this.stateService.shiftGroupDetailss.shifts, (item: ShiftDetails) => {
        let existingShift = _.find(this.selectedShifts, pos => pos.id === item.shiftId);
        if (!existingShift) {
          this.shiftGroupShift = new LookupMultiselectModel()
          this.shiftGroupShift.id = item.shiftId;
          this.shiftGroupShift.isSelected = true;
          this.shiftGroupShift.name = item.name;
          this.shiftGroupShift.object = this.shiftGroupShift.object || {};
          this.shiftGroupShift.object.group = this.shiftGroupShift.object.group || {};
          this.shiftGroupShift.object.group.name = item.group;
          this.shiftGroupShift.object.group.id = item.groupId;
          this.shiftGroupShift.object.name = item.name;
          this.shiftGroupShift.object.id = item.shiftId;
          this.shiftGroupShift.object.durationHours = item.totalHours;
          this.selectedShifts.push(this.shiftGroupShift);
        }
      });
    }
    else {
      _.forEach(this.stateService.shiftGroupDetailss.shifts, (item: ShiftDetails) => {
        this.shiftGroupShift = new LookupMultiselectModel()
        this.shiftGroupShift.id = item.shiftId;
        this.shiftGroupShift.isSelected = true;
        this.shiftGroupShift.name = item.name;
        this.shiftGroupShift.object = this.shiftGroupShift.object || {};
        this.shiftGroupShift.object.group = this.shiftGroupShift.object.group || {};
        this.shiftGroupShift.object.group.name = item.group;
        this.shiftGroupShift.object.group.id = item.groupId;
        this.shiftGroupShift.object.name = item.name;
        this.shiftGroupShift.object.id = item.shiftId;
        this.shiftGroupShift.object.durationHours = item.totalHours;
        this.selectedShifts.push(this.shiftGroupShift);
      });
    }
    this.selectedShifts = this.selectedShifts.filter(itemA =>
      this.stateService.shiftGroupDetailss.shifts.some(itemB => itemA.id === itemB.shiftId));
    this.stateService.configureShiftGroupData(this.selectedShifts);
  }
  public setShiftGroupStatus(): void {
    if (this.stateService.selectedPositionType.id == 1) {
      this.stateService.isShiftGroupEnabled = true;
    }
    else {
      this.stateService.isShiftGroupEnabled = false;
    }
  }
  public typeChanged(): void {
    if(this.stateService.selectedPositionType.name == 'Shift Group')
    {
      this.stateService.GetIdealShiftValidationData();
    }    
    this.stateService.selectedPositionType.id = this.stateService.selectedPositionType.name == 'Shift Group' ? 1 : this.stateService.selectedPositionType.id;
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    options.isShiftGroup = true;
    options.height = 180;
    options.orgLevelId = this.currentOrgLevel.id;
    if (this.stateService.selectedPositionType.id == 1)
      this.stateService.isShiftNavPopupEnabled = this.stateService.shiftGroupDetailss.shiftGroupCount < 3 ? true : false;
    else
      this.stateService.isShiftNavPopupEnabled = false;
    if (this.stateService.isDefaultShiftGroupFlagEnabled && this.stateService.isShiftNavPopupEnabled && this.stateService.selectedPositionType.id == 1) {
      ConfirmDialogComponent.openDialog(
        'Warning',
        'The Default Shift must be configured per each shift group before setting up Ideal Schedule per Shift Group.',
        this.modalService,
        (result: boolean) => {

        }, options
      );
    }
    else
    {
      if(this.stateService.selectedPositionType.id == 1)
      {
        this.BindShiftGroupFixed();
        this.filtersChanged();
      }
    }
    this.setShiftGroupStatus();
  }

  public filtersChanged(): void {
    if (this.discarding) return;
    if (!this.stateService.isFixedCensus) {
      this.stateService.reconfigureIdealSchedule();
      return;
    }

    const deselectedUnits = _.filter(this.stateService.getSelectedUnits(), unit => {
      return unit.isSelected && !_.some(this.selectedUnits, selectedUnit => selectedUnit.object.id === unit.object.id);
    });

    const deselectedShifts = _.filter(this.stateService.getSelectedShifts(), shift => {
      return shift.isSelected && !_.some(this.selectedShifts, selectedShift => selectedShift.object.id === shift.object.id);
    });

    const hasHours = this.positionHasHours(deselectedUnits, deselectedShifts);

    if (hasHours) {
      this.showDiscardHoursDialog().then((apply: boolean) => {
        this.discarding = false;
        if (apply) {
          this.applyFilters();
        } else {
          this.restoreFilters();
        }
        this.stateService.reconfigureIdealSchedule();
      });
    } else {
      this.applyFilters();
      this.stateService.reconfigureIdealSchedule();
    }
  }

  private applyFilters(): void {
    this.stateService.setSelectedUnits(_.map(this.selectedUnits, selectedUnit => {
      selectedUnit.isSelected = true;
      return selectedUnit;
    }));

    this.stateService.setSelectedShifts(_.map(this.selectedShifts, selectedShift => {
      selectedShift.isSelected = true;
      return selectedShift;
    }));
  }

  private restoreFilters(): void {
    this.selectedUnits = _.map(this.stateService.getSelectedUnits(), selectedUnit => {
      selectedUnit.isSelected = true;
      return selectedUnit;
    });

    this.selectedShifts = _.map(this.stateService.getSelectedShifts(), selectedShift => {
      selectedShift.isSelected = true;
      return selectedShift;
    });
  }

  private showDiscardHoursDialog(): Promise<boolean> {
    if (this.discarding) {
      return Promise.resolve(false);
    }

    this.discarding = true;

    return new Promise((resolve) => {
      ConfirmDialogComponent.openDialog(
        'Discard changes',
        'The deselected unit/shift has been used in this Ideal Schedule. Are you sure you wish to proceed?',
        this.modalService,
        resolve
      );
    });
  }

  private positionHasHours(units: LookupMultiselectModel[], shifts: LookupMultiselectModel[]): boolean {
    let hasHours = false;

    if ((!units || !units.length) && (!shifts || !shifts.length)) {
      return false;
    }

    if(this.stateService.selectedPosition == null || undefined) {
      return false;
    }

    const fixedRange = _.first(this.stateService.selectedPosition.ranges);
    const parLevels = _.values(fixedRange.parLevels);

    if (units.length && this.stateService.selectedPositionType.id === IdealScheduleConfigTypeDefinition.ShiftUnit) {
      const unitFilteredParLevels = _.filter(parLevels, parLevel => _.some(units, unit => parLevel.unit.id === unit.object.id));
      hasHours = hasHours || _.some(unitFilteredParLevels, parLevel => !parLevel.isUnused());
    }

    if (shifts.length) {
      const shiftFilteredParLevels = _.filter(parLevels, parLevel => _.some(shifts, shift => parLevel.shift.id === shift.object.id));
      hasHours = hasHours || _.some(shiftFilteredParLevels, parLevel => !parLevel.isUnused());
    }

    return hasHours;
  }

  private orgLevelUpdated(): void {
    let shiftPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.shiftDefinition,
      orgLevelId: this.currentOrgLevel.id,
      employeeId: undefined
    });

    let unitPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.locationUnit,
      orgLevelId: this.currentOrgLevel.id,
      employeeId: undefined
    });

    Promise.all([shiftPromise, unitPromise]).then((arrays: Lookup[]) => {
      this.lookups.shiftLookup = arrays[0];
      this.lookups.unitLookup = arrays[1];

      this.updateLookups(true);
    });
  }

  private updateLookups(updateFilters: boolean = false): void {
    if (!this.stateService.selectedPosition || !this.stateService.isFixedCensus) {
      return;
    }

    const fixedRange = _.first(this.stateService.selectedPosition.ranges);
    let array: ParLevels[] = _.values(fixedRange.parLevels);

    if (this.lookups.shiftLookup) {
      this.lookups.shiftModels = this.scheduleService.filterShiftLookup(array, this.lookups.shiftLookup);
      this.selectedShifts = _.filter(this.lookups.shiftModels, shiftModel => shiftModel.isSelected);
    }

    if (this.lookups.unitLookup) {
      this.lookups.unitModels = this.scheduleService.filterUnitLookup(array, this.lookups.unitLookup);
      this.selectedUnits = _.filter(this.lookups.unitModels, unitModel => unitModel.isSelected);
    }

    if (updateFilters) {
      this.applyFiltersAfterLookupsUpdating();
    }
  }

  private applyFiltersAfterLookupsUpdating(): void {
    if (this.stateService.isFixedCensus) {
      this.applyFilters();
    }
    this.stateService.reconfigureIdealSchedule();
  } 

}

<slx-spinner class="dua-spinner" [show]="isLoading$ | async">
    <div class="slx-high-box">
        <div class="row slx-high-box__body slx-main-content-indents res">
            <div class="display-button">
                <button type="submit" class="theme-button-apply btn-sync" [disabled]="isSyncDisabled$ | async"
                    (click)="syncData()">Sync Now</button>
                <button type="submit" class="theme-button-apply btn-syncrefresh" (click)="onRefreshClicked()">Refresh
                </button>
            </div>
            <!-- </div> -->
            <div class="row">
              <kendo-grid
                #grid="kendoGrid"
                [kendoGridBinding]="viewModel$ | async"
                [pageable]="true"
                filterable="menu"
                [sortable]="{ mode: 'multiple' }"
                [pageSize]="pageSize"
                [filter]="filter$ | async"
                (filterChange)="onFilterChanged($event)"
                class="grid slx-full-height slx-blue-grid"
              >
                    <kendo-grid-checkbox-column [width]="35">
                        <ng-template kendoGridHeaderTemplate>
                            <input type="checkbox" [checked]="isCheckedAll$ | async"
                                [disabled]="isCheckAllDisabled$ | async" (change)="toggleCheckAll($event)" />
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <input type="checkbox" [checked]="dataItem.ischecked"
                                (change)="onCheckboxChange($event, dataItem)" [disabled]="dataItem.isDisabled" />
                        </ng-template>
                    </kendo-grid-checkbox-column>

                    <kendo-grid-column field="homeOrgLevelId" title="Home Org Level ID" width="180" filter="numeric"></kendo-grid-column>
                    <kendo-grid-column field="homeOrganization" title="Home Organization"
                        width="190"></kendo-grid-column>
                    <kendo-grid-column field="secondaryOrgLevelId" title="Secondary Org Level ID"
                        width="210" filter="numeric"></kendo-grid-column>
                    <kendo-grid-column field="secondaryOrganization" title="Secondary Organization Name"
                        width="260"></kendo-grid-column>
                    <kendo-grid-column title="Secondary Position Dept" field="secondaryPositionDept" width="220">
                    </kendo-grid-column>
                    <kendo-grid-column title="Secondary Position Name" field="secondaryPositionName" width="230">
                    </kendo-grid-column>
                    <kendo-grid-column title="Action" field="action" width="110">
                        <ng-template
                        kendoGridFilterMenuTemplate
                        let-column="column"
                        let-filter="filter"
                        let-filterService="filterService"
                      >
                        <slx-multicheck-filter
                          [isPrimitive]="true"
                          [field]="column.field"
                          [filterService]="filterService"
                          [currentFilter]="filter"
                          [data]="distinctPrimitive(column.field, false)"
                        ></slx-multicheck-filter>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column title="Company ID" field="companyId" width="150" filter="numeric">
                        <ng-template
                            kendoGridFilterMenuTemplate
                            let-column="column"
                            let-filter="filter"
                            let-filterService="filterService"
                        >
                            <slx-multicheck-filter
                            [isPrimitive]="true"
                            [field]="column.field"
                            [filterService]="filterService"
                            [currentFilter]="filter"
                            [data]="distinctPrimitive(column.field, true)"
                            ></slx-multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column title="Company Code" field="companyCode" width="170" filter="numeric">
                        <ng-template
                            kendoGridFilterMenuTemplate
                            let-column="column"
                            let-filter="filter"
                            let-filterService="filterService">
                            <slx-multicheck-filter
                            [isPrimitive]="true"
                            [field]="column.field"
                            [filterService]="filterService"
                            [currentFilter]="filter"
                            [data]="distinctPrimitive(column.field, false)"
                            ></slx-multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column title="Paygroup" field="paygroup" width="170">
                        <ng-template
                            kendoGridFilterMenuTemplate
                            let-column="column"
                            let-filter="filter"
                            let-filterService="filterService">
                            <slx-multicheck-filter
                            [isPrimitive]="true"
                            [field]="column.field"
                            [filterService]="filterService"
                            [currentFilter]="filter"
                            [data]="distinctPrimitive(column.field, false)"
                            ></slx-multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column title="Short Code" field="shortCode" width="150" filter="numeric">
                    </kendo-grid-column>
                    <kendo-grid-column title="Status" field="status" width="110">
                        <ng-template
                        kendoGridFilterMenuTemplate
                        let-column="column"
                        let-filter="filter"
                        let-filterService="filterService">
                        <slx-multicheck-filter
                          [isPrimitive]="true"
                          [field]="column.field"
                          [filterService]="filterService"
                          [currentFilter]="filter"
                          [data]="distinctPrimitive(column.field, false)"
                        ></slx-multicheck-filter>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column title="Failed At" field="failedAt" width="170"> </kendo-grid-column>
                    <kendo-grid-column title="Message" field="message" width="170">
                    </kendo-grid-column>
                    <ng-template kendoGridNoRecordsTemplate>
                        <p>There is no data to display</p>
                    </ng-template>
                    <kendo-grid-messages filterMenuTitle="Filter">
                    </kendo-grid-messages>  
                </kendo-grid>
            </div>
        </div>
    </div>
</slx-spinner>

import * as _ from 'lodash';
import { Validator, NG_VALIDATORS, UntypedFormControl } from '@angular/forms';
import { Directive, Input, Provider, forwardRef, Host } from '@angular/core';

import { AutocompleteComponent } from '../../components/autocomplete/autocomplete.component';

const IVALID_VALUE_VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => AutocompleteInvalidValueValidator),
  multi: true
};

@Directive({
  selector: 'slx-autocomplete[slxAutocompleteInvalidValue][formControlName],slx-autocomplete[slxAutocompleteInvalidValue][formControl],slx-autocomplete[slxAutocompleteInvalidValue][ngModel]',
  providers: [IVALID_VALUE_VALIDATOR]
})
export class AutocompleteInvalidValueValidator implements Validator {
  constructor(public autocomplete: AutocompleteComponent) {}

  public validate(fControl: UntypedFormControl): StringMap<any> {
    const value = this.autocomplete.getValue(fControl.value);
    const item = this.autocomplete.findItemInList(value);
    if (_.isNil(item) && !_.isNil(this.autocomplete.value)) {
      return { 'autocompleteInvalidValue': true };
    }

    return null;
  }

}

import { ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import { GridDataResult, SelectionEvent, DataStateChangeEvent, PageChangeEvent, GridComponent, RowArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor, GroupDescriptor, State } from '@progress/kendo-data-query';
import { StateResetTypes } from '../../../core/models/settings/reset-types';
export type saveEvent<T> = { dataItem: T, isNew: boolean, rowIndex: number };
export type removeEvent<T> = { dataItem: T, rowIndex: number };
export type cancelEvent<T> = { dataItem: T, rowIndex: number, isNew: boolean };

import { UntypedFormGroup } from '@angular/forms';

export class KendoGridStateHelper<T> {
  public view: GridDataResult;
  public state: State;
  public onRefreshGrid: ReplaySubject<State>;
  public onSelectionChanged: ReplaySubject<T[]>;
  public editedRowIndex: number;
  public savedEditedRecord: T;
  public editedRecord: T;
  public onSave$: ReplaySubject<saveEvent<T>>;
  public onEdit$: ReplaySubject<T>;
  public onRemove$: ReplaySubject<removeEvent<T>>;
  public onCancel$: ReplaySubject<cancelEvent<T>>;
  public selectedRowsIds: any[];
  public itemKey: string;
  public gridDefinition: string;
  public preventCloseEditorOnSave: boolean;
  public deSelectedChanged: ReplaySubject<T[]>;

  public gridComponentKey: string;
  public gridControlStateKey: string;
  public gridComponentStateResetType: StateResetTypes;
  public assignRestoredState: boolean = false;
  public refreshGridAfterRestoreState: boolean = false;

  constructor(gridDefinition?: string) {
    this.gridDefinition = gridDefinition;
    this.onRefreshGrid = new ReplaySubject(1);
    this.deSelectedChanged = new ReplaySubject(1);
    this.onSelectionChanged = new ReplaySubject(1);
    this.onSave$ = new ReplaySubject(1);
    this.onEdit$ = new ReplaySubject(1);
    this.onRemove$ = new ReplaySubject(1);
    this.onCancel$ = new ReplaySubject(1);
    this.state = {
      skip: undefined,
      take: undefined,
      filter: undefined,
      sort: undefined,
      group: undefined
    };
    this.state.sort = [];
    this.state.group = [];
    this.selectedRowsIds = [];
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.refreshGrid();
  }

  public groupChange(group: GroupDescriptor[]): void {
    this.state.group = group;
    this.refreshGrid();
  }

  public pageChange(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.refreshGrid();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.refreshGrid();
  }

  public selectRowsByKeys(keys: any[], suppressEvent?: boolean): void {
    if (!this.itemKey) {
      throw Error('Please define itemKey to get selection by ability');
    }
    let itemToSelect = _.filter(this.view.data, (item: any) => _.includes(keys, item[this.itemKey]));
    this.selectedRowsIds = keys;
    if (!suppressEvent) {
      this.onSelectionChanged.next(itemToSelect);
    }
  }

  public selectRowByKey(key: any, suppressEvent?: boolean): void {
    if (!this.itemKey) {
      throw Error('Please define itemKey to get selection by ability');
    }
    let itemToSelect = _.find(this.view.data, (item: any) => item[this.itemKey] === key);
    this.selectedRowsIds = [key];
    if (!suppressEvent) {
      this.onSelectionChanged.next([itemToSelect]);
    }
  }


  public selectFirstRow(suppressEvent?: boolean): void {
    if (!this.itemKey) {
      throw Error('Please define itemKey');
    }
    let firstItem = _.first(this.view.data);
    if (!firstItem) {
      return;
    }
    this.selectedRowsIds = [firstItem[this.itemKey]];
    if (!suppressEvent) {
      this.onSelectionChanged.next([firstItem]);
    }
  }

  public selectionChange(selection: SelectionEvent): void {
    let selectedRecords: T[] = _.map(selection.selectedRows, (args: RowArgs) => args.dataItem);
    let deselectedRecords: T[] =  _.map(selection.deselectedRows, (args: RowArgs) => args.dataItem);
    this.onSelectionChanged.next(selectedRecords);
    this.deSelectedChanged.next(deselectedRecords);
  }

  public editHandler(event: { sender: GridComponent, dataItem: T, rowIndex: number }): void {
    if (this.editedRecord && this.savedEditedRecord) {
      _.merge(this.editedRecord, this.savedEditedRecord);
      this.closeEditor(event.sender);
    } else {
      this.closeEditor(event.sender);
    }
    this.editedRowIndex = event.rowIndex;
    this.editedRecord = event.dataItem;
    this.savedEditedRecord = _.cloneDeep(event.dataItem);
    event.sender.editRow(this.editedRowIndex);
    this.onEdit$.next(this.editedRecord);
  }

  public editHandlerReactive(event: { sender: GridComponent, dataItem: T, rowIndex: number }, formGroup: UntypedFormGroup): void {
    this.closeEditor(event.sender);
    this.editedRowIndex = event.rowIndex;
    this.editedRecord = event.dataItem;
    this.savedEditedRecord = _.cloneDeep(event.dataItem);
    event.sender.editRow(this.editedRowIndex, formGroup);
  }

  public cancelHandler(event: { sender: GridComponent, rowIndex: number, dataItem?: T, isNew?: boolean }): void {
    const editedRecord = _.merge(this.editedRecord, this.savedEditedRecord);
    this.closeEditor(event.sender);
    this.onCancel$.next({ dataItem: editedRecord, rowIndex: event.rowIndex, isNew: event.isNew });
  }

  public removeHandler(event: { sender: GridComponent, rowIndex: number, dataItem: T, isNew: boolean }): void {
    if (!event.dataItem) {
      return;
    }
    this.onRemove$.next({ dataItem: event.dataItem, rowIndex: event.rowIndex });
    this.refreshGrid();
  }

  public closeEditor(sender: GridComponent): void {
    sender.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
    this.editedRecord = undefined;
  }

  public saveHandler(event: { sender: any, rowIndex: number, dataItem: T, isNew: boolean }): void {
    if (!this.preventCloseEditorOnSave) {
      this.closeEditor(event.sender);
      this.onSave$.next({ dataItem: event.dataItem, isNew: event.isNew, rowIndex: event.rowIndex });
      this.refreshGrid();
    } else {
      this.onSave$.next({ dataItem: event.dataItem, isNew: event.isNew, rowIndex: event.rowIndex });
    }
  }

  public updateStateWithDelay(state: State): void {
    if (_.isObjectLike(state)) {
      Promise.resolve()
        .then(() => {
          this.updateState(state);
        });
    }
  }

  public refreshGrid(): void {
    this.onRefreshGrid.next(this.state);
  }

  private updateState(state: State): void {
    if (_.isObjectLike(state)) {
      this.state = state;
      this.refreshGrid();
    }
  }
}

import { Component, OnInit, Input, ViewChild, ElementRef, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EmployeeActivitiesApiService } from  '../../../../../app/employee/employee/services/employee-activities/employee-add-api.service'; //'../../../../services/employee-activities/employee-add-api.service';
import { DOCUMENT } from '@angular/common';
import * as _ from 'lodash';
import { select } from '@angular-redux/store';
import { Subscription ,  Observable } from 'rxjs';
import {
  PageScrollConfig,
  PageScrollingViews,
  ScrollWatchAreaEvent,
  ScrollWatchAreaEventType
} from '../../../../common/models/index';
import { PageScrollService, PageScrollInstance, ScrollWatchService, EmployeeSectionTabNavigationService } from '../../../../common/index';
import { EmployeeSectionApiService, EmployeeSectionManagementService } from '../../services/index';

import { Assert, StringUtils } from '../../../../framework/index';
import { EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { EmployeeShortInfo } from '../../../../organization/models/index';
import { MenuItem, MenuItemActivatedEvent } from '../../../../common/models/index';
import { EmployeeSectionsContext, EmployeeBannerInfo, AddEmployeeRestrictions } from '../../../employee/models/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';

import {
  EmployeeSection,
  EmployeeSubSection,
  employeeSectionConfig,
  EmployeeSectionConfig,
  EmployeeSectionsPersonal,
  EmployeeSectionsEmployment,
  EmployeeSectionsCustom,
  EmployeeSectionsAudit,
  EmployeeSectionsPerformance,
  EmployeeSectionsSchedule,
  EmployeeSectionsProfile,
  EmployeeSectionsBenefitsManagement,
  EmployeeSectionsAccruals,
  EmployeeWorkplace
} from '../../models/index';
import { EmployeeSectionBridgeService } from '../../services/index';
import { DateTimeService } from '../../../../common/services/date-time/date-time.service';
import { EmployeeSectionsProfileComponent } from '../employee-sections-personal/employee-sections-profile/employee-sections-profile.component';
import { EmpScrollService } from '../../services/index';
import { EmpScrollEvent, EmpScrollSection } from '../../models/index';

import { AppSettingsManageService } from '../../../../app-settings/services/index';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';
import { OrgLevel } from '../../../../state-model/models';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections',
  templateUrl: 'employee-sections.component.html',
  styleUrls: ['employee-sections.component.scss']
})
export class EmployeeSectionsComponent implements OnInit, OnDestroy {
  @Input()
  public set employeeSectionsContext(employeeSectionsContext: EmployeeSectionsContext) {
    if (!employeeSectionsContext) { return; }
    if ((employeeSectionsContext && !this.currentEmployeeSectionsContext) || (this.currentEmployeeSectionsContext.employeeId !== employeeSectionsContext.employeeId)) {
      this.employeeSectionManagementService = new EmployeeSectionManagementService(employeeSectionsContext, this.employeeSectionBridgeService, this.employeeSectionApiService, this.dateTimeService);
      this.menuLoaded = false;
      this.currentEmployeeSectionsContext = employeeSectionsContext;
      this.activeSubSection = null;
      this.refresh();
    }
  }

  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;


  public canNotEditOwnProfile: boolean;
  public hasEditingSection: boolean;
  public menuCollapsed: boolean;
  public employeeShortInfo: EmployeeShortInfo;
  public employeeSections: EmployeeSection[];
  public menuItems: MenuItem[];

  public currentEmployeeSectionsContext: EmployeeSectionsContext;
  public activeSection: EmployeeSection;
  public activeSubSection: EmployeeSubSection;
  public isTermHistoryEditEndabled: boolean = false;
  public addEmployeeRestrictions: AddEmployeeRestrictions;

  public orgLevelSubscription: Subscription;
  public orgLevel: OrgLevel;


  public get employeeBannerInfo(): EmployeeBannerInfo {
    return this.employeeSectionManagementService.employeeBannerInfo;
  }
  public get personalSection(): EmployeeSectionsPersonal {
    return this.employeeSectionManagementService.personalSection;
  }
  public get employmentSection(): EmployeeSectionsEmployment {
    return this.employeeSectionManagementService.employmentSection;
  }
  public get customSection(): EmployeeSectionsCustom {
    return this.employeeSectionManagementService.customSection;
  }
  public get auditSection(): EmployeeSectionsAudit {
    return this.employeeSectionManagementService.auditSection;
  }
  public get performanceSection(): EmployeeSectionsPerformance {
    return this.employeeSectionManagementService.performanceSection;
  }
  public get scheduleSection(): EmployeeSectionsSchedule {
    return this.employeeSectionManagementService.scheduleSection;
  }
  public get benefitsManagementSection(): EmployeeSectionsBenefitsManagement {
    return this.employeeSectionManagementService.benefitManagementSection;
  }
  public get accrualsSection(): EmployeeSectionsAccruals {
    return this.employeeSectionManagementService.accrualsSection;
  }
  public get accrualsStartDate(): Date {
    return this.employeeSectionManagementService.accrualsStartDate;
  }
  public get accrualsEndDate(): Date {
    return this.employeeSectionManagementService.accrualsEndDate;
  }
  public get attendancePointsStartDate(): Date {
    return this.employeeSectionManagementService.attendancePointsStartDate;
  }
  public get attendancePointsEndDate(): Date {
    return this.employeeSectionManagementService.attendancePointsEndDate;
  }

  public get sectionsAreVisible(): boolean {
    return this.employeeSectionBridgeService.sectionsAreVisible;
  }

  public get canEditTermHistory(): boolean {
    if(this.employeeBannerInfo && this.isTermHistoryEditEndabled)
      return this.employeeBannerInfo.canTerminate && this.employeeBannerInfo.isTerminated && this.isTermHistoryEditEndabled;
    else
      return false;
  }

  private employeeSectionBridgeService: EmployeeSectionBridgeService;
  private activeSectionSubscription: Subscription;
  private preactiveSectionSubscription: Subscription;
  private pageScrollSubscription: Subscription;
  private routeSubscripion: Subscription;
  private editSubscription: Subscription;
  private document: Document;
  private pageScrollService: PageScrollService;
  private scrollWatchService: ScrollWatchService;
  private dateTimeService: DateTimeService;
  private scrollContainer: PageScrollingViews;

  @ViewChild('contentContainer', { static: true })
  private container: ElementRef;
  @ViewChild('employeeSectionsProfile')
  private employeeSectionsProfile: EmployeeSectionsProfileComponent;

  private employeeSectionManagementService: EmployeeSectionManagementService;
  private configuration: EmployeeSectionConfig;
  private menuLoaded: boolean;
  private editInProgress: boolean;
  private route: ActivatedRoute;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;
  private employeeSectionApiService: EmployeeSectionApiService;
  private editingSections: StringMap<boolean>;
  private scrollService: EmpScrollService;
  private pageScrollFinish: EventEmitter<boolean>;
  private workplace: EmployeeWorkplace;


  constructor(
    employeeSectionBridgeService: EmployeeSectionBridgeService,
    employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    employeeSectionApiService: EmployeeSectionApiService,
    pageScrollService: PageScrollService,
    scrollWatchService: ScrollWatchService,
    dateTimeService: DateTimeService,
    scrollService: EmpScrollService,
    route: ActivatedRoute,
    @Inject(DOCUMENT) document: any,
    private appSettingsManageService: AppSettingsManageService,
    public empSectionTabNavService: EmployeeSectionTabNavigationService,
    private readonly employeeActivitiesApiService : EmployeeActivitiesApiService
  ) {
    this.employeeSectionBridgeService = employeeSectionBridgeService;
    this.employeeSectionApiService = employeeSectionApiService;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    this.pageScrollService = pageScrollService;
    this.scrollWatchService = scrollWatchService;
    this.dateTimeService = dateTimeService;
    this.scrollService = scrollService;
    this.document = document;
    this.route = route;
    this.menuCollapsed = true;
    this.editingSections = {};
    this.pageScrollFinish = new EventEmitter<boolean>();
  }

  public ngAfterViewInit() {
    const sectionId = this.empSectionTabNavService.empSectionTab ? this.empSectionTabNavService.empSectionTab : 'Personal';
    setTimeout(() => {
      if (sectionId === 'Schedule') {
        this.navigate(StringUtils.format('#{0}', sectionId));
        this.empSectionTabNavService.empSectionTab = null;
      }
      else {
        this.navigate(StringUtils.format('#{0}', sectionId));
      }
    }, 3000);
  }

  public ngOnInit(): void {
    this.configuration = employeeSectionConfig;
    this.editInProgress = false;
    this.configure();

    this.editSubscription = this.employeeSectionBridgeService.editStateChange$.subscribe((change: { editing: boolean, section: EmployeeSubSection }) => {
      let isAnyEditing: boolean = false;
      this.editingSections[change.section.id] = change.editing;
      _.forOwn(this.editingSections, (value: boolean, key: string) => {
        if (value) isAnyEditing = value;
      });
      this.hasEditingSection = isAnyEditing;
    });

    this.employeeSectionManagementService = new EmployeeSectionManagementService(this.currentEmployeeSectionsContext, this.employeeSectionBridgeService, this.employeeSectionApiService, this.dateTimeService);
    this.refresh();
    this.routeSubscripion = this.route.params.subscribe((params: Params) => {
      this.employeeSectionsContext = new EmployeeSectionsContext(+params['employeeId']);
      this.scrollService.resetScroll();
    });

    this.activeSectionSubscription = this.scrollService.subscribeToActive((sec: EmpScrollSection) => {
      this.activeSection = this.employeeSectionManagementService.getSectionBySubsectionId(sec.id);
      this.activeSubSection = this.employeeSectionManagementService.getSubSectionById(sec.id);
      this.loadSection(this.activeSection);
    });

    this.preactiveSectionSubscription = this.scrollService.subscribeToPreactive((sec: EmpScrollSection) => {
      let section: EmployeeSection = this.employeeSectionManagementService.getSectionBySubsectionId(sec.id);
      this.loadSection(section);
    });

    this.pageScrollSubscription = this.pageScrollFinish.subscribe(() => {
      this.scrollService.suspended(false);
    });

    this.appSettingsManageService.getAppServerConfig()
      .then((conf: AppServerConfig) => {
        this.canNotEditOwnProfile = !conf.userPermissions.canEditOwnProfile;
        this.isTermHistoryEditEndabled = conf.isTermHistoryEditEnabled;
      });

      this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
        this.orgLevel = orgLevel;
        this.loadRestrictions(this.orgLevel.id)
      });
  }

  public ngOnDestroy(): void {
    if (this.routeSubscripion) {
      this.routeSubscripion.unsubscribe();
    }

    if (this.editSubscription) {
      this.editSubscription.unsubscribe();
    }

    if (this.activeSectionSubscription) {
      this.activeSectionSubscription.unsubscribe();
    }

    if (this.preactiveSectionSubscription) {
      this.preactiveSectionSubscription.unsubscribe();
    }
  }

  public getIsLoadingPersonal(): boolean {
    return this.employeeSectionManagementService.isLoadingPersonal;
  }
  public refresh(): void {
    if (this.menuLoaded) return;
    if (!this.currentEmployeeSectionsContext) return;
    this.menuLoaded = true;
    this.loadWorkplace();
    this.employeeSectionBridgeService.getSections(this.currentEmployeeSectionsContext.employeeId)
      .then((employeeSections: EmployeeSection[]) => {
        this.employeeSections = employeeSections;
        this.mapSectionsToMenu();
        this.employeeSectionManagementService.createSectionsMapping(this.employeeSections);
        if (!this.activeSubSection) {
          let section: EmployeeSection = this.employeeSectionManagementService.getDefaultSection();
          let subsection: EmployeeSubSection = this.employeeSectionManagementService.getDefaultSubSection();
          this.activeSubSection = subsection;
          this.activeSection = section;
          this.loadSection(section);
        }
      });
    this.employeeDefinitionsApiService.getEmployeeShortInfo(this.currentEmployeeSectionsContext.employeeId)
      .then((employeeShortInfo: EmployeeShortInfo) => {
        this.employeeShortInfo = employeeShortInfo;
        this.employeeSectionManagementService.employeeShortInfo = this.employeeShortInfo;
      });
  }

  public loadSection(section: EmployeeSection): void {
    this.employeeSectionManagementService.loadSection(section)
      .then((hasLoaded: boolean) => {
        if (hasLoaded) {
          this.scrollService.updateScroll();
        }
      });
  }

  public loadWorkplace(): void {
    this.employeeSectionManagementService.loadWorkplace()
      .then((workplace: EmployeeWorkplace) => {
        this.workplace = workplace;
      });
  }

  public onItemActivated(event: MenuItemActivatedEvent): void {
    if (!event.item) { return; }
    let section: EmployeeSection = this.employeeSectionManagementService.getSectionById(event.item.id);
    let subsection: EmployeeSubSection;
    if (event.subItem) {
            subsection = this.employeeSectionManagementService.getSubSectionById(event.subItem.id);
      this.scrollTo(event, subsection);
    } else {
      this.scrollTo(event, section);
    }
  }

  public scrollTo(event: MenuItemActivatedEvent, subsection: EmployeeSubSection | EmployeeSection): void {
        this.navigate(subsection.anchor);
  }

  public getIsLoadingSection(subsectionId: string): boolean {
    return this.employeeSectionManagementService.getSectionStateBySubsectionId(subsectionId);
  }

  public menuToggled(isCollapsed: boolean): void {
    this.menuCollapsed = isCollapsed;
  }

  public transfer(event: Event): void {
    this.employeeSectionsProfile.onTransfer();
  }

  public undoTransfer(event: Event): void {
    this.employeeSectionsProfile.undoTransfer();
  }


  public terminate(event: Event): void {
    this.employeeSectionsProfile.onTerminate(this.workplace.ignoreLockedPayrollCycles);
  }

  public rehire(event: Event): void {
    this.employeeSectionsProfile.onRehire();
  }

  public cancelTerminate(event: Event): void {
    this.employeeSectionsProfile.onCancelTerminate();
  }

  public changeTerminationDate(event: Event): void {
    this.employeeSectionsProfile.onChangeTerminationDate(this.workplace.ignoreLockedPayrollCycles);
  }

  public onResendTimeclocksEmail(event: Event): void {
    this.employeeSectionsProfile.onResendTimeclocksEmail();
  }

  public updateBanner(profile: EmployeeSectionsProfile): void {
    this.employeeSectionManagementService.employeeShortInfo = this.employeeShortInfo;
    this.employeeSectionManagementService.setEmployeeBannerInfo(profile);
  }

  private navigate(anchor: string): void {
    Assert.isNotNull(anchor, 'anchor');
    this.scrollService.suspended(true);
    let pageScrollInstance: PageScrollInstance = PageScrollInstance.advancedInstance(
      this.document,
      anchor,
      [this.scrollContainer],
      null,
      40,
      null,
      null,
      null,
      this.pageScrollFinish
    );
    this.pageScrollService.start(pageScrollInstance);
  }

  private mapSectionsToMenu(): void {
    this.menuItems = [];
    _.forEach(this.employeeSections, (section: EmployeeSection) => {
      let item: MenuItem = new MenuItem();
      item.id = section.id;
      item.displayName = section.displayName;
      item.item = section;
      item.items = [];
      this.menuItems.push(item);
      this.mapSubSectionsToMenu(section, item);
    });
  }

  private mapSubSectionsToMenu(section: EmployeeSection, root: MenuItem): void {
    _.forEach(section.subsections, (subSection: EmployeeSubSection) => {
      let item: MenuItem = new MenuItem();
      item.id = subSection.id;
      item.displayName = subSection.displayName;
      item.item = subSection;
      root.items.push(item);
    });
  }

  private configure(): void {
    $('.k-window-titlebar.k-header').css({ 'z-index': 4 });
    this.scrollContainer = this.container.nativeElement;
    PageScrollConfig.defaultScrollOffset = this.configuration.offsetScroll;
  }

  public loadRestrictions(orgLevelId: number): void {
      this.employeeActivitiesApiService.getAddEmployeeProperties(orgLevelId)
      .then((addEmployeeRestrictions: AddEmployeeRestrictions) => {
        this.addEmployeeRestrictions = addEmployeeRestrictions;
      });
  }

}


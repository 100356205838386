import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Subscription ,  Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IApplicationConfig, appConfig } from '../../../../../app/app.config';
import { IScreenUtils, screenUtils } from '../../../../../app/common/utils/screenUtils';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../common/models/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { InfoDialogComponent } from '../../../../common/components/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../common/services/index';
import {  WorkCompTableEditResult, WorkCompTableModelContainer, WorkCompTableTypeModel, WcCodeDefinition } from '../../models/index';
import { WorkersCompensationTableApiService } from '../../services/index';
import { Lookup } from '../../../../organization/models/lookup/lookup-definition';

@Component({
  moduleId: module.id,
  selector: 'slx-workers-compensation-table',
  templateUrl: './workers-compensation-table.component.html',
  styleUrls: ['./workers-compensation-table.component.scss'],
  providers: [StateManagementService, ColumnManagementService]
})

export class WorkersCompensationTableComponent implements OnInit, OnDestroy {
  @Input()
  public codeTypeLookup: Lookup;
  public isLoading: boolean;
  public appConfig: IApplicationConfig;
  public records: WorkCompTableTypeModel[];
  public gridState: KendoGridStateHelper<WorkCompTableTypeModel>;
  public canEdit: boolean;
  public pageSize = 50;
  public screenUtils: IScreenUtils;
  public columnGroup = 'WorkerCompensation';
  public prohibitedCodeValues: string[] = [];
  public codeTypeLookupValues = [];

  @ViewChild('CodeForm', { static: true })
  public workerCompensationForm: UntypedFormGroup;

  @ViewChild('kendoGrid', { static: true })
  public kendoGrid: GridComponent;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  private subscriptions: StringMap<Subscription> = {};
  private container: WorkCompTableModelContainer;
  private orgLevel: OrgLevel;

  constructor(
    private apiService: WorkersCompensationTableApiService,
    private stateManagement: StateManagementService,
    private columnManagementService: ColumnManagementService,
    private modalService: ModalService
  ) {
    this.gridState = new KendoGridStateHelper<WorkCompTableTypeModel>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.appConfig = appConfig;
    this.screenUtils = screenUtils;
    this.canEdit = true;
  }

  public ngOnInit(): void {
    this.stateManagement.init('WorkersCompensationTableComponent');
    this.columnManagementService.init('WorkersCompensationTableComponent');
    this.columnManagementService.initGroup(this.columnGroup, 6);
    this.subscribeToOrgLevel();
    this.loadCodeTypes();

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<WorkCompTableTypeModel>) => {
      this.isLoading = true;
      if (event.isNew) {
        this.doAdd(event.dataItem);
        return;
      }
      this.doEdit(event.dataItem, event.rowIndex);
    });

    this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe((event: removeEvent<WorkCompTableTypeModel>) => {
      this.doRemove(event.dataItem, event.rowIndex);
    });
  }

  public async doAdd(dataItem: WorkCompTableTypeModel): Promise<any> {
    return this.apiService.createcodeDefinition(dataItem)
      .then((res: WorkCompTableEditResult) => {
        if (res.isSuccess) {
          this.records.push(res.item);
          this.prohibitedCodeValues = _.map(this.records, (m: WorkCompTableTypeModel) => m.codeDefinition.code);
          this.reload();
        } else {
          InfoDialogComponent.OpenDialog('Warning', res.message, this.modalService);
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public async doEdit(dataItem: WorkCompTableTypeModel, rowIndex: number): Promise<any> {
    return this.apiService.modifycodeDefinition(dataItem)
      .then((res: WorkCompTableEditResult) => {
        if (res.isSuccess) {
          this.records.splice(rowIndex, 1, res.item);
          this.prohibitedCodeValues = _.map(this.records, (m: WorkCompTableTypeModel) => m.codeDefinition.code);
          this.reload();
        } else {
          this.reload();
          InfoDialogComponent.OpenDialog('Warning', res.message, this.modalService);
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public async doRemove(dataItem: WorkCompTableTypeModel, rowIndex: number): Promise<any> {
    this.isLoading = true;
    return this.apiService.deleteCodeDefinition(dataItem.codeDefinition.id)
      .then((res: WorkCompTableEditResult) => {
        if (res.isSuccess) {
          this.records.splice(rowIndex, 1);
          this.prohibitedCodeValues = _.map(this.records, (m: WorkCompTableTypeModel) => m.codeDefinition.code);
          this.reload();
        } else {
          InfoDialogComponent.OpenDialog('Warning', res.message, this.modalService);
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  private loadCodeTypes(): Promise<any> {
    return this.apiService.getCodeTypes()
      .then((container: []) => {
        this.codeTypeLookupValues = container;
      }).finally(() => {
        this.isLoading = false;
      });
  }

  private subscribeToOrgLevel(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .pipe(filter((o: OrgLevel) => o && _.isFinite(o.id)))
      .subscribe(async (orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
        this.reload();
      });
  }

  public async reload(): Promise<any> {
    this.isLoading = true;
    return this.apiService.getWrkrsCompTableModels(this.orgLevel.id)
      .then((container: WorkCompTableTypeModel[]) => {
        this.records = container;
        this.prohibitedCodeValues = _.map(this.records, (m: WorkCompTableTypeModel) => m.codeDefinition.code);
        this.refreshGrid();
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public addType(): void {
    let model: WorkCompTableTypeModel = new WorkCompTableTypeModel();
    model.codeDefinition = new WcCodeDefinition();
    this.gridState.closeEditor(this.kendoGrid);
    this.kendoGrid.addRow(model);
  }

  private onCodeTypeChange(codeType, codeDefinition): void {
    this.prohibitedCodeValues = [];
    for(let i in this.records){
      if(this.records[i].codeDefinition.codeType == codeType){
        this.prohibitedCodeValues.push(this.records[i].codeDefinition.code)
      }
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

}

import * as _ from 'lodash';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserLoginSessionInfo } from '../../models/user-login-management/user-session-info';
import { UserLoginManagementApiService } from '../../services';

import { process, State } from '@progress/kendo-data-query';
import { ExcelExportData, Workbook, WorkbookSheet, WorkbookSheetRow } from '@progress/kendo-angular-excel-export';
import { KendoGridStateHelper } from '../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { KendoGridCustomSelectionHelper } from '../../../common/models/kendo-grid-helpers/kendo-grid-custom-selection-helper';
import { unsubscribe } from '../../../core/decorators/unsubscribe-decorator';
import { IApplicationConfig, appConfig } from '../../../app.config';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import { ModalService } from '../../../common/services/modal/modal.service';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import * as moment from 'moment';

@Component({
    moduleId: module.id,
    selector: 'slx-user-login-management',
    templateUrl: 'user-login-management.component.html',
    styleUrls: ['user-login-management.component.scss']
})

export class UserLoginManagement implements OnInit, OnDestroy {

    @ViewChild('kendoGrid')
    public kendoGrid: GridComponent;

    public state: {
        isLoading: boolean;
        isActiveOnly: boolean;
        hasSelected: boolean;
    };

    private unsub = new Subject<void>();
    private subscriptions: Subscription;

    private refreshRate: number = 30000;
    private autoRefreshRunning: boolean;
    private sessions: UserLoginSessionInfo[];

    public gridState: KendoGridStateHelper<UserLoginSessionInfo>;
    public pageSize: number = 50;
    public selectionHelper: KendoGridCustomSelectionHelper<UserLoginSessionInfo>;

    public appConfig: IApplicationConfig;

    @unsubscribe()
    private gridStateSubscription: Subscription;
    @unsubscribe()
    private selectionHelperSubscription: Subscription;

    private destroyed: boolean;

    constructor(private api: UserLoginManagementApiService, private modalService: ModalService, private notificationsService: NotificationsService) {

        this.appConfig = appConfig;

        this.state = {
            isLoading: false,
            isActiveOnly: true,
            hasSelected: false
        };
        this.gridState = new KendoGridStateHelper<UserLoginSessionInfo>();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);

        this.gridState = new KendoGridStateHelper<UserLoginSessionInfo>();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'userName', dir: 'asc' }
        ];
    }

    public ngOnInit() {
        this.gridStateSubscription = this.gridState.onRefreshGrid.subscribe((state: State) => {
            this.refreshGrid();
        });
        this.selectionHelperSubscription = this.selectionHelper.onSelectionChanged.subscribe(() => {

            if (this.selectionHelper.selectionLength > 0) {
                this.state.hasSelected = true;
            } else {
                this.state.hasSelected = false;
            }
        });
        this.loadSessions();
    }

    public logOffSelected(): void {

        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
            'Confirmation',
            'Are you sure you want to log-off selected users?',
            this.modalService,
            (result: boolean) => {
                if (result) {
                    const users = _.map(this.selectionHelper.selection, s => s.userId);
                    if (users.length > 0) {
                        this.api.logOffSelected(users)
                        .then(() => {
                            this.notificationsService.success('Users logged out');
                            this.stopTimer();
                            this.loadSessions();
                        });
                    }
                }
            }, options);
    }

    public ngOnDestroy(): void {
        this.stopTimer();
        this.destroyed = true;
    }

    public onDisplaySettingsChanged(): void {
        this.stopTimer();
        this.loadSessions();
    }

    public isVisible(column: string): boolean {
        if (column == 'status') return !this.state.isActiveOnly;
        return true;
    }


    public allData(): () => ExcelExportData {

        return () => {
            const cleanState: State = {
                filter: this.gridState.state.filter,
                group: this.gridState.state.group,
                sort: this.gridState.state.sort,
                skip: 0,
                take: this.sessions.length
            };

            const result: ExcelExportData = {
                data: process(this.sessions, cleanState).data
            };

            return result;
        }
    }


    public exportToExcel(): void {
        this.kendoGrid.saveAsExcel();
    }

    public exportToPdf(): void {
        this.kendoGrid.saveAsPDF();
    }

    public onExcelExport(e: any): void {
        const rows = e.workbook.sheets[0].rows;
        rows.forEach((row) => {
            if (row.type === 'data') {
                let lastLogin = row.cells[6].value;
                let createdon = new Date(row.cells[4].value);
                row.cells[4].value = moment(createdon).format('MM/DD/YYYY hh:mm A');
                let lastlogin = new Date(row.cells[6].value);
                row.cells[6].value = moment(lastlogin).format('MM/DD/YYYY hh:mm A');
                row.cells[7].value = dateTimeUtils.formatDuration(moment.duration(row.cells[7].value));
                row.cells.forEach((cell, index) => {
                    if (_.isNil(lastLogin)) {
                        if (index >= 5) {
                            cell.value = 'N/A';
                        }
                        if (index == 2) {
                            cell.value = 'N';
                        }
                    } else {
                        if (index == 2) {
                            cell.value = cell.value ? 'Y' : 'N';
                        }
                    }
                });
            }
        });
    }

    private startTimer() {
        this.stopTimer();
        this.unsub = new Subject<void>();
        this.subscriptions = new Subscription();
        this.subscriptions.add(
            timer(0, this.refreshRate)
                .pipe(
                    tap((x) => console.log(x)),
                    takeUntil(this.unsub),
                    switchMap(() => this.loadSessions())
                ).subscribe()
        );
        this.autoRefreshRunning = true;
    }

    private loadSessions(): Promise<void> {
        this.state.isLoading = true;
        return this.api.getUserSessions(this.state.isActiveOnly).then(managementInfo => {

            let selectionIds = [];
            let restoreSelection = false;
            if (this.sessions && this.sessions.length === managementInfo.sessions.length) {

                _.each(this.sessions, s => {
                    _.each(managementInfo.sessions, ns => {
                        if (s.userId === ns.userId) {
                            ns.isSelected = s.isSelected;
                        }
                    });
                });

                restoreSelection = true;
                selectionIds = _.map(_.filter(managementInfo.sessions, s => s.isSelected), s => s.userId);

            } else {
                this.gridState.state.skip = 0;
                this.selectionHelper.clearSelection();
            }

            this.sessions = managementInfo.sessions;

            this.refreshGrid();

            if (restoreSelection) {
                const selection = _.filter(this.sessions, s => selectionIds.indexOf(s.userId) != -1);
                _.each(selection, s => s.isSelected = true);
                this.selectionHelper.restoreSelection(selection);
            }


            if (this.refreshRate != managementInfo.refreshRate) {
                this.stopTimer();
                this.refreshRate = managementInfo.refreshRate;
            }
            if (!this.autoRefreshRunning && !this.destroyed) {
                this.startTimer();
            }
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            this.state.isLoading = false;
        });
    }

    private stopTimer() {

        if (this.unsub) {
            this.unsub.next();
        }

        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
        this.autoRefreshRunning = false;
    }

    private refreshGrid(): void {
        if (!this.sessions) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.sessions, this.gridState.state);
        this.selectionHelper.view = this.gridState.view;
    }

}

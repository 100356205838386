import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from './../../../../app.config';
import { Injectable } from '@angular/core';
import { Redirect2PageService } from '../redirect-to-page/redirect-to-page.service';
import { SessionService } from '../../../../core/services/session/session.service';
import { EmployeeDefinitionsApiService } from '../../employee/employee-definitions-api.service';
import { EmployeeShortInfo } from '../../../../organization/models/index';
import { BenAdminMenuItemState } from './ben-admin-menu-item-state';
import { BenAdminMenuMapService } from './ben-admin-menu-map.service';

@Injectable()
export class BenAdminMenuManagementService {

    public appConfig: IApplicationConfig;
    private empInfo: EmployeeShortInfo;

    constructor(private redirectService: Redirect2PageService,
        private sessionService: SessionService,
        private employeeDefinitionService: EmployeeDefinitionsApiService) {
        this.appConfig = appConfig;
    }

    public async initialize(): Promise<void> {
        this.empInfo = null;
        let user = this.sessionService.getUser();
        if (user && user.linkedEmpId) {
            this.empInfo = await this.employeeDefinitionService.getEmployeeShortInfo(user.linkedEmpId);
                // .then((info: EmployeeShortInfo) => {
                //     this.empInfo = info;
                // });
        }

    }

    public isBenAdminItem(name: string): boolean {
        return name === appConfig.extraApplicationsInfo.benAdmin.name;
    }

    public openBswift(orgLevelId: number = 0): void {
        let jwt = this.sessionService.getToken();
        this.redirectService.RedirectByGet(`${this.appConfig.api.url}/${this.appConfig.api.version}/bswift?token=${jwt}`, '_blank');
    }

    public getBenAdminMenuItemState(): BenAdminMenuItemState {
        let benAdminMenuItemState = new BenAdminMenuItemState();
        benAdminMenuItemState.IsEmployeeMappedToUser = !!this.empInfo;
        benAdminMenuItemState.EmployeeRecordHasSSN = !!this.empInfo ? !(this.empInfo.ssn === '' || _.isNil(this.empInfo.ssn)) : null;
        benAdminMenuItemState.EmployeeRecordHasDateOfBirth = !!this.empInfo ? !(_.isNil(this.empInfo.dateOfBirth) || !_.isDate(this.empInfo.dateOfBirth)) : null;
        return benAdminMenuItemState;
    }

    public getAccessDeniedMessage(): string {
        return this.appConfig.extraApplicationsInfo.benAdmin.accessDeniedMessage;
    }
}

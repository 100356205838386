
import {filter} from 'rxjs/operators';
import { IndividualScheduleNightShiftService } from './../../services/individual-schedule/individual-schedule-night-shift.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription ,  Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { select } from '@angular-redux/store';
import { LookupApiService, LookupService } from '../../../organization/services/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { IndSchEmployeeSchedulesRequest } from '../../models/index';
import {
  IndividualScheduleApiService, IndividualScheduleManagementService, IndividualScheduleEmpManagementService
} from '../../services/index';
import { unsubscribe, destroyService, mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { IIndividualScheduleState, IOrgLevelIndividualScheduleState } from '../../store/index';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { ScheduleCycle } from '../../../organization/models/index';
import { IControlState, StateResetTypes } from '../../../core/models/index';
import { EmployeeSearchBase } from '../../../employee/employee/services/index';
import { PayCycleEmployeeProvider } from '../../services/index';
import { scheduleMicrotask } from '../../../core/utils';

@Component({
  moduleId: module.id,
  selector: 'slx-individual-schedule',
  templateUrl: 'individual-schedule.component.html',
  styleUrls: ['individual-schedule.component.scss'],
  providers: [IndividualScheduleManagementService, IndividualScheduleEmpManagementService, StateManagementService]
})

export class IndividualScheduleComponent implements OnInit, OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @select(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  @select(['sidebar', 'isLeftSidebarHidden'])
  public isLeftSidebarHidden: Observable<boolean>;

  @mutableSelect(['sidebar', 'isLeftSideNavOpen'])
  public isLeftSideNavOpen: Observable<boolean>;

  public selectedOrgLevel: OrgLevel;

  public state: {
    isEmployeeLoading: boolean;
    isScheduleLoading: boolean;
    isNightInfoLoading: boolean;
    isLeftSideNavBarOpen:boolean;
  };
  private employeeId: number;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private uiLockSubscription: Subscription;
  @unsubscribe()
  private nightShiftSubscription: Subscription;
  @destroyService()
  public individualScheduleManagementService: IndividualScheduleManagementService;
  @destroyService()
  private individualScheduleEmpManagementService: IndividualScheduleEmpManagementService;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  @unsubscribe()
  private isLeftSidebarOpenSubscripion: Subscription;
  public scheduleCycles: ScheduleCycle[];
  public selectedScheduleCycle: ScheduleCycle;
  private scheduleCycleControlKey: string = 'scheduleCycle';

  private get weeksCount(): number {
    if (!this.selectedScheduleCycle) return 0;
    let weeksCount: number = this.selectedScheduleCycle.endDate.diff(this.selectedScheduleCycle.startDate, 'days');
    weeksCount = Math.ceil(weeksCount / 7);
    return weeksCount;
  }

  constructor(
    individualScheduleManagementService: IndividualScheduleManagementService,
    individualScheduleEmpManagementService: IndividualScheduleEmpManagementService,
    private stateManagement: StateManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private lookupApiService: LookupApiService,
    private lookupService: LookupService,
    private nightShiftService: IndividualScheduleNightShiftService
  ) {
    this.individualScheduleManagementService = individualScheduleManagementService;
    this.individualScheduleEmpManagementService = individualScheduleEmpManagementService;
    this.state = {
      isEmployeeLoading: false,
      isScheduleLoading: false,
      isNightInfoLoading: false,
      isLeftSideNavBarOpen: false
    };
  }

  public ngOnInit(): void {
    this.isLeftSidebarOpenSubscripion = this.isLeftSideNavOpen.subscribe((value: boolean) => {
      scheduleMicrotask(() => { this.state.isLeftSideNavBarOpen = value; });
    });
    this.state.isLeftSideNavBarOpen = false;
    this.stateManagement.init('IndividualScheduleComponent', true);
    this.uiLockSubscription = this.individualScheduleEmpManagementService.onUILock$.subscribe((isLocked: boolean) => {
      this.state.isEmployeeLoading = isLocked;
    });
    this.nightShiftSubscription = this.nightShiftService.onLoading$.subscribe((loading: boolean) => {
      this.state.isNightInfoLoading = loading;
    });
    this.orgLevelSubscription = this.orgLevel$.pipe(
      filter((o: OrgLevel) => !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id))
      .subscribe((o: OrgLevel) => {
        this.selectedOrgLevel = o;
        this.loadPayCycles();
      });

    this.individualScheduleManagementService.loadOrgLvlEmployees$.next(this.selectedScheduleCycle);

    this.route.queryParams.subscribe(params => {
      if (params.empID) {
      let cycle: ScheduleCycle = {startDate: moment(params.startDate), id: null, departmentId: null, endDate: moment(params.endDate), name: ''};
        this.onScheduleCycleSelected(cycle);
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private loadPayCycles(): void {
    if (!this.selectedOrgLevel) {
      return;
    }
    this.lookupApiService.getScheduleCycles(this.selectedOrgLevel.id)
      .then((cycles: ScheduleCycle[]) => {
        this.scheduleCycles = cycles;
        if (!this.scheduleCycles) return;
        if (this.selectedScheduleCycle) {
          let selectedCycle: ScheduleCycle = _.find(this.scheduleCycles, (cycle: ScheduleCycle) => {
            return moment(this.selectedScheduleCycle.startDate).isSameOrAfter(cycle.startDate) && moment(this.selectedScheduleCycle.startDate).isSameOrBefore(cycle.endDate);
          });
          this.selectedScheduleCycle = selectedCycle;
        }
        if (!this.selectedScheduleCycle) {
          const date = moment().startOf('day');
          this.selectedScheduleCycle = _.find(this.scheduleCycles, ({ startDate, endDate }: ScheduleCycle) => startDate.isSameOrBefore(date) && endDate.isSameOrAfter(date));
          if (!this.selectedScheduleCycle) {
            this.selectedScheduleCycle = _.head(this.scheduleCycles);
          }
        }
        this.restorePayCycles();
        this.individualScheduleManagementService.loadOrgLvlEmployees$.next(this.selectedScheduleCycle);
      })
      .catch(() => {
      });
  }

  private savePayCycles(): void {
    if (!this.selectedScheduleCycle) {
      return;
    }
    this.stateManagement.setControlState(this.scheduleCycleControlKey,
      { value: { startDate: this.selectedScheduleCycle.startDate, endDate: this.selectedScheduleCycle.endDate } }, StateResetTypes.All);
  }

  private restorePayCycles(): void {
    let state: IControlState = this.stateManagement.getControlState(this.scheduleCycleControlKey);
    if (state.value) {
      let selectedCycle: ScheduleCycle = _.find(this.scheduleCycles, (cycle: ScheduleCycle) => {
        return moment(state.value.startDate).isSameOrAfter(cycle.startDate) && moment(state.value.startDate).isSameOrBefore(cycle.endDate);
      });
      if (selectedCycle) {
        this.selectedScheduleCycle = selectedCycle;
      }
    }
  }

  public onScheduleCycleSelected(cycle: ScheduleCycle): void {
    if(cycle !== null) {
      this.selectedScheduleCycle = cycle;
      this.savePayCycles();
      if (this.individualScheduleManagementService.showEmployeeList) {
        this.individualScheduleManagementService.loadOrgLvlEmployees$.next(this.selectedScheduleCycle);
      } else {
        this.individualScheduleManagementService.onScheduleChange(this.selectedScheduleCycle, this.weeksCount);
      }
    }
  }
}

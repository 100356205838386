
import { Injectable } from '@angular/core';
import { OrgLevel } from '../../../state-model/models/index';
import { OrgLevelApiService } from './org-level-api.service';
import { OrgLevelMapService } from './org-level-map.service';
import { OrgLevelWatchService } from './org-level-watch.service';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { Observable ,  Subscription ,  combineLatest } from 'rxjs';
import * as _ from 'lodash';

import { UserApplication, User } from '../../../state-model/models/index';
import { ManagementBaseService, UserService } from '../../../core/services/index';
import { AppUserSettingsService, ComponentStateStorageService } from '../../../common/services/index';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';
import { scheduleMicrotask } from '../../../core/utils/index';
import { StateResetTypes } from '../../../core/models/settings/reset-types';
import { ControlStateKey, IControlState } from '../../../core/models/settings/component-states';


@Injectable()
export class OrgLevelManagementService extends ManagementBaseService<OrgLevel[], any> {

  public selectedOrganizationLevel: OrgLevel = null;
  public noData: boolean;

  private orgTreeLoaded: boolean;
  private readonly selectedOrgLevelGroup = 'LastOrgLevel';
  private readonly componentId = 'OrganizationStructureComponent';
  private readonly selectedOrgLevelResetType: StateResetTypes = StateResetTypes.None;

  //@unsubscribeInService()
  private orgLevelSelectedByIdSubscription: Subscription;
  //@unsubscribeInService()
  private orgLevelLoadedSubscription: Subscription;
  //@unsubscribeInService()
  private applicationSubscription: Subscription;
  //@unsubscribeInService()
  private initializeSubscription: Subscription;
  private loginSubscription: Subscription;

  constructor(
    private orgLevelApiService: OrgLevelApiService,
    private orgLevelWatchService: OrgLevelWatchService,
    private userService: UserService,
    private stateManagement: ComponentStateStorageService,
    private appStateBusService: ApplicationStateBusService,
    private orgLevelMapService: OrgLevelMapService
  ) {
    super();
    this.stateManagement.restoreServerControlsStorage(this.componentId).then(() => {
      this.onReloadOrgLevels();
      this.orgLevelSelectedByIdSubscription = this.appStateBusService.orgLevelIdSelected$.subscribe((id: number) => {
        this.orgLevelWatchService.getOrgLevelByIdSafe(id)
          .then((ogLevel: OrgLevel) => {
            this.onOrganizationLevelChanged(ogLevel);
          });
      });
    });

    this.applicationSubscription = this.appStateBusService.reloadOrgLevels$.subscribe(() => {
      this.onReloadOrgLevels();
    });

    this.loginSubscription = this.appStateBusService.login$.subscribe((isAliasChanged: boolean) => {
      this.orgLevelWatchService.resetOrgLevelTree();
      this.stateManagement.restoreServerControlsStorage(this.componentId, true).then(() => {
        this.onReloadOrgLevels();
      });
    });
  }

  public onReloadOrgLevels(): void {
    this.refresh();
  }

  public refresh(): void {
    this.onLoadStatusChanged(true);
    this.orgLevelApiService.getOrganizationTree()
      .then((data: OrgLevel[]) => {
        this.orgTreeLoaded = true;
        this.onLoadStatusChanged(false);
        this.noData = (!data || data.length === 0);
        if (!this.noData) {
          this.orgLevelWatchService.setOrgLevelTree(data);
          this.appStateBusService.loadedOrgLevels(data);
          this.orgLevelsLoaded(data);
        } else {
          this.appStateBusService.loadedOrgLevels(data);
        }
      });
  }

  public onOrganizationLevelChanged(orgLevel: OrgLevel): void {
    this.onOrganizationLevelChangedInternal(orgLevel, true);
  }

  public getLastSelectedOrgLevel(): OrgLevel {
    let state: IControlState = this.stateManagement.getControlState(this.componentId, this.selectedOrgLevelGroup);
    if (state && state.value) {
      const orgLevel = this.orgLevelMapService.mapToOrgLevel(state.value);
      return orgLevel;
    }
    return null;
  }

  private onOrganizationLevelChangedInternal(orgLevel: OrgLevel, save: boolean): void {
    if (save &&
      (!this.selectedOrganizationLevel && orgLevel
        || this.selectedOrganizationLevel && !orgLevel
        || (this.selectedOrganizationLevel && orgLevel && this.selectedOrganizationLevel.id !== orgLevel.id)
      )
    ) {
      this.setLastSelectedOrgLevel(orgLevel);
    }

    this.selectedOrganizationLevel = orgLevel;
    this.orgLevelWatchService.selectOrgLevel(this.selectedOrganizationLevel);
    this.appStateBusService.selectOrgLevel(this.selectedOrganizationLevel);
  }

  private orgLevelsLoaded(orgLevelTree: OrgLevel[]): void {

    // TODO if selected orglevel should be global across applications - we need implement restore orglevel here
    let lastOrgLevel = this.getLastSelectedOrgLevel();
    if (lastOrgLevel && lastOrgLevel.id) {
      lastOrgLevel = this.orgLevelWatchService.getOrgLevelById(lastOrgLevel.id);
    } else {
      lastOrgLevel = _.first(orgLevelTree);
    }
    this.appStateBusService.restoredLastOrgLevel(lastOrgLevel);

    /*
    this.orgLevelApiService.getLastSelectedLevel(this.organizationTreeContext)
      .then((result: OrgLevel) => {
        this.onLoadStatusChanged(false);
        this.onOrganizationLevelChanged(result);
      }).catch((error: any) => {
        this.onLoadStatusChanged(false);
      });*/
  }


  private setLastSelectedOrgLevel(orgLevel: OrgLevel): void {
    this.stateManagement.setControlState(this.componentId, this.selectedOrgLevelGroup,
      {
        value: this.orgLevelMapService.mapOrgLevelDTO(orgLevel)
      }, this.selectedOrgLevelResetType);
  }

}

<slx-spinner [show]="isLoading">
  <div class="modal-body">
    <div>
      <div class="compose-message medium">
        <div class="compose-message-text">By using the SmartLinx Application, you agree to the <a
              href="https://www.smartlinx.com/sms-terms/" target="_blank">Terms and Conditions.</a> Please
          remember, conversations are visible to users with rights to the employee(s).
          <br/><br/>Note, if needing to send an SMS to a large group of employees, please navigate to the Actions Menu from the Employee List.
        </div>
      </div>
    </div>
    <div>
      <div class="providers-tabs flex">
        <div class="providers-tabs__slider providers-tabs__slider_in_compose">
          <ul #list class="providers-tabs__list providers-tabs__list_in_compose flex flex-direction-row">
            <li class="providers-tabs__item providers-tabs__item_in_compose tabs_item_SMS number-lebels" *ngIf="(!isExpanded || (isExpanded && isActiveMenu(0))) && canSendSMS" [ngClass]="{
               'active':isActiveMenu(0),
               'disabled':!isActiveMenu(0)
             }" (click)="onClickTab(0)">
              <em class="fas fa-envelope font-icon slx-line-tabs__icon number-color"></em>
              <span class="header-title__text">
                SMS
              </span>
            </li>
            <li class="providers-tabs__item providers-tabs__item_in_compose tabs_item_Slate number-lebels" *ngIf="(!isExpanded || (isExpanded && isActiveMenu(1))) && canSendSlate" [ngClass]="{
               'active':isActiveMenu(1),
               'disabled':!isActiveMenu(1)
             }" (click)="onClickTab(1)">
              <em class="fas fa fa-sticky-note font-icon slx-line-tabs__icon number-color"></em>
              <span class="header-title__text">
                Slate
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="activeTab == 0">
      <div class="search-container">
        <div class="toText pd-rt-0 mr">
          <span>To:</span>
        </div>
        <div class="search pd-0">
          <kendo-multiselect #multiselectText
                             spellcheck="false"
                             autocomplete="off"
                             class="slx-msg-multiselect-text searchlist-height"
                             [clearButton] = "false"
                             [data]="employeeSMSContacts"
                             [(ngModel)]="selectEmployeeSMSContacts"
                             [textField]="'employeeName'"
                             [valueField]="'employeeId'"
                             placeholder="Search by name or phone number"
                             [filterable]="true"
                             (filterChange)="handleFilter($event)"
                             [itemDisabled]="itemDisabled">
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <div class="employee-row">
                <div class="employee-list" text-align="center">
                  <slx-msg-grid-thumbnail-src [mobilePhoneNumber]="dataItem.mobilePhoneNumber" [empOptIn]="dataItem.empOptIn" [employeeId]="dataItem.employeeId" [employeeName]="dataItem.employeeName" [parent]="'composeNewModal'"></slx-msg-grid-thumbnail-src>
                </div>
                <div class="employee-info-list">
                  <div class="">
                    <div class="employee-Name">
                      <span [ngClass]="!dataItem.mobilePhoneNumber || dataItem.empOptIn!=1 ? 'emp-disable': ''">{{dataItem.employeeName}}</span>
                      <span class="exclamation-circle"
                            *ngIf="!dataItem.mobilePhoneNumber">
                        <em class="fas fa-exclamation-circle"></em> Mobile number not available
                      </span>
                      <span class="exclamation-circle"
                            *ngIf="dataItem.mobilePhoneNumber && dataItem.empOptIn == 0">
                        <em class="fas fa-exclamation-circle"></em> Opted Out
                      </span>
                      <span class="exclamation-circle"
                            *ngIf="dataItem.mobilePhoneNumber && dataItem.empOptIn == 2">
                        <em class="fas fa-exclamation-circle"></em> Not responded to the Opt. In Request
                      </span>
                    </div>
                  </div>
                  <div [ngClass]="!dataItem.mobilePhoneNumber || dataItem.empOptIn!=1 ? 'emp-disable': ''">
                    <div class="emp_details"
                         *ngIf="!dataItem.mobilePhoneNumber">
                      <strong>Position: </strong> {{dataItem.jobDescription}} |
                      <strong>Facility​: </strong> {{dataItem.organizationName}} |
                      <strong>Department </strong> {{dataItem.departmentName}} |
                      <strong class="mobileMissing-text">Mobile:-</strong>
                    </div>
                    <div class="emp_details"
                         *ngIf="dataItem.mobilePhoneNumber" x-ms-format-detection="none">
                      <strong>Position:</strong> {{dataItem.jobDescription}} |
                      <strong>Facility​: </strong> {{dataItem.organizationName}} |
                      <strong>Department: </strong>{{dataItem.departmentName}} |
                      <strong>Mobile: </strong> {{dataItem.mobilePhoneNumber}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-multiselect>
        </div>
      </div>
      <div class="col-sm-12 full-width-textarea">
        <textarea #msg1ref [ngModelOptions]="{ standalone: true }" [(ngModel)]="message1" (ngModelChange)="validationMessage()"
                  class="border-hidden msg-box" spellcheck="true" placeholder="Enter your text" data-gramm="true">
         </textarea>
        <span class="msgcount">{{this.messageCount}}/{{maxCharPerSms}}</span>
      </div>
      <div class="no-of-segment" *ngIf="message1">
        <span>{{getSegmentCount()}} </span>
      </div>
    </div>
    <div *ngIf="activeTab == 1">
      <div class="search-container">
        <div class="toText pd-rt-0 mr">
          <span>To:</span>
        </div>
        <div class="search pd-0">
          <kendo-multiselect #multiselectText
                             spellcheck="false"
                             autocomplete="off"
                             [clearButton] = "false"
                             class="slx-msg-multiselect-text searchlist-height"
                             [data]="employeeSMSContacts"
                             [(ngModel)]="selectEmployeeSlateContacts"
                             [textField]="'employeeName'"
                             [valueField]="'employeeId'"
                             placeholder="Search by name or phone number"
                             [filterable]="true"
                             (filterChange)="handleFilter($event)"
                             [itemDisabled]="slateEmployeeDisabled">
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <div class="employee-row">
                <div class="employee-list" text-align="center">
                  <slx-msg-grid-thumbnail-src [mobilePhoneNumber]="dataItem.mobilePhoneNumber" [employeeId]="dataItem.employeeId" [employeeName]="dataItem.employeeName" [parent]="'composeNewModal'"></slx-msg-grid-thumbnail-src>
                </div>
                <div class="employee-info-list">
                  <div class="">
                    <div class="employee-Name">
                      <span [ngClass]="dataItem.badgeId == 0 ? 'emp-disable': ''">{{dataItem.employeeName}}</span>
                      <span class="exclamation-circle"
                            *ngIf="!!!dataItem.badgeId">
                        <em class="fas fa-exclamation-circle"></em> Badge Id not available.
                      </span>
                    </div>
                  </div>
                  <div [ngClass]="dataItem.badgeId == 0 ? 'emp-disable': ''">
                    <div class="emp_details" x-ms-format-detection="none">
                      <strong>Position:</strong> {{dataItem.jobDescription}} |
                      <strong>Facility​: </strong> {{dataItem.organizationName}} |
                      <strong>Department: </strong>{{dataItem.departmentName}} |
                      <div *ngIf="!!dataItem.mobilePhoneNumber">
                        <strong>Mobile: </strong> {{dataItem.mobilePhoneNumber}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-multiselect>
        </div>
      </div>
      <div class="search-container subject-container">
        <div class="toText pd-rt-0 mr">
          <span>Subject:</span>
        </div>
        <div class="search pd-0">
          <input type="text" class="form-control subject-field" [(ngModel)]="subject" maxlength="150" required>
        </div>
      </div>
      <div class="col-sm-12 full-width-textarea">
        <textarea class="border-hidden msg-box" spellcheck="true" [(ngModel)]="message" placeholder="Enter Text Here" data-gramm="true" rows="7" (ngModelChange)="validateSlateMessage()"></textarea>
        <span class="msgcount">{{this.messageCount}}/{{maxCharPerSms}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn red" (click)="cancel()">
      <em class="fa fa-times" aria-hidden="true"></em>
      <span class="btn-text__padding">Cancel</span>
    </button>
    <button class="btn green" (click)="sendMessage()"
            [disabled]="activeTab == 0 ? (selectEmployeeSMSContacts.length == 0 ||this.message1.length == 0): (selectEmployeeSlateContacts.length == 0 || this.subject.trim().length == 0 || this.message.trim().length == 0)">
      <span class="btn-text__padding"><em class="fas fa-paper-plane"></em>Send <span *ngIf="activeTab == 0">SMS</span><span *ngIf="activeTab == 1"> to Slate</span></span>
    </button>
  </div>
</slx-spinner>

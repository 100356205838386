export * from './approval-required-codes/approval-required-codes-api.service';
export * from './approval-required-codes/approval-required-codes-map.service';
export * from './overtime-approvals/overtime-approvals-api.service';
export * from './overtime-approvals/overtime-approvals-map.service';

import { Provider } from '@angular/core';

import { ApprovalRequiredCodesApiService } from './approval-required-codes/approval-required-codes-api.service';
import { ApprovalRequiredCodesMapService } from './approval-required-codes/approval-required-codes-map.service';
import { OvertimeApprovalsApiService } from './overtime-approvals/overtime-approvals-api.service';
import { OvertimeApprovalsMapService } from './overtime-approvals/overtime-approvals-map.service';

export const services: Provider[] = [
    ApprovalRequiredCodesApiService,
    ApprovalRequiredCodesMapService,
    OvertimeApprovalsApiService,
    OvertimeApprovalsMapService
];

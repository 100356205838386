import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { commonConfig, ICommonConfig } from '../../../../../common/common.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { AttachmentFile, ReadFile } from '../../../../../organization/models/index';
import { OrgLevelWatchService } from '../../../../../organization/services/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { benefitsConfig } from '../../../benefits.config';
import { BenefitDetailsBasic, BenefitDetailsCalcMethod, BenefitDetailsConfig, BenefitDetailsEditModes, BenefitDetailsGroup, BenefitDetailsProviderEntity, BenefitDetailsProviderLineStandartEntity, BenefitDetailsTier, BenefitDetailsUserActions, BenefitEligibilityRule, BenefitEligibilityRuleStatus, BenefitEligibleEmployeesInfo, BenefitEligibleEmployeesRequest, BenefitEnrolledEmployeesRequest } from '../../../models/index';
import { BenefitEligibilityRulesManagementService } from '../../../services/benefit-eligibility-rules/index';
import { BenefitDetailsApiService, BenefitDetailsManagementService, BenefitDetailsPermissionService, BenefitDetailsStandartManagementService } from '../../../services/index';
import { BenefitDetailsEligibilityRuleDialogComponent } from '../benefit-details-eligibility-rule-dialog/benefit-details-eligibility-rule-dialog.component';
import { ModalService } from './../../../../../common/services/modal/modal.service';
import { ActivatedRoute } from '@angular/router';




@Component({
  selector: 'slx-benefit-details-benefit-info-line',
  templateUrl: './benefit-details-benefit-info-line.component.html',
  styleUrls: ['./benefit-details-benefit-info-line.component.scss'],
})
export class BenefitDetailsBenefitInfoLineComponent implements OnInit, OnDestroy {
  @ViewChild('benefitPayrollDeductionCodeMD', { static: true })
  public deduction:NgModel;
  @ViewChild('eRDeductionCode', { static: true })
  public eRdeductionCode: NgModel;
  public isEditMode: boolean;
  public group: BenefitDetailsGroup;
  public config: BenefitDetailsConfig;

  public benefitName: string;
  public benefitPlanId: string;
  public benefitPayrollDeductionCode:string = '';
  public employerDeductionCode: string = '';
  public highDeductible: boolean;
  public hasBeneficiaries: boolean;
  public benefitNote: string;
  public totalEnrolledEmployees: number = null;
  public totalEligibleEmployees: BenefitEligibleEmployeesInfo = null;
  public nonTobacco: boolean;
  public domesticPartnerEligible: boolean;
  public companyProvided: boolean;
  public acaMinCoverage: boolean;
  public acaMinValue: boolean;
  public acaPovLevelTest: boolean;
  public acaHarborCode: BenefitDetailsBasic<number, string>;
  public attachments: AttachmentFile[] = [];
  public filesToAttach: ReadFile[] = [];
  public isValidProviderTierName: boolean = true;
  public isValidProviderTierName1: boolean = true;
  public isValidERdeductionCode: boolean = true;
  public eligibleEmpData: BenefitEligibleEmployeesRequest;
  public enrolledEmpData: BenefitEnrolledEmployeesRequest;
  public selectedProvider: BenefitDetailsProviderEntity;
  public maxFiles = benefitsConfig.files.maxFiles;
  public maxFileSizeBytes = benefitsConfig.files.maxFileSizeBytes;
  public acceptedFileTypes = benefitsConfig.files.acceptedTypes;
  public pattern = /[^\w\s]|[_]/gi;
  private readonly alphanumericRegex = /^[a-zA-Z0-9 ]*$/;
  public benefitEligibilityRules: BenefitEligibilityRule[];
  public benefitEligibilityRulesNotBelongToTier: BenefitEligibilityRule[];
  public benefitRulesAlreadyUsedNames: string[] = [];
  public availableExistingBenefitRules: BenefitEligibilityRule[] = [];
  public isLoading: boolean;
  public isLoadingEligibleEmployees: boolean;
  public isLoadingEnrolledEmployees: boolean;
  public canDelete = false;
  public currentTierName: string;
  public orgLevelId: number;
  public deductionValue :boolean =false;
  public duplicateValue:boolean=false
  public deductions:any;
  eENgModel: NgModel;
  eRNgModel: NgModel;
  groupId: number;

  public get shownAcaCompliance(): boolean {
    return _.isObjectLike(this.group) && this.group.type === this.groupConfig.medical.type;
  }

  public get fileLength() {
    return this.addedFiles.length + this.attachments.length;
  }
  // public ngAfterViewInit(): void {
  //   this.deduction.control.setValidators(Validators.required)
  //     this.subscriptions.enrolledEmployeesChanged = this.deduction.statusChanges
  //     .subscribe((status: string) => {
  //       if (this.isEditMode) {
  //       this.UpdateSaveStatus(this.deduction.valid);
  //       // this.UpdateSaveStatus1(this.deduction.invalid);
  //       }
  //     });
  // }

  private UpdateSaveStatus(valid:boolean){
    if (this.isEditMode) {
      this.manService.updatedeductionCode(valid ? this.benefitPayrollDeductionCode : '');
    }
  }
  // private UpdateSaveStatus1(valid:boolean){
  //   if (this.isEditMode) {
  //     this.manService.updateSpecial(!valid );
  //   }
  // }
  public get shownEmployeesCounters(): boolean {
    return !this.isEditMode;
  }

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private selectedProviderLine: BenefitDetailsProviderLineStandartEntity;
  private selectedProviderTier: BenefitDetailsTier;
  private providerTierId: number;
  private addedFiles: ReadFile[] = [];
  private loadedEmpsFor = { orgLevelId: null, tierId: null, date: null };
  private refreshEmpInfo$ = new Subject();
  private readonly groupConfig = benefitsConfig.benefitGroupsConfig;
  public act: NgModel;
  public benefitCodeSearchText = new Subject<string>();
  public eRDeductionCodeSearchText = new Subject<string>();
  public isEmployeeEarningCode: boolean = false;
  public isEmployerEarningCode: boolean = false;
  public commonConfig: ICommonConfig;

  constructor(
    private permissionService: BenefitDetailsPermissionService,
    private commonManService: BenefitDetailsManagementService,
    public manService: BenefitDetailsStandartManagementService,
    private modalService: ModalService,
    private orgLevelWatchService: OrgLevelWatchService,
    private benefitDetailsApiService: BenefitDetailsApiService,
    private rulesManagementService: BenefitEligibilityRulesManagementService,
    private route: ActivatedRoute

  ) {
    this.eligibleEmpData = new BenefitEligibleEmployeesRequest();
    this.enrolledEmpData = new BenefitEnrolledEmployeesRequest();
  }

  public ngOnInit() {
    this.commonConfig = commonConfig;
    this.groupId = +this.route.snapshot.queryParamMap.get('groupId');
    this.subscriptions.eRDeductionCodeSearchText = this.eRDeductionCodeSearchText.pipe(debounceTime(500)).subscribe((searchValue) => {
      this.manService.employerDeductionCode = searchValue;
      this.onChangeTierERDeductionCode(searchValue);
    });

    this.subscriptions.benefitCodeSearchText = this.benefitCodeSearchText.pipe(debounceTime(500)).subscribe((searchValue) => {
      this.manService.employeeDeductionCode = searchValue;
      this.onChangeTierpayrollDeductionCode(searchValue);
    });

    this.subscriptions.refreshEmpInfo = this.refreshEmpInfo$
      .pipe(debounceTime(50))
      .subscribe(() => this.onRefreshEmployees());

    this.subscriptions.config = this.commonManService.subscribeToChangeConfig(
      (config: BenefitDetailsConfig) => (this.config = config)
    );

    this.subscriptions.calcMethod = this.commonManService.subscribeToSelectGroup(
      (group: BenefitDetailsGroup) => (this.group = group)
    );

    this.subscriptions.orgLevel = this.commonManService.subscribeToOrgLevel((o: OrgLevel) => {
      this.orgLevelId = o.id;
      this.refreshEmpInfo$.next();
    });

    this.subscriptions.provider = this.commonManService.subscribeToSelectProvider(
      (provider: BenefitDetailsProviderEntity) => {
        this.selectedProvider = provider;
      }
    );

    this.subscriptions.line = this.commonManService.subscribeToSelectProviderLine(
      (l: BenefitDetailsProviderLineStandartEntity) => {
        this.selectedProviderLine = l;
        this.enrolledEmpData.lineId = l.current.id;
        this.enrolledEmpData.startDate = l.current.line.startDate;
        this.enrolledEmpData.endDate = l.current.line.endDate;
        this.eligibleEmpData.lineDetails = l.current;
        this.enrolledEmpData.lineDetails = l.current;
        this.eligibleEmpData.planName = l.current.line.name;
        this.enrolledEmpData.planName = l.current.line.name;
        this.enrolledEmpData.lowLevelOrgId = this.commonManService.getProperOrglevelBasedOnSelectedOrglevel();
      }
    );

    this.subscriptions.calcMethod = this.manService.subscribeToChangeCalcMethod(
      (calcMethod: BenefitDetailsCalcMethod) => {
        if (_.isObjectLike(calcMethod)) {
          this.enrolledEmpData.calcMethod = calcMethod.id;
          this.eligibleEmpData.calcMethod = calcMethod.id;
        }
      }
    );

    this.subscriptions.providerTier = this.manService.subscribeToSelectProviderTier((tier: BenefitDetailsTier) => {
        this.addedFiles = [];
        this.providerTierId = tier.isDraft ? null : tier.id;
        this.selectedProviderTier = tier;
        this.benefitName = tier.name;
      this.currentTierName = tier.name;
        this.benefitPlanId = tier.planId || '';
        this.benefitPayrollDeductionCode=tier.payrollDeductionCode;
        this.employerDeductionCode = tier.employerDeductionCode;
        this.manService.employeeDeductionCode = tier.payrollDeductionCode;
        this.manService.employerDeductionCode = tier.employerDeductionCode;
        this.isEmployeeEarningCode = tier.isEmployeeEarningCode || false;
        this.isEmployerEarningCode = tier.isEmployerEarningCode || false;
        this.highDeductible = tier.highDeductableTier || false;
        this.hasBeneficiaries = tier.hasBeneficiaries || false;
        this.benefitNote = tier.notes || '';
        this.nonTobacco = tier.nonTobacco || false;
        this.domesticPartnerEligible = tier.domesticPartnerEligible || false;
        this.companyProvided = tier.companyProvided || false;
        this.acaMinCoverage = tier.acaMinCoverage || false;
        this.acaMinValue = tier.acaMinValue || false;
        this.acaPovLevelTest = tier.acaPovLevelTest || false;
        this.acaHarborCode = tier.acaHarborCode || null;
        this.attachments = tier.attachments || [];
        this.filesToAttach = tier.filesToAttach || [];
        this.eligibleEmpData.tierId = tier.id;
        this.enrolledEmpData.tierId = tier.id;
        this.eligibleEmpData.tierDetails = tier;
        this.enrolledEmpData.tierDetails = tier;

        this.enrolledEmpData.employeeContributionAmount = tier.empContribution;
        this.enrolledEmpData.employerContributionAmount = tier.erContribution;

        if (tier.isDraft) {
        this.isValidProviderTierName = this.manService.updateProviderTierName(
          this.selectedProviderTier.id,
          this.benefitName
        );
        } else {
          this.isValidProviderTierName = true;
        }
        if (tier.isDraft) {
          this.manService.updateProviderTierEECode(
            this.selectedProviderTier.id,
            this.benefitPayrollDeductionCode
          );
          this.manService.updateProviderTierERCode(
            this.selectedProviderTier.id,
            this.employerDeductionCode
          );
          } else {
            this.manService.providerTierEECodeIsUnique = true;
            this.manService.providerTierERCodeIsUnique = true;
          }
      this.benefitEligibilityRules = [];
      this.benefitRulesAlreadyUsedNames = [];
      this.availableExistingBenefitRules = [];

        if (this.providerTierId) {
        this.rulesManagementService.loadBenefitEligibilityRulesByTierId(
          this.providerTierId,
          this.currentTierName,
          this.isEditMode
        );
          this.rulesManagementService.loadBenefitEligibilityRulesNotBelongsToTier(this.providerTierId);
      } else {
        this.benefitEligibilityRules = this.rulesManagementService.getExistingBenefitEligibilityRulesByTierName(
          this.currentTierName
        );
        }
        this.onBenefitEligibilityRulesUpdated();
        this.refreshEmpInfo$.next();
        this.onChangeTierpayrollDeductionCode(this.benefitPayrollDeductionCode);
        this.onChangeTierERDeductionCode(this.employerDeductionCode);
      });

    this.subscriptions.benefitEligibleInfo = this.manService.subscribeToBenefitEligibleEmployeesInfo(
      (info: BenefitEligibleEmployeesInfo) => {
      this.totalEligibleEmployees = info;
      }
    );

    this.subscriptions.benefitenrolledInfo = this.manService.subscribeToBenefitEnrolledEmployeesInfo(
      (count: number) => {
      this.totalEnrolledEmployees = count || 0;
      }
    );

    this.subscriptions.planDate = this.manService.subscribeToChangeProviderPlanDate((d: Date) => {
        this.enrolledEmpData.date = d;
        this.eligibleEmpData.date = d;
        this.refreshEmpInfo$.next();
      });

    this.subscriptions.editMode = this.permissionService.subscribeToEditMode((v: BenefitDetailsEditModes) => {
        this.isEditMode = v.providerInfo;
        this.eligibleEmpData.canEnroll = this.isEditMode;
        this.enrolledEmpData.canEnroll = this.isEditMode;
      });

    this.subscriptions.userActions = this.permissionService.subscribeToUserActions((v: BenefitDetailsUserActions) => {
        this.canDelete = v.canDelete;
        this.eligibleEmpData.isUserCanEnroll = v.canEnroll;
      	this.eligibleEmpData.canMapPayroll = v.canMapPayroll;
      	this.enrolledEmpData.canMapPayroll = v.canMapPayroll;
    });

    this.subscriptions.benefitRulesByTierId = this.rulesManagementService.subscribeToLoadBenefitRulesByTierId(
      (rulesPair: KeyValuePair<string, BenefitEligibilityRule[]>) => {
        if (rulesPair.key === this.currentTierName) {
          this.benefitEligibilityRules = rulesPair.value;
          this.onBenefitEligibilityRulesUpdated();
      }
      }
    );

    this.subscriptions.benefitRulesNotBelongsToTier = this.rulesManagementService.subscribeToLoadBenefitRulesNotBelongsToTier(
      (rules: BenefitEligibilityRule[]) => {
        this.benefitEligibilityRulesNotBelongToTier = rules;
        this.onBenefitEligibilityRulesUpdated();
      }
    );

    this.subscriptions.Loading = this.rulesManagementService.subscribeToLoading((isLoading) => {
      this.isLoading = isLoading;
    });

    this.subscriptions.forceRefresh = this.manService.subscribeToChangeupdateEmployees((value) => {
     this.onRefreshEmployees(value);
    });

    this.subscriptions.loadingEligibleEmployees = this.commonManService.subscribeToEligibleEmployeesLoading(
      (isLoadingEligibleEmployees) => {
       this.isLoadingEligibleEmployees = isLoadingEligibleEmployees;
      }
    );

    this.subscriptions.loadingEnrolledEmployees = this.commonManService.subscribeToEnrolledEmployeesLoading(
      (isLoadingEnrolledEmployees) => {
      this.isLoadingEnrolledEmployees = isLoadingEnrolledEmployees;
  }
    );
  }
  public special: boolean;

  // }
  checkForSpecialCharacters(value: string): boolean {
    return this.alphanumericRegex.test(value);
  }
  public hasSpecialCharacters: boolean;
  // onInputChange() {
  //   if (this.deduction) {
  //     // this.deduction.control.setErrors(
  //     //   this.checkForSpecialCharacters(this.benefitPayrollDeductionCode) ? null : { specialCharacters: true }

  //     // );
  //     this.hasSpecialCharacters = this.checkForSpecialCharacters(this.benefitPayrollDeductionCode) ? true : false;
  //     this.UpdateSaveStatus(this.hasSpecialCharacters);
  //   }
  // }
  public ngOnDestroy(): void {
    this.rulesManagementService.clearExistingMappedRules();
  }

  public onSearch(ngModel: NgModel) {
    this.eENgModel = ngModel;
    this.benefitCodeSearchText.next(this.benefitPayrollDeductionCode);
    this.eRDeductionCodeSearchText.next(this.employerDeductionCode);
  }

  public onERDeductionCodeChange(ngModel: NgModel){
    this.eRNgModel = ngModel;
    this.eRDeductionCodeSearchText.next(this.employerDeductionCode);
    this.benefitCodeSearchText.next(this.benefitPayrollDeductionCode);
  }

  public onChangeTierName(ngModel: NgModel): void {
    this.isValidProviderTierName = this.manService.updateProviderTierName(
      this.selectedProviderTier.id,
      this.benefitName
    );
    const errors = ngModel.control.errors || {};
    if (this.isValidProviderTierName) {
      delete errors.uniqueName;
      ngModel.control.setErrors(_.size(errors) > 0 ? errors : null);
      this.rulesManagementService.updateRuleTierName(this.benefitName, this.currentTierName);
      this.currentTierName = this.benefitName;
    } else {
      errors.uniqueName = true;
      ngModel.control.setErrors(errors);
    }
  }

  public onChangeTierPlanId(): void {
    if (this.selectedProviderTier.planId !== this.benefitPlanId) {
      this.selectedProviderTier.planId = this.benefitPlanId;
      this.manService.updateProviderTier();
    }
  }

  public onChangeEECodeType(): void {
    if (this.selectedProviderTier.isEmployeeEarningCode !== this.isEmployeeEarningCode) {
      this.selectedProviderTier.isEmployeeEarningCode = this.isEmployeeEarningCode;
      this.onChangeTierpayrollDeductionCode(this.benefitPayrollDeductionCode);
      this.onChangeTierERDeductionCode(this.employerDeductionCode);
      this.manService.updateProviderTier();
    }
  }

  public onChangeERCodeType(): void {
    if (this.selectedProviderTier.isEmployerEarningCode !== this.isEmployerEarningCode) {
      this.selectedProviderTier.isEmployerEarningCode = this.isEmployerEarningCode;
      this.onChangeTierpayrollDeductionCode(this.benefitPayrollDeductionCode);
      this.onChangeTierERDeductionCode(this.employerDeductionCode);
      this.manService.updateProviderTier();
    }
  }

  public containsSpecialCharacters(value: string): boolean {
    const regex = commonConfig.deductionCodePattern;
    return !regex.test(value);
  }

  public async onChangeTierpayrollDeductionCode(searchValue) {
    searchValue = searchValue ? searchValue.toLowerCase() : '';
    this.manService.deductions = await this.benefitDetailsApiService.getdeductionCodeInfo(this.groupId, searchValue, this.manService.eEdeductionType);
    this.manService.duplicateValue = this.manService.deductions.isDuplicateDeductionCode;
    this.manService.eeValidationMessage = this.manService.deductions.validationMessage ? this.manService.deductions.validationMessage : '';
    this.manService.updateProviderTierEECode(this.selectedProviderTier.id, this.benefitPayrollDeductionCode);
    this.manService.updateProviderTierERCode(this.selectedProviderTier.id, this.employerDeductionCode);
    if(this.containsSpecialCharacters(this.benefitPayrollDeductionCode)){
      this.UpdateSaveStatus(false);
    }
    else if(this.manService.duplicateValue){
      this.UpdateSaveStatus(false);
    }
    else{
      this.UpdateSaveStatus(true);
    }
    if(this.eENgModel){
      const errors = this.eENgModel.control.errors || {};
      if (!this.manService.duplicateValue || this.manService.providerTierEECodeIsUnique) {
        delete errors.uniqueName;
        this.eENgModel.control.setErrors(_.size(errors) > 0 ? errors : null);
      } else {
        errors.uniqueName = true;
        this.eENgModel.control.setErrors(errors);
      }
    }
    this.manService.updateProviderTier();
  }

  public async onChangeTierERDeductionCode(searchValue){
    searchValue = searchValue ? searchValue.toLowerCase() : '';
    this.manService.deductions = await this.benefitDetailsApiService.getdeductionCodeInfo(this.groupId, searchValue, this.manService.eRdeductionType);
    this.manService.isDuplicateERDeductionCode = this.manService.deductions.isDuplicateDeductionCode;
    this.manService.erValidationMessage = this.manService.deductions.validationMessage ? this.manService.deductions.validationMessage : '';
    this.manService.updateProviderTierERCode(this.selectedProviderTier.id, this.employerDeductionCode);
    this.manService.updateProviderTierEECode(this.selectedProviderTier.id, this.benefitPayrollDeductionCode);
    if(this.containsSpecialCharacters(this.employerDeductionCode)){
      this.updateERDeductionCode(false);
    }
    else if(this.manService.isDuplicateERDeductionCode){
      this.updateERDeductionCode(false);
    }
    else{
      this.updateERDeductionCode(true);
    }
    if(this.eRNgModel){
      const errors = this.eRNgModel.control.errors || {};
      if (!this.manService.isDuplicateERDeductionCode || this.manService.providerTierERCodeIsUnique) {
        delete errors.uniqueName;
        this.eRNgModel.control.setErrors(_.size(errors) > 0 ? errors : null);
      } else {
        errors.uniqueName = true;
        this.eRNgModel.control.setErrors(errors);
      }
    }
    this.manService.updateProviderTier();
  }

  private updateERDeductionCode(valid:boolean){
    if (this.isEditMode) {
      this.manService.updateERDeductionCode(valid ? this.employerDeductionCode: '');
    }
  }

  private UpdateSaveStatus1(valid:boolean){
    if (this.isEditMode) {
      this.manService.updatedeductionCode(valid ?this.benefitPayrollDeductionCode:'' );
    }
  }
  public onChangeTierDeductible(): void {
    if (this.selectedProviderTier.highDeductableTier !== this.highDeductible) {
      this.selectedProviderTier.highDeductableTier = this.highDeductible;
      this.manService.updateProviderTier();
    }
  }

  public onChangeTierBeneficiaries(): void {
    if (this.selectedProviderTier.hasBeneficiaries !== this.hasBeneficiaries) {
      this.selectedProviderTier.hasBeneficiaries = this.hasBeneficiaries;
      this.manService.updateProviderTier();
    }
  }

  public onChangeTierNote(): void {
    if (this.selectedProviderTier.notes !== this.benefitNote) {
      this.selectedProviderTier.notes = this.benefitNote;
      this.manService.updateProviderTier();
    }
  }

  public onChangeTierTobacco(): void {
    if (this.selectedProviderTier.nonTobacco !== this.nonTobacco) {
      this.selectedProviderTier.nonTobacco = this.nonTobacco;
      this.manService.updateProviderTier();
    }
  }

  public onChangeTierPartner(): void {
    if (this.selectedProviderTier.domesticPartnerEligible !== this.domesticPartnerEligible) {
      this.selectedProviderTier.domesticPartnerEligible = this.domesticPartnerEligible;
      this.manService.updateProviderTier();
    }
  }

  public onChangeTierCompanyProvided(): void {
    if (this.selectedProviderTier.companyProvided !== this.companyProvided) {
      this.selectedProviderTier.companyProvided = this.companyProvided;
      this.manService.updateProviderTier();
    }
  }

  public onChangeTierAcaCoverage(): void {
    if (this.selectedProviderTier.acaMinCoverage !== this.acaMinCoverage) {
      this.selectedProviderTier.acaMinCoverage = this.acaMinCoverage;
      this.manService.updateProviderTier();
    }
  }

  public onChangeTierAcaValue(): void {
    if (this.selectedProviderTier.acaMinValue !== this.acaMinValue) {
      this.selectedProviderTier.acaMinValue = this.acaMinValue;
      this.manService.updateProviderTier();
    }
  }

  public onChangeTierPoverty(): void {
    if (this.selectedProviderTier.acaPovLevelTest !== this.acaPovLevelTest) {
      this.selectedProviderTier.acaPovLevelTest = this.acaPovLevelTest;
      this.manService.updateProviderTier();
    }
  }

  public onChangeHarbourCode(): void {
    if (!_.isEqual(this.selectedProviderTier.acaHarborCode, this.acaHarborCode)) {
      this.selectedProviderTier.acaHarborCode = this.acaHarborCode;
      this.manService.updateProviderTier();
    }
  }

  public onAddedFiles(files: ReadFile[]): void {
    this.addedFiles = _.concat(this.addedFiles, files);
    this.selectedProviderTier.filesToAttach = this.addedFiles;
    this.selectedProviderLine.hasTiersAttachments = _.size(this.selectedProviderTier.filesToAttach) > 0;
    this.manService.updateProviderTier();
  }

  public onDeletedFile(file: ReadFile): void {
    this.addedFiles = _.filter(
      this.addedFiles,
      (f) => !(f.fileName === file.fileName && f.sizeInBytes === file.sizeInBytes)
    );
    this.selectedProviderTier.filesToAttach = this.addedFiles;
    this.selectedProviderLine.hasTiersAttachments = _.size(this.selectedProviderTier.filesToAttach) > 0;
    this.manService.updateProviderTier();
  }

  public onDeletedAttachment(file: AttachmentFile): void {
    this.manService.deleteTierAttachments([file.id]);
    this.manService.updateProviderTier();
  }

  public onDownloadAttachment(file: AttachmentFile): void {
    this.manService.downloadTierAttachment(file.id);
  }

  public onRefreshEmployees(force?: boolean): void {
    if (
      (_.isFinite(this.orgLevelId) &&
        _.isFinite(this.providerTierId) &&
        _.isDate(this.eligibleEmpData.date) &&
        this.shownEmployeesCounters &&
        (this.loadedEmpsFor.orgLevelId !== this.orgLevelId ||
          this.loadedEmpsFor.tierId !== this.providerTierId ||
          !_.isEqual(this.loadedEmpsFor.date, this.eligibleEmpData.date))) ||
      force
    ) {

      this.totalEnrolledEmployees = 0;
      this.totalEligibleEmployees = null;
      this.eligibleEmpData.lowLevelOrgId = this.commonManService.getProperOrglevelBasedOnSelectedOrglevel();
      this.loadedEmpsFor.orgLevelId = this.commonManService.getProperOrglevelBasedOnSelectedOrglevel();
      this.loadedEmpsFor.tierId = this.providerTierId;
      this.loadedEmpsFor.date = this.eligibleEmpData.date;
      if (this.loadedEmpsFor.tierId) {
        this.manService.getBenefitEligibleEmployeesInfo(this.loadedEmpsFor);
        this.manService.getBenefitEnrolledEmployeesInfo(this.loadedEmpsFor);
      }
    }
  }

  public showEligibilityRuleDialog() {
    BenefitDetailsEligibilityRuleDialogComponent.openDialog(
      this.modalService,
      this.benefitRulesAlreadyUsedNames,
      this.orgLevelId,
      null,
      !this.isEditMode,
      (result: boolean, rule: BenefitEligibilityRule) => {
        if (result) {
          this.benefitEligibilityRulesAdded(rule);
          this.manService.updateCanSaveState();
        }
      }
    );
  }

  public addExistigBenefitEligibilityRule(addedRule: BenefitEligibilityRule): void {
    addedRule.status = BenefitEligibilityRuleStatus.Added;
    this.rulesManagementService.addExistigBenefitEligibilityRule(addedRule, this.benefitName);
    this.manService.updateCanSaveState();
    this.selectedProviderTier.setStateUpdated();
  }

  public benefitEligibilityRulesAdded(addedRule: BenefitEligibilityRule) {
    this.rulesManagementService.addBenefitEligibilityRule(addedRule, this.benefitName);
    this.manService.updateCanSaveState();
    this.selectedProviderTier.setStateUpdated();
  }

  public benefitEligibilityRulesUpdated(updatedRule: BenefitEligibilityRule) {
    this.rulesManagementService.updateBenefitEligibilityRule(updatedRule, this.benefitName);
    this.manService.updateCanSaveState();
    this.selectedProviderTier.setStateUpdated();
  }

  public benefitEligibilityRulesDeleted(rule: BenefitEligibilityRule) {
    this.rulesManagementService.deleteBenefitEligibilityRule(rule.id, this.benefitName);
    this.manService.updateCanSaveState();
    this.selectedProviderTier.setStateUpdated();
  }

  private onBenefitEligibilityRulesUpdated() {
    this.benefitRulesAlreadyUsedNames = this.rulesManagementService.getUsedRuleNames(
      this.benefitEligibilityRules,
      this.benefitEligibilityRulesNotBelongToTier
    );
    this.availableExistingBenefitRules = _.differenceWith(
      this.benefitEligibilityRulesNotBelongToTier,
      this.benefitEligibilityRules,
      (existingRule: BenefitEligibilityRule, selectedRule: BenefitEligibilityRule) =>
        existingRule.id === selectedRule.id && selectedRule.status !== BenefitEligibilityRuleStatus.Deleted
    );
  }

  get isDuplicate(): boolean {
    return this.manService.duplicateValue;
  }

  get isSpecialCharactersPresent(): boolean {
    return this.containsSpecialCharacters(this.benefitPayrollDeductionCode);
  }

}

<form novalidate #form="ngForm" *ngIf="employeeSectionsEssTemplate">
  <div class="row">

    <div class="col-sm-12 col-lg-2" >
        <slx-input-decorator>
            <input slx-input
              [readonly]="true"
              [ngModel]="employeeSectionsEssTemplate?.essTemplate?.essTemplateDefinition?.name"
              placeholder="Go User Template"
              name="essTemplate"
              type="text">
          </slx-input-decorator>

      <slx-input-decorator class="element" *ngIf="state.isEditMode">
        <slx-dropdown-input slx-input [lookup]="{ lookupType: 'essPasswordTypesFull' }" valueField="id" titleField="name"
          [readonly]="!state.isEditMode" [(ngModel)]="passwordType"
          name="passInput" required placeholder="Password">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>

      <slx-input-decorator class="element" *ngIf="showPasswordField">
        <input slx-input [type]="customPasswordType" required="true" name="password" placeholder="Password" [(ngModel)]="customPassword"
          [readonly]="!state.isEditMode" (focus)="onCustomPasswordFocused()"
          [server]="{ validationName: passwordValidation, 
                      parameters: [customPassword], 
                      validationAdapter: passwordValidator, 
                      componentName: 'password', 
                      form: form }">
        >
        <a class="toggle-password" *ngIf="passwordInFocus"
          (click)="togglePasswordVisibility()" [title]="!showPassword ? 'Show Password' : 'Hide Password'">
          <i class="fa" [ngClass]="{ 'fa-eye': !showPassword, 'fa-eye-slash': showPassword }" aria-hidden="true"></i>
        </a>
        <span errorMessage for="required">Value is reqired</span>
        <span errorMessage [for]="passwordValidation">Password must comply with the requirements</span>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
          <slx-checkbox-input [readonly]="!state.isEditMode" slx-input name="passwordShouldBeReseted" fieldName="passwordShouldBeReseted" caption="Change password at next login"
           [(ngModel)]="employeeSectionsEssTemplate.passwordShouldBeReseted">
          </slx-checkbox-input>
        </slx-input-decorator>
    </div>
    <div class="col-sm-12 col-lg-10 ess-groups">
        <div class="editor-option-group" *ngFor="let group of employeeSectionsEssTemplate?.essTemplate?.groups">
          <div class="group-name">{{group.name}}</div>
          <div class="template-options">
            <div *ngFor="let option of group.options">
              <slx-ess-template-option [template]="employeeSectionsEssTemplate?.essTemplate" [option]="option" [readonly]="true"></slx-ess-template-option>
            </div>
          </div>
        </div>
      </div>

  </div>
</form>

import { Component, OnInit, OnDestroy, Input, ViewChild, HostListener, ElementRef } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';
import * as _ from 'lodash';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';

import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../organization/services/index';
import { ApplicationStateBusService } from '../../services/index';
import { OrgLevelBreadcrumbModes } from '../../models/index';
import { IRouteInfo } from '../../../core/models/index';
import { Assert } from '../../../framework/index';

@Component({
  moduleId: module.id,
  selector: 'slx-org-level-breadcrumb',
  templateUrl: 'org-level-breadcrumb.component.html',
  styleUrls: ['org-level-breadcrumb.component.scss']
})
export class OrgLevelBreadcrumbComponent implements OnInit, OnDestroy {
  @Input()
  public mode: string;
  @ViewChild('orgTree', { read: ElementRef, static: true })
  public orgTree: ElementRef;
  public showOrgTree: boolean;
  public posTop: number;
  public posLeft: number;
  public iconShift: number;
  public orgLevel: OrgLevel;
  public currentPath: OrgLevel[];
  public canChangeOrgLevel: boolean;
  public userCannotChangeOrglevelMessage: string;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private orgTreeLoadedSubscription: Subscription;
  @unsubscribe()
  private routeInfoSubscription: Subscription;

  constructor(
    private orgLevelWatchService: OrgLevelWatchService,
    private applicationStateBusService: ApplicationStateBusService,
    private elementRef: ElementRef
  ) {
    this.showOrgTree = false;
    this.posTop = 0;
    this.posLeft = 0;
    this.iconShift = 0;
    this.currentPath = [];
    this.canChangeOrgLevel = true;
    this.userCannotChangeOrglevelMessage = 'You cannot change organization level on this page';
  }
  public ngOnInit(): void {
    this.orgLevelSubscription = this.applicationStateBusService.subscribeToSelectOrgLevel((o: OrgLevel) => {
      if (_.isObject(o) && _.isNumber(o.id) && (!this.orgLevel || this.orgLevel.id !== o.id)) {
        this.orgLevel = o;
        this.getPath();
      }
    });

    this.orgTreeLoadedSubscription = this.applicationStateBusService.subscribeToOrgLevelsLoaded(() => {
      this.getPath();
    });

    this.routeInfoSubscription = this.applicationStateBusService.subscribeToRouteInfo((data: IRouteInfo) => {
      if (_.isObject(data)) {
        const canChange: boolean = !data.orgLevelChangeDisabled;
        if (this.canChangeOrgLevel !== canChange) {
          this.canChangeOrgLevel = canChange;
        }
        return;
      }
      this.canChangeOrgLevel = true;
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  @HostListener('document:keyup', ['$event'])
  public pressEscape(event: KeyboardEvent): void {
    if (this.showOrgTree && event.keyCode === 27) {
      this.showOrgTree = false;
    }
  }

  @HostListener('document:click', ['$event'])
  public clickOutside(event: MouseEvent): void {
    const elem: HTMLElement = this.elementRef.nativeElement;
    if (elem && this.showOrgTree) {
      if (!elem.contains(event.target as Node)) {
        this.showOrgTree = false;
      }
    }
  }

  public onToggleOrgTree(event: MouseEvent, fromNavMenu: boolean): void {
    if (this.canChangeOrgLevel) {
      this.showOrgTree = !this.showOrgTree;
      if (this.showOrgTree) {
        const breadcrumbElem: HTMLElement = event.currentTarget as HTMLElement;
        const iconElem: HTMLElement = breadcrumbElem.querySelector('.js-icon');
        this.refreshPosition(breadcrumbElem, iconElem);
      }
    }
  }

  public refreshPosition(breadcrumbElem: HTMLElement, iconElem: HTMLElement): void {
    Assert.isNotNull(breadcrumbElem, 'breadcrumbElem');
    Assert.isNotNull(iconElem, 'iconElem');

    let posLeft: number = 0;
    let posTop: number = 0;
    const minIndentFromBorder: number = 0;
    const horizontalShift: number = 25;
    const verticalShift: number = 7;
    const iconStyles: CSSStyleDeclaration = window.getComputedStyle(iconElem);
    const iconPosCenter: number = _.isNaN(parseInt(iconStyles.width)) ? 0 : parseInt(iconStyles.width) / 2;
    const treeStyles: CSSStyleDeclaration = window.getComputedStyle(this.orgTree.nativeElement);
    const treeWidth: number = _.isNaN(parseInt(treeStyles.width)) ? 0 : parseInt(treeStyles.width);
    const rect: ClientRect = breadcrumbElem.getBoundingClientRect();
    const iconPosLeft: number = rect.left + iconPosCenter;
    posTop = rect.top + rect.height + verticalShift;
    posLeft = iconPosLeft - horizontalShift;
    if (posLeft < 0) {
      posLeft = minIndentFromBorder;
    }
    if (posLeft + treeWidth >= window.innerWidth) {
      posLeft = window.innerWidth - treeWidth - minIndentFromBorder;
    }
    this.posTop = posTop;
    this.posLeft = posLeft;
    this.iconShift = Math.abs(posLeft - iconPosLeft);
  }

  public getStateIconClass(): string {
    return this.showOrgTree ? 'fa-chevron-up' : 'fa-chevron-down';
  }

  public isMenuMode(): boolean {
    return this.mode === OrgLevelBreadcrumbModes.menu;
  }

  public getCorpName(withAlternative: boolean): string {
    let name: string = '';
    const corpOrgLevel: OrgLevel = _.head(this.currentPath);
    if (corpOrgLevel) {
      name = corpOrgLevel.name;
    }

    if (withAlternative) {
      return this.canChangeOrgLevel ? name : this.userCannotChangeOrglevelMessage;
    }

    return name;
  }

  public getLastOrgName(withAlternative: boolean): string {
    let name: string = '';
    const lastOrgLevel: OrgLevel = _.last(this.currentPath);
    if (lastOrgLevel) {
      name = lastOrgLevel.name;
    }

    if (withAlternative) {
      return this.canChangeOrgLevel ? name : this.userCannotChangeOrglevelMessage;
    }

    return name;
  }

  public getPath(): void {
    if (!this.orgLevel) return;

    let path: OrgLevel[] = this.orgLevelWatchService.getOrgLevelPath(this.orgLevel.id);
    if (path.length > 2) {
      path = path.slice(-2);
    }
    this.currentPath = path;
  }
}

import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { LMCreationAbsenceManagementService } from '../../../services/index';
import { RecurrenceFrequencies } from '../../../models/index';
import { LMCommonTab } from '../../lm-common-tab';

import { appConfig, IApplicationConfig } from '../../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-intermittent-tab',
  templateUrl: 'lm-intermittent-tab.component.html',
  styleUrls: ['lm-intermittent-tab.component.scss']
})
export class LMIntermittentTabComponent extends LMCommonTab implements OnInit, OnDestroy {
  public get isCustom(): boolean {
    return this.manService.isCustom;
  }
  public get isDaily(): boolean {
    return this.manService.isDaily;
  }
  public get isWeekly(): boolean {
    return this.manService.isWeekly;
  }
  public get isMonthly(): boolean {
    return this.manService.isMonthly;
  }

  public appConfig: IApplicationConfig = appConfig;

  private subscriptions: StringMap<Subscription> = {};
  constructor(private manService: LMCreationAbsenceManagementService) {
    super(manService);
  }

  public ngOnInit(): void {
    super.init();

    if (this.manService.isCreatingNew) {
      this.manService.initRecurrenceService(null, RecurrenceFrequencies.custom);
    } else {
      const loaRepeat = this.manService.getLoaRepeat();
      this.manService.initRecurrenceService(loaRepeat.recurrenceRule, null);
    }
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });

    this.subscriptions = {};
  }
}

import { EventReport, WfmTransactionReport } from '../../../models/wfm-sync';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
import { WfmSyncService } from '../../../services/wfm/wfm-sync.service';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { NotificationsService } from '../../../../core/components';
import * as moment from 'moment';
import { AppServerConfig } from '../../../../../app/app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../app/app-settings/services';
import { KendoGridStateHelper } from '../../../../common/index';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/index';
@Component({
  selector: 'slx-ta-event-report',
  templateUrl: './ta-event-report.component.html',
  styleUrls: ['./ta-event-report.component.scss']
})
export class TAEventReportComponent implements OnInit {
  public gridState: KendoGridStateHelper<EventReport>;
  public viewCol: any[] = [{ field: "StartDate", title: 'Start Date' },
  { field: "endDate", title: 'End Date' }, { field: "syncType", title: 'Sync Type' },
  { field: "errorMessage", title: "Error Message" }, { field: "errorCode", title: "Error Code" }, { field: "employeeName", title: "Employee Name" }];
  public dataView: EventReport[] = [];
  // public state: { isLoading: boolean };
  public syncId: string;
  public endDate: string = '';
  public isLoading: boolean = false;
  public checkStartDate: Date;
  public startDate: string = '';
  public dateError: boolean = false;
  public endDateValue: Date = new Date();
  public today: Date = new Date();
  public disable:boolean=false;
  public month: number = (this.endDateValue.getMonth());
  // new Date(now.setDate(now.getDate() - 30))
  public day: number = (this.endDateValue.getDay()-53);
  public year: number = (this.endDateValue.getFullYear());
  // public startDateValue: Date = new Date(this.year, this.month, this.day, 0, 0, 0, 0);
  public startDateValue:Date= moment(new Date()).subtract(7, 'days').toDate();
  private appSettingManageService: AppSettingsManageService;
  public isNgpEnabled: boolean = false;
  public pageSize: number = 50;
  public startDateMessage: string;
  public endDateMessage: string;
  @unsubscribe()
  private refreshSubscription: Subscription;

  constructor(
    private wfmsyncService: WfmSyncService,
    private route: ActivatedRoute,
    public notificationService: NotificationsService,
    appSettingManageService: AppSettingsManageService,
  ) { 
    this.allData = this.allData.bind(this);
    this.isLoading=true; 
    this.appSettingManageService = appSettingManageService;
    this.gridState = new KendoGridStateHelper<EventReport>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
  }

  ngOnInit() {
    this.startDate = this.startDateValue ? dateTimeUtils.convertToDtoString(this.startDateValue) : '';
    this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';
    this.res();
    this.calculateInitialDates();
    this.getNGPEnabledFlag();
    this.fetchData();
    this.refreshSubscription = this.gridState.onRefreshGrid.subscribe((v): void => {
      this.refreshGrid();
    });
  }
  public res() {
    this.isLoading = true;
    this.wfmsyncService.taEventReport(this.startDate, this.endDate).then((value2: any) => {
      this.dataView = value2.data;
      this.gridState.state.take = this.pageSize;
      this.gridState.state.skip = 0;
      this.refreshGrid();
      this.isLoading = false;
    }).catch(error => {
      this.isLoading = false;
    });
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  calculateInitialDates() {
    this.endDateValue = new Date();
    this.startDateValue = moment(this.endDateValue).subtract(2, 'months').toDate();
  }

  fetchData() {
    this.isLoading = true;
    this.wfmsyncService.taEventReport(this.startDate, this.endDate)
      .then((value2: any) => {
        this.dataView = value2.data;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.refreshGrid();
        this.isLoading = false;
      })
      .catch(error => {
        this.isLoading = false;
        // Handle error
      });
  }

  public onChangeStartDate(dateSent: Date): void {
    this.dateError = false;
    this.startDateMessage = '';
    let value = Math.floor((Date.UTC(this.endDateValue.getFullYear(), this.endDateValue.getMonth(), this.endDateValue.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
    if (value >= 62 || value < 0) {
      this.dateError = true;
      this.disable=true;
      this.startDateMessage = 'Difference between Start date and End date should not be greater than two months';
    }else{
      this.disable=false;
    }
    this.startDateValue=dateSent;
    this.startDate = this.startDateValue ? dateTimeUtils.convertToDtoString(this.startDateValue) : '';
    this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';

  }
  
  onEndDateChange(dateSent: Date) {
    this.dateError = false;
    this.endDateMessage = '';
    let value = Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(this.startDateValue.getFullYear(), this.startDateValue.getMonth(), this.startDateValue.getDate())) / (1000 * 60 * 60 * 24));
    if (value >= 62 || value < 0) {
      this.dateError = true;
      this.disable=true;
      this.endDateMessage = 'Difference between Start date and End date should not be greater than two months';
    }else{
      this.disable=false;
    }
    this.endDateValue=dateSent;
    this.startDate = this.startDate;
    this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';

  }
  public apply(){
    // this.isLoading=true;
    this.res();
    // this.isLoading=false;
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataView, {
        sort: [{ field: 'transactionId', dir: 'asc' }],
      }).data
    };

    return result;
  }

  public async getNGPEnabledFlag() {
    let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();       
    this.isNgpEnabled = appServerConfig.IsNextgenPayrollEnabled;
    if(this.isNgpEnabled)
      this.startDateValue = moment(new Date()).subtract(7, 'days').toDate();
  }

  private refreshGrid(): void {
    if (!this.dataView) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.dataView, this.gridState.state);
  }

}


<slx-spinner [show]="isLoading">
  <div class="slx-high-box workers-compensation-configuration">
    <div class="slx-content-toolbar-indents">
      <div class="leave-types-toolbar__left">
        <button *ngIf="canEdit" type="button" class="slx-button slx-blue slx-with-icon leave-type_add"
          (click)="addType()">
          <i aria-hidden="true" class="slx-button__icon fal fa-plus"></i>Add
        </button> 
      </div>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <form #codeForm="ngForm" novalidate class="slx-full-height">

        <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
        slxKendoGridState="WC_TABLE_CONFIGURATION" 
        [slxKendoGridColumnsGroup]="columnGroup" 
        [data]="gridState?.view"
        [filterable]="'menu'" 
        [filter]="gridState.state.filter" 
        [skip]="gridState.state.skip" 
        [pageable]="true"
        [pageSize]="pageSize" 
        [sortable]="{ mode: 'multiple' }"
        [sort]="gridState.state.sort"
        (pageChange)="gridState.pageChange($event)"
        
        (cancel)="gridState.cancelHandler($event)" 
        (edit)="gridState.editHandler($event)"
        (remove)="gridState.removeHandler($event)" 
        (save)="gridState.saveHandler($event)"
        (dataStateChange)="gridState.dataStateChange($event)">

          
          <!-- ---------------------desktop view--------------------------------- -->
          
          <kendo-grid-command-column title="Edit" width="100" [hidden]="!canEdit" [media]="screenUtils.getKendoMediaForDesktop()">
            <ng-template kendoGridCellTemplate>
              <div class="inline-buttons">
                <button kendoGridEditCommand type="button" *ngIf="canEdit" class="slx-inline-edit-button2">
                  <i class="fas fa-edit slx-inline-edit-button__icon" aria-hidden="true"></i>
                </button>
                <button kendoGridRemoveCommand *ngIf="canEdit" class="slx-icon-button slx-inline-remove-button2">
                  <i class="fas fa-trash slx-inline-edit-button__icon" aria-hidden="true"></i>
                </button>
                <button kendoGridSaveCommand type="button" [disabled]="!codeForm.valid"
                  class="slx-icon-button slx-inline-apply-button2">
                  <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
                </button>
                <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button2">
                  <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
                </button>
              </div>
            </ng-template>
          </kendo-grid-command-column>

          <kendo-grid-column title="Code ID" width="100" field="codeDefinition.id" slxKendoGridColumnHiddenState
            [media]="screenUtils.getKendoMediaForDesktop()" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Code ID</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.codeDefinition.id }}
            </ng-template>
          </kendo-grid-column>
          
          <kendo-grid-column title="Code Type" field="codeDefinition.codeType" [sortable]="true">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.codeDefinition.codeType}}</ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <slx-dropdown-list #codeType="ngModel"
                    class="slx-wide slx-short"
                    [options]="codeTypeLookupValues"
                    [(ngModel)]="dataItem.codeDefinition.codeType"
                    [required]="true"
                    (ngModelChange)="onCodeTypeChange($event, dataItem)"
                    [valuePrimitive]="true"
                    name="codeType"
                    placeholder="Code Type"
                  ></slx-dropdown-list>
                  <div *ngIf="codeType.errors" class="slx-error-block">
                    <span *ngIf="codeType.errors.required" errorMessage for="required"></span>
                  </div>
                </ng-template>
               
              </kendo-grid-column>

          <kendo-grid-column title="Code" width="200" field="codeDefinition.code"
            slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Code</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.codeDefinition.code }}
            </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              <slx-input-decorator>
                <input slx-input required="true" name="code" placeholder="Code"
                  [(ngModel)]="dataItem.codeDefinition.code" slxMaxLength="100" [selfValue]="dataItem.codeDefinition.code" 
                  [prohibitedValues]="{values: prohibitedCodeValues, excludeSelf: true, caseSensitive: false}">
                <span errorMessage for="required"></span>
                <span errorMessage for="slxMaxLength">Code should be a maximum length of '100'</span>
                <span errorMessage for="prohibitedValues">Code should be unique</span>
              </slx-input-decorator>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Code Description" width="200" field="codeDefinition.description"
            slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Code Description</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.codeDefinition.description }}
            </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              <slx-input-decorator>
                <input slx-input required="true" name="codeDescription" placeholder="Code Description"
                  [(ngModel)]="dataItem.codeDefinition.description" slxMaxLength="100">
                <span errorMessage for="required"></span>
                <span errorMessage for="slxMaxLength">Code Description should be a maximum length of '100'</span>
              </slx-input-decorator>
            </ng-template>
          </kendo-grid-column>


          <kendo-grid-column title="Modified by" width="200" field="modifiedBy" slxKendoGridColumnHiddenState
            [media]="screenUtils.getKendoMediaForDesktop()" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Modified by</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.modifiedBy }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Modified Date" width="200" field="modifiedAt" filter="date" slxKendoGridColumnHiddenState
            [media]="screenUtils.getKendoMediaForDesktop()" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Modified Date</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.modifiedAt | amDateFormat: appConfig.dateFormat  }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-messages filterMenuTitle="Filter">
          </kendo-grid-messages>  
        </kendo-grid>
      </form>
    </div>
  </div>
</slx-spinner>

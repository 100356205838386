import { EmployeePayRateComponent, IEmployeePayRateComponent, IEmployeePayRateComponents } from './../../../employee/models/employee-pay-rate-component';
import {
  IMassRateAdjustmentsDefenition, IMassRateAdjustmentsDefenitions,
  IPayRateDefenition,
  IPayRateDefenitions,
  MassRateAdjustmentsDefenition, MassRateAdjustmentsDefenitions, PayRateDefenition, PayRateDefenitions
} from './../../../../organization/models/employee/mass-rate-adjustments';
import { Injectable } from '@angular/core';
import { Assert } from '../../../../framework/index';
import * as _ from 'lodash';

@Injectable()
export class MassPayRateAdjustmentMapService {

  public mapToPayRateDefenitions(dto: IPayRateDefenitions): PayRateDefenitions {
    Assert.isNotNull(dto, 'MassRateAdjustmentsDefenitions');
    let _PayRateDefenitions: PayRateDefenitions = new PayRateDefenitions();
    _PayRateDefenitions.isEnableRateComponent = dto.isEnableRateComponent;
    _PayRateDefenitions.isEnableRate4DecimalPlaces = dto.isEnableRate4DecimalPlaces;
    _PayRateDefenitions.empMassPayRateRecords = _.map(dto.empMassPayRateRecords, (recordDto: IPayRateDefenition) => {
      let record: PayRateDefenition = new PayRateDefenition();
      record.empId = recordDto.empId;
      record.hourlyRate = recordDto.hourlyRate;
      record.employeeSecondaryPositionId = recordDto.employeeSecondaryPositionId;
      record.payRateComponents = this.mapRateComponents(recordDto.payRateComponents, recordDto.hourlyRate,dto.isEnableRateComponent);
      return record;
    });
    return _PayRateDefenitions;
  }


  public mapToMassRateAdjustmentsDefenitions(dto: IMassRateAdjustmentsDefenitions): MassRateAdjustmentsDefenitions {
    Assert.isNotNull(dto, 'MassRateAdjustmentsDefenitions');
    let _MassRateAdjustmentsDefenitions: MassRateAdjustmentsDefenitions
      = new MassRateAdjustmentsDefenitions();
    _MassRateAdjustmentsDefenitions.increment = dto.increment;
    _MassRateAdjustmentsDefenitions.incrementType = dto.incrementType;
    _MassRateAdjustmentsDefenitions.isEnableRateComponent = dto.isEnableRateComponent;
    _MassRateAdjustmentsDefenitions.isEnableRate4DecimalPlaces = dto.isEnableRate4DecimalPlaces;
    _MassRateAdjustmentsDefenitions.increaseReason = dto.increaseReason;
    _MassRateAdjustmentsDefenitions.empMassPayRateRecords = _.map(dto.empMassPayRateRecords, (recordDto: IMassRateAdjustmentsDefenition) => {
      let record: MassRateAdjustmentsDefenition = new MassRateAdjustmentsDefenition();
      record.empId = recordDto.empId;
      record.empName = recordDto.empName;
      record.hourlyRate = recordDto.hourlyRate;
      record.position = recordDto.position;
      record.positionID = recordDto.positionID;
      record.union = recordDto.union;
      record.department = recordDto.department;
      record.departmentID = recordDto.departmentID;
      record.payRateComponents = this.mapRateComponents(recordDto.payRateComponents, recordDto.hourlyRate,dto.isEnableRateComponent)
      return record;

    });
    return _MassRateAdjustmentsDefenitions;

  }

  public mapRateComponents(items: IEmployeePayRateComponents, payrate: number,isEnableRateComponent:boolean): EmployeePayRateComponent[] {
    let rateRecords: EmployeePayRateComponent[] = [];
    rateRecords = _.map(_.get(items, 'records', []), (item: IEmployeePayRateComponent) => {
      const rateRecord = new EmployeePayRateComponent();
      rateRecord.component = item.component;
      if(isEnableRateComponent==true){
      if ((_.isEqual(item.component, 'Base') || _.isEqual(item.component, 'Base Rate')) && _.isEqual(item.rate, 0) && !_.isNil(payrate)) {
        rateRecord.rate = payrate;
      } else {
        rateRecord.rate = item.rate;
      }
    }
    else{
      if ((_.isEqual(item.component, 'Base') || _.isEqual(item.component, 'Base Rate'))) {
        rateRecord.rate = payrate;
      } else {
        rateRecord.rate = item.rate;
      }
    }
      return rateRecord;
    });
    return rateRecords;
  }

  public mapToAdjustedEmployee(data: MassRateAdjustmentsDefenitions): IMassRateAdjustmentsDefenitions {
    Assert.isNotNull(data, 'data');
    let changeditems: IMassRateAdjustmentsDefenition[] = _.map(data.empMassPayRateRecords, (rec: MassRateAdjustmentsDefenition) => {
      let payRateComponents: IEmployeePayRateComponents;
      payRateComponents = this.mapPayRateComponentDto(rec.payRateComponents);

      let recordDto: IMassRateAdjustmentsDefenition = {
        empId: rec.empId,
        empName: rec.empName,
        hourlyRate: rec.hourlyRate,
        positionID: rec.positionID,
        union: rec.union,
        department: rec.department,
        departmentID: rec.departmentID,
        payRateComponents,
        incrementType: rec.incrementType,
        increment: rec.increment,
        position: rec.position,
        effectiveDate: rec.effectiveDate,
        revisedHourlyRate: rec.revisedHourlyRate,
        id: rec.id,
        name: rec.name,
        secondaryJobId: rec.secondaryJobId
      }
      recordDto.payRateComponents = payRateComponents;
      return recordDto;
    });
    let _massRateAdjustmentsDefenitions: IMassRateAdjustmentsDefenitions = {
      increment: data.increment,
      incrementType: data.incrementType,
      empMassPayRateRecords: changeditems,
      effectiveDate: data.effectiveDate,
      isEnableRateComponent: data.isEnableRateComponent,
      isEnableRate4DecimalPlaces: data.isEnableRate4DecimalPlaces,
      increaseReason:data.increaseReason
      
    };
    return _massRateAdjustmentsDefenitions;
  }

  public mapPayRateComponentDto(allocations: EmployeePayRateComponent[]): IEmployeePayRateComponents {
    return {
      records: _.reduce(allocations, (items: EmployeePayRateComponent[], allocation: EmployeePayRateComponent) => {
        if (allocation.component || allocation.rate) {
          items.push(allocation);
        }
        return items;
      }, [])
    }
  }
}
import { Component, OnDestroy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { LookupApiService } from '../../../../organization/services/index';
import { BudgetedGroup } from '../../../../organization/models/index';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { BudgetApiService, BudgetManagementService } from '../../../services/index';

export type groupWrapper = { group: BudgetedGroup, inEdit: boolean, toDelete: boolean, groupName: string };

@Component({
  moduleId: module.id,
  selector: 'slx-budgeted-group-editor',
  templateUrl: 'budgeted-group-editor.component.html',
  styleUrls: ['budgeted-group-editor.component.scss']
})
export class BudgetedGroupEditorComponent implements OnDestroy, OnInit {

  @Input()
  public startDate: Date;
  @Input()
  public set orgLevelId(o: number) {
    if (o === this.currentOrgLevelId) { return; }
    this.currentOrgLevelId = o;
    this.loadGroups();
  }
  @Output()
  public finishEditing: EventEmitter<boolean>;

  public gridState: KendoGridStateHelper<groupWrapper>;

  public state: {
    isLoading: boolean;
  };
  public isAnySelected: boolean;
  public groups: groupWrapper[];
  private currentOrgLevelId: number;
  private lookupApiService: LookupApiService;
  private budgetApiService: BudgetApiService;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridRemoveSubscription: Subscription;
  @unsubscribe()
  private saveBudgetSubscription: Subscription;

  constructor(private budgetManagementService:BudgetManagementService, lookupApiService: LookupApiService, budgetApiService: BudgetApiService) {
    this.lookupApiService = lookupApiService;
    this.budgetApiService = budgetApiService;
    this.finishEditing = new EventEmitter();
    this.state = {
      isLoading: false
    };
    this.isAnySelected = false;
    this.gridState = new KendoGridStateHelper<groupWrapper>();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public loadGroups(forcedLoadGroup: boolean = false): void {
    if (this.currentOrgLevelId === 0 || !this.currentOrgLevelId) { return; }
    this.state.isLoading = true;
    this.lookupApiService.getBudgetedGroups(this.currentOrgLevelId, forcedLoadGroup)
      .then((groups: BudgetedGroup[]) => {
        this.state.isLoading = false;
        this.groups = _.map(groups, (grp: BudgetedGroup) => { return { group: grp, inEdit: false, toDelete: false, groupName: grp.description }; });
        this.refreshGrid();
      })
      .catch(() => {
        this.state.isLoading = false;
      });
  }

  public onEditGroupsDiscard(): void {
    this.finishEditing.emit(false);
  }

  public onAddGroup(grid: GridComponent): void {
    this.gridState.closeEditor(grid);
    let group = new BudgetedGroup();
    const newRecord: groupWrapper = { group: group, inEdit: false, toDelete: false, groupName: group.description };
    grid.addRow(newRecord);
  }

  public onKeyName(event: KeyboardEvent, wrapper: groupWrapper, nameField: NgModel): void {
    if (!nameField) {
      return;
    }
    const name: string = nameField.value;
    const existGroup: groupWrapper = _.find(this.groups, (record: groupWrapper) => record.group.description === name && record.group.id !== wrapper.group.id);
    if (existGroup) {
      nameField.control.setErrors({ unique: true });
    }
  }

  public ngOnInit(): void {
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });
    this.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<groupWrapper>) => {
      event.dataItem.group.description = event.dataItem.groupName;
      if (event.isNew) {
        this.addGroup(event.dataItem.group);
      } else {
        this.updateGroup(event.dataItem.group);
      }
    });
    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe((event: removeEvent<groupWrapper>) => {
      this.deleteGroup([event.dataItem.group]);
    });
   this.saveBudgetSubscription= this.budgetManagementService.onSaveBudget$.subscribe((isSave: boolean) => {
      if (isSave) {
        this.loadGroups(isSave);
      }
    });
  }

  private addGroup(grp: BudgetedGroup): void {
    this.state.isLoading = true;
    this.budgetApiService.createBudgetedGroup(this.currentOrgLevelId, grp)
      .then((newgrp: BudgetedGroup) => {
        const newRecord: groupWrapper = { group: newgrp, inEdit: false, toDelete: false, groupName: newgrp.description };
        this.groups.unshift(newRecord);
        this.refreshGrid();
        this.state.isLoading = false;
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }
  private updateGroup(grp: BudgetedGroup): void {
    this.state.isLoading = true;
    this.budgetApiService.updateBudgetedGroup(grp)
      .then((grp: BudgetedGroup) => {
        this.state.isLoading = false;
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }
  private deleteGroup(grp: BudgetedGroup[]): void {
    this.state.isLoading = true;
    let ids: number[] = _.map(grp, (g: BudgetedGroup) => g.id);
    this.budgetApiService.deleteBudgetedGroup(ids)
      .then((result: any) => {
        this.state.isLoading = false;
        _.remove(this.groups, (item: groupWrapper) => {
          return _.includes(ids, item.group.id);
        });
        this.refreshGrid();
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  private refreshGrid(): void {
    if (!this.groups) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.groups, this.gridState.state);
  }
}

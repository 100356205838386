import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MomentModule } from 'angular2-moment';

import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { ComponentsLibraryModule } from '../../components-library/index';
import { NgxPopperModule } from 'ngx-popper';

import { services } from './services/index';
import { messageCenterComponents, entryComponents } from './components/index';
import { MessagesGridComponent } from './components/messages-grid/messages-grid.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { HighlightSearch } from './pipes/highlight-search.pipe';
import { ConvertDate, ConvertUserFrndlyDate } from './pipes/humanize-date.pipe';
import { ConvertUserFrndlyName } from './pipes/convert-name.pipe';
import { ConvertUSformatNumber } from './pipes/phone-number-format.pipe';
import { MessageThreadThumbnailSrcComponent } from './components/message-thread-thumbnail-src/message-thread-thumbnail-src.component';
import { MsgGridThumbnailSrcComponent } from './components/msg-grid-thumbnail-src/msg-grid-thumbnail-src.component';
@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        HttpClientModule,
        CommonAppModule,
        CommonModule,
        OrganizationModule,
        MomentModule,
        GridModule,
        PDFModule,
        ExcelModule,
        LayoutModule,
        NgxPopperModule,
        ComponentsLibraryModule,
        ReactiveFormsModule,
        DropDownsModule
    ],
    declarations: [
        ...messageCenterComponents,
        MessagesGridComponent,
        HighlightSearch,
        ConvertUserFrndlyDate, ConvertUserFrndlyName, ConvertUSformatNumber, ConvertDate, MessageThreadThumbnailSrcComponent, MsgGridThumbnailSrcComponent
    ],
    providers: [...services],
    exports: [...messageCenterComponents, ConvertUserFrndlyDate, ConvertUserFrndlyName, ConvertUSformatNumber, ConvertDate]
})
export class MessageCenterModule {}

import { Provider } from '@angular/core';

export * from './payroll-export/index';

import { payrollExportServices } from './payroll-export/index';
import { PayrollExportTestApiService } from './payroll-export-test/payroll-export-test-api.service';
import { PayrollExportTestMapService } from './payroll-export-test/payroll-export-test-map.service';

export const services: Provider[] = [
  ...payrollExportServices,
  PayrollExportTestApiService,
  PayrollExportTestMapService
];

import { Component, OnInit, OnDestroy, Input, Provider, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren, QueryList, ContentChildren } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';

import { PunchLogEntry } from '../../../models/index';
import { PunchLogsManagementService } from '../../../services/punches/punch-logs-management.service';

@Component({
  moduleId: module.id,
  selector: 'slx-punch-logs-grid',
  templateUrl: 'punch-logs-grid.component.html',
  styleUrls: ['punch-logs-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchLogsGridComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public records: PunchLogEntry[];
  public gridState: KendoGridStateHelper<PunchLogEntry>;
  public groupName: string = 'PunchLogs';

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private exportSubscription: Subscription;

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;

  constructor(private managementService: PunchLogsManagementService, private changeDetector: ChangeDetectorRef) {
    this.gridState = new KendoGridStateHelper<PunchLogEntry>();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(
      (records: PunchLogEntry[]) => {
        this.records = records;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(
      (records: PunchLogEntry[]) => {
        this.records = records;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.exportSubscription = this.managementService.onExport$.subscribe((v: any): void => {
      this.grid.saveAsExcel();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}


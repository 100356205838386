import { IAvailShifts, IScheduleRotationTemplate, OverlapOverTimeMessage, ScheduleRotationTemplate } from "./../../../../../app/scheduler/models/schedule-rotation/schedule-rotation-template.model";
import * as _ from "lodash";
import { Provider, Injectable } from "@angular/core";
import { DialogOptions, ModalService } from "./../../../../../app/common";
import { ShiftOverlapWarningMessageComponent } from "./../../../../../app/common/components/shift-overlap-warning-message/shift-overlap-warning-message.component";
import { Subject } from "rxjs";
import { ScheduleRotationFilterService } from "./../../../../../app/scheduler/services/scheduled-rotation-template/schedule-rotations-filter.service";
@Injectable()
export class ScheduledRotationEmployeeGridHelper extends ScheduleRotationFilterService {
    public modalService: ModalService;
    public shiftEdit$ = new Subject<any>();
    public shiftRemove$ = new Subject<boolean>();
    public shiftOverTimeProceed$ = new Subject<any>();
    public shiftOverlapMessageData: string;
    public shiftOverTimeMessageData: string;

    constructor() {
        super();
     }

    public shiftOverLapMessage(result: any[], employee: ScheduleRotationTemplate, shift: IAvailShifts) {
        let overLapDatesData = _.map(result, (item) => item.overLappingDate);
        let overLappingDates = overLapDatesData.join();
        let options: DialogOptions = new DialogOptions();
        options.width = 368;
        options.showCloseButton = true;
        options.hideTitleBar = true;
        options.className = 'slx-srt-warning-message';
        options.message = 'Overlapping Shift'
        let message: OverlapOverTimeMessage = new OverlapOverTimeMessage();
        message.overLap = `This shift will overlap with another shift on following dates - ${overLapDatesData} and cannot be added`;
        let resolvedProviders: Provider[] = [
            {
                provide: DialogOptions,
                useValue: options
            },
            {
                provide: ModalService,
                useValue: this.modalService
            },
            {
                provide: OverlapOverTimeMessage,
                useValue: message
            }
        ];
        let dialog: ShiftOverlapWarningMessageComponent = this.modalService.globalAnchor.openDialog(
            ShiftOverlapWarningMessageComponent,
            'Warning',
            options, resolvedProviders, (res: boolean) => {
                if (!res) {
                    this.shiftEdit$.next([true, shift]);
                }
            });
    }

    public shiftOverTimeMessage(result: any[], employee: ScheduleRotationTemplate, shift: IAvailShifts) {
        let overTimeDatesData = _.map(result, (item) => item.overLappingDate);
        let overTimeDates = overTimeDatesData.join();
        let options: DialogOptions = new DialogOptions();
        options.width = 390;
        options.showCloseButton = true;
        options.hideTitleBar = true;
        options.className = 'slx-srt-warning-message';
        options.message = 'Overtime';
        let message: OverlapOverTimeMessage = new OverlapOverTimeMessage();
        message.overTime = `By adding this shift it will result in overtime on following dates - ${overTimeDatesData}`;
        let resolvedProviders: Provider[] = [
            {
                provide: DialogOptions,
                useValue: options
            },
            {
                provide: ModalService,
                useValue: this.modalService
            },
            {
                provide: OverlapOverTimeMessage,
                useValue: message
            }
        ];
        let dialog: ShiftOverlapWarningMessageComponent = this.modalService.globalAnchor.openDialog(
            ShiftOverlapWarningMessageComponent,
            'Warning',
            options, resolvedProviders, (result: boolean) => {
                this.shiftOverTimeProceed$.next([result, shift]);
            });
        if (dialog.initialized) {
            console.log(dialog.dialogResult);
        }
    }
}
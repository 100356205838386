import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { MomentModule } from 'angular2-moment';

import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { ComponentsLibraryModule } from '../../components-library/index';
import { NgxPopperModule } from 'ngx-popper';
import { services } from './services/index';
import { components, PayrollExportConfigPopupComponent } from './components/index';

@NgModule({
    imports: [
        RouterModule,
        FormsModule, ReactiveFormsModule,
        HttpClientModule,
        CommonAppModule,
        CommonModule,
        OrganizationModule,
        MomentModule,
        GridModule,
        ChartsModule,
        DropDownsModule,
        NgxPopperModule,
        ComponentsLibraryModule
    ],
    declarations: [
        ...components
    ],
    providers: [
        ...services
    ],
    exports: []
})
export class PayrollModule { }


export * from './message-center/message-center.component';
export * from './sms-compose-new-dialog/sms-compose-new-dialog.component';

import { MessageCenterComponent } from './message-center/message-center.component';
import { SmsComposeNewDialogComponent } from './sms-compose-new-dialog/sms-compose-new-dialog.component';
import { MessageContentComponent } from './message-content/message-content.component';
import { MessageListComponent } from './message-list/message-list.component';
import { SlateMessageListComponent } from './slate-message-list/slate-message-list.component';
import { SlateMessageContentComponent } from './slate-message-content/slate-message-content.component';
import { SlateMessagesGridComponent } from './slate-messages-grid/slate-messages-grid.component';

export const messageCenterComponents: any[] = [
  MessageCenterComponent,
  SmsComposeNewDialogComponent,
  MessageContentComponent,
  MessageListComponent,
  SlateMessageListComponent,
  SlateMessageContentComponent,
  SlateMessagesGridComponent
];

export const entryComponents: any[] = [
  SmsComposeNewDialogComponent
];


import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { KendoGridStateHelper, saveEvent, removeEvent, cancelEvent } from '../../../../../common/models/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../../common/services/index';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { PopoverContentComponent } from '../../../../../common/index';
import { benefitsConfig } from '../../../benefits.config';
import {
  BenefitDetailsEditModes,
  BenefitDetailsTier,
  BenefitDetailsOption,
  BenefitDetailsLineStandart,
  BenefitDetailsProviderLineStandartEntity,
  BenefitDetailsGroup,
  CoverageOptionTypes,
  coverageOptionTypesList
} from '../../../models/index';
import { BenefitDetailsStandartManagementService, BenefitDetailsPermissionService, BenefitDetailsManagementService } from '../../../services/index';
import { AcaCategory } from '../../../../../organization/models/index';

@Component({
  selector: 'slx-benefit-details-coverage-calc-options',
  templateUrl: './benefit-details-coverage-calc-options.component.html',
  styleUrls: ['./benefit-details-coverage-calc-options.component.scss'],
  providers: [StateManagementService, ColumnManagementService]
})
export class BenefitDetailsCoverageCalcOptionsComponent implements OnInit, OnDestroy {
  public appConfig: IApplicationConfig;
  public records: BenefitDetailsOption[] = [];
  public gridState: KendoGridStateHelper<BenefitDetailsOption>;
  public selectedProvidedTier: BenefitDetailsTier;
  public group: string;
  public min: number = 0;
  public max: number = 99999999.99;
  public step = 1;
  public format = 'c2';
  public isEditMode = false;
  public prohibitedCodeValues: string[];
  public spousesList = [this.createDropDownItem(true), this.createDropDownItem(false)];
  public selectedProviderLine: BenefitDetailsLineStandart;
  public childrenValues: KeyValuePair<boolean | number, string>[] = [];
  public coverageOptionTypes: CoverageOptionTypes[] = coverageOptionTypesList;
  public get isRowEditing(): boolean {
    return _.isObjectLike(this.gridState.editedRecord);
  }

  @ViewChild('kendoGrid', { static: true })
  public kendoGrid: GridComponent;
  public acaCategoryLookupValues: string[];
  private selectedRowIndex: number;
  private childRange = Array(10).fill(0).map((x, i) => this.createDropDownItem(i));
  public isHealthBenifitPlanSelected: boolean = false;
  private readonly groupConfig = benefitsConfig.benefitGroupsConfig;
  private pageSize = 50;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private modalService: ModalService,
    private commonManService: BenefitDetailsManagementService,
    private manService: BenefitDetailsStandartManagementService,
    private permissionService: BenefitDetailsPermissionService
  ) {
    this.gridState = new KendoGridStateHelper<BenefitDetailsOption>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'type', dir: 'asc' }];
    this.gridState.state.take = this.pageSize;
    this.appConfig = appConfig;
    this.childrenValues = [...this.childRange,this.createDropDownItem(99)];
  }

  public ngOnInit(): void {
    this.subscriptions.acaCategoryLookupSubscription = this.commonManService.subscribeToAcaCategoryLookup((v: AcaCategory[]) => {
      this.acaCategoryLookupValues = _.map(v, (item: AcaCategory) => item.value);
    });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    this.subscriptions.getBenifitPlanSubscription = this.commonManService.subscribeToSelectGroup((v: BenefitDetailsGroup) => {
      this.isHealthBenifitPlanSelected = v.type === this.groupConfig.medical.type;
    });

    this.subscriptions.editMode = this.permissionService
      .subscribeToEditMode((v: BenefitDetailsEditModes) => {
        this.isEditMode = v.providerInfo;
        this.recalculateProhibitedCodeValues();
        this.permissionService.toggleCanAddCovOptions(this.isEditMode);
        if (!this.isEditMode) {
          this.gridState.closeEditor(this.kendoGrid);
        }
      });

    this.subscriptions.coverageOption = this.manService
      .subscribeToAddNewCoverageOption((o: BenefitDetailsOption) => this.addNewOption(o));

    this.subscriptions.onEdit = this.gridState.onEdit$
      .subscribe((option: BenefitDetailsOption) => {
        this.permissionService.toggleCanAddCovOptions(false);
      });

    this.subscriptions.onCancel = this.gridState.onCancel$
      .subscribe(() => {
        this.permissionService.toggleCanAddCovOptions(true);
      });

      this.subscriptions.onSave = this.gridState.onSave$
        .subscribe((event: saveEvent<BenefitDetailsOption>) => {
          this.permissionService.toggleCanAddCovOptions(true);
          if (event.isNew) {
            this.addOption(event.dataItem);
            return;
          }
          this.updateOption(event.dataItem);
        });

    this.subscriptions.onRemove = this.gridState.onRemove$
      .subscribe((event: removeEvent<BenefitDetailsOption>) => {

        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog(
          'Delete Coverage Option',
          'Are you sure you want to remove selected coverage option? This will remove this plan for all enrolled employees',
          this.modalService,
          (isDelete: boolean) => {
            if (isDelete) {
              this.removeOption(event.dataItem);
              this.permissionService.toggleCanAddCovOptions(true);
            }
          },
          options);

      });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());

    this.subscriptions.providerLine = this.commonManService
      .subscribeToSelectProviderLine((v: BenefitDetailsProviderLineStandartEntity) => {
        this.selectedProviderLine = v.current;
        this.refreshGrid();
      });

    this.commonManService.loadAcaCategory();

    this.subscriptions.providerTier = this.manService
      .subscribeToSelectProviderTier((v: BenefitDetailsTier) => {
        this.selectedProvidedTier = v;
        this.gridState.closeEditor(this.kendoGrid);
        this.filterOutRecords();
        this.permissionService.toggleCanAddCovOptions(true);
      });
  }

  public ngOnDestroy(): void {}

  public isCurrentRowEditing(option: BenefitDetailsOption): boolean {
    return this.isRowEditing && this.gridState.editedRecord.id === option.id;
  }

  public addNewOption(option: BenefitDetailsOption): void {
    this.gridState.closeEditor(this.kendoGrid);
    this.kendoGrid.addRow(option);
    this.gridState.editedRecord = option;
    this.permissionService.toggleCanAddCovOptions(false);
  }

  public addOption(option: BenefitDetailsOption): void {
    this.manService.saveCoverageOption(option, false);
    this.filterOutRecords();
  }

  public updateOption(option: BenefitDetailsOption): void {
    this.manService.updateCoverageOption(option);
  }

  public removeOption(option: BenefitDetailsOption): void {
    this.manService.removeCoverageOption(option);
    this.filterOutRecords();
  }

  public onChangeContribution(option: BenefitDetailsOption) {
    this.manService.updateTierOptionCost(this.selectedProviderLine, option);
  }

  public recalculateProhibitedCodeValues(): void {
    const opts: BenefitDetailsOption[] = this.manService.getAllCoverageOptions();
    if (opts) {
      this.prohibitedCodeValues = opts.map(o => o.code);
    } else {
      this.prohibitedCodeValues = [];
    }
  }

  public findDropDownItem(id: number | boolean, arr: KeyValuePair<boolean | number, string>[] ): KeyValuePair<boolean | number, string> {
    return _.find(arr, x => x.key === id);
  }

  public onSpouseChanged(option: BenefitDetailsOption, value: KeyValuePair<boolean, string>): void {
    option.spouse = value.key;
  }

  public onChildrenChanged(option: BenefitDetailsOption, value: KeyValuePair<number, string>): void {
    option.children = value.key;
  }

  private createDropDownItem(item: boolean | number): KeyValuePair<boolean | number, string> {
    return {
      key: item,
      value: item.toString()
    };
  }

  private filterOutRecords(): void {
    this.records = _.filter(this.selectedProvidedTier.options, (o: BenefitDetailsOption) => !o.stateIsDeleted);
    this.refreshGrid();
  }

  private refreshGrid(): void {
    this.recalculateProhibitedCodeValues();
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

}

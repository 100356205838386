<kendo-grid #kendoGrid class="slx-full-height slx-grid-slim-rows slx-blue-grid slx-blue-selected-row-grid"
  [data]="gridState.view" [sortable]="true" [sort]="gridState.state.sort" (sortChange)="gridState.sortChange($event)"
  [selectable]="{ mode:'single'}" (selectionChange)="gridState.selectionChange($event)"
  [kendoGridSelectBy]="gridState.itemKey" [selectedKeys]="gridState.selectedRowsIds" [resizable]="true"
  (dataStateChange)="gridState.dataStateChange($event)" [filterable]="'menu'" [filter]="gridState.state.filter">

  <kendo-grid-column title="Total hours" field="totalScheduleHours" [locked]="false" width="75">
    <ng-template kendoGridHeaderTemplate>
      <i class="far fa-calendar-alt" aria-hidden="true"></i>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <i *ngIf="dataItem.totalScheduleHours<=0" class="kendo-color-icon" aria-hidden="true"></i>
      <div *ngIf="dataItem.totalScheduleHours>0" class="schedule-exist">
        <i class="kendo-color-icon far fa-check schedule-exist-icon" aria-hidden="true"></i>
        {{dataItem.totalScheduleHours}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Name" field="employee.name" [locked]="false" width="150" filter="text">
    <ng-template kendoGridHeaderTemplate>
      Name
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [employeeSectionLink]="dataItem.employee.id" #employeeLink id="Employee_Id_{{ dataItem?.employee?.id }}">
        <img class="emp-icon" [getEmpInitials]="true" [employeeThumbinalSrc]="dataItem?.employee?.id" alt="Employee Photo">
        {{dataItem.employee.name}}
      </a>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Position" field="position.name" [locked]="false" width="140">
    <ng-template kendoGridHeaderTemplate>
      Position
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.position.name}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Type" field="employee.employeeType.name" [locked]="false" width="90">
    <ng-template kendoGridHeaderTemplate>
      Type
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.employee.employeeType.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeePunchRequestManagementService } from './../../../../../app/time-and-attendance/services/punches/employee-punch-request-management.service';
import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { ColumnManagementService, StateManagementService } from '../../../../common/services/index';

@Component({
  selector: 'slx-employee-punch-request',
  templateUrl: './employee-punch-request.component.html',
  styleUrls: ['./employee-punch-request.component.scss'],
  providers: [ColumnManagementService, StateManagementService, EmployeePunchRequestManagementService]

})
export class EmployeePunchRequestComponent implements OnInit, OnDestroy {

  public isLoading: boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(public manageService: EmployeePunchRequestManagementService) { }

  public ngOnInit() {
    this.manageService.init();
    this.subscriptions.loading = this.manageService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  public ngOnDestroy(): void {
    this.manageService.destroy();
  }

}

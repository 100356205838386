
import {filter} from 'rxjs/operators';
import { Component, Output, OnInit, OnDestroy, EventEmitter, Input } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable ,  Subscription } from 'rxjs';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { LookupApiService } from '../../../organization/services/index';
import { BudgetDefinition } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-budget-dropdown-selector',
  templateUrl: 'budget-dropdown-selector.component.html',
  styleUrls: ['budget-dropdown-selector.component.scss']
})
export class BudgetSelectorDropdownComponent implements OnInit, OnDestroy {
  @Input()
  public selectedBudgetDefinition: BudgetDefinition;

  @Input()
  public ignoreOrgLevelChange: boolean;

  @Output()
  public budgetDefinitionSelected: EventEmitter<BudgetDefinition>;
  @Output()
  public budgetDefinitionAdd: EventEmitter<any>;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public budgetDefinitions: BudgetDefinition[];

  public appConfig: IApplicationConfig;
  public state: {
    isLoading: boolean;
    isCorporation: boolean;
  };
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  private lookupApiService: LookupApiService;
  private selectedOrgLevel: OrgLevel;
  private selectedOrganizationOrgLevelId: number;

  constructor(lookupApiService: LookupApiService) {
    this.lookupApiService = lookupApiService;
    this.budgetDefinitionSelected = new EventEmitter<BudgetDefinition>();
    this.budgetDefinitionAdd = new EventEmitter<any>();
    this.state = {
      isLoading: false,
      isCorporation: false
    };
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    if (!this.ignoreOrgLevelChange) {
      this.orgLevelSubscription = this.orgLevel$.pipe(
        filter((o: OrgLevel) => !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id))
        .subscribe((o: OrgLevel) => {
          this.setOrglevel(o);
        });
    }
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public setOrglevel(o: OrgLevel): void {
    if (o.type === OrgLevelType.department) {
      this.selectedOrganizationOrgLevelId = o.parentId;
      this.state.isCorporation = false;
      this.loadBudgetsDefinitions(this.selectedOrganizationOrgLevelId);
    } else if (o.type === OrgLevelType.organization) {
      this.selectedOrganizationOrgLevelId = o.id;
      this.state.isCorporation = false;
      this.loadBudgetsDefinitions(this.selectedOrganizationOrgLevelId, true);
    } else {
      this.selectedOrganizationOrgLevelId = 0;
      this.state.isCorporation = true;
    }
    this.selectedOrgLevel = o;
  }

  public SelectBudgetDefinition(itemToSelect: BudgetDefinition, riseEvent: boolean): void {
      if (this.budgetDefinitions && this.budgetDefinitions.length > 0) {
        if (!!itemToSelect && !!this.budgetDefinitions) {
          this.selectedBudgetDefinition = _.find(this.budgetDefinitions, (item: BudgetDefinition) => {
            return item.id === itemToSelect.id;
          });
        }
        if (!this.selectedBudgetDefinition) {
          this.selectedBudgetDefinition = this.budgetDefinitions[0];
        }
        if (riseEvent) {
          this.onBudgetDefinitionSelect(this.selectedBudgetDefinition);
        }
      } else {
        if (riseEvent) {
          this.selectedBudgetDefinition = null;
          this.onBudgetDefinitionSelect(this.selectedBudgetDefinition);
        }
      }
  }

  public onBudgetDefinitionAdd(): void {
    this.budgetDefinitionAdd.emit(null);
  }

  public loadBudgetsDefinitions(orgLevelId: number, updateCacheForced: boolean = false, isDeleteBudget: boolean = false): void {
    this.lookupApiService.getBudgetDefinitions(orgLevelId, updateCacheForced)
      .then((budgetDefinitions: BudgetDefinition[]) => {
        this.budgetDefinitions = _.orderBy(budgetDefinitions, (item: BudgetDefinition) => { return moment(item.startDate).unix(); }, 'desc');
        if (this.budgetDefinitions && this.budgetDefinitions.length > 0 && !isDeleteBudget) {
          this.onBudgetDefinitionSelect(this.budgetDefinitions[0]);
        }
      });
  }

  public onBudgetDefinitionSelect(item: BudgetDefinition): void {
    if(!this.selectedBudgetDefinition || 
      !!item && 
        (this.selectedBudgetDefinition.id != item.id 
          || this.selectedBudgetDefinition.startDate != item.startDate 
          || this.selectedBudgetDefinition.endDate != item.endDate)) {
      this.selectedBudgetDefinition = item;
      this.budgetDefinitionSelected.emit(this.selectedBudgetDefinition);
      }
  }

}
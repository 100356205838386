
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { StateManagementService, ColumnManagementService } from '../../../../../../common/services/index';

import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitLineInfo, BenefitDetailsTier } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/index';

@Component({
  selector: 'benefit-option-detail-flat-grid',
  templateUrl: 'benefit-option-detail-flat-grid.component.html',
  providers: [StateManagementService, ColumnManagementService]
})
export class BenefitOptionDetailFlatGridComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<BenefitLineInfo>;
  public columnGroup = 'BenefitOptionDetailFlatGridComponent';
  public appConfig: IApplicationConfig;
  public data: BenefitDetailsTier[] = [];
  public coverageList: number[] = [];
  public isLoading: boolean = true;
  public selectableSettings: SelectableSettings;
  
  @Input()
  public tierDetails: BenefitDetailsTier;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  constructor(private manService: BenefitEmployeeManagementService,
    private stateManagement: StateManagementService,
    private columnManagementService: ColumnManagementService
  ) {
    this.gridState = new KendoGridStateHelper<BenefitLineInfo>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.selectableSettings = {
      checkboxOnly: true
    };
    this.appConfig = appConfig;
  }

  public ngOnInit() {

    this.stateManagement.init('BenefitOptionDetailFlatGridComponent');
    this.columnManagementService.init('BenefitOptionDetailFlatGridComponent');
    this.columnManagementService.initGroup(this.columnGroup, 2);
    this.defaultContribution();
    this.refreshGrid();
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });
  }

  public defaultContribution() {
    const item = new BenefitDetailsTier();
    item.empContribution = this.tierDetails.empContribution;
    item.erContribution = this.tierDetails.erContribution;

    this.data.push(item);
  }

  public ngOnDestroy(): void {

  }

  private refreshGrid(): void {
    this.isLoading = false;
    if (!this.data) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.data, this.gridState.state);
  }

}

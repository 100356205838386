import { ConfirmOptions } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AddEvent, CancelEvent, EditEvent, GridComponent, GridItem, SaveEvent } from '@progress/kendo-angular-grid';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { appMessages, IApplicationMessages } from '../../../app.messages';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmDialogComponent } from './../../../common/components/confirm-dialog/confirm-dialog.component';
import { destroyService } from '../../../core/decorators/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import {
  DialogOptions,
  KendoGridStateHelper,
  KendoGridCustomSelectionHelper, ColumnManagementService, StateManagementService, InfoDialogComponent, PopoverContentComponent, PopoverDirective
} from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';

import { EditableListActionKind } from '../../models/editableList/editable-list-action.model';
import { AccessManagementService, ShiftsManagementService } from '../../services/index';
import { Shift } from '../../models/shifts/shift';

import { ShiftRemoveDialogComponent } from './shift-remove-dialog/shift-remove-dialog.component';
import { ShiftsContainer } from '../../models/shifts/shifts-container';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { dateTimeUtils } from '../../../common/utils/index';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { AppSettingsManageService } from './../../../../app/app-settings/services';
import { AppServerConfig } from './../../../../app/app-settings/model/app-server-config';
import { ShiftSetting } from '../../models/shifts/shift-setting';

@Component({
  moduleId: module.id,
  selector: 'slx-shifts-component',
  templateUrl: 'shifts.component.html',
  styleUrls: ['shifts.component.scss'],
  providers: [ShiftsManagementService, AccessManagementService, ColumnManagementService, StateManagementService]
})
export class ShiftsComponent implements OnInit, OnDestroy {

  public readonly columnsGroupName: string = 'ConfigureShifts';
  public loadingPanelVisible = true;

  public get container(): ShiftsContainer {
    return this.m_container;
  }

  public onAddItem: () => void;
  public onDeletedItems: () => void;

  public crudHelper: ConfigurationComponentHelper<Shift>;
  public gridState: KendoGridStateHelper<Shift>;
  public selectionHelper: KendoGridCustomSelectionHelper<Shift>;

  public appConfig: IApplicationConfig;
  public appMessages: IApplicationMessages;

  public prohibitedNameValues: string[] = [];

  public state: {
    isLoading: boolean;
    configureMode: boolean;
    copyMode: boolean;
  };

  private m_container: ShiftsContainer;
  public parentShift: Shift = undefined;

  @ViewChild('kendoGrid')
  private set grid(value: GridComponent) {
    if (this.crudHelper) this.crudHelper.grid = value;
    this.m_grid = value;
  }

  private get grid(): GridComponent {
    return this.m_grid;
  }

  private m_grid: GridComponent;


  @ViewChild('pSettingsPopover')
  private set settingPopover(value: PopoverContentComponent) {
    this.m_settingPopover = value;
  }

  private get settingPopover(): PopoverContentComponent {
    return this.m_settingPopover;
  }

  private m_settingPopover: PopoverContentComponent;


  @ViewChild('templateForm', { static: true })
  private mainForm: NgForm;

  @destroyService()
  private management: ShiftsManagementService;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private editSubscription: Subscription;
  @unsubscribe()
  private removeSubscription: Subscription;
  @unsubscribe()
  private errorSubscription: Subscription;
  @unsubscribe()
  private savedSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private mainFormSubscription: Subscription;
  @unsubscribe()
  private onLoadSubscription: Subscription;
  @unsubscribe()
  private missingShiftSubscription: Subscription;

  public defaultShift: any = [
    { id: 0, name: 'NO' },
    { id: 1, name: 'YES' }
  ];
  public isDefaultShiftEnabled: boolean;
  public isPartialShiftEnabled: boolean;
  public defaultPartialShiftCount: number;
  public missingTimeRanges: any[] = [];


  constructor(management: ShiftsManagementService, private modalService: ModalService, private stateManagement: StateManagementService, private appSettingManageService: AppSettingsManageService,) {
    this.management = management;
    this.gridState = new KendoGridStateHelper<Shift>("ShiftsComponent");
    this.gridState.preventCloseEditorOnSave = true;
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

    this.crudHelper = new ConfigurationComponentHelper<Shift>();
    this.crudHelper.gridState = this.gridState;
    this.crudHelper.selectionHelper = this.selectionHelper;
    this.crudHelper.management = management;

    // directive intercepted methods (scope bug)
    this.onAddItem = () => {
      let shift: Shift = new Shift();
      let start: moment.Moment = moment().startOf('day');
      let end: moment.Moment = start.clone().add(1, 'hours');
      shift.start = start.toDate();
      shift.end = end.toDate();
      shift.name = Shift.createShiftName(shift.start, shift.end);
      shift.duration = 1;
      shift.lunchDuration = 0;
      shift.hasPartialShift = false;
      shift.shiftSetting = new ShiftSetting();
      this.crudHelper.addItem(shift);
    };

    this.onDeletedItems = () => {
      this.crudHelper.deleteSelected();
    };
    this.getSettings();

  }

  /* ---------------------- Event Hanlders -----------*/
  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.appMessages = appMessages;

    this.state = {
      isLoading: false,
      configureMode: true,
      copyMode: false
    };

    this.stateManagement.init('ShiftsComponent');

    this.stateSubscription = this.management.onStateChanged$.subscribe((state: { isLoading: boolean, configureMode: boolean, copyMode: boolean }) => {
      if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
      if (_.has(state, 'configureMode')) this.state.configureMode = state.configureMode;
      if (_.has(state, 'copyMode')) this.state.copyMode = state.copyMode;
    });

    this.editSubscription = this.management.editItemCmd$.subscribe((shift: Shift) => {
      if (shift) {
        this.updateProhibitedNameValues(shift);
        if (this.mainForm) {
          this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
            if (this.mainForm.dirty) {
              this.management.markAsDirty();
            }
          });
        }
      } else {
        if (this.mainFormSubscription) {
          this.mainFormSubscription.unsubscribe();
        }
      }
    });

    this.savedSubscription = this.management.savedItemCmd$.subscribe((shift: Shift) => {
      this.parentShift = undefined;
      this.gridState.closeEditor(this.grid);
      this.gridState.refreshGrid();
    });

    this.errorSubscription = this.management.errorNotify$.subscribe((message: string) => {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = false;
      options.showOK = true;
      ConfirmDialogComponent.openDialog(
        'Error',
        message,
        this.modalService,
        (result: boolean) => {
          _.noop();
        }, options);
    });

    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: Shift[]): void => {
      this.crudHelper.selectionChange(_.first(records), 0);
    });

    this.removeSubscription = this.management.removeItemsCmd$.subscribe((request: { dialogOptions: DialogOptions, itemToDelete: Shift }) => {
      if (request.dialogOptions) {

        let dialog: ShiftRemoveDialogComponent = this.modalService.globalAnchor.openDialog(ShiftRemoveDialogComponent, 'Choose shift to reassign employees', request.dialogOptions, null,
          (result: boolean) => {
            if (result) {
              request.itemToDelete = this.management.onRemoveChildPartialShifts(request.itemToDelete);
              this.management.doRemoveItem(request.itemToDelete, dialog.shift);
            }
          }
        );
        dialog.shifts = this.management.container.records.filter((obj: Shift, index: number, array: Shift[]) => {
          if (obj === request.itemToDelete || obj.id === 0)
            return false;
          return true;
        });

      } else if (request.itemToDelete.usedInIdealSchedule) {

        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Confirmation',
          'Shift used in Ideal Schedule. Do you want to delete the shift?',
          this.modalService,
          (result: boolean) => {
            if (result) {
              request.itemToDelete = this.management.onRemoveChildPartialShifts(request.itemToDelete);
              this.management.doRemoveItem(request.itemToDelete);
            }
          }, options);

      } else {

        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Confirmation',
          'Do you want to delete the shift?',
          this.modalService,
          (result: boolean) => {
            if (result) {
              request.itemToDelete = this.management.onRemoveChildPartialShifts(request.itemToDelete);
              this.management.doRemoveItem(request.itemToDelete);
            }
          }, options);
      }
    });

    this.crudHelper.grid = this.grid;
    this.crudHelper.init();
    this.management.init();
    this.state.isLoading = false;
    this.onLoadSubscription = this.management.onLoaded$.subscribe(() => {
      this.stateManagement.loadedData({});
      this.loadingPanelVisible = false;
    });
  }

  // Must be, see #issueWithAOTCompiler
  public ngOnDestroy(): void {
    if (this.crudHelper) {
      this.crudHelper.destroy();
    }
  }
  private getSettings() {
    let appServerConfig: Promise<AppServerConfig> = this.appSettingManageService.getAppServerConfig();
    appServerConfig.then((result: AppServerConfig) => {
      this.isDefaultShiftEnabled = result.isDefaultShiftEnabled;
      this.isPartialShiftEnabled = result.isPartialShiftEnabled;
      this.defaultPartialShiftCount = result.defaultPartialShiftCount;
    });
  }
  public onCopyItems(event: MouseEvent): void {
    event.preventDefault();
    this.management.openCopyItems();
  }

  public switchToConfigure(): void {
    this.management.closeCopyItems();
  }

  public updateProhibitedNameValues(shift: Shift): void {
    if (shift) {
      let values: string[] = [];
      _.each(this.management.container.records, (s: Shift) => {
        if (shift.id !== s.id) {
          values.push(s.name);
        }
      });
      this.prohibitedNameValues = values;
    }
  }

  public onShiftDateChanged(shift: Shift): void {
    const start = moment(shift.start);
    const end = moment(shift.end);
    if (this.parentShift?.start && this.parentShift?.end) {
      this.adjustShiftToParentRange(shift, start, end);
    }
  
    this.updateShiftTimingAndDuration(shift, start, end);
  }
  
  private adjustShiftToParentRange(shift: Shift, start: moment.Moment, end: moment.Moment): void {
    const parentStart = moment(this.parentShift.start);
    const parentEnd = moment(this.parentShift.end);
    const parentCrossesMidnight = parentStart.isAfter(parentEnd, 'minute');
  
    this.handleChildCrossesMidnight(start, end);
  
    if (parentCrossesMidnight) {
      this.adjustForParentCrossingMidnight(start, end, parentStart, parentEnd);
    } else {
      this.adjustForParentNotCrossingMidnight(start, end, parentStart, parentEnd);
    }
  
    if (start.isSameOrAfter(end)) {
      start.subtract(1, 'day');
    }
  }
  
  private handleChildCrossesMidnight(start: moment.Moment, end: moment.Moment): void {
    if (start.isAfter(end, 'minute')) {
      end.add(1, 'day');
    }
  }
  
  private adjustForParentCrossingMidnight(
    start: moment.Moment,
    end: moment.Moment,
    parentStart: moment.Moment,
    parentEnd: moment.Moment
  ): void {
    if (start.isBefore(parentStart) && start.isAfter(parentEnd)) {
      start.add(1, 'day');
    }
    if (end.isAfter(parentEnd) && end.isBefore(parentStart)) {
      end.subtract(1, 'day');
    }
  }
  
  private adjustForParentNotCrossingMidnight(
    start: moment.Moment,
    end: moment.Moment,
    parentStart: moment.Moment,
    parentEnd: moment.Moment
  ): void {
    if (start.isBefore(parentStart)) {
      start.add(1, 'day');
    }
    if (end.isAfter(parentEnd)) {
      end.subtract(1, 'day');
    }
  }
  public updateShiftTimingAndDuration(shift: Shift, start: moment.Moment, end: moment.Moment): void {
    shift.start = start.toDate();
    shift.end = end.toDate();
    let hours: number = dateTimeUtils.getDurationDiffHours(shift.start, shift.end);
    hours = parseFloat(hours.toFixed(2));
    let duration: number = hours - shift.lunchDuration;
    if (duration < 0 || _.isNaN(shift.lunchDuration) && duration < shift.lunchDuration) {
      shift.duration = 0;
      shift.lunchDuration = duration;
      return;
    }

    if (shift.lunchDuration > 0) {
      shift.duration = hours - shift.lunchDuration;
    } else {
      shift.duration = hours;
    }

    if ((!shift.isNameEdited && this.management.isEditingNewItem) || (shift.parentShiftId > 0)) {
      shift.name = Shift.createShiftName(shift.start, shift.end);
    }
  }

  public shiftPaidTimeChanged(shift: Shift, value: number): void {
    if (_.isNaN(value)) {
      shift.duration = 0;
      return;
    }

    shift.duration = value > 0 ? value : 0;

    if (shift.start) {
      let totalHours: number = shift.duration + shift.lunchDuration;
      let milliseconds: number = totalHours * 1000 * 60 * 60;
      shift.end = new Date(milliseconds + shift.start.getTime());
    }

    if (!shift.isNameEdited && this.management.isEditingNewItem) {
      shift.name = Shift.createShiftName(shift.start, shift.end);
    }
  }

  public shiftUnpaidTimeChanged(shift: Shift, value: number): void {
    if (_.isNaN(value)) {
      shift.lunchDuration = 0;
      return;
    }

    let hours: number = dateTimeUtils.getDurationDiffHours(shift.start, shift.end);
    shift.lunchDuration = value > 0 ? value : 0;
    shift.duration = hours - shift.lunchDuration;

    if (shift.duration < 0) {
      shift.duration = 0;
    }
    if (shift.start) {
      let totalHours: number = shift.duration + shift.lunchDuration;
      let milliseconds: number = totalHours * 1000 * 60 * 60;
      shift.end = new Date(milliseconds + shift.start.getTime());
    }
  }

  public descriptionChanged(shift: Shift): void {
    shift.isNameEdited = true;
  }

  public onMobileRendererEvent(action: EditableListActionKind, item: Shift, index: number): void {
    if (action === EditableListActionKind.SELECTION_CHANGE) {
      this.crudHelper.selectionChange(item, index);
    } else if (action === EditableListActionKind.START_EDIT) {
      this.crudHelper.startEdit(item, index);
    }
  }

  public onMobileEditorEvent(action: EditableListActionKind, item: Shift, index: number): void {
    if (action === EditableListActionKind.COMPLETE_EDIT) {
      this.crudHelper.completeEdit(item, index);
    } else if (action === EditableListActionKind.CANCEL_EDIT) {
      this.crudHelper.cancelEdit(index);
    }
  }

  public get minDateLimit(): Date {
    return this.parentShift ? this.parentShift.start : new Date();
  }

  public get maxDateLimit(): Date {
    return this.parentShift ? this.parentShift.end : new Date();
  }


  public defaultShiftChange(shift: Shift, index: number, e: any) {
    let defaultShift = _.filter(this.crudHelper.container.records, (i) => i.defaultshift == 1);
    let isShiftPresent = _.some(defaultShift, (i) => i.id == shift.id);
    if (defaultShift.length == 3 && isShiftPresent && e == 'NO') {
      InfoDialogComponent.OpenDialog(
        'Warning',
        'User not allowed to remove already configured Default Shift',
        this.modalService);
      this.crudHelper.cancelEdit(index);
      return;
    }
    if (defaultShift.length === 3 && !isShiftPresent && e == 'YES') {
      InfoDialogComponent.OpenDialog(
        'Warning',
        `Please note, these changes to the Default Shift will take affect on ${moment().format('MM/DD/YYYY')}`,
        this.modalService);
      return;
    }
  }

  public onAllowPartialShiftChanged(shift: Shift, index: number) {
    // Up on partial shift enable
    if (shift.hasPartialShift && shift.partialShiftList.length == 0) {
      shift.shiftSetting = new ShiftSetting();
      shift.shiftSetting.isUpdated = true;
      shift.shiftSetting.minPartialShiftCount = this.defaultPartialShiftCount;
      let setting = shift.shiftSetting;
      let remaining = shift.duration + shift.lunchDuration;
      let count = setting.minPartialShiftCount;

      // Step 1: Calculate the average duration for each partial shift based on count
      let averageShiftDuration = _.round(remaining / count);
      // Step 2: Start with equal distribution of durations
      let partialDurations = Array(count).fill(averageShiftDuration);
      // Step 3: Adjust the last partial shift to ensure it meets the minimum required duration of 1 hour
      let totalAssignedDuration = _.sum(partialDurations);
      let lastPartialDuration = remaining - (totalAssignedDuration - averageShiftDuration);

      if (lastPartialDuration < 1) {
          // If the last partial shift is less than 1 hour, adjust the previous one to compensate
          let adjustment = 1 - lastPartialDuration;
          partialDurations[partialDurations.length - 2] -= adjustment;
          lastPartialDuration += adjustment;
      }
      // Update the last partial duration to cover the remaining time
      partialDurations[partialDurations.length - 1] = lastPartialDuration;
      // Step 4: Create partial shifts based on the calculated dynamic durations
      let newStart = shift.start;
      for (let pDuration of partialDurations) {
          let newPS = this.createPartialShift(shift, newStart, pDuration);
          newStart = newPS.end;
      }
      this.grid.expandRow(index);
      this.settingPopover.show();
    }
    if (!shift.hasPartialShift) {
      shift.partialShiftList.forEach((ps) => { if (ps.id > 0) { shift.deletedPartials.push(ps); } });
      //TODO: Create A dialog box before clearing.
      shift.partialShiftList.splice(0, shift.partialShiftList.length);
      //Reset ShiftSetting
      shift.shiftSetting = new ShiftSetting();
      this.grid.collapseRow(index);
      if (this.settingPopover && this.settingPopover.popoverDir) {
        this.settingPopover.popoverDir.toggle();
      }
    }
    this.parentShift = shift;
    this.management.editingItem = shift;
  }

  private originalPartialShiftList = undefined;

  onEdit($event: EditEvent) {
    this.parentShift = $event.dataItem;
    this.originalPartialShiftList = _.cloneDeep($event.dataItem.partialShiftList);
    $event.dataItem.isEditing = true;
    $event.sender.expandRow($event.rowIndex);
    this.gridState.editHandler($event);
  }

  onCancel($event: CancelEvent) {
    $event.dataItem.deletedPartials = [];
    $event.dataItem.partialShiftList = this.originalPartialShiftList;
    this.parentShift = undefined;
    this.originalPartialShiftList = undefined;
    this.grid.collapseRow($event.rowIndex);
    this.gridState.cancelHandler($event);
    $event.dataItem.isEditing = false;
  }


  public hasPartialDataOrNew(shift: Shift): boolean {
    return shift.hasPartialShift ? true : false;
  }


  pgAddHandler($event: AddEvent) {
    this.missingShiftSubscription = this.getMissingTimeRanges(this.parentShift).subscribe(
      (missingRanges) => {
        // Ensure missingTimeRanges is defined
        this.missingTimeRanges = missingRanges || [];
        
        const duration = this.management.calculateTotalShiftDurations(this.parentShift.partialShiftList, this.parentShift);
        if (duration.totalPartialShiftDuration + 0.5 > duration.totalShiftDuration) {
          $event.dataItem = this.createPartialShift(
            this.parentShift,
            moment(this.parentShift.end).add(-1, 'hours').toDate(),
            1
          );      
        } else if (this.missingTimeRanges.length > 0) {
          const firstMissingRange = this.missingTimeRanges[0];
          $event.dataItem = this.createPartialShift(this.parentShift, firstMissingRange.start, firstMissingRange.duration);
          this.missingTimeRanges.splice(0, 1);
        } else {
          $event.dataItem = this.createPartialShift(this.parentShift, this.parentShift.start, 1);
        }
        
        $event.sender.editRow($event.rowIndex);
        this.sortShifts(this.parentShift.partialShiftList, moment(this.parentShift.start));
      },
      (error) => {
        console.error("Failed to retrieve missing time ranges:", error);
      }
    );
  }


  pgRemoveHandler($event) {
    if ($event.rowIndex < 0) {
      return;
    }
    if(this.parentShift.partialShiftList.length == 1) {
      InfoDialogComponent.OpenDialog(
        'Warning',
        'At least 1 Partial Shift must be configured. Please disable Partial Shifts or add at least 1 Partial Shift.',
        this.modalService);
      return;
    }
    $event.dataItem.isDeleted = true;
    if ($event.dataItem.id > 0) {
      this.parentShift.deletedPartials.push($event.dataItem);
    }
    this.parentShift.partialShiftList.splice($event.rowIndex, 1);
    this.missingShiftSubscription = this.getMissingTimeRanges(this.parentShift).subscribe();
  }

  pgCancelHandler($event) {
    $event.dataItem.isUpdated = false;
    $event.sender.closeRow($event.rowIndex);
  }

  pgEditHandler($event) {
    $event.sender.editRow($event.rowIndex);
  }

  pgSaveHanlder($event) {
    $event.dataItem.isUpdated = true;
    $event.sender.closeRow($event.rowIndex);
  }

  onSaveShiftSetting(shift: Shift, index: number) {
    shift.shiftSetting.isUpdated = true;
  }

  private async applyShiftSetting(shift: Shift) {
    if (shift && shift.id && !shift.shiftSetting.isUpdated) {
      await this.management.getShiftSetting(shift.id).then(setting => {
        if (!setting) {
          setting = new ShiftSetting();
          setting.shiftId = shift.id;
          setting.minPartialShiftCount = this.defaultPartialShiftCount;
        }
        if (setting.minPartialShiftCount == 0) {
          setting.minPartialShiftCount = this.defaultPartialShiftCount;
        }
        shift.shiftSetting = setting;
      });
    }
  }

  onShowShiftSetting(shift: Shift) {
    if (shift.hasPartialShift) {
      this.applyShiftSetting(shift);
    }
    else {
      this.settingPopover.hide();
    }
  }

  private createPartialShift(parent: Shift, newStart: Date, addDuration: number, lunchduration: number = 0): Shift {

    let ps: Shift = new Shift();
    ps.isUpdated = true;
    ps.start = newStart;
    let endMoment: moment.Moment = moment(newStart).clone().add(addDuration, 'hours');
    ps.end = endMoment.toDate();
    ps.name = Shift.createShiftName(ps.start, ps.end);
    ps.duration = addDuration;
    ps.lunchDuration = lunchduration;
    ps.hasPartialShift = false;
    ps.shiftSetting = new ShiftSetting();
    if (parent) {
      ps.group = parent.group;
      ps.parentShiftId = parent.id;
      parent.partialShiftList.push(ps);
    }
    return ps;
  }

  private getMissingTimeRanges(shift: Shift): Observable<{ start: Date; end: Date; duration: number }[]> {
    this.missingTimeRanges = [];
    return of(shift.partialShiftList).pipe(
      map((splittedShifts) => {
        const mainStart = moment(shift.start);
        const mainEnd = moment(shift.end);
        const sortedShifts = this.getNormalizedAndSortedShifts(splittedShifts, mainStart, mainEnd);
        this.missingTimeRanges = [
          ...this.getMissingRangeBeforeFirstShift(mainStart, sortedShifts),
          ...this.getMissingRangesBetweenShifts(sortedShifts),
          ...this.getMissingRangeAfterLastShift(mainEnd, sortedShifts)
        ];
        this.missingTimeRanges.sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf());
        return this.missingTimeRanges;
      })
    );
  }
  
  private getNormalizedAndSortedShifts(
    splittedShifts: { start: Date; end: Date }[],
    mainStart: moment.Moment,
    mainEnd: moment.Moment
  ): { start: moment.Moment; end: moment.Moment }[] {
    return splittedShifts
      .map((shift) => {
        let start = moment(shift.start);
        let end = moment(shift.end);
  
        // Handle shifts crossing midnight
        if (end.isBefore(start)) {
          end.add(1, 'day');
        }
  
        // Align shifts within parent range
        while (start.isBefore(mainStart)) {
          start.add(1, 'day');
          end.add(1, 'day');
        }
        while (start.isAfter(mainEnd)) {
          start.subtract(1, 'day');
          end.subtract(1, 'day');
        }
        return { start, end };
      })
      .sort((a, b) => a.start.valueOf() - b.start.valueOf());
  }
  
  private getMissingRangeBeforeFirstShift(
    mainStart: moment.Moment,
    sortedShifts: { start: moment.Moment; end: moment.Moment }[]
  ): { start: Date; end: Date; duration: number }[] {
    if (sortedShifts.length === 0 || !mainStart.isBefore(sortedShifts[0].start)) {
      return [];
    }
  
    const duration = this.calculateDuration(mainStart, sortedShifts[0].start);
    return duration > 0 && duration <= 24
      ? [{ start: mainStart.toDate(), end: sortedShifts[0].start.toDate(), duration }]
      : [];
  }
  
  private getMissingRangesBetweenShifts(
    sortedShifts: { start: moment.Moment; end: moment.Moment }[]
  ): { start: Date; end: Date; duration: number }[] {
    const missingRanges = [];
  
    for (let i = 0; i < sortedShifts.length - 1; i++) {
      const endCurrentShift = sortedShifts[i].end;
      const startNextShift = sortedShifts[i + 1].start;
  
      if (endCurrentShift.isBefore(startNextShift)) {
        const duration = this.calculateDuration(endCurrentShift, startNextShift);
        if (duration > 0 && duration <= 24) {
          missingRanges.push({
            start: endCurrentShift.toDate(),
            end: startNextShift.toDate(),
            duration
          });
        }
      }
    }
  
    return missingRanges;
  }
  
  private getMissingRangeAfterLastShift(
    mainEnd: moment.Moment,
    sortedShifts: { start: moment.Moment; end: moment.Moment }[]
  ): { start: Date; end: Date; duration: number }[] {
    if (sortedShifts.length === 0 || !sortedShifts[sortedShifts.length - 1].end.isBefore(mainEnd)) {
      return [];
    }
  
    const duration = this.calculateDuration(sortedShifts[sortedShifts.length - 1].end, mainEnd);
    return duration > 0 && duration <= 24
      ? [{ start: sortedShifts[sortedShifts.length - 1].end.toDate(), end: mainEnd.toDate(), duration }]
      : [];
  }
  

  private calculateDuration(start: moment.Moment, end: moment.Moment): number {
    if (end.isBefore(start)) {
      end = end.add(1, 'day');
    }
    const duration = moment.duration(end.diff(start));
    return duration.asHours();
  }

  public sortShifts(partialShifts: Shift[], parentStartTime: moment.Moment): Shift[] {
    return partialShifts.sort((a, b) => {
      // Get start times as moment objects
      const aStartTime = moment(a.start);
      const bStartTime = moment(b.start);

      // Normalize start times by adding a day if they are less than the parent start time
      const normalizeStartTime = (startTime: moment.Moment): moment.Moment => 
          startTime.isBefore(parentStartTime) ? startTime.add(1, 'day') : startTime;

      // Sort based on the normalized start times
      return normalizeStartTime(aStartTime).valueOf() - normalizeStartTime(bStartTime).valueOf();
    });
  }
}

import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Provider, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription ,  Observable } from 'rxjs';
import { EmployeeListMapService } from './../../../../../employee/employee-list/services/employee-list/employee-list-map.service';
import { GenericListDefinition } from '../../../../../organization/models/generic-list/generic-list-definition';
import { UserApplication } from '../../../../../state-model/models/index';
import { GenericListApiService } from '../../../../../organization/services/generic-list/generic-list-api.service';
import { orgLevelReducer } from './../../../../../store/org-level/org-level.reducer';
import { EmployeeActionDefinition } from './../../../../../employee/employee-list/models/employeeActionDefinition';
import { GroupSmsComponent } from './../../../../../employee/employee-list/components/group-sms/group-sms.component';
import { EmployeeCallLogNavigationService } from './../../../../../common/services/navigation/employee-call-log-navigation.service';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN } from './../../../../../core/models/tokens';

import { ModalRef } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';

import { OrgLevel } from '../../../../../state-model/models/index';
import { EmployeeCallLogManagementService } from '../../../services/index';
import { ModalService } from './../../../../../common/services/modal/modal.service';
import { ModalBehavior } from './../../../../../common/models/modal-behavior';
import { TimeclockCommunicationComponent } from './../../timeclock-communication/timeclock-communication.component';
import { RangeDates, IRangeDates } from '../../../../../common/models/range-dates';
import { LookupService } from '../../../../../organization/services/index';
import { LookupType, Lookup } from '../../../../../organization/models/index';

import { mutableSelect } from '../../../../../core/decorators/index';
import { StateManagementService } from '../../../../../common/services/index';
import { ComponentStateStorageService } from '../../../../../common/services/component-state/component-state-storage.service';
import { ControlStateKey, IControlState, StateResetTypes } from '../../../../../core/models/settings/index';
import { EmployeeDefinition } from '../../../../../organization/models/index';
import { scheduleMicrotask } from '../../../../../core/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-call-logs',
  templateUrl: 'employee-call-logs.component.html',
  styleUrls: ['employee-call-logs.component.scss'],
  providers: [StateManagementService]
})
export class EmployeeCallLogsComponent implements OnInit, OnDestroy {
  public state: {
    isLoading: boolean;
    startDate: Date;
    endDate: Date;
    isLeftSidebarOpen :boolean
  };
  @mutableSelect('application')
  public application$: Observable<UserApplication>;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;
  public actions: any[];
  public pageSize: number;
  public empFilter: any;
  public employeeLink: boolean;
  public employees: EmployeeDefinition[];

  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private appSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private messageCenterEnabledSubscription: Subscription;

  private employeesIds: number[];
  private navigateService: EmployeeCallLogNavigationService;

  private m_dateFiltersControlId: string = 'DateFiltersNgx';
  private m_dateFiltersResetType: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;

  private m_employeeFilterControlId: string = 'EmployeeFiltersNgx';
  private m_employeeFiltersResetType: StateResetTypes = StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
  private stateKey: ControlStateKey;
  public screenWidth: number;
  @unsubscribe()
  private isLeftSidebarOpenSubscripion: Subscription;

  constructor(
    private managementService: EmployeeCallLogManagementService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private lookupService: LookupService) {

    this.state = {
      isLoading: false,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(), 
      isLeftSidebarOpen : false,
    };    
    this.pageSize = 20;
    this.employeeLink = true;
    this.employeesIds = [];
    this.navigateService = new EmployeeCallLogNavigationService(this.router, this.activatedRoute);
  }
  
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
  }

  public ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.isDesktop();
    this.stateManagement.init('EmployeeCallLogsComponent', false);

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevel = orgLevel;
      this.updateList();
      this.managementService.freeze = true;
      this.managementService.orgLevelChanged(this.orgLevel);
    });

    this.appSubscription = this.application$.subscribe((app: UserApplication) => {
      this.managementService.applicationId = app.id;
    });

    this.loadStatusSubscription = this.managementService.onLoadStatus$.subscribe((value: boolean) => {
      this.state.isLoading = value;
    });

    this.loadSubscription = this.stateManagement.loadData$.subscribe(() => {
      this.managementService.dateRangeChanged(this.state.startDate, this.state.endDate);
    });

    this.managementService.smsDataLoaded$.subscribe((value: EmployeeActionDefinition) => {
      this.showDialog2(value);
    });

    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(callLog => {
      const sendSmsItem: any = _.find(this.actions, (a) => a.type === 'send-sms');
      if (sendSmsItem){
          if (!callLog.canSendSms)this.actions = this.actions.filter(action=>action.type!='send-sms');
          else sendSmsItem.enabled = callLog.canSendSms;
        }

      const logPhoneCallItem: any = _.find(this.actions, (a) => a.type === 'log-phone-call');
      if (logPhoneCallItem) logPhoneCallItem.enabled = callLog.canLogPhoneCall;

      const sendClockMessageItem: any = _.find(this.actions, (a) => a.type === 'send-clock-message');
      if (sendClockMessageItem) sendClockMessageItem.enabled = callLog.canSendToTimeClock;
    });

    this.actions = [
      { type: 'send-sms', displayName: 'Send SMS' },
      { type: 'log-phone-call', displayName: 'Log Phone Call', winClass: '' },
      { type: 'send-clock-message', displayName: 'Send Clock Message', winClass: TimeclockCommunicationComponent }
      // { type: 'send-email', displayName: 'Send Email', winClass: '' },
      // { type: 'conversation-note', displayName: 'Conversation Note', winClass: '' }
    ];

    this.messageCenterEnabledSubscription = this.managementService.messageCenterEnabled$.subscribe(() => {
      this.managementService.dateRangeChanged(this.state.startDate, this.state.endDate);
    });
    this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe((value: boolean) => {
      scheduleMicrotask(() => { this.state.isLeftSidebarOpen = value; });
    });
  }

  public ngOnDestroy(): void { }

  public onDataRangeChange({ startDate, endDate }: IRangeDates): void {
    this.state.startDate = startDate;
    this.state.endDate = endDate;
    this.saveDateFiltersStates();
  }

  public showWindow(action: any): void {
    switch (action.type) {
      case 'send-clock-message':
        this.navigateService.navigateToSendClockMessage();
        break;
      case 'log-phone-call':
        this.navigateService.navigateToLogPhoneCall();
        break;
      case 'send-sms':
        this.managementService.loadSmsData();
        break;
      default:
        this.modalService.globalAnchor.openInfoDialog('Warning', action.displayName + ' hasn\'t implemented yet');
    }
  }

  public onEmployeesChange(employeesIds: number[]): void {
    this.employeesIds.length = 0;
    if (_.isArray(employeesIds)) {
      this.employeesIds.push(...employeesIds);
    }
    this.saveEmployeeFilterStates(this.employeesIds);
    this.managementService.employeesChanged(this.employeesIds);
  }

  public showDialog2(actionDefinition: EmployeeActionDefinition): void {
   GroupSmsComponent.openDialog(this.modalService, this.managementService.getSmsWindowProviders(actionDefinition), (result: boolean) => {
      if (result) {
      }
    });
  }

  private saveDateFiltersStates(): void {
    this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId,
      {
        value: { startDate: this.state.startDate, endDate: this.state.endDate }
      },
      this.m_dateFiltersResetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
  }

  private saveEmployeeFilterStates(employeesIds: any): void {
    if (!_.isArray(employeesIds)) {
      employeesIds = [];
    }
    this.storageService.setControlState(this.stateManagement.componentKey, this.m_employeeFilterControlId + this.orgLevel.id,
      {
        value: { employeesIds: employeesIds.join(',') }
      },
      this.m_employeeFiltersResetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.m_employeeFilterControlId);
  }

  private restoreStates(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
    if (state && state.value) {
      if (state.value.startDate) this.state.startDate = moment(state.value.startDate).toDate();
      if (state.value.endDate) this.state.endDate = moment(state.value.endDate).toDate();
      this.managementService.dateRangeChanged(this.state.startDate, this.state.endDate);
    }

    state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_employeeFilterControlId + this.orgLevel.id);
    this.employeesIds = [];
    if (state && state.value) {
      if (state.value.employeesIds && state.value.employeesIds.length) {
        let ids = state.value.employeesIds.split(',');
        this.employeesIds = _.map(ids, id => +id);
      }
    }
    this.updateSelectedUser();
    this.managementService.freeze = false;
    this.managementService.employeesChanged(this.employeesIds);
  }

  private updateList(): void {
    if (!this.lookupService) return;

    this.lookupService.getLookup({ lookupType: LookupType.employeeList, orgLevelId: this.orgLevel.id, isActive: false })
      .then((lookup: Lookup) => {
        this.employees = lookup.items;
        this.restoreStates();
      }).catch(() => {
        this.employees = [];
      });
  }

  private updateSelectedUser(): void {
    if (this.employees && this.employeesIds) {
      this.empFilter = [];
      for (let selectedEmpId of this.employeesIds) {
        let selectedEmployee: any = _.filter(this.employees, (emp: any) => {
          return emp.masterId === selectedEmpId;
        });
        if (selectedEmployee && selectedEmployee.length > 0) {
          _.each(selectedEmployee, (emp: any) => {
            this.empFilter.push(emp.masterId);
          });
        }
      }
    }
  }

  public isDesktop(){
    return this.screenWidth > 1023 ? true : false
  }

}

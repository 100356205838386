import * as _ from 'lodash';
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { IncidentReportDialogOptions, WcReport } from '../../../models/index';
import { Employee } from '../../../../../organization/models/index';
import { WCReportManagementService } from '../../../services';
import { OrgLevel } from '../../../../../state-model/models';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'slx-wc-incident-report-container',
    templateUrl: 'wc-incident-report-container.component.html',
    styleUrls: ['wc-incident-report-container.component.scss'],
    providers: [WCReportManagementService]
})
export class WCIncidentReportContainerComponent implements OnInit, OnDestroy {

    @Input()
    public options: IncidentReportDialogOptions;

    @Output()
    public action = new EventEmitter<boolean>();
    @Output()
    public employeeSelected = new EventEmitter<number>();

    public get editMode(): boolean {
        return this.report ? this.report.editMode : false;
    }

    public get hasEmployee(): boolean {
        return _.isFinite(this.employeeId);
    }
    public get hasReportId(): boolean {
        return _.isFinite(this.reportId);
    }

    public get canSave(): boolean {
        return this.report.isValid;
    }

    public get canAdd(): boolean {
        return this.report.canAdd;
    }

    public get canEdit(): boolean {
        return this.report.canEdit;
    }

    public orgLevelId: number;
    public isCreatingNew: boolean;

    public isLoading: boolean;
    public hasError: boolean;

    public errorMessage: string;

    @unsubscribeAll()
    public subscriptions: StringMap<Subscription> = {};

    public report: WcReport;

    public formValid: boolean;

    private employeeId: number;
    private reportId: number;

    constructor (private management: WCReportManagementService) {}

    public ngOnInit(): void {

        this.subscriptions.orgLevel = this.management.subscribeToOrgLevelChange((o: OrgLevel) => {
            if (o) {
                this.orgLevelId = o.id;
            } else {
                this.orgLevelId = null;
            }
        });

        this.subscriptions.loadStatus = this.management.onLoadStatus$.subscribe((isLoading: boolean) => {
            this.isLoading = isLoading;
        });

        this.subscriptions.load = this.management.subscribeToReportLoad(x => {
            this.hasError = false;
            this.report = x;
            this.reportId = this.report.id;
            this.employeeId = this.report.employee.id;
        });

        this.subscriptions.error = this.management.subscribeToError((msg: string) => {
            this.report = null;
            this.employeeId = null;
            this.reportId = null;
            this.errorMessage = msg;
            this.hasError = true;
        });

        this.subscriptions.close = this.management.subscribeToClosePopup((flag: boolean) => {
            this.onClose(flag);
        });

        this.subscriptions.valid = this.management.onValidityChanged((valid: boolean) => {
            this.formValid = valid;
        });

        if (this.options) {
            if (_.isFinite(this.options.reportId)) {
                this.reportId = this.options.reportId;
                this.management.setReportId(this.options.reportId);
                this.isCreatingNew = false;
            } else if (_.isFinite(this.options.employeeId)) {
                this.employeeId = this.options.employeeId;
                this.management.createNewReport(this.employeeId);
                this.isCreatingNew = true;
            }
        }
    }

    public ngOnDestroy(): void {}

    public onSearchLoadStatusChange(isLoading: boolean): void {
        this.isLoading = isLoading;
    }

    public onEmployeeSelected(emp: Employee): void {
        if (emp) {
            this.employeeId = emp.id;
            this.isCreatingNew = true;
            this.management.createNewReport(this.employeeId);
            this.employeeSelected.emit(this.employeeId);
        }
    }

    public async onSave(): Promise<void> {
        if (this.isCreatingNew) {
            await this.saveReport();
        } else {
            await this.updateReport();
        }
    }

    public onCancel(): void {
        this.management.onCancel();
    }

    public onClose(flag: boolean = false): void {
        this.action.emit(flag);
    }

    public onEdit(): void {
        this.management.toggleEditMode();
    }

    private async saveReport(): Promise<void> {
        try {
            await this.management.saveReport();
        } catch (err) {
            this.errorMessage = err && err.message || 'An error has occurred, can\'t save report';
            console.error(err);
        }
    }

    private async updateReport(): Promise<void> {
        try {
            await this.management.updateReport();
        } catch (err) {
            this.errorMessage = err && err.message || 'An error has occurred, can\'t update report';
            console.error(err);
        }
    }

}

import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { ChangeManagementService } from '../../../../../common/services';
import { WcReport } from '../../../models';

@Component({
    moduleId: module.id,
    selector: 'slx-wc-report-section',
    templateUrl: 'wc-report-section.component.html',
    styleUrls: ['wc-report-section.component.scss']
})

export class WcReportSectionComponent implements OnInit, OnDestroy {

    @Input()
    public notifications: number = 0;
    @Input()
    public title: String;
    @Input()
    public icon: String;
    @Input()
    public validatingFunction: () => boolean;

    public get hasErrors(): boolean {
        if (this.validatingFunction) {
            return this.validatingFunction();
        }
        return false;
    }

    public get isCollapsed(): boolean {
        return this.m_isCollapsed;
    }

    public report: WcReport;


    private m_isCollapsed: boolean = false;


    @unsubscribeAll()
    public subscriptions: StringMap<Subscription> = {};

    constructor (private management: WCReportManagementService,
        private changeDetector: ChangeDetectorRef,
        private changeManagementService: ChangeManagementService) { }

    public ngOnInit(): void {
        this.subscriptions.load = this.management.subscribeToReportLoad(x => {
            this.report = x;
            if (this.report && _.isFinite(this.report.id)) {
                this.m_isCollapsed = true;
            } else {
                this.m_isCollapsed = false;
            }
        });
    }

    public ngOnDestroy(): void {}

    public collapsedChange(isCollapsed: boolean): void {
        this.m_isCollapsed = isCollapsed;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    }

}

import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { ReportParameter } from '../../../models/index';
import { Observable ,  Subscription } from 'rxjs';
import { ILookupEntity, LookupType } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lookup-multiselect-parameter',
  templateUrl: 'lookup-multiselect-parameter.component.html',
  styleUrls: ['lookup-multiselect-parameter.component.scss']
})
export class ReportLookupMultiselectParameterComponent {
  public includeThousandsSeparator: boolean = false;
  private lookupsNamesNotActiveOnly = [LookupType.employeeList];

  @Input()
  public orgLevel: OrgLevel;
  @Input()
  public set parameter(value: ReportParameter) {
    this.m_parameter = value;
    this.processParameter(this.m_parameter);
  }

  public get parameter(): ReportParameter {
    return this.m_parameter;
  }

  public get lookupValue(): ILookupEntity {
    return this.m_lookupValue;
  }

  public set lookupValue(value: ILookupEntity) {
    if (this.parameter) {
      this.parameter.value = value;
    }
    this.m_lookupValue = value;
  }

  public get lookupName(): string {
    if(this.parameter && this.parameter.dataType)
      return this.parameter.dataType.lookupName;

    return undefined;
  }

  //TODO: Rework with getting IsActive parameter attribute from API(stored in report definition)
  public get isActiveOnly(): boolean {
    return _.indexOf(this.lookupsNamesNotActiveOnly, this.lookupName) === -1;
  }

  private m_parameter: ReportParameter;
  private m_lookupValue: ILookupEntity;

  private processParameter(parameter: ReportParameter): void {
    parameter.value = parameter.defaultValue;
  }
}

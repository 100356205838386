import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { unsubscribe } from '../../../../../core/decorators/index';
import { PayrollExportManagementService } from '../../../services/index';
import { PayrollExportContainer } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-payroll-export',
  templateUrl: 'payroll-export.component.html',
  styleUrls: ['payroll-export.component.scss'],
  providers: [PayrollExportManagementService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayrollExportComponent implements OnInit, OnDestroy {

  public isLoading: boolean;
  public data: PayrollExportContainer;


  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private statusSubscription: Subscription;

  constructor(private managementService: PayrollExportManagementService, private changeDetector: ChangeDetectorRef) {

  }

  public ngOnInit(): void {
    this.loadedSubscription = this.managementService.onLoaded$.subscribe((data: PayrollExportContainer) => {
      this.data = data;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
    this.statusSubscription = this.managementService.onLoadStatus$.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}

<!-- #bugInIE11WithEmployeeRecord (ngIf) -->
<form novalidate #form="ngForm" *ngIf="employeeSectionsContacts">
  <div [ngClass]="{ 'col-xs-8': isMessageCenterEnabled, 'col-xs-12': !isMessageCenterEnabled }">
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator  *ngIf="state.isEditMode">
        <input slx-input type="text" maxlength="255" name="address" placeholder="Address"
               [inputPolicy]="employeeSectionsContacts?.address.Policy" [(ngModel)]="employeeSectionsContacts?.address.fieldValue" slxTrim="both">
        <span errorMessage for="required"></span>
      </slx-input-decorator>
      <div class="slx-form-group slx-readonly" *ngIf="!state.isEditMode">
        <label class="slx-label">Address</label>
        <div class="slx-input-group kendo kendo-common kendo-default">
          <div class="address-text">
            {{employeeSectionsContacts?.address.fieldValue}}
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input type="text" maxlength="50" [readonly]="!state.isEditMode" name="city" placeholder="City"
               [inputPolicy]="employeeSectionsContacts?.city.Policy" [(ngModel)]="employeeSectionsContacts?.city.fieldValue" slxTrim="both">
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>

    <div class="col-sm-6 col-lg-2">
      <slx-input-decorator>
        <slx-dropdown-input slx-input lookup="state" [readonly]="!state.isEditMode" name="state"
                            placeholder="State" [inputPolicy]="employeeSectionsContacts?.state.Policy"
                            [(ngModel)]="employeeSectionsContacts?.state.fieldValue">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>

    <div class="col-sm-6 col-lg-2">
      <slx-input-decorator>
        <slx-zip-input slx-input [readonly]="!state.isEditMode" name="zip"
                       placeholder="Zip" [inputPolicy]="employeeSectionsContacts?.zip.Policy"
                       [(ngModel)]="employeeSectionsContacts?.zip.fieldValue">
        </slx-zip-input>
        <span errorMessage for="required"></span>
        <span errorMessage for="min">Min length is 5</span>
        <span errorMessage for="max">Max length is 10</span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-4 col-lg-3" *ngIf="!isMessageCenterEnabled">
      <slx-input-decorator>
        <slx-phone-input slx-input phone [readonly]="!state.isEditMode" name="mobile"
                         placeholder="Mobile Phone" [inputPolicy]="employeeSectionsContacts?.mobile.Policy"
                         [(ngModel)]="employeeSectionsContacts?.mobile.fieldValue">
        </slx-phone-input>
        <span errorMessage for="required"></span>
        <span errorMessage for="phone"></span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-phone-input slx-input phone [readonly]="!state.isEditMode" name="phone" placeholder="Phone"
                         [inputPolicy]="employeeSectionsContacts?.phone.Policy"
                         [(ngModel)]="employeeSectionsContacts?.phone.fieldValue">
        </slx-phone-input>
        <span errorMessage for="required"></span>
        <span errorMessage for="phone"></span>
      </slx-input-decorator>
    </div>

    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-phone-input slx-input phone [readonly]="!state.isEditMode" name="alternativePhone"
                         placeholder="Alt Phone" [inputPolicy]="employeeSectionsContacts?.alternativePhone.Policy"
                         [(ngModel)]="employeeSectionsContacts?.alternativePhone.fieldValue">
        </slx-phone-input>
        <span errorMessage for="required"></span>
        <span errorMessage for="phone"></span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-email-input slx-input slx-email [readonly]="!state.isEditMode" name="email1" maxlength="255"
                         titlename="Email"
                         placeholder="example@ex.com" [inputPolicy]="employeeSectionsContacts?.email1.Policy"
                         [(ngModel)]="employeeSectionsContacts?.email1.fieldValue"
                         [server]="{ validationName: emailValidation, 
                                     parameters: [employeeId], 
                                     validationAdapter: employeeActivitiesValidatorAdapter,
                                     validateOnReadonly: false, 
                                     componentName: 'email1', 
                                     form: form }">
        </slx-email-input>
        <span errorMessage for="required"></span>
        <span errorMessage for="email"></span>
        <span errorMessage *ngIf="state.isEditMode" [for]="emailValidation"></span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-email-input slx-input slx-email [readonly]="!state.isEditMode" name="email2" maxlength="255"
                         titlename="Alt Email"
                         placeholder="example@ex.com" [inputPolicy]="employeeSectionsContacts?.email2.Policy"
                         [(ngModel)]="employeeSectionsContacts?.email2.fieldValue">
        </slx-email-input>
        <span errorMessage for="required"></span>
        <span errorMessage for="email"></span>
      </slx-input-decorator>

    </div>


  </div>
  <div class="col-xs-4" *ngIf="isMessageCenterEnabled">
    <div class="col-sm-6 col-lg-3" *ngIf="isMessageCenterEnabled">
      <slx-input-decorator>
        <slx-phone-input slx-input phone [readonly]="!state.isEditMode" name="mobile"
                         placeholder="Mobile Phone" [inputPolicy]="employeeSectionsContacts?.mobile.Policy"
                         [(ngModel)]="employeeSectionsContacts?.mobile.fieldValue">
        </slx-phone-input>
        <span errorMessage for="required"></span>
        <span errorMessage for="phone"></span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-12 col-lg-12" *ngIf="isMessageCenterEnabled && employeeSectionsContacts?.mobile.fieldValue!=null && !state.isEditMode">
      <div class="group mt-18">
        <div class="sms">
          <span class="sms-icon"><i class="fas fa-comment" aria-hidden="true"></i></span>
          <span class="sms-text">SMS:</span>
        </div>
        <div class="employee-option">
          Employee option to receive SMS messages with any updates to the mobile number saved on their Employee Profile.
        </div>
        <div class="opt-sms">
          <ng-container [ngSwitch]="employeeSectionsContacts.empOptIn">
            <ng-container *ngSwitchCase="1">
              <span class="icon-parent-width mobile-alt">
                <i class="fas fa-mobile-alt sms-opt-in" aria-hidden="true"></i>
              </span>
              <span class="sms-opted">{{firstName}} has <strong>opted in</strong> for SMS messages on <strong>{{employeeSectionsContacts.empOptInDate | date:"MMMM d, y 'at' h:mm a" }}</strong></span>
            </ng-container>
            <ng-container *ngSwitchCase="0">
              <span class="icon-parent-width mobile-alt">
                <i class="fas fa-mobile-alt sms-opt-out" aria-hidden="true"></i>
              </span>
              <span class="sms-opted">{{firstName}} has <strong>opted out</strong> for SMS messages on <strong>{{employeeSectionsContacts.empOptInDate | date:"MMMM d, y 'at' h:mm a" }}</strong></span>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <span class="icon-parent-width mobile-alt">
                <i class="fas fa-mobile-alt sms-opt" aria-hidden="true"></i>
              </span>
              <span class="sms-opted"> {{firstName}} has <strong>not responded</strong> to Opt In for SMS messaging.</span>
            </ng-container>
          </ng-container>
        </div>
        <div class="pText" *ngIf="employeeSectionsContacts.empOptIn != null && employeeSectionsContacts.empOptIn == 2">
          They can make a selection in Smartlinx Go or you can resend the T&C for the employee to Opt In.
        </div>
        <div class="opt-in-sms">
          <ng-container [ngSwitch]="employeeSectionsContacts.empOptIn">
            <ng-container *ngSwitchCase="2">
              <button class="slx-button resendOptIn" (click)="resendOptInSMS()">
                <i class="fas fa-envelope envelopeSMS" aria-hidden="true"></i>Resend Opt-In
              </button>
              <span>
                <i class="fa fa-info-circle infoCircleAlgin" aria-hidden="true"><span [slxTooltip]="optInTemplateText" [tipClass]="'optIn-tip'" tipPosition="left"></span></i>
              </span>
              <div class="pText">Opt In request sent on {{employeeSectionsContacts.empOptInDate | date:"MMMM d, y 'at' h:mm a" }}</div>
            </ng-container>
            <ng-container *ngSwitchCase="0">
              <button class="slx-button resendOptIn" [disabled]="enableEmailOptIn" (click)="resendOptInEmail()">
                <i class="fas fa-envelope envelopeSMS" aria-hidden="true"></i>Email Opt In Instructions
              </button>
              <span>
                <i class="fa fa-info-circle infoCircleAlgin" aria-hidden="true">
                  <span [slxTooltip]="optInTemplateText" [tipClass]="'emailOptIn-tip'" tipPosition="right"></span>
                </i>
              </span>
              <div *ngIf="!enableEmailOptIn && employeeSectionsContacts.empOptInEmailDate != null" class="pText">Email sent on {{employeeSectionsContacts.empOptInEmailDate | date:"MMMM d, y 'at' h:mm a" }}</div>
            </ng-container>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
  <div class="col-xs-12">
    <button class="theme-iconed-accent-button" [employeeCallLogButton]="employeeId" type="button" aria-expanded="true">
      <span class="icon-button-content">
        <span>Call Log</span>
        <i aria-hidden="true" class="fa fa-th-list"></i>
      </span>
    </button>
  </div>

</form>

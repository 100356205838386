import * as signalR from '@microsoft/signalr';
import { SignalrHubConfig } from '../../models';
import { Injectable, OnDestroy } from '@angular/core';
import { ThrottlingService, SessionService } from '../../../core/services';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class SignalrHub2Service implements OnDestroy {
  private signalrHubConfig: SignalrHubConfig;
  private connectionBuilder: signalR.HubConnectionBuilder;
  private connection: signalR.HubConnection;
  public connectionStatedChanged: ReplaySubject<boolean>;

  constructor(
    private throttlingService: ThrottlingService,
    private sessionService: SessionService
  ) {
    this.connectionBuilder = new signalR.HubConnectionBuilder();
    this.connectionStatedChanged = new ReplaySubject<boolean>();
  }

  public init(signalrHubConfig: SignalrHubConfig): void {
    this.signalrHubConfig = signalrHubConfig;

    const options: signalR.IHttpConnectionOptions = {
      transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling,
      accessTokenFactory: () => this.sessionService.getToken()
    };

    this.connection = this.connectionBuilder
      .withUrl(signalrHubConfig.url + '/' + signalrHubConfig.hubName, options)
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this.throttlingService.subscribeToModeChange((event) => {
      this.reconnect().then(() => {

      });
    });

    this.connection.onclose((error?: Error) => {
      this.connectionStatedChanged.next(false);
    });

    this.connection.onreconnecting((error?: Error) => {
      this.connectionStatedChanged.next(false);
    });

    this.connection.onreconnected((connectionId: string) => {
      this.connectionStatedChanged.next(true);
    });

    this.start();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  public subscribe(methodName: string): ReplaySubject<any[]> {
    const subject = new ReplaySubject<any[]>();
    this.connection.on(methodName, (...args: any[]) => {
      subject.next(args);
    });

    return subject;
  }

  public destroy() {
    this.connection.stop();
  }

  private reconnect(): Promise<void> {
    return this.stop().then(() => {
      this.start().then(() => {});
    });
  }

  private start(): Promise<void> {
    return this.connection.start()
      .then(() => {
        this.connectionStatedChanged.next(true);
      }).catch((exc: any) => {
        this.connectionStatedChanged.next(false);
      });
  }

  private stop(): Promise<void> {
    return this.connection.stop()
      .then(() => {
        this.connectionStatedChanged.next(false);
      }).catch((exc: any) => {
        this.connectionStatedChanged.next(this.connection.state === signalR.HubConnectionState.Connected);
      });
  }

  public getConnectionStatus(): signalR.HubConnectionState | undefined{
    if(this.connection){
      return this.connection.state;
    }
    return undefined;
  }

  public RestartConnection(): void{
    if(this.connection && this.connection.state === signalR.HubConnectionState.Disconnected){
      this.reconnect();
    }
  }
}

import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject ,  Observable ,  ReplaySubject ,  Subject ,  Subscription } from 'rxjs';
import {
  ChangeManagementService,
  StateManagementService,
} from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { StateResetTypes } from '../../../core/models/index';
import { ManagementBaseService } from '../../../core/services/index';
import { LookupApiService, ProductModuleMapService } from '../../../organization/services/index';
import { OrgLevel } from '../../../state-model/models/index';
import { PartnerPosition, RoleColumnsState, RolesContainer } from '../../models/index';
import { PartnerPositionsContainer } from '../../models/patner-positions/partner-positions-container';
import { RolesApiService } from '../roles/roles-api.service';
import { PartnerConfigApiService } from './partner-config-api.service';

@Injectable()
export class PartnerConfigManagementService extends ManagementBaseService<PartnerPositionsContainer, any> {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public roleRightFilterChanged$: ReplaySubject<{ filter: CompositeFilterDescriptor; type: string }>;
  public rolesColumnsStateChanged$: BehaviorSubject<RoleColumnsState>;
  public clearChanges$: Subject<any>;
  public currentOrgLevel: OrgLevel;
  public container: RolesContainer;

  @unsubscribeInService()
  private loadSubscription: Subscription;
  @unsubscribeInService()
  private initSubscription: Subscription;

  private apiService: RolesApiService;
  private lookupApiService: LookupApiService;
  private readonly componentId: string = 'UserRolesComponent';
  private readonly controlName: string = 'rolesColumns';
  private readonly resetBy: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;

  constructor(
    apiService: RolesApiService,
    lookupApiService: LookupApiService,
    public changeService: ChangeManagementService,
    private stateService: StateManagementService,
    private partnerConfigApiService: PartnerConfigApiService
  ) {
    super();
    this.clearChanges$ = new Subject();
    this.roleRightFilterChanged$ = new ReplaySubject(1);
    this.rolesColumnsStateChanged$ = new BehaviorSubject<RoleColumnsState>(new RoleColumnsState());
    this.apiService = apiService;
    this.lookupApiService = lookupApiService;
    this.stateService.init(this.componentId);
  }

  public getPartnerPositions(customerId: string, partnerId: string): Promise<any> {
    let promise: Promise<any> = this.partnerConfigApiService
      .getPartnerPositions(customerId, partnerId)
      .then((response) => {
        return response;
      });
    return promise;
  }

  public getPartnerPositionsDefinitions(partnerId: string): Promise<any> {
    let promise: Promise<any> = this.partnerConfigApiService
      .getPartnerPositionsDefinitions(partnerId)
      .then((response) => {
        return response;
      });
    return promise;
  }

  public addPartnerPosition(
    customerId: string,
    partnerId: string,
    partnerPositionsList: PartnerPosition[]
  ): Promise<any> {
    let promise: Promise<any> = this.partnerConfigApiService
      .addPartnerPosition(customerId, partnerId, partnerPositionsList)
      .then((response) => {
        return response;
      });
    return promise;
  }

  public getPartnersList(subscriptionId: number): Promise<any> {
    let promise: Promise<any> = this.partnerConfigApiService.getPartnerDefinitions(subscriptionId).then((response) => {
      return response;
    });
    return promise;
  }

  public deletePosotionMapping(customerId: string, partnerId: string, mapId: number): Promise<any> {
    let promise: Promise<any> = this.partnerConfigApiService
      .DeletePartnerPositionMapping(customerId, partnerId, mapId)
      .then((response) => {
        console.log(response);
        return response;
      });
    return promise;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MomentModule } from 'angular2-moment';

import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { ComponentsLibraryModule } from '../../components-library/index';
import { NgxPopperModule } from 'ngx-popper';

import { services } from './services/index';
import { components ,entryComponents } from './components/index';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ColorPickerModule } from '@progress/kendo-angular-inputs';
@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        HttpClientModule,
        CommonAppModule,
        CommonModule,
        OrganizationModule,
        MomentModule,
        GridModule,
        NgxPopperModule,
        DropDownsModule,
        ComponentsLibraryModule,
        ReactiveFormsModule,
        ChartsModule,
        PDFModule,
        ExcelModule,
        LayoutModule,
        ColorPickerModule,
    ],
    declarations: [
        ...components
    ],
    providers: [
        ...services
    ],
    exports: [
        ...components
    ]
})
export class PunchProfileModule { }

<kendo-grid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  (dataStateChange)="gridState.dataStateChange($event)"
>

  <kendo-grid-column title="Test Name" field="description" width="300">
    <ng-template kendoGridHeaderTemplate>
       Test Name
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="test-name" (click)="onTestClicked(dataItem)">
          <div [ngClass]="'state-indicator ' + getClassColor(dataItem?.statusLabel)"></div>
          <div [ngClass]="{'link-test': isLinkEnabled(dataItem?.testId)}">{{dataItem?.description}}</div>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Last Run User" field="runByUser" width="150">
      <ng-template kendoGridHeaderTemplate>
          Last Run User
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.runByUser }}</span>
      </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Last Run Date" field="runAt" width="150" filter="date">
      <ng-template kendoGridHeaderTemplate>
          Last Run Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.runAt | amDateFormat: appConfig.dateTimeFormatUS }}</span>
      </ng-template>
  </kendo-grid-column>
  
  
  <kendo-grid-column title="Results" field="summary" width="150">
      <ng-template kendoGridHeaderTemplate>
          Results
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [ngClass]="getClassColor(dataItem?.statusLabel)">
          <span>{{ dataItem.summary }}</span>
        </div>
      </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Status" field="statusLabel" width="150">
      <ng-template kendoGridHeaderTemplate>
          Status
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [ngClass]="'status-column ' + getClassColor(dataItem?.statusLabel)">
          <i *ngIf="dataItem.statusLabel === 'Pass'" class="fas fa-check-circle icon" aria-hidden="true"></i>
          <i *ngIf="dataItem.statusLabel === 'Alert'" class="fas fa-exclamation-circle icon" aria-hidden="true"></i>
          <i *ngIf="dataItem.statusLabel === 'Fail'" class="fas fa-exclamation-triangle icon" aria-hidden="true"></i>
          <span>{{ dataItem.statusLabel }}</span>
        </div>
      </ng-template>
  </kendo-grid-column>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

import { EmployeeDefinitionsApiService, EmployeeValidatorAdapter, LookupService } from '../../../../../../organization/services/index';
import { EmployeeShortInfo, EmployeeTerminationReason, Lookup, LookupType } from '../../../../../../organization/models/index';
import { EmployeeToTerminate, EmployeeTerminate } from '../../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-terminate',
  templateUrl: 'employee-terminate.component.html',
  styleUrls: ['employee-terminate.component.scss']
})
export class EmployeeTerminateComponent implements OnInit {
  @Input('employee')
  public set emp(emp: EmployeeToTerminate) {
    this.employee = emp;
    this.setEmployeeId();
  }
  public employee: EmployeeToTerminate;
  public employeeFullName: String;
  public appConfig: IApplicationConfig;
  public requestToTerminate: EmployeeTerminate;
  public employeeShortInfo: EmployeeShortInfo;
  public get minDate(): Date {
    if(!this.employee.ignoreLockedPayrollCycles && this.employee.rehireDate){
      if(!_.isNil(this.employeeShortInfo) && this.employeeShortInfo.effectiveDate > this.employee.rehireDate){
        return this.employeeShortInfo.effectiveDate;
      }
      else{
        return this.employee.rehireDate;
      }
    }
    else if(!this.employee.ignoreLockedPayrollCycles && !this.employee.rehireDate && !_.isNil(this.employeeShortInfo)){
      return this.employeeShortInfo.effectiveDate;
    }
    else{
      return null;
    }
  }

  @ViewChild('form')
  public ngForm: NgForm;
  public reasons: Lookup;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;
  private filterByVoluntare: Function;
  private lookupService: LookupService;
  public employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter;

  constructor(
    employeeDefinitionsApiService: EmployeeDefinitionsApiService, 
    lookupService: LookupService,
    employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter) {
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    this.lookupService = lookupService;
    this.employeeActivitiesValidatorAdapter=employeeActivitiesValidatorAdapter;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.requestToTerminate = new EmployeeTerminate();
    this.requestToTerminate.date = moment().startOf('day').toDate();
    this.requestToTerminate.voluntary = false;
    this.setEmployeeId();
    this.loadLookup();
  }
  public get emailValidation(): string {
    return EmployeeValidatorAdapter.emailValidation;
  }


  public onReasonChange(reason: EmployeeTerminationReason): void {
    this.requestToTerminate.voluntary = reason.isVoluntary;
  }

  private loadLookup(): void {
    this.lookupService.getLookup({ lookupType: LookupType.employeeTerminationReason, employeeId: undefined, orgLevelId: undefined })
      .then((reasons: Lookup) => {
        this.reasons = reasons;
      });
  }

  private setEmployeeId(): void {
    if (this.employee && this.requestToTerminate) {
      this.requestToTerminate.employeeId = this.employee.employeeId;
      this.loadEmployeeInfo();
    }
  }

  private loadEmployeeInfo(): void {
    this.employeeDefinitionsApiService.getEmployeeShortInfo(this.employee.employeeId)
      .then((employeeShortInfo: EmployeeShortInfo) => {
        this.employeeShortInfo = employeeShortInfo;
        this.employeeFullName = this.employeeShortInfo.fullName;
        this.requestToTerminate.email=!_.isNull(this.employeeShortInfo.email) ?this.employeeShortInfo.email : '';
      });
  }
 
}

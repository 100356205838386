<slx-spinner [show]="state.isLoading" novalidate>
    <slx-generic-grid (selectionChanged)="onSelectionChanged($event)"
                      gridKey="APPROVAL_REQUIRED_CODES">
        <form #form="ngForm" class="row-form row-item row-form-fix">
            <div class="row">
                <slx-date-range-ngx
                  name="startEndDate"
                  startDatePlaceholder="Start Date"
                  endDatePlaceholder="End Date"
                  [startDate]="startDate"
                  [endDate]="endDate"
                  (rangeDateChanged)="onFilterDateChanged($event)"
                  slxControlActiveState
                ></slx-date-range-ngx>
                <div class="spacer"></div>
                <div class="element top-offset">
                    <button type="button" class="theme-button-apply btn" (click)="unapprove()" [disabled]="!canUnapprove">Un-Approve Pay Code</button>
                </div>
                <div class="element top-offset">
                    <button type="button" class="theme-button-apply btn" (click)="approve()" [disabled]="!canApprove">Approve Pay Code</button>
                </div>
            </div>
        </form>
    </slx-generic-grid>
</slx-spinner>

import {
  Component, Directive, ContentChild, ElementRef, HostBinding, Input, ViewEncapsulation, DoCheck,
  Optional, ContentChildren, QueryList, Renderer2, OnChanges
} from '@angular/core';
import { IFieldPolicy } from '../../../core/models/index';
import { NgControl } from '@angular/forms';

import { ErrorMessageDirective } from '../../error-filters/errorMessage';
import { InputPolicyDirective } from '../../directives/index';
import * as _ from 'lodash';

@Directive({
  selector: '[slx-input-suffix]'
})
export class SlxInputSuffixDirective {
  @HostBinding('class')
  public className: string = 'slx-input-suffix';
}

@Directive({
  selector: '[slx-input-hint]'
})
export class SlxInputHintDirective {
  @HostBinding('class')
  public className: string = 'slx-input-hint';
}

@Directive({
  selector: 'input[slx-input], textarea[slx-input]'
})
export class SlxInputDirective {
  @Input()
  @HostBinding('disabled')
  public disabled: boolean;

  @Input()
  @HostBinding('readonly')
  public readonly: boolean;

  @Input()
  @HostBinding('required')
  public required: boolean;

  @Input()
  @HostBinding('placeholder')
  public placeholder: string;

  @Input()
  @HostBinding('class')
  public className: string = 'slx-form-control';

  @Input()
  public titlename: string;

  public ngControl: NgControl;
  public ngControlHost: any;
  public elementRef: ElementRef;

  constructor( @Optional() ngControl: NgControl, elementRef: ElementRef) {
    this.ngControl = ngControl;
    this.elementRef = elementRef;
  }

}

/*
Temporary approach since HostBinding does not works with ng2 component inputs
https://github.com/angular/angular/issues/13776
*/
@Directive({
  selector: `
  slx-time-input[slx-input],
  slx-date-picker-ngx[slx-input],
  slx-moment-time-input[slx-input],
  slx-date-time-input[slx-input],
  slx-date-time-picker-ext[slx-input],
  slx-ssn-input[slx-input],
  slx-zip-input[slx-input],
  slx-phone-input[slx-input],
  slx-email-input[slx-input],
  slx-money-input[slx-input],
  slx-dropdown-input[slx-input],
  slx-checkbox-input[slx-input],
  slx-checkbox-toggle[slx-input],
  slx-radio-input[slx-input],
  slx-percent-input[slx-input],
  slx-duration-input[slx-input],
  select[slx-input],
  slx-ipv4-input[slx-input],
  slx-lookup-multiselect[slx-input],
  slx-dropdown-multiselect[slx-input],
  slx-autocomplete-input[slx-input],
  slx-autocomplete-combobox-input[slx-input],
  slx-schedule-cycle-date-picker[slx-input],
  slx-kendo-number[slx-input],
  slxDurationInputInput[slx-input]`
})
export class SlxInputAltDirective implements OnChanges {

  @Input()
  public readonly: boolean;

  @Input()
  public required: boolean;

  @Input()
  public placeholder: string;

  @Input()
  public titlename: string;

  @HostBinding('class')
  public className: string = 'slx-form-control';

  public ngControl: NgControl;
  public ngControlHost: any;
  public elementRef: ElementRef;

  constructor( @Optional() ngControl: NgControl, elementRef: ElementRef) {
    this.ngControl = ngControl;
    if (ngControl) this.ngControlHost = ngControl.valueAccessor;
    this.elementRef = elementRef;
  }

  public ngOnChanges(changes: any): void {
    if (this.ngControlHost) this.ngControlHost.className = this.className;
  }
}

@Component({
  moduleId: module.id,
  selector: 'slx-input-decorator',
  templateUrl: 'input-decorator.component.html',
  styleUrls: ['input-decorator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputDecoratorComponent implements DoCheck {
  private prevStateClass: any;
  @Input()
  public className: string;

  @ContentChild(SlxInputDirective)
  public fieldChild1: SlxInputDirective;

  @ContentChild(SlxInputAltDirective)
  public fieldChild2: SlxInputAltDirective;

  @ContentChild(InputPolicyDirective)
  public inputPolicyDirective: InputPolicyDirective;

  public get fieldChild(): SlxInputDirective | SlxInputAltDirective {
    if (this.fieldChild1) { return this.fieldChild1; }
    if (this.fieldChild2) { return this.fieldChild2; }
    throw Error('Underlying input was not founded. Try to check if you assigned [slx-input] directive');
  }

  public get hideFieldChild(): boolean {
    if (this.inputPolicy) {
      return !this.inputPolicy.isVisible || this.inputPolicy.isMasked;
    }
    return false;
  }

  public get inputPolicy(): IFieldPolicy {
    return this.inputPolicyDirective ? this.inputPolicyDirective.inputPolicy : null;
  }

  public get titlename(): string {
    return this.fieldChild.titlename || this.fieldChild.placeholder;
  }

  @ContentChildren(ErrorMessageDirective)
  public errorMessages: QueryList<ErrorMessageDirective>;
  public get decoratorClass(): any {
    let isReadonly: boolean = false;
    let required: boolean = false;
    if (this.fieldChild) {
      required = Boolean(this.fieldChild.required);
      isReadonly = Boolean(this.fieldChild.readonly);
    }

    let pending: boolean = false;
    let empty: boolean = false;
    if (this.fieldChild && this.fieldChild.ngControl) {
      pending =  this.fieldChild.ngControl.pending;
      empty = !this.fieldChild.ngControl.value;
    }
    let dClass: any = {
      'slx-form-group': true,
      'slx-readonly': isReadonly || (this.inputPolicy ? !this.inputPolicy.isEditable : false),
      'slx-required': required || (this.inputPolicy ? this.inputPolicy.isRequired : false),
      'slx-pending': pending,
      'slx-empty': empty
    };
    if (this.className) {
      dClass[this.className] = true;
    }
    if (_.isEqual(this.prevStateClass, dClass)) {
      return this.prevStateClass;
    } else {
      this.prevStateClass = dClass;
    }
    return dClass;
  }

  public elementRef: ElementRef;
  private alreadyHidden: boolean = false;
  constructor(elementRef: ElementRef, private renderer: Renderer2) {
    this.elementRef = elementRef;
  }
  public ngDoCheck(): void {
    if (this.alreadyHidden || !this.hideFieldChild) {
      return;
    }
    if (!this.fieldChild) {
      return;
    }
    this.renderer.setStyle(this.fieldChild.elementRef.nativeElement, 'display', 'none');
    this.fieldChild.ngControl.control.disable();
    this.alreadyHidden = true;
  }
}

import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IDialog, ModalService, DialogOptions } from '../../../../common/index';
import { Assert } from '../../../../framework/index';
import { Lookup, LookupType, Employee } from '../../../../organization/models/index';
import { VirtualizationSettings } from '@progress/kendo-angular-dropdowns';
import { AbstractControl, NgForm } from '@angular/forms';
import { OrgLevel } from '../../../../state-model/models';
import { LookupService } from '../../../../organization/services/index';
import { Aca1095cRecord } from '../../../models/aca-1095-c';
import { ACA1095cConfig } from '../../../aca-1095.config';

@Component({
  moduleId: module.id,
  selector: 'slx-aca-1095c-add-employee-popup.component',
  templateUrl: 'add-employee-popup.component.html',
  styleUrls: ['add-employee-popup.component.scss']
})

export class Aca1095CAddEmployeePopupComponent implements IDialog, OnInit, OnDestroy {

  private get initialized(): boolean {
    return this.m_initialized;
  }

  @ViewChild('form')
  public ngForm: NgForm;

  @Input()
  public virtual: VirtualizationSettings = {
    itemHeight: 55
  };

  @Output()
  public loadStatusChange: EventEmitter<boolean>;

  @Output()
  public employeeSelected: EventEmitter<Employee>;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  public disabledEmployees: Aca1095cRecord[] = [];
  public orgLevel: OrgLevel;
  public hasEmployee: boolean = false;
  public options: DialogOptions;
  public dialogResult: boolean;
  public isLoading: boolean;
  private modalService: ModalService;
  public employeesLookup: Lookup;
  public employee: Employee;
  public instructions: String;
  public year: number;

  private m_initialized: boolean;

  constructor(
    options: DialogOptions,
    modalService: ModalService,
    private lookupService: LookupService) {
    Assert.isNotNull(modalService, 'modalService');
    Assert.isNotNull(options, 'options');
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
    this.isLoading = false;
    this.loadStatusChange = new EventEmitter<boolean>();
    this.employeeSelected = new EventEmitter<Employee>();
    this.employeesLookup = this.createLookup([], '', '');
  }

  public ngOnInit(): void {
    this.m_initialized = true;
    this.loadSubsection();
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public loadSubsection(): void {
    if (this.orgLevel && this.orgLevel.id) {
      this.loadLookup();
    }
  }

  private loadLookup() {
    this.loadStatusChange.emit(true);
    this.lookupService.getLookup({ lookupType: LookupType.employeeList, orgLevelId: this.orgLevel.id, isActive: false })
      .then((employeesLookup: Lookup) => {
        this.employeesLookup = this.filterLookup(employeesLookup);
        this.loadStatusChange.emit(false);
      });
  }

  private filterLookup(lookup: Lookup): Lookup {
    if (!lookup || !lookup.items) {
      return lookup;
    }

    lookup.items = _.filter(lookup.items,
      item => !_.some(ACA1095cConfig.hiddenEmployeeTypesForAdd,
        hiddenType => _.trim(_.toLower(hiddenType)) === _.trim(_.toLower(item.type))
      ) && (!item.terminationDate || moment(item.terminationDate).year() >= this.year)
    );

    return lookup;
  }

  public isEmployeeExist(employeeId: any) {
    const employeeList = _.filter(this.disabledEmployees,
      (employee: Aca1095cRecord) => employee.employeeId == employeeId
    );

    return employeeList.length > 0;
  }

  public itemDisabled = (itemArgs: { dataItem: string, index: number }): boolean => {
    return this.isEmployeeExist(itemArgs.dataItem['id']);
  }

  public ngOnDestroy(): void { }

  public onEmployeeChanged(emp: Employee): void {
    if (_.isNil(emp)) {
      this.hasEmployee = false;
    } else {
      this.hasEmployee = true;
    }
    this.employee = emp;
    this.employeeSelected.emit(emp);
  }

  private createLookup(items: any[], titleField: string, valueField: string): Lookup {
    let lookup: Lookup = new Lookup();
    lookup.titleField = titleField;
    lookup.valueField = valueField;
    lookup.items = items;
    return lookup;
  }
}

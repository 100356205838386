<slx-spinner [show]="state.isLoading">
    <div class="copy-paycodes-main-container">
        <div class="select-paycodes">
            <p class="select-head">Select Pay Codes to Copy</p>
            <kendo-grid class="copy-paycodes-grid" [kendoGridBinding]="currentPaycodes" [filterable]="'menu'">
                <!-- Pay Code ID Column -->
                <kendo-grid-column title="Pay Code ID" field="payCodeId" filter="text" [width]="50">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.id }}
                    </ng-template>
                </kendo-grid-column>

                <!-- Pay Code Column with Link -->
                <kendo-grid-column title="Pay Code" field="paycodeName" filter="text" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a (click)="handleDescriptionClick($event, dataItem.id)">
                            {{ dataItem.paycodeName }}
                        </a>
                    </ng-template>
                </kendo-grid-column>

                <!-- Checkbox Column -->
                <kendo-grid-checkbox-column [width]="50">
                    <ng-template kendoGridHeaderTemplate>
                        <input type="checkbox" class="k-checkbox checkbox-copy-paycodes" [checked]="isAllPaycodesCheck"
                            (change)="selectAllPaycodes()" />
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox" [value]="dataItem.isChecked === true"
                            [checked]="dataItem.isChecked === true" (change)="onChangePaycodeCheck($event, dataItem)" />
                    </ng-template>
                </kendo-grid-checkbox-column>
            </kendo-grid>
        </div>
        <div class="copy-paycodes-to">
            <p class="copy-head">Organizations</p>
            <kendo-grid class="copy-paycodes-grid" [kendoGridBinding]="currentOrganizations" [filterable]="'menu'">
                <kendo-grid-column title="To" field="organizationName" filter="text" [width]="200"></kendo-grid-column>
                <kendo-grid-checkbox-column [width]="50">
                    <ng-template kendoGridHeaderTemplate>
                        <input type="checkbox" class="k-checkbox checkbox-copy-paycodes" [checked]="isAllOrgsCheck"
                            (change)="selectAllOrganizations()" />
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox" [value]="dataItem.isChecked === true"
                            [checked]="dataItem.isChecked === true"
                            (change)="onChangeOrgLevelCheck($event, dataItem)" />
                    </ng-template>
                </kendo-grid-checkbox-column>
            </kendo-grid>
        </div>
        <div class="checkbox-container">
            <label>
                <input type="checkbox" [(ngModel)]="overrideMatchingException"
                    (change)="onOverrideMatchingExceptionChange($event)" />
                Override Matching Exception
            </label>
            <label>
                <input type="checkbox" [(ngModel)]="copyIfExceptionExists"
                    (change)="onCopyIfExceptionExistsChange($event)" [disabled]="!overrideMatchingException" />
                Copy Only If Exception Already Exists in Target Department
            </label>
        </div>
        <div class="footer-btns-container">
            <button type="button" class="cancel-btn" (click)="cancelChanges()">
                Cancel
            </button>
            <button type="button" class="discard-btn" (click)="discard()">
                Discard
            </button>
            <button type="button" [ngClass]="saveDisabled ? 'disabled' : 'save-btn'" (click)="save()">
                Copy Selected Pay Codes
            </button>
        </div>
    </div>
</slx-spinner>
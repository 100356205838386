<kendo-grid [data]="gridView"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.sort"
  (sortChange)="sortChange($event)"
  [filterable]=" 'menu'"
  [filter]="gridState.filter"
  [selectable]="true"
  [pageable]="false"
  [rowSelected]="isRowSelected()"
  (cellClick)="onCellClick($event)"
  (dataStateChange)="dataStateChange($event)"
  [slxKendoGridState]="'ShiftReplacement'"  (stateRestored)="dataStateChange($event)"
  class="slx-full-height slx-blue-grid"
>
  <kendo-grid-column *ngIf="settings.isMultipleSelectionMode" [width]="40" title="Select"
    field="isSelected"
    [sortable]="false"
    [filterable]="false"
    [locked]="true"
  >
    <ng-template kendoGridHeaderTemplate>
      <input type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onPutCheck(true)">
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [(ngModel)]="dataItem.isChecked" (ngModelChange)="onPutCheck(false)">
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="sendSmsModeOn" [width]="40" title="Select"
    field="isSelected"
    [sortable]="false"
    [filterable]="false"
    [locked]="true"
  >
    <ng-template kendoGridHeaderTemplate>
      <i class="fas fa-mobile-alt" aria-hidden="true" title="Select checkbox to send SMS to employee"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" *ngIf="dataItem.canReceiveSms" [disabled]="validate(dataItem)" [(ngModel)]="dataItem.isChecked" (ngModelChange)="onPutCheck(false)">
      <input type="checkbox" *ngIf="!dataItem.canReceiveSms" [disabled]="true" slxTooltip="Employee doesn't have mobile number" tipPosition="right">
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="40"
    [sortable]="false"
    [filterable]="false"
    [locked]="true"
  >
    <ng-template kendoGridHeaderTemplate>
      <i class="fas fa-paper-plane icon-sent-sms" aria-hidden="true" title="Message was already sent or not"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <i *ngIf="dataItem.isSmsSent" aria-hidden="true"
         title="SMS sent at {{ dataItem.smsResponse?.dateSent | amDateFormat: appConfig.dateTimeFormatUS }}"
         class="fas fa-paper-plane icon-sent-sms"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isMessageCenterEnabled" [locked]="true" [width]="50" field="empOptIn">
    <ng-template kendoGridHeaderTemplate>
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate  let-column="column" let-filter="filter" let-filterService="filterService">
      <slx-kendo-grid-multiselect-checkbox-filter [isPrimitive]="false"
                                                  [field]="column.field"
                                                  [currentFilter]="filter"
                                                  [filterService]="filterService"
                                                  textField="text"
                                                  valueField="value"
                                                  [data]="typeFilters">
      </slx-kendo-grid-multiselect-checkbox-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="optin-icon" *ngIf="dataItem.empOptIn || dataItem.empOptIn==0">
        <span [slxTooltip]="getOptInTooltip(dataItem.empOptIn)" tipPosition="right">
          <em class="fas fa-mobile-alt mobile-alt" [ngClass]="{'sms-opt-in':dataItem.empOptIn == 1,'sms-opt-out':dataItem.empOptIn == 0,'sms-opt':dataItem.empOptIn == 2}" aria-hidden="true"></em>
        </span>
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Name" field="employee.name" [locked]="true" [width]="170">
    <ng-template kendoGridHeaderTemplate>
      <!--<div>
        <slx-shift-replacement-header style="float: right" *ngIf="!screenUtils.isMobile" [settings]="settings" (settingsChanged)="onSettingsChanged($event)"></slx-shift-replacement-header>
      </div>-->
      Name
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="name-container">
        <div class="circular-portrait">
          <img class="img" [employeeThumbinalSrc]="dataItem.employee.id" alt="Employee Photo" *ngIf="dataItem.employee" />
        </div>

        <div class="name-text" [ngStyle]="getColor(dataItem)">{{dataItem.employee?.name}}</div>
      </div>
    </ng-template>
  </kendo-grid-column>

  <!-- <kendo-grid-column title="Response" [width]="100" field="isAnyResponse">
    <ng-template kendoGridHeaderTemplate>
      Response
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.isAnyResponse | boolyn}}
      </div>
    </ng-template>

    <ng-template kendoGridFilterMenuTemplate let-filter="filter" let-column="column" let-filterService="filterService">
      <slx-kendo-grid-boolean-filter-menu [filter]="filter" [column]="column" [filterService]="filterService"></slx-kendo-grid-boolean-filter-menu>
    </ng-template>
  </kendo-grid-column> -->
  <kendo-grid-column title="Response Text" [width]="150" field="smsResponse.responseText">
    <ng-template kendoGridHeaderTemplate>
      Response Text
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.smsResponse?.responseText }}</span>
      <!-- <div class="contacts-container" [ngStyle]="getColor(dataItem)">
        <div class="icons-container">
          <i *ngIf="dataItem.isAppNotificationSent" class="far fa-sticky-note" aria-hidden="true"></i>
          <div *ngIf="dataItem.appResponse">
            <i slxTooltip="{{dataItem.appResponse.messageText}} {{dataItem.appResponse.responseText}}" tipPosition="top" class="far fa-check-circle app-notify-icon" aria-expanded="true"></i>
          </div>
        </div>
        <div class="icons-container">
          <i *ngIf="dataItem.isSmsSent" class="fas fa-comment-alt" aria-hidden="true"></i>
          <div *ngIf="dataItem.smsResponse">
            <i slxTooltip="{{dataItem.smsResponse.messageText}} {{dataItem.smsResponse.responseText}}" tipPosition="top" class="far fa-check-square app-notify-icon" aria-hidden="true"></i>
          </div>
        </div>
        <div class="icons-container">
          <i *ngIf="dataItem.isPhoneCalled" class="fa fa-phone" aria-hidden="true"></i>
        </div>
      </div> -->
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('position')" title="Position" field="position.name" [width]="130">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.position?.name}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('department')" title="Department" field="department.name" [width]="150">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Department</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.department?.name}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('acaType')" title="ACA Type" field="acaType" [width]="100">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>ACA Type</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.acaType}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('acaAvgHours')" title="ACA Average Hours" field="acaAvgHours" [width]="120">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>ACA Average Hours</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content  align-right" [ngStyle]="getColor(dataItem)">
        {{dataItem.acaAvgHours}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('union')" title="Union" field="union" [width]="90">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Union</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.union}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('weeklyScheduledHours')" title="Weekly Scheduled Hours" field="weeklyScheduledHours" [width]="120">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Weekly Scheduled Hours</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content align-right" [ngStyle]="getColor(dataItem)">
        {{dataItem.weeklyScheduledHours}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('weeklyWorkedHours')" title="Weekly Worked Hours" field="weeklyWorkedHours" [width]="120">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Weekly Worked Hours</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content align-right" [ngStyle]="getColor(dataItem)">
        {{dataItem.weeklyWorkedHours}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('type')" title="Type" field="employee.employeeType.name" [width]="80">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Type</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.employee?.employeeType.name}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('weeklyProjectedHours')" title="Weekly Projected Hours" field="weeklyProjectedHours" [width]="120">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Weekly Projected Hours</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content align-right" [ngStyle]="getColor(dataItem)">
        {{dataItem.weeklyProjectedHours}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('payRate')" title="Pay Rate" field="payRate" [width]="100">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Pay Rate</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content align-right" [ngStyle]="getColor(dataItem)">
        {{isNumber(dataItem.payRate) ? (dataItem.payRate | number:'1.2-2') : dataItem.payRate }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('dateHired')" title="Date of Hire" field="employee.dateHired" filter="date" [width]="120">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Date of Hire</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.employee?.dateHired | amDateFormat: appConfig.dateFormat}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('homePhone')" title="Home Phone" field="homePhone" [width]="140">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Home Phone</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.homePhone}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('mobilePhone')" title="Mobile Phone" field="mobilePhone" [width]="140">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Mobile Phone</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.mobilePhone}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('altPhone')" title="Alt Phone" field="altPhone" [width]="140">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Alt Phone</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.altPhone}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isDisplayed('approachingOT')" title="Approaching OT" field="approachingOT" [width]="120">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Approaching OT</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content" [ngStyle]="getColor(dataItem)">
        {{dataItem.approachingOT | boolyn}}
      </div>
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter="filter" let-column="column" let-filterService="filterService">
      <slx-kendo-grid-boolean-filter-menu [filter]="filter" [column]="column" [filterService]="filterService"></slx-kendo-grid-boolean-filter-menu>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

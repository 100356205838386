
import {debounceTime} from 'rxjs/operators';
import * as _ from 'lodash';
import * as SignalR from '@microsoft/signalr';

import { EventEmitter, Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';

import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { ResponseBody } from '../../../core/models/api/response-body';
import { SessionService } from '../../../core/services';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework';
import { configurationConfig } from '../../../configuration/configuration.config';

import {
  ReminderModel,
  MessageModel,
  IMessageModel,
  IReminderModel,
  IIconAccess,
  IconAccess,
  MessageNotificationModel,
  IMessageNotificationModel
} from '../../models/index';
import { NotificationsMapService } from './notifications-map.service';
import { UnreadMessageCount } from '../../../app-modules/message-center/models';

const NOTIFICATIONS_API_DEBOUNCE_TIME_MS = 2500;

@Injectable()
export class NotificationsApiService {
  private hubConnection: SignalR.HubConnection;
  messageReceived = new EventEmitter<any>();
  private connectionCount: number = 0;
  private messageReceivedDebouncer = new Subject<any>();

  constructor(private apiUtilService: ApiUtilService, private urlParamsService: UrlParamsService, private mapService: NotificationsMapService,
    private sessionService: SessionService) {
    this.startSignalR();
  }

  public getReminders(): Promise<ReminderModel[]> {
    const url: string = this.getRemindersApi();

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<IReminderModel[], Meta>(request).then((response: ResponseBody<IReminderModel[], Meta>) => {
      return this.mapService.mapToReminders(response.data);
    }).catch(() => {
      return [];
    });
  }

  public markRemindersAsRead(reminders: ReminderModel[]): void {
    const url: string = this.getRemindersApi();

    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, {
      reminderIds: _.map(reminders, (reminder: ReminderModel) => {
        return reminder.reminderId;
      })
    });

    this.apiUtilService
      .request<any, Meta>(request)
      .catch(() => { });
  }

  public getMessages(): Promise<MessageModel[]> {
    const url: string = this.getMessagesApi();

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<IMessageModel[], Meta>(request).then((response: ResponseBody<IMessageModel[], Meta>) => {
      return this.mapService.mapToMessages(response.data);
    }).catch(() => {
      return [];
    });
  }

  public getIconAccessCheck(orgLevelId: number = null): Promise<IconAccess> {
    let url = this.getCheckAccessApiRoot();

    if (orgLevelId) {
      url += `/${orgLevelId}`;
    }

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<IIconAccess, Meta>(request)
      .then((response: ResponseBody<IIconAccess, Meta>) => {
        return this.mapService.mapToIconAccess(response.data);
      });
  }

  public async getMessageStatusUpdate(id): Promise<MessageNotificationModel> {
    let obj = {
      "employees": [id],
      "excludeEmployees": [],
      "all": false,
      "orgLevelId": null,
      "isArchived": null,
      "allUnRead":  false
    }
    const url: string = `${this.getMessageUpdateByUserIdUrl()}?${configurationConfig.api.configuration.messageCenter.apiVersion}=${this.getCommunicationApiRootVersion()}`;
    let request: HttpRequest<any> = new HttpRequest('PUT', url, obj);
    return this.apiUtilService.request<IMessageNotificationModel, Meta>(request).then((response) => this.mapService.mapToUpdateReadStatus(response));
  }

  public getMessageUpdateByUserIdUrl(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.read.configuration}`;
  }

  public getUnreadCount(orgLevelId: number, isMyMessage: boolean): Promise<UnreadMessageCount> {
    const url: string = `${this.getUnreadCountByUserIdUrl()}?${configurationConfig.api.configuration.messageCenter.isArchived}=false&isMyMessage=${isMyMessage}&${configurationConfig.api.configuration.messageCenter.orgLevelId}=${orgLevelId}&${configurationConfig.api.configuration.messageCenter.apiVersion}=${this.getCommunicationApiRootVersion()}`;
    let request: HttpRequest<UnreadMessageCount> = new HttpRequest('GET', url);
    return this.apiUtilService.request<UnreadMessageCount, Meta>(request).then((response) => this.mapService.unreadMsgCount(response));
  }

  public getUnreadCountByUserIdUrl(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.unreadCount}`;
  }

  public async startSignalR(): Promise<void> {
    try {
      const access = await this.getIconAccessCheck();

      if (!access.isMessageCenterEnabled) {
        return;
      }

      this.buildConnection();
      this.startConnection();
      this.checkCloseConnection();
    } catch (e) { }
  }

  public buildConnection = () => {
    this.hubConnection = new SignalR.HubConnectionBuilder()
      .withUrl(`${this.apiUtilService.getSignalRApiRoot()}/${configurationConfig.api.configuration.messageCenter.signalr}/${configurationConfig.api.configuration.messageCenter.communication}?${configurationConfig.api.configuration.messageCenter.apiVersion}=${this.getCommunicationApiRootVersion()}&${configurationConfig.api.configuration.messageCenter.accesstToken}=${this.sessionService.getToken()}`)
      .build();
  }
  
  public startConnection = () => {
    this.connectionCount = this.connectionCount + 1;
    this.hubConnection
      .start()
      .then(() => {
        this.registerMessageEvent();
      })
      .catch(err => {
        if (!this.sessionService.isExpired() && this.connectionCount <= 5) {
          setTimeout(() => {
            this.startConnection();
          }, 3000);
        }
      });
  }

  public registerMessageEvent() {
    this.hubConnection.on('MessageReceived', data => {
      this.messageReceived.emit(data);
    });
  }

  public subcribeToNewmessageAlert(callback: (v: any) => void, debounce = false): Subscription {
    Assert.isNotNull(callback, 'callback');

    if (debounce) {
      return this.messageReceived.pipe(
        debounceTime(NOTIFICATIONS_API_DEBOUNCE_TIME_MS))
        .subscribe(callback);
    }

    return this.messageReceived.subscribe(callback);
  }

  public getArchiveCount(orgLevelId: number, isMyMessage: boolean): Promise<UnreadMessageCount> {
    const url: string = `${this.getArchiveCountByUserIdUrl()}?${configurationConfig.api.configuration.messageCenter.isArchived}=true&isMyMessage=${isMyMessage}&${configurationConfig.api.configuration.messageCenter.orgLevelId}=${orgLevelId}&${configurationConfig.api.configuration.messageCenter.apiVersion}=${this.getCommunicationApiRootVersion()}`;
    let request: HttpRequest<UnreadMessageCount> = new HttpRequest('GET', url);
    return this.apiUtilService.request<UnreadMessageCount, Meta>(request).then((response) => this.mapService.unreadMsgCount(response));
  }

  public getArchiveCountByUserIdUrl(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.unreadCount}`;
  }

  private getRemindersApi(): string {
    return `${this.getUserApiRoot()}/${configurationConfig.api.configuration.messageCenter.reminders}`;
  }

  private getMessagesApi(): string {
    return `${this.getUserApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}`;
  }

  private getUserApiRoot(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.configuration.messageCenter.user}`;
  }

  private getCheckAccessApiRoot(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.configuration.messageCenter.messagecenter}/${configurationConfig.api.configuration.messageCenter.checkaccess}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }

  private getCommunicationApiRootVersion(): string {
    return `${this.apiUtilService.getCommunicationApiRootVersion()}`;
  }

  public checkCloseConnection = () => {
    this.hubConnection.onclose(() => {
      this.buildConnection();
      this.startConnection();
      this.checkCloseConnection();
    });
  }
}

import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { UntypedFormControl, AbstractControl, NgControl } from '@angular/forms';
import { Assert, StringUtils } from '../../framework/index';

@Pipe({
  name: 'valid',
  pure: false
})
@Injectable()
export class Valid implements PipeTransform {
  public transform(form: UntypedFormControl | NgControl, controlPath: string, errorType?: string): boolean {
    Assert.isNotNull(form, 'form');

    if (form instanceof NgControl || StringUtils.isNullOrEmpty(controlPath)) {
      return form.valid;
    }

    let control: AbstractControl = form.get(controlPath);
    if (!control) {
      return true;
    }

    let isValid: boolean = this.checkValid(control, errorType);
    return isValid;
  }

  protected checkValid(control: AbstractControl, errorType?: string): boolean {
    if (!control.dirty) {
      return true;
    }
    let isValid: boolean = StringUtils.isNullOrEmpty(errorType) ? control.valid : !control.hasError(errorType);
    return isValid;
  }
}

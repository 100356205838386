<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  [reorderable]="false"
  (dataStateChange)="gridState.dataStateChange($event)"
  (excelExport)="onExcelExport($event)"
>

<kendo-grid-column
  title="Facility"
  field="organization"
  width="250"
  [hidden]="!isVisible('organization')"
  [locked]="true"
  [kendoGridColumnFilterableOn]="['desktop']"
  [kendoGridColumnSortableOn]="['desktop']"
>
  <ng-template kendoGridHeaderTemplate>
    <span slxKendoGridHeaderTooltip>Facility</span>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization }}</ng-template>
  <ng-template kendoGridFooterTemplate ><strong class="wc-comp-grid-highlight__text no-align wc-total">Total</strong></ng-template>
</kendo-grid-column>

<kendo-grid-column
  title="Total"
  field="total"
  width="80"
  [hidden]="!isVisible('total')"
  [hidden]="false"
  [locked]="true"
  [filterable]="false"
>
  <ng-template kendoGridHeaderTemplate>
    <span slxKendoGridHeaderTooltip><strong class="wc-comp-grid-highlight__text wc-header-highlight">Total</strong></span>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem><strong class="wc-comp-grid-highlight__text">{{ dataItem.total }}</strong></ng-template>
  <ng-template kendoGridFooterTemplate><strong class="wc-comp-grid-highlight__text">{{ totals.total?.sum }}</strong></ng-template>
</kendo-grid-column>

<ng-template ngFor [ngForOf]="columns" let-column>
    <kendo-grid-column field="{{column.name}}"
    title="{{column.description}}"
    width="80"
    [hidden]="!isVisible(column.name)"
    [filterable]="false"
    [sortable]="false"
    [locked]="false"
  >
  <ng-template kendoGridCellTemplate let-dataItem>{{ getMonthwiseCount(dataItem, column) }}</ng-template>
  <ng-template kendoGridFooterTemplate><strong class="wc-comp-grid-highlight__text">{{ getMonthwiseTotalCount(totals, column) }}</strong></ng-template>
  </kendo-grid-column>
</ng-template>

<kendo-grid-excel fileName="Workers Comp Comparison.xlsx" >
</kendo-grid-excel>

<kendo-grid-pdf fileName="Workers Comp Comparison.pdf" [allPages]="true" paperSize="A0" [repeatHeaders]="true" [landscape]="true">
  <kendo-grid-pdf-margin top="4cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
  <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
   <div class="page-template">
      <div class="header">
        <div class="wc-pdf-header"></div>
        Workers' Comp Comparison<br/>
        Selected years :{{ yearArray }}
      </div>
    </div>
  </ng-template>
</kendo-grid-pdf>

<kendo-grid-messages filterMenuTitle="Filter">
</kendo-grid-messages>  
</kendo-grid>

import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { GroupResult, process, State, aggregateBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ColorUtil } from '../../../../common/utils/index';
import { ArrayUtils } from '../../../../framework/array-utils/array-utils';
import { IColumnSettings } from '../../../../core/models/index';
import { ExcelExportData, Workbook } from '@progress/kendo-angular-excel-export';
import * as kendoUiUtils from '../../../../core/utils/kendo-ui-utils';

import {
  GridComponent,
  CellClickEvent,
  ColumnBase,
  ColumnComponent
} from '@progress/kendo-angular-grid';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { PayRuleDefinition, EmployeeDefinition } from '../../../../organization/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { timeAndAttendanceConfig, ITimeAndAttendanceConfig } from '../../../time-and-attendance.config';

import { IndividualTimecardsManagementService, TimecardDisplayCommonService } from '../../../services/index';
import {
  IndividualTimecardsContainer, IndividualTimecardsDay, TimecardsEarning, TimecardsAction, TimecardsActionCmd, IndividualTimecardsState,
  IndividualTimecardFlatRecord, TimecardsColumnState, PayCodeGridModel, TimecardFirstWeekTotals, TimecardsEarningPayRule
} from '../../../models/index';
import { A4 } from '../../../../common/models/media/paper-sizes';

export class IndividualTimecardMobile {
  public weekNumber: string;
  public groupsOfDays: Array<IndividualTimecardFlatRecord[]>;
  public start: Date;
  public end: Date;
  public weekErrors: StringMap<number>;
}


@Component({
  moduleId: module.id,
  selector: 'slx-individual-timecards-flat-grid',
  templateUrl: 'individual-timecards-flat-grid.component.html',
  styleUrls: ['individual-timecards-flat-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndividualTimecardsFlatGridComponent implements OnInit, OnDestroy {

  public get exportFilename(): string {
    if (this.container) {
      let nameStr: string = this.getEmployeeName(this.container.employee);
      let sanitizedNameStr = this.sanitizeForFileName(nameStr);
      return `Timecards_${sanitizedNameStr}`;
    }
    return 'Timecards';
  }

  public get pdfScale(): number {
    return this.m_pdfScale;
  }

  public appConfig: IApplicationConfig;
  public taConfig: ITimeAndAttendanceConfig;
  public records: IndividualTimecardFlatRecord[];
  public payCycleRecords: IndividualTimecardFlatRecord[];
  public groupsOfWeeks: IndividualTimecardMobile[];
  public container: IndividualTimecardsContainer;
  public state: IndividualTimecardsState;
  public gridState: KendoGridStateHelper<IndividualTimecardFlatRecord>;
  public isAllHidden: boolean;
  public collapsed: StringMap<boolean>;
  public aggregates: any = [];
  public firstWeekTotals: TimecardFirstWeekTotals;

  public get isShowHighPrecision(): boolean {
    if (!this.state) {
      return false;
    }
    return this.state.isShowHighPrecision;
  }

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  private managementService: IndividualTimecardsManagementService;
  private isAllSelected: boolean;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private stateChangedSubscription: Subscription;
  @unsubscribe()
  private actionSubscription: Subscription;
  @unsubscribe()
  private stateSubscription: Subscription;

  private changeDetector: ChangeDetectorRef;

  private aggregatesDef: any = [
    { field: 'day.week', aggregate: 'max' },
    { field: 'productiveHours', aggregate: 'sum' },
    { field: 'nonProductiveHours', aggregate: 'sum' },
    { field: 'totalHours', aggregate: 'sum' },
    { field: 'regularHours', aggregate: 'sum' },
    { field: 'regularPay', aggregate: 'sum' },
    { field: 'overtimeAndExtraHours', aggregate: 'sum' },
    { field: 'overtimeAndExtraPay', aggregate: 'sum' },
    { field: 'totalOtherPay', aggregate: 'sum' },
    { field: 'overTimePay', aggregate: 'sum' },
    { field: 'totalAbsencePay', aggregate: 'sum' },
    { field: 'totalPay', aggregate: 'sum' },
  ];
  private total: any = {};
  private highlightedRows: string[];
  private loading: boolean = false;

  private m_pdfScale: number;

  constructor(managementService: IndividualTimecardsManagementService, changeDetector: ChangeDetectorRef, private _commonService: TimecardDisplayCommonService) {
    this.managementService = managementService;
    this.gridState = new KendoGridStateHelper<IndividualTimecardFlatRecord>();
    this.changeDetector = changeDetector;
    this.gridState.state.group = [{ field: 'day.week', aggregates: this.aggregates }];
    this.gridState.state.sort = [{ field: 'day.date', dir: 'asc' }];
    this.aggregates = _.map(this.aggregatesDef, (rule: PayRuleDefinition) => rule);
    this.highlightedRows = [];
    this.collapsed = {};
  }

  public ngOnInit(): void {

    this.appConfig = appConfig;
    this.taConfig = timeAndAttendanceConfig;
    this.stateChangedSubscription = this.managementService.onLoaded$.subscribe(
      (container: IndividualTimecardsContainer) => {
        this.container = container;
        this.aggregates = _.map(this.aggregatesDef, (rule: PayRuleDefinition) => rule);
        _.forEach(this.container.usedRulesDefinitions, (rule: PayRuleDefinition) => {
          this.aggregates.push({ field: rule.uniqName, aggregate: 'sum' });
        });
        this.firstWeekTotals = this.container.firstWeekTotals;
        this.load();
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.actionSubscription = this.managementService.onActionCmd$.subscribe((v: TimecardsAction): void => {
      if (v.cmd === TimecardsActionCmd.excelExport) {
        // this.grid.saveAsExcel();
        this.makeXlsExport();
      }
      if (v.cmd === TimecardsActionCmd.pdfExport) {
        this.setupPdfTemplate();
        this.grid.saveAsPDF();
      }

    });

    this.stateSubscription = this.managementService.onStateChanged$
      .subscribe((state: IndividualTimecardsState) => {
        this.state = state;
        const col = _.find(state.empColumns.columns, (c: IColumnSettings) => this.isVisible(c.name));
        this.isAllHidden = !!col;
        if (this.container && !this.loading) {
          this.load();
        } else {
          this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
        }
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onCellClick(cell: CellClickEvent): void {
    const record: IndividualTimecardFlatRecord = _.get(cell, 'dataItem', null);
    if (_.isObject(record)) {
      const currentId: string = moment(record.day.date).format(appConfig.dateFormat);
      const previousId: string = _.head(this.highlightedRows);
      this.highlightedRows.length = 0;
      if (currentId !== previousId) {
        this.highlightedRows.push(currentId);
      }
    }
  }

  public onChangedState(...indexes: number[]): void {
    const key: string = _.map(indexes, (index: number) => String(index)).join('');
    this.collapsed[key] = !this.collapsed[key];
  }

  public isRowSelected(): Function {
    return (event: { dataItem: IndividualTimecardFlatRecord, index: number }): boolean => {
      const date: string = moment(event.dataItem.day.date).format(appConfig.dateFormat);
      return _.indexOf(this.highlightedRows, date) !== -1;
    };
  }

  public getUnit(field: string, value: any): string {
    if (!value) { return null; }
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].unit : null;
  }

  public isVisible(field: string, payCode?: boolean): boolean {
    if (!this.state) {
      return false;
    }
    let columnState: TimecardsColumnState = payCode ? this.state.payCodeColumns : this.state.empColumns;
    if (!columnState || !columnState.columnsMap[field]) {
      return false;
    }
    let column: IColumnSettings = columnState.columnsMap[field];
    if (!this.state.isShowPayRates && column.payload && column.payload.payRateRelated) {
      return false;
    }
    return column.displayed;
  }

  public getWidth(field: string): number {
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].width : 50;
  }

  public getFilter(field: string): string {
    return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].filter : null;
  }

  public getColor(ruleDef: PayRuleDefinition): string {
    return ColorUtil.DecToHexString(ruleDef.color, true);
  }

  public getFieldPath(ruleDef: PayRuleDefinition): string {
    return ruleDef.uniqName;
  }

  public getCurrentState(...indexes: number[]): boolean {
    const key: string = _.map(indexes, (index: number) => String(index)).join('');

    return _.isBoolean(this.collapsed[key]) ? this.collapsed[key] : (this.collapsed[key] = true);
  }

  public getPayCodeModel(record: IndividualTimecardFlatRecord): PayCodeGridModel {
    const payCode: PayCodeGridModel = new PayCodeGridModel();
    payCode.positionName = record.positionName;
    payCode.rulesMap = record.rulesMap;

    return payCode;
  }

  public getGridData(): () => ExcelExportData {
    return (): ExcelExportData => {
      const data: GroupResult[] = _.cloneDeep(this.gridState.view.data);
      _.forEach(data, (group: GroupResult) => {
        _.forEach(group.items, (timecard: any) => {
          const punchIn: moment.Moment = moment(timecard.day.punchInTime);
          const punchOut: moment.Moment = moment(timecard.day.punchOutTime);
          if (punchIn.isValid()) {
            timecard.day.punchInSeconds = punchIn.format(appConfig.timeFormat);
          }
          if (punchOut.isValid()) {
            timecard.day.punchOutSeconds = punchOut.format(appConfig.timeFormat);
          }
        });
      });

      return {
        data: data,
        group: this.gridState.state.group
      };
    };
  }

  public makeGrouppingForMobile(): void {
    const weekErrors: StringMap<StringMap<number>> = {};
    const groupsOfWeeks: StringMap<StringMap<IndividualTimecardFlatRecord[]>> = {};
    _.forEach(this.payCycleRecords, (r: IndividualTimecardFlatRecord) => {
      let week: StringMap<number> = weekErrors[r.day.week] || { errors: 0, invalidPunches: 0, edited: 0 };
      if (r.isError) week.errors++;
      if (r.isInvalidPunch || r.isInvalidLogin || r.isNoShow) week.invalidPunches++;
      if (r.isEdited) week.edited++;
      weekErrors[r.day.week] = week;

      const day: string = moment(r.day.date).format(this.appConfig.dateFormat);
      const grouppedByDays: StringMap<IndividualTimecardFlatRecord[]> = groupsOfWeeks[r.day.week] || {};
      grouppedByDays[day] = grouppedByDays[day] || [];
      grouppedByDays[day].push(r);
      groupsOfWeeks[r.day.week] = grouppedByDays;

    });
    this.groupsOfWeeks = _.map(groupsOfWeeks, (groupsOfDays: StringMap<IndividualTimecardFlatRecord[]>, weekNumber: string) => {
      const indTimecard: IndividualTimecardMobile = new IndividualTimecardMobile();
      indTimecard.groupsOfDays = _.values(groupsOfDays);
      indTimecard.weekNumber = weekNumber;
      indTimecard.start = _.head(indTimecard.groupsOfDays)[0].day.date;
      indTimecard.end = _.last(indTimecard.groupsOfDays)[0].day.date;
      indTimecard.weekErrors = weekErrors[weekNumber];

      return indTimecard;
    });
  }

  protected addHeaderInformation(): string {

    let exported: string = moment().format(appConfig.dateTimeFormatUS);
    let empName: string = this.getEmployeeName(this.container.employee);
    let position: string = this.container.primaryPosition.name;
    let id: string = this.container.employee ? this.container.employee.id.toString() : '';
    let extempt: string = this.container.exemptStatus ? this.container.exemptStatus.name : '';
    let payPolicy: string = this.container.payPolicy ? this.container.payPolicy.name : '';
    let shiftDiffPolicy: string = this.container.shiftDiffPolicy && this.container.shiftDiffPolicy.name ? this.container.shiftDiffPolicy.name : '';
    let payrollNumber: string = this.container.employee ? this.container.employee.payrollNumber : '';
    let employeeType: string = this.container.employee ? this.container.employee.employeeType.name : '';
    let dateHired: string = this.container.employee ? moment(this.container.employee.dateHired).format(appConfig.dateFormat) : '';

    let headerString: string = `Id: ${id}, Employee: ${empName}, Position: ${position}, Exempt Ind: ${extempt}, Pay Policy: ${payPolicy}, Shift Diff Policy: ${shiftDiffPolicy}, Payroll #: ${payrollNumber}, Type: ${employeeType}, Hire Date: ${dateHired}, Exported on ${exported}`;

    return headerString;

  }


  protected setupPdfTemplate(): void {

    const pdfMargins: { top: number, left: number, bottom: number, right: number } = this.taConfig.settings.export.pdf.margins;
    let cssPixelWidth: number = this.grid.lockedWidth + this.grid.nonLockedWidth;
    let physPixelWidth: number = cssPixelWidth;
    let mmWidth: number = physPixelWidth * this.taConfig.settings.export.pdf.inchInMm / this.taConfig.settings.export.pdf.baseDencity;

    pdfMargins.top = 20; // in this page the top margin is 2 cm = 20mm
    pdfMargins.bottom = 20; // in this page the botton margin is 2 cm = 20mm

    let scale = (A4.heightMM - (pdfMargins.top + pdfMargins.bottom)) / (mmWidth - (pdfMargins.left + pdfMargins.right));
    this.m_pdfScale = Math.min(scale, 0.75);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private refreshGrid(): void {
    if (!this.payCycleRecords) {
      this.gridState.view = null;
      return;
    }
    let keepFilters;
    if (this.gridState.state.filter && !this.isShowHighPrecision) {
      keepFilters = this.adaptFilterPrecision(this.gridState.state.filter);
    }
    this.gridState.state.group.forEach((group: any) => group.aggregates = this.aggregates);
    this.gridState.view = process(this.payCycleRecords, this.gridState.state);
    this.total = aggregateBy(this.records, this.aggregates);
    if (keepFilters) {
      this.gridState.state.filter = keepFilters;
    }
  }

  public adaptFilterPrecision(filter: CompositeFilterDescriptor): CompositeFilterDescriptor {
    let filters = _.cloneDeep(filter);
    kendoUiUtils.adaptNumberFilterPrecision(filter, 2);
    return filters;
  }

  private getEmployeeName(employee: EmployeeDefinition): string {
    if (employee) {
      return employee.name;
    }
    return null;
  }

  private sanitizeForFileName(inputStr: string): string {

    if (inputStr && inputStr.length > 0) {
      const MAX_FILENAME_LENGTH = 100;
      const reRelativePath = /^\.+/;
      const nonWordChars = /[^\w+]/gi;
      const doubleDashes = /_{2,}/gi;
      const replacement = '_';
      inputStr = _.trim(inputStr);
      inputStr = inputStr.replace(nonWordChars, replacement);
      inputStr = inputStr.replace(doubleDashes, replacement);
      inputStr = inputStr.replace(reRelativePath, replacement);
      inputStr = inputStr.slice(0, MAX_FILENAME_LENGTH);
      return inputStr;
    }
    return 'file';
  }

  protected makeXlsExport() {
    const gridState = _.cloneDeep(this.gridState);
    const grid = this.grid;
    const addHeaderInfo = this.addHeaderInformation();
    const group = this.gridState.state.group;
    const fileName = this.exportFilename;
    const usedRulesDefinitionsPayRule = this.container ? this.container.usedRulesDefinitions : [];
    this._commonService.makeXlsExport(gridState, grid, group, addHeaderInfo, fileName, usedRulesDefinitionsPayRule);
  }

  private load(): void {
    this.loading = true;
    try {
      let groupByKeys = this.getGroupByKeys();
      this.records = _.reduce(this.container.records, (result: IndividualTimecardFlatRecord[], day: IndividualTimecardsDay) => {
        let first: boolean = true;
        let rs: IndividualTimecardFlatRecord[] = _.map(day.earnings, (earn: TimecardsEarning) => {
          let r: IndividualTimecardFlatRecord = new IndividualTimecardFlatRecord();
          r.day = day;
          r.earning = earn;
          r.regularHours = earn.regularHours;
          r.regularPay = earn.regularPay;
          r.overtimeAndExtraHours = earn.overtimeAndExtraHours;
          r.overtimeAndExtraPay = earn.overtimeAndExtraPay;
          r.totalOtherPay = earn.totalOtherPay;
          r.overTimePay = earn.overTimePay;
          r.totalAbsencePay = earn.totalAbsencePay;
          r.productiveHours = earn.productiveHours;
          r.nonProductiveHours = earn.nonProductiveHours;
          r.totalHours = earn.totalHours;
          r.rulesMap = earn.rulesMap;
          r.payPolicy = earn.payPolicy;
          r.shiftDiffPolicy = earn.shiftDiffPolicy;
          r.isError = day.isError;
          r.isNoShow = day.isNoShow;
          r.isInvalidPunch = day.isInvalidPunch;
          r.isInvalidLogin = day.isInvalidLogin;
          r.empOrganization = this.container.empOrganization;
          r.isLocked = day.isLocked || this.container.isPayCycleLocked || this.container.isOrganizationPayrollLocked;
          r.isEdited = day.isEdited;
          let ur: any = r;
          _.forEach(this.container.usedRulesDefinitions, (rule: PayRuleDefinition) => {
            ur[rule.uniqName] = r.getRuleValue(rule.name) ? r.getRuleValue(rule.name) : null;
          });
          r.totalPay = earn.totalPay;
          if (first) {
            first = false;
            r.isFirst = true;
          }
          return r;
        });
        if (rs.length === 0) {
          let er: IndividualTimecardFlatRecord = new IndividualTimecardFlatRecord();
          er.day = day;
          er.earning = null;
          er.productiveHours = day.productiveHours;
          er.nonProductiveHours = day.nonProductiveHours;
          er.totalHours = day.totalHours;
          er.regularHours = null;
          er.regularPay = day.regularPay;
          er.overtimeAndExtraHours = null;
          er.overtimeAndExtraPay = day.overtimeAndExtra;
          er.totalOtherPay = day.totalOtherPay;
          er.overTimePay = day.overTimePay;
          er.totalAbsencePay = day.totalAbsencePay;
          er.totalPay = day.totalPay;
          er.rulesMap = {};
          er.payPolicy = this.container.payPolicy;
          er.shiftDiffPolicy = this.container.shiftDiffPolicy;
          er.isFirst = true;
          er.isError = day.isError;
          er.isNoShow = day.isNoShow;
          er.isInvalidPunch = day.isInvalidPunch;
          er.isInvalidLogin = day.isInvalidLogin;
          er.empOrganization = this.container.empOrganization;
          er.isLocked = day.isLocked || this.container.isPayCycleLocked || this.container.isOrganizationPayrollLocked;
          er.isEdited = day.isEdited;
          let uer: any = er;
          _.forEach(this.container.usedRulesDefinitions, (rule: PayRuleDefinition) => {
            uer[rule.uniqName] = null;
          });
          rs.push(er);
        }

        if (rs.length > 1) {
          let groupResult = ArrayUtils.groupObjects(rs, groupByKeys);
          let groupedRecords = [];
          let isFirst = true;
          _.forEach(groupResult, groupRecords => {
            let er: IndividualTimecardFlatRecord = new IndividualTimecardFlatRecord();
            groupRecords.forEach((item: IndividualTimecardFlatRecord) => {
              this.mergeTimecardRecord(item, er);
              er.isFirst = isFirst;
            });
            this.rebuildRulesMap(er);
            isFirst = false;
            groupedRecords.push(er);
          });
          rs = groupedRecords;
        }

        return (result || []).concat(rs);
      }, []);
      this.payCycleRecords = _.filter(this.records, (r: IndividualTimecardFlatRecord) => r.day.isInPayCycle);
      this.makeGrouppingForMobile();
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
    finally {
      this.loading = false;
    }
  }

  private getGroupByKeys(): string[] {
    let groupByProperties = ['day.date'];
    this.addGroupByField('organization', 'empOrganization', groupByProperties);
    this.addGroupByField('department', 'departmentName', groupByProperties);
    this.addGroupByField('position', 'positionName', groupByProperties);
    this.addGroupByField('approved', 'day.approved', groupByProperties);
    this.addGroupByField('payPolicy', 'payPolicy', groupByProperties);
    this.addGroupByField('shiftDiffPolicy', 'shiftDiffPolicy', groupByProperties);
    this.addGroupByField('costCenterCode', 'earning.costCenterCode', groupByProperties);
    if (this.state.isShowPayRates) {
      this.addGroupByField('payRate', 'payRate', groupByProperties);
    }
    return groupByProperties;
  }

  private addGroupByField(columnName, fieldName, target: String[]) {
    if (this.isVisible(columnName)) {
      target.push(fieldName);
    }
  }

  private mergeTimecardRecord(source: IndividualTimecardFlatRecord, target: IndividualTimecardFlatRecord) {
    target.day = source.day;
    if (!target.earning) {
      target.earning = _.cloneDeep(source.earning);
    } else {
      source.earning.rules.forEach(rule => {
        let r = _.cloneDeep(rule);
        target.earning.rules.push(r);
      });
    }
    target.productiveHours = source.productiveHours + (target.productiveHours ? target.productiveHours : 0);
    target.nonProductiveHours = source.nonProductiveHours + (target.nonProductiveHours ? target.nonProductiveHours : 0);
    target.totalHours = source.totalHours + (target.totalHours ? target.totalHours : 0);
    target.regularHours = source.regularHours + (target.regularHours ? target.regularHours : 0);
    target.overtimeAndExtraHours = source.overtimeAndExtraHours + (target.overtimeAndExtraHours ? target.overtimeAndExtraHours : 0);
    target.regularPay = source.regularPay + (target.regularPay ? target.regularPay : 0);
    target.overtimeAndExtraPay = source.overtimeAndExtraPay + (target.overtimeAndExtraPay ? target.overtimeAndExtraPay : 0);
    target.totalOtherPay = source.totalOtherPay + (target.totalOtherPay ? target.totalOtherPay : 0);
    target.overTimePay = source.overTimePay + (target.overTimePay ? target.overTimePay : 0);
    target.totalAbsencePay = source.totalAbsencePay + (target.totalAbsencePay ? target.totalAbsencePay : 0);
    target.payPolicy = source.payPolicy;
    target.shiftDiffPolicy = source.shiftDiffPolicy;
    target.isError = source.isError;
    target.isNoShow = source.isNoShow;
    target.isInvalidPunch = source.isInvalidPunch;
    target.isInvalidLogin = source.isInvalidLogin;
    target.isMoneyRule = source.isMoneyRule;
    target.empOrganization = source.empOrganization;
    target.isLocked = source.isLocked;
    target.totalPay = source.totalPay + (target.totalPay ? target.totalPay : 0);
    target.isEdited = source.isEdited;
  }

  private rebuildRulesMap(target: IndividualTimecardFlatRecord) {
    target.earning.rulesMap = _.groupBy(target.earning.rules, (rule: TimecardsEarningPayRule) => {
      return rule.payRule.name;
    });
    target.rulesMap = _.groupBy(target.earning.rules, (rule: TimecardsEarningPayRule) => {
      return rule.payRule.name;
    });
    let ur: any = target;
    _.forEach(this.container.usedRulesDefinitions, (rule: PayRuleDefinition) => {
      ur[rule.uniqName] = target.getRuleValue(rule.name) ? target.getRuleValue(rule.name) : null;
    });
  }
}


import { IndividualScheduleNightShiftService } from './../../../services/individual-schedule/individual-schedule-night-shift.service';
import { NightShiftSetting } from './../../../../app-settings/model/night-shift-setting';
import { Organization } from './../../../../organization/models/lookup/organization';
import { OrgLevel } from './../../../../state-model/models/org-level/org-level';
import { ShiftGroupOrder } from './../../../../organization/models/schedule/shift-group-order';
import {
  Component,
  Input,
  Output,
  EventEmitter, OnInit, OnDestroy,
  HostListener
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { IndividualQuickEditOvelapDecisionRequest, IndividualQuickEditOvelap, IndividualQuickEditOvelapDecision, IndividualQuickEditOvelapDecisionState, IndividualQuickEditOvelapState, IndividualQuickEditOvelapStatus, IndSchDefWrapper, IndSchEntryWrapper, QuickEditOvelapDecision, QuickEditOvelapDecisionRequest, QuickEditOvelapState, QuickEditOvelapStatus } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { DailyData, DateRange } from '../../../../core/models/index';
import { ScheduleEntryDefinition, ScheduledShiftDefinition, ScheduleCycle } from '../../../../organization/models/index';
import { DateTimeService, IDateRange } from '../../../../common/services/date-time/date-time.service';
import { ModalService } from '../../../../common/services';
import { ConfirmOptions, ConfirmDialogComponent } from '../../../../common';
import {
  Position,
  LocationUnit,
  ShiftDefinition,
  ConstraintDefinition,
  EmployeeShift,
  ScheduleAbsence
} from '../../../../organization/models/index';
import { OverlappingSchedulePopupComponent } from '../overlapping-schedule-popup/overlapping-schedule-popup.component';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { IndividualScheduleEmpManagementService } from '../../../services/index';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils'

@Component({
  moduleId: module.id,
  selector: 'slx-individual-schedule-entry-editor',
  templateUrl: 'individual-schedule-entry-editor.component.html',
  styleUrls: ['individual-schedule-entry-editor.component.scss']
})

export class IndividualScheduleEntryEditorComponent implements OnInit, OnDestroy {
  public dayShiftLimit: number = 10;
  public scheduleCycles: ScheduleCycle[];
  public selectedScheduleCycle: ScheduleCycle;

  @Input()
  public readonly: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public employeeShift: EmployeeShift;

  @Input()
  public dailyData: DailyData<IndSchEntryWrapper>;

  @Input()
  public previousDayData: DailyData<IndSchEntryWrapper>;

  @Input()
  public nextDayData: DailyData<IndSchEntryWrapper>;

  @Input()
  public scheduleDateRange: DateRange;

  @Output()
  public onEntryChanged: EventEmitter<DailyData<IndSchEntryWrapper>>;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  public screenWidth: number;

  public get isEmptyDay(): boolean {
    return (!this.dailyData.data || !this.dailyData.data.entryDef.shifts || this.dailyData.data.entryDef.shifts.length === 0);
  }
  public get isCanAddShift(): boolean {
    return (!this.dailyData.data || !this.dailyData.data.entryDef.shifts || this.dailyData.data.entryDef.shifts.length < this.dayShiftLimit);
  }
  public get isSingleShift(): boolean {
    return (this.dailyData.data && this.dailyData.data.entryDef.shifts && this.dailyData.data.entryDef.shifts.length === 1);
  }
  public get isOutOfPositionPeriod(): boolean {
    return this.dailyData.startOfDay.isBefore(this.employeeShift.position.startDate) || this.dailyData.startOfDay.isAfter(this.employeeShift.position.endDate);
  }
  public get employeeShiftIsFilled(): boolean {
    return this.employeeShift && this.employeeShift.isValid;
  }

  public get canShowAddShift(): boolean {
    return this.isCanAddShift || !this.readonly || !this.isOutOfPositionPeriod || this.employeeShiftIsFilled;
  }

  public appConfig: IApplicationConfig;

  private dateTimeService: DateTimeService;
  private decision: IndividualQuickEditOvelapDecision;

  constructor(dateTimeService: DateTimeService,
    private modalService: ModalService,
    private individualScheduleEmpManagementService: IndividualScheduleEmpManagementService,
    private nightShiftService: IndividualScheduleNightShiftService
  ) {
    this.dateTimeService = dateTimeService;
    this.onEntryChanged = new EventEmitter<DailyData<IndSchEntryWrapper>>();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
  }
  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.screenWidth = window.innerWidth;
    this.subscriptions.loading = this.individualScheduleEmpManagementService
      .subscribeToCellClicked((cellClicked: string) => {
        this.onShiftDefAdd(cellClicked);
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public getOverLapDescision(req: IndividualQuickEditOvelapDecisionRequest): Promise<IndividualQuickEditOvelapDecision> {
    req.isMobile = this.screenWidth <= this.appConfig.devices.mobileWidth.max ? true : false;
    let promise: Promise<IndividualQuickEditOvelapDecision> = new Promise((resolve, reject) => {
      OverlappingSchedulePopupComponent.openDialog(req, 'Please select an option', this.modalService,
        (result: IndividualQuickEditOvelapDecision) => {
          this.decision = result;
          resolve(this.decision);
        });
    });
    return promise;
  }

  public async onShiftDefAdd(cellClicked: string) {
    if (cellClicked === this.dailyData.startOfDay.format(appConfig.dateFormat)) {
      if (this.readonly || !this.isCanAddShift) return;
      if (!this.dailyData.data) {
        this.dailyData.addData(new IndSchEntryWrapper());
        this.dailyData.data.date = this.dailyData.startOfDay.toDate();
        this.dailyData.data.entryDef = new ScheduleEntryDefinition();
        this.dailyData.data.entryDef.dateOn = this.dailyData.data.date;
        this.dailyData.data.entryDef.shifts = [];
      }

      const res = new IndividualQuickEditOvelapDecisionState();
      this.dailyData.data.isDirty = true;
      let entry: ScheduledShiftDefinition = new ScheduledShiftDefinition();
      this.fillScheduledShiftDefinition(entry);
      res.overlap = this.getOvelapState(this.dailyData.data.entryDef.shifts, entry);


      if (_.size(res.overlap.messages) === 0 &&  _.size(this.dailyData.data.entryDef.shifts) === 0) {
        this.makeReplace();
        return Promise.resolve(res);
      }
      else if(_.size(res.overlap.messages) === 0 && res.overlap.currentDayState.status === IndividualQuickEditOvelapStatus.NoOverlap) {
        this.makeAppend(res.overlap);
        return Promise.resolve(res);
      }

      const req = new IndividualQuickEditOvelapDecisionRequest();
      req.overlap = res.overlap;
      req.canCancel = true;

      await this.getOverLapDescision(req).then(decision => {
        switch (decision) {
          case IndividualQuickEditOvelapDecision.Replace:
            this.makeReplace();
            break;
          case IndividualQuickEditOvelapDecision.Append:
            this.makeAppend(res.overlap);
            break;
          case IndividualQuickEditOvelapDecision.Override:
            this.makeOverride(res.overlap);
            break;
          case IndividualQuickEditOvelapDecision.Cancel:
            break;
          default:
            throw new Error('unknown overlap decision');
        }
      });
    }
  }

  public makeReplace(): void {
    this.dailyData.data.isDirty = true;
    let entry: ScheduledShiftDefinition = new ScheduledShiftDefinition();
    this.fillScheduledShiftDefinition(entry);
    entry.shift.isDirty = true;
    _.pullAll(this.dailyData.data.entryDef.shifts, this.dailyData.data.entryDef.shifts);
    this.dailyData.data.entryDef.shifts.push(entry);
    this.dailyData.data.entryDef.shifts = _.sortBy(this.dailyData.data.entryDef.shifts, (s) => s.start);
    this.onEntryChanged.emit(this.dailyData);
  }

  public onShiftDefRemove(entry: ScheduledShiftDefinition, index: number, e: Event): void {
    this.dailyData.data.isDirty = true;
    _.pullAt(this.dailyData.data.entryDef.shifts, index);
    this.dailyData.data.entryDef.shifts = _.sortBy(this.dailyData.data.entryDef.shifts, (s) => s.start);
    this.onEntryChanged.emit(this.dailyData);
    e.stopPropagation();
  }

  private fillScheduledShiftDefinition(entry: ScheduledShiftDefinition): void {
    entry.position = this.employeeShift.position;
    entry.shift = this.employeeShift.shift;
    entry.unit = this.employeeShift.unit;
    if (!this.employeeShift.constraint || this.employeeShift.constraint.id === 0) {
      entry.constraint = null;
    } else {
      entry.constraint = this.employeeShift.constraint;
    }
    if (!this.employeeShift.absence || this.employeeShift.absence.code === '0') {
      entry.absence = null;
    } else {
      entry.absence = this.employeeShift.absence;
    }
    if (entry.shift) {
      let range: IDateRange = this.dateTimeService.getDateRangeFromTimeRange(this.dailyData.startOfDay, entry.shift.start, entry.shift.end);
      entry.start = range.start.toDate();
      entry.end = range.end.toDate();

      this.nightShiftService.updateTimeByNightShiftSetting(entry);
    }
  }

  private getOvelapState(shiftsInfo: ScheduledShiftDefinition[], shiftInfo: ScheduledShiftDefinition): IndividualQuickEditOvelap {
    const overlap = new IndividualQuickEditOvelap();
    overlap.canAppend = true;
    overlap.canOverride = true;
    overlap.canReplace = true;
    overlap.messages = [];
    let nextentryDef: ScheduleEntryDefinition = null;
    let previousEntryDef: ScheduleEntryDefinition = null;
    if (!_.isNil(this.previousDayData) && !_.isNil(this.previousDayData.data) && !_.isNil(this.previousDayData.data.entryDef))
      previousEntryDef = this.previousDayData.data.entryDef;
    if (!_.isNil(this.nextDayData) && !_.isNil(this.nextDayData.data) && !_.isNil(this.nextDayData.data.entryDef))
      nextentryDef = this.nextDayData.data.entryDef;

    if (!_.isNil(this.previousDayData) && !_.isNil(previousEntryDef))
      overlap.prevDayState = this.checkCellOvelap(previousEntryDef.shifts, shiftInfo);
    if (!_.isNil(this.nextDayData) && !_.isNil(nextentryDef))
      overlap.nextDayState = this.checkCellOvelap(this.nextDayData.data.entryDef.shifts, shiftInfo);

    overlap.currentDayState = this.checkCellOvelap(shiftsInfo, shiftInfo);

    if (!_.isNil(overlap.prevDayState) && overlap.prevDayState.status !== IndividualQuickEditOvelapStatus.NoOverlap) {
      overlap.canOverride = false;
      overlap.canReplace = false;
      overlap.messages.push('Has overlapping with previous day schedule');
      if (this.hasOvelap(overlap.prevDayState.rightOverlapShifts, overlap.currentDayState.leftOverlapShifts)) {
        overlap.canAppend = false;
        overlap.messages.push('No free space between previous day schedule and current day schedule');
      }
      if (overlap.prevDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleInside) {
        overlap.canAppend = false;
        overlap.messages.push('New shift inside previous day shift schedule');
      }
      if (overlap.prevDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleCover) {
        overlap.canAppend = false;
        overlap.messages.push('New shift cover previous day shift schedule');
      }
    }

    if (!_.isNil(overlap.nextDayState) && overlap.nextDayState.status !== IndividualQuickEditOvelapStatus.NoOverlap) {
      overlap.canOverride = false;
      overlap.canReplace = false;
      overlap.messages.push('Has overlapping with next day schedule');

      if (this.hasOvelap(overlap.nextDayState.leftOverlapShifts, overlap.currentDayState.rightOverlapShifts)) {
        overlap.canAppend = false;
        overlap.messages.push('No free space between next day schedule and current day schedule');
      }
      if (overlap.nextDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleInside) {
        overlap.canAppend = false;
        overlap.messages.push('New shift inside next day shift schedule');
      }
      if (overlap.nextDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleCover) {
        overlap.canAppend = false;
        overlap.messages.push('New shift cover next day shift schedule');
      }
    }

    if (overlap.currentDayState.leftOverlapShifts.length > 0 && overlap.currentDayState.rightOverlapShifts.length > 0) {
      let intervalduration = moment(overlap.currentDayState.leftOverlapShifts[0].end).diff(overlap.currentDayState.leftOverlapShifts[0].start)
        + moment(overlap.currentDayState.rightOverlapShifts[0].end).diff(overlap.currentDayState.rightOverlapShifts[0].start);
      let shiftinterval = moment(shiftInfo.end).diff(shiftInfo.start);
      if (intervalduration >= shiftinterval)
        overlap.canAppend = false;
    }

    if (!_.isNil(overlap.prevDayState) && overlap.prevDayState.leftOverlapShifts.length > 0 && overlap.currentDayState.rightOverlapShifts.length > 0) {
      overlap.canAppend = overlap.canOverride = overlap.canReplace = true;
    }

    if (!_.isNil(overlap.nextDayState) && overlap.nextDayState.rightOverlapShifts.length > 0 && overlap.currentDayState.leftOverlapShifts.length > 0) {
      overlap.canAppend = overlap.canOverride = overlap.canReplace = true;
    }
    if (overlap.currentDayState.status === IndividualQuickEditOvelapStatus.NoOverlap) {
      overlap.canOverride = false;
    }
    if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleInside) {
      overlap.canAppend = false;
      overlap.canOverride = false;
      overlap.messages.push('New shift inside current day shift schedule');
    }
    if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleCover) {
      overlap.canAppend = false;
      overlap.canOverride = false;
      overlap.messages.push('New shift cover current day shift schedule');
    }

    return overlap;
  }

  private hasOvelap(list1: ScheduledShiftDefinition[], list2: ScheduledShiftDefinition[]): boolean {
    let hasOverlap = false;
    _.forEach(list1, (s1: ScheduledShiftDefinition) => {
      _.forEach(list2, (s2: ScheduledShiftDefinition) => {
        if (moment(s1.start).isSameOrBefore(s2.end) && moment(s1.end).isSameOrAfter(s2.start)) {
          hasOverlap = true;
        }
        return !hasOverlap;
      });
      return !hasOverlap;
    });
    return hasOverlap;
  }

  public makeOverride(overlap: IndividualQuickEditOvelap) {
    this.dailyData.data.isDirty = true;
    let shiftInfo: ScheduledShiftDefinition = new ScheduledShiftDefinition();
    this.fillScheduledShiftDefinition(shiftInfo);

    if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapLeft) {
      _.forEach(overlap.currentDayState.leftOverlapShifts, (s: ScheduledShiftDefinition) => {
        const index = this.dailyData.data.entryDef.shifts.findIndex(p => p.end === s.end && p.start === s.start && p.shift === s.shift);
        let shift = this.dailyData.data.entryDef.shifts[index];
        this.dailyData.data.entryDef.shifts[index].end = s.end = moment(shiftInfo.start).subtract(1, 's').toDate();
        this.dailyData.data.entryDef.shifts[index].start = s.start;
        this.dailyData.data.entryDef.shifts[index].duration = moment(shift.end).diff(shift.start);

      });
    }

    if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapRight) {
      _.forEach(overlap.currentDayState.rightOverlapShifts, (s: ScheduledShiftDefinition) => {
        const index = this.dailyData.data.entryDef.shifts.findIndex(p => p.end === s.end && p.start === s.start && p.shift === s.shift);
        let shift = this.dailyData.data.entryDef.shifts[index];
        this.dailyData.data.entryDef.shifts[index].end = s.end;
        this.dailyData.data.entryDef.shifts[index].start = s.start = moment(shiftInfo.end).subtract(1, 's').toDate();
        this.dailyData.data.entryDef.shifts[index].duration = moment(shift.end).diff(shift.start);
      });
    }

    shiftInfo.shift.isDirty = true;
    this.dailyData.data.entryDef.shifts.push(shiftInfo);
    this.dailyData.data.entryDef.shifts = _.sortBy(this.dailyData.data.entryDef.shifts, (s) => s.start);
    this.onEntryChanged.emit(this.dailyData);

  }

  public makeAppend(
    overlap: IndividualQuickEditOvelap): void {
    this.dailyData.data.isDirty = true;
    let shiftInfo: ScheduledShiftDefinition = new ScheduledShiftDefinition();
    this.fillScheduledShiftDefinition(shiftInfo);
    if (overlap.prevDayState != null && overlap.prevDayState.status !== IndividualQuickEditOvelapStatus.NoOverlap) {
      const lastPrevSchedule = _.maxBy(overlap.prevDayState.leftOverlapShifts, (s: ScheduledShiftDefinition) => moment(s.end).unix());
      shiftInfo.start = moment(lastPrevSchedule.end).add(1, 's').toDate();
    }

    if (overlap.nextDayState != null && overlap.nextDayState.status !== IndividualQuickEditOvelapStatus.NoOverlap) {
      const firstNextSchedule = _.minBy(overlap.nextDayState.rightOverlapShifts, (s: ScheduledShiftDefinition) => moment(s.start).unix());
      shiftInfo.end = moment(firstNextSchedule.start).subtract(1, 's').toDate();
    }
    if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapLeft) {
      const lastLeftSchedule = _.maxBy(overlap.currentDayState.leftOverlapShifts, (s: ScheduledShiftDefinition) => moment(s.end).unix());
      shiftInfo.start = moment(lastLeftSchedule.end).add(1, 's').toDate();
    }
    if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapRight) {
      const firstRightSchedule = _.minBy(overlap.currentDayState.rightOverlapShifts, (s: ScheduledShiftDefinition) => moment(s.start).unix());
      shiftInfo.end = moment(firstRightSchedule.start).subtract(1, 's').toDate();
    }
    shiftInfo.duration = shiftInfo.shift ? moment(shiftInfo.end).diff(shiftInfo.start) - dateTimeUtils.convertFromDtoDurationStringToNumber(shiftInfo.shift.lunchDuration, 'ms') : moment(shiftInfo.end).diff(shiftInfo.start);
    //#152878 waiting for solution from product
    if (shiftInfo.duration <= 0) {
      console.log('You tried to add a shift with duration less than zero');
    } else {
      shiftInfo.shift.isDirty = true;
      this.dailyData.data.entryDef.shifts.push(shiftInfo);
      this.dailyData.data.entryDef.shifts = _.sortBy(this.dailyData.data.entryDef.shifts, (s) => s.start);
      this.onEntryChanged.emit(this.dailyData);
    }
  }

  private checkCellOvelap(shiftsInfo: ScheduledShiftDefinition[], shiftInfo: ScheduledShiftDefinition): IndividualQuickEditOvelapState {
    const state = new IndividualQuickEditOvelapState();
    state.status = IndividualQuickEditOvelapStatus.NoOverlap;
    if (!shiftsInfo) {
      return state;
    }
    _.forEach(shiftsInfo, (s: ScheduledShiftDefinition) => {
      if (moment(s.start).isSameOrBefore(shiftInfo.start) && moment(s.end).isBefore(shiftInfo.end) && moment(s.end).isAfter(shiftInfo.start)) {
        state.status = state.status | IndividualQuickEditOvelapStatus.OverlapLeft;
        state.leftOverlapShifts.push(s);
      }
      if (moment(s.start).isAfter(shiftInfo.start) && moment(s.end).isSameOrAfter(shiftInfo.end) && moment(s.start).isBefore(shiftInfo.end)) {
        state.status = state.status | IndividualQuickEditOvelapStatus.OverlapRight;
        state.rightOverlapShifts.push(s);
      }
      if (moment(s.start).isSameOrBefore(shiftInfo.start) && moment(s.end).isSameOrAfter(shiftInfo.end)) {
        state.status = state.status | IndividualQuickEditOvelapStatus.OverlapMiddleInside;
      }
      if (moment(s.start).isSameOrAfter(shiftInfo.start) && moment(s.end).isSameOrBefore(shiftInfo.end)) {
        state.status = state.status | IndividualQuickEditOvelapStatus.OverlapMiddleCover;
      }
    });
    return state;
  }

}

import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnDestroy, Input, Output, OnInit, ViewChild, EventEmitter, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { timeAndAttendanceConfig } from '../../../time-and-attendance.config'
import { Actions } from '../../../../core/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { DailyPayRule, TimeCardModel, TimeCardDisplayOptions, LinePunch, DailyPayRuleStatus } from '../../../models/index';
import { DailyTimecardManagementService, editAction, resetAction } from '../../../services/index';
import { NotificationsService } from '../../../../core/components/index';
import { ChangeManagementService, ModalService } from '../../../../common/services/index';
import { KendoGridStateHelper, saveEvent, removeEvent, DialogOptions } from '../../../../common/models/index';
import { PayRule, PayRuleType, PayRuleOffsetDefinition, ScheduledShiftDefinition, Position, Lookup, LookupEntity } from '../../../../organization/models/index';
import { LookupApiService } from '../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { INoMoreValidatorConf } from '../../../../common/validators/common-validators-models';
import { PayCodesAdditionalDataComponent } from '../pay-codes-additional-data/pay-codes-additional-data.component';

@Component({
  moduleId: module.id,
  selector: 'slx-timecard-paycodes-list',
  templateUrl: 'timecard-paycodes-list.component.html',
  styleUrls: ['timecard-paycodes-list.component.scss']
})
export class TimeCardPaycodesListComponent implements OnInit, OnDestroy {
  public retroField: boolean = false;
  public isFormValid: boolean = false;
  public maxAmt: number = 999999.99;

  @Input()
  public set model(value: TimeCardModel) {
    if (!value) {
      return;
    }
    this.m_model = value;
    this.records = value.payRules;
    if (!this.employeeId || this.employeeId !== value.employee.id) {
      this.employeeId = value.employee.id;
      this.loadPositionsLookup();
      this.getPayRules();
    }
    this.maxDateLimit = moment(value.date).add(1, 'd').toDate();
    this.minDateLimit = moment(value.date).subtract(1, 'd').toDate();
    this.filterPositionLookup();
    this.refreshGrid();
  }
  public get isRetroField(): boolean {    
    return (_.filter(this.records,(item: DailyPayRule)=>(!_.isNull(item.ruleDefinition.additionalVariable))).length>0 || this.retroField);
   }

  public isRowValid(item: DailyPayRule): boolean{
     return ((item.ruleDefinition && _.isEqual(item.ruleDefinition.additionalVariable,timeAndAttendanceConfig.retroFieldValue))  && (_.isNull(item.additionalVariable) || _.isEmpty(item.additionalVariable)))? false : true;
   }



  public isRetroFieldRow(item: DailyPayRule,isValueRequired):boolean{        
    if(isValueRequired)
    {
    return ((item.ruleDefinition && _.isEqual(item.ruleDefinition.additionalVariable,timeAndAttendanceConfig.retroFieldValue))  && (!_.isNull(item.additionalVariable) && !_.isEmpty(item.additionalVariable)));
    }
    else
    {
    return ((item.ruleDefinition && _.isEqual(item.ruleDefinition.additionalVariable,timeAndAttendanceConfig.retroFieldValue)) && (_.isNull(item.additionalVariable) || _.isEmpty(item.additionalVariable)) );
    }
  }
  public get model(): TimeCardModel {
    return this.m_model;
  }
  public records: DailyPayRule[];
  public editMode: boolean;
  public employeeId: number;

  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<DailyPayRule>;
  public hourCodeRules: PayRule[];
  public moneyCodeRules: PayRule[];
  public hoursType: PayRuleType;
  public moneyType: PayRuleType;
  public minDateLimit: Date;
  public maxDateLimit: Date;
  public canEditTimecard: boolean;
  public payRulesLookup: PayRule[];
  public displayOptions: TimeCardDisplayOptions;
  public moneyCodesValidationConig: INoMoreValidatorConf;

  public positionsLookup: Position[];
  public positionsOriginLookup: Position[];


  @ViewChild('payCodesForm', { static: true })
  public payCodesForm: UntypedFormGroup;
  @ViewChild('kendoGrid', { static: true })
  public kendoGrid: GridComponent;

  @unsubscribe()
  private resetSubscription: Subscription;
  @unsubscribe()
  private canEditChangedSubscription: Subscription;
  @unsubscribe()
  private optionsChangedSubscription: Subscription;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;
  @unsubscribe()
  private gridRemoveSubscription: Subscription;


  private changeGroup: string = 'TimeCardPaycodesListComponent';
  private m_model: TimeCardModel;

  constructor (
    private managementService: DailyTimecardManagementService,
    private lookupApiService: LookupApiService,
    private changeManagementService: ChangeManagementService,
    private modalService: ModalService
  ) {
    this.gridState = new KendoGridStateHelper<DailyPayRule>();
    this.hoursType = PayRuleType.hours;
    this.moneyType = PayRuleType.dollars;
    this.appConfig = appConfig;
    this.moneyCodesValidationConig = { values: [], propertyName: 'id', noMore: 1, value: undefined };
    this.modalService = modalService;

  }

  public ngOnInit(): void {
    this.resetSubscription = this.managementService.resetSection$.pipe(filter((m: resetAction) => m.target === 'paycodes')).subscribe((m: resetAction) => {
      this.records = m.model.payRules;
      this.refreshGrid();
    });

    this.canEditChangedSubscription = this.managementService.canEditChanged$
      .subscribe((canEdit: boolean) => {
        this.canEditTimecard = canEdit;

      });

    this.optionsChangedSubscription = this.managementService.displayOptionSelected$
      .subscribe((options: TimeCardDisplayOptions) => {
        this.displayOptions = options;
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.editMode = false;
      this.refreshGrid();
    });

    this.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<DailyPayRule>) => {
      if (event.isNew) {
        this.records.push(event.dataItem);
      }
      this.managementService.onPayCodesEditAction(false);
      this.editMode = false;
      this.retroField=false;
      this.changeManagementService.changeNotify(this.changeGroup);
    });

    this.gridCancelSubscription = this.gridState.onCancel$.subscribe(() => {
      this.managementService.onPayCodesEditAction(false);
      this.editMode = false;
      this.retroField=false;
    });

    this.gridEditSubscription = this.gridState.onEdit$.subscribe((editedRecord) => {
      this.managementService.onPayCodesEditAction(true);
      this.editMode = true;
      this.fillSelectedMoneyCodeRules(editedRecord);

    });


    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe((event: removeEvent<DailyPayRule>) => {
      if (event.dataItem.status === DailyPayRuleStatus.deleted) {
        this.records.splice(event.rowIndex, 1);
      } else {
        event.dataItem.status = DailyPayRuleStatus.deleted;
      }
      this.refreshGrid();
      this.changeManagementService.changeNotify(this.changeGroup);
    });

  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public loadPositionsLookup(): void {
    this.lookupApiService.getPositions(this.employeeId, null, null, true)
      .then((positions: Position[]) => {
        this.positionsOriginLookup = positions;
        this.filterPositionLookup();
      });
  }

  public onChangeRuleType(payCode: DailyPayRule): void {
    this.calculateTimes(payCode, payCode.ruleDefinition);
    this.fillSelectedMoneyCodeRules(payCode);
    if(_.isEqual(payCode.ruleDefinition.additionalVariable,timeAndAttendanceConfig.retroFieldValue))
    {
      this.retroField = true;
    }
    else
    {
      this.retroField=false;
    }
  }

  public fillSelectedMoneyCodeRules(payCode?: DailyPayRule): void {
    let moneyRules = _.filter(this.records, (d: DailyPayRule) => d.type === PayRuleType.dollars);
    if (payCode) {
      moneyRules = _.without(moneyRules, payCode);
    }
    this.moneyCodesValidationConig.values = _.map(moneyRules, (d: DailyPayRule) => d.ruleDefinition);
  }

  public calculateTimes(payCode: DailyPayRule, payRule: PayRule): void {
    if (payCode.start && payCode.end) {
      return;
    }
    let scheduleStart: Date = this.model.date;
    let lastPunchTime: Date = this.model.date;
    let firstPunchTime: Date = this.model.date;

    let scheduleStartDef: ScheduledShiftDefinition = _.first(_.orderBy(this.model.schedule, (shift: ScheduledShiftDefinition) => {
      return shift.start;
    }));
    if (scheduleStartDef) {
      scheduleStart = scheduleStartDef.start;
    }

    let orderedPunches = _.orderBy(this.model.dailyPunches, (punch: LinePunch) => {
      return punch.time;
    });
    let lastPunch = _.last(orderedPunches);
    if (lastPunch) {
      lastPunchTime = _.isNil(lastPunch.roundedTime) ? lastPunch.time : lastPunch.roundedTime;
    }
    let firstPunch = _.first(orderedPunches);
    if (firstPunch) {
      firstPunchTime = _.isNil(firstPunch.roundedTime) ? firstPunch.time : firstPunch.roundedTime;
    }

    if (!payCode.start) {
      if (payRule.startTimeOffset === 'Schedule Start') {
        payCode.start = scheduleStart;
      } else if (payRule.startTimeOffset === 'Last Punch') {
        payCode.start = lastPunchTime;
      } else {
        payCode.start = firstPunchTime;
      }
    }

    if (!payCode.end) {
      if (payRule.endTimeOffset === 'Schedule Start') {
        payCode.end = scheduleStart;
      } else if (payRule.endTimeOffset === 'First Punch') {
        payCode.end = firstPunchTime;
      } else {
        payCode.end = lastPunchTime;
      }
    }
    this.recalculateInterval(payCode);
  }

  public addHoursCode(): void {
    if(!this.hourCodeRulesExists || this.editMode || !this.canEditTimecard){
      this.modalService.globalAnchor.openInfoDialog(`Warning`, 'This timecard can`t be Edited.');
      return;
    }
    let code: DailyPayRule = new DailyPayRule();
    code.type = this.hoursType;
    code.start = null;
    code.end = null;
    code.interval = 0;
    code.status = DailyPayRuleStatus.adjusted;
    code.isNew = true;
    this.recalculateInterval(code);
    this.gridState.closeEditor(this.kendoGrid);
    this.kendoGrid.addRow(code);
    this.managementService.onPayCodesEditAction(true);
    this.editMode = true;
  }

  public isValidDate(date: Date) {
    return moment(date).isValid();
  }

  public onStartDateChanged(dataItem: DailyPayRule): void {
    if (!this.isValidDate(dataItem.start)) {
      dataItem.start = null;
      return;
    }
    this.recalculateInterval(dataItem);
  }

  public onEndDateChanged(dataItem: DailyPayRule): void {
    if (!this.isValidDate(dataItem.end)) {
      dataItem.end = null;
      return;
    }
    this.recalculateInterval(dataItem);
  }

  public onIntervalChanged(dataItem: DailyPayRule, hours: number): void {
    let ms: number = hours * 60 * 60 * 1000;
    dataItem.interval = ms;
    this.calculateEndDate(dataItem);
  }

  public onIntervalHMChanged(dataItem: DailyPayRule, value: Date): void {
    let interval = dateTimeUtils.getIntervalFromIntervalTime(value, 'h');
    this.onIntervalChanged(dataItem, interval);
  }

  public addMoneyCode(): void {
    if(!this.moneyCodeRulesExists || this.editMode || !this.canEditTimecard){
      this.modalService.globalAnchor.openInfoDialog(`Warning`, 'This timecard can`t be Edited.');
      return;
    }
    let code: DailyPayRule = new DailyPayRule();
    code.type = this.moneyType;
    code.start = this.model.date;
    code.end = this.model.date;
    code.interval = 0;
    code.moneyAmount = 0;
    code.status = DailyPayRuleStatus.adjusted;
    code.isNew = true;
    this.gridState.closeEditor(this.kendoGrid);
    this.kendoGrid.addRow(code);
    this.managementService.onPayCodesEditAction(true);
    this.editMode = true;
    this.fillSelectedMoneyCodeRules(code);
  }


  public discardChanges(): void {
    this.filterPositionLookup();
    this.changeManagementService.clearChanges(this.changeGroup);
    this.managementService.onPayCodesDiscardAction();
  }

  public get hourCodeRulesExists(): boolean {
    return this.hourCodeRules && this.hourCodeRules.length > 0;
  }

  public get moneyCodeRulesExists(): boolean {
    return this.moneyCodeRules && this.moneyCodeRules.length > 0;
  }

  public filterPositionLookup(): void {
    if (!this.positionsOriginLookup) {
      return;
    }
    this.positionsLookup = _.cloneDeep(this.positionsOriginLookup);
    this.positionsLookup = _.filter(this.positionsLookup, (p: Position) => {
      const res = (moment(this.model.date).isSameOrAfter(p.startDate) && (!p.endDate || moment(this.model.date).isSameOrBefore(p.endDate)));
      return res;
    });
    _.each(this.records, (r: DailyPayRule) => {
      if (r.position && r.position.id) {
        let index = _.findIndex(this.positionsLookup, (p: Position) => {
          return r.position.id == p.id;
        });

        if (index == -1) {
          this.positionsLookup.push(r.position);
        }
      }
    })
  }

  private calculateEndDate(item: DailyPayRule): void {
    if (item.interval < 0) {
      item.start = moment(this.model.date).startOf('day').toDate();
      item.end = item.start;
    } else {
      item.end = new Date(item.interval + item.start.getTime());
    }
    this.recalculateInterval(item);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
    this.fillSelectedMoneyCodeRules();
  }

  private filterTypeOptions(forHours: boolean): PayRule[] {
    let options: PayRule[] = [];
    let lookup: PayRule[] = this.payRulesLookup;
    if (lookup) {
      options = _.filter(lookup, (rule: PayRule) => {
        if (forHours) {
          return rule.ruleType === this.hoursType;
        }
        return rule.ruleType === this.moneyType;
      });
    }
    return options;
  }

  private getPayRules(): void {
    this.lookupApiService.getPayRules(this.employeeId)
      .then((codes: PayRule[]) => {
        this.payRulesLookup = codes;
        this.hourCodeRules = this.filterTypeOptions(true);
        this.moneyCodeRules = this.filterTypeOptions(false);
      });
  }

  private recalculateInterval(item: DailyPayRule): void {
    DailyPayRule.calculateInterval(item);
  }

  public setAdditionalData(item: DailyPayRule):void{
    this.openAdditionalDataDialog(item);
  }
  public openAdditionalDataDialog(item: DailyPayRule): void {

    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 250;
    dialogOptions.width = 350;
    dialogOptions.hideTitleBar=true;
    let dialog: PayCodesAdditionalDataComponent = this.modalService.globalAnchor.openDialog(
      PayCodesAdditionalDataComponent,'',
      dialogOptions, undefined, (result: boolean) => {
        if (result) {
          item.additionalVariable =dialog.retroDate ? moment(dialog.retroDate).format(appConfig.dateFormat) : '';
        }
      });
    if (dialog && !dialog.isLoading && dialog.initialized) {
      dialog.load(new Date(item.additionalVariable));
    }
  }

}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import { mutableSelect } from '../../../core/decorators/redux-decorators';
import { unsubscribe } from '../../../core/decorators/unsubscribe-decorator';
import { IUser, ISession } from '../../../authentication/store/session/session.types';
import { NotificationType } from '../../models/notification-type.enum';
import { PopperContent, PopperController } from 'ngx-popper';
import { PopperPositions } from '../../../common/models/index';
import { scheduleMicrotask } from '../../../core/utils/index';
@Component({
    moduleId: module.id,
    selector: 'slx-header-notification-button',
    templateUrl: 'header-notification-button.component.html',
    styleUrls: [
        'header-notification-button.component.scss'
    ]
})
export class NotificationButtonComponent implements OnInit {

    @Input()
    public popperPosition: PopperPositions = 'bottom';
    @Input()
    public notificationType: NotificationType;

    @Output()
    public popperOnShow: EventEmitter<PopperController> = new EventEmitter<PopperController>();
    @Output()
    public popperOnHide: EventEmitter<PopperController> = new EventEmitter<PopperController>();

    @ViewChild('popperContent', {static: true})
    public popperContent: PopperContent;

    @mutableSelect(['session'])
    public session$: Observable<ISession>;

    public notificationTypes: any;

    public notificationsCount: number = 0;

    public popupShown: boolean;

    public popperModifiers: any = {
        arrow: {
            order: 500,
            enabled: true,
            element: '[x-arrow]'
        }
    };

    @unsubscribe()
    private userSubscription: Subscription;

    private escCode: number = 27;

    constructor() {
        this.notificationTypes = NotificationType;
    }

    public ngOnInit(): void {

        this.userSubscription = this.session$.subscribe((session: ISession) => {
            if (session && session.user) {
                switch (this.notificationType) {
                    case NotificationType.MESSAGES:
                        scheduleMicrotask(() => {
                            this.notificationsCount = session.user.messagesUnread;
                        });
                        break;
                    case NotificationType.REMINDERS:
                        scheduleMicrotask(() => {
                            this.notificationsCount = session.user.remidersUnread;
                        });
                        break;
                }
            }
        });
    }

    public onKeyup(event: KeyboardEvent): void {
        if (event.keyCode === this.escCode) {
            this.popperContent.hide();
        }
    }

    public onShown(popper: PopperController): void {
        this.popupShown = true;
        this.popperOnShow.emit(popper);
    }

    public onHidden(popper: PopperController): void {
        this.popupShown = false;
        this.popperOnHide.emit(popper);
    }
}

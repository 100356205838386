import * as _ from 'lodash';
import { Component, OnDestroy } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-portal-employee',
  templateUrl: 'portal-employee.component.html',
  styleUrls: ['portal-employee.component.scss']
})
export class PortalEmployeeComponent implements OnDestroy {
  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}

import { Provider } from '@angular/core';

export * from './application-state-bus/application-state-bus.service';
export * from './org-level/org-level-watch.service';
export * from './org-level/org-level-api.service';

export * from './org-level/org-level-map.service';
export * from './org-level/org-level-management.service';
export * from './navigation/navigation-menu-api.service';
export * from './navigation/navigation-menu-map.service';
export * from './navigation/navigation-menu-management.service';
export * from './navigation/message-center-config.service';
export * from './lookup/lookup-map.service';
export * from './lookup/lookup-api.service';
export * from './lookup/lookup.service';
export * from './employee/employee-definitions-api.service';
export * from './employee/employee-definitions-map.service';
export * from './employee/employee-validator-adapter';
export * from './schedule/schedule-definitions-map.service';
export * from './employee/employee-images.service';
export * from './accessible/accessible-api.service';
export * from './accessible/accessible.service';
export * from './accessible/accessible.guard';
export * from './accessible/menu-accessible-provider/menu-accessible-provider.service';
export * from './pay-cycle-helper/pay-cycle-helper.service';
export * from './schedule/schedule-cycle-helper.service';
export * from './generic-list/generic-list-api.service';
export * from './generic-list/generic-list-map.service';
export * from './generic-list/generic-list-management.service';
export * from './time-clock/time-clock-api.service';
export * from './time-clock/time-clock-map.service';
export * from './time-clock/time-clock-data.service';
export * from './navigation/sso-navigation.service';
export * from './lookup/ess-lookup-manage.service';

export * from './pay-cycle-resolver/paycycle-resolver.service';
export * from './constraints/constraints-api.service';

export * from './navigation/get-hired/get-hired-menu-api.service';
export * from './navigation/get-hired/get-hired-menu-map.service';
export * from './navigation/get-hired/get-hired-menu-managment.service';

export * from './navigation/ben-admin/ben-admin-menu-management.service';
export * from './navigation/redirect-to-page/redirect-to-page.service';
export * from './monitoring/monitoring.service';
export * from './product-module/product-module-map.service';

export * from './accruals/accrual-api.service';
export * from './accruals/accrual-map.service';
export * from './navigation/navigation-menu-redirect.service';


import { AccrualApiService } from './accruals/accrual-api.service';
import { AccrualMapService } from './accruals/accrual-map.service';

import { ApplicationStateBusService } from './application-state-bus/application-state-bus.service';
import { OrgLevelWatchService } from './org-level/org-level-watch.service';
import { OrgLevelApiService } from './org-level/org-level-api.service';

import { OrgLevelMapService } from './org-level/org-level-map.service';
import { OrgLevelManagementService } from './org-level/org-level-management.service';
import { NavigationMenuApiService } from './navigation/navigation-menu-api.service';
import { NavigationMenuMapService } from './navigation/navigation-menu-map.service';
import { MessageCenterConfigService } from './navigation/message-center-config.service';
import { LookupMapService } from './lookup/lookup-map.service';
import { LookupApiService } from './lookup/lookup-api.service';
import { LookupService } from './lookup/lookup.service';
import { EmployeeDefinitionsApiService } from './employee/employee-definitions-api.service';
import { EmployeeDefinitionsMapService } from './employee/employee-definitions-map.service';
import { EmployeeValidatorAdapter } from './employee/employee-validator-adapter';
import { ScheduleDefinitionsMapService } from './schedule/schedule-definitions-map.service';
import { EmployeeImagesService } from './employee/employee-images.service';
import { AccessibleApiService } from './accessible/accessible-api.service';
import { AccessibleService } from './accessible/accessible.service';
import { AccessibleGuard } from './accessible/accessible.guard';
import { MenuAccessibleProviderService } from './accessible/menu-accessible-provider/menu-accessible-provider.service';
import { PayCycleHelperService } from './pay-cycle-helper/pay-cycle-helper.service';
import { ScheduleCycleHelperService } from './schedule/schedule-cycle-helper.service';
import { GenericListApiService } from './generic-list/generic-list-api.service';
import { GenericListMapService } from './generic-list/generic-list-map.service';
import { GenericListManagementService } from './generic-list/generic-list-management.service';
import { TimeclockApiService } from './time-clock/time-clock-api.service';
import { TimeclockMapService } from './time-clock/time-clock-map.service';
import { SsoNavigationService } from './navigation/sso-navigation.service';

import { PayCycleResolver } from './pay-cycle-resolver/paycycle-resolver.service';
import { ConstraintsApiService } from './constraints/constraints-api.service';
import { EssLookupManageService } from './lookup/ess-lookup-manage.service';
import { GetHiredMenuApiService } from './navigation/get-hired/get-hired-menu-api.service';
import { GetHiredMenuMapService } from './navigation/get-hired/get-hired-menu-map.service';
import { GetHiredMenuManagementService } from './navigation/get-hired/get-hired-menu-managment.service';
import { BenAdminMenuManagementService } from './navigation/ben-admin/ben-admin-menu-management.service';
import { BenAdminMenuMapService } from './navigation/ben-admin/ben-admin-menu-map.service';
import { Redirect2PageService } from './navigation/redirect-to-page/redirect-to-page.service';
import { MonitoringService } from './monitoring/monitoring.service';
import { ProductModuleMapService } from './product-module/product-module-map.service';
import { NavigationMenuRedirectService } from './navigation/navigation-menu-redirect.service';
import { config } from 'process';

export const services: Provider[] = [
  ApplicationStateBusService,
  OrgLevelApiService,
  OrgLevelWatchService,
  OrgLevelMapService,
  OrgLevelManagementService,
  EmployeeDefinitionsApiService,
  NavigationMenuMapService,
  NavigationMenuApiService,
  MessageCenterConfigService,
  LookupMapService,
  LookupApiService,
  LookupService,
  EmployeeDefinitionsMapService,
  EmployeeValidatorAdapter,
  ScheduleDefinitionsMapService,
  EmployeeImagesService,
  AccessibleApiService,
  AccessibleGuard,
  AccessibleService,
  PayCycleHelperService,
  ScheduleCycleHelperService,
  GenericListApiService,
  GenericListMapService,
  GenericListManagementService,
  TimeclockApiService,
  TimeclockMapService,
  SsoNavigationService,
  PayCycleResolver,
  ConstraintsApiService,
  EssLookupManageService,
  GetHiredMenuApiService,
  GetHiredMenuMapService,
  GetHiredMenuManagementService,
  BenAdminMenuManagementService,
  BenAdminMenuMapService,
  Redirect2PageService,
  MonitoringService,
  ProductModuleMapService,
  AccrualApiService,
  AccrualMapService,
  NavigationMenuRedirectService
];

import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, Host, NgZone, Input, ViewChild } from '@angular/core';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { ModalService } from '../../../../../common/services/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSectionsBase } from '../../../models/index';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../../core/decorators/unsubscribe-decorator';
import { NgForm, AbstractControl } from '@angular/forms';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models/kendo-grid-helpers';
import { IApplicationConfig } from '../../../../../app.config';
import { PmCreateReviewPopupComponent } from '../../../../../app-modules/performance-management/components';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { PmReviewRecord, EmployeeRosterRecord } from '../../../../../app-modules/performance-management/models';
import { Lookup, PerformanceReviewCode, Employee } from '../../../../../organization/models';
import { EmployeeSectionsReviews } from '../../../models/employee-sections-employment/employee-sections-reviews';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-section-performance-management',
  templateUrl: './employee-section-performance-management.component.html',
  styleUrls: ['./employee-section-performance-management.component.scss']
})
export class EmployeeSectionPerformanceManagementComponent extends EmployeeSectionsBasicComponent implements OnDestroy, OnInit {

  @Input()
  public set reviews(c: EmployeeSectionsReviews) {
    this.m_reviews = c;
    if (this.m_reviews && this.m_reviews.records) {
      this.generateFilters(this.m_reviews.records)
    }
    this.refreshGrid();
  }

  public get reviews(): EmployeeSectionsReviews {
    return this.m_reviews;
  }

  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }

  @Input()
  public set employeeId(value: number) {
    this.m_employeeId = value;
  }

  public get employeeId(): number {
    return this.m_employeeId;
  }

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<EmployeeRosterRecord>;
  public gridView: GridDataResult;
  public pageSize: number = 5;

  public ratings: Lookup<PerformanceReviewCode>;
  public reviewTypes: Lookup<PerformanceReviewCode>;
  public ratingTypeFilters: any[] = [];
  public reviewTypeFilters: any[] = [];

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild('gridForm', { static: true })
  private ngFormChild: NgForm;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  private m_employeeId: number;
  private m_reviews: EmployeeSectionsReviews;

  constructor (
    private modalService: ModalService,
    private apiService: EmployeeSectionsEmploymentApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone
  ) {
    super(decorator, ngZone);
    this.gridState = new KendoGridStateHelper<EmployeeRosterRecord>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'reviewRecord.reviewDate', dir: 'desc' }];
  }

  public ngOnInit(): void {
    this.createSubscriptions();
    this.refreshGrid();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.m_reviews;
  }

  public pageChanged(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  public onStartAdd(): void {
    this.openNewReportDialog();
  }

  public onSelectItem(item: EmployeeRosterRecord): void {
    this.openEditReportDialog(item.reviewRecord);
  }


  protected createSubscriptions(): void {

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

  }


  protected openNewReportDialog(): void {
    let info = this.employeeShortInfo;
    let employee = new Employee();
    employee.id = info.id;
    employee.name = info.name;
    employee.organization = info.organization;
    employee.department = info.department;
    employee.position = info.position;
    
    PmCreateReviewPopupComponent.openDialog('Create New Review', { review: null, employee: employee }, this.modalService, () => {
      this.loadSubsection();
    });
  }

  protected openEditReportDialog(review: PmReviewRecord): void {
    PmCreateReviewPopupComponent.openDialog('Edit Review', { review: review, employee: null }, this.modalService, () => {
      this.loadSubsection();
    });
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.apiService.getEmployeePerformanceReviews(this.employeeId)
      .then((subsectionData: EmployeeSectionsReviews) => {
        this.reviews = subsectionData;
        this.refreshGrid();
        this.stopProgress();
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  private generateFilters(records: EmployeeRosterRecord[]): void {

    let ratingTypes = [];
    _.map(records, (item: EmployeeRosterRecord) => {
      if (item.rating) {
        ratingTypes.push({ text: item.rating.name, value: item.rating.id });
      }
    });
    ratingTypes = _.uniqBy(ratingTypes, 'value');
    this.ratingTypeFilters = ratingTypes;

    let reviewTypes = [];
    _.each(records, (item: EmployeeRosterRecord) => {
      if (item.reviewType) {
        reviewTypes.push({ text: item.reviewType.name, value: item.reviewType.id });
      }
    });
    reviewTypes = _.uniqBy(reviewTypes, 'value');
    this.reviewTypeFilters = reviewTypes;
  }

  private refreshGrid(): void {
    if (!this.m_reviews || !this.m_reviews.records) {
      this.gridView = null;
      return;
    }

    this.gridState.view = process(this.m_reviews.records, this.gridState.state);
  }

}

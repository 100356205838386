
import {merge as observableMerge,  Subscription ,  Observable } from 'rxjs';

import {combineLatest} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';



import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { Organization } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupApiService, OrgLevelWatchService } from '../../../organization/services/index';
import { appConfig, IApplicationConfig } from '../../../app.config';

import { AccessManagementService, AgenciesApiService, AgencyDetailsManagementService } from '../../services/index';
import { AgencyMapping } from '../../models/index';

import { AGENCY_ORGANIZATION_ID } from '../../configuration.config';

import { AgencyMappingsComponent } from './agency-mappings/agency-mappings.component';
import { HandledError, StatusCodes } from '../../../core/models/index';
import * as _ from 'lodash';
@Component({
    moduleId: module.id,
    selector: 'slx-configure-agency',
    templateUrl: 'configure-agency.component.html',
    styleUrls: ['configure-agency.component.scss'],
    providers: [AccessManagementService, AgenciesApiService, AgencyDetailsManagementService]
})
export class ConfigureAgencyComponent implements OnInit, OnDestroy {

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    public state: {
        isLoading: boolean;
        agencySelected: boolean;
        departmentSelected: boolean;
        agencyOrgSelected: boolean;
        organizationSelected: boolean;
        corporationSelected: boolean;
    };

    public get agencyMappings(): AgencyMapping[] {
        return this.m_agencyMappings;
    }

    public get agencyMapping(): AgencyMapping {
        return this.m_agencyMapping;
    }

    public get organizations(): Organization[] {
        return this.m_organizations;
    }

    @unsubscribe()
    public orgLevelSubscription: Subscription;

    @unsubscribe()
    public detaulsActionSubscription: Subscription;

    @unsubscribe()
    public detaulsActionResultSubscription: Subscription;

    @unsubscribe()
    public detaulsActionErrorSubscription: Subscription;

    @unsubscribe()
    public detailsUpdatedSubscription: Subscription;

    public effectiveOrgLevelId: number;
    public currentOrgLevel: OrgLevel;

    private m_agencyMapping: AgencyMapping;
    private m_agencyMappings: AgencyMapping[];
    private m_organizations: Organization[];

    private appConfig: IApplicationConfig;
    private lastParentOrganizationId: number;

    @ViewChild('agencyMappingsComponent')
    private mappingsComponent: AgencyMappingsComponent;

    constructor(
        public stateManagement: AccessManagementService,
        private api: AgenciesApiService,
        private lookup: LookupApiService,
        private detailsManagement: AgencyDetailsManagementService,
        private orglevelWatch: OrgLevelWatchService
    ) {
    }

    public ngOnInit(): void {

        this.appConfig = appConfig;

        this.state = {
            isLoading: false,
            agencySelected: false,
            departmentSelected: false,
            agencyOrgSelected: false,
            organizationSelected: false,
            corporationSelected: false
        };

        this.detaulsActionSubscription = observableMerge(this.detailsManagement.onAdd$, this.detailsManagement.onRemove$, this.detailsManagement.onUpdate$).subscribe(() => {
            this.state.isLoading = true;
        });

        this.detaulsActionResultSubscription = observableMerge(this.detailsManagement.onAdded$, this.detailsManagement.onRemoved$, this.detailsManagement.onUpdated$).subscribe(() => {
            this.state.isLoading = false;
        });

        this.detaulsActionErrorSubscription = observableMerge(this.detailsManagement.onAddError$, this.detailsManagement.onRemoveError$, this.detailsManagement.onUpdateError$).subscribe(() => {
            this.state.isLoading = false;
        });

        this.detailsUpdatedSubscription = this.detailsManagement.onUpdated$.subscribe (() => {
            this.orglevelWatch.reloadOrgTree ();
        });

        this.orgLevelSubscription = this.orgLevel$.pipe(
        combineLatest(this.lookup.getOrganizations()))
        .subscribe(([orgLevel, organizations]) => {
            this.m_organizations = organizations;
            this.currentOrgLevel = orgLevel;
            this.stateManagement.orgLevelType = this.currentOrgLevel.type;

            if (this.mappingsComponent) {
                this.mappingsComponent.closeEditor();
                this.mappingsComponent.editMode = false;
            }

            if (this.stateManagement.isOrganization) {

                this.state.corporationSelected = true;
                this.state.departmentSelected = false;
                this.state.agencyOrgSelected = orgLevel.relatedItemId === AGENCY_ORGANIZATION_ID;
                this.state.organizationSelected = !this.state.agencyOrgSelected;
                this.effectiveOrgLevelId = orgLevel.id;

                if (this.state.agencyOrgSelected) {
                    this.lastParentOrganizationId = orgLevel.id;
                } else {
                    this.lastParentOrganizationId = undefined;
                }

                this.state.agencySelected = false;
                this.loadAgenciesList();

            } else if (this.stateManagement.isDepartment) {
                this.effectiveOrgLevelId = orgLevel.parentId;
                this.state.corporationSelected = true;
                this.state.agencyOrgSelected = false;
                this.state.organizationSelected = false;
                let org: Organization = _.find(this.organizations, (o: Organization) => {
                    return o.orgLevelId === orgLevel.parentId;
                });
                this.state.agencySelected = org && org.id === AGENCY_ORGANIZATION_ID;
                this.state.departmentSelected = !this.state.agencySelected;

                if (this.state.agencySelected) {
                    this.lastParentOrganizationId = undefined;
                    this.loadAgencyInfo();
                } else {
                    if (this.lastParentOrganizationId !== orgLevel.parentId) {
                        this.lastParentOrganizationId = orgLevel.parentId;
                        this.loadAgenciesList();
                    }
                }
            } else {
                this.effectiveOrgLevelId = undefined;
                this.lastParentOrganizationId = undefined;
                this.state.agencyOrgSelected = false;
                this.state.organizationSelected = false;
                this.state.agencySelected = false;
                this.state.departmentSelected = false;
                this.state.corporationSelected = true;
                this.loadAgenciesList();
            }
        });
    }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

    public onRemoveAgency(mapping: AgencyMapping): void {
        this.state.isLoading = true;
        this.api.removeAgency(mapping.agency).then(() => {
            this.state.isLoading = false;
            this.mappingsComponent.completeRemoveRequest();
            this.orglevelWatch.reloadOrgTree();
        }).catch((result: any) => {
            this.state.isLoading = false;
            this.mappingsComponent.cancelRemoveRequest();
            this.orglevelWatch.reloadOrgTree();
        });
    }

    public onSaveAgency(mapping: AgencyMapping): void {
        this.state.isLoading = true;
        if (mapping.agency.id) {
            this.api.updateAgency(this.currentOrgLevel.id, mapping.agency).then((value: AgencyMapping) => {
                this.state.isLoading = false;
                this.mappingsComponent.completeSaveRequest(value);
                this.orglevelWatch.reloadOrgTree();
            }).catch((result: any) => {
                this.state.isLoading = false;
            });
        } else {
            this.api.addAgency(this.currentOrgLevel.id, mapping).then((value: AgencyMapping) => {
                this.state.isLoading = false;
                this.mappingsComponent.completeSaveRequest(value);
                this.orglevelWatch.reloadOrgTree();
            }).catch((result: any) => {
                this.state.isLoading = false;
            });
        }
    }

    protected loadAgenciesList(): void {

        this.state.isLoading = true;
        this.m_agencyMappings = null;

        this.api.getAgencies(this.currentOrgLevel.id).then((mappings: AgencyMapping[]) => {
            this.m_agencyMappings = mappings;
            this.state.isLoading = false;
        }).catch((result: any) => {
            this.state.isLoading = false;
        });
    }

    protected loadAgencyInfo(): void {
        //TODO: investigate possible bug here
        // first update of orglevel has relatedItemId = null
        if (this.currentOrgLevel.relatedItemId) {
            this.state.isLoading = true;
            this.api.getAgency(this.currentOrgLevel.relatedItemId).then((mapping: AgencyMapping) => {
                this.m_agencyMapping = mapping;
                this.state.isLoading = false;
            }).catch((result: any) => {
                this.state.isLoading = false;
            });
        }
    }
}


import {first, combineLatest, filter } from 'rxjs/operators';
import { TimecardQueueDialogRequest, UnprocessedTimecardStats } from './../../models/timecards/unprocessed-timecard-stats';
import { ModalService } from './../../../common/services/modal/modal.service';
import { TimecardQueueDialogComponent } from './../../components/timecards-display/timecard-queue-dialog/timecard-queue-dialog.component';
import { NotificationMessage } from './../../../app.messages';
import { ErrorHandlingService } from './../../../core/services/error-handling/error-handling.service';
import { Injectable } from '@angular/core';
import { ReplaySubject ,  Subject ,  Observable ,  Subscription ,  forkJoin } from 'rxjs';


import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';

import {
  TimecardsSummary,
  TimecardsEmployee,
  TimecardsAction,
  TimecardsState,
  PayrollExportSubmitResults,
  ITimecardsLastSelectionState
} from '../../models/index';

import { dateTimeUtils } from '../../../common/utils/index';
import { PayCycle, PayRuleDefinition } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../organization/services/index';
import { TimecardsApiService } from './timecards-api.service';
import { ITimecardsDisplayState, ITimecardsPosition } from '../../store/index';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';
import { StateManagementService, ColumnSettingsStorageService } from '../../../common/services/index';
import { IDestroyService } from '../../../core/models/index';

import { ConstraintsApiService } from '../../../organization/services/index';
import { PayCycleApprovalRequest, TimecardApprovalRequest } from '../../models/timecards/timecards-approval-request';
import { AppUserSettingsService } from '../../../common/services/app-user-settings/app-user-settings.service';

@Injectable()
export class TimecardsDisplayManagementService implements IDestroyService {
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  @mutableSelect(['timecards', 'timecardsDisplay'])
  public timecardsDisplay$: Observable<ITimecardsDisplayState>;

  public onActionCmd$: Subject<TimecardsAction>;
  public onExportTimecards$: Subject<any>;
  public onLoadStatus$: ReplaySubject<boolean>;
  public onLoaded$: ReplaySubject<TimecardsSummary>;
  public onStateChanged$: ReplaySubject<TimecardsState>;
  public onRecordsSelected$: ReplaySubject<TimecardsEmployee[]>;
  public onOrgLevelChanged$: ReplaySubject<OrgLevel>;
  public lastSelectionState$: ReplaySubject<ITimecardsLastSelectionState>;
  public currentOrgLevel: OrgLevel;
  public currentPayCycle: PayCycle;
  public container: TimecardsSummary;
  public state: TimecardsState;
  public selectedRecords: TimecardsEmployee[];
  public initStandartScrollPosition: ITimecardsPosition;
  public initExtendedScrollPosition: ITimecardsPosition;
  public empColumnsKey: string = 'empColumns';
  public payColumnsKey: string = 'payColumns';
  public hideShowEmptyTimeCardKey: string = 'hideEmptyTimeCard';
  public currentOrganizationId: number;

  private get componentKey(): string {
    return this.stateManagementService.componentKey;
  }

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  @unsubscribeInService()
  private timecardsDisplaySubscription: Subscription;

  private settings: ITimecardsDisplayState;
  private timeoutStateChange: any;

  constructor(
    private constraintService: ConstraintsApiService,
    private orgLevelWatchService: OrgLevelWatchService,
    private stateManagementService: StateManagementService,
    private columnSettingsStorageService: ColumnSettingsStorageService,
    private errorService: ErrorHandlingService,
    private modalService: ModalService,
    private apiService: TimecardsApiService,
    private appUserSettingsService: AppUserSettingsService
  ) {
    this.onActionCmd$ = new Subject();
    this.onExportTimecards$ = new Subject();
    this.onLoadStatus$ = new ReplaySubject(1);
    this.onRecordsSelected$ = new ReplaySubject(1);
    this.onLoaded$ = new ReplaySubject(1);
    this.onStateChanged$ = new ReplaySubject(1);
    this.onOrgLevelChanged$ = new ReplaySubject(1);
    this.lastSelectionState$ = new ReplaySubject(1);
  }

  public init(): void {
    this.orgLevelSubscription = this.orgLevel$
      .pipe(filter((o: OrgLevel) => !this.currentOrgLevel || o && this.currentOrgLevel.id !== o.id))
      .subscribe((o: OrgLevel) => {
        this.handleOrgChange(o);
      });

    this.timecardsDisplaySubscription = this.timecardsDisplay$.pipe(
      combineLatest(this.orgLevel$, this.stateManagementService.onInit$))
      .subscribe(([settings, o, p]: [ITimecardsDisplayState, OrgLevel, any]) => {
        this.handleStateChange(settings);
      });
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public handleOrgChange(o: OrgLevel) {
    if (!o || !o.id) {
      return;
    }
    if (o.type === OrgLevelType.organization) {
      this.currentOrganizationId = o.relatedItemId;
    } else if (o.type === OrgLevelType.department) {
      this.orgLevelWatchService.getOrgLevelByIdSafe(o.parentId)
        .then((parent: OrgLevel) => {
          this.currentOrganizationId = parent ? parent.relatedItemId : 0;
          this.currentOrgLevel = o;
          this.onOrgLevelChanged$.next(o);
        });
      return;
    } else {
      this.currentOrganizationId = 0;
    }

    this.currentOrgLevel = o;
    this.onOrgLevelChanged$.next(o);
  }

  public handleStateChange(settings: ITimecardsDisplayState) {
    if (this.timeoutStateChange) {
      clearTimeout(this.timeoutStateChange);
      this.timeoutStateChange = null;
    }
    // Adding this timeout to execute state change functionality only once if its called multiple times within 200 ms
    this.timeoutStateChange = setTimeout(() => {
      this.settings = settings;
      let state: TimecardsState = new TimecardsState();
      this.state = state;
      state.createEmpColumns();
      this.columnSettingsStorageService.getColumnsState(this.componentKey, this.empColumnsKey, state.empColumns.columns);
      state.empColumns.mapColumns();
      state.flatMode = settings.flatMode;
      state.isShowPayRates = settings.isShowPayRates;
      state.isShowHighPrecision = settings.isShowHighPrecision;
      state.lastViewedEmployee = settings.lastViewedEmployee;
      state.lastViewedPage = settings.lastViewedPage;
      state.lastSelectedEntries = settings.lastSelectedEntries;
      state.gridState = settings.gridState;
      state.selectedEmployeeId = settings.selectedEmployeeId;
      if (this.container) {
        this.recreatePayCodeColumns(state, this.container.usedRulesDefinitions);
      }
      this.onStateChanged(state);
    }, 200);
  }
  
  public loadTimecardsSummary(orgLevelId: number, payCycle: PayCycle): void {
    this.onLoadStatusChanged(true);
    const timecardPromise = this.apiService.getTimecardsSummary(orgLevelId, payCycle);
    const isPayrollSubmittedPromise = this.constraintService.isPayrollSubmittedForOrganization(this.currentOrgLevel.id, payCycle.startDate, payCycle.endDate);

    forkJoin([timecardPromise, isPayrollSubmittedPromise])
      .subscribe((results: any[]) => {
        // results[0] will be timecardsSummary
        // results[1] will be the isPayrollSubmitted
        this.container = results[0];
        this.container.isPayCycleSubmitted = results[1];
        this.recreatePayCodeColumns(this.state, this.container.usedRulesDefinitions);
        this.onStateChanged$.pipe(first())  // await until state will be loaded or take first if already has been loaded
        .subscribe((state: TimecardsState) => {
          this.onLoaded(this.container);
          this.onLoadStatusChanged(false);
        });
      }, error => {
        this.onLoadStatusChanged(false);
      });
  }

  public checkTimecardsApproval(orgLevel: OrgLevel, payCycle: PayCycle): void {
    let empIds:number[] = _.map(this.selectedRecords, (timecardsEmp:TimecardsEmployee)=>{ return timecardsEmp.employeePosition.employee.id })
    this.apiService.getPendingTimecards(orgLevel.id, payCycle.startDate, payCycle.endDate, empIds)
      .then((stats: UnprocessedTimecardStats) => {
        if (stats.pendingRecords > 0) {
          this.showTimecardQueueDialog(orgLevel, payCycle, true, empIds, stats);
        } else {
          this.approveTimecards(orgLevel.id, payCycle)
        }
      }).catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public approveTimecards(orgLevelId: number, payCycle: PayCycle){
    let ids: number[] = this.extractIdsForModification('approve');
    if (ids.length === 0) return;
    let request = new TimecardApprovalRequest();
    let payCycleReq = new PayCycleApprovalRequest();
    payCycleReq.startDate = dateTimeUtils.convertToDtoString(payCycle.startDate);
    payCycleReq.endDate = dateTimeUtils.convertToDtoString(payCycle.endDate);
    payCycleReq.employeeIds = ids;
    request.payCycles.push(payCycleReq);

    this.onLoadStatusChanged(true);
    this.apiService.approveTimecards(this.currentOrgLevel.id, request)
      .then((result: any) => {
        this.loadTimecardsSummary(orgLevelId, payCycle);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public unapproveTimecards(orgLevelId: number, payCycle: PayCycle): void {
    let ids: number[] = this.extractIdsForModification('unapprove');
    if (ids.length === 0) return;

    this.onLoadStatusChanged(true);
    this.apiService.unapproveTimecards(this.currentOrgLevel.id, ids, this.currentPayCycle)
      .then((result: any) => {
        this.loadTimecardsSummary(orgLevelId, payCycle);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public recalculateTimecards(orgLevelId: number, payCycle: PayCycle): void {
    this.onLoadStatusChanged(true);
    let ids: number[] = _.map(this.selectedRecords, (emp: TimecardsEmployee) => emp.employeePosition.employee.id);
    this.apiService.recalculateTimecards(ids, this.currentPayCycle)
      .then((result: any) => {
        this.loadTimecardsSummary(orgLevelId, payCycle);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public exportTimecards(organizationId: number, startDate: Date, endDate: Date): void {
    this.onLoadStatusChanged(true);
    this.apiService.getExportedTimecards(organizationId, startDate, endDate)
      .then((file: FileBlobResponse) => {
        this.onExportTimecards$.next(file);
        this.onLoadStatusChanged(false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public showTimecardQueueDialog(orgLevel: OrgLevel, payCycle:PayCycle, approveTimecards:boolean = false, empIds:number[] = null, unprocessedTimecardStats:UnprocessedTimecardStats = null): void {
    let request: TimecardQueueDialogRequest = new TimecardQueueDialogRequest();
    request.orgLevel = orgLevel;
    request.payCycle = payCycle;
    request.approveTimeCards = approveTimecards;
    request.empIds = empIds;
    request.unprocessedTimecardStats = unprocessedTimecardStats;
    TimecardQueueDialogComponent.openDialog(this.modalService, this.apiService, request, (result: boolean): void => {
      if(result){
        this.approveTimecards(orgLevel.id, payCycle);
      }
    });
  }

  public onRecordsSelected(records: TimecardsEmployee[]): void {
    this.selectedRecords = records;
    this.onRecordsSelected$.next(records);
  }

  public onStateChanged(state: TimecardsState): void {
    this.state = state;
    if (!this.state) {
      this.state = new TimecardsState();
      this.state.createEmpColumns();
    }
    this.onStateChanged$.next(state);
  }

  public onActionCmd(cmd: TimecardsAction): void {
    this.onActionCmd$.next(cmd);
  }

  public onLoadStatusChanged(isLoading: boolean): void {
    this.onLoadStatus$.next(isLoading);
  }

  public onLoaded(container: TimecardsSummary): void {
    this.onLoaded$.next(container);
  }

  public onSaveLastSelectionState(state: ITimecardsLastSelectionState): void {
    this.lastSelectionState$.next(state);
  }

  public onPayCycleChanged(payCycle: PayCycle): void {
    this.currentPayCycle = payCycle;
    if (this.currentOrgLevel && this.currentPayCycle) {
      this.loadTimecardsSummary(this.currentOrgLevel.id, this.currentPayCycle);
    }
  }

  public changeEmpColumns(): void {
    if (!this.state) {
      return;
    }
    this.columnSettingsStorageService.setColumnsState(this.componentKey, this.empColumnsKey, this.state.empColumns.columns);
    this.stateManagementService.changes(null);
    this.onStateChanged(this.state);
  }

  public changePayColumns(): void {
    if (!this.state) {
      return;
    }
    this.columnSettingsStorageService.setColumnsState(this.componentKey, this.payColumnsKey, this.state.payCodeColumns.columns);
    this.stateManagementService.changes(null);
    this.onStateChanged(this.state);
  }

  public showHideEmptyTimeCards(value: boolean):void{
    let conf = this.appUserSettingsService.getConfigurationByLocalNames(this.componentKey,  this.hideShowEmptyTimeCardKey);
    this.appUserSettingsService.saveToServer([{ settingsGroup: this.appUserSettingsService.getServerGroupName(this.componentKey), name: conf.serverName, value: value }]);
  }

  public onReleaseToPayroll(): Promise<PayrollExportSubmitResults> {
    this.onLoadStatusChanged(true);
    return this.apiService.submitPayroll(this.currentOrganizationId, this.currentPayCycle.startDate, this.currentPayCycle.endDate)
      .then((r: PayrollExportSubmitResults) => {
        this.onLoadStatusChanged(false);
        return r;
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
        return null;
      });
  }

  private recreatePayCodeColumns(state: TimecardsState, usedPayRules: PayRuleDefinition[]): void {
    if (!state) {
      return;
    }
    state.createPayCodeColumns(usedPayRules);
    this.columnSettingsStorageService.getColumnsState(this.componentKey, this.payColumnsKey, state.payCodeColumns.columns);
    state.payCodeColumns.mapColumns();
    this.changePayColumns();
  }

  private extractIdsForModification(operation: 'approve' | 'unapprove'): number[] {
    let filtered: TimecardsEmployee[] = _.filter(this.selectedRecords, rec => rec.canModifyOwnTimecard);
    let ids: number[] = _.map(filtered, (emp: TimecardsEmployee) => emp.employeePosition.employee.id);
    if (filtered.length < this.selectedRecords.length) {
      this.errorService.info(new NotificationMessage('Timecard is readonly.',
        `You are not authorized to ${operation} your own timecard`));
    }
    return ids;
  }
}

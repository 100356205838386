<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells slx-cell-padding"
            [slxKendoGridColumnsGroup]="columnsGroupName"
            [data]="gridState.view"
            [sortable]="{ mode: 'multiple' }"
            [sort]="gridState.state.sort"
            [filterable]="'menu'"
            [filter]="gridState.state.filter"
            [skip]="gridState.state.skip"
            [pageable]="true"
            [pageSize]="pageSize"
            [groupable]="true"
            [group]="gridState.state.group"
            (dataStateChange)="gridState.dataStateChange($event)"
            >
  
  <kendo-grid-column title="Employee Name"
                    field="employee.name"
                    width="300"
                    filter="text"
                    [locked]="isLockedColumn"
                    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span>Employee Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [title]="dataItem.employee?.name">
        <a class="temp-readings-emp__link" [employeeSectionLink]="dataItem.employee?.id">{{dataItem.employee?.name}}</a>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Organization"
                    field="organization.name"
                    width="200"
                    filter="text"
                    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Organization</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Department"
                  field="department.name"
                  width="200"
                  filter="text"
                  slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Department</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.department?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Position"
                    field="position.name"
                    width="250"
                    filter="text"
                    slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Clock"
                     field="clock"
                     width="250"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Clock</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.clock }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Temp Reading"
                     field="tempReading"
                     width="180"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Temp Reading</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.tempReading }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Temperature"
                     field="temperatureWithDec"
                     width="150"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Temperature</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.temperatureWithDec }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Threshold"
                     field="thresholdWithDec"
                     width="150"
                     filter="text"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Threshold</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.thresholdWithDec }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Date Time"
                     field="eventDate"
                     width="150"
                     filter="date"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>DateTime</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a class="slx-pbj-rec-grid__text no-align" [dailyTimecardsLink]="dataItem.employee?.id" [timecardDate]="dataItem.eventDate">
        {{ dataItem.eventDate | amDateFormat: appConfig.dateFormat }}
      </a>
    </ng-template>
    
  </kendo-grid-column>

  <kendo-grid-column title="Event DateTime"
                     field="timeStamp"
                     width="180"
                     filter="date"
                     slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Event DateTime</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.timeStamp | amDateFormat: appConfig.dateTimeFormatUS }}
    </ng-template>
    
  </kendo-grid-column>

  <kendo-grid-excel [fileName]="xlsxName" [fetchData]="retriveAllPages()"></kendo-grid-excel>
  <kendo-grid-pdf [fileName]="pdfName" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'employeeTypeColorCodes',
})
export class EmployeeTypeColorCodesPipe implements PipeTransform {
    transform(value: string): any[] {
        const types = value ? value.split(',') : [];
        return types.map((type) => {
            const typeMap = {
                Agency: { value: '#f68d2e', toolTipMessage: 'Agency employee' },
                Absence: { value: 'lawngreen', toolTipMessage: 'Has Absence' },
                'Partical Absence': { value: 'yellow', toolTipMessage: 'Has Partial Absence' },
                OtherDepartment: { value: '#0047bb', toolTipMessage: 'Scheduled in different department' },
                SecondaryPosition: { value: '#000', toolTipMessage: 'Scheduled to secondary position' },
                MultipleShifts: { value: 'darkgreen', toolTipMessage: 'Scheduled to multiple shifts' },
            };
            return typeMap[type] || null;
        }).filter(Boolean);
    }
}

import { Component, OnInit, OnDestroy, Input, Host, ViewChild, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

import { LookupService } from '../../../../../organization/services/index';
import {
  Organization,
  Department,
  Position,
  PayType
} from '../../../../../organization/models/lookup/index';
import { EmployeeSectionsPosition, EmployeeSectionsPositions, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionBridgeService, EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';

import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../../../core/decorators/index';

import { FieldAccessType } from '../../../../../core/models/field/field-access-type';
import { Lookup, LookupType } from '../../../../../organization/models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-positions',
  templateUrl: 'employee-sections-positions.component.html',
  styleUrls: ['employee-sections-positions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsPositionsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {
  @Input()
  public set employeeSectionsPositions(positions: EmployeeSectionsPositions) {
    this.empPositions = positions;
    this.getPrimaryPositon();
    this.loadDependentsLookups();
    this.actualPositions = this.calcActualPositions();
    this.minDateForEndDate = null;
    this.updateData();
  }

  @Input() public componentId: string;
  @Input() public employeeId: number;
  @Input() public employeePayType : PayType;
  @Input() public employeeRehireDate : Date;
  @Input() public employeeStatus: string;

  get positionMinDate(): Date{
    return (this.employeeStatus.toLowerCase() === "future rehire") ? this.employeeRehireDate: null;
  }
  
  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }
  @ViewChild('form')
  public ngForm: NgForm;
  
  public get salaryPayType(): string {
    if (!this.employeePayType) { return; }
    return this.employeePayType &&
      this.employeePayType.description;
  }

  public get getRateTitle(): string {
    let pos: EmployeeSectionsPosition = new EmployeeSectionsPosition();
    if (!this.basicCheck(pos)) { return ''; }
    if (this.salaryPayType === '') { return 'Hourly Rate'; }
    return this.salaryPayType === "Salaried" ? 'Weekly Rate' : 'Hourly Rate';
  }

  public positionLookup: NumberMap<Lookup>;
  public departmentLookup: NumberMap<Lookup>;
  public payPolicyLookup: NumberMap<Lookup>;
  public shiftDiffPolicyLookup: NumberMap<Lookup>;
  public empPositions: EmployeeSectionsPositions;
  public primaryPosition: EmployeeSectionsPosition;
  public actualPositions: EmployeeSectionsPosition[];
  public appConfig: IApplicationConfig = appConfig;
  public decimalLimit = null;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService;
  private lookupService: LookupService;
  private minDateForEndDate: Date = null;

  constructor(
    employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    lookupService: LookupService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private modalService: ModalService,
    private employeeBridgeService: EmployeeSectionBridgeService
  ) {
    super(decorator, ngZone);
    this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
    this.lookupService = lookupService;
    this.departmentLookup = {};
    this.positionLookup = {};
    this.payPolicyLookup = {};
    this.shiftDiffPolicyLookup = {};
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.payRateFormat = this.employeeBridgeService.subscribeToChangePayRateFormat((isEnableRate4DecimalPlaces: boolean) => {
      if (isEnableRate4DecimalPlaces) {
        this.decimalLimit = 4;
      } else {
        this.decimalLimit = 2;
      }
    });
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.empPositions;
  }

  public setEditState(editState: boolean): void {
    super.setEditState(editState)
    this.minDateForEndDate = null;
  }

  public updateData(): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public addNewPosition(): void {
    let pos: EmployeeSectionsPosition = new EmployeeSectionsPosition();
    pos.position = this.empPositions.positionMetaData.createBasedOn();
    pos.position.securityAttribute.access = FieldAccessType.full;
    pos.organization = this.empPositions.organizationMetaData.createBasedOn();
    pos.organization.securityAttribute.access = FieldAccessType.full;
    pos.department = this.empPositions.departmentMetaData.createBasedOn();
    pos.department.securityAttribute.access = FieldAccessType.full;
    pos.shiftDiffPolicy = this.empPositions.shiftDiffPolicyMetaData.createBasedOn();
    pos.shiftDiffPolicy.securityAttribute.access = FieldAccessType.full;
    pos.payPolicy = this.empPositions.payPolicyMetaData.createBasedOn();
    pos.payPolicy.securityAttribute.access = FieldAccessType.full;
    pos.hourlyRate = this.empPositions.hourlyRateMetaData.createBasedOn();
    pos.startDate = this.empPositions.startDateMetaData.createBasedOn();
    pos.startDate.securityAttribute.access = FieldAccessType.full;
    let today: Date = moment().startOf('day').toDate();
    pos.startDate.fieldValue = today;
    pos.endDate = this.empPositions.endDateMetaData.createBasedOn();
    pos.endDate.securityAttribute.access = FieldAccessType.full;
    this.empPositions.positions.push(pos);
    this.actualPositions = this.calcActualPositions();
    this.updateData();
  }

  public organizationEditable(pos: EmployeeSectionsPosition): boolean {
    if (!this.basicCheck(pos)) { return false; }
    return pos.organization.securityAttribute.editable && this.state.isEditMode;
  }

  public departmentEditable(pos: EmployeeSectionsPosition): boolean {
    if (!this.basicCheck(pos)) { return false; }
    if (!pos.organization.fieldValue) return false;
    return pos.department.securityAttribute.editable && this.state.isEditMode;
  }

  public positionEditable(pos: EmployeeSectionsPosition): boolean {
    if (!this.basicCheck(pos)) { return false; }
    if (!pos.organization.fieldValue) return false;
    if (!pos.department.fieldValue) return false;
    return pos.position.securityAttribute.editable && this.state.isEditMode;
  }

  public startDateEditable(pos: EmployeeSectionsPosition): boolean {
    if (!this.basicCheck(pos)) { return false; }
    return pos.startDate.securityAttribute.editable && this.state.isEditMode;
  }

  public endDateEditable(pos: EmployeeSectionsPosition): boolean {
    if (!this.basicCheck(pos, true)) { return false; }
    return this.state.isEditMode;
  }

  public payPolicyEditable(pos: EmployeeSectionsPosition): boolean {
    if (!this.basicCheck(pos, true)) { return false; }
    if (!pos.organization.fieldValue) { return false; }
    if (!pos.department.fieldValue) { return false; }
    if (!pos.position.fieldValue) { return false; }
    return pos.payPolicy.securityAttribute.editable && this.state.isEditMode && !this.isStartAndEndDateAreSame(pos);
  }

  public shiftDiffPolicyEditable(pos: EmployeeSectionsPosition): boolean {
    if (!this.basicCheck(pos, true)) { return false; }
    if (!pos.organization.fieldValue) { return false; }
    if (!pos.department.fieldValue) { return false; }
    if (!pos.position.fieldValue) { return false; }
    return pos.shiftDiffPolicy.securityAttribute.editable && this.state.isEditMode && !this.isStartAndEndDateAreSame(pos);
  }

  public hourlyRateEditable(pos: EmployeeSectionsPosition): boolean {
    if (!this.basicCheck(pos, true)) { return false; }
    if (!pos.organization.fieldValue) { return false; }
    if (!pos.department.fieldValue) { return false; }
    if (!pos.position.fieldValue) { return false; }
    return pos.hourlyRate.securityAttribute.editable && this.state.isEditMode && !this.isStartAndEndDateAreSame(pos);
  }

  public basicCheck(pos: EmployeeSectionsPosition, ignoreSaved: boolean = false): boolean {
    if (!pos) { return false; }
    if (pos.isSaved && !ignoreSaved) { return false; }
    return true;
  }

  public getEndDate(date: Date): Date {
    const isSame = moment(date).startOf('day').isSame(this.appConfig.maxCorrectDate);
    return isSame ? null : date;
  }

  public getMinDate(date: Date): Date {
    return _.isDate(this.minDateForEndDate) ? this.minDateForEndDate : date;
  }

  public isStartAndEndDateAreSame(pos: EmployeeSectionsPosition): boolean {
    return moment(pos.startDate.fieldValue).isSame(pos.endDate.fieldValue);
  }

  public onChangeCriticalData(startDate: Date): void {
    this.minDateForEndDate = moment(startDate).add(1, 'days').toDate()
  }

  public onRemovePosition(pos: EmployeeSectionsPosition): void {
    const options = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Delete Secondary Position',
      'Are you sure you want to remove this position including all history?',
      this.modalService,
      (isDelete: boolean) => {
        if (isDelete) {
          pos.isDeleted = true;
          this.actualPositions = this.calcActualPositions();
          this.updateData();
        }
      },
      options);
  }

  public onOrganizationChanged(pos: EmployeeSectionsPosition, org: Organization, ind: number): void {
    this.clearDependences(pos);
    pos.department.fieldValue = null;
    pos.position.fieldValue = null;
    this.loadDepartments(org, ind);
  }

  public onDepartmentChanged(pos: EmployeeSectionsPosition, dep: Department, ind: number): void {
    this.clearDependences(pos);
    pos.position.fieldValue = null;
    this.loadPositions(dep, ind);
  }

  public clearDependences(pos: EmployeeSectionsPosition): void {
    pos.payPolicy.fieldValue = null;
    pos.shiftDiffPolicy.fieldValue = null;
  }

  public onPositionChanged(pos: Position, ind: number): void {
    this.loadByPosition(pos.orgLevelId, ind);
  }

  public hasSecondaryPayRate(value: string): boolean {
    return parseFloat(value) > 0;
  }

  protected loadDependentsLookups(): void {
    if (!this.actualPositions) { return; }
    _.times(this.actualPositions.length, (num: number) => {
      const orgLevelId = _.get(this.actualPositions[num], 'department.fieldValue.orgLevelId');
      this.loadByPosition(orgLevelId, num);
    });
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmploymentPositions(this.employeeId)
      .then((employeeSectionsPositions: EmployeeSectionsPositions) => {
        this.empPositions = employeeSectionsPositions;
        this.actualPositions = this.calcActualPositions();
        this.getPrimaryPositon();
        this.loadDependentsLookups();
        this.stopProgress();
        this.updateData();
      })
      .catch(() => {
        this.stopProgress();
      });
  }

  protected getPrimaryPositon(): void {
    if (!this.empPositions) return;
    this.primaryPosition = _.find(this.empPositions.positions, (pos: EmployeeSectionsPosition) => {
      return pos.isPrimary;
    });
  }

  protected calcActualPositions(): EmployeeSectionsPosition[] {
    let res: EmployeeSectionsPosition[] = [];
    if (!this.empPositions) return res;
    res = _.filter(this.empPositions.positions, function (pos: EmployeeSectionsPosition): boolean {
      return !pos.isDeleted && !pos.isPrimary;
    });
    return res;
  }

  protected doSave(effectiveDate: Date): void {
    this.employeeSectionsEmploymentApiService.setEmploymentPositions(this.employeeId, this.empPositions, effectiveDate)
      .then((status: any) => {
        this.onActionComplete(true);
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected loadDepartments(org: Organization, ind: number): void {
    if (!org) return;
    this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: org.orgLevelId, employeeId: undefined })
      .then((departmentLookup: Lookup) => {
        this.departmentLookup[ind] = departmentLookup;
      });
  }

  protected loadPositions(dep: Department, ind: number): void {
    if (!dep) return;
    this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: dep.orgLevelId, employeeId: undefined })
      .then((positionLookup: Lookup) => {
        positionLookup.items = this.getAvaliablePostionsForPosition(this.actualPositions[ind], positionLookup.items);
        this.positionLookup[ind] = positionLookup;
      });
  }

  protected getAvaliablePostionsForPosition(targetEmpPosition: EmployeeSectionsPosition, items: Position[]): Position[] {
    let filteredItems: Position[] = _.filter(items, (item: Position) => {
      let allowPosition: boolean = true;
      _.each(this.empPositions.positions, (empPosition: EmployeeSectionsPosition) => {
        if (targetEmpPosition !== empPosition) {
          if (targetEmpPosition.department.fieldValue && empPosition.department.fieldValue) {
            if (targetEmpPosition.department.fieldValue.id === empPosition.department.fieldValue.id) {
              if (empPosition.position.fieldValue) {
                if (item.id === empPosition.position.fieldValue.id) {
                  allowPosition = false;
                }
              }
            }
          }
        }
      });
      return allowPosition;
    });
    return filteredItems;
  }

  protected loadByPosition(orgLevelId: number, ind: number): void {
    if (!_.isFinite(+orgLevelId)) return;

    this.lookupService.getLookup({ lookupType: LookupType.payPolicy, orgLevelId })
      .then((payPolicyLookup: Lookup) => {
        this.payPolicyLookup[ind] = payPolicyLookup;
      });
    this.lookupService.getLookup({ lookupType: LookupType.shiftDiffPolicy, orgLevelId })
      .then((shiftDiffPolicyLookup: Lookup) => {
        this.shiftDiffPolicyLookup[ind] = shiftDiffPolicyLookup;
      });
  }

  /*
  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    return this.metaFieldsTemporalDirtyArrayChecker(this.empPositions.positions);
  }*/
}

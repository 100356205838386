import { Directive, ElementRef, forwardRef, Host, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, NG_ASYNC_VALIDATORS, Validator, FormControl } from '@angular/forms';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { CommonValidators } from './common-validators';
import { IServerValidatorConfExtended } from './common-validators-models';

const SERVER_VALIDATOR: any = {
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => ServerValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[server][formControlName],[server][formControl],[server][ngModel]',
  /* tslint:enable */
  providers: [SERVER_VALIDATOR]
})
export class ServerValidator implements Validator, OnInit, OnDestroy, OnChanges {

  @Input()
  public originalValue: any;

  @Input()
  public server: IServerValidatorConfExtended;

  private model: FormControl;

  /* tslint:disable */
  private _validator: AsyncValidatorFn;
  private _onChange: () => void;
  /* tslint:enable */

  private validationActive: boolean;
  private valueChangedSubscription: Subscription;

  constructor(
    @Host() private elem: ElementRef
  ) {
    this.validationActive = false;
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.model = this.server.form.controls[this.server.componentName] as FormControl;
      console.log('ServerValidator ngOnInit => model for ' + this.server.componentName, this.model);

      this.valueChangedSubscription = this.model.valueChanges.subscribe((value: any) => {
        if(this.server.validateOnReadonly){
          this.handleValueChange(value);
        }else if(!this.elem.nativeElement.attributes.getNamedItem('readonly')){
          this.handleValueChange(value);
        }
      });     
    })
  }

  public ngOnDestroy(): void {
    if (this.valueChangedSubscription) {
      this.valueChangedSubscription.unsubscribe();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && this.model.value) {
      this.valueChangedSubscription = this.model.valueChanges.subscribe((value: any) => {
        if(this.server.validateOnReadonly){
          this.handleValueChange(value);
        }else if(!this.elem.nativeElement.attributes.getNamedItem('readonly')){
          this.handleValueChange(value);
        }
      });
    }

    if (changes['server']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): { [key: string]: any } {

    if (!this.validationActive || _.isNil(this.server) || !this._validator) {
      return Promise.resolve(null);
    }

    if (this.model && !_.isNil (this.originalValue) && (this.model.value === this.originalValue)) {
      return Promise.resolve(null);
    }

    return this._validator(c);
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = CommonValidators.serverValidator(this.server);
  }

  private stopValidation(): void {
    this.validationActive = false;
  }

  private startValidation(): void {
    if (!this.validationActive) {
      this.validationActive = true;
      this.model.updateValueAndValidity();
    }
  }

  private invalidate(): void {
    const errors = Object.assign({}, this.model.errors, { 'unverified': true });
    this.model.setErrors(errors);
  }

  private hasOtherErrors(): boolean {
    let errorKeys = Object.keys(this.model.errors);
    let isUnverified = errorKeys.findIndex((key: string) => key === 'unverified') > -1;

    return isUnverified ? errorKeys.length >= 2 : !!errorKeys.length;
  }

  private handleValueChange(value: any): void {
    this.invalidate();

    if (!this.hasOtherErrors()) {
      this.startValidation();
    }
  }
}

import * as _ from 'lodash';
import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, Renderer2 } from '@angular/core';

import { Observable ,  Subscription } from 'rxjs';

import { EmployeePhoto } from '../../models/index';
import { EmployeeDefinitionsApiService } from '../../services/index';

const imageCache: StringMap<string> = {};

@Directive({
  selector: 'img[employeeImageSrc]'
})
export class EmployeeImageSrcDirective implements OnChanges {
  @Input()
  public employeeImageSrc: string;

  private elementRef: ElementRef;
  private renderer: Renderer2;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;
  private defaultPlaceholder: string;

  constructor(elementRef: ElementRef, renderer: Renderer2, employeeDefinitionsApiService: EmployeeDefinitionsApiService) {
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    this.defaultPlaceholder = 'assets/img/user-image.png';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['employeeImageSrc']) {
      __ngRendererSetElementAttributeHelper(this.renderer, this.elementRef.nativeElement, 'src', this.defaultPlaceholder);
      this.setEmpPhoto(+this.employeeImageSrc);
    }
  }

  public setEmpPhoto(employeeId: number, newPhoto: string = ''): void {
    let empPhoto: string = imageCache[employeeId];
    if (_.size(empPhoto) > 0) {
      if (_.size(newPhoto) > 0) {
        empPhoto = imageCache[employeeId] = newPhoto;
      }
      __ngRendererSetElementAttributeHelper(this.renderer, this.elementRef.nativeElement, 'src', empPhoto);

      return;
    }

    this.loadEmpPhoto(employeeId);
  }

  public loadEmpPhoto(employeeId: number): void {
    this.employeeDefinitionsApiService.getEmployeePhoto(employeeId)
      .then(this.applyPhoto.bind(this));
  }

  public applyPhoto(employeePhoto: EmployeePhoto): void {
    let photo: string = this.defaultPlaceholder;
    if (_.size(employeePhoto.photo) > 0) {
      photo = employeePhoto.photo;
    }
    imageCache[employeePhoto.employeeId] = photo;
    __ngRendererSetElementAttributeHelper(this.renderer, this.elementRef.nativeElement, 'src', photo);
  }

  public updateEmployeeImage(employeeId: number, newImage: string): void {
    this.setEmpPhoto(employeeId, newImage);
  }
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ":") {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ["", name];
}

function __ngRendererSetElementAttributeHelper(renderer: AnyDuringRendererMigration, element: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    if (value != null) {
        renderer.setAttribute(element, name, value, namespace);
    }
    else {
        renderer.removeAttribute(element, name, namespace);
    }
}

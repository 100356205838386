import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import * as moment from 'moment';

import { ResponseBody, Meta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { TimeclockMapService } from './time-clock-map.service';
import {
  TimeclockDailySummaryContainer, ITimeclockDailySummaryContainer,RebootClockContainer,IRebootClockContainer, IRebootStatus
} from '../../models/index';
import { timeAndAttendanceConfig } from '../../../time-and-attendance/time-and-attendance.config';
import { SlxHttpRequest } from '../../../core/models/index';

@Injectable()
export class TimeclockApiService {
  constructor(
    private arrivalsDeparturesMapService: TimeclockMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getTimeclockDailySummaryContainer(orgLevelId: number, isPolling: boolean): Promise<TimeclockDailySummaryContainer> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = this.getTimeclocksApiRoot(orgLevelId);
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);
    request.isBackgroundRequest = isPolling;

    return this.apiUtilService.requestNew<TimeclockDailySummaryContainer, Meta>(request, true)
      .then((response: ResponseBody<ITimeclockDailySummaryContainer, Meta>) => this.arrivalsDeparturesMapService.mapTimeclocksDailySummaryContainer(response.data));
  }

  public getTimeclocksApiUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.timeclock.root}/${timeAndAttendanceConfig.api.timeclock.orgLevel.root}`;
  }

  public rebootClock(orgLevelId: number,body:RebootClockContainer): Promise<IRebootStatus>{
   const url:string = this.rebootClockApiUrl(orgLevelId);
   const request: SlxHttpRequest<any> = this.urlParamsService.requestPost(url,body);
   let promise: any = this.apiUtilService.requestNew<IRebootStatus, Meta>(request,true)
            .then((response: ResponseBody<IRebootStatus, Meta>) => response.data);
        return promise;
  }

  private rebootClockApiUrl(orgLevelId: number): string {
    return `${this.apiUtilService.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.timeclock.root}/${timeAndAttendanceConfig.api.timeclock.orgLevel.root}/${orgLevelId}/${timeAndAttendanceConfig.api.timeclock.orgLevel.rebootClock}`;
  }

  private getTimeclocksApiRoot(orgLevelId: number): string {
    return `${this.getTimeclocksApiUrl()}/${orgLevelId}`;
  }
}

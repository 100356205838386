import { AcaExportType } from './enums/aca-export-type';
import { AcaExportConfiguration } from './models/aca-export/aca-export-configuration';
import { AcaExportCorrectionType } from './models/aca-export/aca-export-correctiontype';

export interface IExportDataConfig {
  api: {
    root: string;
    signalr: string;
    exports: {
      signalRApi: string;
      signalRHub: string;
      exportConfigurations: string;
      exportDataStatuses: string;
      exportDataHistory: string;
      generate: string;
      cancel: string;
      download: string;
    };
    aca: {
      root: string;
      exportConfigurations: string;
      exportDataStatuses: string;
      exportDataHistory: string;
      generate: string;
      cancel: string;
      download: string;
      latest: string;
      correction: {
        root: string;
        employees: string;
        correction1094c: string;
      },
      replacement: {
        root: string;
        employees: string;
      },
      supplemental: {
        root: string;
        employees: string;
      }
    };
    aca1095: {
      root: string;
      report: string;
      employer: {
        root: string;
        status: string;
      };
    };
  };
}

export const exportDataConfig: IExportDataConfig = {
  api: {
    root: 'exports',
    signalr: 'signalr',
    exports: {
      signalRApi: 'signalr',
      signalRHub: 'export-data',
      exportConfigurations: 'configurations',
      exportDataStatuses: 'status',
      exportDataHistory: 'history',
      generate: 'generate',
      cancel: 'cancel',
      download: 'download',
    },
    aca: {
      root: 'aca',
      exportConfigurations: 'configurations',
      exportDataStatuses: 'status',
      exportDataHistory: 'history',
      generate: 'generate',
      cancel: 'cancel',
      download: 'download',
      latest: 'latest',
      correction: {
        root: 'correction',
        employees: 'employees',
        correction1094c: '1094c',
      },
      replacement: {
        root: 'replacement',
        employees: 'employees'
      },
      supplemental: {
        root: 'supplemental',
        employees: 'employees'
      }
    },
    aca1095: {
      root: 'ACA',
      report: 'report',
      employer: {
        root: 'employer',
        status: 'status',
      },
    },
  }
};

export const exportConfigurations: AcaExportConfiguration[] = [{
  id: 2,
  exportType: AcaExportType.Export1095C,
  name: '1095-C PDF',
  isCollapsed: true,
  lastExecuted: null,
  history: null,
  exportParams: {
    year: null,
    employerId: 0,
    employerName: null,
    exportType: 2,
    qualifiedOfferMethod: 'Yes',
    offerMethod98: 'Yes',
    memberOfAleGroup: [],
    offeredMec: [],
    empIds: [],
    empMasterIds: [],
    recordIds: [],
    hidden: true,
    replacement: 'No',
    receiptId: '',
    overrides: null
  },
  requiresAllEmployeesToBeConfirmed: false,
  requiresAtLeastOneEmployeeToBeConfirmed: true,
  requiresTccCode: true,
  pageSkip: 0,
  isActionEnabled: false
}, {
  id: 1,
  exportType: AcaExportType.Export1094C,
  name: '1094-C PDF',
  isCollapsed: true,
  lastExecuted: null,
  history: null,
  exportParams: {
    year: null,
    employerId: 0,
    employerName: null,
    exportType: 1,
    qualifiedOfferMethod: 'Yes',
    offerMethod98: 'Yes',
    memberOfAleGroup: [],
    offeredMec: [],
    hidden: false,
    replacement: 'No',
    receiptId: '',
    empIds: [],
    empMasterIds: [],
    recordIds: [],
    overrides: null
  },
  requiresAllEmployeesToBeConfirmed: false,
  requiresAtLeastOneEmployeeToBeConfirmed: false,
  requiresTccCode: true,
  pageSkip: 0,
  isActionEnabled: false
}, {
  id: 3,
  exportType: AcaExportType.ExportXmlTest,
  name: 'Communication Test XML',
  isCollapsed: true,
  lastExecuted: null,
  history: null,
  exportParams: {
    year: null,
    employerId: 0,
    employerName: null,
    exportType: 3,
    qualifiedOfferMethod: 'Yes',
    offerMethod98: 'Yes',
    memberOfAleGroup: [],
    offeredMec: [],
    hidden: true,
    replacement: 'No',
    receiptId: '',
    empIds: [],
    empMasterIds: [],
    recordIds: [],
    overrides: null
  },
  requiresAllEmployeesToBeConfirmed: false,
  requiresAtLeastOneEmployeeToBeConfirmed: false,
  requiresTccCode: true,
  pageSkip: 0,
  isActionEnabled: false
}, {
  id: 4,
  exportType: AcaExportType.ExportXmlCommunication,
  name: 'Production XML (IRS Filing)',
  isCollapsed: true,
  lastExecuted: null,
  history: null,
  exportParams: {
    year: null,
    employerId: 0,
    employerName: null,
    exportType: 4,
    qualifiedOfferMethod: 'Yes',
    offerMethod98: 'Yes',
    memberOfAleGroup: [],
    offeredMec: [],
    hidden: false,
    replacement: 'No',
    receiptId: '',
    empIds: [],
    empMasterIds: [],
    recordIds: [],
    overrides: null
  },
  requiresAllEmployeesToBeConfirmed: true,
  requiresAtLeastOneEmployeeToBeConfirmed: false,
  requiresTccCode: true,
  pageSkip: 0,
  isActionEnabled: true
}];

export const exportTypeName: Map<AcaExportType, string> = new Map<AcaExportType, string>();
exportTypeName.set(AcaExportType.Export1094C, 'Original');
exportTypeName.set(AcaExportType.Export1095C, 'Original');
exportTypeName.set(AcaExportType.ExportPdfCorrected1094C, 'Corrected 1094-Cs');
exportTypeName.set(AcaExportType.ExportPdfCorrected1095C, 'Corrected 1095-Cs');
exportTypeName.set(AcaExportType.ExportPdfCorrected1095C1094C, 'Corrected 1094-Cs');
exportTypeName.set(AcaExportType.ExportPdfSupplemental1095C, 'Supplemental 1095-Cs');
exportTypeName.set(AcaExportType.ExportPdfSupplemental1094C, 'Supplemental 1094-Cs');
exportTypeName.set(AcaExportType.ExportXmlCommunication, 'Original');
exportTypeName.set(AcaExportType.ExportXmlCorrected1094C, 'Corrected 1094-Cs');
exportTypeName.set(AcaExportType.ExportXmlCorrected1095C, 'Corrected 1095-Cs');
exportTypeName.set(AcaExportType.ExportXmlReplacement1095C, 'Replacement');
exportTypeName.set(AcaExportType.ExportXmlSupplemental1095C, 'Supplemental 1095-Cs');
exportTypeName.set(AcaExportType.ExportXmlTest, 'Original');

export const acaCorrectionTypeConfig: AcaExportCorrectionType[] = [
  {
    id: 1,
    correctionType: 'Corrected 1094-C',
    receiptId: '',
    relatedExportType: [AcaExportType.ExportXmlCorrected1094C, AcaExportType.ExportPdfCorrected1094C, AcaExportType.Export1094C, AcaExportType.Export1095C, AcaExportType.ExportXmlCommunication, AcaExportType.ExportXmlReplacement1095C, AcaExportType.ExportXmlTest]
  },
  {
    id: 2,
    correctionType: 'Corrected 1095-Cs',
    receiptId: '',
    relatedExportType: [AcaExportType.ExportXmlSupplemental1095C, AcaExportType.ExportPdfSupplemental1095C, AcaExportType.ExportPdfCorrected1095C, AcaExportType.ExportXmlCorrected1095C, AcaExportType.Export1094C, AcaExportType.Export1095C, AcaExportType.ExportXmlCommunication, AcaExportType.ExportXmlReplacement1095C, AcaExportType.ExportXmlTest]
  },
  {
    id: 3,
    correctionType: 'Supplemental 1095-Cs',
    receiptId: '',
    relatedExportType: [AcaExportType.ExportXmlSupplemental1095C, AcaExportType.ExportPdfSupplemental1095C, AcaExportType.ExportPdfCorrected1095C, AcaExportType.ExportXmlCorrected1095C, AcaExportType.Export1094C, AcaExportType.Export1095C, AcaExportType.ExportXmlCommunication, AcaExportType.ExportXmlReplacement1095C, AcaExportType.ExportXmlTest]
  }
];

export const receiptIdMaskRegExp: any = {
  mask: [/[1]/, /[0]/, /[9]/, /[4|5]/, /[C|c]/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  guide: true
}; 

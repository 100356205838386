import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { GridComponent} from "@progress/kendo-angular-grid";
import { PBJExportLogEmployeeData } from '../../../models/pbj-export/pbj-log-export-with-batch-id';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { PBJExportParentGrid } from '../../../models/pbj-export/pbj-export-parent-grid';
import { Subscription ,  Observable } from 'rxjs';
import { PbjExportLogManagementService } from '../../../services';
import { PBJBatchExport } from '../../../models';
import { ApplicationStateBusService } from '../../../../../organization/services';
import { OrgLevel } from '../../../../../state-model/models';
import * as _ from 'lodash';
import { mutableSelect } from './../../../../../../app/core/decorators';
import * as moment from 'moment';
import { PBJChildDownload, PBJDownloadBulkExport, PBJDownloadExcelPdf, PBJDownloadSummary  } from '../../../models/pbj-export/pbjChildGridDownload';
import { ListItemModel } from '@progress/kendo-angular-buttons';
import { IPBJExportNotificationMessage } from '../../../models/pbj-export/pbj-export-notification-message';
import { SessionService } from '../../../../../core/services';
import { PbjSignalrService } from '../../../../../portal/services/pbj-signalr/pbj-signalr.service';

@Component({
  selector: 'slx-pbj-export-grid-parent',
  templateUrl: './pbj-export-grid-parent.component.html',
  styleUrls: ['./pbj-export-grid-parent.component.scss'],
  // providers: [ PbjExportLogManagementService]
})
export class PbjExportGridParentComponent implements OnInit {
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  @ViewChild(GridComponent)
  public grid: GridComponent;
  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;
  public gridState: KendoGridStateHelper<PBJExportLogEmployeeData>;
  public records: PBJExportParentGrid[] = [];
  private orgLevelSubscription: Subscription;
  public orgLevel: OrgLevel;
  childRequestObj: PBJExportParentGrid


  public realTimeFeedback:any;

  constructor(private managementService: PbjExportLogManagementService,
    private changeDetector: ChangeDetectorRef, private sessionService: SessionService, private pbjSignalRService: PbjSignalrService
  ) {
    this.gridState = new KendoGridStateHelper<PBJExportLogEmployeeData>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'batchId', dir: 'desc' }];
    this.managementService.sort = this.gridState.state.sort;
    this.gridState.state.filter = null;
    this.gridState.state.group = [];
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevel = orgLevel;
    });
    this.managementService.filteredData$.subscribe((val: PBJExportParentGrid[]) => {
      this.records = val;
      if (this.records === undefined || this.records.length == 0) {
        this.gridState.view = null;
        return;
      }
      if (this.records.length != 0) this.gridState.view = process(this.records, this.gridState.state);
      this.gridState.view = process(this.records, this.gridState.state);
      this.managementService.collapseAll$.subscribe((data) => {
        _.forEach(this.records, (record, ind) => {
          this.grid.collapseRow(ind);
          // _.forEach(data, (item, index) => {
          //   if (item === record.batchId) {
          //     this.grid.expandRow(index);
          //   }
          // });
        });
      });
    });

    this.pbjSignalRService.pbjExportRealtimeFeedback$.subscribe((args: any[]) => {
      let parentRealTimeUpdate: IPBJExportNotificationMessage = args && args.length > 2 ? JSON.parse(args[2]) : null;
      if(this.sessionService.getAlias().toLowerCase() === args[1].toLowerCase()){
        this.updateParentProgressBar(parentRealTimeUpdate);
      }
    })
  }

  ngOnInit() {
    this.refreshGrid();
  }

  public updateParentProgressBar(exportParentItem:IPBJExportNotificationMessage){
    this.records.map((item:PBJExportParentGrid)=>{
      if(item.batchId === exportParentItem.BatchId){
        item.batchProgress= exportParentItem.BatchProgress
        item.batchStatus = exportParentItem.BatchStatus == 'SUCCESS'? 'Completed' : exportParentItem.BatchStatus;
      }
    })
  }

  private refreshGrid(): void {
    let batchExportObj = new PBJBatchExport();
    batchExportObj.startDate = this.startDate;
    batchExportObj.endDate = this.endDate;
    batchExportObj.startDate = this.startDate;
    batchExportObj.orgLevelId = this.orgLevel.id;
    this.managementService.getParentGridExportData(batchExportObj)
      .then((val: PBJExportParentGrid[]) => {
        this.records = val;
        this.managementService.onLoadStatusChanged(false);
        if (this.records === undefined || this.records.length == 0) {
          this.gridState.view = null;
          return;
        }
        if (this.records.length != 0) this.gridState.view = process(this.records, this.gridState.state);
      }).catch((reason: any) => {
        this.managementService.onError(reason);
      });
    this.gridState.view = process(this.records, this.gridState.state);
    this.managementService.collapseAll$.subscribe((data) => {
      _.forEach(this.records, (record, ind) => {
        this.grid.collapseRow(ind);
      });
    });
  }

  public filterChangeHandler(event: any): void {
    this.gridState.state.filter = event;
    this.filterGrid();
  }

  public filterGrid(): void {
    if (this.records === undefined || this.records.length == 0) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  public get dateFormat(): string {
    return '{0:MM/dd/yyyy}';
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.state.sort = sort;
    this.filterGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public downloadParentGridData(batchId: any) {
    let exportObj = new PBJChildDownload();
    exportObj.exportIds = 0;
    exportObj.batchId = batchId;
    exportObj.orgLevelId = this.orgLevel.id;
    this.managementService.downloadChildExportData(exportObj);
  }

  public onDownloadSummary(dataItem: any, orgLevelId: any): void {
    let exportObj= new PBJDownloadSummary();

    exportObj.batchId= dataItem.batchId;
    exportObj.orgLevelId= orgLevelId;
    exportObj.startDate = dataItem.startDate;
    exportObj.endDate = dataItem.endDate;

    this.managementService.downloadExportSummaryData(exportObj)
    console.log(`Download Summary in process !!`);
  }

  public onDownloadBulkExport(dataItem: PBJExportParentGrid, orgLevelId: string):void{
    if (dataItem.staffingInfo == 'N') {
      this.managementService.showBulkExportWarn();
      return
    }
    let bulkFileObj = new PBJDownloadBulkExport();
    bulkFileObj.batchId= dataItem.batchId;
    bulkFileObj.orgLevelId= orgLevelId;
     this.managementService.downloadBulkExportFile(bulkFileObj)
  
  }
  public detailExpand(e) {
    if (e.expand) {
      this.managementService.expandedBatchIds.push(e.dataItem.batchId);
    }
  }

  public detailCollapse(e) {
    let index = this.managementService.expandedBatchIds.findIndex(x => e.dataItem.batchId === x);
    this.managementService.expandedBatchIds.splice(index, 1);
  }

  public onDownloadExcelPdf(batchId: number, type: string) {
    this.pbjSignalRService.isPDFDownloadInitiated$.next(true);
    let expObj = new PBJDownloadExcelPdf();
    expObj.batchId = batchId;
    expObj.type = type;
    expObj.alias = null;
    expObj.userId= null;
    expObj.orgLevelId= this.orgLevel.id;
    expObj.exportId = '0';
    this.managementService.downloadExcelPDF(expObj)
  }
}


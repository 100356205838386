import { Injectable } from "@angular/core";
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from "@angular/forms";
import * as _ from "lodash";
import { AccrualPolicy } from "../../models";
import { AccrualPolicyDialogInfo } from "../../models/accruals-policy/accrual-policy-dialog-info";
import { AccrualPolicyDialogHelper } from "../../components/accruals-policy/accruals-policy-dialog-helper";
import { ModalService } from "./../../../../../app/common";
import { AccrualsMapService } from "../accruals-map.service";
import { AccrualsPolicyMapService } from "./accruals-policy-map.service";
import { AccrualsPolicyApiService } from "./accruals-policy-api.service";
import { AccrualOrgLevels, IAccrualOrgLevels } from "../../models/accruals-policy/copy-accruals-policy";

@Injectable()
export class AccrualPolicyManagementService extends AccrualPolicyDialogHelper {

    public orgLevelId: number;
    public isCreateorEditPolicy: boolean;
    public isCreateorEditAccrualsRolePermission: boolean;
    public isDeleteAccrualsRolePermission: boolean;
    public policyForm: UntypedFormGroup;
    public title: string;
    public text: string;
    public text1: string;
    public errorMessage: string = "";
    public isShow: boolean = false;
    public dialogInfo: any;
    public data: any;
    public isDisabled: boolean;
    public rdoAccrualsTypePeriod: number;
    public rdoAccrualsTypeDates: number;
    public isViewed: boolean;
    public isViewedSrIndex: number;
    public isActive: boolean;
    public accrualPolicies: AccrualPolicy[] = [];
    public m_minimumDate: Date = new Date("1/1/1900");
    public m_maximumDate: Date = new Date("6/6/2079");
    public menuBtnClick: boolean = false;
    public accrualPoliciesData: any;
    public accrualOrgLevelData: AccrualOrgLevels[] = [];

    constructor(modalService: ModalService, mapService: AccrualsPolicyMapService, public apiService: AccrualsPolicyApiService) {
        super(modalService, mapService, apiService);
        this.dialogInfo = AccrualPolicyDialogInfo;
    }

    public minStartDate(): Date {
        return this.m_minimumDate;
    }

    public onInputChange(e: any, accrualPolicyFormGroup: UntypedFormGroup, index: number, srIndex: number) {
        let rulesFormArray = accrualPolicyFormGroup.controls['rules'] as UntypedFormArray;
        let seniorityRulesFormGroup = rulesFormArray.controls[index] as UntypedFormGroup;
        let data = seniorityRulesFormGroup.controls.seniorityRules;
        if (data.value.length - 2 >= 0) {
            if ((e > data.value[data.value.length - 2].periodToValue && data.value[data.value.length - 2].periodFromType == "Days")
                || (e > data.value[data.value.length - 2].periodToValue && data.value[data.value.length - 2].periodFromType == "Weeks")
                || (e > data.value[data.value.length - 2].periodToValue && data.value[data.value.length - 2].periodFromType == "Months")
                || (e > data.value[data.value.length - 2].periodToValue && data.value[data.value.length - 2].periodFromType == "Years")) {
                this.errorMessage = "";
            } else {
                this.errorMessage = "The value cannot be equal or less than " + data.value[data.value.length - 2].periodToValue;
            }
        } else {
            console.log("it has only one row");
        }
    }

    public dropDownOpen(child: UntypedFormGroup) {
        this.isShow = !this.isShow;
        child.get('isBenefitViewMode').setValue(false);
        this.isDisabled = true;
    }

    public dropDownClose(child: UntypedFormGroup) {
        this.isShow = !this.isShow;
        child.get('isBenefitViewMode').setValue(true);
        this.isDisabled = false;
        this.data = [];
    }

    public setRequiredValues(policyForm: UntypedFormGroup, rulesForm: UntypedFormGroup) {
        if (rulesForm.get('accrualCalcType').value == 1) {
            rulesForm.get('accrualCalcTypeName').setValue('Periods');
            rulesForm.get('rdoAccrualsTypePeriod').setValue(1);
            rulesForm.get('rdoAccrualsTypeDates').setValue(0);
            this.rdoAccrualsTypePeriod = 1;
            this.rdoAccrualsTypeDates = 0;
        }
        else {
            rulesForm.get('accrualCalcTypeName').setValue('Specific Dates');
            rulesForm.get('rdoAccrualsTypePeriod').setValue(0);
            rulesForm.get('rdoAccrualsTypeDates').setValue(1);
            this.rdoAccrualsTypeDates = 1;
            this.rdoAccrualsTypePeriod = 0;
        }
    }

    public getLastIndex(ruleFormGroup: UntypedFormGroup, seniorityRuleIndex: number) {
        return (ruleFormGroup.get('seniorityRules') as UntypedFormArray).length - 1 == seniorityRuleIndex;
    }

    public isView(i: number, si?: number) {
        this.menuBtnClick = true;
        let data = this.dialogInfo.dialogInfoData;
        data.find(x => {
            if (x.id == i) {
                this.isViewed = x.id;
                if (si != null) {
                    this.isViewedSrIndex = si; //to handle multiple info dialog opening when multiple records were available in Seniority Grid
                }
                this.title = x.title;
                this.text = x.description;
                this.text1 = x.description2;
            }
        })
        this.isActive = !this.isActive;
    }

    public get getRate(): string {
        let data = this.dialogInfo.dialogInfoData;
        let rateInfo: string;
        data.find(x => {
            if (x.id == 12) {
                rateInfo = x.title + x.description + x.description2;
            }
        })
        return rateInfo;
    }

    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    public policyNameValidator(control: UntypedFormControl) {
        let policyName: any = localStorage.getItem("Policy Names");
        let policyNameData: any = JSON.parse(policyName);
        let name = control.value;
        if (name && policyNameData.includes(name)) {
            return {
                duplicateName: {
                    name: name
                }
            }
        }
        return null;
    }

    public isStartDateEmpty(d): boolean {
        return d == '' || null ? true : false;
    }

    public isEndDateEmpty(d): boolean {
        return d == '' || null ? true : false;
    }

    public isInputActive(e: number): boolean {
        return _.isFinite(e);
    }

    public maxDate(): Date {
        return this.m_maximumDate;
    }

    public copyPolicyDialog(orgLevelName, orgLevelId): void {
        this.accrualOrgLevelData = this.apiService.getOrganizationNames(orgLevelId);
        this.accrualOrgLevelData.length > 0 && this.copyPolicy(this.accrualPoliciesData, orgLevelName, this.accrualOrgLevelData, orgLevelId);
    }
}
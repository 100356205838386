import { Component, OnInit, OnDestroy, Input, Provider, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import { GroupResult, orderBy, groupBy, process, State, GroupDescriptor } from '@progress/kendo-data-query';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { GenericListDefinition, GenericList, GenericRow, GenericFieldDefinition } from '../../../models/generic-list/index';
import { GenericListManagementService } from '../../../services/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { genericGridConfig, IGenericGridConfig } from './generic-grid.config';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { PaperSizes, PaperSize, UnscaledGridColumnWidthMM, A4 } from '../../../../common/models/media/paper-sizes';
import { MetricLengthUnits, MetricLengthUnit, millimeter, centimeter, convertUnit, getMetricLengthUnitByName } from '../../../../common/models/media/metric-units';

@Component({
  moduleId: module.id,
  selector: 'slx-generic-grid',
  templateUrl: 'generic-grid.component.html',
  styleUrls: ['generic-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericGridComponent implements OnInit, OnDestroy {
  @Input()
  public gridKey: string;
  @Input()
  public pageSize: number = 30;
  @Input()
  public genericGridConfig: IGenericGridConfig;
  @Input()
  public disableEmployeeLink: boolean;
  @Input()
  public applyStyle: boolean = true;
  @Input()
  public columnCustomCssClass: string = null;
  @Output()
  public employeeLinkClick: EventEmitter<number>;
  @Output()
  public selectionChanged: EventEmitter<GenericRow[]>;

  @ViewChild('kendoGridElem', {static: true})
  public gridElem: GridComponent;

  public appConfig: IApplicationConfig;
  public isAllSelected: boolean;
  public gridState: KendoGridStateHelper<GenericRow>;
  public listDefinitions: GenericListDefinition;

  public get isPdfExportConfigured(): boolean {
    return !!_.get(this.genericGridConfig, 'pdfExport', null);
  }

  public get isExcelExportConfigured(): boolean {
    return !!_.get(this.genericGridConfig, 'excelExport', null);
  }

  private genericListManagementService: GenericListManagementService;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private definitionsLoadedSubscription: Subscription;
  @unsubscribe()
  private valuesLoadedSubscription: Subscription;

  private listValues: GenericList;
  private changeDetector: ChangeDetectorRef;

  constructor(genericListManagementService: GenericListManagementService, changeDetector: ChangeDetectorRef) {
    Assert.isNotNull(genericListManagementService, 'genericListManagementService');
    this.genericListManagementService = genericListManagementService;
    this.changeDetector = changeDetector;
    this.employeeLinkClick = new EventEmitter<number>();
    this.selectionChanged = new EventEmitter<GenericRow[]>();

    this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe((defs: GenericListDefinition) => {
      this.listDefinitions = defs;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe((list: GenericList) => {
      this.listValues = list;
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.appConfig = appConfig;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public exportExcelData(): () => ExcelExportData {
    return (): ExcelExportData => {
      const exportedDate: ExcelExportData = {
        data: this.genericGridConfig.excelExport.allPages ?
                process(this.listValues.rows, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
                : this.gridState.view.data
      };

      return exportedDate;
    };
  }

  public getFilter(type: string): string {
    if (type === 'int') return 'numeric';
    if (type === 'boolean') return 'boolean';
    if (type === 'date') return 'date';
    if (type === 'datetime') return 'date';
    return null;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<GenericRow>(this.gridKey ? this.gridKey : 'defaultGenericGrid');
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
  }

  public onToggleAllSelected(isAllSelected: boolean): void {
    let rows: GenericRow[] = [];
    let stateValue = Object.assign({}, this.gridState.state);
    stateValue.skip = 0;
    stateValue.group =[];
    stateValue.take = this.listValues.rows.length;    
    let filteredRows = process(this.listValues.rows, stateValue).data;

    _.forEach(filteredRows, (row: GenericRow) => {
      if (row.selectable) {
        row.selected = this.isAllSelected;
      } else {
        row.selected = false;
      }

      if (row.selected) {
        rows.push(row);
      }
    });

    this.genericListManagementService.onRowsSelected(rows);
    this.selectionChanged.emit(rows);

    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public selectionChange(row: GenericRow): void {
    let rows: GenericRow[] = _.filter(this.listValues.rows, (row: GenericRow) => {
      return row.selected;
    });

    this.genericListManagementService.onRowsSelected(rows);
    this.selectionChanged.emit(rows);

    let stateValue = Object.assign({}, this.gridState.state);
    stateValue.skip = 0;
    stateValue.take = this.listValues.rows.length;
    stateValue.group = [];
    let filteredRows = process(this.listValues.rows, stateValue).data;
    let selectableRows : GenericRow[] = _.filter(filteredRows, (selectableRow: GenericRow) => {
      return selectableRow.selectable;
    });
    let allSelectedRows = _.every(selectableRows,(selectedRow: GenericRow) => {
      return selectedRow.selected;
    })
     if(allSelectedRows) {
      this.isAllSelected = true;
     }
     
    if (!row.selected) {
      this.isAllSelected = false;
    }

    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public onEmployeeLinkClick(empId: number): void {
    this.employeeLinkClick.emit(empId);
  }

  public exportToExcel(): void {

    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();

    this.gridElem.saveAsExcel();
  }

  public exportToPdf(): void {

    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();

    if (this.genericGridConfig.pdfExport.autoscale) {
      let fieldCount: number = 0;
      _.each(this.genericListManagementService.listDefinition.fields, (field: GenericFieldDefinition) => {
        fieldCount++;
      });
      let paper: PaperSize = _.find(PaperSizes, (paper: PaperSize) => {
        return paper.name === this.genericGridConfig.pdfExport.paperSize;
      });
      let width: number;
      if (paper) {
        width = this.genericGridConfig.pdfExport.landscape ? paper.heightMM : paper.widthMM;
      } else {
        width = this.genericGridConfig.pdfExport.landscape ? A4.heightMM : A4.widthMM;
      }

      let leftMargin: string = this.genericGridConfig.pdfExport.left;
      if (leftMargin) {
        width = this.subtractMargin(width, leftMargin);
      }

      let rightMargin: string = this.genericGridConfig.pdfExport.right;
      if (rightMargin) {
        width = this.subtractMargin(width, rightMargin);
      }

      this.genericGridConfig.pdfExport.scale = width / (fieldCount * UnscaledGridColumnWidthMM);
    }
    this.refreshGrid();
    this.gridElem.saveAsPDF();
  }

  public groupStateChanged(groups: GroupDescriptor[]): void {
    this.validateGroups(groups);
  }

  protected reCheckSelectAll(currentRows: GenericRow[]): void {
    let rows: GenericRow[] = [];

    for (const row of currentRows) {
      if (!row.selectable) {
        continue;
      }

      if (row.selected) {
        rows.push(row);
      } else {
        this.isAllSelected = false;
      }
    }

    this.genericListManagementService.onRowsSelected(rows);
    this.selectionChanged.emit(rows);

    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  protected subtractMargin(width: number, margin: string): number {
    let unit: MetricLengthUnit;
    let value: number;
    value = this.getMarginValue(margin);
    unit = getMetricLengthUnitByName(this.getMarginUnit(margin));
    if (!isNaN(value)) {
      if (unit && unit !== millimeter) {
        value = convertUnit(unit, millimeter, value);
      }
      width -= value;
    }
    return width;
  }

  protected getMarginUnit(margin: string): string {
    let unit: string;
    let value: number = parseFloat(margin);
    if (!isNaN(value)) unit = margin.replace(value.toString(10), '');
    return unit;
  }

  protected getMarginValue(margin: string): number {
    return parseFloat(margin);
  }

  protected validateGroups(groups: GroupDescriptor[]): void {
    let validGroups: GroupDescriptor[] = [];
    let hadInvalid: boolean = false;
    _.each(groups, (groupField: GroupDescriptor) => {
      if (this.isGroupFieldValid(groupField)) {
        validGroups.push(groupField);
      } else {
        hadInvalid = true;
      }
    });
    this.gridState.state.group = validGroups;
    if (hadInvalid) this.refreshGrid();
  }

  protected isGroupFieldValid(groupField: GroupDescriptor): boolean {
    let valid: boolean = true;
    if (!groupField.field) {
      valid = false;
    } else {
      if (groupField.field.indexOf('Selectable') !== -1) {
        valid = false;
      }
      if (this.genericGridConfig && this.genericGridConfig.groupFieldCustomValidation) {
        if (!this.genericGridConfig.groupFieldCustomValidation(groupField)) valid = false;
      }
    }
    return valid;
  }

  protected pageChange(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  private refreshGrid(): void {
    if (!this.listValues || !this.listValues.rows) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.listValues.rows, this.gridState.state);
    this.reCheckSelectAll(this.listValues.rows);
  }
}



import {combineLatest as observableCombineLatest,  Observable ,  Subject ,  Subscription } from 'rxjs';

import {tap, filter, combineLatest, map} from 'rxjs/operators';
import { OrgLevelWatchService } from './../../../organization/services/org-level/org-level-watch.service';
import { ScheduleCycle } from './../../../organization/models/lookup/schedule-cycle';
import { IndividualScheduleNavigationService } from './../../../common/services/navigation/individual-schedule-navigation.service';
import { IControlState, StateResetTypes } from './../../../core/models/settings/index';
import { ComponentStateStorageService } from './../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from './../../../common/services/state-management/state-management.service';

import { StringUtils } from './../../../framework/string-utils/string-utils';
import { ShiftDefinition } from './../../../organization/models/lookup/shift-definition';
import { DailyUnitAssignmentNavigationService } from './../../../common/services/navigation/daily-unit-assignment-navigation.service';
import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DecimalPipe } from '@angular/common';



import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas-pro';

import * as moment from 'moment';
import * as _ from 'lodash';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';

import { LookupService, ScheduleCycleHelperService } from '../../../organization/services/index';
import { Position, Lookup, LookupType, LookupEntity, LocationUnit, Shift, PositionGroup } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';

import { ShiftReplacementRequest, ShiftAddEmployeeCmd, ShiftReplaceCmd, GroupDetailsData } from '../../models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { DetailScreenService, DetailScreenMapService, ShiftReplacementApiService, ScheduleApiService } from '../../services/index';
import { Details, DetailRow, DetailField, DetailGroup, OpenShiftDetails } from '../../models/index';
import { ScheduleEntryNavigationService, SlxTooltipDirective, SoConsoleNavigationService } from '../../../common/index';
import { employeeListConfig } from '../../../employee/employee-list/employee-list.config';
import { appConfig } from '../../../app.config';
import { DetailGroupViewSettingsTypes } from '../../models/index';
import { EmployeeAddShiftOpenComponent, ShiftReplacementReplaceComponent } from '../shift-replacement/index';

import { ExportExcelGridComponent } from '../daily-unit-grid/export-excel-grid/export-excel-grid.component';
import { DailyUnitGridEmployee } from '../daily-unit-grid/daily-unit-grid-employees/daily-unit-grid-employees-model';

const imageCache: StringMap<string> = {};
const VIEW_MODE_KEY: string = 'view_mode';
const FILTERS: string = 'filters';
const DATE: string = 'date';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-unit',
  templateUrl: 'daily-unit.component.html',
  styleUrls: ['daily-unit.component.scss'],
  providers: [StateManagementService]
})
export class DailyUnitComponent implements OnInit, OnDestroy {
  @Input()
  public set dateOn(value: Date) {
    this.m_dateOn = value;
  }

  @Input()
  public shiftGroup: boolean;

  public get dateOn(): Date {
    return this.m_dateOn;
  }

  @Input()
  public isEmptyExists: boolean = false;
  public GroupedListArray: any = [];
  public GroupedList: any = [];
  public showGrid = false;
  public IsEnabled: boolean = true;
 
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;

  public details: Details;

  public state: {
    isLoading: boolean;
    isSelectPropagated: boolean;
    switchShiftMode: boolean;
  };

  public currentOrgLevel: OrgLevel;
  public previousGroups: DetailGroup[];
  public previousDayDetails: DetailGroup[];
  public nextDayDetails: DetailGroup[];
  public previousDetails: Details;
  public filtersOn: boolean;
  public restrictedInfo: string[];
  public rowForShiftChange: DetailRow;

  public positionGroupFilter: PositionGroup[];
  public posFilter: Position[];
  public unitFilter: LocationUnit[];
  public shiftFilter: Shift[];

  public positionGroupLookup: Lookup;
  public positionLookup: Lookup;
  public unitsLookup: Lookup;
  public shiftsLookup: Lookup;
  public currentShifts: ShiftDefinition[];

  public currentViewMode: DetailGroupViewSettingsTypes;
  public filtersApplied: boolean = false;

  private prePositionGroupFilter: PositionGroup[];
  private prevPosFilter: Position[];
  private prevUnitFilter: LocationUnit[];
  private prevShiftFilter: Shift[];
  private m_dateOn: Date;
  private pathPositionGroupIds: number[];
  private pathPositionIds: number[];
  private unitIds: number[];
  private shiftIds: number[];

  private scheduleCycleHelperService: ScheduleCycleHelperService;
  private detailScreenService: DetailScreenService;
  private scheduleApiService: ScheduleApiService;
  private detailScreenMapService: DetailScreenMapService;
  private loadDetailsEvent: Subject<any>;
  @unsubscribe()
  private detailsSubscription: Subscription;
  @unsubscribe()
  private dateSubscription: Subscription;
  @unsubscribe()
  private notDepartmentSubscription: Subscription;
  @unsubscribe()
  private routeSubscripion: Subscription;
  @unsubscribe()
  private orgLevelSubscripion: Subscription;

  private modalService: ModalService;
  private activatedRoute: ActivatedRoute;
  private router: Router;
  private shiftReplacementApiService: ShiftReplacementApiService;
  private domSanitizer: DomSanitizer;

  private selectedPositionGroupIds: number[];
  private selectedPositionIds: number[];
  private selectedUnitIds: number[];
  private selectedShiftIds: number[];

  private changeDetector: ChangeDetectorRef;
  private navigateService: DailyUnitAssignmentNavigationService;
  private soNavService: SoConsoleNavigationService;
  private individualNavService: IndividualScheduleNavigationService;
  private groupTable: Element;
  private groupContainer: HTMLElement;
  private scrollToValue: number = 0;

  constructor(
    scheduleApiService: ScheduleApiService,
    detailScreenService: DetailScreenService, detailScreenMapService: DetailScreenMapService, modalService: ModalService,
    activatedRoute: ActivatedRoute, router: Router, shiftReplacementApiService: ShiftReplacementApiService,
    domSanitizer: DomSanitizer, changeDetector: ChangeDetectorRef, scheduleCycleHelperService: ScheduleCycleHelperService,
    private lookupService: LookupService, private stateManagement: StateManagementService, private storageService: ComponentStateStorageService,
    private orgLevelService: OrgLevelWatchService, private elementRef: ElementRef) {
    this.scheduleApiService = scheduleApiService;
    this.detailScreenService = detailScreenService;
    this.detailScreenMapService = detailScreenMapService;
    this.modalService = modalService;
    this.activatedRoute = activatedRoute;
    this.router = router;
    this.shiftReplacementApiService = shiftReplacementApiService;
    this.scheduleCycleHelperService = scheduleCycleHelperService;
    this.domSanitizer = domSanitizer;
    this.restrictedInfo = ['ShiftGroup'];

    this.selectedPositionGroupIds =[];
    this.selectedPositionIds = [];
    this.selectedShiftIds = [];
    this.selectedUnitIds = [];
    this.changeDetector = changeDetector;

    this.navigateService = new DailyUnitAssignmentNavigationService(this.router, this.activatedRoute);
    this.individualNavService = new IndividualScheduleNavigationService(this.router, this.activatedRoute);
    this.soNavService = new SoConsoleNavigationService(this.router, this.activatedRoute);

    this.state = {
      isLoading: false,
      isSelectPropagated: true,
      switchShiftMode: false
    };
  }

  public ngOnInit(): void {
    this.stateManagement.init('DailyUnitComponent', true);
    this.loadDetailsEvent = new Subject<any>();
    this.state.isLoading = true;
    this.shiftGroup = true;
    let dateOnEvent$: Observable<Date> = this.activatedRoute.params.pipe(
      map(({ date: dateOn }: Params) => {
        let d: Date = moment(dateOn, appConfig.linkDateFormat).toDate();
        if (!dateOn) {
          return this.getSavedDate();
        } else {
          this.saveDate(d);
        }
        return d;
      }));
    this.routeSubscripion = this.activatedRoute.params.pipe(
      combineLatest(this.activatedRoute.queryParams))
      .subscribe(([params, queryParams]) => {
        let posGroupIds: string = queryParams['positionGroupId'];
        if (posGroupIds) this.pathPositionGroupIds = _.map(posGroupIds.split(','), (item: string) => +item);
        let posIds: string = queryParams['positionId'];
        if (posIds) this.pathPositionIds = _.map(posIds.split(','), (item: string) => +item);
        let unitIds: string = queryParams['unitId'];
        if (unitIds) this.unitIds = _.map(unitIds.split(','), (item: string) => +item);
        let shiftIds: string = queryParams['shiftId'];
        if (shiftIds) this.shiftIds = _.map(shiftIds.split(','), (item: string) => +item);
        this.setViewMode(queryParams[VIEW_MODE_KEY]);
      });
    this.dateSubscription = dateOnEvent$.subscribe((dateOn: Date) => this.dateOn = dateOn);
    this.orgLevelSubscripion = this.orgLevel$.subscribe((selectedOrgLevel: OrgLevel) => {
      this.orgLevel = selectedOrgLevel;
    });

    let orgLevelChangeEvent$: Observable<OrgLevel> = this.orgLevel$.pipe(
      filter((selectedOrgLevel: OrgLevel) => selectedOrgLevel.type === OrgLevelType.department));

    let detailsEvent$: Observable<[OrgLevel, Date, any]> = observableCombineLatest(orgLevelChangeEvent$, dateOnEvent$, this.loadDetailsEvent).pipe(
      tap(() => this.state.isLoading = true));

    this.detailsSubscription = detailsEvent$.subscribe(([selectedOrgLevel, dateOn]: [OrgLevel, Date, any]) => {
      if (!moment(dateOn).isValid()) {
        return;
      }

      this.loadDetails(selectedOrgLevel, dateOn)
        .then((d: Details) => {
          this.details = d;
          this.previousGroups = _.cloneDeep(this.details.groups);
          this.previousDetails = _.cloneDeep(this.details);
          this.currentOrgLevel = selectedOrgLevel;
          this.applyFilters();
          this.restoreGroupsStates(this.previousGroups); //always after filters!
        })
        .catch((error: any) => console.log(error))
        .then(() => this.state.isLoading = false);

        this.loadDetails(selectedOrgLevel, moment(dateOn).subtract(1, 'day').toDate())
        .then((d: Details) => {
          this.previousDayDetails = _.cloneDeep(d.groups);
        }).catch((error: any) => console.log(error))

      this.loadDetails(selectedOrgLevel, moment(dateOn).add(1, 'day').toDate())
        .then((d: Details) => {
          this.nextDayDetails = _.cloneDeep(d.groups);
        }).catch((error: any) => console.log(error))

      this.loadFilterLookups(selectedOrgLevel.id);
    });

    this.notDepartmentSubscription = this.orgLevel$.pipe(
      filter((o: OrgLevel) => o.type !== OrgLevelType.department))
      .subscribe(() => {
        this.soNavService.navigateToSoConsole();
      });
    this.loadDetailsEvent.next();
    this.convertToDailyUnitEmployee();
    this.showGrid = true;
    this.getExportData();
  }
  
  convertToDailyUnitEmployee() {
 
    this.GroupedListArray =[];
    this.GroupedList =[];
    this.previousDetails && this.previousDetails.groups.forEach((currentDetailGroup) => {
      
      
      currentDetailGroup && currentDetailGroup.rows.forEach((currentDetailRow) => {
        let dailyUnitGridEmployee: DailyUnitGridEmployee = new DailyUnitGridEmployee();
        currentDetailRow.fields.forEach((field) => {
          if ('InPar' === field.name) {
            dailyUnitGridEmployee.InPar = field.value;
          } else if ('EmpId' === field.name) {
            dailyUnitGridEmployee.EmpId = field.value;
          } else if ('EmpName' === field.name) {
            dailyUnitGridEmployee.EmpName = field.value;
          } else if ('DepartmentId' === field.name) {
            dailyUnitGridEmployee.DepartmentId = field.value;
          } else if ('PositionGroupId' === field.name) {
            dailyUnitGridEmployee.PositionGroupId = field.value;
          } else if ('PositionGroupName' === field.name) {
            dailyUnitGridEmployee.PositionGroupName = field.value;
          } else if ('JobCode' === field.name) {
            dailyUnitGridEmployee.JobCode = field.value;
          } else if ('JobDescription' === field.name) {
            dailyUnitGridEmployee.JobDescription = field.value;
          } else if ('ShiftGroupId' === field.name) {
            dailyUnitGridEmployee.ShiftGroupId = field.value;
          } else if ('ShiftGroup' === field.name) {
            dailyUnitGridEmployee.ShiftGroup = field.value;
          } else if ('ShiftId' === field.name) {
            dailyUnitGridEmployee.ShiftId = field.value;
          } else if ('ShiftName' === field.name) {
            dailyUnitGridEmployee.ShiftName = field.value;
          } else if ('ShiftStart' === field.name) {
            dailyUnitGridEmployee.ShiftStart = field.value;
          } else if ('ShiftEnd' === field.name) {
            dailyUnitGridEmployee.ShiftEnd = field.value;
          } else if ('UnitId' === field.name) {
            dailyUnitGridEmployee.UnitId = field.value;
          } else if ('UnitName' === field.name) {
            dailyUnitGridEmployee.UnitName = field.value;
          } else if ('SchedHours' === field.name) {
            dailyUnitGridEmployee.SchedHours = field.value;
          } else if ('Hours' === field.name) {
            dailyUnitGridEmployee.Hours = field.value;
          } else if ('IsOvertime' === field.name) {
            dailyUnitGridEmployee.IsOvertime = field.value;
          } else if ('PPD' === field.name) {
            dailyUnitGridEmployee.PPD = field.value;
          } else if ('SlxpartnerdataId' === field.name) {
            dailyUnitGridEmployee.SlxpartnerdataId = field.value;
          } else if ('RequeststatusId' === field.name) {
            dailyUnitGridEmployee.RequeststatusId = field.value;
          } else if ('MessageCount' === field.name) {
            dailyUnitGridEmployee.MessageCount = field.value;
          } else if ('PartnerId' === field.name) {
            dailyUnitGridEmployee.PartnerId = field.value;
          } else if ('ShiftRequestDate' === field.name) {
            dailyUnitGridEmployee.ShiftRequestDate = field.value;
          }
          else if ('ActualShiftStart' === field.name) {
            dailyUnitGridEmployee.actualShiftStart = field.value;
          }
          else if ('ActualShiftEnd' === field.name) {
            dailyUnitGridEmployee.actualShiftEnd = field.value;
          }
          else if ('ConfigType' === field.name) {
            dailyUnitGridEmployee.ConfigType = field.value;
          }
          else if ('AllowAssignment' === field.name) {
            dailyUnitGridEmployee.AllowAssignment = (field.value == '1') ? true : false;
          }
          else if('EmpAssignedType' === field.name) {
            dailyUnitGridEmployee.EmpAssignedType = field.value;
          }
        });
        if(dailyUnitGridEmployee.RequeststatusId == "1" && dailyUnitGridEmployee.EmpName == ""){
          dailyUnitGridEmployee.EmpName = employeeListConfig.pendingShiftPartnerStatus;
          dailyUnitGridEmployee.AgencyName = "";
        }
        if(dailyUnitGridEmployee.RequeststatusId == "4" && dailyUnitGridEmployee.EmpName == ""){
          dailyUnitGridEmployee.EmpName = employeeListConfig.preassignPendingStatus;
          dailyUnitGridEmployee.AgencyName = "";
        }
        if(currentDetailRow.isEmpty && !currentDetailRow.isPendingShift){
          dailyUnitGridEmployee.IsOpenShift = currentDetailRow.isEmpty;
        }
        this.GroupedList.push(dailyUnitGridEmployee);
      });
      //this.GroupedList.sort((a: { EmpName: { toUpperCase: () => number; }; }, b: { EmpName: { toUpperCase: () => number; }; }) => (a.EmpName.toUpperCase() > b.EmpName.toUpperCase()) ? 1 : -1);
      this.GroupedListArray.push(this.GroupedList);
    });
    
    this.getExportData();
    
  }
  private getExportData(): void {
    try {
      this.GroupedListArray = this.GroupedListArray.length > 0 ? this.GroupedListArray[0] : this.GroupedListArray;
    } catch (e) {}
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public setViewMode(routeMode: string): void {
    if (routeMode && routeMode in DetailGroupViewSettingsTypes) {
      this.currentViewMode = <DetailGroupViewSettingsTypes>routeMode;
    } else {
      this.restoreViewMode();
    }
  }

  public loadFilterLookups(selectedOrgLevelId: number): void {
    let positionGroupsPromise: Promise<Lookup> =this.lookupService.getLookup({ lookupType: LookupType.positionGroups, orgLevelId: selectedOrgLevelId });
    let positionsPromise: Promise<Lookup> = this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: selectedOrgLevelId });
    let unitsPromise: Promise<Lookup> = this.lookupService.getLookup({ lookupType: LookupType.locationUnit, orgLevelId: selectedOrgLevelId });
    let shiftsPromise: Promise<Lookup> = this.lookupService.getLookup({ lookupType: LookupType.shift, orgLevelId: selectedOrgLevelId });

    Promise.all([positionGroupsPromise,positionsPromise, unitsPromise, shiftsPromise])
      .then((values: any[]) => {
        [this.positionGroupLookup,this.positionLookup, this.unitsLookup, this.shiftsLookup] = values;
        this.restoreFilters();
      });
  }

  public loadDetails(orgLevel: OrgLevel, dateOn: Date): Promise<Details> {
    return this.detailScreenService.getDailyUnits(orgLevel, dateOn);
  }

  public shiftOpen(row: DetailRow): void {
    let empIdField: DetailField = _.find(row.fields, (f: DetailField) => f.name === employeeListConfig.employeeIdentifierName);
    if (!empIdField) {
      return;
    }
    this.navigateService.navigateToMasterScheduleOpenShifts(this.dateOn, empIdField.value);
  }

  public async onFillShift(row: DetailRow): Promise<void> {
    let shiftIdField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'ShiftId');
    let shiftNameField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'ShiftName');
    let positionIdField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'JobCode');
    let positionNameField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'JobDescription');
    let unitIdField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'UnitId');
    let unitNameField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'UnitName');
    if (!shiftIdField || !positionIdField || !unitIdField) {
      return;
    }

    let orgLevel: OrgLevel;
    if(this.orgLevel.parentId > 0) {
      orgLevel = await this.orgLevelService.getOrgLevelByIdSafe(this.orgLevel.parentId);
    } else {
      orgLevel = this.orgLevel;
    }

    let request: ShiftReplacementRequest = new ShiftReplacementRequest();
    request.shiftId = +shiftIdField.value;
    request.shiftName = shiftNameField.value;
    request.positionId = +positionIdField.value;
    request.positionName = positionNameField.value;
    request.organizationName = orgLevel.name;
    request.unitId = +unitIdField.value;
    request.unitName = unitNameField.value;
    request.date = this.dateOn;
    request.showDayOffEmployees = true;
    request.showSendSmsButton = true;
    const data: GroupDetailsData = {
      request: request,
      groups: this.previousGroups,
      previousDayGroups: this.previousDayDetails,
      nextDayGroups: this.nextDayDetails
    }
    EmployeeAddShiftOpenComponent.openDialog(data, +unitIdField.value, this.modalService, (result: boolean, cmd: ShiftAddEmployeeCmd) => {
      if (result && cmd) {
        this.state.isLoading = true;
        this.shiftReplacementApiService.addToShift(cmd)
          .catch((error: any) => {
            this.state.isLoading = false;
            console.log(error);
          })
          .then((status: number) => {
            this.state.isLoading = false;
            this.loadDetailsEvent.next();
          });
      }
    });
  }

  public onOpenIndividual(row: DetailRow): void {
    let employeeId: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'EmpId');
    let departamentId: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'DepartmentId');
    let posOrgLevel: OrgLevel = this.orgLevelService.getOrgLevelByRelatedItemId(+departamentId.value, OrgLevelType.department);
    if (!posOrgLevel) return;
    let orgLevelId: number = posOrgLevel.id;

    this.state.isLoading = true;
    this.scheduleCycleHelperService.getScheduleCycleByDate(this.dateOn, orgLevelId)
      .then(({ startDate, endDate }: ScheduleCycle) => {
        this.state.isLoading = false;
        this.individualNavService.NavigateToIndividualScheduleEmp(+employeeId.value, startDate.toDate(), endDate.toDate(), this.dateOn);
      });
  }

  public onReplaceEmployee(row: DetailRow): void {
    let employeeName: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'EmpName');
    let employeeId: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'EmpId');
    let shiftIdField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'ShiftId');
    let shiftStartField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'ShiftStart');
    let shiftEndField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'ShiftEnd');
    let shiftNameField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'ShiftName');
    let positionIdField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'JobCode');
    let unitIdField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'UnitId');
    let actualShiftStartField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'ActualShiftStart');
    let actualShiftEndField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'ActualShiftEnd');
    if (!shiftIdField || !positionIdField || !unitIdField) {
      return;
    }

    let request: ShiftReplacementRequest = new ShiftReplacementRequest();
    request.replacedEmployeeName = employeeName.value;
    request.replacedEmployeeId = +employeeId.value;
    request.shiftId = +shiftIdField.value;
    request.shiftName = shiftNameField.value;
    request.positionId = +positionIdField.value;
    request.date = this.dateOn;
    request.shiftStart = moment(shiftStartField.value).toDate();
    request.shiftEnd = moment(shiftEndField.value).toDate();
    request.momentActualShiftStart = moment(actualShiftStartField.value);
    request.momentActualShiftEnd = moment(actualShiftEndField.value)
    request.showDayOffEmployees = true;
    request.orgLevelId = this.currentOrgLevel.id;


    ShiftReplacementReplaceComponent.openDialog(request, this.modalService, (result: boolean, cmd: ShiftReplaceCmd): void => {
      if (result && cmd) {
        this.state.isLoading = true;
        this.shiftReplacementApiService.replaceEmployee(cmd)
          .catch((error: any) => {
            this.state.isLoading = false;
            console.log(error);
          })
          .then((status: number) => {
            this.state.isLoading = false;
            this.loadDetailsEvent.next();
          });
      }
    });
  }

  public hideTable(groupTable: HTMLElement): void {
    $(groupTable).toggle();
  }

  public changeDateOn(dateOn: Date): void {
    this.navigateService.navigateToDailyUnitAssignmentDate(dateOn, this.pathPositionIds, this.currentViewMode, false, this.unitIds, this.shiftIds);
  }

  public onFilterChange(filterName: string, filter: any[]): void {
    this.state.isSelectPropagated = false;

    let ids: number[] = _.map(filter, m => m.id);
    if (filterName === 'unit') {
      this.selectedUnitIds = ids;
    } else if (filterName === LookupType.position) {
      this.selectedPositionIds = ids;
    } else if (filterName === LookupType.shift) {
      this.selectedShiftIds = ids;
    } else if (filterName === LookupType.positionGroups) {
      this.selectedPositionGroupIds = ids;
    }
  }

  public calcAppliedFilters(): void {
    this.filtersApplied = Boolean(this.selectedUnitIds && this.selectedUnitIds.length) ||
      Boolean(this.selectedPositionIds && this.selectedPositionIds.length) ||
      Boolean(this.selectedShiftIds && this.selectedShiftIds.length) ||
      Boolean(this.selectedPositionGroupIds && this.selectedPositionGroupIds.length);
  }

  public filterRows(rows: DetailRow[]): DetailRow[] {
    let filtered: DetailRow[] = this.filterRowsBy(rows, 'UnitId', this.selectedUnitIds);
    filtered = this.filterRowsBy(filtered, 'ShiftId', this.selectedShiftIds);
    filtered = this.filterRowsBy(filtered, 'JobCode', this.selectedPositionIds);
    filtered = this.filterRowsBy(filtered, 'PositionGroupId', this.selectedPositionGroupIds);
    return filtered;
  }

  public filterRowsBy(rows: DetailRow[], fieldName: string, filteredItemIds: number[]): DetailRow[] {
    if (!filteredItemIds || filteredItemIds.length === 0) return rows;

    let filtered: DetailRow[] = _.filter(rows, (row: DetailRow) => {
      let field: DetailField = _.find(row.fields, (f: DetailField) => f.name === fieldName);
      let id: number = _.find(filteredItemIds, (itemId: number) => +itemId === +field.value);
      return !!id;
    });

    return filtered;
  }

  public stopPropagation($event: Event): void {
    if (this.state.isSelectPropagated) {
      $event.stopPropagation();
    } else {
      this.state.isSelectPropagated = true;
    }
  }

  public employeeDelete(row: DetailRow): void {
    let shiftIdField: DetailField = _.find(row.fields, (f: DetailField) => f.name === 'ShiftId');
    let employeeId: DetailField = _.find(row.fields, (f: DetailField) => f.name === employeeListConfig.employeeIdentifierName);
    this.detailScreenService.deleteDailyUnitEmployee(this.dateOn, +shiftIdField.value, +employeeId.value)
      .then(() => this.loadDetailsEvent.next());
  }

  public onSwitchEmployeeShift(row: DetailRow): void {
    this.rowForShiftChange = row;
    this.state.switchShiftMode = true;
  }

  public discardShiftSwitch(): void {
    this.rowForShiftChange = null;
    this.state.switchShiftMode = false;
  }

  public performShiftSwitch(item: OpenShiftDetails): void {

    let field: DetailField;
    let empId: number;
    let originalId: number;
    let replaceId: number;
    let replaceUnitId: number;

    field = this.getField(this.rowForShiftChange.fields, 'EmpId');
    if (field) empId = parseInt(field.value);

    field = this.getField(this.rowForShiftChange.fields, 'ShiftId');
    if (field) {
      originalId = parseInt(field.value);
      field.value = item.shift.id.toString();
      replaceId = parseInt(field.value);
    }

    field = this.getField(this.rowForShiftChange.fields, 'ShiftName');
    if (field) field.value = item.shift.name;

    field = this.getField(this.rowForShiftChange.fields, 'UnitId');
    if (field) {
      field.value = item.unit.id.toString();
      replaceUnitId = parseInt(field.value);
    }

    field = this.getField(this.rowForShiftChange.fields, 'UnitName');
    if (field) field.value = item.unit.name;

    this.state.isLoading = true;
    this.scheduleApiService.changeOpenShift(this.dateOn, empId, originalId, replaceId, replaceUnitId).then(() => {
      this.state.isLoading = false;
      this.rowForShiftChange = null;
      this.state.switchShiftMode = false;
      this.loadDetailsEvent.next();
    }).catch((err: any) => {
      this.state.isLoading = false;
    });
  }

  public get censusCount(): number {
    return this.details ? this.details.censusCount : 0;
  }

  public onCensusEdit(): void {
    this.navigateService.navigateToCensusEditor(this.dateOn);
  }

  public savePreviousFilters(): void {
    this.prePositionGroupFilter = this.positionGroupFilter ? this.positionGroupFilter.concat([]) : [];
    this.prevPosFilter = this.posFilter ? this.posFilter.concat([]) : [];
    this.prevUnitFilter = this.unitFilter ? this.unitFilter.concat([]) : [];
    this.prevShiftFilter = this.shiftFilter ? this.shiftFilter.concat([]) : [];
  }

  public applyFilters(): void {
    if (this.details) {
      this.previousGroups = _.cloneDeep(this.details.groups);
      this.previousDetails = _.cloneDeep(this.details);
    }
    _.each(this.previousDetails.groups, (group: DetailGroup) => {
      group.rows = this.filterRows(group.rows);
      group.recalculateFilteredValues();
    });
    _.each(this.previousGroups, (group: DetailGroup) => {
      group.rows = this.filterRows(group.rows);
      group.recalculateFilteredValues();
    });
    this.calcAppliedFilters();
    this.convertToDailyUnitEmployee();
  }

  public cancelFilters(): void {
    this.positionGroupFilter = this.prePositionGroupFilter;
    this.posFilter = this.prevPosFilter;
    this.unitFilter = this.prevUnitFilter;
    this.shiftFilter = this.prevShiftFilter;
  }

  public toggleEmptySlots(group: DetailGroup, event: any): void {
    group.isEmptySlotFilterSelected = !group.isEmptySlotFilterSelected;
    group.recalculateFilteredValues();
    this.saveGroupState(group, 'isEmptySlotFilterSelected', group.isEmptySlotFilterSelected);
    event.stopPropagation();
  }

  public toggleOutOfPar(group: DetailGroup, event: any): void {
    group.isOutOfParFilterSelected = !group.isOutOfParFilterSelected;
    group.recalculateFilteredValues();
    this.saveGroupState(group, 'isOutOfParFilterSelected', group.isOutOfParFilterSelected);
    event.stopPropagation();
  }

  public toggleNewMessages(group: DetailGroup, event: any): void {
    group.isMessageFilterSelected = !group.isMessageFilterSelected;
    group.recalculateFilteredValues();
    this.saveGroupState(group, 'isMessageFilterSelected', group.isMessageFilterSelected);
    event.stopPropagation();
  }

  public toggleOvertimeEmployees(group: DetailGroup, event: any): void {
    group.isOvertimeFilterSelected = !group.isOvertimeFilterSelected;
    group.recalculateFilteredValues();
    this.saveGroupState(group, 'isOvertimeFilterSelected', group.isOvertimeFilterSelected);
    event.stopPropagation();
  }

  public viewSettingsChanged(event: DetailGroupViewSettingsTypes): void {
    this.currentViewMode = event;
    this.saveViewMode();
  }

  public saveFilters(): void {
    let filters: any = {
      positionGroup: this.positionGroupFilter,
      positions: this.posFilter,
      units: this.unitFilter,
      shifts: this.shiftFilter
    };
    this.storageService.setControlState(this.stateManagement.componentKey, FILTERS,
      { value: filters }, StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.ParameterInRoute);

    if (this.selectedPositionGroupIds && this.selectedPositionGroupIds.length) {
      this.pathPositionGroupIds = this.selectedPositionGroupIds.concat([]);
    } else {
      this.pathPositionGroupIds = [];
    }

    if (this.selectedPositionIds && this.selectedPositionIds.length) {
      this.pathPositionIds = this.selectedPositionIds.concat([]);
    } else {
      this.pathPositionIds = [];
    }

    if (this.selectedUnitIds && this.selectedUnitIds.length) {
      this.unitIds = this.selectedUnitIds.concat([]);
    } else {
      this.unitIds = [];
    }

    if (this.selectedShiftIds && this.selectedShiftIds.length) {
      this.shiftIds = this.selectedShiftIds.concat([]);
    } else {
      this.shiftIds = [];
    }

    this.changeDateOn(this.dateOn);
  }

  public groupContainerCreated(item: HTMLElement): void {
    this.groupContainer = item;
    this.scrollGroupContainer();
  }

  public scrollTo(value: number): void {
    this.scrollToValue = value;
    this.scrollGroupContainer();
  }

  public get totalEmptySlotCount(): number {
    if (!this.previousGroups) return 0;
    let mapped: number[] = _.map(this.previousGroups, 'emptySlotCount');
    return _.sum(mapped);
  }

  private scrollGroupContainer(): void {
    if (this.groupContainer && this.scrollToValue > 0) {
      this.groupContainer.scrollTop = this.scrollToValue - this.groupContainer.offsetTop;
      this.scrollToValue = 0;
      this.groupContainer = null;
    }
  }

  private saveGroupState(group: DetailGroup, prop: string, propValue: boolean): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, group.name);
    if (!state || !state.value) {
      state = { value: {} };
    }
    state.value[prop] = propValue;
    this.storageService.setControlState(this.stateManagement.componentKey, group.name,
      state, StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange);
  }

  private saveViewMode(): void {
    this.storageService.setControlState(this.stateManagement.componentKey, VIEW_MODE_KEY,
      { value: this.currentViewMode }, StateResetTypes.None);
  }

  private saveDate(date: Date): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, DATE);
    if (!state || !state.value) {
      state = { value: {} };
    }
    state.value = date;
    this.storageService.setControlState(this.stateManagement.componentKey, DATE,
      state, StateResetTypes.SessionEnd | StateResetTypes.MenuChange);
  }

  private getSavedDate(): Date {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, DATE);
    if (state.value) return state.value;
    return new Date();
  }

  private restoreViewMode(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, VIEW_MODE_KEY);
    if (state.value && state.value in DetailGroupViewSettingsTypes) {
      this.currentViewMode = state.value;
    } else {
      this.currentViewMode = DetailGroupViewSettingsTypes.slots;
    }
  }

  private restoreFilters(): void {
    let positionGroupToSelect: PositionGroup[] = [];
    if (this.pathPositionGroupIds && this.pathPositionGroupIds.length) {
      if (this.positionGroupLookup && this.positionGroupLookup.items) {
        _.forEach(this.pathPositionGroupIds, (id: number) => {
          let pos: PositionGroup = _.find(this.positionGroupLookup.items, (item: PositionGroup): boolean => {
            return item.id === id;
          });
          if (pos) positionGroupToSelect.push(pos);
        });
        if (positionGroupToSelect && positionGroupToSelect.length) {
          this.positionGroupFilter = positionGroupToSelect;
          this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
        }
      }
    }

    let positionToSelect: Position[] = [];
    if (this.pathPositionIds && this.pathPositionIds.length) {
      if (this.positionLookup && this.positionLookup.items) {
        _.forEach(this.pathPositionIds, (id: number) => {
          let pos: Position = _.find(this.positionLookup.items, (item: Position): boolean => {
            return item.id === id;
          });
          if (pos) positionToSelect.push(pos);
        });
        if (positionToSelect && positionToSelect.length) {
          this.posFilter = positionToSelect;
          this.onFilterChange(LookupType.position, this.posFilter);
        }
      }
    }

    let unitToSelect: LocationUnit[] = [];
    if (this.unitIds && this.unitIds.length) {
      if (this.unitsLookup && this.unitsLookup.items) {
        _.forEach(this.unitIds, (id: number) => {
          let unit: LocationUnit = _.find(this.unitsLookup.items, (item: LocationUnit): boolean => {
            return item.id === id;
          });
          if (unit) unitToSelect.push(unit);
        });
        if (unitToSelect && unitToSelect.length) {
          this.unitFilter = unitToSelect;
          this.onFilterChange('unit', this.unitFilter);
        }
      }
    }

    let shiftsToSelect: Shift[] = [];
    if (this.shiftIds && this.shiftIds.length) {
      if (this.shiftsLookup && this.shiftsLookup.items) {
        _.forEach(this.shiftIds, (id: number) => {
          let shift: Shift = _.find(this.shiftsLookup.items, (item: Shift): boolean => {
            return item.id === id;
          });
          if (shift) shiftsToSelect.push(shift);
        });
        if (shiftsToSelect && shiftsToSelect.length) {
          this.shiftFilter = shiftsToSelect;
          this.onFilterChange(LookupType.shift, this.shiftFilter);
        }
      }
    }

    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, FILTERS);
    let filters = state.value;
    if (filters) {
      if (!positionGroupToSelect || !positionGroupToSelect.length) {
        this.positionGroupFilter = filters.positionGroup;
      }
      if (!positionToSelect || !positionToSelect.length) {
        this.posFilter = filters.positions;
      }
      if (!unitToSelect || !unitToSelect.length) {
        this.unitFilter = filters.units;
      }
      if (!shiftsToSelect || !shiftsToSelect.length) {
        this.shiftFilter = filters.shifts;
      }
      this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
      this.onFilterChange(LookupType.position, this.posFilter);
      this.onFilterChange(LookupType.shift, this.shiftFilter);
      this.onFilterChange('unit', this.unitFilter);
    } else {
      if (!positionGroupToSelect || !positionGroupToSelect.length) {
         this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
       }
      if (positionToSelect && positionToSelect.length) {
        this.onFilterChange(LookupType.position, this.posFilter);
      }
      if (unitToSelect || unitToSelect.length) {
        this.onFilterChange('unit', this.unitFilter);
      }
      if (shiftsToSelect || shiftsToSelect.length) {
        this.onFilterChange(LookupType.shift, this.shiftFilter);
      }
    }
    this.applyFilters();
  }

  private restoreGroupsStates(groups: DetailGroup[]): void {
    _.forEach(groups, (group: DetailGroup) => {
      let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, group.name);
      if (state && state.value) {
        let val: any = state.value;
        group.isEmptySlotFilterSelected = val.isEmptySlotFilterSelected && (group.emptySlotCount > 0);
        group.isOutOfParFilterSelected = val.isOutOfParFilterSelected && (group.outOfParCount > 0);
        group.isMessageFilterSelected = val.isMessageFilterSelected && (group.messageCount > 0);
        group.isOvertimeFilterSelected = val.isOvertimeFilterSelected && (group.overtimeCount > 0);
        group.recalculateFilteredValues();
      }
    });
  }

  private getField(fields: DetailField[], name: string): DetailField {
    return _.find(fields, (field: DetailField) => {
      return field.name === name;
    });
  }

  public captureScreen() {
    let data = document.getElementById('contentToConvert');
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      let imgWidth = 210;
      let pageHeight = 295;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');

      let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let position = 10;
      let _selectedDateVal = document.getElementById('_selectedDateVal').innerText;
      let current = new Date(this.dateOn);
      let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
      let cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
      let dateTime = cDate + ' ' + cTime;

      pdf.setFontSize(10);
      //pdf.text(dateTime, 7, 7);
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('Daily_Unit_Assignment_' + cDate + '.pdf'); // Generated PDF
    });
  }

  public exportToExcel(): void {
    let myCompOneObj = new ExportExcelGridComponent();
    myCompOneObj.exportData();
  }

}


import {of as observableOf,  Observable ,  Subscription } from 'rxjs';

import {delay} from 'rxjs/operators';
import * as _ from 'lodash';

import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';

import { appConfig, IApplicationConfig } from '../../../../../../app.config';

import { EmployeeWarningSections, EmployeeWarningBasic, EmployeeWarningExtended } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-warnings-entity',
  templateUrl: 'employee-sections-warnings-entity.component.html',
  styleUrls: ['employee-sections-warnings-entity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsWarningsEntityComponent implements OnInit, OnDestroy {
  @Input()
  public set employeeWarning(v: EmployeeWarningBasic) {
    this.assignWarningBasic(v);
  }

  @Output()
  public changeWarning = new EventEmitter<EmployeeWarningExtended>();

  @Output()
  public warningEdited = new EventEmitter<boolean>();

  public warningBasic: EmployeeWarningBasic;
  public warning: EmployeeWarningExtended;
  public isLoading = true;

  public get canDownload(): boolean {
    return _.isObjectLike(this.warningBasic) && _.isFinite(this.warningBasic.id);
  }

  @ViewChild('form', { static: true })
  public form: NgForm;

  @unsubscribeAll()
  public subscriptions: StringMap<Subscription> = {};
  public appConfig: IApplicationConfig = appConfig;

  constructor(
    private readonly manService: EmployeeSectionWarningsManagementService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.warning = new EmployeeWarningExtended();
  }

  public ngOnInit(): void {
    this.manService.createLoadedWarning();

    if (_.isNil(this.warningBasic)) {
      this.manService.getWarningTemplate();
    } else {
      this.manService.getWarning(this.warningBasic.id);
    }

    this.subscriptions.spinner = this.manService.subscribeToSpinner(isShown => {
      this.isLoading = isShown;
      this.updateView();
    });

    this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(warning => {
      this.warning = warning;
      this.updateView();
    });

    this.subscriptions.statusChanges = this.form.statusChanges.subscribe(() => {
      this.manService.changeSectionValidity(EmployeeWarningSections.MainSection, this.form.valid);
    });

    this.subscriptions.canSave = this.manService.subscribeToCanSaveWarning(canSave => {
      this.changeWarning.next(canSave ? this.warning : null);
    });

    this.subscriptions.canSave = this.manService.subscribeToChangesInWarning(hasChanges => {
      this.warningEdited.next(hasChanges);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.manService.destroyLoadedWarning();
  }

  public onChangeWarningSubject(): void {
    this.manService.markWarningAsEdited(true);
  }

  public onChangeWarningDate(): void {
    this.manService.markWarningAsEdited(true);
  }

  public onDownload(): void {
    this.manService.downloadWarning(this.warningBasic.id);
  }

  private updateView(): void {
    observableOf(true).pipe(
      delay(200))
      .subscribe(() => this.changeDetector.detectChanges());
  }

  private assignWarningBasic(warning: EmployeeWarningBasic): void {
    this.warningBasic = _.isObjectLike(warning) && _.isFinite(warning.id) ? warning : null;
  }
}

export interface IWeatherConfig {
    api: {
      root: string;
      current: string;
      forecast: string;
    };
}


export const weatherConfig: IWeatherConfig = {
    api: {
      root: 'weather',
      current: 'current',
      forecast: 'forecast'
    }
};


import {debounceTime} from 'rxjs/operators';
import * as _ from 'lodash';

import { Component, Input, Output, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Subscription ,  Subject ,  Observable } from 'rxjs';


import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ShiftEligibleEmployee, ShiftReplacementSms } from '../../../models/index';
import { ShiftReplacementManagementService } from '../../../services/index';
import { IconAccess } from '../../../../portal/models/messages/icon-access.model';
import { OrgLevel } from '../../../../state-model/models/org-level/org-level';
import { NotificationsApiService } from '../../../../portal/services/notifications/notifications-api.service';


@Component({
  moduleId: module.id,
  selector: 'slx-shift-replacement-sms',
  templateUrl: 'shift-replacement-sms.component.html',
  styleUrls: ['shift-replacement-sms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShiftReplacementSmsComponent implements OnInit, OnDestroy {
  @Input('message')
  public set initialMessage(msg: string) {
    if (_.isString(msg)) {
      this.message = msg;
    }
  }
  
  public appConfig: IApplicationConfig;
  public recipients: ShiftEligibleEmployee[];
  public message: string;
  public maxSmsCharactersLength: number = 255;
  private messageChange$: Subject<null>;
  @unsubscribe()
  private checkedEmployeesSubscription: Subscription;

  @unsubscribe()
  private messageChangeSubscription: Subscription;
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<any>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  public orgLevel: OrgLevel;

  constructor(private shiftManagementService: ShiftReplacementManagementService, private changeDetector: ChangeDetectorRef, private notificationApiService: NotificationsApiService) {
    this.appConfig = appConfig;
    this.messageChange$ = new Subject<null>();
  }

  public ngOnInit(): void {
    this.checkedEmployeesSubscription = this.shiftManagementService
      .subscribeToCheckedEmployees((selectedEmployees: ShiftEligibleEmployee[]) => {
        this.recipients = selectedEmployees;
        this.changeDetector.detectChanges();

        this.changeAvailabilityToSendSms();
      });
    this.messageChangeSubscription = this.messageChange$.pipe(
      debounceTime(400))
      .subscribe(() => {
        this.changeAvailabilityToSendSms();
      });
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevel = orgLevel;
        this.checkMessageCenterAccess();
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onMessageChange(): void {
    this.messageChange$.next();
  }

  public onRemoveRecipient(emp: ShiftEligibleEmployee): void {
    const index: number = _.findIndex(this.recipients, (recipient: ShiftEligibleEmployee) => recipient.employee.id === emp.employee.id);
    if (index !== -1) {
      this.recipients.splice(index, 1);
      this.changeAvailabilityToSendSms();
      this.shiftManagementService.changeCheckedEmployees(this.recipients);
    }
  }

  private changeAvailabilityToSendSms(): void {
    let smsData: ShiftReplacementSms = new ShiftReplacementSms();
    if (this.message.length > 0 && this.recipients.length > 0) {
      smsData.message = this.message;
      smsData.recipients = this.recipients;
    }
    this.shiftManagementService.changeAvailabilityToSendSms(smsData);
  }

  public async checkMessageCenterAccess(): Promise<void> {
    const access: IconAccess = await this.notificationApiService.getIconAccessCheck(this.orgLevel.id);
    if (access.isMessageCenterEnabled) {
      this.maxSmsCharactersLength = 320;
      this.changeDetector.detectChanges();
    }
  }

}

import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Observable ,  ReplaySubject ,  Subject ,  Subscription ,  from } from 'rxjs';
import { Assert } from '../../../framework';
import { EmployeeSmsManagementApiService } from './employee-sms-management-api.service';
import { IEmployeeSMSContacts, EmployeeSMSContacts, EmployeeSendSms } from '../../../app-modules/message-center/models';
import { debounceTime, switchMap, distinctUntilChanged } from 'rxjs/operators';


@Injectable()
export class EmployeeSmsManagementService {

  private loading$ = new Subject<boolean>();
  private sendSms$ = new Subject<boolean>();

  constructor(
    private apiService: EmployeeSmsManagementApiService
  ) {

  }

  public subscribeToSendSms(callback: (v) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.sendSms$.subscribe(callback);
  }

  public async sendSmsToEmployee(employeeSendSms: EmployeeSendSms) :  Promise<any> {
    this.startProgress();   
    return this.apiService.sendSmsToEmployee(employeeSendSms).then((response: any)=> {
      this.stopProgress();
    })
  }

  public startProgress(): void {
    this.loading$.next(true);
  }

  public stopProgress(): void {
    this.loading$.next(false);
  }

  public searchEmployee(terms$: Observable<string>): Observable<EmployeeSMSContacts[]> {
    return terms$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => from(this.apiService.getEmployeeSmsManagementList(term)))
    )
  }

}

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CopyPayCodesManagementService } from '../../services/copy-paycodes/copy-paycodes-management.service';
import { OrgLevel } from '../../../state-model/models/index';
import { mutableSelect } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common/index';
import { AccessManagementService } from '../../services';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { Location } from '@angular/common';

@Component({
  selector: 'slx-copy-paycodes',
  templateUrl: 'copy-paycodes.component.html',
  styleUrls: ['copy-paycodes.component.scss'],
  providers: [
    AccessManagementService,
    CopyPayCodesManagementService
  ]
})
export class CopyPayCodesComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public appConfig: IApplicationConfig;
  public currentPaycodes: any[] = [];
  public currentOrganizations: any[] = [];
  public orgLevels: any[] = [];
  public orgLevelId: number;
  public orgId: number;
  public isAllPaycodesCheck: boolean = false;
  public isAllOrgsCheck: boolean = false;
  public overrideMatchingException: boolean = false;
  public copyIfExceptionExists: boolean = false;
  public state: { isLoading: boolean } = { isLoading: false };

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  constructor(
    private management: CopyPayCodesManagementService,
    private stateManagement: StateManagementService,
    private cdr: ChangeDetectorRef,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.state.isLoading = true; // Set loading state to true at the start
    this.subscriptions.push(
      this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
        this.orgLevelId = orgLevel.id;
        this.orgId = orgLevel.organizationId;
        this.loadData(); // Load data on init
      })
    );
    this.bindPageLoadData();
    this.management.init();
  }

  private loadData(): void {
    // Promise.all to wait for both promises (loadPayCodes and loadOrganizations) to complete
    Promise.all([this.loadOrganizations(), this.loadPayCodes()])
      .then(() => {
        this.state.isLoading = false; // Set loading state to false when both are done
        this.cdr.detectChanges();
      })
      .catch(error => {
        console.error('Error loading data:', error);
        this.state.isLoading = false; // Set loading state to false even if one fails
        this.cdr.detectChanges();
      });
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public bindPageLoadData(): void {
    this.initServices();
    this.appConfig = appConfig;
  }

  private initServices(): void {
    this.stateManagement.init('CopyPayCodesComponent');
    this.management.init();
  }

  private loadPayCodes(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.orgLevelId) {
        this.management.loadPayCodes(this.orgId).then(() => {
          this.currentPaycodes = this.management.container.records
            .filter(pc => pc.exceptionDescription) // Ensure valid pay codes
            .map(pc => ({
              paycodeName: pc.exceptionDescription,
              id: pc.id,
              isChecked: false
            }));
          resolve();
        }).catch(error => {
          console.error('Error loading pay codes:', error);
          reject(error);
        });
      } else {
        resolve(); // Resolve immediately if no orgLevelId
      }
    });
  }

  handleDescriptionClick(event: MouseEvent, id: string): void {
    if (event) {
      event.preventDefault();
    }
    const newPath = 'apps/common/paycode_details?orgLevelId=' + this.orgLevelId + '&id=' + id;
    const url = window.location.origin + '/#/' + newPath;
    window.location.href = url;
  }

  private loadOrganizations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.orgLevelId) {
        this.management.loadOrganizations().then(() => {
          this.currentOrganizations = this.management.container.organizations
            .filter(o => o.id != this.orgId) // Apply any necessary filters
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(o => ({
              organizationName: o.name,
              id: o.id,
              isChecked: false
            }));
          resolve();
        }).catch(error => {
          console.error('Error loading organizations:', error);
          reject(error);
        });
      } else {
        resolve(); // Resolve immediately if no orgLevelId
      }
    });
  }

  selectAllPaycodes() {
    this.isAllPaycodesCheck = !this.isAllPaycodesCheck;
    this.setAllCheck(this.currentPaycodes, this.isAllPaycodesCheck);
  }

  selectAllOrganizations() {
    this.isAllOrgsCheck = !this.isAllOrgsCheck;
    this.setAllCheck(this.currentOrganizations, this.isAllOrgsCheck);
  }

  onChangePaycodeCheck(e, data) {
    data.isChecked = !data.isChecked;
    this.isAllPaycodesCheck = this.currentPaycodes.every(pc => pc.isChecked);
  }

  onChangeOrgLevelCheck(e, data) {
    data.isChecked = !data.isChecked;
    this.isAllOrgsCheck = this.currentOrganizations.every(org => org.isChecked);
  }

  setAllCheck(data, isChecked) {
    data.forEach(item => item.isChecked = isChecked);
  }

  get saveDisabled(): boolean {
    const currentPaycodesChecked = this.currentPaycodes.filter(i => i.isChecked);
    const orgLevelsChecked = this.currentOrganizations.filter(i => i.isChecked);
    return !(currentPaycodesChecked.length > 0 && orgLevelsChecked.length > 0);
  }

  // Method to handle changes in the first checkbox
  public onOverrideMatchingExceptionChange(event: any) {
    this.overrideMatchingException = event.target.checked;
    if (!this.overrideMatchingException) {
      this.copyIfExceptionExists = false;
    }
  }

  // Method to handle changes in the second checkbox
  public onCopyIfExceptionExistsChange(event: any) {
    if (event.target.checked) {
      this.overrideMatchingException = true;
    }
    this.copyIfExceptionExists = event.target.checked;
  }

  save() {
    const selectedPayCodeIds = this.currentPaycodes.filter(pc => pc.isChecked).map(pc => pc.id);
    const selectedOrgIds = this.currentOrganizations.filter(org => org.isChecked).map(org => org.id);

    this.state.isLoading = true; // Set loading state to true
    this.management.copyPayCodes(
      selectedPayCodeIds,
      selectedOrgIds,
      this.orgId,
      this.overrideMatchingException,
      this.copyIfExceptionExists
    ).then(() => {
      console.log('Save operation completed successfully.');
      this.discard();  // Uncheck everything after a successful save
      this.state.isLoading = false; // Set loading state to false
    }).catch(error => {
      console.error('Failed to copy pay codes:', error);
      this.state.isLoading = false; // Set loading state to false
    });
  }

  discard() {
    this.isAllPaycodesCheck = false;
    this.isAllOrgsCheck = false;

    this.setAllCheck(this.currentPaycodes, false);
    this.setAllCheck(this.currentOrganizations, false);

    this.cdr.detectChanges();
  }

  cancelChanges(): void {
    this.location.back(); // Navigates back to the previous page
  }
}

import {combineLatest} from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChild, NgModule } from '@angular/core';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

import { unsubscribe, unsubscribeAll } from '../../../../../core/decorators/index';

import { KendoGridStateHelper, saveEvent } from '../../../../../common/models/index';
import { PbjManualEntryManagementService } from '../../../services/pbj-manual-entry/pbj-manual-entry-management.service';
import { PBJManualTimeEntity, PBJManualTimeEntry } from '../../../models/index';
import { Organization, Department, IOrganization, IDepartment, Lookup, LookupType, PbjActualPosition } from '../../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';
import { OrgLevelWatchService, LookupService, EmployeeDefinitionsApiService } from '../../../../../organization/services/index';
import { RangeDates } from '../../../../../common/models/range-dates';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ConfirmDialog2Component, ConfirmOptions, ModalService } from '../../../../../common/index';
import { AutocompleteComponent } from '../../../../../components-library/components';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-manual-entry-grid',
  templateUrl: 'pbj-manual-entry-grid.component.html',
  styleUrls: ['pbj-manual-entry-grid.component.scss'],
})

export class PbjManualEntryGridComponent implements OnInit, OnDestroy {
  @Input()
  public pageSize: number;
  public canAdd: boolean;
  public gridState: KendoGridStateHelper<any>;
  public appConfig: IApplicationConfig;
  public selectedAll: boolean;
  public employeesLookup: Lookup;
  public organizationsLookup: Lookup;
  public departmentsLookup: Lookup;
  public positionsLookup: Lookup;
  public isPositionReadonly: boolean;
  public filterDates: RangeDates;
  public hours: { min: number, max: number };
  public actualRange: { startDate: Date, endDate: Date };
  public get isOrganizationReadonly(): boolean {
    return _.isObject(this.predefinedOrganization);
  }
  public get isDepartmentReadonly(): boolean {
    return _.isObject(this.predefinedDepartment);
  }
  public mealGeneratedType: string = 'PBJMeal';
  public xlsxName = 'PBJ_Manual_Entry_';
  public pdfName = 'PBJ_Manual_Entry_';
  public isLoading: boolean = false;

  private entity: PBJManualTimeEntity;
  private lookupService: LookupService;
  private managementService: PbjManualEntryManagementService;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private onDateRangeSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private onSavedSubscription: Subscription;
  @unsubscribe()
  private onAddEntrySubscription: Subscription;
  @unsubscribe()
  private onAddedEntrySubscription: Subscription;
  @unsubscribe()
  private onRemovedEntriesSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private onEditStateSubscription: Subscription;

  @unsubscribeAll()
  private manualEntrySubscriptions: StringMap<Subscription> = {};
  
  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;

  @ViewChild('employeeModel', {static: true})
  private empModel: AutocompleteComponent;

  private orgLevel: OrgLevel;
  private orgLevelWatchService: OrgLevelWatchService;
  private predefinedOrganization: IOrganization;
  private predefinedDepartment: IDepartment;
  private currentEmployeeId: number;
  private currentOrganization: IOrganization;
  private currentDepartment: IDepartment;
  private currentPositions: PbjActualPosition[];

  constructor(managementService: PbjManualEntryManagementService, 
    lookupService: LookupService, 
    orgLevelWatchService: OrgLevelWatchService, 
    employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    private modalService: ModalService
    ) {
    this.appConfig = appConfig;

    this.gridState = new KendoGridStateHelper();
    this.gridState.state.skip = 0;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = this.createSorting();
    this.gridState.state.group = [];

    this.managementService = managementService;
    this.lookupService = lookupService;
    this.orgLevelWatchService = orgLevelWatchService;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;

    this.selectedAll = false;
    this.predefinedOrganization = null;
    this.predefinedDepartment = null;
    this.isPositionReadonly = true;
    this.hours = { min: -24, max: 24 };
    this.actualRange = { startDate: null, endDate: null };

    this.employeesLookup = this.createLookup([], '', '');
    this.organizationsLookup = this.createLookup([], '', '');
    this.departmentsLookup = this.createLookup([], '', '');
    this.positionsLookup = this.createLookup([], '', '');

    this.pageSize = 20;
  }

  public ngOnInit(): void {
    const date = new Date().toLocaleDateString();
    this.xlsxName += `${date}.xlsx`;
    this.pdfName += `${date}.pdf`;

    this.manualEntrySubscriptions.savePDF = this.managementService.exportToPdf$.subscribe(() => {
      this.grid.saveAsPDF();
    });

    this.manualEntrySubscriptions.saveExcel = this.managementService.exportToExcel$.subscribe(() => {
      this.grid.saveAsExcel();
    });

    this.orgLevelSubscription = this.managementService.onOrgLevel$.pipe(
      combineLatest(this.orgLevelWatchService.orgLevelTreeLoaded$))
      .subscribe((value: [OrgLevel, boolean]) => {
        const [orgLevel]: [OrgLevel, boolean] = value;
        this.orgLevel = orgLevel;
        this.defineOrgLevels();
      });

    this.onDateRangeSubscription = this.managementService.onFilterDates$
      .subscribe((filterDates: RangeDates) => {
        this.filterDates = filterDates;
        this.actualRange.startDate = filterDates.startDate;
        this.actualRange.endDate = filterDates.endDate;
      });

    this.onLoadedSubscription = this.managementService.onLoaded$
      .subscribe((entity: PBJManualTimeEntity): void => {
        this.entity = entity;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.resetControls();
        this.gridState.closeEditor(this.grid);
        this.managementService.editStateChanged(true);
        this.refreshGrid();
      });

    this.onAddEntrySubscription = this.managementService.onAddEntry$
      .subscribe((e: PBJManualTimeEntry): void => {
        let promise: Promise<any> = Promise.resolve();
        const entry: PBJManualTimeEntry = _.cloneDeep(e);

        if (entry.employee && entry.position) {
          promise = this.loadPositions(entry.employee.id, entry);
        } else {
          this.isPositionReadonly = true;
          entry.position = null;
          this.currentPositions = null;
        }

        promise.then(() => {
          if (e.employee) this.currentEmployeeId = entry.employee.id;
          if (e.position) this.assignPositionFromLookup(entry);

          this.gridState.closeEditor(this.grid);
          this.grid.addRow(entry);
          this.gridState.editedRecord = entry;
          this.managementService.editStateChanged(false);
        });
      });

    this.onAddedEntrySubscription = this.managementService.onAddedEntry$
      .subscribe((entry: PBJManualTimeEntry): void => {
        this.entity.entities.push(entry);
        this.refreshGrid();
      });

    this.onSavedSubscription = this.managementService.onSavedEntry$
      .subscribe((entry: PBJManualTimeEntry): void => {
        const editedEntry: PBJManualTimeEntry = _.find(this.entity.entities, { 'id': entry.id });
        _.assign(editedEntry, entry);
        this.refreshGrid();
      });

    this.onRemovedEntriesSubscription = this.managementService.onRemovedEntries$
      .subscribe((entriesIds: number[]): void => {
        _.forEach(entriesIds, (id) => {
          const index: number = _.findIndex(this.entity.entities, { id });
          this.entity.entities.splice(index, 1);
        });
        _.forEach(this.entity.entities, (entry) => entry._selectedEntry = false);
        this.selectedAll = false;
        this.filteringSelectedEntries();
        this.refreshGrid();
        this.managementService.editStateChanged(true);
      });

    this.gridSaveSubscription = this.gridState.onSave$
      .subscribe((item: saveEvent<PBJManualTimeEntry>): void => {
        if (item.isNew) {
          this.managementService.addEntry(item.dataItem);
        } else {
          this.managementService.saveEntry(item.dataItem);
        }
        this.managementService.editStateChanged(true);

        // After saving we should reset all controls to initial state
        this.resetControls();
      });

    this.gridEditSubscription = this.gridState.onEdit$
      .subscribe((entry: PBJManualTimeEntry) => {
        if (entry && entry.employee) {
          this.currentEmployeeId = entry.employee.id;
        }
        
        let employeeSource: number = null;

        if (this.currentDepartment) {
          employeeSource = this.currentDepartment.orgLevelId;
        } else if (this.currentOrganization) {
          employeeSource = this.currentOrganization.orgLevelId;
        }

        this.loadEmployees(employeeSource, entry.date).then(() => {
          this.managementService.editStateChanged(false);
        });
      });

    this.gridCancelSubscription = this.gridState.onCancel$
      .subscribe(() => {
        this.managementService.editStateChanged(true);

        // After canceling we should reset all controls to initial state
        this.resetControls();
      });

    this.onEditStateSubscription = this.managementService.onEditState$.subscribe((isCanEdit: boolean) => {
      this.canAdd = isCanEdit;
    });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid
      .subscribe(this.refreshGrid.bind(this));
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.entity.entities, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }

  public isEntryEditable(dataItem: PBJManualTimeEntry): boolean {
    if (dataItem) {
      return dataItem.entryType !== this.mealGeneratedType;
    }
    return false;
  }


  public getMinDate(item: PBJManualTimeEntry): Date {

    if (this.actualRange.startDate === null) return null;

    if (item !== null && item.employee !== null && item.employee.dateHired !== null && moment(item.employee.dateHired).isAfter(this.actualRange.startDate)) {
      return item.employee.dateHired;
    }

    return moment(this.actualRange.startDate).startOf('day').toDate();
  }

  public getMaxDate(item: PBJManualTimeEntry): Date {
    if (this.actualRange.endDate === null) return null;
    return this.actualRange.endDate;
  }

  public onClone(dataItem: PBJManualTimeEntry): void {
    this.managementService.cloneEntry(dataItem);
  }

  public onSelectedEntry(dataItem: PBJManualTimeEntry, isSelected: boolean): void {
    dataItem._selectedEntry = isSelected;
    this.updateSelectionState();
  }

  public updateSelectionState(): void {
    let selectedCount: number = 0;
    let editableCount: number = 0;
    let isAllSelected: boolean = false;
    if (this.entity) {
      _.forEach(this.entity.entities, (entry) => {
        if (this.isEntryEditable(entry)) {
          editableCount++;
          if (entry._selectedEntry) {
            selectedCount++;
          }
        } else {
          entry._selectedEntry = false;
        }
      });
      isAllSelected = selectedCount === editableCount;
    } else {
      isAllSelected = false;
    }

    this.selectedAll = isAllSelected;
    this.filteringSelectedEntries();
  }

  public onSelectedEntries(isAllSelected: boolean): void {
    this.selectedAll = isAllSelected;
    if (!isAllSelected) {
      _.forEach(this.entity.entities, (record) => {
        record._selectedEntry = false;
      });
    } else {
      const state: State = {
        skip: 0,
        take: undefined,
        filter: this.gridState.state.filter
      };
      const filtered = process(this.entity.entities, state);
      _.forEach(filtered.data, (record) => {
        if (this.isEntryEditable(record)) {
          record._selectedEntry = isAllSelected;
        }
      });
    }

    this.filteringSelectedEntries();
  }

  public isEmployeeSelected(entry: PBJManualTimeEntry): boolean {
    return !!_.get(entry, 'employee', null);
  }

  public isDateAndEmpSelected(entry: PBJManualTimeEntry): boolean {
    return !!_.get(entry, 'employee', null) && !!_.get(entry, 'date', null);
  }

  public isDateSelected(entry: PBJManualTimeEntry): boolean {
    return !!_.get(entry, 'date', null);
  }

  public onEmployeeChanged(entry: PBJManualTimeEntry): void {
    const employeeId = _.get(entry, 'employee.id', null);

    if (!_.isNumber(employeeId) || this.currentEmployeeId !== employeeId) {
      this.isLoading = true;
      this.currentPositions = null;
      this.currentEmployeeId = null;

      entry.center = null;
      entry.department = null;

      this.resetPosition(entry);
      this.resetLookups();
      this.isLoading = false;
    }

    if (_.isNumber(employeeId)) {
      this.isLoading = true;
      this.currentEmployeeId = employeeId;
      this.loadPositions(employeeId, entry);
    }
  }

  public onOrganizationChanged(entry: PBJManualTimeEntry): void {
    if (!entry.center) {
      entry.department = null;
      this.resetPosition(entry);

      return;
    }

    const employeeId: number = _.get(entry, 'employee.id', null);

    this.loadPositions(employeeId, entry).then(() => {
      const centerId: number = _.get(entry, 'center.id', null);
      const departmentId: number = _.get(entry, 'department.id', null);

      let positions = _.filter(this.currentPositions, position => position.organizationId === centerId);
      let departments = _.map(positions, position => {
        const department = new Department();
        department.id = position.departmentId;
        department.name = position.departmentName;
        department.parentOrganizationId = position.organizationId;
        return department;
      });
      departments = _.uniqBy(departments, department => department.id);
      departments = _.sortBy(departments, department => department.name);

      this.departmentsLookup = this.createLookup(departments, 'name', 'id');

      let findDepartmentId = departmentId;

      if (!_.isNumber(departmentId)) {
        const primaryPosition = _.first(_.sortBy(_.filter(positions, position => position.isPrimary && position.organizationId === centerId), x => x.id))

        if (primaryPosition) {
          findDepartmentId = primaryPosition.departmentId;
        }
      }

      let departmentFound: Department;

      if (findDepartmentId) {
        departmentFound = departments.find(department =>
          department.id === findDepartmentId && department.parentOrganizationId === centerId);
      }

      if (departmentFound) {
        entry.department = departmentFound;
      } else {
        entry.department = null;
        this.resetPosition(entry);
      }
    });
  }

  public onDepartmentChanged(entry: PBJManualTimeEntry): void {
    if (!entry.department) {
      if (entry.center && !this.isEmployeeSelected(entry)) {
        this.loadEmployees(entry.center.orgLevelId);
      }
      return;
    }

    const employeeId: number = _.get(entry, 'employee.id', null);

    this.loadPositions(employeeId, entry).then(() => {
      const departmentId: number = _.get(entry, 'department.id', null);

      let positions = _.filter(this.currentPositions, position => position.departmentId === departmentId);

      this.positionsLookup = this.createLookup(positions, 'nameWithDate', 'nameWithDate');
      this.isPositionReadonly = false;

      this.assignPositionFromLookup(entry);
    });
  }

  public onPositionChanged(entry: PBJManualTimeEntry): void {
    const startDate: Date = _.get(entry, 'position.startDate', null);
    const endDate: Date = _.get(entry, 'position.endDate', null);

    if (_.isDate(startDate)) {
      const posStartDate = moment(startDate);
      if (posStartDate.isBefore(this.filterDates.startDate)) {
        this.actualRange.startDate = this.filterDates.startDate;
      }
    } else {
      this.actualRange.startDate = this.filterDates.startDate;
    }

    if (_.isDate(endDate)) {
      const posEndDate = moment(endDate);
      if (posEndDate.isAfter(this.filterDates.endDate)) {
        this.actualRange.endDate = this.filterDates.endDate;
      }
    } else {
      this.actualRange.endDate = this.filterDates.endDate;
    }
    this.isLoading = false;
  }

  private assignPositionFromLookup(entry: PBJManualTimeEntry): void {
    if (!this.currentPositions || !this.currentPositions.length) {
      return;
    }

    const centerId = _.get(entry, 'center.id', null);
    const positionId = _.get(entry, 'position.id', null);
    const departmentId = _.get(entry, 'department.id', null);

    let foundPosition: PbjActualPosition;

    if (positionId && departmentId) {
      foundPosition = this.currentPositions.find(position =>
        position.id === positionId && position.departmentId === departmentId);
    }

    if (!foundPosition && centerId && departmentId) {
      foundPosition = _.first(_.sortBy(_.filter(this.currentPositions, position => 
        position.isPrimary && position.organizationId === centerId && position.departmentId === departmentId), x => x.id));
    }

    if (foundPosition) {
      entry.position = foundPosition;
    } else {
      this.resetPosition(entry);

      if (departmentId) {
        this.isPositionReadonly = false;
      }
    }
  }

  private resetLookups(): void {
    this.positionsLookup = this.createLookup([], '', '');
    this.departmentsLookup = this.createLookup([], '', '');
    this.organizationsLookup = this.createLookup([], '', '');
  }

  private resetControls(entryDate: Date = null): void {
    let employeeSource: number = null;

    if (this.currentDepartment) {
      employeeSource = this.currentDepartment.orgLevelId;
    } else if (this.currentOrganization) {
      employeeSource = this.currentOrganization.orgLevelId;
    }

    this.loadEmployees(employeeSource, entryDate);

    this.currentPositions = null;
    this.isPositionReadonly = true;
  }

  private resetPosition(entry: PBJManualTimeEntry): void {
    this.isPositionReadonly = true;
    entry.position = null;
    this.currentPositions = null;

    this.actualRange.startDate = null;
    this.actualRange.endDate = null;
  }

  private filteringSelectedEntries(): void {
    let seletedEntries: PBJManualTimeEntry[] = [];
    if (this.entity) {
      seletedEntries = _.filter(this.entity.entities, { _selectedEntry: true });
    }
    this.managementService.onEntriesSelected$.next(seletedEntries);
  }

  private defineOrgLevels(): void {
    switch (this.orgLevel.type) {
      case OrgLevelType.department:
        const parentOrgLevel: OrgLevel = this.orgLevelWatchService.getOrgLevelById(this.orgLevel.parentId);
        const currentOrgLevel: OrgLevel = this.orgLevelWatchService.getOrgLevelById(this.orgLevel.id);

        if (parentOrgLevel) this.predefinedOrganization = this.makeOrganization(parentOrgLevel);
        this.predefinedDepartment = this.makeDepartment(currentOrgLevel);
        break;
      case OrgLevelType.organization:
        this.predefinedOrganization = this.makeOrganization(this.orgLevel);
        this.predefinedDepartment = null;
        break;
      case OrgLevelType.corporation:
        this.predefinedOrganization = null;
        this.predefinedDepartment = null;
        break;
      default:
    }

    this.currentOrganization = this.predefinedOrganization;
    this.currentDepartment = this.predefinedDepartment;
  }

  private makeOrganization(orgLevel: OrgLevel): IOrganization {
    const entity = new Organization();
    entity.id = orgLevel.relatedItemId;
    entity.name = orgLevel.name;
    entity.orgLevelId = orgLevel.id;

    return entity;
  }

  private makeDepartment(orgLevel: OrgLevel): IDepartment {
    const entity = new Department();
    entity.id = orgLevel.relatedItemId;
    entity.name = orgLevel.name;
    entity.orgLevelId = orgLevel.id;

    return entity;
  }

  private createLookup(items: any[], titleField: string, valueField: string): Lookup {
    let lookup: Lookup = new Lookup();
    lookup.titleField = titleField;
    lookup.valueField = valueField;
    lookup.items = items;

    return lookup;
  }

  private createSorting(): SortDescriptor[] {
    const sorting: SortDescriptor[] = [];
    sorting.push({ field: 'addedDate', dir: 'desc' });
    return sorting;
  }

  public onChangeDate(effectiveDate: Date, item: PBJManualTimeEntry): void {
    let employeeSource: number = null;

    if (this.currentDepartment) {
      employeeSource = this.currentDepartment.orgLevelId;
    } else if (this.currentOrganization) {
      employeeSource = this.currentOrganization.orgLevelId;
    }

    this.loadEmployees(employeeSource, effectiveDate);

    if (item.employee != null && (item.employee.dateTerminated !== null && moment(effectiveDate).isAfter(item.employee.dateTerminated)))
    {
      var termDate = moment(item.employee.dateTerminated).format(appConfig.dateFormat);
      var msg = `You have selected a date that is after the employee's Termination date (${termDate}), Please verify the selected date before proceeding`;
      let options: ConfirmOptions = new ConfirmOptions();
      options.showOK = true;
      options.buttonOKtext = 'OK';

      ConfirmDialog2Component.openDialog(
        'Confirmation',
        msg,
        this.modalService,
        (result: boolean) => {},
        options
      );
    }
  }

  private loadEmployees(orgLevelId?: number, entryDate: Date = null): Promise<Lookup> {
    var updatedDates = _.clone(this.filterDates);
    if (entryDate !== null) {
      updatedDates.startDate = entryDate;
    }
    return this.lookupService
      .getLookup({ lookupType: LookupType.manualEntryActiveEmployees, orgLevelId, dateRange: updatedDates })
      .then((employeesLookup: Lookup) => {
        const names = new Map<string, Set<number>>();
        let duplicates = new Set<number>();
        employeesLookup.items.forEach((emp, index) => {
          const value = (names.get(emp.name) || new Set()).add(index);
          names.set(emp.name, value);
          if (value.size > 1) {
            value.forEach((v) => duplicates.add(v));
          }
        });

        if (duplicates.size > 0) {
          duplicates.forEach((index) => {
            const emp = employeesLookup.items[index];
            emp.name = `${emp.name} (${emp.id})`;
          });
        }

        return this.employeesLookup = employeesLookup;
      });
  }

  private loadPositions(employeeId: number, entry: PBJManualTimeEntry): Promise<Lookup> {
    let orgLevelId: number = null;

    if (this.currentDepartment) {
      orgLevelId = this.currentDepartment.orgLevelId;
    } else if (this.currentOrganization) {
      orgLevelId = this.currentOrganization.orgLevelId;
    }

    return this.lookupService
      .getLookup({ lookupType: LookupType.pbjPosition, orgLevelId, employeeId, isActive: false })
      .then((lookup: Lookup) => this.processPositions(lookup, entry));
  }

  private processPositions(lookup: Lookup, entry: PBJManualTimeEntry): Lookup {
    let processedLookup = this.filteringPositionsByDates(lookup, this.filterDates.startDate, this.filterDates.endDate);
    processedLookup = this.filteringOutDuplicates(lookup);

    this.currentPositions = processedLookup.items;

    let organizations = _.map(processedLookup.items, (position: PbjActualPosition) => {
      const organization = new Organization();
      organization.id = position.organizationId;
      organization.name = position.organizationName;
      return organization;
    });
    organizations = _.uniqBy(organizations, organization => organization.id);
    organizations = _.sortBy(organizations, organization => organization.name);

    this.organizationsLookup = this.createLookup(organizations, 'name', 'id');

    const centerId = _.get(entry, 'center.id', null);

    if (!centerId && this.currentOrganization) {
      const foundOrganization = organizations.find(organization => organization.id === this.currentOrganization.id);

      if (foundOrganization) {
        entry.center = foundOrganization;

        if (entry.department) {
          this.onOrganizationChanged(entry);
        }
      }
    } else {
      this.isLoading = false;
    }

    return processedLookup;
  }

  private filteringPositionsByDates(positionsLookup: Lookup, startDate: Date, endDate: Date): Lookup {
    if (positionsLookup.items.length === 0) return positionsLookup;

    const rangeStart = moment(startDate);
    const rangeEnd = moment(endDate);
    const positions: Lookup = this.createLookup([], 'nameWithDate', 'nameWithDate');
    positions.items = _.filter(positionsLookup.items, (position: PbjActualPosition) => {
      const posStartDate = position.startDate;
      let posEndDate = position.endDate;

      if (!_.isDate(posEndDate)) posEndDate = new Date();

      return rangeStart.isSameOrBefore(posEndDate) && rangeEnd.isSameOrAfter(posStartDate);
    });

    return positions;
  }

  private filteringOutDuplicates(lookup): Lookup {
    if (lookup.items.length === 0) return lookup;
    const positions: Lookup = this.createLookup([], 'nameWithDate', 'nameWithDate');
    const groupped = _.groupBy<PbjActualPosition>(lookup.items, (pos) => `${pos.id}_${pos.name}_${pos.orgLevelId}`);
    positions.items = _.map(groupped, (p) => {
      if (p.length > 1) {
        const sorted = _.sortBy(p, (pos) => pos.startDate.getTime());
        return _.head(sorted);
      }
      return _.head(p);
    });

    return positions;
  }

  private refreshGrid(): void {
    if (!this.entity) {
      this.gridState.view = null;

      return;
    }
    this.gridState.view = process(this.entity.entities, this.gridState.state);
  }
}

import * as _ from 'lodash';
import { Directive, Input, OnDestroy, ElementRef, AfterContentInit, NgZone } from '@angular/core';
import { EmpScrollService } from '../../services/index';
import { EmpScrollEvent, EmpScrollSection } from '../../models/index';
import { Subscription ,  Subscribable } from 'rxjs';

@Directive({
  selector: '[scrollContainer]',
})

export class ScrollContainerDirective implements AfterContentInit, OnDestroy {
  @Input('scrollContainer')
  public upperShiftActiveArea: number;

  private elementRef: ElementRef;
  private zone: NgZone;
  private scrollService: EmpScrollService;
  private sections: EmpScrollSection[];
  private preactiveSection: EmpScrollSection;
  private activeSection: EmpScrollSection;
  private sectionAddedSubscription: Subscription;
  private scrollUpdateSubscription: Subscription;
  private scrollResetSubscription: Subscription;
  private suspendScrollSubscription: Subscription;
  private clientHeight: number;
  private element: HTMLElement;
  private update: boolean;
  private suspended: boolean;
  private isUpScrolling: boolean;
  private prevScrollTop: number;
  private upperShiftViewedArea: number;

  constructor(elementRef: ElementRef, zone: NgZone, scrollService: EmpScrollService) {
    this.elementRef = elementRef;
    this.zone = zone;
    this.scrollService = scrollService;
    this.upperShiftActiveArea = 0;
    this.upperShiftViewedArea = 0;
    this.sections = [];
    this.prevScrollTop = 0;
    this.update = true;
    this.suspended = false;
    this.clientHeight = 0;
    this.element = null;
    this.preactiveSection = new EmpScrollSection();
    this.activeSection = new EmpScrollSection();
  }

  public ngAfterContentInit(): void {
    this.element = this.elementRef.nativeElement;
    this.clientHeight = this.element.clientHeight;

    this.zone.runOutsideAngular(() => {
      this.element.addEventListener('scroll', this.scrollHandler, false);
    });

    this.sectionAddedSubscription = this.scrollService.subscribeToAdded((section: EmpScrollSection) => {
      this.sections = this.scrollService.getSections();
    });

    this.scrollResetSubscription = this.scrollService.subscribeToResetScroll(() => this.applyReset());
    this.scrollUpdateSubscription = this.scrollService.subscribeToUpdateScroll(() => {
      this.update = true;
      this.calculateScroll();
    });

    this.suspendScrollSubscription = this.scrollService.subscribeToSuspendScroll((isPaused: boolean) => {
      this.suspended = isPaused;
      if (!this.suspended) {
        this.update = true;
        this.calculateScroll();
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.sectionAddedSubscription) {
      this.sectionAddedSubscription.unsubscribe();
    }
    if (this.scrollUpdateSubscription) {
      this.scrollUpdateSubscription.unsubscribe();
    }
    if (this.suspendScrollSubscription) {
      this.suspendScrollSubscription.unsubscribe();
    }
    if (this.scrollResetSubscription) {
      this.scrollResetSubscription.unsubscribe();
    }

    if (this.element && this.element.removeEventListener) {
      this.element.removeEventListener('scroll', this.scrollHandler, false);
    }
  }

  private applyReset(): void {
    this.sections.length = 0;
    this.preactiveSection = new EmpScrollSection();
    this.activeSection = new EmpScrollSection();
    this.prevScrollTop = 0;
    this.element.scrollTo(0, 0);
  }

  private scrollHandler = () => {
    this.calculateScroll();
  }

  private calculateScroll(): void {
    if (!this.suspended) {
      if (this.update) {
        this.updateScroll();
        this.update = false;
      }
      this.defineSections(this.clientHeight, this.element.scrollTop);
    }
  }

  private updateScroll(): void {
    this.sections = this.scrollService.getSections();
    this.clientHeight = this.element.clientHeight;

    const firstSection: EmpScrollSection = _.head(this.sections);
    this.upperShiftViewedArea = firstSection && firstSection.start || 0;
  }

  private defineSections(clientHeight: number, scrollTop: number): void {
    const preactiveSections: number[] = [];
    let activeSection: EmpScrollSection = null;

    const areaStart: number = scrollTop + this.upperShiftViewedArea;
    const areaEnd: number = areaStart + clientHeight - this.upperShiftViewedArea;
    const activeArea: number = areaStart + this.upperShiftActiveArea;
    this.isUpScrolling = this.prevScrollTop > scrollTop;

    _.forEach(this.sections, (section: EmpScrollSection, index: number) => {
      section.update();

      if (section.start < activeArea && section.end > activeArea) {
        section.active = true;
        activeSection = section;
      }
      if (
        section.start > areaStart && section.start < areaEnd ||
        section.end > areaStart && section.end < areaEnd ||
        section.start < areaStart && section.end > areaEnd
      ) {
        section.displayed = true;
        preactiveSections.push(index);
      }
    });

    this.prevScrollTop = scrollTop;
    this.changePreactive(preactiveSections);
    this.changeActive(activeSection);
  }

  private changePreactive(preactiveSections: number[]): void {
    if (preactiveSections.length > 0) {
      let index: number = Math.max(_.head(preactiveSections) - 1, 0); // scrolling up
      if (!this.isUpScrolling) {
        index = Math.min(_.last(preactiveSections) + 1, this.sections.length - 1); // scrolling down
      }
      const preactiveSection: EmpScrollSection = this.sections[index];
      if (this.preactiveSection.id !== preactiveSection.id) {
        preactiveSection.preactive = true;
        this.preactiveSection = preactiveSection;
        this.zone.run(() => {
          this.scrollService.changePreactive(preactiveSection);
        });
      }
    }
  }

  private changeActive(activeSection: EmpScrollSection): void {
    if (!_.isNull(activeSection) && this.activeSection.id !== activeSection.id) {
      this.activeSection = activeSection;
      this.zone.run(() => {
        this.scrollService.changeActive(activeSection);
      });
    }
  }
}

